import { zodResolver } from "@hookform/resolvers/zod";
import { employeeInformationValueSchema } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { DatePickerV2 } from "~/components/uiParts";

type Props = {
  onSave: ({
    dateOfBirth,
  }: Pick<z.infer<typeof employeeInformationValueSchema>, "dateOfBirth">) => void;
  onCancel: () => void;
  data?: Date;
};

export const DateOfBirthEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      dateOfBirth: data,
    },
    resolver: zodResolver(employeeInformationValueSchema.pick({ dateOfBirth: true })),
  });

  return (
    <InformationValueEditor
      form={
        <form style={{ display: "flex", columnGap: 16 }} onSubmit={handleSubmit(onSave)}>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field }) => (
              <DatePickerV2
                name={field.name}
                value={field.value || null}
                fullWidth
                placeholder="日程を選択"
                disablePast={false}
                onChange={(data) => {
                  field.onChange(data);
                }}
              />
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};
