import React, { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Loading } from "~/components/uiParts";
import { useGetLineAccessTokenByAuthorizeCode } from "~/hooks/employee/";
import { useSigninWithAccessToken } from "~/hooks/employee/useSigninWithCustomToken";
import { useLineAuthStateInLocalStorage } from "~/hooks/lineAuthentication/useLineAuthStateInLocalStorage";
import { useSnackbar } from "~/hooks/shared";
import { useNavigateWithToDestPath } from "~/hooks/shared/useNavigateWithToDestPath";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";

import { captureException } from "~/util";

// クエリパラメータからLINE認可コードを取得する
const useAuthorizeCodeInQueryParam = () => {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const authorizeCode = params.get("code") || "";
  return { authorizeCode };
};

// クエリパラメータからLINE認証用のstateを取得する
const useLineAuthStateInQueryParam = () => {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const value = params.get("state");

  return { value };
};

/**
 * LINE認可サーバーにリクエストした際にstateをsessionStorageに保存しておき、
 * このページに遷移した際にstateが一致するかを確認する。
 * 同一のブラウザからのリクエストであることを確認するための処理
 */
const validateLineAuthState = (stateInStorage: string | null, stateInQueryParam: string | null) => {
  if (!stateInStorage || !stateInQueryParam) throw new Error("stateが存在しません");
  if (stateInStorage !== stateInQueryParam) throw new Error("stateが一致しません");
};

// セッションストレージに保存したstateとクエリパラメータから取得したstateを使って同一ブラウザであることを検証する
// https://developers.line.biz/ja/docs/line-login/integrate-line-login/#receiving-the-authorization-code-or-error-response-with-a-web-app
const useValidateLineAuthState = () => {
  const { value: stateInQueryParam } = useLineAuthStateInQueryParam();
  const { value: stateInStorage, remove: removeStateInStorage } = useLineAuthStateInLocalStorage();

  const validate = useCallback(() => {
    validateLineAuthState(stateInStorage, stateInQueryParam);
  }, [stateInQueryParam, stateInStorage]);

  return { validate, removeStateInStorage };
};

/**
 * LINE認可サーバー(LINEプラットフォーム)でLINEアカウントの承認を終えた後
 * リダイレクトされるAfterAuthenticationByLinePlatformページでの処理
 */
const useAfterAuthenticationByLinePlatform = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { validate, removeStateInStorage } = useValidateLineAuthState();

  const { authorizeCode } = useAuthorizeCodeInQueryParam();

  const navigate = useNavigate();
  const { navigateToDestPath } = useNavigateWithToDestPath();

  const [isLoading, setLoading] = useState(true);

  const { getLineAccessTokenByAuthorizeCode } = useGetLineAccessTokenByAuthorizeCode();

  const { signinWithLineAccessToken } = useSigninWithAccessToken();
  const signinWithLineAuth = useCallback(async () => {
    try {
      // LINEアクセストークンの取得
      const { accessToken, isSuccess: isSuccessGetAccessToken } =
        await getLineAccessTokenByAuthorizeCode({
          authorizeCode,
          tenantId: getTenantIdFromDomain() ?? undefined,
        });
      if (!isSuccessGetAccessToken || !accessToken) {
        throw new Error("LINEアクセストークンの取得に失敗しました。");
      }

      await signinWithLineAccessToken({ accessToken });
      removeStateInStorage();
      navigateToDestPath();
    } catch (e) {
      captureException({
        error: e as Error,
        tags: { type: "AfterLineAuthentication" },
      });
      enqueueSnackbar("LINEログインに失敗しました", { variant: "error" });
      navigate("/portal/accounts/login");
    } finally {
      setLoading(false);
    }
  }, [
    getLineAccessTokenByAuthorizeCode,
    authorizeCode,
    signinWithLineAccessToken,
    removeStateInStorage,
    navigateToDestPath,
    enqueueSnackbar,
    navigate,
  ]);

  useEffect(() => {
    validate();
    signinWithLineAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
  };
};

export const AfterAuthenticationByLinePlatform: FC = () => {
  useAfterAuthenticationByLinePlatform();

  return <Loading size={"large"} fullHeight />;
};
