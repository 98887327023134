import { ContactMessageTemplateFolderWithTemplates } from "@onn/common";
import { useState } from "react";

export const useContactMessageTemplateMenu = (
  templateFolders: ContactMessageTemplateFolderWithTemplates[]
) => {
  const [childMenuAnchorEl, setChildMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(childMenuAnchorEl);
  const [selectedTemplateFolder, setSelectedTemplateFolder] = useState<
    ContactMessageTemplateFolderWithTemplates | undefined
  >(undefined);

  const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>) => {
    setSelectedTemplateFolder(
      templateFolders.find((folder) => folder.id === event.currentTarget.id)
    );
    setChildMenuAnchorEl(event.currentTarget);
  };
  const handleCloseChildMenu = () => {
    setChildMenuAnchorEl(null);
    if (selectedTemplateFolder)
      // NOTE: メニューが閉じる際にちらつきが生じてしまうので、完全に閉じるまで表示に必要な状態はリセットしない。
      setTimeout(() => {
        setSelectedTemplateFolder(undefined);
      }, 200);
  };

  return {
    childMenuAnchorEl,
    isOpen,
    handleClickMenuItem,
    handleCloseChildMenu,
    selectedTemplateFolder,
  };
};
