import { v4 } from "uuid";

import { AnswerOnnTaskTriggerSetting as IAnswerOnnTaskTriggerSetting } from "../../_gen/zodSchema/index";

import { answerOnnTaskTriggerSettingSchema } from "./schema";

export class AnswerOnnTaskTriggerSetting implements IAnswerOnnTaskTriggerSetting {
  static readonly type = "AnswerOnnTaskTriggerSetting" as const;
  type = AnswerOnnTaskTriggerSetting.type;

  static readonly validator = answerOnnTaskTriggerSettingSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  triggerId: string;
  onnTaskId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<AnswerOnnTaskTriggerSetting>, "type">) {
    const parsedInit = AnswerOnnTaskTriggerSetting.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.triggerId = parsedInit.triggerId;
    this.onnTaskId = parsedInit.onnTaskId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(
    params: Omit<
      ExcludeMethods<AnswerOnnTaskTriggerSetting>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new AnswerOnnTaskTriggerSetting({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
