import { Box } from "@material-ui/core";
import { OnnTask } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { OnnTaskContentContainer } from "./OnnTaskContentContainer";
import { TargetNewGraduateContainer } from "./TargetNewGraduateContainer";

type Props = {
  onnTask: OnnTask;
};

export const TabPage: FC<Props> = ({ onnTask }) => {
  return (
    <StyledBox display="flex" alignItems="flex-start" mt="40px" gridGap="40px">
      <Box flex={1}>
        <OnnTaskContentContainer onnTask={onnTask} />
      </Box>
      <Box width="336px">
        <TargetNewGraduateContainer onnTask={onnTask} />
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
`;
