import qs from "qs";

type Args = {
  path: string;
  liffId: string;
  query?: { key: string; value: string }[];
};

/**
 * @deprecated
 * 今後は generateLiffUrlString をクエリパラメータを渡せるように拡張してgenerateLiffUrlStringを使うこと
 */
export const generateLiffUrl = (args: Args) => {
  const queryString = (args.query || []).map((d) => qs.stringify({ [d.key]: d.value })).join("&");
  return `https://liff.line.me/${args.liffId}${args.path}` + (queryString ? `?${queryString}` : "");
};

export const liffUrls = {
  changeAuthenticationType: (params: Omit<Args, "path">) =>
    generateLiffUrl({ ...params, path: "/change_authentication" }),
};
