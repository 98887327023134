import React, { FC } from "react";

import { Loading } from "~/components/uiParts";

import { FileAttachedButtonForStorage } from "~/components/uiParts/FileAttachedButton";
import { useMetaDataByUrls } from "~/hooks/file";

export const FileQuestionContent: FC<{
  filePath: string | undefined;
  signedUrl: string | undefined;
}> = ({ filePath, signedUrl }) => {
  const { data, isLoading } = useMetaDataByUrls(filePath ? [filePath] : []);

  const ext = filePath?.split(".")?.pop() || "";
  const isImageFile = ["png", "jpg", "jpeg", "gif"].includes(ext);

  if (isLoading) return <Loading size="large" fullHeight />;
  if (!data) return <>回答なし</>;
  if (isImageFile) {
    return (
      <img
        src={(data[0] as (typeof data)[number]).url}
        width={"100%"}
        style={{ objectFit: "contain" }}
      />
    );
  } else {
    return <FileAttachedButtonForStorage filePath={filePath} signedUrl={signedUrl} />;
  }
};
