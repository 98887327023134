import { Box } from "@material-ui/core";
import React from "react";

import { Badge, Divider, Icon, Typography } from "~/components/uiParts";

export const PortalListItem = ({
  title,
  content,
  onClick,
  isRead,
  isLast,
}: {
  title: string;
  content: string;
  onClick: () => void;
  isRead: boolean;
  isLast: boolean;
}): JSX.Element => {
  return (
    <Box>
      <Box display="flex">
        <Box display="flex" alignItems="center" minWidth={"40px"}>
          <Badge color="secondary" variant={isRead ? "standard" : "dot"}>
            <Icon icon="notice" size="md" color="primary" />
          </Badge>
        </Box>
        <Box onClick={onClick} flexGrow={1} minWidth="100px">
          <Typography variant="body1" display="block" color="textPrimary" noWrap disablePreWrap>
            {title}
          </Typography>
          <Typography variant="caption" display="block" color="textSecondary" noWrap disablePreWrap>
            {content}
          </Typography>
        </Box>
      </Box>
      {!isLast && (
        <Box mt="16px" mb="16px">
          <Divider />
        </Box>
      )}
    </Box>
  );
};
