import { z } from "zod";

import { OnnFormTaskAnswerSchema } from "../../_gen/zodSchema/index";
import { Answer } from "../Answer";

export const onnFormTaskAnswerSchema = OnnFormTaskAnswerSchema.merge(
  z.object({
    firstReadAt: z.date().nullable(),
    lastReadAt: z.date().nullable(),
    lastAnsweredAt: z.date().nullable(),
    answeredFormRevisionId: z.string().nullable(),
    answers: z.array(z.record(z.any()).transform((v) => v as Answer)),
    createdAt: z.date().nullable(),
    updatedAt: z.date().nullable(),
  })
);

export type IOnnFormTaskAnswerSchema = z.infer<typeof onnFormTaskAnswerSchema>;
