import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  label: string;
  isSelected?: boolean;
  onClick: () => void;
  displayAlert?: boolean;
};

export const RecruitmentStatusButton: FC<Props> = ({
  label,
  isSelected,
  onClick,
  displayAlert,
}) => {
  const buttonColor = useMemo(() => {
    if (displayAlert) return "secondary";
    if (isSelected) return "primary";
    return "default";
  }, [displayAlert, isSelected]);

  const buttonProps: React.ComponentProps<typeof Button> = useMemo(
    () => ({
      variant: isSelected ? "contained" : "outlined",
      color: buttonColor,
      fullWidth: true,
      fullHeight: true,
      borderRadius: "regular",
      onClick: isSelected ? undefined : onClick,
    }),
    [buttonColor, isSelected, onClick]
  );

  return (
    <Box height={"48px"}>
      <Button {...buttonProps}>
        <Typography variant="body2" bold noWrap>
          {label}
        </Typography>
        {displayAlert && <Icon icon="alert" color={isSelected ? "white" : "secondary"} size="md" />}
      </Button>
    </Box>
  );
};
