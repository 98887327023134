import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  totalCount: number | undefined;
};

export const SearchResultCountTooltip: FC<Props> = ({ totalCount }) => {
  return (
    <Box borderRadius={5} bgcolor="white" px={1}>
      <Typography variant="caption" bold>
        {totalCount ?? "-"}
      </Typography>
    </Box>
  );
};
