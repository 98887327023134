import { EmployeeTag } from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { useCurrentUser } from "../employee/useCurrentUser";

import { apiClient } from "~/libs";

const getKey = (tenantId: string) => {
  return {
    endpoint: "/tag_api/employee-tags",
    tenantId,
  } as const;
};

export const useEmployeeTags = () => {
  const { currentUser } = useCurrentUser();
  return useSWR(getKey(currentUser.tenantId), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);

    return {
      employeeTags: response.data.employeeTags
        .map((employeeTag) => {
          return new EmployeeTag(employeeTag);
        })
        .sort((a, b) => {
          return b.createdAt.getTime() - a.createdAt.getTime();
        }),
      meta: response.meta,
    };
  });
};

export const useMutateEmployeeTags = () => {
  const { mutate } = useSWRConfig();
  const { currentUser } = useCurrentUser();

  const mutateEmployeeTags = useCallback(async () => {
    await mutate(getKey(currentUser.tenantId));
  }, [currentUser.tenantId, mutate]);

  return { mutateEmployeeTags };
};
