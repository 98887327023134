import { Switch } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useCallback } from "react";

import { RowData } from "../types";

import { Typography } from "~/components/uiParts";

export const useBodyCellRenderer = () => {
  const bodyCellRenderer = useCallback(
    ({
      columnIndex,
      rowData,
      onChangeIsAutoCancel,
    }: {
      columnIndex: number;
      rowData: RowData;
      onChangeIsAutoCancel: ({
        spaceId,
        shouldCancelDeliveryRegardlessOfScenario,
      }: {
        spaceId: string;
        shouldCancelDeliveryRegardlessOfScenario: boolean;
      }) => void;
    }) => {
      if (columnIndex === 0) {
        return (
          <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} padding={"8px"}>
            <Typography variant="body1" color="textPrimary">
              {rowData.spaceName}
            </Typography>
          </Stack>
        );
      }
      if (columnIndex === 1) {
        return (
          <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} padding={"8px"}>
            <Switch
              checked={rowData.spaceSetting?.shouldCancelDeliveryRegardlessOfScenario}
              onChange={() =>
                onChangeIsAutoCancel({
                  spaceId: rowData.spaceId,
                  shouldCancelDeliveryRegardlessOfScenario:
                    !rowData.spaceSetting?.shouldCancelDeliveryRegardlessOfScenario,
                })
              }
              color="primary"
            />
          </Stack>
        );
      }
      return null;
    },
    []
  );

  return { bodyCellRenderer };
};
