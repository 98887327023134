import { RecruitmentProcessRecordOnnEventItem } from "./RecruitmentProcessRecordOnnEventItem";
import { RecruitmentProcessRecordOnnTaskItem } from "./RecruitmentProcessRecordOnnTaskItem";

export {
  recruitmentProcessRecordOnnTaskItemSchema,
  recruitmentProcessRecordOnnEventItemSchema,
} from "./schema";

export { RecruitmentProcessRecordOnnEventItem };
export { RecruitmentProcessRecordOnnTaskItem };

export type AnyRecruitmentProcessRecordItem =
  | RecruitmentProcessRecordOnnEventItem
  | RecruitmentProcessRecordOnnTaskItem;

export type AnyRecruitmentProcessRecordItemExcludeMethods =
  | ExcludeMethods<RecruitmentProcessRecordOnnEventItem>
  | ExcludeMethods<RecruitmentProcessRecordOnnTaskItem>;

export const instantiateFromAnyRecruitmentProcessRecordItemExcludeMethods = (
  anyRecruitmentProcessRecordItemExcludeMethods: AnyRecruitmentProcessRecordItemExcludeMethods
) => {
  switch (anyRecruitmentProcessRecordItemExcludeMethods.type) {
    case RecruitmentProcessRecordOnnEventItem.type:
      return new RecruitmentProcessRecordOnnEventItem(
        anyRecruitmentProcessRecordItemExcludeMethods
      );
    case RecruitmentProcessRecordOnnTaskItem.type:
      return new RecruitmentProcessRecordOnnTaskItem(anyRecruitmentProcessRecordItemExcludeMethods);

    default: {
      const _exhaustiveCheck: never = anyRecruitmentProcessRecordItemExcludeMethods;
      return _exhaustiveCheck;
    }
  }
};

export type AnyCreateNewArgs =
  | Omit<ExcludeMethods<RecruitmentProcessRecordOnnTaskItem>, "id" | "createdAt" | "updatedAt">
  | Omit<ExcludeMethods<RecruitmentProcessRecordOnnEventItem>, "id" | "createdAt" | "updatedAt">;

export const createNewFromAnyRecruitmentProcessRecordItem = (createNewArgs: AnyCreateNewArgs) => {
  switch (createNewArgs.type) {
    case RecruitmentProcessRecordOnnEventItem.type:
      return RecruitmentProcessRecordOnnEventItem.createNew(createNewArgs);
    case RecruitmentProcessRecordOnnTaskItem.type:
      return RecruitmentProcessRecordOnnTaskItem.createNew(createNewArgs);

    default: {
      const _exhaustiveCheck: never = createNewArgs;
      return _exhaustiveCheck;
    }
  }
};
