import { Box, Grid } from "@material-ui/core";
import { EmployeePrediction } from "@onn/common";
import React, { useCallback, useContext } from "react";

import { EmployeePredictionCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import { Choice } from "../../parts/dropdown-menus/Choice";
import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { CommonProps } from "./type";

import { EmployeePredictionContext } from "~/components/providers/EmployeePredictionProvider";

const TARGET = "employeePrediction";

type Props = CommonProps<EmployeePredictionCondition>;
export const EmployeePredictionConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { employeePredictions } = useContext(EmployeePredictionContext);

  const { onChangePredictionIds } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
  });
  const { choices } = useChoices({ employeePredictions });

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target) => onChangeTarget(index, target)}
          />
        </Grid>
        <Grid item xs={9}>
          <SelectMultipleConditionDropdown
            placeHolder="ヨミを選択"
            key="employeePredictions"
            selectedChoices={choices.filter((c) => condition.predictionIds?.includes(c.value))}
            choices={choices}
            withSearch
            searchPlaceholder="ヨミ名で検索"
            onChange={onChangePredictionIds}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
}: Pick<Props, "index" | "onChangeCondition" | "condition">) => {
  const onChangePredictionIds = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        predictionIds: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangePredictionIds,
  };
};

const useChoices = ({
  employeePredictions,
}: {
  employeePredictions: EmployeePrediction[] | undefined;
}) => {
  if (!employeePredictions) {
    return {
      choices: [],
    };
  }

  return {
    choices: employeePredictions.map((e) => ({ label: e.label, value: e.id })),
  };
};
