import { v4 } from "uuid";

import { AnyTriggerSetting } from "../triggerSettings";

import { IEmployeeTagActionConditionSchema, employeeTagActionConditionSchema } from "./schema";

export class EmployeeTagActionCondition implements IEmployeeTagActionConditionSchema {
  static readonly type = "EmployeeTagActionCondition" as const;
  type = EmployeeTagActionCondition.type;

  static readonly validator = employeeTagActionConditionSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  createdAt: Date;
  updatedAt: Date;
  actionId: string;
  employeeTagIds: string[];

  constructor(init: Omit<ExcludeMethods<EmployeeTagActionCondition>, "type">) {
    const parsedInit = EmployeeTagActionCondition.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.actionId = parsedInit.actionId;
    this.employeeTagIds = parsedInit.employeeTagIds;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(
    params: Omit<
      ExcludeMethods<EmployeeTagActionCondition>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new EmployeeTagActionCondition({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static isAvailableTriggerSettingType(_type: AnyTriggerSetting["type"]): boolean {
    return true;
  }
}
