import { MenuItem } from "@material-ui/core";
import { ContactMessageTemplate } from "@onn/common";

import React, { FC } from "react";
import styled from "styled-components";

import { Typography, IconButton, TooltipWhenTextTruncated } from "~/components/uiParts";

type Props = {
  contactMessageTemplate: ContactMessageTemplate;
  isSelected: boolean;
  isAdmin: boolean;
  openTemplateChangeMenu: (value: React.MouseEvent<HTMLElement>) => void;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export const TemplateMenuItem: FC<Props> = ({
  contactMessageTemplate,
  isSelected,
  isAdmin,
  openTemplateChangeMenu,
  onClick,
}) => {
  return (
    <>
      <StyledMenuItem onClick={onClick} selected={isSelected}>
        <TooltipWhenTextTruncated text={contactMessageTemplate.title}>
          {(ref) => (
            <>
              <Typography variant="body2" noWrap ref={ref}>
                {contactMessageTemplate.title}
              </Typography>
              {isAdmin && (
                <IconButton
                  icon="menuVert"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    openTemplateChangeMenu(e);
                  }}
                />
              )}
            </>
          )}
        </TooltipWhenTextTruncated>
      </StyledMenuItem>
    </>
  );
};

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-top: 2px;
    padding-bottom: 2px;
    min-height: 40px;

    // TooltipWhenTextTruncated を使用するために、root, span のそれぞれに flex style が必要
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.selected && props.theme.palette.action.selected};

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
