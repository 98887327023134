import { Box } from "@material-ui/core";
import React from "react";

import { NameCondition } from "../../../types/condition";

import { FreeTextInput } from "../../parts/FreeTextInput";
import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import { CommonProps } from "./type";

const TARGET = "name";

type Props = CommonProps<NameCondition>;
export const NameConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton target={TARGET} onChange={(target) => onChangeTarget(index, target)} />
      </Box>
      <Box flex={9}>
        <FreeTextInput
          value={condition.searchString ?? ""}
          onChange={(s) => onChangeCondition(index, { target: TARGET, searchString: s })}
        />
      </Box>
    </Box>
  );
};
