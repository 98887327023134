import { zodResolver } from "@hookform/resolvers/zod";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { DatePickerV2 } from "~/components/uiParts";

const inputSchema = z.object({ value: z.date() });
type InputState = z.infer<typeof inputSchema>;
type Props = {
  onSave: (data: InputState) => void;
  onCancel: () => void;
  value?: Date;
};

export const CustomEmployeeInformationDateTypeEditor: FC<Props> = ({ onSave, onCancel, value }) => {
  const { handleSubmit, control, formState } = useForm<InputState>({
    defaultValues: {
      value,
    },
    resolver: zodResolver(inputSchema),
  });
  const { isValid, isDirty } = formState;

  return (
    <InformationValueEditor
      form={
        <form style={{ display: "flex", columnGap: 16 }} onSubmit={handleSubmit(onSave)}>
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <DatePickerV2
                value={field.value || null}
                fullWidth
                placeholder="日程を選択"
                disablePast={false}
                onChange={(data) => field.onChange(data)}
              />
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!isValid || !isDirty}
    />
  );
};
