import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";
import { RecruitmentStatusTypeSchema } from "../inputTypeSchemas/RecruitmentStatusTypeSchema";

/////////////////////////////////////////
// RECRUITMENT STATUS SCHEMA
/////////////////////////////////////////

export const RecruitmentStatusSchema = z.object({
  type: RecruitmentStatusTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  scenarioId: z.string(),
  deleted: z.boolean(),
  label: z.string(),
  recruitmentProcessRelationSetting: JsonValueSchema.nullable(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RecruitmentStatus = z.infer<typeof RecruitmentStatusSchema>;

/////////////////////////////////////////
// RECRUITMENT STATUS OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const RecruitmentStatusOptionalDefaultsSchema = RecruitmentStatusSchema.merge(
  z.object({
    deleted: z.boolean().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type RecruitmentStatusOptionalDefaults = z.infer<
  typeof RecruitmentStatusOptionalDefaultsSchema
>;

export default RecruitmentStatusSchema;
