import { Box } from "@material-ui/core";
import { Employee, OnnEvent } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { useTabPage } from "../../hooks/OnnEventContentsTab/useTabPage";

import { CandidateListItem } from "./CandidateListItem";

import { DeliveredEmployeeVirtualizedList } from "~/components/domains/delivery/DeliveredEmployeeList";
import { Paper, Typography, Button, Loading, Icon } from "~/components/uiParts";

type Props = {
  currentUser: Employee;
  onnEvent: OnnEvent;
};
export const TargetNewGraduateContainer: FC<Props> = ({ currentUser, onnEvent }) => {
  const {
    onnEventAnswersWithEmployee,
    answeredOnnEventAnswersWithEmployee,
    noAnsweredOnnEventAnswersWithEmployee,
    isLoadingOnnEventAnswersWithEmployee,
    onClickDeliverySetting,
    onClickOpenRemoveModal,
    onSubmitRemove,
    filter: {
      selectedFilterIsAnswered,
      onClickFilterButtonAnswered,
      onClickFilterButtonNoAnswered,
    },
    onnEventAnswersToDisplay,
  } = useTabPage({
    onnEvent,
  });

  const dataUndefined = !onnEventAnswersWithEmployee || !answeredOnnEventAnswersWithEmployee;
  const shouldShowLoading = dataUndefined;

  if (shouldShowLoading)
    return (
      <StyledTargetNewGraduatePaperWithPaddingY square={false}>
        <Loading size="large" />
      </StyledTargetNewGraduatePaperWithPaddingY>
    );

  return (
    <TargetNewGraduatePaper square={false}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="24px">
        <Typography variant="body2" bold>
          配信対象({onnEventAnswersWithEmployee.length})
        </Typography>
        {currentUser.isAdmin() && onnEventAnswersWithEmployee.length !== 0 && (
          <Button
            onClick={onClickDeliverySetting}
            color="primary"
            variant="outlined"
            borderRadius="regular"
          >
            配信設定
          </Button>
        )}
      </Box>
      {onnEventAnswersWithEmployee.length !== 0 && (
        <StyledBoxWithFlex mb="24px">
          <StyledDivFlex>
            <Button
              onClick={onClickFilterButtonAnswered}
              color={selectedFilterIsAnswered === "answered" ? "primary" : "default"}
              variant={selectedFilterIsAnswered === "answered" ? "contained" : "text"}
              borderRadius="regular"
              fullWidth
            >
              <Box>
                回答済(
                {isLoadingOnnEventAnswersWithEmployee
                  ? "-"
                  : answeredOnnEventAnswersWithEmployee.length}
                )
              </Box>
            </Button>
          </StyledDivFlex>
          <StyledDivFlex>
            <Button
              onClick={onClickFilterButtonNoAnswered}
              color={selectedFilterIsAnswered === "noAnswered" ? "primary" : "default"}
              variant={selectedFilterIsAnswered === "noAnswered" ? "contained" : "text"}
              borderRadius="regular"
              fullWidth
            >
              <Box>
                未回答(
                {isLoadingOnnEventAnswersWithEmployee
                  ? "-"
                  : noAnsweredOnnEventAnswersWithEmployee.length}
                )
              </Box>
            </Button>
          </StyledDivFlex>
        </StyledBoxWithFlex>
      )}
      {onnEventAnswersWithEmployee.length === 0 && (
        <WhenEmployeeLengthIsZero
          onClickDeliverySetting={onClickDeliverySetting}
          isDisplayAddTargetButton={
            currentUser.isAdmin() && (onnEvent.isNormalEvent() || onnEvent.isNewInterviewEvent())
          }
        />
      )}
      <DeliveredEmployeeVirtualizedList
        itemCount={onnEventAnswersToDisplay.length}
        rowRenderer={(props) => {
          const employee = onnEventAnswersToDisplay[props.index];
          if (!employee) return null;
          return (
            <Box
              key={props.key}
              style={props.style} // 動的に計算されるtopなどのプロパティが入る
            >
              <CandidateListItem
                key={employee.id}
                selectedFilterIsAnswered={selectedFilterIsAnswered}
                onnEvent={onnEvent}
                onnEventAnswerWithEmployee={employee}
                onClickOpenRemoveModal={onClickOpenRemoveModal}
                onSubmitRemove={onSubmitRemove}
                isAdmin={currentUser.isAdmin()}
              />
            </Box>
          );
        }}
      />
    </TargetNewGraduatePaper>
  );
};

const WhenEmployeeLengthIsZero: FC<{
  onClickDeliverySetting: () => void;
  isDisplayAddTargetButton: boolean;
}> = ({ onClickDeliverySetting, isDisplayAddTargetButton }) => {
  return (
    <Box>
      <Typography variant="body2" color="textSecondary">
        配信対象を追加して、イベントへの参加回答を集めましょう
      </Typography>
      {isDisplayAddTargetButton && (
        <Box mt="24px" display="flex" justifyContent="space-around">
          <Button
            color="primary"
            variant="contained"
            borderRadius="circle"
            startIcon={<Icon icon="add" color="white" size="md" />}
            onClick={onClickDeliverySetting}
          >
            配信対象を追加
          </Button>
        </Box>
      )}
    </Box>
  );
};

const TargetNewGraduatePaper = styled(Paper)`
  padding: 24px;
  width: 30%;
`;

const StyledBoxWithFlex = styled(Box)`
  &.MuiBox-root {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const StyledDivFlex = styled.div`
  flex: 1 1 auto;
`;

const StyledTargetNewGraduatePaperWithPaddingY = styled(TargetNewGraduatePaper)`
  &.MuiPaper-root {
    padding: 50px 0;
  }
`;
