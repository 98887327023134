import { Action, AnyTriggerSetting } from "@onn/common";
import { useCallback } from "react";

import { ValidInputState } from "../inputStateSchema";

import { useConvertInputToActionCondition } from "./useConvertInputToActionCondition";
import { useConvertInputToActionTiming } from "./useConvertInputToActionTiming";
import { useConvertInputToNewActionSetting } from "./useConvertInputToNewActionSetting";
import { useEnqueueInconsistencyErrorSnackbar } from "./useEnqueueInconsistencyErrorSnackbar";
import { useNotifyInconsistencyError } from "./useNotifyInconsistencyError";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const useCreateActionFromInput = () => {
  const { currentSpace } = useCurrentSpace();

  const { enqueueInconsistencyErrorSnackbar } = useEnqueueInconsistencyErrorSnackbar();

  const { convertInputToActionTiming } = useConvertInputToActionTiming();

  const { convertInputToActionCondition } = useConvertInputToActionCondition();

  const { notifyInconsistencyError } = useNotifyInconsistencyError();

  const { convertInputToNewActionSetting } = useConvertInputToNewActionSetting();

  const createActionFromInput = useCallback(
    ({
      validInputValue,
      triggerSetting,
    }: {
      validInputValue: ValidInputState;
      triggerSetting: AnyTriggerSetting;
    }) => {
      const newAction = Action.create({
        tenantId: currentSpace.tenantId,
        spaceId: currentSpace.id,
        triggerId: triggerSetting.triggerId,
        ...convertInputToActionTiming(validInputValue.deliveryTiming),
      });
      const actionCondition = convertInputToActionCondition({
        actionConditionInput: validInputValue.actionCondition,
        actionId: newAction.id,
        triggerSetting,
      });
      // NOTE: conditionTypeの指定があるのに、actionConditionが存在しない場合はトリガー設定が不整合なので、エラーを通知
      if (validInputValue.actionCondition.conditionType && actionCondition === undefined) {
        notifyInconsistencyError(triggerSetting, validInputValue);
        enqueueInconsistencyErrorSnackbar();
      }

      return {
        action: newAction,
        actionSetting: convertInputToNewActionSetting({
          actionSettingInput: validInputValue.actionSetting,
          actionId: newAction.id,
        }),
        actionCondition,
        triggerId: triggerSetting.triggerId,
      };
    },
    [
      convertInputToActionCondition,
      convertInputToActionTiming,
      convertInputToNewActionSetting,
      currentSpace.id,
      currentSpace.tenantId,
      enqueueInconsistencyErrorSnackbar,
      notifyInconsistencyError,
    ]
  );

  return { createActionFromInput };
};
