import { CSVConditionField } from "@onn/common";
import { useState } from "react";

export const useSelectionConditions = () => {
  const [conditionFields, setConditionFields] = useState<Field[]>(fields);

  const changeOrder = ({
    sourceIndex,
    destinationIndex,
  }: {
    sourceIndex: number;
    destinationIndex: number;
  }) =>
    setConditionFields((prev) => {
      const newFields = [...prev];
      const [removed] = newFields.splice(sourceIndex, 1);
      if (!removed) return newFields;

      newFields.splice(destinationIndex, 0, removed);
      return newFields;
    });

  const toggleIsSelected = (key: string) => {
    setConditionFields((prev) => {
      const newFields = [...prev].map((field) => {
        if (field.key === key) {
          return { ...field, isSelected: !field.isSelected };
        }
        return field;
      });
      return newFields;
    });
  };

  const selectedConditionFieldKeys = conditionFields
    .filter((field) => field.isSelected)
    .map((field) => field.key);

  return {
    conditionFields,
    selectedConditionFieldKeys,
    changeOrder,
    toggleIsSelected,
  };
};

const fields: Field[] = [
  {
    key: "name" as const,
    label: "氏名",
    sampleValues: ["例田", "太郎"],
  },
  {
    key: "kana_name" as const,
    label: "フリガナ",
    sampleValues: ["レイタ", "タロウ"],
  },
  {
    key: "recruitment_status" as const,
    label: "選考ステータス",
    sampleValue: "プレエントリー",
  },
  {
    key: "assignee" as const,
    label: "担当者",
    sampleValue: "末長 紘子",
  },
  {
    key: "admin_memo" as const,
    label: "社内メモ",
    sampleValue:
      "評価：A+ \n協調性があり具体的な提案ができるタイプ。物怖じせずに様々な人と連携が取れるリーダーシップを持っています。",
  },
  {
    key: "tags" as const,
    label: "タグ",
    sampleValue: "AA大学, 夏インターン参加, エンジニア志望",
  },
  {
    key: "gender" as const,
    label: "性別",
    sampleValue: "その他",
  },
  {
    key: "birthday" as const,
    label: "生年月日",
    sampleValue: "2001/12/20",
  },
  {
    key: "mail_address" as const,
    label: "メールアドレス",
    sampleValue: "xxxxxxxxxx@xxxxx.com",
  },
  {
    key: "phone_number" as const,
    label: "電話番号（携帯）",
    sampleValue: "000-0000-0000",
  },
  {
    key: "home_phone_number" as const,
    label: "電話番号（自宅）",
    sampleValue: "000-0000-0000",
  },
  {
    key: "graduation_year_and_month" as const,
    label: "卒業年月",
    sampleValue: "2020年 2月",
  },
  {
    key: "affiliation" as const,
    label: "学校",
    sampleValues: ["〇〇大学", "□□学部", "〇〇学科"],
  },
  {
    key: "address/current" as const,
    label: "現住所",
    sampleValues: ["000-0000", "東京都", "千代田区", "千代田1-1", "千代田ビル"],
  },
  {
    key: "address/hometown" as const,
    label: "出身地",
    sampleValues: ["000-0000", "東京都", "千代田区", "千代田1-1", "千代田ビル"],
  },
  {
    key: "id/unique_id" as const,
    label: "Onn 固定ID",
    sampleValue: "ONN12345678",
  },
  {
    key: "id/external_id" as const,
    label: "予備ID",
    sampleValue: "12345678",
  },
  {
    key: "recruitment_process_records" as const,
    label: "選考履歴",
    sampleValues: [
      "人事面談（履歴名）",
      "合格（ステータス）",
      "説明会（イベント名）",
      "2024/12/13（日程/日付）",
      "11:15-11:45（日程/時刻）",
      "末長 紘子（担当者名）",
      "xxxxxxx（評価）",
      "2024/12/12（作成日）",
      "2024/12/14（更新日）",
    ],
  },
  {
    key: "offer_acceptance_deadline" as const,
    label: "内定承諾期限",
    sampleValue: "2020/01/01",
  },
].map((field) => ({ ...field, isSelected: true }));

type FieldBase = {
  key: CSVConditionField;
  label: string;
  isSelected: boolean;
};

type Field =
  | (FieldBase & {
      sampleValues: string[];
    })
  | (FieldBase & {
      sampleValue: string;
    });
