import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE TAG SCHEMA
/////////////////////////////////////////

export const EmployeeTagSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  creatorId: z.string(),
  name: z
    .string()
    .trim()
    .min(1, { message: "タグ名が短すぎます" })
    .max(30, { message: "タグ名が長すぎます" }),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeTag = z.infer<typeof EmployeeTagSchema>;

/////////////////////////////////////////
// EMPLOYEE TAG OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeTagOptionalDefaultsSchema = EmployeeTagSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type EmployeeTagOptionalDefaults = z.infer<typeof EmployeeTagOptionalDefaultsSchema>;

export default EmployeeTagSchema;
