import { OnnEventPlace, APISchema } from "@onn/common";
import { useCallback } from "react";

import { useMutateOnnEventPlaces } from "./useOnnEventPlaces";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/onn_event_place_api/onn-event-place"]["PATCH"];
export const useUpdateOnnEventPlace = () => {
  const { mutateOnnEventPlaces } = useMutateOnnEventPlaces();
  const updateOnnEventPlace = useCallback(
    async (body: EndPoint["body"]) => {
      const response = await apiClient.patch(`/onn_event_place_api/onn-event-place`, body, {
        isNotifyError: true,
      });
      mutateOnnEventPlaces();
      return new OnnEventPlace(response.data);
    },
    [mutateOnnEventPlaces]
  );

  return { updateOnnEventPlace };
};
