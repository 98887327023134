import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { mutateScenarioTriggersForDisplay } from "./useScenarioTriggersForDisplay";

import { apiClient } from "~/libs";
import { ApiResponseError } from "~/util";

type Endpoint = APISchema["/scenario_api/edit-scenarios"]["POST"];

export const useEditScenario = () => {
  const { enqueueSnackbar } = useSnackbar();

  const editScenario = useCallback(
    async ({
      scenarioId,
      workflows,
    }: {
      scenarioId: string;
      workflows: Endpoint["body"]["workflows"];
    }) => {
      try {
        await apiClient.post(`/scenario_api/edit-scenarios`, { scenarioId, workflows });
        mutateScenarioTriggersForDisplay();
      } catch (e) {
        if (e instanceof ApiResponseError && e.status === 400) {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("シナリオの更新に失敗しました。管理者までお問い合わせください。", {
            variant: "error",
          });
        }
      }
    },
    [enqueueSnackbar]
  );

  return { editScenario };
};
