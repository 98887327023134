import { z } from "zod";

import { DeliveryTimingType } from "./DeliveryTimingForm/types/deliveryTimingTypes";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_literal) {
    return { message: "配信タイミングを選択してください" };
  }
  return { message: ctx.defaultError };
};

export type DeliveryTimingInput = {
  deliveryTiming: {
    isImmediateDelivery: boolean;
    scheduledDeliveryTiming?: {
      type?: DeliveryTimingType;
      timingNumber?: number;
      atHour?: number;
    };
  };
};

export const deliveryTimingInputSchema = z.object({
  deliveryTiming: z.discriminatedUnion("isImmediateDelivery", [
    z.object({
      isImmediateDelivery: z.literal(true),
    }),
    z.object({
      isImmediateDelivery: z.literal(false),
      scheduledDeliveryTiming: z.discriminatedUnion("type", [
        z.object({
          type: z.literal("minutes", {
            // NOTE: invalid_literalエラーのメッセージはerrorMapを使用する必要がある
            errorMap: customErrorMap,
          }),
          timingNumber: z.number().min(1, "1以上の数値を入力してください"),
        }),
        z.object({
          type: z.literal("hours", {
            // NOTE: invalid_literalエラーのメッセージはerrorMapを使用する必要がある
            errorMap: customErrorMap,
          }),
          timingNumber: z
            .number()
            .min(0, "0〜23の数値を入力してください")
            .max(23, "0〜23の数値を入力してください"),
        }),
        z.object({
          type: z.literal("days", {
            // NOTE: invalid_literalエラーのメッセージはerrorMapを使用する必要がある
            errorMap: customErrorMap,
          }),
          timingNumber: z.number().min(1, "1以上の数値を入力してください"),
          atHour: z
            .number()
            .min(0, "0〜23の数値を入力してください")
            .max(23, "0〜23の数値を入力してください"),
        }),
      ]),
    }),
  ]),
});

export type DeliveryTimingValidInput = z.infer<typeof deliveryTimingInputSchema>;
