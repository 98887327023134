import { Box } from "@material-ui/core";
import { OnnTask } from "@onn/common";
import React, { FC, useCallback } from "react";

import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { useTabPage } from "../../hooks/useTabPage";

import { OnnFormTaskAnswersList } from "./parts/OnnFormTaskAnswersList";

import { Paper, Typography, Button, Loading, Icon } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  onnTask: OnnTask;
};
export const TargetNewGraduateContainer: FC<Props> = ({ onnTask }) => {
  const { currentUser } = useCurrentUser();
  const navigation = useNavigate();
  const handleClickDeliverySetting = useCallback(() => {
    navigation(`/onn_tasks/${onnTask.id}/delivery_setting?from_page=detail`);
  }, [navigation, onnTask.id]);

  const {
    onnFromTaskAnswers,
    isLoadingOnnFromTaskAnswers,
    isLoadingNewGraduates,
    newGraduatesMap,
    deleteOnnFormTaskAnswer,
    answeredOnnFromTaskAnswers,
    nonAnsweredOnnFromTaskAnswers,
    filter: {
      selectedFilterIsAnswered,
      onClickFilterButtonAnswered,
      onClickFilterButtonNoAnswered,
    },
  } = useTabPage({
    onnTask,
  });

  return (
    <TargetNewGraduatePaper square={false}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="24px">
        <Typography variant="body2" bold>
          配信対象({onnFromTaskAnswers ? onnFromTaskAnswers.length : "-"})
        </Typography>
        {currentUser.isAdmin() && (
          <Button
            onClick={handleClickDeliverySetting}
            color="primary"
            variant="outlined"
            borderRadius="regular"
          >
            配信設定
          </Button>
        )}
      </Box>
      <StyledBoxWithFlex mb="24px">
        <StyledDivFlex>
          <Button
            onClick={onClickFilterButtonAnswered}
            color={selectedFilterIsAnswered === "answered" ? "primary" : "default"}
            variant={selectedFilterIsAnswered === "answered" ? "contained" : "text"}
            borderRadius="regular"
            fullWidth
          >
            <Box>
              回答済({isLoadingOnnFromTaskAnswers ? "-" : answeredOnnFromTaskAnswers.length})
            </Box>
          </Button>
        </StyledDivFlex>
        <StyledDivFlex>
          <Button
            onClick={onClickFilterButtonNoAnswered}
            color={selectedFilterIsAnswered === "noAnswered" ? "primary" : "default"}
            variant={selectedFilterIsAnswered === "noAnswered" ? "contained" : "text"}
            borderRadius="regular"
            fullWidth
          >
            <Box>
              未回答(
              {isLoadingOnnFromTaskAnswers ? "-" : nonAnsweredOnnFromTaskAnswers.length})
            </Box>
          </Button>
        </StyledDivFlex>
      </StyledBoxWithFlex>
      {isLoadingOnnFromTaskAnswers || isLoadingNewGraduates ? (
        <Loading size="small" />
      ) : (
        <>
          {onnFromTaskAnswers.length === 0 ? (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="body2" color="textSecondary">
                配信対象を追加して、タスクの回答を集めましょう
              </Typography>
              {currentUser.isAdmin() && (
                <Box mt="24px" display="flex" justifyContent="space-around">
                  <Button
                    color="primary"
                    variant="contained"
                    borderRadius="circle"
                    startIcon={<Icon icon="add" color="white" size="md" />}
                    onClick={handleClickDeliverySetting}
                  >
                    配信対象を追加
                  </Button>
                </Box>
              )}
            </Box>
          ) : (
            <OnnFormTaskAnswersList
              onnTask={onnTask}
              onnFromTaskAnswers={
                selectedFilterIsAnswered === "answered"
                  ? answeredOnnFromTaskAnswers
                  : nonAnsweredOnnFromTaskAnswers
              }
              newGraduatesMap={newGraduatesMap}
              deleteOnnFormTaskAnswer={deleteOnnFormTaskAnswer}
            />
          )}
        </>
      )}
    </TargetNewGraduatePaper>
  );
};

const TargetNewGraduatePaper = styled(Paper)`
  padding: 24px;
`;

const StyledBoxWithFlex = styled(Box)`
  &.MuiBox-root {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const StyledDivFlex = styled.div`
  flex: 1 1 auto;
`;
