type OptionType = { destPath?: string };

/**
 * LIFF URL を生成する
 * @param {string} liffId
 * @param {string} path
 * @param {OptionType} options
 * @returns
 */
export const generateLiffUrlString = (
  liffId: string,
  path: string,
  options?: OptionType
): string => {
  const { destPath } = options || {};

  const liffUrl = new URL(path, `https://liff.line.me`);
  liffUrl.pathname = `/${liffId}` + liffUrl.pathname; // liffUrl を足す
  if (destPath) {
    liffUrl.searchParams.set("dest-path", destPath);
  }

  return liffUrl.toString();
};
