import { CandidateDate } from "../../../../../domain";
import { ToCloudFunctionsCompatible } from "../../../../../utils";

export const convertUnixTimeToDateCandidateDate = (
  d: ToCloudFunctionsCompatible<CandidateDate>
): CandidateDate => {
  return new CandidateDate({
    ...d,
    from: new Date(d.from),
    until: new Date(d.until),
    createdAt: new Date(d.createdAt),
    updatedAt: new Date(d.updatedAt),
  });
};
