import { v4 } from "uuid";

import { Employee } from "../../../domain/Employee/Employee";

import { OnnEvent } from "../OnnEvent/OnnEvent";

import { IOnnEventAnswer, onnEventAnswerSchema } from "./schema";

export type OnnEventAnswerOption = "possible" | "impossible" | "pending";
export type OnnEventAnswerStatus = "answered" | "unAnswered";
type candidateDateId = string;

export class OnnEventAnswer implements IOnnEventAnswer {
  static readonly validator = onnEventAnswerSchema;

  readonly id: string;
  readonly onnEventId: string;
  readonly tenantId: string;
  readonly employeeId: string;
  readonly answer: Record<candidateDateId, OnnEventAnswerOption>;
  readonly selectedOnnEventSlotDateId?: string | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventAnswer>) {
    const parsedInit = OnnEventAnswer.validator.parse(init);

    this.id = parsedInit.id;
    this.onnEventId = parsedInit.onnEventId;
    this.tenantId = parsedInit.tenantId;
    this.employeeId = parsedInit.employeeId;
    this.answer = parsedInit.answer;
    this.selectedOnnEventSlotDateId = parsedInit.selectedOnnEventSlotDateId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public update(update: Partial<ExcludeMethods<OnnEventAnswer>>): OnnEventAnswer {
    return new OnnEventAnswer({
      ...this,
      ...update,
      updatedAt: new Date(),
    });
  }

  /**
   * 回答を選択したものに変更する
   * - [CAUTION] 単一回答にしか対応していない
   */
  public changeAnswer(selectedCandidateDateId: string): OnnEventAnswer {
    return this.update({
      answer: {
        ...Object.fromEntries(Object.keys(this.answer).map((id) => [id, "impossible"])),
        [selectedCandidateDateId]: "possible",
      },
    });
  }

  /**
   * 編集可能かどうかを返す
   * @param {Employee} currentUser 操作者
   * @returns boolean
   */
  isEditable(currentUser: Employee): boolean {
    return currentUser.id === this.employeeId;
  }

  /**
   * 回答が存在するかどうかを返す
   * @returns boolean
   */
  isAnswered(): boolean {
    // NOTE: 新面談タイプの場合はanswerを更新しないのでselectedOnnEventSlotDateIdが存在するかどうかで判定する
    return !!this.selectedOnnEventSlotDateId || Object.keys(this.answer).length !== 0;
  }

  /**
   * 参加できる日程がないかどうかを返す
   * @returns boolean
   */
  isUnavailableCandidates(): boolean {
    return (
      this.isAnswered() && Object.values(this.answer).every((answer) => answer === "impossible")
    );
  }

  /**
   * 参加可能な日程のIDを返す
   * - [CAUTION] 単一回答にしか対応していない
   */
  getPossibleCandidateDateId(): string | undefined {
    return Object.entries(this.answer).find(([, answer]) => answer === "possible")?.[0];
  }

  /**
   * OnnEventAnswerを新規作成するときに使うメソッド
   * @param {Optional<ExcludeMethods<OnnEventAnswer>, "id" | "createdAt" | "updatedAt">} params
   * @returns OnnEventAnswer
   */
  public static create(
    params: Optional<ExcludeMethods<OnnEventAnswer>, "id" | "createdAt" | "updatedAt">
  ): OnnEventAnswer {
    return new OnnEventAnswer({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
    });
  }

  public static createWithSelection(
    params: Omit<
      Optional<ExcludeMethods<OnnEventAnswer>, "id" | "createdAt" | "updatedAt">,
      "answer"
    >,
    selectedCandidateDateId: string,
    onnEvent: OnnEvent
  ): OnnEventAnswer {
    const ids = onnEvent.candidateDates.map((date) => date.id);
    if (!ids.includes(selectedCandidateDateId)) throw new Error("選択した日程が存在しません");

    return OnnEventAnswer.create({
      ...params,
      answer: {
        ...Object.fromEntries(onnEvent.candidateDates.map((date) => [date.id, "impossible"])),
        [selectedCandidateDateId]: "possible",
      },
    });
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 OnnEventAnswer にしている
   */
  static forceConvertToDate(onnEventAnswer: ExcludeMethods<OnnEventAnswer>) {
    return new OnnEventAnswer({
      ...onnEventAnswer,
      createdAt: new Date(onnEventAnswer.createdAt),
      updatedAt: new Date(onnEventAnswer.updatedAt),
    });
  }
}
