import { Employee, EmployeeToDisplay } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { useMutateAllNewcomers } from "./useAllNewcomers";
import { useGenerateMutateEmployee } from "./useEmployee";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * 新卒候補者用のメモを更新する関数を提供するhooks
 */
export const useUpdateNewGraduateMemo = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAllNewcomers } = useMutateAllNewcomers();
  const { generateMutateEmployee } = useGenerateMutateEmployee();

  /**
   * 新卒候補者用のメモを更新する
   * @param {Employee} employee
   * @param {string} newMemo
   */
  const updateNewGraduateMemo = useCallback(
    async (employee: Employee, newMemo: string) => {
      try {
        await EmployeeUseCase.update(employee.id, { admin_memo: newMemo });
        mutateAllNewcomers();
        generateMutateEmployee(employee.id)((currentData) => {
          // NOTE: 代入が確定するためキャストしている
          const employeeForMutate = new Employee({
            ...employee,
            admin_memo: newMemo,
          }) as EmployeeToDisplay;
          employeeForMutate.employeeTagIds = currentData?.employeeTagIds ?? [];
          return employeeForMutate;
        }, false);
      } catch (e) {
        enqueueSnackbar("メモの保存に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useUpdateNewGraduateMemo:updateNewGraduateMemo" },
        });
      }
    },
    [generateMutateEmployee, enqueueSnackbar, mutateAllNewcomers]
  );

  return { updateNewGraduateMemo };
};
