import { Menu, MenuItem } from "@material-ui/core";
import React, { FC, useState } from "react";

import { Typography, IconButton } from "~/components/uiParts";

type Props = {
  isDisplayResendInvitationButton: boolean;
  onClickResendInvitationButton: () => void;
  isDisplayDeleteEmployeeButton: boolean;
  onClickDeleteEmployeeButton: () => void;
};

export const IconButtonWithManageMemberMenu: FC<Props> = ({
  isDisplayResendInvitationButton,
  onClickResendInvitationButton,
  isDisplayDeleteEmployeeButton,
  onClickDeleteEmployeeButton,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!isDisplayResendInvitationButton && !isDisplayDeleteEmployeeButton) {
    return null;
  }

  return (
    <>
      <IconButton icon="menuVert" onClick={(e) => setAnchorEl(e.currentTarget)} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {isDisplayResendInvitationButton && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onClickResendInvitationButton();
            }}
          >
            <Typography variant="body2">招待通知の再送</Typography>
          </MenuItem>
        )}
        {isDisplayDeleteEmployeeButton && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onClickDeleteEmployeeButton();
            }}
          >
            <Typography variant="body2">アカウント削除</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
