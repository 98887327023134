import { Box } from "@material-ui/core";
import React from "react";

import { Typography } from "~/components/uiParts";

// タイトルを表示するセル
export const TitleCell = ({ title }: { title: string }) => {
  return (
    <Box>
      <Typography variant="body1" color="textPrimary" bold>
        {title}
      </Typography>
    </Box>
  );
};
