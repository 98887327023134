import { z } from "zod";

export const individualInvitationSettingSchema = z.object({
  id: z.string().trim(),
  tenantId: z.string().trim(),
  spaceId: z.string().trim(),
  employeeId: z.string().trim(),
  onnEventIds: z.array(z.string().trim()),
  onnTaskIds: z.array(z.string().trim()),
  createdAt: z.date(),
  updatedAt: z.date(),
  lastUpdaterEmployeeId: z.string().trim().nullable(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IIndividualInvitationSettingSchema
  extends z.infer<typeof individualInvitationSettingSchema> {}
