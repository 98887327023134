import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Switch, Typography } from "~/components/uiParts";
export const RequiredSwitch: FC<{
  isRequired: boolean;
  handleOnClick: () => void;
}> = ({ isRequired, handleOnClick }) => {
  return (
    <Box
      display="flex"
      sx={{
        alignItems: "center",
      }}
    >
      <Typography>{isRequired ? "必須" : "任意"}</Typography>
      <Switch color="primary" checked={isRequired} onClick={() => handleOnClick()} />
    </Box>
  );
};
