import { v4 } from "uuid";

import {
  employeeInformationMultipleTypeOptionSchema,
  IEmployeeInformationMultipleTypeOption,
} from "./schema";

export class EmployeeInformationMultipleTypeOption
  implements IEmployeeInformationMultipleTypeOption
{
  static validator = employeeInformationMultipleTypeOptionSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeInformationMultipleSelectTypeFieldId: string;
  readonly label: string;
  readonly order: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<EmployeeInformationMultipleTypeOption>) {
    EmployeeInformationMultipleTypeOption.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeInformationMultipleSelectTypeFieldId =
      init.employeeInformationMultipleSelectTypeFieldId;
    this.label = init.label;
    this.order = init.order;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationMultipleTypeOption>,
      "id" | "createdAt" | "updatedAt"
    >
  ): EmployeeInformationMultipleTypeOption {
    return new EmployeeInformationMultipleTypeOption({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(
    params: Pick<EmployeeInformationMultipleTypeOption, "label" | "order">
  ): EmployeeInformationMultipleTypeOption {
    return new EmployeeInformationMultipleTypeOption({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
