import { deleteEmployeeInformationFieldQuerySchema } from "@onn/common";
import { useCallback } from "react";
import { z } from "zod";

import { mutateEmployeeInformationGroups } from "../employeeInformationGroup/useEmployeeInformationGroups";
import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

export const useDeleteEmployeeInformationField = () => {
  const { enqueueSnackbar } = useSnackbar();

  const execDeleteEmployeeInformationField = useCallback(
    async (data: z.infer<typeof deleteEmployeeInformationFieldQuerySchema>): Promise<void> => {
      await apiClient
        .delete("/api/employee-information-fields", data)
        .then(async () => {
          enqueueSnackbar("項目を削除しました", { variant: "success" });
          mutateEmployeeInformationGroups();
        })
        .catch((error) => {
          if (error instanceof Error) {
            enqueueSnackbar(error.message, { variant: "error" });
          }
        });
    },
    [enqueueSnackbar]
  );

  return { execDeleteEmployeeInformationField };
};
