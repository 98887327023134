import { OnnEvent, generateSampleCsvDataForAnswerNewInterviewEventOnBehalf } from "@onn/common";
import { useCallback } from "react";

export const useGenerateSampleCSV = () => {
  const generateSampleCSV = useCallback((onnEvent: OnnEvent) => {
    return generateSampleCsvDataForAnswerNewInterviewEventOnBehalf(onnEvent);
  }, []);

  return { generateSampleCSV };
};
