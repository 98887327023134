import { AllContactRoom } from "@onn/common";
import { useCallback } from "react";

export const useFilterByNewGraduateIds = () => {
  const filterByNewGraduateIds = useCallback(
    (contactRooms: AllContactRoom[], newGraduateIds: Set<string>) => {
      return contactRooms.filter((contactRoom) => {
        return newGraduateIds.has(contactRoom.employeeId);
      });
    },
    []
  );

  return {
    filterByNewGraduateIds,
  };
};
