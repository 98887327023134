import { State } from "../reducer";

type UploadFilesInfo = {
  questionId: string;
  path: string;
  file: File;
};

// Firebase Storageにアップロードするファイルの情報を作成する
export const useConstructUploadFilesInfo = () => {
  const constructUploadFilesInfo = ({
    state,
    isPreview,
  }: {
    state: State;
    isPreview?: boolean;
  }): UploadFilesInfo[] => {
    const uploadFilesInfo = state.questions.flatMap((question) => {
      if (question.type !== "FILE") return [];
      if (!question.sampleFile) return [];

      const questionId = question.id;

      return {
        questionId,
        path: isPreview ? question.sampleFilePathForPreview : question.sampleFilePath,
        file: question.sampleFile,
      };
    });

    return uploadFilesInfo;
  };

  return constructUploadFilesInfo;
};
