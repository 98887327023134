import { Box } from "@material-ui/core";
import React from "react";

import { Button, Link, Paper, Typography } from "~/components/uiParts";

export const NoSlotPaper = () => {
  return (
    <Paper>
      <Box display="flex" flexDirection="column" gridRowGap="24px">
        <Typography variant="body2" color="textSecondary">
          予約可能な日程がありません。 以下のボタンから採用担当者にお問い合わせください。
        </Typography>
        <Link to="/portal/contact_rooms">
          <Button variant="outlined" color="primary" borderRadius="circle" fullWidth>
            採用担当者に問い合わせる
          </Button>
        </Link>
      </Box>
    </Paper>
  );
};
