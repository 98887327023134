import React, { FC } from "react";

export const Link: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
      <path
        stroke="none"
        d="M7.18794 17.885C5.74817 17.885 4.52356 17.3804 3.51413 16.3711C2.50471 15.3619 2 14.1376 2 12.6981C2 11.2586 2.50471 10.0324 3.51413 9.01944C4.52356 8.00648 5.74817 7.5 7.18794 7.5H9.96724C10.2637 7.5 10.5144 7.60423 10.7193 7.8127C10.9242 8.02116 11.0266 8.27402 11.0266 8.57128C11.0266 8.86855 10.9242 9.11896 10.7193 9.32253C10.5144 9.5261 10.2637 9.62788 9.96724 9.62788H7.18794C6.33087 9.62788 5.60508 9.92477 5.01056 10.5185C4.41605 11.1123 4.11879 11.8372 4.11879 12.6932C4.11879 13.5493 4.41605 14.2754 5.01056 14.8717C5.60508 15.468 6.33087 15.7662 7.18794 15.7662H9.96724C10.2637 15.7662 10.5144 15.8689 10.7193 16.0743C10.9242 16.2798 11.0266 16.5311 11.0266 16.8284C11.0266 17.1257 10.9242 17.3761 10.7193 17.5796C10.5144 17.7832 10.2637 17.885 9.96724 17.885H7.18794ZM8.86324 13.5888C8.61147 13.5888 8.39989 13.5044 8.2285 13.3355C8.05711 13.1666 7.97142 12.9528 7.97142 12.6942C7.97142 12.4356 8.0556 12.2228 8.22396 12.0557C8.39233 11.8887 8.60543 11.8052 8.86324 11.8052H15.1277C15.3794 11.8052 15.591 11.8897 15.7624 12.0586C15.9338 12.2275 16.0195 12.4412 16.0195 12.6998C16.0195 12.9584 15.9353 13.1713 15.767 13.3383C15.5986 13.5053 15.3855 13.5888 15.1277 13.5888H8.86324ZM14.0327 17.885C13.7302 17.885 13.4765 17.7823 13.2716 17.5768C13.0667 17.3714 12.9643 17.12 12.9643 16.8228C12.9643 16.5255 13.0667 16.2751 13.2716 16.0715C13.4765 15.868 13.7302 15.7662 14.0327 15.7662H16.803C17.66 15.7662 18.3858 15.4693 18.9803 14.8755C19.5749 14.2817 19.8721 13.5568 19.8721 12.7008C19.8721 11.8448 19.5749 11.1186 18.9803 10.5223C18.3858 9.92603 17.66 9.62788 16.803 9.62788H14.0327C13.7302 9.62788 13.4765 9.52516 13.2716 9.31972C13.0667 9.11428 12.9643 8.86292 12.9643 8.56566C12.9643 8.26839 13.0667 8.01647 13.2716 7.80989C13.4765 7.6033 13.7302 7.5 14.0327 7.5H16.803C18.2427 7.5 19.4689 8.00613 20.4813 9.01838C21.4938 10.0306 22 11.2565 22 12.696C22 14.1354 21.4938 15.3602 20.4813 16.3701C19.4689 17.38 18.2427 17.885 16.803 17.885H14.0327Z"
      />
    </svg>
  );
};
