import { z } from "zod";

/////////////////////////////////////////
// SEND CONTACT MESSAGE ACTION SETTING SCHEMA
/////////////////////////////////////////

export const SendContactMessageActionSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  actionId: z.string(),
  text: z.string(),
  filePaths: z.string().array(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type SendContactMessageActionSetting = z.infer<typeof SendContactMessageActionSettingSchema>;

/////////////////////////////////////////
// SEND CONTACT MESSAGE ACTION SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const SendContactMessageActionSettingOptionalDefaultsSchema =
  SendContactMessageActionSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type SendContactMessageActionSettingOptionalDefaults = z.infer<
  typeof SendContactMessageActionSettingOptionalDefaultsSchema
>;

export default SendContactMessageActionSettingSchema;
