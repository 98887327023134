import {
  DeprecatedNewGraduate,
  Employee,
  NewGraduateToDisplay,
  NewGraduateToListView,
} from "@onn/common";
import { useCallback } from "react";

import { useMutateAllNewcomers } from "../employee/useAllNewcomers";
import { useGenerateMutateEmployee } from "../employee/useEmployee";
import { useGenerateMutateNewGraduate } from "../employee/useNewGraduate";
import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

export type OnEditEmployeePredictionRelation = (args: {
  updatedNewGraduate: NewGraduateToListView;
  editEmployeePredictionRelationsPromise: Promise<unknown>;
}) => void | Promise<void>;

export const useEditEmployeePredictionRelations = (options?: {
  onEditEmployeePredictionRelation?: OnEditEmployeePredictionRelation;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAllNewcomers } = useMutateAllNewcomers();
  const { generateMutateEmployee } = useGenerateMutateEmployee();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();

  const editEmployeePredictionRelations = useCallback(
    async ({
      employeePredictionId,
      newGraduate,
      newGraduateToListView,
    }: {
      employeePredictionId: string | null;
      newGraduate: NewGraduateToDisplay;
      newGraduateToListView?: NewGraduateToListView;
    }) => {
      const updatedNewGraduate = new NewGraduateToDisplay(
        new Employee({
          ...newGraduate,
        }) as DeprecatedNewGraduate,
        newGraduate.employeeTagIds,
        {
          id: newGraduate.recruitmentStatusId,
          label: newGraduate.recruitmentStatusLabel,
          type: newGraduate.recruitmentStatusType,
        },
        employeePredictionId
      );

      const editEmployeePredictionRelationsPromise = apiClient.post(
        "/api/employee/id/employee-prediction-relation",
        {
          employeePredictionId,
          employeeId: newGraduate.id,
        }
      );

      // ヨミの変更を画面に即時反映させるために、楽観的更新を行う
      const mutateEmployee = generateMutateEmployee(newGraduate.id);
      mutateEmployee(
        async () => {
          await editEmployeePredictionRelationsPromise;
          return updatedNewGraduate;
        },
        {
          optimisticData: updatedNewGraduate,
        }
      );

      const mutateNewGraduate = generateMutateNewGraduate(newGraduate.id);
      mutateNewGraduate(
        async () => {
          await editEmployeePredictionRelationsPromise;
          return updatedNewGraduate;
        },
        {
          optimisticData: updatedNewGraduate,
        }
      );

      // NOTE: 一覧画面と詳細画面で異なるmutateを行う必要がある、今は一つなのでnewGraduateToListViewがあるかどうかで分岐している
      if (newGraduateToListView) {
        const updatedNewGraduateToListView = new NewGraduateToListView(
          newGraduateToListView,
          newGraduateToListView.employeeTagIds,
          {
            id: newGraduateToListView.recruitmentStatusId,
            label: newGraduateToListView.recruitmentStatusLabel,
            type: newGraduateToListView.recruitmentStatusType,
          },
          employeePredictionId,
          newGraduateToListView.nextPlan,
          newGraduateToListView.unansweredOnnTaskCount,
          newGraduateToListView.schoolName,
          newGraduateToListView.affiliationFullName,
          newGraduateToListView.phoneNumber
        );
        options?.onEditEmployeePredictionRelation?.({
          updatedNewGraduate: updatedNewGraduateToListView,
          editEmployeePredictionRelationsPromise,
        });
      }

      await editEmployeePredictionRelationsPromise
        .then(async () => {
          enqueueSnackbar("ヨミを更新しました。", { variant: "success" });
        })
        .catch((error) => {
          if (error instanceof Error) {
            enqueueSnackbar(error.message, { variant: "error" });
          }
        });

      // 全候補者のミューテーション
      mutateAllNewcomers();
    },
    [
      enqueueSnackbar,
      generateMutateEmployee,
      generateMutateNewGraduate,
      mutateAllNewcomers,
      options,
    ]
  );

  return { editEmployeePredictionRelations };
};
