import { Box } from "@mui/material";
import { Question } from "@onn/common/domain/OnnTask/Question/Question";
import React, { useMemo } from "react";
import styled from "styled-components";

import { Loading, SelectForm, Typography } from "~/components/uiParts";
import { useFormRevisions } from "~/hooks/onnTask";

export type OnnTaskQuestionAnswerSelectionFormProps = {
  questionSelector: {
    value?: Question["id"];
    onChange: (value: Question["id"]) => void;
    errorMessage?: string;
  };
  questionOptionSelector: {
    value?: string;
    onChange: (value: string) => void;
    errorMessage?: string;
  };
  onnFormTaskId: string;
};

export const OnnTaskQuestionAnswerSelectionForm: React.FC<
  OnnTaskQuestionAnswerSelectionFormProps
> = (props) => {
  const { data: onnFormRevisionsData, isLoading } = useFormRevisions({
    onnFormTaskId: props.onnFormTaskId,
    isOnlyLatest: true,
  });

  const questionId = props.questionSelector.value;
  const menuItems = useMemo(() => {
    return onnFormRevisionsData?.formRevisions.flatMap((revision) => {
      return revision.questions
        .filter((question) => question.type === "RADIO")
        .map((question) => ({
          name: question.title,
          value: question.id,
        }));
    });
  }, [onnFormRevisionsData]);

  const selectableOptions = useMemo(() => {
    if (!questionId) return [];
    return (
      onnFormRevisionsData?.formRevisions.flatMap((revision) => {
        return revision.questions
          .filter((question) => question.id === questionId)
          .flatMap((question) => {
            if (question.type === "RADIO") {
              return question.options.map((option) => ({
                name: option.text,
                value: option.id,
              }));
            }
            return [];
          });
      }) || []
    );
  }, [onnFormRevisionsData?.formRevisions, questionId]);

  if (isLoading) {
    return <Loading size="small" />;
  }
  return (
    <Root>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Typography variant="caption" bold component="div">
          設問
        </Typography>
        <SelectForm
          fullWidth
          selected={props.questionSelector.value || null}
          onChange={(event) => props.questionSelector.onChange(event.target.value as string)}
          errorText={props.questionSelector.errorMessage}
          menuItems={menuItems}
        />
      </Box>
      {props.questionSelector.value && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Typography variant="caption" bold component="div">
            選択肢
          </Typography>
          <SelectForm
            fullWidth
            selected={props.questionOptionSelector.value || null}
            onChange={(event) =>
              props.questionOptionSelector.onChange(event.target.value as string)
            }
            errorText={props.questionOptionSelector.errorMessage}
            menuItems={selectableOptions}
          />
        </Box>
      )}
      <Typography variant="overline" color="textSecondary">
        ※回答方式が「選択式」の設問のみ選択することができます
      </Typography>
    </Root>
  );
};

const Root = styled(Box)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;
