import { BriefingSessionCategory } from "@onn/common";
import { BriefingSessionEvent } from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useForm } from "./useForm";

import { useCreateOnnEventV2 } from "~/hooks/onnEvent/useCreateOnnEventV2";
import { useUpdateOnnEventV2 } from "~/hooks/onnEvent/useUpdateOnnEventV2";
import { useSnackbar } from "~/hooks/shared";

export const usePage = (
  params:
    | {
        mode: "create";
      }
    | {
        mode: "edit";
        onnEvent: BriefingSessionEvent;
        briefingSessionCategories: BriefingSessionCategory[];
      }
) => {
  const { form } = useForm(params);
  const [searchParams] = useSearchParams();

  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { createOnnEvent } = useCreateOnnEventV2();
  const { updateOnnEvent } = useUpdateOnnEventV2();

  // 保存して次へボタンクリック時のハンドラー
  const onClickSaveAndToNext = form.handleSubmit(
    async (input) => {
      if (params.mode === "create") {
        try {
          const data = await createOnnEvent({
            title: input.title,
            content: input.content,
            isRestrictedAnswerFromNewGraduate: !!input.isRestrictedAnswerFromNewGraduate,
            isRestrictedDaysAgoWhenAnswer: !!input.isRestrictedDaysAgoWhenAnswer,
            isRestrictedEditAnswerFromNewGraduate: !!input.isRestrictedEditAnswerFromNewGraduate,
            daysAgoRestrictAnswer:
              typeof input.daysAgoRestrictAnswer === "string"
                ? Number(input.daysAgoRestrictAnswer)
                : undefined,
            type: "briefing_session",
            briefingSessions: input.briefingSessions,
          });
          if (!data) throw new Error();
          navigation(`/events/${data.onnEvent.id}/slot_default_value_setting?from_page=create`);
        } catch (_error) {
          enqueueSnackbar("イベントの作成に失敗しました。", { variant: "error" });
        }
      } else {
        try {
          const alreadyRegisteredSessionCategories = params.briefingSessionCategories;
          const existingSessionCategoryIdMap = new Map(
            alreadyRegisteredSessionCategories.map((category) => [category.id, category])
          );
          const briefingSessionCategoriesToCreate = input.briefingSessions.slice(
            alreadyRegisteredSessionCategories.length
          );

          const briefingSessionCategoriesToUpdate = input.briefingSessions.flatMap((category) => {
            if (!category.id) return [];
            const existingCategory = existingSessionCategoryIdMap.get(category.id);
            if (!existingCategory) return [];
            if (
              existingCategory.title === category.title &&
              existingCategory.description === category.description
            ) {
              return [];
            }
            return {
              id: category.id,
              title: category.title,
              description: category.description,
            };
          });
          await updateOnnEvent({
            id: params.onnEvent.id,
            updateObject: {
              title: input.title,
              content: input.content,
              isRestrictedAnswerFromNewGraduate: !!input.isRestrictedAnswerFromNewGraduate,
              isRestrictedDaysAgoWhenAnswer: !!input.isRestrictedDaysAgoWhenAnswer,
              isRestrictedEditAnswerFromNewGraduate: !!input.isRestrictedEditAnswerFromNewGraduate,
              daysAgoRestrictAnswer:
                typeof input.daysAgoRestrictAnswer === "string"
                  ? Number(input.daysAgoRestrictAnswer)
                  : undefined,
            },
            briefingSessionCategoriesToCreate,
            briefingSessionCategoriesToUpdate,
          });
          navigation(`/events/${params.onnEvent.id}`);
        } catch (_error) {
          enqueueSnackbar("イベントの編集に失敗しました。", { variant: "error" });
        }
      }
    },
    () => {
      form.trigger();
    }
  );

  const fromPage = searchParams.get("from_page");

  // キャンセルボタンクリック時のハンドラー
  const onClickCancel = () => {
    if (fromPage === "detail" && params.mode === "edit") {
      navigation(`/events/${params.onnEvent.id}`);
    } else {
      navigation(`/events`);
    }
  };

  const hasError = Object.keys(form.formState.errors).length > 0;
  const isSaveButtonDisabled = form.formState.isSubmitting || hasError;

  // NOTE: 1stではイベント編集時に説明会カテゴリーの削除はできないため、すでに登録済の説明会カテゴリーのインデックスを取得し、それを元に削除ボタンを無効化する
  const disabledSessionCategoriesDeleteIndexes =
    params.mode === "edit" ? params.briefingSessionCategories.map((_, i) => i) : undefined;

  return {
    form,
    onClickSaveAndToNext,
    onClickCancel,
    isSaveButtonDisabled,
    disabledSessionCategoriesDeleteIndexes,
  };
};
