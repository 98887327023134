import React, { ChangeEvent, FC, memo } from "react";
import styled from "styled-components";

import { IconButton } from "../IconButton";

import { TextFieldDeprecated } from "~/components/uiParts/TextField";

type SearchFormProps = {
  placeholder: string;
  variant: "standard" | "outlined";
  fullWidth?: boolean;
  fullHeight?: boolean;
  searchValue: string;
  onSearchValue: (value: string) => void;
  backgroundColor?: string;
  errorText?: string;
};

export const SearchForm: FC<SearchFormProps> = memo(
  ({
    placeholder,
    variant,
    fullWidth,
    fullHeight,
    searchValue,
    onSearchValue,
    backgroundColor,
    errorText,
  }: SearchFormProps) => {
    return (
      <StyledTextField
        variant={variant}
        placeholder={placeholder}
        value={searchValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onSearchValue(e.target.value);
        }}
        endAdornment={<IconButton icon="search" size="sm" />}
        error={Boolean(errorText)}
        helperText={errorText}
        $fullWidth={Boolean(fullWidth)}
        $fullHeight={Boolean(fullHeight)}
        $backgroundColor={backgroundColor}
      />
    );
  }
);

const StyledTextField = styled(TextFieldDeprecated)<{
  $fullWidth: boolean;
  $fullHeight: boolean;
  $backgroundColor?: string;
}>`
  width: ${(props) => (props.$fullWidth ? "100%" : "280px")};
  height: ${(props) => (props.$fullHeight ? "100%" : undefined)};
  .MuiInputBase-input {
    font-size: 14px;
  }
  .MuiInputBase-root {
    height: ${(props) => (props.$fullHeight ? "100%" : undefined)};
    background-color: ${(props) => props.$backgroundColor};
  }
`;
