import { Box, Stack } from "@mui/material";
import { EmployeeInformationGroupWithFieldAndOptions } from "@onn/common";
import React, { FC, useCallback } from "react";

import { DropResult } from "react-beautiful-dnd";
import { Controller } from "react-hook-form";

import { v4 } from "uuid";

import { InformationGroupInputRow } from "./components/InformationGroupInputRow";
import { InputState, useForm } from "./hooks/useForm";

import {
  Button,
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
  ScrollableBodyModal,
  TextField,
  Typography,
} from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel(): void;
  data: {
    groups: EmployeeInformationGroupWithFieldAndOptions[];
    type: "add" | "edit";
    onSubmit: (inputValue: InputState) => void;
  };
};

export const EditEmployeeInformationGroupModal: FC<Props> = ({ open, onCancel, data }) => {
  const onSubmit = data.onSubmit;
  const modalTile = data.type === "add" ? "グループ追加" : "グループ編集";
  const modalDescription =
    data.type === "add" ? (
      <center>
        この設定への変更はすべての候補者詳細に反映されます。
        <br />
        グループを削除した場合、配下の項目に関連する
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          全ての候補者情報
        </span>
        も削除されます。
      </center>
    ) : (
      <center>
        候補者情報に表示する項目をまとめたグループをカスタマイズすることができます。
        <br />
        この設定への変更はすべての候補者詳細に反映されます。
      </center>
    );

  const { generateFormDefaultValues } = useGenerateFormDefaultValues();
  const { form, addGroup, removeGroup, changeGroupOrder, handleSubmit, isError } = useForm({
    defaultValues: generateFormDefaultValues(data.groups, data.type),
    onSubmit,
    closeModal: onCancel,
  });

  const onClickSaveButton = useCallback(handleSubmit, [handleSubmit, onCancel]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;
      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;
      changeGroupOrder(sourceIndex, destinationIndex);
    },
    [changeGroupOrder]
  );

  return (
    <ScrollableBodyModal
      open={open}
      title={modalTile}
      footer={
        <Box
          display={"flex"}
          style={{
            columnGap: "16px",
          }}
        >
          <Box>
            <Button borderRadius="circle" variant="text" color="default" onClick={onCancel}>
              キャンセル
            </Button>
          </Box>
          <Box>
            <Button
              onClick={onClickSaveButton}
              borderRadius="circle"
              variant="contained"
              color="primary"
              disabled={isError}
            >
              変更を保存
            </Button>
          </Box>
        </Box>
      }
      content={
        <Box>
          <Stack direction="row" alignItems="start" justifyContent={"center"} mb={"24px"}>
            <Typography variant="body1" color="textPrimary">
              {modalDescription}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="start" mb={"8px"}>
            <Box flexBasis={"48px"} />
            <Box style={{ cursor: "not-allowed" }} flex={1}>
              <TextField
                fullWidth
                variant="outlined"
                value={"基本情報"}
                onChange={() => {}}
                disabled
              />
            </Box>
            <Box flexBasis={"48px"} />
          </Stack>
          <DnDProvider onDragEnd={onDragEnd}>
            <Box width="100%">
              <DnDDroppable droppableId="droppableId" isDropDisabled={false}>
                {form.watch("groups").map((group, index) => (
                  <DnDDraggable
                    draggableId={group.id}
                    key={group.id}
                    index={index}
                    isDragDisabled={false}
                    shouldRoundCorner
                  >
                    <Stack direction="row" alignItems="start" spacing={1} width="100%">
                      <Box px={1} py={2} display="flex" justifyContent="center">
                        <Icon icon="gripVertical" size="md" color="grey400" />
                      </Box>
                      <Controller
                        key={group.id}
                        control={form.control}
                        name={`groups.${index}.label`}
                        render={({ field, fieldState }) => (
                          <InformationGroupInputRow
                            onChange={field.onChange}
                            value={field.value}
                            errorText={fieldState.error?.message}
                            onClickRemoveButton={() => removeGroup(index)}
                          />
                        )}
                      />
                    </Stack>
                  </DnDDraggable>
                ))}
              </DnDDroppable>
            </Box>
          </DnDProvider>
          <Box mt="24px">
            <Button
              variant="text"
              borderRadius="regular"
              color="primary"
              startIcon={<Icon icon="add" size="ssm" color="primary" />}
              onClick={addGroup}
            >
              グループを追加
            </Button>
          </Box>
        </Box>
      }
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const useGenerateFormDefaultValues = () => {
  const generateFormDefaultValues = useCallback(
    (groups: EmployeeInformationGroupWithFieldAndOptions[], type: "add" | "edit") => {
      const defaultGroups = groups.map(({ employeeInformationGroup }) => ({
        id: employeeInformationGroup.id,
        label: employeeInformationGroup.label,
      }));
      if (type === "add") {
        defaultGroups.push({ id: v4(), label: "" });
      }

      return {
        groups: defaultGroups,
      };
    },
    []
  );

  return { generateFormDefaultValues };
};
