import { OnnEvent } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

export type OnnEventDataForPortal = {
  onnEvent: OnnEvent;
  isAnswered: boolean;
};

const generateKey = (employeeId: string) => {
  return { endpoint: `/api/portal/onn-events`, employeeId: `${employeeId}` } as const;
};

export const useOnnEventsForPortal = ({
  employeeId,
}: {
  employeeId: string;
}): SWRResponse<OnnEventDataForPortal[], Error> => {
  return useSWR(generateKey(employeeId), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);
    return response.data.map((d) => {
      return {
        onnEvent: new OnnEvent(d.onnEvent),
        isAnswered: d.isAnswered,
      };
    });
  });
};
