import { Box } from "@material-ui/core";
import { OnnTaskQuestion, TaskOptionTypes } from "@onn/common";
import {
  Answer,
  CheckBoxAnswer,
  FileAnswer,
  RadioAnswer,
  TextAnswer,
} from "@onn/common/domain/OnnTask/Answer/Answer";
import React, { FC, memo } from "react";
import styled from "styled-components";

import { TooltipWhenTextTruncated, Typography } from "~/components/uiParts";

export const AnswerCell: FC<{
  question: OnnTaskQuestion;
  answers: Answer[];
}> = ({ answers, question }) => {
  const answer = answers.find((answer) => question.id === answer.questionId);

  const content = (() => {
    if (!answer) return <></>;

    if (question.type === TaskOptionTypes.TEXT) {
      const _answer = answer as TextAnswer;
      return <AnswerCellCore value={_answer.value ?? ""} />;
    } else if (question.type === TaskOptionTypes.RADIO) {
      const _answer = answer as RadioAnswer;
      const option = question.options.find((option) => option.id === _answer.selectedOptionId);
      return <AnswerCellCore value={option?.text ?? ""} />;
    } else if (question.type === TaskOptionTypes.CHECK_BOX) {
      const _answer = answer as CheckBoxAnswer;
      const options = question.options.filter((option) =>
        _answer.selectedOptionIds.includes(option.id)
      );
      const value = options.map((option) => option.text).join(", ");
      return <AnswerCellCore value={value} />;
    } else if (question.type === TaskOptionTypes.FILE) {
      const _answer = answer as FileAnswer;
      const fileName = _answer.filePath.split("/").pop() || "";
      return <AnswerCellCore value={fileName} />;
    }
    return <></>;
  })();

  return <AnswerCellWrapper>{content}</AnswerCellWrapper>;
};

const AnswerCellWrapper = memo<{ children: React.ReactNode }>(({ children }) => (
  <StyledRowCellBox px="8px">{children}</StyledRowCellBox>
));

const AnswerCellCore = memo<{ value: string }>(({ value }) => {
  return (
    <TooltipWhenTextTruncated text={value}>
      {(ref) => (
        <Typography variant="body2" color="textPrimary" noWrap ref={ref}>
          {value}
        </Typography>
      )}
    </TooltipWhenTextTruncated>
  );
});

const StyledRowCellBox = styled(Box)<{ isLast?: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px ${(props) => (props.isLast ? 0 : 1)}px 0px 0px;
  display: flex;
  align-items: center;
  background-color: transparent;
`;
