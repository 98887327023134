import React, { createContext } from "react";

import { Action } from "../../_share_in_create_edit/reducer";

type CreateOnnTaskPageContextType = {
  onnFormTaskId: string;
  dispatch: React.Dispatch<Action>;
};

const initialContext: CreateOnnTaskPageContextType = {
  onnFormTaskId: "",
  dispatch: () => 0,
};

export const CreateOnnTaskPageContext = createContext(initialContext);

export const useCreateOnnTaskPageContext = () => {
  return React.useContext(CreateOnnTaskPageContext);
};
