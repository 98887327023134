import { Box } from "@mui/material";
import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useRichMenuTabContext } from "../../RichMenuTabContext";
import { InputState } from "../../useForm";

import { RichMenuImageFilePicker } from "./RichMenuImageFilePicker";

import { Button, Icon, Typography } from "~/components/uiParts";
export const ImageFileForm: FC = () => {
  const { control } = useFormContext<InputState>();
  const { isUploadingRichMenuImage: isUploading } = useRichMenuTabContext();
  const richMenuImageController = useController({ control, name: "richMenuImage" });
  const errorMessage = richMenuImageController.fieldState.error?.message;
  return (
    <Box>
      <Box display="flex" columnGap="16px" alignItems="center">
        <RichMenuImageFilePicker>
          <Button
            startIcon={<Icon icon="clip" size="sm" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            disabled={isUploading}
            isLoading={isUploading}
          >
            ファイルを選択
          </Button>
        </RichMenuImageFilePicker>
        {!isUploading && (
          <Typography variant="body2">
            {richMenuImageController.field.value.file?.name || "ファイルが選択されていません"}
          </Typography>
        )}
      </Box>
      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
