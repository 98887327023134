import {
  Accordion as AccordionOrigin,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import React, { FC, ReactNode, useCallback } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";

type Props = {
  defaultExpanded: boolean;
  title: string;
  children: ReactNode;
};

export const NewAccordion: FC<Props> = ({ defaultExpanded, title, children }) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);
  const handleToggleExpanded = useCallback(() => setExpanded((prev) => !prev), []);

  return (
    <StyledAccordion onChange={handleToggleExpanded} expanded={expanded}>
      <StyledAccordionSummary>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ fontWeight: 700, lineHeight: 1.5, flex: 1 }}
        >
          {title}
        </Typography>
        <Icon icon={expanded ? "chevronUp" : "chevronDown"} size="md" color="grey" />
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(AccordionOrigin)`
  &.MuiAccordion-rounded:last-child {
    border-radius: 15px;
  }
  &.MuiAccordion-rounded:first-child {
    border-radius: 15px;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 24px;

  &.MuiAccordionSummary-root {
    padding: 24px;
    width: 100%;
    min-height: unset;
  }

  &.MuiAccordionSummary-root.Mui-expanded {
    margin: 0;
    min-height: unset;
  }

  > .MuiAccordionSummary-content {
    margin: 0;
    width: 100%;
    align-items: center;

    &.Mui-expanded {
      margin: 0;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 24px;
    padding-top: 0px;
  }
`;
