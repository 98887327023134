import { TaskOptionTypes } from "../Question";

export interface BaseAnswer {
  id: string;
  type: keyof typeof TaskOptionTypes;
  questionId: string;
}

export interface CheckBoxAnswer extends BaseAnswer {
  type: "CHECK_BOX";
  selectedOptionIds: string[];
  freeText?: string;
}

export interface TextAnswer extends BaseAnswer {
  type: "TEXT";
  value: string;
}

export interface RadioAnswer extends BaseAnswer {
  type: "RADIO";
  selectedOptionId: string;
}

export interface FileAnswer extends BaseAnswer {
  type: "FILE";
  filePath: string;
}

export type Answer = CheckBoxAnswer | TextAnswer | RadioAnswer | FileAnswer;

export const isCheckBoxAnswer = (answer: Answer): answer is CheckBoxAnswer => {
  return answer.type === "CHECK_BOX";
};
export const isTextAnswer = (answer: Answer): answer is TextAnswer => {
  return answer.type === "TEXT";
};
export const isRadioAnswer = (answer: Answer): answer is RadioAnswer => {
  return answer.type === "RADIO";
};
export const isFileAnswer = (answer: Answer): answer is FileAnswer => {
  return answer.type === "FILE";
};
