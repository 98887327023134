import { OnnEvent, generatePagePath } from "@onn/common";
import { useNavigate } from "react-router-dom";

export const useOnClickCreateButton = (type: OnnEvent["type"]) => {
  const navigation = useNavigate();
  const onClickCreateButton = () => {
    navigation(generatePagePath.onnEvent.create({ eventType: type }));
  };

  return {
    onClickCreateButton,
  };
};
