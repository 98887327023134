import { RichMenuImageFileConstraints, validateRichMenuImageSize } from "@onn/common";
import { useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useRichMenuTabContext } from "../../RichMenuTabContext";
import { InputState } from "../../useForm";

import { useGenerateRichMenuImageFilePath } from "./useGenerateRichMenuImageFilePath";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useSnackbar } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { getImageMetaDataFromFile } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

/**
 * リッチメニュー画像ファイルが選択された時の処理を提供するカスタムフック
 */
export const useOnChangeFile = () => {
  const { control } = useFormContext<InputState>();
  const richMenuImageController = useController({ control, name: "richMenuImage" });
  const { enqueueSnackbar } = useSnackbar();
  const { generateRichMenuImageFilePath } = useGenerateRichMenuImageFilePath();
  const { setIsUploadingRichMenuImage } = useRichMenuTabContext();

  const onChangeFile = useCallback(
    async (payload: Payload) => {
      if (payload.status === "success") {
        const file = payload.files[0];
        if (!file) return;
        const validTypes = RichMenuImageFileConstraints.accept;
        if (!validTypes.includes(file.type)) {
          enqueueSnackbar("JPEGまたはPNG形式の画像ファイルを選択してください", {
            variant: "error",
          });
          return;
        }
        const imageSize = await getImageMetaDataFromFile(file);
        const sizeValidationResult = validateRichMenuImageSize({
          width: imageSize.naturalWidth,
          height: imageSize.naturalHeight,
        });
        if (!sizeValidationResult.isOk) {
          enqueueSnackbar(sizeValidationResult.message, { variant: "error" });
          return;
        }

        const filePath = generateRichMenuImageFilePath();
        try {
          setIsUploadingRichMenuImage(true);
          await fileAPIAdapter.uploadFiles([
            {
              path: filePath,
              file: file,
            },
          ]);
          richMenuImageController.field.onChange({
            file,
            filePath,
          });
        } catch (_e) {
          enqueueSnackbar(
            "ファイルのアップロードに失敗しました。何回も発生する場合は、管理者までお問い合わせください。",
            { variant: "error" }
          );
        } finally {
          setIsUploadingRichMenuImage(false);
        }
      } else {
        const errorMessage = payload.message;
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    },
    [
      enqueueSnackbar,
      generateRichMenuImageFilePath,
      richMenuImageController.field,
      setIsUploadingRichMenuImage,
    ]
  );

  return { onChangeFile };
};
