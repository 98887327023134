import { isInvalidDate } from "@onn/common";
import { set } from "date-fns";
import { Control, useController, UseFormTrigger } from "react-hook-form";

import { InputState } from "./InputState";

import {
  SELECTABLE_END_HOUR,
  SELECTABLE_START_HOUR,
} from "~/hooks/onnEvent/useSelectableTimeItems";

type UseCandidateDateRHFRowProps = {
  index: number;
  control: Control<InputState>;
  trigger: UseFormTrigger<InputState>;
  isEdit?: boolean;
  candidateDateId: string;
  isCandidateDateDuplicate: boolean;
};

export const useCandidateDateRHFRow = ({
  index,
  control,
  trigger,
  isEdit,
  candidateDateId,
  isCandidateDateDuplicate,
}: UseCandidateDateRHFRowProps) => {
  const {
    field: { value: hasCapacity },
  } = useController({ control, name: "hasCapacity" });
  const {
    field: { value: candidateDatesValue }, // 候補日の入力フォームの配列。イベント新規登録の際、候補日入力フォームの削除可否の判定に使用
  } = useController({ control, name: `candidateDates` });
  const candidateDateFrom = useController({ control, name: `candidateDates.${index}.from` });
  const onChangeCandidateDateFrom = candidateDateFrom.field.onChange;
  const candidateDateUntil = useController({ control, name: `candidateDates.${index}.until` });
  const onChangeCandidateDateUntil = candidateDateUntil.field.onChange;
  const candidateDateFromTime = useController({
    control,
    name: `candidateDates.${index}.fromTime`,
  });

  const candidateDateUntilTime = useController({
    control,
    name: `candidateDates.${index}.untilTime`,
  });

  const {
    field: { onChange: onChangeCapacity, value: capacityValue },
    fieldState: { error: capacityError },
  } = useController({
    control,
    name: `candidateDates.${index}.capacity`,
  });

  // 開始時間セレクターのクリックハンドラー
  const onChangeFromTimeSelector = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const newFromTimeValue = Number(event.target.value);
    candidateDateFromTime.field.onChange(newFromTimeValue);

    // NOTE: 開始時間が変更された時は、終了時間も30分後(0.5h)へ変更する。
    const timeSpan = 0.5;
    const newUntilTime =
      newFromTimeValue + timeSpan > SELECTABLE_END_HOUR
        ? SELECTABLE_END_HOUR
        : newFromTimeValue + timeSpan;
    candidateDateUntilTime.field.onChange(newUntilTime);

    // 重複処理
    if (
      candidateDateFrom.field.value !== null &&
      candidateDateUntil.field.value !== null &&
      !isInvalidDate(candidateDateFrom.field.value)
    ) {
      const fromHour = Math.trunc(newFromTimeValue);
      const fromMinute = (newFromTimeValue - fromHour) * 60;
      onChangeCandidateDateFrom(
        set(candidateDateFrom.field.value, { hours: fromHour, minutes: fromMinute })
      );
      const untilHour = Math.trunc(newUntilTime);
      const untilMinute = (newUntilTime - untilHour) * 60;
      onChangeCandidateDateUntil(
        set(candidateDateUntil.field.value, { hours: untilHour, minutes: untilMinute })
      );
      trigger(`candidateDates.${index}`);
    }
  };

  // 終了時間セレクターのクリックハンドラー
  const onChangeUntilTimeSelector = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const newUntilTimeValue = Number(event.target.value);
    candidateDateUntilTime.field.onChange(newUntilTimeValue);

    // NOTE: 開始時刻>終了時刻の場合、開始時間が変更された時は、終了時間も変更する。
    if (candidateDateFromTime.field.value >= newUntilTimeValue) {
      const timeSpan = candidateDateUntilTime.field.value - candidateDateFromTime.field.value;
      const newFromTime =
        newUntilTimeValue - timeSpan < SELECTABLE_START_HOUR
          ? SELECTABLE_START_HOUR
          : newUntilTimeValue - timeSpan;
      candidateDateFromTime.field.onChange(newFromTime);

      if (candidateDateFrom.field.value !== null && !isInvalidDate(candidateDateFrom.field.value)) {
        const fromHour = Math.trunc(newFromTime);
        const fromMinute = (newFromTime - fromHour) * 60;
        onChangeCandidateDateFrom(
          set(candidateDateFrom.field.value, { hours: fromHour, minutes: fromMinute })
        );
      }
    }

    // 重複処理
    if (candidateDateUntil.field.value !== null && !isInvalidDate(candidateDateUntil.field.value)) {
      const untilHour = Math.trunc(newUntilTimeValue);
      const untilMinute = (newUntilTimeValue - untilHour) * 60;
      onChangeCandidateDateUntil(
        set(candidateDateUntil.field.value, { hours: untilHour, minutes: untilMinute })
      );
      trigger(`candidateDates.${index}`);
    }
  };

  // 候補日のonChangeハンドラ
  const onChangeCandidateDate = (date: Date | null, value?: string | null | undefined) => {
    if (date === null) {
      candidateDateFrom.field.onChange(null);
      candidateDateUntil.field.onChange(null);
      trigger(`candidateDates.${index}`);

      return;
    }
    if (isInvalidDate(date)) {
      candidateDateFrom.field.onChange(date, value);
      candidateDateUntil.field.onChange(date, value);

      return;
    }

    const fromHour = Math.trunc(candidateDateFromTime.field.value);
    const fromMinute = (candidateDateFromTime.field.value - fromHour) * 60;
    onChangeCandidateDateFrom(
      set(date, { hours: Math.trunc(candidateDateFromTime.field.value), minutes: fromMinute }),
      value
    );
    const untilHour = Math.trunc(candidateDateUntilTime.field.value);
    const untilMinute = (candidateDateUntilTime.field.value - untilHour) * 60;
    onChangeCandidateDateUntil(
      set(date, { hours: Math.trunc(candidateDateUntilTime.field.value), minutes: untilMinute }),
      value
    );
    trigger(`candidateDates.${index}`);
  };

  // フォーム自体を削除することができるかどうか
  // NOTE: 新規登録の際は、候補日が1つしかない場合、入力フォームを削除できない
  const canDeleteForm = isEdit ? candidateDateId === "" : candidateDatesValue.length > 1;
  const disabled = isEdit ? candidateDateId !== "" : false;

  // 開始時間・終了時間セレクターのエラー状態
  const isSelectFormError =
    !disabled && candidateDateFrom.fieldState.error !== undefined && isCandidateDateDuplicate;

  // 定員AutoCompleteのハンドラー
  const onChangeCapacityAC = (value: string) => {
    onChangeCapacity(value);
  };

  // 定員AutoCompleteのサジェスト一覧
  const capacityACOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300].map(
    (v) => ({
      label: String(v),
      value: String(v),
    })
  );

  const hasCapacityError = capacityError && capacityValue !== undefined;
  return {
    candidateDateDP: {
      value: candidateDateFrom.field.value,
      onChange: onChangeCandidateDate,
      error: !disabled && candidateDateFrom.fieldState.error !== undefined,
      helperText: !disabled && candidateDateFrom.fieldState.error?.message,
    },
    candidateDateTimeSelector: {
      selected: candidateDateFromTime.field.value,
      onChange: onChangeFromTimeSelector,
      errorBorder: isSelectFormError,
    },
    candidateDateUntilSelector: {
      selected: candidateDateUntilTime.field.value,
      onChange: onChangeUntilTimeSelector,
      errorBorder: isSelectFormError,
    },
    capacityAutoComplete: {
      options: capacityACOptions.map((option) => option.label),
      onChange: onChangeCapacityAC,
      value: capacityValue === undefined ? "" : String(capacityValue),
      error: hasCapacityError,
      helperText: hasCapacityError && capacityError.message,
    },
    hasCapacity,
    disabled,
    canDeleteForm,
    isSelectFormError,
  };
};
