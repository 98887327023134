import liff from "@line/liff";
import { Box, List } from "@material-ui/core";
import { typedEntries } from "@onn/common";
import React, { FC, ReactNode, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { LinkKey, useViewableLinks } from "./hooks/useViewableLinks/useViewableLinks";

import { ContactContextV2 } from "~/components/providers";
import { Divider, GlobalNaviMenu, Header } from "~/components/uiParts";
import { AccountSettingButton } from "~/components/uiParts/GlobalNaviMenu/parts/AccountSettingButton";
import { ListItem } from "~/components/uiParts/GlobalNaviMenu/parts/ListItem";
import { LogoutButton } from "~/components/uiParts/GlobalNaviMenu/parts/LogoutButton";
import { useAnnouncementsForPortal } from "~/hooks/announcement/useAnnouncementsForPortal";
import { useCurrentUser } from "~/hooks/employee";
import { useLibraries } from "~/hooks/library";
import { useOnnEventsForPortal } from "~/hooks/onnEvent";
import { useQuery } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting";

type Props = {
  children: ReactNode;
  fixedWindowHeight?: boolean;
};

export const PortalLayout: FC<Props> = ({ fixedWindowHeight, children }) => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { tenantSettings } = useTenantSettings();
  const { data: libraries } = useLibraries(currentUser.tenantId, currentUser.spaceId);
  const { data: onnEventDataForPortalArray } = useOnnEventsForPortal({
    employeeId: currentUser.id,
  });
  const { data: announcementsData } = useAnnouncementsForPortal();

  const { totalUnreadCount, contactRooms } = useContext(ContactContextV2);
  const { query } = useQuery();
  const isPreview = query.get("preview") === "true";

  const viewableLinks = useViewableLinks({
    currentUser,
    tenantSettings,
    contactRooms,
    libraries,
    onnEvents: onnEventDataForPortalArray?.map((v) => v.onnEvent),
    announcements: announcementsData?.announcements,
    isPreview,
  });

  const isDisplayContactBadge = useMemo(() => 0 < totalUnreadCount, [totalUnreadCount]);

  const handleClickLogo = () => {
    navigate({ pathname: "/portal", search: location.search });
  };

  const featurePaths: Record<LinkKey, { label: string; to: string; badgeLabel?: string }> = {
    home: { label: "ホーム", to: "/portal" },
    lineQr: { label: "LINE公式アカウント", to: "/portal/line_qr" },
    announcements: { label: "お知らせ", to: "/portal/announcements" },
    events: { label: "イベント", to: "/portal/events" },
    libraries: { label: "ライブラリ", to: "/portal/libraries" },
    onnTasks: { label: "フォーム", to: "/portal/onn_tasks" },
    contactRooms: {
      label: "コンタクト",
      to: "/portal/contact_rooms",
      badgeLabel: 0 < totalUnreadCount ? totalUnreadCount.toString() : undefined,
    },
  };
  const featureLinks = typedEntries(featurePaths).map(([key, value]) => ({
    isView: viewableLinks.includes(key),
    ...value,
  }));

  return (
    <StyledDivWrapper $fixedWindowHeight={fixedWindowHeight}>
      <Header
        currentTenantId={currentUser.tenantId}
        onClickLogo={handleClickLogo}
        drawerMenu={
          <GlobalNaviMenu
            isBadge={isDisplayContactBadge}
            renderDrawerContent={(closeDrawer: () => void) => (
              <List
                style={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
                disablePadding
              >
                {featureLinks.map(
                  (link, i) =>
                    link.isView && (
                      <ListItem
                        key={i}
                        label={link.label}
                        to={link.to}
                        badgeLabel={link.badgeLabel}
                        onClick={closeDrawer}
                      />
                    )
                )}
                <Divider margin={0} orientation="horizontal" />
                <AccountSettingButton currentUser={currentUser} onClick={closeDrawer} />
                {/* TODO: コンポーネント分割するなどして、liff を排除する */}
                {!liff.isInClient() && (
                  <>
                    <Divider margin={0} orientation="horizontal" />
                    <Box pb="16px">
                      <LogoutButton />
                    </Box>
                  </>
                )}
              </List>
            )}
          />
        }
      />
      <StyledBox display="flex" justifyContent="center" $fixedWindowHeight={fixedWindowHeight}>
        <StyledDiv $fixedWindowHeight={fixedWindowHeight}>{children}</StyledDiv>
      </StyledBox>
    </StyledDivWrapper>
  );
};

const StyledDivWrapper = styled.div<{ $fixedWindowHeight?: boolean }>`
  height: ${({ $fixedWindowHeight }) => ($fixedWindowHeight ? "100%;" : "auto")};
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledBox = styled(Box)<{ $fixedWindowHeight?: boolean }>`
  height: ${({ $fixedWindowHeight, theme }) =>
    $fixedWindowHeight ? `calc(100% - ${theme.constants.HEADER_HEIGHT}px)` : "auto"};
`;

const StyledDiv = styled.div<{ $fixedWindowHeight?: boolean }>`
  ${({ $fixedWindowHeight, theme }) =>
    $fixedWindowHeight
      ? "height: 100%"
      : `min-height: calc(100vh - ${theme.constants.HEADER_HEIGHT}px)`};
  width: 100vw;
  max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`};
  // モバイル端末用のブレークポイントとはあえてずらしている
`;
