import { Box, PopoverProps } from "@mui/material";
import { Action, AnyActionCondition, AnyActionSetting, AnyTriggerSetting } from "@onn/common";
import React, { FC, useMemo } from "react";

import { Controller } from "react-hook-form";

import { useEditModeContext } from "../../EditModeContextProvider";
import { SelectorOfOnnEvent } from "../Selectors/SelectorOfOnnEvent";
import { SelectorOfOnnTask } from "../Selectors/SelectorOfOnnTask";
import { SelectorOfRecruitmentStatus } from "../Selectors/SelectorOfRecruitmentStatus";

import { TextareaOfContactMessageText } from "../Textarea/TextareaOfContactMessageText";

import { ActionConditionForm } from "./components/ActionConditionForm/ActionConditionForm";
import { DeliveryTimingForm } from "./components/DeliveryTimingForm/DeliveryTimingForm";
import { ActionType, ValidInputState } from "./inputStateSchema";

import { useForm } from "./useForm";

import { Button, Typography } from "~/components/uiParts";
import { Popover } from "~/components/uiParts/Popover";
import { usePageContext } from "~/pages/scenario/pageContext";

type Props = {
  anchorEl: PopoverProps["anchorEl"];
  onClose: () => void;
  isOpen: boolean;
  backToSelectActionType: () => void;
  triggerSetting: AnyTriggerSetting;
  actionType: ActionType;
  onClickApplyButton: (inputValue: ValidInputState) => void;
} & (
  | {
      mode: "create";
      existingAction: null;
      existingAnyActionSetting: null;
      existingActionCondition: null;
    }
  | {
      mode: "update";
      existingAction: Action;
      existingAnyActionSetting: AnyActionSetting;
      existingActionCondition?: AnyActionCondition;
    }
);

export const PopoverOfActionSetting: FC<Props> = ({
  anchorEl,
  onClose,
  isOpen,
  backToSelectActionType,
  triggerSetting,
  actionType,
  onClickApplyButton,
  ...props
}) => {
  const { selectedRecruitmentStatus } = usePageContext();
  const { availableRecruitmentStatuses } = useEditModeContext();

  const { setValue, handleSubmit, formState, watch, trigger, clearErrors, control } = useForm({
    ...props,
    actionType,
    triggerSetting,
  });

  const apply = handleSubmit(async (inputValue) => {
    // NOTE: inputValue は zod によって型が保証されているため、ValidInputState でキャスト
    const validInputValue = inputValue as ValidInputState;
    onClickApplyButton(validInputValue);
    onClose();
  });

  const title = useMemo(() => {
    switch (actionType) {
      case "changeRecruitmentStatus":
        return "選考ステータスを変更";
      case "deliveryOnnEvent":
        return "イベントを付与・配信";
      case "deliveryOnnTask":
        return "タスクを付与・配信";
      case "sendContactMessage":
        return "カスタムメッセージを配信";
      default: {
        const _exhaustiveCheck: never = actionType;
        return _exhaustiveCheck;
      }
    }
  }, [actionType]);

  const formError = formState.errors;
  const isApplyButtonDisabled = Object.keys(formError).length > 0;

  return (
    <Popover
      id={actionType}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      title={title}
      onClickArrowLeftIconButton={backToSelectActionType}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        style={{
          gap: "16px",
        }}
      >
        {watch("actionSetting.actionType") === "changeRecruitmentStatus" && (
          <>
            <Typography variant="caption">対象の選考ステータスを選択してください。</Typography>
            <Controller
              name="actionSetting.recruitmentStatusId"
              control={control}
              render={({ field: { onChange, value }, formState }) => (
                <SelectorOfRecruitmentStatus
                  selectedRecruitmentStatusId={value}
                  onChange={(recruitmentStatusId) => {
                    onChange(recruitmentStatusId);
                    clearErrors("actionSetting");
                  }}
                  errorMessage={formState.errors.actionSetting?.recruitmentStatusId?.message ?? ""}
                  selectableRecruitmentStatuses={availableRecruitmentStatuses.filter(
                    (status) => status.id !== selectedRecruitmentStatus.id
                  )}
                />
              )}
            />
          </>
        )}
        {watch("actionSetting.actionType") === "deliveryOnnEvent" && (
          <>
            <Typography variant="caption">対象のイベントを選択してください。</Typography>
            <Controller
              name="actionSetting.onnEventId"
              control={control}
              render={({ field: { onChange, value }, formState }) => (
                <SelectorOfOnnEvent
                  value={value}
                  onChange={(onnEventId) => {
                    onChange(onnEventId);
                    clearErrors("actionSetting");
                  }}
                  errorMessage={formState.errors.actionSetting?.onnEventId?.message ?? ""}
                />
              )}
            />
          </>
        )}
        {watch("actionSetting.actionType") === "deliveryOnnTask" && (
          <>
            <Typography variant="caption">対象のタスクを選択してください。</Typography>
            <Controller
              name="actionSetting.onnTaskId"
              control={control}
              render={({ field: { onChange, value }, formState }) => (
                <SelectorOfOnnTask
                  value={value}
                  onChange={(onnTaskId) => {
                    onChange(onnTaskId);
                    clearErrors("actionSetting");
                  }}
                  errorMessage={formState.errors.actionSetting?.onnTaskId?.message ?? ""}
                />
              )}
            />
          </>
        )}
        {watch("actionSetting.actionType") === "sendContactMessage" && (
          <>
            <Typography variant="caption">配信するメッセージを設定してください。</Typography>
            <Controller
              name="actionSetting.text"
              control={control}
              render={({ field: { onChange, value }, formState }) => (
                <TextareaOfContactMessageText
                  value={value || ""}
                  onChange={(onnTaskId) => {
                    onChange(onnTaskId);
                    clearErrors("actionSetting");
                  }}
                  errorMessage={formState.errors.actionSetting?.text?.message ?? ""}
                />
              )}
            />
          </>
        )}
        <ActionConditionForm
          actionConditionTypeSelectionFormProps={{
            value: watch("actionCondition.conditionType"),
            onChange: (value) => {
              setValue("actionCondition.conditionType", value, { shouldValidate: true });
              clearErrors("actionCondition");
            },
            errorMessage: formError.actionCondition?.conditionType?.message ?? "",
          }}
          onnEventEvaluationRankSelectionFormProps={{
            values: watch("actionCondition.eventEvaluationRankIds"),
            onChange: (value) => {
              setValue("actionCondition.eventEvaluationRankIds", value, { shouldValidate: true });
            },
            errorMessage: formError.actionCondition?.eventEvaluationRankIds?.message ?? "",
          }}
          tagSelectionFormProps={{
            values: watch("actionCondition.tagIds"),
            onChange: (value) => {
              setValue("actionCondition.tagIds", value, { shouldValidate: true });
            },
            errorMessage: formError.actionCondition?.tagIds?.message ?? "",
          }}
          onnTaskQuestionAnswerSelectionFormProps={{
            questionSelector: {
              value: watch("actionCondition.questionId"),
              onChange: (value) => {
                setValue("actionCondition.questionId", value, { shouldValidate: true });
              },
              errorMessage: formError.actionCondition?.questionId?.message ?? "",
            },
            questionOptionSelector: {
              value: watch("actionCondition.questionOptionId"),
              onChange: (value) => {
                setValue("actionCondition.questionOptionId", value, { shouldValidate: true });
              },
              errorMessage: formError.actionCondition?.questionOptionId?.message ?? "",
            },
          }}
          triggerSetting={triggerSetting}
        />
        <DeliveryTimingForm
          checkBoxOfIsImmediateDeliveryProps={{
            value: watch("deliveryTiming.isImmediateDelivery"),
            onChange: (value) => {
              setValue("deliveryTiming.isImmediateDelivery", value, { shouldValidate: true });
              trigger("deliveryTiming");
            },
          }}
          selectorOfDeliveryTimingTypeProps={{
            value: watch("deliveryTiming.scheduledDeliveryTiming.type"),
            onClickItem: (value) => {
              setValue("deliveryTiming.scheduledDeliveryTiming.type", value, {
                shouldValidate: true,
              });
            },
            errorMessage: formError.deliveryTiming?.scheduledDeliveryTiming?.message ?? "",
          }}
          textFieldOfDeliveryTimingNumberProps={{
            value: watch("deliveryTiming.scheduledDeliveryTiming.timingNumber"),
            onChange: (value) => {
              setValue("deliveryTiming.scheduledDeliveryTiming.timingNumber", value, {
                shouldValidate: true,
              });
            },
            errorMessage:
              formError.deliveryTiming?.scheduledDeliveryTiming?.timingNumber?.message ?? "",
          }}
          textFieldOfDeliveryAtHourProps={{
            value: watch("deliveryTiming.scheduledDeliveryTiming.atHour"),
            onChange: (value) => {
              setValue("deliveryTiming.scheduledDeliveryTiming.atHour", value, {
                shouldValidate: true,
              });
            },
            errorMessage: formError.deliveryTiming?.scheduledDeliveryTiming?.atHour?.message ?? "",
          }}
        />
        <Button
          onClick={apply}
          fullWidth
          borderRadius="regular"
          variant="contained"
          color="primary"
          disabled={isApplyButtonDisabled}
        >
          適用
        </Button>
      </Box>
    </Popover>
  );
};
