import liff from "@line/liff";
import React, { useEffect } from "react";
import { Navigate, createSearchParams, useLocation } from "react-router-dom";

import { Loading } from "~/components/uiParts";

import { useLineAccessToken } from "~/hooks/context";
import { useRegistrationInfoByLiff } from "~/hooks/employee";
import { useRedirectToPortal } from "~/hooks/portal/redirect";
import { useQuery } from "~/hooks/shared";

/**
 * アカウント登録のためのページ
 * リッチメニューやシステム通知経由など、特定の招待URLが使われない場合に利用される
 *
 * - LIFF & 作成済みのアカウントが紐づいている: 個別招待ページ(招待トークンを用いる)へリダイレクト
 * - それ以外: 共通招待リンク用アカウント登録中間ページへリダイレクト
 *   (共通招待リンクルートの場合の制御は、中間ページの方で行う)
 */
export const AccountRegister = (): JSX.Element => {
  const { guardAndGetLineAccessTokenFromLiff } = useLineAccessToken();
  const { search } = useLocation();
  const { query } = useQuery();

  const { data: registrationInfo, isLoading: isLoadingRegistrationInfo } =
    useRegistrationInfoByLiff({
      liffAccessToken: liff.isInClient() ? guardAndGetLineAccessTokenFromLiff() : undefined,
    });

  const { redirectToPortal } = useRedirectToPortal();

  useEffect(() => {
    // アカウント登録前のシステム通知から、このページへ流入する場合がある。
    // そのため、エラー表示はせずにリダイレクトだけさせる。
    // またこのページにくる時点でLINEアカウントフォローは済んでいるため、LINEアカウントフォローページへ遷移させる必要はない
    if (registrationInfo && registrationInfo.employee.isRegistered()) {
      redirectToPortal();
    }
  }, [redirectToPortal, registrationInfo]);

  if (isLoadingRegistrationInfo) {
    return <Loading size="large" fullHeight />;
  }

  if (
    registrationInfo &&
    registrationInfo.employee.invitationToken &&
    registrationInfo.employee.isNotRegisteredAccountFromIndividualRegister()
  ) {
    return (
      <Navigate
        to={`/portal/accounts/invitations/${registrationInfo.employee.invitationToken}${search}`}
      />
    );
  }

  return (
    <Navigate
      to={`/portal/accounts/registration/line/registration_invitation_links?${createSearchParams(
        query
      )}`}
    />
  );
  // 個別招待ではない(=共通招待)でここに辿り着く場合、LINE版であることは確定している
};
