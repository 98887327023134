import { zodResolver } from "@hookform/resolvers/zod";
import { FormControlLabel, Stack } from "@mui/material";
import React, { FC, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

import styled from "styled-components";
import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { Checkbox, Typography } from "~/components/uiParts";

const inputSchema = z.object({ optionIds: z.array(z.string()) });
type InputState = z.infer<typeof inputSchema>;
type Props = {
  onSave: (data: InputState) => void;
  onCancel: () => void;
  optionIds: string[];
  options: { value: string; label: string }[];
};

export const CustomEmployeeInformationMultipleSelectTypeEditor: FC<Props> = ({
  onSave,
  onCancel,
  optionIds,
  options,
}) => {
  const { handleSubmit, control, setValue, getValues, formState } = useForm<InputState>({
    defaultValues: {
      optionIds,
    },
    resolver: zodResolver(inputSchema),
  });
  const { isValid, isDirty } = formState;

  // NOTE: checkboxの操作による変更でControllerのonChangeだと制御ができないので、手動で値を変更する
  const handleCheckboxChange = useCallback(
    (value: string) => {
      const prevValue = getValues("optionIds");
      setValue(
        "optionIds",
        prevValue.includes(value) ? prevValue.filter((id) => id !== value) : [...prevValue, value],
        { shouldDirty: true }
      );
    },
    [getValues, setValue]
  );

  return (
    <InformationValueEditor
      form={
        <form style={{ display: "flex", columnGap: 16 }} onSubmit={handleSubmit(onSave)}>
          <Controller
            control={control}
            name="optionIds"
            render={({ field }) => {
              return (
                <Stack gap={2}>
                  {options.map((option) => (
                    <StyledFormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <StyledCheckbox
                          color="primary"
                          checked={field.value.includes(option.value)}
                          onChange={() => handleCheckboxChange(option.value)}
                        />
                      }
                      label={<Typography variant="body2">{option.label}</Typography>}
                    />
                  ))}
                </Stack>
              );
            }}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!isValid || !isDirty}
    />
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
