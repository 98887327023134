import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Checkbox } from "../Checkbox";

import { Typography } from "~/components/uiParts/Typography";

type TableHeaderRowType =
  | {
      text: string;
    }
  | {
      onChangeCheckBox: () => void;
      checked: boolean;
      indeterminate: boolean;
    };

type Props = {
  widthOptions: string[];
  headers: TableHeaderRowType[];
};

export const TableHeaderRow: FC<Props> = ({ widthOptions, headers }) => {
  return (
    <StyledTableHeadRow display="flex" alignItems="center" height="100%" position="relative">
      {headers.map((header, index) => {
        if ("onChangeCheckBox" in header) {
          return (
            <StyledBox key={`TableHead-${index}`} width={widthOptions[index]}>
              <Checkbox
                indeterminate={header.indeterminate}
                checked={header.checked}
                onClick={() => header.onChangeCheckBox()}
                size="small"
              />
            </StyledBox>
          );
        } else {
          return (
            <Box key={`TableHead-${index}`} width={widthOptions[index]}>
              <Typography display="block" variant="overline" color="textSecondary" noWrap>
                {header.text}
              </Typography>
            </Box>
          );
        }
      })}
    </StyledTableHeadRow>
  );
};

const StyledTableHeadRow = styled(Box)`
  > div {
    &:first-child {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }

  &::after {
    content: "";
    background-color: ${(props) => props.theme.palette.grey[100]};
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 0%;
    height: 1px;
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
`;
