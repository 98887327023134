import { EMPLOYEE_ACTION_TYPE, IEmployeeActiveLogSchemaSchema } from "./schema";

export class EmployeeActiveLog implements IEmployeeActiveLogSchemaSchema {
  id: string;
  employeeId: string;
  type: EMPLOYEE_ACTION_TYPE;
  createdAt: Date;
  tenantId: string;
  targetId?: string;
  constructor({
    id,
    employeeId,
    type,
    createdAt,
    targetId,
    tenantId,
  }: {
    id: string;
    employeeId: string;
    type: EMPLOYEE_ACTION_TYPE;
    createdAt: Date;
    tenantId: string;
    targetId?: string;
  }) {
    this.id = id;
    this.employeeId = employeeId;
    this.type = type;
    this.createdAt = createdAt;
    this.tenantId = tenantId;
    this.targetId = targetId;
  }
}
