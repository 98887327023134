import { ListOnnEventsRequest, ListOnnEventsResponse } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { functionOperator } from "~/infrastructure/api/functionOperator";

const CF = "onnevent";

export const useAllOnnEvent = () => {
  const { currentUser } = useCurrentUser();

  const key: SWRKeyWhenCF<string> = {
    CF,
    actionType: ListOnnEventsRequest.actionType,
    args: currentUser.tenantId,
  };

  return useSWR(key, async ({ args: tenantId }) => {
    const response = await functionOperator.httpsCallFor2ndGen<
      ReturnType<typeof ListOnnEventsRequest.createRequestBody>,
      typeof ListOnnEventsResponse.ResponseBody
    >(CF, ListOnnEventsRequest.createRequestBody({ tenantId }));
    const { onnEvents } = ListOnnEventsResponse.convertUnixTimeToDate(response.data);

    return onnEvents;
  });
};
