import { Box, FormControlLabel } from "@material-ui/core";
import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "../hooks/useForm";

import { RadioButton, Typography } from "~/components/uiParts";
import { Tag } from "~/components/uiParts/Tag";

export const OnnEventFormatForm: FC = () => {
  const { control, setValue } = useFormContext<InputState>();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "eventType" });

  const isError = error !== undefined;

  return (
    <Box display="flex" flexDirection="column" gridRowGap={24}>
      <Box display="flex" alignItems="center" gridColumnGap={8}>
        <Typography variant="body1" color="textPrimary" bold>
          開催方法を選択
        </Typography>
        <Tag color="secondary" size="sm" text="必須" />
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        <StyledFormControlLabel
          value="online"
          control={
            <StyledRadioButton
              color={"primary"}
              checked={value === "online"}
              onChange={() => {
                setValue("eventPlaceId", null);
                setValue("selectedDate", null);
                setValue("onnEventSlotDateId", null);
                onChange("online");
              }}
            />
          }
          label={<Typography variant="body2">オンライン</Typography>}
        />
        <StyledFormControlLabel
          value="offline"
          control={
            <StyledRadioButton
              color={"primary"}
              checked={value === "offline"}
              onChange={() => {
                setValue("eventPlaceId", null);
                setValue("selectedDate", null);
                setValue("onnEventSlotDateId", null);
                onChange("offline");
              }}
            />
          }
          label={<Typography variant="body2">オフライン・現地</Typography>}
        />
      </Box>
      {isError && (
        <Typography variant="caption" color="secondary">
          {error.message}
        </Typography>
      )}
    </Box>
  );
};

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  margin-right: 8px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiButtonBase-root {
    padding: 2px 0px;
    margin-right: 8px;
  }
`;
