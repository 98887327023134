import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React from "react";

import { UserIcon } from "~/components/uiParts";

type IconListProps = {
  employees: Employee[];
};

export const IconList = ({ employees }: IconListProps): JSX.Element => {
  return (
    // NOTE: スクロールバーと重なってしまうため padding-bottom を設定
    <Box display="flex" gridGap="4px" flexWrap="wrap" paddingBottom="8px">
      {employees.map((employee) => (
        <UserIcon
          key={employee.id}
          circular
          size="small"
          username={employee ? employee.getName() : "未登録"}
          profileIconImageUrl={employee?.profileIconImageUrl}
          borderColor="primary"
        />
      ))}
    </Box>
  );
};
