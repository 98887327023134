import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useDryRunCreateNewGraduateWithCsv = () => {
  const dryRunCreateNewGraduateWithCsv = useCallback(
    async ({ base64EncodedCsvFile }: { base64EncodedCsvFile: string }) => {
      const requestBody: APISchema["/api/employee/create-new-graduates-with-csv"]["POST"]["body"] =
        {
          base64EncodedCsvFile,
          isDryRun: true,
        };

      const response = await apiClient.post(
        "/api/employee/create-new-graduates-with-csv",
        requestBody
      );
      return response;
    },
    []
  );

  return { dryRunCreateNewGraduateWithCsv };
};
