import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

export const EventDescriptionTypography: FC<{ description: string }> = ({ description }) => {
  return (
    <Typography variant="body2" color="textPrimary">
      {description}
    </Typography>
  );
};
