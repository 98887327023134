import React, { FC, ReactNode, useState, createContext, useCallback } from "react";

import { useLocalStorage } from "~/hooks/shared";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";

const TENANT_ID_KEY = "tenantId";

export const CurrentTenantIdContext = createContext<{
  currentTenantId: string;
  updateCurrentTenantId: (tenantId: string) => void;
  resetCurrentTenantId: () => void;
}>({
  currentTenantId: "",
  updateCurrentTenantId: () => void 0,
  resetCurrentTenantId: () => void 0,
});

const useDefaultTenantId = () => {
  const { retrieveValue, storeValue } = useLocalStorage();

  // NOTE: プレビューではサブドメインからテナントIDを取得できないため env で設定
  if (process.env.NODE_ENV === "preview") {
    return process.env.PREVIEW_TENANT_ID;
  }

  const tenantIdFromDomain = getTenantIdFromDomain();
  if (tenantIdFromDomain) {
    storeValue(TENANT_ID_KEY, tenantIdFromDomain);
    return tenantIdFromDomain;
  }

  const tenantIdFromStorage = retrieveValue<string>(TENANT_ID_KEY);
  return tenantIdFromStorage;
};

export const CurrentTenantIdProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { storeValue, removeValue } = useLocalStorage();

  const defaultValue = useDefaultTenantId();
  const [currentTenantId, setTenantId] = useState<string>(defaultValue ?? "");

  const updateCurrentTenantId = useCallback(
    (tenantId: string) => {
      if (tenantId) {
        setTenantId(tenantId);
        storeValue(TENANT_ID_KEY, tenantId);
      }
    },
    [storeValue]
  );

  const resetCurrentTenantId = useCallback(() => {
    setTenantId("");
    removeValue(TENANT_ID_KEY);
  }, [removeValue]);

  return (
    <CurrentTenantIdContext.Provider
      value={{ currentTenantId, updateCurrentTenantId, resetCurrentTenantId }}
    >
      {children}
    </CurrentTenantIdContext.Provider>
  );
};
