import { useState } from "react";

import { useMutateAnnouncements } from "../announcement/useAnnouncements";
import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useDeleteAnnouncementDistribution = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAnnouncements } = useMutateAnnouncements();

  const execDeleteAnnouncementDistribution = async (announcementDistributionId: string) => {
    try {
      await apiClient.delete("/announcement_api/announcements/distribution", {
        announcementDistributionId,
      });
      enqueueSnackbar("配信対象者を削除しました", { variant: "success" });
      return;
    } catch (e) {
      enqueueSnackbar("削除に失敗しました。管理者より連絡がくるまで、お待ちください。", {
        variant: "error",
      });
      captureException({
        error: e as Error,
        tags: {
          type: "useDeleteAnnouncementDistribution:execDeleteAnnouncementDistribution",
        },
      });
    } finally {
      mutateAnnouncements();

      setIsLoading(false);
    }
  };

  return { execDeleteAnnouncementDistribution, isLoading };
};
