import { FILE_PATH_NORMALIZATION_FORM } from "@onn/common";
import { useCallback } from "react";
import { v4 } from "uuid";

import { useTenant } from "~/hooks/tenant";

/**
 * リッチメニュー画像のGCSパスを生成するカスタムフック
 */
export const useGenerateRichMenuImageFilePath = () => {
  const {
    tenant: { tenantId },
  } = useTenant();
  const generateRichMenuImageFilePath = useCallback(() => {
    const uuidV4 = v4();
    return `tenants/${tenantId}/rich_menus/richmenu-${uuidV4}`.normalize(
      FILE_PATH_NORMALIZATION_FORM
    );
  }, [tenantId]);

  return { generateRichMenuImageFilePath };
};
