/**
 * モバイルのブラウザかどうかを判定
 * userAgentは2023/05にChromeから廃止予定のため、userAgentと画面幅を両方用いて判定する
 * userAgentは書き換えられていないことを前提とする
 * see: https://blog.devgenius.io/4-ways-to-detect-mobile-browsers-in-javascript-943b66657524
 */
export const isMobileBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const isUserAgentUnAvailable = userAgent === "" || userAgent == null;
  const isUserAgentMobile = /Mobile|Android/.test(userAgent);

  // NOTE: 767pxにした情報ソースは以下
  // - https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
  const isMobileLikeMediaQuery = window.matchMedia("(max-width: 767px)").matches;

  // userAgentが使えない場合のみisMobileLikeMaxTouchPointsが有効
  return isUserAgentMobile || (isUserAgentUnAvailable && isMobileLikeMediaQuery);
};
