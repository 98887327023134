import { onnEventSchema, isInvalidDate, CandidateDateWithNumberOfParticipants } from "@onn/common";
import { format, set } from "date-fns";
import { toNumber } from "lodash";

import { z } from "zod";

export const getOnnEventsFormSchema = ({
  isEdit,
  candidateDatesWithNumberOfParticipants = [],
}: {
  isEdit: boolean;
  candidateDatesWithNumberOfParticipants?: CandidateDateWithNumberOfParticipants[];
}) =>
  z
    .object({
      title: onnEventSchema.shape.title,
      content: onnEventSchema.shape.content,
      type: onnEventSchema.shape.type,
      hasCapacity: z.boolean(),
      isRestrictedAnswerFromNewGraduate: onnEventSchema.shape.isRestrictedAnswerFromNewGraduate,
      isRestrictedDaysAgoWhenAnswer: onnEventSchema.shape.isRestrictedDaysAgoWhenAnswer,
      isRestrictedEditAnswerFromNewGraduate:
        onnEventSchema.shape.isRestrictedEditAnswerFromNewGraduate,
      daysAgoRestrictAnswer: z.union([z.string(), z.number().int(), z.undefined()]),
      candidateDates: z
        .object({
          candidateDateId: z.string(),
          from: z.date().nullable(),
          until: z.date().nullable(),
          fromTime: z.number(),
          untilTime: z.number(),
          capacity: z.string().optional(),
        })
        .superRefine((value, ctx) => {
          // 1stリリースでは、編集できないのでバリデーションもしない
          if (isEdit && value.candidateDateId) return;
          if (value.from === null) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_date,
              message: "開始日時を入力してください",
              path: [`from`],
            });
            return z.NEVER;
          }
          if (isInvalidDate(value.from)) return;
          const today = set(new Date(), { hours: 0, minutes: 0, seconds: 1 });
          if (value.from < today) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_date,
              message: "候補日程は本日を含む未来日を設定してください",
              path: [`from`],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_date,
              message: "候補日程は本日を含む未来日を設定してください",
              path: [`until`],
            });
          }
        })
        .array()
        .superRefine((values, ctx) => {
          values.forEach((value, i, self) => {
            if (value.from === null || isInvalidDate(value.from)) return;
            if (value.until === null || isInvalidDate(value.until)) return;
            const fromFmt = format(value.from, "yyyy/MM/dd HH:mm");
            const untilFmt = format(value.until, "yyyy/MM/dd HH:mm");
            const dup = self.find((v, ii) => {
              if (i === ii) return false;
              if (v.from === null || isInvalidDate(v.from)) return false;
              if (v.until === null || isInvalidDate(v.until)) return false;
              const oFromFmt = format(v.from, "yyyy/MM/dd HH:mm");
              const oUntilFmt = format(v.until, "yyyy/MM/dd HH:mm");
              return fromFmt + untilFmt === oFromFmt + oUntilFmt;
            });
            if (dup) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "重複しています",
                path: [`${i}.from`],
              });
            }
          });
        }),
    })
    .superRefine((value, ctx) => {
      if (value.isRestrictedDaysAgoWhenAnswer) {
        const daysAgoRestrictAnswer = Number(value.daysAgoRestrictAnswer);
        if (!Number.isInteger(daysAgoRestrictAnswer) || daysAgoRestrictAnswer <= 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "1以上の整数を設定してください",
            path: ["daysAgoRestrictAnswer"],
          });
        }
      }
      if (value.hasCapacity) {
        value.candidateDates.forEach((candidateDate, i) => {
          if (candidateDate.capacity === "" || candidateDate.capacity === undefined) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "定員を入力してください",
              path: [`candidateDates.${i}.capacity`],
            });

            return z.NEVER;
          }

          if (isNaN(toNumber(candidateDate.capacity))) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "数値を入力してください",
              path: [`candidateDates.${i}.capacity`],
            });

            return z.NEVER;
          }

          if (!Number.isInteger(Number(candidateDate.capacity))) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "整数を入力してください",
              path: [`candidateDates.${i}.capacity`],
            });

            return z.NEVER;
          }

          try {
            onnEventSchema.shape.candidateDates.element
              .sourceType()
              .shape.capacity.parse(parseInt(candidateDate.capacity));
          } catch (err) {
            if (err instanceof z.ZodError) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: (err.issues[0] as (typeof err.issues)[number]).message,
                path: [`candidateDates.${i}.capacity`],
              });
              return z.NEVER;
            }
          }

          // 定員を編集する際は、参加人数を下回る値を設定できないようにする
          const targetCandidateDate = candidateDatesWithNumberOfParticipants.find(
            (c) => c.id === candidateDate.candidateDateId
          );
          if (targetCandidateDate) {
            const numberOfParticipants = targetCandidateDate.numberOfParticipants;
            if (parseInt(candidateDate.capacity) < numberOfParticipants) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `参加確定済み${numberOfParticipants}人より上の値を設定してください。`,
                path: [`candidateDates.${i}.capacity`],
              });
            }
          }

          return parseInt(candidateDate.capacity);
        });
      }
    });
