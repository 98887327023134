import { Stack } from "@mui/material";
import React, { useCallback } from "react";

import { Icon, Tooltip, Typography } from "~/components/uiParts";

export const useHeaderCellRenderer = () => {
  const headerCellRenderer = useCallback(({ columnIndex }: { columnIndex: number }) => {
    return cellNodes[columnIndex];
  }, []);

  return { headerCellRenderer };
};

const cellNodes = [
  <Stack height={"100%"} alignItems={"center"} justifyContent={"center"} key={0}>
    <Typography variant="body1" color="textPrimary" bold>
      スペース
    </Typography>
  </Stack>,
  <Stack
    height={"100%"}
    alignItems={"center"}
    justifyContent={"center"}
    flexDirection={"row"}
    padding={"8px"}
    columnGap={"8px"}
    key={1}
  >
    <Typography variant="body1" color="textPrimary" bold>
      辞退・不採用時の自動配信取消
    </Typography>
    <Tooltip
      title={
        <>
          辞退・不採用にした際に、シナリオ以外で配信したイベント・タスク・お知らせを自動で取り消すかを設定できます。
          <br />
          <br />
          有効にした場合、辞退・不採用にした際にシナリオ以外で配信したイベント・タスク・お知らせは自動で取り消されます。
        </>
      }
      placement="top-start"
    >
      <Icon icon="info" size="ssm" color="grey" />
    </Tooltip>
  </Stack>,
];
