import { OnnEventSlotDatesForDisplay } from "@onn/common";
import { useCallback } from "react";

export const useSortSlots = () => {
  const sortSlots = useCallback((slots: OnnEventSlotDatesForDisplay[]) => {
    const orderedOnnEventSlotDatesForDisplay = OnnEventSlotDatesForDisplay.sortForDisplay(slots);
    return orderedOnnEventSlotDatesForDisplay;
  }, []);

  return { sortSlots };
};
