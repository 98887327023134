import { useCallback, useState } from "react";

export const useAddEmployeeTagsActionModal = () => {
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

  const onChangeTagIds = useCallback((tagIds: string[]) => {
    setSelectedTagIds(tagIds);
  }, []);

  return {
    selectedTagIds,
    onChangeTagIds,
  };
};
