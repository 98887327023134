import { useLayoutEffect, useState } from "react";

const calculateContentHeight = (
  headerHeight: number,
  footerHeight: number,
  sumOfVerticalPadding: number,
  sumOfVerticalMargin: number
) => {
  // 画面の高さ
  const windowHeight = window.innerHeight;
  return windowHeight - headerHeight - footerHeight - sumOfVerticalPadding - sumOfVerticalMargin;
};

/**
 * モーダルのコンテンツの高さを計算するためのフック。中の要素の高さを可変にしたいときに使う
 */
export const useModalContentHeight = ({
  headerHeight,
  footerHeight,
  sumOfVerticalPadding,
  sumOfVerticalMargin,
}: {
  headerHeight: number;
  footerHeight: number;
  sumOfVerticalPadding: number;
  sumOfVerticalMargin: number;
}): {
  contentHeight: number;
} => {
  const [contentHeight, setContentHeight] = useState<number>(
    calculateContentHeight(headerHeight, footerHeight, sumOfVerticalPadding, sumOfVerticalMargin)
  );

  useLayoutEffect(() => {
    const handleResize = () => {
      setContentHeight(
        calculateContentHeight(
          headerHeight,
          footerHeight,
          sumOfVerticalPadding,
          sumOfVerticalMargin
        )
      );
    };
    window.addEventListener("resize", handleResize, false);
    return () => window.removeEventListener("resize", handleResize, false);
  }, [headerHeight, footerHeight, sumOfVerticalPadding, sumOfVerticalMargin]);

  return { contentHeight };
};
