import { Box } from "@material-ui/core";
import React from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";

import { ConductorPaperToAddCondition } from "../../_share_InvitationQR/ConductorPaperToAddCondition";

import { Button, Icon } from "~/components/uiParts";

export const ModalContent = ({
  isAddedCondition,
  registrationInvitationLinkUrl,
  onClickToAddCondition,
  onClickCopyLink,
  onClickDownloadQRCode,
  qrCodeDivRef,
}: {
  isAddedCondition: boolean;
  registrationInvitationLinkUrl: string;
  onClickToAddCondition: () => void;
  onClickCopyLink: () => void;
  onClickDownloadQRCode: () => void;
  qrCodeDivRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <Box display="flex" flexDirection="column" py="16px" textAlign="center">
      <ConductorPaperToAddCondition
        isAddedCondition={isAddedCondition}
        onClickToAddCondition={onClickToAddCondition}
      />
      <Box
        mt="32px"
        mb="24px"
        display="flex"
        justifyContent="center"
        height="200px"
        alignItems="center"
      >
        <div ref={qrCodeDivRef}>
          <QRCode
            style={{ height: "156px", width: "156px" }}
            value={registrationInvitationLinkUrl}
          />
        </div>
      </Box>
      <StyledButtonContainer>
        <Box width="166px">
          <Button
            variant="outlined"
            color="primary"
            borderRadius="regular"
            fullWidth
            onClick={onClickCopyLink}
            startIcon={<Icon icon="link" size="sm" color="primary" />}
          >
            招待URLをコピー
          </Button>
        </Box>
        <Box width="166px">
          <Button
            variant="outlined"
            color="primary"
            borderRadius="regular"
            onClick={onClickDownloadQRCode}
            startIcon={<Icon icon="download" size="sm" color="primary" />}
          >
            画像をダウンロード
          </Button>
        </Box>
      </StyledButtonContainer>
    </Box>
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 24px;
  justify-content: center;
  width: 100%;
`;
