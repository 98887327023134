import { LineStamp, allLineStamps } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export function generateSentLineStampMessagesKey({ tenantId }: { tenantId: string }) {
  return { url: `/api/contact-room/get-sent-line-stamps`, tenantId } as const;
}

export function useSentLineStamps({ tenantId }: { tenantId: string }) {
  return useSWR(generateSentLineStampMessagesKey({ tenantId }), async ({ url, tenantId }) => {
    const { data } = await apiClient.get(url, { tenantId });

    return data.stamps.map(
      (s) => allLineStamps.find((v) => v.stickerId === s.stickerId) as LineStamp
    );
  });
}
