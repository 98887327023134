import { ContactMessageTemplateFolderWithTemplates } from "@onn/common";
import React, { FC } from "react";

import { FolderMenuItem } from "./FolderMenuItem";

type Props = {
  contactMessageTemplateFolders: ContactMessageTemplateFolderWithTemplates[];
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
  selectedTemplateFolder: ContactMessageTemplateFolderWithTemplates | undefined;
};

export const FolderMenuItems: FC<Props> = ({
  contactMessageTemplateFolders,
  handleClick,
  selectedTemplateFolder,
}) => {
  return (
    <>
      {contactMessageTemplateFolders.map((folder) => (
        <FolderMenuItem
          id={folder.id}
          isSelected={selectedTemplateFolder?.id === folder.id}
          key={folder.id}
          folder={folder}
          handleClick={(e: React.MouseEvent<HTMLElement>) => {
            handleClick(e);
          }}
        />
      ))}
    </>
  );
};
