import { OnnTask, OnnFormTask } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

const generateOnnTasksKey = (employeeId: string, isPreview: boolean) => {
  return {
    endpoint: "/api/onn-task/get-onn-tasks-for-portal",
    employeeId: `${employeeId}`,
    isPreview,
  } as const;
};

export const useOnnTasksForPortal = ({
  employeeId,
  isPreview = false,
}: {
  employeeId: string;
  isPreview?: boolean;
}): SWRResponse<OnnTask[], Error> => {
  return useSWR(generateOnnTasksKey(employeeId, isPreview), async ({ endpoint, isPreview }) => {
    if (isPreview) return [];
    const response = await apiClient.get(endpoint);
    return response.data.map((d) => {
      switch (d.type) {
        case "FORM_TASK": {
          return new OnnFormTask(d);
        }
        default: {
          throw new Error(`${d.type} is not implemented yet`);
        }
      }
    });
  });
};
