import { AnnouncementDistribution } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export const useAnnouncementDistributionsByAnnouncementId = ({
  announcementId,
}: {
  announcementId?: string;
}) => {
  const key = announcementId
    ? (["/announcement_api/announcements/distributions", announcementId] as const)
    : null;
  return useSWR(key, async ([endpoint, announcementId]) => {
    const data = await apiClient.get(endpoint, { announcementId }).then((res) => res.data);

    return data.announcementDistributions.map(
      (announcementDistribution) => new AnnouncementDistribution(announcementDistribution)
    );
  });
};
