import { z } from "zod";

import { onnEventSchema } from "../../../../domain";

export const notifyOnnEventAnswerRemindByEventIdRequestSchema = z.object({
  tenantId: onnEventSchema.shape.tenantId,
  onnEventId: onnEventSchema.shape.id,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotifyOnnEventAnswerRemindByEventIdRequest
  extends z.infer<typeof notifyOnnEventAnswerRemindByEventIdRequestSchema> {}
