import { APISchema } from "@onn/common";

import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { useMutateScenarios } from "./useScenarios";

import { apiClient } from "~/libs";
import { ApiResponseError } from "~/util";

type EndPoint = APISchema["/scenario_api/duplicate-scenarios"]["POST"];

export const useDuplicateScenario = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateScenarios } = useMutateScenarios();

  const duplicateScenario = useCallback(
    async ({ scenarioId }: { scenarioId: string }) => {
      const body: EndPoint["body"] = { scenarioId };
      try {
        enqueueSnackbar("シナリオを複製しています。", {
          variant: "info",
        });
        await apiClient.post("/scenario_api/duplicate-scenarios", body);
        mutateScenarios();
        enqueueSnackbar("シナリオを複製しました。", {
          variant: "success",
        });
      } catch (e) {
        if (e instanceof ApiResponseError && e.status === 400) {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("シナリオの複製に失敗しました。管理者までお問い合わせください。", {
            variant: "error",
          });
        }
      }
    },
    [enqueueSnackbar, mutateScenarios]
  );

  return { duplicateScenario };
};
