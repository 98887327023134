import { RecruitmentStatusRevision } from "@onn/common";
import {
  CollectionReference,
  DocumentData,
  Timestamp,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

const COLLECTION_NAME = "recruitmentStatusRevisions";

type RecruitmentStatusRevisionForDB = Omit<
  ExcludeMethods<RecruitmentStatusRevision>,
  "createdAt" | "updatedAt"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export class RecruitmentStatusRevisionRepository {
  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private dbToObject({
    createdAt,
    updatedAt,
    ...rest
  }: RecruitmentStatusRevisionForDB): RecruitmentStatusRevision {
    return new RecruitmentStatusRevision({
      ...rest,
      createdAt: convertTimestampToDate(createdAt),
      updatedAt: convertTimestampToDate(updatedAt),
    });
  }

  listenRecruitmentStatusRevision({
    tenantId,
    spaceId,
    onChangedRecruitmentStatusRevision,
  }: {
    tenantId: string;
    spaceId: string;
    onChangedRecruitmentStatusRevision: (newRevision: RecruitmentStatusRevision) => void;
  }) {
    const q = query(
      this.collection(),
      where("tenantId", "==", tenantId),
      where("spaceId", "==", spaceId)
    );
    return onSnapshot(q, (snapshot) => {
      return snapshot.docChanges().forEach((change) => {
        const newRevision = this.dbToObject(change.doc.data() as RecruitmentStatusRevisionForDB);
        switch (change.type) {
          case "added":
          case "modified":
            return onChangedRecruitmentStatusRevision(newRevision);

          // 消えることはないので監視の対象外とする
          case "removed":
            return;
        }
      });
    });
  }
}
