import { Employee, NewGraduateToDisplay } from "@onn/common";
import useSWR, { KeyedMutator, useSWRConfig } from "swr";

import { apiClient } from "~/libs";

/**
 * @deprecated useEmployee以外のファイルでは使用せず,useGenerateMutateNewGraduateを使用する
 * 全て置き換えたらdeprecatedを外してexportを削除する
 */
export const generateUseNewGraduateKey = ({ newGraduateId }: { newGraduateId?: string }) => {
  return newGraduateId ? { url: "/api/employee/get-new-graduate" as const, newGraduateId } : null;
};
/**
 * idをもとにnewGraduateを返すswr
 */
export const useNewGraduate = ({ newGraduateId }: { newGraduateId?: string }) => {
  const keys = generateUseNewGraduateKey({ newGraduateId });

  return useSWR(
    keys,
    async ({ url, newGraduateId }) =>
      await apiClient
        .get(url, {
          id: newGraduateId,
        })
        .then(({ data }) => {
          if (!data) return null;
          return new NewGraduateToDisplay(
            Employee.castToNewGraduate(Employee.plainToInstance(data)),
            data.employeeTagIds,
            {
              id: data.recruitmentStatusId,
              label: data.recruitmentStatusLabel,
              type: data.recruitmentStatusType,
            },
            data.predictionId
          );
        })
  );
};

export const useGenerateMutateNewGraduate = () => {
  const { mutate } = useSWRConfig();

  const generateMutateNewGraduate: (
    newGraduateId: string
  ) => KeyedMutator<NewGraduateToDisplay | undefined> = (newGraduateId: string) => {
    return async (data, opts) => {
      const key = generateUseNewGraduateKey({ newGraduateId });
      if (!data) {
        return mutate(key);
      }
      return mutate(key, data, opts);
    };
  };

  return { generateMutateNewGraduate };
};
