import React, { FC } from "react";

export const ChevronDown: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_660_952)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6 8L12 12.6L7.4 8L6 9.4L12 15.4L18 9.4L16.6 8Z"
          stroke="none"
        />
      </g>
    </svg>
  );
};
