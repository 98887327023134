import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { mutate } from "swr";

import { generateOnnEventSlotDatesForDisplayKey } from "./useOnnEventSlotDatesForDisplay";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/onn_event_api/answer-on-behalf-to-exist-slot-date-with-csv"]["POST"];
export const useAnswerOnBehalfToExistedSlotDatesWithCsv = () => {
  const answerOnBehalfToExistedSlotDatesWithCsv = useCallback(
    async (arg: { onnEventId: string; base64EncodedCsvFile: string; isDryRun: boolean }) => {
      const requestBody: Endpoint["body"] = {
        onnEventId: arg.onnEventId,
        base64EncodedCsvFile: arg.base64EncodedCsvFile,
        isDryRun: arg.isDryRun,
      };
      const response = await apiClient.post(
        "/onn_event_api/answer-on-behalf-to-exist-slot-date-with-csv",
        requestBody
      );
      mutate(generateOnnEventSlotDatesForDisplayKey(arg.onnEventId));

      return response;
    },
    []
  );

  return { answerOnBehalfToExistedSlotDatesWithCsv };
};
