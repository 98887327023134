import { useCallback } from "react";

import { useFormContext } from "./useScenarioForm";

export const useGetTriggerFormIndex = () => {
  const { watch } = useFormContext();
  const inputState = watch();

  const getTriggerFormIndex = useCallback(
    ({ recruitStatusId, triggerId }: { recruitStatusId: string; triggerId: string }) => {
      const scenarioIndex = inputState.body.findIndex(
        (scenario) => scenario.recruitmentStatusId === recruitStatusId
      );
      const targetScenario = inputState.body[scenarioIndex];
      if (!targetScenario) {
        throw new Error("scenario not found");
      }
      const triggerIndex = targetScenario.blocks.findIndex(
        (block) => block.trigger.id === triggerId
      );

      return { scenarioIndex, triggerIndex };
    },
    [inputState.body]
  );

  return { getTriggerFormIndex };
};
