import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

type Props = {
  conditionDescription: string;
};

export const ActionCondition: FC<Props> = ({ conditionDescription }) => {
  return (
    <Root>
      <Typography color="textSecondary" variant="caption" component="div">
        {conditionDescription}
      </Typography>
    </Root>
  );
};

const Root = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
