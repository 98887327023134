import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent/OnnEvent/schema";

export const updateOnnEventRequestSchema = z.object({
  onnEventId: onnEventSchema.shape.id,
  updateObject: z
    .object({
      title: onnEventSchema.shape.title,
      content: onnEventSchema.shape.content,
      candidateDates: onnEventSchema.shape.candidateDates,
      isRestrictedAnswerFromNewGraduate: onnEventSchema.shape.isRestrictedAnswerFromNewGraduate,
      isRestrictedEditAnswerFromNewGraduate:
        onnEventSchema.shape.isRestrictedEditAnswerFromNewGraduate,
    })
    .or(
      z.object({
        assigneeIds: onnEventSchema.shape.assigneeIds,
      })
    ),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdateOnnEventRequestSchema extends z.infer<typeof updateOnnEventRequestSchema> {}
