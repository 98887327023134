import React, { FC } from "react";

export const ChevronUp: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_660_968)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.4 15.4L12 10.8L16.6 15.4L18 14L12 8.00002L6 14L7.4 15.4Z"
          stroke="none"
        />
      </g>
    </svg>
  );
};
