import { APISchema, Reminder } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

/**
 * 未完了のリマインダー一覧を取得するhooks
 * - 最新3件のみ
 * - リマインド日時昇順
 */
export const useInCompletedReminders = ({
  tenantId,
  contactRoomId,
}: {
  tenantId: string;
  contactRoomId: string;
}): SWRResponse<Reminder[], Error> & { refetchKey: Key } => {
  const queryParams: Query = {
    "contact-room-id": contactRoomId,
    "is-completed": false,
    limit: 3,
    sort: [{ key: "remindedAt", direction: "asc" }],
  };
  const keyObj = { name: `reminders-${tenantId}`, ...queryParams };

  const asyncJob = async (): Promise<Reminder[]> => {
    const response = await apiClient.get(`/reminder_api/reminders`, queryParams);
    return response.data.map((d) => new Reminder(d));
  };

  return { ...useSWR(keyObj, asyncJob), refetchKey: keyObj };
};

type Query = APISchema["/reminder_api/reminders"]["GET"]["query"];
export type Key = Query & { name: string };
