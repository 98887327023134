import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { AllContactRoom, LatestContactMessage } from "@onn/common";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { RoomIconV2 } from "./parts/RoomIconV2";

import { Chip, Icon, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useTenant } from "~/hooks/tenant";

type Props = {
  contactRoom: AllContactRoom;
  isSelected: boolean;
  onClickContactRoomListItem: () => void;
  unreadCount: number;
  isLoadingLatestMessages?: boolean;
  latestMessage?: LatestContactMessage;
  isPinned?: boolean;
};

export const ContactRoomListItemV2: FC<Props> = ({
  contactRoom,
  isSelected,
  onClickContactRoomListItem,
  unreadCount,
  isLoadingLatestMessages,
  latestMessage,
  isPinned,
}) => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();
  const LatestMessageTypography = useMemo(() => {
    const latestMessageContent = (() => {
      if (latestMessage?.type === "system") return latestMessage.displayLabel;
      if (latestMessage?.type === "contact") {
        const prefix = currentUser.id === latestMessage?.createdEmployeeId ? "あなた：" : "";
        return prefix + latestMessage.displayLabel;
      }
      return "メッセージがありません";
    })();

    return (
      <Typography
        variant="caption"
        color={unreadCount > 0 ? "textPrimary" : "textSecondary"}
        bold={unreadCount > 0}
        noWrap
      >
        {latestMessageContent}
      </Typography>
    );
  }, [currentUser.id, latestMessage, unreadCount]);

  const displayDate = (() => {
    if (latestMessage instanceof LatestContactMessage) {
      return latestMessage.getDisplayDate();
    }
    return "";
  })();

  const roomName = contactRoom.getRoomName();

  return (
    <StyledBox
      key={contactRoom.id}
      height="80px"
      width="100%"
      p="12px 24px"
      display="flex"
      alignItems="center"
      gridGap="16px"
      onClick={onClickContactRoomListItem}
      $isSelected={isSelected}
    >
      {/* NONE: 中途向けの場合 Badge は表示しない */}
      <RoomIconV2 contactRoom={contactRoom} isDisplayBadge={tenant.isActiveNewGraduate} />
      <Box display="flex" width="100%" flexDirection="column" gridGap="8px" overflow="auto">
        <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="8px">
          <Box display="flex" alignItems="center" gridGap="8px" overflow="hidden">
            {isPinned && <Icon icon="pin" size="sm" color="primary" />}
            <Typography bold variant="body1" color="textPrimary" noWrap>
              {roomName}
            </Typography>
          </Box>
          <StyledTypography variant="overline" color="textSecondary" noWrap>
            {displayDate}
          </StyledTypography>
        </Box>
        <StyledMessageWrapper>
          {isLoadingLatestMessages ? (
            <Box width={"100%"}>
              <Skeleton variant="text" />
            </Box>
          ) : (
            LatestMessageTypography
          )}
          {unreadCount > 0 && <StyledChip color="secondary" label={unreadCount.toString()} bold />}
        </StyledMessageWrapper>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)<{ $isSelected: boolean }>`
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
  ${(props) => props.$isSelected && `background-color: ${props.theme.palette.grey[50]}`}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    overflow: visible;
  }
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: fit-content;
    width: fit-content;
    font-size: 12px;
    padding: 0 6px;

    & > .MuiChip-label {
      padding: 0;
    }
  }
`;

const StyledMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`;
