import { Box } from "@mui/material";
import { NewGraduateToDisplay, NewGraduateToListView } from "@onn/common";
import React, { ComponentProps, FC } from "react";

import styled from "styled-components";

import { ChangeEmployeePredictionSelector } from "~/components/domains/employeePrediction/ChangeEmployeePredictionSelector";
import { ChangeRecruitmentStatusSelector } from "~/components/domains/recruitmentStatus/ChangeRecruitmentStatusSelector";
import { Typography } from "~/components/uiParts";
import { OnEditEmployeePredictionRelation } from "~/hooks/employeePrediction/useEditEmployeePredictionRelations";

type Props = {
  newGraduate: NewGraduateToDisplay;
  newGraduateToListView?: NewGraduateToListView;
  onUpdateRecruitmentStatus?: ComponentProps<
    typeof ChangeRecruitmentStatusSelector
  >["onUpdateRecruitmentStatus"];
  onEditEmployeePredictionRelation?: OnEditEmployeePredictionRelation;
  selectorWidth?: string;
  disabled?: boolean;
};

export const NewGraduateRecruitmentStatusAndPrediction: FC<Props> = ({
  newGraduate,
  newGraduateToListView,
  disabled,
  onUpdateRecruitmentStatus,
  onEditEmployeePredictionRelation,
  selectorWidth,
}) => {
  const scenarioName = "共通";
  // TODO[複数シナリオ]: DBから取得したシナリオ名を表示する
  return (
    <Root>
      <ChangeSelectorWrapper
        title={scenarioName}
        selectorComponent={
          <ChangeRecruitmentStatusSelector
            newGraduate={newGraduate}
            newGraduateToListView={newGraduateToListView}
            disabled={disabled}
            onUpdateRecruitmentStatus={onUpdateRecruitmentStatus}
          />
        }
        selectorWidth={selectorWidth}
      />
      <ChangeSelectorWrapper
        title="ヨミ"
        selectorComponent={
          <ChangeEmployeePredictionSelector
            newGraduate={newGraduate}
            newGraduateToListView={newGraduateToListView}
            disabled={disabled}
            onEditEmployeePredictionRelation={onEditEmployeePredictionRelation}
          />
        }
        selectorWidth={selectorWidth}
      />
    </Root>
  );
};

const ChangeSelectorWrapper: FC<{
  title: string;
  selectorComponent: React.ReactElement;
  selectorWidth?: string;
}> = ({ title, selectorComponent, selectorWidth }) => {
  return (
    <Box
      width={selectorWidth || "50%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="overline" color="textPrimary">
        {title}
      </Typography>
      {selectorComponent}
    </Box>
  );
};

const Root = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
