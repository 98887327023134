import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import { format } from "date-fns";
import React, { useCallback, FC } from "react";
import styled from "styled-components";

import { IconButtonWithManageMemberMenu } from "./TableRowItems/IconButtonWithManageMemberMenu";

import { Chip, Typography, UserIconWithLabel } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useCurrentUser, useDeleteEmployee, useResendInvitationToSlack } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

type OrderType = "asc" | "desc";

type Props = {
  isLoading: boolean;
  employees: Employee[];
  mutateEmployees: () => void;
  order: OrderType;
  onClickSortLastRefreshTime: () => void;
};

export const MemberTable: FC<Props> = ({
  isLoading,
  employees,
  mutateEmployees,
  order,
  onClickSortLastRefreshTime,
}) => {
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();
  const { resendInvitationToSlack } = useResendInvitationToSlack();
  const { deleteEmployee } = useDeleteEmployee();

  const handleClickResendInvitationButton = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "resendInvitationModal",
        args: {
          employee,
          onSubmit: async () => {
            await resendInvitationToSlack(employee.id);
          },
        },
      });
    },
    [handleModal, resendInvitationToSlack]
  );

  const handleDeleteEmployeeButton = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "deleteMemberModal",
        args: {
          profileIconImageUrl: employee.profileIconImageUrl,
          username: employee.getName(),
          onSubmit: async () => {
            await deleteEmployee(employee, employees).then(() => {
              mutateEmployees();
            });
          },
        },
      });
    },
    [deleteEmployee, employees, handleModal, mutateEmployees]
  );

  const widthOptions = ["55%", "15%", "15%", "15%"];

  return (
    <VirtualizedTableV2<Employee>
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={[
        {
          text: "ユーザー",
        },
        {
          text: "権限",
        },
        {
          text: "最終ログイン",
          order,
          onSort: onClickSortLastRefreshTime,
        },
        {
          text: "",
        },
      ]}
      rows={employees}
      rowRenderer={({ key, index, style, rowData: employee }) => {
        const contents = [
          <Box display="flex" key={`userIconWithLabel-${employee.id}`}>
            <UserIconWithLabel
              iconPath={employee.profileIconImageUrl || ""}
              name={employee.getName()}
              secondaryText={employee.email}
              iconCircular
            />
          </Box>,
          <Typography key={`displayRole-${employee.id}`} variant="caption" color="textSecondary">
            {employee.getDisplayRole()}
          </Typography>,
          <>
            {employee.uid ? (
              <Typography
                key={`lastRefreshTime-${employee.id}`}
                variant="caption"
                color="textSecondary"
              >
                {employee.lastRefreshTime ? format(employee.lastRefreshTime, "yyyy/MM/dd") : ""}
              </Typography>
            ) : (
              <StyledChipContainer>
                <Chip color="grey" label="招待中" />
              </StyledChipContainer>
            )}
          </>,
          <Box key={`menuButton-${employee.id}`} display="flex" justifyContent="center">
            <IconButtonWithManageMemberMenu
              isDisplayResendInvitationButton={!employee.isNewcomer() && !employee.uid}
              onClickResendInvitationButton={() => handleClickResendInvitationButton(employee)}
              isDisplayDeleteEmployeeButton={
                currentUser.isAdmin() && currentUser.id !== employee.id
              }
              onClickDeleteEmployeeButton={() => handleDeleteEmployeeButton(employee)}
            />
          </Box>,
        ];
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
              }}
              widthOptions={widthOptions}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

const StyledChipContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 62px;
`;
