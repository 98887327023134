import { useMemo } from "react";

import { useOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";

/**
 * 選択可能な予約枠を取得する
 * ※ 予約枠が非公開、募集締切の場合は除外する。
 * ※ 予約枠が埋まっている場合は除外する。ただしincludeIdsRegardlessOfFullに含まれるものは除外しない
 */
export const useSelectableOnnEventSlotDatesForDisplay = ({
  onnEventId,
  includeIdsRegardlessOfFull,
  briefingSessionCategoryId,
}: {
  onnEventId: string;
  includeIdsRegardlessOfFull?: string[];
  briefingSessionCategoryId: string | null;
}) => {
  const { data: onnEventSlotDatesForDisplay, isLoading } =
    useOnnEventSlotDatesForDisplay(onnEventId);

  const selectableOnnEventSlotDatesForDisplay = useMemo(() => {
    if (!onnEventSlotDatesForDisplay) return [];
    return onnEventSlotDatesForDisplay.filter((v) => {
      if (briefingSessionCategoryId && briefingSessionCategoryId !== v.briefingSessionCategoryId) {
        return false;
      }
      if (includeIdsRegardlessOfFull && includeIdsRegardlessOfFull.includes(v.id)) return true;
      return v.isReservable();
    });
  }, [briefingSessionCategoryId, includeIdsRegardlessOfFull, onnEventSlotDatesForDisplay]);

  const selectableOnnEventSlotDatesForDisplayMap = new Map(
    selectableOnnEventSlotDatesForDisplay.map((v) => [v.id, v])
  );

  return {
    selectableOnnEventSlotDatesForDisplay,
    selectableOnnEventSlotDatesForDisplayMap,
    isLoading,
  };
};
