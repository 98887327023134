import React from "react";

import { Button, Icon } from "~/components/uiParts";

type Props = {
  onClick(): void;
};
export const AddConditionButton = ({ onClick }: Props): JSX.Element => {
  return (
    <Button onClick={onClick} color="default" variant="outlined" borderRadius="regular">
      <Icon icon="add" color="grey" size="sm" />
      条件を追加
    </Button>
  );
};
