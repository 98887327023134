import { Employee } from "@onn/common";
import { useMemo } from "react";

import { useAllEmployees } from "~/hooks/employee";

export const useEmployeeIdToEmployeeMap = () => {
  const { allEmployees } = useAllEmployees();
  const employeeIdToEmployeeMap = useMemo(() => {
    return new Map<string, Employee>(allEmployees.map((employee) => [employee.id, employee]));
  }, [allEmployees]);

  return { employeeIdToEmployeeMap };
};
