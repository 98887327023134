import { Chip as MuiChip } from "@material-ui/core";
import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts";

export type AnswerStatus =
  | "unregistered_attendance_and_past"
  | "unregistered_attendance_and_not_past"
  | "absent_attendance"
  | "registered_attendance"
  | "answer_to_unable_to_participate_all_candidates";
type Props = {
  status: AnswerStatus;
  openMenu: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  type?: "RecruitmentProcessTab";
};

export const AnswerStatusChip: FC<Props> = ({ status, openMenu, disabled, type }) => {
  switch (status) {
    case "unregistered_attendance_and_past":
      return (
        <StyledSecondaryChip
          label={"参加未登録"}
          icon={disabled ? undefined : <Icon icon="dropdownArrow" color="secondary" size="sm" />}
          onClick={disabled ? undefined : openMenu}
        />
      );

    case "unregistered_attendance_and_not_past": {
      if (type === "RecruitmentProcessTab") return <StyledOutlinePrimaryChip label={"回答済み"} />;
      return <StyledBlueChip label={"回答済み"} />;
    }

    case "absent_attendance":
      return (
        <StyledGreyChip
          label={"不参加"}
          icon={disabled ? undefined : <Icon icon="dropdownArrow" color="white" size="sm" />}
          onClick={disabled ? undefined : openMenu}
        />
      );
    case "registered_attendance":
      return (
        <StyledPrimaryChip
          label={"参加済み"}
          icon={disabled ? undefined : <Icon icon="dropdownArrow" color="white" size="sm" />}
          onClick={disabled ? undefined : openMenu}
        />
      );
    case "answer_to_unable_to_participate_all_candidates":
      return <StyledBlueChip label={"回答済み"} />;
    default: {
      const _exhaustiveCheck: never = status;
      return null;
    }
  }
};

const StyledChipBase = styled(MuiChip)`
  &.MuiChip-root {
    > .MuiChip-icon {
      order: 1; // アイコンと文字列の表示順を入れ替える
      margin-left: -12px;
      margin-right: 4px;
    }
    height: 32px;
    font-size: 14px;
    font-weight: 700;
  }
`;

const StyledBlueChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: ${(props) => props.theme.palette.blue.main};
    border: 1px solid ${(props) => props.theme.palette.blue.main};
    color: white;
    :hover {
      background-color: ${(props) => props.theme.palette.blue.dark};
    }
  }
`;

const StyledSecondaryChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: white;
    border: 1px solid ${(props) => props.theme.palette.secondary.main};
    color: ${(props) => props.theme.palette.secondary.main};
    :hover {
      background-color: ${(props) => props.theme.palette.secondary.light};
    }
  }
`;

const StyledGreyChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: ${(props) => props.theme.palette.grey[300]};
    border: 1px solid ${(props) => props.theme.palette.grey[300]};
    color: white;
    :hover {
      background-color: ${(props) => props.theme.palette.grey[400]};
    }
  }
`;

const StyledPrimaryChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: ${(props) => props.theme.palette.primary.main};
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    color: white;
    :hover {
      background-color: ${(props) => props.theme.palette.primary.dark};
    }
  }
`;

const StyledOutlinePrimaryChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: ${(props) => props.theme.palette.common.white};
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
