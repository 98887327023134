import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { useMemo, useState } from "react";

import { AnyTarget } from "../../../types/condition";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  target: AnyTarget;
  onChange(target: AnyTarget): void;
  onnEventType: Extract<OnnEvent["type"], "briefing_session" | "new_interview">;
};
export const SelectTargetButton = ({ target, onChange, onnEventType }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const targetLabelMap = useMemo(() => {
    switch (onnEventType) {
      case "briefing_session":
        return new Map<AnyTarget, string>([
          ["slotFromDateRange", "開催日時"],
          ["briefingSessionCategoryIds", "説明会"],
        ]);
      case "new_interview":
        return new Map<AnyTarget, string>([["slotFromDateRange", "開催日時"]]);
      default: {
        const _exhaustiveCheck: never = onnEventType;
        return _exhaustiveCheck;
      }
    }
  }, [onnEventType]);

  return (
    <>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Box flex={1} display={"flex"}>
          <Typography variant="body2" noWrap>
            {targetLabelMap.get(target) ?? "対象を選択"}
          </Typography>
        </Box>
      </Button>
      <Menu
        key={"selectLogicTypeButton"}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {[...targetLabelMap.entries()].map(([target, label]) => (
          <Box key={target}>
            <MenuItem key={target} onClick={() => onChange(target)}>
              <Typography variant="body2">{label}</Typography>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </>
  );
};
