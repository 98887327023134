import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";
import theme from "~/config/theme";

type Props = {
  activeStep: number;
  labels: string[];
};

export const VerticalStepper: FC<Props> = ({ activeStep, labels }) => {
  return (
    <Box width="100px" position="relative">
      {labels.map((label, index) => {
        return (
          <StyledBox
            position="absolute"
            key={index}
            $borderHeight={80}
            top={index * 80}
            right={0}
            $borderColor={
              activeStep - 1 > index ? theme.palette.primary.main : theme.palette.grey[100]
            }
          >
            <Box display="flex" gridGap="12px" justifyContent="between" alignItems="center">
              <Box textAlign="right" display="flex" flexDirection="column">
                <Typography bold noWrap color="textSecondary" variant="caption">
                  Step{index + 1}
                </Typography>
                <Typography noWrap variant="caption">
                  {label}
                </Typography>
              </Box>
              {activeStep - 1 < index && (
                <Box>
                  <StyledGrayCircle />
                </Box>
              )}
              {activeStep - 1 === index && (
                <Box>
                  <StyledPrimaryCircle />
                </Box>
              )}
              {activeStep - 1 > index && (
                <StyledCheckboxWrapperBox textAlign="center" alignItems="center">
                  <Icon color="white" icon="check" size="sm" />
                </StyledCheckboxWrapperBox>
              )}
            </Box>
          </StyledBox>
        );
      })}
    </Box>
  );
};

const StyledPrimaryCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    background-color: white;
  }
`;

const StyledGrayCircle = styled(Box)`
  &.MuiBox-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.grey[100]};
    background-color: white;
  }
`;

const StyledCheckboxWrapperBox = styled(Box)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  svg {
    width: 14px;
    height: 14px;
  }
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledBox = styled(Box)<{ $borderHeight: number; $borderColor: string }>`
  :not(:last-child)::after {
    width: 2px;
    right: 9px;
    position: absolute;
    height: ${(props) => props.$borderHeight}px;
    content: "" "";
    top: 28px;
    background-color: ${(props) => props.$borderColor};
  }
`;
