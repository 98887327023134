import { Action } from "@onn/common";
import { useCallback } from "react";

import { DeliveryTimingInput } from "../components/deliveryTimingInputSchema";

import { deliveryTimingDefaultValue } from "./defaultValue/deliveryTimingDefaultValue";

export const useGenerateDeliveryTimingDefaultValue = () => {
  const generateDeliveryTimingDefaultValue = useCallback(
    ({ existingAction }: { existingAction: Action }): DeliveryTimingInput["deliveryTiming"] => {
      const existingTiming = existingAction.getExecutionTiming();
      switch (existingTiming.type) {
        case "immediate": {
          return deliveryTimingDefaultValue;
        }
        case "days": {
          return {
            isImmediateDelivery: false,
            scheduledDeliveryTiming: {
              type: "days",
              timingNumber: existingTiming.executionTimeAfterDays,
              atHour: existingTiming.executionTimeAtHours,
            },
          };
        }
        case "minutes": {
          const scheduledDeliveryTiming = (() => {
            if (existingTiming.executionTimeWithinMinutes % 60 === 0) {
              return {
                type: "hours",
                timingNumber: existingTiming.executionTimeWithinMinutes / 60,
                atHour: deliveryTimingDefaultValue.scheduledDeliveryTiming.atHour,
              } as const;
            } else {
              return {
                type: "minutes",
                timingNumber: existingTiming.executionTimeWithinMinutes,
                atHour: deliveryTimingDefaultValue.scheduledDeliveryTiming.atHour,
              } as const;
            }
          })();
          return {
            isImmediateDelivery: false,
            scheduledDeliveryTiming,
          };
        }
      }
    },
    []
  );

  return { generateDeliveryTimingDefaultValue };
};
