import { z } from "zod";

export const AnnouncementDistributionScalarFieldEnumSchema = z.enum([
  "id",
  "tenantId",
  "announcementId",
  "employeeId",
  "firstReadAt",
  "lastReadAt",
  "readDates",
  "createdAt",
]);

export default AnnouncementDistributionScalarFieldEnumSchema;
