import { TextField as MuiTextField, InputAdornment, InputBaseProps } from "@material-ui/core";
import React, { FC, ComponentProps, ChangeEvent } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type MuiTextFieldProps = ComponentProps<typeof MuiTextField>;

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  variant?: Exclude<MuiTextFieldProps["variant"], "filled">;
  startAdornment?: InputBaseProps["startAdornment"];
  endAdornment?: InputBaseProps["endAdornment"];
  maxTextCount?: number;
} & Pick<
  MuiTextFieldProps,
  | "value"
  | "type"
  | "size"
  | "autoComplete"
  | "autoFocus"
  | "label"
  | "helperText"
  | "error"
  | "fullWidth"
  | "placeholder"
  | "disabled"
  | "minRows"
  | "variant"
  | "multiline"
  | "name"
  | "onFocus"
  | "onBlur"
  | "inputProps"
  | "inputRef"
  | "defaultValue"
>;

/**
 * @deprecated
 */
export const TextFieldDeprecated: FC<Props> = React.forwardRef<HTMLInputElement, Props>(
  ({ value, startAdornment, endAdornment, maxTextCount, variant, size, ...rest }, ref) => {
    return (
      <StyledMuiTextField
        value={value}
        variant={variant}
        margin="none"
        size={size ?? "medium"}
        InputProps={{
          ref,
          ...(startAdornment
            ? { startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment> }
            : {}),
          ...(endAdornment || maxTextCount
            ? {
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      {maxTextCount && typeof value === "string" && (
                        <StyledTypography variant="caption">
                          {value.length}/{maxTextCount}
                        </StyledTypography>
                      )}
                      {endAdornment}
                    </InputAdornment>
                  </>
                ),
              }
            : {}),
        }}
        {...rest}
      />
    );
  }
);

const StyledMuiTextField = styled(MuiTextField)`
  color: ${(props) => props.theme.palette.text.primary};

  .MuiInputBase-input {
    font-weight: normal;
    font-size: ${({ size }) => (size === "small" ? `14px` : `16px`)};
  }

  .MuiFormHelperText-root {
    font-size: 10px;
  }
`;

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
