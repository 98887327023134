import { useCallback } from "react";

import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";

// 以下のページはレスポンシブで表示する
const SP_PAGES_PATHS = [
  "/account", // 登録画面
  "/signin", // サインイン
  "/reset_email", // メールアドレスリセット
  "/reset_password", // パスワードリセット
  "/portal", // ポータル全体
  "/settings/account", // アカウント設定
  "/messages", // メッセージ記入
  "/complete", // 完了(サンクス)ページ
  "/profile", // プロフィール作成
  "/contact_rooms", // コンタクトルーム
  "/share_invite", // 共通QRコード
  "/change_authentication", // 認証切り替え画面
];

/**
 *  * スマートフォン向けのUIが用意されているページは、viewportをwidth=device-widthにする
 *  * それ以外のページは、PC向けのUIしか用意されていないので、デバイスに関わらずviewportを1280pxで固定する
 */
export const useSwitchViewport = () => {
  const metaElement = document.querySelector("meta[name='viewport']") as Element;

  // 任意のタイミングでの実行
  const switchViewport = useCallback(
    (pathname: string) => {
      const isNewGraduateOrigin = !!getTenantIdFromDomain();
      const currentPathname = pathname ?? location.pathname;
      let viewportContent;

      // NOTE: 新卒向け画面の場合は全てレスポンシブ対応しているので、viewportをwidth=device-widthにする
      if (isNewGraduateOrigin || SP_PAGES_PATHS.some((path) => currentPathname.includes(path))) {
        viewportContent = "width=device-width";
      } else {
        viewportContent = "width=1280";
      }
      metaElement.setAttribute("content", viewportContent);
    },
    [metaElement]
  );

  return { switchViewport };
};
