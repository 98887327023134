import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { TriggerDescriptionArea } from "../components/TriggerBlock/TriggerDescriptionArea/TriggerDescriptionArea";
import { useErrorTargetText } from "../components/TriggerBlock/useErrorTargetText";

import { TriggerBlockWidth } from "../constant/constant";
import { TriggersApiResponse } from "../type";

import { ActionAreaForViewMode } from "./ActionAreaForViewMode";
import { useIsActionRelatedDeletedStatus } from "./hooks/useIsActionRelatedDeletedStatus";
import { useIsTriggerRelatedDeletedStatus } from "./hooks/useIsTriggerRelatedDeletedStatus";
import { useDescriptionForViewMode } from "./useDescriptionForViewMode";

import { Icon, Typography } from "~/components/uiParts";

type Props = {
  trigger: TriggersApiResponse[0];
};

export const TriggerBlockForViewMode: FC<Props> = (props) => {
  const { isTriggerRelatedDeletedStatus } = useIsTriggerRelatedDeletedStatus({
    trigger: props.trigger,
  });
  const { isActionRelatedDeletedStatus } = useIsActionRelatedDeletedStatus({
    trigger: props.trigger,
  });

  const { description, triggerSubDescription } = useDescriptionForViewMode(props);
  const { errorTargetText } = useErrorTargetText({
    isTriggerRelatedDeletedStatus,
    isActionRelatedDeletedStatus,
  });

  return (
    <Root $isError={isTriggerRelatedDeletedStatus}>
      <TriggerDescriptionArea
        type={props.trigger.triggerSetting.type}
        triggerSettingId={props.trigger.triggerSetting.id}
        waitingTriggerBlockCount={props.trigger.waitingTriggerBlockCount}
        triggerId={props.trigger.id}
        actionCount={props.trigger.actions.length}
        actionConditionCount={props.trigger.actions.filter((action) => action.condition).length}
        triggerDescription={description}
        triggerSubDescription={triggerSubDescription}
        isError={isTriggerRelatedDeletedStatus}
        isEditMode={false}
      />
      {props.trigger.actions.map((action) => (
        <ActionAreaForViewMode
          key={action.id}
          triggerId={props.trigger.id}
          recruitmentStatusId={props.trigger.recruitmentStatusId}
          action={action}
        />
      ))}
      {errorTargetText && (
        <Box display="flex" gridColumnGap="8px" alignItems="center">
          <Icon icon="alert" size="md" color="secondary" />
          <Typography variant="caption" color="secondary">
            {`${errorTargetText}に削除された「選考ステータス」が含まれています。\nエラーマークが表示されている箇所の条件や設定を編集してください。`}
          </Typography>
        </Box>
      )}
    </Root>
  );
};

const Root = styled(Box)<{ $isError: boolean }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border: 2px solid
    ${(props) =>
      props.$isError ? props.theme.palette.secondary.main : props.theme.palette.primary.main};
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.common.white};
  width: ${TriggerBlockWidth}px;
`;
