import { employeePredictionSchema } from "@onn/common";
import { z } from "zod";

const inputSchema = z.object({
  id: employeePredictionSchema.shape.id,
  label: employeePredictionSchema.shape.label,
});

export const formSchema = z.array(inputSchema).superRefine((array, ctx) => {
  array.forEach((value, index) => {
    const sameLabelValues = array.filter((v) => v.label === value.label);
    if (1 < sameLabelValues.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "名前が重複しています。別の名前を入力してください。",
        path: [index, "label"],
      });
    }
  });
});

export type InputState = { employeePredictions: z.infer<typeof formSchema> };
