import { useMemo, useState } from "react";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";

/**
 * 名前・メールアドレスによる絞り込みと結果
 */
export const useFilterByNameOrEmail = (
  onnEventAnswersWithEmployee: OnnEventAnswerWithEmployee[]
) => {
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const [searchValue, setSearchValue] = useState("");

  const answersFilteredBySearchValue = useMemo(() => {
    return onnEventAnswersWithEmployee
      ? filterObjectByPartialMatch({
          objects: onnEventAnswersWithEmployee,
          searchText: searchValue,
          getProperties: [
            (onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee) =>
              onnEventAnswerWithEmployee.employee.getName(),
            (onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee) =>
              onnEventAnswerWithEmployee.employee.email,
          ],
        })
      : [];
  }, [onnEventAnswersWithEmployee, searchValue, filterObjectByPartialMatch]);

  return {
    searchValue,
    setSearchValue,
    answersFilteredBySearchValue,
  };
};
