import { FormControlLabel } from "@material-ui/core";
import { CheckBoxQuestion } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { Checkbox, Typography } from "~/components/uiParts";

export const CheckboxQuestionContent: FC<{
  question: CheckBoxQuestion;
  selectedOptionIds: string[];
}> = ({ question, selectedOptionIds }) => {
  return (
    <>
      {question.options.map((option) => {
        const checked = selectedOptionIds.includes(option.id);
        return <CheckboxOptionRow key={option.id} optionLabel={option.text} checked={checked} />;
      })}
    </>
  );
};

export const CheckboxOptionRow: FC<{ optionLabel: string; checked: boolean }> = ({
  optionLabel,
  checked,
}) => {
  return (
    <StyledFormControlLabel
      control={<StyledCheckbox color="primary" checked={checked} />}
      label={
        <StyledTypography variant="body2" checked={checked}>
          {optionLabel}
        </StyledTypography>
      }
    />
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0;
  cursor: default;
`;

const StyledTypography = styled(Typography)<{ checked: boolean }>`
  &.MuiTypography-root {
    overflow: visible;
    color: ${(props) => !props.checked && props.theme.palette.grey[200]};
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
