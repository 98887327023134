import { Box } from "@material-ui/core";
import { Employee, OnnEvent, BriefingSessionCategory } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { EventContentContainer } from "./EventContentContainer";
import { TargetNewGraduateContainer } from "./TargetNewGraduateContainer";

type Props = {
  currentUser: Employee;
  onnEvent: OnnEvent;
  briefingSessionCategories?: BriefingSessionCategory[];
};

export const TabPage: FC<Props> = ({ currentUser, onnEvent, briefingSessionCategories }) => {
  return (
    <StyledBox display="flex" alignItems="flex-start" mt="40px">
      <EventContentContainer
        currentUser={currentUser}
        onnEvent={onnEvent}
        briefingSessionCategories={briefingSessionCategories}
      />
      <TargetNewGraduateContainer currentUser={currentUser} onnEvent={onnEvent} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
`;
