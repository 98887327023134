import React, { FC } from "react";

export const ArrowDown: FC = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M20 12L18.545 10.545L13.039 16.051L13.039 4L10.961 4L10.961 16.052L5.455 10.545L4 12L12 20L20 12Z"
          stroke="none"
        />
      </g>
    </svg>
  );
};
