import { patchEmployeeInformationFieldsOrderRequestSchema } from "@onn/common";
import { useCallback } from "react";
import { z } from "zod";

import { mutateEmployeeInformationGroups } from "../employeeInformationGroup/useEmployeeInformationGroups";
import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

export const useUpdateEmployeeInformationFieldsOrder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const execUpdateEmployeeInformationFieldsOrder = useCallback(
    async (
      data: z.infer<typeof patchEmployeeInformationFieldsOrderRequestSchema>
    ): Promise<void> => {
      await apiClient
        .patch("/api/employee-information-fields/orders", data)
        .then(async () => {
          enqueueSnackbar("項目を更新しました", { variant: "success" });
          mutateEmployeeInformationGroups();
        })
        .catch((error) => {
          if (error instanceof Error) {
            enqueueSnackbar(error.message, { variant: "error" });
          }
        });
    },
    [enqueueSnackbar]
  );

  return { execUpdateEmployeeInformationFieldsOrder };
};
