import liff from "@line/liff";
import { liffUrls } from "@onn/common";
import React, { FC } from "react";

import { NotFound } from "../NotFound";

import { PageOnLiffBrowser } from "./components/PageOnLiffBrowser";
import { PageOnNotLiffBrowser } from "./components/PageOnNotLiffBrowser";

import { useQuery } from "~/hooks/shared";

const useValuesFromQuery = () => {
  const { query } = useQuery();
  const changeAuthenticationTypeLinkId = query.get("changeAuthenticationTypeLinkId");
  const employeeId = query.get("employeeId");
  const liffId = query.get("liffId");

  return { changeAuthenticationTypeLinkId, employeeId, liffId };
};

/**
 * 認証の切り替えを行うページ
 */
export const ChangeAuthenticationPage: FC = () => {
  const { changeAuthenticationTypeLinkId, employeeId, liffId } = useValuesFromQuery();
  if (!changeAuthenticationTypeLinkId || !employeeId || !liffId) {
    return <NotFound />;
  }

  // ログイン方法を切り替えるためのURL
  const urlToChangeAuthenticationType = liffUrls.changeAuthenticationType({
    liffId,
    query: [
      {
        key: "changeAuthenticationTypeLinkId",
        value: changeAuthenticationTypeLinkId,
      },
      {
        key: "employeeId",
        value: employeeId,
      },
      {
        key: "liffId",
        value: liffId,
      },
    ],
  });

  if (liff.isInClient()) {
    return (
      <PageOnLiffBrowser
        changeAuthenticationTypeLinkId={changeAuthenticationTypeLinkId}
        employeeId={employeeId}
      />
    );
  } else {
    return <PageOnNotLiffBrowser urlForQRCode={urlToChangeAuthenticationType} />;
  }
};
