import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { useDeleteOnnEventSlotDate } from "~/hooks/onnEventSlotDates/useDeleteOnnEventSlotDate";
import { useSnackbar } from "~/hooks/shared";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEventId: string;
  onnEventSlotId: string;
  isExistReservation: boolean;
};

export const ConfirmDeleteSlotModal: FC<Props> = ({
  open,
  onCancel,
  onnEventId,
  onnEventSlotId,
  isExistReservation,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const { deleteOnnEventSlotDates } = useDeleteOnnEventSlotDate();

  const handleAccept = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteOnnEventSlotDates({
        onnEventId,
        onnEventSlotId,
      });
      enqueueSnackbar(
        `予約枠が削除されました。${
          isExistReservation ? "予約取り消しが候補者に通知されました。" : ""
        } `,
        {
          variant: "success",
        }
      );
      onCancel();
    } catch {
      enqueueSnackbar("予約枠の削除に失敗しました", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [
    deleteOnnEventSlotDates,
    enqueueSnackbar,
    isExistReservation,
    onCancel,
    onnEventId,
    onnEventSlotId,
  ]);

  return (
    <Modal
      open={open}
      title="予約枠削除"
      content={
        <>
          <Box mb={2} textAlign="center" display="flex" flexDirection="column" gridGap="18px">
            <Typography variant="body1">
              {`予約枠を削除しますか？\n予約がある場合、予約は全て取り消され候補者へ通知が送られます。`}
            </Typography>

            <Typography variant="caption" color="textSecondary">
              この操作は取り消すことができません
            </Typography>
          </Box>
        </>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="secondary"
            onClick={handleAccept}
            isLoading={isLoading}
          >
            削除
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
