import {
  OnnEventDeterminedDate,
  BriefingSessionEvent,
  NewInterviewEvent,
  Employee,
} from "@onn/common";
import React, { createContext, useContext } from "react";

import { useEmployees } from "~/hooks/employee";
import { useDeterminedDate } from "~/hooks/onnEvent";

type DeterminedDateWithEmployee = OnnEventDeterminedDate & {
  employee: Employee | undefined;
};

type ContextType = {
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
  determinedDatesWithEmployee: DeterminedDateWithEmployee[];
  slotId: string;
  slotFrom: Date;
  slotUntil: Date;
  briefingSessionCategoryId?: string;
  isLoading: boolean;
};

/**
 * 予約一覧モーダルでのみ使用するコンテキスト
 */
export const ContextOfListOfEmployeesParticipatingForSlotModal = createContext<
  ContextType | undefined
>(undefined);

export const useContextOfListOfEmployeesParticipatingForSlotModal = () => {
  const c = useContext(ContextOfListOfEmployeesParticipatingForSlotModal);
  if (!c)
    throw new Error(
      "useContextOfListOfEmployeesParticipatingForSlotModal must be inside a Provider with a value"
    );
  return c;
};

export const ContextProviderOfListOfEmployeesParticipatingForSlotModal: React.FC<{
  children: React.ReactNode;
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
  slotId: string;
  slotFrom: Date;
  slotUntil: Date;
  briefingSessionCategoryId?: string;
}> = ({ children, onnEvent, slotId, slotFrom, slotUntil, briefingSessionCategoryId }) => {
  const { data: onnEventDeterminedDates = [], isLoading: isLoadingDeterminedDates } =
    useDeterminedDate({ onnEventId: onnEvent.id });

  const targetDeterminedDates = onnEventDeterminedDates.filter(
    (determinedDate) => determinedDate.onnEventSlotDateId === slotId
  );

  const employeeIds = targetDeterminedDates.map((determinedDate) => determinedDate.employeeId);
  const { data: employees = [], isLoading: isLoadingEmployees } = useEmployees(employeeIds);

  const determinedDatesWithEmployee = targetDeterminedDates.map((determinedDate) => {
    const employee = employees.find((employee) => employee.id === determinedDate.employeeId);
    const determinedDateWithEmployee = Object.assign(determinedDate, { employee });
    return determinedDateWithEmployee;
  });

  const isLoading = isLoadingDeterminedDates || isLoadingEmployees;

  return (
    <ContextOfListOfEmployeesParticipatingForSlotModal.Provider
      value={{
        onnEvent,
        determinedDatesWithEmployee,
        slotId,
        slotFrom,
        slotUntil,
        briefingSessionCategoryId,
        isLoading,
      }}
    >
      {children}
    </ContextOfListOfEmployeesParticipatingForSlotModal.Provider>
  );
};
