import { Box } from "@material-ui/core";
import React, { FC } from "react";

import styled from "styled-components";

import { Typography, Loading } from "~/components/uiParts";

import { UserIcon } from "~/components/uiParts/UserIcon";
import { useEmployee } from "~/hooks/employee";

type Props = {
  assigneeId: string;
};

export const AssigneeCell: FC<Props> = ({ assigneeId }) => {
  const { data: employee, isLoading } = useEmployee(assigneeId);

  if (isLoading) return <Loading size="small" fullHeight={false} />;
  if (!employee) return <></>;

  return (
    <StyledClickableBox display="flex" alignItems="center" gridGap={"8px"} ml={0}>
      <UserIcon
        username={employee.getName()}
        profileIconImageUrl={employee.profileIconImageUrl}
        size={"small"}
        circular={true}
      />
      <Box display="grid" gridRowGap="4px">
        <Typography variant={"body2"} color="textPrimary" noWrap>
          {employee.getName()}
        </Typography>
      </Box>
    </StyledClickableBox>
  );
};

const StyledClickableBox = styled(Box)`
  cursor: pointer;
  width: 100%;
`;
