import React, { FC } from "react";

import { BodyRowData } from "../../const";

import { AdjustingCell } from "./Adjusting";

import { DraftCell } from "./DraftCell";

import { CellItem, StyleProps, Typography } from "~/components/uiParts";
import { colors } from "~/config/theme";

export const StatusCell: FC<{ rowData: BodyRowData["status"] } & StyleProps> = ({
  rowData,
  style,
  ...styleProps
}) => {
  switch (rowData.status) {
    case "done":
      return BaseStatusCell({
        children: "開催済み",
        ...styleProps,
        style: {
          ...style,
          backgroundColor: colors.grey[100],
        },
      });
    case "adjusting":
      return (
        <AdjustingCell
          {...styleProps}
          style={{ ...style, backgroundColor: colors.secondary.light }}
          onnEventId={rowData.onnEventId}
          slotId={rowData.slotId}
          reservedCount={rowData.reservedCount}
        />
      );
    case "full":
      return BaseStatusCell({
        children: "満員",
        ...styleProps,
        style: {
          ...style,
          backgroundColor: colors.primary.light,
        },
      });
    case "closed":
      return BaseStatusCell({
        children: "募集締切",
        ...styleProps,
        style: {
          ...style,
          backgroundColor: colors.primary.light,
        },
      });
    case "draft":
      return (
        <DraftCell
          {...styleProps}
          style={style}
          onnEventId={rowData.onnEventId}
          slotId={rowData.slotId}
        />
      );

    default:
      return (
        <CellItem
          {...styleProps}
          style={{ ...style, backgroundColor: colors.primary.light }}
          isShowTopBorder
        >
          <Typography variant="body2" bold color="textSecondary">
            {status}
          </Typography>
        </CellItem>
      );
  }
};

const BaseStatusCell: FC<{ children: React.ReactNode; bold?: boolean } & StyleProps> = ({
  children,
  bold = true,
  ...styleProps
}) => {
  return (
    <CellItem {...styleProps} isShowTopBorder>
      <Typography variant="body2" bold={bold} color="textSecondary">
        {children}
      </Typography>
    </CellItem>
  );
};
