import { Employee, contactMessagesOrderSchema } from "@onn/common";
import { set } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { z } from "zod";

export type ContactMessagesOrderForm = z.input<typeof contactMessagesOrderFormSchema>;
export type ContactMessagesOrderFormOutput = z.output<typeof contactMessagesOrderFormSchema>;

export const contactMessagesOrderFormSchema = z
  .object({
    sender: z.enum(["official", "currentUser"]),
    text: contactMessagesOrderSchema.shape.text,
    files: z.array(z.instanceof(File)),

    receiverEmployees: z
      .array(z.instanceof(Employee))
      .min(1, { message: "配信対象を1件以上指定してください" }),

    scheduledDate: z
      .object({
        isInstant: z.boolean(),
        // 以下は、isInstant==trueの場合は無視する
        // isInstantの場合は選択不要だが、デフォルト値があり未選択状態が存在しないため必須項目としている
        date: z.date(),
        time: z.string(),
      })
      .transform((data) => {
        const [hour, minute] = data.time.split(":");
        return {
          ...data,
          scheduledDate: zonedTimeToUtc(
            set(utcToZonedTime(data.date, "Asia/Tokyo"), {
              hours: Number(hour),
              minutes: Number(minute),
              seconds: 0,
              milliseconds: 0,
            }),
            "Asia/Tokyo"
          ),
        };
      })
      .superRefine((data, context) => {
        if (data.isInstant) return;
        if (data.scheduledDate <= new Date()) {
          context.addIssue({
            path: ["time"],
            code: z.ZodIssueCode.invalid_date,
            message: "現在より後の時刻を設定してください",
          });
        }
      }),
  })
  .transform((value) => {
    const { sender, ...output } = value;
    return { ...output, isSentByOfficialName: sender === "official" };
  });
