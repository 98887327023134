import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const tabs = [
  {
    label: "選考",
    value: "interview",
  },
  {
    label: "説明会",
    value: "briefing_session",
  },
  {
    label: "その他",
    value: "other",
  },
] as const;

type TabValue = (typeof tabs)[number]["value"];

export const useTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TabValue>("interview");
  const onChangeActiveTab = (_: unknown, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };

  useEffect(() => {
    switch (location.hash) {
      case "#interview": {
        setActiveTab("interview");
        break;
      }
      case "#briefing_session": {
        setActiveTab("briefing_session");
        break;
      }
      case "#other": {
        setActiveTab("other");
        break;
      }
      default: {
        void 0;
      }
    }
  }, [location.hash, navigate]);

  return { tabs, activeTab, onChangeActiveTab };
};
