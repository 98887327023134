import { Box, TableCell, TableRow, Menu, MenuItem } from "@material-ui/core";
import { Library } from "@onn/common";
import { format } from "date-fns";
import React, { FC, MouseEvent, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ConfirmDeleteLibraryModal } from "./ConfirmDeleteLibraryModal";

import { Chip, IconButton, Typography } from "~/components/uiParts";
import { useCurrentUser, useAllEmployeesWithDeleted } from "~/hooks/employee";
import { useLocalStorage, useSnackbar } from "~/hooks/shared";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { LibraryUseCase } from "~/service/usecases/libraryUseCase";
import { captureException } from "~/util";

const libraryUseCase = new LibraryUseCase();

const TIMESTAMP_FORMAT = "yyyy/MM/dd";
const LIBRARY_FOR_PREVIEW = "libraryForPreview";

type Props = {
  library: Library;
  displayChevronUpButton: boolean;
  displayChevronDownButton: boolean;
  disabledLibrary: boolean;
  onClickChevronButton: ({ library, isUp }: { library: Library; isUp: boolean }) => Promise<void>;
  onClickDeleteButton: (library: Library) => Promise<void>;
  syncUpdatedLibrary: ({
    updatedLibrariesIds,
    updatedLibraries,
  }: {
    updatedLibrariesIds: string[];
    updatedLibraries: Library[];
  }) => void;
  isUpdating: boolean;
};

export const LibraryTableRow: FC<Props> = ({
  library,
  displayChevronUpButton,
  displayChevronDownButton,
  disabledLibrary,
  onClickChevronButton,
  onClickDeleteButton,
  syncUpdatedLibrary,
  isUpdating,
}) => {
  const { currentUser } = useCurrentUser();
  const { data: allEmployeesWithDeleted = [] } = useAllEmployeesWithDeleted(currentUser.tenantId);
  const { tenantSettings } = useTenantSettings();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { storeValue } = useLocalStorage();

  const [openConfirmDeleteLibraryModal, setOpenConfirmDeleteLibraryModal] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleClickChevronButton = (e: MouseEvent<HTMLElement>, isUp: boolean) => {
    e.stopPropagation();
    e.preventDefault();
    onClickChevronButton({ library, isUp });
  };

  const changeStatus = async (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const bool = !library.isPublic;

    const newLibrary = { ...library, isPublic: bool };
    try {
      await libraryUseCase.updateLibrary({ libraryId: library.id, library: newLibrary });
      syncUpdatedLibrary({
        updatedLibrariesIds: [library.id],
        updatedLibraries: [newLibrary],
      });
      enqueueSnackbar(bool ? "公開されました" : "下書きに戻しました", { variant: "success" });
      setAnchorEl(null);
    } catch (e) {
      enqueueSnackbar("更新に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "LibraryTableRow:changeStatus" },
      });
    }
  };

  const displayPreview = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
    storeValue(LIBRARY_FOR_PREVIEW, library);
    window.open(`/portal/libraries/${library.id}?preview=true`, "_blank");
  };

  const deleteCategory = async () => {
    setAnchorEl(null);
    onClickDeleteButton(library);
  };

  const options = [
    // ライブラリ機能がオフのときはステータスを変更できない
    ...(tenantSettings.features.library
      ? [
          {
            title: "公開ステータスを変更",
            func: changeStatus,
          },
        ]
      : []),
    {
      title: "表示をプレビュー",
      func: displayPreview,
    },
    {
      title: "カテゴリーを削除",
      func: (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenConfirmDeleteLibraryModal(true);
      },
    },
  ];

  const chipColor = disabledLibrary ? "grey" : "primary";
  const isOutlined = !library.isPublic;
  let chipLabel: string;
  if (disabledLibrary) {
    chipLabel = library.isPublic ? "停止中" : "下書き";
  } else {
    chipLabel = library.isPublic ? "公開中" : "下書き";
  }

  const createdEmployeeText = useMemo(() => {
    const employee = allEmployeesWithDeleted.find((v) => v.id === library.updatedEmployeeId);
    if (!employee) {
      return "ユーザーが取得できませんでした";
    }
    return employee.getName();
  }, [allEmployeesWithDeleted, library.updatedEmployeeId]);

  const moveToLibrary = () => navigate(`/libraries/${library.id}/edit`);

  return (
    <>
      <StyledTableRow key={library.id} hover onClick={moveToLibrary}>
        <StyledTableCell>
          <StyledIconButtonWrapper $display={displayChevronUpButton}>
            <IconButton
              size="md"
              icon="chevronUp"
              onClick={(e) => handleClickChevronButton(e, true)}
              disabled={isUpdating}
            />
          </StyledIconButtonWrapper>
          <StyledIconButtonWrapper $display={displayChevronDownButton}>
            <IconButton
              size="md"
              icon="chevronDown"
              onClick={(e) => handleClickChevronButton(e, false)}
              disabled={isUpdating}
            />
          </StyledIconButtonWrapper>
        </StyledTableCell>
        <StyledTableCell>
          <Box display="flex">
            <Typography variant="body1" color="textSecondary">
              {library.label}
            </Typography>
            <Box display="inline-block" width="8px" />
            <Typography variant="body1" color="textSecondary" noWrap>
              {library.title}
            </Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body1" color="textPrimary">
            {library.contents.length}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Box display="column">
            <Typography variant="caption" bold color="textPrimary">
              {createdEmployeeText}
            </Typography>
            <br />
            <Typography variant="caption" color="textSecondary">
              {format(library.updatedAt, TIMESTAMP_FORMAT)}
            </Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Chip color={chipColor} label={chipLabel} isOutlined={isOutlined} bold />
        </StyledTableCell>
        <StyledTableCell>
          <IconButton icon="menuVert" onClick={openMenu} />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.title} onClick={option.func}>
                <Typography variant="body2">{option.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </StyledTableCell>
      </StyledTableRow>
      <ConfirmDeleteLibraryModal
        open={openConfirmDeleteLibraryModal}
        libraryTitle={library.title}
        onAccept={deleteCategory}
        onCancel={() => setOpenConfirmDeleteLibraryModal(false)}
      />
    </>
  );
};

const StyledIconButtonWrapper = styled.div<{ $display: boolean }>`
  visibility: ${(props) => !props.$display && `hidden`};

  .MuiIconButton-root {
    padding: 6px;
  }
  .MuiIconButton-label {
    width: 12px;
    height: 12px;
  }
`;

const StyledTableRow = styled(TableRow)`
  &.MuiTableRow-root {
    cursor: pointer;
  }
`;

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]};
  }
`;
