import { OnnEventAnswerForDisplay } from "@onn/common";
import { useCallback, useMemo, useState } from "react";

import {
  AnyInputCondition,
  AnyValidCondition,
} from "~/components/domains/onnEvents/OnnEventSlotSearchModal/types/condition";
import { LogicType } from "~/components/domains/onnEvents/OnnEventSlotSearchModal/types/logic-type";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";

export const useOnnEventAnswersSearchByConditions = ({
  onnEventId,
  onnEventAnswersForDisplay,
}: {
  onnEventId: string;
  onnEventAnswersForDisplay: OnnEventAnswerForDisplay[];
}) => {
  const [searchedAnswerIds, setSearchedAnswerIds] = useState<string[] | null>(null);
  const [conditions, setConditions] = useState<AnyInputCondition[]>([
    { target: "slotFromDateRange", rangeStart: undefined, rangeEnd: undefined },
  ]);
  const [logicType, setLogicType] = useState<LogicType>("AND");

  const { enqueueSnackbar } = useSnackbar();

  const onResetSearchConditions = useCallback(() => {
    setConditions([{ target: "slotFromDateRange", rangeStart: undefined, rangeEnd: undefined }]);
    setSearchedAnswerIds(null);
    setLogicType("AND");
  }, []);

  const onSearchConfirm = useCallback(
    async (logicType: LogicType, conditions: AnyValidCondition[]) => {
      setConditions(
        conditions.length
          ? conditions
          : [{ target: "slotFromDateRange", rangeStart: undefined, rangeEnd: undefined }]
      );
      setLogicType(logicType);

      if (conditions.length === 0) {
        onResetSearchConditions();
        return;
      }

      const { data: slotIds } = await apiClient
        .post("/onn_event_api/slots/search-by-conditions", {
          type: logicType,
          conditions,
          onnEventId,
        })
        .catch((e) => {
          enqueueSnackbar("検索に失敗しました。通信環境をご確認の上、再度お試しください。", {
            variant: "error",
          });
          throw e;
        });

      const matchedAnswerIds = onnEventAnswersForDisplay.filter((answer) => {
        return slotIds.includes(answer.selectedOnnEventSlotDateId || "");
      });
      setSearchedAnswerIds(matchedAnswerIds.map((answer) => answer.id));
    },
    [enqueueSnackbar, onResetSearchConditions, onnEventAnswersForDisplay, onnEventId]
  );

  const searchedAnswers = useMemo(() => {
    if (searchedAnswerIds === null) return null;
    return onnEventAnswersForDisplay.filter((answer) => searchedAnswerIds.includes(answer.id));
  }, [onnEventAnswersForDisplay, searchedAnswerIds]);

  return { conditions, logicType, onSearchConfirm, searchedAnswers, onResetSearchConditions };
};
