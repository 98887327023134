import { z } from "zod";

/////////////////////////////////////////
// BRIEFING SESSION CATEGORY SCHEMA
/////////////////////////////////////////

export const BriefingSessionCategorySchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  title: z.string(),
  description: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type BriefingSessionCategory = z.infer<typeof BriefingSessionCategorySchema>;

/////////////////////////////////////////
// BRIEFING SESSION CATEGORY OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const BriefingSessionCategoryOptionalDefaultsSchema = BriefingSessionCategorySchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type BriefingSessionCategoryOptionalDefaults = z.infer<
  typeof BriefingSessionCategoryOptionalDefaultsSchema
>;

export default BriefingSessionCategorySchema;
