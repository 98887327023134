import React, { FC, useCallback } from "react";

import styled from "styled-components";

import { BodyRowData } from "../const";

import { CellItem, StyleProps, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const ReservedCell: FC<{ cellData: BodyRowData["reserved"] } & StyleProps> = ({
  style,
  cellData,
}) => {
  const { handleModal } = useModal();

  const openListOfEmployeesForSlotDatesModal = useCallback(() => {
    handleModal({
      name: "listOfEmployeesParticipatingForSlotDate",
      args: {
        onnEvent: cellData.onnEvent,
        slotId: cellData.slotId,
        briefingSessionCategoryId: cellData.briefingSessionCategoryId,
        fromDate: cellData.fromDate,
        untilDate: cellData.untilDate,
        type: cellData.type,
        isReservable: cellData.isReservable,
      },
    });
  }, [
    handleModal,
    cellData.onnEvent,
    cellData.slotId,
    cellData.briefingSessionCategoryId,
    cellData.fromDate,
    cellData.untilDate,
    cellData.type,
    cellData.isReservable,
  ]);

  return (
    <CellItem style={style} isShowTopBorder>
      <StyledTypography
        variant="body2"
        color="primary"
        onClick={openListOfEmployeesForSlotDatesModal}
      >
        {cellData.count}
      </StyledTypography>
    </CellItem>
  );
};

const StyledTypography = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;
`;
