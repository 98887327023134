import { NewGraduateToDisplay, NewGraduateToListView } from "@onn/common";
import React, { FC, useCallback } from "react";

import { EmployeePredictionSelector } from "../EmployeePredictionSelector";

import {
  OnEditEmployeePredictionRelation,
  useEditEmployeePredictionRelations,
} from "~/hooks/employeePrediction/useEditEmployeePredictionRelations";

type Props = {
  newGraduate: NewGraduateToDisplay;
  newGraduateToListView?: NewGraduateToListView;
  disabled?: boolean;
  onEditEmployeePredictionRelation?: OnEditEmployeePredictionRelation;
};
export const ChangeEmployeePredictionSelector: FC<Props> = ({
  newGraduate,
  newGraduateToListView,
  disabled,
  onEditEmployeePredictionRelation,
}) => {
  const { editEmployeePredictionRelations } = useEditEmployeePredictionRelations({
    onEditEmployeePredictionRelation,
  });

  const onChange_ = useCallback(
    (predictionId: string | null) => {
      // NOTE: 同じヨミに変更しようとした場合は何もしない
      if (predictionId === newGraduate.predictionId) return;
      editEmployeePredictionRelations({
        employeePredictionId: predictionId,
        newGraduateToListView,
        newGraduate,
      });
    },
    [editEmployeePredictionRelations, newGraduateToListView, newGraduate]
  );

  return (
    <EmployeePredictionSelector
      selectedPredictionId={newGraduate.predictionId || ""}
      disabled={disabled}
      onChange={(e) => onChange_(e ? e.id : null)}
    />
  );
};
