import { v4 } from "uuid";

import {
  IRecruitmentProcessRecordOnnEventItemSchema,
  recruitmentProcessRecordOnnEventItemSchema,
} from "./schema";

export class RecruitmentProcessRecordOnnEventItem
  implements IRecruitmentProcessRecordOnnEventItemSchema
{
  static readonly type = "Event" as const;
  type = RecruitmentProcessRecordOnnEventItem.type;
  static readonly validator = recruitmentProcessRecordOnnEventItemSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  employeeId: string;
  recruitmentStatusId: string;

  recruitmentProcessRecordId: string;
  onnEventId: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<RecruitmentProcessRecordOnnEventItem>) {
    const parsedInit = RecruitmentProcessRecordOnnEventItem.validator.parse(init);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.employeeId = parsedInit.employeeId;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;

    this.recruitmentProcessRecordId = parsedInit.recruitmentProcessRecordId;

    this.onnEventId = parsedInit.onnEventId;

    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<RecruitmentProcessRecordOnnEventItem>,
      "id" | "createdAt" | "updatedAt"
    >
  ): RecruitmentProcessRecordOnnEventItem {
    return new RecruitmentProcessRecordOnnEventItem({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
