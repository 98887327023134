import { useMemo } from "react";

/**
 * @deprecated
 * NOTE: いずれすべて useToggleSelectAllForIds に置き換える
 */
export const useToggleSelectAll = <T extends { id: string }>({
  options,
  selectedOptions,
}: {
  options: T[];
  selectedOptions: T[];
}) => {
  const selectedEmployeeSet = useMemo(
    () => new Set(selectedOptions.map((e) => e.id)),
    [selectedOptions]
  );

  const stateType: StateType = useMemo(() => {
    if (0 === selectedEmployeeSet.size) {
      return "isUnchecked";
    }
    const isCheckedAll = options.every((e) => selectedEmployeeSet.has(e.id));

    return isCheckedAll ? "isCheckedAll" : "isCheckedSome";
  }, [options, selectedEmployeeSet]);

  const state: State = useMemo(() => {
    const isSelectedAll = stateType === "isCheckedAll";
    const label = {
      text: isSelectedAll ? "全て解除" : "全て選択",
      count: selectedOptions.length,
    };

    switch (stateType) {
      case "isCheckedAll":
        return {
          stateType,
          checked: true,
          indeterminate: false,
          label,
        };
      case "isCheckedSome":
        return {
          stateType,
          checked: true,
          indeterminate: true,
          label,
        };
      case "isUnchecked":
        return {
          stateType,
          checked: false,
          indeterminate: false,
          label,
        };
    }
  }, [selectedOptions.length, stateType]);

  const toggleSelectAll = (): T[] => {
    const isSelectedAll = stateType === "isCheckedAll";
    const newIsSelectedAll = !isSelectedAll;

    if (newIsSelectedAll) {
      const newSelected = options.filter((e) => !selectedEmployeeSet.has(e.id));

      return [...selectedOptions, ...newSelected];
    } else {
      return [];
    }
  };

  return { toggleSelectAll, allSelectionState: state };
};

type StateType = "isCheckedAll" | "isCheckedSome" | "isUnchecked";
export type State = {
  stateType: StateType;
  checked: boolean;
  indeterminate: boolean;
  label: {
    text: string;
    count: number;
  };
};
