import { employeeInformationValueSchema } from "@onn/common/domain/EmployeeInformation/schema";
import { useCallback } from "react";
import { z } from "zod";

import { apiClient } from "~/libs";

export const useUpdateEmployeeInformation = () => {
  const execUpdateEmployeeInformation = useCallback(
    async ({
      employeeId,
      value,
    }: {
      employeeId: string;
      value: z.infer<typeof employeeInformationValueSchema>;
    }): Promise<void> => {
      await apiClient.patch("/patch_employee_information", {
        employeeId,
        value,
      });
    },
    []
  );

  return { execUpdateEmployeeInformation };
};
