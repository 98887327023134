import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";

import { ScenarioListTable } from "./ScenarioListTable/ScenarioListTable";

import { OptionSettingDrawer } from "~/components/domains/richmenu/OptionSettingDrawer/OptionSettingDrawer";
import { Button, Icon } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import { useOnnFeatureToggle } from "~/hooks/shared";

export const ScenarioTab = () => {
  // TODO[シナリオF]: 複数シナリオ解禁時にfeatureToggleを外す
  const { multiScenario } = useOnnFeatureToggle("multiScenario");

  const [isOpenOptionSettingDrawer, setIsOpenOptionSettingDrawer] = useState(false);
  const [scenarioId, setScenarioId] = useState<string | null>(null);
  const onClickRichMenuSetting = useCallback((scenarioId: string) => {
    setIsOpenOptionSettingDrawer(true);
    setScenarioId(scenarioId);
  }, []);
  const closeOptionSettingDrawer = useCallback(() => {
    setIsOpenOptionSettingDrawer(false);
  }, []);

  const optionSettingDrawerKey = isOpenOptionSettingDrawer ? "open" : "close";
  return (
    <Box
      pt={4}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      style={{
        rowGap: "32px",
      }}
    >
      {multiScenario && <TableManager />}
      <ScenarioListTable onClickRichMenuSetting={onClickRichMenuSetting} />
      {scenarioId && (
        <OptionSettingDrawer
          key={optionSettingDrawerKey}
          isOpen={isOpenOptionSettingDrawer}
          close={closeOptionSettingDrawer}
          scenarioId={scenarioId}
          recruitmentStatusId={null}
          type={"registrationRichMenu"}
        />
      )}
    </Box>
  );
};

const useHandleAddScenario = () => {
  const { handleModal } = useModal();
  const handleOnAddScenario = useCallback(() => {
    // シナリオ追加処理
    handleModal({
      name: "addOrUpdateScenarioModal",
      args: {
        data: {
          type: "create",
        },
      },
    });
  }, [handleModal]);

  return { handleOnAddScenario };
};

const TableManager: FC<{}> = () => {
  const { handleOnAddScenario } = useHandleAddScenario();

  return (
    <Box display="flex" justifyContent="flex-end" alignItems={"center"} height="40px">
      <Button
        color="primary"
        variant="outlined"
        borderRadius="regular"
        startIcon={<Icon icon="add" size="ssm" color="primary" />}
        onClick={handleOnAddScenario}
      >
        シナリオを追加
      </Button>
    </Box>
  );
};
