import { z } from "zod";

export const types = [
  "SEQUENCE_START",
  "SEQUENCE_END",
  "SEQUENCE_STOP",
  "SEQUENCE_RESTART",
] as const;
export type TType = (typeof types)[number];

export const lineUserActiveLogSchema = z.object({
  id: z.string(),
  externalLineUserId: z.string(),
  tenantId: z.string(),
  location: z.string(),
  spaceId: z.string().optional(),
  type: z.enum(types),
  sequenceId: z.string().optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILineUserActiveLogSchema extends z.infer<typeof lineUserActiveLogSchema> {}
