import { AnyActionCondition, AnyTriggerSetting } from "@onn/common";
import { useCallback } from "react";

import { ActionConditionInput } from "../components/ActionConditionForm/actionConditionInputSchema";

import { actionConditionDefaultValue } from "./defaultValue/actionConditionDefaultValue";

export const useGenerateActionConditionDefaultValue = () => {
  const generateActionConditionDefaultValue = useCallback(
    ({
      actionCondition,
      triggerSetting,
    }: {
      actionCondition?: AnyActionCondition;
      triggerSetting: AnyTriggerSetting;
    }): ActionConditionInput["actionCondition"] => {
      if (!actionCondition) {
        return actionConditionDefaultValue;
      }
      switch (actionCondition.type) {
        case "EmployeeTagActionCondition": {
          return {
            conditionType: "EmployeeTagActionCondition",
            tagIds: actionCondition.employeeTagIds,
          };
        }
        case "OnnEventEvaluationActionCondition": {
          if (triggerSetting.type !== "EvaluateOnnEventTriggerSetting") {
            return {
              conditionType: "OnnEventEvaluationActionCondition",
              eventEvaluationRankIds: [],
            };
          } else {
            if (triggerSetting.onnEventId !== actionCondition.onnEventId) {
              return {
                conditionType: "OnnEventEvaluationActionCondition",
                eventEvaluationRankIds: [],
              };
            }
          }
          return {
            conditionType: "OnnEventEvaluationActionCondition",
            eventEvaluationRankIds: actionCondition.onnEventEvaluationRankIds,
          };
        }
        case "OnnFormTaskResultActionCondition": {
          if (triggerSetting.type !== "AnswerOnnTaskTriggerSetting") {
            return {
              conditionType: "OnnFormTaskResultActionCondition",
              questionId: "",
              questionOptionId: "",
            };
          } else {
            if (triggerSetting.onnTaskId !== actionCondition.onnFormTaskId) {
              return {
                conditionType: "OnnFormTaskResultActionCondition",
                questionId: "",
                questionOptionId: "",
              };
            }
          }
          return {
            conditionType: "OnnFormTaskResultActionCondition",
            questionId: actionCondition.questionId,
            questionOptionId: actionCondition.optionId,
          };
        }
        default: {
          const _exhaustiveCheck: never = actionCondition;
          return _exhaustiveCheck;
        }
      }
    },
    []
  );

  return { generateActionConditionDefaultValue };
};
