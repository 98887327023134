import {
  AnswerOnnEventTriggerSetting,
  AnswerOnnTaskTriggerSetting,
  ChangeRecruitmentStatusTriggerSetting,
  ChangeStatusOfOnnEventTriggerSetting,
  EvaluateOnnEventTriggerSetting,
} from "@onn/common";
import { useMemo } from "react";

import { TriggersApiResponse } from "../../type";

export const useIsTriggerRelatedDeletedStatus = ({
  trigger,
}: {
  trigger: TriggersApiResponse[0];
}) => {
  const triggerSetting = trigger.triggerSetting;
  const triggerType = triggerSetting.type;
  // NOTE: isDeletedRecruitmentStatusがtrueの場合、削除されたステータスのトリガーであることを表示する
  const isTriggerRelatedDeletedStatus = useMemo(() => {
    switch (triggerType) {
      case ChangeRecruitmentStatusTriggerSetting.type:
        return triggerSetting.isDeletedRecruitmentStatus;
      case ChangeStatusOfOnnEventTriggerSetting.type:
      case EvaluateOnnEventTriggerSetting.type:
      case AnswerOnnTaskTriggerSetting.type:
      case AnswerOnnEventTriggerSetting.type:
        return false;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = triggerType;
        return _exhaustiveCheck;
    }
  }, [triggerSetting, triggerType]);

  return { isTriggerRelatedDeletedStatus };
};
