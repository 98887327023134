import { z } from "zod";

export const schema = z.object({
  id: z.string(),
  tenantId: z.string(),
  externalLineUserId: z.string(),
  registrationInvitationLinkId: z.string(),
  createdAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRegistrationInvitationLinkAccessLog extends z.infer<typeof schema> {}
