import { z } from "zod";

/////////////////////////////////////////
// TRIGGER SCHEMA
/////////////////////////////////////////

export const TriggerSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  recruitmentStatusId: z.string(),
  parentId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Trigger = z.infer<typeof TriggerSchema>;

/////////////////////////////////////////
// TRIGGER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const TriggerOptionalDefaultsSchema = TriggerSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type TriggerOptionalDefaults = z.infer<typeof TriggerOptionalDefaultsSchema>;

export default TriggerSchema;
