import {
  Employee,
  EmployeeInformationGroupWithFieldAndOptions,
  EmployeeTag,
  OnnEvent,
  OnnTask,
  RecruitmentStatus,
} from "@onn/common";
import { orderBy } from "lodash";
import { useCallback } from "react";

/**
 * 候補者csv一括登録用の設定csvのデータを生成する
 */
export const useGenerateSettingCSV = () => {
  const { generateLabelToSampleValue } = useGenerateLabelToSampleValue();
  const generateSettingCSV = useCallback(
    ({
      employeeTags,
      onnEvents,
      onnTasks,
      recruitmentStatuses,
      allEmployees,
      employeeInformationGroups,
    }: {
      employeeTags: EmployeeTag[];
      onnEvents: OnnEvent[];
      onnTasks: OnnTask[];
      recruitmentStatuses: RecruitmentStatus[];
      allEmployees: Employee[];
      employeeInformationGroups: EmployeeInformationGroupWithFieldAndOptions[];
    }) => {
      const labelToSampleValue = generateLabelToSampleValue({
        employeeTags,
        onnEvents,
        onnTasks,
        recruitmentStatuses,
        allEmployees,
        employeeInformationGroups,
      });

      const length = Math.max(...Object.values(labelToSampleValue).map((v) => v.length));

      const settingCsvData = Array.from({ length }).flatMap((_, index) => {
        const row = Object.entries(labelToSampleValue).map(([_, data]) => data[index] || "");
        if (index === 0) {
          return [
            Object.entries(labelToSampleValue).map(([label]) => `「${label}」コピー欄`),
            Object.entries(labelToSampleValue).map(
              ([label]) =>
                `該当する「${label}」は以下候補からコピーして入社者情報記入欄にペーストしてください`
            ),
            row,
          ];
        }
        return [row];
      });

      return settingCsvData;
    },
    [generateLabelToSampleValue]
  );

  return { generateSettingCSV };
};

const useGenerateLabelToSampleValue = () => {
  const generateLabelToSampleValue = useCallback(
    ({
      employeeTags,
      onnEvents,
      onnTasks,
      recruitmentStatuses,
      allEmployees,
      employeeInformationGroups,
    }: {
      employeeTags: EmployeeTag[];
      onnEvents: OnnEvent[];
      onnTasks: OnnTask[];
      recruitmentStatuses: RecruitmentStatus[];
      allEmployees: Employee[];
      employeeInformationGroups: EmployeeInformationGroupWithFieldAndOptions[];
    }) => {
      const assignableAssigneeEmails = allEmployees.flatMap((employee) =>
        employee.isRegularAcceptanceEmployee() ? employee.email : []
      );

      const selectTypeInformationFieldToOptions = orderBy(
        employeeInformationGroups,
        "order",
        "asc"
      ).flatMap(({ employeeInformationFieldWithOptions, employeeInformationGroup }) => {
        const orderedFields = orderBy(employeeInformationFieldWithOptions, "order", "asc");
        return orderedFields.map((field) => {
          switch (field.type) {
            case "MULTIPLE_SELECT": {
              const optionLabels = orderBy(field.options, "order", "asc").map((o) => o.label);
              return { [`${employeeInformationGroup.label}/${field.label}`]: optionLabels };
            }
            case "SINGLE_SELECT": {
              const optionLabels = orderBy(field.options, "order", "asc").map((o) => o.label);
              return { [`${employeeInformationGroup.label}/${field.label}`]: optionLabels };
            }
            case "TEXT":
            case "DATE": {
              return {};
            }
            default: {
              const _exhaustiveCheck: never = field;
              return _exhaustiveCheck;
            }
          }
        });
      });

      const labelToSampleValue: {
        選考ステータス: string[];
        担当者: string[];
        タグ: string[];
        イベント: string[];
        タスク: string[];
      } & {
        [key: string]: string[];
      } = {
        選考ステータス: recruitmentStatuses.map((s) => s.label),
        担当者: assignableAssigneeEmails.map((email) => email),
        タグ: employeeTags.map((tag) => tag.name),
        イベント: onnEvents.filter((event) => event.type === "normal").map((event) => event.title),
        タスク: onnTasks.map((t) => t.title),
      };

      selectTypeInformationFieldToOptions.forEach((labelToOptions) => {
        Object.entries(labelToOptions).forEach(([label]) => {
          labelToSampleValue[label] = labelToOptions[label] || [];
        });
      });

      return labelToSampleValue;
    },
    []
  );

  return { generateLabelToSampleValue };
};
