import React, { FC, ReactNode } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import { portalGuestRoutes } from "./portalGuestRoutes";

import { DefaultLayout } from "~/components/layouts";
import { useAuthenticationNonGuarded } from "~/hooks/context";
import { useScrollTopByDetectTransition } from "~/hooks/shared";
import { AfterAuthenticationByLinePlatform } from "~/pages/AfterAuthenticationByLinePlatform/AfterAuthenticationByLinePlatform";
import { Account } from "~/pages/account/show";
import { ResetEmailShow } from "~/pages/reset_email/show";
import ResetPasswordComplete from "~/pages/reset_password/complete";
import { ResetPasswordShow } from "~/pages/reset_password/show";
import { SignIn } from "~/pages/signIn";

const GuestRoute = ({ component: Component }: { component: FC }) => {
  useScrollTopByDetectTransition();
  const { authUser } = useAuthenticationNonGuarded();

  if (authUser) {
    return <Navigate to="/" />;
  }

  return <Component />;
};

type Props = {
  children: ReactNode;
};

export const GuestRouterWrapper: FC<Props> = ({ children }) => {
  return useRoutes([
    ...portalGuestRoutes,
    {
      // 招待トークン(個別招待URL)を用いたアカウント登録ページ
      // 過去には、候補者の登録ページとしても利用していた
      path: "/account/:id",
      element: (
        <DefaultLayout isBackgroundImage>
          <GuestRoute component={Account} />
        </DefaultLayout>
      ),
    },
    {
      path: "/signin",
      element: (
        <DefaultLayout isBackgroundImage>
          <GuestRoute component={SignIn} />
        </DefaultLayout>
      ),
    },
    {
      // デフォルトのリンクの場合遷移時にリセットが実行されてしまうため、メールアドレスの変更時のリセットリンクの遷移先にOnnのリンクを設定している
      // NOTE: https://support.google.com/firebase/answer/7000714#actionlink
      path: "/reset_email",
      element: (
        <DefaultLayout isBackgroundImage>
          <ResetEmailShow />
        </DefaultLayout>
      ),
    },
    {
      path: "/reset_password",
      element: (
        <DefaultLayout isBackgroundImage>
          <GuestRoute component={ResetPasswordShow} />
        </DefaultLayout>
      ),
    },
    {
      path: "/reset_password/complete",
      element: (
        <DefaultLayout isBackgroundImage>
          <GuestRoute component={ResetPasswordComplete} />
        </DefaultLayout>
      ),
    },
    {
      path: "/after-line-authentication",
      element: (
        <DefaultLayout isBackgroundImage>
          <GuestRoute component={AfterAuthenticationByLinePlatform} />
        </DefaultLayout>
      ),
    },
    {
      path: "*",
      element: children,
    },
  ]);
};
