import { EmployeeInformation } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

const getKey = (employeeId: string) => {
  return { endpoint: "/get_employee_information", employeeId } as const;
};

export const useEmployeeInformation = (employeeId: string) => {
  return useSWR(getKey(employeeId), async ({ endpoint, employeeId }) => {
    const response = await apiClient.get(endpoint, { ["employee-id"]: employeeId });

    return response.data.employeeInformation
      ? new EmployeeInformation(response.data.employeeInformation)
      : null;
  });
};

export const mutateEmployeeInformation = (employeeId: string) => {
  return mutate(getKey(employeeId));
};
