import { Employee } from "@onn/common";
import React from "react";

import { Checkbox } from "~/components/uiParts";

type Props = {
  displayNewHire: Employee;
  selectedNewGraduateIds: Set<string>;
  onSelectNewGraduateId(newGraduateId: string): void;
};
export const NewGraduateCheckbox = ({
  displayNewHire,
  selectedNewGraduateIds,
  onSelectNewGraduateId,
}: Props) => {
  return (
    <Checkbox
      checked={selectedNewGraduateIds.has(displayNewHire.id)}
      onChange={() => onSelectNewGraduateId(displayNewHire.id)}
    />
  );
};
