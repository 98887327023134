import { Box, Stack } from "@mui/material";
import { Scenario } from "@onn/common";
import React, { FC } from "react";
import { Controller } from "react-hook-form";

import { useForm } from "./useForm";

import { Button, Loading, ScrollableBodyModal, TextField, Typography } from "~/components/uiParts";
import { useScenarios } from "~/hooks/scenario/useScenarios";

type Props = {
  open: boolean;
  onCancel: () => void;
  data:
    | {
        type: "create";
      }
    | {
        type: "update";
        scenarioId: string;
        scenarioName: string;
      };
};

export const AddOrUpdateScenarioModal: FC<Props> = (props) => {
  const title = props.data.type === "create" ? "シナリオ追加" : "シナリオ編集";
  const { data: scenarios = [], isLoading } = useScenarios();
  return (
    <ScrollableBodyModal
      open={props.open}
      disableBackdropModal
      disableEnforceFocus
      title={title}
      content={
        isLoading ? (
          <Loading size="small" />
        ) : (
          <ModalContent {...props} existingScenarios={scenarios} />
        )
      }
      onCancel={props.onCancel}
    />
  );
};

const ModalContent: FC<
  Props & {
    existingScenarios: Scenario[];
  }
> = ({ onCancel, ...props }) => {
  const { control, onSubmit, formState } = useForm({
    defaultValues: {
      scenarioName: props.data.type === "create" ? "" : props.data.scenarioName,
    },
    existingScenarios: props.existingScenarios,
    onCancel,
    ...props.data,
  });
  const { isSubmitting, isValid, isDirty } = formState;

  return (
    <>
      <Stack gap="32px">
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" color="textPrimary">
            シナリオ名を入力してください。
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Controller
            name="scenarioName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Box>
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          <Button variant="text" borderRadius="circle" color="default" onClick={onCancel}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            borderRadius="circle"
            color="primary"
            onClick={onSubmit}
            disabled={isSubmitting || !isValid || !isDirty}
            isLoading={isSubmitting}
            type="submit"
          >
            {props.data.type === "create" ? "追加" : "変更を保存"}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
