import { CandidateDate } from "../CandidateDate/CandidateDate";

import {
  ICandidateDateWithNumberOfParticipants,
  candidateDateWithNumberOfParticipantsSchema,
} from "./schema";

export class CandidateDateWithNumberOfParticipants
  extends CandidateDate
  implements ICandidateDateWithNumberOfParticipants
{
  readonly numberOfParticipants: number;
  constructor(init: ExcludeMethods<CandidateDate & { numberOfParticipants: number }>) {
    super(init);
    this.numberOfParticipants =
      candidateDateWithNumberOfParticipantsSchema.shape.numberOfParticipants.parse(
        init.numberOfParticipants
      );
  }

  // 候補日程に参加可能かどうかを返す
  canParticipate(): boolean {
    // 定員が設定されていない場合は常に参加可能
    if (!this.capacity) return true;
    return this.numberOfParticipants < this.capacity;
  }

  // 候補日程が定員オーバーしているかどうかを返す
  doesOverCapacity(): boolean {
    return this.numberOfParticipants > (this.capacity || 0);
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 ICandidateDateWithNumberOfParticipants にしている
   */
  static forceConvertToDate(
    candidateDateWithNumberOfParticipants: ExcludeMethods<CandidateDateWithNumberOfParticipants>
  ) {
    return new CandidateDateWithNumberOfParticipants({
      ...candidateDateWithNumberOfParticipants,
      createdAt: new Date(candidateDateWithNumberOfParticipants.createdAt),
      updatedAt: new Date(candidateDateWithNumberOfParticipants.updatedAt),
      from: new Date(candidateDateWithNumberOfParticipants.from),
      until: new Date(candidateDateWithNumberOfParticipants.until),
    });
  }
}
