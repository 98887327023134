import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Modal, Typography, UserIcon } from "~/components/uiParts";

type Props = {
  open: boolean;
  assignees: Employee[];
  onCancel: () => void;
  onSubmit: (employeeIds: string[]) => Promise<void>;
  onClickAddAssignee: (assignees: Employee[]) => void;
};

export const ManageNotifyModal: FC<Props> = ({
  open,
  assignees: _assignees,
  onCancel,
  onSubmit,
  onClickAddAssignee,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [assignees, setAssignees] = useState<Employee[]>(_assignees);

  const handleClickDismissButton = useCallback(
    (targetEmployeeId: string) => {
      setIsLoading(true);
      const newAssigneeIds = assignees.flatMap((v) => (v.id !== targetEmployeeId ? v.id : []));
      onSubmit(newAssigneeIds)
        .then(() => {
          setAssignees((prev) => prev.filter((v) => v.id !== targetEmployeeId));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [assignees, onSubmit]
  );

  const EmployeeItem = ({
    label,
    onClick,
    employee,
  }: {
    label: string;
    onClick: () => void;
    employee: Employee;
  }) => (
    <Box mt={2} display="flex">
      <UserIcon
        username={employee.getName()}
        profileIconImageUrl={employee?.profileIconImageUrl}
        size="small"
        circular
      />
      <Box display="inline-block" ml={2}>
        <Typography variant="body1" bold>
          {employee.getName()}
        </Typography>
        <Typography variant="caption">{employee.email}</Typography>
      </Box>
      <Box ml="auto">
        <Button
          variant="outlined"
          borderRadius="regular"
          color="default"
          onClick={onClick}
          disabled={isLoading}
        >
          {label}
        </Button>
      </Box>
    </Box>
  );

  const Content = (
    <>
      <Box display="flex" justifyContent="space-between">
        <StyledTypography
          variant="body2"
          bold
          color="primary"
          onClick={() => onClickAddAssignee(assignees)}
        >
          対象者を追加
        </StyledTypography>
      </Box>
      {assignees.map((assignee) => (
        <EmployeeItem
          label="削除"
          employee={assignee}
          key={assignee.id}
          onClick={() => handleClickDismissButton(assignee.id)}
        />
      ))}
    </>
  );

  return <Modal open={open} title="通知設定" content={Content} onCancel={onCancel} />;
};

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;
