import { EmailType, LineType } from "@onn/common/domain/Employee/AuthenticationType";
import { useCallback, useState } from "react";

import { Condition } from "./type";

/**
 * 選択中の条件（イベントやタグなど）を管理するhooks
 */
export const useSelectCondition = ({
  defaultCondition,
}: {
  defaultCondition?: Partial<Condition>;
}) => {
  // 選択中の条件（イベントやタグなど）を保持するステート
  const [selectedCondition, setSelectedCondition] = useState<Condition>({
    authenticationFlowTypes: defaultCondition?.authenticationFlowTypes || ["line"],
    recruitmentStatusId: defaultCondition?.recruitmentStatusId || "",
    onnEventIds: defaultCondition?.onnEventIds || [],
    onnTaskIds: defaultCondition?.onnTaskIds || [],
    tagIds: defaultCondition?.tagIds || [],
  });

  const addSelectedAuthenticationFlowTypes = useCallback(
    (authenticationFlowTypes: (LineType | EmailType)[]) => {
      setSelectedCondition((prev) => {
        const authenticationFlowTypeSet = new Set([
          ...prev.authenticationFlowTypes,
          ...authenticationFlowTypes,
        ]);
        return {
          ...prev,
          authenticationFlowTypes: Array.from(
            authenticationFlowTypeSet
          ) as Condition["authenticationFlowTypes"],
        };
      });
    },
    []
  );

  const removeSelectedAuthenticationFlowTypes = useCallback(
    (authenticationFlowTypes: (LineType | EmailType)[]) => {
      setSelectedCondition((prev) => {
        return {
          ...prev,
          authenticationFlowTypes: prev.authenticationFlowTypes.filter(
            (type) => !authenticationFlowTypes.includes(type)
          ) as Condition["authenticationFlowTypes"],
        };
      });
    },
    []
  );

  const changeSelectedRecruitmentStatusId = useCallback((recruitmentStatusId: string) => {
    setSelectedCondition((prev) => {
      return {
        ...prev,
        recruitmentStatusId,
      };
    });
  }, []);

  const addSelectedOnnEventIds = useCallback((onnEventIds: string[]) => {
    setSelectedCondition((prev) => {
      const onnEventSet = new Set([...prev.onnEventIds, ...onnEventIds]);
      return {
        ...prev,
        onnEventIds: Array.from(onnEventSet),
      };
    });
  }, []);

  const removeSelectedOnnEventIds = useCallback((onnEventIds: string[]) => {
    setSelectedCondition((prev) => {
      return {
        ...prev,
        onnEventIds: prev.onnEventIds.filter((id) => !onnEventIds.includes(id)),
      };
    });
  }, []);

  const addSelectedOnnTaskIds = useCallback((onnTaskIds: string[]) => {
    setSelectedCondition((prev) => {
      const onnTaskSet = new Set([...prev.onnTaskIds, ...onnTaskIds]);
      return {
        ...prev,
        onnTaskIds: Array.from(onnTaskSet),
      };
    });
  }, []);

  const removeSelectedOnnTaskIds = useCallback((onnTaskIds: string[]) => {
    setSelectedCondition((prev) => {
      return {
        ...prev,
        onnTaskIds: prev.onnTaskIds.filter((id) => !onnTaskIds.includes(id)),
      };
    });
  }, []);

  const addSelectedTagIds = useCallback((tagIds: string[]) => {
    setSelectedCondition((prev) => {
      const tagSet = new Set([...prev.tagIds, ...tagIds]);
      return {
        ...prev,
        tagIds: Array.from(tagSet),
      };
    });
  }, []);

  const removeSelectedTagIds = useCallback((tagIds: string[]) => {
    setSelectedCondition((prev) => {
      return {
        ...prev,
        tagIds: prev.tagIds.filter((id) => !tagIds.includes(id)),
      };
    });
  }, []);

  return {
    selectedCondition,
    addSelectedAuthenticationFlowTypes,
    removeSelectedAuthenticationFlowTypes,
    changeSelectedRecruitmentStatusId,
    addSelectedOnnEventIds,
    removeSelectedOnnEventIds,
    addSelectedOnnTaskIds,
    removeSelectedOnnTaskIds,
    addSelectedTagIds,
    removeSelectedTagIds,
  };
};
