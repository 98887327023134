import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC } from "react";

import { NoneCell } from "../../../common/NoneCell";
import { Cell } from "../../_share/Cell";

import { Typography } from "~/components/uiParts";

export const UpdatedAtCell: FC<{ updatedAt?: Date }> = ({ updatedAt }) => {
  if (!updatedAt) return <NoneCell />;
  return (
    <Cell>
      <Typography variant="body2" color="textSecondary">
        {format(updatedAt, "MM/dd(E) HH:mm", { locale: ja })}
      </Typography>
    </Cell>
  );
};
