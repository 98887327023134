import { OnnEventEvaluationRank } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee";
import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

const generateKey = (arg: { tenantId: string; spaceId: string }) =>
  ["/get_onn_event_evaluation_ranks", arg.tenantId, arg.spaceId] as const;
export const useOnnEventEvaluationRanks = () => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      spaceId: currentSpace.id,
    }),
    async ([endpoint]) => {
      const response = await apiClient.get(endpoint);
      return response.data.map((d) => new OnnEventEvaluationRank(d));
    }
  );
};
