import { useOnnFormTaskAnswers } from "./useOnnFormTaskAnswers";

// 管理画面でカウントの対象になる回答のみを返すhooks
export const useOnnFormTasksAnswersOnlyCountable = ({ onnTaskId }: { onnTaskId?: string }) => {
  const {
    data: onnFormTaskAnswers,
    isLoading: isLoadingOnnFormTaskAnswers,
    mutate,
  } = useOnnFormTaskAnswers({
    onnTaskId: onnTaskId,
  });

  return {
    isLoading: isLoadingOnnFormTaskAnswers,
    data: onnFormTaskAnswers || [],
    mutate,
  };
};
