import { Box, Menu } from "@material-ui/core";
import { Space } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useMemo, FC, useCallback, Fragment } from "react";
import styled from "styled-components";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  IconButton,
  Loading,
  Typography,
} from "~/components/uiParts";

type NonEmptySpaces = [Space, ...Space[]];

type Props = {
  defaultLabel?: string;
  spaces: NonEmptySpaces;
  selectedSpaceIds: string[];
  isLoading?: boolean;
  onChange: (ids: string[]) => void;
};

export const SpaceFilter: FC<Props> = ({
  defaultLabel = "年次",
  spaces,
  selectedSpaceIds,
  isLoading,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const labelText = useMemo(() => {
    if (isEmpty(selectedSpaceIds) || selectedSpaceIds.length === spaces.length) {
      return defaultLabel;
    }
    return `${selectedSpaceIds.length}個の年次`;
  }, [selectedSpaceIds, spaces.length, defaultLabel]);

  const handleSetNewSpaceIds = useCallback(
    (currentSelected: Space) => {
      const newIds = selectedSpaceIds.includes(currentSelected.id)
        ? selectedSpaceIds.filter((spaceId) => spaceId !== currentSelected.id)
        : [...selectedSpaceIds, currentSelected.id];
      return onChange(newIds);
    },
    [onChange, selectedSpaceIds]
  );

  const alreadySelected = useCallback(
    (spaceId: string) => selectedSpaceIds.includes(spaceId),
    [selectedSpaceIds]
  );

  return (
    <>
      <Box position="relative">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" noWrap>
              {labelText}
            </Typography>
            <StyledIcon icon="filter" size="sm" color="grey" $isEmpty={isEmpty(selectedSpaceIds)} />
          </Box>
        </Button>
        {/* Buttonのhoverも効いてしまうので内包しないようにする */}
        {!isEmpty(selectedSpaceIds) && (
          <StyledIconButton icon="close" size="sm" color="grey" onClick={() => onChange([])} />
        )}
      </Box>

      <Menu
        key="statusFilter"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: -8, horizontal: "right" }}
      >
        {isLoading ? (
          <Loading size="large" />
        ) : (
          <>
            <Box py="16px" width="318px" display="flex" flexDirection="column">
              <Box px="24px" maxHeight={300} overflow="auto" display="flex" flexDirection="column">
                {spaces.map((space) => (
                  <Fragment key={space.id}>
                    <StyledFormControlLabel
                      onChange={() => handleSetNewSpaceIds(space)}
                      control={
                        <Checkbox
                          checked={alreadySelected(space.id)}
                          name={space.id}
                          value={space.id}
                        />
                      }
                      disabled={false}
                      label={
                        <Box
                          width="100%"
                          display="flex"
                          alignItems="center"
                          gridGap="4px"
                          overflow="hidden"
                        >
                          <StyledTypography variant="body2" noWrap>
                            {space.name}
                          </StyledTypography>
                        </Box>
                      }
                    />
                  </Fragment>
                ))}
              </Box>
            </Box>
            <Box mb="8px" px="24px">
              <StyledResetTypography variant="body2" color="primary" onClick={() => onChange([])}>
                全てクリア
              </StyledResetTypography>
            </Box>
          </>
        )}
      </Menu>
    </>
  );
};

// ====================
// style
// ====================

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;

const StyledResetTypography = styled(Typography)`
  cursor: pointer;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 8px;
    color: ${(props) => props.theme.palette.text.secondary}
`;
