import { CandidateDate, OnnEvent } from "../../../../../domain";

export const convertUnixTimeToDateOnnEvent = (
  d: DeepConvertValueType<
    DeepConvertValueType<ExcludeMethods<OnnEvent>, Date, number>,
    Date | undefined,
    number | undefined
  >
): OnnEvent => {
  return new OnnEvent({
    ...d,
    deadlineDate: d.deadlineDate ? new Date(d.deadlineDate) : undefined,
    createdAt: new Date(d.createdAt),
    updatedAt: new Date(d.updatedAt),
    candidateDates: d.candidateDates.map(
      (cd) =>
        new CandidateDate({
          ...cd,
          from: new Date(cd.from),
          until: new Date(cd.until),
          createdAt: new Date(cd.createdAt),
          updatedAt: new Date(cd.updatedAt),
        })
    ),
    scheduledDate: d.scheduledDate ? new Date(d.scheduledDate) : undefined,
    firstDeliveredAt: d.firstDeliveredAt ? new Date(d.firstDeliveredAt) : undefined,
  });
};
