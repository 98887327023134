import { createTheme } from "@mui/material";

// TODO: 既存のカラーコードを転記している。確認したら置き換える https://www.figma.com/design/40gvwVYg21k2kXUSQLUjNz?node-id=267-1715#846034005
export const colors = {
  primary: {
    light: "#D4F7F0",
    main: "#27E0B7",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#FFE5E4",
    main: "#FC7878",
    contrastText: "#ffffff",
  },
  grey: {
    50: "#F7F7F7",
    100: "#E6E6E6",
    200: "#CCCCCC",
    300: "#959595",
    400: "#757575",
    500: "#404040",
  },
  blue: {
    light: "#C8EFFE",
    main: "#27C1FC",
    contrastText: "#ffffff",
  },
  error: {
    main: "#E00B75",
    dark: "#E0340B",
    light: "#fd7878",
  },
  text: {
    primary: "#404040",
    secondary: "#757575",
    muted: "#959595",
  },
  background: {
    default: "#fff",
  },
  divider: "#E8E8E8",
  action: {
    active: "#000000",
  },
};

export const themeForMuiV5 = createTheme({
  palette: colors,
  typography: {
    h1: {
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: 32,
      letterSpacing: 0,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 1.3,
    },
    h4: {
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 1.3,
    },
    body1: {
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 1.7,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 1.7,
    },
    button: {
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    overline: {
      fontSize: 10,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
  },
});
