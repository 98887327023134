import { z } from "zod";

/////////////////////////////////////////
// PORTAL RICH MENU RELATION V 2 SCHEMA
/////////////////////////////////////////

export const PortalRichMenuRelationV2Schema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  scenarioId: z.string(),
  recruitmentStatusId: z.string(),
  internalRichMenuId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type PortalRichMenuRelationV2 = z.infer<typeof PortalRichMenuRelationV2Schema>;

/////////////////////////////////////////
// PORTAL RICH MENU RELATION V 2 OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const PortalRichMenuRelationV2OptionalDefaultsSchema = PortalRichMenuRelationV2Schema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type PortalRichMenuRelationV2OptionalDefaults = z.infer<
  typeof PortalRichMenuRelationV2OptionalDefaultsSchema
>;

export default PortalRichMenuRelationV2Schema;
