import liff from "@line/liff";
import { Box, Link } from "@material-ui/core";
import { RegistrationInvitationLink } from "@onn/common";

import { isEmpty } from "lodash";
import React, { useState, FC, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";

import { useEmailInput } from "../hooks/useEmailInput";

import { TermsOfUse } from "~/components/domains/account/_shared";
import { TextFieldDeprecated, Paper, Button, Typography } from "~/components/uiParts";
import {
  useAuthenticationNonGuarded,
  useAuthorizationNonGuarded,
  useLineAccessToken,
} from "~/hooks/context";
import { useCreateUnregisteredNewGraduate } from "~/hooks/employee";
import { useAuthorizeForRegistrationInvitationLink } from "~/hooks/employee/useAuthorizeForRegistrationInvitationLink";
import { useSignUpByLine } from "~/hooks/employee/useSignUpByLine";
import { useLiffId } from "~/hooks/liff";
import { useRedirectAfterRegistration } from "~/hooks/portal/redirect";
import { useSnackbar } from "~/hooks/shared";
import { mixin } from "~/util";

export const ShareInviteByLineAuthOnLiff: FC<{
  registrationInvitationLink: RegistrationInvitationLink;
}> = ({ registrationInvitationLink }) => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const liffId = useLiffId();
  const { enqueueSnackbar } = useSnackbar();
  const { redirectAfterRegistration } = useRedirectAfterRegistration();

  const emailInput = useEmailInput({ email });
  const { guardAndGetLineAccessTokenFromLiff } = useLineAccessToken();
  const { signUpByLineRegistrationInvitationLink, loading: isSignUpLoading } = useSignUpByLine();
  const { createUnregisteredNewGraduate } = useCreateUnregisteredNewGraduate();

  const { authorize } = useAuthorizeForRegistrationInvitationLink();

  const { isAuthenticated } = useAuthenticationNonGuarded();
  const { authorizedUsers } = useAuthorizationNonGuarded();

  const [isLoadingError, setIsLoadingError] = useState<boolean>(false);

  const isDisableSignUpButton = useMemo(() => {
    return (
      emailInput.error ||
      firstName.trim().length === 0 ||
      lastName.trim().length === 0 ||
      firstNameError ||
      lastNameError ||
      !isAgreed
    );
  }, [emailInput.error, firstName, firstNameError, isAgreed, lastName, lastNameError]);

  const checkName = useCallback(
    (
      value: string,
      setValue: (value: string) => void,
      setError?: (error: boolean) => void
    ): void => {
      setValue(value);

      if (!setError) return;

      if (value.length > 0) {
        setError(false);
      } else {
        setError(true);
      }
    },
    []
  );

  const handleSubmit = useCallback(async () => {
    const lineAccessToken = liff.isInClient() ? guardAndGetLineAccessTokenFromLiff() : undefined;
    if (!lineAccessToken) {
      return;
    }

    if (isAuthenticated) {
      await authorize({
        registrationInvitationLinkId: registrationInvitationLink.id,
        email: emailInput.email,
        firstName,
        lastName,
        lineAccessToken,
      });
    } else {
      await signUpByLineRegistrationInvitationLink({
        registrationInvitationLinkId: registrationInvitationLink.id,
        email: emailInput.email,
        firstName,
        lastName,
        lineAccessToken,
      });
    }

    // redirectAfterRegistration はここでは呼ばない
    // authorize, signUpByLineRegistrationInvitationLink でそれぞれ呼び出されるため。
  }, [
    guardAndGetLineAccessTokenFromLiff,
    isAuthenticated,
    authorize,
    registrationInvitationLink.id,
    emailInput.email,
    firstName,
    lastName,
    signUpByLineRegistrationInvitationLink,
  ]);

  useEffect(() => {
    const lineAccessToken = liff.isInClient() ? guardAndGetLineAccessTokenFromLiff() : undefined;
    if (liffId && lineAccessToken) {
      const asyncFn = async () => {
        const result = await createUnregisteredNewGraduate({
          registrationInvitationLinkId: registrationInvitationLink.id,
          lineAccessToken,
          liffId,
        });
        // RegisteredError の場合の画面遷移は、他の useEffect で行なっている
        if (result === "Error") {
          enqueueSnackbar("エラーが発生しました", { variant: "error" });
          setIsLoadingError(true);
        }
      };
      asyncFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // NOTE: 公式アカウント友だち追加せずに同じ共通登録ページに再度アクセスした場合、友だち追加QRページに遷移する
  // NOTE: 登録済みの場合は、登録済みであることを表示してポータルへ遷移する
  useEffect(() => {
    if (!authorizedUsers || isEmpty(authorizedUsers)) {
      return;
    }

    // NOTE: 複数テナント登録時には別テナントが useCurrentUserNonGuarded の currentUser になる可能性があるため find している
    const currentUser = authorizedUsers.find(
      (u) => u.tenantId === registrationInvitationLink.tenantId
    );
    if (!currentUser) {
      return;
    }

    const shouldNavigateToLineQR = !currentUser.isFollowedLineOfficialAccount;
    if (!shouldNavigateToLineQR) {
      enqueueSnackbar("すでにアカウントが登録されています", { variant: "info" });
    }

    redirectAfterRegistration(currentUser);
    return;
  }, [
    authorizedUsers,
    enqueueSnackbar,
    redirectAfterRegistration,
    registrationInvitationLink.id,
    registrationInvitationLink.tenantId,
  ]);

  if (isLoadingError) {
    return (
      <Box textAlign="center" mt={5}>
        <StyledPaper>
          <Typography variant="body2">
            エラーが発生しました。
            <br />
            このリンクからアカウント登録をすることはできません。
          </Typography>
        </StyledPaper>
      </Box>
    );
  }

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        <Box>
          <StyledForm
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display="flex" flexDirection="column">
              <StyledTypographyTitle variant="h2" color="textPrimary" bold>
                アカウント情報入力
              </StyledTypographyTitle>
              <StyledTypographyDescription variant="body1" display="block">
                アカウントに必要な情報を入力してください。
              </StyledTypographyDescription>
              <StyledTypographySectionTitle variant="body2" gutterBottom>
                氏名
              </StyledTypographySectionTitle>
              <StyledBoxSection display="flex" gridColumnGap="16px">
                <TextFieldDeprecated
                  fullWidth
                  error={lastNameError}
                  placeholder="姓"
                  label={null}
                  name="lname"
                  variant="outlined"
                  type="text"
                  size="medium"
                  helperText={lastNameError && "姓を入力してください"}
                  onChange={(event) => checkName(event.target.value, setLastName, setLastNameError)}
                />
                <TextFieldDeprecated
                  fullWidth
                  error={firstNameError}
                  placeholder="名"
                  label={null}
                  name="fname"
                  variant="outlined"
                  type="text"
                  size="medium"
                  helperText={firstNameError && "名前を入力してください"}
                  onChange={(event) =>
                    checkName(event.target.value, setFirstName, setFirstNameError)
                  }
                />
              </StyledBoxSection>
              <StyledBoxSection>
                <StyledTypographySectionTitle variant="body2" gutterBottom>
                  メールアドレス
                </StyledTypographySectionTitle>
                <TextFieldDeprecated
                  placeholder="メールアドレス"
                  label={null}
                  name="email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  size="medium"
                  helperText={emailInput.isChanged && emailInput.helperText}
                  error={emailInput.isChanged && emailInput.error}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </StyledBoxSection>
            </Box>

            <StyledTypographyMinusMargin variant="body1" color="textPrimary" bold gutterBottom>
              情報の取扱いに関する同意
            </StyledTypographyMinusMargin>
            <TermsOfUse isAgreed={isAgreed} isNewGrad onChange={() => setIsAgreed((prv) => !prv)} />
            <Box
              textAlign="center"
              color="textSecondary"
              lineHeight="24px"
              mt={5}
              mb={4}
              pl={3}
              pr={3}
            >
              <Typography variant="caption">アカウントを作成することにより、Onnの</Typography>
              <Link
                href="https://onn-hr.com/privacy_policy"
                underline="always"
                target="_blank"
                color="textSecondary"
              >
                <Typography variant="caption">プライバシーポリシー</Typography>
              </Link>
              <Typography variant="caption">に同意するものとします。</Typography>
            </Box>
            <Box textAlign="center" mt={2} mb={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                borderRadius="circle"
                color="primary"
                isLoading={isSignUpLoading}
                disabled={isDisableSignUpButton || isSignUpLoading}
              >
                {isSignUpLoading ? "送信中" : "登録"}
              </Button>
            </Box>
          </StyledForm>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.portalSp`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.portalSp`
      box-shadow: none;
      `}
  }
`;

const StyledForm = styled.form`
  width: 100%;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledTypographyTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 24px;
  }
`;

const StyledTypographyDescription = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 48px;
  }
`;

const StyledTypographySectionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 16px;
    display: block;
  }
`;

const StyledBoxSection = styled(Box)`
  margin-bottom: 32px;
`;

// TODO: CheckTerms の mt が消えたら消す
const StyledTypographyMinusMargin = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: -24px;
    display: block;
  }
`;
