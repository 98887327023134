import { MidCarrierTenant } from "./MidCarrierTenant";
import { NewGraduateTenant } from "./NewGraduateTenant";

export enum ChannelType {
  EMAIL = "EMAIL",
  SLACK = "SLACK",
}

/**
 * Tenant
 * - ユーザーが編集可能な値は TenantSettings
 */
export class Tenant {
  constructor(
    readonly tenantId: string,
    readonly tenantName: string,
    readonly deleted: boolean, // 解約済みかどうか
    readonly isIntegratedSlack: boolean,
    readonly isActiveNewGraduate: boolean, // 新卒向けテナントかどうか
    readonly lineInvitationLink?: string, // インストールしたOnn Appに対して付与されたSlackのアクセストークン
    /**
     * ログインチャネルのLIFF ID(新卒向けの場合は必須)
     */
    readonly lineLiffId?: string,
    readonly lineLoginChannelClientId?: string,
    readonly prefixForEmployeeUniqueId?: string
  ) {}

  public static isNewGraduateTenant(v: Tenant): v is NewGraduateTenant {
    return v.isActiveNewGraduate;
  }

  public static isMidCarrierTenant(v: Tenant): v is MidCarrierTenant {
    return !v.isActiveNewGraduate;
  }

  public static plainToInstance(init: ExcludeMethods<Tenant>): Tenant {
    return new Tenant(
      init.tenantId,
      init.tenantName,
      init.deleted,
      init.isIntegratedSlack,
      init.isActiveNewGraduate,
      init.lineInvitationLink,
      init.lineLiffId,
      init.lineLoginChannelClientId,
      init.prefixForEmployeeUniqueId
    );
  }
}
