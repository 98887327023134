import { z } from "zod";

import {
  ChangeRecruitmentStatusTriggerSettingSchema,
  AnswerOnnTaskTriggerSettingSchema,
  EvaluateOnnEventTriggerSettingSchema,
  ChangeStatusOfOnnEventTriggerSettingSchema,
  AnswerOnnEventTriggerSettingSchema,
} from "../../_gen/zodSchema/index";

export const changeRecruitmentStatusTriggerSettingSchema =
  ChangeRecruitmentStatusTriggerSettingSchema;

export const answerOnnEventTriggerSettingSchema = AnswerOnnEventTriggerSettingSchema;

export const answerOnnTaskTriggerSettingSchema = AnswerOnnTaskTriggerSettingSchema;

export const evaluateOnnEventTriggerSettingSchema = EvaluateOnnEventTriggerSettingSchema;

export const changeStatusOfOnnEventTriggerSettingSchema =
  ChangeStatusOfOnnEventTriggerSettingSchema;

export const anyTriggerSettingWithTypeSchema = z.union([
  changeRecruitmentStatusTriggerSettingSchema.merge(
    z.object({
      type: z.literal("ChangeRecruitmentStatusTriggerSetting"),
    })
  ),
  answerOnnTaskTriggerSettingSchema.merge(
    z.object({
      type: z.literal("AnswerOnnTaskTriggerSetting"),
    })
  ),
  evaluateOnnEventTriggerSettingSchema.merge(
    z.object({
      type: z.literal("EvaluateOnnEventTriggerSetting"),
    })
  ),
  changeStatusOfOnnEventTriggerSettingSchema.merge(
    z.object({
      type: z.literal("ChangeStatusOfOnnEventTriggerSetting"),
    })
  ),
  answerOnnEventTriggerSettingSchema.merge(
    z.object({
      type: z.literal("AnswerOnnEventTriggerSetting"),
    })
  ),
]);
