import { z } from "zod";

/////////////////////////////////////////
// LINK RICH MENU TRANSACTION SCHEMA
/////////////////////////////////////////

export const LinkRichMenuTransactionSchema = z.object({
  id: z.string(),
  lineUserId: z.string(),
  completedAt: z.date().nullish(),
  richMenuName: z.string().nullish(),
  tenantId: z.string(),
  createdAt: z.date(),
});

export type LinkRichMenuTransaction = z.infer<typeof LinkRichMenuTransactionSchema>;

/////////////////////////////////////////
// LINK RICH MENU TRANSACTION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const LinkRichMenuTransactionOptionalDefaultsSchema = LinkRichMenuTransactionSchema.merge(
  z.object({})
);

export type LinkRichMenuTransactionOptionalDefaults = z.infer<
  typeof LinkRichMenuTransactionOptionalDefaultsSchema
>;

export default LinkRichMenuTransactionSchema;
