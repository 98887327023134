import { Box } from "@material-ui/core";
import { Reminder } from "@onn/common";

import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components";

import { Tooltip, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

type Props = {
  reminders: Reminder[];
  isLoading: boolean;
  createReminder: (args: { title: string; remindedAt: Date }) => Promise<void>;
};

export const CreateReminderButton: FC<Props> = React.memo(
  ({ reminders, isLoading, createReminder }) => {
    const { handleModal } = useModal();

    const handleCreateButtonClick = useCallback(() => {
      handleModal({
        name: "createReminderModal",
        args: {
          onSubmit: async (params: { title: string; remindedAt: Date }) => {
            await createReminder(params);
          },
        },
      });
    }, [createReminder, handleModal]);

    const canCreate = !isLoading && reminders.length < 3;

    const button = useMemo(() => {
      if (canCreate) {
        return (
          <StyledTypography
            variant="body2"
            color="primary"
            onClick={handleCreateButtonClick}
            $disable={false}
            bold
          >
            + リマインダーを追加
          </StyledTypography>
        );
      }

      return (
        <Tooltip
          title="リマインダーの登録数上限は、各コンタクトルームごとに3件までです。"
          placement="top-start"
        >
          <StyledTypography variant="body2" color="textSecondary" bold $disable={true}>
            + リマインダーを追加
          </StyledTypography>
        </Tooltip>
      );
    }, [canCreate, handleCreateButtonClick]);

    return <Box mt="24px">{button}</Box>;
  }
);

const StyledTypography = styled(Typography)<{ $disable: boolean }>`
  &.MuiTypography-root {
    cursor: ${(props) => (props.$disable ? "not-allowed" : "pointer")};
    ${(props) => (props.$disable ? `color: ${props.theme.palette.grey[200]}` : "")};
  }
`;
