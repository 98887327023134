import { Box } from "@material-ui/core";
import { Employee, Announcement, AnnouncementDistribution } from "@onn/common";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { AnnouncementContents } from "./AnnouncementContent/AnnouncementContents";
import { AnnouncementDetailSummary } from "./AnnouncementDetailSummary/AnnouncementDetailSummary";

import { Loading, Icon, Typography } from "~/components/uiParts";

import { useAnnouncement } from "~/hooks/announcement/useAnnouncement";
import { useAnnouncementDistributionsByAnnouncementId } from "~/hooks/announcementDistribution/useAnnouncementDistributionsByAnnouncementId";
import { useEmployee } from "~/hooks/employee";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { NotFound } from "~/pages/NotFound";

export const Page = () => {
  const { id: announcementId } = useParams<"id">();
  const { switchSpaceTemporary } = useCurrentSpace();

  const { data: announcement, isLoading: isLoadingAnnouncement } = useAnnouncement({
    announcementId: announcementId as string,
  });
  const { data: creator, isLoading: isLoadingCreator } = useEmployee(announcement?.creatorId);
  const { data: announcementDistributions = [], isLoading: isLoadingAnnouncementDistributions } =
    useAnnouncementDistributionsByAnnouncementId({
      announcementId,
    });

  useEffect(() => {
    if (!announcement) return;
    switchSpaceTemporary(announcement.spaceId);
  }, [announcement, switchSpaceTemporary]);

  const isLoading = isLoadingAnnouncement || isLoadingCreator || isLoadingAnnouncementDistributions;
  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!announcement || !announcementDistributions || !creator) {
    return <NotFound />;
  }

  return (
    <PageCore
      announcement={announcement}
      creator={creator}
      announcementDistributions={announcementDistributions ?? []}
    />
  );
};

const PageCore = ({
  announcement,
  creator,
  announcementDistributions,
}: {
  announcement: Announcement;
  creator: Employee;
  announcementDistributions: AnnouncementDistribution[];
}) => {
  return (
    <>
      <StyledLink to="/tools#announcements">
        <Box display="flex" alignItems="center" gridGap="8px">
          <Icon icon="arrowLeft" color="grey" size="md" />
          <Typography variant="body2" color="textSecondary" style={{ lineHeight: "24px" }}>
            お知らせ一覧
          </Typography>
        </Box>
      </StyledLink>
      <AnnouncementDetailSummary
        announcement={announcement}
        numberOfReads={announcementDistributions.filter((d) => d.isRead()).length}
        numberOfDistribution={announcementDistributions.length}
        creator={creator}
      />
      <AnnouncementContents announcement={announcement} />
    </>
  );
};
const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin-bottom: 48px;
`;
