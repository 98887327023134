import React, { FC } from "react";
import styled from "styled-components";

import { RichMenuImageFilePicker } from "./RichMenuImageFilePicker";

export const PreviewRichMenuImageFile: FC<{
  src?: string;
}> = ({ src }) => {
  return (
    <RichMenuImageFilePicker>
      <StyledImage src={src} alt="thumbnail" />
    </RichMenuImageFilePicker>
  );
};

const StyledImage = styled.img`
  width: 100%;
  &:hover {
    opacity: 0.33;
    cursor: pointer;
  }
`;
