import liff from "@line/liff";
import { useSearchParams } from "react-router-dom";

import { useSessionStorage } from "../shared";

import { captureException } from "~/util";

export const STORAGE_KEY_LIFF_ID = "liffId";

/**
 * セッションストレージ経由で liff-id を取得する
 */
export const useLiffId = () => {
  const { storeValue, retrieveValue } = useSessionStorage();
  const [searchParams] = useSearchParams();

  const liffId = searchParams.get("liff-id");

  if (liffId) {
    storeValue(STORAGE_KEY_LIFF_ID, liffId);
  }

  if (liff.isInClient() && !liffId && !retrieveValue<string>(STORAGE_KEY_LIFF_ID)) {
    captureException({
      error: new Error("[要確認]LIFF IDがLIFFブラウザで取得できませんでした"),
      tags: {
        type: "LIFF ID is not found when on LIFF browser",
      },
      extras: {
        location: window.location.href,
        isCookieEnabled: navigator.cookieEnabled,
      },
    });
  }

  return liffId ?? retrieveValue<string>(STORAGE_KEY_LIFF_ID);
};
