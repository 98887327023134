import { z } from "zod";

/////////////////////////////////////////
// DELIVER ONN EVENT ACTION SETTING SCHEMA
/////////////////////////////////////////

export const DeliverOnnEventActionSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  actionId: z.string(),
  onnEventId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type DeliverOnnEventActionSetting = z.infer<typeof DeliverOnnEventActionSettingSchema>;

/////////////////////////////////////////
// DELIVER ONN EVENT ACTION SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const DeliverOnnEventActionSettingOptionalDefaultsSchema =
  DeliverOnnEventActionSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type DeliverOnnEventActionSettingOptionalDefaults = z.infer<
  typeof DeliverOnnEventActionSettingOptionalDefaultsSchema
>;

export default DeliverOnnEventActionSettingSchema;
