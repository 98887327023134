import { Box, Divider } from "@material-ui/core";
import { OnnEvent, OnnEventAnswer, OnnEventDeterminedDate } from "@onn/common";
import { format, isToday } from "date-fns";
import { ja } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Button, RadioButtonGroup, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useCreateEmployeeActiveLog } from "~/hooks/employeeActiveLog";
import { useAttendOnnEvent } from "~/hooks/onnEvent/useAttendOnnEvent";
import { useSnackbar } from "~/hooks/shared";
import { captureException, mixin } from "~/util";

type Props = {
  eventData: {
    onnEvent: OnnEvent;
    onnEventAnswer: OnnEventAnswer | null;
    onnEventDeterminedDate: OnnEventDeterminedDate | null;
  };
  complete: () => void;
};

export const Form: FC<Props> = ({ eventData: { onnEventAnswer, ...eventData }, complete }) => {
  const { currentUser } = useCurrentUser();
  const { createEmployeeLog } = useCreateEmployeeActiveLog();
  const { enqueueSnackbar } = useSnackbar();
  const { attendOnnEvent } = useAttendOnnEvent();

  const candidateDates = useMemo(
    () =>
      eventData.onnEvent.candidateDates.filter((date) =>
        isToday(utcToZonedTime(date.from, "Asia/Tokyo"))
      ),
    [eventData.onnEvent.candidateDates]
  );
  const defaultSelectedValue = useMemo(
    () =>
      onnEventAnswer && onnEventAnswer.isAnswered() && onnEventAnswer.answer
        ? (() => {
            const answeredCandidateDateId = onnEventAnswer.getPossibleCandidateDateId();
            const index = candidateDates.findIndex((date) => date.id === answeredCandidateDateId);
            return 0 <= index ? index : null;
          })()
        : null,
    [onnEventAnswer, candidateDates]
  );

  const [selectedValue, setSelectedValue] = useState<number | null>(defaultSelectedValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!eventData) return;

    createEmployeeLog(
      "VISITED_ONN_EVENT_ATTENDANCE_PAGE",
      eventData.onnEvent.id,
      currentUser.tenantId
    );
    // NOTE: アクセスしたタイミングのみ記録するため、eslint-disable-next-lineを記述
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!eventData) return;

    try {
      if (selectedValue == null) return;
      const targetCandidateDate = candidateDates[selectedValue];
      if (!targetCandidateDate) throw new Error();

      setIsLoading(true);
      await attendOnnEvent({
        onnEventId: eventData.onnEvent.id,
        candidateDateId: targetCandidateDate.id,
      });
      complete();
    } catch (e) {
      enqueueSnackbar("参加登録に失敗しました。", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "OnnEventAttendance:handleSubmit" },
      });
    }
    setIsLoading(false);
  }, [eventData, selectedValue, candidateDates, attendOnnEvent, complete, enqueueSnackbar]);

  const options = useMemo(() => {
    const candidateDatesOptions = candidateDates.map((date, index) => {
      const labelDate = `${format(date.from, "MM/dd(E) HH:mm", { locale: ja })}〜${format(
        date.until,
        "HH:mm"
      )}`;

      return {
        index,
        option: labelDate,
        isOther: false,
      };
    });
    return candidateDatesOptions;
  }, [candidateDates]);

  return (
    <StyledBox maxWidth="848px">
      <Typography variant="h2" color="textPrimary" style={{ fontWeight: 400 }}>
        参加登録
      </Typography>
      <StyledCard>
        <Box mb={1}>
          <Typography variant="body2" color="textPrimary">
            「{eventData.onnEvent.title}
            」の参加登録ページです。以下の日程から参加している日程を選択し、参加登録を完了しましょう。
          </Typography>
        </Box>
        <Divider />
        <Box my={4}>
          <StyledRadioButtonGroup
            options={options}
            name="answer"
            value={selectedValue}
            onChange={(value: number) => setSelectedValue(value)}
            onChangeFreeAnswerText={() => undefined}
          />
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          borderRadius="circle"
          onClick={handleSubmit}
          disabled={selectedValue == null}
          isLoading={isLoading}
        >
          参加登録
        </Button>
      </StyledCard>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;

  ${mixin.portalSp`
    background-color: white;
    padding: 40px 24px;
    width: 100%;
    height: 100%;
  `}
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 40px;
  padding: 80px;

  ${mixin.portalSp`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 28px;
    padding: 0px;
    width: 100%;
  `}
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  &.MuiFormGroup-root {
    gap: 8px;
  }
`;
