import { LatestContactMessage } from "@onn/common";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

// TODO: いつか spaceId を入れたい
const getKey = ({
  tenantId,
  contactRoomIds,
  limit,
  isPreview,
}: {
  tenantId: string;
  contactRoomIds?: string[];
  limit?: number;
  isPreview?: boolean;
}) => {
  return {
    endpoint: "/get_contact_rooms_latest_messages",
    tenantId,
    contactRoomIds,
    limit,
    isPreview,
  } as const;
};

/**
 * limit を指定していない場合、mutate は高負荷な処理になる可能性があるため、注意が必要
 */
export const useLatestContactMessage = ({
  tenantId,
  contactRoomIds,
  limit,
  isPreview,
}: {
  tenantId: string;
  contactRoomIds?: string[];
  limit?: number;
  isPreview?: boolean;
}) => {
  // NOTE: mutate したい場合は、useMutateAllLatestContactMessageByContactRoomId を使うことで、特定のコンタクトルームの最新メッセージを更新する
  return useSWR(
    getKey({ tenantId, contactRoomIds, limit, isPreview }),
    async ({ endpoint, contactRoomIds, limit, isPreview }) => {
      if (isPreview) return;

      const response = await apiClient.get(endpoint, { limit, contactRoomIds });
      return response.data.map((d) => {
        return new LatestContactMessage(d);
      });
    },
    { keepPreviousData: true }
  );
};

const throttleDelay = 2000; // 一旦 2 秒に設定 (負荷によっては調整する)
/**
 * 仕様:
 * - throttleDelay 秒以内に同じcontactRoomIdで呼び出された場合、最後の呼び出しから throttleDelay 秒経過するまで処理を実行せず、ID プールに contactRoomId を追加する
 * - throttleDelay秒経過後、ID プールにある contactRoomId を元に処理を自動的に実行し、ID プールをクリアする
 */
export const useMutateAllLatestContactMessageByContactRoomId = ({
  tenantId,
}: {
  tenantId: string;
}): { mutateByContactRoomId: (contactRoomId: string) => Promise<void> } => {
  // atomic な処理を保証するため、またメモ化のために setState の 更新用関数を利用している。
  // state は setState 内部から参照し、ここから直接参照しない。
  const [, setContactRoomIdsToMutate] = useState<string[]>([]);
  const [, setCalledOnce] = useState<boolean>(false);

  const mutateByContactRoomIds = useCallback(
    async (contactRoomIds: string[]) => {
      return mutate<LatestContactMessage[] | undefined>(
        getKey({ tenantId }),
        async (prevLatestContactMessages) => {
          if (!prevLatestContactMessages) return prevLatestContactMessages;

          const response = await apiClient.get("/get_contact_rooms_latest_messages", {
            ids: contactRoomIds,
            limit: contactRoomIds.length,
          });
          const latestContactMessages = response.data;

          if (isEmpty(latestContactMessages)) return prevLatestContactMessages;

          return prevLatestContactMessages.map((prevMessage) => {
            const latestContactMessage = latestContactMessages.find(
              (l) => l.contactRoomId === prevMessage.contactRoomId
            );
            if (latestContactMessage) {
              return new LatestContactMessage(latestContactMessage);
            }
            return prevMessage;
          });
        },
        { revalidate: false }
      );
    },
    [tenantId]
  );

  const mutateByContactRoomId = useCallback(
    async (contactRoomId: string) => {
      setCalledOnce((prevIsCalledOnce) => {
        if (!prevIsCalledOnce) {
          setContactRoomIdsToMutate((prev) => {
            mutateByContactRoomIds([...prev, contactRoomId]);
            return [];
          });
        } else {
          setContactRoomIdsToMutate((prev) => {
            return [...prev, contactRoomId];
          });
        }
        return true;
      });
    },
    [mutateByContactRoomIds]
  );

  useEffect(() => {
    const timeout = setInterval(async () => {
      // 実行時の state を使うために更新用関数内で mutate する
      setContactRoomIdsToMutate((prev) => {
        if (prev.length > 0) {
          mutateByContactRoomIds(prev);
        }
        return [];
      });
    }, throttleDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [mutateByContactRoomIds]);

  return { mutateByContactRoomId };
};
