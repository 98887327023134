import { zodResolver } from "@hookform/resolvers/zod";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import styled from "styled-components";

import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { TextField } from "~/components/uiParts";

const inputSchema = z.object({ value: z.string() });
type InputState = z.infer<typeof inputSchema>;
type Props = {
  onSave: ({ value }: { value: string }) => void;
  onCancel: () => void;
  value?: string;
};

export const CustomEmployeeInformationTextTypeEditor: FC<Props> = ({ onSave, onCancel, value }) => {
  const { handleSubmit, control, formState } = useForm<InputState>({
    defaultValues: {
      value,
    },
    mode: "onChange",
    resolver: zodResolver(inputSchema),
  });

  return (
    <InformationValueEditor
      form={
        <form style={{ display: "flex", columnGap: 16 }} onSubmit={handleSubmit(onSave)}>
          <Controller
            name="value"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                value={field.value}
                placeholder="テキストを入力" // TODO: サンプルデータを確認して適切なプレースホルダーを設定できるようにする https://app.clickup.com/t/86eqaurf1
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;
