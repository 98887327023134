import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/api/onn-event-answers/id/attendance-status"]["PATCH"];

export const useUpdateAttendanceStatus = () => {
  const execUpdateAttendanceStatus = useCallback(async (args: EndPoint["body"]): Promise<void> => {
    await apiClient.patch("/api/onn-event-answers/id/attendance-status", {
      ...args,
    });
  }, []);

  return { execUpdateAttendanceStatus };
};
