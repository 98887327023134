import { APISchema, SpaceSetting } from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/space_api/space-settings"]["GET"];

const generateKey = ({ tenantId }: { tenantId: string }) => {
  return {
    endpoint: "/space_api/space-settings",
    tenantId,
    type: "allSpaceSettings",
  } as const;
};

export const useSpaceSettings = () => {
  const { currentUser } = useCurrentUser();
  const key = generateKey({
    tenantId: currentUser.tenantId,
  });

  return useSWR(key, async ({ endpoint, tenantId }) => {
    const query: EndPoint["query"] = {
      tenantId,
    };
    const response = await apiClient.get(endpoint, query);
    return response.data.spaceSettings.map((spaceSetting) => new SpaceSetting(spaceSetting));
  });
};

export const useMutateSpaceSettings = () => {
  const { currentUser } = useCurrentUser();

  const { mutate } = useSWRConfig();

  const mutateSpaceSettings = useCallback(() => {
    mutate(generateKey({ tenantId: currentUser.tenantId }));
  }, [mutate, currentUser.tenantId]);

  return { mutateSpaceSettings };
};
