import {
  Dialog,
  Box,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import React, { FC, ComponentProps, useCallback } from "react";
import styled from "styled-components";

import { Divider } from "../Divider";

import { Icon } from "~/components/uiParts/Icon";
import { IconButton } from "~/components/uiParts/IconButton";
import { Tooltip } from "~/components/uiParts/Tooltip";
import { Typography } from "~/components/uiParts/Typography";

export const useBlurBackdrop = makeStyles(() => ({
  backDrop: {
    backdropFilter: "blur(10px)",
  },
}));

type Props = Omit<ComponentProps<typeof Dialog>, "content"> & {
  open: boolean;
  title?: string;
  titleSize?: ComponentProps<typeof Typography>["variant"];
  titleHint?: string;
  content: JSX.Element;
  onCancel?: () => void;
  footer?: JSX.Element;
  isSpMode?: boolean;
  disableBackdropModal?: boolean;
  isShowFooterDivider?: boolean;
};

/**
 * ModalのContentに渡したもののみがスクロールするモーダル
 * 既存のModalコンポーネントだとモーダル全体がスクロールしてしまうため中身のみスクロールさせたい場合に使用する
 * Content の中でも一部のみをスクロールさせるときはその要素にのみ `overflowY: "scroll",` を付与する
 */
export const ScrollableBodyModal: FC<Props> = ({
  open,
  title,
  titleHint,
  titleSize,
  content,
  footer,
  onCancel,
  isSpMode,
  fullWidth,
  fullScreen,
  disableBackdropModal,
  isShowFooterDivider,
  ...props
}) => {
  const classes = useBlurBackdrop();

  const handleCancel = useCallback(() => {
    if (disableBackdropModal) return;

    onCancel && onCancel();
  }, [disableBackdropModal, onCancel]);

  if (isSpMode) {
    return (
      <StyledSpDialog
        maxWidth="sm"
        open={open}
        onClose={onCancel}
        fullScreen={fullScreen}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        {...props}
      >
        {onCancel && (
          <Box display="flex" justifyContent="flex-end">
            <IconButton size="md" icon="close" onClick={onCancel} color="white" />
          </Box>
        )}

        {content}
      </StyledSpDialog>
    );
  } else {
    return (
      <StyledPcDialog
        maxWidth="md"
        open={open}
        onClose={handleCancel}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        {...props}
      >
        <DialogTitle style={{ padding: 0 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" justifyContent="space-between" alignItems="center" gridGap={8}>
              {title && (
                <Typography variant={titleSize || "h4"} bold>
                  {title}
                </Typography>
              )}
              {titleHint && (
                <Tooltip title={titleHint} placement="top-start">
                  <Icon icon="help" size="ssm" color="grey" />
                </Tooltip>
              )}
            </Box>
            {onCancel && <StyledIconButton size="md" icon="close" onClick={onCancel} />}
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: 0, display: "flex", flexDirection: "column" }}>
          {content}
        </DialogContent>
        {isShowFooterDivider && <Divider />}
        {footer && <DialogActions style={{ padding: 0 }}>{footer}</DialogActions>}
      </StyledPcDialog>
    );
  }
};

const StyledPcDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0;
    row-gap: 40px;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      min-width: 800px;
      padding: 40px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
      margin: 16px;
      padding: 20px;
    }
  }
`;

const StyledSpDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    background-color: unset;
    box-shadow: unset;

    @media screen and (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      min-width: 400px;
    }
    @media screen and (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
      width: 100%;
      margin: 16px;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 4px;
  }
`;
