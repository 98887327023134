import { AnyActionSettingExcludeMethods } from "@onn/common";
import { useCallback } from "react";

import { ActionSettingInput, ActionType } from "../inputStateSchema";

export const useGenerateActionSettingDefaultValue = () => {
  const generateActionSettingDefaultValue = useCallback(
    ({
      actionType,
      existingAnyActionSetting,
    }: {
      actionType: ActionType;
      existingAnyActionSetting?: AnyActionSettingExcludeMethods;
    }): ActionSettingInput => {
      if (!existingAnyActionSetting) {
        return getDefaultValue(actionType);
      }
      switch (existingAnyActionSetting.type) {
        case "ChangeRecruitmentStatusActionSetting": {
          if (actionType !== "changeRecruitmentStatus") return getDefaultValue(actionType);
          return {
            actionType: "changeRecruitmentStatus",
            recruitmentStatusId: existingAnyActionSetting.recruitmentStatusId,
          };
        }
        case "DeliverOnnEventActionSetting": {
          if (actionType !== "deliveryOnnEvent") return getDefaultValue(actionType);
          return {
            actionType: "deliveryOnnEvent",
            onnEventId: existingAnyActionSetting.onnEventId,
          };
        }
        case "DeliverOnnTaskActionSetting": {
          if (actionType !== "deliveryOnnTask") return getDefaultValue(actionType);
          return {
            actionType: "deliveryOnnTask",
            onnTaskId: existingAnyActionSetting.onnTaskId,
          };
        }
        case "SendContactMessageActionSetting": {
          if (actionType !== "sendContactMessage") return getDefaultValue(actionType);
          return {
            actionType: "sendContactMessage",
            text: existingAnyActionSetting.text,
          };
        }
        default: {
          const _exhaustiveCheck: never = existingAnyActionSetting;
          return _exhaustiveCheck;
        }
      }
    },
    []
  );

  return { generateActionSettingDefaultValue };
};

const getDefaultValue = (actionType: ActionType) => ({
  actionType,
  recruitmentStatusId: undefined,
  onnTaskId: undefined,
  onnEventId: undefined,
  text: undefined,
});
