import { Box } from "@material-ui/core";
import { getHowLongAgo, NewGraduateToDisplay, NewGraduateToListView } from "@onn/common";
import React, { FC, memo, useMemo } from "react";
import styled from "styled-components";

import { NewGraduateInvitationChip } from "../NewGraduateStatusChip";

import { Icon } from "~/components/uiParts";
import { Typography } from "~/components/uiParts/Typography";
import { UserIcon } from "~/components/uiParts/UserIcon";

type Props = {
  newGraduate: NewGraduateToDisplay;
  newGraduateToListView?: NewGraduateToListView;
  isPinned?: boolean;
  isDetailed?: boolean;
};

export const NewGraduateUseIcon: FC<Props> = memo(
  ({ newGraduate, newGraduateToListView, isPinned, isDetailed }) => {
    const employeeStatusText = useMemo(() => {
      return newGraduate.lastActiveTime ? getHowLongAgo(newGraduate.lastActiveTime) : "なし";
    }, [newGraduate.lastActiveTime]);

    const iconBadgeType: "email" | "lineChat" | undefined = useMemo(() => {
      const displayType = newGraduate.getUserDisplayType();

      switch (displayType) {
        case "line":
          return "lineChat";
        case "email":
          return "email";
        case "isUnauthenticatedAndUnSynchronized":
          return undefined;
        default: {
          const _exhaustiveCheck: never = displayType;
          return _exhaustiveCheck;
        }
      }
    }, [newGraduate]);

    const username = newGraduate.getName();

    return (
      <>
        <Box display="flex" alignItems="center" gridGap="16px">
          <UserIcon
            username={username}
            profileIconImageUrl={newGraduate.profileIconImageUrl || ""}
            size="small"
            backgroundColor={newGraduate.uid ? "primary" : "grey"}
            badgeType={iconBadgeType}
          />
          <Box display="grid" gridRowGap="4px">
            <Box display="flex" alignItems="center" gridGap="8px" overflow="hidden">
              {isPinned && <Icon icon="pin" color="primary" size="sm" />}
              <StyledTypography variant="body1" color="textPrimary" bold noWrap>
                {username}
              </StyledTypography>
              {!newGraduate.isRegistered() && (
                <NewGraduateInvitationChipWrapper>
                  <NewGraduateInvitationChip invited={newGraduate.isInvited()} />
                </NewGraduateInvitationChipWrapper>
              )}
            </Box>
            {isDetailed
              ? newGraduateToListView?.affiliationFullName && (
                  <Box display="flex" alignItems="center" gridGap="8px">
                    <StyledTypography variant="caption">
                      {newGraduateToListView?.affiliationFullName}
                    </StyledTypography>
                  </Box>
                )
              : newGraduateToListView?.schoolName && (
                  <StyledTypography variant="caption" color="textSecondary" noWrap>
                    {newGraduateToListView?.schoolName}
                  </StyledTypography>
                )}
            <Box display="flex" alignItems="center" gridGap="8px" flexWrap="wrap">
              <StyledTypography variant="caption" color="textSecondary" noWrap disablePreWrap>
                最終アクセス：{employeeStatusText}
              </StyledTypography>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
);

const NewGraduateInvitationChipWrapper = styled(Box)`
  .MuiChip-root {
    padding: 0;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;
