import { format } from "date-fns";
import React from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

/**
 * (新卒テナント用)メッセージのユーザー名と日付を表示するコンポーネント
 */
export const NewGraduateMessageUserNameAndDate = React.memo<{
  creatorName: string;
  messageCreatedAt: Date;
}>(({ creatorName, messageCreatedAt }) => {
  return (
    <>
      <Typography variant="caption" color="textSecondary" bold>
        {creatorName}
      </Typography>
      <StyledCreatedAtTypography variant="caption" color="textSecondary">
        {format(messageCreatedAt, "yyyy/MM/dd HH:mm")}
      </StyledCreatedAtTypography>
    </>
  );
});

const StyledCreatedAtTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
`;
