import { zodResolver } from "@hookform/resolvers/zod";
import { CandidateDateWithNumberOfParticipants, OnnEvent } from "@onn/common";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import {
  InputState,
  getOnnEventsFormSchema,
  createOnnEventOrNull,
} from "../../_shared/createAndEdit";

import { useFooterAction } from "./useFooterAction";

import { useCurrentUser } from "~/hooks/employee";
import { useSelectableTimeItems, useUpdateOnnEvent } from "~/hooks/onnEvent";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";

type Props = {
  currentOnnEvent: OnnEvent;
  candidateDatesWithNumberOfParticipants: CandidateDateWithNumberOfParticipants[];
};

export const usePage = ({ currentOnnEvent, candidateDatesWithNumberOfParticipants }: Props) => {
  const [searchParams] = useSearchParams();
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  const { isLoading: isUpdatingOnnEvent } = useUpdateOnnEvent();

  const fromPage = searchParams.get("from_page");

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = useForm<InputState>({
    defaultValues: {
      title: currentOnnEvent.title,
      content: currentOnnEvent.content,
      type: currentOnnEvent.type,
      candidateDates: currentOnnEvent.candidateDates.map((v) => {
        const fromTimeHour = v.from.getHours();
        const fromTimeMinute = v.from.getMinutes();
        const fromTime = fromTimeHour + fromTimeMinute / 60;

        const untilTimeHour = v.until.getHours();
        const untilTimeMinute = v.until.getMinutes();
        let untilTime = untilTimeHour + untilTimeMinute / 60;
        untilTime = untilTime === 0 ? 24 : untilTime;
        return {
          candidateDateId: v.id,
          from: v.from,
          until: v.until,
          fromTime,
          untilTime,
          capacity: v.capacity === undefined ? "10" : v.capacity.toString(),
        };
      }),
      hasCapacity: currentOnnEvent.hasCapacity(),
      isRestrictedAnswerFromNewGraduate: currentOnnEvent.isRestrictedAnswerFromNewGraduate,
      isRestrictedDaysAgoWhenAnswer: currentOnnEvent.isRestrictedDaysAgoWhenAnswer,
      isRestrictedEditAnswerFromNewGraduate: currentOnnEvent.isRestrictedEditAnswerFromNewGraduate,
      daysAgoRestrictAnswer: currentOnnEvent.daysAgoRestrictAnswer,
    },
    mode: "onChange",
    resolver: zodResolver(
      getOnnEventsFormSchema({
        isEdit: true,
        candidateDatesWithNumberOfParticipants,
      })
    ),
  });

  // NOTE: isRestrictedDaysAgoWhenAnswerが変化あったときにdaysAgoRestrictAnswerのバリデーションも再実行する
  const isRestrictedDaysAgoWhenAnswer = watch("isRestrictedDaysAgoWhenAnswer");
  useEffect(() => {
    if (!isRestrictedDaysAgoWhenAnswer) {
      trigger();
    }
  }, [isRestrictedDaysAgoWhenAnswer, trigger, watch]);

  // バリデーションエラーがある場合は「保存して次へ」ボタンを押せないようにする
  useEffect(() => {
    trigger();
  }, [trigger]);

  const { fromTimeItems, untilTimeItems } = useSelectableTimeItems();

  const RHFInput = watch();

  /**
   * NOTE:
   * 「保存して次へ」ボタンのdisabled判定に使用。
   * また、createOnnEventリクエストにも使用する
   */
  const onnEventOrNull = createOnnEventOrNull({
    tenantId: tenant.tenantId,
    currentUserId: currentUser.id,
    spaceId: currentSpace.id,
    RHFInput,
    isEdit: true,
    currentOnnEventId: currentOnnEvent.id,
  });

  // onnEventドメインオブジェクトの生成に失敗（zodバリデーション失敗）or formがエラー状態の時は次へ進めない
  const isDisableSaveButton = Object.keys(errors).length !== 0;
  const {
    onClickCancel,
    onClickSaveAndToNext,
    isReadyToOpenPortalOnnEventPreview,
    onClickPreview,
  } = useFooterAction({
    onnEventOrNull,
    currentOnnEvent,
    isDisableSaveButton,
  });

  const saveButtonText = fromPage === "delivery_setting" ? "保存して次へ" : "変更を保存";

  return {
    isDisableSaveButton: isDisableSaveButton || isUpdatingOnnEvent,
    footerAction: {
      onClickCancel,
      onClickSaveAndToNext: handleSubmit(onClickSaveAndToNext),
      isReadyToOpenPortalOnnEventPreview,
      onClickPreview,
    },
    onClickSaveAndToNext,
    candidateForm: {
      fromTimeItems,
      untilTimeItems,
    },
    reactHookForm: { control, handleSubmit, errors, trigger },
    saveButtonText,
  };
};
