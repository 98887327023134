import React, { FC, memo } from "react";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { v4 } from "uuid";

import { FileQuestionFormUIMemo } from "../../_share_in_create_edit/components/forms/FileQuestionFormUIMemo";
import { useCreateFilePath } from "../../_share_in_create_edit/hooks/useCreateFilePath";
import { Action } from "../../_share_in_create_edit/reducer";
import { FIlE_QUESTION_FOR_FORM } from "../../_share_in_create_edit/types";
import { useCreateOnnTaskPageContext } from "../context";

import { useQuestionFormBase } from "./useQuestionFormBase";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";

export type Props = {
  fileQuestion: FIlE_QUESTION_FOR_FORM & { sampleFile?: File };
  dispatch: React.Dispatch<Action>;
  questionDragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

export const FileQuestionForm: FC<Props> = ({
  fileQuestion,
  dispatch,
  questionDragHandleProps,
}) => {
  const { id: questionId, title: questionTitle, isRequired } = fileQuestion;
  const { createFileQuestionFilePath, createFileQuestionFilePathForPreview } = useCreateFilePath();

  const { onChangeQuestionOptionType, onChangeTitle, onChangeIsRequired, deleteQuestion } =
    useQuestionFormBase({
      question: {
        id: questionId,
        isRequired,
        title: questionTitle,
        isDirtyTitle: fileQuestion.isDirtyTitle,
        isTitleError: fileQuestion.isTitleError,
        titleHelperText: fileQuestion.titleHelperText,
        disabledOptionType: fileQuestion.disabledOptionType,
        disabledTitle: fileQuestion.disabledTitle,
      },
      dispatch,
    });

  const { onnFormTaskId } = useCreateOnnTaskPageContext();
  // TODO: 実装
  const onChangeFile = (payload: Payload) => {
    if (payload.status === "success") {
      const file = payload.files[0] as (typeof payload.files)[number];
      dispatch({
        type: "ADD_ATTACHMENT_FILE_TO_FILE_QUESTION",
        payload: {
          questionId: questionId,
          sampleFile: file,
          sampleFilePath: createFileQuestionFilePath({
            questionId: questionId,
            fileName: file.name,
            onnFormTaskId,
          }),
          sampleFilePathForPreview: createFileQuestionFilePathForPreview({
            questionId: questionId,
            fileName: file.name,
            onnFormTaskId,
          }),
        },
      });
    }
  };

  const onClickDuplication = () => {
    const questionId = v4();
    dispatch({
      type: "DUPLICATION_QUESTION",
      payload: {
        question: {
          type: "FILE",
          title: questionTitle,
          isRequired: isRequired,
          id: questionId,
          sampleFile: fileQuestion.sampleFile,
          sampleFilePath: createFileQuestionFilePath({
            questionId: questionId,
            fileName: fileQuestion.sampleFile ? fileQuestion.sampleFile.name : "",
            onnFormTaskId,
          }),
          sampleFilePathForPreview: createFileQuestionFilePathForPreview({
            questionId: questionId,
            fileName: fileQuestion.sampleFile ? fileQuestion.sampleFile.name : "",
            onnFormTaskId,
          }),
          disabledTitle: false,
          disabledOptionType: false,
          isDirtyTitle: fileQuestion.isDirtyTitle,
          isTitleError: fileQuestion.isTitleError,
          titleHelperText: fileQuestion.titleHelperText,
        },
        fromQuestionId: fileQuestion.id,
      },
    });
  };

  return (
    <FileQuestionFormUIMemo
      onChangeOptionType={onChangeQuestionOptionType}
      onChangeTitle={onChangeTitle}
      selectedOptionType={"FILE"}
      isRequired={isRequired}
      onChangeIsRequired={onChangeIsRequired}
      onClickDelete={deleteQuestion}
      onClickDuplication={onClickDuplication}
      onChangeFile={onChangeFile}
      sampleFileName={fileQuestion.sampleFile?.name}
      questionDragHandleProps={questionDragHandleProps}
      titleTextField={{
        value: questionTitle,
        helperText: fileQuestion.titleHelperText,
        error: fileQuestion.isTitleError,
      }}
    />
  );
};

export const FileQuestionFormMemo = memo<Props>(FileQuestionForm);
