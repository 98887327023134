import { Employee } from "@onn/common";
import { useCallback } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { captureException } from "~/util";

/**
 * operationLogの生成関数
 */
const operationLog = {
  notifyAttemptToChangeAuthentication: (currentUser: Employee): LogType => {
    return {
      type: "success",
      title: `【${
        currentUser.tenantId
      }】${currentUser.getName()}さんが認証切り替えボタンを押しました。`,
      text: `現在の認証方法：${currentUser.currentAuthenticationType}`,
      footer: {
        icon: "pencil",
        processName: "認証切り替え試行",
      },
    };
  },
  notifyStartChangeAuthentication: (employeeId: string): LogType => {
    return {
      type: "success",
      title: `【employeeId：${employeeId}】認証切り替え画面`,
      text: `認証切り替えが始まります。`,
      footer: {
        icon: "pencil",
        processName: "認証切り替え試行",
      },
    };
  },
  notifyCSVUploadError: (currentUser: Employee, csvName: string, errorMessage: string): LogType => {
    return {
      type: "info",
      title: `【${
        currentUser.tenantId
      }】${currentUser.getName()}さんのアップロードしたCSVにエラーを検知しました。(${csvName})`,
      text: `\`\`\`${errorMessage}\`\`\``,
      footer: {
        icon: "warning",
        processName: "CSVアップロードエラー",
      },
    };
  },
  notifyDeterminedRegistrationInvitationLink: (
    type: "前回の招待リンク" | "デフォルト招待リンク",
    registrationInvitationLinkId: string
  ): LogType => {
    return {
      type: "success",
      title: "アカウント登録中間ページで、使用する招待リンクが決定されました",
      text: `\`\`\`type: ${type}\nid: ${registrationInvitationLinkId}\`\`\``,
      footer: {
        icon: "link",
        processName: "招待リンクの利用",
      },
    };
  },
};

export type LogType = {
  // 以下、type は SendNotificationArg に合わせる
  type: "success" | "alert" | "warning" | "info";
  title: string;
  text: string;
  footer: {
    icon: string;
    processName: string;
  };
};

/**
 * #Onn-logに対するオペレーションログを送信するhooks
 */
export const useNotifyOperationLog = () => {
  const notifyOperationLog = useCallback(async (log: LogType) => {
    try {
      await functionOperator.httpsCall("notifyOperationLog", log);
    } catch (error) {
      // 取得に失敗した場合にエラーは投げないが、こちら側で気付けるようにSentryに吐いておく
      if (error instanceof Error) {
        captureException({ error, tags: { type: "fileAPIAdapter.fetchMetaDataByPaths" } });
      }
    }
  }, []);

  return { operationLog, notifyOperationLog };
};
