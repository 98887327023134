import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";

import React, { useMemo } from "react";
import { List as VirtualizedList } from "react-virtualized";

import { ClosableEmployeeIconAndName } from "../ClosableEmployeeIconAndName/ClosableEmployeeIconAndName";

import { Button, Icon } from "~/components/uiParts";

const ROW_HEIGHT = 56;

export const NewGraduateSelectionForm = ({
  employees,
  openAddNewGraduateModal,
  openAddNewGraduateWithCSVModal,
  onClickDeleteButton,
}: {
  employees: Employee[];
  openAddNewGraduateModal: () => void;
  openAddNewGraduateWithCSVModal?: () => void;
  onClickDeleteButton: (target: Employee) => void;
}) => {
  const virtualizedListHeight = useMemo(() => {
    const itemCount = employees.length;
    if (itemCount === 0) return 0;
    if (itemCount < 3) return ROW_HEIGHT;
    if (itemCount < 5) return ROW_HEIGHT * 2;
    if (itemCount < 7) return ROW_HEIGHT * 3;
    return ROW_HEIGHT * 4;
  }, [employees.length]);

  const virtualizedListCount = useMemo(() => {
    return Math.ceil(employees.length / 2);
  }, [employees.length]);

  return (
    <Box>
      <Box mb={employees.length === 0 ? "0px" : "16px"} display="flex" gridColumnGap="8px">
        <Button
          variant="text"
          borderRadius="regular"
          onClick={openAddNewGraduateModal}
          color="primary"
          startIcon={<Icon icon="add" color="primary" size="md" />}
        >
          配信対象を追加
        </Button>
        {openAddNewGraduateWithCSVModal && (
          <Button
            variant="text"
            borderRadius="regular"
            onClick={openAddNewGraduateWithCSVModal}
            color="primary"
            startIcon={<Icon icon="upload" color="primary" size="md" />}
          >
            CSVアップロード
          </Button>
        )}
      </Box>
      <Box mt="16px">
        <VirtualizedList
          height={virtualizedListHeight}
          width={720}
          rowCount={virtualizedListCount}
          rowHeight={ROW_HEIGHT}
          rowRenderer={({ index, key, style }) => {
            const firstIemIndex = index * 2;
            const firstEmployee = employees[firstIemIndex];
            const secondEmployee = employees[firstIemIndex + 1];
            return (
              <Box key={key} style={style} sx={{ display: "flex" }}>
                <Box width={"356px"} mr={"8px"}>
                  {firstEmployee && (
                    <ClosableEmployeeIconAndName
                      key={firstEmployee.id}
                      employee={firstEmployee}
                      onClickDeleteButton={onClickDeleteButton}
                    />
                  )}
                </Box>
                <Box width={"356px"}>
                  {secondEmployee && (
                    <ClosableEmployeeIconAndName
                      key={secondEmployee.id}
                      employee={secondEmployee}
                      onClickDeleteButton={onClickDeleteButton}
                    />
                  )}
                </Box>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};
