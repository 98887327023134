import { z } from "zod";

import { MessageTypeSchema } from "../inputTypeSchemas/MessageTypeSchema";

/////////////////////////////////////////
// LATEST CONTACT MESSAGE SCHEMA
/////////////////////////////////////////

export const LatestContactMessageSchema = z.object({
  type: MessageTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  contactRoomId: z.string(),
  displayLabel: z.string(),
  messageId: z.string(),
  createdEmployeeId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type LatestContactMessage = z.infer<typeof LatestContactMessageSchema>;

/////////////////////////////////////////
// LATEST CONTACT MESSAGE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const LatestContactMessageOptionalDefaultsSchema = LatestContactMessageSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type LatestContactMessageOptionalDefaults = z.infer<
  typeof LatestContactMessageOptionalDefaultsSchema
>;

export default LatestContactMessageSchema;
