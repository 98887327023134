import { CandidateDate } from "../../../../domain";
import { toCloudFunctionsCompatible, ToCloudFunctionsCompatible } from "../../../../utils";
import { convertUnixTimeToDateCandidateDateWithNumberOfParticipants } from "../../_shared/convertUnixTimeToDate";
type CandidateDateWithNumberOfParticipants = ExcludeMethods<
  CandidateDate & { numberOfParticipants: number }
>;

export class ListCandidateDatesWithNumberOfParticipantsResponse {
  static ResponseBody: ToCloudFunctionsCompatible<CandidateDateWithNumberOfParticipants>[];

  public static createResponseBody({
    candidateDateWithNumberOfParticipants,
  }: {
    candidateDateWithNumberOfParticipants: CandidateDateWithNumberOfParticipants[];
  }) {
    return candidateDateWithNumberOfParticipants.map((v) => toCloudFunctionsCompatible(v));
  }
  public static convertUnixTimeToDate(response: typeof this.ResponseBody) {
    const candidateDatesWithNumberOfParticipants = response;
    return candidateDatesWithNumberOfParticipants.map((v) =>
      convertUnixTimeToDateCandidateDateWithNumberOfParticipants(v)
    );
  }
}
