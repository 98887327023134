import { Box } from "@material-ui/core";

import React from "react";

import { Paper, Icon, Typography, Tooltip } from "~/components/uiParts";

type Props = {
  title: string;
  tooltipTitle?: string;
  form: JSX.Element;
  rowGap?: number;
};

export const FormWithTitlePaper = ({
  title,
  tooltipTitle,
  form,
  rowGap = 16,
}: Props): JSX.Element => {
  const tooltip = tooltipTitle ? (
    <Tooltip title={tooltipTitle} placement="top-start">
      <Icon icon="help" size="sm" color="grey" />
    </Tooltip>
  ) : null;
  return (
    <Box mb={"40px"}>
      <Paper>
        <Box display={"flex"} flexDirection={"column"} gridGap={`${rowGap}px`}>
          <Box display={"flex"} alignItems={"center"} gridGap={"8px"}>
            <Typography variant="body1" bold>
              {title}
            </Typography>
            {tooltip}
          </Box>
          {form}
        </Box>
      </Paper>
    </Box>
  );
};
