import { Box } from "@material-ui/core";
import { OnnEventSlotDateWithNumberOfParticipants } from "@onn/common";
import { isSameDay } from "date-fns";
import { first } from "lodash";
import React, { FC, useCallback } from "react";
import { useController, useFormContext } from "react-hook-form";

import { InputState } from "../hooks/useForm";

import { Typography } from "~/components/uiParts";
import { StaticDatePicker } from "~/components/uiParts/StaticDatePicker";

import { Tag } from "~/components/uiParts/Tag";

type Props = {
  selectableSlotsWithNumberOfParticipants: OnnEventSlotDateWithNumberOfParticipants[];
};
export const SlotDateForm: FC<Props> = ({ selectableSlotsWithNumberOfParticipants }) => {
  const { control, watch, setValue, clearErrors } = useFormContext<InputState>();
  const {
    field,
    fieldState: { error },
  } = useController({ control, name: "selectedDate" });
  const isError = error !== undefined;

  const eventType = watch("eventType");

  const isDisabledDate = useCallback(
    (date: Date) => {
      if (!eventType) return true;
      const isSelectable = selectableSlotsWithNumberOfParticipants.some((v) =>
        isSameDay(v.onnEventSlotDate.from, date)
      );
      return !isSelectable;
    },
    [eventType, selectableSlotsWithNumberOfParticipants]
  );

  return (
    <Box display="flex" flexDirection="column" gridRowGap={24}>
      <Box display="flex" alignItems="center" gridColumnGap={8}>
        <Typography variant="body1" color="textPrimary" bold>
          日付を選択
        </Typography>
        <Tag color="secondary" size="sm" text="必須" />
      </Box>
      <StaticDatePicker
        {...field}
        onChange={(date) => {
          setValue("onnEventSlotDateId", null);
          clearErrors("onnEventSlotDateId");
          field.onChange(date);
        }}
        referenceDate={
          first(selectableSlotsWithNumberOfParticipants)?.onnEventSlotDate.from || new Date()
        }
        shouldDisableDate={isDisabledDate}
      />
      {isError && (
        <Typography variant="caption" color="secondary">
          {error.message}
        </Typography>
      )}
    </Box>
  );
};
