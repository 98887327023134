import { AuthenticationType } from "@onn/common";
import React, { FC } from "react";

import { SettingsAccountForEmailAuth } from "./SettingsAccountForEmailAuth";
import { SettingsAccountForGoogleAuth } from "./SettingsAccountForGoogleAuth";
import { SettingsAccountForLineAuth } from "./SettingsAccountForLineAuth";

import { useCurrentUser } from "~/hooks/employee";

export const Account: FC = () => {
  const { currentUser } = useCurrentUser();

  const renderSettingsAccount = (authenticationType: AuthenticationType) => {
    switch (authenticationType) {
      case "email":
        return <SettingsAccountForEmailAuth />;
      case "line":
        return <SettingsAccountForLineAuth />;
      case "google":
        return <SettingsAccountForGoogleAuth />;
      default:
        throw new Error("Invalid authenticationType");
    }
  };

  return (
    <>
      {currentUser.currentAuthenticationType
        ? renderSettingsAccount(currentUser.currentAuthenticationType)
        : null}
    </>
  );
};
