import { Box } from "@material-ui/core";
import React, { FC, useMemo } from "react";

import { Typography, PortalListItem, Paper } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useLibraries } from "~/hooks/library";
import { useTenantSettings } from "~/hooks/tenantSetting";

export const LibraryIndex: FC = () => {
  const { currentUser: newcomerEmployee } = useCurrentUser();
  const { data: libraries } = useLibraries(newcomerEmployee.tenantId, newcomerEmployee.spaceId);

  const { tenantSettings } = useTenantSettings();

  const isViewableList = libraries && 0 < libraries.length && tenantSettings.features.library;

  const contents = useMemo(
    () => (
      <>
        {libraries &&
          libraries.map((library) => (
            <Box key={library.id} mt="24px">
              <PortalListItem
                title={library.title}
                to={`/portal/libraries/${library.id}`}
                emoji={library.label}
              />
            </Box>
          ))}
      </>
    ),
    [libraries]
  );

  return (
    <Box pt="40px" px="24px" pb="40px">
      <Typography variant="h3" color="textPrimary">
        Library
      </Typography>
      <Typography variant="caption" color="textPrimary">
        {/* TODO: 中途の方を文言変更(https://app.clickup.com/t/864emfdcf) */}
        会社の理解を深めていきましょう！
      </Typography>
      <Box mt="24px">
        {isViewableList ? (
          contents
        ) : (
          <Paper>
            <Typography align="center">ライブラリーは登録されていません</Typography>
          </Paper>
        )}
      </Box>
    </Box>
  );
};
