import { Box, Grid, useMediaQuery } from "@material-ui/core";
import React, { FC } from "react";

import theme from "~/config/theme";

type Props = {
  filter: JSX.Element;
  searchForm: JSX.Element;
};

export const TableActionsLayoutWithFilterAndSearchForm: FC<Props> = ({ filter, searchForm }) => {
  return (
    <Box flexGrow={2}>
      <Grid container spacing={1} alignItems="flex-end" justifyContent="space-between">
        <Grid item xs={4} md={3}>
          {filter}
        </Grid>
        {useMediaQuery(theme.breakpoints.up("md")) && <Grid md={2}></Grid>}
        <Grid item xs={6}>
          {searchForm}
        </Grid>
      </Grid>
    </Box>
  );
};
