import { Box, Menu, MenuItem } from "@material-ui/core";

import { ContactMessageTemplate, ContactMessageTemplateFolder } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC } from "react";
import styled from "styled-components";

import { FolderMenuItems } from "./FolderMenu/FolderMenuItems";
import { TemplateMenu } from "./TemplateMenu/TemplateMenu";

import { useContactMessageTemplateMenu } from "./useContactMessageTemplateMenu";

import { Divider, Typography, Loading, IconButton } from "~/components/uiParts";
import { useContactMessageTemplates } from "~/hooks/contactMessageTemplate/useContactMessageTemplates";
import { useCreateContactMessageTemplate } from "~/hooks/contactMessageTemplate/useCreateContactMessageTemplate";
import { useContactMessageTemplateFolders } from "~/hooks/contactMessageTemplateFolder/useContactMessageTemplateFolder";
import { useCreateContactMessageTemplateFolder } from "~/hooks/contactMessageTemplateFolder/useCreateContactMessageTemplateFolder";
import { useDeleteContactMessageTemplateFolder } from "~/hooks/contactMessageTemplateFolder/useDeleteContactMessageTemplateFolder";
import { useUpdateContactMessageTemplateFolder } from "~/hooks/contactMessageTemplateFolder/useUpdateContactMessageTemplateFolder";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant/useTenant";
type Props = {
  menuStartAnchorEl: HTMLElement | null;
  closeMenu: () => void;
  reflectMessage: (v: string) => void;
};

export const ContactMessageTemplateMenu: FC<Props> = ({
  menuStartAnchorEl,
  closeMenu,
  reflectMessage,
}) => {
  const {
    tenant: { tenantId },
  } = useTenant();
  const { currentSpace } = useCurrentSpace();
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();

  const {
    data: allContactMessageTemplates,
    isLoading: isLoadingTemplates,
    refetchKey: templatesRefetchKey,
  } = useContactMessageTemplates(tenantId);

  const {
    data: contactMessageTemplateFolders,
    isLoading: isLoadingFolders,
    refetchKey: foldersRefetchKey,
  } = useContactMessageTemplateFolders(tenantId, currentSpace.id);
  const { createContactMessageTemplateFolder } = useCreateContactMessageTemplateFolder({
    contactMessageTemplateFolders: contactMessageTemplateFolders || [],
    refetchKey: foldersRefetchKey,
  });
  const { updateContactMessageTemplateFolder } = useUpdateContactMessageTemplateFolder({
    contactMessageTemplateFolders: contactMessageTemplateFolders || [],
    refetchKey: foldersRefetchKey,
  });
  const { deleteContactMessageTemplateFolder } = useDeleteContactMessageTemplateFolder({
    contactMessageTemplateFolders: contactMessageTemplateFolders || [],
    refetchKey: foldersRefetchKey,
  });

  const { createContactMessageTemplate } = useCreateContactMessageTemplate({
    contactMessageTemplates: allContactMessageTemplates || [],
    refetchKey: templatesRefetchKey,
    foldersRefetchKey,
  });

  const {
    childMenuAnchorEl,
    isOpen,
    handleClickMenuItem,
    handleCloseChildMenu,
    selectedTemplateFolder,
  } = useContactMessageTemplateMenu(contactMessageTemplateFolders || []);

  return (
    <>
      <StyledTemplatesMenu
        anchorEl={menuStartAnchorEl}
        open={Boolean(menuStartAnchorEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
      >
        {isLoadingTemplates || isLoadingFolders ? (
          <Loading size="small" />
        ) : (
          <StyledMenuItemsBox>
            {isEmpty(allContactMessageTemplates) ? (
              <Box display="flex" alignItems="center" height="40px" px="24px">
                <Typography variant="body2" color="textSecondary">
                  テンプレートが存在しません
                </Typography>
              </Box>
            ) : (
              <StyledMenuItem
                selected={selectedTemplateFolder === undefined && childMenuAnchorEl !== null}
                onClick={handleClickMenuItem}
              >
                <Typography variant="body2">すべてのテンプレート</Typography>
                <IconButton icon="chevronRight" />
              </StyledMenuItem>
            )}
            <FolderMenuItems
              contactMessageTemplateFolders={contactMessageTemplateFolders || []}
              selectedTemplateFolder={selectedTemplateFolder}
              handleClick={handleClickMenuItem}
            />
          </StyledMenuItemsBox>
        )}

        {currentUser.isAdmin() && [
          <StyledDivider key="divider" />,
          <StyledCreateOrEditMenuItem
            key="create"
            onClick={() =>
              handleModal({
                name: "editContactMessageTemplateModal",
                args: {
                  mode: "create",
                  onSubmit: async (newTemplate: ContactMessageTemplate) => {
                    createContactMessageTemplate(newTemplate);
                  },
                  templateFolders: contactMessageTemplateFolders || [],
                },
              })
            }
          >
            <Typography variant="body2">テンプレート新規作成</Typography>
          </StyledCreateOrEditMenuItem>,
          <StyledCreateOrEditMenuItem
            key="createFolder"
            onClick={() =>
              handleModal({
                name: "editContactMessageTemplateFolderModal",
                args: {
                  mode: "create",
                  onSubmit: async (newTemplateFolder: ContactMessageTemplateFolder) => {
                    createContactMessageTemplateFolder(newTemplateFolder);
                  },
                },
              })
            }
          >
            <Typography variant="body2">フォルダ新規作成</Typography>
          </StyledCreateOrEditMenuItem>,
        ]}
      </StyledTemplatesMenu>
      <TemplateMenu
        contactMessageTemplates={
          (selectedTemplateFolder
            ? contactMessageTemplateFolders?.find(
                (folder) => folder.id === selectedTemplateFolder.id
              )?.templates
            : allContactMessageTemplates) || []
        }
        templatesRefetchKey={templatesRefetchKey}
        foldersRefetchKey={foldersRefetchKey}
        templateFolders={contactMessageTemplateFolders || []}
        reflectMessage={reflectMessage}
        closeMenu={() => {
          handleCloseChildMenu();
          closeMenu();
        }}
        anchorEl={childMenuAnchorEl}
        handleMenuClose={handleCloseChildMenu}
        isOpen={isOpen}
        selectedTemplateFolder={selectedTemplateFolder}
        handleSubmitEditFolder={async (newTemplateFolder: ContactMessageTemplateFolder) => {
          updateContactMessageTemplateFolder(newTemplateFolder);
        }}
        handleDeleteFolder={async (targetTemplateFolder: ContactMessageTemplateFolder) => {
          deleteContactMessageTemplateFolder(targetTemplateFolder);
          handleCloseChildMenu();
        }}
      />
    </>
  );
};

const StyledTemplatesMenu = styled(Menu)`
  .MuiPaper-root {
    width: 244px;
  }

  .MuiList-padding {
    padding-top: 16px;
  }
`;

const StyledMenuItemsBox = styled(Box)`
  &.MuiBox-root {
    max-height: 242px; // スクロールできるような見た目にするため5.5行分の高さにしている (44 * 5.5)
    overflow-y: auto;
  }
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 10px 0 2px 0;
  }
`;

const StyledCreateOrEditMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-top: 2px;
    padding-bottom: 2px;

    // TooltipWhenTextTruncated を使用するために、root, span のそれぞれに flex style が必要
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.selected && props.theme.palette.action.selected};

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
