import { Box } from "@material-ui/core";
import { isValidEmail } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts/Button";
import { Icon } from "~/components/uiParts/Icon";
import { IconButton } from "~/components/uiParts/IconButton";
import { TextFieldDeprecated } from "~/components/uiParts/TextField";
import { Typography } from "~/components/uiParts/Typography";

type Props = {
  emails: string[];
  onChangeInputText: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onDeleteEmails: (i: number) => void;
  onAddEmails: () => void;
  maxInvitedFields?: number;
};

export const EmailForm: FC<Props> = ({
  emails,
  onChangeInputText,
  onDeleteEmails,
  onAddEmails,
  maxInvitedFields = 10,
}) => {
  const checkValidEmail = useCallback((email: string): boolean => {
    if (email === "") return true;

    return isValidEmail(email);
  }, []);

  return (
    <Box>
      <Typography variant="caption" noWrap disablePreWrap bold color="textSecondary">
        メールアドレス
      </Typography>
      {emails.map((email, index) => {
        return (
          <StyledBox key={`InviteMember__${index}`} mt="16px">
            <TextFieldDeprecated
              name="email"
              type="email"
              value={email}
              fullWidth
              variant="outlined"
              placeholder="name@example.com"
              helperText={!checkValidEmail(email) && "メールアドレスの形式が間違っています"}
              error={!checkValidEmail(email)}
              onChange={(event) => onChangeInputText(event, index)}
            />
            {emails.length !== 1 && (
              <div>
                <IconButton icon="trash" onClick={() => onDeleteEmails(index)} size="md" />
              </div>
            )}
          </StyledBox>
        );
      })}
      {emails.length !== maxInvitedFields && (
        <Box mt="24px">
          <Button color="primary" variant="text" borderRadius="regular">
            <StyledTypography
              variant="caption"
              noWrap
              disablePreWrap
              bold
              color="primary"
              onClick={() => onAddEmails()}
            >
              <Icon size="sm" icon="add" color="primary" />
              入社者を追加
            </StyledTypography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: baseline;
  > * + * {
    margin-left: 12px;
  }
`;

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
