import { ContactMessageTemplate } from "@onn/common";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { Key as FolderKey } from "../contactMessageTemplateFolder/useContactMessageTemplateFolder";

import { Key } from "./useContactMessageTemplates";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * コンタクトメッセージテンプレートを作成するAPIを呼び出す関数を返す
 * @param mutateKeys
 */
export const useCreateContactMessageTemplate = ({
  contactMessageTemplates,
  refetchKey,
  foldersRefetchKey,
}: {
  contactMessageTemplates: ContactMessageTemplate[];
  refetchKey: Key;
  foldersRefetchKey: FolderKey;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  /**
   *  コンタクトメッセージテンプレートを作成する
   */
  const createContactMessageTemplate = useCallback(
    async (template: ContactMessageTemplate) => {
      try {
        const optimisticData = [...contactMessageTemplates, template];

        await mutate(
          refetchKey,
          async () => {
            await apiClient.post("/api/contact-message-templates", {
              ["title"]: template.title,
              ["text"]: template.text,
              ["folderId"]: template.folderId,
            });
            // Note: フォルダに紐つくテンプレート一覧も表示の対象なので、refetchして更新する必要がある
            if (template.folderId) {
              mutate(foldersRefetchKey);
            }
            return mutate(refetchKey);
          },
          {
            optimisticData,
          }
        );
        enqueueSnackbar("新しいテンプレートが追加されました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("テンプレートの作成に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useCreateContactMessageTemplate:createContactMessageTemplate",
          },
        });
      }
    },
    [contactMessageTemplates, enqueueSnackbar, mutate, refetchKey, foldersRefetchKey]
  );

  return { createContactMessageTemplate };
};
