import { Box } from "@mui/material";
import { FormRevision } from "@onn/common";
import { Question } from "@onn/common/domain/OnnTask/Question/Question";
import React, { FC } from "react";

import { Controller } from "react-hook-form";

import { useFormContext } from "../../../../useForm";

import { CheckboxQuestionAndAnswer } from "./components/CheckboxQuestionAndAnswer";

import { FileQuestionAndAnswer } from "./components/FileQuestionAndAnswer";
import { RadioQuestionAndAnswer } from "./components/RadioQuestionAndAnswer";

import { TextQuestionAndAnswer } from "./components/TextQuestionAndAnswer";

import { Typography } from "~/components/uiParts";

export const QuestionsAndAnswers: FC<{ formRevision: FormRevision }> = ({ formRevision }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {formRevision.questions.map((question, index) => {
        return <QuestionAndAnswer key={question.id} question={question} index={index} />;
      })}
    </Box>
  );
};

const QuestionAndAnswer: FC<{ question: Question; index: number }> = ({ question, index }) => {
  const form = useFormContext();

  return (
    <QuestionAndAnswerWrapper
      key={question.id}
      isRequired={question.isRequired}
      AnswerArea={
        <Controller
          control={form.control}
          render={({ field }) => {
            switch (question.type) {
              case "TEXT": {
                const value = field.value?.type === "TEXT" ? field.value.text : "";
                return (
                  <TextQuestionAndAnswer
                    question={question}
                    value={value}
                    onChange={(value) => {
                      form.setValue(`answers.${index}`, {
                        type: "TEXT",
                        text: value,
                        questionId: question.id,
                      });
                    }}
                    isDisabled={true}
                  />
                );
              }
              case "RADIO": {
                let selectedOptionId: string | null = null;
                if (field.value?.type === "RADIO") {
                  selectedOptionId = field.value.selectedOptionId || null;
                }
                return (
                  <RadioQuestionAndAnswer
                    question={question}
                    selectedOptionId={selectedOptionId}
                    onChangeRadio={(optionId) => {
                      form.setValue(`answers.${index}`, {
                        type: "RADIO",
                        selectedOptionId: optionId,
                        questionId: question.id,
                      });
                    }}
                    isDisabled={true}
                  />
                );
              }
              case "CHECK_BOX": {
                let selectedOptionIds: string[] = [];
                if (field.value?.type === "CHECK_BOX") {
                  selectedOptionIds = field.value.selectedOptionIds || [];
                }
                return (
                  <CheckboxQuestionAndAnswer
                    question={question}
                    selectedOptionIds={selectedOptionIds}
                    onChangeCheckBox={(optionId) => {
                      form.setValue(`answers.${index}`, {
                        type: "CHECK_BOX",
                        selectedOptionIds: selectedOptionIds.includes(optionId)
                          ? selectedOptionIds.filter((id) => id !== optionId)
                          : [...selectedOptionIds, optionId],
                        questionId: question.id,
                      });
                    }}
                    isDisabled={true}
                  />
                );
              }
              case "FILE": {
                const filePath = field.value?.type === "FILE" ? field.value.filePath : null;
                return <FileQuestionAndAnswer question={question} filePath={filePath} />;
              }
              default: {
                const _: never = question;
                return _;
              }
            }
          }}
          name={`answers.${index}`}
        />
      }
      questionTitle={question.title}
    />
  );
};

const QuestionAndAnswerWrapper: FC<{
  questionTitle: string;
  AnswerArea: React.ReactNode;
  isRequired?: boolean;
}> = ({ questionTitle, AnswerArea, isRequired }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography bold variant="body1" appendRequiredSymbol={isRequired}>
        {questionTitle}
      </Typography>
      <Box>{AnswerArea}</Box>
    </Box>
  );
};
