import { searchNewGraduateConditionSchema } from "@onn/common";
import { throttle } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { AnyCondition, AnyInputCondition, AnyTarget, AnyValidCondition } from "./types/condition";
import { LogicType } from "./types/logic-type";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";

export type Data = {
  conditions: AnyInputCondition[];
  logicType: LogicType;
  defaultCount: number;
  onCancel(): void;
  onSearchConfirm(logicType: LogicType, conditions: AnyCondition[]): Promise<void>;
};
export const useViewModel = ({
  conditions,
  logicType,
  defaultCount,
  onCancel,
  onSearchConfirm,
}: Data) => {
  const { enqueueSnackbar } = useSnackbar();

  const [currentConditions, setCurrentConditions] = useState<AnyInputCondition[]>(conditions);
  const [currentLogicType, setCurrentLogicType] = useState(logicType);
  const [searchResultCount, setSearchResultCount] = useState<number>(defaultCount);

  const validateConditions = useCallback(
    (conditions: AnyInputCondition[]) =>
      conditions.filter(
        (c): c is AnyValidCondition => searchNewGraduateConditionSchema.safeParse(c).success
      ),
    []
  );

  const validConditions: AnyValidCondition[] = useMemo(
    () => validateConditions(currentConditions),
    [currentConditions, validateConditions]
  );

  const [isLoadingChange, setLoadingChange] = useState(false);
  const throttledOnChange = useMemo(
    () =>
      throttle(async (logicType: LogicType, conditions: AnyInputCondition[]) => {
        const validConditions = validateConditions(conditions);

        const { total } = await apiClient
          .post("/api/new-graduates/search/count", {
            type: logicType,
            conditions: validConditions,
          })
          .catch((e) => {
            enqueueSnackbar("検索に失敗しました。通信環境をご確認の上、再度お試しください。", {
              variant: "error",
            });
            throw e;
          })
          .finally(() => setLoadingChange(false));

        setSearchResultCount(total);
      }, 1000),
    [enqueueSnackbar, validateConditions]
  );

  const onAddCondition = useCallback(() => {
    setCurrentConditions([...currentConditions, { target: undefined }]);
  }, [currentConditions]);

  const onClearAllConditions = useCallback(() => {
    setCurrentConditions([{ target: undefined }]);
    throttledOnChange(currentLogicType, [{ target: undefined }]);
  }, [currentLogicType, throttledOnChange]);

  const onRemoveCondition = useCallback(
    (index: number) => {
      const cloned = [...currentConditions];

      const isLastOne = cloned.length === 1;
      if (isLastOne) {
        setCurrentConditions([{ target: undefined }]);
        throttledOnChange(currentLogicType, [{ target: undefined }]);
        return;
      }

      cloned.splice(index, 1);
      setCurrentConditions(cloned);
      throttledOnChange(currentLogicType, cloned);
    },
    [currentConditions, currentLogicType, throttledOnChange]
  );

  /**
   * count API を叩いて、検索結果の件数を取得する
   */
  const onChangeCondition = useCallback(
    (index: number, changedCondition: AnyInputCondition) => {
      const cloned = [...currentConditions];
      cloned.splice(index, 1, changedCondition);
      setCurrentConditions(cloned);

      throttledOnChange(currentLogicType, validateConditions(cloned));
    },
    [currentConditions, currentLogicType, throttledOnChange, validateConditions]
  );

  const onChangeTarget = useCallback(
    (index: number, changedTarget: AnyTarget) => {
      const exTarget = currentConditions[index]?.target;
      const isChanged = changedTarget !== exTarget;
      if (isChanged) {
        const cloned = [...currentConditions];
        cloned.splice(index, 1, { target: changedTarget });
        setCurrentConditions(cloned);
        throttledOnChange(currentLogicType, cloned);
      }
    },
    [currentConditions, currentLogicType, throttledOnChange]
  );

  const onSelectLogicType = useCallback(
    (logicType: LogicType) => {
      setCurrentLogicType(logicType);
      throttledOnChange(logicType, currentConditions);
    },
    [currentConditions, throttledOnChange]
  );

  const [isLoadingSearch, setLoadingSearch] = useState(false);
  const handleSearch = useCallback(async () => {
    setLoadingSearch(true);
    await onSearchConfirm(currentLogicType, validConditions).finally(() => setLoadingSearch(false));
    onCancel();
  }, [currentLogicType, onCancel, onSearchConfirm, validConditions]);

  return {
    currentLogicType,
    currentConditions,
    validConditions,
    isLoadingSearch,
    isLoadingChange,
    searchResultCountByCondition: searchResultCount,
    onAddCondition,
    onRemoveCondition,
    onChangeCondition,
    onChangeTarget,
    onClearAllConditions,
    onSelectLogicType,
    handleSearch,
  };
};
