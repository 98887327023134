import { Box } from "@material-ui/core";
import { OnnEventPlace } from "@onn/common";
import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "../formUtils/zodFormSchema";

import {
  FormControlLabel,
  Icon,
  Loading,
  RadioButton,
  SelectForm,
  TextareaAutosize,
  Typography,
  TextFieldDeprecated,
} from "~/components/uiParts";

export const OnnEventTypeFormPart: FC<{
  form: UseFormReturn<InputState>;
  eventPlaces: {
    isLoading: boolean;
    data: OnnEventPlace[];
  };
}> = ({ form, eventPlaces }) => {
  return (
    <Box>
      <Box display="flex" gridGap="12px" alignItems="center">
        <Typography variant="body2" bold>
          開催方法
        </Typography>
      </Box>
      <Box my="12px" display="flex" flexDirection="column" gridGap="24px">
        <Box>
          <Controller
            name="slotInfo.type"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <StyledFormControlLabel
                value="online"
                control={
                  <StyledRadioButton
                    color="primary"
                    defaultColor={value === "online" ? "primary" : "default"}
                    checked={value === "online"}
                    onChange={(e) => {
                      onChange(e.target.value);
                      form.clearErrors("slotInfo");
                    }}
                  />
                }
                label={<Typography variant="body2">オンライン</Typography>}
              />
            )}
          />
          {form.watch("slotInfo.type") === "online" && (
            <Box mt="12px" pl="28px" gridGap="8px">
              <Box display="flex" alignItems="center" gridGap="16px">
                <Icon icon="line" size="md" color="lightGrey" />
                <Controller
                  name="slotInfo.online.description"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TextareaAutosize
                      {...field}
                      fullWidth
                      placeholder="開催概要を入力"
                      minRows={3}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box display="flex" alignItems="center" gridGap="16px">
                <Icon icon="link" size="md" color="lightGrey" />
                <Controller
                  name="slotInfo.online.url"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TextFieldDeprecated
                      {...field}
                      fullWidth
                      variant="outlined"
                      placeholder="オンライン会議ツールのURL"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          <Controller
            name="slotInfo.type"
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <StyledFormControlLabel
                value="offline"
                control={
                  <StyledRadioButton
                    color="primary"
                    defaultColor={value === "offline" ? "primary" : "default"}
                    checked={value === "offline"}
                    onChange={(e) => onChange(e.target.value)}
                  />
                }
                label={<Typography variant="body2">オフライン・対面</Typography>}
              />
            )}
          />
          {form.watch("slotInfo.type") === "offline" && (
            <Box mt="12px" pl="28px">
              <Box display="flex" alignItems="center" gridGap="16px">
                <Icon icon="line" size="md" color="lightGrey" />
                <Controller
                  name="slotInfo.offline.description"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TextareaAutosize
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        form.trigger("slotInfo.offline.description");
                      }}
                      placeholder="開催概要を入力"
                      minRows={3}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box display="flex" alignItems="center" gridGap="16px">
                <Icon icon="location" size="md" color="lightGrey" />
                <Controller
                  name="slotInfo.offline.location"
                  control={form.control}
                  render={({ field, fieldState }) =>
                    eventPlaces.isLoading ? (
                      <Loading size="small" />
                    ) : (
                      <StyledSelectForm
                        {...field}
                        fullWidth
                        menuItems={eventPlaces.data.map((place) => ({
                          value: place.id,
                          name: place.name,
                        }))}
                        selected={field.value || null}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          const selectedPlace = eventPlaces.data.find(
                            (place) => place.id === e.target.value
                          );
                          if (selectedPlace) {
                            form.setValue("slotInfo.offline.postCode", selectedPlace.postCode);
                            form.setValue(
                              "slotInfo.offline.address",
                              `${selectedPlace.state}${selectedPlace.city}${selectedPlace.address1}${selectedPlace.address2}`
                            );
                          }
                          form.trigger([
                            "slotInfo.offline.location",
                            "slotInfo.offline.postCode",
                            "slotInfo.offline.address",
                          ]);
                        }}
                        errorBorder={!!fieldState.error}
                        errorText={fieldState.error?.message}
                      />
                    )
                  }
                />
              </Box>
              <Box display="flex" alignItems="center" gridGap="16px" mt="8px">
                <Box width={"80px"}>
                  <Typography variant="body2" color="textSecondary">
                    郵便番号
                  </Typography>
                </Box>

                <Controller
                  name="slotInfo.offline.postCode"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <StyledTextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        form.trigger([
                          "slotInfo.offline.location",
                          "slotInfo.offline.postCode",
                          "slotInfo.offline.address",
                        ]);
                      }}
                      placeholder="(例: 123-4567)"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <Box display="flex" alignItems="center" gridGap="16px" mt="8px">
                <Box width={"80px"}>
                  <Typography variant="body2" color="textSecondary">
                    住所詳細
                  </Typography>
                </Box>
                <Controller
                  name="slotInfo.offline.address"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <TextareaAutosize
                      {...field}
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        form.trigger([
                          "slotInfo.offline.location",
                          "slotInfo.offline.postCode",
                          "slotInfo.offline.address",
                        ]);
                      }}
                      placeholder=""
                      minRows={3}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    height: 40px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  margin-right: 8px;
`;

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-root {
    height: 40px;
  }
`;
