import liff from "@line/liff";
import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { LineLoginButton } from "./parts/LineLoginButton";

import { ErrorPage } from "~/components/domains/account/ErrorPage";
import { LoginEmailForm } from "~/components/domains/employees/LoginEmailForm";
import { Button, Divider, Loading, Typography } from "~/components/uiParts";
import { CenterPaper } from "~/components/uiParts/CenterPaper";
import { useQuery } from "~/hooks/shared";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";
import {
  LienLoginChannelInfo,
  useTenantLineLoginChannelInfo,
} from "~/hooks/tenant/useTenantLineLoginChannelInfo";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";

export const LoginPage: FC = () => {
  const tenantId = getTenantIdFromDomain();
  const { data: dataLineLoginChannelInfo, isLoading: isLoadingTenantLineLoginChannelInfo } =
    useTenantLineLoginChannelInfo(tenantId || undefined);
  const { query } = useQuery();
  const spaceId = query.get("space-id");
  const registrationInvitationLinkId = query.get("registration-invitation-link-id");

  if (liff.isInClient()) {
    return (
      <ErrorPage
        pageType="ログインページ"
        detail="liff でログインページは表示出来ません"
        extraInfo={{ tenantId, spaceId, registrationInvitationLinkId, query }}
      />
    );
  }

  if (isLoadingTenantLineLoginChannelInfo) {
    return <Loading size="large" />;
  }
  if (!dataLineLoginChannelInfo) {
    return (
      <ErrorPage
        pageType="ログインページ"
        detail="dataLineLoginChannelInfo is not found"
        extraInfo={{ tenantId }}
      />
    );
  }

  return (
    <LoginPageCore
      spaceId={spaceId}
      registrationInvitationLinkId={registrationInvitationLinkId}
      dataLineLoginChannelInfo={dataLineLoginChannelInfo}
    />
  );
};

type Props = {
  spaceId: string | null;
  registrationInvitationLinkId: string | null;
  dataLineLoginChannelInfo: LienLoginChannelInfo;
};

export const LoginPageCore: FC<Props> = ({
  spaceId,
  registrationInvitationLinkId,
  dataLineLoginChannelInfo,
}) => {
  const navigate = useNavigateWithQuery();

  return (
    <StyledBox>
      <CenterPaper isGrayColorInSP={true}>
        <Typography variant="h2" color="textPrimary" style={{ fontWeight: 700, marginBottom: 40 }}>
          ログイン
        </Typography>
        <Box mb="40px">
          <LineLoginButton dataLineLoginChannelInfo={dataLineLoginChannelInfo} />
        </Box>
        <LoginEmailForm />
        {(spaceId || registrationInvitationLinkId) && (
          <Box textAlign="center">
            <Divider orientation="horizontal" margin={40} />
            <StyledButtonWrapper>
              <Button
                fullWidth
                variant="text"
                color="primary"
                borderRadius="circle"
                onClick={() => navigate("/portal/accounts/registration")}
              >
                <Typography variant="caption" color="primary" style={{ fontWeight: 700 }}>
                  新規登録はこちら
                </Typography>
              </Button>
            </StyledButtonWrapper>
          </Box>
        )}
      </CenterPaper>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

// NOTE: 今のButtonコンポーネントだとサイズを自由に変更ができないので、一旦styled-componentsで上書きしている
const StyledButtonWrapper = styled(Box)`
  .MuiButtonBase-root > div {
    padding: 4.5px 16px;
  }
`;
