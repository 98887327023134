import { Announcement } from "@onn/common";
import { useCallback, useState } from "react";

import { useGenerateMutateAnnouncement } from "./useAnnouncement";

import { apiClient } from "~/libs";

export const useUpdateAnnouncement = () => {
  const [isLoading, setLoading] = useState(false);
  const { generateMutateAnnouncement } = useGenerateMutateAnnouncement();

  const execUpdateAnnouncement = useCallback(
    async ({
      announcementId,
      title,
      content,
      filePaths,
    }: {
      announcementId: string;
      title: string;
      content: string;
      filePaths: string[];
    }): Promise<Announcement> => {
      setLoading(true);
      const announcement = await apiClient
        .patch("/announcement_api/announcement", {
          announcementId,
          title,
          content,
          filePaths,
        })
        .then((res) => {
          const updatedAnnouncement = new Announcement(res.data.announcement);

          generateMutateAnnouncement(updatedAnnouncement.id)(updatedAnnouncement);

          return updatedAnnouncement;
        })
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });

      return announcement;
    },
    [generateMutateAnnouncement]
  );

  return { execUpdateAnnouncement, isLoading };
};
