import { Box } from "@material-ui/core";
import { CandidateDate } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography, Paper, Icon } from "~/components/uiParts";

import { mixin } from "~/util";

type Props = {
  title: string;
  description: string;
  candidateDate?: CandidateDate;
};

export const Completion: FC<Props> = ({ title, description, candidateDate }) => {
  return (
    <StyledBox>
      <Typography variant="h2" style={{ fontWeight: 400 }}>
        {title}
      </Typography>
      <StyledPaper>
        <Box display="flex" justifyContent="center" mb="32px">
          <Icon icon="checkOutlineCircle" size="xxl" color="primary" />
        </Box>

        <Typography variant="body1" align="center" color="textPrimary">
          {description}
          <br />
          {candidateDate &&
            `【${format(candidateDate.from, "MM月dd日(E) HH:mm", { locale: ja })}~${format(
              candidateDate.until,
              "HH:mm"
            )}】`}
        </Typography>
      </StyledPaper>
      <Box mt={4} display="flex" justifyContent="center">
        <Typography variant="caption" color="textSecondary">
          ブラウザを閉じて終了してください。
        </Typography>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;
  width: 100%;

  ${mixin.portalSp`
    padding: 40px 24px;
    width: 100%;
  `}
`;

const StyledPaper = styled(Paper)`
  margin-top: 28px;
  padding: 80px;
  ${mixin.portalSp`
    margin-top: 24px;
    padding: 80px 24px;
  `}
`;
