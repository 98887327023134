import {
  OnnEvent,
  Library,
  TenantSettings,
  Employee,
  typedEntries,
  AllContactRoom,
  Announcement,
} from "@onn/common";
import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useLatestContactMessage } from "~/hooks/contactMessage";

import { useCanDisplayContactRooms } from "~/hooks/portal";
import { useCanDisplayOnnTasks } from "~/hooks/portal/useCanDisplayOnnTasks";

export type LinkKey =
  | "home"
  | "contactRooms"
  | "lineQr"
  | "announcements"
  | "events"
  | "libraries"
  | "onnTasks";

/**
 * 表示可能な遷移先ページのキーをを返すhooks
 */
export const useViewableLinks: (args: {
  currentUser: Employee;
  tenantSettings: TenantSettings;
  contactRooms: AllContactRoom[];
  libraries: Library[] | undefined;
  onnEvents?: OnnEvent[];
  announcements?: Announcement[];
  isPreview?: boolean;
}) => LinkKey[] = ({
  currentUser,
  tenantSettings,
  contactRooms,
  libraries,
  onnEvents,
  announcements,
  isPreview,
}) => {
  const { canDisplayContactRooms } = useCanDisplayContactRooms();
  const { canDisplayOnnTasks } = useCanDisplayOnnTasks(isPreview);

  const { data: latestContactMessage = [] } = useLatestContactMessage({
    contactRoomIds: contactRooms.map((v) => v.id),
    tenantId: currentUser.tenantId,
    isPreview,
  });

  const isDisplayContactRoom = useMemo(
    () =>
      canDisplayContactRooms({
        doesExistsContactRooms: !isEmpty(contactRooms),
        doesExistsLatestMessages: !isEmpty(latestContactMessage),
        isContactInitialDisplay: tenantSettings.features.contactInitialDisplay,
      }),
    [
      canDisplayContactRooms,
      contactRooms,
      latestContactMessage,
      tenantSettings.features.contactInitialDisplay,
    ]
  );

  const linkRecord: Record<LinkKey, boolean> = {
    home: true,
    lineQr:
      currentUser.selectedAuthenticationFlowType === "line" &&
      !currentUser.isFollowedLineOfficialAccount,
    contactRooms: isDisplayContactRoom,
    announcements: !isEmpty(announcements),
    events: !isEmpty(onnEvents),
    libraries: !isEmpty(libraries) && tenantSettings.features.library,
    onnTasks: canDisplayOnnTasks(),
  };

  return typedEntries(linkRecord).flatMap(([k, v]) => (v ? [k] : []));
};
