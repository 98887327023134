import { Box } from "@material-ui/core";
import { NewInterviewEvent, NewGraduateToDisplay, OnnEventAnswerForDisplay } from "@onn/common";
import React, { useCallback } from "react";
import styled from "styled-components";

import {
  IconButton,
  TooltipWhenTextTruncated,
  Typography,
  UncontrolledMenu,
  UserIcon,
} from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const UserIconWithLabel = ({
  newGraduateToDisplay,
  onnEvent,
  onnEventAnswer,
  isAlreadyAnswered,
}: {
  newGraduateToDisplay: NewGraduateToDisplay;
  onnEvent: NewInterviewEvent;
  onnEventAnswer: OnnEventAnswerForDisplay;
  isAlreadyAnswered: boolean;
}) => {
  const { handleModal } = useModal();

  const onClickOpenRemoveModal = useCallback(() => {
    handleModal({
      name: "removeEmployeeFromOnnEventInterviewModal",
      args: {
        onnEvent,
        employee: newGraduateToDisplay,
        isAlreadyAnswered,
        onnEventAnswer,
      },
    });
  }, [handleModal, isAlreadyAnswered, newGraduateToDisplay, onnEvent, onnEventAnswer]);

  const openNewInterviewAnswerModal = () => {
    handleModal({
      name: "answerEventOnBehalfModal",
      args: {
        onnEvent,
        fixedOption: {
          selectedEmployeeId: newGraduateToDisplay.id,
        },
        mode: onnEventAnswer.selectedOnnEventSlotDate
          ? {
              type: "edit",
              onnEventSlotDateId: onnEventAnswer.selectedOnnEventSlotDate.id,
              briefingSessionCategoryId:
                onnEventAnswer.selectedOnnEventSlotDate.briefingSessionCategoryId || undefined,
            }
          : {
              type: "create",
            },
        slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
      },
    });
  };

  const onClickOpenRemoveDeterminedDateModal = useCallback(() => {
    handleModal({
      name: "removeNewInterviewDeterminedDateModal",
      args: {
        onnEvent,
        employee: newGraduateToDisplay,
        isAlreadyAnswered,
      },
    });
  }, [handleModal, isAlreadyAnswered, newGraduateToDisplay, onnEvent]);

  const menuItemOptions = [
    {
      text: onnEventAnswer.selectedOnnEventSlotDate ? "予約変更" : "代理予約",
      onClick: openNewInterviewAnswerModal,
    },
    isAlreadyAnswered
      ? {
          text: "予約取り消し",
          onClick: onClickOpenRemoveDeterminedDateModal,
        }
      : [],
    {
      text: "削除",
      onClick: onClickOpenRemoveModal,
    },
  ].flat();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mr="8px">
      <Box display="flex" alignItems="center" gridGap={"16px"} style={{ flexGrow: 1 }}>
        <UserIcon
          username={newGraduateToDisplay.getName()}
          profileIconImageUrl={newGraduateToDisplay.profileIconImageUrl}
          size={"small"}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="grid">
            <TooltipWhenTextTruncated text={newGraduateToDisplay.getName()}>
              {(ref) => (
                <StyledTypographyName ref={ref} variant={"body1"} color="textPrimary">
                  {newGraduateToDisplay.getName()}
                </StyledTypographyName>
              )}
            </TooltipWhenTextTruncated>
          </Box>
          <UncontrolledMenu
            renderButton={(openMenu) => {
              return <IconButton icon="menuVert" onClick={openMenu} />;
            }}
            menuItemOptions={menuItemOptions}
          />
        </Box>
      </Box>
    </Box>
  );
};

const StyledTypographyName = styled(Typography)`
  padding-right: 12px;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
