import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useAuthorizationNonGuarded } from "../context";
import { useCurrentTenantId } from "../tenant/useCurrentTenantId";

export const useCurrentUserNonGuarded = () => {
  const { currentTenantId } = useCurrentTenantId();
  const { authorizedUsers } = useAuthorizationNonGuarded();

  const currentUser = useMemo(() => {
    // 型ガードのために ! を isEmpty とは別に使っている
    if (!authorizedUsers || isEmpty(authorizedUsers)) {
      return null;
    }

    if (authorizedUsers.length === 1) {
      return authorizedUsers[0];
    }

    if (currentTenantId) {
      return authorizedUsers.find((v) => v.tenantId === currentTenantId) ?? null;
    }

    return null;
  }, [currentTenantId, authorizedUsers]);

  return { currentUser };
};
