import { Box } from "@material-ui/core";
import { createIMockEmployee } from "@onn/common";
import { addWeeks, format } from "date-fns";
import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";

import { FrontPage as NewGraduateFrontPage } from "./NewGraduate/FrontPage";
import { PortalContent as NewGraduatePortalContent } from "./NewGraduate/PortalContent";

import { Loading } from "~/components/uiParts";
import { useAnnouncementsForPortal } from "~/hooks/announcement/useAnnouncementsForPortal";
import { useCurrentUser } from "~/hooks/employee";
import { useLibraries } from "~/hooks/library";
import { useQuery, useSetQueryString } from "~/hooks/shared";
import { useTenant } from "~/hooks/tenant";

type LocationState = {
  isFirstLogin: boolean;
};

export const PortalIndex: FC = () => {
  const { tenant } = useTenant();

  const locationState = useLocation().state as LocationState;
  const { query } = useQuery();
  const { setQueryString } = useSetQueryString();

  const locationQuery = query.get("location");
  const isPreview = query.get("preview") === "true";
  const isFirstLogin = locationState?.isFirstLogin;

  // プレビューモードのときはcurrentUserをmockする
  const currentUserOrigin = useCurrentUser().currentUser;
  const currentUser = !isPreview
    ? currentUserOrigin
    : createIMockEmployee({
        ...currentUserOrigin,
        assignedAsNewcomer: true,
        joinAt: format(addWeeks(new Date(), 1), "yyyy-MM-dd"), // mockのcurrentUserは入社1週間前
      });

  const { data: libraries, isLoading: isLoadingLibraries } = useLibraries(
    tenant.tenantId,
    currentUser.spaceId
  );
  const { data: announcementsData, isLoading: isLoadingAnnouncements } =
    useAnnouncementsForPortal();

  const [showedFrontPage, setShowedFrontPage] = useState(false);

  const handleClickFrontPageButton = () => {
    setShowedFrontPage(true);
    setQueryString({ location: undefined });
  };

  if (isLoadingLibraries || isLoadingAnnouncements) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  if (locationQuery === "welcome" && (isFirstLogin || isPreview) && !showedFrontPage) {
    return (
      <NewGraduateFrontPage
        tenantName={tenant.tenantName}
        userName={currentUser.getName()}
        onClick={handleClickFrontPageButton}
      />
    );
  }

  return (
    <NewGraduatePortalContent
      libraries={libraries || []}
      isPreview={isPreview}
      announcementsData={announcementsData}
    />
  );
};
