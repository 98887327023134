import { Box } from "@mui/material";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { TextareaAutosize } from "~/components/uiParts/TextareaAutosize";
import { Typography } from "~/components/uiParts/Typography";

type CustomProps = {
  footerButtons: React.ReactNode;
};

type Props = ComponentProps<typeof TextareaAutosize> & CustomProps;

export const TextareaAutosizeWithFooterButtons: FC<Props> = React.forwardRef(
  ({ footerButtons, readOnly, error, helperText, ...props }, ref) => {
    return (
      <>
        <StyledForm $isError={!!error}>
          <StyledTextareaAutosize fullWidth readOnly={readOnly} {...props} ref={ref} />
          <Box
            display="flex"
            columnGap="8px"
            justifyContent="start"
            alignItems="center"
            padding="8px"
          >
            {footerButtons}
          </Box>
        </StyledForm>
        {helperText && (
          <StyledTypography variant="overline" color={error ? "error" : "textSecondary"}>
            {helperText}
          </StyledTypography>
        )}
      </>
    );
  }
);

const StyledTextareaAutosize = styled(TextareaAutosize)`
  border: none !important;
  outline: none !important;
`;

const StyledForm = styled.form<{ $isError: boolean }>`
  border: 1px solid
    ${(props) => (props.$isError ? props.theme.palette.error.main : props.theme.palette.grey[200])};
  border-radius: 4px;
  background-color: white;

  &:hover {
    border: 1px solid
      ${(props) =>
        props.$isError ? props.theme.palette.error.main : props.theme.palette.action.active};
  }

  &:focus-within {
    border: 1px solid
      ${(props) =>
        props.$isError ? props.theme.palette.error.main : props.theme.palette.primary.main};
    outline: 1px solid
      ${(props) =>
        props.$isError ? props.theme.palette.error.main : props.theme.palette.primary.main};
  }
`;

const StyledTypography = styled(Typography)`
  display: block;
`;
