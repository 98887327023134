import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC } from "react";

import { CellItem, StyleProps } from "./CellItem";

import { Icon, Typography } from "~/components/uiParts";

export const AnsweredAtCell: FC<
  {
    date?: Date;
  } & StyleProps
> = ({ date, ...styleProps }) => {
  if (!date) {
    return (
      <CellItem {...styleProps}>
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      </CellItem>
    );
  }

  return (
    <CellItem {...styleProps}>
      <Typography variant="body2" color="textSecondary">
        {format(date, "M/dd(E) H:mm", { locale: ja })}
      </Typography>
    </CellItem>
  );
};
