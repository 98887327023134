import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";
import styled from "styled-components";

import { Button, Typography } from "~/components/uiParts";

export const ModalFooter = ({
  onCancel,
  onClickSendOrResend,
  isLoading,
  lastInvitedAt,
  hasNotifiedInvitation,
}: {
  onCancel: () => void;
  onClickSendOrResend: () => void;
  isLoading: boolean;
  lastInvitedAt?: Date;
  hasNotifiedInvitation: boolean;
}) => {
  return (
    <Box width={"100%"}>
      {lastInvitedAt && (
        <Box mb="16px" textAlign="center">
          <Typography variant="caption" color="textSecondary">
            前回の招待送信日：{format(lastInvitedAt, "yyyy/MM/dd")}
          </Typography>
        </Box>
      )}
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
            disabled={isLoading}
            isLoading={isLoading}
          >
            キャンセル
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={onClickSendOrResend}
            disabled={isLoading}
            isLoading={isLoading}
          >
            招待を{hasNotifiedInvitation ? "再送" : "送信"}
          </Button>
        </StyledButtonContainer>
      </Box>
    </Box>
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 24px;
  justify-content: center;
  width: 504px;
`;
