import React from "react";

import { Button } from "~/components/uiParts";

type Props = {
  onClick(): void;
};
export const ClearAllConditionsButton = ({ onClick }: Props): JSX.Element => {
  return (
    <Button onClick={onClick} color="primary" variant="text" borderRadius="regular">
      全ての条件をクリア
    </Button>
  );
};
