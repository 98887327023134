import { OnnEventPlace } from "@onn/common";
import { useCallback } from "react";

import { useModal } from "~/hooks/modal";

export const useUpdateEventPlaceModal = () => {
  const { handleModal } = useModal();

  const openUpdateEventPlaceModal = useCallback(
    (onnEventPlace: OnnEventPlace) => {
      handleModal({
        name: "createOrUpdateEventPlaceModal",
        args: {
          data: { mode: "update", onnEventPlace },
        },
      });
    },
    [handleModal]
  );

  return { openUpdateEventPlaceModal };
};
