import liff from "@line/liff";
import { convertBytesToSize } from "@onn/common";
import React, { FC } from "react";

import { FileAttachedButtonUI } from "~/components/uiParts/FileAttachedButton";
import { downloadFileAsync } from "~/util";

export const FileAttachedButtonForStorage: FC<{ file: File; url?: string; signedUrl?: string }> = ({
  file,
  url,
}) => {
  const ext = file?.name.split(".")?.pop() || "";

  const type = (() => {
    if (["png", "jpg", "jpeg", "gif"].includes(ext)) return "openNewTab";
    return "download";
  })();

  const onClick = () => {
    if (type !== "download") {
      window.open(url, "_blank");
      return;
    }
    if (liff.isInClient() && url) {
      liff.openWindow({
        url,
        external: true,
      });
      return;
    }

    url && downloadFileAsync({ url, fileName: file.name });
    return;
  };

  return (
    <FileAttachedButtonUI
      fileName={file.name}
      type={type}
      onClick={onClick}
      fileSizeLabel={convertBytesToSize(file.size)}
    />
  );
};
