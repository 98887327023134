import { OnnFormTask, APISchema, OnnFormTaskAnswer } from "@onn/common";
import { isArray } from "lodash";
import useSWR, { useSWRConfig } from "swr";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

const generateKey = ({ newGraduateId }: { newGraduateId: string }) => {
  return {
    endpoint: "/api/onn-form-task-answers",
    newGraduateId,
  } as const;
};

type EndPoint = APISchema["/api/onn-form-task-answers"]["GET"];

export const useOnnFormTasksAnswersForAdmin = ({ newGraduateId }: { newGraduateId: string }) => {
  return useSWR(generateKey({ newGraduateId }), async ({ endpoint }) => {
    const query: EndPoint["query"] = {
      "new-graduate-id": newGraduateId,
    };
    const response = await apiClient.get(endpoint, query);
    if (!isArray(response.data)) {
      captureException({
        error: new Error("onnエンドポイントから想定外のレスポンスが返ってきています"),
        tags: {
          type: "useOnnFormTasksAnswersForAdmin",
        },
        extras: {
          response,
          query,
          endpoint,
        },
      });
      return [];
    }
    return response.data.map((v) => {
      return {
        onnTask: new OnnFormTask(v.onnTask),
        onnFormTaskAnswer: new OnnFormTaskAnswer(v.onnFormTaskAnswer),
        answerStatus: v.answerStatus,
      };
    });
  });
};

export const useMutateOnnFormTasksAnswersForAdmin = () => {
  const { mutate } = useSWRConfig();
  return {
    mutateOnnFormTasksAnswersForAdmin: (newGraduateId: string) => {
      mutate(generateKey({ newGraduateId }));
    },
  };
};
