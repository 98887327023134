import { useRef } from "react";

const MARGIN = 16;

export const useDownloadSvg = (fileName: string) => {
  const divRef = useRef<HTMLDivElement>(null);

  const downloadSvg = () => {
    // 参考: https://github.com/rosskhanas/react-qr-code/blob/c9560c81757bba9c379dcf523e7c209eb549b996/demo/src/components/App.js#L30-L47
    const svg = divRef.current?.querySelector("svg");
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width + MARGIN * 2;
      canvas.height = img.height + MARGIN * 2;

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(img, MARGIN, MARGIN);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = fileName;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return {
    divRef,
    downloadSvg,
  };
};
