import { OnnEvent } from "@onn/common";
import { useState, useEffect, useCallback, useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useModal } from "~/hooks/modal";
import { useUpdateOnnEventAssignee } from "~/hooks/onnEvent";
import { useNotifyOnnEventAnswerRemindByEventId } from "~/hooks/onnEventAnswer/useNotifyOnnEventAnswerRemindByEventId";
import { useSnackbar } from "~/hooks/shared";

const _tabValueForNewInterview = ["eventContents", "eventSlots", "interviewSchedule"] as const;
const _tabValueForOthers = ["eventContents", "answer"] as const;

type TabValueForNewInterview = (typeof _tabValueForNewInterview)[number];
type TabValueForOthers = (typeof _tabValueForOthers)[number];
type TabValue = TabValueForNewInterview | TabValueForOthers;

export const useEventDetail = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabs: Array<{ label: string; value: string }> = useMemo(() => {
    switch (onnEvent.type) {
      case "new_interview":
        return [
          {
            label: "イベント内容",
            value: "eventContents",
          },
          {
            label: "予約枠",
            value: "eventSlots",
          },
          {
            label: "面談予定",
            value: "interviewSchedule",
          },
        ];
      case "briefing_session":
        return [
          {
            label: "イベント内容",
            value: "eventContents",
          },
          {
            label: "予約枠",
            value: "eventSlots",
          },
          // RELEASE_TODO: 後続のチケットで表示と実装 https://app.clickup.com/t/86ep9u12e
          // {
          //   label: "面談予定",
          //   value: "interviewSchedule",
          // },
        ];
      case "normal":
        return [
          {
            label: "イベント内容",
            value: "eventContents",
          },
          {
            label: "回答ステータス",
            value: "answer",
          },
        ];
    }
  }, [onnEvent.type]);

  const [activeTab, setActiveTab] = useState<TabValue>("eventContents");

  const handleChangeActiveTab = (_: unknown, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };
  const isNewInterviewEvent = useMemo(() => onnEvent.isNewInterviewEvent(), [onnEvent]);

  useEffect(() => {
    if (isNewInterviewEvent) {
      switch (location.hash) {
        case "#eventContents": {
          setActiveTab("eventContents");
          break;
        }
        case "#eventSlots": {
          setActiveTab("eventSlots");
          break;
        }
        case "#interviewSchedule": {
          setActiveTab("interviewSchedule");
          break;
        }
        default: {
          void 0;
        }
      }
    } else {
      switch (location.hash) {
        case "#eventContents": {
          setActiveTab("eventContents");
          break;
        }
        case "#answer": {
          setActiveTab("answer");
          break;
        }
        default: {
          void 0;
        }
      }
    }
  }, [location.hash, navigate, isNewInterviewEvent]);

  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { updateOnnEventAssignee } = useUpdateOnnEventAssignee();
  const { notifyOnnEventAnswerRemindByEventId } = useNotifyOnnEventAnswerRemindByEventId();

  const onClickBulkRemindButton = useCallback(
    (tenantId: string, eventId: string) => {
      handleModal({
        name: "eventBulkRemindModal",
        args: {
          tenantId,
          eventId,
          onSubmit: async (tenantId: string, eventId: string) => {
            await notifyOnnEventAnswerRemindByEventId({
              tenantId: tenantId,
              onnEventId: eventId,
            }).catch((e) => {
              enqueueSnackbar("リマインド送信が失敗しました。再度お試しください。", {
                variant: "error",
              });
              throw e;
            });
            enqueueSnackbar("リマインドを送信しました", { variant: "success" });
          },
        },
      });
    },
    [handleModal, notifyOnnEventAnswerRemindByEventId, enqueueSnackbar]
  );

  const onClickAssignButton = useCallback(() => {
    handleModal({
      name: "updateEventAssigneeModal",
      args: {
        existingAssigneeIds: onnEvent.assigneeIds,
        onSubmit: async (employeeIds) => {
          updateOnnEventAssignee({ onnEventId: onnEvent.id, employeeIds });
        },
      },
    });
  }, [onnEvent, handleModal, updateOnnEventAssignee]);

  return {
    activeTab,
    handleChangeActiveTab,
    tabs,
    onClickBulkRemindButton,
    onClickAssignButton,
  };
};
