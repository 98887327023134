import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { FC, useCallback } from "react";

import { InformationValueViewer } from "../InformationValueViewer";

import { AttachmentFileLink } from "./parts/AttachmentFileLink";

import { Loading } from "~/components/uiParts";
import { useMetaDataByUrls } from "~/hooks/file";

type Props = {
  value?: string[];
  onChangeModeToEdit: () => void;
  onClickCopy?: () => void;
};

export const AttachmentFileViewer: FC<Props> = ({ value, onChangeModeToEdit, onClickCopy }) => {
  const { data: files, isLoading } = useMetaDataByUrls(value || []);

  const renderLabel = useCallback(() => {
    if (isLoading) return <Loading size="small" />;
    if (isEmpty(files)) return undefined;

    return (
      <Box py="8px" display="flex" flexDirection="column" gridRowGap="8px">
        {files?.map((file) => (
          <AttachmentFileLink key={file.name} file={file} />
        ))}
      </Box>
    );
  }, [files, isLoading]);

  return (
    <InformationValueViewer
      label={renderLabel()}
      onChangeModeToEdit={onChangeModeToEdit}
      onClickCopy={onClickCopy}
    />
  );
};
