import { v4 } from "uuid";

import { IOnnEventEvaluation, onnEventEvaluationSchema } from "./schema";

export class OnnEventEvaluation implements IOnnEventEvaluation {
  static readonly validator = onnEventEvaluationSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly onnEventId: string;
  readonly employeeId: string;

  readonly onnEventEvaluationRankId?: string;
  readonly text?: string;
  readonly filePaths: string[];

  readonly updatedEmployeeId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventEvaluation>) {
    const parsedInit = OnnEventEvaluation.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.onnEventId = parsedInit.onnEventId;
    this.employeeId = parsedInit.employeeId;

    this.onnEventEvaluationRankId = parsedInit.onnEventEvaluationRankId;
    this.text = parsedInit.text;
    this.filePaths = parsedInit.filePaths;

    this.updatedEmployeeId = parsedInit.updatedEmployeeId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static create(
    params: Omit<ExcludeMethods<OnnEventEvaluation>, "id" | "createdAt" | "updatedAt">
  ): OnnEventEvaluation {
    return new OnnEventEvaluation({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update({
    onnEventEvaluationRankId,
    text,
    filePaths,
    updatedEmployeeId,
  }: {
    onnEventEvaluationRankId?: string;
    text: string;
    filePaths?: string[];
    updatedEmployeeId: string;
  }): OnnEventEvaluation {
    return new OnnEventEvaluation({
      ...this,
      updatedAt: new Date(),
      onnEventEvaluationRankId,
      text,
      filePaths,
      updatedEmployeeId,
    });
  }
}
