import { Box, Stack } from "@mui/material";
import React, { FC, useState } from "react";

import styled from "styled-components";

import { IconButton, TextField } from "~/components/uiParts";

export const InformationGroupInputRow: FC<{
  value: string;
  onChange: (value: string) => void;
  errorText?: string;
  onClickRemoveButton: () => void;
}> = ({ value, onChange, errorText, onClickRemoveButton }) => {
  // TextFieldのhelperTextに以前の値を表示し続けるためにuseStateを使って初期値を固定している
  const [defaultLabel] = useState(value);

  return (
    <Stack direction="row" alignItems="start" spacing={1} py={1} width="100%">
      <TextField
        fullWidth
        variant="outlined"
        placeholder="グループ名を入力してください。"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        error={!!errorText}
        helperText={errorText || defaultLabel}
      />
      <Box px={1} py={1} display="flex" justifyContent="center">
        <StyledIconButton icon="close" size="ssm" color="grey400" onClick={onClickRemoveButton} />
      </Box>
    </Stack>
  );
};

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    padding: 2px;
  }
`;
