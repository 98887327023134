import liff from "@line/liff";
import { useCallback } from "react";

import { useIssueCustomTokenForLine } from "../useIssueCustomTokenForLine";

import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";
import { captureMessage } from "~/util/loggerUtil";

const captureExceptionForDebugging = async () => {
  const profile = await liff.getProfile().catch((e) => {
    captureException({
      error: new Error(`issueCustomTokenForLine でプロフィールが取れませんでした`),
      tags: {
        type: "issueCustomTokenForLine",
      },
      extras: {
        location: window.location.href,
        isCookieEnabled: navigator.cookieEnabled,
        e,
        dummyName: document.cookie,
      },
    });
  });
  if (!profile) {
    return;
  }

  const userId = profile.userId;
  // NOTE: エラー発生していた対象ユーザーの userId
  const isTarget = userId === "U30806ddfd7b063f603e4bf8d0832870b";
  if (isTarget) {
    captureException({
      error: new Error(`[調査用] issueCustomTokenForLine が実行されました`),
      tags: {
        type: "issueCustomTokenForLine",
      },
      extras: {
        location: window.location.href,
        isCookieEnabled: navigator.cookieEnabled,
        profile,
        dummyName: document.cookie,
      },
    });
  }
};

export const useSigninWithAccessToken = () => {
  const { issueCustomTokenForLine } = useIssueCustomTokenForLine();
  const signinWithLineAccessToken = useCallback(
    async ({ accessToken }: { accessToken: string }) => {
      // Firebaseカスタムトークンの発行
      let customToken: string;
      try {
        captureMessage({
          message: "issueCustomTokenForLine が実行されました",
          tags: { type: "issueCustomTokenForLine" },
          extras: {
            accessToken,
          },
        });
        captureExceptionForDebugging();
        customToken = await issueCustomTokenForLine({
          accessToken,
        });
      } catch (e) {
        captureException({
          error: new Error(`issueCustomTokenForLineが失敗しました`),
          tags: {
            type: "issueCustomTokenForLine",
          },
          extras: {
            location: window.location.href,
            isCookieEnabled: navigator.cookieEnabled,
            e,
          },
        });
        throw new Error(`Firebase Authentication カスタムトークンの発行に失敗しました。`);
      }

      try {
        captureMessage({
          message: "signInWithCustomToken が実行されました",
          tags: { type: "signInWithCustomToken" },
          extras: {
            accessToken,
            customToken,
          },
        });
        await AccountUseCase.signInWithCustomToken(customToken);
      } catch (e) {
        captureMessage({
          message: "signInWithCustomTokenが失敗しました",
          tags: {
            type: "signInWithCustomToken",
          },
          extras: {
            location: window.location.href,
            isCookieEnabled: navigator.cookieEnabled,
            e,
          },
        });
        throw new Error("Firebase Authentication カスタムトークンによるログインに失敗しました。");
      }

      captureMessage({
        message: "signInWithCustomToken が成功しました",
        tags: { type: "signInWithCustomToken" },
        extras: {
          accessToken,
          customToken,
        },
      });
    },
    [issueCustomTokenForLine]
  );

  return { signinWithLineAccessToken };
};
