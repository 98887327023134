import useSWR from "swr";

import { OnnTaskFormAnswerDraftUseCase } from "~/service/usecases/onnTaskFormAnswerDraftUseCase";

const onnTaskFormAnswerDraftUsecase = new OnnTaskFormAnswerDraftUseCase();

export const useOnnTaskFormAnswerDraft = ({
  tenantId,
  employeeId,
  onnTaskFormAnswerId,
}: {
  tenantId: string;
  employeeId: string;
  onnTaskFormAnswerId: string;
}) => {
  const key = () =>
    ({
      collectionName: "onn-task-form-answer-draft",
      tenantId,
      employeeId,
      onnTaskFormAnswerId,
    } as const);
  return useSWR(
    key,
    async ({ tenantId, employeeId, onnTaskFormAnswerId }) =>
      await onnTaskFormAnswerDraftUsecase.findOrCreateNew({
        tenantId,
        employeeId,
        onnTaskFormAnswerId,
      }),
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    }
  );
};
