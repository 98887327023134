import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import { instantiateFromAnyTriggerSettingExcludeMethods } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { ButtonToAddAction } from "../../../components/TriggerBlock/ButtonToAddAction";
import { ButtonToDeleteTrigger } from "../../../components/TriggerBlock/ButtonToDeleteTrigger";
import { TriggerDescriptionArea } from "../../../components/TriggerBlock/TriggerDescriptionArea/TriggerDescriptionArea";
import { useErrorTargetText } from "../../../components/TriggerBlock/useErrorTargetText";
import { useMenu } from "../../../components/useMenu";
import { TriggerBlockWidth } from "../../../constant/constant";

import { InputBlockState } from "../../../hooks/scenarioForm/InputState";

import { useRemoveActionFromForm } from "../../../hooks/scenarioForm/actions/useRemoveActionFromForm";
import { useUpdateTriggerSettingOnForm } from "../../../hooks/scenarioForm/triggers/useUpdateTriggerOnForm";

import { useIsActionRelatedDeletedStatus } from "../../hooks/useIsActionRelatedDeletedStatus";
import { useIsTriggerRelatedDeletedStatus } from "../../hooks/useIsTriggerRelatedDeletedStatus";
import { usePopoverOfActionSetting } from "../../hooks/usePopoverOfActionSetting";
import { usePopoverOfTriggerSetting } from "../../hooks/usePopoverOfTriggerSetting";
import { useDescriptionForEditMode } from "../../useDescriptionForEditMode";
import { ActionAreaForEditMode } from "../ActionAreaForEditMode/ActionAreaForEditMode";
import { PopoverOfActionSetting } from "../ActionPopovers/PopoverOfActionSetting";
import { MenuOfActionSelection } from "../MenuOfActionSelection";

import { MenuOfTriggerSelection } from "../MenuOfTriggerSelection";
import {
  PopoverOfApplyTriggerSettings,
  TriggerSettingsFormInputValue,
} from "../PopoverOfApplyTriggerSettings";

import { useApplyToScenarioForm } from "./useApplyToScenarioForm";
import { useExtractActionConditionError } from "./useExtractActionConditionError";

import { Icon, Typography } from "~/components/uiParts";

type Props = {
  block: InputBlockState;
  waitingTriggerBlockCount: number;
  deleteTriggerBlock: () => void;
  blockIndex: number;
};

export const TriggerBlockForEditMode: FC<Props> = ({
  block,
  waitingTriggerBlockCount,
  deleteTriggerBlock,
  blockIndex,
}) => {
  const { extractActionConditionError } = useExtractActionConditionError();
  const { updateTriggerSettingOnForm } = useUpdateTriggerSettingOnForm({
    recruitmentStatusId: block.trigger.recruitmentStatusId,
    triggerId: block.trigger.id,
  });

  const { isTriggerRelatedDeletedStatus } = useIsTriggerRelatedDeletedStatus(block.triggerSetting);
  const { isActionRelatedDeletedStatus } = useIsActionRelatedDeletedStatus(block.actions);
  const isChangeRecruitmentStatusTriggerError = judgeIsChangeRecruitmentStatusTriggerError({
    block,
    blockIndex,
  });
  const { removeActionFromForm } = useRemoveActionFromForm({
    recruitmentStatusId: block.trigger.recruitmentStatusId,
    triggerId: block.trigger.id,
  });

  const { description, triggerSubDescription } = useDescriptionForEditMode({
    triggerSetting: block.triggerSetting,
  });
  const { errorTargetText: deletedRecruitmentStatusErrorText } = useErrorTargetText({
    isTriggerRelatedDeletedStatus,
    isActionRelatedDeletedStatus,
  });

  const actionSelectMenu = useMenu();

  const popoverOfActionSetting = usePopoverOfActionSetting();

  const triggerSelectMenu = useMenu();
  const popoverOfApplyTriggerSettings = usePopoverOfTriggerSetting();

  // NOTE: アクション設定セレクトメニューとトリガー設定変更ポップオーバーを同じ位置に表示させるためアンカー要素を同期させる
  popoverOfApplyTriggerSettings.anchorEl = triggerSelectMenu.anchorEl;
  // NOTE: アクション設定セレクトメニューとアクション設定変更ポップオーバーを同じ位置に表示させるためアンカー要素を同期させる
  popoverOfActionSetting.anchorEl = actionSelectMenu.anchorEl;

  const changeTriggerSetting = useCallback(
    (inputValue: TriggerSettingsFormInputValue) => {
      const anyTriggerSetting = instantiateFromAnyTriggerSettingExcludeMethods({
        ...block.triggerSetting,
        ...inputValue,
        updatedAt: new Date(),
      });

      updateTriggerSettingOnForm(anyTriggerSetting);
    },
    [block.triggerSetting, updateTriggerSettingOnForm]
  );

  const { applyToScenarioForm } = useApplyToScenarioForm({
    recruitmentStatusId: block.trigger.recruitmentStatusId,
    triggerSetting: block.triggerSetting,
    closePopover: popoverOfActionSetting.close,
  });

  const { actionConditionErrorMessage } = extractActionConditionError({
    recruitmentStatusId: block.trigger.recruitmentStatusId,
    triggerId: block.trigger.id,
  });
  const isActionConditionError = !!actionConditionErrorMessage;

  const isBlockError =
    isTriggerRelatedDeletedStatus ||
    isActionConditionError ||
    isChangeRecruitmentStatusTriggerError;
  return (
    <Root $isError={isBlockError}>
      <TriggerDescriptionArea
        type={block.triggerSetting.type}
        triggerId={block.trigger.id}
        triggerSettingId={block.triggerSetting.id}
        waitingTriggerBlockCount={waitingTriggerBlockCount}
        actionCount={block.actions.length}
        actionConditionCount={block.actions.filter((v) => v.actionCondition).length}
        triggerDescription={description}
        triggerSubDescription={triggerSubDescription}
        isError={isBlockError}
        isEditMode
        editButtonRef={triggerSelectMenu.anchorEl}
        onClickEditButton={() => popoverOfApplyTriggerSettings.open(block.triggerSetting.type)}
      />
      {block.actions.map((actionWithRelatedInfo) => (
        <ActionAreaForEditMode
          key={actionWithRelatedInfo.action.id}
          triggerId={block.trigger.id}
          recruitmentStatusId={block.trigger.recruitmentStatusId}
          actionWithRelatedInfo={actionWithRelatedInfo}
          triggerSetting={block.triggerSetting}
          deleteAction={() => removeActionFromForm({ actionId: actionWithRelatedInfo.action.id })}
          actionConditionErrorMessage={actionConditionErrorMessage}
        />
      ))}
      {deletedRecruitmentStatusErrorText && (
        <Box display="flex" gridColumnGap="8px" alignItems="center">
          <Icon icon="alert" size="md" color="secondary" />
          <Typography variant="caption" color="secondary">
            {`${deletedRecruitmentStatusErrorText}に削除された「選考ステータス」が含まれています。\nエラーマークが表示されている箇所の条件や設定を編集してください。`}
          </Typography>
        </Box>
      )}
      {isChangeRecruitmentStatusTriggerError && (
        <Box display="flex" gridColumnGap="8px" alignItems="center">
          <Icon icon="alert" size="md" color="secondary" />
          <Typography variant="caption" color="secondary">
            {`選考ステータスが変更された時に発火するトリガーは、１番始めに設定してください。`}
          </Typography>
        </Box>
      )}
      <Stack direction="row" columnGap="8px">
        <ButtonToAddAction
          onClick={actionSelectMenu.openMenu}
          buttonRef={actionSelectMenu.anchorEl}
        />
        <ButtonToDeleteTrigger
          onClick={() => deleteTriggerBlock()}
          disabled={waitingTriggerBlockCount > 0}
        />
      </Stack>
      <MenuOfActionSelection
        anchorEl={actionSelectMenu.anchorEl.current}
        onClose={actionSelectMenu.closeMenu}
        onClickItem={(type) => {
          // TODO: ActionTypeとAnyActionSetting["type"] の型を揃える
          switch (type) {
            case "ChangeRecruitmentStatusActionSetting":
              popoverOfActionSetting.open("changeRecruitmentStatus");
              break;
            case "DeliverOnnEventActionSetting":
              popoverOfActionSetting.open("deliveryOnnEvent");
              break;
            case "DeliverOnnTaskActionSetting":
              popoverOfActionSetting.open("deliveryOnnTask");
              break;
            case "SendContactMessageActionSetting":
              popoverOfActionSetting.open("sendContactMessage");
              break;
            default: {
              const _: never = type;
              break;
            }
          }
          actionSelectMenu.closeMenu();
        }}
        isOpen={actionSelectMenu.isOpen}
      />
      {popoverOfActionSetting.openedActionType && (
        <PopoverOfActionSetting
          key={`${String(popoverOfActionSetting.isOpen)}-${block.trigger.id}-${
            popoverOfActionSetting.openedActionType
          }`}
          anchorEl={popoverOfActionSetting.anchorEl.current}
          onClose={popoverOfActionSetting.close}
          isOpen={popoverOfActionSetting.isOpen}
          mode="create"
          existingAction={null}
          existingAnyActionSetting={null}
          existingActionCondition={null}
          backToSelectActionType={() => {
            actionSelectMenu.openMenu();
            popoverOfActionSetting.close();
          }}
          triggerSetting={block.triggerSetting}
          actionType={popoverOfActionSetting.openedActionType}
          onClickApplyButton={applyToScenarioForm}
        />
      )}
      <MenuOfTriggerSelection
        anchorEl={triggerSelectMenu.anchorEl.current}
        onClose={triggerSelectMenu.closeMenu}
        onSelectTriggerSettingsType={(type) => {
          popoverOfApplyTriggerSettings.open(type);
          triggerSelectMenu.closeMenu();
        }}
        isOpen={triggerSelectMenu.isOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      />
      {popoverOfApplyTriggerSettings.openedTriggerSettingType && (
        <PopoverOfApplyTriggerSettings
          backToSelectTriggerType={() => {
            triggerSelectMenu.openMenu();
            popoverOfApplyTriggerSettings.close();
          }}
          currentTriggerSetting={block.triggerSetting}
          triggerSettingType={popoverOfApplyTriggerSettings.openedTriggerSettingType}
          anchorEl={popoverOfApplyTriggerSettings.anchorEl.current}
          onClose={popoverOfApplyTriggerSettings.close}
          isOpen={popoverOfApplyTriggerSettings.isOpen}
          onApplyTriggerSetting={changeTriggerSetting}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        />
      )}
    </Root>
  );
};

// NOTE: トリガー設定が「選考ステータスの変更」の場合、１番目に設定されているかどうかを判定する
const judgeIsChangeRecruitmentStatusTriggerError = ({
  block,
  blockIndex,
}: {
  block: InputBlockState;
  blockIndex: number;
}) => {
  if (block.triggerSetting.type === "ChangeRecruitmentStatusTriggerSetting") {
    if (blockIndex !== 0) {
      return true;
    }
  }
  return false;
};

const Root = styled(Box)<{ $isError: boolean }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border: 2px solid
    ${(props) =>
      props.$isError ? props.theme.palette.secondary.main : props.theme.palette.primary.main};
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.common.white};
  width: ${TriggerBlockWidth}px;
`;
