import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { FC, useCallback, MouseEvent, useState } from "react";
import styled from "styled-components";

import { Icon, UserIcon, Tooltip } from "~/components/uiParts";

type Props = {
  username: string;
  imageUrl?: string;
  title: string;
  menuItemBoxes: {
    onClick: () => void;
    content: React.ReactNode;
  }[];
};

export const UserIconMenu: FC<Props> = ({ username, imageUrl, title, menuItemBoxes }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Tooltip title={title} placement="top" arrow={true}>
        <StyledBox onClick={openMenu}>
          <UserIcon
            username={username}
            profileIconImageUrl={imageUrl}
            size={"semiSmall"}
            circular
          />
          <Icon icon="chevronDown" color="grey400" size="sm" />
        </StyledBox>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {menuItemBoxes.map((itemBox, index) => (
          <MenuItem
            key={index}
            onClick={(e) => {
              closeMenu(e);
              itemBox.onClick();
            }}
          >
            <Box width={220}>{itemBox.content}</Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
