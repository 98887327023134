// ポータルのホスティング先を環境変数を元に取得する関数
export const generateNewGraduateHost = (tenantId: string) => {
  switch (process.env.NODE_ENV) {
    case "localhost":
      return `http://${tenantId}.localhost:8080`; // e.g. http://tenantName.localhost:8080
    case "staging":
      return `https://staging.${tenantId}.onn-hr.com`; // e.g. https://staging.tenantName.onn-hr.com
    case "production":
      return `https://${tenantId}.onn-hr.com`; // e.g. https://tenantName.onn-hr.com
    case "preview":
      return process.env.HOST;
    case "lab":
      return `https://lab.${tenantId}.onn-hr.com`; // e.g. https://lab.tenantName.onn-hr.com
    default:
      return "";
  }
};
