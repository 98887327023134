import { v4 } from "uuid";

import { IEmployeePrediction, employeePredictionSchema } from "./schema";

export class EmployeePrediction implements IEmployeePrediction {
  static validator = employeePredictionSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;

  readonly label: string;
  readonly order: number;

  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<EmployeePrediction>) {
    const parsedInit = employeePredictionSchema.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;

    this.label = parsedInit.label;
    this.order = parsedInit.order;

    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(init: ExcludeMethods<Omit<EmployeePrediction, "id" | "createdAt" | "updatedAt">>) {
    const parsedInit = EmployeePrediction.validator.parse({
      ...init,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    return new EmployeePrediction(parsedInit);
  }

  updateOrderAndLabel({ order, label }: { order: number; label: string }) {
    return new EmployeePrediction({
      ...this,
      order,
      label,
      updatedAt: new Date(),
    });
  }
}
