import { Box } from "@material-ui/core";
import styled from "styled-components";

export const TableRowWrapper = styled(Box)`
  :not(:last-child)&::after {
    content: "";
    background-color: ${(props) => props.theme.palette.grey[100]};
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 0%;
    height: 1px;
  }
  > a {
    > div {
      > div {
        padding: 0;
        padding-right: 16px;
      }
      > div:first-child {
        padding-left: 24px;
        padding-right: 0px;
      }
      > div:last-child {
        padding-right: 24px;
      }
    }
  }
`;
