import { z } from "zod";

import { RecruitmentProcessRecordItemTypeSchema } from "../inputTypeSchemas/RecruitmentProcessRecordItemTypeSchema";

/////////////////////////////////////////
// RECRUITMENT PROCESS RECORD ITEM SCHEMA
/////////////////////////////////////////

export const RecruitmentProcessRecordItemSchema = z.object({
  type: RecruitmentProcessRecordItemTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  employeeId: z.string(),
  recruitmentStatusId: z.string(),
  recruitmentProcessRecordId: z.string(),
  onnEventId: z.string().nullish(),
  onnTaskId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RecruitmentProcessRecordItem = z.infer<typeof RecruitmentProcessRecordItemSchema>;

/////////////////////////////////////////
// RECRUITMENT PROCESS RECORD ITEM OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const RecruitmentProcessRecordItemOptionalDefaultsSchema =
  RecruitmentProcessRecordItemSchema.merge(z.object({}));

export type RecruitmentProcessRecordItemOptionalDefaults = z.infer<
  typeof RecruitmentProcessRecordItemOptionalDefaultsSchema
>;

export default RecruitmentProcessRecordItemSchema;
