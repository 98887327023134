import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

type Props = {
  errorMessage: string;
};

export const ErrorMessageContent: FC<Props> = ({ errorMessage }) => {
  return (
    <StyledBox>
      <Typography color="secondary" bold={true}>
        エラーの内容を確認し、修正したファイルをアップロードしてください
      </Typography>
      <Box mt={1}>
        <StyledTypography variant="body2">{errorMessage}</StyledTypography>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  padding: 16px;
  color: ${(props) => props.theme.palette.secondary.main};
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  border-radius: 4px;
`;

const StyledTypography = styled(Typography)`
  word-break: break-all;
  color: ${(props) => props.theme.palette.secondary.main};
`;
