import { zodResolver } from "@hookform/resolvers/zod";
import { OnnEvent, OnnEventAnswer, CandidateDateWithNumberOfParticipants } from "@onn/common";

import { isPast } from "date-fns";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { attendNormalEventOnBehalfFormSchema } from "./attendNormalEventOnBehalfFormSchema";

import { useCurrentUser } from "~/hooks/employee";
import { useUpdateAttendanceStatus } from "~/hooks/onnEvent";
import { mutateOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { mutateOnnEventAnswersWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";
import { mutateDeterminedDate } from "~/hooks/onnEvent/determinedDate/useDeterminedDate";

import { mutateCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent/useCandidateDatesWithNumberOfParticipants";
import { mutateOnnEvent } from "~/hooks/onnEvent/useOnnEvent";
import { useSnackbar } from "~/hooks/shared";

type InputState = {
  candidateDateId: string;
};

type Args = {
  onnEvent: OnnEvent;
  currentOnnEventAnswer: OnnEventAnswer;
  onSubmit: () => void;
  candidateDatesWithNumberOfParticipants: CandidateDateWithNumberOfParticipants[];
};

export const useAttendNormalEventOnBehalfForm = ({
  onnEvent,
  currentOnnEventAnswer,
  onSubmit,
  candidateDatesWithNumberOfParticipants,
}: Args) => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();
  const { execUpdateAttendanceStatus } = useUpdateAttendanceStatus();
  const { enqueueSnackbar } = useSnackbar();

  const defaultSelectedValue = useMemo(
    () =>
      currentOnnEventAnswer.isAnswered()
        ? (() => {
            if (currentOnnEventAnswer.isUnavailableCandidates()) return undefined;
            const answeredCandidateDateId = currentOnnEventAnswer.getPossibleCandidateDateId();
            return onnEvent.candidateDates.find((date) => date.id === answeredCandidateDateId)?.id;
          })()
        : undefined,
    [currentOnnEventAnswer, onnEvent.candidateDates]
  );

  const form = useForm<InputState>({
    defaultValues: {
      candidateDateId: defaultSelectedValue,
    },
    mode: "onChange",
    resolver: zodResolver(attendNormalEventOnBehalfFormSchema),
  });

  /**
   * NOTE: 参加登録できる候補日はすでに開催時刻を迎えたもののみが対象
   */
  const candidateDateOptions = useMemo(
    () =>
      onnEvent.candidateDates
        .filter((date) => isPast(date.from))
        .map((date) => {
          const candidateDateDetail = (candidateDatesWithNumberOfParticipants || []).find(
            (v) => v.id === date.id
          );

          const disabled = candidateDateDetail && !candidateDateDetail.canParticipate();
          return {
            value: date.id,
            candidateDate: candidateDateDetail,
            disabled,
          };
        }),
    [candidateDatesWithNumberOfParticipants, onnEvent]
  );

  return {
    ...form,
    candidateDateOptions,
    isLoading,
    handleSubmit: form.handleSubmit(async (inputValue: InputState) => {
      setIsLoading(true);

      await execUpdateAttendanceStatus({
        onnEventId: onnEvent.id,
        targetEmployeeId: currentOnnEventAnswer.employeeId,
        type: "normal",
        updates: {
          attendanceStatus: "ATTENDED",
          selectedCandidateDateId: inputValue.candidateDateId,
        },
      })
        .then(() => {
          onSubmit();
          enqueueSnackbar("ステータスが「参加済み」に変更されました", { variant: "success" });
          mutateDeterminedDate(onnEvent.id);
          mutateOnnEvent(onnEvent.id);
          mutateOnnEventAnswers(onnEvent.id);
          mutateOnnEventAnswersWithEmployee(onnEvent.id);
          mutateCandidateDatesWithNumberOfParticipants(currentUser.id, onnEvent.id);
        })
        .catch(() => {
          enqueueSnackbar("エラーが発生しました。Onnサポートチームまでお問い合わせください。", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }),
  };
};
