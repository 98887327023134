import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent/OnnEvent/schema";

export const listOnnEventDeterminedDateRequestSchema = z.object({
  onnEventId: onnEventSchema.shape.id,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IListOnnEventDeterminedDateRequest
  extends z.infer<typeof listOnnEventDeterminedDateRequestSchema> {}
