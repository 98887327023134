import { z } from "zod";

import { onnEventAnswerSchema } from "../../../../domain/OnnEvent/OnnEventAnswer";

export const removeFromDeliveredNewGraduatesRequestSchema = z.object({
  onnEventAnswer: onnEventAnswerSchema.omit({ id: true }),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRemoveFromDeliveredNewGraduatesRequest
  extends z.infer<typeof removeFromDeliveredNewGraduatesRequestSchema> {}
