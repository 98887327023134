import { Announcement, AnnouncementDistribution } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

export const useAnnouncementsForPortal = () => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    ["/announcement_api/announcements-for-portal", currentUser.id] as const,
    async ([endpoint, announcementId]) => {
      const data = await apiClient.get(endpoint, { announcementId }).then((res) => res.data);

      return {
        announcements: data.announcements.map((announcement) => new Announcement(announcement)),
        announcementDistributions: data.announcementDistributions.map(
          (announcementDistribution) => new AnnouncementDistribution(announcementDistribution)
        ),
      };
    }
  );
};
