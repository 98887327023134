import { Employee, OnnFormTaskAnswer } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { useDeleteOnnFormTaskAnswer } from "./useDeleteOnnFormTaskAnswer";

import { mutateOnnFromTaskAnswers } from "./useOnnFormTaskAnswers";

import { useMutateOnnFormTasksAnswersForAdmin } from "./useOnnFormTasksAnswersForAdmin";

import { useModal } from "~/hooks/modal";

/**
 * タスクの「配信取消」ボタンを押した際の処理を共通化したカスタムフック
 */
export const useOnClickCancelDelivery = () => {
  const { handleModal } = useModal();
  const { execDeleteOnnFormTaskAnswer } = useDeleteOnnFormTaskAnswer();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateOnnFormTasksAnswersForAdmin } = useMutateOnnFormTasksAnswersForAdmin();

  const deleteOnnFormTaskAnswer = useCallback(
    async (onnFormTaskAnswer: OnnFormTaskAnswer) => {
      await execDeleteOnnFormTaskAnswer({ onnFormTaskAnswerId: onnFormTaskAnswer.id })
        .catch(async (e) => {
          enqueueSnackbar("削除に失敗しました。管理者より連絡がくるまで、お待ちください。", {
            variant: "error",
          });
          throw e;
        })
        .finally(() => {
          mutateOnnFromTaskAnswers(onnFormTaskAnswer.formTaskId);
          mutateOnnFormTasksAnswersForAdmin(onnFormTaskAnswer.employeeId);
        });

      // NOTE: システムの応答はシンプルに保つため、一旦、削除対象者の情報は表示しない
      // - 対応する場合は他の部分もまとめて議論
      enqueueSnackbar(
        "削除が完了しました。該当の候補者には削除された旨を伝える通知が送信されています。",
        { variant: "success" }
      );
    },
    [enqueueSnackbar, execDeleteOnnFormTaskAnswer, mutateOnnFormTasksAnswersForAdmin]
  );

  const onClickCancelDelivery = useCallback(
    (onnFromTaskAnswer: OnnFormTaskAnswer, onnTaskTitle: string, employee: Employee) => {
      //  未回答の場合はモーダルで確認を取らずに削除する
      if (!onnFromTaskAnswer.isAnswered()) {
        deleteOnnFormTaskAnswer(onnFromTaskAnswer);
        return;
      }

      handleModal({
        name: "removeEmployeeFromOnnTaskAnswersModal",
        args: {
          onSubmit: () => deleteOnnFormTaskAnswer(onnFromTaskAnswer),
          taskTitle: onnTaskTitle,
          employeeName: employee.getName(),
        },
      });
    },
    [deleteOnnFormTaskAnswer, handleModal]
  );

  return { onClickCancelDelivery };
};
