import React, { FC } from "react";

import { Tooltip } from "../Tooltip";

import { useIsTruncated } from "~/hooks/shared";

type Props = {
  text: string;
  children: (ref: (node: HTMLElement) => false | undefined) => React.ReactElement<HTMLElement>;
};

/**
 * @deprecated 使い方によっては無限レンダリングが発生して画面がクラッシュする可能性があるので新規では使用しないでください
 * 経緯：https://github.com/workside-inc/onn/pull/6436
 * テキストが省略されたときに全文をツールチップに表示する機能を提供するコンポーネント
 */
export const TooltipWhenTextTruncated: FC<Props> = ({ text, children }) => {
  const { isTruncated, setRef } = useIsTruncated();

  return (
    <Tooltip title={isTruncated ? text : ""} arrow>
      {children(setRef)}
    </Tooltip>
  );
};
