import { z } from "zod";

export const RecruitmentStatusTypeSchema = z.enum([
  "pre_entry",
  "screening",
  "job_offer",
  "offer_accepted",
  "rejected",
  "withdrew",
]);

export type RecruitmentStatusTypeType = `${z.infer<typeof RecruitmentStatusTypeSchema>}`;

export default RecruitmentStatusTypeSchema;
