import { ListOnnEventsRequest, ListOnnEventsResponse } from "@onn/common";
import useSWR from "swr";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export const useNotExpiredOnnEvents = ({ tenantId }: { tenantId: string }) => {
  const CF = "onnevent";
  const key: SWRKeyWhenCF<{
    tenantId: string;
    isExpired: boolean;
  }> = {
    CF,
    actionType: ListOnnEventsRequest.actionType,
    args: { tenantId, isExpired: false },
  };

  return useSWR(key, async ({ args }) => {
    const response = await functionOperator.httpsCallFor2ndGen<
      ReturnType<typeof ListOnnEventsRequest.createRequestBody>,
      typeof ListOnnEventsResponse.ResponseBody
    >(CF, ListOnnEventsRequest.createRequestBody(args));
    const { onnEvents } = ListOnnEventsResponse.convertUnixTimeToDate(response.data);

    return onnEvents;
  });
};
