const OnnEventErrorType = {
  NO_EXISTING_ONNEVENT_TITLE: "NO_EXISTING_ONNEVENT_TITLE",
  DUPLICATED_ONNEVENT_TITLE: "DUPLICATED_ONNEVENT_TITLE",
} as const;

const OnnTaskErrorType = {
  NO_EXISTING_ONNTASK_TITLE: "NO_EXISTING_ONNTASK_TITLE",
  DUPLICATED_ONNTASK_TITLE: "DUPLICATED_ONNTASK_TITLE",
} as const;

const TagErrorType = {
  NO_EXISTING_TAG_NAME: "NO_EXISTING_TAG_NAME",
  TAG_NAME_WITH_HALF_SLASH: "TAG_NAME_WITH_HALF_SLASH",
  DUPLICATED_TAG_NAME: "DUPLICATED_TAG_NAME",
} as const;

const RecruitmentStatusErrorType = {
  NO_EXISTING_RECRUITMENT_STATUS_LABEL: "NO_EXISTING_RECRUITMENT_STATUS_LABEL",
} as const;

const InvitationFlagErrorType = {
  INVALID_SEND_INVITATION_FLAG: "INVALID_SEND_INVITATION_FLAG",
} as const;

const NewGraduateInformationInCsvErrorType = {
  INVALID_FORMAT_GENDER_IN_CSV: "INVALID_FORMAT_GENDER_IN_CSV",
  INVALID_FORMAT_DATE_OF_BIRTH_IN_CSV: "INVALID_FORMAT_DATE_OF_BIRTH_IN_CSV",
  INVALID_FORMAT_GRADUATION_YEAR_IN_CSV: "INVALID_FORMAT_GRADUATION_YEAR_IN_CSV",
  INVALID_FORMAT_GRADUATION_MONTH_IN_CSV: "INVALID_FORMAT_GRADUATION_MONTH_IN_CSV",
  INVALID_FORMAT_ADDRESS_PREFECTURE_IN_CSV: "INVALID_FORMAT_ADDRESS_PREFECTURE_IN_CSV",
  INVALID_FORMAT_HOMETOWN_ADDRESS_PREFECTURE_IN_CSV:
    "INVALID_FORMAT_HOMETOWN_ADDRESS_PREFECTURE_IN_CSV",
  INVALID_FORMAT_OFFER_ACCEPTANCE_DEADLINE_IN_CSV:
    "INVALID_FORMAT_OFFER_ACCEPTANCE_DEADLINE_IN_CSV",
} as const;

const MentorErrorType = {
  EMAIL_NOT_ASSIGNABLE_MENTOR: "EMAIL_NOT_ASSIGNABLE_MENTOR",
} as const;

export const ErrorType = {
  OVER_THE_LIMIT: "OVER_THE_LIMIT",
  NO_SPACE_NAME: "NO_SPACE_NAME",
  INVALID_SPACE_NAME: "INVALID_SPACE_NAME",
  MISMATCH_SPACE_NAME: "MISMATCH_SPACE_NAME",
  NO_EMAIL_ADDRESS: "NO_EMAIL_ADDRESS",
  DUPLICATED_EMAIL_ADDRESS_IN_CSV: "DUPLICATED_EMAIL_ADDRESS_IN_CSV",
  INVALID_EMAIL_ADDRESS: "INVALID_EMAIL_ADDRESS",
  ALREADY_REGISTERED_EMAIL_ADDRESS: "ALREADY_REGISTERED_EMAIL_ADDRESS",
  NOT_FOUND_EMPLOYEE_BY_EMAIL_ADDRESS: "NOT_FOUND_EMPLOYEE_BY_EMAIL_ADDRESS",
  ALREADY_DELETED_USER: "ALREADY_DELETED_USER",
  ALREADY_OTHER_SPACE_EMAIL_ADDRESS: "ALREADY_OTHER_SPACE_EMAIL_ADDRESS",
  ...InvitationFlagErrorType,
  ...RecruitmentStatusErrorType,
  ...TagErrorType,
  ...OnnEventErrorType,
  ...OnnTaskErrorType,
  ...NewGraduateInformationInCsvErrorType,
  ...MentorErrorType,
  ALREADY_TAG_REGISTERED: "ALREADY_TAG_REGISTERED",
} as const;

export type ErrorTypes = keyof typeof ErrorType;

export type ErrorStatus = Map<ErrorTypes, number[]>;
