import React, { FC } from "react";

import { Button, Icon } from "~/components/uiParts";

export const AddQuestionButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    color="primary"
    variant="dashedOutlined"
    borderRadius="regular"
    startIcon={<Icon icon="add" color="primary" size="md" />}
    onClick={onClick}
    fullWidth
  >
    設問を追加
  </Button>
);
