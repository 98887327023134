import { z } from "zod";

import { LinkRichMenuTransactionSchema } from "../../_gen/zodSchema/index";

export const linkRichMenuTransactionSchema = LinkRichMenuTransactionSchema.extend({
  completedAt: z.date().optional(),
  richMenuName: z.string().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinkRichMenuTransaction extends z.infer<typeof linkRichMenuTransactionSchema> {}
