import { Box } from "@material-ui/core";
import { FormRevision } from "@onn/common";

import {
  CheckBoxAnswer,
  FileAnswer,
  RadioAnswer,
  TextAnswer,
} from "@onn/common/domain/OnnTask/Answer/Answer";
import React, { FC } from "react";

import { State } from "../../reducer";

import { CheckboxQuestionContent } from "../preview/CheckboxQuestionContent";
import { FileQuestionContent } from "../preview/FileQuestionContent";
import { RadioQuestionContent } from "../preview/RadioQuestionContent";

import { Typography } from "~/components/uiParts";
type Props = {
  formRevision: FormRevision;
  state: State;
  sampleFileSignedUrlMapObj: Record<string, string>;
};

export const AnswerPreview: FC<Props> = ({ formRevision, state, sampleFileSignedUrlMapObj }) => {
  return formRevision.questions.map((question) => {
    const answer = state.answers.find((a) => a.questionId === question.id);

    let content: React.ReactNode = null;
    if (question.type === "TEXT") {
      const textAnswer = answer ? (answer as TextAnswer).value || "回答なし" : "";
      content = <Typography variant="body1">{textAnswer}</Typography>;
    } else if (question.type === "CHECK_BOX") {
      const selectedOptionIds = answer ? (answer as CheckBoxAnswer).selectedOptionIds : [];
      content = (
        <CheckboxQuestionContent question={question} selectedOptionIds={selectedOptionIds} />
      );
    } else if (question.type === "FILE") {
      const fileAnswer = answer
        ? (answer as FileAnswer & {
            file?: File;
          })
        : undefined;

      if (fileAnswer?.file) {
        content = (
          <FileQuestionContent
            file={fileAnswer?.file}
            signedUrl={sampleFileSignedUrlMapObj[fileAnswer?.filePath ?? ""]}
          />
        );
      }
    } else if (question.type === "RADIO") {
      const selectedOptionId = answer && (answer as RadioAnswer).selectedOptionId;
      content = <RadioQuestionContent question={question} selectedOptionId={selectedOptionId} />;
    }

    return (
      <Box mb="40px" key={question.id} display="flex" flexDirection="column" gridRowGap="16px">
        <Typography variant="body2" bold>
          {question.title}
        </Typography>
        {content}
      </Box>
    );
  });
};
