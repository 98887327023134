import { Box } from "@material-ui/core";
import { TaskOptionTypes, TaskOptionTypesKeys } from "@onn/common";
import React, { memo } from "react";

import styled from "styled-components";

import { QuestionFormPaper } from "../parts/QuestionFormPaper";

import { BaseProps } from "./types";

import { FilePickerButton } from "~/components/domains/onnTasks";
import { Typography } from "~/components/uiParts";
import { Payload } from "~/components/uiParts/FilePicker/FilePicker";

type Props = BaseProps & {
  selectedOptionType: TaskOptionTypesKeys;
  onChangeOptionType: (e: React.ChangeEvent<{ value: keyof typeof TaskOptionTypes }>) => void;
  onChangeFile: (payload: Payload) => void;
  sampleFileName?: string;
};

export const FileQuestionFormUIMemo = memo<Props>(
  ({
    isRequired,
    onChangeTitle,
    onChangeOptionType,
    onChangeIsRequired,
    onClickDelete,
    onClickDuplication,
    onChangeFile,
    sampleFileName,
    disabledTitle,
    disabledOptionType,
    questionDragHandleProps,
    titleTextField,
  }) => (
    <QuestionFormPaper
      selectedOptionType={"FILE"}
      isRequired={isRequired}
      onChangeTitle={onChangeTitle}
      onChangeOptionType={onChangeOptionType}
      onChangeIsRequired={onChangeIsRequired}
      onClickDelete={onClickDelete}
      onClickDuplication={onClickDuplication}
      disabledTitle={disabledTitle}
      disabledOptionType={disabledOptionType}
      questionDragHandleProps={questionDragHandleProps}
      titleTextField={titleTextField}
    >
      <Box mb="32px" paddingX={5}>
        <Box mb="16px">
          <Box mb="8px">
            <Typography variant="caption" color="textPrimary" bold>
              テンプレートファイルのアップロード（任意）
            </Typography>
          </Box>
          {/* TODO: heightを32pxにする */}
          <StyledTypography variant="caption" color="textSecondary">
            ※回答者が記入するファイルのテンプレートとなるファイルを1つ設定することができます。
            <br />
            ※回答者は合計1GBまでのファイルを1つアップロードすることができます
          </StyledTypography>
        </Box>
        <Box
          mb="32px"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FilePickerButton onChange={onChangeFile} />
          <Box ml="16px">
            <Typography variant="caption" color="textSecondary">
              {sampleFileName || "ファイルが選択されていません"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </QuestionFormPaper>
  )
);

const StyledTypography = styled(Typography)``;
