import { EmployeeTag } from "@onn/common";
import { useCallback, useState } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * 新規でタグを作成する関数を提供するhooks
 */
export const useCreateEmployeeTag = (): {
  isLoading: boolean;
  createEmployeeTag: (tenantId: string, tag: EmployeeTag) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * 新規でタグを作成する関数
   */
  const createEmployeeTag = useCallback(
    async (_tenantId: string, tag: EmployeeTag) => {
      setIsLoading(true);
      await apiClient
        .post("/tag_api/employee-tags", {
          name: tag.name,
        })
        .then(() => {
          enqueueSnackbar("タグを追加しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useCreateEmployeeTag:createEmployeeTag",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar]
  );

  return {
    isLoading,
    createEmployeeTag,
  };
};
