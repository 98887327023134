import {
  ChangeRecruitmentStatusActionSetting,
  DeliverOnnEventActionSetting,
  DeliverOnnTaskActionSetting,
  SendContactMessageActionSetting,
} from "@onn/common";
import { useCallback } from "react";

import { ValidInputState } from "../inputStateSchema";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const useConvertInputToNewActionSetting = () => {
  const { currentSpace } = useCurrentSpace();

  const convertInputToNewActionSetting = useCallback(
    ({
      actionSettingInput,
      actionId,
    }: {
      actionSettingInput: ValidInputState["actionSetting"];
      actionId: string;
    }) => {
      switch (actionSettingInput.actionType) {
        case "changeRecruitmentStatus": {
          return ChangeRecruitmentStatusActionSetting.create({
            tenantId: currentSpace.tenantId,
            spaceId: currentSpace.id,
            recruitmentStatusId: actionSettingInput.recruitmentStatusId,
            actionId,
          });
        }
        case "deliveryOnnEvent": {
          return DeliverOnnEventActionSetting.create({
            tenantId: currentSpace.tenantId,
            spaceId: currentSpace.id,
            onnEventId: actionSettingInput.onnEventId,
            actionId,
          });
        }
        case "deliveryOnnTask": {
          return DeliverOnnTaskActionSetting.create({
            tenantId: currentSpace.tenantId,
            spaceId: currentSpace.id,
            onnTaskId: actionSettingInput.onnTaskId,
            actionId,
          });
        }
        case "sendContactMessage": {
          return SendContactMessageActionSetting.create({
            tenantId: currentSpace.tenantId,
            spaceId: currentSpace.id,
            actionId,
            text: actionSettingInput.text,
            filePaths: [],
          });
        }
        default: {
          const _: never = actionSettingInput;
          return _;
        }
      }
    },
    [currentSpace.id, currentSpace.tenantId]
  );

  return { convertInputToNewActionSetting };
};
