import { EmployeeTag } from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { apiClient } from "~/libs";

const getKey = (employeeTagId: string) => {
  return { endpoint: "/tag_api/employee-tags/one", employeeTagId } as const;
};

export const useEmployeeTag = (employeeTagId: string) => {
  return useSWR(getKey(employeeTagId), async ({ endpoint, employeeTagId }) => {
    const response = await apiClient.get(endpoint, { employeeTagId });

    return { data: new EmployeeTag(response.data.employeeTag), meta: response.meta };
  });
};

export const useMutateEmployeeTag = () => {
  const { mutate } = useSWRConfig();

  const mutateEmployeeTag = useCallback(
    async (employeeTagId: string) => {
      mutate(getKey(employeeTagId));
    },
    [mutate]
  );

  return { mutateEmployeeTag };
};
