import { Box } from "@material-ui/core";
import { ICopyToClipboardButtonPart } from "@onn/common";
import React, { FC } from "react";

import { Button, Typography } from "~/components/uiParts";
import { useClipboard } from "~/hooks/shared";

type Props = {
  content: ICopyToClipboardButtonPart;
};
export const CopyToClipboardButtonPart: FC<Props> = ({ content }) => {
  const { handleCopy } = useClipboard();

  return (
    <Box display="flex" justifyContent="center">
      <Box width="90%" height="40px">
        <Button
          fullWidth
          fullHeight
          borderRadius="circle"
          variant={content.isPrimary ? "contained" : "text"}
          color="primary"
          onClick={() => handleCopy(content.textToCopy, content.buttonText)}
        >
          <Typography variant="body2">{content.buttonText}</Typography>
        </Button>
      </Box>
    </Box>
  );
};
