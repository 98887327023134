import { v4 } from "uuid";

import { RichMenuCellSetting } from "../RichMenuCellSetting/RichMenuCellSetting";

import { ILinePlatformRichMenu, linePlatformRichMenuSchema } from "./schema";

/**
 * LinePlatformリッチメニュー
 *
 * - LINEプラットフォーム上で管理しているリッチメニュー
 */
export class LinePlatformRichMenu extends RichMenuCellSetting implements ILinePlatformRichMenu {
  static readonly validator = linePlatformRichMenuSchema;
  readonly id: string;
  readonly tenantId: string;
  readonly externalRichMenuId: string;
  readonly imagePath: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(data: ExcludeMethods<LinePlatformRichMenu>) {
    const parsedInit = LinePlatformRichMenu.validator.parse(data);
    super(parsedInit);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.externalRichMenuId = parsedInit.externalRichMenuId;
    this.imagePath = parsedInit.imagePath;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(
    init: Omit<ExcludeMethods<LinePlatformRichMenu>, "id" | "createdAt" | "updatedAt" | "deleted">
  ): LinePlatformRichMenu {
    return new LinePlatformRichMenu({
      ...init,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public isSameSetting(
    comparison: ExcludeMethods<RichMenuCellSetting> & Pick<LinePlatformRichMenu, "imagePath">
  ): boolean {
    return (
      this.imagePath === comparison.imagePath &&
      this.cell1Type === comparison.cell1Type &&
      this.cell1ExternalSiteUrl === comparison.cell1ExternalSiteUrl &&
      this.cell1MessageText === comparison.cell1MessageText &&
      this.cell2Type === comparison.cell2Type &&
      this.cell2ExternalSiteUrl === comparison.cell2ExternalSiteUrl &&
      this.cell2MessageText === comparison.cell2MessageText &&
      this.cell3Type === comparison.cell3Type &&
      this.cell3ExternalSiteUrl === comparison.cell3ExternalSiteUrl &&
      this.cell3MessageText === comparison.cell3MessageText &&
      this.cell4Type === comparison.cell4Type &&
      this.cell4ExternalSiteUrl === comparison.cell4ExternalSiteUrl &&
      this.cell4MessageText === comparison.cell4MessageText &&
      this.cell5Type === comparison.cell5Type &&
      this.cell5ExternalSiteUrl === comparison.cell5ExternalSiteUrl &&
      this.cell5MessageText === comparison.cell5MessageText &&
      this.cell6Type === comparison.cell6Type &&
      this.cell6ExternalSiteUrl === comparison.cell6ExternalSiteUrl &&
      this.cell6MessageText === comparison.cell6MessageText
    );
  }
}
