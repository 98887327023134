import { generateOnnEventPlaceFilePath } from "@onn/common";
import { useCallback } from "react";

import { v4 } from "uuid";

import { useCurrentUser } from "~/hooks/employee";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const useUploadRemarkFilesToStorage = () => {
  const { currentUser } = useCurrentUser();
  const uploadRemarkFilesToStorage = useCallback(
    async (files: File[]) => {
      const uploadParams = files.map((file) => ({
        file,
        path: generateOnnEventPlaceFilePath({
          tenantId: currentUser.tenantId,
          fileName: file.name.normalize("NFC"),
          pathUid: v4(),
        }),
      }));
      await fileAPIAdapter.uploadFiles(uploadParams);

      return uploadParams;
    },
    [currentUser.tenantId]
  );

  return { uploadRemarkFilesToStorage };
};
