import { z } from "zod";

import { candidateDateSchema } from "../CandidateDate";

export const candidateDateWithNumberOfParticipantsSchema = candidateDateSchema.sourceType().extend({
  numberOfParticipants: z.number().int().min(0),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICandidateDateWithNumberOfParticipants
  extends z.infer<typeof candidateDateWithNumberOfParticipantsSchema> {}
