import { Box } from "@material-ui/core";
import { FormRevision, OnnFormTask, OnnFormTaskAnswer } from "@onn/common";
import {
  CheckBoxAnswer,
  FileAnswer,
  RadioAnswer,
  TextAnswer,
} from "@onn/common/domain/OnnTask/Answer/Answer";
import { first, isEmpty } from "lodash";
import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

import { ContainerPaper } from "../_share/ContainerPaper";

import { OnnTaskDescriptionTypography } from "../_share/TaskDesctiptionTypograpy";
import { TitleAndDeadlineDate } from "../_share/TitleAndDeadlineDate";

import { CheckboxQuestionContent } from "./CheckboxQuestionContent";
import { FileQuestionContent } from "./FileQuestionContent";

import { RadioQuestionContent } from "./RadioQuestionContent";

import { Button, Divider, Loading, Typography } from "~/components/uiParts";

import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";
import { useFormRevisions, useOnnTask } from "~/hooks/onnTask";
import { useEffectForCreationActiveLog } from "~/hooks/shared/useEffectForCreationActiveLog";
import { NotFound } from "~/pages/NotFound";
import { mixin } from "~/util";

export const OnnTasksLanding: FC = () => {
  const { id: onnTaskId } = useParams<"id">();

  const { data: onnTask, isLoading: isLoadingOnnTask } = useOnnTask({
    onnTaskId,
  });

  const { data: onnFormTaskAnswers, isLoading: isLoadingOnnFormTaskAnswers } =
    useOnnFormTasksAnswersOnlyCountable({ onnTaskId: onnTask?.id });

  const { data: formRevisionsData, isLoading: isLoadingFRs } = useFormRevisions({
    onnFormTaskId: onnTaskId,
    isOnlyLatest: true,
  });

  useEffectForCreationActiveLog({
    type: "VISITED_ONN_TASK_LANDING_PAGE",
    targetId: onnTaskId as string, // 必ず存在するため型キャストしている
  });

  if (isLoadingOnnTask || isLoadingOnnFormTaskAnswers || isLoadingFRs)
    return <Loading size="large" fullHeight />;
  if (!onnTask || !onnFormTaskAnswers || !formRevisionsData) {
    return <NotFound />;
  }

  const formRevision = first(formRevisionsData.formRevisions);
  const onnFormTaskAnswer = first(onnFormTaskAnswers);

  if (!formRevision || !onnFormTaskAnswer) {
    return <NotFound />;
  }

  if (onnTask.type === "FORM_TASK") {
    return (
      <OnnTasksLandingCore
        onnFormTask={onnTask}
        formRevision={formRevision}
        onnFormTaskAnswer={onnFormTaskAnswer}
        sampleFileSignedUrlMapObj={formRevisionsData.meta?.sampleFileSignedUrlMapObj}
      />
    );
  } else {
    return <>TODOタスク: 未実装</>;
  }
};

const OnnTasksLandingCore: FC<{
  onnFormTask: OnnFormTask;
  formRevision: FormRevision;
  onnFormTaskAnswer: OnnFormTaskAnswer;
  sampleFileSignedUrlMapObj?: Record<string, string>;
}> = ({ onnFormTask, formRevision, onnFormTaskAnswer, sampleFileSignedUrlMapObj }) => {
  const navigate = useNavigate();

  const isExistAnswer = !isEmpty(onnFormTaskAnswer.answers);

  const onClick = () => {
    navigate(`/portal/onn_tasks/${onnFormTask.id}/answer`);
  };

  const canAnswer = isExistAnswer ? onnFormTask.canEditAnswer() : onnFormTask.canAnswer();

  return (
    <ContainerPaper>
      <TitleAndDeadlineDate title={onnFormTask.title} deadlineDate={onnFormTask.deadlineDate} />
      <StyledCard mb={5}>
        <Box mb={5}>
          <OnnTaskDescriptionTypography description={onnFormTask.description} />
        </Box>
        {/* 回答がすでに存在する場合は、回答状況を表示 */}
        {isExistAnswer && (
          <>
            <Divider />
            <Box mt="40px">
              <Box mb="40px">
                <Typography variant="body1" bold>
                  回答
                </Typography>
              </Box>
              <AnswerResultArea
                formRevision={formRevision}
                onnFormTaskAnswer={onnFormTaskAnswer}
                sampleFileSignedUrlMapObj={sampleFileSignedUrlMapObj ?? {}}
              />
            </Box>
          </>
        )}
        {canAnswer && (
          <Button
            fullWidth
            variant={isExistAnswer ? "outlined" : "contained"}
            color={isExistAnswer ? "default" : "primary"}
            borderRadius="circle"
            onClick={onClick}
          >
            {isExistAnswer ? "回答を編集" : "回答を始める"}
          </Button>
        )}
      </StyledCard>
    </ContainerPaper>
  );
};

const AnswerResultArea: FC<{
  formRevision: FormRevision;
  onnFormTaskAnswer: OnnFormTaskAnswer;
  sampleFileSignedUrlMapObj: Record<string, string>;
}> = ({ formRevision, onnFormTaskAnswer, sampleFileSignedUrlMapObj }) => {
  return formRevision.questions.map((question) => {
    const answer = onnFormTaskAnswer.answers.find((a) => a.questionId === question.id);

    let content: React.ReactNode = null;
    if (question.type === "TEXT") {
      const textAnswer = answer ? (answer as TextAnswer).value || "回答なし" : "";
      content = <Typography variant="body1">{textAnswer}</Typography>;
    } else if (question.type === "CHECK_BOX") {
      const selectedOptionIds = answer ? (answer as CheckBoxAnswer).selectedOptionIds : [];
      content = (
        <CheckboxQuestionContent question={question} selectedOptionIds={selectedOptionIds} />
      );
    } else if (question.type === "FILE") {
      const fileAnswer = answer ? (answer as FileAnswer) : undefined;
      content = (
        <FileQuestionContent
          filePath={fileAnswer?.filePath}
          signedUrl={sampleFileSignedUrlMapObj[fileAnswer?.filePath ?? ""]}
        />
      );
    } else if (question.type === "RADIO") {
      const selectedOptionId = answer && (answer as RadioAnswer).selectedOptionId;
      content = <RadioQuestionContent question={question} selectedOptionId={selectedOptionId} />;
    }

    return (
      <Box mb="40px" key={question.id} display="flex" flexDirection="column" gridRowGap="16px">
        <Typography variant="body2" bold>
          {question.title}
        </Typography>
        {content}
      </Box>
    );
  });
};

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 28px;
  padding: 40px;

  ${mixin.breakDown.sm`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 40px;
    padding: 0px;
    width: 100%;
  `}
`;
