import { Box } from "@material-ui/core";
import { TenantSettings } from "@onn/common";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { SettingsWrapper } from "../SettingsWrapper";

import {
  ImageUploadArea,
  Paper,
  TextFieldDeprecated,
  TextareaAutosize,
  Typography,
  UserIconWithLabel,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useFileUrl } from "~/hooks/file";
import { useSnackbar } from "~/hooks/shared";
import { usePrompt } from "~/hooks/shared/usePrompt";
import { getNameAndExtensionFromFilePath } from "~/util/getNameAndExtensionFromFilePath";

type ContentFormType = {
  iconPath?: string;
  fullName: string;
  content: string;
};
type Props = {
  invitationMessage?: TenantSettings["invitationMessage"];
  editing: boolean;
  updating: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: (invitationMessage: ContentFormType, inputIcon?: File) => void;
};

export const InvitationMessageSettings: FC<Props> = ({
  invitationMessage,
  editing,
  onSubmit,
  ...rest
}) => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { data: currentIcon } = useFileUrl(invitationMessage?.iconPath);
  const [isChanged, setIsChanged] = useState(false);
  usePrompt("編集内容を破棄しますか？", isChanged);

  const [inputIcon, setInputIcon] = useState<File>();
  const [inputIconPath, setInputIconPath] = useState("");
  const [newInvitationMessage, setNewInvitationMessage] = useState<ContentFormType>(
    invitationMessage
      ? {
          iconPath: invitationMessage.iconPath,
          fullName: invitationMessage.fullName,
          content: invitationMessage.content,
        }
      : { iconPath: "", fullName: "", content: "" }
  );

  const handleErrorUploadImage = useCallback(() => {
    enqueueSnackbar("アイコンは120px*120pxから2100px*2100pxの間で設定してください", {
      variant: "error",
    });
  }, [enqueueSnackbar]);

  const handleChangeInvitationMessage = useCallback(
    (newObject: Partial<ContentFormType>) => {
      if (!isChanged) setIsChanged(true);

      setNewInvitationMessage((prev) => ({ ...prev, ...newObject }));
    },
    [isChanged]
  );

  const handleSubmit = useCallback(() => {
    setIsChanged(false);
    onSubmit(newInvitationMessage, inputIcon);
  }, [inputIcon, newInvitationMessage, onSubmit]);

  const handleUploadImage = useCallback(
    async (file: File) => {
      setInputIcon(file);
      if (!isChanged) setIsChanged(true);

      const reader = new FileReader();
      reader.onload = () => {
        // readAsDataURLはstringしか返さないが、resultの型にArrayBufferが含まれるので型チェックする
        if (typeof reader.result === "string") {
          const { name, extension } = getNameAndExtensionFromFilePath(file.name);
          setInputIconPath(reader.result);
          setNewInvitationMessage((prev) => ({
            ...prev,
            iconPath: `tenants/${currentUser.tenantId}/invitation_message/${name}.${extension}`,
          }));
        }
      };
      reader.readAsDataURL(file);
    },
    [currentUser.tenantId, isChanged]
  );

  return (
    <SettingsWrapper
      {...rest}
      editing={editing}
      isSubmitDisabled={
        newInvitationMessage.fullName.trim() === "" || newInvitationMessage.content.trim() === ""
      }
      onSubmit={handleSubmit}
      mode="content"
    >
      {editing ? (
        <>
          <Typography variant="body2">招待者(固定表示)</Typography>
          <StyledTypography variant="caption">
            {`招待者として表示されるアイコン画像と氏名を設定してください。\n職種を名前の後につけることを推奨しています。例：田中 太郎(人事)`}
          </StyledTypography>
          <Box mt="12px" mb="32px" display="flex" alignContent="center" gridGap="8px">
            <ImageUploadArea
              alt="アイコン"
              defaultImage={currentIcon}
              imagePath={inputIconPath}
              allowImageSize={{
                max: { width: 2100, height: 2100 },
                min: { width: 120, height: 120 },
              }}
              onChange={handleUploadImage}
              onError={handleErrorUploadImage}
              size="small"
            />
            <StyledTextField
              variant="outlined"
              placeholder="氏名を設定してください"
              fullWidth
              value={newInvitationMessage.fullName}
              onChange={(e) => handleChangeInvitationMessage({ fullName: e.target.value })}
            />
          </Box>
          <Typography variant="body2">招待メッセージ</Typography>
          <StyledTypography variant="caption">
            入社者全員に同じ文言が表示されるので、新しいメンバーに向けてどんな目的で招待しているのかを伝えましょう！
          </StyledTypography>
          <Box mt="16px" mb="8px">
            <TextareaAutosize
              name="content"
              value={newInvitationMessage.content}
              fullWidth
              placeholder="新しいメンバーが招待メールで最初に目にするご案内です。"
              onChange={(e) => handleChangeInvitationMessage({ content: e.target.value })}
              minRows={4}
              maxTextCount={2000}
              error={newInvitationMessage.content.length > 2000}
            />
          </Box>
        </>
      ) : (
        <>
          {invitationMessage && (
            <>
              <Typography variant="body2">メールに添付されるメッセージプレビュー</Typography>
              <Box mt="12px">
                <StyledPaper border="primary">
                  {invitationMessage.iconPath ? (
                    <UserIconWithLabel
                      name={invitationMessage.fullName}
                      iconPath={invitationMessage.iconPath}
                    />
                  ) : (
                    <Typography variant="body1" bold noWrap>
                      {invitationMessage.fullName}
                    </Typography>
                  )}
                  <Box height="16px" />
                  <Typography color="textPrimary">{invitationMessage.content}</Typography>
                </StyledPaper>
              </Box>
            </>
          )}
        </>
      )}
    </SettingsWrapper>
  );
};

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-root {
    height: 48px;
  }
`;

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    padding: 24px;
  }
`;
