import { useEffect, useMemo, useRef } from "react";
import { GridCellRenderer, Index, MultiGrid } from "react-virtualized";

import { BodyCell } from "./Body/BodyCell";
import { HeaderCell } from "./Header/HeaderCell";
import {
  BodyRowData,
  columnTypes,
  HEADER_CELL_HEIGHT_PX,
  BODY_CELL_HEIGHT_PX,
  widthPxMap,
} from "./const";

/**
 * BodyRowDataを元に、行の高さを計算する
 */
const generateBodyRowHeight = (bodyRowData: BodyRowData) => {
  const fileCount = bodyRowData.relatedFiles.length;
  if (fileCount < 3) return BODY_CELL_HEIGHT_PX;
  const fileNameHeight = 24;
  const fileNameMarginTopTotal = 4 * (fileCount - 1);
  const paddingY = 20;
  return fileCount * fileNameHeight + paddingY + fileNameMarginTopTotal;
};

export const useTableView = ({
  bodyRowDataArray,
  onCancel,
}: {
  bodyRowDataArray: BodyRowData[];
  onCancel: () => void;
}) => {
  const isNotEmpty = useMemo(() => !!bodyRowDataArray.length, [bodyRowDataArray]);

  const headerRowCount = 1;
  const rowCount = headerRowCount + bodyRowDataArray.length;
  const columnCount = columnTypes.length;

  // 各行の高さを格納した配列
  const rowHeightList = useMemo(
    () => [
      HEADER_CELL_HEIGHT_PX,
      ...bodyRowDataArray.map((bodyRowData) => generateBodyRowHeight(bodyRowData)),
    ],
    [bodyRowDataArray]
  );

  // MultiGridが各行の高さを算出するための関数
  const rowHeight = (params: Index) => {
    return rowHeightList[params.index] || BODY_CELL_HEIGHT_PX;
  };

  // MultiGridが各列の幅を算出するための関数
  const columnWidth = (params: Index) => {
    const columnType = columnTypes[params.index];
    if (columnType) return widthPxMap[columnType];

    return widthPxMap.title;
  };

  // MultiGridの高さ
  const multiGridHight = useMemo(() => {
    const height = rowHeightList.reduce((acc, cur) => acc + cur, 0);
    return height > 300 ? 300 : height;
  }, [rowHeightList]);

  const cellRenderer: GridCellRenderer = ({ style, rowIndex, columnIndex }) => {
    const columnType = columnTypes[columnIndex];
    if (!columnType) return;

    const isLastColumn = columnIndex === columnTypes.length - 1;

    if (rowIndex === 0) {
      return HeaderCell({
        style,
        columnType,
        isLastColumn,
      });
    }

    const bodyRowData = bodyRowDataArray[rowIndex - 1];
    if (!bodyRowData) return;
    return BodyCell({
      bodyRowData,
      style,
      columnType,
      isLastColumn,
      onCancel,
    });
  };

  /**
   * 関連ファイルの数によってセルの高さを決めている。
   * そのため関連ファイルの数が変わったときにグリッドの再計算する
   */
  const multiGridRef = useRef<MultiGrid>(null);
  const totalFileCount = bodyRowDataArray.reduce((acc, cur) => acc + cur.relatedFiles.length, 0);
  useEffect(() => {
    if (multiGridRef.current) {
      multiGridRef.current.recomputeGridSize();
    }
  }, [totalFileCount]);

  return {
    cellRenderer,
    rowCount,
    columnCount,
    rowHeight,
    columnWidth,
    multiGridHight,
    isNotEmpty,
    multiGridRef,
  };
};
