import { v4 } from "uuid";

import {
  IEmployeeInformationMultipleSelectTypeValue,
  employeeInformationMultipleSelectTypeValueSchema,
} from "./schema";

export class EmployeeInformationMultipleSelectTypeValue
  implements IEmployeeInformationMultipleSelectTypeValue
{
  static readonly type = "MULTIPLE_SELECT" as const;
  type = EmployeeInformationMultipleSelectTypeValue.type;
  static validator = employeeInformationMultipleSelectTypeValueSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeId: string;
  readonly employeeInformationMultipleSelectTypeFieldId: string;
  readonly optionId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<EmployeeInformationMultipleSelectTypeValue>, "type">) {
    EmployeeInformationMultipleSelectTypeValue.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeId = init.employeeId;
    this.employeeInformationMultipleSelectTypeFieldId =
      init.employeeInformationMultipleSelectTypeFieldId;
    this.optionId = init.optionId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationMultipleSelectTypeValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): EmployeeInformationMultipleSelectTypeValue {
    return new EmployeeInformationMultipleSelectTypeValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(
    params: Pick<EmployeeInformationMultipleSelectTypeValue, "optionId">
  ): EmployeeInformationMultipleSelectTypeValue {
    return new EmployeeInformationMultipleSelectTypeValue({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
