import { useCallback } from "react";

import { useModal } from "~/hooks/modal";
import { useDeleteOnnEventSlotsDate } from "~/hooks/onnEventSlotDates/useDeleteOnnEventSlotsDate";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

export const useSlotBulkDeleteConfirmModal = ({
  onnEventId,
  onnEventSlotIds,
  onResetSelectedSlotIds,
}: {
  onnEventId: string;
  onnEventSlotIds: string[];
  onResetSelectedSlotIds: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleModal } = useModal();

  const { deleteOnnEventSlots } = useDeleteOnnEventSlotsDate();

  const onClickAccept = useCallback(async () => {
    try {
      const result = await deleteOnnEventSlots({
        onnEventId,
        onnEventSlotIds,
      });
      onResetSelectedSlotIds();
      // NOTE: 一括削除の場合、一部が成功、一部が失敗する可能性もあるので、レスポンスないようによってスナックバーの表示を変える
      if (result.successfullyDeletedSlotIds.length > 0) {
        enqueueSnackbar(
          `${result.successfullyDeletedSlotIds.length}件が削除されました。${
            result.notifiedEmployeeIds.length > 0 ? "予約取り消しが候補者に通知されました。" : ""
          } `,
          {
            variant: "success",
          }
        );
      }
      if (result.failedToDeleteSlotIds.length > 0) {
        enqueueSnackbar(`${result.failedToDeleteSlotIds.length}件の削除に失敗しました`, {
          variant: "error",
        });
        captureException({
          error: new Error("予約枠の一括削除に失敗しました"),
          tags: {
            type: "useSlotBulkDeleteConfirmModal",
          },
          extras: {
            result,
          },
        });
      }
    } catch {
      enqueueSnackbar("予約枠の削除に失敗しました", { variant: "error" });
    }
  }, [deleteOnnEventSlots, enqueueSnackbar, onResetSelectedSlotIds, onnEventId, onnEventSlotIds]);

  const openSlotBulkDeleteConfirmModal = useCallback(() => {
    handleModal({
      name: "confirmModal",
      args: {
        title: "予約枠一括削除",
        onClickAccept,
        mainContent: `${onnEventSlotIds.length}件の予約枠を削除しますか？\n予約がある場合、予約は全て取り消され候補者へ通知が送られます。`,
        subContent: `この操作は取り消すことができません`,
        acceptButtonColor: "secondary",
        acceptLabel: "削除",
      },
    });
  }, [handleModal, onClickAccept, onnEventSlotIds.length]);

  return { openSlotBulkDeleteConfirmModal };
};
