import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { mutateOnnEventSlotDatesForDisplay } from "./useOnnEventSlotDatesForDisplay";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-slot-dates"]["DELETE"];
export const useDeleteOnnEventSlotsDate = () => {
  const deleteOnnEventSlots = useCallback(async (arg: Endpoint["body"]) => {
    const requestBody: Endpoint["body"] = {
      onnEventId: arg.onnEventId,
      onnEventSlotIds: arg.onnEventSlotIds,
    };

    const response = await apiClient.delete("/api/onn-event-slot-dates", {}, requestBody, {
      isNotifyError: true,
    });
    mutateOnnEventSlotDatesForDisplay(arg.onnEventId);

    return response;
  }, []);

  return { deleteOnnEventSlots };
};
