import { Box } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import styled from "styled-components";

import { Paper, Typography } from "~/components/uiParts";
import { captureException } from "~/util";

export const FailedToChangeAuthenticationPage: FC = () => {
  useEffect(() => {
    captureException({
      error: new Error("ログイン方法切り替え時に失敗し、想定外の画面が表示されています。"),
      tags: {
        type: "FailedToChangeAuthenticationPage",
      },
      extras: {
        windowLocation: window.location.href,
      },
    });
  }, []);
  return (
    <Box maxWidth="100%" display="flex" justifyContent="center">
      <Box>
        <Box mx="24px" my="40px">
          <Typography variant="h2" bold>
            ログイン方法の変更が完了できませんでした
          </Typography>
        </Box>
        <Box mx="24px">
          <StyledPaper>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography variant="body1">
                お手数ですが、担当者までお問い合わせください。
              </Typography>
            </Box>
          </StyledPaper>
        </Box>
      </Box>
    </Box>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px;
`;
