import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useGoBackButtonHandler = (onnEventId: string) => {
  const navigate = useNavigate();

  const onClickGoBack = useCallback(() => {
    navigate(`/events/${onnEventId}/edit?from_page=delivery_setting`);
  }, [navigate, onnEventId]);

  return { onClickGoBack };
};
