import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  children: React.ReactNode;
  questionTitle: string;
  isRequired?: boolean;
};

export const AnswerBaseBox: FC<Props> = ({ children, questionTitle, isRequired }) => {
  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="body2" bold appendRequiredSymbol={isRequired}>
        {`${questionTitle} `}
      </Typography>
      {children}
    </Box>
  );
};
