import { ContactRoomPin } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const endPointPath = "/contact_room_api/contact-room-pins";

export const generateContactRoomPinKey = (employeeId?: string) => {
  if (!employeeId) return null;
  return [endPointPath, employeeId] as const;
};

const fetcher = async ([endPoint]: [typeof endPointPath]) => {
  const response = await apiClient.get(endPoint);
  return response.data.map((d) => new ContactRoomPin(d));
};
export const useContactRoomPins = () => {
  const { currentUser } = useCurrentUser();
  return useSWR(generateContactRoomPinKey(currentUser.id), fetcher);
};

export const useMutateContactRoomPins = () => {
  const { currentUser } = useCurrentUser();
  const mutateContactRoomPins = useCallback(async () => {
    const key = generateContactRoomPinKey(currentUser.id);
    await mutate(key);
  }, [currentUser.id]);

  return { mutateContactRoomPins };
};
