import { SnackbarProvider as SnackbarProviderOrigin } from "notistack";
import React, { FC, forwardRef } from "react";

import { SnackbarForOnWebsiteNotice } from "../uiParts/Snackbar/SnackbarForOnWebsiteNotice";
import { SnackbarForRevision } from "../uiParts/Snackbar/SnackbarForRevision";

import { Snackbar } from "~/components/uiParts";

type Props = {
  children: React.ReactNode;
};

export type Message = { text: string } & (
  | {
      variant?: never;
      onAction: () => void;
      actionLabel: string;
      type: "revision";
    }
  | {
      variant: "success" | "error" | "info";
      onAction?: never;
      actionLabel?: never;
      type: undefined;
    }
  | {
      variant: "success" | "error" | "info";
      onAction: () => void;
      actionLabel: string;
      type: "onWebsiteNotice";
    }
);

export const SnackbarProvider: FC<Props> = ({ children }) => {
  return (
    <SnackbarProviderOrigin
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      /** @TODO: mui のバージョンアップ後に notistack のバージョンアップをして ignore を外す. mui の依存関係があるため */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      content={(key, message: Message) => {
        return <SnackBarWrapper id={key} message={message} />;
      }}
    >
      {children}
    </SnackbarProviderOrigin>
  );
};

const SnackBarWrapper = forwardRef<
  // textは共通して必須
  // ActionSnackbarの場合、onActionとactionLabelのみ必須
  // Snackbarの場合、variantのみ必須
  HTMLDivElement,
  {
    id: string | number;
    message: Message;
  }
>((props, ref) => {
  let component: React.ReactNode = null;
  switch (props.message.type) {
    case "onWebsiteNotice":
      component = (
        <SnackbarForOnWebsiteNotice
          text={props.message.text}
          color={props.message.variant}
          onAction={props.message.onAction}
          actionLabel={props.message.actionLabel}
        />
      );
      break;
    case "revision":
      component = (
        <SnackbarForRevision
          text={props.message.text}
          actionLabel={props.message.actionLabel}
          onAction={props.message.onAction}
        />
      );
      break;
    default:
      component = <Snackbar text={props.message.text} color={props.message.variant} />;
      break;
  }
  return <div ref={ref}>{component}</div>;
});
