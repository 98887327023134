import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";

import { LogicType } from "../../../types/logic-type";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = { logicType: LogicType; onChange(logicType: LogicType): void };
export const SelectLogicTypeButton = ({ logicType, onChange }: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Typography variant="body2" noWrap>
          {logicType}
        </Typography>
      </Button>
      <Menu
        key={"selectLogicTypeButton"}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {(["AND", "OR"] as const).map((type) => (
          <Box key={type}>
            <MenuItem key={type} onClick={() => onChange(type)}>
              <Typography variant="body2">{type}</Typography>
            </MenuItem>
          </Box>
        ))}
      </Menu>
    </>
  );
};
