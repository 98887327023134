import { v4 } from "uuid";

import { IContactRoomPinSchema, contactRoomPinSchema } from "./schema";

export class ContactRoomPin implements IContactRoomPinSchema {
  static validator = contactRoomPinSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeId: string;
  readonly contactRoomId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<ContactRoomPin>) {
    const parsedInit = ContactRoomPin.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.employeeId = parsedInit.employeeId;
    this.contactRoomId = parsedInit.contactRoomId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static create(
    params: Optional<ExcludeMethods<ContactRoomPin>, "id" | "createdAt" | "updatedAt">
  ): ContactRoomPin {
    return new ContactRoomPin({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
    });
  }
}
