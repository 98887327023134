import useSWR from "swr";

import { apiClient } from "~/libs";

export const useTenantLineLoginChannelInfo = (tenantId?: string) => {
  return useSWR(tenantId ? generateKeyObj(tenantId) : null, async ({ endpoint, tenantId }) => {
    const response = await apiClient.get(endpoint, {
      "tenant-id": tenantId,
    });
    return response;
  });
};

const generateKeyObj = (tenantId: string) => {
  return {
    endpoint: `/get_tenant_line_login_channel_info`,
    tenantId,
  } as const;
};

export type LienLoginChannelInfo = NonNullable<
  ReturnType<typeof useTenantLineLoginChannelInfo>["data"]
>;
