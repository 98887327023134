import { CsvInputValueKeyToInfoType } from "./_share";

export type AnswerOnBehalfToExistedSlotDateInfo = {
  onnUniqueId: string;
  slotDateUniqueId: string;
};

export const answerOnBehalfToExistedSlotDateInfo: CsvInputValueKeyToInfoType<
  Required<AnswerOnBehalfToExistedSlotDateInfo>
> = {
  onnUniqueId: { labelInCsv: "Onn固有ID", isRequired: true },
  slotDateUniqueId: { labelInCsv: "日程ID", isRequired: true },
} as const;

// サンプルCSVファイルのデータ
const generateSampleCsvBodyData = () => {
  return [
    {
      onnUniqueId: "AAA0000000001",
      slotDateUniqueId: "00000001",
    },
  ];
};

export const generateSampleCsvDataAboutAnswerOnBehalfToExistedSlotDate = () => {
  return [
    [
      ...Object.entries(answerOnBehalfToExistedSlotDateInfo).flatMap(([_, value]) => {
        return value.labelInCsv;
      }),
    ],
    ...generateSampleCsvBodyData().map((value) => [
      ...Object.entries(value).flatMap(([_, value]) => value),
    ]),
  ];
};
