import { UpdateOnnEventRequest, UpdateOnnEventResponse } from "@onn/common";
import { useState } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";

/**
 * @deprecated この仕組みはonCallの仕組みに則って実装されたものです。useUpdateOnnEventV2を使用してください。
 */
export const useUpdateOnnEvent = () => {
  const [isLoading, setIsLoading] = useState(false);

  const execUpdateOnnEvent = async ({
    onnEventId,
    updateObject,
  }: {
    onnEventId: string;
    updateObject: UpdateOnnEventRequest["updateObject"];
  }) => {
    try {
      const data = UpdateOnnEventRequest.createRequestBody({
        onnEventId,
        updateObject,
      });
      setIsLoading(true);
      const response = await functionOperator.httpsCallFor2ndGen<
        ReturnType<typeof UpdateOnnEventRequest.createRequestBody>,
        typeof UpdateOnnEventResponse.ResponseBody
      >("onnevent", data);

      return UpdateOnnEventResponse.convertUnixTimeToDate(response.data);
    } finally {
      setIsLoading(false);
    }
  };

  return { execUpdateOnnEvent, isLoading };
};
