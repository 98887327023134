import { Box, Tab } from "@material-ui/core";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Employee } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

import { NewGraduateSelectionForm } from "../NewGraduateSelectionForm";

import { ListOfNewGraduateForDeliver } from "./ListOfNewGraduateForDeliver";

import { Paper } from "~/components/uiParts";

type EmployeeID = string;

type Props = {
  isAlreadyDelivered: boolean;
  onClickAddNewGraduateToButton: () => void;
  onClickUploadCSVButton?: () => void;
  deleteButtonHandlers: {
    fromNewSelectedNewGraduates: (target: Employee) => void;
    fromRegisteredNewGraduates?: (target: Employee) => void;
    // TODO[登録済み削除機能実装時に対応]: 必須にする
    // 配信前削除に対応していない場合があるため、一時的に任意にしている。
  };
  newSelectedNewGraduates: Employee[];
  registeredNewGraduateIds: EmployeeID[];
};

export const AddEmployeeToDeliverTabsPaper = ({
  isAlreadyDelivered,
  onClickAddNewGraduateToButton,
  onClickUploadCSVButton,
  deleteButtonHandlers,
  newSelectedNewGraduates,
  registeredNewGraduateIds,
}: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState("add");

  const registeredLabel =
    (isAlreadyDelivered ? "配信済み" : "配信予約") +
    `(${registeredNewGraduateIds ? registeredNewGraduateIds.length : "-"})`;

  return (
    <Paper>
      <Box>
        <TabContext value={selectedTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, v) => setSelectedTab(v)}
          >
            {[
              {
                value: "add",
                label: `${isEmpty(registeredNewGraduateIds) ? "" : "追加"}配信対象(${
                  newSelectedNewGraduates.length
                })`,
                disabled: false,
              },
              {
                value: "registered",
                label: registeredLabel,
                disabled: isEmpty(registeredNewGraduateIds),
              },
            ].map((tab) => (
              <StyledTab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                disabled={tab.disabled}
              />
            ))}
          </StyledTabList>
          <StyledTabPanel value="add">
            <NewGraduateSelectionForm
              employees={newSelectedNewGraduates}
              openAddNewGraduateModal={onClickAddNewGraduateToButton}
              openAddNewGraduateWithCSVModal={onClickUploadCSVButton}
              onClickDeleteButton={deleteButtonHandlers.fromNewSelectedNewGraduates}
            />
          </StyledTabPanel>
          <StyledTabPanel value="registered">
            {registeredNewGraduateIds && (
              <ListOfNewGraduateForDeliver
                registeredNewGraduateIds={registeredNewGraduateIds}
                isAlreadyDelivered={isAlreadyDelivered}
                onClickDeleteButton={deleteButtonHandlers.fromRegisteredNewGraduates}
              />
            )}
          </StyledTabPanel>
        </TabContext>
      </Box>
    </Paper>
  );
};

const StyledTabList = styled(TabList)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]};
  &.MuiTabs-root {
    padding-top: 0;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 24px 0 0 0;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-textColorPrimary:not(.Mui-selected) {
    color: ${(props) => props.theme.palette.text.primary};
  }
  &.MuiTab-root {
    padding: 0px 20px;
  }
`;
