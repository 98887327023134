import { z } from "zod";

export const onnEventEvaluationRankSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  label: z.string(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationRank extends z.infer<typeof onnEventEvaluationRankSchema> {}
