import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC, useMemo } from "react";

import { Navigate, useParams } from "react-router-dom";

import styled from "styled-components";

import { NewInterviewEventFormatInfoSection } from "./components/NewInterviewEventFormatInfoSection";

import { EventTypeDisplaySwitcher } from "~/components/domains/events/EventTypeDisplaySwitcher";
import { Button, Divider, Linkify, Loading, Typography } from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useOnnEventForPortal } from "~/hooks/onnEvent";
import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventForPortal";
import { useOnnEventSlot } from "~/hooks/onnEventSlotDates/useOnnEventSlot";
import { useQuery } from "~/hooks/shared";
import { useEffectForCreationActiveLog } from "~/hooks/shared/useEffectForCreationActiveLog";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";
import { NotFound } from "~/pages/NotFound";

import { captureException, mixin } from "~/util";

export const OnnEventLanding: FC = () => {
  const { id } = useParams<"id">();
  const { currentUser } = useCurrentUser();
  const { query } = useQuery();

  const isPreview = query.get("preview") === "true";

  const {
    data: onnEventDataForPortal,
    isLoading,
    isValidating,
    error,
  } = useOnnEventForPortal({
    employeeId: currentUser.id,
    id: typeof id === "string" ? id : "",
    isPreview,
  });

  // 回答期限が切れている場合は回答結果画面を表示させずに/portal/events画面へ遷移させる
  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!onnEventDataForPortal) {
    captureException({
      error: new Error("[デバッグ用] ポータルのイベント回答ページで NotFound が表示されました"),
      tags: { type: "support_portal_event" },
      extras: { onnEventId: id, currentUser, isPreview, isLoading, error, href: location.href },
    });
    return <NotFound />;
  }

  return (
    <OnnEventLandingPage
      eventData={onnEventDataForPortal}
      isPreview={isPreview}
      isValidating={isValidating}
    />
  );
};

type OnnEventLandingPageProps = {
  eventData: OnnEventDataForPortal;
  isPreview: boolean;
  isValidating: boolean;
};

const OnnEventLandingPage: FC<OnnEventLandingPageProps> = ({
  eventData,
  isPreview,
  isValidating,
}) => {
  useEffectForCreationActiveLog({
    type: "VISITED_ONN_EVENT_FORM_PAGE",
    targetId: eventData.onnEvent.id,
  });

  return (
    <EventTypeDisplaySwitcher
      onnEvent={eventData.onnEvent}
      renderForNormal={() => (
        <OnnEventLandingPageCore
          eventData={eventData}
          isPreview={isPreview}
          isValidating={isValidating}
        />
      )}
      renderForBriefingSession={() => (
        <OnnEventLandingPageForNewInterviewOrBriefingSessionCore
          eventData={eventData}
          isPreview={isPreview}
          isValidating={isValidating}
        />
      )}
      renderForNewInterview={() => (
        <OnnEventLandingPageForNewInterviewOrBriefingSessionCore
          eventData={eventData}
          isPreview={isPreview}
          isValidating={isValidating}
        />
      )}
    />
  );
};

const OnnEventLandingPageCore: FC<OnnEventLandingPageProps> = ({ eventData, isPreview }) => {
  const navigate = useNavigateWithQuery();
  const answeredCandidateDateId = eventData.onnEventAnswer.getPossibleCandidateDateId();
  const answeredCandidateDate = eventData.onnEvent.candidateDates.find(
    (date) => date.id === answeredCandidateDateId
  );
  const isAnswered = eventData.onnEventAnswer.isAnswered() && answeredCandidateDate;

  const canEdit = useMemo(
    () =>
      eventData.onnEvent.canEditAnswer(eventData.onnEventDeterminedDate?.candidateDateId) &&
      eventData.onnEvent.type === "normal" &&
      // NOTE: 初期版では面談イベントは入社者が回答を編集できないようにするため
      !eventData.onnEventDeterminedDate?.isRegisteredAttendance(),
    [eventData.onnEvent, eventData.onnEventDeterminedDate]
  );

  return (
    <StyledBox>
      <Typography variant="h2" color="textPrimary" style={{ fontWeight: 400 }}>
        {eventData.onnEvent.title}
      </Typography>
      <StyledCard>
        <Box mb={1}>
          <Typography variant="body2" color="textPrimary">
            <Linkify>{eventData.onnEvent.content}</Linkify>
          </Typography>
        </Box>
        {isAnswered ? (
          <>
            <Divider />
            <Box my={4}>
              <StyledTypography variant="body1" bold>
                回答
              </StyledTypography>
              <StyledTypography variant="body2">
                {eventData.onnEventAnswer.isUnavailableCandidates()
                  ? `参加できる日程がない`
                  : `${format(answeredCandidateDate.from, "yyyy年MM月dd日 HH:mm")}~${format(
                      answeredCandidateDate.until,
                      "HH:mm"
                    )}`}
              </StyledTypography>
            </Box>
            {canEdit && (
              <Button
                fullWidth
                variant="outlined"
                color="default"
                borderRadius="circle"
                onClick={() => navigate(`/portal/events/${eventData.onnEvent.id}/new`)}
              >
                回答を編集
              </Button>
            )}
          </>
        ) : (
          <>
            <Box height="32px" />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              borderRadius="circle"
              onClick={() => navigate(`/portal/events/${eventData.onnEvent.id}/new`)}
              disabled={!isPreview && !eventData.onnEvent.canAnswer()}
            >
              回答を始める
            </Button>
          </>
        )}
      </StyledCard>
    </StyledBox>
  );
};

// 新面談イベント
const OnnEventLandingPageForNewInterviewOrBriefingSessionCore: FC<OnnEventLandingPageProps> = ({
  eventData,
  isPreview,
  isValidating,
}) => {
  const navigate = useNavigateWithQuery();

  const { data: selectedOnnEventSlotDate, isLoading: isLoadingSelectedSlot } = useOnnEventSlot({
    slotId: eventData.onnEventDeterminedDate?.onnEventSlotDateId,
  });
  const isAnswered = !!selectedOnnEventSlotDate;

  const selectedCategory = eventData.briefingSessionCategories.find((category) => {
    return category.id === selectedOnnEventSlotDate?.briefingSessionCategoryId;
  });
  const selectedOnnEventPlace = eventData.onnEventPlaces.find(
    (place) => place.id === selectedOnnEventSlotDate?.eventPlaceId
  );
  const canEdit = useMemo(
    () =>
      eventData.onnEvent.canEditAnswerBasedOnSlotDate(selectedOnnEventSlotDate) &&
      !eventData.onnEventDeterminedDate?.isRegisteredAttendance(),
    [eventData.onnEvent, eventData.onnEventDeterminedDate, selectedOnnEventSlotDate]
  );

  // NOTE: 回答直後は再取得中で古いデータを参照する可能性があるためisValidatingも考慮する
  if (isValidating || isLoadingSelectedSlot) {
    return <Loading size="large" fullHeight />;
  }

  if (!isAnswered) {
    return (
      <Navigate
        to={`/portal/events/${eventData.onnEvent.id}/new${isPreview ? "?preview=true" : ""}`}
      />
    );
  }
  return (
    <StyledBox>
      <Typography variant="h2" color="textPrimary" style={{ fontWeight: 400 }}>
        {eventData.onnEvent.title}
      </Typography>
      <StyledCard display="flex" flexDirection="column" gridRowGap={40}>
        <Box display="flex" flexDirection="column" gridRowGap={16}>
          <Typography variant="body2" color="textPrimary">
            <Linkify>{eventData.onnEvent.content}</Linkify>
          </Typography>
          <Divider />
        </Box>
        {eventData.onnEvent.isBriefingSessionEvent() && (
          <Box display="flex" flexDirection="column" gridRowGap={24}>
            <Typography variant="body1" bold>
              説明会
            </Typography>
            <Typography variant="body2">{selectedCategory && selectedCategory.title}</Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gridRowGap={24}>
          <Typography variant="body1" bold>
            参加日時
          </Typography>
          <Typography variant="body2">
            {`${format(selectedOnnEventSlotDate.from, "yyyy年MM月dd日 HH:mm")}~${format(
              selectedOnnEventSlotDate.until,
              "HH:mm"
            )}`}
          </Typography>
        </Box>
        {selectedOnnEventSlotDate && (
          <NewInterviewEventFormatInfoSection
            selectedOnnEventSlotDate={selectedOnnEventSlotDate}
            selectedOnnEventPlace={selectedOnnEventPlace}
          />
        )}
        {canEdit && (
          <Button
            fullWidth
            variant="outlined"
            color="default"
            borderRadius="circle"
            onClick={() => navigate(`/portal/events/${eventData.onnEvent.id}/new`)}
          >
            回答を編集
          </Button>
        )}
      </StyledCard>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;

  ${mixin.portalSp`
    background-color: white;
    padding: 40px 24px;
    width: 100%;
    height: 100%;
  `}
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 40px;
  padding: 80px;

  ${mixin.portalSp`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 28px;
    padding: 0px;
    width: 100%;
  `}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 16px;
  }
`;
