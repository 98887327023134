import { IReminder, REMINDER_TITLE_MAX_LENGTH, reminderSchema } from "./schema";

/**
 * 管理者がコンタクトルームごとに設定する管理者向けリマインダー
 */
export class Reminder implements IReminder {
  static validator = reminderSchema;
  static titleMaxLength = REMINDER_TITLE_MAX_LENGTH;

  readonly id: string;
  readonly tenantId: string;
  readonly contactRoomId: string;
  readonly title: string;
  readonly isCompleted: boolean;
  readonly remindedAt: Date;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ReminderInput) {
    const parsedInit = reminderSchema.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.contactRoomId = parsedInit.contactRoomId;
    this.title = parsedInit.title;
    this.isCompleted = parsedInit.isCompleted;
    this.remindedAt = parsedInit.remindedAt;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(init: {
    tenantId: string;
    contactRoomId: string;
    title: string;
    remindedAt: Date;
  }): Reminder {
    return new Reminder(init);
  }

  public update(
    updateParams: Pick<ReminderInput, "title" | "isCompleted" | "remindedAt">
  ): Reminder {
    return new Reminder({
      ...this,
      ...updateParams,
      updatedAt: new Date(),
    });
  }

  public complete(): Reminder {
    return this.update({ isCompleted: true });
  }

  public isExpired(): boolean {
    return this.remindedAt.getTime() <= new Date().getTime();
  }
}

export type ReminderInput = Partial<ExcludeMethods<Reminder>>;
