import { OnnEventSlotDate } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

const generateKey = (slotId?: string) => {
  if (!slotId) return null;
  return [`/onn_event_api/slots`, slotId] as const;
};

export const useOnnEventSlot = ({ slotId }: { slotId?: string }) => {
  return useSWR(generateKey(slotId), async ([endpoint, slotId]) => {
    const response = await apiClient.get(endpoint, { id: slotId });
    return new OnnEventSlotDate(response.data);
  });
};

export const mutateOnnEventSlot = async (slotId: string) => {
  await mutate(generateKey(slotId));
};
