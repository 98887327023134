import { Box } from "@material-ui/core";

import { Announcement, AnnouncementDistribution } from "@onn/common";
import { isPast } from "date-fns";
import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { useHooks } from "../hooks/useHooks";

import { ActionFooter } from "./ActionFooter";

import { PublicationSettingsPaper } from "./PublicationSettingsPaper";

import { AddEmployeeToDeliverTabsPaper } from "~/components/domains/employees";
import { Icon, Loading, Tooltip, Typography } from "~/components/uiParts";
import { useAnnouncement } from "~/hooks/announcement/useAnnouncement";
import { useAnnouncementDistributionsByAnnouncementId } from "~/hooks/announcementDistribution";

import { useCurrentUser } from "~/hooks/employee";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { NotFound } from "~/pages/NotFound";

export const Page = () => {
  const { currentUser } = useCurrentUser();
  const params = useParams();
  const announcementId = params.id;
  const { switchSpaceTemporary } = useCurrentSpace();

  const {
    data: announcement,
    isLoading: isLoadingAnnouncement,
    mutate: mutateAnnouncement,
  } = useAnnouncement({
    announcementId: announcementId as string,
  });
  const {
    data: announcementDistributions,
    isLoading: isLoadingAnnouncementDistributions,
    mutate: mutateAnnouncementDistributions,
  } = useAnnouncementDistributionsByAnnouncementId({
    announcementId,
  });

  useEffect(() => {
    if (!announcement) return;
    switchSpaceTemporary(announcement.spaceId);
  }, [announcement, switchSpaceTemporary]);

  if (!currentUser.isAdmin()) return <NotFound />;

  const isLoading = isLoadingAnnouncement || isLoadingAnnouncementDistributions;

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!announcementId || !announcement || !announcementDistributions) {
    return <NotFound />;
  }

  return (
    <PageCore
      announcement={announcement}
      announcementDistributions={announcementDistributions}
      mutateAnnouncement={(announcement: Announcement) => mutateAnnouncement(announcement)}
      mutateAnnouncementDistributions={mutateAnnouncementDistributions}
    />
  );
};

export const PageCore = ({
  announcement,
  announcementDistributions,
  mutateAnnouncement,
  mutateAnnouncementDistributions,
}: {
  announcement: Announcement;
  announcementDistributions: AnnouncementDistribution[];
  mutateAnnouncement: (announcement: Announcement) => Promise<Announcement | null | undefined>;
  mutateAnnouncementDistributions: (
    announcementDistributions: AnnouncementDistribution[]
  ) => Promise<AnnouncementDistribution[] | undefined>;
}): JSX.Element => {
  const {
    onClickAddNewGraduateToAnnouncementButton,
    onClickDeleteTargetNewGraduate,
    onClickGoBack,
    onClickSetLater,
    onClickCancel,
    onClickConfirmSave,
    onClickUploadCSVButton,
    publicationEndDateInput,
    publicationEndDate,
    updatePublicationEndDate,
    publicationStartDateInput,
    publicationStartDate,
    updatePublicationStartDate,
    isDirtyPublicationDate,
    selectedNewGraduates,
    isDisabledSaveButton,
    fromPage,
    isSubmitting,
  } = useHooks({
    announcement,
    announcementDistributions,
    mutateAnnouncement,
    mutateAnnouncementDistributions,
  });

  const isAlreadyPublished = announcement.isAlreadyPublished();
  const willPublishImmediately =
    announcement.isUnderPublication() && announcementDistributions.length > 0;

  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth="800px">
        <Box mb="24px" display={"flex"} alignItems={"center"} gridGap={"8px"}>
          <Typography variant="h4" bold>
            配信設定
          </Typography>
          <Tooltip
            title="配信時に配信可能な状態になっていない対象者には配信されません。"
            placement="right-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box mb="40px">
          <Typography variant="body1" color="textPrimary">
            「{announcement.title}」の配信に関する設定をしましょう
          </Typography>
        </Box>

        <Box mb={"24px"}>
          <PublicationSettingsPaper
            publicationStartDateInput={publicationStartDateInput}
            publicationStartDate={publicationStartDate}
            updatePublicationStartDate={updatePublicationStartDate}
            publicationEndDateInput={publicationEndDateInput}
            publicationEndDate={publicationEndDate}
            updatePublicationEndDate={updatePublicationEndDate}
            isDirtyPublicationDate={isDirtyPublicationDate}
            fixedPublicationStartDate={
              announcement.publicationStartDate && isPast(announcement.publicationStartDate)
                ? announcement.publicationStartDate
                : undefined
            }
          />
        </Box>

        <Box mb={"40px"}>
          <AddEmployeeToDeliverTabsPaper
            onClickAddNewGraduateToButton={onClickAddNewGraduateToAnnouncementButton}
            onClickUploadCSVButton={onClickUploadCSVButton}
            deleteButtonHandlers={{
              fromNewSelectedNewGraduates: onClickDeleteTargetNewGraduate,
            }}
            newSelectedNewGraduates={selectedNewGraduates}
            registeredNewGraduateIds={
              announcementDistributions ? announcementDistributions.map((d) => d.employeeId) : []
            }
            isAlreadyDelivered={isAlreadyPublished}
          />
        </Box>
        <ActionFooter
          cancelButtonText={fromPage === "create" ? "あとで設定" : "キャンセル"}
          submitButtonText={willPublishImmediately ? "保存して配信" : "配信予約"}
          fromPage={fromPage ?? ""}
          onClickGoBack={onClickGoBack}
          onClickCancel={fromPage === "create" ? onClickSetLater : onClickCancel}
          onClickConfirmSave={onClickConfirmSave}
          isDisabledSaveButton={isDisabledSaveButton}
          isSubmitting={isSubmitting}
        />
      </Box>
    </Box>
  );
};
