import { z } from "zod";

/////////////////////////////////////////
// ANSWER ONN TASK TRIGGER SETTING SCHEMA
/////////////////////////////////////////

export const AnswerOnnTaskTriggerSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  triggerId: z.string(),
  onnTaskId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AnswerOnnTaskTriggerSetting = z.infer<typeof AnswerOnnTaskTriggerSettingSchema>;

/////////////////////////////////////////
// ANSWER ONN TASK TRIGGER SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const AnswerOnnTaskTriggerSettingOptionalDefaultsSchema =
  AnswerOnnTaskTriggerSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type AnswerOnnTaskTriggerSettingOptionalDefaults = z.infer<
  typeof AnswerOnnTaskTriggerSettingOptionalDefaultsSchema
>;

export default AnswerOnnTaskTriggerSettingSchema;
