import { z } from "zod";

import { OnnTaskSchema } from "../../_gen/zodSchema/index";

export const baseOnnTaskSchema = OnnTaskSchema.merge(
  z.object({
    scheduledDate: z.date().nullable(),
    deadlineDate: z.date().nullable(),
    firstDeliveredAt: z.date().nullable(),
  })
);

export type IBaseOnnTaskSchema = z.infer<typeof baseOnnTaskSchema>;
