import { EMPLOYEE_ACTION_TYPE, EmployeeActiveLog } from "@onn/common";
import { useState } from "react";

import { useCurrentUser } from "../employee/useCurrentUser";

import { EmployeeActiveLogUseCase } from "~/service/usecases/employeeActiveLogUseCase";

const employeeActiveLogUseCase = new EmployeeActiveLogUseCase();

/**
 * アクティブログを作成する
 * NOTE: 本来フロントエンドからデータの作成はしないがログを取るための簡易的なデータ生成なのでフロントで実行している
 * @returns isLoading データを作成中かどうか
 * @returns createEmployeeLog ログを作成する関数
 */
export const useCreateEmployeeActiveLog = (): {
  isLoading: boolean;
  createEmployeeLog: (
    type: EMPLOYEE_ACTION_TYPE,
    targetId: string,
    tenantId: string
  ) => Promise<EmployeeActiveLog>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();

  const createEmployeeLog = async (
    type: EMPLOYEE_ACTION_TYPE,
    targetId: string,
    tenantId: string
  ) => {
    setIsLoading(true);
    return await employeeActiveLogUseCase
      .create({ employeeId: currentUser.id, type, tenantId, targetId })
      .then((res) => res)
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    createEmployeeLog,
  };
};
