import React, { FC } from "react";

export const Code: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M13 20V18H15C15.2833 18 15.5208 17.9042 15.7125 17.7125C15.9042 17.5208 16 17.2833 16 17V15C16 14.3667 16.1833 13.7917 16.55 13.275C16.9167 12.7583 17.4 12.3917 18 12.175V11.825C17.4 11.6083 16.9167 11.2417 16.55 10.725C16.1833 10.2083 16 9.63333 16 9V7C16 6.71667 15.9042 6.47917 15.7125 6.2875C15.5208 6.09583 15.2833 6 15 6H13V4H15C15.8333 4 16.5417 4.29167 17.125 4.875C17.7083 5.45833 18 6.16667 18 7V9C18 9.28333 18.0958 9.52083 18.2875 9.7125C18.4792 9.90417 18.7167 10 19 10H20V14H19C18.7167 14 18.4792 14.0958 18.2875 14.2875C18.0958 14.4792 18 14.7167 18 15V17C18 17.8333 17.7083 18.5417 17.125 19.125C16.5417 19.7083 15.8333 20 15 20H13ZM9 20C8.16667 20 7.45833 19.7083 6.875 19.125C6.29167 18.5417 6 17.8333 6 17V15C6 14.7167 5.90417 14.4792 5.7125 14.2875C5.52083 14.0958 5.28333 14 5 14H4V10H5C5.28333 10 5.52083 9.90417 5.7125 9.7125C5.90417 9.52083 6 9.28333 6 9V7C6 6.16667 6.29167 5.45833 6.875 4.875C7.45833 4.29167 8.16667 4 9 4H11V6H9C8.71667 6 8.47917 6.09583 8.2875 6.2875C8.09583 6.47917 8 6.71667 8 7V9C8 9.63333 7.81667 10.2083 7.45 10.725C7.08333 11.2417 6.6 11.6083 6 11.825V12.175C6.6 12.3917 7.08333 12.7583 7.45 13.275C7.81667 13.7917 8 14.3667 8 15V17C8 17.2833 8.09583 17.5208 8.2875 17.7125C8.47917 17.9042 8.71667 18 9 18H11V20H9Z"
        stroke="none"
      />
    </svg>
  );
};
