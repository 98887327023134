import { TaskOptionTypes } from "@onn/common";
import React, { useCallback } from "react";

import { v4 } from "uuid";

import { Action } from "../../../_share_in_create_edit/reducer";

import {
  CHECK_BOX_QUESTION_FOR_FORM,
  FIlE_QUESTION_FOR_FORM,
  QuestionForState,
  RADIO_QUESTION_FOR_FORM,
  TEXT_QUESTION_FOR_FORM,
} from "../../../_share_in_create_edit/types";

export type OnChangeTitle = ({ questionId, title }: { questionId: string; title: string }) => void;

export type OnChangeIsRequired = ({
  questionId,
  isRequired,
}: {
  questionId: string;
  isRequired: boolean;
}) => void;

export type OnChangeQuestionOptionType = ({
  questionId,
  isRequired,
  questionTitle,
  taskOptionType,
  question,
}: {
  questionId: string;
  isRequired: boolean;
  questionTitle: string;
  taskOptionType: keyof typeof TaskOptionTypes;
  question: Pick<
    QuestionForState,
    | "id"
    | "title"
    | "isRequired"
    | "isDirtyTitle"
    | "isTitleError"
    | "titleHelperText"
    | "disabledTitle"
    | "disabledOptionType"
  >;
}) => void;

export type OnChangeOptionOrderType = ({
  questionId,
  sourceIndex,
  destinationIndex,
}: {
  questionId: string;
  sourceIndex: number;
  destinationIndex: number;
}) => void;

export type OnClickDelete = ({ questionId }: { questionId: string }) => void;

export const useQuestionFormCommonHandler = (dispatch: React.Dispatch<Action>) => {
  const onChangeTitle: OnChangeTitle = useCallback(
    ({ questionId, title }: { questionId: string; title: string }) => {
      const isEmpty = title === "";
      const helperText = isEmpty ? "タイトルを入力してください" : "";
      dispatch({
        type: "CHANGE_QUESTION_TITLE",
        payload: { questionId, title, isError: isEmpty, helperText },
      });
    },
    [dispatch]
  );

  const onChangeIsRequired: OnChangeIsRequired = useCallback(
    ({ questionId, isRequired }: { questionId: string; isRequired: boolean }) => {
      dispatch({
        type: "CHANGE_ONN_TASK_REQUIRED",
        payload: { questionId, isRequired },
      });
    },
    [dispatch]
  );

  const onChangeQuestionOptionType: OnChangeQuestionOptionType = useCallback(
    ({
      questionId,
      isRequired,
      questionTitle,
      taskOptionType,
      question: currentQuestion,
    }: {
      questionId: string;
      isRequired: boolean;
      questionTitle: string;
      taskOptionType: keyof typeof TaskOptionTypes;
      question: Pick<
        QuestionForState,
        | "id"
        | "title"
        | "isRequired"
        | "isDirtyTitle"
        | "isTitleError"
        | "titleHelperText"
        | "disabledTitle"
        | "disabledOptionType"
      >;
    }) => {
      const question = (() => {
        switch (taskOptionType) {
          case "CHECK_BOX":
            // eslint-disable-next-line no-case-declarations
            const cQuestion: CHECK_BOX_QUESTION_FOR_FORM = {
              id: questionId,
              type: "CHECK_BOX",
              isRequired: isRequired,
              options: [
                {
                  id: v4(),
                  text: "選択肢 1",
                },
                {
                  id: v4(),
                  text: "選択肢 2",
                },
              ],
              title: questionTitle,
              allowFreeText: false,
              disabledTitle: currentQuestion.disabledTitle,
              disabledOptionType: currentQuestion.disabledOptionType,
              isDirtyTitle: currentQuestion.isDirtyTitle,
              titleHelperText: currentQuestion.titleHelperText,
              isTitleError: currentQuestion.isTitleError,
            };
            return cQuestion;
          case "TEXT":
            // eslint-disable-next-line no-case-declarations
            const tQuestion: TEXT_QUESTION_FOR_FORM = {
              id: questionId,
              type: "TEXT",
              isRequired: isRequired,
              title: questionTitle,
              disabledTitle: currentQuestion.disabledTitle,
              disabledOptionType: currentQuestion.disabledOptionType,
              isDirtyTitle: currentQuestion.isDirtyTitle,
              titleHelperText: currentQuestion.titleHelperText,
              isTitleError: currentQuestion.isTitleError,
              isWordLimit: false,
            };
            return tQuestion;
          case "FILE":
            // eslint-disable-next-line no-case-declarations
            const fQuestion: FIlE_QUESTION_FOR_FORM = {
              id: questionId,
              type: "FILE",
              isRequired: isRequired,
              title: questionTitle,
              sampleFilePath: "",
              sampleFile: undefined,
              sampleFilePathForPreview: "",
              disabledTitle: currentQuestion.disabledTitle,
              disabledOptionType: currentQuestion.disabledOptionType,
              isDirtyTitle: currentQuestion.isDirtyTitle,
              titleHelperText: currentQuestion.titleHelperText,
              isTitleError: currentQuestion.isTitleError,
            };
            return fQuestion;
          case "RADIO":
            // eslint-disable-next-line no-case-declarations
            const rQuestion: RADIO_QUESTION_FOR_FORM = {
              id: questionId,
              type: "RADIO",
              isRequired: isRequired,
              options: [
                {
                  id: v4(),
                  text: "選択肢 1",
                },
                {
                  id: v4(),
                  text: "選択肢 2",
                },
              ],
              title: questionTitle,
              disabledTitle: currentQuestion.disabledTitle,
              disabledOptionType: currentQuestion.disabledOptionType,
              isDirtyTitle: currentQuestion.isDirtyTitle,
              titleHelperText: currentQuestion.titleHelperText,
              isTitleError: currentQuestion.isTitleError,
            };
            return rQuestion;
        }
      })();
      dispatch({
        type: "CHANGE_QUESTION_OPTION_TYPE",
        payload: {
          taskOptionType,
          questionId,
          question: question,
        },
      });
    },
    [dispatch]
  );

  const onClickDelete: OnClickDelete = useCallback(
    ({ questionId }: { questionId: string }) => {
      dispatch({
        type: "DELETE_QUESTION",
        payload: {
          questionId,
        },
      });
    },
    [dispatch]
  );

  const onChangeOptionOrder: OnChangeOptionOrderType = useCallback(
    ({
      questionId,
      sourceIndex,
      destinationIndex,
    }: {
      questionId: string;
      sourceIndex: number;
      destinationIndex: number;
    }) => {
      dispatch({
        type: "CHANGE_OPTION_ORDER",
        payload: {
          questionId,
          sourceIndex,
          destinationIndex,
        },
      });
    },
    [dispatch]
  );

  return {
    onChangeTitle,
    onChangeIsRequired,
    onChangeQuestionOptionType,
    onClickDelete,
    onChangeOptionOrder,
  };
};
