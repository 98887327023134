import {
  BriefingSessionEvent,
  NewInterviewEvent,
  generateSampleCsvDataForCreateOnnEventSlotDates,
} from "@onn/common";
import { useCallback } from "react";

export const useGenerateSampleCSV = () => {
  const generateSampleCSV = useCallback((onnEvent: NewInterviewEvent | BriefingSessionEvent) => {
    return generateSampleCsvDataForCreateOnnEventSlotDates(onnEvent);
  }, []);

  return { generateSampleCSV };
};
