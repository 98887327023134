import { z } from "zod";

import { AnnouncementDistributionSchema } from "../../_gen/zodSchema";

export const announcementDistributionSchema = AnnouncementDistributionSchema.merge(
  z.object({
    firstReadAt: z.date().optional(),
    lastReadAt: z.date().optional(),
  })
);
