import { v4 } from "uuid";

import { OnnTaskFormAnswerDraftRepository } from "~/infrastructure/api/onnTaskFormAnswerDraftRepository";
import { OnnTaskFormAnswerDraft } from "~/types/firestore/OnnTaskFormAnswerDraft";
import { captureException } from "~/util";

const onnTaskFormAnswerDraftRepository = new OnnTaskFormAnswerDraftRepository();

export class OnnTaskFormAnswerDraftUseCase {
  /** 新規作成または更新をする */
  async save(draft: OnnTaskFormAnswerDraft) {
    await onnTaskFormAnswerDraftRepository.save(draft);
  }
  async findOrCreateNew({
    employeeId,
    onnTaskFormAnswerId,
    tenantId,
  }: {
    employeeId: string;
    onnTaskFormAnswerId: string;
    tenantId: string;
  }): Promise<OnnTaskFormAnswerDraft> {
    try {
      const drafts = await onnTaskFormAnswerDraftRepository.find({
        tenantId,
        employeeId,
        onnTaskFormAnswerId,
      });

      // 最新の下書きから見て入力がある下書きを取得
      const draft = drafts?.find((draft) =>
        draft.answers.some((answer) => {
          switch (answer.type) {
            case "TEXT":
              return answer.value;
            case "CHECK_BOX":
              return answer.selectedOptionIds.length;
            case "RADIO":
              return answer.selectedOptionId;
            case "FILE":
              return answer.filePath;
          }
        })
      );

      return draft || createNewDraft({ tenantId, employeeId, onnTaskFormAnswerId });
    } catch (error) {
      captureException({
        error: error as Error,
        tags: { type: "OnnTaskFormAnswerDraftUseCase:findOrCreateNew" },
        extras: { tenantId, employeeId, onnTaskFormAnswerId },
      });
      return createNewDraft({ tenantId, employeeId, onnTaskFormAnswerId });
    }
  }
}

const createNewDraft = (args: {
  tenantId: string;
  employeeId: string;
  onnTaskFormAnswerId: string;
}) => ({
  ...args,
  id: v4(),
  answers: [],
  createdAt: new Date(),
  updatedAt: new Date(),
});
