import { useMemo } from "react";

import { useContactRoomPins } from "~/hooks/contactRoomPin/useContactRoomPins";

export const useContactRoomPin = ({ selectedContactRoomId }: { selectedContactRoomId: string }) => {
  const { data: contactRoomPins = [], isLoading } = useContactRoomPins();
  const contactRoomPin = useMemo(
    () =>
      contactRoomPins.find(
        (contactRoomPin) => contactRoomPin.contactRoomId === selectedContactRoomId
      ),
    [contactRoomPins, selectedContactRoomId]
  );

  return { contactRoomPin, isLoading };
};
