import { CandidateDateWithNumberOfParticipants, OnnEventDeterminedDate } from "@onn/common";
import { useCallback, useMemo } from "react";

export const useGetDeterminedAndCandidateDate = ({
  determinedDates,
  candidateDateList,
}: {
  determinedDates: OnnEventDeterminedDate[];
  candidateDateList: CandidateDateWithNumberOfParticipants[];
}) => {
  const employeeIdToDeterminedDate = useMemo(
    () =>
      new Map(
        (determinedDates || []).map((determinedDate) => [determinedDate.employeeId, determinedDate])
      ),
    [determinedDates]
  );
  const candidateDateIdToCandidateDateMap = useMemo(
    () => new Map(candidateDateList.map((candidateDate) => [candidateDate.id, candidateDate])),
    [candidateDateList]
  );

  const getOnnEventDeterminedDate = useCallback(
    (employeeId: string) => employeeIdToDeterminedDate.get(employeeId),
    [employeeIdToDeterminedDate]
  );
  const getAnsweredCandidateDate = useCallback(
    (employeeId: string) => {
      const determinedDate = getOnnEventDeterminedDate(employeeId);
      return determinedDate
        ? candidateDateIdToCandidateDateMap.get(determinedDate.candidateDateId)
        : undefined;
    },
    [getOnnEventDeterminedDate, candidateDateIdToCandidateDateMap]
  );

  return {
    getOnnEventDeterminedDate,
    getAnsweredCandidateDate,
  };
};
