import { fileTypeMatchers, FileType, acceptValueForFileTypesRecord } from "@onn/common";

import { captureException } from "~/util/loggerUtil";

/**
 * 指定されたファイル形式に対応する input タグ accept 属性の値を返す。
 * ただし、fileTypes が空の場合はすべてのファイル形式を選択可能にするため空文字列を返す。
 *
 * @param fileTypes input タグで受け付けるファイルタイプの配列
 * @returns 対応する拡張子をカンマで繋げた文字列
 */
export const getAcceptValueForFileTypes = (fileTypes: FileType[]) => {
  return fileTypes
    .map((t) => acceptValueForFileTypesRecord[t])
    .flat()
    .join(",");
};

/**
 * 引数で指定されたファイル形式によって、ファイル列をフィルタリングした結果を返す
 *
 * @param files フィルタリング元のファイル列
 * @param fileTypes ファイル形式
 */
export const filterFilesByType = (files: File[], fileTypes: FileType[]): File[] => {
  return fileTypes.length
    ? files.filter((file) =>
        fileTypes.some((fileType) => file.name.match(fileTypeMatchers[fileType]))
      )
    : files;
};

/**
 * ファイルをダウンロードする
 *
 * @param url 対象のファイルURL
 * @param fileName ダウンロードされるファイルの名前
 */
export const downloadFileAsync = async ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}): Promise<void> => {
  try {
    const res = await fetch(url, {
      method: "GET",
    });

    if (!res.ok) {
      throw new Error("ファイルのダウンロードに失敗しました");
    }

    const blob = await res.blob();

    // 一時的なダウンロード用リンクを作成し、ダウンロード終了後に破棄する
    const link = document.createElement("a");

    link.href = URL.createObjectURL(new Blob([blob]));
    link.setAttribute("download", fileName);

    document.body.appendChild(link);

    link.click();
    link.parentNode?.removeChild(link);
  } catch (error) {
    if (error instanceof Error) {
      captureException({ error, tags: { type: "downloadFileAsync" } });
    }
    throw error;
  }
};

/**
 * ファイル名に使用できない文字をハイフンに置換します
 * 使用できない文字(9種) ¥ / : * ? " < > |
 */
export const removeUnusableCharacters = (fileName: string) => {
  return fileName.replace(/¥|\/|:|\*|\?|"|<|>|\|/g, "-");
};
