import { Employee } from "@onn/common";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import { useOptimisticCreateMessage } from "./useOptimisticCreateMessage";
import { generateSentLineStampMessagesKey } from "./useSentLineStampMessages";

export function usePostLineStampMessage({
  tenantId,
  contactRoomId,
  employee,
}: {
  tenantId: string;
  contactRoomId: string;
  employee: Employee;
}) {
  const { mutate } = useSWRConfig();
  const { postMessage } = useOptimisticCreateMessage({ tenantId, contactRoomId });

  return useSWRMutation(
    { key: "postLineStampMessage", contactRoomId, employee },
    (_key, { arg }: { arg: { stickerId: string; isOfficialName?: boolean } }) => {
      return postMessage({
        employee,
        stickerId: arg.stickerId,
        isOfficialName: arg.isOfficialName,
      });
    },
    {
      onSuccess() {
        mutate(generateSentLineStampMessagesKey({ tenantId }));
      },
    }
  );
}
