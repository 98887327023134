import { z } from "zod";

/////////////////////////////////////////
// RECRUITMENT PROCESS RECORD ONN TASK ITEM SCHEMA
/////////////////////////////////////////

export const RecruitmentProcessRecordOnnTaskItemSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  employeeId: z.string(),
  recruitmentStatusId: z.string(),
  recruitmentProcessRecordId: z.string(),
  onnTaskId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RecruitmentProcessRecordOnnTaskItem = z.infer<
  typeof RecruitmentProcessRecordOnnTaskItemSchema
>;

/////////////////////////////////////////
// RECRUITMENT PROCESS RECORD ONN TASK ITEM OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const RecruitmentProcessRecordOnnTaskItemOptionalDefaultsSchema =
  RecruitmentProcessRecordOnnTaskItemSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type RecruitmentProcessRecordOnnTaskItemOptionalDefaults = z.infer<
  typeof RecruitmentProcessRecordOnnTaskItemOptionalDefaultsSchema
>;

export default RecruitmentProcessRecordOnnTaskItemSchema;
