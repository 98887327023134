import { Action, AnyActionCondition, AnyActionSetting } from "@onn/common";
import { useCallback } from "react";

import { useGetTriggerFormName } from "../useGetTriggerFormName";
import { useFormContext } from "../useScenarioForm";
import { useUpdateWarningRecruitmentStatuses } from "../useUpdateWarningRecruitmentStatuses";

export const useUpdateActionOnForm = ({
  recruitmentStatusId,
  triggerId,
}: {
  recruitmentStatusId: string;
  triggerId: string;
}) => {
  const { getTriggerFormName } = useGetTriggerFormName();
  const { trigger, getValues, setValue } = useFormContext();
  const { updateWarningRecruitmentStatuses } = useUpdateWarningRecruitmentStatuses();

  const updateActionOnForm = useCallback(
    ({
      action,
      actionSetting,
      actionCondition,
    }: {
      action: Action;
      actionSetting: AnyActionSetting;
      actionCondition?: AnyActionCondition | null;
    }) => {
      const triggerFormName = getTriggerFormName({
        recruitStatusId: recruitmentStatusId,
        triggerId,
      });
      const values = getValues(`${triggerFormName}.actions`);
      setValue(
        `${triggerFormName}.actions`,
        values.map((value) => {
          if (value.action.id === action.id) {
            return {
              action,
              actionSetting,
              actionCondition: actionCondition || undefined,
            };
          }
          return value;
        })
      );

      trigger();
      updateWarningRecruitmentStatuses();
    },
    [
      getTriggerFormName,
      getValues,
      recruitmentStatusId,
      setValue,
      trigger,
      triggerId,
      updateWarningRecruitmentStatuses,
    ]
  );

  return { updateActionOnForm };
};
