import { Control, useFormState } from "react-hook-form";

import { InputState } from "./ReactHooksFormComponents/InputState";

export const useIsCandidateDateDuplicate = ({ control }: { control: Control<InputState> }) => {
  const { errors } = useFormState({ control });

  let isCandidateDateDuplicate = false;
  if (errors && errors.candidateDates && errors.candidateDates.length !== 0) {
    for (let i = 0; i < (errors.candidateDates.length || 0); i++) {
      if (errors.candidateDates[i]) {
        if (errors.candidateDates[i]?.from?.message === "重複しています") {
          isCandidateDateDuplicate = true;
        }
      }
    }
  }

  return { isCandidateDateDuplicate };
};
