import { useCallback } from "react";

import { mutate } from "swr";

import { generateKeyObj } from "./useIndividualInvitationSetting";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useUpdateIndividualInvitationSetting = () => {
  /**
   * 未登録ユーザーの招待日時を更新する
   */
  const updateIndividualInvitationSetting = useCallback(
    async ({
      employeeId,
      individualInvitationSettingId,
      onnEventIds,
      onnTaskIds,
    }: {
      employeeId: string;
      individualInvitationSettingId: string;
      onnEventIds: string[];
      onnTaskIds: string[];
    }) => {
      try {
        await apiClient.patch("/patch_individual_invitation_setting", {
          individualInvitationSettingId,
          onnEventIds,
          onnTaskIds,
        });
        await mutate(generateKeyObj({ employeeId }));
      } catch (e) {
        captureException({
          error: e as Error,
          tags: { type: "updateUnregisteredEmployeeInvitedAt" },
        });
      }
    },
    []
  );

  return { updateIndividualInvitationSetting };
};
