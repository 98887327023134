import React, { FC } from "react";

import { Button, FilePicker, Icon } from "~/components/uiParts";
import { Payload } from "~/components/uiParts/FilePicker/FilePicker";

const wordExtensions = [".doc", ".docx"];
const excelExtensions = [".xls", ".xlsx"];
const powerPointExtensions = [".ppt", ".pptx"];
const pdfExtensions = [".pdf"];
const imageExtensions = [".gif", ".jpg", ".jpeg", ".png"];

const accepts = wordExtensions
  .concat(["application/gzip", ".zip"])
  .concat(excelExtensions)
  .concat(powerPointExtensions)
  .concat(pdfExtensions)
  .concat(imageExtensions);

const inputAccept = accepts.join(" ,");

export const FilePickerButton: FC<{ onChange: (payload: Payload) => void }> = ({ onChange }) => {
  return (
    <FilePicker
      accepts={["image", "word", "excel", "powerPoint", "pdf", "zip", "csv"]}
      inputAccept={inputAccept}
      multiple={false}
      onChange={onChange}
    >
      <Button
        startIcon={<Icon icon="clip" size="sm" color="primary" />}
        color={"primary"}
        borderRadius={"regular"}
        variant={"outlined"}
      >
        ファイルを選択
      </Button>
    </FilePicker>
  );
};
