import { Box } from "@material-ui/core";
import React, { FC, ReactNode, useEffect, useState } from "react";

import { Icon, Typography } from "~/components/uiParts";
import theme from "~/config/theme";

// TODO: UIpartに移動する

// NOTE: この値は不要にレンダリングされるのを防ぐために使用する。ResizeObserverで監視する要素の高さをreactの外で保持しておく。
let previousInnerAreaHeight = 0;
// NOTE: 高さが100pxを超えたら「続きを見る」ボタンを表示する
const BoundaryHeight = 100;

type Props = {
  children: ReactNode;
};

export const ClosableOnPaper: FC<Props> = ({ children }) => {
  const [isExpand, setIsExpand] = useState(false);
  // NOTE: 選択されている会場が変わったらアコーディオンを閉じる
  useEffect(() => {
    setIsExpand(false);
  }, []);

  // NOTE: 備考欄テキスト＋画像の高さに応じて、「続きを見る」ボタンの表示を切り替えるため、ここで高さを動的に取得している
  const innerAreaRef = React.useRef<HTMLDivElement>(null);
  // NOTE: 画像読み込み後に高さが変わるため、useSyncExternalStoreを使って高さを動的に取得している
  const innerAreaHeight = React.useSyncExternalStore(
    (onStoreChange: () => void) => {
      const observer = new ResizeObserver((entries) => {
        entries.forEach((_) => {
          // NOTE: 不要にレンダリングされるのを防ぐため、高さが変わった時だけ再レンダリングする
          if (previousInnerAreaHeight !== innerAreaRef.current?.offsetHeight) {
            onStoreChange();
          }
          previousInnerAreaHeight = innerAreaRef.current?.offsetHeight || 0;
        });
      });
      if (innerAreaRef.current) {
        observer.observe(innerAreaRef.current);
      }
      return () => {
        observer.disconnect();
      };
    },
    () => {
      return innerAreaRef.current?.offsetHeight;
    }
  );

  // 「続きを見る」ボタンを表示するかどうか
  const isShowAccordionButton = innerAreaHeight && innerAreaHeight > BoundaryHeight;

  return (
    <Box p="16px" bgcolor={theme.palette.grey[50]} borderRadius="8px">
      <div
        style={{
          overflow: "hidden",
          transition: "height 0.3s",
          position: "relative",
          height: isExpand
            ? undefined
            : innerAreaHeight && innerAreaHeight > BoundaryHeight
            ? BoundaryHeight
            : innerAreaHeight,
        }}
      >
        {isShowAccordionButton && (
          <>
            {/* NOTE: 「続きを見る」ボタンの上周辺の文字をぼやかす */}
            {!isExpand && (
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  background: `linear-gradient(to top,${theme.palette.grey[50]} 30%, transparent 100%)`,
                }}
              />
            )}
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                backgroundColor: theme.palette.grey[50],
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccordionButton isExpand={isExpand} onClick={() => setIsExpand(!isExpand)} />
            </Box>
          </>
        )}
        <div ref={innerAreaRef}>{children}</div>
        {/* NOTE: アコーディオンが開いている時はボトムに「閉じる」ボタンが来るのでそのための40px */}
        <Box height={isExpand ? "40px" : "0px"} />
      </div>
    </Box>
  );
};

const AccordionButton = ({ isExpand, onClick }: { isExpand: boolean; onClick: () => void }) => {
  return (
    <Box
      style={{
        backgroundColor: theme.palette.grey[50],
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Icon icon={isExpand ? "chevronUp" : "chevronDown"} size="sm" color="grey" />
      <Typography variant="body2" color="textPrimary" style={{ marginLeft: "4px" }}>
        {isExpand ? "閉じる" : "続きを見る"}
      </Typography>
    </Box>
  );
};
