import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { RegisterIndividualByLineWrapper } from "./RegisterIndividualByLineWrapper";

import { ErrorPage } from "~/components/domains/account/ErrorPage";
import { RegisterIndividualByEmail } from "~/components/domains/account/RegisterIndividualByEmail";
import { RegisterIndividualByLine } from "~/components/domains/account/RegisterIndividualByLine";

import { RegistrationInfo } from "~/hooks/employee/useRegistrationInfoByInvitationToken";

type Props = {
  registrationInfoByInvitationToken: RegistrationInfo;
  liffAccessToken: string | null | undefined;
  dataLineLoginChannelInfo: {
    liffId: string;
    clientId: string;
  };
};
export const RegistrationIndividualDetail: FC<Props> = ({
  registrationInfoByInvitationToken,
  liffAccessToken,
  dataLineLoginChannelInfo,
}) => {
  const { type } = useParams<"type">();

  if (type === "line") {
    return (
      <RegisterIndividualByLineWrapper dataLineLoginChannelInfo={dataLineLoginChannelInfo}>
        <RegisterIndividualByLine
          registrationInfo={registrationInfoByInvitationToken}
          liffAccessToken={liffAccessToken}
        />
      </RegisterIndividualByLineWrapper>
    );
  }

  if (type === "email") {
    return <RegisterIndividualByEmail registrationInfo={registrationInfoByInvitationToken} />;
  }

  return (
    <ErrorPage
      pageType="アカウント登録ページ"
      detail="typeが不正です"
      extraInfo={{ type, id: registrationInfoByInvitationToken.employee.invitationToken }}
    />
  );
};
