import { RecruitmentStatus, RecruitmentStatusWithRelatedInfo, Scenario } from "@onn/common";
import React, { createContext, useContext, useState } from "react";

import { useIsLocking } from "./useIsLocking";

import { useSelectedRecruitmentStatus } from "./useSelectedRecruitmentStatus";

import { useRecruitmentStatusesWithRelations } from "~/hooks/recruitmentStatusWithRelation";
import { useScenario } from "~/hooks/scenario/useScenario";
import { useQuery } from "~/hooks/shared";

type Mode = "view" | "edit";

type ContextType = {
  scenarioId: string;
  scenario?: Scenario;
  selectedRecruitmentStatusId: string;
  selectedRecruitmentStatus: RecruitmentStatus;
  changeSelectedRecruitmentStatusId: (id: string) => void;
  resetSelectedRecruitmentStatus: () => void;
  isDeveloperMode: boolean;
  mode: Mode;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  isLocking: boolean;
  isLockingByOwn: boolean;
  isLockingByOthers: boolean;
  warningRecruitmentStatuses: RecruitmentStatus[];
  recruitmentStatusesRelatedToScenario: RecruitmentStatusWithRelatedInfo[];
  setWarningRecruitmentStatuses: React.Dispatch<React.SetStateAction<RecruitmentStatus[]>>;
  resetWarningRecruitmentStatuses: () => void;
};

/**
 * シナリオ画面でのみ使用するコンテキスト
 */
export const PageContext = createContext<ContextType | undefined>(undefined);

export const usePageContext = () => {
  const c = useContext(PageContext);
  if (!c) throw new Error("useCtx must be inside a Provider with a value");
  return c;
};

export const PageContextProvider: React.FC<{
  children: React.ReactNode;
  scenarioId: string;
}> = ({ children, scenarioId }) => {
  const { isDeveloperMode } = useIsDeveloperMode();
  const { isLocking, isLockingByOwn, isLockingByOthers } = useIsLocking({ scenarioId });
  const { data: scenario } = useScenario({ scenarioId: scenarioId || null });

  const {
    selectedRecruitmentStatus,
    changeSelectedRecruitmentStatusId,
    resetSelectedRecruitmentStatus,
    warningRecruitmentStatuses,
    setWarningRecruitmentStatuses,
    resetWarningRecruitmentStatuses,
  } = useSelectedRecruitmentStatus();
  const { mode, setMode } = useMode();

  const { data: recruitmentStatuses = [] } = useRecruitmentStatusesWithRelations({
    withDeleted: false,
  });
  const recruitmentStatusesRelatedToScenario = recruitmentStatuses.filter(
    (recruitmentStatus) => recruitmentStatus.scenarioId === scenarioId
  );

  return (
    <PageContext.Provider
      value={{
        scenarioId,
        scenario,
        selectedRecruitmentStatusId: selectedRecruitmentStatus.id,
        selectedRecruitmentStatus,
        changeSelectedRecruitmentStatusId,
        resetSelectedRecruitmentStatus,
        isDeveloperMode,
        isLocking,
        mode,
        setMode,
        isLockingByOwn,
        isLockingByOthers,
        warningRecruitmentStatuses,
        setWarningRecruitmentStatuses,
        resetWarningRecruitmentStatuses,
        recruitmentStatusesRelatedToScenario,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

const useMode = () => {
  const [mode, setMode] = useState<Mode>("view");
  return { mode, setMode };
};

const useIsDeveloperMode = () => {
  const { query } = useQuery();
  return {
    isDeveloperMode: !!query.has("isDeveloperMode"),
  };
};
