import React, { FC } from "react";

import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { Registration } from "../Registration";

import { ErrorPage } from "~/components/domains/account/ErrorPage";
import { Loading } from "~/components/uiParts";
import { CenterBox } from "~/components/uiParts/CenterBox";

import { useDefaultRegistrationInvitationLinkBySpace } from "~/hooks/registrationInvitationLink/useDefaultRegistrationInvitationLinkBySpace";
import { useRegistrationInvitationLink } from "~/hooks/registrationInvitationLink/useRegistrationInvitationLink";
import { useQuery } from "~/hooks/shared";
import { useSpaceSetting } from "~/hooks/space/useSpaceSetting";
import { useTenantLineLoginChannelInfo } from "~/hooks/tenant/useTenantLineLoginChannelInfo";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";
import { captureException } from "~/util";

export const Wrapper: FC<{
  children: typeof Registration;
}> = ({ children: Children }) => {
  const tenantId = getTenantIdFromDomain();
  const { data: dateLineLoginChannelInfo, isLoading: isLoadingLineLoginChannelInfo } =
    useTenantLineLoginChannelInfo(tenantId || undefined);

  const navigate = useNavigate();
  const location = useLocation();
  const { query } = useQuery();
  const registrationInvitationLinkId = query.get("registration-invitation-link-id");
  const spaceId = query.get("space-id");
  const {
    data: registrationInvitationLinkData,
    isLoading: isLoadingRegistrationInvitationLink,
    error: errorRegistrationInvitationLink,
  } = useRegistrationInvitationLink(registrationInvitationLinkId || undefined);
  const { data: defaultLinkData, isLoading: isLoadingDefaultLink } =
    useDefaultRegistrationInvitationLinkBySpace({
      tenantId,
      spaceId: registrationInvitationLinkId ? undefined : spaceId,
      // registrationInvitationLinkId が指定されている場合は、デフォルト招待リンクを取得しない
    });

  const { data: _spaceSetting, isLoading: isLoadingSpaceSetting } = useSpaceSetting({
    tenantId: tenantId || undefined,
    spaceId: spaceId || undefined,
  });
  const spaceSetting = _spaceSetting || undefined;

  const isLoading =
    isLoadingLineLoginChannelInfo ||
    isLoadingRegistrationInvitationLink ||
    isLoadingDefaultLink ||
    isLoadingSpaceSetting;

  if (isLoading) {
    return (
      <CenterBox>
        <Loading size="large" />
      </CenterBox>
    );
  }

  if (!dateLineLoginChannelInfo) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="dateLineLoginChannelInfo is not found"
        extraInfo={{ tenantId }}
      />
    );
  }

  if (registrationInvitationLinkId && !registrationInvitationLinkData) {
    captureException({
      error: new Error(
        "指定された共通招待リンクは存在しないため、共通招待リンクの指定を外してアカウント登録ページを表示します"
      ),
      tags: { type: "Registration" },
      extras: { registrationInvitationLinkId, errorRegistrationInvitationLink },
    });

    query.delete("registration-invitation-link-id");
    navigate(location.pathname + "?" + createSearchParams(query));
  }

  if (!spaceId && !registrationInvitationLinkId) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="共通招待リンクを特定するための情報が存在しません"
        extraInfo={{ spaceId, registrationInvitationLinkId }}
      />
    );
  }

  return (
    <Children
      liffId={dateLineLoginChannelInfo.liffId}
      registrationInvitationLink={registrationInvitationLinkData?.registrationInvitationLink}
      isPreEntry={
        registrationInvitationLinkData?.recruitmentStatus.isPreEntry() ||
        defaultLinkData?.recruitmentStatus?.isPreEntry() ||
        false
      }
      canSelectMail={
        (!!registrationInvitationLinkData &&
          registrationInvitationLinkData.registrationInvitationLink.canAuthenticateByEmail()) ||
        (!!defaultLinkData?.registrationInvitationLink &&
          defaultLinkData.registrationInvitationLink.canAuthenticateByEmail())
      }
      spaceSetting={spaceSetting}
      // この時に取得された defaultLink を 使用する共通招待リンクとして決定させることはしない
      // LIFF を立ち上げた場合に、別の共通招待リンクが優先される場合があるため。
    />
  );
};
