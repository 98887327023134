import { Employee } from "@onn/common";
import React, { FC, createContext, ReactNode, useCallback } from "react";

import { useCurrentUser } from "~/hooks/employee";

export const AccessControlContext = createContext<{
  isEditable: (newHire: Employee) => boolean;
}>({
  isEditable: () => false,
});

export const AccessControlProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  const isEditable = useCallback((): boolean => {
    return currentUser.isAdmin();
  }, [currentUser]);

  return (
    <AccessControlContext.Provider value={{ isEditable }}>{children}</AccessControlContext.Provider>
  );
};
