import { APISchema } from "@onn/common";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/rich_menu_api/registration-rich-menus"]["POST"];

export type EditRegistrationRichMenuParams = EndPoint["body"];

export const useEditRegistrationRichMenu = () => {
  const editRegistrationRichMenu = async (params: EditRegistrationRichMenuParams) => {
    await apiClient.post("/rich_menu_api/registration-rich-menus", params);
  };

  return { editRegistrationRichMenu };
};
