import { RegistrationInvitationLink } from "@onn/common";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { ErrorPage } from "~/components/domains/account/ErrorPage";
import { ShareInviteByEmailCoreFirstTime } from "~/pages/portal/Account/Registration/RegistrationInvitationLinksDetail/parts/ShareInviteByEmailCoreFirstTime";
import { ShareInviteByLineAuthOnLiff } from "~/pages/portal/Account/Registration/RegistrationInvitationLinksDetail/parts/ShareInviteByLineAuthOnLiff";

type Props = {
  registrationInvitationLink: RegistrationInvitationLink;
};
export const RegistrationInvitationLinksDetail: FC<Props> = ({ registrationInvitationLink }) => {
  const { type } = useParams<"type">();

  if (type === "line") {
    return <ShareInviteByLineAuthOnLiff registrationInvitationLink={registrationInvitationLink} />;
  }

  if (type === "email") {
    return (
      <ShareInviteByEmailCoreFirstTime registrationInvitationLink={registrationInvitationLink} />
    );
  }

  return (
    <ErrorPage
      pageType="アカウント登録ページ"
      detail="typeが不正です"
      extraInfo={{ type, id: registrationInvitationLink.id }}
    />
  );
};
