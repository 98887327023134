import { useState } from "react";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useReadAnnouncementDistribution = () => {
  const [isLoading, setIsLoading] = useState(false);

  const execReadAnnouncementDistribution = async (announcementDistributionId: string) => {
    try {
      await apiClient.post("/announcement_api/announcements/distribution/read", {
        announcementDistributionId,
      });
      return;
    } catch (e) {
      captureException({
        error: e as Error,
        tags: {
          type: "useReadAnnouncementDistribution:execReadAnnouncementDistribution",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { execReadAnnouncementDistribution, isLoading };
};
