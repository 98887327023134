import { AuthenticationType } from "@onn/common";

export const useRegistrationLinks = (
  invitationToken: string
): {
  pathForLine: string;
  pathForEmail: string;
} => {
  return {
    pathForLine: generatePath(invitationToken, "line"),
    pathForEmail: generatePath(invitationToken, "email"),
  };
};

const generatePath = (invitationToken: string, authenticationType: AuthenticationType) => {
  return `/portal/accounts/invitations/${invitationToken}/registration/${authenticationType}`;
};
