import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// EMPLOYEE INFORMATION SCHEMA
/////////////////////////////////////////

export const EmployeeInformationSchema = z.object({
  id: z.string(),
  value: JsonValueSchema,
  employeeId: z.string(),
  tenantId: z.string(),
});

export type EmployeeInformation = z.infer<typeof EmployeeInformationSchema>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationOptionalDefaultsSchema = EmployeeInformationSchema.merge(
  z.object({})
);

export type EmployeeInformationOptionalDefaults = z.infer<
  typeof EmployeeInformationOptionalDefaultsSchema
>;

export default EmployeeInformationSchema;
