import { Box } from "@mui/material";
import React, { FC } from "react";

import { Button, Icon, Tooltip } from "~/components/uiParts";

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
};

export const ButtonToDeleteTrigger: FC<Props> = ({ onClick, disabled }) => {
  return (
    <Tooltip
      title={disabled ? "候補者が存在するブロックは削除ができません" : undefined}
      arrow
      isWrapSpan={false}
    >
      {/* tooltipのchildrenにはrefを渡す必要があるのでBoxでラップしている */}
      <Box width={"100%"}>
        <Button
          startIcon={<Icon icon="trash" size="md" color={disabled ? "lightGrey" : "secondary"} />}
          color="secondary"
          borderRadius={"regular"}
          variant="outlined"
          fullWidth
          disabled={disabled}
          onClick={disabled ? undefined : onClick}
        >
          ブロックを削除
        </Button>
      </Box>
    </Tooltip>
  );
};
