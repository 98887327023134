import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { ModalContent } from "./modal-content/ModalContent";

import { useViewModel, type Data } from "./useViewModel";

import { Button, ScrollableBodyModal } from "~/components/uiParts";

type Props = {
  open: boolean;
} & Data;

export const NewGraduateSearchModal = ({
  open,
  conditions,
  logicType,
  defaultCount,
  onCancel,
  onSearchConfirm,
}: Props): JSX.Element => {
  const {
    currentLogicType,
    currentConditions,
    isLoadingSearch,
    isLoadingChange,
    searchResultCountByCondition,
    onAddCondition,
    onChangeCondition,
    onChangeTarget,
    onClearAllConditions,
    onRemoveCondition,
    onSelectLogicType,
    handleSearch,
  } = useViewModel({
    conditions,
    logicType,
    defaultCount,
    onCancel,
    onSearchConfirm,
  });

  return (
    <ScrollableBodyModal
      open={open}
      title={"絞り込み"}
      content={
        <ModalContent
          conditions={currentConditions}
          logicType={currentLogicType}
          onAddCondition={onAddCondition}
          onClearAllConditions={onClearAllConditions}
          onRemoveCondition={onRemoveCondition}
          onChangeCondition={onChangeCondition}
          onChangeTarget={onChangeTarget}
          onSelectLogicType={onSelectLogicType}
        />
      }
      footer={
        <StyledButtonContainer gridGap={"16px"}>
          <Box>{/* TODO: 条件を保存して使用できるようにする */}</Box>
          <Box>
            <Button borderRadius="circle" variant="text" color="default" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              borderRadius="circle"
              variant="contained"
              color="primary"
              onClick={handleSearch}
              isLoading={isLoadingSearch}
            >
              {/* TODO: 表示される件数はキーワード検索によって変化するので文言を変えたほうが良さそう */}
              {`${isLoadingChange ? "-" : searchResultCountByCondition}名の候補者を表示`}
            </Button>
          </Box>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;
