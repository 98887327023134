import { v4 } from "uuid";

import {
  employeeInformationSingleSelectTypeFieldSchema,
  IEmployeeInformationSingleSelectTypeField,
} from "./schema";

export class EmployeeInformationSingleSelectTypeField
  implements IEmployeeInformationSingleSelectTypeField
{
  static readonly type = "SINGLE_SELECT" as const;
  type = EmployeeInformationSingleSelectTypeField.type;
  static validator = employeeInformationSingleSelectTypeFieldSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeInformationGroupId: string;
  readonly label: string;
  readonly order: number;
  readonly sampleValue: string;
  readonly isCustom: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<EmployeeInformationSingleSelectTypeField>, "type">) {
    EmployeeInformationSingleSelectTypeField.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeInformationGroupId = init.employeeInformationGroupId;
    this.label = init.label;
    this.order = init.order;
    this.sampleValue = init.sampleValue;
    this.isCustom = init.isCustom;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationSingleSelectTypeField>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): EmployeeInformationSingleSelectTypeField {
    return new EmployeeInformationSingleSelectTypeField({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
