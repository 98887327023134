import { OnnTask } from "@onn/common";
import { useMemo } from "react";

export const filterOptionStatus = {
  ALL: "ALL",
  EXPIRED: "EXPIRED",
} as const;

export type FilterOptionStatus = (typeof filterOptionStatus)[keyof typeof filterOptionStatus];

const filterByFilterOptionStatus = (status: FilterOptionStatus, onnTasks: OnnTask[]) => {
  switch (status) {
    case "ALL":
      return onnTasks;
    case "EXPIRED":
      return onnTasks.filter((task) => task.isExceededDeadlineDate());
  }
};

export const useFilteredOnnTasks = (
  selectedAssigneeIds: string[],
  queryString: string | undefined,
  selectedFilterOptionStatus: FilterOptionStatus,
  onnTasks: OnnTask[]
) => {
  const filteredOnnTasks = useMemo(() => {
    if (
      selectedAssigneeIds.length === 0 &&
      queryString === undefined &&
      selectedFilterOptionStatus === "ALL"
    ) {
      return onnTasks;
    }

    return filterByFilterOptionStatus(selectedFilterOptionStatus, onnTasks).filter(
      (task) =>
        (selectedAssigneeIds.length === 0 || selectedAssigneeIds.includes(task.assigneeId)) &&
        (queryString === undefined || task.title.includes(queryString))
    );
  }, [selectedAssigneeIds, queryString, selectedFilterOptionStatus, onnTasks]);

  return { filteredOnnTasks };
};
