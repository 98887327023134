import { z } from "zod";

/////////////////////////////////////////
// CHANGE RECRUITMENT STATUS ACTION SETTING SCHEMA
/////////////////////////////////////////

export const ChangeRecruitmentStatusActionSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  actionId: z.string(),
  recruitmentStatusId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ChangeRecruitmentStatusActionSetting = z.infer<
  typeof ChangeRecruitmentStatusActionSettingSchema
>;

/////////////////////////////////////////
// CHANGE RECRUITMENT STATUS ACTION SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ChangeRecruitmentStatusActionSettingOptionalDefaultsSchema =
  ChangeRecruitmentStatusActionSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type ChangeRecruitmentStatusActionSettingOptionalDefaults = z.infer<
  typeof ChangeRecruitmentStatusActionSettingOptionalDefaultsSchema
>;

export default ChangeRecruitmentStatusActionSettingSchema;
