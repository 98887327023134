import { Box } from "@mui/material";
import { EmployeeInformationGroupWithFieldAndOptions } from "@onn/common";
import React, { FC } from "react";

import { CustomInformationSettings } from "./components/CustomInformationSettings";
import { DefaultInformationSettings } from "./components/DefaultInformationSettings";

import { Paper } from "~/components/uiParts";

export const InformationSettingTables: FC<{
  groups: EmployeeInformationGroupWithFieldAndOptions[];
  isLoadingEmployeeInformationGroups: boolean;
}> = ({ groups, isLoadingEmployeeInformationGroups }) => {
  return (
    <Box>
      <Paper style={{ borderRadius: 10 }}>
        <Box
          display="flex"
          flexDirection="column"
          style={{
            rowGap: "16px",
          }}
        >
          <DefaultInformationSettings />
          <CustomInformationSettings
            groups={groups}
            isLoadingEmployeeInformationGroups={isLoadingEmployeeInformationGroups}
          />
        </Box>
      </Paper>
    </Box>
  );
};
