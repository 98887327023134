import { OnnTaskQuestion } from "@onn/common";
import { isEmpty } from "lodash";

import { AnswerForState, State } from "../reducer";

export const useValidateInput = () => {
  const isValidForRequirement = (question: OnnTaskQuestion, answers: AnswerForState[]) => {
    if (!question.isRequired) return true;
    const answer = answers.find((answer) => answer.questionId === question.id);
    if (!answer) {
      return false;
    }
    if (answer.type === "TEXT") {
      if (answer.value === "") {
        return false;
      }
    }
    if (answer.type === "FILE") {
      if (answer.filePath === "") {
        return false;
      }
    }
    if (answer.type === "CHECK_BOX") {
      if (isEmpty(answer.selectedOptionIds)) {
        return false;
      }
    }
    if (answer.type === "RADIO") {
      if (answer.selectedOptionId === "") {
        return false;
      }
    }
    return true;
  };
  const isValidForLength = (question: OnnTaskQuestion, answers: AnswerForState[]) => {
    if (question.type !== "TEXT") return true;
    if (!question.isWordLimit || typeof question.wordLimit === "undefined") return true;

    const answer = answers.find((answer) => answer.questionId === question.id);
    if (!answer) return true;
    if (answer.type !== "TEXT") return true;

    return answer.value.length <= question.wordLimit;
  };

  /**
   * 回答のバリデーション
   * @returns 回答が全て正しい場合はtrue
   */

  const validateInput = (state: State, questions: OnnTaskQuestion[]) => {
    const { answers } = state;

    return questions.every(
      (question) => isValidForRequirement(question, answers) && isValidForLength(question, answers)
    );
  };

  return {
    validateInput,
  };
};
