import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE PREDICTION RELATION SCHEMA
/////////////////////////////////////////

export const EmployeePredictionRelationSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  employeePredictionId: z.string(),
  employeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeePredictionRelation = z.infer<typeof EmployeePredictionRelationSchema>;

/////////////////////////////////////////
// EMPLOYEE PREDICTION RELATION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeePredictionRelationOptionalDefaultsSchema =
  EmployeePredictionRelationSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeePredictionRelationOptionalDefaults = z.infer<
  typeof EmployeePredictionRelationOptionalDefaultsSchema
>;

export default EmployeePredictionRelationSchema;
