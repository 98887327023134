import { OnnEventPlace, APISchema } from "@onn/common";
import { useCallback } from "react";

import { useMutateOnnEventPlaces } from "./useOnnEventPlaces";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/onn_event_place_api/onn-event-place"]["POST"];

export const useCreateOnnEventPlace = () => {
  const { mutateOnnEventPlaces } = useMutateOnnEventPlaces();
  const createOnnEventPlace = useCallback(
    async (body: EndPoint["body"]) => {
      const response = await apiClient.post(`/onn_event_place_api/onn-event-place`, body);
      mutateOnnEventPlaces();
      return new OnnEventPlace(response.data);
    },
    [mutateOnnEventPlaces]
  );

  return { createOnnEventPlace };
};
