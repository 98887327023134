import { Box } from "@material-ui/core";
import { ContactMessageTemplateFolder } from "@onn/common";
import React, { useMemo, FC } from "react";

import styled from "styled-components";

import { Button, Modal, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  contactMessageTemplateFolder: ContactMessageTemplateFolder;
  onSubmit: (template: ContactMessageTemplateFolder) => void;
};

export const DeleteContactMessageTemplateFolderModal: FC<Props> = ({
  open,
  onCancel,
  contactMessageTemplateFolder,
  onSubmit,
}) => {
  const content = useMemo(
    () => (
      <Box marginTop="16px">
        <Box marginBottom="32px">
          <StyledTypography variant="body1">
            「{contactMessageTemplateFolder.name}」を削除しますか？
          </StyledTypography>{" "}
          <StyledTypography variant="body1">
            フォルダに含まれるテンプレートは削除されません。
          </StyledTypography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="caption" color="textSecondary">
            この操作は取り消すことができません。
          </Typography>
        </Box>
      </Box>
    ),
    [contactMessageTemplateFolder]
  );

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="secondary"
          onClick={() => {
            onSubmit(contactMessageTemplateFolder);
            onCancel();
          }}
        >
          削除
        </Button>
      </StyledButtonContainer>
    ),
    [onCancel, onSubmit, contactMessageTemplateFolder]
  );

  return (
    <Modal
      title={`フォルダ削除`}
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    text-align: center;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
