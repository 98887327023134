export const columnTypes = ["type", "title", "status", "relatedFiles", "answeredAt"] as const;
// key の順序をテーブル表示に利用する

export type ColumnType = (typeof columnTypes)[number];
export type BodyRowData = {
  type: "イベント" | "タスク";
  title: {
    text: string;
    linkPath?: string;
  };
  status?: string;
  relatedFiles: string[];
  answeredAt?: Date;
};

export const labelMap: Record<ColumnType, string> = {
  type: "タイプ",
  title: "タイトル",
  status: "ステータス",
  relatedFiles: "関連ファイル",
  answeredAt: "回答日時",
};

export const widthPxMap: Record<ColumnType, number> = {
  type: 80,
  title: 200,
  status: 120,
  relatedFiles: 200,
  answeredAt: 120,
};

export const EMPTY_TEXT_AREA_HEIGHT = 200;

export const HEADER_CELL_HEIGHT_PX = 85;
export const BODY_CELL_HEIGHT_PX = 72;
