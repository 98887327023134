import { z } from "zod";

import { emailType, lineType } from "../Employee/AuthenticationType";

/**
 * 候補者が メール => LINE の切り替えの際に使用するドメイン
 * ※ゆくゆくは LINE => メールも対応する
 */
export const changeAuthenticationTypeLinkSchema = z.object({
  id: z.string(),
  employeeId: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  fromType: z.literal(emailType),
  toType: z.literal(lineType),
  usedAt: z.date().optional(), // 候補者がこのリンクを使って認証を切り替えた日時
  expiredAt: z.date(), // このリンクの有効期限
  createdAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChangeAuthenticationTypeLinkSchema
  extends z.infer<typeof changeAuthenticationTypeLinkSchema> {}
