import { Box } from "@material-ui/core";
import React, { FC } from "react";

import styled from "styled-components";

import {
  TextFieldOfDeliveryAtHour,
  TextFieldOfDeliveryAtHourProps,
} from "./inputs/TextFieldOfDeliveryAtHour";

import { Typography } from "~/components/uiParts";

export const DeliveryAtHourForm: FC<{
  textFieldOfDeliveryAtHourProps: TextFieldOfDeliveryAtHourProps;
}> = ({ textFieldOfDeliveryAtHourProps }) => {
  return (
    <Root>
      <Typography variant="caption" bold>
        配信時間
      </Typography>
      <Box
        display={"flex"}
        style={{
          gap: "8px",
          alignItems: "center",
        }}
      >
        <TextFieldOfDeliveryAtHour {...textFieldOfDeliveryAtHourProps} />
        <Typography variant="body2" color="textSecondary" noWrap>
          時
        </Typography>
      </Box>
    </Root>
  );
};

const Root = styled(Box)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;
