import { RadioQuestion } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { FormControlLabel, RadioButton, Typography } from "~/components/uiParts";

export const RadioQuestionContent: FC<{
  question: RadioQuestion;
  selectedOptionId?: string;
}> = ({ question, selectedOptionId }) => {
  return (
    <>
      {question.options.map((option) => {
        const checked = selectedOptionId === option.id;
        return <CheckboxOptionRow key={option.id} optionLabel={option.text} checked={checked} />;
      })}
    </>
  );
};

export const CheckboxOptionRow: FC<{ optionLabel: string; checked: boolean }> = ({
  optionLabel,
  checked,
}) => {
  return (
    <StyledFormControlLabel
      value={optionLabel}
      control={<StyledRadioButton color="primary" checked={checked} />}
      label={
        <StyledTypography variant="body2" checked={checked}>
          {optionLabel}
        </StyledTypography>
      }
    />
  );
};

const StyledRadioButton = styled(RadioButton)<{ checked: boolean }>`
  padding-left: 0;
  cursor: default;
  .MuiIconButton-colorPrimary {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  &.MuiRadio-root {
    color: ${(props) => !props.checked && props.theme.palette.grey[200]};
  }
`;

const StyledTypography = styled(Typography)<{ checked: boolean }>`
  &.MuiTypography-root {
    overflow: visible;
    color: ${(props) => !props.checked && props.theme.palette.grey[200]};
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
