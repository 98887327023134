import { OnWebsiteNotice } from "@onn/common";
import { FC, useCallback, useEffect } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { mutateScenarioTriggersForDisplay } from "~/hooks/scenario/useScenarioTriggersForDisplay";
import { useSnackbar } from "~/hooks/shared";
import { OnWebsiteNoticeRepository } from "~/infrastructure/api/onWebsiteNoticeRepository";

function refreshPage() {
  window.location.reload();
}

const onWebsiteNoticeRepository = new OnWebsiteNoticeRepository();

const textShouldPromptContactToDevelopers =
  "解決しない場合はOnnサポートチームまでお問い合わせください。";

export const OnWebsiteNoticeHandler: FC = () => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleByActionType = useCallback((actionType: OnWebsiteNotice["actionType"]) => {
    if (!actionType) return;

    switch (actionType) {
      case "editScenarioCompleted": {
        mutateScenarioTriggersForDisplay();
        return;
      }
      default: {
        const _exhaustiveCheck: never = actionType;
        return;
      }
    }
  }, []);

  const onWebsiteNoticeCreated = useCallback(
    (onWebsiteNotice: OnWebsiteNotice) => {
      let text = `${onWebsiteNotice.text}`;
      if (onWebsiteNotice.shouldPromptContactToDevelopers) {
        text = text + `\n${textShouldPromptContactToDevelopers}`;
      }
      const onAction = onWebsiteNotice.shouldPromptRefetch ? refreshPage : undefined;
      const actionLabel = onWebsiteNotice.shouldPromptRefetch ? "更新する" : undefined;
      enqueueSnackbar(text, {
        variant: onWebsiteNotice.type,
        onAction,
        actionLabel,
        type: "onWebsiteNotice",
      });

      handleByActionType(onWebsiteNotice.actionType);
    },
    [enqueueSnackbar, handleByActionType]
  );

  useEffect(() => {
    const unsubscribe = onWebsiteNoticeRepository.listenNewNotice({
      tenantId: currentUser.tenantId,
      employeeId: currentUser.id,
      onWebsiteNoticeCreated,
    });
    return () => {
      unsubscribe();
    };
  }, [currentUser, enqueueSnackbar, onWebsiteNoticeCreated]);

  return null;
};
