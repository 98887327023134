import { OnnFormTask, FormRevision } from "@onn/common";
import React, { useCallback, useReducer } from "react";

import { v4 } from "uuid";

import { useValidateInput } from "../../_share_in_create_edit/hooks/useValidateInput";

import { reducer } from "../../_share_in_create_edit/reducer";
import { useCreateInitialState } from "../reducer";

import { useActionFooter } from "./actionFooter/useActionFooter";

export const usePageCore = ({
  onnFormTask,
  latestFormRevision,
  isAlreadySentToSomeone,
}: {
  onnFormTask: OnnFormTask;
  latestFormRevision: FormRevision;
  isAlreadySentToSomeone: boolean;
}) => {
  const onnFormTaskId = onnFormTask.id;
  const { createInitialState } = useCreateInitialState();
  const [state, dispatch] = useReducer(
    reducer,
    createInitialState({
      onnTaskTitle: onnFormTask.title,
      onnTaskDescription: onnFormTask.description,
      questions: latestFormRevision.questions,
      isAllowEditAnswer: onnFormTask.isAllowEditAnswer ?? false,
      isAlreadySentToSomeone,
    })
  );
  const {
    actionFooterHandler,
    isSubmitting,
    buttonsText: actionFooterButtonsText,
  } = useActionFooter({
    state,
    onnFormTaskId,
  });

  const { validateInput } = useValidateInput(onnFormTaskId);

  const isDisableSaveButton = !validateInput({ state }) || isSubmitting;
  const isLoadingSaveButton = isSubmitting;

  const onChangeTaskType = useCallback((_: React.ChangeEvent<{ value: unknown }>) => {
    // 後続対応: todoタスク実装時に実装
  }, []);

  const onChangeIsAllowEditAnswer = useCallback(() => {
    dispatch({
      type: "TOGGLE_IS_ALLOW_ANSWERED_ONCE",
    });
  }, []);

  const onChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const isEmpty = e.target.value === "";
    const isIncludePeriod = e.target.value.includes(".");
    const helperText = isEmpty
      ? "タイトルを入力してください"
      : isIncludePeriod
      ? `"."はタイトルに使用できません`
      : "";
    dispatch({
      type: "CHANGE_ONN_TASK_TITLE",
      payload: {
        onnTaskTitle: e.target.value,
        error: isEmpty || isIncludePeriod,
        helperText,
      },
    });
  }, []);

  const onChangeDescription = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const isEmpty = e.target.value === "";
    const helperText = isEmpty ? "説明を入力してください" : "";
    dispatch({
      type: "CHANGE_ONN_TASK_DESCRIPTION",
      payload: {
        onnTaskDescription: e.target.value,
        error: isEmpty,
        helperText,
      },
    });
  }, []);

  const onClickAddQuestion = useCallback(() => {
    dispatch({
      type: "ADD_QUESTION",
      payload: {
        questionId: v4(),
      },
    });
  }, []);

  return {
    onnFormTaskId,
    actionFooterHandler,
    reducer: { state, dispatch },
    isDisableSaveButton,
    isLoadingSaveButton,
    onnTaskTitleInput: {
      ...state.onnTaskTitleTextField,
      onChangeTitle,
    },
    onnTaskDescriptionInput: {
      ...state.onnTaskDescriptionTextField,
      onChangeDescription,
    },
    onChangeTaskType,
    onChangeIsAllowEditAnswer,
    onClickAddQuestion,
    actionFooterButtonsText,
  };
};
