import { v4 } from "uuid";
import { z } from "zod";

export const REMINDER_TITLE_MAX_LENGTH = 31;

export const reminderSchema = z.object({
  id: z.string().trim().default(v4),
  tenantId: z.string(),
  contactRoomId: z.string(),
  title: z
    .string()
    .trim()
    .min(1, { message: "タイトルが短すぎます" })
    .max(REMINDER_TITLE_MAX_LENGTH, { message: "タイトルが長すぎます" }),
  isCompleted: z.boolean().default(false),
  remindedAt: z.date(),
  createdAt: z.date().default(() => new Date()),
  updatedAt: z.date().default(() => new Date()),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IReminder extends z.infer<typeof reminderSchema> {}
