import { OnnEventSlotDatesForDisplay } from "@onn/common";
import React, { useCallback } from "react";

import { useToggleSelectAll } from "~/hooks/shared/useToggleSelectAll";

export const useSelectedSlots = ({
  onnEventSlotDatesForDisplay,
}: {
  onnEventSlotDatesForDisplay: OnnEventSlotDatesForDisplay[];
}) => {
  const [selectedSlotIds, setSelectedSlotIds] = React.useState<string[]>([]);

  const { allSelectionState } = useToggleSelectAll<{ id: string }>({
    options: onnEventSlotDatesForDisplay.map((slotDate) => ({ id: slotDate.id })),
    selectedOptions: selectedSlotIds.map((id) => ({ id })),
  });
  const onResetSelectedSlotIds = useCallback(() => {
    setSelectedSlotIds([]);
  }, []);

  return { selectedSlotIds, allSelectionState, setSelectedSlotIds, onResetSelectedSlotIds };
};
