import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION TEXT TYPE VALUE SCHEMA
/////////////////////////////////////////

export const EmployeeInformationTextTypeValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  employeeInformationTextTypeFieldId: z.string(),
  value: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationTextTypeValue = z.infer<
  typeof EmployeeInformationTextTypeValueSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION TEXT TYPE VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationTextTypeValueOptionalDefaultsSchema =
  EmployeeInformationTextTypeValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationTextTypeValueOptionalDefaults = z.infer<
  typeof EmployeeInformationTextTypeValueOptionalDefaultsSchema
>;

export default EmployeeInformationTextTypeValueSchema;
