import { zodResolver } from "@hookform/resolvers/zod";
import { ContactMessageTemplateFolder } from "@onn/common";
import { useForm } from "react-hook-form";

import { ContactMessageTemplateFolderFormSchema } from "./ContactMessageTemplateFolderFormSchema";

export const useContactMessageTemplateFolderForm = ({
  previousContactMessageTemplateFolder,
  tenantId,
  spaceId,
  onSubmit,
}: {
  previousContactMessageTemplateFolder?: ContactMessageTemplateFolder;
  tenantId: string;
  spaceId: string;
  onSubmit: (newContactMessageTemplateFolder: ContactMessageTemplateFolder) => void;
}) => {
  const form = useForm<{
    name: string;
  }>({
    defaultValues: { name: previousContactMessageTemplateFolder?.name ?? "" },
    mode: "onChange",
    resolver: zodResolver(ContactMessageTemplateFolderFormSchema),
  });

  return {
    ...form,
    handleSubmit: form.handleSubmit((values) => {
      const folder = previousContactMessageTemplateFolder
        ? previousContactMessageTemplateFolder.update(values)
        : ContactMessageTemplateFolder.createNew({
            tenantId,
            spaceId,
            ...values,
          });
      onSubmit(folder);
    }),
  };
};
