import { useCallback, useRef, useState } from "react";

export const usePaperPopover = () => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);
  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  return { anchorEl, isOpen, closeMenu, openMenu };
};
