import { Box, FormControlLabel } from "@material-ui/core";
import { OnnEventSlotDateWithNumberOfParticipants } from "@onn/common";
import { format } from "date-fns";
import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "../hooks/useForm";

import { RadioButton, Typography } from "~/components/uiParts";
import { Tag } from "~/components/uiParts/Tag";

type Props = {
  selectableSlotHours: OnnEventSlotDateWithNumberOfParticipants[];
};

export const SlotHourForm: FC<Props> = ({ selectableSlotHours }) => {
  const { control } = useFormContext<InputState>();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "onnEventSlotDateId" });

  const isError = error !== undefined;

  return (
    <Box display="flex" flexDirection="column" gridRowGap={24}>
      <Box display="flex" alignItems="center" gridColumnGap={8}>
        <Typography variant="body1" color="textPrimary" bold>
          時間を選択
        </Typography>
        <Tag color="secondary" size="sm" text="必須" />
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        {selectableSlotHours.map(({ onnEventSlotDate }) => (
          <StyledFormControlLabel
            key={onnEventSlotDate.id}
            value={onnEventSlotDate.id}
            control={
              <StyledRadioButton
                color={"primary"}
                checked={value === onnEventSlotDate.id}
                onChange={onChange}
              />
            }
            label={
              <Typography variant="body2">
                {`${format(onnEventSlotDate.from, "H:mm")}〜${format(
                  onnEventSlotDate.until,
                  "H:mm"
                )}`}
              </Typography>
            }
          />
        ))}
      </Box>
      {isError && (
        <Typography variant="caption" color="secondary">
          {error.message}
        </Typography>
      )}
    </Box>
  );
};

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  margin-right: 8px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiButtonBase-root {
    padding: 2px 0px;
    margin-right: 8px;
  }
`;
