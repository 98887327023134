import React, { useContext, type ReactNode, useCallback, useEffect } from "react";

import { useLocation, useNavigate, useMatch } from "react-router-dom";

import { LoadingForInitialRendering } from "../shared";

import { AuthenticationContext } from "./AuthenticationProvider";

import { getDecodedLiffState } from "~/util";

type Props = {
  children: ReactNode;
};

const useRedirectToSigninIfNoLiffState = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAttendancePage = useMatch("/portal/events/:id/attendance");

  const redirectToSigninIfNoLiffState = useCallback(() => {
    const liffState = getDecodedLiffState();

    // liffState があるならリダイレクトしない
    if (liffState) {
      return;
    }

    const redirectPath = isAttendancePage ? "/portal/accounts/registration" : "/signin";
    // 非認証時のリダイレクト先がログインページではないページが増えるごとに、処理をここに追加していく必要がある
    // portalドメインの場合、signinからさらにポータル用ログインページへリダイレクトするようになっている
    const state = isAttendancePage ? { notice: "参加登録にはアカウント登録が必要です" } : undefined;

    const toUrl = new URL(
      `${redirectPath}?dest-path=${location.pathname}`,
      "http://dummy.example.com"
    );
    const currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.forEach((value, key) => {
      toUrl.searchParams.append(key, value);
    });

    navigate(`${toUrl.pathname}${toUrl.search}`, { state });
  }, [isAttendancePage, location.pathname, location.search, navigate]);

  return { redirectToSigninIfNoLiffState };
};

export const AuthenticationGuard = ({ children }: Props) => {
  const { authUser, guardAuthUser, isReady } = useContext(AuthenticationContext);

  const { redirectToSigninIfNoLiffState } = useRedirectToSigninIfNoLiffState();

  useEffect(() => {
    if (!isReady) {
      return;
    }

    if (!guardAuthUser(authUser)) {
      redirectToSigninIfNoLiffState();
    }
  }, [authUser, guardAuthUser, redirectToSigninIfNoLiffState, isReady]);

  if (!guardAuthUser(authUser)) {
    return <LoadingForInitialRendering enableCaptureException />;
  }

  return <>{children}</>;
};
