import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Condition } from "../../type";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";
import { FormControlLabel, RadioButton, Typography } from "~/components/uiParts";

/**
 * 選考ステータス選択タブ
 * - [NOTE] 将来的には、シナリオもここで選択する予定
 */
export const RecruitmentStatusTabPanel = ({
  selectedCondition,
  changeSelectedRecruitmentStatusId,
}: {
  selectedCondition: Condition;
  changeSelectedRecruitmentStatusId: (recruitmentStatusId: string) => void;
}) => {
  const { recruitmentStatuses } = useRecruitmentStatusList();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeSelectedRecruitmentStatusId(e.target.value);
  };
  const selectableRecruitmentStatuses = recruitmentStatuses.filter((recruitmentStatus) =>
    recruitmentStatus.isSelectableWhenCreateNewGraduate()
  );

  return (
    <StyledBox py={"24px"} height={"300px"}>
      {selectableRecruitmentStatuses.map((recruitmentStatus) => {
        const isSelected = selectedCondition.recruitmentStatusId === recruitmentStatus.id;
        return (
          <Box key={recruitmentStatus.id}>
            <StyledFormControlLabel
              key={recruitmentStatus.id}
              value={recruitmentStatus.id}
              control={
                <StyledRadioButton
                  color="primary"
                  defaultColor={isSelected ? "primary" : "default"}
                  checked={isSelected}
                  onChange={onChange}
                />
              }
              label={<Typography variant="body2">{recruitmentStatus.label}</Typography>}
            />
          </Box>
        );
      })}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  overflow-y: scroll;
`;

const StyledRadioButton = styled(RadioButton)`
  padding-left: 0;
  padding-right: 0;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
    height: 24px;
    margin-bottom: 16px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
