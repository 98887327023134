import { Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { FC } from "react";
import { Control, useFieldArray, useController, UseFormTrigger } from "react-hook-form";
import styled from "styled-components";

import { useIsCandidateDateDuplicate } from "../useIsCandidateDateDuplicate";

import { InputState } from "./InputState";
import { useCandidateDateRHFRow } from "./useCandidateDateRHFRow";

import {
  Typography,
  DatePickerV2,
  SelectForm,
  Button,
  Icon,
  Checkbox,
  Tooltip,
  FormControlLabel,
} from "~/components/uiParts";

type Props = {
  trigger: UseFormTrigger<InputState>;
  control: Control<InputState>;
  isEdit?: boolean;
  displayDeliveryFromTimeItems: {
    value: number;
    name: string;
  }[];
  displayDeliveryUntilTimeItems: {
    value: number;
    name: string;
  }[];
};

export const CandidateDateRHF: FC<Props> = ({
  trigger,
  control,
  isEdit = false,
  displayDeliveryFromTimeItems,
  displayDeliveryUntilTimeItems,
}) => {
  const { isCandidateDateDuplicate } = useIsCandidateDateDuplicate({ control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "candidateDates",
  });

  const { field: capacity } = useController({ control, name: "hasCapacity" });

  const onClickAddCandidateDate = () => {
    append({
      candidateDateId: "",
      from: null,
      until: new Date(),
      fromTime: 10,
      untilTime: 11,
      capacity: "10",
    });
  };

  const onClickDeleteCandidateDate = (i: number) => {
    remove(i);
  };

  const onChangeCapacityCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    capacity.onChange(event, checked);
    // NOTE: hasCapacityの状態と候補日時の定員は紐付くため、ここでtriggerを実行する
    fields.forEach((_, index) => {
      trigger(`candidateDates.${index}.capacity`);
    });
  };

  return (
    <Box>
      <Box height="32px">
        <Typography variant="body2" bold display="inline">
          候補日程
        </Typography>
      </Box>
      <Box height="24px" marginBottom="16px" display="flex" alignItems="center">
        <FormControlLabel
          control={<Checkbox onChange={onChangeCapacityCheckBox} checked={capacity.value} />}
          label={
            <Typography variant="caption" display="inline">
              日時ごとに定員を設け、定員上限まで自動で日程確定処理を行う
            </Typography>
          }
        />
        <Tooltip
          title="定員を設定する場合、定員上限まで先着順で参加日程が確定し自動で参加確定通知が回答者に送信されます。また、定員に達するとそれ以降の回答者は該当の日程を選択できなくなります。"
          placement="top-start"
        >
          <Icon icon="help" size="ssm" color="grey" />
        </Tooltip>
      </Box>
      {isCandidateDateDuplicate && (
        <StyledErrorBox height="46px" display="flex" alignItems="center" pl="16px">
          <Typography variant="body2" color="secondary">
            重複した日程が設定されています。
          </Typography>
        </StyledErrorBox>
      )}

      {fields.map((item, index) => {
        return (
          <CandidateDateRHFRow
            isCandidateDateDuplicate={isCandidateDateDuplicate}
            candidateDateId={item.candidateDateId}
            isEdit={isEdit}
            key={item.id}
            index={index}
            control={control}
            trigger={trigger}
            displayDeliveryFromTimeItems={displayDeliveryFromTimeItems}
            displayDeliveryUntilTimeItems={displayDeliveryUntilTimeItems}
            onClickDeleteCandidateDate={onClickDeleteCandidateDate}
          />
        );
      })}
      <Box>
        <Button
          variant="text"
          color="primary"
          borderRadius="regular"
          onClick={onClickAddCandidateDate}
          startIcon={<Icon icon="add" color="primary" size="md" />}
        >
          候補日程を追加
        </Button>
      </Box>
    </Box>
  );
};

const StyledErrorBox = styled(Box)`
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.secondary.light};
  margin-bottom: 16px;
`;

type CandidateDateRHFRowProps = {
  candidateDateId: string;
  isEdit?: boolean;
  index: number;
  control: Control<InputState>;
  trigger: UseFormTrigger<InputState>;
  displayDeliveryFromTimeItems: {
    value: number;
    name: string;
  }[];
  displayDeliveryUntilTimeItems: {
    value: number;
    name: string;
  }[];
  onClickDeleteCandidateDate: (index: number) => void;
  isCandidateDateDuplicate: boolean;
};

const CandidateDateRHFRow = ({
  candidateDateId,
  isEdit,
  index,
  control,
  trigger,
  displayDeliveryFromTimeItems,
  displayDeliveryUntilTimeItems,
  onClickDeleteCandidateDate,
  isCandidateDateDuplicate,
}: CandidateDateRHFRowProps) => {
  const {
    disabled,
    hasCapacity,
    canDeleteForm,
    candidateDateDP,
    candidateDateTimeSelector,
    candidateDateUntilSelector,
    capacityAutoComplete,
  } = useCandidateDateRHFRow({
    candidateDateId,
    isEdit,
    index,
    control,
    trigger,
    isCandidateDateDuplicate,
  });

  return (
    <React.Fragment key={index}>
      <Box display="flex" minHeight="50px" mb="16px">
        <Box mr="16px" width="200px">
          <StyledDatePicker
            // 1stリリースでは、編集できない
            disabled={disabled}
            fullWidth
            placeholder="日程を選択"
            value={candidateDateDP.value}
            onChange={candidateDateDP.onChange}
            disablePast={true}
            error={candidateDateDP.error}
            helperText={candidateDateDP.helperText}
          />
        </Box>
        <Box mr="8px">
          <StyledSelectForm
            disabled={disabled}
            selected={candidateDateTimeSelector.selected}
            onChange={candidateDateTimeSelector.onChange}
            menuItems={displayDeliveryFromTimeItems}
            icon="clock"
            errorBorder={candidateDateTimeSelector.errorBorder}
          />
        </Box>
        <Box mr="8px" mt="12px">
          <Typography color="textSecondary">〜</Typography>
        </Box>
        <Box mr="16px">
          <StyledSelectForm
            disabled={disabled}
            selected={candidateDateUntilSelector.selected}
            menuItems={displayDeliveryUntilTimeItems}
            onChange={candidateDateUntilSelector.onChange}
            icon="clock"
            errorBorder={candidateDateUntilSelector.errorBorder}
          />
        </Box>
        {hasCapacity && (
          <Box width="160px" mr="16px">
            <StyledAutocomplete
              freeSolo
              disableClearable
              options={capacityAutoComplete.options}
              onChange={(_, value) => capacityAutoComplete.onChange(value)}
              value={String(capacityAutoComplete.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => {
                    capacityAutoComplete.onChange(e.target.value);
                  }}
                  label="定員"
                  value={String(capacityAutoComplete.value)}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <Typography color="textSecondary">人</Typography>,
                  }}
                  helperText={capacityAutoComplete.helperText}
                  error={capacityAutoComplete.error}
                />
              )}
            />
          </Box>
        )}

        {/* NOTO: 初回リリースでは設定済みの候補日には削除ボタンはつけない */}
        {canDeleteForm && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box>
              <StyledBox onClick={() => onClickDeleteCandidateDate(index)}>
                <Icon icon="close" size="sm" color="grey" />
              </StyledBox>
            </Box>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

const StyledBox = styled(Box)`
  cursor: pointer;
`;

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 228px;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    height: 50px;
  }
`;

const StyledAutocomplete = styled(Autocomplete<string, undefined, true, true>)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6.5px 14px;
  }
  .MuiFormHelperText-root {
    width: 250px;
  }
`;
