import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import styled from "styled-components";

import { InputState } from "../../../_shared/CreateOrEditBriefingSessionEvent/useForm";

import {
  Typography,
  Icon,
  Checkbox,
  Tooltip,
  FormControlLabel,
  TextFieldDeprecated,
} from "~/components/uiParts";

export const RestrictAnswerForm: FC = () => {
  const { watch } = useFormContext<InputState>();
  const isRestrictedDaysAgoWhenAnswer = watch("isRestrictedDaysAgoWhenAnswer");
  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Typography variant="body2" bold display="inline">
        回答／編集制限
      </Typography>
      <IsRestrictedAnswerFromNewGraduateInput />
      <IsRestrictedEditAnswerFromNewGraduateInput />
      <IsRestrictedDaysAgoWhenAnswerInput />
      {isRestrictedDaysAgoWhenAnswer && <DaysAgoRestrictAnswerInput />}
    </Box>
  );
};

const IsRestrictedAnswerFromNewGraduateInput: FC = () => {
  const { control } = useFormContext<InputState>();

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px">
      <Box height="24px" display="flex" alignItems="center">
        <Controller
          name="isRestrictedAnswerFromNewGraduate"
          control={control}
          render={({ field }) => (
            <StyledFormControlLabel
              {...field}
              control={<Checkbox defaultChecked={field.value} />}
              label={
                <Typography variant="body2" display="inline">
                  候補者の回答を停止する
                </Typography>
              }
            />
          )}
        />
        <Tooltip
          title="有効にする場合管理者の代理回答のみ有効となり、回答依頼の通知は候補者に届きません。"
          placement="top-start"
        >
          <Icon icon="help" size="ssm" color="grey" />
        </Tooltip>
      </Box>
    </Box>
  );
};

const IsRestrictedEditAnswerFromNewGraduateInput: FC = () => {
  const { control, watch } = useFormContext<InputState>();
  const isRestrictedAnswerFromNewGraduate = watch("isRestrictedAnswerFromNewGraduate");

  return (
    <Box display="flex" flexDirection="column" gridRowGap="8px">
      <Box height="24px" display="flex" alignItems="center">
        <Controller
          name="isRestrictedEditAnswerFromNewGraduate"
          control={control}
          render={({ field }) => (
            <StyledFormControlLabel
              {...field}
              control={<Checkbox defaultChecked={field.value} />}
              // 初回の回答を制限する場合はそもそも候補者からの回答を制限することになるため
              disabled={isRestrictedAnswerFromNewGraduate}
              label={
                <Typography variant="body2" display="inline">
                  候補者の編集を停止する
                </Typography>
              }
            />
          )}
        />
        <Tooltip
          title="有効にする場合管理者による回答の更新のみ有効となり、候補者は一度した回答を編集できません。"
          placement="top-start"
        >
          <Icon icon="help" size="ssm" color="grey" />
        </Tooltip>
      </Box>
    </Box>
  );
};

const IsRestrictedDaysAgoWhenAnswerInput: FC = () => {
  const { control, watch, trigger } = useFormContext<InputState>();
  const isRestrictedAnswerFromNewGraduate = watch("isRestrictedAnswerFromNewGraduate");

  return (
    <Box display="flex" flexDirection="column" gridRowGap="8px">
      <Box height="24px" display="flex" alignItems="center">
        <Controller
          name="isRestrictedDaysAgoWhenAnswer"
          control={control}
          render={({ field }) => (
            <StyledFormControlLabel
              {...field}
              onChange={(e) => {
                field.onChange(e);
                // NOTE: 初回にエラーを表示させないため
                if (field.value) {
                  trigger();
                }
              }}
              control={<Checkbox defaultChecked={field.value} />}
              // 初回の回答を制限する場合はそもそも候補者からの回答を制限することになるため
              disabled={isRestrictedAnswerFromNewGraduate}
              label={
                <Typography variant="body2" display="inline">
                  回答／編集に制限をかける
                </Typography>
              }
            />
          )}
        />
        <Tooltip
          title="候補者が回答／編集を行える期間を制限することができます。制限を加えると、未回答の候補者には該当の日程が非表示になり、回答済みの候補者には編集不可のメッセージが表示されます。"
          placement="top-start"
        >
          <Icon icon="help" size="ssm" color="grey" />
        </Tooltip>
      </Box>
    </Box>
  );
};

const DaysAgoRestrictAnswerInput: FC = () => {
  const { control } = useFormContext<InputState>();
  return (
    <Box paddingLeft="28px" display="flex" alignItems="top" gridColumnGap="8px">
      <Typography variant="body2" color="textSecondary" style={{ lineHeight: "40px" }}>
        日程の
      </Typography>
      <Controller
        name="daysAgoRestrictAnswer"
        control={control}
        render={({ field, fieldState }) => (
          <TextFieldDeprecated
            {...field}
            type="number"
            size="small"
            placeholder="1"
            variant="outlined"
            name={field.name}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <Typography variant="body2" color="textSecondary" style={{ lineHeight: "40px" }}>
        日前から回答／編集が不可
      </Typography>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 4px;
  }
  .MuiCheckbox-root {
    margin-left: 9px;
    margin-right: 8px;
    padding: 0;
  }
  .MuiTypography-root {
    line-height: 1;
  }
`;
