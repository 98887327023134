import { Box } from "@mui/material";
import { Question } from "@onn/common/domain/OnnTask/Question/Question";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

import { FileAttachedButtonForStorage } from "~/components/uiParts/FileAttachedButton";

export const FileQuestionAndAnswer: FC<{
  question: Question;
  filePath: string | null;
}> = ({ filePath }) => {
  return (
    <Box width={"400px"}>
      {filePath ? (
        <FileAttachedButtonForStorage
          filePath={filePath}
          renderCustomButton={({ fileName, onClick }) => (
            <StyledAnchor onClick={onClick} rel="noreferrer">
              {fileName}
            </StyledAnchor>
          )}
          LoadingProps={{
            size: "small",
          }}
        />
      ) : (
        <Typography>未回答</Typography>
      )}
    </Box>
  );
};

const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  text-decoration: underline;
`;
