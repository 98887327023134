import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Icon, Typography } from "~/components/uiParts";

export const ConductorPaperToAddCondition: FC<{
  isAddedCondition: boolean;
  onClickToAddCondition: () => void;
}> = ({ isAddedCondition, onClickToAddCondition }) => {
  return (
    <StyledBox isAddedCondition={isAddedCondition}>
      <Box height={"32px"}>
        <StyledTypography
          variant="caption"
          color="textSecondary"
          style={{ whiteSpace: "pre-wrap" }}
          bold
        >
          {isAddedCondition
            ? "招待QR・URLに条件が付与されています"
            : "招待QR・URLに条件付与を行う場合はこちらから条件を選択してください"}
        </StyledTypography>
      </Box>
      <Box height={"32px"}>
        <Button
          borderRadius="regular"
          variant="outlined"
          color="primary"
          fullHeight
          startIcon={<Icon icon={"setting"} size="ssm" color="primary" />}
          onClick={onClickToAddCondition}
        >
          条件付与
        </Button>
      </Box>
    </StyledBox>
  );
};

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const StyledBox = styled(Box)<{ isAddedCondition: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) =>
    props.isAddedCondition ? props.theme.palette.primary.light : props.theme.palette.grey[50]};
  border-radius: 4px;
  white-space: pre-wrap;
  word-break: break-all;
  padding: 16px 24px;
`;
