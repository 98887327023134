import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { usePageContext } from "../pageContext";

import { RecruitmentStatusButton } from "./components/RecruitmentStatusButton";

import { IconButton, Typography, UncontrolledMenu } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useRecruitmentStatusesWithRelations } from "~/hooks/recruitmentStatusWithRelation";

export const LeftSide: FC = () => {
  return (
    <Root>
      <ScenarioNameArea />
      <RecruitmentStatusesArea />
    </Root>
  );
};

const ScenarioNameArea: FC = () => {
  const { scenario } = usePageContext();
  return (
    <Box
      paddingY={"8px"}
      style={{
        borderBottom: "1px solid #E0E0E0",
      }}
    >
      <Typography bold variant="h4">
        {scenario?.name}
      </Typography>
    </Box>
  );
};

const RecruitmentStatusesArea: FC = () => {
  const { currentUser } = useCurrentUser();
  const { data: recruitmentStatusesWithRelations = [] } = useRecruitmentStatusesWithRelations({
    withDeleted: false,
  });
  const {
    scenarioId,
    selectedRecruitmentStatusId,
    changeSelectedRecruitmentStatusId,
    resetSelectedRecruitmentStatus,
    mode,
    isLocking,
    warningRecruitmentStatuses,
  } = usePageContext();
  const { handleModal } = useModal();

  const recruitmentStatusesRelatedToScenario = recruitmentStatusesWithRelations.filter(
    (recruitmentStatus) => recruitmentStatus.scenarioId === scenarioId
  );

  const generateOnClickHandler = useCallback(
    (recruitmentStatusId: string) => () => {
      changeSelectedRecruitmentStatusId(recruitmentStatusId);
    },
    [changeSelectedRecruitmentStatusId]
  );

  const openEditRecruitmentStatusesModal = useCallback(() => {
    handleModal({
      name: "editRecruitmentStatusesModal",
      args: {
        resetSelectedRecruitmentStatus,
        scenarioId,
        recruitmentStatuses: recruitmentStatusesRelatedToScenario,
      },
    });
  }, [
    handleModal,
    resetSelectedRecruitmentStatus,
    scenarioId,
    recruitmentStatusesRelatedToScenario,
  ]);

  return (
    <Box display="flex" flexDirection="column" gridRowGap="24px">
      <Box height={"40px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography bold variant="body2">
          選考ステータス
        </Typography>
        {/* NOTE: 選考ステータスは閲覧モードでのみ編集ができる */}
        {currentUser.isAdmin() && mode === "view" && !isLocking && (
          <UncontrolledMenu
            renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
            menuItemOptions={[
              {
                onClick: openEditRecruitmentStatusesModal,
                text: "設定",
              },
            ]}
          />
        )}
      </Box>
      {recruitmentStatusesRelatedToScenario.map((recruitmentStatus) => {
        const isSelected = selectedRecruitmentStatusId === recruitmentStatus.id;
        const displayAlert = warningRecruitmentStatuses.some((s) => s.id === recruitmentStatus.id);
        return (
          <RecruitmentStatusButton
            key={recruitmentStatus.id}
            label={recruitmentStatus.label}
            isSelected={isSelected}
            onClick={generateOnClickHandler(recruitmentStatus.id)}
            displayAlert={displayAlert}
          />
        );
      })}
    </Box>
  );
};

const Root = styled(Box)`
  position: relative;
  padding: 40px 24px;
  height: 100vh;
  background-color: ${(props) => props.theme.palette.common.white};
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  overflow-y: scroll;
  /* NOTE: 画面下部に表示されるappBarよりも上に表示するための記述 */
  z-index: ${(props) => props.theme.zIndex.appBar + 1};
`;
