import React from "react";

import { useFormContext, useController } from "react-hook-form";

import { InputState } from "../useForm";

import { OnnEventTitleTextField } from "~/pages/events/_shared/createAndEdit/OnnEventTitleTextField";

export const OnnEventTitleInputForm = () => {
  const { control } = useFormContext<InputState>();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "title" });
  const isError = error !== undefined;
  const helperText = isError ? error.message : "";

  return (
    <OnnEventTitleTextField
      value={value}
      onChange={onChange}
      error={isError}
      helperText={helperText}
    />
  );
};
