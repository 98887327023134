import { OnnTask } from "@onn/common";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

type TabValue = "onnTaskContents" | "answer";
export const usePage = (onnTask: OnnTask) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<TabValue>("onnTaskContents");

  useLayoutEffect(() => {
    switch (location.hash) {
      case "#onnTaskContents": {
        setActiveTab("onnTaskContents");
        break;
      }
      case "#answer": {
        setActiveTab("answer");
        break;
      }
      default: {
        void 0;
      }
    }
  }, [navigate]);

  const tabs: Array<{ label: string; value: string }> = useMemo(
    () => [
      {
        label: "タスク内容",
        value: "onnTaskContents",
      },
      {
        label: "回答ステータス",
        value: "answer",
      },
    ],
    []
  );

  const handleChangeActiveTab = useCallback(
    (_: unknown, newValue: TabValue) => {
      navigate(`#${newValue}`);
      setActiveTab(newValue);
    },
    [navigate]
  );

  return { onnTask, tabs, activeTab, handleChangeActiveTab };
};
