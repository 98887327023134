import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { UserIcon, Button, Divider, Typography } from "~/components/uiParts";

type Props = {
  mentor?: Employee | null;
  followers?: Employee[];
  onlyView?: boolean;
  onClickAddMentorButton?: () => void;
  onClickAddAssignFollowers?: () => void;
  onClickChangeMentorButton?: (mentor: Employee) => void;
  onClickDismissButton?: (follower: Employee) => void;
};
export const NewGraduateMentorAndFollowerList: FC<Props> = ({
  mentor,
  followers = [],
  onlyView,
  onClickAddMentorButton,
  onClickAddAssignFollowers,
  onClickChangeMentorButton,
  onClickDismissButton,
}) => {
  return (
    <Box pt={1}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" bold>
          担当者
        </Typography>
        {!mentor && !onlyView && (
          <StyledTypography variant="body2" bold color="primary" onClick={onClickAddMentorButton}>
            + 担当者を追加
          </StyledTypography>
        )}
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <UserIcon
          username={mentor ? mentor.getName() : "担当者未設定"}
          profileIconImageUrl={mentor?.profileIconImageUrl}
          size="small"
          circular
          borderColor="primary"
        />
        {mentor ? (
          <>
            <Box display="inline-block" ml={2}>
              <Typography variant="body1" bold>
                {mentor.getName()}
              </Typography>
              <Typography variant="caption">{mentor?.email}</Typography>
            </Box>
            {!onlyView && (
              <Box ml="auto">
                <Button
                  variant="outlined"
                  borderRadius="regular"
                  color="default"
                  onClick={() => onClickChangeMentorButton?.(mentor)}
                >
                  変更
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box ml={1}>
            <Typography variant="caption">未設定</Typography>
          </Box>
        )}
      </Box>
      <Box mt={4} mb={4}>
        <Divider />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2" bold>
          フォロワー
        </Typography>
        {!onlyView && (
          <StyledTypography
            variant="body2"
            bold
            color="primary"
            onClick={onClickAddAssignFollowers}
          >
            + フォロワーを追加
          </StyledTypography>
        )}
      </Box>
      {followers.length === 0 && (
        <Box mt={2} display="flex" alignItems="center">
          <UserIcon username="担当者未設定" size="small" circular borderColor="blue" />
          <Box ml={1}>
            <Typography variant="caption">未設定</Typography>
          </Box>
        </Box>
      )}
      {followers.map((follower) => (
        <Box mt={2} display="flex" key={follower.id}>
          <UserIcon
            username={follower.getName()}
            profileIconImageUrl={follower.profileIconImageUrl}
            size="small"
            circular
            borderColor="blue"
          />
          <Box display="inline-block" ml={2}>
            <Typography variant="body1" bold>
              {follower.getName()}
            </Typography>
            <Typography variant="caption">{follower.email}</Typography>
          </Box>
          {!onlyView && (
            <Box ml="auto">
              <Button
                variant="outlined"
                borderRadius="regular"
                color="default"
                onClick={onClickDismissButton ? () => onClickDismissButton(follower) : undefined}
              >
                解除
              </Button>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;
