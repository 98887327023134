import { isPast, isValid } from "date-fns";
import { useCallback } from "react";

export const useScheduledDateInput = ({
  deadlineDate,
  scheduledDate,
  initialValue,
  isFixed,
}: {
  deadlineDate: Date | null;
  scheduledDate: Date | null;
  initialValue?: Date | null;
  isFixed: boolean;
}) => {
  const getHelperText = useCallback(
    (date: Date | null) => {
      if (date == null && deadlineDate) {
        return "配信タイミングを設定してください";
      }

      if (date == null) {
        return "";
      }

      if (!isValid(date)) {
        return "正しいフォーマットで入力してください (例: 2000/01/01)";
      }

      if (isPast(date)) {
        return "過去の日付は入力できません";
      }

      return "";
    },
    [deadlineDate]
  );

  const isChanged = scheduledDate?.getTime() !== initialValue?.getTime();
  const isError = useCallback(
    (date: Date | null) => {
      return !isFixed && !!getHelperText(date);
    },
    [isFixed, getHelperText]
  );

  return {
    helperText: getHelperText(scheduledDate),
    error: isError(scheduledDate),
    isChanged,
  };
};
