import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { DeliveryTimingType, deliveryTimingTypesMap } from "../../types/deliveryTimingTypes";

import { Button, Typography, Icon, UncontrolledMenu } from "~/components/uiParts";
import { MenuItemOption } from "~/components/uiParts/UncontrolledMenu/UncontrolledMenu";

export type SelectorOfDeliveryTimingTypeProps = {
  value?: DeliveryTimingType;
  onClickItem: (value: DeliveryTimingType) => void;
  errorMessage?: string;
};

export const SelectorOfDeliveryTimingType: FC<SelectorOfDeliveryTimingTypeProps> = ({
  value,
  onClickItem,
  errorMessage,
}) => {
  const label = value ? deliveryTimingTypesMap[value] : "---";

  const menuItemOptions: MenuItemOption[] = [
    ...Object.entries(deliveryTimingTypesMap).map(([key, value]) => ({
      text: value,
      onClick: () => onClickItem(key as DeliveryTimingType),
    })),
  ];
  if (value === undefined) {
    menuItemOptions.unshift({
      text: "---",
      onClick: () => void 0,
    });
  }

  return (
    <>
      <UncontrolledMenu
        renderButton={(openMenu) => (
          <Button
            color="default"
            variant="outlined"
            borderRadius="regular"
            fullWidth
            onClick={openMenu}
            endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
          >
            <Box width={"100%"} flex={1} display={"flex"}>
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Box>
          </Button>
        )}
        menuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
        }}
        menuItemOptions={menuItemOptions}
      />
      {errorMessage && (
        <Typography variant="overline" color="error" noWrap>
          {errorMessage}
        </Typography>
      )}
    </>
  );
};
