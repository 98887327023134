import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useMutateOnnEventPlaces } from "./useOnnEventPlaces";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/onn_event_place_api/onn-event-place"]["DELETE"];
export const useDeleteOnnEventPlace = () => {
  const { mutateOnnEventPlaces } = useMutateOnnEventPlaces();

  const deleteOnnEventPlace = useCallback(
    async (body: EndPoint["body"]) => {
      const response = await apiClient.delete(
        `/onn_event_place_api/onn-event-place`,
        undefined,
        body,
        {
          isNotifyError: true,
        }
      );
      mutateOnnEventPlaces();
      return response;
    },
    [mutateOnnEventPlaces]
  );

  return { deleteOnnEventPlace };
};
