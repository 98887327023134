import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts";

export const AbsoluteGripHorizontalBox: FC = () => {
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box sx={{ position: "absolute", width: "100%", top: "-32px" }}>
        <GripHorizontalBox />
      </Box>
    </Box>
  );
};

export const GripHorizontalBox: FC = () => {
  return (
    <StyledBox
      width={"100%"}
      display="flex"
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
      mt={"8px"}
      height={"24px"}
    >
      <Icon icon="gripHorizontal" size="sm" color="lightGrey" id="gripVerticalIcon" />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  position: relative;
  width: 100%;
  padding: 0 40px;
  color: inherit;
`;
