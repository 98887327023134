import { Box } from "@material-ui/core";
import React, { FC } from "react";

import styled from "styled-components";

import {
  SelectorOfDeliveryTimingType,
  SelectorOfDeliveryTimingTypeProps,
} from "./inputs/SelectorOfDeliveryTimingType";
import {
  TextFieldOfDeliveryTimingNumber,
  TextFieldOfDeliveryTimingNumberProps,
} from "./inputs/TextFieldOfDeliveryTimingNumber";

import { Typography } from "~/components/uiParts";

export const DeliveryTimingTypeAndNumberForm: FC<{
  textFieldOfDeliveryTimingNumberProps: TextFieldOfDeliveryTimingNumberProps;
  selectorOfDeliveryTimingTypeProps: SelectorOfDeliveryTimingTypeProps;
}> = ({ textFieldOfDeliveryTimingNumberProps, selectorOfDeliveryTimingTypeProps }) => {
  return (
    <Root>
      <Box width={"132px"}>
        <TextFieldOfDeliveryTimingNumber {...textFieldOfDeliveryTimingNumberProps} />
      </Box>
      <Box width={"132px"}>
        <SelectorOfDeliveryTimingType {...selectorOfDeliveryTimingTypeProps} />
      </Box>
      <Typography variant="body2" color="textSecondary" noWrap>
        後に配信
      </Typography>
    </Root>
  );
};

const Root = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
