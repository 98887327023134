import { useCallback, useState } from "react";
import { v4 } from "uuid";

import { useSnackbar } from "~/hooks/shared";
import { getNameAndExtensionFromFilePath } from "~/util";

type InputIconType = {
  dataUrl?: string;
  path?: string;
};

/**
 * InputIconのdataUrlとpathがundefinedではないときにtrueを返す
 * @param inputIcon
 */
export const checkInputIconHasValues = (
  inputIcon: InputIconType
): inputIcon is Required<InputIconType> => {
  return typeof inputIcon.dataUrl !== "undefined" && typeof inputIcon.path !== "undefined";
};

/**
 * Iconのinput field関連の状態やメソッド等を返すhooks
 * @param defaultInputIcon inputIconの初期値
 */
export const useInputIcon = (defaultInputIcon: InputIconType) => {
  const [inputIcon, setInputIcon] = useState(defaultInputIcon);
  const handleUploadIcon = useCallback(async (file: File) => {
    const { extension } = getNameAndExtensionFromFilePath(file.name);
    const reader = new FileReader();

    reader.onload = () => {
      // readAsDataURLはstringしか返さないが、resultの型にArrayBufferが含まれるので型チェックする
      if (typeof reader.result === "string") {
        setInputIcon({
          dataUrl: reader.result,
          path: `uploads/profile/${v4()}.${extension}`,
        });
      }
    };
    reader.readAsDataURL(file);
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const handleErrorUploadIcon = () => {
    enqueueSnackbar("写真は120px*120pxから2100px*2100pxの間で設定してください", {
      variant: "error",
    });
  };

  const resetInputIcon = () => {
    setInputIcon(defaultInputIcon);
  };

  const allowImageSize = {
    max: { width: 2100, height: 2100 },
    min: { width: 120, height: 120 },
  };

  return {
    inputIcon,
    handleUploadIcon,
    handleErrorUploadIcon,
    resetInputIcon,
    allowImageSize,
  };
};
