import { escapeDoubleQuotesInCSVRow, escapeDoubleQuotesInCSVRows } from "@onn/common";
import React, { useMemo } from "react";
// eslint-disable-next-line no-restricted-imports
import { CSVLink } from "react-csv";

import { LinkProps } from "react-csv/components/Link";

/**
 * https://www.ietf.org/rfc/rfc4180.txt の仕様に基づいて CSV をエスケープする
 */
export const EscapedCSVLink = ({
  data,
  filename,
  children,
  ...rest
}: // NOTE: CSVLink に ref を渡すと TS エラーになるため渡せないようにしている
Omit<LinkProps, "data" | "ref"> & { data: string | string[][] | (readonly string[])[] }) => {
  const escapedData = useMemo(() => {
    if (typeof data === "string") return escapeDoubleQuotesInCSVRow(data);

    // NOTE: readonly の配列を渡しても強制的にエスケープするため as で型を変換している(値はコピーしていて元データに影響はないので問題ない)
    return data.map((d) => escapeDoubleQuotesInCSVRows(d as string[]));
  }, [data]);

  return (
    <CSVLink {...rest} data={escapedData} filename={filename}>
      {children}
    </CSVLink>
  );
};
