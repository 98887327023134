import { Box } from "@material-ui/core";
import { Reminder } from "@onn/common";
import { format } from "date-fns";

import React, { FC } from "react";
import styled from "styled-components";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  reminder: Reminder;
  completeReminder: (_r: Reminder) => Promise<void>;
};

export const ReminderItem: FC<Props> = ({ reminder, completeReminder }) => {
  return (
    <StyledListBox>
      <StyleBox>
        <Icon icon="clock" size="sm" color={reminder.isExpired() ? "secondary" : "lightGrey"} />
        <Box ml="16px">
          <StyledTypography
            variant="body2"
            color={reminder.isExpired() ? "secondary" : "textSecondary"}
          >
            {reminder.title}
          </StyledTypography>
          <Typography
            variant="caption"
            color={reminder.isExpired() ? "secondary" : "textSecondary"}
          >
            期日: {format(new Date(reminder.remindedAt), "yyyy/MM/dd")}
          </Typography>
        </Box>
      </StyleBox>

      <Box ml="10px">
        <Button
          borderRadius="regular"
          variant="outlined"
          color="primary"
          onClick={() => completeReminder(reminder)}
        >
          完了
        </Button>
      </Box>
    </StyledListBox>
  );
};

const StyledListBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 5px 0;
`;

const StyleBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.3;
  }
`;
