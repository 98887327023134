import { v4 } from "uuid";

import { DeliverOnnTaskActionSetting as IDeliverOnnTaskActionSetting } from "../../_gen/zodSchema/index";

import { deliverOnnTaskActionSettingSchema } from "./schema";

export class DeliverOnnTaskActionSetting implements IDeliverOnnTaskActionSetting {
  static readonly type = "DeliverOnnTaskActionSetting" as const;
  type = DeliverOnnTaskActionSetting.type;

  static readonly validator = deliverOnnTaskActionSettingSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  actionId: string;
  onnTaskId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<DeliverOnnTaskActionSetting>, "type">) {
    const parsedInit = DeliverOnnTaskActionSetting.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.actionId = parsedInit.actionId;
    this.onnTaskId = parsedInit.onnTaskId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public update(params: Pick<ExcludeMethods<DeliverOnnTaskActionSetting>, "onnTaskId">) {
    return new DeliverOnnTaskActionSetting({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }

  static create(
    params: Omit<
      ExcludeMethods<DeliverOnnTaskActionSetting>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new DeliverOnnTaskActionSetting({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
