import { ContactMessageDraft } from "@onn/common";
import { useCallback, useState, MouseEvent, useRef } from "react";

import { generateMessageWithTemplateInserted } from "../generateMessageWithTemplateInserted";

/**
 * コンタクトメッセージテンプレートMenuのUIを操作するための関数を提供するhooks
 */
export const useContactMessageTemplatesMenuHandling = ({
  contactMessageDraft,
  setNewMessage,
  saveContactMessageDraft,
  currentMessage,
}: {
  contactMessageDraft: ContactMessageDraft;
  setNewMessage: (message: string) => void;
  saveContactMessageDraft: (contactMessageDraft: ContactMessageDraft) => void;
  currentMessage: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // メッセージ入力textareaのカーソル位置を取得するためのref
  const textareaAutosizeAttachableFileRef = useRef<HTMLTextAreaElement>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  /**
   * テンプレートをメッセージ入力textarea＆下書きに反映する
   */
  const handleReflectMessage = useCallback(
    (text: string) => {
      const templateAppliedMessage = generateMessageWithTemplateInserted({
        currentMessage,
        templateMessage: text,
        textareaAutosizeAttachableFileRef,
      });

      setNewMessage(templateAppliedMessage);
      saveContactMessageDraft(
        new ContactMessageDraft({
          id: contactMessageDraft.id,
          contactRoomId: contactMessageDraft.contactRoomId,
          createdUserId: contactMessageDraft.createdUserId,
          text: templateAppliedMessage,
          tenantId: contactMessageDraft.tenantId,
        })
      );
    },
    [
      contactMessageDraft.contactRoomId,
      contactMessageDraft.createdUserId,
      contactMessageDraft.id,
      contactMessageDraft.tenantId,
      currentMessage,
      saveContactMessageDraft,
      setNewMessage,
    ]
  );

  return {
    anchorEl,
    handleOpen,
    handleClose,
    handleReflectMessage,
    textareaAutosizeAttachableFileRef,
  };
};
