import { z } from "zod";

import { AnnouncementSchema } from "../../_gen/zodSchema/index";

export const announcementSchema = AnnouncementSchema.merge(
  z.object({
    publicationStartDate: z.date().optional(),
    publicationEndDate: z.date().optional(),
    firstPublishedAt: z.date().optional(),
  })
).superRefine((value, ctx) => {
  if (value.publicationStartDate && !value.publicationEndDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "公開終了日を設定してください",
      path: ["publicationEndDate"],
    });
  }

  if (!value.publicationStartDate && value.publicationEndDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "公開開始日を設定してください",
      path: ["publicationStartDate"],
    });
  }

  if (value.publicationStartDate && value.publicationEndDate) {
    if (value.publicationStartDate > value.publicationEndDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "公開開始日は公開終了日より前に設定してください",
        path: ["publicationStartDate"],
      });
    }
  }
});
