import { ChangeAuthenticationTypeLink } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

export const generateKey = (currentEmployeeId: string) => {
  return {
    endpoint: "/get_valid_change_authentication_type_link",
    currentEmployeeId,
  } as const;
};

export const useChangeAuthenticationTypeLink = () => {
  const { currentUser } = useCurrentUser();
  return useSWR(generateKey(currentUser.id), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);
    return response.changeAuthenticationTypeLink
      ? new ChangeAuthenticationTypeLink(response.changeAuthenticationTypeLink)
      : null;
  });
};

export const useMutateChangeAuthenticationTypeLink = () => {
  const { currentUser } = useCurrentUser();
  const mutateChangeAuthenticationTypeLink = useCallback(
    () => mutate(generateKey(currentUser.id)),
    [currentUser.id]
  );

  return { mutateChangeAuthenticationTypeLink };
};
