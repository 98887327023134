import { v4 } from "uuid";

import { IRegistrationRichMenuRelation, registrationRichMenuRelationSchema } from "./schema";

export class RegistrationRichMenuRelation implements IRegistrationRichMenuRelation {
  static readonly validator = registrationRichMenuRelationSchema;
  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly scenarioId: string;

  readonly internalRichMenuId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(data: ExcludeMethods<RegistrationRichMenuRelation>) {
    const parsedInit = RegistrationRichMenuRelation.validator.parse(data);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.scenarioId = parsedInit.scenarioId;

    this.internalRichMenuId = parsedInit.internalRichMenuId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(
    init: Omit<ExcludeMethods<RegistrationRichMenuRelation>, "id" | "createdAt" | "updatedAt">
  ): RegistrationRichMenuRelation {
    return new RegistrationRichMenuRelation({
      ...init,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(params: Pick<ExcludeMethods<RegistrationRichMenuRelation>, "internalRichMenuId">) {
    return new RegistrationRichMenuRelation({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
