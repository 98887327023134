import React, { useCallback } from "react";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useCreateFilePath } from "~/pages/onn_task/_share_in_create_edit/hooks/useCreateFilePath";
import { Action } from "~/pages/onn_task/_share_in_create_edit/reducer";

export const useFileQuestionForm = (dispatch: React.Dispatch<Action>) => {
  const { createFileQuestionFilePath, createFileQuestionFilePathForPreview } = useCreateFilePath();

  const onChangeFile = useCallback(
    ({
      onnFormTaskId,
      questionId,
      payload,
    }: {
      payload: Payload;
      questionId: string;
      onnFormTaskId: string;
    }) => {
      if (payload.status === "success") {
        dispatch({
          type: "ADD_ATTACHMENT_FILE_TO_FILE_QUESTION",
          payload: {
            questionId: questionId,
            sampleFile: payload.files[0] as (typeof payload.files)[number],
            sampleFilePath: createFileQuestionFilePath({
              questionId: questionId,
              fileName: (payload.files[0] as (typeof payload.files)[number]).name,
              onnFormTaskId,
            }),
            sampleFilePathForPreview: createFileQuestionFilePathForPreview({
              questionId: questionId,
              fileName: (payload.files[0] as (typeof payload.files)[number]).name,
              onnFormTaskId,
            }),
          },
        });
      }
    },
    [createFileQuestionFilePath, createFileQuestionFilePathForPreview, dispatch]
  );

  return { onChangeFile };
};
