import { EmployeePrediction } from "@onn/common";
import React, { FC, createContext, ReactNode, useMemo } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { useEmployeePredictions } from "~/hooks/employeePrediction";

export const EmployeePredictionContext = createContext<{
  employeePredictions: EmployeePrediction[];
  employeePredictionsMap: Map<string, EmployeePrediction>;
}>({
  employeePredictions: [],
  employeePredictionsMap: new Map(),
});

export const EmployeePredictionProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  // ポータルの場合は、ヨミ一覧を使用しないので即時レンダリングを行う
  if (currentUser.assignedAsNewcomer) {
    return <>{children}</>;
  }

  return <EmployeePredictionProviderCore>{children}</EmployeePredictionProviderCore>;
};

export const EmployeePredictionProviderCore: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { data: employeePredictions = [], isLoading } = useEmployeePredictions();

  const employeePredictionsMap: Map<EmployeePrediction["id"], EmployeePrediction> = useMemo(() => {
    return new Map(
      employeePredictions.map((employeePrediction) => [employeePrediction.id, employeePrediction])
    );
  }, [employeePredictions]);

  if (isLoading) return null;

  return (
    <EmployeePredictionContext.Provider value={{ employeePredictions, employeePredictionsMap }}>
      {children}
    </EmployeePredictionContext.Provider>
  );
};
