import React, { FC } from "react";

import { Button } from "~/components/uiParts";

export const CancelSelectionButton: FC<{ onResetSelectedSlotIds: () => void }> = ({
  onResetSelectedSlotIds,
}: {
  onResetSelectedSlotIds: () => void;
}) => {
  return (
    <Button
      onClick={onResetSelectedSlotIds}
      color="default"
      variant="outlined"
      borderRadius="regular"
      fullHeight
    >
      選択解除
    </Button>
  );
};
