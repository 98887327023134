import React, { FC } from "react";

export const Memo: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <rect fill="none" />
      <path
        d="M6.988,19.562h8.331V15.29h4.272V6.959H6.988v12.6Zm0,1.688A1.678,1.678,0,0,1,5.3,19.562V6.959a1.664,1.664,0,0,1,.491-1.207,1.611,1.611,0,0,1,1.2-.5h12.6A1.714,1.714,0,0,1,21.3,6.959v8.908L15.9,21.25Zm2.563-6.6v-1.41H13.1v1.41Zm0-3.546V9.672h7.477V11.1ZM6.988,19.562V6.959h0v12.6Z"
        transform="translate(-1.3 -1.25)"
        stroke="none"
      />
    </svg>
  );
};
