import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC } from "react";

import { Cell } from "../../_share/Cell";

import { Typography } from "~/components/uiParts";

export const ImplementDateCell: FC<{ fromDate: Date; untilDate: Date }> = ({
  fromDate,
  untilDate,
}) => {
  return (
    <Cell>
      <Typography variant="body2" color="textSecondary">
        {format(fromDate, "M/d (E) HH:mm", { locale: ja })}-{format(untilDate, "HH:mm")}
      </Typography>
    </Cell>
  );
};
