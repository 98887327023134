import { Box } from "@material-ui/core";
import React, { ReactNode } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  label: string;
  children: ReactNode;
  captionText?: string;
};

export const Section = ({ label, children, captionText }: Props) => {
  return (
    <Box>
      <Box marginBottom="16px">
        <Typography variant="caption" color="textPrimary" bold>
          {label}
        </Typography>
        {captionText && (
          <Typography variant="caption" color="textSecondary">
            {captionText}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};
