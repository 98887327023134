import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

export const EmployeeDefaultInformationTable: FC<{
  nameCellComponent: React.ReactNode;
  kanaNameCellComponent: React.ReactNode;
  genderCellComponent: React.ReactNode;
  dateOfBirthCellComponent: React.ReactNode;
  emailCellComponent: React.ReactNode;
  phoneNumberCellComponent: React.ReactNode;
  homePhoneNumberCellComponent: React.ReactNode;
  graduationYearAndMonthCellComponent: React.ReactNode;
  affiliationCellComponent: React.ReactNode;
  addressCellComponent: React.ReactNode;
  hometownAddressCellComponent: React.ReactNode;
  uniqueIdCellComponent: React.ReactNode;
  externalIdCellComponent: React.ReactNode;
  resumeFilePathsCellComponent: React.ReactNode;
  entrySheetFilePathsCellComponent: React.ReactNode;
  spiFilePathsCellComponent: React.ReactNode;
  offerAcceptanceDeadlineCellComponent: React.ReactNode;
}> = ({
  nameCellComponent,
  kanaNameCellComponent,
  genderCellComponent,
  dateOfBirthCellComponent,
  emailCellComponent,
  phoneNumberCellComponent,
  homePhoneNumberCellComponent,
  graduationYearAndMonthCellComponent,
  affiliationCellComponent,
  addressCellComponent,
  hometownAddressCellComponent,
  uniqueIdCellComponent,
  externalIdCellComponent,
  resumeFilePathsCellComponent,
  entrySheetFilePathsCellComponent,
  spiFilePathsCellComponent,
  offerAcceptanceDeadlineCellComponent,
}) => {
  return (
    <StyledTable>
      <TableBody>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center" width={320}>
            <Typography variant="body2">氏名</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{nameCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">フリガナ</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{kanaNameCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">性別</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{genderCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">生年月日(年齢)</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{dateOfBirthCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center" width={320}>
            <Typography variant="body2">メールアドレス</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{emailCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft rowSpan={2} align="center">
            <Typography variant="body2">電話番号</Typography>
          </StyledTableCellLeft>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">携帯</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{phoneNumberCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">自宅</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{homePhoneNumberCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">卒業年月</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{graduationYearAndMonthCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">学校</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{affiliationCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft rowSpan={2} align="center">
            <Typography variant="body2">住所</Typography>
          </StyledTableCellLeft>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">自宅(現住所)</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{addressCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">帰省先</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{hometownAddressCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft rowSpan={2} align="center">
            <Typography variant="body2">ID</Typography>
          </StyledTableCellLeft>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">Onn 固定ID</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{uniqueIdCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">予備ID</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{externalIdCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft rowSpan={3} align="center">
            <Typography variant="body2">ファイル</Typography>
          </StyledTableCellLeft>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">履歴書</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{resumeFilePathsCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">エントリーシート</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{entrySheetFilePathsCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">SPI</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{spiFilePathsCellComponent}</StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">内定承諾期日</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>{offerAcceptanceDeadlineCellComponent}</StyledTableCellRight>
        </TableRow>
      </TableBody>
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &.MuiTable-root {
    width: 100%;
    table-layout: fixed;
  }
  .MuiTableRow-root {
    .MuiTableCell-root {
      border: 1px solid ${(props) => props.theme.palette.grey[100]};
    }
    .MuiTableCell-root:first-child {
      border-left: none;
    }
    .MuiTableCell-root:last-child {
      border-right: none;
    }
  }
`;

const StyledTableCellLeft = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: ${(props) => props.theme.palette.grey[50]};
    padding: 16px 8px;
  }
`;

const StyledTableCellRight = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 8px 16px;
  }
`;
