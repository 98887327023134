import { Box, colors } from "@material-ui/core";
import { BriefingSessionEvent } from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { FC } from "react";
import styled from "styled-components";

import { CreateEventButton } from "../common/components/CreateEventButton";
import { useFilter } from "../common/hooks/useFilter";

import { BriefingSessionEventListTable } from "./components/Table";

import { SearchForm } from "~/components/uiParts";

export const BriefingSessionEventTabPage: FC<{ events: BriefingSessionEvent[] }> = ({ events }) => {
  const { filter, setFilter, filteredEvents } = useFilter({ events });

  return (
    <>
      <Box>
        <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
          <Box height="40px" mt="40px" mb="24px" width="224px">
            <StyledSearchForm
              variant="outlined"
              searchValue={filter.eventName}
              onSearchValue={(value) => setFilter({ eventName: value })}
              placeholder="イベント名"
              fullHeight
              fullWidth
              backgroundColor={colors.common.white}
            />
          </Box>
          <Box height="40px" mt="40px" mb="24px">
            <CreateEventButton eventType="briefing_session" />
          </Box>
        </Box>
        <BriefingSessionEventListTable events={filteredEvents} isLoading={false} />
      </Box>
    </>
  );
};

const StyledSearchForm = styled(SearchForm)`
  width: 100%;
  height: 100%;
  .MuiInputBase-root {
    height: 100%;
  }
`;
