import { z } from "zod";

import { OnnEventSlotDateEventTypeSchema } from "../inputTypeSchemas/OnnEventSlotDateEventTypeSchema";
import { OnnEventSlotDateStatusSchema } from "../inputTypeSchemas/OnnEventSlotDateStatusSchema";

/////////////////////////////////////////
// ONN EVENT SLOT DATE SCHEMA
/////////////////////////////////////////

export const OnnEventSlotDateSchema = z.object({
  eventType: OnnEventSlotDateEventTypeSchema,
  status: OnnEventSlotDateStatusSchema,
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  from: z.date(),
  until: z.date(),
  capacity: z.number().int().min(1, { message: "最低1名以上で設定してください" }).nullish(),
  assigneeId: z.string().nullish(),
  subAssigneeIds: z.string().array(),
  uniqueId: z.number(),
  description: z.string(),
  eventPlaceId: z.string().nullish(),
  eventAddressPostCode: z.string().nullish(),
  eventAddressText: z.string().nullish(),
  url: z.string().nullish(),
  briefingSessionCategoryId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventSlotDate = z.infer<typeof OnnEventSlotDateSchema>;

/////////////////////////////////////////
// ONN EVENT SLOT DATE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventSlotDateOptionalDefaultsSchema = OnnEventSlotDateSchema.merge(
  z.object({
    subAssigneeIds: z.string().array().optional(),
    uniqueId: z.number().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventSlotDateOptionalDefaults = z.infer<
  typeof OnnEventSlotDateOptionalDefaultsSchema
>;

export default OnnEventSlotDateSchema;
