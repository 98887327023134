import { Box, Grid } from "@material-ui/core";
import { Content, Library, Space } from "@onn/common";
import React, { FC, useState, useEffect, useCallback, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { ContentEditor } from "./ContentEditor";

import {
  Button,
  Divider,
  Icon,
  Paper,
  EmojiPicker,
  TextFieldDeprecated,
  Typography,
  Tooltip,
  Loading,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSnackbar, useLocalStorage } from "~/hooks/shared";
import { usePrompt } from "~/hooks/shared/usePrompt";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { LibraryUseCase } from "~/service/usecases/libraryUseCase";
import { captureException } from "~/util";

type ContentFormType = Omit<Content, "id"> & { id?: string };
type LibraryFormType = Pick<
  Library,
  "label" | "title" | "createdEmployeeId" | "updatedEmployeeId" | "tenantId" | "spaceId"
> & { contents: ContentFormType[] };

const libraryUseCase = new LibraryUseCase();

const LIBRARY_FOR_PREVIEW = "libraryForPreview";
const LIBRARY_TITLE_MAX_NUM = 50;

export const LibraryEdit: FC = () => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();
  const { id: libraryId } = useParams<"id">();

  const [isLoading, setIsLoading] = useState(true);
  const [library, setLibrary] = useState<Library>();

  useEffect(() => {
    const fetchLibrary = async (libraryId: string) => {
      const library = await libraryUseCase.findById(libraryId);
      setLibrary(library);
      setIsLoading(false);
    };
    if (libraryId) {
      fetchLibrary(libraryId);
    } else {
      setIsLoading(false);
    }
  }, [currentUser.id, libraryId]);

  if (isLoading) return <Loading size="large" fullHeight />;

  if (tenant.isActiveNewGraduate) {
    // 中途向けではSpaceProviderを扱えないため新卒向けテナントにスペース機能を提供するためにwrapしている
    return <LibraryEditCoreWrapper library={library} />;
  } else {
    return <LibraryEditCore library={library} />;
  }
};

type Props = {
  library?: Library;
  space?: Space;
};

const LibraryEditCoreWrapper: FC<Props> = ({ library }) => {
  const { currentSpace, switchSpaceTemporary } = useCurrentSpace();

  useEffect(() => {
    if (!library) return;
    library.spaceId && switchSpaceTemporary(library.spaceId);
  }, [library, switchSpaceTemporary]);

  return <LibraryEditCore library={library} space={currentSpace} />;
};

const LibraryEditCore: FC<Props> = ({ library, space }) => {
  const { currentUser } = useCurrentUser();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { storeValue } = useLocalStorage();

  const [isOpenEmojiPicker, setIdOpenEmojiPicker] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isSavingLibrary, setIsSavingLibrary] = useState<boolean>(false);
  const [isContentFormError, setIsContentFormError] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState(false);
  usePrompt("編集内容を破棄しますか？", isChanged);

  const [newLibrary, setNewLibrary] = useState<LibraryFormType>({
    tenantId: currentUser.tenantId,

    spaceId: space?.id || undefined,
    label: "",
    title: "",
    contents: [
      {
        title: "",
        index: 1,
        url: "",
        description: "",
      },
    ],
    createdEmployeeId: currentUser.id,
    updatedEmployeeId: currentUser.id,
  });

  useEffect(() => {
    if (!library) return;
    setNewLibrary({
      tenantId: library.tenantId,
      spaceId: library.spaceId,
      label: library.label,
      title: library.title,
      contents: library.contents,
      createdEmployeeId: library.createdEmployeeId,
      updatedEmployeeId: currentUser.id,
    });
  }, [currentUser.id, library]);

  const moveToLibraries = () => {
    navigate("/tools#library");
  };

  const isInvalidLibrary = useCallback(
    (library: LibraryFormType): boolean => {
      return (
        !library.title.trim() ||
        library.title.trim().length > LIBRARY_TITLE_MAX_NUM ||
        isContentFormError
      );
    },
    [isContentFormError]
  );

  useEffect(() => {
    // 1件もコンテンツを持たない場合はボタンを非表示にする
    const flag = isInvalidLibrary(newLibrary) || newLibrary.contents.length === 0;

    setButtonDisabled(flag);
  }, [newLibrary, isInvalidLibrary]);

  const handleOnSelectEmoji = (emoji: string) => {
    updateLibrary({ label: emoji });
    setIdOpenEmojiPicker(false);
  };

  const updateLibrary = (newObject: Partial<LibraryFormType>) => {
    if (!isChanged) setIsChanged(true);
    setNewLibrary((prev) => ({ ...prev, ...newObject }));
  };

  const saveLibrary = async ({ isPublic }: { isPublic: boolean }) => {
    setIsSavingLibrary(true);
    await libraryUseCase
      .create({ ...newLibrary, isPublic }, currentUser.tenantId, space?.id)
      .then(() => {
        setIsChanged(false);
        enqueueSnackbar("ライブラリを保存しました", { variant: "success" });
        navigate("/tools#library");
      })
      .catch((e) => {
        enqueueSnackbar("ライブラリを保存できませんでした", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "LibraryEdit:saveLibrary" },
        });
      })
      .finally(() => {
        setIsSavingLibrary(false);
      });
  };

  const updateNewLibrary = async ({ isPublic }: { isPublic: boolean }) => {
    if (!library) return;

    setIsSavingLibrary(true);
    await libraryUseCase
      .updateLibrary({ libraryId: library.id, library: { ...library, ...newLibrary, isPublic } })
      .then(() => {
        setIsChanged(false);
        enqueueSnackbar("ライブラリを保存しました", { variant: "success" });
        navigate("/tools#library");
      })
      .catch((e) => {
        enqueueSnackbar("ライブラリを保存できませんでした", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "LibraryEdit:libraryUseCase" },
        });
      })
      .finally(() => {
        setIsSavingLibrary(false);
      });
  };

  const handleClickSaveButton = ({ isPublic }: { isPublic: boolean }) => {
    setIsChanged(false);
    if (library) {
      updateNewLibrary({ isPublic });
    } else {
      saveLibrary({ isPublic });
    }
  };

  const handleClickAddContentButton = () => {
    updateLibrary({
      contents: [
        ...newLibrary.contents,
        {
          title: "",
          index: newLibrary.contents.length + 1,
          url: "",
          description: "",
        },
      ],
    });
  };

  const handleChangeContentForm = (
    content: ContentFormType,
    newContentObject: Partial<ContentFormType>
  ) => {
    const newContent = { ...content, ...newContentObject };
    const newContents = [
      ...newLibrary.contents.filter((v) => v.index !== content.index),
      newContent,
    ].sort((a, b) => (a.index < b.index ? -1 : 1));
    updateLibrary({ contents: newContents });
  };

  const handleClickDeleteContent = (contentIndex: number) => {
    // index を調整するために消したものより大きい index の場合は -1 する
    const newContents = newLibrary.contents
      .filter((content) => content.index !== contentIndex)
      .map((content) => {
        if (content.index > contentIndex) {
          return { ...content, index: content.index - 1 };
        }
        return content;
      })
      .sort((a, b) => (a.index < b.index ? -1 : 1));

    updateLibrary({
      contents: newContents,
    });
  };

  const sortContents = ({ content, isUp }: { content: ContentFormType; isUp: boolean }) => {
    // 上向きの時は index は下がる
    const targetIndex = isUp ? content.index - 1 : content.index + 1;

    const updatedContents = newLibrary.contents.map((v) => {
      if (v.index === targetIndex) {
        return {
          ...v,
          index: content.index,
        };
      }
      if (v.index === content.index) {
        return {
          ...v,
          index: targetIndex,
        };
      }
      return v;
    });

    updateLibrary({ contents: updatedContents.sort((a, b) => (a.index < b.index ? -1 : 1)) });
  };

  const previewLibrary = () => {
    storeValue(LIBRARY_FOR_PREVIEW, newLibrary);
    window.open(
      "/portal/libraries/dummy?editing=true&preview=true",
      "_blank",
      "noopener noreferrer"
    );
  };

  return (
    <>
      <StyledContainerBox pt={6} pb={6} pl={5} pr={5}>
        <Button
          variant="text"
          borderRadius="regular"
          color="default"
          onClick={() => moveToLibraries()}
        >
          <Typography variant="body2" color="textSecondary">
            ← ライブラリ
          </Typography>
        </Button>
        <Box height="40px" />
        <Typography variant="h4" bold>
          ライブラリコンテンツ設定
        </Typography>
        <Box height="40px" />
        <Grid container spacing={5}>
          <Grid item xs={8}>
            <StyledTypography variant="body2">
              ライブラリは、候補者がポータルで閲覧できる「組織・カルチャー・人」などに関わるコンテンツを登録する場所です。入社者が入社1日目を迎えるまでに会社へのエンゲージメントを高めるコンテンツをカテゴリーごとに登録しましょう。
            </StyledTypography>
            <Box height="44px" />
            <Typography variant="body2" bold>
              カテゴリータイトル
            </Typography>
            <Box height="24px" />
            <Paper square>
              <StyledMutedTypography variant="caption" color="textSecondary">
                コンテンツ群を総称するカテゴリータイトルを入力してください。カテゴリーには任意でアイコンを追加することができます。カテゴリータイトルは入社者ポータルにそのまま表示されます。
              </StyledMutedTypography>
              <Box height="16px" />
              <Box display="flex" alignItems="center">
                <Tooltip arrow title="アイコン設定" placement="bottom">
                  <StyledIconWrapper onClick={() => setIdOpenEmojiPicker(true)}>
                    {newLibrary.label || <Icon size="lg" icon="emojiSmile" color="lightGrey" />}
                  </StyledIconWrapper>
                </Tooltip>
                <Box display="inline-block" width="8px" />
                <TextFieldDeprecated
                  name="title"
                  value={newLibrary.title}
                  fullWidth
                  variant="outlined"
                  placeholder="カテゴリータイトルを入力してください"
                  onChange={(e) => updateLibrary({ title: e.target.value })}
                  maxTextCount={LIBRARY_TITLE_MAX_NUM}
                  error={newLibrary.title.length > LIBRARY_TITLE_MAX_NUM}
                />
              </Box>
              <EmojiPicker
                onSelect={handleOnSelectEmoji}
                isOpen={isOpenEmojiPicker}
                onClose={() => setIdOpenEmojiPicker(false)}
              />
            </Paper>
            <Divider margin={40} orientation="horizontal" />
            {newLibrary.contents.map((content) => {
              return (
                <Fragment key={content.index}>
                  <ContentEditor
                    content={content}
                    displaySortUpButton={content.index !== 1}
                    displaySortDownButton={content.index !== newLibrary.contents.length}
                    onChangeContentForm={handleChangeContentForm}
                    onClickDeleteContent={handleClickDeleteContent}
                    onCLickSortButton={sortContents}
                    onChangeContentFormError={(bool) => setIsContentFormError(bool)}
                  />
                  <Box height="40px" />
                </Fragment>
              );
            })}
            <StyledButtonWrapper>
              <Button
                fullWidth
                borderRadius="circle"
                color="default"
                onClick={handleClickAddContentButton}
                variant="outlined"
              >
                + コンテンツを追加
              </Button>
            </StyledButtonWrapper>
          </Grid>
          <Grid item xs={4}>
            <Box position="sticky" top="40px">
              <Button
                variant="contained"
                borderRadius="circle"
                color="primary"
                fullWidth
                onClick={() => handleClickSaveButton({ isPublic: true })}
                disabled={buttonDisabled || isSavingLibrary}
              >
                公開
              </Button>
              <Box height="16px" />
              <Button
                variant="outlined"
                borderRadius="circle"
                color="primary"
                fullWidth
                onClick={() => handleClickSaveButton({ isPublic: false })}
                disabled={buttonDisabled || isSavingLibrary}
              >
                下書き保存
              </Button>
              <Box height="16px" />
              <Box display="flex" justifyContent="center">
                <Button
                  variant="text"
                  borderRadius="regular"
                  color="default"
                  onClick={() => moveToLibraries()}
                >
                  キャンセル
                </Button>
                <Divider margin={16} orientation="vertical" />
                <Button
                  variant="text"
                  borderRadius="regular"
                  color="primary"
                  onClick={previewLibrary}
                  disabled={buttonDisabled}
                  startIcon={
                    <Icon icon="eye" color={buttonDisabled ? "lightGrey" : "primary"} size="md" />
                  }
                >
                  表示をプレビュー
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </StyledContainerBox>
    </>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: calc(100vh - 80px);
`;

const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  font-size: 30px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.action.active};
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledButtonWrapper = styled.div`
  /* ボタンにスタイルを当てるためのワークアラウンド */
  .MuiButtonBase-root > div {
    color: ${(props) => props.theme.palette.grey[200]};
    border: ${(props) => `2px dashed ${props.theme.palette.grey[200]}`};
    font-size: 14px;
    border-radius: 10px;
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
      border: ${(props) => `2px dashed ${props.theme.palette.primary.main}`};
    }
  }
`;

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[400]};
`;

const StyledMutedTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
