import { useState } from "react";

import { EmployeeActiveLogUseCase } from "~/service/usecases/employeeActiveLogUseCase";

const employeeActiveLogUseCase = new EmployeeActiveLogUseCase();

/**
 * アクティブログを削除する
 * @returns isLoading データを削除中かどうか
 * @returns deleteEmployeeActiveLog ログを削除する関数
 */
export const useDeleteEmployeeActiveLog = (): {
  isLoading: boolean;
  deleteEmployeeActiveLog: (employeeActiveLogId: string) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteEmployeeActiveLog = async (employeeActiveLogId: string) => {
    setIsLoading(true);
    await employeeActiveLogUseCase
      .deleteById(employeeActiveLogId)
      .then((res) => res)
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    deleteEmployeeActiveLog,
  };
};
