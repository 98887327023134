import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { BodyRowData } from "../const";

import { CellItem, StyleProps, Typography } from "~/components/uiParts";

export const DescriptionCell: FC<
  { description: BodyRowData["description"]; isShowRightBorder?: boolean } & StyleProps
> = ({ style, description, isShowRightBorder }) => {
  return (
    <CellItem
      style={{ ...style }}
      isShowTopBorder
      textAlign="left"
      isShowRightBorder={isShowRightBorder}
    >
      <Box
        style={{ textAlign: "left", lineHeight: 1.0, overflowY: "scroll" }}
        width={"100%"}
        height={"100%"}
        p="8px"
      >
        <Typography variant="caption" color="textPrimary">
          {description}
        </Typography>
      </Box>
    </CellItem>
  );
};
