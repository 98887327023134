import { Box } from "@material-ui/core";
import React, { ComponentProps, FC, useCallback, useMemo } from "react";
import { AutoSizer, MultiGrid, WindowScroller } from "react-virtualized";
import styled from "styled-components";

import { Loading } from "../Loading";
import { Paper } from "../Paper";
import { Typography } from "../Typography";

const EMPTY_OR_LOADING_HEIGHT = 240;
type Props = Pick<
  ComponentProps<typeof MultiGrid>,
  "cellRenderer" | "columnWidth" | "columnCount" | "rowCount"
> & {
  headerCellHeight: number;
  bodyCellHeight: number;
  isLoading: boolean;
  emptyText?: string;
  paddingSize: "sm" | "md";
};

export const VirtualizedMultiGrid: FC<Props> = ({
  cellRenderer,
  columnWidth,
  columnCount,
  headerCellHeight,
  bodyCellHeight,
  rowCount,
  isLoading,
  emptyText = "結果が見つかりませんでした。",
  paddingSize,
}) => {
  // NOTE: ヘッダーの数分引いて計算している
  const isExistData = rowCount > 1;
  const paperHeight = useMemo(() => {
    const paddingTop = 40;
    const paddingBottom = 40;
    if (isLoading || !isExistData) return paddingTop + paddingBottom + EMPTY_OR_LOADING_HEIGHT;

    const rowsHeight = bodyCellHeight * (rowCount - 1);

    const result = paddingTop + paddingBottom + headerCellHeight + rowsHeight;
    return result;
  }, [isLoading, isExistData, bodyCellHeight, rowCount, headerCellHeight]);

  const loadingRenderer = useCallback(() => {
    return (
      <Box
        height={EMPTY_OR_LOADING_HEIGHT}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="100%" height="40px">
          <Loading size="large" />
        </Box>
      </Box>
    );
  }, []);

  const noRowsRenderer = useCallback(() => {
    return (
      <Box
        height={EMPTY_OR_LOADING_HEIGHT}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width="100%" height="40px">
          <Typography variant="body2" align="center" color="textSecondary">
            {emptyText}
          </Typography>
        </Box>
      </Box>
    );
  }, [emptyText]);

  return (
    <WindowScroller>
      {({ height, scrollTop }) => (
        <StyledPaper height={paperHeight} $paddingSize={paddingSize}>
          {isLoading ? (
            loadingRenderer()
          ) : !isExistData ? (
            noRowsRenderer()
          ) : (
            <AutoSizer disableHeight>
              {({ width }) => {
                return (
                  <MultiGrid
                    autoHeight
                    scrollTop={scrollTop}
                    cellRenderer={cellRenderer}
                    rowHeight={({ index }) => (index === 0 ? headerCellHeight : bodyCellHeight)}
                    columnWidth={columnWidth}
                    columnCount={columnCount}
                    rowCount={rowCount}
                    fixedColumnCount={1}
                    fixedRowCount={1}
                    enableFixedColumnScroll={false}
                    overscanRowCount={5}
                    overscanColumnCount={5}
                    height={height}
                    width={width}
                  />
                );
              }}
            </AutoSizer>
          )}
        </StyledPaper>
      )}
    </WindowScroller>
  );
};

const StyledPaper = styled(Paper)<{ height: number; $paddingSize: "sm" | "md" }>`
  padding: ${(props) => (props.$paddingSize === "sm" ? "24" : "40")}px;
  height: ${(props) => props.height}px;
`;
