import { v4 } from "uuid";

import { IPortalRichMenuRelationV2, portalRichMenuRelationV2Schema } from "./schema";

export class PortalRichMenuRelationV2 implements IPortalRichMenuRelationV2 {
  static readonly validator = portalRichMenuRelationV2Schema;
  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly scenarioId: string;
  readonly recruitmentStatusId: string;
  readonly internalRichMenuId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(data: ExcludeMethods<PortalRichMenuRelationV2>) {
    const parsedInit = PortalRichMenuRelationV2.validator.parse(data);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.scenarioId = parsedInit.scenarioId;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;
    this.internalRichMenuId = parsedInit.internalRichMenuId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(
    init: Omit<ExcludeMethods<PortalRichMenuRelationV2>, "id" | "createdAt" | "updatedAt">
  ): PortalRichMenuRelationV2 {
    return new PortalRichMenuRelationV2({
      ...init,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
  public update(params: Pick<ExcludeMethods<PortalRichMenuRelationV2>, "internalRichMenuId">) {
    return new PortalRichMenuRelationV2({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
