import { Box, ButtonBase, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type Props = {
  title: string;
  totalCount: number;
  isSelected: boolean;
  loading?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const FilterButton: FC<Props> = (props) => {
  const { title, totalCount, isSelected, loading, onClick } = props;

  return (
    <ButtonBase onClick={onClick} component="div">
      <StyledPaper square $isSelected={isSelected}>
        <Box pt={2} pb={2} pl={3} pr={3}>
          {loading ? (
            <Box height="42px" display="flex" alignItems="center">
              <Skeleton variant="text" height={32} width="60%" />
            </Box>
          ) : (
            <Typography variant="h2" bold>{`${totalCount}`}</Typography>
          )}

          <StyledTypography variant="caption">{title}</StyledTypography>
        </Box>
      </StyledPaper>
    </ButtonBase>
  );
};

const StyledPaper = styled(Paper)<{ $isSelected: boolean }>`
  width: 180px;
  height: 100px;
  &.MuiPaper-root {
    ${(props) =>
      props.$isSelected && `border-bottom: solid 4px ${props.theme.palette.primary.main};`}
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`;
