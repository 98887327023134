import { Employee, Role, ErrorStatus } from "@onn/common";
import { UserCredential } from "firebase/auth";

import { HttpsCallableResult } from "firebase/functions";

import { factory } from "../repository/iEmployeeRepository";

import { InputValue } from "~/hooks/employee";
import { functionOperator } from "~/infrastructure/api/functionOperator";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

const employeeRepository = factory.employeeRepository();
export const EmployeeUseCase = {
  findById: async (employeeId: string): Promise<Employee | undefined> => {
    return employeeRepository.findById(employeeId);
  },
  findByIds: async (employeeIds: string[]): Promise<Employee[]> => {
    return employeeRepository.whereByIds(employeeIds);
  },
  findAll: async (tenantId: string): Promise<Employee[]> => {
    return employeeRepository.findAll(tenantId);
  },
  findAllWithDeleted: async (): Promise<Employee[]> => {
    return employeeRepository.findAllWithDeleted();
  },
  findByRole: async (tenantId: string, role: Role): Promise<Employee[]> => {
    return employeeRepository.findByRole(tenantId, role);
  },
  /**
   * プロパティの一部を与えて更新する
   * @param employeeId 更新するemployeeのId
   * @param newObject 更新するプロパティ
   */
  update: async (employeeId: string, newObject: Partial<Employee>): Promise<void> => {
    return await employeeRepository.update(employeeId, newObject);
  },
  updateCurrentEmployee: async (newObject: {
    firstName?: string;
    lastName?: string;
    email?: string;
    profileIconImageUrl?: string;
  }): Promise<void> => {
    await apiClient.patch("/api/employee/update-current-employee", newObject);
  },
  deleteNewHire: async (employeeId: string): Promise<void> => {
    return await employeeRepository.deleteNewHire(employeeId);
  },
  /**
   *
   * @param employeeId
   * @param employeeIdToDisMiss
   */
  dismissSupportMember: async (
    employeeId: string,
    employeeIdToDismiss: string
  ): Promise<HttpsCallableResult> => {
    return await functionOperator.httpsCall("dismissSupportMember", {
      employeeId,
      employeeIdToDismiss,
    });
  },
  checkValidation: async (inputValues: InputValue[]): Promise<ErrorStatus> => {
    const response = await functionOperator.httpsCallFor2ndGen<
      unknown,
      {
        errorStatus: { [k: string]: number[] };
      }
    >("checkinputvalues", {
      inputValues,
    });

    return new Map(Object.entries(response.data.errorStatus)) as ErrorStatus;
  },
};

const accountRepository = factory.accountRepository();
export const AccountUseCase = {
  signIn: async (email: string, password: string): Promise<void> => {
    return accountRepository.signIn(email, password);
  },
  findByUid: async (): Promise<Employee[]> => {
    return accountRepository.findByUid();
  },
  sendPasswordResetEmail: async (email: string): Promise<void> => {
    return accountRepository.sendPasswordResetEmail(email);
  },
  updatePassword: async (currentPassword: string, newPassword: string): Promise<void> => {
    return accountRepository.updatePassword(currentPassword, newPassword);
  },
  signInWithCustomToken: async (customToken: string): Promise<UserCredential> => {
    return accountRepository.signInWithCustomToken(customToken);
  },
  checkMailExist: async (email: string): Promise<boolean> => {
    return employeeRepository.checkMailExist(email);
  },
  // TODO[https://app.clickup.com/t/860t11h4k]: 新卒候補者の場合は重複を許すようにする
  updateEmail: async (newEmail: string, currentPassword: string): Promise<void> => {
    const isMailExist = await AccountUseCase.checkMailExist(newEmail);
    if (isMailExist) {
      const error = new Error("既に同じメールアドレスを持つユーザーが存在します");
      captureException({
        error,
        tags: { type: "employeeUseCase:updateEmail" },
        extras: { newEmail },
      });
      throw error;
    }

    return accountRepository.updateEmail(newEmail, currentPassword);
  },
  updateLastRefreshTime: async (): Promise<void> => {
    await apiClient.patch("/api/employee/update-last-refresh-time", {
      lastRefreshTime: new Date(),
    });
  },
  updateLastActiveTime: async (): Promise<void> => {
    await apiClient.patch("/api/employee/update-current-employee", {
      lastActiveTime: new Date(),
    });
  },
};
