import React, { FC, useCallback } from "react";

import { LineButton } from "~/components/domains/account/_shared/LineButton";
import { useLiffUrl } from "~/hooks/liff";
import { useLineAuthStateInLocalStorage } from "~/hooks/lineAuthentication/useLineAuthStateInLocalStorage";
import { useQuery } from "~/hooks/shared";
import { LienLoginChannelInfo } from "~/hooks/tenant/useTenantLineLoginChannelInfo";
import { isMobileBrowser } from "~/util";

/**
 * LINE認証用のstateをセッションストレージに保存する
 * https://developers.line.biz/ja/docs/line-login/integrate-line-login/
 */
const useStoreLineAuthStateInSessionStorage = () => {
  const { store: storeLineAuthStateInStorage } = useLineAuthStateInLocalStorage();
  const lineLoginState = new Date().getTime().toString();
  const storeValue = () => {
    storeLineAuthStateInStorage(lineLoginState);
    return lineLoginState;
  };

  return { storeValue };
};

type Props = {
  dataLineLoginChannelInfo: LienLoginChannelInfo;
};

export const LineLoginButton: FC<Props> = ({ dataLineLoginChannelInfo }) => {
  const { storeValue: storeLineAuthState } = useStoreLineAuthStateInSessionStorage();
  const { openLiff } = useLiffUrl();
  const { query } = useQuery();

  const loginWithPC = useCallback(async () => {
    const origin = window.location.origin;
    const url = new URL(origin);
    url.pathname = "/after-line-authentication";
    const redirectURL = encodeURI(url.toString());
    const clientID = dataLineLoginChannelInfo.clientId;

    // リダイレクト先で取得できるstateと照合するために、sessionStorageに保存しておく
    const lineLoginState = storeLineAuthState();
    // see: https://developers.line.biz/ja/docs/line-login/integrate-line-login/#making-an-authorization-request
    const href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientID}&redirect_uri=${redirectURL}&state=${lineLoginState}&scope=profile%20openid&nonce=09876xyz&initial_amr_display=lineqr&switch_amr=false`;
    location.href = href;
  }, [dataLineLoginChannelInfo, storeLineAuthState]);

  const loginWithSP = useCallback(
    () =>
      openLiff({
        path: query.get("dest-path") || "/portal",
        liffId: dataLineLoginChannelInfo.liffId,
      }),
    [dataLineLoginChannelInfo, openLiff, query]
  );

  const handleLogin = isMobileBrowser() ? loginWithSP : loginWithPC;

  return <LineButton type="login" onClick={handleLogin} />;
};
