import { Box } from "@material-ui/core";
import { Announcement, AnnouncementDistribution } from "@onn/common";
import React from "react";

import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { PortalListItem } from "~/components/domains/announcement/PortalListItem";

import { Typography, Loading, Button } from "~/components/uiParts";
import { useAnnouncementsForPortal } from "~/hooks/announcement/useAnnouncementsForPortal";

import { NotFound } from "~/pages/NotFound";

import { mixin } from "~/util";

export const AnnouncementList = (): JSX.Element => {
  const { data: announcementsData, isLoading: isLoadingAnnouncementsData } =
    useAnnouncementsForPortal();

  if (isLoadingAnnouncementsData) {
    return <Loading size="large" fullHeight />;
  }

  if (!announcementsData) {
    return <NotFound />;
  }

  return (
    <PageCore
      announcements={announcementsData.announcements}
      announcementDistributions={announcementsData.announcementDistributions}
    />
  );
};

type Props = {
  announcements: Announcement[];
  announcementDistributions: AnnouncementDistribution[];
};

const PageCore = ({ announcements, announcementDistributions }: Props) => {
  const navigate = useNavigate();

  return (
    <StyledBox>
      <StyledPageTitle variant="h2" color="textPrimary">
        お知らせ一覧
      </StyledPageTitle>
      {announcements.length === 0 ? (
        <StyledCard>
          <Box mt="80px" mb="80px" display="flex" alignItems="center" justifyContent="center">
            <Typography display="block" color="textSecondary">
              公開中のお知らせはありません。
            </Typography>
          </Box>
        </StyledCard>
      ) : (
        <StyledCard>
          <Box>
            {announcements.map((announcement, i) => (
              <PortalListItem
                key={announcement.id}
                title={announcement.title}
                content={announcement.content}
                onClick={() => navigate(`/portal/announcements/${announcement.id}`)}
                isRead={announcementDistributions.some(
                  (d) => d.announcementId === announcement.id && d.isRead()
                )}
                isLast={i === announcements.length - 1}
              />
            ))}
          </Box>
        </StyledCard>
      )}
      <StyledButtonContainer width="100%">
        <Button
          color="primary"
          fullWidth
          variant="text"
          borderRadius="regular"
          onClick={() => navigate("/portal")}
        >
          TOPへ戻る
        </Button>
      </StyledButtonContainer>
    </StyledBox>
  );
};

const StyledPageTitle = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
`;

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;

  ${mixin.portalSp`
    padding: 40px 24px;
    width: 100%;
    height: 100%;
  `}
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 16px;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
