import { z } from "zod";

import { ActionSettingTypeSchema } from "../inputTypeSchemas/ActionSettingTypeSchema";

/////////////////////////////////////////
// ACTION ORDER SCHEMA
/////////////////////////////////////////

export const ActionOrderSchema = z.object({
  type: ActionSettingTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  employeeId: z.string(),
  cloudTasksTaskName: z.string().nullish(),
  isCompleted: z.boolean(),
  isCanceled: z.boolean(),
  actionId: z.string(),
  recruitmentStatusIdWhenGeneratedActionOrder: z.string(),
  recruitmentStatusId: z.string().nullish(),
  onnEventId: z.string().nullish(),
  onnTaskId: z.string().nullish(),
  text: z.string().nullish(),
  filePaths: z.string().array(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ActionOrder = z.infer<typeof ActionOrderSchema>;

/////////////////////////////////////////
// ACTION ORDER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ActionOrderOptionalDefaultsSchema = ActionOrderSchema.merge(
  z.object({
    isCompleted: z.boolean().optional(),
    isCanceled: z.boolean().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type ActionOrderOptionalDefaults = z.infer<typeof ActionOrderOptionalDefaultsSchema>;

export default ActionOrderSchema;
