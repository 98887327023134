import { EmployeePrediction } from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { useCurrentUser } from "../employee/useCurrentUser";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

const getKey = ({ tenantId, spaceId }: { tenantId: string; spaceId: string }) => {
  return {
    endpoint: "/api/employee-predictions",
    tenantId,
    spaceId,
  } as const;
};

// NOTE: 基本的には Provider で提供している employeePredictions を使うことを想定している
export const useEmployeePredictions = () => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  return useSWR(
    getKey({ tenantId: currentUser.tenantId, spaceId: currentSpace.id }),
    async ({ endpoint }) => {
      const response = await apiClient.get(endpoint);

      return response.data.map((p) => new EmployeePrediction(p));
    }
  );
};

export const useMutateEmployeePredictions = () => {
  const { mutate } = useSWRConfig();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  const mutateEmployeePredictions = useCallback(async () => {
    await mutate(getKey({ tenantId: currentUser.tenantId, spaceId: currentSpace.id }));
  }, [currentUser.tenantId, currentSpace.id, mutate]);

  return { mutateEmployeePredictions };
};
