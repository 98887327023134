import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Timestamp,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { OnnTaskFormAnswerDraft } from "~/types/firestore/OnnTaskFormAnswerDraft";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";
import { toFirestoreCompatible } from "~/util/toFirestoreCompatible";

const COLLECTION_NAME = "onnTaskFormAnswerDrafts";
type OnnTaskFormAnswerDraftForDB = Omit<OnnTaskFormAnswerDraft, "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export class OnnTaskFormAnswerDraftRepository {
  /** 新規作成または更新をする */
  async save(draft: OnnTaskFormAnswerDraft): Promise<void> {
    const ref = this.doc(draft.id);

    await setDoc(
      ref,
      toFirestoreCompatible({
        ...draft,
        updatedAt: new Date(),
      }),
      { merge: true }
    );
  }

  async find({
    tenantId,
    employeeId,
    onnTaskFormAnswerId,
  }: {
    tenantId: string;
    employeeId: string;
    onnTaskFormAnswerId: string;
  }): Promise<OnnTaskFormAnswerDraft[] | undefined> {
    const { docs } = await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("employeeId", "==", employeeId),
        where("onnTaskFormAnswerId", "==", onnTaskFormAnswerId),
        orderBy("updatedAt", "desc")
      )
    );
    if (!docs[0]) return;

    return docs.map((doc) => this.dbToObject(doc.data() as OnnTaskFormAnswerDraftForDB));
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private dbToObject(object: OnnTaskFormAnswerDraftForDB): OnnTaskFormAnswerDraft {
    return {
      ...object,
      createdAt: convertTimestampToDate(object.createdAt),
      updatedAt: convertTimestampToDate(object.updatedAt),
    };
  }
}
