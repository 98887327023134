import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";

import { Form } from "./parts/form";
import { Registered } from "./parts/registered";
import { Thanks } from "./parts/thanks";

import { Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useOnnEventRelatedEmployee } from "~/hooks/onnEvent";
import { NotFound } from "~/pages/NotFound";

export const OnnEventAttendance: FC = () => {
  const { currentUser } = useCurrentUser();
  const { id: onnEventId } = useParams<"id">();
  const {
    data: eventData,
    isLoading,
    mutate,
  } = useOnnEventRelatedEmployee({ id: onnEventId, employeeId: currentUser.id });
  const [isCompleted, setIsCompleted] = useState(false);

  const answeredCandidateDate = (eventData?.onnEvent.candidateDates || []).find(
    (date) => date.id === eventData?.onnEventDeterminedDate?.candidateDateId
  );

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!eventData) {
    return <NotFound />;
  }

  if (isCompleted) {
    return <Thanks onnEvent={eventData.onnEvent} candidateDate={answeredCandidateDate} />;
  }

  if (eventData.onnEventDeterminedDate?.isAttended()) {
    return <Registered onnEvent={eventData.onnEvent} candidateDate={answeredCandidateDate} />;
  }

  return (
    <Form
      eventData={eventData}
      complete={() => {
        mutate();
        setIsCompleted(true);
      }}
    />
  );
};
