import { Box, colors } from "@material-ui/core";
import React, { FC, useCallback } from "react";

import styled from "styled-components";

import { useCreateEventPlaceModal } from "../../useCreateEventPlaceModal";

import { PlaceTable } from "./components/PlaceTable";

import { useFilter } from "./hooks/useFilter";

import { Button, Icon, SearchForm } from "~/components/uiParts";
import { useOnnEventPlaces } from "~/hooks/onnEventPlace/useOnnEventPlaces";

export const PlaceSettingTab: FC = () => {
  const { data: onnEventPlaces = [], isLoading } = useOnnEventPlaces();
  const { filter, setFilter, filteredOnnEventPlaces } = useFilter({ onnEventPlaces });

  const { openCreateEventPlaceModal } = useCreateEventPlaceModal();
  const onClickCreateButton = useCallback(
    () => openCreateEventPlaceModal(),
    [openCreateEventPlaceModal]
  );

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} pt="40px">
        <Box height="40px" mb="24px" width="224px">
          <StyledSearchForm
            variant="outlined"
            searchValue={filter.nameOrAddress}
            onSearchValue={(value) => setFilter({ nameOrAddress: value })}
            placeholder="会場名・住所"
            fullHeight
            fullWidth
            backgroundColor={colors.common.white}
          />
        </Box>
        <Box height="40px">
          <Button
            color="primary"
            variant="outlined"
            borderRadius="regular"
            startIcon={<Icon icon="add" color="primary" size="md" />}
            onClick={onClickCreateButton}
          >
            会場追加
          </Button>
        </Box>
      </Box>
      <PlaceTable isLoading={isLoading} onnEventPlaces={filteredOnnEventPlaces} />
    </>
  );
};

const StyledSearchForm = styled(SearchForm)`
  width: 100%;
  height: 100%;
  .MuiInputBase-root {
    height: 100%;
  }
`;
