import { ITitlePart } from "@onn/common";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  content: ITitlePart;
};
export const TitlePart: FC<Props> = ({ content }) => {
  return (
    <Typography variant="body2" bold>
      {content.emoji} {content.text}
    </Typography>
  );
};
