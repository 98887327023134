import React, { FC } from "react";

import { NewGraduatePCContactMessages, NewGraduateSPContactMessages } from "./NewGraduate";

import { useShouldSPView } from "~/hooks/shared/useShouldSPView";

export const ContactMessages: FC = () => {
  const shouldSPView = useShouldSPView();

  return shouldSPView ? <NewGraduateSPContactMessages /> : <NewGraduatePCContactMessages />;
};
