import React, { FC } from "react";

export const Copy: FC = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.30511 17.5469C8.77547 17.5469 8.32277 17.3551 7.94701 16.9716C7.57125 16.5881 7.38337 16.1261 7.38337 15.5855V4.96972C7.38337 4.42687 7.57125 3.96287 7.94701 3.57773C8.32277 3.19258 8.77547 3 9.30511 3H18.0701C18.602 3 19.0566 3.19258 19.4339 3.57773C19.8113 3.96287 20 4.42687 20 4.96972V15.5855C20 16.1261 19.8113 16.5881 19.4339 16.9716C19.0566 17.3551 18.602 17.5469 18.0701 17.5469H9.30511ZM9.30511 15.5855H18.0701V4.96972H9.30511V15.5855ZM5.92993 21C5.39804 21 4.94341 20.8074 4.56604 20.4223C4.18868 20.0371 4 19.5731 4 19.0303V7.4338C4 7.15937 4.09317 6.92732 4.2795 6.73765C4.46583 6.54796 4.69517 6.45312 4.96752 6.45312C5.23987 6.45312 5.46836 6.54796 5.65299 6.73765C5.83762 6.92732 5.92993 7.15937 5.92993 7.4338V19.0303H15.6558C15.9246 19.0303 16.152 19.1254 16.3379 19.3155C16.5237 19.5057 16.6166 19.7398 16.6166 20.0177C16.6166 20.2957 16.5237 20.5289 16.3379 20.7173C16.152 20.9058 15.9246 21 15.6558 21H5.92993Z"
        stroke="none"
      />
    </svg>
  );
};
