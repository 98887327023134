import { useCallback, useState } from "react";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useCurrentUser } from "~/hooks/employee";
import { useNotifyOperationLog, useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

/**
 * uiパーツのFilePickerでCSVファイルを選択したときの処理をまとめたカスタムフック
 */
export const useHandleInputCsvFileByFilePicker = ({
  checkingFileOnInputFile,
  objectForErrorLog,
}: {
  checkingFileOnInputFile: (file: File) => Promise<{
    isOk: boolean;
    errorMessages: string[];
  }>;
  objectForErrorLog:
    | {
        // エラーログに出力するためのオブジェクト。使用箇所によって出力する内容が異なるため、使用するたびにtypeの定義を増やす
        type: "SampleType";
      }
    | {
        type: "createNewGraduateWithCSV";
      };
}) => {
  const [loadedFileName, setLoadedFileName] = useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { notifyOperationLog, operationLog } = useNotifyOperationLog();
  const { currentUser } = useCurrentUser();

  const [isCheckingCsv, setIsCheckingCsv] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [inputFile, setInputFile] = useState<File | undefined>();

  const handleInputFile = useCallback(
    async (payload: Payload) => {
      try {
        setIsCheckingCsv(true);
        if (payload.status === "error") {
          enqueueSnackbar(payload.message, { variant: "error" });
          return;
        }

        const file = payload.files[0] as (typeof payload.files)[number];
        setInputFile(file);

        const { isOk, errorMessages } = await checkingFileOnInputFile(file);

        if (isOk) {
          setErrorMessages([]);
        } else {
          setErrorMessages(errorMessages);
          notifyOperationLog(
            operationLog.notifyCSVUploadError(
              currentUser,
              "候補者一括登録",
              errorMessages.join("\n")
            )
          );
        }
        setLoadedFileName(file.name);
      } catch (e) {
        captureException({
          error: e as Error,
          tags: {
            type: "useHandleInputCsvFileByFilePicker" + objectForErrorLog.type,
          },
          extras: {
            currentUser,
            ...objectForErrorLog,
          },
        });
        enqueueSnackbar(
          "エラーが発生しました。もう一度お試しいただけますでしょうか。もし解決しない場合はお問い合わせください。",
          { variant: "error" }
        );
      } finally {
        setIsCheckingCsv(false);
      }
    },
    [
      currentUser,
      enqueueSnackbar,
      notifyOperationLog,
      objectForErrorLog,
      checkingFileOnInputFile,
      operationLog,
    ]
  );

  return { handleInputFile, loadedFileName, errorMessages, isCheckingCsv, inputFile };
};
