import React, { FC } from "react";
import styled from "styled-components";

import { Linkify, Typography } from "~/components/uiParts";

export const OnnTaskDescriptionTypography: FC<{ description: string }> = ({ description }) => {
  return (
    <Linkify>
      <StyledTypography>{description}</StyledTypography>
    </Linkify>
  );
};

const StyledTypography = styled(Typography)`
  overflow-wrap: break-word;
`;
