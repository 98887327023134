import { APISchema, ChangeAuthenticationTypeLink } from "@onn/common";
import { EmailType, LineType } from "@onn/common/domain/Employee/AuthenticationType";
import { useCallback } from "react";

import { useMutateChangeAuthenticationTypeLink } from "./useChangeAuthenticationTypeLink";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/create_change_authentication_type_link"]["POST"];
export const useCreateChangeAuthenticationTypeLink = () => {
  const { mutateChangeAuthenticationTypeLink } = useMutateChangeAuthenticationTypeLink();

  const createChangeAuthenticationTypeLink = useCallback(
    async ({ fromType, toType }: { fromType: EmailType; toType: LineType }) => {
      const body: EndPoint["body"] = {
        fromType,
        toType,
      };
      const response = await apiClient.post("/create_change_authentication_type_link", body);
      mutateChangeAuthenticationTypeLink();
      return new ChangeAuthenticationTypeLink(response.data);
    },
    [mutateChangeAuthenticationTypeLink]
  );

  return { createChangeAuthenticationTypeLink };
};
