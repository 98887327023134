import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// ONN FORM TASK ANSWER SCHEMA
/////////////////////////////////////////

export const OnnFormTaskAnswerSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  answeredFormRevisionId: z.string().nullish(),
  formTaskId: z.string(),
  firstReadAt: z.date().nullish(),
  lastReadAt: z.date().nullish(),
  lastAnsweredAt: z.date().nullish(),
  answers: JsonValueSchema.array(),
  createdAt: z.date().nullish(),
  updatedAt: z.date().nullish(),
});

export type OnnFormTaskAnswer = z.infer<typeof OnnFormTaskAnswerSchema>;

/////////////////////////////////////////
// ONN FORM TASK ANSWER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnFormTaskAnswerOptionalDefaultsSchema = OnnFormTaskAnswerSchema.merge(z.object({}));

export type OnnFormTaskAnswerOptionalDefaults = z.infer<
  typeof OnnFormTaskAnswerOptionalDefaultsSchema
>;

export default OnnFormTaskAnswerSchema;
