import { useCallback } from "react";

import { useOnnEventPlaces } from "~/hooks/onnEventPlace/useOnnEventPlaces";

export const useGenerateSettingCSV = () => {
  const { data: onnEventPlaces = [], isLoading } = useOnnEventPlaces();
  const generateSettingCSV = useCallback(() => {
    return [["「会場名」コピー欄"], ...onnEventPlaces.map((place) => [place.name])];
  }, [onnEventPlaces]);

  return { generateSettingCSV, isLoading };
};
