import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import enAccount from "./en/account.json";
import jaAccount from "./ja/account.json";

i18next.use(initReactI18next).init({
  // デフォルトはja
  fallbackLng: "ja",
  // trueにするとi18nの状態と詳細が標準出力される
  debug: process.env.NODE_ENV === "localhost",
  interpolation: {
    // ReactではHTMLを注入しても解釈されないのでエスケープはデフォルトでfalseにする
    escapeValue: false,
  },
  returnObjects: true,
  resources: {
    ja: {
      account: jaAccount,
    },
    en: {
      account: enAccount,
    },
  },
});
