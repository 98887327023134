import { APISchema, OnnEventSlotDate } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-slot-dates/id"]["PATCH"];
export const useUpdateOnnEventSlotDates = () => {
  const updateOnnEventSlotDates = useCallback(async (arg: Endpoint["body"]) => {
    const requestBody: Endpoint["body"] = {
      onnEventId: arg.onnEventId,
      onnEventSlotId: arg.onnEventSlotId,
      paramsToUpdate: arg.paramsToUpdate,
    };

    const response = await apiClient.patch("/api/onn-event-slot-dates/id", requestBody);
    return new OnnEventSlotDate(response.onnEventSlotDate);
  }, []);

  return { updateOnnEventSlotDates };
};
