import { z } from "zod";

import {
  RecruitmentProcessRecordOnnEventItemSchema,
  RecruitmentProcessRecordOnnTaskItemSchema,
} from "../../_gen/zodSchema";

export const recruitmentProcessRecordOnnEventItemSchema =
  RecruitmentProcessRecordOnnEventItemSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRecruitmentProcessRecordOnnEventItemSchema
  extends z.infer<typeof recruitmentProcessRecordOnnEventItemSchema> {}

export const recruitmentProcessRecordOnnTaskItemSchema = RecruitmentProcessRecordOnnTaskItemSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRecruitmentProcessRecordOnnTaskItemSchema
  extends z.infer<typeof recruitmentProcessRecordOnnTaskItemSchema> {}
