import { OnnEventPlace } from "@onn/common";
import React, { FC, memo } from "react";

import styled from "styled-components";

import { useDeletePlaceConfirmModal } from "../hooks/useDeletePlaceConfirmModal";

import { useUpdateEventPlaceModal } from "../hooks/useUpdateEventPlaceModal";

import {
  IconButton,
  TooltipWhenTextTruncated,
  Typography,
  UncontrolledMenu,
} from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";

type Props = {
  isLoading: boolean;
  onnEventPlaces: OnnEventPlace[];
};

export const PlaceTable: FC<Props> = ({ isLoading, onnEventPlaces }) => {
  const { openDeletePlaceConfirmModal } = useDeletePlaceConfirmModal();
  const { openUpdateEventPlaceModal } = useUpdateEventPlaceModal();

  const widthOptions = ["20%", "30%", "40%", "10%"];

  const rowHeight = 80;
  return (
    <VirtualizedTableV2<OnnEventPlace>
      rowHeight={rowHeight}
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={[
        {
          text: "会場名",
        },
        {
          text: "住所",
        },
        {
          text: "備考",
        },
        {
          text: "",
        },
      ]}
      rows={onnEventPlaces}
      rowRenderer={({ key, index, style, rowData: onnEventPlace }) => {
        const contents = [
          <TooltipWhenTextTruncated key={`placeName-${onnEventPlace.id}`} text={onnEventPlace.name}>
            {(ref) => (
              <StyledTypography variant="body2" color="textPrimary" ref={ref}>
                {onnEventPlace.name}
              </StyledTypography>
            )}
          </TooltipWhenTextTruncated>,
          <OnnEventAddressText
            key={`address-${onnEventPlace.id}`}
            addressText={`${onnEventPlace.postCode} ${onnEventPlace.getFullAddress()}`}
          />,
          <OnnEventPlaceRemarkText
            key={`remark-${onnEventPlace.id}`}
            remarkText={onnEventPlace.remarkText}
          />,
          <UncontrolledMenu
            key={`menu-${onnEventPlace.id}`}
            menuItemOptions={[
              { text: "編集", onClick: () => openUpdateEventPlaceModal(onnEventPlace) },
              { text: "削除", onClick: () => openDeletePlaceConfirmModal(onnEventPlace) },
            ]}
            renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
          />,
        ];
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
              }}
              widthOptions={widthOptions}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

const OnnEventAddressText = memo<{ addressText: string }>(({ addressText }) => {
  return (
    <TooltipWhenTextTruncated text={addressText}>
      {(ref) => (
        <StyledTypography variant="caption" color="textPrimary" ref={ref} $lineClamp={3}>
          {addressText}
        </StyledTypography>
      )}
    </TooltipWhenTextTruncated>
  );
});

const OnnEventPlaceRemarkText = memo<{ remarkText: string }>(({ remarkText }) => {
  return (
    <TooltipWhenTextTruncated text={remarkText}>
      {(ref) => (
        <StyledTypography variant="caption" color="textPrimary" ref={ref} $lineClamp={3}>
          {remarkText}
        </StyledTypography>
      )}
    </TooltipWhenTextTruncated>
  );
});

const StyledTypography = styled(Typography)<{ $lineClamp?: number }>`
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) => props.$lineClamp || 1};
  }
`;
