import { OnnFormTask } from "@onn/common";

import { State } from "../reducer";

import { useConstructUploadFilesInfo } from "./useConstructUploadFilesInfo";

import { useCurrentUser } from "~/hooks/employee";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";

/**
 * 入力値のバリデーション用のカスタムフック
 */
export const useValidateInput = (onnFormTaskId: string) => {
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  const constructUploadFilesInfo = useConstructUploadFilesInfo();

  const tenantId = tenant.tenantId;

  const validateInput = ({ state }: { state: State }): boolean => {
    const uploadFilesInfo = constructUploadFilesInfo({
      state,
    });

    try {
      OnnFormTask.create({
        id: onnFormTaskId,
        isAllowEditAnswer: state.isAllowEditAnswer,
        title: state.onnTaskTitleTextField.value,
        description: state.onnTaskDescriptionTextField.value,
        filePaths: uploadFilesInfo.map((v) => v.path),
        tenantId,
        spaceId: currentSpace.id,
        assigneeId: currentUser.id,
        scheduledDate: null,
        deadlineDate: null,
        firstDeliveredAt: null,
        canAnswerAfterDeadline: false,
      });

      if (state.questions.length === 0) {
        throw new Error("invalid question");
      }
      state.questions.forEach((question) => {
        if (!question.id || !question.title.trim() || !question.type) {
          throw new Error("invalid question");
        }
        if (question.type === "CHECK_BOX" || question.type === "RADIO") {
          if (question.options.length < 1) {
            throw new Error("invalid question");
          }
          question.options.forEach((option) => {
            if (!option.text.trim()) {
              throw new Error("invalid question");
            }
          });
        }

        if (question.type === "TEXT") {
          if (
            question.isWordLimit &&
            (!question.wordLimit || !new RegExp(/^([1-9]\d*)$/).test(question.wordLimit))
          ) {
            throw new Error("invalid question");
          }
        }
      });
    } catch {
      return false;
    }
    return true;
  };

  return { validateInput };
};
