import { z } from "zod";

export type InputState = z.infer<typeof zodFormSchema>;

export const zodFormSchema = z.object({
  placeName: z.string().trim().min(1, { message: "必須項目です" }),
  postCode: z.string().trim().min(1, { message: "必須項目です" }),
  state: z.string().trim().min(1, { message: "必須項目です" }),
  city: z.string().trim().min(1, { message: "必須項目です" }),
  address1: z.string().trim().min(1, { message: "必須項目です" }),
  address2: z.string().trim().min(1, { message: "必須項目です" }),
  remark: z.object({
    note: z.string().trim(),
    files: z
      .array(z.custom<File | Pick<File, "name">>())
      .max(3, { message: "ファイル数の上限は3つです" }),
  }),
});
