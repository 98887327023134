import { DeleteOnnEventRequest } from "@onn/common";
import { useState } from "react";

import { useSWRConfig } from "swr";

import { useSnackbar } from "../shared";

import { useAllOnnEvent } from "./useAllOnnEvents";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { captureException } from "~/util";

export const useDeleteOnnEvent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const { mutate: mutateAllOnnEvents } = useAllOnnEvent();

  const execDeleteOnnEvent = async (onnEventId: string) => {
    try {
      const data = DeleteOnnEventRequest.createRequestBody({ onnEventId });
      setIsLoading(true);
      const response = await functionOperator.httpsCallFor2ndGen<
        ReturnType<typeof DeleteOnnEventRequest.createRequestBody>
      >("onnevent", data);
      enqueueSnackbar("イベントを削除しました", { variant: "success" });
      return response;
    } catch (e) {
      enqueueSnackbar(
        `削除に失敗しました。${(e as Error).message}。解消できない場合、管理者へお問合せください。`,
        {
          variant: "error",
        }
      );
      captureException({
        error: e as Error,
        tags: {
          type: "useDeleteOnnEvent:execDeleteOnnEvent",
        },
      });
    } finally {
      // 削除処理の一部に失敗しても、すでにデータは削除済みの場合があるため、再取得する
      mutate(`retrieveOnnEvent-${onnEventId}`);
      mutateAllOnnEvents();

      setIsLoading(false);
    }
  };

  return { execDeleteOnnEvent, isLoading };
};
