import useSWR, { mutate } from "swr";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

const generateKey = () => {
  return {
    endpoint: "/scenario_api/triggers",
  } as const;
};

export const useScenarioTriggersForDisplay = () => {
  const { enqueueSnackbar } = useSnackbar();
  // NOTE: シナリオのDB不整合等でエラーが発生した場合エラーになるので、エラースナックバーを表示する
  return useSWR(generateKey(), async ({ endpoint }) => {
    try {
      const response = await apiClient.get(endpoint, { isNotifyError: true });
      return response.data;
    } catch (_error) {
      enqueueSnackbar("シナリオフロー一覧の取得に失敗しました。", { variant: "error" });
    }
  });
};

export const mutateScenarioTriggersForDisplay = () => {
  mutate(generateKey());
};
