import { Popover as MuiPopover, PopoverProps } from "@mui/material";
import React, { FC } from "react";

type Props = Pick<
  PopoverProps,
  "id" | "anchorEl" | "open" | "onClose" | "className" | "anchorOrigin" | "transformOrigin"
> & {
  children?: React.ReactNode;
};
export const PaperPopover: FC<Props> = ({ children, anchorOrigin, transformOrigin, ...props }) => {
  return (
    <MuiPopover
      disableEnforceFocus // モーダルと一緒に用いる場合このオプションが設定されていない場合にメモリリークを引き起こすため
      anchorOrigin={
        anchorOrigin || {
          vertical: "bottom",
          horizontal: "left",
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: "top",
          horizontal: "left",
        }
      }
      slotProps={{
        paper: {
          sx: {
            p: "16px",
            width: "400px",
            borderRadius: "15px",
          },
        },
      }}
      {...props}
    >
      {children}
    </MuiPopover>
  );
};
