import { v4 } from "uuid";

import { Action as IAction } from "../../_gen/zodSchema/index";

import { actionSchema } from "./schema";

export class Action implements IAction {
  static readonly validator = actionSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  triggerId: string;
  createdAt: Date;
  updatedAt: Date;
  executionTimeAfterDays?: number;
  executionTimeAtHours?: number;
  executionTimeWithinMinutes?: number;

  constructor(init: ExcludeMethods<Action>) {
    const parsedInit = Action.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.triggerId = parsedInit.triggerId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.executionTimeAfterDays = parsedInit.executionTimeAfterDays;
    this.executionTimeAtHours = parsedInit.executionTimeAtHours;
    this.executionTimeWithinMinutes = parsedInit.executionTimeWithinMinutes;
  }

  public isImmediateDelivery() {
    return (
      this.executionTimeAfterDays == undefined &&
      this.executionTimeAtHours == undefined &&
      this.executionTimeWithinMinutes == undefined
    );
  }

  public getExecutionTiming() {
    if (this.isImmediateDelivery()) {
      return {
        type: "immediate",
      } as const;
    } else {
      if (this.executionTimeAfterDays) {
        return {
          type: "days",
          executionTimeAfterDays: this.executionTimeAfterDays,
          executionTimeAtHours: this.executionTimeAtHours as number, // NOTE: バリデーションでundefinedにならないためキャスト
        } as const;
      } else {
        return {
          type: "minutes",
          executionTimeWithinMinutes: this.executionTimeWithinMinutes as number, // NOTE: バリデーションでundefinedにならないためキャストs
        } as const;
      }
    }
  }

  public update(
    params: Pick<
      ExcludeMethods<Action>,
      "executionTimeAfterDays" | "executionTimeAtHours" | "executionTimeWithinMinutes"
    >
  ) {
    return new Action({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }

  static create(params: Omit<ExcludeMethods<Action>, "id" | "createdAt" | "updatedAt">) {
    return new Action({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
