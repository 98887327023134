import { useCallback } from "react";

import { actionConditionDefaultValue } from "./common/defaultValue/actionConditionDefaultValue";
import { deliveryTimingDefaultValue } from "./common/defaultValue/deliveryTimingDefaultValue";
import { ActionType, InputState } from "./inputStateSchema";

export const useGenerateDefaultValuesForCreate = () => {
  const generateDefaultValuesForCreate = useCallback(
    ({ actionType }: { actionType: ActionType }): InputState => {
      return {
        actionSetting: {
          actionType,
          recruitmentStatusId: undefined,
          onnTaskId: undefined,
          onnEventId: undefined,
          text: undefined,
        },
        deliveryTiming: deliveryTimingDefaultValue,
        actionCondition: actionConditionDefaultValue,
      };
    },
    []
  );

  return { generateDefaultValuesForCreate };
};
