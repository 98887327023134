import {
  OnnEventAnswer,
  ListOnnEventAnswersRequest,
  ListOnnEventAnswersResponse,
  Employee,
} from "@onn/common";
import useSWR, { SWRResponse, mutate } from "swr";

import { useMutateOnnEventAnswers } from "./useMutateOnnEventAnswer";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export class OnnEventAnswerWithEmployee extends OnnEventAnswer {
  employee: Employee;
  constructor(init: ExcludeMethods<OnnEventAnswerWithEmployee>) {
    super(init);
    this.employee = init.employee;
  }
}

const getKey = (onnEventId?: string) => {
  return onnEventId ? `listOnnEventAnswersWithEmployee-${onnEventId}` : null;
};

type Res = SWRResponse<OnnEventAnswerWithEmployee[], Error> & {
  mutateOnnEventAnswers: () => Promise<void>;
};
export const useOnnEventAnswersWithEmployee = ({ onnEventId }: { onnEventId?: string }): Res => {
  const mutateOnnEventAnswers = useMutateOnnEventAnswers({ onnEventId });
  const swrRes = useSWR(getKey(onnEventId), async () => {
    const response = await functionOperator.httpsCallFor2ndGen<
      ReturnType<typeof ListOnnEventAnswersRequest.createRequestBody>,
      typeof ListOnnEventAnswersResponse.ResponseBody
    >(
      "onneventanswer",
      ListOnnEventAnswersRequest.createRequestBody({
        onnEventId: onnEventId || "",
        join: ["employeeId"],
      })
    );
    const res = ListOnnEventAnswersResponse.convertUnixTimeToDate(response.data);
    return res
      .map((v) => {
        return v.employee
          ? new OnnEventAnswerWithEmployee({
              ...v,
              employee: v.employee as Employee,
            })
          : false;
      })
      .filter((a): a is OnnEventAnswerWithEmployee => a instanceof OnnEventAnswerWithEmployee);
  });

  // mutateOnnEventAnswersをswrResに追加
  (swrRes as Res).mutateOnnEventAnswers = mutateOnnEventAnswers;

  return swrRes as Res;
};

export const mutateOnnEventAnswersWithEmployee = (onnEventId: string) => {
  mutate(getKey(onnEventId));
};
