import { v4 } from "uuid";

import { z } from "zod";

import {
  IAddressSchema,
  IAffiliationSchema,
  IEmployeeInformation,
  IGraduationYearAndMonthSchema,
  IKanaNameSchema,
  addressSchema,
  affiliationSchema,
  employeeInformationSchema,
  employeeInformationValueSchema,
  graduationYearAndMonthSchema,
  kanaNameSchema,
} from "./schema";

export class KanaName implements IKanaNameSchema {
  static validator = kanaNameSchema;

  readonly lastName?: string;
  readonly firstName?: string;
  constructor(init: ExcludeMethods<KanaName>) {
    KanaName.validator.parse(init);

    this.lastName = init.lastName;
    this.firstName = init.firstName;
  }

  getFullKanaName() {
    return `${this.lastName ?? ""} ${this.firstName ?? ""}`;
  }
}

export class Address implements IAddressSchema {
  static validator = addressSchema;

  readonly postalCode?: string;
  readonly prefecture?: string;
  readonly city?: string;
  readonly addressLine1?: string;
  readonly addressLine2?: string;
  constructor(init: ExcludeMethods<Address>) {
    Address.validator.parse(init);

    this.postalCode = init.postalCode;
    this.prefecture = init.prefecture;
    this.city = init.city;
    this.addressLine1 = init.addressLine1;
    this.addressLine2 = init.addressLine2;
  }

  getFullAddress() {
    return `${this.postalCode ?? ""} ${this.prefecture ?? ""}${this.city ?? ""}${
      this.addressLine1 ?? ""
    }${this.addressLine2 ?? ""}`;
  }

  getFullAddressWithoutPostalCode() {
    return `${this.prefecture ?? ""}${this.city ?? ""}${this.addressLine1 ?? ""}${
      this.addressLine2 ?? ""
    }`;
  }
}

export class GraduationYearAndMonth implements IGraduationYearAndMonthSchema {
  static validator = graduationYearAndMonthSchema;

  readonly graduationYear?: number;
  readonly graduationMonth?: number;
  constructor(init: ExcludeMethods<GraduationYearAndMonth>) {
    GraduationYearAndMonth.validator.parse(init);

    this.graduationYear = init.graduationYear;
    this.graduationMonth = init.graduationMonth;
  }

  getFullText() {
    return `${this.graduationYear ? `${this.graduationYear}年` : ""} ${
      this.graduationMonth ? `${this.graduationMonth}月` : ""
    }`;
  }
}

export class Affiliation implements IAffiliationSchema {
  static validator = affiliationSchema;

  readonly schoolName?: string;
  readonly faculty?: string;
  readonly department?: string;
  constructor(init: ExcludeMethods<Affiliation>) {
    Affiliation.validator.parse(init);

    this.schoolName = init.schoolName;
    this.faculty = init.faculty;
    this.department = init.department;
  }

  getFullText() {
    return [this.schoolName, this.faculty, this.department].filter((v) => !!v).join(" ");
  }
}

// NOTE: IEmployeeInformation は prisma の型を使用していない
// TODO[EmployeeInformation正規化]: JSON型が解消されたら、prisma の型を使用するようにする
export class EmployeeInformation implements IEmployeeInformation {
  static validator = employeeInformationSchema;

  readonly id: string;
  value: {
    kanaName?: KanaName;
    gender?: string;
    dateOfBirth?: Date;
    phoneNumber?: string;
    phoneNumberWithoutHyphen?: string;
    homePhoneNumber?: string;
    homePhoneNumberWithoutHyphen?: string;
    address?: Address;
    hometownAddress?: Address;
    graduationYearAndMonth?: GraduationYearAndMonth;
    externalId?: string;
    affiliation?: Affiliation;
    resumeFilePaths?: string[];
    entrySheetFilePaths?: string[];
    spiFilePaths?: string[];
    offerAcceptanceDeadline?: Date;
  };
  readonly employeeId: string;
  readonly tenantId: string;
  constructor(init: z.infer<typeof employeeInformationSchema>) {
    EmployeeInformation.validator.parse(init);

    this.id = init.id;
    this.employeeId = init.employeeId;
    this.tenantId = init.tenantId;
    this.value = {
      ...init.value,
      kanaName: init.value.kanaName ? new KanaName(init.value.kanaName) : undefined,
      phoneNumberWithoutHyphen: init.value.phoneNumber?.replace(/-/g, ""),
      homePhoneNumberWithoutHyphen: init.value.homePhoneNumber?.replace(/-/g, ""),
      address: init.value.address ? new Address(init.value.address) : undefined,
      hometownAddress: init.value.hometownAddress
        ? new Address(init.value.hometownAddress)
        : undefined,
      graduationYearAndMonth: init.value.graduationYearAndMonth
        ? new GraduationYearAndMonth(init.value.graduationYearAndMonth)
        : undefined,
      affiliation: init.value.affiliation ? new Affiliation(init.value.affiliation) : undefined,
      resumeFilePaths: init.value.resumeFilePaths,
      entrySheetFilePaths: init.value.entrySheetFilePaths,
      spiFilePaths: init.value.spiFilePaths,
    };
  }

  static create(
    params: Omit<Optional<z.infer<typeof employeeInformationSchema>, "id">, "value"> & {
      value: Omit<
        z.infer<typeof employeeInformationValueSchema>,
        "phoneNumberWithoutHyphen" | "homePhoneNumberWithoutHyphen"
      >;
    }
  ): EmployeeInformation {
    return new EmployeeInformation({
      ...params,
      id: params.id ?? v4(),
    });
  }

  updateValue(
    value: Omit<
      z.infer<typeof employeeInformationValueSchema>,
      "phoneNumberWithoutHyphen" | "homePhoneNumberWithoutHyphen"
    >
  ) {
    return new EmployeeInformation({
      ...this,
      value: {
        ...this.value,
        ...value,
      },
    });
  }
}
