import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@material-ui/core";
import { PopoverOrigin, PopoverProps, Stack } from "@mui/material";
import { AnyTriggerSetting } from "@onn/common";
import React, { FC, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { SelectorOfOnnEvent } from "./Selectors/SelectorOfOnnEvent";
import { SelectorOfOnnEventStatus } from "./Selectors/SelectorOfOnnEventStatus";
import { SelectorOfOnnTask } from "./Selectors/SelectorOfOnnTask";
import { SelectorOfRecruitmentStatus } from "./Selectors/SelectorOfRecruitmentStatus";

import { Button, Typography } from "~/components/uiParts";
import { Popover } from "~/components/uiParts/Popover";
import { usePageContext } from "~/pages/scenario/pageContext";

const triggerSettingsFormSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("AnswerOnnEventTriggerSetting"),
    onnEventId: z.string({ required_error: "イベントを選択してください" }),
  }),
  z.object({
    type: z.literal("AnswerOnnTaskTriggerSetting"),
    onnTaskId: z.string({ required_error: "タスクを選択してください" }),
  }),
  z.object({
    type: z.literal("ChangeRecruitmentStatusTriggerSetting"),
    recruitmentStatusId: z.string({ required_error: "選考ステータスを選択してください" }),
  }),
  z.object({
    type: z.literal("ChangeStatusOfOnnEventTriggerSetting"),
    onnEventId: z.string({ required_error: "イベントを選択してください" }),
    changeType: z.literal("ATTEND").default("ATTEND"), // NOTE: ChangeStatusOfOnnEventTriggerSettingはATTEND確定のためフォーム管理しない
  }),
  z.object({
    type: z.literal("EvaluateOnnEventTriggerSetting"),
    onnEventId: z.string({ required_error: "イベントを選択してください" }),
  }),
]);

export type TriggerSettingsFormInputValue = z.infer<typeof triggerSettingsFormSchema>;

type Props = {
  backToSelectTriggerType: () => void;
  currentTriggerSetting?: AnyTriggerSetting;
  triggerSettingType: AnyTriggerSetting["type"];
  anchorEl: PopoverProps["anchorEl"];
  onClose: () => void;
  isOpen: boolean;
  onApplyTriggerSetting: (inputValue: TriggerSettingsFormInputValue) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

export const PopoverOfApplyTriggerSettings: FC<Props> = ({
  backToSelectTriggerType,
  currentTriggerSetting,
  triggerSettingType,
  anchorEl,
  onClose,
  isOpen,
  onApplyTriggerSetting,
  anchorOrigin,
  transformOrigin,
}) => {
  const { selectedRecruitmentStatus } = usePageContext();
  const { control, formState, handleSubmit } = useForm<TriggerSettingsFormInputValue>({
    defaultValues: {
      ...currentTriggerSetting,
      type: triggerSettingType,
    },
    resolver: zodResolver(triggerSettingsFormSchema),
  });

  const handleClickApplyButton = handleSubmit((data) => {
    onApplyTriggerSetting(data);
    onClose();
  });
  const isSubmitButtonDisabled = formState.isSubmitting || !formState.isValid;

  const { title, description, infoIconText, form } = useMemo(() => {
    switch (triggerSettingType) {
      case "AnswerOnnTaskTriggerSetting": {
        return {
          title: "タスクが「回答済み」になった時",
          description: "対象となるタスクを選択してください",
          infoIconText: "候補者により対象タスクのステータスが“回答済み”になった際に発火されます。",
          form: (
            <Controller
              name="onnTaskId"
              control={control}
              render={({ field, fieldState }) => (
                <SelectorOfOnnTask
                  value={field.value}
                  onChange={(taskId) => field.onChange(taskId)}
                  errorMessage={fieldState.error?.message ?? ""}
                />
              )}
            />
          ),
        };
      }
      case "ChangeRecruitmentStatusTriggerSetting": {
        return {
          title: "選考ステータスが変更された時",
          description: "対象となる選考ステータスを選択してください。",
          infoIconText: "選考ステータスが手動または自動で変更された際に発火されます。",
          form: (
            <Controller
              name="recruitmentStatusId"
              control={control}
              render={({ field, fieldState }) => (
                <SelectorOfRecruitmentStatus
                  selectedRecruitmentStatusId={field.value}
                  onChange={(recruitmentStatusId) => field.onChange(recruitmentStatusId)}
                  errorMessage={fieldState.error?.message ?? ""}
                  selectableRecruitmentStatuses={[selectedRecruitmentStatus]}
                />
              )}
            />
          ),
        };
      }
      case "AnswerOnnEventTriggerSetting":
      case "ChangeStatusOfOnnEventTriggerSetting": {
        return {
          title: "イベントのステータスが変更された時",
          description: "対象となるイベントとステータスを選択してください。",
          infoIconText:
            "候補者または管理者により対象イベントのステータスが変更された際に発火されます。",
          form: (
            <Stack direction="column" rowGap="16px">
              <Controller
                name="onnEventId"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectorOfOnnEvent
                    value={field.value}
                    onChange={(id) => field.onChange(id)}
                    errorMessage={fieldState.error?.message ?? ""}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectorOfOnnEventStatus
                    value={field.value}
                    onChange={(status) => field.onChange(status)}
                    errorMessage={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Stack>
          ),
        };
      }
      case "EvaluateOnnEventTriggerSetting": {
        return {
          title: "候補者の評価が入力された時",
          description: "評価の対象イベントを選択してください。",
          infoIconText: "選考タイプのイベントで選考担当からの評価が入力された際に発火されます",
          form: (
            <Controller
              name="onnEventId"
              control={control}
              render={({ field, fieldState }) => (
                <SelectorOfOnnEvent
                  value={field.value}
                  onChange={(id) => field.onChange(id)}
                  errorMessage={fieldState.error?.message ?? ""}
                />
              )}
            />
          ),
        };
      }
      default: {
        const _exhaustiveCheck: never = triggerSettingType;
        return _exhaustiveCheck;
      }
    }
  }, [control, selectedRecruitmentStatus, triggerSettingType]);

  return (
    <Popover
      id={"MenuOfChangeRecruitmentStatus"}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      title={title}
      onClickArrowLeftIconButton={backToSelectTriggerType}
      infoIconText={infoIconText}
      anchorOrigin={
        anchorOrigin || {
          vertical: "bottom",
          horizontal: "left",
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: "top",
          horizontal: "left",
        }
      }
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        style={{
          gap: "16px",
        }}
      >
        <Typography variant="caption">{description}</Typography>
        {form}
        <Button
          fullWidth
          borderRadius="regular"
          variant="contained"
          color="primary"
          disabled={isSubmitButtonDisabled}
          onClick={() => handleClickApplyButton()}
        >
          適用
        </Button>
      </Box>
    </Popover>
  );
};
