import { useSnackbar } from "~/hooks/shared";

export const useEnqueueInconsistencyErrorSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueInconsistencyErrorSnackbar = () => {
    enqueueSnackbar(
      "アクション条件の設定に失敗しました。もう一度お試しいただけますでしょうか。もし解決しない場合はお問い合わせください。",
      { variant: "error" }
    );
  };

  return { enqueueInconsistencyErrorSnackbar };
};
