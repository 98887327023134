import { ContactMessageTemplateFolder, ContactMessageTemplate } from "@onn/common";
import { captureException } from "@sentry/react";
import { useCallback } from "react";

import { useSWRConfig } from "swr";

import { Key as FolderKey } from "../contactMessageTemplateFolder/useContactMessageTemplateFolder";

import { Key as TemplateKey } from "./useContactMessageTemplates";

import { useSnackbar } from "~/hooks/shared";

import { apiClient } from "~/libs";

export const useMoveContactMessageTemplate = ({
  contactMessageTemplates,
  templateFolders,
  templatesRefetchKey,
  foldersRefetchKey,
}: {
  contactMessageTemplates: ContactMessageTemplate[];
  templateFolders: ContactMessageTemplateFolder[];
  templatesRefetchKey: TemplateKey;
  foldersRefetchKey: FolderKey;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  const moveContactMessageTemplate = useCallback(
    async (targetTemplate: ContactMessageTemplate, folderId: string) => {
      try {
        const optimisticData = contactMessageTemplates.filter((t) => t.id !== targetTemplate.id);

        await mutate(
          templatesRefetchKey,
          async () => {
            await apiClient.patch("/api/contact-message-templates", {
              id: targetTemplate.id,
              ["title"]: targetTemplate.title,
              ["text"]: targetTemplate.text,
              ["folderId"]: folderId,
            });
            mutate(foldersRefetchKey);
            return mutate(templatesRefetchKey);
          },
          {
            optimisticData,
          }
        );

        const targetFolder = templateFolders.find((folder) => folder.id === folderId);
        if (!targetFolder) {
          throw new Error("フォルダが見つかりません");
        }
        enqueueSnackbar(`「${targetFolder.name}」フォルダに移動しました`, { variant: "success" });
      } catch (e) {
        enqueueSnackbar("テンプレートの移動に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useUpdateContactMessageTemplate:moveContactMessageTemplate",
          },
        });
      }
    },
    [
      contactMessageTemplates,
      enqueueSnackbar,
      mutate,
      templatesRefetchKey,
      templateFolders,
      foldersRefetchKey,
    ]
  );
  return { moveContactMessageTemplate };
};
