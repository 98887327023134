import liff from "@line/liff";
import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";

import { Typography } from "../uiParts";

import logoSquare from "~/images/logo-square.svg";
import { captureException } from "~/util";

const LONG_RENDERING_TIME = 10_000;

type Props = {
  ns?: string;
  enableCaptureException?: boolean;
};

// NOTE: 初回ローディングなのであまりにも長い時間かかる場合にエラーをキャプチャできるようにしている
export const LoadingForInitialRendering = ({ ns, enableCaptureException }: Props): JSX.Element => {
  useEffect(() => {
    if (!enableCaptureException) return;

    if (!liff.isInClient()) {
      return;
    }

    const timer = setTimeout(async () => {
      const profile = await liff.getProfile().catch((e) => {
        captureException({
          error: new Error(
            `[${ns || "no namespace"}] LoadingForInitialRenderingでプロフィールが取れませんでした`
          ),
          tags: {
            type: "LoadingForInitialRendering",
          },
          extras: {
            location: window.location.href,
            isCookieEnabled: navigator.cookieEnabled,
            e,
          },
        });
      });
      if (!profile) {
        return;
      }

      const userId = profile.userId;
      // NOTE: エラー発生していた対象ユーザーの userId
      const isTarget = userId === "U30806ddfd7b063f603e4bf8d0832870b";
      captureException({
        error: new Error(
          `[${ns || "no namespace"}]${
            isTarget ? "[要確認]" : ""
          } 比較的長いローディングが発生しました (${LONG_RENDERING_TIME}ms)`
        ),
        tags: {
          type: "LoadingForInitialRendering",
        },
        extras: {
          location: window.location.href,
          isCookieEnabled: navigator.cookieEnabled,
          profile,
          isTarget,
        },
      });
    }, LONG_RENDERING_TIME);

    return () => clearTimeout(timer);
  }, [enableCaptureException, ns]);

  return (
    <LoadingWrapper>
      <StyledLogoImage height={64} width={64} src={logoSquare} alt="loading-image" />
      <Typography variant="caption">読み込み中です</Typography>
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled(Box)`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
`;

const StyledLogoImage = styled.img`
  animation: blinking 1s ease-in-out infinite alternate;
  @keyframes blinking {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
