import React, { FC, ReactNode } from "react";

import { v4 } from "uuid";

import { useSessionStorage } from "~/hooks/shared";

export const CreateSessionIdInSessionStorage: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { retrieveValue, storeValue } = useSessionStorage();
  const sessionIdInStorage = retrieveValue("sessionId");
  if (!sessionIdInStorage) {
    storeValue("sessionId", v4());
  }
  return <>{children}</>;
};
