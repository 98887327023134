import { CandidateDate, OnnEvent } from "@onn/common";
import React, { FC, useEffect } from "react";

import { Completion } from "./completion";

import { useCurrentUser } from "~/hooks/employee";
import { useCreateEmployeeActiveLog } from "~/hooks/employeeActiveLog";

type Props = {
  onnEvent: OnnEvent;
  candidateDate?: CandidateDate;
};

export const Thanks: FC<Props> = ({ onnEvent, candidateDate }) => {
  const { currentUser } = useCurrentUser();

  const { createEmployeeLog } = useCreateEmployeeActiveLog();

  useEffect(() => {
    createEmployeeLog(
      "VISITED_ONN_EVENT_ATTENDANCE_THANKS_PAGE",
      onnEvent.id,
      currentUser.tenantId
    );
    // NOTE: アクセスしたタイミングのみ記録するため、eslint-disable-next-lineを記述
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Completion
      title="参加登録完了"
      description={`「${onnEvent.title}」への参加登録が完了しました。`}
      candidateDate={candidateDate}
    />
  );
};
