import { Box } from "@material-ui/core";
import React, { useMemo } from "react";

import { Control, Controller, UseFormTrigger } from "react-hook-form";

import styled from "styled-components";

import { ContactMessagesOrderForm } from "../hooks/contactMessagesOrderFormSchema";

import {
  Typography,
  FormControlLabel,
  Checkbox,
  DatePickerV2,
  SelectForm,
} from "~/components/uiParts";
import { FormWithTitlePaper } from "~/components/uiParts/FormWithTitlePaper";

type Props = {
  control: Control<ContactMessagesOrderForm, unknown>;
  trigger: UseFormTrigger<ContactMessagesOrderForm>;
  canSelectScheduledDate: boolean;
  selectableTimeMenuItems: {
    value: string;
    name: string;
  }[];
};

export const ScheduleDateFormPaper = ({
  control,
  trigger,
  canSelectScheduledDate,
  selectableTimeMenuItems,
}: Props): JSX.Element => {
  const dateForm = useMemo(
    () => (
      <Box mr="16px" width="200px">
        <Controller
          name="scheduledDate.date"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledDatePicker
              fullWidth
              placeholder="日程を選択"
              disablePast={true}
              error={!!error}
              helperText={error?.message}
              disabled={!canSelectScheduledDate}
              {...field}
              onChange={(data) => {
                field.onChange(data);
                trigger("scheduledDate.time");
              }}
            />
          )}
        />
      </Box>
    ),
    [canSelectScheduledDate, control, trigger]
  );

  const timeForm = useMemo(
    () => (
      <Box width="120px">
        <Controller
          name="scheduledDate.time"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <StyledSelectForm
                fullWidth
                icon="clock"
                isSmall={true}
                menuItems={selectableTimeMenuItems}
                selected={field.value}
                errorBorder={!!error}
                errorText={error?.message}
                disabled={!canSelectScheduledDate}
                {...field}
                onChange={(selection) => {
                  field.onChange(selection);
                  trigger("scheduledDate.date");
                }}
              />
            );
          }}
        />
      </Box>
    ),
    [canSelectScheduledDate, control, selectableTimeMenuItems, trigger]
  );

  const isInstantForm = useMemo(
    () => (
      <Controller
        name="scheduledDate.isInstant"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                onChange={(bool) => {
                  field.onChange(bool);
                  trigger("scheduledDate.date");
                  trigger("scheduledDate.time");
                }}
              />
            }
            labelPlacement="end"
            label={
              <Typography variant="body2" color="textPrimary" noWrap>
                即時配信する
              </Typography>
            }
          />
        )}
      />
    ),
    [control, trigger]
  );

  return (
    <FormWithTitlePaper
      title="配信タイミング"
      form={
        <>
          <Box display="flex" minHeight="50px">
            {dateForm}
            {timeForm}
          </Box>
          {isInstantForm}
        </>
      }
    />
  );
};

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 228px;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    height: 50px;
  }
`;
