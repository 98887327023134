import React, { FC, useMemo } from "react";

import { SelectForm } from "~/components/uiParts";

export const SelectorOfOnnEventStatus: FC<{
  value: string;
  onChange: (status: string) => void;
  errorMessage?: string;
}> = ({ onChange, value, errorMessage }) => {
  const menuItems = useMemo(() => {
    return [
      {
        value: "ChangeStatusOfOnnEventTriggerSetting",
        name: "参加済み",
      },
      {
        value: "AnswerOnnEventTriggerSetting",
        name: "回答済み",
      },
    ];
  }, []);

  return (
    <SelectForm
      selected={value}
      menuItems={menuItems}
      onChange={(e) => onChange(e.target.value as string)}
      errorText={errorMessage}
    />
  );
};
