import { z } from "zod";

import { OnnEventEvaluationSchema } from "../../../domain/_gen/zodSchema";

export const onnEventEvaluationSchema = OnnEventEvaluationSchema.merge(
  z.object({
    onnEventEvaluationRankId: z.string().optional(),
    text: z.string().optional(),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluation extends z.infer<typeof onnEventEvaluationSchema> {}
