import { Action } from "@onn/common";

export const createDifferenceLabel = (
  action: Pick<
    ExcludeMethods<Action>,
    "executionTimeAfterDays" | "executionTimeAtHours" | "executionTimeWithinMinutes"
  >
): string => {
  if (action.executionTimeAfterDays && action.executionTimeAtHours) {
    return createLabelForAfterTomorrow({
      executionTimeAfterDays: action.executionTimeAfterDays,
      executionTimeAtHours: action.executionTimeAtHours,
    });
  }

  if (action.executionTimeWithinMinutes) {
    return createLabelForAFewMinutesLater(action.executionTimeWithinMinutes);
  }

  return "即時配信";
};

// NOTE: executionTimeAfterDaysとexecutionTimeAtHoursは同時に設定される
// ex) 1日後 10時 配信
const createLabelForAfterTomorrow = ({
  executionTimeAfterDays,
  executionTimeAtHours,
}: {
  executionTimeAfterDays: number;
  executionTimeAtHours: number;
}) => {
  return `${executionTimeAfterDays}日後 ${executionTimeAtHours}時 配信`;
};

const createLabelForAFewMinutesLater = (executionTimeWithinMinutes: number) => {
  if (executionTimeWithinMinutes < 60) {
    return `${executionTimeWithinMinutes}分後 配信`;
  }

  const minutes = executionTimeWithinMinutes % 60;
  const hours = Math.trunc(executionTimeWithinMinutes / 60);
  return minutes < 1 ? `${hours}時間後 配信` : `${hours}時間${minutes}分後 配信`;
};
