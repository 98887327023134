import { useState } from "react";

import { useSnackbar } from "../shared";

import { useMutateAnnouncements } from "./useAnnouncements";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useDeleteAnnouncement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAnnouncements } = useMutateAnnouncements();

  const execDeleteAnnouncement = async (announcementId: string) => {
    try {
      await apiClient.delete("/announcement_api/announcement", {
        announcementId,
      });
      enqueueSnackbar("イベントを削除しました", { variant: "success" });
      return;
    } catch (e) {
      enqueueSnackbar("削除に失敗しました。管理者より連絡がくるまで、お待ちください。", {
        variant: "error",
      });
      captureException({
        error: e as Error,
        tags: {
          type: "useDeleteAnnouncement:execDeleteAnnouncement",
        },
      });
    } finally {
      mutateAnnouncements();

      setIsLoading(false);
    }
  };

  return { execDeleteAnnouncement, isLoading };
};
