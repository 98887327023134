import { ChangeAuthenticationLog, SystemMessage } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { AllContactMessage } from "./type";

import { NewGraduateContactMessageItem } from "~/components/domains/contactMessage";
import { ChangeAuthenticationLogMessage } from "~/components/domains/contactRooms/ContactRoomItem/parts/MessageListView/MessageItems";
import { SystemMessageItem } from "~/components/domains/systemMessage/SystemMessageItem";
import { Paper, Typography } from "~/components/uiParts";
import { useTenant } from "~/hooks/tenant";

export const MessagesList: FC<{
  contactMessages: AllContactMessage[];
  fileSignedUrlMapObj: Record<string, string>;
}> = ({ contactMessages, fileSignedUrlMapObj }) => {
  const { tenant } = useTenant();

  return (
    <>
      {contactMessages.map((message) => {
        // システムメッセージの表示
        if (message instanceof SystemMessage) {
          return (
            <SystemMessageItem
              key={message.id}
              systemMessage={message}
              tenantName={tenant.tenantName}
              isDisabledButton={false}
              variant="left"
            />
          );
        }
        // 認証方法変更ログの場合
        if (message instanceof ChangeAuthenticationLog) {
          return <ChangeAuthenticationLogMessage toType={message.toType} key={message.id} />;
        }

        // LINEグループコンタクトルームの場合、退会メッセージを表示する
        if ("type" in message) {
          const log = message;

          return (
            <StyledUnavailableMessagePaper elevation={0} key={log.createdAt.toISOString()}>
              <Typography variant="body2">{`${log.lineUser.displayName}さんがグループを退会しました`}</Typography>
            </StyledUnavailableMessagePaper>
          );
        }

        const variant = message.isNewHireMessage ? "right" : "left";

        return (
          <NewGraduateContactMessageItem
            key={message.id}
            message={message}
            variant={variant}
            fileSignedUrlMapObj={fileSignedUrlMapObj}
          />
        );
      })}
    </>
  );
};

const StyledUnavailableMessagePaper = styled(Paper)`
  width: 80%;
  text-align: center;
  padding: 16px;
  margin: 8px auto 0 auto;
  &.MuiPaper-root {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
  &.MuiPaper-radius {
    border-radius: 10px;
  }
`;
