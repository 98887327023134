import { v4 } from "uuid";
import { z } from "zod";

const phases = ["RECRUITING", "FOLLOW_UP_JOB_OFFER"] as const;
export type Phase = (typeof phases)[number];

export const spaceSchema = z.object({
  id: z.string().default(() => v4()),
  name: z.string().max(3),
  tenantId: z.string(),
  order: z.number(),
  phase: z.enum(phases),
  defaultRecruitmentStatusId: z.string(),
  createdAt: z.date().default(() => new Date()),
  updatedAt: z.date().default(() => new Date()),
});

export type ISpace = z.infer<typeof spaceSchema>;
