import { User } from "firebase/auth";
import { useContext } from "react";

import { AuthenticationContext } from "~/components/providers";

type GuardedResult = {
  authUser: User;
  isAuthenticated: boolean;
};
type NonGuardedResult = {
  authUser: User | null;
  isAuthenticated: boolean;
  isReady: boolean;
};

export const useAuthentication = (): GuardedResult => {
  const { authUser, guardAuthUser } = useContext(AuthenticationContext);

  if (authUser === undefined) {
    throw new Error("AuthenticationProvider の子コンポーネントでのみ使用できます");
  }

  if (!guardAuthUser(authUser)) {
    throw new Error("AuthenticationGuard の子コンポーネントでのみ使用できます");
  }

  return { authUser, isAuthenticated: !!authUser };
};

export const useAuthenticationNonGuarded = (): NonGuardedResult => {
  const { authUser, isReady } = useContext(AuthenticationContext);

  if (authUser === undefined) {
    throw new Error("AuthenticationProvider の子コンポーネントでのみ使用できます");
  }

  return { authUser, isAuthenticated: !!authUser, isReady };
};
