import {
  LinePlatformRichMenu,
  PortalRichMenuRelationV2,
  RegistrationRichMenuRelation,
} from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

export const generateAllRichMenusKeyObj = ({
  spaceId,
  scenarioId,
}: {
  spaceId: string;
  scenarioId: string;
}) => {
  return {
    endpoint: `/rich_menu_api/rich-menus`,
    spaceId,
    scenarioId,
  } as const;
};

export const useAllRichMenus = (scenarioId: string) => {
  const { currentSpace } = useCurrentSpace();
  return useSWR(
    generateAllRichMenusKeyObj({ spaceId: currentSpace.id, scenarioId }),
    async ({ endpoint }) => {
      const response = await apiClient.get(endpoint, { scenarioId });
      return {
        portalRichMenusRelationWithRichMenu: response.data.portalRichMenuRelationWithRichMenu.map(
          ({ relation, richMenu }) => ({
            relation: new PortalRichMenuRelationV2(relation),
            richMenu: new LinePlatformRichMenu(richMenu),
          })
        ),
        registrationRichMenu: response.data.registrationRichMenu
          ? {
              relation: new RegistrationRichMenuRelation(
                response.data.registrationRichMenu.relation
              ),
              richMenu: new LinePlatformRichMenu(response.data.registrationRichMenu.richMenu),
            }
          : null,
      };
    }
  );
};

export const useDeleteAllRichMenusCache = (scenarioId: string) => {
  const { currentSpace } = useCurrentSpace();
  const { cache } = useSWRConfig();
  const deleteAllRichMenusCache = useCallback(() => {
    cache.delete(
      JSON.stringify(generateAllRichMenusKeyObj({ spaceId: currentSpace.id, scenarioId }))
    );
  }, [cache, currentSpace.id, scenarioId]);

  return { deleteAllRichMenusCache };
};
