import { RegistrationInvitationLink } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useLiffId } from "~/hooks/liff";

import { apiClient } from "~/libs";

export const useLastRegistrationInvitationLink = ({
  liffAccessToken,
}: {
  liffAccessToken?: string | null;
}): SWRResponse<RegistrationInvitationLink | null, Error> => {
  const liffId = useLiffId();

  const shouldFetch = liffAccessToken && liffId;
  const key = shouldFetch
    ? ([
        "/api/registration-invitation-link/retrieve-last-registration-invitation-link",
        liffAccessToken,
        liffId,
      ] as const)
    : null;

  return useSWR<RegistrationInvitationLink | null>(
    key,
    async ([endpoint, lineAccessToken, liffId]: [
      "/api/registration-invitation-link/retrieve-last-registration-invitation-link",
      string,
      string
    ]) => {
      const response = await apiClient.post(endpoint, {
        lineAccessToken,
        liffId,
      });
      return response.data.registrationInvitationLink
        ? new RegistrationInvitationLink(response.data.registrationInvitationLink)
        : null;
    }
  );
};
