import { v4 } from "uuid";

import {
  IOnWebsiteNotice,
  onWebsiteNoticeActionTypes,
  onWebsiteNoticeSchema,
  onWebsiteNoticeTypes,
} from "./schema";

export class OnWebsiteNotice implements IOnWebsiteNotice {
  static validator = onWebsiteNoticeSchema;
  static types = onWebsiteNoticeTypes;
  static actionTypes = onWebsiteNoticeActionTypes;
  readonly id: string;
  readonly tenantId: string;
  readonly targetEmployeeId: string;
  readonly shouldPromptRefetch: boolean;
  readonly shouldPromptContactToDevelopers: boolean;
  readonly type: (typeof OnWebsiteNotice.types)[number];
  readonly actionType?: (typeof OnWebsiteNotice.actionTypes)[number];
  readonly text: string;
  readonly createdAt: Date;
  constructor(init: ExcludeMethods<OnWebsiteNotice>) {
    const parsedInit = onWebsiteNoticeSchema.parse(init);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.targetEmployeeId = parsedInit.targetEmployeeId;
    this.shouldPromptRefetch = parsedInit.shouldPromptRefetch;
    this.shouldPromptContactToDevelopers = parsedInit.shouldPromptContactToDevelopers;
    this.type = parsedInit.type;
    this.actionType = parsedInit.actionType;
    this.text = parsedInit.text;
    this.createdAt = parsedInit.createdAt;
  }

  public static createNew(init: Omit<ExcludeMethods<OnWebsiteNotice>, "id" | "createdAt">) {
    return new OnWebsiteNotice({
      ...init,
      id: v4(),
      createdAt: new Date(),
    });
  }
}
