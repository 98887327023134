import React, { memo } from "react";

import { AnyInputCondition } from "../../types/condition";

import { BriefingSessionCategoriesConditionSelector } from "./condition-selectors/BriefingSessionCategoriesConditionSelector";
import { SlotFromDateRangeConditionSelector } from "./condition-selectors/SlotFromDateRangeConditionSelector";
import { UndefinedConditionSelector } from "./condition-selectors/UndefinedConditionSelector";

type Props = {
  index: number;
  condition: AnyInputCondition;
};

/**
 * condition の target に応じて Selector を出し分けるコンポーネント
 */
export const ConditionSelector = memo(({ index, condition }: Props): JSX.Element => {
  switch (condition.target) {
    // NOTE: ここに条件の種類ごとのコンポーネントを追加していく
    case "slotFromDateRange":
      return <SlotFromDateRangeConditionSelector index={index} condition={condition} />;
    case "briefingSessionCategoryIds":
      return <BriefingSessionCategoriesConditionSelector index={index} condition={condition} />;
    case "assigneeIds":
    case "eventTypes":
    case "placeIds":
    case "statuses":
      throw new Error("Not implemented");
    case undefined:
      return <UndefinedConditionSelector index={index} />;
    default: {
      const _exhaustiveCheck: never = condition;
      return _exhaustiveCheck;
    }
  }
});
