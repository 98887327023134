import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee, Announcement } from "@onn/common";
import React, { useState, MouseEvent, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ContentWithLabel } from "./ContentWithLabel";

import {
  IconButton,
  Paper,
  Typography,
  UserIcon,
  TooltipWhenTextTruncated,
} from "~/components/uiParts";
import { useDeleteAnnouncement } from "~/hooks/announcement";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

export type Props = {
  announcement: Announcement;
  numberOfDistribution: number;
  numberOfReads: number;
  creator: Employee;
  isRounded?: boolean;
};

export const AnnouncementDetailSummaryPaper = memo(
  ({
    isRounded = true,
    announcement,
    creator,
    numberOfReads,
    numberOfDistribution,
  }: Props): JSX.Element => {
    const { currentUser } = useCurrentUser();
    const navigation = useNavigate();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { handleModal } = useModal();
    const { execDeleteAnnouncement } = useDeleteAnnouncement();

    const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const onClickDeliverySetting = useCallback(() => {
      handleCloseMenu();
      navigation(`/announcements/${announcement.id}/delivery_setting/?from_page=detail`);
    }, [announcement.id, handleCloseMenu, navigation]);

    const onClickEditAnnouncement = useCallback(() => {
      handleCloseMenu();
      navigation(`/announcements/${announcement.id}/edit/?from_page=detail`);
    }, [announcement.id, handleCloseMenu, navigation]);

    const onClickDeleteButton = useCallback(() => {
      handleCloseMenu();
      handleModal({
        name: "announcementDeleteConfirmModal",
        args: {
          title: announcement.title,
          onSubmit: async () => {
            await execDeleteAnnouncement(announcement.id);
            navigation("/tools#announcements");
          },
        },
      });
    }, [
      announcement.id,
      announcement.title,
      execDeleteAnnouncement,
      handleCloseMenu,
      handleModal,
      navigation,
    ]);

    return (
      <StyledPaper square={!isRounded}>
        <Box display="flex" alignItems="center" justifyContent="space-between" height={68}>
          <Box display="flex">
            <Box display="flex" alignItems="center" maxWidth="350px">
              <TooltipWhenTextTruncated text={announcement.title}>
                {(ref) => (
                  <StyledTitle ref={ref} bold variant="h4">
                    {announcement.title}
                  </StyledTitle>
                )}
              </TooltipWhenTextTruncated>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box minWidth={50}>
              <Creator creator={creator} />
            </Box>
            <Box ml="40px" minWidth={50}>
              <NumberOfDistribution count={numberOfDistribution} />
            </Box>
            <Box ml="40px" minWidth={50}>
              <NumberOfReads count={numberOfReads} />
            </Box>
            {currentUser.isAdmin() && (
              <Box ml="40px">
                <IconButton icon="menuVert" onClick={handleOpenMenu} />
                <StyledMenu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem onClick={onClickEditAnnouncement}>編集</MenuItem>
                  <MenuItem onClick={onClickDeliverySetting}>配信設定</MenuItem>
                  <MenuItem onClick={onClickDeleteButton}>削除</MenuItem>
                </StyledMenu>
              </Box>
            )}
          </Box>
        </Box>
      </StyledPaper>
    );
  }
);

const Creator = ({ creator }: { creator: Employee }) => {
  return (
    <ContentWithLabel label="作成者">
      <StyledClickableBox display="flex" alignItems="center">
        <Box display="flex" alignItems="center" gridGap={"8px"}>
          <UserIcon
            username={creator.getName()}
            profileIconImageUrl={creator.profileIconImageUrl}
            size={"small"}
            circular={true}
          />
          <Box display="grid" gridRowGap="4px">
            <StyledTypography variant={"caption"} color="textPrimary" noWrap>
              {creator.getName()}
            </StyledTypography>
          </Box>
        </Box>
      </StyledClickableBox>
    </ContentWithLabel>
  );
};

const NumberOfDistribution = ({ count }: { count: number }) => {
  return (
    <ContentWithLabel label="配信数">
      <Typography variant="h3">{count}</Typography>
    </ContentWithLabel>
  );
};

const NumberOfReads = ({ count }: { count: number }) => {
  return (
    <ContentWithLabel label="既読数">
      <Typography variant="h3">{count}</Typography>
    </ContentWithLabel>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const StyledMenu = styled(Menu)`
  margin-top: 8px;
`;

const StyledClickableBox = styled(Box)<{ $width?: number }>`
  cursor: pointer;
  ${({ $width }) => ($width ? `width: ${$width}px` : "width: 100%")}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;

const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
