import { z } from "zod";

import { FormRevisionSchema } from "../../_gen/zodSchema/index";
import { OnnTaskQuestion as Question } from "../Question";

export const formRevisionSchema = FormRevisionSchema.merge(
  z.object({
    questions: z.array(z.record(z.any()).transform((v) => v as Question)),
  })
);

export type IFormRevisionSchema = z.infer<typeof formRevisionSchema>;
