import { Box, Grid } from "@material-ui/core";
import { RecruitmentStatus } from "@onn/common";
import React, { useCallback } from "react";

import { RecruitmentStatusCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import { Choice } from "../../parts/dropdown-menus/Choice";
import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { CommonProps } from "./type";

import { Loading } from "~/components/uiParts";
import { useRecruitmentStatuses } from "~/hooks/recruitmentStatus";

const TARGET = "recruitmentStatus";

type Props = CommonProps<RecruitmentStatusCondition>;
export const RecruitmentStatusConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: recruitmentStatusData, isLoading } = useRecruitmentStatuses({ withDeleted: false });

  const { onChangeStatusIds } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
  });
  const { statusChoices } = useChoices({
    allStatuses: recruitmentStatusData,
  });

  if (isLoading) {
    return <Loading size="small" />;
  }

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target) => onChangeTarget(index, target)}
          />
        </Grid>
        <Grid item xs={9}>
          <SelectMultipleConditionDropdown
            placeHolder="選考ステータスを選択"
            key="recruitmentStatus"
            selectedChoices={statusChoices.filter((c) => condition.statusIds?.includes(c.value))}
            choices={statusChoices}
            withSearch
            searchPlaceholder="選考ステータス名で検索"
            onChange={onChangeStatusIds}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
}: Pick<Props, "index" | "onChangeCondition" | "condition">) => {
  const onChangeStatusIds = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        statusIds: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangeStatusIds,
  };
};

const useChoices = ({ allStatuses }: { allStatuses: RecruitmentStatus[] | undefined }) => {
  if (!allStatuses) {
    return {
      statusChoices: [],
    };
  }

  return {
    statusChoices: allStatuses.map((e) => ({ label: e.label, value: e.id })),
  };
};
