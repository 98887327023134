import { Box } from "@material-ui/core";
import { orderBy } from "lodash";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { PortalOnnEventCard } from "./components";

import { Typography, Loading, Button, Paper } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

import { useOnnEventsForPortal } from "~/hooks/onnEvent";

type Filter = "answered" | "noAnswered";

export const OnnEventIndex: FC = () => {
  const { currentUser } = useCurrentUser();
  const { data: onnEventDataArray, isLoading: isLoadingOnnEvents } = useOnnEventsForPortal({
    employeeId: currentUser.id,
  });

  const [selectedFilterIsAnswered, setSelectedFilterIsAnswered] = useState<Filter>("noAnswered");
  // 回答済フィルターボタンクリック
  const onClickFilterButtonAnswered = () => setSelectedFilterIsAnswered("answered");
  // 未回答フィルターボタンクリック
  const onClickFilterButtonNoAnswered = () => setSelectedFilterIsAnswered("noAnswered");

  // 回答期限が切れていない未回答のイベント
  const noAnsweredAndCanAnswer = useMemo(() => {
    return orderBy(
      (onnEventDataArray || []).filter((v) => !v.isAnswered && v.onnEvent.canAnswer()),
      "onnEvent.deadlineDate",
      "asc"
    );
  }, [onnEventDataArray]);

  // 回答済か回答できないイベント
  const answeredOrCanNotAnswer = useMemo(() => {
    return orderBy(
      (onnEventDataArray || []).filter((v) => v.isAnswered || !v.onnEvent.canAnswer()),
      "onnEvent.deadlineDate",
      "desc"
    );
  }, [onnEventDataArray]);

  if (isLoadingOnnEvents) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <Box pt="40px" px="24px" pb="126px">
      <Typography variant="h2" color="textPrimary">
        イベント
      </Typography>
      <Box mt={5} mb={4}>
        <StyledPaper square elevation={1}>
          <Box display="flex" width="100%">
            <Button
              fullWidth
              onClick={onClickFilterButtonNoAnswered}
              color={selectedFilterIsAnswered === "noAnswered" ? "primary" : "default"}
              variant={selectedFilterIsAnswered === "noAnswered" ? "contained" : "text"}
              borderRadius="regular"
            >
              <Box width="50%">未回答({noAnsweredAndCanAnswer.length})</Box>
            </Button>
            <Button
              fullWidth
              onClick={onClickFilterButtonAnswered}
              color={selectedFilterIsAnswered === "answered" ? "primary" : "default"}
              variant={selectedFilterIsAnswered === "answered" ? "contained" : "text"}
              borderRadius="regular"
            >
              <Box width="50%">回答済({answeredOrCanNotAnswer.length})</Box>
            </Button>
          </Box>
        </StyledPaper>
      </Box>
      {(selectedFilterIsAnswered === "answered"
        ? answeredOrCanNotAnswer
        : noAnsweredAndCanAnswer
      ).map((event) => {
        return (
          <Box key={event.onnEvent.id} mb={2}>
            <PortalOnnEventCard onnEventData={event} />
          </Box>
        );
      })}
    </Box>
  );
};

const StyledPaper = styled(Paper)`
  border-radius: 8px;
  padding: 8px 16px;
`;
