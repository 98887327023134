import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Notice, Typography } from "~/components/uiParts";
import paperBgImageBottom from "~/images/paper_background_bottom.svg";
import paperBgImageTop from "~/images/paper_background_top.svg";
import { mixin } from "~/util";

export const ExpiredChangeAuthenticationTypeLinkPage: FC = () => {
  return (
    <StyledBox px="24px">
      <Notice title="リンクの期限が切れています。" showCloseAnnotation={false}>
        <StyledTypography variant="body1" color="textSecondary" align="center">
          ログイン方法変更のリンクの期限が切れています。
          <br />
          お手数ですが、ポータル画面から再度ログイン方法変更のリンクを発行してください。
        </StyledTypography>
        <StyledButtonContainer width="100%"></StyledButtonContainer>
      </Notice>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  position: absolute;
  left: 0;
  /* FIXME: 一時的に NotFound で背景表示。背景画像はルールを整理して場所を移す */
  background-image: url(${paperBgImageTop}), url(${paperBgImageBottom});
  background-repeat: no-repeat;
  background-position: right top, left bottom;
  background-size: 60%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 32px;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
