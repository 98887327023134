import { v4 } from "uuid";

import {
  IEmployeeInformationDateTypeValue,
  employeeInformationDateTypeValueSchema,
} from "./schema";

export class EmployeeInformationDateTypeValue implements IEmployeeInformationDateTypeValue {
  static readonly type = "DATE" as const;
  type = EmployeeInformationDateTypeValue.type;
  static validator = employeeInformationDateTypeValueSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeId: string;
  readonly employeeInformationDateTypeFieldId: string;
  readonly value: Date;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<EmployeeInformationDateTypeValue>, "type">) {
    EmployeeInformationDateTypeValue.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeId = init.employeeId;
    this.employeeInformationDateTypeFieldId = init.employeeInformationDateTypeFieldId;
    this.value = init.value;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationDateTypeValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): EmployeeInformationDateTypeValue {
    return new EmployeeInformationDateTypeValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<Pick<EmployeeInformationDateTypeValue, "value">>
  ): EmployeeInformationDateTypeValue {
    return new EmployeeInformationDateTypeValue({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
