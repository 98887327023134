import React, { FC } from "react";

import { ColumnType, HeaderRowData, labelMap } from "./const";

import { CellItem, Checkbox, Typography } from "~/components/uiParts";

export const HeaderCell: FC<{
  style: React.CSSProperties;
  columnType: ColumnType;
  isShowRightBorder: boolean;
  headerRowData: HeaderRowData;
}> = ({ columnType, headerRowData: { checkBox }, ...props }) => {
  if (columnType === "checkBox") {
    return (
      <CellItem style={props.style} isShowRightBorder={false}>
        <Checkbox
          checked={checkBox.isChecked}
          indeterminate={checkBox.indeterminate}
          onChange={checkBox.onClick}
        />
      </CellItem>
    );
  }
  const text = labelMap[columnType];

  return (
    <CellItem {...props}>
      <Typography variant="body2">{text}</Typography>
    </CellItem>
  );
};
