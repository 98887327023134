import { Failure, Success, Result, OnnEvent } from "../../../../domain";
import { toCloudFunctionsCompatible } from "../../../../utils/toCloudFunctionsCompatible";
import { onnEventActionTypes } from "../shared";

import { IDeleteOnnEventRequestSchema, deleteOnnEventRequestSchema } from "./schema";

export class DeleteOnnEventRequest implements IDeleteOnnEventRequestSchema {
  constructor(init: DeleteOnnEventRequest) {
    this.onnEventId = init.onnEventId;
  }

  static actionType: typeof onnEventActionTypes.deleteOnnEvent = "deleteOnnEvent";
  onnEventId: OnnEvent["id"];

  static RequestBody: {
    onnEventId: OnnEvent["id"];
  };

  // クライアント側でリクエストボディを作成する
  public static createRequestBody(init: DeleteOnnEventRequest) {
    return toCloudFunctionsCompatible({
      ...init,
      actionType: this.actionType,
    });
  }

  static readonly validator = deleteOnnEventRequestSchema;

  public static validate(
    data: typeof DeleteOnnEventRequest.RequestBody
  ): Result<DeleteOnnEventRequest, Error> {
    const result = DeleteOnnEventRequest.validator.safeParse(data);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new DeleteOnnEventRequest(data));
  }
}
