import { Menu, MenuItem } from "@material-ui/core";
import { Box } from "@mui/material";
import React, { FC, useState, MouseEvent } from "react";

import { IconButton, Tooltip, Typography } from "~/components/uiParts";

export const ControlMenu: FC<{
  onClickDelete: () => void;
  onClickDuplication: () => void;
  deleteMenuOption?: {
    isDisabled: true;
    reason: string;
  };
}> = ({ onClickDelete, onClickDuplication, deleteMenuOption }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClickButton = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const menuOptions = [
    {
      id: "DUPLICATION",
      title: "複製",
      onClick: onClickDuplication,
      isDisabled: false,
      tooltip: "",
    },
    {
      id: "DELETE",
      title: "削除",
      onClick: onClickDelete,
      isDisabled: deleteMenuOption?.isDisabled,
      tooltip: deleteMenuOption?.reason,
    },
  ];

  return (
    <>
      <IconButton icon="menuVert" onClick={handleClickButton} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuOptions.map((option) => (
          <Tooltip key={option.id} title={option.tooltip} placement="right">
            <MenuItem
              key={option.id}
              onClick={(e) => {
                e.stopPropagation();
                option.onClick();
                setAnchorEl(null);
              }}
              disabled={option.isDisabled}
            >
              <Box>
                <Typography variant="body2" content="div">
                  {option.title}
                </Typography>
              </Box>
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </>
  );
};
