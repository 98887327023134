import { Box, Menu, FormControlLabel } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useContext, useMemo } from "react";
import styled from "styled-components";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";

import { Typography, Checkbox } from "~/components/uiParts";

const useDisplayRecruitmentStatuses = (includeRejectedAndWithdrew: boolean) => {
  const { recruitmentStatuses } = useContext(RecruitmentStatusesContext);

  const displayRecruitmentStatuses = useMemo(() => {
    return includeRejectedAndWithdrew
      ? recruitmentStatuses
      : recruitmentStatuses.filter(({ type }) => type !== "rejected" && type !== "withdrew");
  }, [recruitmentStatuses, includeRejectedAndWithdrew]);

  return { displayRecruitmentStatuses };
};

export const RecruitmentStatusFilterMenu = ({
  anchorEl,
  setAnchorEl,
  selectedRecruitmentStatusIds,
  setSelectedRecruitmentStatusIds,
  includeRejectedAndWithdrew,
}: {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  selectedRecruitmentStatusIds: string[];
  setSelectedRecruitmentStatusIds: (ids: string[]) => void;
  includeRejectedAndWithdrew: boolean;
}) => {
  const { displayRecruitmentStatuses } = useDisplayRecruitmentStatuses(includeRejectedAndWithdrew);

  return (
    <Menu
      key="statusFilter"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: -8, horizontal: "right" }}
    >
      <Box py="16px" width="240px" display="flex" flexDirection="column">
        <Box
          px="24px"
          maxHeight={300}
          overflow="auto"
          display="flex"
          flexDirection="column"
          gridGap="8px"
        >
          {displayRecruitmentStatuses.map(({ id: recruitmentStatusId, label }) => {
            const isSelected = selectedRecruitmentStatusIds.includes(recruitmentStatusId);
            const onChange = () =>
              isSelected
                ? setSelectedRecruitmentStatusIds(
                    selectedRecruitmentStatusIds.filter(
                      (statusId) => statusId !== recruitmentStatusId
                    )
                  )
                : setSelectedRecruitmentStatusIds([
                    ...selectedRecruitmentStatusIds,
                    recruitmentStatusId,
                  ]);
            return (
              <React.Fragment key={recruitmentStatusId}>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={isSelected}
                      onChange={onChange}
                      name={recruitmentStatusId}
                      value={recruitmentStatusId}
                    />
                  }
                  label={
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      gridGap="4px"
                      overflow="hidden"
                    >
                      <Typography variant="body2" color="textSecondary" noWrap>
                        {label}
                      </Typography>
                    </Box>
                  }
                />
              </React.Fragment>
            );
          })}
          {!isEmpty(selectedRecruitmentStatusIds) && (
            <Box mt="8px">
              <StyledTypography
                variant="body2"
                color="primary"
                onClick={() => setSelectedRecruitmentStatusIds([])}
              >
                全てクリア
              </StyledTypography>
            </Box>
          )}
        </Box>
      </Box>
    </Menu>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 0;
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)`
  cursor: pointer;
`;
