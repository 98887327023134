import { OnnEvent } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { convertUnixTimeToDateOnnEvent } from "../../_shared/convertUnixTimeToDate";

export class UpdateOnnEventResponse {
  static ResponseBody: { onnEvent: ToCloudFunctionsCompatible<OnnEvent> };
  public static createResponseBody({ onnEvent }: { onnEvent: OnnEvent }) {
    return { onnEvent: toCloudFunctionsCompatible(onnEvent) };
  }
  public static convertUnixTimeToDate(response: typeof this.ResponseBody) {
    const onnEvent = response.onnEvent;
    return { onnEvent: convertUnixTimeToDateOnnEvent(onnEvent) };
  }
}
