import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/api/employee/change-authentication-by-link"]["POST"];
export const useChangeAuthentication = () => {
  const changeAuthentication = useCallback(
    async ({
      changeAuthenticationTypeLinkId,
      employeeId,
      lineAccessToken,
    }: {
      changeAuthenticationTypeLinkId: string;
      employeeId: string;
      lineAccessToken: string;
    }) => {
      const requestBody: EndPoint["body"] = {
        changeAuthenticationTypeLinkId,
        lineAccessToken,
        employeeId,
      };
      const response = await apiClient.post(
        "/api/employee/change-authentication-by-link",
        requestBody
      );
      return response.data;
    },
    []
  );

  return { changeAuthentication };
};
