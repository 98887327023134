import { z } from "zod";

import { EmployeeTagRelationSchema } from "../_gen/zodSchema/index";

export const employeeTagRelationSchema = EmployeeTagRelationSchema.extend({
  creatorId: z.string().trim().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeTagRelation extends z.infer<typeof employeeTagRelationSchema> {}
