import { format, isPast, isSameDay, subDays } from "date-fns";
import ja from "date-fns/locale/ja";
import { v4 } from "uuid";

import { OnnEventType } from "../../../../../function/src/.prisma/client";

import { IOnnEventSlotDate, onnEventSlotDateSchema } from "./schema";

// NOTE: 現在OnnEventSlotDateの作成を許可するのは新面談と説明会のみ。briefingSessionCategoryIdの型を制限するためにこのような型定義をしている
type ParamsToCreate =
  | (Optional<ExcludeMethods<OnnEventSlotDate>, "id" | "uniqueId" | "createdAt" | "updatedAt"> & {
      type: Extract<OnnEventType, "briefing_session">;
      briefingSessionCategoryId: string;
    })
  | (Optional<ExcludeMethods<OnnEventSlotDate>, "id" | "uniqueId" | "createdAt" | "updatedAt"> & {
      type: Extract<OnnEventType, "new_interview">;
      briefingSessionCategoryId: null;
    });

export class OnnEventSlotDate implements IOnnEventSlotDate {
  static validator = onnEventSlotDateSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly onnEventId: string;
  readonly uniqueId?: number;
  readonly from: Date;
  readonly until: Date;
  readonly capacity: number | null;
  readonly assigneeId: string | null;
  readonly subAssigneeIds: string[];
  readonly eventType: "offline" | "online";
  readonly description: string;
  readonly eventPlaceId: string | null;
  readonly eventAddressPostCode: string | null;
  readonly eventAddressText: string | null;
  readonly url: string | null;
  readonly briefingSessionCategoryId: string | null;

  readonly status: "draft" | "published" | "closed";
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventSlotDate>) {
    const parsedInit = OnnEventSlotDate.validator.parse(init);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventId = parsedInit.onnEventId;
    this.uniqueId = parsedInit.uniqueId;
    this.from = parsedInit.from;
    this.until = parsedInit.until;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.capacity = parsedInit.capacity;
    this.eventType = parsedInit.eventType;
    this.assigneeId = parsedInit.assigneeId;
    this.subAssigneeIds = parsedInit.subAssigneeIds;
    this.description = parsedInit.description;
    this.eventAddressPostCode = parsedInit.eventAddressPostCode;
    this.eventAddressText = parsedInit.eventAddressText;
    this.url = parsedInit.url;
    this.eventPlaceId = parsedInit.eventPlaceId;
    this.status = parsedInit.status;
    this.briefingSessionCategoryId = parsedInit.briefingSessionCategoryId;
  }

  public update(update: Partial<ExcludeMethods<OnnEventSlotDate>>): OnnEventSlotDate {
    return new OnnEventSlotDate({
      ...this,
      ...update,
      updatedAt: new Date(),
    });
  }

  public static create(params: ParamsToCreate): OnnEventSlotDate {
    return new OnnEventSlotDate({
      ...params,
      id: params.id ?? v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public isOnline(): boolean {
    return this.eventType === "online";
  }
  public isOffline(): boolean {
    return this.eventType === "offline";
  }

  public isDraft(): boolean {
    return this.status === "draft";
  }
  public isPublished(): boolean {
    return this.status === "published";
  }
  public isClosed(): boolean {
    return this.status === "closed";
  }

  public isDone(): boolean {
    return isPast(this.until);
  }

  public isFull(reservedCount: number): boolean {
    return this.capacity !== null && this.capacity <= reservedCount;
  }

  public isPublishedAndNotDone(): boolean {
    return this.isPublished() && !this.isDone();
  }

  public isRemindTargetPreviousDay(currentDate: Date = new Date()): boolean {
    return isSameDay(subDays(new Date(this.from), 1), currentDate);
  }

  public getTermText() {
    return `${format(this.from, "MM/dd(E) HH:mm", { locale: ja })}〜${format(this.until, "HH:mm")}`;
  }

  static getStatusLabel(status: OnnEventSlotDate["status"]) {
    return {
      draft: "非公開",
      published: "公開",
      closed: "募集締切",
    }[status];
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 IOnnEventSlotDate にしている
   */
  static forceConvertToDate(onnEventSlotDate: ExcludeMethods<OnnEventSlotDate>) {
    return new OnnEventSlotDate({
      ...onnEventSlotDate,
      createdAt: new Date(onnEventSlotDate.createdAt),
      updatedAt: new Date(onnEventSlotDate.updatedAt),
      from: new Date(onnEventSlotDate.from),
      until: new Date(onnEventSlotDate.until),
    });
  }
}
