import { OnnEventDeterminedDate } from "@onn/common";
import { useCallback } from "react";

import { AttendanceStatus } from "./types";

export const useConvertFromDeterminedDateAttendanceStatus = () => {
  const convertStatus = useCallback(
    (attendanceStatus: OnnEventDeterminedDate["attendanceStatus"]): AttendanceStatus => {
      switch (attendanceStatus) {
        case "ABSENT": {
          return "absent_attendance";
        }
        case "ATTENDED": {
          return "registered_attendance";
        }
        case "UNREGISTERED": {
          return "unregistered_attendance_and_past";
        }
        default: {
          const _exhaustiveCheck: never = attendanceStatus;
          return _exhaustiveCheck;
        }
      }
    },
    []
  );

  return { convertStatus };
};
