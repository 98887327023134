import { z } from "zod";

import { OnnEventNotificationStatusSchema } from "../inputTypeSchemas/OnnEventNotificationStatusSchema";
import { OnnEventNotificationTypeSchema } from "../inputTypeSchemas/OnnEventNotificationTypeSchema";

/////////////////////////////////////////
// BM ONN EVENT NOTIFICATION SCHEDULER SCHEMA
/////////////////////////////////////////

export const BmOnnEventNotificationSchedulerSchema = z.object({
  type: OnnEventNotificationTypeSchema,
  status: OnnEventNotificationStatusSchema,
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  employeeIdToSend: z.string(),
  scheduleTime: z.date(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type BmOnnEventNotificationScheduler = z.infer<typeof BmOnnEventNotificationSchedulerSchema>;

/////////////////////////////////////////
// BM ONN EVENT NOTIFICATION SCHEDULER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const BmOnnEventNotificationSchedulerOptionalDefaultsSchema =
  BmOnnEventNotificationSchedulerSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type BmOnnEventNotificationSchedulerOptionalDefaults = z.infer<
  typeof BmOnnEventNotificationSchedulerOptionalDefaultsSchema
>;

export default BmOnnEventNotificationSchedulerSchema;
