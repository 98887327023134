import { Box } from "@material-ui/core";
import React, { useMemo } from "react";

import { Control, Controller } from "react-hook-form";

import { ContactMessagesOrderForm } from "../hooks/contactMessagesOrderFormSchema";

import { Typography, UserIcon } from "~/components/uiParts";
import { FormWithTitlePaper } from "~/components/uiParts/FormWithTitlePaper";
import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  control: Control<ContactMessagesOrderForm, unknown>;
};

export const SenderFormPaper = ({ control }: Props): JSX.Element => {
  const { currentUser } = useCurrentUser();

  const form = useMemo(
    () => (
      <Controller
        name="sender"
        control={control}
        render={({ field }) => (
          <SelectFormV2
            selected={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            menuItems={[
              {
                name: "企業アカウント",
                value: "official",
              },
              {
                name: currentUser.getName(),
                content: (
                  <Box display="flex" alignItems="center" gridGap={4}>
                    <UserIcon
                      username={currentUser.getName()}
                      profileIconImageUrl={currentUser.profileIconImageUrl}
                      size="extraSmall"
                      circular
                    />
                    <Typography variant="body2" color="textPrimary" noWrap>
                      {currentUser.getName()}
                    </Typography>
                  </Box>
                ),
                value: "currentUser",
              },
            ]}
            fullWidth
          />
        )}
      />
    ),
    [control, currentUser]
  );

  return (
    <FormWithTitlePaper
      title="送信元アカウント"
      tooltipTitle="候補者に通知される送信元アカウントを、企業アカウントまたはメッセージ作成者のアカウントから選択できます。"
      form={form}
    />
  );
};
