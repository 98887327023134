import { CsvInputValueKeyToInfoType } from "./_share";

export type InputValue = {
  uniqueId: string;
  employeeLastName: string;
  employeeFirstName: string;
  employeeKanaLastName: string;
  employeeKanaFirstName: string;
  gender: string;
  dateOfBirth: string;
  phoneNumber: string;
  homePhoneNumber: string;
  graduationYear: string;
  graduationMonth: string;
  schoolName: string;
  faculty: string;
  department: string;
  addressPostalCode: string;
  addressPrefecture: string;
  addressCity: string;
  addressLine1: string;
  addressLine2: string;
  hometownAddressPostalCode: string;
  hometownAddressPrefecture: string;
  hometownAddressCity: string;
  hometownAddressLine1: string;
  hometownAddressLine2: string;
  externalId: string;
  offerAcceptanceDeadline: string;
};

export const generateCsvInputValueKeyToInfo = (): CsvInputValueKeyToInfoType<
  Required<InputValue>
> => {
  return {
    uniqueId: { labelInCsv: "Onn固有ID", isRequired: true },
    employeeLastName: { labelInCsv: "姓", isRequired: true },
    employeeFirstName: { labelInCsv: "名", isRequired: true },
    employeeKanaLastName: { labelInCsv: "姓（フリガナ）", isRequired: true },
    employeeKanaFirstName: { labelInCsv: "名（フリガナ）", isRequired: true },
    gender: { labelInCsv: "性別", isRequired: true },
    dateOfBirth: { labelInCsv: "生年月日(例：20240101)", isRequired: true },
    phoneNumber: { labelInCsv: "電話番号（携帯）", isRequired: true },
    homePhoneNumber: { labelInCsv: "電話番号（自宅）", isRequired: true },
    graduationYear: { labelInCsv: "卒業年", isRequired: true },
    graduationMonth: { labelInCsv: "卒業月", isRequired: true },
    schoolName: { labelInCsv: "学校名", isRequired: true },
    faculty: { labelInCsv: "学部", isRequired: true },
    department: { labelInCsv: "学科", isRequired: true },
    addressPostalCode: { labelInCsv: "自宅住所・郵便番号", isRequired: true },
    addressPrefecture: { labelInCsv: "自宅住所・都道府県", isRequired: true },
    addressCity: { labelInCsv: "自宅住所・市区町村", isRequired: true },
    addressLine1: { labelInCsv: "自宅住所・番地", isRequired: true },
    addressLine2: { labelInCsv: "自宅住所・建物名", isRequired: true },
    hometownAddressPostalCode: { labelInCsv: "帰省先住所・郵便番号", isRequired: true },
    hometownAddressPrefecture: { labelInCsv: "帰省先住所・都道府県", isRequired: true },
    hometownAddressCity: { labelInCsv: "帰省先住所・市区町村", isRequired: true },
    hometownAddressLine1: { labelInCsv: "帰省先住所・番地", isRequired: true },
    hometownAddressLine2: { labelInCsv: "帰省先住所・建物名", isRequired: true },
    externalId: { labelInCsv: "ID（その他）", isRequired: true },
    offerAcceptanceDeadline: { labelInCsv: "内定承諾期日", isRequired: true },
  };
};

const generateSampleCsvBodyData = () => {
  return [
    {
      uniqueId: "",
      employeeLastName: "田中",
      employeeFirstName: "太郎",
      employeeKanaLastName: "タナカ",
      employeeKanaFirstName: "タロウ",
      gender: "男性",
      dateOfBirth: "20240101",
      phoneNumber: "000-1234-5678",
      homePhoneNumber: "000-9876-5432",
      graduationYear: "2024",
      graduationMonth: "3",
      schoolName: "○○大学",
      faculty: "○○学部",
      department: "○○学科",
      addressPostalCode: "000-0000",
      addressPrefecture: "東京都",
      addressCity: "千代田区",
      addressLine1: "千代田1-1",
      addressLine2: "千代田ビル",
      hometownAddressPostalCode: "000-0000",
      hometownAddressPrefecture: "東京都",
      hometownAddressCity: "千代田区",
      hometownAddressLine1: "千代田1-1",
      hometownAddressLine2: "千代田ビル",
      externalId: "1234567890",
      offerAcceptanceDeadline: "20200101",
    },
  ];
};

export const generateSampleCsvData = () => {
  return [
    [
      ...Object.entries(generateCsvInputValueKeyToInfo()).flatMap(([_, value]) => {
        return value.labelInCsv;
      }),
    ],
    ...generateSampleCsvBodyData().map((value) => [
      ...Object.entries(value).flatMap(([_, value]) => value),
    ]),
  ];
};
