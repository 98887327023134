import { Box } from "@material-ui/core";
import { OnnEventSlotDate } from "@onn/common";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";

import { EventDescriptionTypography } from "../OnnEventNewOrEdit/EventDescriptionTypography";
import { EventTitleTypography } from "../OnnEventNewOrEdit/EventTitleTypography";
import { NoSlotPaper } from "../OnnEventNewOrEdit/NoSlotPaper";
import { PageWrapper } from "../OnnEventNewOrEdit/PageWrapper";

import { BriefingSessionCategorySelector } from "./components/BriefingSessionCategoryForm";

import { OnnEventFormatForm } from "./components/OnnEventFormatForm";
import { PlaceForm } from "./components/PlaceForm";
import { SlotDateForm } from "./components/SlotDateForm";

import { SlotHourForm } from "./components/SlotHourForm";
import { useForm } from "./hooks/useForm";
import { usePageCore } from "./usePageCore";

import { Button, Typography } from "~/components/uiParts";

import theme from "~/config/theme";
import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventForPortal";

type Props = {
  eventData: OnnEventDataForPortal;
};

export const OnnEventNewOrEditPageBriefingSession: FC<Props> = ({ eventData }) => {
  const { form } = useForm({ eventData });
  return (
    <FormProvider {...form}>
      <OnnEventNewOrEditPageBriefingSessionCore eventData={eventData} />
    </FormProvider>
  );
};

const OnnEventNewOrEditPageBriefingSessionCore: FC<Props> = ({ eventData }) => {
  const {
    selectableCategories,
    selectableEventTypes,
    selectablePlaces,
    selectableSlotsWithNumberOfParticipants,
    selectableSlotHours,
    watchValue: { selectedBriefingSessionCategoryId, selectedEventType },
    isDisplayNoSlotPaper,
    isSubmitButtonDisabled,
    isSubmitButtonLoading,
    onSubmit,
  } = usePageCore({
    eventData,
  });

  const renderAnswerForm = () => {
    return (
      <>
        <BriefingSessionCategorySelector selectableCategories={selectableCategories} />
        {selectedBriefingSessionCategoryId && (
          <>
            <>
              {selectableEventTypes.length === 1 ? (
                <ShowEventFormat
                  eventFormat={selectableEventTypes[0] as OnnEventSlotDate["eventType"]}
                />
              ) : (
                <OnnEventFormatForm />
              )}
            </>
            {selectedEventType === "offline" && (
              <PlaceForm selectableEventPlace={selectablePlaces} />
            )}
            <SlotDateForm
              selectableSlotsWithNumberOfParticipants={selectableSlotsWithNumberOfParticipants}
            />
            {selectableSlotHours.length > 0 && (
              <SlotHourForm selectableSlotHours={selectableSlotHours} />
            )}
          </>
        )}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          borderRadius="circle"
          onClick={onSubmit}
          disabled={isSubmitButtonDisabled}
          isLoading={isSubmitButtonLoading}
        >
          回答を送信
        </Button>
      </>
    );
  };

  return (
    <PageWrapper isWhiteBackgroundForSp={!isDisplayNoSlotPaper}>
      <EventTitleTypography onnEventTitle={eventData.onnEvent.title} />
      <EventDescriptionTypography description={eventData.onnEvent.content} />
      {isDisplayNoSlotPaper ? <NoSlotPaper /> : renderAnswerForm()}
    </PageWrapper>
  );
};

const ShowEventFormat = ({ eventFormat }: { eventFormat: OnnEventSlotDate["eventType"] }) => {
  return (
    <Box
      p="16px"
      bgcolor={theme.palette.grey[50]}
      borderRadius="8px"
      display="flex"
      gridColumnGap="8px"
    >
      <Typography variant="body2" color="textPrimary" bold style={{ width: "80px" }}>
        開催方法
      </Typography>
      <Typography variant="body2" color="textPrimary">
        {eventFormat === "online" ? "オンライン" : "オフライン・現地"}
      </Typography>
    </Box>
  );
};
