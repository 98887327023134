import { zodResolver } from "@hookform/resolvers/zod";
import {
  RecruitmentStatus,
  RecruitmentStatusWithRelatedInfo,
  postScenarioRecruitmentStatusSchema,
  recruitmentStatusSchema,
} from "@onn/common";
import { useCallback, useMemo, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useFieldArray, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";

import { useEditScenarioRecruitmentStatus } from "~/hooks/scenario/useEditScenarioRecruitmentStatus";
import { usePrompt } from "~/hooks/shared";

const postScenarioRecruitmentStatusFormSchema =
  postScenarioRecruitmentStatusSchema.shape.body.shape.recruitmentStatuses._def.type
    .merge(
      z.object({
        label: z.string().trim().max(30, { message: "30文字以下で入力してください。" }), // フォームでは何も入力しなかった場合に標準ラベルの上書きを行うため最低文字数のバリデーションを外している
        type: recruitmentStatusSchema.shape.type,
        isRelatedEmployee: z.boolean(),
      })
    )
    .refine((val) => !(val.label.trim() === "" && val.type === "screening"), {
      // NOTE: 選考中ラベルは標準ラベルによる上書きを行わないため空文字を許容しない
      message: "1文字以上で入力してください。",
      path: ["label"],
    });

type RecruitmentStatusForm<T> = z.infer<typeof postScenarioRecruitmentStatusFormSchema> & {
  type: T;
  index: number;
};

export type InputState = {
  recruitmentStatuses: Array<z.infer<typeof postScenarioRecruitmentStatusFormSchema>>;
};

export const useRecruitmentStatusesForm = ({
  recruitmentStatuses,
  scenarioId,
  onSuccess,
}: {
  recruitmentStatuses: RecruitmentStatusWithRelatedInfo[];
  scenarioId: string;
  onSuccess: () => void;
}) => {
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenConfirmEditModal, setIsOpenConfirmEditModal] = useState(false);
  const { editScenarioRecruitmentStatus } = useEditScenarioRecruitmentStatus();
  const form = useForm<InputState>({
    defaultValues: {
      recruitmentStatuses: recruitmentStatuses.map((recruitmentStatus) => {
        return {
          type: recruitmentStatus.type,
          id: recruitmentStatus.id,
          label: recruitmentStatus.label,
          isRelatedEmployee: recruitmentStatus.isRelatedEmployee,
        };
      }),
    },
    resolver: zodResolver(
      z.object({
        recruitmentStatuses: postScenarioRecruitmentStatusFormSchema.array(),
      })
    ),
    mode: "all",
  });

  const { fields, insert, move, remove } = useFieldArray({
    control: form.control,
    name: "recruitmentStatuses",
  });

  usePrompt("選考ステータスの編集を破棄しますか？", form.formState.isDirty);

  const addStatus = useCallback(() => {
    // NOTE: 常に内定より前に追加するため
    const findIndex = fields.findIndex((field) => field.type === "job_offer");
    insert(findIndex, {
      type: "screening",
      id: v4(),
      label: "",
      isRelatedEmployee: false,
    });
  }, [fields, insert]);

  const removeStatus = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const submitForm = form.handleSubmit(async (data) => {
    await editScenarioRecruitmentStatus({
      data: {
        ...data,
        scenarioId,
        recruitmentStatuses: data.recruitmentStatuses.map((status) => {
          // NOTE: 選考中以外のステータスは入力値を空にした場合に標準ラベルに戻す
          if (status.label.trim() === "" && status.type !== "screening") {
            return {
              ...status,
              label: RecruitmentStatus.typeToDefaultLabelMap[status.type],
            };
          }
          return status;
        }),
      },
      successMessage: getIsDeleteStatuesRelatedScenarioStatus()
        ? "削除された選考ステータスが紐づいた設定が解除されました。シナリオをご確認ください。"
        : "選考ステータスを更新しました",
    }).then(() => {
      onSuccess();
    });
  });

  // NOTE: 削除される選考ステータスがシナリオに紐づくかどうかを判定する
  const getIsDeleteStatuesRelatedScenarioStatus = useCallback(() => {
    const statusIdsOnForm = form.getValues("recruitmentStatuses").map((status) => status.id);
    const deleteTargetStatuses = recruitmentStatuses.filter(
      (status) => !statusIdsOnForm.includes(status.id)
    );
    return deleteTargetStatuses.some((status) => status.isRelatedScenario);
  }, [form, recruitmentStatuses]);

  // NOTE: 編集される選考ステータスがシナリオに紐づくかどうかを判定する
  const getIsEditStatuesRelatedScenarioStatus = useCallback(() => {
    const statusMap = new Map(
      form
        .getValues("recruitmentStatuses")
        .map((status, index) => [status.id, { label: status.label, index }])
    );
    const changedLabelStatuses = recruitmentStatuses.filter((status) => {
      const targetStatus = statusMap.get(status.id);
      if (!targetStatus) return false;
      return status.label !== targetStatus.label;
    });
    return changedLabelStatuses.some((status) => status.isRelatedScenario);
  }, [form, recruitmentStatuses]);

  const handleSubmit = useCallback(async () => {
    // NOTE: 新規追加のステータスの中で入力されていないものがあれば削除する
    const existedStatusIds = recruitmentStatuses.map((status) => status.id);
    const { recruitmentStatuses: formRecruitmentStatuses } = form.getValues();
    const removeTargets = formRecruitmentStatuses.filter(
      (status) => !status.label && !existedStatusIds.includes(status.id)
    );

    remove(removeTargets.map((field) => formRecruitmentStatuses.indexOf(field)));
    await submitForm();
  }, [form, recruitmentStatuses, remove, submitForm]);

  const handleClickSaveButton = useCallback(() => {
    // NOTE: 削除されるものが含まれている場合は削除用を優先して表示する
    if (getIsDeleteStatuesRelatedScenarioStatus()) {
      setIsOpenConfirmDeleteModal(true);
    } else if (getIsEditStatuesRelatedScenarioStatus()) {
      setIsOpenConfirmEditModal(true);
    } else {
      handleSubmit();
    }
  }, [
    getIsDeleteStatuesRelatedScenarioStatus,
    getIsEditStatuesRelatedScenarioStatus,
    handleSubmit,
  ]);

  const changeOrder = (result: DropResult) => {
    if (!result.destination) return;

    const {
      source: { index: sourceIndex },
      destination: { index: destinationIndex },
    } = result;

    // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
    if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

    move(sourceIndex, destinationIndex);
  };

  const separatedFields = useMemo(
    () =>
      fields.reduce(
        (acc, field, index) => {
          switch (field.type) {
            case "pre_entry": {
              return {
                ...acc,
                preEntry: [
                  ...acc.preEntry,
                  {
                    ...field,
                    index,
                  } as RecruitmentStatusForm<"pre_entry">,
                ],
              };
            }
            case "screening": {
              return {
                ...acc,
                screening: [
                  ...acc.screening,
                  { ...field, index } as RecruitmentStatusForm<"screening">,
                ],
              };
            }
            default: {
              return {
                ...acc,
                others: [
                  ...acc.others,
                  { ...field, index } as RecruitmentStatusForm<
                    Exclude<RecruitmentStatus["type"], "pre_entry" | "screening">
                  >,
                ],
              };
            }
          }
        },
        {
          preEntry: [],
          screening: [],
          others: [],
        } as {
          preEntry: RecruitmentStatusForm<"pre_entry">[];
          screening: RecruitmentStatusForm<"screening">[];
          others: RecruitmentStatusForm<
            Exclude<RecruitmentStatus["type"], "pre_entry" | "screening">
          >[];
        }
      ),
    [fields]
  );

  const isSubmitButtonDisabled = form.formState.isSubmitting || !form.formState.isValid;

  return {
    form,
    separatedFields,
    changeOrder,
    addStatus,
    removeStatus,
    handleClickSaveButton,
    handleSubmit,
    isSubmitButtonDisabled,
    isOpenConfirmDeleteModal,
    setIsOpenConfirmDeleteModal,
    isOpenConfirmEditModal,
    setIsOpenConfirmEditModal,
  };
};
