import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery } from "~/hooks/shared";

export const useRedirectToPortal = () => {
  const navigate = useNavigate();
  const { query } = useQuery();

  const redirectToPortal = useCallback(async () => {
    // dest-path があれば、そこへ遷移する
    const destPath = query.get("dest-path");
    if (destPath) {
      navigate(destPath, { state: { isFirstLogin: false } });
      return;
    }

    navigate("/portal", { state: { isFirstLogin: false } });
  }, [navigate, query]);

  return { redirectToPortal };
};
