import { v4 } from "uuid";
import { z } from "zod";

export const candidateDateSchema = z
  .object({
    id: z.string().default(v4),
    from: z.date(),
    until: z.date(),
    capacity: z.number().int().min(1, { message: "最低1名以上で設定してください" }).optional(),
    createdAt: z.date().default(() => new Date()),
    updatedAt: z.date().default(() => new Date()),
  })
  .refine((data) => data.from < data.until, {
    message: "終了日時は開始日時より後に設定してください",
  });

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICandidateDate extends z.infer<typeof candidateDateSchema> {}
