import { Box } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Icon } from "../Icon";

import { Typography } from "~/components/uiParts/Typography";
import { UserIcon } from "~/components/uiParts/UserIcon";

type Props = {
  iconPath?: string;
  name: string;
  secondaryText?: string;
  iconBorderColor?: "primary" | "blue";
  iconBackgroundColor?: "primary" | "grey";
  iconCircular?: boolean;
  iconBadgeType?: "dot" | "slack" | "lineChat" | "email";
  size?: ComponentProps<typeof UserIcon>["size"];
  textSize?: "normal" | "small";
  to?: string;
  isPinned?: boolean;
  isTargetBlank?: boolean;
};

export const UserIconWithLabel: FC<Props> = ({
  iconPath,
  name,
  secondaryText,
  iconBorderColor,
  iconBackgroundColor,
  iconCircular,
  iconBadgeType,
  size = "small",
  textSize = "normal",
  to,
  isPinned,
  isTargetBlank,
  ...props
}) => {
  const Contents = (
    <Box
      display="flex"
      alignItems="center"
      gridGap={textSize === "normal" ? "16px" : "4px"}
      {...props}
    >
      <UserIcon
        username={name}
        profileIconImageUrl={iconPath}
        size={size}
        circular={iconCircular}
        borderColor={iconBorderColor}
        backgroundColor={iconBackgroundColor}
        badgeType={iconBadgeType}
      />

      <Box display="grid" gridRowGap="4px">
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gridGap: "4px",
            overflow: "hidden",
          }}
        >
          {isPinned && <Icon icon="pin" size="sm" color="primary" />}
          <StyledTypography
            variant={TEXT_SIZE[textSize]}
            color="textPrimary"
            bold={textSize === "normal"}
            noWrap
          >
            {name}
          </StyledTypography>
        </Box>
        {secondaryText && (
          <StyledTypography variant="caption" color="textSecondary" noWrap disablePreWrap>
            {secondaryText}
          </StyledTypography>
        )}
      </Box>
    </Box>
  );

  if (to) {
    return (
      <StyledLink to={to} target={`${isTargetBlank ? "_blank" : "_self"}`}>
        {Contents}
      </StyledLink>
    );
  }

  return Contents;
};

const TEXT_SIZE = {
  normal: "body1",
  small: "caption",
} as const;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
