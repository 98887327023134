import { Box } from "@mui/material";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";
import theme from "~/config/theme";

export const AsyncWarningPaper: FC<{
  warningTargetStatusLabels: string[];
}> = ({ warningTargetStatusLabels }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      columnGap={"8px"}
      bgcolor={theme.palette.secondary.light}
      p={"16px"}
      borderRadius={"8px"}
    >
      <Typography variant="caption" color="secondary">
        既にリッチメニューが設定されている「{warningTargetStatusLabels.join(",")}
        」を選択しています。上書きにご注意ください。
      </Typography>
    </Box>
  );
};
