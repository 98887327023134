import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { EmailType, LineType } from "@onn/common/domain/Employee/AuthenticationType";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Condition } from "../type";

import { AuthenticationFlowTypeTabPanel } from "./AuthenticationFlowTypeTabPanel/AuthenticationFlowTypeTabPanel";
import { OnnEventTabPanel } from "./OnnEventTabPanel/OnnEventTabPanel";

import { OnnTaskTabPanel } from "./OnnTaskTabPanel/OnnTaskTabPanel";
import { RecruitmentStatusTabPanel } from "./RecruitmentStatusTabPanel/RecruitmentStatusTabPanel";

import { TagTabPanel } from "./tagTabPanel/tagTabPanel";

import { Typography } from "~/components/uiParts";

type TabValue = "signUpFlowType" | "scenarioAndRecruitmentStatus" | "onnEvent" | "onnTask" | "tag";

const useTabs = (selectedCondition: Condition) => {
  const [activeTab, setActiveTab] = useState<TabValue>("signUpFlowType");
  const tabs: Array<{ label: string; value: TabValue }> = useMemo(
    () =>
      [
        {
          label: `登録形式(${selectedCondition.authenticationFlowTypes.length})`,
          value: "signUpFlowType" as const,
        },
        {
          label: `シナリオ(1)`,
          // 常に1つは選択されている
          value: "scenarioAndRecruitmentStatus" as const,
        },
        {
          label: `イベント(${selectedCondition.onnEventIds.length})`,
          value: "onnEvent" as const,
        },
        {
          label: `タスク(${selectedCondition.onnTaskIds.length})`,
          value: "onnTask" as const,
        },
        {
          label: `タグ(${selectedCondition.tagIds.length})`,
          value: "tag" as const,
        },
      ].filter((tab): tab is Exclude<typeof tab, undefined> => tab !== undefined),
    [selectedCondition]
  );

  const handleChangeActiveTab = useCallback((_: unknown, newValue: TabValue) => {
    setActiveTab(newValue);
  }, []);

  return { activeTab, tabs, handleChangeActiveTab };
};

export const ModalContent = ({
  selectedCondition,
  addSelectedAuthenticationFlowTypes,
  removeSelectedAuthenticationFlowTypes,
  addSelectedOnnEventIds,
  removeSelectedOnnEventIds,
  addSelectedOnnTaskIds,
  removeSelectedOnnTaskIds,
  addSelectedTagIds,
  removeSelectedTagIds,
  changeSelectedRecruitmentStatusId,
}: {
  selectedCondition: Condition;
  addSelectedAuthenticationFlowTypes: (authenticationFlowTypes: (LineType | EmailType)[]) => void;
  removeSelectedAuthenticationFlowTypes: (
    authenticationFlowTypes: (LineType | EmailType)[]
  ) => void;
  addSelectedOnnEventIds: (onnEventIds: string[]) => void;
  removeSelectedOnnEventIds: (onnEventIds: string[]) => void;
  addSelectedOnnTaskIds: (onnTaskIds: string[]) => void;
  removeSelectedOnnTaskIds: (onnTaskIds: string[]) => void;
  addSelectedTagIds: (tagIds: string[]) => void;
  removeSelectedTagIds: (tagIds: string[]) => void;
  changeSelectedRecruitmentStatusId: (recruitmentStatusId: string) => void;
}) => {
  const { activeTab, tabs, handleChangeActiveTab } = useTabs(selectedCondition);

  return (
    <Box mt="16px">
      <Box display="flex" justifyContent="center">
        <Typography variant="body1">
          招待QR・URLに付与する条件を以下から選択してください。
        </Typography>
      </Box>

      <Box height={"100%"}>
        <TabContext value={activeTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeActiveTab}
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>

          <StyledTabPanel value="signUpFlowType">
            <AuthenticationFlowTypeTabPanel
              selectedAuthenticationFlowTypes={selectedCondition.authenticationFlowTypes}
              addSelectedAuthenticationFlowTypes={addSelectedAuthenticationFlowTypes}
              removeSelectedAuthenticationFlowTypes={removeSelectedAuthenticationFlowTypes}
            />
          </StyledTabPanel>
          {
            <StyledTabPanel value="scenarioAndRecruitmentStatus">
              <RecruitmentStatusTabPanel
                selectedCondition={selectedCondition}
                changeSelectedRecruitmentStatusId={changeSelectedRecruitmentStatusId}
              />
            </StyledTabPanel>
          }
          <StyledTabPanel value="onnEvent">
            <OnnEventTabPanel
              selectedOnnEventIds={selectedCondition.onnEventIds}
              addSelectedOnnEventIds={addSelectedOnnEventIds}
              removeSelectedOnnEventIds={removeSelectedOnnEventIds}
            />
          </StyledTabPanel>
          <StyledTabPanel value="onnTask">
            <OnnTaskTabPanel
              selectedOnnTaskIds={selectedCondition.onnTaskIds}
              addSelectedOnnTaskIds={addSelectedOnnTaskIds}
              removeSelectedOnnTaskIds={removeSelectedOnnTaskIds}
            />
          </StyledTabPanel>
          <StyledTabPanel value="tag">
            <TagTabPanel
              selectedTagIds={selectedCondition.tagIds}
              addSelectedTagIds={addSelectedTagIds}
              removeSelectedTagIds={removeSelectedTagIds}
            />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
