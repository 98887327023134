import liff from "@line/liff";
import React, { FC } from "react";

import { useParams } from "react-router-dom";

import { RegistrationInvitationLinks } from "../Registration";

import { ErrorPage } from "~/components/domains/account/ErrorPage";

export const Wrapper: FC<{
  children: typeof RegistrationInvitationLinks;
}> = ({ children: Children }) => {
  const { type } = useParams<"type">();

  if (liff.isInClient() && type !== "line") {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="LINE認証タイプではないリンクは LIFF では開けません"
        extraInfo={{ type }}
      />
    );
  }

  return <Children />;
};
