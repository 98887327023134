import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION DATE TYPE VALUE SCHEMA
/////////////////////////////////////////

export const EmployeeInformationDateTypeValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  employeeInformationDateTypeFieldId: z.string(),
  value: z.date(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationDateTypeValue = z.infer<
  typeof EmployeeInformationDateTypeValueSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION DATE TYPE VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationDateTypeValueOptionalDefaultsSchema =
  EmployeeInformationDateTypeValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationDateTypeValueOptionalDefaults = z.infer<
  typeof EmployeeInformationDateTypeValueOptionalDefaultsSchema
>;

export default EmployeeInformationDateTypeValueSchema;
