import { Box, Link } from "@material-ui/core";
import { IButtonPart } from "@onn/common";
import React, { FC } from "react";

import { Button, Tooltip, Typography } from "~/components/uiParts";

type Props = {
  content: IButtonPart;
  isDisabledButton: boolean;
};
export const ButtonPart: FC<Props> = ({ content, isDisabledButton }) => {
  if (content.isPrimary) {
    const button = (
      <Box width="90%" height="40px" margin="8px 0">
        <Button
          fullWidth
          fullHeight
          borderRadius="circle"
          variant="contained"
          color="primary"
          disabled={isDisabledButton}
        >
          <Typography variant="body2">{content.buttonText}</Typography>
        </Button>
      </Box>
    );

    if (isDisabledButton) {
      return (
        <Tooltip
          title="候補者はこのボタンをクリックすることができます"
          arrow
          style={{ textAlign: "center", justifyContent: "center", cursor: "not-allowed" }}
        >
          {button}
        </Tooltip>
      );
    } else {
      return (
        <Link href={content.url}>
          <Box display="flex" justifyContent="center">
            {button}
          </Box>
        </Link>
      );
    }
  }

  if (isDisabledButton) {
    return (
      <Tooltip
        title="候補者はこのボタンをクリックすることができます"
        arrow
        style={{ textAlign: "center", justifyContent: "center", cursor: "not-allowed" }}
      >
        <Typography variant="body2" color="textSecondary">
          {content.buttonText}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Link
      href={content.url}
      style={{
        textAlign: "center",
        width: "content-fit",
        margin: "0 auto",
        textDecoration: "none",
      }}
    >
      <Typography variant="body2">{content.buttonText}</Typography>
    </Link>
  );
};
