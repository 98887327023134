import React, { FC } from "react";

import styled from "styled-components";

import { TextField } from "~/components/uiParts";

export type TextFieldOfDeliveryTimingNumberProps = {
  value?: number;
  onChange: (value: number) => void;
  errorMessage?: string;
};

export const TextFieldOfDeliveryTimingNumber: FC<TextFieldOfDeliveryTimingNumberProps> = (
  deliveryTimingNumberForm
) => {
  return (
    <StyledTextField
      type="number"
      fullWidth
      value={
        deliveryTimingNumberForm.value === undefined
          ? undefined
          : deliveryTimingNumberForm.value.toString()
      }
      onChange={(e) => {
        const value = Number(e.target.value);
        if (isNaN(value)) {
          deliveryTimingNumberForm.onChange(0);
        } else {
          deliveryTimingNumberForm.onChange(value < 0 ? 0 : value);
        }
      }}
      error={!!deliveryTimingNumberForm.errorMessage}
      helperText={deliveryTimingNumberForm.errorMessage}
    />
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    background: ${({ theme }) => theme.palette.background.paper};
  }
`;
