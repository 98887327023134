import { Box } from "@material-ui/core";
import { Address, Affiliation, GraduationYearAndMonth, KanaName } from "@onn/common";
import React, { FC } from "react";

import { SectionPart } from "./SectionPart";

import { Typography } from "~/components/uiParts";

type Props = {
  kanaName?: KanaName;
  phoneNumber?: string;
  homePhoneNumber?: string;
  affiliation?: Affiliation;
  graduationYearAndMonth?: GraduationYearAndMonth;
  address?: Address;
  email?: string;
};

export const NewGraduateProfileSummary: FC<Props> = ({
  kanaName,
  phoneNumber,
  homePhoneNumber,
  affiliation,
  graduationYearAndMonth,
  address,
  email,
}) => {
  return (
    <Box>
      <Typography variant="body2" bold>
        プロフィールサマリー
      </Typography>
      <Box display="flex" flexDirection="column" gridGap={32} mt={2}>
        <SectionPart title="フリガナ" values={[{ value: kanaName?.getFullKanaName() }]} />
        <SectionPart
          title="電話番号"
          values={[
            { subtitle: "携帯", value: phoneNumber },
            { subtitle: "自宅", value: homePhoneNumber },
          ]}
        />
        <SectionPart title="メールアドレス" values={[{ value: email }]} />
        <SectionPart title="学校" values={[{ value: affiliation?.getFullText() }]} />
        <SectionPart title="卒業年月" values={[{ value: graduationYearAndMonth?.getFullText() }]} />
        <SectionPart
          title="自宅(現住所)"
          values={[{ value: address?.getFullAddressWithoutPostalCode() }]}
        />
      </Box>
    </Box>
  );
};
