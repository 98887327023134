import { v4 } from "uuid";

import { recruitmentStatusRevisionSchema } from "./schema";

/**
 * 選考ステータス変更管理リビジョン
 *
 * - スペースごとのデータ（選考ステータスの設定はシナリオごとだが、管理画面ではスペースごとに選考ステータスを取得・利用するため）
 */
export class RecruitmentStatusRevision {
  static readonly validator = recruitmentStatusRevisionSchema;
  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly updatedEmployeeId: string;

  constructor(init: ExcludeMethods<RecruitmentStatusRevision>) {
    const parsedInit = RecruitmentStatusRevision.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.updatedEmployeeId = parsedInit.updatedEmployeeId;
  }

  public static createNew(
    init: Omit<RecruitmentStatusRevision, "id" | "createdAt" | "updatedAt">
  ): RecruitmentStatusRevision {
    return new RecruitmentStatusRevision({
      ...init,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
