import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { StatusForDisplayEventTable } from "../utils/getStatusForDisplayEventTable";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type Props = {
  menuOptions: { value: StatusForDisplayEventTable; label: string }[];
  selected: StatusForDisplayEventTable[];
  onClickCheckBox: (value: StatusForDisplayEventTable) => void;
};

export const StatusFilterSelectMenu: FC<Props> = ({ menuOptions, selected, onClickCheckBox }) => {
  return (
    <Box py="16px" width="240px" display="flex" flexDirection="column">
      <Box px="24px" maxHeight={350} overflow="auto" display="flex" flexDirection="column">
        {menuOptions.map((option) => (
          <StyledFormControlLabel
            key={option.value}
            onChange={() => onClickCheckBox(option.value)}
            control={
              <Checkbox
                checked={selected.some((v) => v === option.value)}
                name={option.label}
                value={option.value}
              />
            }
            disabled={false}
            label={
              <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
                <StyledTypography variant="body2" noWrap>
                  {option.label}
                </StyledTypography>
              </Box>
            }
          />
        ))}
      </Box>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 8px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;
