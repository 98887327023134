import { z } from "zod";

/////////////////////////////////////////
// LINE USER SCHEMA
/////////////////////////////////////////

export const LineUserSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  userId: z.string(),
  tenantId: z.string(),
  language: z.string().nullish(),
  pictureUrl: z.string().nullish(),
  statusMessage: z.string().nullish(),
  isFollowedWhenCreated: z.boolean(),
  createdAt: z.date().nullish(),
  isUnFollow: z.boolean(),
  lastUnfollowedAt: z.date().nullish(),
  internalRichMenuId: z.string().nullish(),
});

export type LineUser = z.infer<typeof LineUserSchema>;

/////////////////////////////////////////
// LINE USER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const LineUserOptionalDefaultsSchema = LineUserSchema.merge(z.object({}));

export type LineUserOptionalDefaults = z.infer<typeof LineUserOptionalDefaultsSchema>;

export default LineUserSchema;
