import React, { useMemo } from "react";

import { useContextOfOnnEventSlotSearchModal } from "../Context";

import { ModalContentLayout } from "./ModalContentLayout";

import { ConditionForm } from "./condition-form/ConditionForm";
import { AddConditionButton } from "./parts/buttons/AddConditionButton";
import { ClearAllConditionsButton } from "./parts/buttons/ClearAllConditionsButton";

export const ModalContent = (): JSX.Element => {
  const { addCondition, clearAllConditions } = useContextOfOnnEventSlotSearchModal();

  const conditionForm = useMemo(() => <ConditionForm />, []);

  const addConditionButton = useMemo(
    () => <AddConditionButton onClick={addCondition} />,
    [addCondition]
  );

  const clearAllConditionsButton = useMemo(
    () => <ClearAllConditionsButton onClick={clearAllConditions} />,
    [clearAllConditions]
  );

  return (
    <ModalContentLayout
      conditionForm={conditionForm}
      addConditionButton={addConditionButton}
      clearAllConditionsButton={clearAllConditionsButton}
    />
  );
};
