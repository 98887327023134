import { AnyTriggerSetting } from "@onn/common";
import { useCallback } from "react";

import { useGetTriggerFormName } from "../useGetTriggerFormName";
import { useFormContext } from "../useScenarioForm";

export const useUpdateTriggerSettingOnForm = ({
  recruitmentStatusId,
  triggerId,
}: {
  recruitmentStatusId: string;
  triggerId: string;
}) => {
  const { getTriggerFormName } = useGetTriggerFormName();
  const { setValue, trigger } = useFormContext();

  const updateTriggerSettingOnForm = useCallback(
    (anyTriggerSettings: AnyTriggerSetting) => {
      const triggerFormName = getTriggerFormName({
        recruitStatusId: recruitmentStatusId,
        triggerId,
      });
      setValue(`${triggerFormName}.triggerSetting`, anyTriggerSettings);
      trigger();
    },
    [getTriggerFormName, recruitmentStatusId, setValue, trigger, triggerId]
  );

  return { updateTriggerSettingOnForm };
};
