import { ChangeAuthenticationLog } from "@onn/common";
import React, { memo } from "react";

import styled from "styled-components";

import { Typography, Paper } from "~/components/uiParts";

/**
 * ログイン方法変更ログのメッセージ
 */
export const ChangeAuthenticationLogMessage = memo(
  (props: Pick<ChangeAuthenticationLog, "toType">) => {
    const typeToLabel = (type: "email" | "line") => {
      if (type === "email") return "メールアドレス";
      if (type === "line") return "LINE";
      return "";
    };
    const toTypeLabel = typeToLabel(props.toType);
    const text = `ログイン方法が${toTypeLabel}にログイン変更されました`;
    return (
      <StyledUnavailableMessageOrLogPaper elevation={0}>
        <Typography variant="body2">{text}</Typography>
      </StyledUnavailableMessageOrLogPaper>
    );
  }
);

/**
 * LINE公式アカウントをブロックしているのメッセージ
 */
export const MessageWhenLineBlocked = memo(() => {
  return (
    <StyledUnavailableMessageOrLogPaper elevation={0}>
      <Typography variant="body2">
        候補者がブロックをしているため、メッセージの送受信ができません。
      </Typography>
    </StyledUnavailableMessageOrLogPaper>
  );
});

/**
 * LINE公式アカウントを一度もフォローしていない場合のメッセージ
 */
export const MessageWhenNeverFollowed = memo(() => {
  return (
    <StyledUnavailableMessageOrLogPaper elevation={0}>
      <Typography variant="body2">
        候補者がLINE公式アカウントをフォローしていないため、メッセージの送受信ができません。
      </Typography>
    </StyledUnavailableMessageOrLogPaper>
  );
});

/**
 * メッセージが存在しない場合のメッセージ
 */
export const NoMessages = memo(() => {
  return (
    <StyledPaper>
      <Typography variant="caption">
        {`入社前後の連絡や入社者のフォローアップなどのやり取りを行うことができます。\nメッセージを送信すると入社者のメールに通知され、やりとりが開始されます。`}
      </Typography>
    </StyledPaper>
  );
});

/**
 * LINEユーザーがグループを退会した場合のメッセージ
 */
export const MessageWhenWithdrawalLineGroup = memo(
  ({ date, lineUserDisplayName }: { date: Date; lineUserDisplayName: string }) => {
    return (
      <StyledUnavailableMessageOrLogPaper elevation={0} key={date.toISOString()}>
        <Typography variant="body2">{`${lineUserDisplayName}さんがグループを退会しました`}</Typography>
      </StyledUnavailableMessageOrLogPaper>
    );
  }
);

/**
 * ユーザーが削除されている場合のメッセージ
 */
export const MessageWhenUserDeleted = memo(() => {
  return (
    <StyledUnavailableMessageOrLogPaper elevation={0}>
      <Typography variant="body2">{`この入社者アカウントは削除されています。\nメッセージの送受信はできません。`}</Typography>
    </StyledUnavailableMessageOrLogPaper>
  );
});

/**
 * コンタクトルームが利用終了している時のメッセージ
 */
export const MessageWhenRoomIsClosed = memo(() => {
  return (
    <StyledUnavailableMessageOrLogPaper elevation={0}>
      <Typography variant="body2">{`このコンタクトルームは利用終了しています。\nメッセージの送信はできません。`}</Typography>
    </StyledUnavailableMessageOrLogPaper>
  );
});

const StyledUnavailableMessageOrLogPaper = styled(Paper)`
  width: 80%;
  text-align: center;
  padding: 16px;
  margin: 8px auto 0 auto;
  &.MuiPaper-root {
    background-color: ${(props) => props.theme.palette.grey[100]};
  }
  &.MuiPaper-radius {
    border-radius: 10px;
  }
`;

const StyledPaper = styled(Paper)`
  text-align: center;
  padding: 16px;
  max-width: 480px;
  margin: 0 auto;
`;
