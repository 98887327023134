import React, { FC } from "react";

import { InformationValueViewer } from "../InformationValueViewer";

import { Typography } from "~/components/uiParts";

type Props = {
  value?: string;
  onChangeModeToEdit: () => void;
  onClickCopy?: () => void;
};

export const PhoneNumberViewer: FC<Props> = ({ value, onChangeModeToEdit, onClickCopy }) => {
  return (
    <InformationValueViewer
      label={value ? <Typography variant="body2">{value}</Typography> : undefined}
      onChangeModeToEdit={onChangeModeToEdit}
      onClickCopy={onClickCopy}
    />
  );
};
