import liff from "@line/liff";
import { TType } from "@onn/common/domain/LineUserActiveLog/schema";

import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";

import { useLineAccessToken } from "~/hooks/context";
import { useCurrentUserNonGuarded } from "~/hooks/employee";

import { useLiffId } from "~/hooks/liff/useLiffId";

import {
  useCreateLineUserActiveLog,
  useUpdateSequenceEndLineUserActiveLogs,
} from "~/hooks/lineUserActiveLog";
import { useSessionStorage } from "~/hooks/shared";
import { captureException } from "~/util";

type Props = {
  children: ReactNode;
};

// NOTE: リッチメニューから外部リンクへの遷移時に、ログ情報を取得するためにOnnの中でiframeを使って表示している
export const PortalLineUserActiveLogger: FC<Props> = ({ children }: Props) => {
  if (!liff.isInClient()) {
    return <>{children}</>;
  }

  const params = new URLSearchParams(location.search);
  const isLineUserActiveLog = params.get("isLineUserActiveLog");

  // ロギング対象ページがどうか
  const isLoggingTargetPage = isLineUserActiveLog === "true";

  if (isLoggingTargetPage) {
    return <LoggingWrapper>{children}</LoggingWrapper>;
  }
  return <>{children}</>;
};

const LoggingWrapper: FC<Props> = ({ children }: Props) => {
  const [isLogging, setIsLogging] = useState(true);
  const [_, setErrorCount] = useState(0);

  useStoreTenantIdAndSpaceIdInSessionStorage();

  const {
    getLineAccessTokenAndCreateLineUserActiveLog,
    getLineAccessTokenAndUpdateLineUserActiveLog,
  } = useGetLineAccessTokenAndCreateOrUpdateLineUserActiveLog();

  /**
   * アクセス時にsequence_startのログとsequence_endのログを作成する
   * 5sおきにsequence_endのログを更新し離脱時のログとする
   */
  useEffect(() => {
    const clientOS = liff.getOS();
    getLineAccessTokenAndCreateLineUserActiveLog("SEQUENCE_START")
      .then(() => getLineAccessTokenAndCreateLineUserActiveLog("SEQUENCE_END"))
      .catch((e) => {
        captureException({
          error: e as Error,
          tags: {
            type: "リダイレクト時のログ作成に失敗しました。",
          },
          extras: {
            location: window.location.href,
            isCookieEnabled: navigator.cookieEnabled,
            clientOS,
            error: e,
          },
        });
      })
      .finally(() => {
        // NOTE: ログ作成時にエラーが発生した場合にローディング画面がでっぱなしにならないようにしている
        setIsLogging(false);
      });

    // NOTE: 5秒ごとにSEQUENCE_ENDのログを更新する
    const logInterval = setInterval(() => {
      getLineAccessTokenAndUpdateLineUserActiveLog()
        .catch((e) => {
          // NOTE: setIntervalのコールバック関数をセットするときにerrorCountをクロージャーに保存してしまい、更新を検知できないので、setErrorCount内でerrorCountを参照している
          setErrorCount((errorCount) => {
            // NOTE: 3回目以降のエラーはログを送信しない
            if (errorCount < 2) {
              captureException({
                error: e as Error,
                tags: {
                  type: "リダイレクト時のログ作成に失敗しました。",
                },
                extras: {
                  location: window.location.href,
                  isCookieEnabled: navigator.cookieEnabled,
                  clientOS,
                  error: e,
                },
              });
            }
            return errorCount + 1;
          });
        })
        .finally(() => {
          setIsLogging(false);
        });
    }, 1000 * 5);

    return () => {
      clearInterval(logInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // NOTE: LINEがバックグラウンドになって、再度LINEに戻ってきたときにSEQUENCE_ENDが同じsequenceIdで更新されてしまう。
    // そのため、バックグラウンドになったときにSEQUENCE_STOPを作成し、再度LINEに戻ってきたときにSEQUENCE_RESTARTを作成することにより、実際にLiffを開いている時間を計測できるようにしている。
    document.onvisibilitychange = async () => {
      try {
        if (document.visibilityState === "visible") {
          await getLineAccessTokenAndCreateLineUserActiveLog("SEQUENCE_RESTART").then(() =>
            getLineAccessTokenAndUpdateLineUserActiveLog()
          );
        } else if (document.visibilityState === "hidden") {
          await getLineAccessTokenAndCreateLineUserActiveLog("SEQUENCE_STOP");
        }
      } catch (e) {
        captureException({
          error: e as Error,
          tags: {
            type: "LINEのバックグラウンド切り替わりのログ作成に失敗しました。",
          },
          extras: {
            location: window.location.href,
            isCookieEnabled: navigator.cookieEnabled,
            clientOS: liff.getOS(),
            error: e,
          },
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLogging) return null;
  return <>{children}</>;
};

const useStoreTenantIdAndSpaceIdInSessionStorage = () => {
  const { retrieveValue, storeValue } = useSessionStorage();
  const tenantIdInStorage = retrieveValue("tenantId");
  const spaceIdInStorage = retrieveValue("spaceId");

  const params = new URLSearchParams(window.location.search);

  const tenantId = params.get("tenantId");
  const spaceId = params.get("spaceId") || undefined;

  if (!tenantIdInStorage) {
    storeValue("tenantId", tenantId);
  }
  if (!spaceIdInStorage) {
    storeValue("spaceId", spaceId);
  }
};

const useSessionId = () => {
  const { retrieveValue } = useSessionStorage();
  return retrieveValue<string>("sessionId");
};

const useTenantId = () => {
  // NOTE: 未登録ユーザーのログ情報も取れるようにクエリパラメータでtenantIdを取得している
  const { retrieveValue } = useSessionStorage();
  const { currentUser } = useCurrentUserNonGuarded();
  const tenantIdInSessionStorage = retrieveValue<string>("tenantId");
  // NOTE: 画面遷移等でクエリパラメータからtenantIdが取得できない場合があるので、その場合はcurrentUserから取得する
  const tenantId = tenantIdInSessionStorage || currentUser?.tenantId;
  return tenantId;
};

const useSpaceId = () => {
  // NOTE: 未登録ユーザーのログ情報も取れるようにクエリパラメータでspaceIdを取得している
  const { retrieveValue } = useSessionStorage();
  const { currentUser } = useCurrentUserNonGuarded();
  const spaceIdInSessionStorage = retrieveValue<string>("spaceId");
  // NOTE: 画面遷移等でクエリパラメータからspaceIdが取得できない場合があるので、その場合はcurrentUserから取得する
  const spaceId = spaceIdInSessionStorage || currentUser?.spaceId;
  return spaceId;
};

const useGetLineAccessTokenAndCreateOrUpdateLineUserActiveLog = () => {
  const sessionId = useSessionId();
  const tenantId = useTenantId();
  const spaceId = useSpaceId();

  const { guardAndGetLineAccessTokenFromLiff } = useLineAccessToken();

  const { createLineUserActiveLogs } = useCreateLineUserActiveLog();
  const { updateSequenceEndLineUserActiveLogs } = useUpdateSequenceEndLineUserActiveLogs();
  const liffId = useLiffId();

  const validate = useCallback(() => {
    if (!liffId) {
      throw new Error("LIFF IDが設定されていません");
    }
    if (!sessionId) {
      throw new Error("セッションIDの取得に失敗しました");
    }
    if (!tenantId) {
      throw new Error("テナントIDの取得に失敗しました");
    }

    return { liffId, sessionId, tenantId };
  }, [liffId, sessionId, tenantId]);

  const getLineAccessTokenAndCreateLineUserActiveLog = useCallback(
    async (type: TType) => {
      const { liffId, sessionId, tenantId } = validate();

      const lineAccessToken = guardAndGetLineAccessTokenFromLiff();

      const location = `${window.location.pathname}${window.location.search}`;

      await createLineUserActiveLogs({
        lineAccessToken,
        location,
        tenantId,
        liffId,
        spaceId,
        type,
        sequenceId: sessionId,
      });
    },
    [createLineUserActiveLogs, guardAndGetLineAccessTokenFromLiff, spaceId, validate]
  );

  const getLineAccessTokenAndUpdateLineUserActiveLog = useCallback(async () => {
    const { liffId, sessionId, tenantId } = validate();

    const lineAccessToken = guardAndGetLineAccessTokenFromLiff();

    const location = `${window.location.pathname}${window.location.search}`;

    await updateSequenceEndLineUserActiveLogs({
      lineAccessToken,
      tenantId,
      liffId,
      sequenceId: sessionId,
      location,
    });
  }, [guardAndGetLineAccessTokenFromLiff, updateSequenceEndLineUserActiveLogs, validate]);

  return {
    getLineAccessTokenAndCreateLineUserActiveLog,
    getLineAccessTokenAndUpdateLineUserActiveLog,
  };
};
