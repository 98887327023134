import { APISchema } from "@onn/common";

import { useCallback } from "react";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/employee/issue-custom-token-for-line"]["POST"];

export const useIssueCustomTokenForLine = () => {
  const issueCustomTokenForLine = useCallback(async ({ accessToken }: { accessToken: string }) => {
    const requestBody: Endpoint["body"] = { accessToken };
    const response = await apiClient.post("/api/employee/issue-custom-token-for-line", requestBody);

    return response.data.customToken;
  }, []);

  return { issueCustomTokenForLine };
};
