import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { OnnTaskListTab } from "./OnnTaskListTab";

import { Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

type TabValue = "onnTaskList";

export const TasksForNewGraduate: FC = () => {
  const [activeTab, setActiveTab] = useState<TabValue>("onnTaskList");
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    switch (location.hash) {
      case "#onnTaskList": {
        setActiveTab("onnTaskList");
        break;
      }

      default: {
        setActiveTab("onnTaskList");
      }
    }
  }, [currentUser, location.hash]);

  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };

  const tabs: Array<{ label: string; value: string }> = [
    {
      label: "配信タスク",
      value: "onnTaskList",
    },
  ];

  return (
    <>
      <Box display="inline" pr={2}>
        <Typography variant="h4" bold display="inline">
          タスク
        </Typography>
      </Box>

      <Box>
        <TabContext value={activeTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeActiveTab}
          >
            {/* StyledTabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <StyledTabPanel value="onnTaskList">
            <OnnTaskListTab />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </>
  );
};

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0px;
  }
`;
