import { Box } from "@material-ui/core";
import { orderBy } from "lodash";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { PortalOnnTaskCard } from "../_share/PortalOnnTaskCard";

import { Typography, Paper, Loading, Button } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useOnnFormTasksAnswers } from "~/hooks/onnFormTaskAnswer";
import { useOnnTasksForPortal } from "~/hooks/onnTask";

type Filter = "answered" | "noAnswered";

export const OnnTasksIndex: FC = () => {
  const { currentUser } = useCurrentUser();
  const { data: onnTasks = [], isLoading } = useOnnTasksForPortal({ employeeId: currentUser.id });
  const { data: onnFormTasksAnswers = [] } = useOnnFormTasksAnswers({ employeeId: currentUser.id });
  const onnTasksWithAnswer = onnTasks.flatMap((onnTask) => {
    const onnTaskAnswer = onnFormTasksAnswers.find((answer) => answer.formTaskId === onnTask.id);
    if (!onnTaskAnswer) return [];
    return { onnTask, onnTaskAnswer };
  });

  const [selectedFilterIsAnswered, setSelectedFilterIsAnswered] = useState<Filter>("noAnswered");
  // 回答済フィルターボタンクリック
  const onClickFilterButtonAnswered = () => setSelectedFilterIsAnswered("answered");
  // 未回答フィルターボタンクリック
  const onClickFilterButtonNoAnswered = () => setSelectedFilterIsAnswered("noAnswered");

  // 回答期限が切れていない未回答のタスク
  const noAnsweredAndCanAnswerOnnTasks = useMemo(() => {
    return orderBy(
      onnTasksWithAnswer.filter((v) => {
        return !v.onnTaskAnswer.isAnswered() && v.onnTask.canAnswer();
      }),
      "onnTask.deadlineDate",
      "asc"
    );
  }, [onnTasksWithAnswer]);

  // 送付されているタスクのうち、回答済か回答期限が切れているタスク
  const answeredOrExceededOnnTasks = useMemo(() => {
    return orderBy(
      onnTasksWithAnswer.filter((v) => {
        return v.onnTaskAnswer.isAnswered() || !v.onnTask.canAnswer();
      }),
      "onnTask.deadlineDate",
      "asc"
    );
  }, [onnTasksWithAnswer]);

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  return (
    <Box pt="40px" px="24px" pb="126px">
      <Typography variant="h2" color="textPrimary" style={{ fontWeight: 400 }}>
        フォーム一覧
      </Typography>
      <Box my={4}>
        <StyledPaper square elevation={1}>
          <Box display="flex" width="100%">
            <Button
              fullWidth
              onClick={onClickFilterButtonNoAnswered}
              color={selectedFilterIsAnswered === "noAnswered" ? "primary" : "default"}
              variant={selectedFilterIsAnswered === "noAnswered" ? "contained" : "text"}
              borderRadius="regular"
            >
              <Box
                width="50%"
                style={{ fontWeight: selectedFilterIsAnswered === "noAnswered" ? 700 : 400 }}
              >
                未回答({noAnsweredAndCanAnswerOnnTasks.length})
              </Box>
            </Button>
            <Button
              fullWidth
              onClick={onClickFilterButtonAnswered}
              color={selectedFilterIsAnswered === "answered" ? "primary" : "default"}
              variant={selectedFilterIsAnswered === "answered" ? "contained" : "text"}
              borderRadius="regular"
            >
              <Box
                width="50%"
                style={{ fontWeight: selectedFilterIsAnswered === "answered" ? 700 : 400 }}
              >
                回答済み({answeredOrExceededOnnTasks.length})
              </Box>
            </Button>
          </Box>
        </StyledPaper>
      </Box>
      {(selectedFilterIsAnswered === "answered"
        ? answeredOrExceededOnnTasks
        : noAnsweredAndCanAnswerOnnTasks
      ).map((onnTaskWithAnswer) => {
        return (
          <Box key={onnTaskWithAnswer.onnTask.id} mb={2}>
            <PortalOnnTaskCard onnTaskWithAnswer={onnTaskWithAnswer} />
          </Box>
        );
      })}
    </Box>
  );
};

const StyledPaper = styled(Paper)`
  border-radius: 15px;
  padding: 8px;
`;
