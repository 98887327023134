import { useCallback, useState } from "react";

import { AccountUseCase } from "~/service/usecases/employeeUseCase";

export const useCheckMailExist = () => {
  const [isLoading, setIsLoading] = useState(false);

  const checkMailExist = useCallback(async ({ email }: { email: string }) => {
    setIsLoading(true);
    const isExist = await AccountUseCase.checkMailExist(email).finally(() => {
      setIsLoading(false);
    });

    return isExist;
  }, []);

  return { checkMailExist, isLoading };
};
