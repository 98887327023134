import { Box } from "@material-ui/core";
import React, { FC, useReducer, useState } from "react";

import { v4 } from "uuid";

import { ActionFooter } from "../_share_in_create_edit/components/ActionFooter";

import { AddQuestionButton } from "../_share_in_create_edit/components/parts/AddQuestionButton";
import { State, reducer } from "../_share_in_create_edit/reducer";

import { OnnFormTaskQuestions } from "./OnnFormTaskQuestions";

import { OnnTaskOverViewFormMemo } from "./OnnTaskOverViewForm";

import { CreateOnnTaskPageContext } from "./context";

import { createInitialState } from "./reducer";
import { usePage } from "./usePage";

import { Typography } from "~/components/uiParts";

export const Page: FC = () => {
  // タスク新規作成ページに遷移したときに、新規作成するタスクのIDを固定させる
  const [onnFormTaskId] = useState(v4());
  const [state, dispatch] = useReducer(reducer, createInitialState());

  return (
    <CreateOnnTaskPageContext.Provider value={{ onnFormTaskId, dispatch }}>
      <PageCore state={state} />
    </CreateOnnTaskPageContext.Provider>
  );
};

export const PageCore: FC<{ state: State }> = ({ state }) => {
  const {
    dispatch,
    handleAddQuestion,
    actionFooterHandler,
    isDisableSaveButton,
    isLoadingSaveButton,
  } = usePage({ state });

  return (
    <Box display="flex" justifyContent="center">
      <Box width="800px" mb="80px">
        <Typography variant="h4" bold style={{ marginBottom: "40px" }}>
          配信タスク作成
        </Typography>
        <OnnTaskOverViewFormMemo
          selectedOnnTaskType={state.selectedOnnTaskTypes}
          isAllowEditAnswer={state.isAllowEditAnswer}
          dispatch={dispatch}
          onnTaskTitleTextField={state.onnTaskTitleTextField}
          onnTaskDescriptionTextField={state.onnTaskDescriptionTextField}
        />
        <Typography variant="body1" color="textPrimary" style={{ marginTop: "40px" }}>
          設問
        </Typography>
        <OnnFormTaskQuestions {...{ state, dispatch }} />
        <AddQuestionButton onClick={handleAddQuestion} />
        <ActionFooter
          onClickCancel={actionFooterHandler.onClickCancel}
          onClickPreview={actionFooterHandler.onClickPreview}
          onClickSaveAndToNext={actionFooterHandler.onClickSaveAndToNext}
          isDisableSaveButton={isDisableSaveButton}
          isLoadingSaveButton={isLoadingSaveButton}
        />
      </Box>
    </Box>
  );
};
