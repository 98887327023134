import { IconButton as MuiIconButton } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";

type IconProps = ComponentProps<typeof Icon>;

type Props = {
  color?: IconProps["color"];
  size?: "sm" | "ssm" | "md" | "lg";
  borderRadius?: "circle" | "regular";
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseOver?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseOut?: (event: React.MouseEvent<HTMLElement>) => void;
  buttonRef?: React.RefObject<HTMLButtonElement>;
} & Pick<IconProps, "icon">;

export const IconButton: FC<Props> = ({
  icon,
  onClick,
  onMouseOver,
  onMouseOut,
  color = "grey",
  size = "md",
  borderRadius = "circle",
  disabled,
  buttonRef,
  ...props
}) => {
  return (
    <StyledIconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      $borderRadius={borderRadius}
      disabled={disabled}
      disableRipple={borderRadius === "regular"} // rippleは円形なので無効にする
      {...props}
      ref={buttonRef}
    >
      <Icon icon={icon} size={size} color={color} />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(MuiIconButton)<{
  $borderRadius: "circle" | "regular";
}>`
  &.MuiIconButton-root {
    padding: 8px;
    ${(props) =>
      props.$borderRadius === "regular" &&
      `
        background-color: ${props.theme.palette.grey[100]};
        border-radius: 5px;
      `}
  }
`;
