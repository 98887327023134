import { z } from "zod";

import { OnnFormTaskResultActionConditionAnswerTypeSchema } from "../inputTypeSchemas/OnnFormTaskResultActionConditionAnswerTypeSchema";

/////////////////////////////////////////
// ONN FORM TASK RESULT ACTION CONDITION SCHEMA
/////////////////////////////////////////

export const OnnFormTaskResultActionConditionSchema = z.object({
  answerType: OnnFormTaskResultActionConditionAnswerTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  actionId: z.string(),
  onnFormTaskId: z.string(),
  questionId: z.string(),
  optionId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnFormTaskResultActionCondition = z.infer<
  typeof OnnFormTaskResultActionConditionSchema
>;

/////////////////////////////////////////
// ONN FORM TASK RESULT ACTION CONDITION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnFormTaskResultActionConditionOptionalDefaultsSchema =
  OnnFormTaskResultActionConditionSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnFormTaskResultActionConditionOptionalDefaults = z.infer<
  typeof OnnFormTaskResultActionConditionOptionalDefaultsSchema
>;

export default OnnFormTaskResultActionConditionSchema;
