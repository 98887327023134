import { z } from "zod";

import {
  EmployeeTagActionConditionSchema,
  OnnFormTaskResultActionConditionSchema,
  OnnEventEvaluationActionConditionSchema,
} from "../../../domain/_gen/zodSchema";

export const employeeTagActionConditionSchema = EmployeeTagActionConditionSchema;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeTagActionConditionSchema
  extends z.infer<typeof employeeTagActionConditionSchema> {}

export const onnFormTaskResultActionConditionSchema = OnnFormTaskResultActionConditionSchema;

export const onnEventEvaluationActionConditionSchema =
  OnnEventEvaluationActionConditionSchema.merge(
    z.object({
      onnEventEvaluationRankIds: z.array(z.string()).min(1),
    })
  );

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventEvaluationActionConditionSchema
  extends z.infer<typeof onnEventEvaluationActionConditionSchema> {}

export const anyActionConditionWithTypeSchema = z.union([
  onnEventEvaluationActionConditionSchema.merge(
    z.object({ type: z.literal("OnnEventEvaluationActionCondition") })
  ),
  onnFormTaskResultActionConditionSchema.merge(
    z.object({ type: z.literal("OnnFormTaskResultActionCondition") })
  ),
  employeeTagActionConditionSchema.merge(
    z.object({ type: z.literal("EmployeeTagActionCondition") })
  ),
]);
