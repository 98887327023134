import { OnnEvent } from "@onn/common";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useFromPageInQS } from "./useFromPageInQS";

export const useCancelButtonHandler = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const fromPage = useFromPageInQS();
  const navigate = useNavigate();

  const onClickCancel = useCallback(() => {
    if (fromPage === "create") {
      navigate(`/events/${onnEvent.id}`);
    } else if (fromPage === "detail") {
      navigate(`/events/${onnEvent.id}`);
    } else {
      navigate("/events");
    }
  }, [navigate, fromPage, onnEvent.id]);

  return { onClickCancel };
};
