import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  dueDate?: Date;
};

export const DueDateCell: FC<Props> = ({ dueDate }) => {
  const dueDateStr = dueDate ? format(dueDate, "yyyy/MM/dd") : "-";
  return (
    <Box display="flex" alignItems="center" gridGap="16px">
      <Typography variant="body2">{dueDateStr}</Typography>
    </Box>
  );
};
