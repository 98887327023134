import React, { FC, useMemo } from "react";
import { useController, Control } from "react-hook-form";

import { OnnEventTitleTextField } from "../OnnEventTitleTextField";

import { InputState } from "./InputState";

type Props = {
  control: Control<InputState>;
};

export const TitleTextField: FC<Props> = ({ control }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "title" });
  const isError = error !== undefined;
  const helperText = isError
    ? value.length === 0
      ? "イベントタイトルを入力してください"
      : error.message
    : "";
  const Component = useMemo(() => {
    return (
      <OnnEventTitleTextField
        fullWidth
        value={value}
        onChange={onChange}
        error={isError}
        autoFocus
        maxTextCount={50}
        helperText={helperText}
      />
    );
  }, [helperText, isError, onChange, value]);

  return Component;
};
