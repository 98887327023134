import React, { FC } from "react";
import { Navigate } from "react-router-dom";

import { DefaultLayout } from "~/components/layouts";
import { useAuthenticationNonGuarded } from "~/hooks/context";
import { useCurrentUserNonGuarded } from "~/hooks/employee";
import { useQuery, useScrollTopByDetectTransition, useSnackbar } from "~/hooks/shared";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";
import { AccountRegister } from "~/pages/account-register/show";
import { ChangeAuthenticationPage } from "~/pages/changeAuthentication/ChangeAuthenticationPage";
import { ExternalPageInIframe, TransitionToExternalPageInLiff } from "~/pages/externalPage";
import { RegistrationIndividual as PortalRegistrationIndividual } from "~/pages/portal/Account/Invitation/Registration";
import { Account as PortalAccount } from "~/pages/portal/Account/Invitation/show";
import { LoginPage } from "~/pages/portal/Account/Login/LoginPage";
import { Registration } from "~/pages/portal/Account/Registration";

import { RegistrationInvitationLinks } from "~/pages/portal/Account/Registration/RegistrationInvitationLinks";

import { RegistrationInvitationLinksDetail } from "~/pages/portal/Account/Registration/RegistrationInvitationLinksDetail";
import { ShareInvite } from "~/pages/shareInvite/show";

const GuestRoute = ({
  component: Component,
  messageForAuthenticated,
}: {
  component: FC;
  messageForAuthenticated?: string;
}) => {
  useScrollTopByDetectTransition();
  const { authUser, isReady } = useAuthenticationNonGuarded();
  const { currentUser } = useCurrentUserNonGuarded();
  const { query } = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  if (!isReady) {
    return null;
  }

  if (isReady && authUser && currentUser) {
    const isSignedInCurrentTenant = getTenantIdFromDomain() === currentUser.tenantId;
    if (isSignedInCurrentTenant) {
      const destPath = query.get("dest-path") || "/portal";
      if (messageForAuthenticated) {
        enqueueSnackbar(messageForAuthenticated, { variant: "info" });
      }
      return <Navigate to={destPath} />;
    }
  }

  return <Component />;
};

/**
 * ポータルのゲストユーザーのルーティング
 */
export const portalGuestRoutes = [
  {
    // [共通招待/個別招待] アカウント登録landingページ
    // リッチメニューやシステム通知経由など、特定の招待URLが使われない場合に利用される
    // ユーザーの状況によって、個別招待 or 共通招待 のどちらかへ進む
    path: "/account/register",
    element: (
      <DefaultLayout isBackgroundImage>
        <GuestRoute component={AccountRegister} />
      </DefaultLayout>
    ),
  },

  {
    // [共通招待] アカウント登録扉ページ
    path: "/portal/accounts/registration",
    element: (
      <DefaultLayout isBackgroundImage>
        <GuestRoute component={Registration} messageForAuthenticated="アカウント登録済みです" />
      </DefaultLayout>
    ),
  },
  {
    // [共通招待] アカウント登録中間ページ（共通招待リンクが決定していない場合に経由する）
    path: "/portal/accounts/registration/:type/registration_invitation_links",
    element: (
      <DefaultLayout isBackgroundImage>
        <GuestRoute
          component={RegistrationInvitationLinks}
          messageForAuthenticated="アカウント登録済みです"
        />
      </DefaultLayout>
    ),
  },
  {
    // [共通招待] アカウント登録ページ
    path: "/portal/accounts/registration/:type/registration_invitation_links/:id",
    element: (
      <DefaultLayout isBackgroundImage>
        <GuestRoute
          component={RegistrationInvitationLinksDetail}
          messageForAuthenticated="アカウント登録済みです"
        />
      </DefaultLayout>
    ),
  },
  {
    // [共通招待] 旧アカウント登録ページ
    // CAUTION: いずれ削除する予定なので、新たに利用しない
    path: "/share_invite/:id",
    element: (
      <DefaultLayout isBackgroundImage>
        <GuestRoute component={ShareInvite} messageForAuthenticated="アカウント登録済みです" />
      </DefaultLayout>
    ),
  },

  {
    // [個別招待] 招待トークン(個別招待URL)を用いたアカウント登録扉ページ
    path: "/portal/accounts/invitations/:id",
    element: (
      <DefaultLayout isBackgroundImage>
        <GuestRoute component={PortalAccount} />
      </DefaultLayout>
    ),
  },
  {
    // [個別招待] 招待トークン(個別招待URL)を用いたアカウント登録ページ
    path: "/portal/accounts/invitations/:id/registration/:type",
    element: (
      <DefaultLayout isBackgroundImage>
        <GuestRoute component={PortalRegistrationIndividual} />
      </DefaultLayout>
    ),
  },

  {
    // 認証切り替えのための画面
    path: "/change_authentication",
    element: (
      <DefaultLayout isBackgroundImage>
        <ChangeAuthenticationPage />
      </DefaultLayout>
    ),
  },

  {
    path: "/portal/accounts/login",
    element: (
      <DefaultLayout isBackgroundImage>
        <GuestRoute component={LoginPage} />
      </DefaultLayout>
    ),
  },

  {
    // 外部サイトへ遷移させる
    path: "/portal/external_page/transition",
    element: <TransitionToExternalPageInLiff />,
  },
  {
    // iframeで外部サイトを表示する
    path: "/portal/external_page/iframe",
    element: <ExternalPageInIframe />,
  },
];
