import { NotifyOnnEventAnswerRemindByEventIdRequest } from "@onn/common";
import { useState } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export const useNotifyOnnEventAnswerRemindByEventId = () => {
  const [isLoading, setIsLoading] = useState(false);

  const notifyOnnEventAnswerRemindByEventId = async ({
    tenantId,
    onnEventId,
  }: NotifyOnnEventAnswerRemindByEventIdRequest) => {
    try {
      const data = NotifyOnnEventAnswerRemindByEventIdRequest.createRequestBody({
        tenantId,
        onnEventId,
      });
      setIsLoading(true);
      await functionOperator.httpsCallFor2ndGen<
        ReturnType<typeof NotifyOnnEventAnswerRemindByEventIdRequest.createRequestBody>,
        void
      >("onneventanswer", data);
    } finally {
      setIsLoading(false);
    }
  };

  return { notifyOnnEventAnswerRemindByEventId, isLoading };
};
