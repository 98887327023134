import { List } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { CheckboxRowRenderer } from "./CheckboxRowRenderer";
import { DefaultRowRenderer } from "./DefaultRowRenderer";

import { Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";

const heightMap = {
  default: 400,
  checkbox: 224,
};
const rowHeight = 56;
const overscanRowCount = 10;

type Props = {
  employees: Employee[];
  isLoading?: boolean;
  rowRendererMode?: "default" | "checkbox";
} & (
  | {
      selectedEmployees: Employee[];
      selectedEmployee?: undefined;
      onSelect: (employees: Employee[]) => void;
      isMultiple: true;
    }
  | {
      selectedEmployees?: undefined;
      selectedEmployee?: Employee;
      onSelect: (employee: Employee) => void;
      isMultiple: false;
    }
);

export const EmployeeList: FC<Props> = ({
  employees,
  selectedEmployees,
  selectedEmployee,
  onSelect,
  isMultiple,
  isLoading,
  rowRendererMode = "default",
}) => {
  const handleSelectEmployee = useCallback(
    (employee: Employee) => {
      if (!isMultiple) {
        return onSelect(employee);
      }

      // 選択済みのユーザーをクリックしたとき
      if (selectedEmployees.includes(employee)) {
        const newArray = selectedEmployees.filter((v) => {
          return v.id !== employee.id;
        });
        onSelect(newArray);
      } else {
        onSelect([...selectedEmployees, employee]);
      }
    },
    [isMultiple, onSelect, selectedEmployees]
  );

  const getSelectedEmployeeIds = useCallback(() => {
    if (isMultiple) {
      return selectedEmployees.map((v) => v.id);
    }
    return selectedEmployee ? [selectedEmployee.id] : [];
  }, [isMultiple, selectedEmployee, selectedEmployees]);

  return (
    <StyledList
      $height={
        heightMap[rowRendererMode] < rowHeight * employees.length
          ? heightMap[rowRendererMode]
          : rowHeight * employees.length
      }
      $isScrollable={!isLoading}
    >
      <AutoSizer
        disableHeight // 子要素の高さが固定長であるため高さを計算しない
      >
        {(size) => (
          <VirtualizedList
            height={
              heightMap[rowRendererMode] < rowHeight * employees.length
                ? heightMap[rowRendererMode]
                : rowHeight * employees.length
            }
            width={size.width}
            overscanRowCount={overscanRowCount}
            rowCount={employees.length}
            rowHeight={rowHeight}
            rowRenderer={(props) => {
              if (rowRendererMode === "checkbox") {
                return (
                  <CheckboxRowRenderer
                    {...props}
                    employees={employees}
                    selectedEmployeeIds={getSelectedEmployeeIds()}
                    onSelect={handleSelectEmployee}
                  />
                );
              }
              return (
                <DefaultRowRenderer
                  {...props}
                  employees={employees}
                  selectedEmployeeIds={getSelectedEmployeeIds()}
                  onSelect={handleSelectEmployee}
                />
              );
            }}
            noRowsRenderer={() => (
              <Typography variant="caption" align="center" display="block" color="textSecondary">
                結果が見つかりませんでした
              </Typography>
            )}
          />
        )}
      </AutoSizer>
    </StyledList>
  );
};

const StyledList = styled(List)<{ $height: number; $isScrollable: boolean }>`
  padding: 0px;
  height: ${(props) => props.$height}px;
  overflow-y: auto;
`;
