import React, { FC, useMemo } from "react";
import { useController, Control } from "react-hook-form";

import { OnnEventContentTextArea } from "../OnnEventContentTextArea";

import { InputState } from "./InputState";

type Props = {
  control: Control<InputState>;
};

export const ContentTextarea: FC<Props> = ({ control }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "content" });
  const isError = error !== undefined;
  const helperText = isError
    ? value.length === 0
      ? "説明文を入力してください"
      : error.message
    : "";
  const Component = useMemo(() => {
    return (
      <OnnEventContentTextArea
        value={value}
        onChange={onChange}
        fullWidth
        error={isError}
        autoFocus
        helperText={helperText}
      />
    );
  }, [helperText, isError, onChange, value]);

  return Component;
};
