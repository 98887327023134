import { z } from "zod";

/////////////////////////////////////////
// TRIGGER LOG SCHEMA
/////////////////////////////////////////

export const TriggerLogSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  employeeId: z.string(),
  triggerId: z.string(),
  createdAt: z.date(),
});

export type TriggerLog = z.infer<typeof TriggerLogSchema>;

/////////////////////////////////////////
// TRIGGER LOG OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const TriggerLogOptionalDefaultsSchema = TriggerLogSchema.merge(
  z.object({
    createdAt: z.date().optional(),
  })
);

export type TriggerLogOptionalDefaults = z.infer<typeof TriggerLogOptionalDefaultsSchema>;

export default TriggerLogSchema;
