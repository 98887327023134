import {
  IContactMessageTemplateSchema,
  TITLE_MAX_LENGTH,
  contactMessageTemplateSchema,
} from "./schema";

export class ContactMessageTemplate implements IContactMessageTemplateSchema {
  static titleMaxLength = TITLE_MAX_LENGTH;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly folderId?: string;
  readonly title: string;
  readonly text: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ContactMessageTemplateInput) {
    const parsedInit = contactMessageTemplateSchema.parse(init);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.title = parsedInit.title;
    this.text = parsedInit.text;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.folderId = parsedInit.folderId;
  }

  public static createNew(init: {
    tenantId: string;
    spaceId: string;
    title: string;
    text: string;
    folderId?: string;
  }): ContactMessageTemplate {
    return new ContactMessageTemplate(init);
  }

  public update(
    updateParams: Pick<ContactMessageTemplateInput, "title" | "text" | "folderId">
  ): ContactMessageTemplate {
    return new ContactMessageTemplate({
      ...this,
      ...updateParams,
      updatedAt: new Date(),
    });
  }
}

export type ContactMessageTemplateType = ExcludeMethods<ContactMessageTemplate>;
export type ContactMessageTemplateInput = Partial<ContactMessageTemplateType>;
