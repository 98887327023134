import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION GROUP SCHEMA
/////////////////////////////////////////

export const EmployeeInformationGroupSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  label: z.string(),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationGroup = z.infer<typeof EmployeeInformationGroupSchema>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION GROUP OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationGroupOptionalDefaultsSchema = EmployeeInformationGroupSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type EmployeeInformationGroupOptionalDefaults = z.infer<
  typeof EmployeeInformationGroupOptionalDefaultsSchema
>;

export default EmployeeInformationGroupSchema;
