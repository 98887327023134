import { Box, Grid } from "@material-ui/core";
import { Employee, SelectableFeatures } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  AdminContentWrapper,
  Button,
  Paper,
  Switch,
  TextContext,
  Typography,
} from "~/components/uiParts";
import { useAdmins, useEmployees } from "~/hooks/employee";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useModal } from "~/hooks/modal";
import { useQuery } from "~/hooks/shared";
import { useScrollToTargetElement } from "~/hooks/shared/useScrollToTargetElement";
import { useUpdateDefaultFollowerIds } from "~/hooks/tenantSetting";
import { useTenantSettings } from "~/hooks/tenantSetting/useTenantSettings";
import { useUpdateNotifyAdminsOfMessageFromUnknownUser } from "~/hooks/tenantSetting/useUpdateNotifyAdminsOfMessageFromUnknownUser";

import { TenantSettingsUseCase } from "~/service/usecases/tenantSettingsUseCase";

const AUTO_SCROLL_TO_CONTENT_Y_OFFSET = 20;

export const FeatureTab: FC = () => {
  const navigate = useNavigate();

  const { currentUser } = useCurrentUser();
  const { tenantSettings, mutateTenantSettings } = useTenantSettings();

  const { updateDefaultFollowerIds } = useUpdateDefaultFollowerIds();
  const { updateAssigneeIds } = useUpdateNotifyAdminsOfMessageFromUnknownUser();

  const { data: accessibleEmployees = [], isValidating: isValidatingAccessibleEmployees } =
    useEmployees(tenantSettings?.contact ? tenantSettings.contact.defaultFollowerEmployeeIds : []);
  const { data: admins = [], isValidating: isValidatingAdmins } = useAdmins(currentUser.tenantId);

  const messageRef = useRef<HTMLDivElement>(null);
  const newcomerPortalRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const [scrollTargetRef, setScrollTargetRef] = useState<RefObject<HTMLDivElement>>();

  const [features, setFeatures] = useState<{ [key in SelectableFeatures]: boolean }>(
    tenantSettings.features
  );

  const { handleModal } = useModal();

  const { query } = useQuery();
  const target = query.get("target");

  const handleClickAddFollower = useCallback(
    (followers: Employee[]) => {
      handleModal({
        name: "addFollowersModal",
        args: {
          followers: followers,
          onSubmit: async (followerIds: string[]) => {
            await updateDefaultFollowerIds(currentUser.tenantId, followerIds);
            mutateTenantSettings();
          },
        },
      });
    },
    [currentUser.tenantId, mutateTenantSettings, handleModal, updateDefaultFollowerIds]
  );

  const handleClickManageButton = useCallback(
    (followers: Employee[], admins: Employee[]) => {
      handleModal({
        name: "manageContactTeamModal",
        args: {
          // 閲覧できるユーザーが設定されていない場合は管理者を表示する
          followers: isEmpty(followers) ? admins : followers,
          onSubmit: async (followers: string[]) => {
            await updateDefaultFollowerIds(currentUser.tenantId, followers);
            mutateTenantSettings();
          },
          onClickAddFollower: (followers: Employee[]) => handleClickAddFollower(followers),
        },
      });
    },
    [
      currentUser.tenantId,
      mutateTenantSettings,
      handleClickAddFollower,
      handleModal,
      updateDefaultFollowerIds,
    ]
  );

  const handleClickAddAdmins = useCallback(
    (assignees: Employee[]) => {
      handleModal({
        name: "addNotifyAdminsOfMessageFromUnknownUserModal",
        args: {
          admins: assignees,
          onSubmit: async (adminIds: string[]) => {
            await updateAssigneeIds(tenantSettings.id, adminIds);
            mutateTenantSettings();
          },
        },
      });
    },
    [tenantSettings.id, mutateTenantSettings, handleModal, updateAssigneeIds]
  );

  const handleClickManageNotifyAdminsButton = useCallback(
    (admins: Employee[]) => {
      const assignees = admins.filter((admin) =>
        tenantSettings.notifyAdminsOfMessageFromUnknownUser?.includes(admin.id)
      );

      handleModal({
        name: "manageNotifyAdminsOfMessageFromUnknownUserModal",
        args: {
          assignees,
          onSubmit: async (assignees: string[]) => {
            await updateAssigneeIds(tenantSettings.id, assignees);
            mutateTenantSettings();
          },
          onClickAddAssignee: (assignees: Employee[]) => handleClickAddAdmins(assignees),
        },
      });
    },
    [tenantSettings, mutateTenantSettings, handleClickAddAdmins, handleModal, updateAssigneeIds]
  );

  useEffect(() => {
    setFeatures(tenantSettings.features);
  }, [tenantSettings]);

  useEffect(() => {
    if (!target) return;

    switch (target) {
      case "message": {
        setScrollTargetRef(messageRef);
        break;
      }
      case "portal": {
        setScrollTargetRef(newcomerPortalRef);
        break;
      }
    }
  }, [target]);

  const handleSwitchFeature = async (
    newObject: Partial<{ [key in SelectableFeatures]: boolean }>
  ) => {
    const newFeatures = { ...features, ...newObject };
    setFeatures(newFeatures);

    await TenantSettingsUseCase.updateFeature(currentUser.tenantId, newFeatures);
    mutateTenantSettings();
  };

  useScrollToTargetElement({
    enabled: !!scrollTargetRef,
    targetRef: scrollTargetRef,
    scrollYOffset: AUTO_SCROLL_TO_CONTENT_Y_OFFSET,
  });

  return (
    <AdminContentWrapper>
      <Paper square>
        <Grid container>
          <Grid item xs={3}>
            <Box
              gridRowGap="32px"
              display="flex"
              flexDirection="column"
              position="sticky"
              top="20px"
            >
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(newcomerPortalRef)}
                $isActive={scrollTargetRef === newcomerPortalRef}
              >
                ポータル
              </StyledSideTypography>
              <StyledSideTypography
                variant="body2"
                onClick={() => setScrollTargetRef(contactRef)}
                $isActive={scrollTargetRef === contactRef}
              >
                コンタクト設定
              </StyledSideTypography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Grid item xs={12} ref={contactRef}>
              <StyledTextContext padding={2} isShowBg>
                <Typography variant="body2" bold>
                  ポータル
                </Typography>
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px={2} pt={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    ライブラリ
                  </Typography>
                  <Switch
                    checked={features.library}
                    onChange={() =>
                      handleSwitchFeature({
                        [SelectableFeatures.LIBRARY]: !features.library,
                      })
                    }
                    color="primary"
                  />
                </Box>
                <StyledTypography variant="caption">
                  候補者がポータルで閲覧できる「組織・カルチャー・人」などに関わるコンテンツをカテゴリーに分けて設定することができます。候補者が入社1日目を迎えるまでに会社へのエンゲージメントを高めるコンテンツをカテゴリーごとに登録しましょう。
                </StyledTypography>
              </Box>
              <Box px={2} mt={3}>
                <Button
                  color="primary"
                  borderRadius="circle"
                  variant="outlined"
                  onClick={() => navigate("/tools#library")}
                >
                  ライブラリ設定へ
                </Button>
              </Box>
            </Grid>
            <Box height="40px" />
            <Grid item xs={12} ref={contactRef}>
              <StyledTextContext padding={2} isShowBg>
                <Typography variant="body2" bold>
                  コンタクト設定
                </Typography>
              </StyledTextContext>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box px="16px" mt="32px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    コンタクト：初期表示
                  </Typography>
                  <Switch
                    checked={features.contactInitialDisplay}
                    onChange={() =>
                      handleSwitchFeature({
                        [SelectableFeatures.CONTACT_INITIAL_DISPLAY]:
                          !features.contactInitialDisplay,
                      })
                    }
                    color="primary"
                  />
                </Box>
                <StyledTypography variant="caption">
                  各採用ツールやメッセージツールに散らばってしまいがちな全候補者とのやりとりをOnn上のチャット機能で集約できる機能です。機能をONにするとポータル上で候補者からいつでもメッセージを受け付けることができ、OFFにすると管理者・受け入れメンバーから1通目のメッセージを送信するまで候補者側にはコンタクト機能は表示されず候補者からのメッセージの送信もできません。
                </StyledTypography>
              </Box>
              <Box px="16px" mt="24px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    デフォルト対応チーム設定
                  </Typography>
                </Box>
                <StyledTypography variant="caption">
                  候補者とのコンタクトを行える初期メンバーをフォロワーとして指定することができます。初期設定としては、管理者の権限を持つメンバーが設定されています。なお、メンバーの追加や削除を行うことも可能です。
                </StyledTypography>
                <Box mt="24px">
                  <Button
                    color="primary"
                    borderRadius="circle"
                    variant="outlined"
                    disabled={isValidatingAdmins || isValidatingAccessibleEmployees}
                    onClick={() => handleClickManageButton(accessibleEmployees, admins)}
                  >
                    デフォルト対応チームを設定
                  </Button>
                </Box>
              </Box>
              <Box px="16px" mt="24px">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" bold>
                    不明なLINEユーザーからメッセージが届いた場合の通知設定
                  </Typography>
                </Box>
                <StyledTypography variant="caption">
                  候補者アカウントに紐づかない不明なLINEユーザーからメッセージが届いた場合、設定した管理者にメールで通知を送ることができます。
                </StyledTypography>
                <Box mt="24px">
                  <Button
                    color="primary"
                    borderRadius="circle"
                    variant="outlined"
                    onClick={() => handleClickManageNotifyAdminsButton(admins)}
                  >
                    通知を受け取る管理者を設定
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </AdminContentWrapper>
  );
};

const StyledSideTypography = styled(Typography)<{ $isActive: boolean }>`
  cursor: pointer;
  ${(props) =>
    props.$isActive &&
    `&.MuiTypography-root {
        color: ${props.theme.palette.primary.main};
        font-weight: bold;
    }`}
`;

const StyledTextContext = styled(TextContext)`
  color: ${(props) => props.theme.palette.grey[400]};
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;
