import { Box } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Badge, Icon, Typography } from "~/components/uiParts";

type Props = {
  unansweredOnnTaskCount: number;
  newGraduateId: string;
};

export const UnansweredOnnTaskCount: FC<Props> = (props) => {
  return (
    <StyledBox>
      <Link to={`/employee/${props.newGraduateId}#onnTask`} target="_blank">
        <StyledBadge
          color="secondary"
          badgeContent={
            props.unansweredOnnTaskCount > 0 ? (
              <Typography bold variant="caption">
                {props.unansweredOnnTaskCount}
              </Typography>
            ) : undefined
          }
        >
          <Icon color="primary" icon="checkOutlineCircle" size="md" />
        </StyledBadge>
      </Link>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
`;

const StyledBadge = styled(Badge)`
  & > .MuiBadge-badge {
    width: 19px;
    height: 13px;
    border-radius: 100vh;
  }
  & .MuiTypography-caption {
    font-size: 10px;
  }
`;
