import React from "react";

export const Location = () => {
  return (
    <svg viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        d="M8 16.9114C9.85592 15.2266 11.2366 13.7224 12.142 12.3989C13.0474 11.0753 13.5001 9.81273 13.5001 8.61116C13.5001 6.96482 12.9692 5.61678 11.9072 4.56704C10.8453 3.51732 9.54275 2.99246 7.99953 2.99246C6.45631 2.99246 5.1539 3.51732 4.09231 4.56704C3.0307 5.61678 2.49989 6.96482 2.49989 8.61116C2.49989 9.81273 2.95259 11.0731 3.858 12.3922C4.76341 13.7114 6.14408 15.2178 8 16.9114ZM7.99682 19.5C7.74328 19.5 7.4905 19.4564 7.23848 19.3691C6.98647 19.2819 6.76146 19.151 6.56345 18.9766C5.48816 18.0094 4.53968 17.0747 3.71801 16.1723C2.89634 15.2699 2.21105 14.3906 1.66213 13.5342C1.11321 12.6778 0.698956 11.8417 0.419364 11.0259C0.139788 10.2101 0 9.40517 0 8.61116C0 6.13824 0.804163 4.16815 2.41249 2.70089C4.02083 1.23363 5.88334 0.5 8 0.5C10.1167 0.5 11.9792 1.23363 13.5875 2.70089C15.1958 4.16815 16 6.13824 16 8.61116C16 9.40517 15.8602 10.2101 15.5806 11.0259C15.301 11.8417 14.8868 12.6778 14.3379 13.5342C13.789 14.3906 13.1037 15.2699 12.282 16.1723C11.4603 17.0747 10.5118 18.0094 9.43655 18.9766C9.23735 19.151 9.01098 19.2819 8.75744 19.3691C8.5039 19.4564 8.25036 19.5 7.99682 19.5ZM8 10.4902C8.56327 10.4902 9.04075 10.2949 9.43245 9.90441C9.82414 9.51388 10.02 9.03781 10.02 8.47622C10.02 7.91462 9.82414 7.43856 9.43245 7.04803C9.04075 6.6575 8.56327 6.46224 8 6.46224C7.43673 6.46224 6.95925 6.6575 6.56755 7.04803C6.17586 7.43856 5.98001 7.91462 5.98001 8.47622C5.98001 9.03781 6.17586 9.51388 6.56755 9.90441C6.95925 10.2949 7.43673 10.4902 8 10.4902Z"
      />
    </svg>
  );
};
