import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";

import { useFormContext, useController } from "react-hook-form";

import styled from "styled-components";

import { InputState } from "../useForm";

import { Button, Icon, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const BriefingSessionsForm = (params: { disabledDeleteIndexes?: number[] }) => {
  const { control } = useFormContext<InputState>();

  const {
    field: { onChange, value: inputValue },
    fieldState: { error },
  } = useController({ control, name: "briefingSessions" });

  const { handleModal } = useModal();

  const handleAddBriefingSessionCategory = useCallback(() => {
    handleModal({
      name: "createOrEditBriefingSessionCategoryModal",
      args: {
        onSave: (input) => {
          onChange([...inputValue, input]);
        },
        data: {
          mode: "create",
        },
      },
    });
  }, [handleModal, onChange, inputValue]);

  const generateOnClickEditBriefingSessionCategoryHandler = useCallback(
    (
        rowIndex: number,
        input: {
          id: string | undefined;
          title: string;
          description: string;
        }
      ) =>
      () => {
        handleModal({
          name: "createOrEditBriefingSessionCategoryModal",
          args: {
            onSave: (input) => {
              onChange(inputValue.map((v, i) => (i === rowIndex ? input : v)));
            },
            data: {
              mode: "edit",
              briefingSession: {
                id: input.id,
                title: input.title,
                description: input.description,
              },
            },
          },
        });
      },
    [handleModal, onChange, inputValue]
  );

  const generateOnClickDeleteBriefingSessionCategoryHandler = useCallback(
    (rowIndex: number) => () => {
      onChange(inputValue.filter((_, i) => i !== rowIndex));
    },
    [onChange, inputValue]
  );

  return (
    <Box display="flex" flexDirection="column" gridRowGap={16}>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        <Box>
          <Typography variant="h4" bold>
            説明会一覧
          </Typography>
        </Box>
        {error && <Typography color="error">{error.message}</Typography>}
        <Box>
          <Box>
            {inputValue.map(({ id, title, description }, i) => {
              const isDisabledDelete = params.disabledDeleteIndexes?.includes(i);
              return (
                <BriefingSessionRow
                  key={i}
                  briefingSessionCategoryName={title}
                  onClickEdit={generateOnClickEditBriefingSessionCategoryHandler(i, {
                    id,
                    title,
                    description,
                  })}
                  onClickDelete={
                    isDisabledDelete
                      ? undefined
                      : generateOnClickDeleteBriefingSessionCategoryHandler(i)
                  }
                />
              );
            })}
          </Box>
          <Box>
            <Button
              variant="text"
              color="primary"
              borderRadius="regular"
              onClick={handleAddBriefingSessionCategory}
              startIcon={<Icon icon="add" size="md" color="primary" />}
            >
              説明会を追加
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const BriefingSessionRow: FC<{
  briefingSessionCategoryName: string;
  onClickEdit: (() => void) | undefined;
  onClickDelete: (() => void) | undefined;
}> = ({ briefingSessionCategoryName, onClickEdit, onClickDelete }) => {
  return (
    <BriefingSessionRowRoot>
      <Typography variant="body1" bold>
        {briefingSessionCategoryName}
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: 24,
          height: "100%",
        }}
      >
        {onClickEdit && (
          <Box height={"48px"} width={"24px"}>
            <Button
              variant="text"
              borderRadius="circle"
              color="default"
              fullWidth
              fullHeight
              onClick={onClickEdit}
            >
              <Icon icon="pencil" size="md" color="grey" />
            </Button>
          </Box>
        )}
        {onClickDelete && (
          <Box height={"48px"} width={"24px"}>
            <Button
              variant="text"
              borderRadius="circle"
              color="default"
              fullWidth
              fullHeight
              onClick={onClickDelete}
            >
              <Icon icon="trash" size="md" color="grey" />
            </Button>
          </Box>
        )}
      </Box>
    </BriefingSessionRowRoot>
  );
};

const BriefingSessionRowRoot = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 16px;
  height: 52px;
`;
