import { isAfter, isPast, isToday, isValid } from "date-fns";
import { useCallback } from "react";

export const useDeadlineDateInput = ({
  scheduledDate,
  deadlineDate,
  initialValue,
}: {
  scheduledDate: Date | null;
  deadlineDate: Date | null;
  initialValue?: Date | null;
}) => {
  const isDeadlineDateBeforeScheduledDate = useCallback(
    (deadlineDate: Date) => {
      if (!scheduledDate) {
        return false;
      }

      return isAfter(scheduledDate, deadlineDate);
    },
    [scheduledDate]
  );

  const getHelperText = useCallback(
    (date: Date | null) => {
      if (date == null && scheduledDate) {
        return "回答期限を設定してください";
      }

      if (date == null) {
        return "";
      }

      if (!isValid(date)) {
        return "正しいフォーマットで入力してください (例: 2000/01/01)";
      }

      if (!isToday(date) && isPast(date)) {
        return "過去の日付は入力できません";
      }

      if (isDeadlineDateBeforeScheduledDate(date)) {
        return "予約配信日より後の日付を入力してください";
      }

      return "";
    },
    [isDeadlineDateBeforeScheduledDate, scheduledDate]
  );

  const isChanged = deadlineDate?.getTime() !== initialValue?.getTime();
  const isError = useCallback(
    (date: Date | null) => {
      return !!getHelperText(date);
    },
    [getHelperText]
  );

  return {
    deadlineDate,
    error: isError(deadlineDate),
    helperText: getHelperText(deadlineDate),
    isChanged,
  };
};
