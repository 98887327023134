import React, { Dispatch, FC, memo } from "react";

import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { v4 } from "uuid";

import { TextQuestionFormUIMemo } from "../../_share_in_create_edit/components/forms/TextQuestionFormUIMemo";
import { Action } from "../../_share_in_create_edit/reducer";

import { TEXT_QUESTION_FOR_FORM } from "../../_share_in_create_edit/types";

import { useQuestionFormBase } from "./useQuestionFormBase";

export type Props = {
  textQuestion: TEXT_QUESTION_FOR_FORM;
  dispatch: React.Dispatch<Action>;
  questionDragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

const useTextQuestionForm = ({
  dispatch,
  textQuestion,
}: {
  dispatch: Dispatch<Action>;
  textQuestion: TEXT_QUESTION_FOR_FORM;
}) => {
  const { onChangeQuestionOptionType, onChangeTitle, onChangeIsRequired, deleteQuestion } =
    useQuestionFormBase({
      question: {
        id: textQuestion.id,
        isRequired: textQuestion.isRequired,
        title: textQuestion.title,
        isDirtyTitle: textQuestion.isDirtyTitle,
        isTitleError: textQuestion.isTitleError,
        titleHelperText: textQuestion.titleHelperText,
        disabledOptionType: textQuestion.disabledOptionType,
        disabledTitle: textQuestion.disabledTitle,
      },
      dispatch,
    });

  const onClickDuplication = () => {
    dispatch({
      type: "DUPLICATION_QUESTION",
      payload: {
        question: {
          type: "TEXT",
          title: textQuestion.title,
          isRequired: textQuestion.isRequired,
          id: v4(),
          disabledTitle: false,
          disabledOptionType: false,
          isWordLimit: false,
          isDirtyTitle: textQuestion.isDirtyTitle,
          isTitleError: textQuestion.isTitleError,
          titleHelperText: textQuestion.titleHelperText,
        },
        fromQuestionId: textQuestion.id,
      },
    });
  };

  const onChangeWordLimit = (limit: string) => {
    dispatch({
      type: "TEXT_QUESTION_UPDATE_WORD_LIMIT",
      payload: {
        questionId: textQuestion.id,
        wordLimit: limit,
      },
    });
  };

  const onClickIsWordLimit = () => {
    dispatch({
      type: "TEXT_QUESTION_UPDATE_IS_WORD_LIMIT",
      payload: {
        questionId: textQuestion.id,
        isWordLimit: !textQuestion.isWordLimit,
      },
    });
  };

  return {
    onChangeQuestionOptionType,
    onChangeTitle,
    onChangeIsRequired,
    deleteQuestion,
    onClickDuplication,
    onChangeWordLimit,
    onClickIsWordLimit,
  };
};

export const TextQuestionForm: FC<Props> = ({
  dispatch,
  textQuestion,
  questionDragHandleProps,
}) => {
  const {
    onChangeQuestionOptionType,
    onChangeTitle,
    onChangeIsRequired,
    deleteQuestion,
    onClickDuplication,
    onChangeWordLimit,
    onClickIsWordLimit,
  } = useTextQuestionForm({ dispatch, textQuestion });

  const { isWordLimit } = textQuestion;

  return (
    <TextQuestionFormUIMemo
      onChangeOptionType={onChangeQuestionOptionType}
      onChangeTitle={onChangeTitle}
      isRequired={textQuestion.isRequired}
      onChangeIsRequired={onChangeIsRequired}
      onClickDelete={deleteQuestion}
      onClickDuplication={onClickDuplication}
      questionDragHandleProps={questionDragHandleProps}
      titleTextField={{
        value: textQuestion.title,
        helperText: textQuestion.titleHelperText,
        error: textQuestion.isTitleError,
      }}
      isWordLimit={isWordLimit}
      onChangeWordLimit={onChangeWordLimit}
      onClickIsWordLimit={onClickIsWordLimit}
    />
  );
};

export const TextQuestionFormMemo = memo<Props>(TextQuestionForm);
