import { Box } from "@material-ui/core";
import { Library, Announcement, AnnouncementDistribution } from "@onn/common";
import { orderBy } from "lodash";
import React, { FC, Fragment, useEffect, useState, useRef, RefObject, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { EventBlock, TaskBlock } from "./components";

import { PortalContent as AnnouncementPortalContent } from "~/components/domains/announcement/PortalContent";
import { NewGraduateWelcomePaper } from "~/components/domains/employees/NewGraduateWelcomePaper";
import { Typography, PortalListItem, Paper, Loading, Icon, Button } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useOnnEventsForPortal } from "~/hooks/onnEvent";
import { useOnnFormTasksAnswers } from "~/hooks/onnFormTaskAnswer";
import { useOnnTasksForPortal } from "~/hooks/onnTask";
import { useScrollToTargetElement } from "~/hooks/shared";
import { useTenant } from "~/hooks/tenant";
import { useTenantSettings } from "~/hooks/tenantSetting/useTenantSettings";

type Props = {
  libraries: Library[];
  isPreview: boolean;
  announcementsData?: {
    announcements: Announcement[];
    announcementDistributions: AnnouncementDistribution[];
  };
};

export const PortalContent: FC<Props> = ({ libraries, isPreview, announcementsData }) => {
  const { tenant } = useTenant();
  const { tenantSettings } = useTenantSettings();
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useCurrentUser();
  const { data: onnEventDataArray, isLoading: isLoadingOnnEvents } = useOnnEventsForPortal({
    employeeId: currentUser.id,
  });
  // NOTE: 回答期限が切れていない未回答のイベントのみを表示する
  const noAnsweredAndNotExpiredEvents = orderBy(
    (onnEventDataArray ?? []).filter((v) => !v.isAnswered && !v.onnEvent.isExpired()),
    "onnEvent.deadlineDate",
    "asc"
  );

  const { data: onnTasks = [], isLoading: isLoadingOnnTasks } = useOnnTasksForPortal({
    employeeId: currentUser.id,
    isPreview,
  });
  const { data: onnFormTasksAnswers = [] } = useOnnFormTasksAnswers({ employeeId: currentUser.id });
  const onnTasksWithAnswer = onnTasks.flatMap((onnTask) => {
    const onnTaskAnswer = onnFormTasksAnswers.find((answer) => answer.formTaskId === onnTask.id);
    if (!onnTaskAnswer) return [];
    return { onnTask, onnTaskAnswer };
  });
  // 回答期限が切れていない未回答のタスク
  const noAnsweredAndCanAnswerOnnTasks = useMemo(() => {
    return orderBy(
      onnTasksWithAnswer.filter((v) => {
        return !v.onnTaskAnswer.isAnswered() && !v.onnTask.isExceededDeadlineDate();
      }),
      "onnTask.deadlineDate",
      "asc"
    );
  }, [onnTasksWithAnswer]);

  const announcementSectionRef = useRef<HTMLDivElement>(null);
  const taskSectionRef = useRef<HTMLDivElement>(null);
  const onnTaskSectionRef = useRef<HTMLDivElement>(null);
  const eventSectionRef = useRef<HTMLDivElement>(null);
  const librarySectionRef = useRef<HTMLDivElement>(null);
  const [scrollTargetRef, setScrollTargetRef] = useState<RefObject<HTMLDivElement>>();

  useScrollToTargetElement({
    enabled: !!scrollTargetRef,
    targetRef: scrollTargetRef,
    scrollYOffset: 0,
  });

  useEffect(() => {
    if (!location.hash) return;
    switch (location.hash) {
      case "#announcement": {
        setScrollTargetRef(announcementSectionRef);
        break;
      }
      case "#task": {
        setScrollTargetRef(taskSectionRef);
        break;
      }
      case "#onnTask": {
        setScrollTargetRef(onnTaskSectionRef);
        break;
      }
      case "#event": {
        setScrollTargetRef(eventSectionRef);
        break;
      }
      case "#library": {
        setScrollTargetRef(librarySectionRef);
        break;
      }
    }
  }, [location.hash]);

  if (isLoadingOnnEvents || isLoadingOnnTasks) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <>
      <Box px={3} py={5}>
        <Paper isShowBgImage border="primary">
          <NewGraduateWelcomePaper tenantName={tenant.tenantName} />
        </Paper>
      </Box>

      <Box px={3} pb={5}>
        {announcementsData && announcementsData.announcements.length > 0 && (
          <Box pt="40px" {...{ ref: announcementSectionRef }}>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography variant="h3" color="textPrimary">
                  お知らせ
                </Typography>
              </Box>
              {announcementsData.announcements.length > 3 && (
                <Button
                  color="default"
                  variant="text"
                  borderRadius="regular"
                  onClick={() => navigate("/portal/announcements")}
                >
                  <Box display="flex" alignItems="center" gridGap="8px">
                    <Typography variant="body2" color="primary" bold>
                      すべて見る
                    </Typography>
                    <Box>
                      <Icon icon="arrowRight" color="primary" size="sm" />
                    </Box>
                  </Box>
                </Button>
              )}
            </Box>
            <Box mt="24px">
              <AnnouncementPortalContent announcementsData={announcementsData} />
            </Box>
          </Box>
        )}

        <Box {...{ ref: eventSectionRef }} pt="40px">
          <EventBlock
            onnEventDataArray={noAnsweredAndNotExpiredEvents.splice(0, 3)}
            isPreview={isPreview}
          />
        </Box>

        <Box {...{ ref: onnTaskSectionRef }} pt="40px">
          <TaskBlock
            onnTasksWithAnswer={noAnsweredAndCanAnswerOnnTasks.splice(0, 3)}
            isPreview={isPreview}
          />
        </Box>

        {tenantSettings.features.library && libraries.length > 0 && (
          <Box pt="40px" {...{ ref: librarySectionRef }}>
            <Typography variant="h3" color="textPrimary">
              Library
            </Typography>
            <Typography variant="caption" color="textPrimary">
              会社の理解を深めていきましょう！
            </Typography>
            {libraries.map((library) => {
              return (
                <Fragment key={library.id}>
                  <Box height="24px" />
                  <PortalListItem
                    to={`/portal/libraries/${library.id}${isPreview ? "?preview=true" : ""}`}
                    title={library.title}
                    emoji={library.label}
                  />
                </Fragment>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};
