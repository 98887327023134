import { z } from "zod";

import { INotificationPart } from "../NotificationPart";
import { SystemMessageSchema } from "../_gen/zodSchema/index";

export const systemMessageSchema = SystemMessageSchema.merge(
  z.object({
    contents: z.array(z.record(z.any()).transform((v) => v as INotificationPart)),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISystemMessage extends z.infer<typeof systemMessageSchema> {}
