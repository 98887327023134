import { Box } from "@material-ui/core";
import { EmployeeToDisplay } from "@onn/common";

import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { TagCheckboxGroup } from "./TagCheckboxGroup";

import {
  Button,
  Divider,
  IconButton,
  Link,
  Loading,
  Modal,
  TextFieldDeprecated,
} from "~/components/uiParts";
import { useAddTagToEmployee } from "~/hooks/employee/useAddTagToEmployee";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useSnackbar, useDebouncedCallback } from "~/hooks/shared";
import { captureException } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  employee: EmployeeToDisplay;
  onAssignTags?: () => void | Promise<void>;
};

export function AssignTagsToEmployeeModal({ open, onCancel, employee, onAssignTags }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { trigger, isMutating } = useAddTagToEmployee({ employeeID: employee.id });
  const { data: employeeTagsData, isLoading } = useEmployeeTags();

  const [searchValue, setSearchValue] = useState("");
  const debouncedSetSearchValue = useDebouncedCallback(setSearchValue, 200);

  const candidateTags = useMemo(() => {
    return (
      employeeTagsData?.employeeTags.filter((tag) => {
        return !employee.employeeTagIds?.includes(tag.id); // 既に付与されているタグは除外
      }) ?? []
    );
  }, [employee.employeeTagIds, employeeTagsData?.employeeTags]);

  const searchFilteredTags = candidateTags.filter((tag) => {
    return tag.name.includes(searchValue);
  });

  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);

  const handleAdd = async () => {
    try {
      await trigger({ newTagIDs: selectedTagIds });
      onAssignTags?.();
      onCancel();
    } catch (e) {
      captureException({
        error: e as Error,
        tags: { type: "AssignTagsToEmployeeModal:handleAdd" },
      });
      enqueueSnackbar("タグの追加に失敗しました", { variant: "error" });
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="タグ追加"
      content={
        <Box display="grid" gridRowGap="24px">
          {/* デザインと微妙に差異があるが、ui component 修正するにも影響が大きいし、important での上書きもしたくないので気にしないこととする... */}
          <StyledTextField
            variant="outlined"
            size="small"
            endAdornment={<IconButton icon="search" color="grey" size="md" />}
            placeholder="タグ名で検索"
            onChange={(e) => {
              debouncedSetSearchValue(e.target.value);
            }}
          />
          <Divider />
          {isLoading ? (
            <Loading size="large" />
          ) : (
            <CheckboxGroupContainer>
              <TagCheckboxGroup
                tags={searchFilteredTags}
                selectedTagIds={selectedTagIds}
                onChange={setSelectedTagIds}
              />
            </CheckboxGroupContainer>
          )}
          <Divider />
        </Box>
      }
      footer={
        <FooterContainer>
          <Link to="/settings/admin#tag" target="_blank">
            <Button variant="text" color="default" borderRadius="regular">
              新規タグ作成
            </Button>
          </Link>
          <ButtonContainer>
            <Button variant="text" borderRadius="regular" color="default" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={handleAdd}
              disabled={isMutating}
            >
              追加
            </Button>
          </ButtonContainer>
        </FooterContainer>
      }
    />
  );
}

const CheckboxGroupContainer = styled("div")`
  height: 320px;
  overflow-y: auto;
  .MuiFormControlLabel-root {
    margin-left: -8px;
  }
`;

const StyledTextField = styled(TextFieldDeprecated)`
  display: block;
  .MuiInputBase-root {
  }
  > div.MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }
`;

const FooterContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;
