import { Chip as MuiChip } from "@material-ui/core";
import React, { FC, ComponentProps } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

type Props = Omit<ComponentProps<typeof MuiChip>, "color"> & {
  numberOfParticipants: number;
  capacity: number;
};

export const Chip: FC<Props> = ({ capacity, numberOfParticipants }) => {
  const canParticipate = numberOfParticipants < capacity;
  return (
    <StyledChip
      label={
        <>
          <Typography variant="overline" display="inline" color="textSecondary">
            {"定員 : "}
          </Typography>
          <Typography variant="caption" display="inline" color="textPrimary">
            {numberOfParticipants}
          </Typography>
          <Typography variant="overline" display="inline" color="textSecondary">
            {` / ${capacity}人`}
          </Typography>
        </>
      }
      $canParticipate={canParticipate}
    ></StyledChip>
  );
};

const StyledChip = styled(MuiChip)<{ $bold?: boolean; $canParticipate: boolean }>`
  &.MuiChip-root {
    font-weight: ${(props) => props.$bold && `bold`};
    font-size: 10px;
    height: 24px;
    padding: 4px;

    background-color: ${(props) =>
      props.$canParticipate ? props.theme.palette.grey[50] : props.theme.palette.primary.light};
  }
`;
