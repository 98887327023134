import { OnWebsiteNotice } from "@onn/common";
import {
  CollectionReference,
  DocumentData,
  collection,
  onSnapshot,
  query,
  where,
  Timestamp,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IOnWebsiteNoticeRepository } from "~/service/repository/IOnWebsiteNoticeRepository";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

const COLLECTION_NAME = "onWebsiteNotices";

type OnWebsiteNoticeForDB = Omit<ExcludeMethods<OnWebsiteNotice>, "createdAt"> & {
  createdAt: Timestamp;
};

export class OnWebsiteNoticeRepository implements IOnWebsiteNoticeRepository {
  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  dbToObject({ createdAt, ...rest }: OnWebsiteNoticeForDB): OnWebsiteNotice {
    return new OnWebsiteNotice({
      ...rest,
      createdAt: convertTimestampToDate(createdAt),
    });
  }

  listenNewNotice({
    tenantId,
    employeeId,
    onWebsiteNoticeCreated,
  }: {
    tenantId: string;
    employeeId: string;
    onWebsiteNoticeCreated: (onWebsiteNotice: OnWebsiteNotice) => void;
  }) {
    const q = query(
      this.collection(),
      where("tenantId", "==", tenantId),
      where("targetEmployeeId", "==", employeeId)
    );

    // NOTE: 初回のsnapshotにはすでに存在するデータが取得されるため、初回の取得時は無視する
    let isInitialLoad = true;
    return onSnapshot(q, (snapshot) => {
      if (isInitialLoad) {
        isInitialLoad = false;
        return;
      }
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          onWebsiteNoticeCreated(this.dbToObject(change.doc.data() as OnWebsiteNoticeForDB));
        }
      });
    });
  }
}
