import { v4 } from "uuid";

import { Announcement as IAnnouncement } from "../../_gen/zodSchema/index";

import { announcementSchema } from "./schema";

export type AnnouncementSettingCompleted = Announcement & {
  publicationStartDate: Date;
  publicationEndDate: Date;
};

export class Announcement implements IAnnouncement {
  id: string;
  creatorId: string;
  title: string;
  content: string;
  filePaths: string[] = [];
  createdAt: Date;
  updatedAt: Date;
  tenantId: string;
  spaceId: string;
  publicationStartDate?: Date;
  publicationEndDate?: Date;
  firstPublishedAt?: Date;

  static validator = announcementSchema;

  /**
   * https://github.com/microsoft/TypeScript/issues/30991 によって private にできない
   * @param init
   */
  constructor(init: {
    id?: string;
    creatorId: string;
    title: string;
    content: string;
    filePaths: string[];
    tenantId: string;
    spaceId: string;
    createdAt?: Date;
    updatedAt?: Date;
    publicationStartDate?: Date;
    publicationEndDate?: Date;
    firstPublishedAt?: Date;
  }) {
    this.id = init.id ?? v4();
    this.creatorId = init.creatorId;
    this.title = init.title;
    this.content = init.content;
    this.filePaths = init.filePaths;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.createdAt = init.createdAt ?? new Date();
    this.updatedAt = init.updatedAt ?? new Date();
    this.publicationStartDate = init.publicationStartDate;
    this.publicationEndDate = init.publicationEndDate;
    this.firstPublishedAt = init.firstPublishedAt;
  }

  static create(...[init]: ConstructorParameters<typeof Announcement>): Announcement {
    const parsedInit = Announcement.validator.parse({
      ...init,
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    return new Announcement(parsedInit);
  }

  /**
   * 更新処理の最後に呼ぶ
   * @returns {Announcement}
   */
  private update(): Announcement {
    this.updatedAt = new Date();

    return new Announcement({ ...this });
  }

  edit(update: { title?: string; content?: string; filePaths?: string[] }) {
    if (update.title) {
      this.title = update.title;
    }

    if (update.content) {
      this.content = update.content;
    }

    if (update.filePaths) {
      this.filePaths = update.filePaths;
    }

    return this.update();
  }

  resetPublicationDates() {
    this.publicationStartDate = undefined;
    this.publicationEndDate = undefined;

    return this.update();
  }

  setPublicationDates(publicationStartDate: Date, publicationEndDate: Date) {
    this.publicationStartDate = publicationStartDate;
    this.publicationEndDate = publicationEndDate;

    return this.update();
  }

  isUnderPublication() {
    if (!this.isSettingCompleted()) {
      return false;
    }

    const now = new Date().getTime();

    return this.publicationStartDate.getTime() <= now && now <= this.publicationEndDate.getTime();
  }

  isFinishedPublication() {
    if (!this.isSettingCompleted()) {
      return false;
    }

    const now = new Date().getTime();

    return this.publicationEndDate.getTime() < now;
  }

  isWaitingPublication() {
    if (!this.isSettingCompleted()) {
      return false;
    }

    const now = new Date().getTime();

    return now < this.publicationStartDate.getTime();
  }

  isSettingCompleted(): this is AnnouncementSettingCompleted {
    return !!this.publicationStartDate && !!this.publicationEndDate;
  }

  shouldFirstPublish(): this is AnnouncementSettingCompleted {
    if (!this.isSettingCompleted()) {
      return false;
    }

    if (this.isAlreadyPublished()) {
      return false;
    }

    return this.isUnderPublication();
  }

  setFirstPublishedAt() {
    this.firstPublishedAt = new Date();
  }

  isAlreadyPublished(): this is AnnouncementSettingCompleted & { firstPublishedAt: Date } {
    return !!this.firstPublishedAt;
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 IAnnouncement にしている
   */
  static forceConvertToDate(announcement: ExcludeMethods<Announcement>) {
    return new Announcement({
      ...announcement,
      createdAt: announcement.createdAt ? new Date(announcement.createdAt) : undefined,
      updatedAt: announcement.updatedAt ? new Date(announcement.updatedAt) : undefined,
      publicationStartDate: announcement.publicationStartDate
        ? new Date(announcement.publicationStartDate)
        : undefined,
      publicationEndDate: announcement.publicationEndDate
        ? new Date(announcement.publicationEndDate)
        : undefined,
    });
  }
}
