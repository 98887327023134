import { Box } from "@material-ui/core";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";

import { NewGraduateRecruitmentStatusAndPrediction } from "~/components/domains/employees/NewGraduateTable/TableRowItems/NewGraduateRecruitmentStatusAndPrediction";

import { Typography } from "~/components/uiParts";

type Props = {
  newGraduate: NewGraduateToDisplay;
  disabled?: boolean;
};

export const NewGraduateRecruitmentStatuses: FC<Props> = ({ newGraduate, disabled }) => {
  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px" position="relative">
      <Box display="flex" gridGap="4px" alignItems="center">
        <Typography variant="body2" bold color="textPrimary">
          シナリオ・選考ステータス
        </Typography>
      </Box>

      <NewGraduateRecruitmentStatusAndPrediction newGraduate={newGraduate} disabled={disabled} />
    </Box>
  );
};
