import { EmployeeInformationDateTypeValue } from "./EmployeeInformationDateTypeValue";
import { EmployeeInformationMultipleSelectTypeValue } from "./EmployeeInformationMultipleSelectTypeValue";
import { EmployeeInformationSingleSelectTypeValue } from "./EmployeeInformationSingleSelectTypeValue";
import { EmployeeInformationTextTypeValue } from "./EmployeeInformationTextTypeValue";

export { EmployeeInformationDateTypeValue } from "./EmployeeInformationDateTypeValue";
export { EmployeeInformationMultipleSelectTypeValue } from "./EmployeeInformationMultipleSelectTypeValue";
export { EmployeeInformationSingleSelectTypeValue } from "./EmployeeInformationSingleSelectTypeValue";
export { EmployeeInformationTextTypeValue } from "./EmployeeInformationTextTypeValue";

export type AnyEmployeeInformationValue =
  | EmployeeInformationDateTypeValue
  | EmployeeInformationMultipleSelectTypeValue
  | EmployeeInformationSingleSelectTypeValue
  | EmployeeInformationTextTypeValue;

export type AnyEmployeeInformationValueExcludeMethods =
  | ExcludeMethods<EmployeeInformationDateTypeValue>
  | ExcludeMethods<EmployeeInformationMultipleSelectTypeValue>
  | ExcludeMethods<EmployeeInformationSingleSelectTypeValue>
  | ExcludeMethods<EmployeeInformationTextTypeValue>;

export const instantiateFromAnyEmployeeInformationValueExcludeMethods = (
  value: AnyEmployeeInformationValueExcludeMethods
): AnyEmployeeInformationValue => {
  switch (value.type) {
    case EmployeeInformationDateTypeValue.type:
      return new EmployeeInformationDateTypeValue(value);
    case EmployeeInformationMultipleSelectTypeValue.type:
      return new EmployeeInformationMultipleSelectTypeValue(value);
    case EmployeeInformationSingleSelectTypeValue.type:
      return new EmployeeInformationSingleSelectTypeValue(value);
    case EmployeeInformationTextTypeValue.type:
      return new EmployeeInformationTextTypeValue(value);
    default: {
      const _exhaustiveCheck: never = value;
      return _exhaustiveCheck;
    }
  }
};

export const generateFieldIdToTextTypeValueMapFromAnyValue = (
  anyEmployeeInformationValues: AnyEmployeeInformationValue[]
) => {
  const fieldIdToExistingTextTypeValueMap = new Map<string, EmployeeInformationTextTypeValue>(
    anyEmployeeInformationValues
      .filter((v): v is EmployeeInformationTextTypeValue => v.type === "TEXT")
      .map((v) => [v.employeeInformationTextTypeFieldId, v])
  );
  return fieldIdToExistingTextTypeValueMap;
};

export const generateFieldIdToSingleSelectTypeValueMapFromAnyValue = (
  anyEmployeeInformationValues: AnyEmployeeInformationValue[]
) => {
  const fieldIdToExistingSingleSelectTypeValueMap = new Map<
    string,
    EmployeeInformationSingleSelectTypeValue
  >(
    anyEmployeeInformationValues
      .filter((v): v is EmployeeInformationSingleSelectTypeValue => v.type === "SINGLE_SELECT")
      .map((v) => [v.employeeInformationSingleSelectTypeFieldId, v])
  );
  return fieldIdToExistingSingleSelectTypeValueMap;
};

// NOTE: 「複数選択」の場合は、fieldに複数のvalueが紐づくことに注意する
export const generateFieldIdToMultipleSelectTypeValuesMapFromAnyValue = (
  anyEmployeeInformationValues: AnyEmployeeInformationValue[]
) => {
  const fieldIdToExistingMultipleSelectTypeValuesMap = anyEmployeeInformationValues.reduce(
    (map, value) => {
      if (value.type !== "MULTIPLE_SELECT") {
        return map;
      }
      const existingValues = map.get(value.employeeInformationMultipleSelectTypeFieldId) ?? [];
      return map.set(value.employeeInformationMultipleSelectTypeFieldId, [
        ...existingValues,
        value,
      ]);
    },
    new Map<string, EmployeeInformationMultipleSelectTypeValue[]>()
  );

  return fieldIdToExistingMultipleSelectTypeValuesMap;
};

export const generateFieldIdToDateTypeValueMapFromAnyValue = (
  anyEmployeeInformationValues: AnyEmployeeInformationValue[]
) => {
  const fieldIdToExistingDateTypeValueMap = new Map<string, EmployeeInformationDateTypeValue>(
    anyEmployeeInformationValues
      .filter((v): v is EmployeeInformationDateTypeValue => v.type === "DATE")
      .map((v) => [v.employeeInformationDateTypeFieldId, v])
  );
  return fieldIdToExistingDateTypeValueMap;
};
