import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent/OnnEvent/schema";

import { supportedJoinProperties } from "./protocol";

export const listOnnEventAnswersRequestSchema = z.object({
  onnEventId: onnEventSchema.shape.id,
  /**
   * e.g. `["employeeId"]`
   * 2023.03.29現在は"employeeId"のみサポート
   */
  join: z.array(z.enum(supportedJoinProperties)).optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IListOnnEventAnswersRequest
  extends z.infer<typeof listOnnEventAnswersRequestSchema> {}
