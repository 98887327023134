import { EMPLOYEE_ACTION_TYPE } from "@onn/common";
import { useEffect } from "react";

import { useCurrentUser } from "../employee";
import { useCreateEmployeeActiveLog } from "../employeeActiveLog";

export const useEffectForCreationActiveLog = ({
  type,
  targetId,
}: {
  type: EMPLOYEE_ACTION_TYPE;
  targetId: string;
}): void => {
  const { currentUser } = useCurrentUser();
  const { createEmployeeLog } = useCreateEmployeeActiveLog();

  useEffect(() => {
    createEmployeeLog(type, targetId, currentUser.tenantId);
    // NOTE: アクセスしたタイミングのみ記録するため、eslint-disable-next-lineを記述
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
