import { Employee, Role } from "@onn/common";

import { functionOperator } from "~/infrastructure/api/functionOperator";
import { TenantSettingsRepository } from "~/infrastructure/api/tenantSettingsRepository";

const tenantSettingsRepository = new TenantSettingsRepository();

export class UpdateDefaultFollowerIdsUseCase {
  async execute(tenantId: string, newFollowerIds: string[]): Promise<void> {
    await tenantSettingsRepository.update(tenantId, {
      contact: {
        defaultFollowerEmployeeIds: [...newFollowerIds],
      },
    });
  }

  // onnに存在しないユーザーをバイネームで指定した時に利用する関数
  private async createAccount(emailsWithoutOnnAccount: string[]) {
    return await functionOperator
      .httpsCallFor2ndGen<unknown, { createdEmployees: Employee[] }>("accountcreate", {
        userDataArray: emailsWithoutOnnAccount.map((email) => {
          return {
            email,
            role: Role.MEMBER,
          };
        }),
      })
      .then((res) => {
        return res.data.createdEmployees;
      });
  }
}
