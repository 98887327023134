import { APISchema, ContactMessageTemplate } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

/**
 * コンタクトメッセージテンプレート一覧を取得するhooks
 * - 作成日時昇順
 */
export const useContactMessageTemplates = (
  tenantId: string
): SWRResponse<ContactMessageTemplate[], Error> & { refetchKey: Key } => {
  const queryParams: Query = {
    sort: [{ key: "created-at", direction: "asc" }],
  };
  const keyObj = { name: `contact-message-template-${tenantId}`, ...queryParams };

  const asyncJob = async (): Promise<ContactMessageTemplate[]> => {
    const response = await apiClient.get(path, queryParams);
    return response.data.map((d) => new ContactMessageTemplate(d));
  };

  return { ...useSWR(keyObj, asyncJob), refetchKey: keyObj };
};

const path = "/api/contact-message-templates";
type Query = APISchema[typeof path]["GET"]["query"];
export type Key = Query & { name: string };
