import { OnnFormTaskAnswer, Employee, NewGraduateToDisplay } from "@onn/common";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import { AnswerFilterStatus } from "../../../components/AnswerResultTab/filter/AnswerStatusSelectMenu";

import { useFilterObjectByPartialMatch } from "~/hooks/shared";

export type Filter = {
  answerStatus: AnswerFilterStatus[];
  emailAndName: string;
};

export const useFilter = ({
  onnFormTaskAnswers,
  targetNewGraduates,
}: {
  onnFormTaskAnswers: OnnFormTaskAnswer[];
  targetNewGraduates: NewGraduateToDisplay[];
}) => {
  const [filter, setFilter] = useState<Filter>({
    answerStatus: [],
    emailAndName: "",
  });

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const filteredEmployees = useMemo(() => {
    return filter.emailAndName
      ? filterObjectByPartialMatch({
          objects: targetNewGraduates,
          searchText: filter.emailAndName,
          getProperties: [(e: Employee) => e.getName(), (e: Employee) => e.email],
        })
      : targetNewGraduates;
  }, [filter.emailAndName, filterObjectByPartialMatch, targetNewGraduates]);

  const filteredOnnFormTaskAnswers = useMemo(
    () =>
      onnFormTaskAnswers.filter((onnFormTaskAnswer) => {
        if (isEmpty(filter.answerStatus)) return true;
        if (filter.answerStatus.includes("answered") && onnFormTaskAnswer.isAnswered()) return true;
        if (
          filter.answerStatus.includes("isRead") &&
          onnFormTaskAnswer.lastReadAt &&
          !onnFormTaskAnswer.isAnswered()
        )
          return true;
        if (filter.answerStatus.includes("unRead") && !onnFormTaskAnswer.lastReadAt) return true;
        return false;
      }),
    [filter.answerStatus, onnFormTaskAnswers]
  );

  return { filter, setFilter, filteredEmployees, filteredOnnFormTaskAnswers };
};
