import { Box, Stack } from "@mui/material";
import { postScenariosBlockSchema } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { z } from "zod";

import { IconButton, TooltipWhenTextTruncated, Typography } from "~/components/uiParts";
import { TriggerIcon } from "~/pages/scenario/Main/components/TriggerBlock/TriggerDescriptionArea/TriggerIcon";
import { useDescriptionForEditMode } from "~/pages/scenario/Main/editMode/useDescriptionForEditMode";

type InputState = z.infer<typeof postScenariosBlockSchema>;

type Props = {
  triggerSetting: InputState["triggerSetting"];
  onClickEditButton: () => void;
  editButtonRef: React.RefObject<HTMLButtonElement>;
};
export const TriggerDescriptionArea: FC<Props> = ({
  triggerSetting,
  onClickEditButton,
  editButtonRef,
}) => {
  const { description, triggerSubDescription } = useDescriptionForEditMode({ triggerSetting });

  return (
    <Root direction="row" columnGap="8px" alignItems="center">
      <TriggerIconWrapper flexShrink={0}>
        <TriggerIcon type={triggerSetting.type} />
      </TriggerIconWrapper>
      {/* 説明文が長い時に、アイコンの横幅を保つためにflexアイテムに横幅指定 */}
      <Box>
        {triggerSubDescription && (
          <Box width="100%">
            <TooltipWhenTextTruncated text={triggerSubDescription}>
              {(ref) => (
                <Typography
                  bold
                  variant="caption"
                  color={"primary"}
                  component={"div"}
                  noWrap
                  ref={ref}
                >
                  {triggerSubDescription}
                </Typography>
              )}
            </TooltipWhenTextTruncated>
          </Box>
        )}
        <Box width="100%">
          <TooltipWhenTextTruncated text={description}>
            {(ref) => (
              <Typography bold color={"textPrimary"} variant="body2" noWrap ref={ref}>
                {description}
              </Typography>
            )}
          </TooltipWhenTextTruncated>
        </Box>
      </Box>
      <Box flexShrink={0} ml="auto">
        <IconButton size="md" icon="pencil" onClick={onClickEditButton} buttonRef={editButtonRef} />
      </Box>
    </Root>
  );
};

const Root = styled(Stack)`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.palette.primary.main};
`;

const TriggerIconWrapper = styled(Box)`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
