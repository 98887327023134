import { z } from "zod";

export const ActionConditionTypeSchema = z.enum([
  "EmployeeTagActionCondition",
  "OnnFormTaskResultActionCondition",
  "OnnEventEvaluationActionCondition",
]);

export type ActionConditionTypeType = `${z.infer<typeof ActionConditionTypeSchema>}`;

export default ActionConditionTypeSchema;
