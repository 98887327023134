import { Box, Menu } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useMemo, FC } from "react";
import styled from "styled-components";

import { EmployeeTagSelectMenu } from "../EmployeeTagSelectMenu";

import { Button, Icon, IconButton, Loading, Typography } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  defaultLabel?: string;
  employeeTags: EmployeeTag[];
  selectedTagIds: string[];
  isLoading?: boolean;
  onChange: (selectedTagIds: string[]) => void;
};

// ====================
// main
// ====================

export const EmployeeTagFilter: FC<Props> = ({
  defaultLabel = "タグ",
  employeeTags,
  selectedTagIds,
  isLoading,
  onChange,
}) => {
  // ====================
  // state
  // ====================

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // ====================
  // variable
  // ====================

  const labelText = useMemo(() => {
    if (isEmpty(selectedTagIds) || selectedTagIds.length === employeeTags.length) {
      return defaultLabel;
    }
    return `${selectedTagIds.length}個のタグ`;
  }, [selectedTagIds, employeeTags.length, defaultLabel]);

  // ====================
  // component
  // ====================

  return (
    <>
      <Box position="relative">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" noWrap>
              {labelText}
            </Typography>
            <StyledIcon icon="filter" size="sm" color="grey" $isEmpty={isEmpty(selectedTagIds)} />
          </Box>
        </Button>
        {/* Buttonのhoverも効いてしまうので内包しないようにする */}
        {!isEmpty(selectedTagIds) && (
          <StyledIconButton icon="close" size="sm" color="grey" onClick={() => onChange([])} />
        )}
      </Box>

      <Menu
        key="statusFilter"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: -8, horizontal: "right" }}
      >
        {isLoading ? (
          <Loading size="large" />
        ) : isEmpty(employeeTags) ? (
          <Box py="16px" width="240px">
            <Box px="24px">
              <Typography variant="caption" color="textSecondary">
                該当するタグは存在しません
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <EmployeeTagSelectMenu
              employeeTags={employeeTags}
              selectedTagIds={selectedTagIds}
              onChange={(selectedTagIds) => onChange(selectedTagIds)}
            />
            <Box mb="8px" px="24px">
              <StyledResetTypography variant="body2" color="primary" onClick={() => onChange([])}>
                全てクリア
              </StyledResetTypography>
            </Box>
          </>
        )}
      </Menu>
    </>
  );
};

// ====================
// style
// ====================

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;

const StyledResetTypography = styled(Typography)`
  cursor: pointer;
`;
