import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { useFieldArray, useForm as useRHF } from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";

import { useEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";

const inputSchema = z
  .object({
    groups: z.array(
      z.object({
        id: z.string(), // 新規追加の場合でもidを発行するが発行されたidはサーバー側で破棄されてidは再生成される
        label: z.string().min(1, "グループ名を入力してください"),
      })
    ),
  })
  .superRefine((value, ctx) => {
    const duplicatedLabels = value.groups
      .filter((group, index, groups) => {
        const isDuplicated = groups.findIndex((g) => g.label === group.label) !== index;
        return isDuplicated;
      })
      .map((group) => group.label);
    const duplicatedIndexes = value.groups.reduce((acc, group, index) => {
      if (duplicatedLabels.includes(group.label)) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);
    duplicatedIndexes.forEach((duplicatedIndex) => {
      ctx.addIssue({
        path: [`groups.${duplicatedIndex}.label`],
        code: z.ZodIssueCode.custom,
        message: "グループ名が重複しています",
      });
    });
  });

export type InputState = z.infer<typeof inputSchema>;

export const useForm = ({
  defaultValues,
  onSubmit,
  closeModal,
}: {
  defaultValues: InputState;
  onSubmit: (inputValue: InputState) => void;
  closeModal: () => void;
}) => {
  const form = useRHF<InputState>({
    defaultValues,
    resolver: zodResolver(inputSchema),
    mode: "all",
  });

  const { append, move, remove } = useFieldArray({
    control: form.control,
    name: "groups",
  });

  const addGroup = useCallback(() => {
    append({ id: v4(), label: "" });
  }, [append]);

  const removeGroup = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const changeGroupOrder = useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      move(sourceIndex, destinationIndex);
    },
    [move]
  );

  const { submit } = useSubmit({
    onSubmit,
    closeModal,
  });
  const handleSubmit = form.handleSubmit(submit, () => {
    form.trigger();
  });

  const isError = Object.keys(form.formState.errors).length > 0;

  return {
    form: {
      watch: form.watch,
      control: form.control,
    },
    addGroup,
    changeGroupOrder,
    removeGroup,
    handleSubmit,
    isError,
  };
};

const useSubmit = ({
  onSubmit,
  closeModal,
}: {
  onSubmit: (inputState: InputState) => void;
  closeModal: () => void;
}) => {
  const { data: exitingGroups } = useEmployeeInformationGroups();
  const submit = useCallback(
    async (inputValue: InputState) => {
      onSubmit(inputValue);
      const existingGroupIds =
        exitingGroups?.map((group) => group.employeeInformationGroup.id) || [];
      const inputGroupIds = inputValue.groups.map((group) => group.id);
      const deletedGroupIds = existingGroupIds.filter(
        (existingGroupId) => !inputGroupIds.includes(existingGroupId)
      );
      // NOTE: グループが削除されるときは、確認モーダルを表示するためモーダルを閉じない
      if (deletedGroupIds.length === 0) {
        closeModal();
      }
    },
    [closeModal, exitingGroups, onSubmit]
  );

  return { submit };
};
