import { z } from "zod";

import { SpaceSettingSchema } from "../_gen/zodSchema/index";

export const spaceSettingSchema = SpaceSettingSchema.merge(
  z.object({
    registrationMessageForPreEntry: z.string().optional(),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISpaceSetting extends z.infer<typeof spaceSettingSchema> {}
