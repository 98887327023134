import { Box } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";

import { useContextOfOnnEventSlotSearchModal } from "../../../Context";
import { BriefingSessionCategoryConditionSchema, InputCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import {
  Choice,
  SelectMultipleConditionDropdown,
} from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

type Props = {
  index: number;
  condition: InputCondition<BriefingSessionCategoryConditionSchema>;
};

const TARGET = "briefingSessionCategoryIds";

export const BriefingSessionCategoriesConditionSelector = ({
  index,
  condition,
}: Props): JSX.Element => {
  const {
    onChangeTarget,
    briefingSessionCategories,
    onChangeCondition,
    isLoadingFetchCategories,
    onnEvent,
  } = useContextOfOnnEventSlotSearchModal();
  const selectedCategoryIds = useMemo(() => condition.categoryIds ?? [], [condition.categoryIds]);
  const selectedCategories = useMemo(
    () => briefingSessionCategories.filter((category) => selectedCategoryIds.includes(category.id)),
    [briefingSessionCategories, selectedCategoryIds]
  );

  const selectedChoices = useMemo(
    () =>
      selectedCategories.map((category) => ({
        value: category.id,
        label: category.title,
      })),
    [selectedCategories]
  );

  const onChange = useCallback(
    (newSelectedChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        target: TARGET,
        categoryIds: newSelectedChoices.map((choice) => choice.value),
      });
    },
    [index, onChangeCondition]
  );

  const choices = useMemo(() => {
    return briefingSessionCategories.map((category) => ({
      value: category.id,
      label: category.title,
    }));
  }, [briefingSessionCategories]);

  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          onnEventType={onnEvent.type}
          target={TARGET}
          onChange={(target) => onChangeTarget(index, target)}
        />
      </Box>
      <Box
        flex={9}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gridGap: "16px",
        }}
      >
        <SelectMultipleConditionDropdown
          placeHolder="説明会を選択"
          key={"event"}
          selectedChoices={selectedChoices}
          choices={choices}
          onChange={onChange}
          isLoading={isLoadingFetchCategories}
        />
      </Box>
    </Box>
  );
};
