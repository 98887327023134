import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT DELIVERY CANCELLATION LOG SCHEMA
/////////////////////////////////////////

export const OnnEventDeliveryCancellationLogSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  onnEventId: z.string(),
  employeeId: z.string(),
  createdAt: z.date(),
});

export type OnnEventDeliveryCancellationLog = z.infer<typeof OnnEventDeliveryCancellationLogSchema>;

/////////////////////////////////////////
// ONN EVENT DELIVERY CANCELLATION LOG OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventDeliveryCancellationLogOptionalDefaultsSchema =
  OnnEventDeliveryCancellationLogSchema.merge(
    z.object({
      createdAt: z.date().optional(),
    })
  );

export type OnnEventDeliveryCancellationLogOptionalDefaults = z.infer<
  typeof OnnEventDeliveryCancellationLogOptionalDefaultsSchema
>;

export default OnnEventDeliveryCancellationLogSchema;
