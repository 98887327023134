import { useCallback } from "react";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { generateNewGraduateHost } from "~/libs";

/**
 * 招待QR・URLのURLを生成する
 */
export const useGenerateRegistrationInvitationLinkUrl = () => {
  const { currentSpace } = useCurrentSpace();

  const generateRegistrationInvitationLinkUrl = useCallback(
    ({ registrationInvitationLinkId }: { registrationInvitationLinkId: string }) => {
      const baseUrl = generateNewGraduateHost(currentSpace.tenantId);
      const url = new URL("portal/accounts/registration", baseUrl);
      url.searchParams.set("space-id", currentSpace.id);
      url.searchParams.set("registration-invitation-link-id", registrationInvitationLinkId);

      return url.toString();
    },
    [currentSpace.id, currentSpace.tenantId]
  );

  return { generateRegistrationInvitationLinkUrl };
};
