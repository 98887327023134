import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useModal } from "~/hooks/modal";
import { useDeleteOnnEvent } from "~/hooks/onnEvent";

// 「削除」ボタンのクリックハンドラ
export const useOnClickDeleteButton = ({
  onnEventTitle,
  onnEventId,
}: {
  onnEventTitle: string;
  onnEventId: string;
}) => {
  const navigation = useNavigate();
  const { handleModal } = useModal();
  const { execDeleteOnnEvent } = useDeleteOnnEvent();

  const onClickDeleteButton = useCallback(() => {
    handleModal({
      name: "eventDeleteConfirmModal",
      args: {
        eventTitle: onnEventTitle,
        onSubmit: async () => {
          await execDeleteOnnEvent(onnEventId);
          navigation(`/events?from_page=detail`);
        },
      },
    });
  }, [handleModal, onnEventTitle, execDeleteOnnEvent, onnEventId, navigation]);

  return { onClickDeleteButton };
};
