import React, { FC, useCallback } from "react";

import styled from "styled-components";

import { mutate } from "swr";

import {
  CellItem,
  Chip,
  Icon,
  StyleProps,
  Typography,
  UncontrolledMenu,
} from "~/components/uiParts";
import { colors } from "~/config/theme";
import { useCurrentUser } from "~/hooks/employee";
import { generateOnnEventSlotDatesForDisplayKey } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";
import { useUpdateOnnEventSlotDates } from "~/hooks/onnEventSlotDates/useUpdateOnnEventSlotDates";

// 「非公開」ステータスのセル
export const DraftCell: FC<StyleProps & { onnEventId: string; slotId: string }> = ({
  style,
  onnEventId,
  slotId,
  ...styleProps
}) => {
  const { currentUser } = useCurrentUser();

  const isAdmin = currentUser?.isAdmin() || false;

  const { updateOnnEventSlotDates } = useUpdateOnnEventSlotDates();

  const updateOnnEventSlotStatus = useCallback(
    async (status: "draft" | "published" | "closed") => {
      await updateOnnEventSlotDates({
        onnEventId,
        onnEventSlotId: slotId,
        paramsToUpdate: { status, type: "briefing_session" },
      });
      await mutate(generateOnnEventSlotDatesForDisplayKey(onnEventId));
    },
    [onnEventId, slotId, updateOnnEventSlotDates]
  );

  return (
    <CellItem {...styleProps} style={{ ...style, backgroundColor: colors.grey[50] }}>
      <UncontrolledMenu
        renderButton={(openMenu) => (
          <StyledChip
            $bgColor={colors.grey[50]}
            label={
              <Typography variant="body2" bold color="textSecondary">
                {"非公開"}
              </Typography>
            }
            color="muted"
            isOutlined
            icon={isAdmin ? <Icon icon="chevronDown" color="grey" size="md" /> : undefined}
            onClick={openMenu}
            size="medium"
          />
        )}
        menuItemOptions={[
          {
            onClick: () => updateOnnEventSlotStatus("published"),
            text: "公開",
          },
        ]}
      ></UncontrolledMenu>
    </CellItem>
  );
};

const StyledChip = styled(Chip)<{ $bgColor: string }>`
  &.MuiChip-root {
    height: 32px;
    width: 90px;
    font-size: 14px;
    background-color: ${({ $bgColor }) => $bgColor};
    border: none;
  }
  > .MuiChip-icon {
    order: 1; // アイコンと文字列の表示順を入れ替える
    margin-left: -12px;
  }
  .MuiChip-label {
    color: ${({ theme }) => theme.palette.text.primary};
    padding-left: 8px;
  }
  background-color: ${({ $bgColor }) => $bgColor};
  :hover,
  :focus {
    background-color: ${({ $bgColor }) => $bgColor};
  }
`;
