import { ContactMessageTemplate } from "../ContactMessageTemplate/ContactMessageTemplate";

import { ContactMessageTemplateFolder } from "./ContactMessageTemplateFolder";

export class ContactMessageTemplateFolderWithTemplates extends ContactMessageTemplateFolder {
  readonly templates: ContactMessageTemplate[];

  constructor(input: ExcludeMethods<ContactMessageTemplateFolderWithTemplates>) {
    super(input);
    this.templates = input.templates || [];
  }
}
