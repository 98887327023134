import { TextQuestion } from "@onn/common";
import React, { FC, memo } from "react";

import { Action } from "../../reducer";

import { AnswerBaseBox } from "./AnswerBaseBox";
import { StyledTextarea } from "./StyledTextarea";

type Props = {
  question: TextQuestion;
  dispatch: React.Dispatch<Action>;
  answer: { text: string };
};

export const TextQuestionForm: FC<Props> = ({ question, dispatch, answer }) => {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({
      type: "CHANGE_TEXT_ANSWER",
      payload: {
        questionId: question.id,
        text: e.target.value,
      },
    });
  };
  const isInvalidTextLength =
    question.type === "TEXT" &&
    typeof question.wordLimit !== "undefined" &&
    answer.text.length > question.wordLimit;
  const errorMessage = isInvalidTextLength
    ? `${question.wordLimit}文字以内で入力してください`
    : undefined;

  return (
    <TextQuestionFormUI
      questionTitle={question.title}
      value={answer.text}
      onChange={onChange}
      isRequired={question.isRequired}
      maxTextCount={question.isWordLimit ? question.wordLimit : undefined}
      errorMessage={errorMessage}
    />
  );
};

const TextQuestionFormUI = memo<{
  questionTitle: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  value: string;
  isRequired?: boolean;
  maxTextCount?: number;
  errorMessage?: string;
}>(({ questionTitle, onChange, value, isRequired, maxTextCount, errorMessage }) => (
  <AnswerBaseBox questionTitle={questionTitle} isRequired={isRequired}>
    <StyledTextarea
      fullWidth
      placeholder="回答を入力"
      minRows={3}
      onChange={onChange}
      value={value}
      isDisplayCount
      maxTextCount={maxTextCount}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
    />
  </AnswerBaseBox>
));
