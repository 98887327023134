import React, { FC } from "react";

import { AssigneeCell } from "./cells/AssigneeCell";
import { CheckBoxCellMemo } from "./cells/CheckBoxCell";
import { DescriptionCell } from "./cells/DescriptionCell";
import { ReservedCell } from "./cells/ReservedCell";
import { SlotTypeCell } from "./cells/SlotTypeCell";
import { StatusCell } from "./cells/StatusCell/StatusCell";
import { ThreeDotCell } from "./cells/ThreeDotCell";
import { BodyRowData, ColumnType } from "./const";

import { CellItem, StyleProps, Typography } from "~/components/uiParts";

export const BodyCell: FC<
  {
    bodyRowData: BodyRowData;
    columnType: ColumnType;
    isShowRightBorder: boolean;
  } & StyleProps
> = ({ bodyRowData, columnType, ...styleProps }) => {
  const result = (): JSX.Element => {
    switch (columnType) {
      case "checkBox":
        return <CheckBoxCellMemo {...styleProps} rowData={bodyRowData[columnType]} />;
      case "menu":
        return <ThreeDotCell rowData={bodyRowData[columnType]} {...styleProps} isShowTopBorder />;
      case "slotDate":
        return (
          <CellItem {...styleProps} isShowTopBorder>
            <Typography variant="body2">{bodyRowData[columnType]}</Typography>
          </CellItem>
        );
      case "slotTime":
        return (
          <CellItem {...styleProps} isShowTopBorder>
            <Typography variant="body2">{bodyRowData[columnType]}</Typography>
          </CellItem>
        );
      case "capacity":
        return (
          <CellItem {...styleProps} isShowTopBorder>
            <Typography variant="body2">{bodyRowData[columnType]}</Typography>
          </CellItem>
        );
      case "reserved":
        return <ReservedCell cellData={bodyRowData[columnType]} {...styleProps} />;
      case "status":
        return <StatusCell rowData={bodyRowData[columnType]} {...styleProps} isShowTopBorder />;
      case "assignee":
        return (
          <AssigneeCell
            assigneeName={bodyRowData[columnType].assigneeName}
            profileIconImageUrl={bodyRowData[columnType].profileIconImageUrl}
            {...styleProps}
            isShowTopBorder
          />
        );
      case "slotType":
        return <SlotTypeCell rowData={bodyRowData[columnType]} {...styleProps} />;
      case "description":
        return <DescriptionCell description={bodyRowData[columnType]} {...styleProps} />;
      default:
        return (
          <CellItem {...styleProps} isShowTopBorder>
            <Typography variant="body2">-</Typography>
          </CellItem>
        );
    }
  };

  return result();
};
