import { z } from "zod";

/////////////////////////////////////////
// SCENARIO SCHEMA
/////////////////////////////////////////

export const ScenarioSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  name: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Scenario = z.infer<typeof ScenarioSchema>;

/////////////////////////////////////////
// SCENARIO OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ScenarioOptionalDefaultsSchema = ScenarioSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type ScenarioOptionalDefaults = z.infer<typeof ScenarioOptionalDefaultsSchema>;

export default ScenarioSchema;
