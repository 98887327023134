import { Box } from "@material-ui/core";
import { BriefingSessionCategory } from "@onn/common";
import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "../formUtils/zodFormSchema";

import { Loading, SelectForm, Typography } from "~/components/uiParts";

export const BriefingSessionCategoryFormPart: FC<{
  form: UseFormReturn<InputState>;
  isLoading: boolean;
  briefingSessionCategories: BriefingSessionCategory[];
}> = ({ form, isLoading, briefingSessionCategories }) => {
  return (
    <Box>
      <Box display="flex" gridGap="12px" alignItems="center">
        <Typography variant="body2" bold>
          説明会
        </Typography>
      </Box>
      <Box mt="12px" width={"240px"}>
        {isLoading ? (
          <Loading size="small" />
        ) : (
          <Controller
            name="briefingSessionCategory.briefingSessionCategoryId"
            control={form.control}
            render={({ field, fieldState }) => (
              <StyledSelectForm
                {...field}
                fullWidth
                menuItems={briefingSessionCategories.map((briefingSessionCategory) => ({
                  value: briefingSessionCategory.id,
                  name: briefingSessionCategory.title,
                }))}
                selected={field.value || null}
                onChange={(e) => field.onChange(e.target.value)}
                errorBorder={!!fieldState.error}
                errorText={fieldState.error?.message}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
};

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    height: 40px;
  }
  .MuiTypography-root {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
