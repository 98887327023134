import {
  AnswerOnnEventTriggerSetting,
  AnswerOnnTaskTriggerSetting,
  ChangeRecruitmentStatusTriggerSetting,
  ChangeStatusOfOnnEventTriggerSetting,
  EvaluateOnnEventTriggerSetting,
} from "@onn/common";
import { useMemo } from "react";

import { InputBlockState } from "../hooks/scenarioForm/InputState";

import { useEditModeContext } from "./EditModeContextProvider";

type Props = {
  triggerSetting: InputBlockState["triggerSetting"];
};

export const useDescriptionForEditMode = ({ triggerSetting }: Props) => {
  const { allOnnEventsMap, allOnnTasksMap, allRecruitmentStatusesMap } = useEditModeContext();
  // NOTE: イベント評価トリガーの場合のみ、サブ説明文を表示する
  const triggerSubDescription = useMemo(() => {
    const triggerType = triggerSetting.type;

    switch (triggerType) {
      case EvaluateOnnEventTriggerSetting.type: {
        const onnEvent = allOnnEventsMap.get(triggerSetting.onnEventId);
        return onnEvent ? onnEvent.title : "";
      }
      case ChangeRecruitmentStatusTriggerSetting.type:
      case AnswerOnnTaskTriggerSetting.type:
      case AnswerOnnEventTriggerSetting.type:
      case ChangeStatusOfOnnEventTriggerSetting.type:
        return undefined;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = triggerType;
        return _exhaustiveCheck;
    }
  }, [allOnnEventsMap, triggerSetting]);

  const description = useMemo(() => {
    const triggerType = triggerSetting.type;

    switch (triggerType) {
      case EvaluateOnnEventTriggerSetting.type:
        return `候補者の評価が入力されたとき`;
      case ChangeRecruitmentStatusTriggerSetting.type: {
        const recruitmentStatus = allRecruitmentStatusesMap.get(triggerSetting.recruitmentStatusId);
        return recruitmentStatus
          ? `選考ステータスが「${recruitmentStatus.label}」に変更された時`
          : "";
      }
      case AnswerOnnTaskTriggerSetting.type: {
        const onnTask = allOnnTasksMap.get(triggerSetting.onnTaskId);
        return onnTask ? `「${onnTask.title}」が「回答済み」に変更された時` : "";
      }
      case AnswerOnnEventTriggerSetting.type: {
        const onnEvent = allOnnEventsMap.get(triggerSetting.onnEventId);
        return onnEvent ? `「${onnEvent.title}」が「回答済み」に変更された時` : "";
      }
      case ChangeStatusOfOnnEventTriggerSetting.type: {
        const onnEvent = allOnnEventsMap.get(triggerSetting.onnEventId);
        switch (triggerSetting.changeType) {
          case "ATTEND":
            return onnEvent ? `「${onnEvent.title}」が「参加済み」に変更された時` : "";
          default:
            // eslint-disable-next-line no-case-declarations
            const _exhaustiveCheck: never = triggerSetting.changeType;
            return _exhaustiveCheck;
        }
      }
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = triggerType;
        return _exhaustiveCheck;
    }
  }, [allOnnEventsMap, allOnnTasksMap, allRecruitmentStatusesMap, triggerSetting]);

  return { triggerSubDescription, description };
};
