import { useState, useCallback } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

/**
 *AssigneeとFollowersのIDを更新するための関数を提供するhooks
 * @returns isLoading データを処理中かどうか
 * @returns updateAssigneeAndFollowers
 */
export const useUpdateAssigneeAndFollowers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  /**
   * aAssigneeとFollowersのIDを更新するための関数
   */
  const updateAssigneeAndFollowers = useCallback(
    async (newGraduateId: string, assigneeId: string | undefined, followerIds: string[]) => {
      setIsLoading(true);
      return await apiClient
        .patch("/api/employee/assignee-and-followers", {
          newGraduateId,
          assigneeId,
          followerIds,
        })
        .then(() => {
          enqueueSnackbar(`対応チームを更新しました`, { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          throw new Error(e.message);
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar]
  );

  return { isLoading, updateAssigneeAndFollowers };
};
