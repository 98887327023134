import { Box } from "@material-ui/core";
import { isValidEmail, Employee, EmployeeTag, OnnEvent } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useState, useCallback, useMemo } from "react";
import styled from "styled-components";

import { useCheckInputValues } from "../InviteNewGraduateWithCSVModal/useCheckInputValues";

import { useGetErrorMessage } from "../InviteNewGraduateWithCSVModal/useGetErrorMessage";

import { ConfirmStep } from "./ConfirmStep";
import { SelectNewGraduateStep } from "./SelectNewGraduateStep";

import { SelectOnnEventsStep } from "./SelectOnnEventsStep";

import { VerticalStepper, Button, Divider, Modal } from "~/components/uiParts";
import { useDebouncedCallback } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { captureException, mixin } from "~/util";

type UserDataType = {
  email: string;
  emailErrorMessage: string;
  isValidEmail: boolean;
  employeeTagIds: string[];
};

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (
    userDataArray: UserDataType[],
    selectedOnnEventIds: string[],
    isSendEmail: boolean
  ) => Promise<void>;
  currentUser: Employee;
  onnEvents: OnnEvent[];
  employeeTags: EmployeeTag[];
};

export const CreateNewGraduateModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  currentUser,
  onnEvents,
  ...props
}) => {
  const { checkInputValues } = useCheckInputValues();
  const { getErrorMessage } = useGetErrorMessage();
  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();

  const [userDataArray, setUserDataArray] = useState<UserDataType[]>([
    {
      email: "",
      emailErrorMessage: "",
      isValidEmail: false,
      employeeTagIds: [],
    },
  ]);

  const [isValidatingInputEmails, setIsValidatingInputEmails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const [selectedOnnEventIds, setSelectedOnnEventIds] = useState<string[]>([]);
  const [isSendEmail, setIsSendEmail] = useState(false);

  const handleSwitchCheckBox = useCallback(
    (setter: (value: React.SetStateAction<string[]>) => void) => (id: string) => {
      setter((prev) => {
        if (prev.includes(id)) {
          return prev.filter((v) => v !== id);
        }
        return [...prev, id];
      });
    },
    []
  );

  const handleClickInvite = useCallback(async () => {
    setLoading(true);
    await onSubmit(userDataArray, selectedOnnEventIds, isSendEmail)
      .then(() => {
        onCancel();
      })
      .catch((e) => {
        captureException({
          error: e as Error,
          tags: { type: "CreateNewGraduateModal:handleClickInvite" },
        });
      })
      .finally(() => setLoading(false));
  }, [onSubmit, userDataArray, selectedOnnEventIds, isSendEmail, onCancel]);

  const updateUserDataArray = useCallback((newObject: Partial<UserDataType>, index: number) => {
    setUserDataArray((prevUserDataArray) => {
      return prevUserDataArray.map((prevUserData, prevIndex) => {
        if (prevIndex === index) {
          return { ...prevUserData, ...newObject };
        }
        return prevUserData;
      });
    });
  }, []);

  const handleValidEmailChange = useDebouncedCallback((callback) => callback(), 2000);
  const checkEmail = useCallback(
    (email: string, index: number) => {
      updateUserDataArray({ email, isValidEmail: false, emailErrorMessage: "" }, index);

      if (email === "") return;

      const isValid = isValidEmail(email);
      if (!isValid) {
        updateUserDataArray({ emailErrorMessage: "メールアドレスの形式が間違っています" }, index);
        return;
      }
      setIsValidatingInputEmails(true);

      handleValidEmailChange(async () => {
        const errorStatus = await checkInputValues(
          userDataArray.map((data, i) => {
            return {
              email: i === index ? email : data.email,
              departmentNames: [],
              tagNames: [],
              onboardingExperienceTitles: [],
              onnEventTitles: [],
            };
          })
        );
        const errorMessage = errorStatus.size
          ? getErrorMessage(errorStatus, {
              isCsv: false,
              rowIndex: index,
              inputRowSize: userDataArray.length,
            })
          : "";
        if (errorMessage) {
          updateUserDataArray({ emailErrorMessage: errorMessage }, index);
        } else {
          updateUserDataArray({ isValidEmail: true, emailErrorMessage: "" }, index);
        }
        setIsValidatingInputEmails(false);
      });
    },
    [updateUserDataArray, handleValidEmailChange, checkInputValues, userDataArray, getErrorMessage]
  );

  const handleAddEmails = useCallback(() => {
    setUserDataArray((prev) => [
      ...prev,
      {
        email: "",
        emailErrorMessage: "",
        isValidEmail: false,
        employeeTagIds: [],
      },
    ]);
  }, []);

  const handleDeleteEmails = useCallback((index: number): void => {
    setUserDataArray((prev) => prev.flatMap((v, prevIndex) => (prevIndex === index ? [] : v)));
  }, []);

  // 1個以上の正しいフォーマットの値(+ 0個以上のブランクの値)のとき真
  const checkUserDataArrayValid = useCallback(
    (userDataArray: UserDataType[]): boolean => {
      const filledUserDataArray = userDataArray.filter((userData) => userData.email !== "");

      if (currentUser.isAdmin()) {
        return (
          !isEmpty(filledUserDataArray) &&
          filledUserDataArray.every((filledUserData) => filledUserData.isValidEmail)
        );
      } else {
        return false;
      }
    },
    [currentUser]
  );

  const stepComponents = useMemo(
    () => [
      {
        label: "対象者情報",
        components: (
          <SelectNewGraduateStep
            userDataArray={userDataArray}
            employeeTags={props.employeeTags}
            onChangeInputEmail={(email: string, index: number) => {
              checkEmail(email, index);
            }}
            onChangeEmployeeTagIds={(selectedTagIds: string[], index: number) => {
              updateUserDataArray({ employeeTagIds: selectedTagIds }, index);
            }}
            onDeleteEmails={handleDeleteEmails}
            onAddEmails={handleAddEmails}
          />
        ),
      },
      ...(isEmpty(onnEvents)
        ? []
        : [
            {
              label: "イベント選択",
              components: (
                <SelectOnnEventsStep
                  onnEvents={onnEvents}
                  selectedIds={selectedOnnEventIds}
                  onSwitchCheckBox={handleSwitchCheckBox(setSelectedOnnEventIds)}
                />
              ),
            },
          ]),
      {
        label: "内容確認",
        components: (
          <ConfirmStep
            onnEvents={onnEvents}
            userDataArray={userDataArray}
            selectedOnnEventIds={selectedOnnEventIds}
            employeeTags={props.employeeTags}
            isSendEmail={isSendEmail}
            setIsSendEmail={setIsSendEmail}
          />
        ),
      },
    ],
    [
      handleAddEmails,
      handleDeleteEmails,
      handleSwitchCheckBox,
      isSendEmail,
      onnEvents,
      props.employeeTags,
      selectedOnnEventIds,
      updateUserDataArray,
      userDataArray,
      checkEmail,
    ]
  );

  const isLastStep = step === stepComponents.length;

  const Content = (
    <Box display="flex" pt={2}>
      <Box pl="30px">
        <VerticalStepper activeStep={step} labels={stepComponents.map((v) => v.label)} />
      </Box>
      <Divider orientation="vertical" margin={20} />
      <Box flexGrow={1} overflow="hidden">
        {stepComponents[step - 1]?.components}
        <StyledButtonContainer mt="40px">
          {step !== 1 && (
            <Button
              fullWidth
              borderRadius="circle"
              variant="outlined"
              color="default"
              onClick={() => setStep((prev) => prev - 1)}
            >
              戻る
            </Button>
          )}
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={() => {
              if (isLastStep) handleClickInvite();
              else {
                setUserDataArray((prev) => prev.filter((v) => v.email.trim() !== ""));
                setStep((prev) => prev + 1);
              }
            }}
            disabled={!checkUserDataArrayValid(userDataArray)}
            isLoading={loading || isValidatingInputEmails}
          >
            {isLastStep ? `${isSendEmail ? "招待メールを送信" : "登録"}` : "次へ"}
          </Button>
        </StyledButtonContainer>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      title={
        isShowSpaceOnScreen(spaces) ? `アカウント追加｜${currentSpace.name}` : "アカウント追加"
      }
      content={Content}
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
