import { APISchema } from "@onn/common";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/api/onn-event-answers/remove-determined-date-of-slot-date"]["POST"];

export const useRemoveNewInterviewDeterminedDate = () => {
  const removeNewInterviewDeterminedDate = async (args: EndPoint["body"]) => {
    const response = await apiClient.post(
      "/api/onn-event-answers/remove-determined-date-of-slot-date",
      args
    );

    return response;
  };

  return { removeNewInterviewDeterminedDate };
};
