import { CandidateDate, OnnEvent } from "@onn/common";
import React, { FC } from "react";

import { Completion } from "./completion";

type Props = {
  onnEvent: OnnEvent;
  candidateDate?: CandidateDate;
};

export const Registered: FC<Props> = ({ onnEvent, candidateDate }) => {
  return (
    <Completion
      title="参加登録完了"
      description={`「${onnEvent.title}」への参加登録はすでに完了しています。`}
      candidateDate={candidateDate}
    />
  );
};
