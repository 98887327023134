/**
 * アカウント登録時の説明文を生成する
 */
export const generateDescriptionMessage = ({
  isPreEntry,
  registrationMessageForPreEntry,
}: {
  isPreEntry: boolean;
  registrationMessageForPreEntry?: string;
}) => {
  if (isPreEntry) {
    return registrationMessageForPreEntry !== undefined
      ? registrationMessageForPreEntry
      : "新卒エントリーページです。アカウント登録後にエントリーや説明会の予約を行うことができます。\n" +
          "またアカウント登録を行うと、担当者からの連絡や最新情報を受け取ることができます。";
  } else {
    return "アカウント登録を行うと、担当者からの連絡や最新情報を受け取ることができます。";
  }
};
