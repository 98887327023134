import { instantiateFromAnyEmployeeInformationValueExcludeMethods } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

const getKey = ({ employeeId }: { employeeId: string }) => {
  return { endpoint: "/api/employee-information-values", employeeId } as const;
};

export const useEmployeeInformationValues = ({ employeeId }: { employeeId: string }) => {
  return useSWR(getKey({ employeeId }), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint, { employeeId });

    return response.data.map((anyEmployeeInformationValue) => {
      return instantiateFromAnyEmployeeInformationValueExcludeMethods(anyEmployeeInformationValue);
    });
  });
};

export const mutateEmployeeInformationValues = ({ employeeId }: { employeeId: string }) => {
  return mutate(getKey({ employeeId }));
};
