import { v4 } from "uuid";

import { AnnouncementDistribution as IAnnouncementDistribution } from "../../_gen/zodSchema/index";

import { announcementDistributionSchema } from "./schema";

export class AnnouncementDistribution implements IAnnouncementDistribution {
  id: string;
  tenantId: string;
  announcementId: string;
  employeeId: string;
  createdAt: Date;
  readDates: Date[];
  firstReadAt?: Date;
  lastReadAt?: Date;

  static validator = announcementDistributionSchema;

  constructor(init: {
    id?: string;
    tenantId: string;
    announcementId: string;
    employeeId: string;
    createdAt?: Date;
    readDates?: Date[];
    firstReadAt?: Date;
    lastReadAt?: Date;
  }) {
    this.id = init.id ?? v4();
    this.tenantId = init.tenantId;
    this.announcementId = init.announcementId;
    this.employeeId = init.employeeId;
    this.createdAt = init.createdAt ?? new Date();
    this.readDates = init.readDates ?? [];
    this.firstReadAt = init.firstReadAt;
    this.lastReadAt = init.lastReadAt;
  }

  static create(
    ...[init]: ConstructorParameters<typeof AnnouncementDistribution>
  ): AnnouncementDistribution {
    const parsedInit = this.validator.parse({
      ...init,
      id: init.id ?? v4(),
      readDates: init.readDates ?? [],
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    return new AnnouncementDistribution(parsedInit);
  }

  /**
   * announcementId と employeeId が同じ AnnouncementDistribution は同一とみなします
   */
  static equals(a: AnnouncementDistribution, b: AnnouncementDistribution): boolean {
    return a.announcementId === b.announcementId && a.employeeId === b.employeeId;
  }

  update(): AnnouncementDistribution {
    return new AnnouncementDistribution({ ...this });
  }

  read(): AnnouncementDistribution {
    if (!this.firstReadAt) this.firstReadAt = new Date();
    this.lastReadAt = new Date();
    this.readDates.push(this.lastReadAt);

    return this.update();
  }

  isRead(): this is AnnouncementDistribution & { firstReadAt: Date; lastReadAt: Date } {
    return !!this.firstReadAt && !!this.lastReadAt;
  }
}
