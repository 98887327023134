import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { TooltipWhenTextTruncatedOneLine } from "~/components/uiParts";

import { UserIcon } from "~/components/uiParts/UserIcon";

type Props = {
  assigneeId: string;
  assigneeName: string;
  assigneeIconUrl?: string;
};

export const AssigneeCell: FC<Props> = ({ assigneeName, assigneeIconUrl }) => {
  return (
    <Box display="flex" alignItems="center" gridGap={"8px"} ml={0}>
      <UserIcon
        username={assigneeName}
        profileIconImageUrl={assigneeIconUrl}
        size={"small"}
        circular={true}
      />
      <TooltipWhenTextTruncatedOneLine
        text={assigneeName}
        typographyProps={{ variant: "body2", color: "textPrimary" }}
      />
    </Box>
  );
};
