import { useCallback, useState } from "react";

import { useConvertFileToBase64 } from "~/hooks/file";
import { useAnswerOnBehalfToExistedSlotDatesWithCsv } from "~/hooks/onnEventSlotDates/useAnswerOnBehalfToExistedSlotDatesWithCsv";
import { captureException } from "~/util";

export const useHandleUploadFile = ({
  onnEventId,
  inputFile,
  onCancel,
}: {
  onnEventId: string;
  inputFile: File | undefined;
  onCancel: () => void;
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const { answerOnBehalfToExistedSlotDatesWithCsv } = useAnswerOnBehalfToExistedSlotDatesWithCsv();

  const { convertFileToBase64 } = useConvertFileToBase64();
  const handleUpload = useCallback(async () => {
    if (!inputFile) return;

    try {
      setIsUploading(true);

      const base64EncodedCsvFile = await convertFileToBase64(inputFile);
      await answerOnBehalfToExistedSlotDatesWithCsv({
        onnEventId,
        base64EncodedCsvFile,
        isDryRun: false,
      });
      onCancel();
    } catch (e) {
      captureException({
        error: e as Error,
        tags: {
          type: "AnswerNewInterviewEventOnBehalfWithCSVModal:handleUpload",
        },
        extras: {
          onnEventId,
        },
      });
    } finally {
      setIsUploading(false);
    }
  }, [
    answerOnBehalfToExistedSlotDatesWithCsv,
    convertFileToBase64,
    inputFile,
    onCancel,
    onnEventId,
  ]);

  return { handleUpload, isUploading };
};
