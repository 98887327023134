import { useCallback } from "react";

import { useDryRunCreateNewGraduateWithCsv } from "~/hooks/employee";
import { useConvertFileToBase64 } from "~/hooks/file";
import { useHandleInputCsvFileByFilePicker } from "~/hooks/file/useHandleInputCsvFileByFilePicker";

/**
 * 添付されたcsvファイルを管理するhooks
 */
export const useHandleInputFile = () => {
  const { dryRunCreateNewGraduateWithCsv } = useDryRunCreateNewGraduateWithCsv();
  const { convertFileToBase64 } = useConvertFileToBase64();
  const checkingFileOnInputFile = useCallback(
    async (file: File) => {
      const base64EncodedCsvFile = await convertFileToBase64(file);
      const response = await dryRunCreateNewGraduateWithCsv({
        base64EncodedCsvFile,
      });
      return {
        isOk: response.isOk,
        errorMessages: response.isOk ? [] : response.errorMessages,
      };
    },
    [convertFileToBase64, dryRunCreateNewGraduateWithCsv]
  );

  const result = useHandleInputCsvFileByFilePicker({
    objectForErrorLog: { type: "createNewGraduateWithCSV" },
    checkingFileOnInputFile,
  });

  return { ...result };
};
