import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { OnnTask } from "@onn/common";
import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import styled from "styled-components";

import { TabPage as OnnTaskAnswersTab } from "./components/AnswerResultTab/TabPage";
import { TabPage as OnnTaskContentsTab } from "./components/OnnTaskContentsTab/TabPage";
import { OnnTaskDetailSummary } from "./components/OnnTaskDetailSummary/OnnTaskDetailSummary";

import { usePage } from "./hooks/usePage";

import { Icon, Link, Loading, Typography } from "~/components/uiParts";
import { useOnnTask } from "~/hooks/onnTask";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { NotFound } from "~/pages/NotFound";

export const Page = () => {
  const params = useParams();
  const onnTaskId = params.id;
  const { data: onnTask, isLoading: isLoadingOnnTask } = useOnnTask({ onnTaskId });
  const { switchSpaceTemporary } = useCurrentSpace();

  useEffect(() => {
    if (!onnTask) return;
    switchSpaceTemporary(onnTask.spaceId);
  }, [switchSpaceTemporary, onnTask]);

  if (isLoadingOnnTask) return <Loading size="large" fullHeight />;
  if (!onnTask) {
    return <NotFound />;
  }

  return <PageCore onnTask={onnTask} />;
};

type Props = { onnTask: OnnTask };

const PageCore: FC<Props> = ({ onnTask: _onnTask }) => {
  const { onnTask, tabs, activeTab, handleChangeActiveTab } = usePage(_onnTask);

  return (
    <>
      <StyledLink to="/onn_tasks">
        <Box display="flex" alignItems="center" gridGap="8px">
          <Icon icon="arrowLeft" color="grey" size="md" />
          <Typography variant="body2" color="textSecondary" style={{ lineHeight: "24px" }}>
            配信タスク一覧
          </Typography>
        </Box>
      </StyledLink>
      <OnnTaskDetailSummary onnTask={onnTask} />
      <Box mt={3}>
        <TabContext value={activeTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeActiveTab}
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          <StyledTabPanel value="onnTaskContents">
            <OnnTaskContentsTab onnTask={onnTask} />
          </StyledTabPanel>
          <StyledTabPanel value="answer">
            <OnnTaskAnswersTab onnFormTask={onnTask} />
          </StyledTabPanel>
        </TabContext>
      </Box>
    </>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin-bottom: 48px;
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
