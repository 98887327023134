import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE PREDICTION SCHEMA
/////////////////////////////////////////

export const EmployeePredictionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  label: z
    .string()
    .trim()
    .min(1, { message: "名前が短すぎます" })
    .max(30, { message: "名前が長すぎます" }),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeePrediction = z.infer<typeof EmployeePredictionSchema>;

/////////////////////////////////////////
// EMPLOYEE PREDICTION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeePredictionOptionalDefaultsSchema = EmployeePredictionSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type EmployeePredictionOptionalDefaults = z.infer<
  typeof EmployeePredictionOptionalDefaultsSchema
>;

export default EmployeePredictionSchema;
