import React, { FC } from "react";

export const Error: FC = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0083 2.00421C13.3593 1.99312 14.6986 2.25573 15.9455 2.77621C17.131 3.26583 18.2081 3.98506 19.1149 4.89254C20.0218 5.80003 20.7405 6.87791 21.2298 8.06422C22.2567 10.5838 22.2567 13.4056 21.2298 15.9252C20.1739 18.3589 18.2036 20.2791 15.7445 21.2708C13.2854 22.2625 10.5352 22.2461 8.08809 21.2252C6.58153 20.5779 5.25601 19.5718 4.22699 18.2945C3.19798 17.0172 2.4967 15.5074 2.18426 13.8968C1.87182 12.2862 1.9577 10.6235 2.43441 9.05371C2.91113 7.48389 3.76421 6.05455 4.91934 4.89021C5.82842 3.98538 6.90509 3.26664 8.08909 2.77421C9.32869 2.25476 10.6604 1.99148 12.0043 2.00021L12.0083 2.00421ZM12.0093 4.12321C10.1897 4.12394 8.42657 4.75609 7.0205 5.91194C5.61443 7.06778 4.65239 8.67579 4.29833 10.4619C3.94428 12.2481 4.22012 14.1018 5.07885 15.7072C5.93757 17.3126 7.32604 18.5704 9.00763 19.2661C10.6892 19.9618 12.5599 20.0525 14.3008 19.5227C16.0417 18.9928 17.5451 17.8753 18.5549 16.3604C19.5647 14.8456 20.0182 13.0272 19.8383 11.2152C19.6585 9.40316 18.8562 7.70964 17.5684 6.42321C16.8468 5.68163 15.9816 5.09516 15.0258 4.69969C14.0699 4.30422 13.0435 4.10806 12.0093 4.12321Z"
        stroke="none"
      />
      <path
        d="M11 8C11 7.44771 11.4477 7 12 7C12.5523 7 13 7.44771 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8Z"
        stroke="none"
      />
      <path
        d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
        stroke="none"
      />
    </svg>
  );
};
