import { Stack } from "@mui/material";
import React, { FC } from "react";

import { useAddTriggerAndActionForm } from "./hooks/useForm";

import { ActionArea } from "./parts/ActionArea/ActionArea";
import { TriggerDescriptionArea } from "./parts/TriggerDescriptionArea";

import { InputState } from "./type";

import { Button, Icon, ScrollableBodyModal, Typography } from "~/components/uiParts";
import { useMenu } from "~/pages/scenario/Main/components/useMenu";
import { MenuOfTriggerSelection } from "~/pages/scenario/Main/editMode/components/MenuOfTriggerSelection";
import {
  PopoverOfApplyTriggerSettings,
  TriggerSettingsFormInputValue,
} from "~/pages/scenario/Main/editMode/components/PopoverOfApplyTriggerSettings";
import { usePopoverOfTriggerSetting } from "~/pages/scenario/Main/editMode/hooks/usePopoverOfTriggerSetting";

type Props = {
  open: boolean;
  onCancel: () => void;
  recruitmentStatusId: string;
  spaceId: string;
  applyToAddBlock: (inputState: InputState) => void;
};

export const AddTriggerAndActionModal: FC<Props> = ({
  open,
  onCancel,
  recruitmentStatusId,
  spaceId,
  applyToAddBlock,
}) => {
  const {
    form,
    triggerSetting,
    isSubmitButtonDisabled,
    addTriggerSetting,
    updateTriggerSetting,
    handleSubmit,
    handleClickCancel,
    addAction,
    updateAction,
    deleteAction,
  } = useAddTriggerAndActionForm({
    recruitmentStatusId,
    spaceId,
    applyToAddBlock,
    onCancel,
  });

  return (
    <>
      <ScrollableBodyModal
        open={open}
        disableBackdropModal
        disableEnforceFocus
        title="ブロック追加"
        content={
          <Stack direction="column" rowGap={4}>
            <TriggerArea
              triggerSetting={triggerSetting}
              addTriggerSetting={addTriggerSetting}
              updateTriggerSetting={updateTriggerSetting}
            />
            <ActionArea
              actions={form.watch("actions")}
              triggerSetting={triggerSetting}
              addAction={addAction}
              updateAction={updateAction}
              deleteAction={deleteAction}
              form={form}
            />
          </Stack>
        }
        footer={
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              borderRadius="circle"
              color="default"
              onClick={handleClickCancel}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitButtonDisabled}
              isLoading={form.formState.isSubmitting}
              type="submit"
            >
              追加
            </Button>
          </Stack>
        }
        onCancel={handleClickCancel}
      />
    </>
  );
};

const TriggerArea: FC<{
  triggerSetting: InputState["triggerSetting"];
  addTriggerSetting: (inputValue: TriggerSettingsFormInputValue) => void;
  updateTriggerSetting: (inputValue: TriggerSettingsFormInputValue) => void;
}> = ({ triggerSetting, addTriggerSetting, updateTriggerSetting }) => {
  const triggerSelectMenu = useMenu();
  const popoverOfApplyTriggerSettings = usePopoverOfTriggerSetting();

  // NOTE: トリガー設定セレクトメニューとトリガー設定ポップオーバーを同じ位置に表示させるためアンカー要素を同期させる
  popoverOfApplyTriggerSettings.anchorEl = triggerSelectMenu.anchorEl;

  return (
    <>
      <Stack direction="column" rowGap={2} alignItems="start">
        <Typography variant="body2" bold>
          トリガー
        </Typography>
        {triggerSetting ? (
          <TriggerDescriptionArea
            triggerSetting={triggerSetting}
            editButtonRef={triggerSelectMenu.anchorEl}
            onClickEditButton={() => popoverOfApplyTriggerSettings.open(triggerSetting.type)}
          />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            borderRadius="regular"
            startIcon={<Icon icon="add" color="primary" size="ssm" />}
            onClick={triggerSelectMenu.openMenu}
            buttonRef={triggerSelectMenu.anchorEl}
          >
            <Typography variant="body2" bold style={{ lineHeight: "20px" }}>
              追加
            </Typography>
          </Button>
        )}
      </Stack>
      <MenuOfTriggerSelection
        anchorEl={triggerSelectMenu.anchorEl.current}
        onClose={triggerSelectMenu.closeMenu}
        onSelectTriggerSettingsType={(type) => {
          popoverOfApplyTriggerSettings.open(type);
          triggerSelectMenu.closeMenu();
        }}
        isOpen={triggerSelectMenu.isOpen}
        {...(triggerSetting
          ? {
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
              transformOrigin: { vertical: "top", horizontal: "right" },
            } // NOTE: 編集時は編集ボタンをanchorとするため
          : {})}
      />

      {popoverOfApplyTriggerSettings.openedTriggerSettingType && (
        <PopoverOfApplyTriggerSettings
          currentTriggerSetting={triggerSetting}
          backToSelectTriggerType={() => {
            triggerSelectMenu.openMenu();
            popoverOfApplyTriggerSettings.close();
          }}
          triggerSettingType={popoverOfApplyTriggerSettings.openedTriggerSettingType}
          anchorEl={popoverOfApplyTriggerSettings.anchorEl.current}
          onClose={popoverOfApplyTriggerSettings.close}
          isOpen={popoverOfApplyTriggerSettings.isOpen}
          onApplyTriggerSetting={triggerSetting ? updateTriggerSetting : addTriggerSetting}
          {...(triggerSetting
            ? {
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
                transformOrigin: { vertical: "top", horizontal: "right" },
              } // NOTE: 編集時は編集ボタンをanchorとするため
            : {})}
        />
      )}
    </>
  );
};
