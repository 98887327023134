import { OnnEvent } from "@onn/common";
import { max } from "lodash";
import { useCallback } from "react";

import { useSelectableNewGraduates } from "../AnswerEventOnBehalfModal/hooks/useSelectableNewGraduates";

import { useOnnEventPlaces } from "~/hooks/onnEventPlace/useOnnEventPlaces";

export const useGenerateSettingCSV = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const { data: onnEventPlaces = [], isLoading: isLoadingPlaces } = useOnnEventPlaces();
  const { selectableNewGraduates = [], isLoading: isLoadingNewGraduates } =
    useSelectableNewGraduates({
      onnEventId: onnEvent.id,
      onnEventType: onnEvent.type,
    });
  const generateSettingCSV = useCallback(() => {
    const length = max([onnEventPlaces.length, selectableNewGraduates.length]) || 0;
    return [
      ["「Onn固有ID」コピー欄", "「会場名」コピー欄"],
      ...Array.from({ length }).map((_, i) => [
        selectableNewGraduates[i]?.uniqueId || "",
        onnEventPlaces[i]?.name || "",
      ]),
    ];
  }, [onnEventPlaces, selectableNewGraduates]);

  const isLoading = isLoadingPlaces || isLoadingNewGraduates;
  return { generateSettingCSV, isLoading };
};
