import useSWR from "swr";

import { apiClient } from "~/libs";

const generateKeyObj = (lineUserId: string) => {
  return {
    endpoint: `/get_linked_rich_menu`,
    lineUserId,
  } as const;
};
export const useRichMenuIdByLineUserId = ({ lineUserId }: { lineUserId?: string }) => {
  return useSWR(
    lineUserId ? generateKeyObj(lineUserId) : null,
    async ({ endpoint, lineUserId }) => {
      const response = await apiClient.get(endpoint, {
        lineUserId,
      });

      return response.data;
    }
  );
};
