import { Box, Grid } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";

import { OnnEventCondition } from "../../../../types/condition";

import { ClosableContainer } from "../../../parts/ClosableContainer";
import { SelectTargetButton } from "../../../parts/buttons/SelectTargetButton";

import { SelectMultipleConditionDropdown } from "../../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { SelectSingleConditionDropdown } from "../../../parts/dropdown-menus/SelectSingleConditionDropdown";

import { CommonProps } from "../type";

import { SlotOrCandidateDateSelector } from "./SlotOrCandidateDateSelector";

import { useChoices } from "./useChoices";

import { useOnChangeConditions } from "./useOnChangeConditions";

import { Loading } from "~/components/uiParts";
import { useAllOnnEvent } from "~/hooks/onnEvent";

const TARGET = "onnEvent";

type Props = CommonProps<OnnEventCondition>;
export const OnnEventConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: allOnnEvents = [], isLoading: isLoadingOnnEvents } = useAllOnnEvent();

  const isSelectedOnnEvent = useMemo(() => !!condition.eventId, [condition.eventId]);
  const selectedOnnEvent = useMemo(
    () => allOnnEvents && allOnnEvents.find((e) => e.id === condition.eventId),
    [allOnnEvents, condition.eventId]
  );

  const {
    onChangeEvent,
    onChangeCandidateDates,
    onChangeSlotIds,
    onChangeEventFormatTypes,
    onChangeAbsenceStatuses,
  } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
    allOnnEvents,
    selectedOnnEvent,
  });
  const {
    onnEventChoices,
    absenceStatusChoices,
    candidateDateChoices,
    slotChoices,
    eventFormatTypeChoices,
    loading: { isLoadingSlotChoices },
  } = useChoices({ selectedOnnEvent, allOnnEvents });

  const selectedAbsenceStatusChoices = useMemo(() => {
    if (!condition || !condition.attendanceStatuses) return [];
    return condition.attendanceStatuses.flatMap((status) => {
      return absenceStatusChoices.find((c) => c.value === status) || [];
    });
  }, [absenceStatusChoices, condition]);

  if (isLoadingOnnEvents) {
    return <Loading size="small" />;
  }

  // 「開催方法」セレクターの表示の有無
  const shouldShowEventFormatTypeSelector =
    isSelectedOnnEvent &&
    (selectedOnnEvent?.isBriefingSessionEvent() || selectedOnnEvent?.isNewInterviewEvent());

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target) => onChangeTarget(index, target)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectSingleConditionDropdown
            placeHolder="イベントを選択"
            key={"event"}
            selectedChoice={onnEventChoices.find((c) => c.value === condition.eventId)}
            choices={onnEventChoices}
            onChange={onChangeEvent}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectMultipleConditionDropdown
            placeHolder="ステータスを選択"
            key={"attendanceStatuses"}
            selectedChoices={selectedAbsenceStatusChoices}
            choices={absenceStatusChoices}
            onChange={onChangeAbsenceStatuses}
          />
        </Grid>
      </Grid>
      {/* FIXME: gridGap があたらないので mt あてた */}
      {isSelectedOnnEvent && (
        <Box width={"100%"} mt="8px">
          <ClosableContainer
            label="詳細条件"
            defaultShown={
              !isEmpty(condition.candidateDateIds) ||
              !isEmpty(condition.slotIds) ||
              !isEmpty(condition.eventFormatTypes)
            }
          >
            <Box width={"100%"} mb={"8px"}>
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <SlotOrCandidateDateSelector
                    selectedOnnEvent={selectedOnnEvent}
                    condition={condition}
                    candidateDateChoices={candidateDateChoices}
                    slotChoices={slotChoices}
                    onChangeCandidateDates={onChangeCandidateDates}
                    onChangeSlotIds={onChangeSlotIds}
                    isLoading={isLoadingSlotChoices}
                  />
                </Grid>
                <Grid item xs={3}>
                  {shouldShowEventFormatTypeSelector && (
                    <SelectMultipleConditionDropdown
                      placeHolder="開催方法を選択"
                      key={"eventFormatType"}
                      selectedChoices={eventFormatTypeChoices.filter((c) =>
                        condition.eventFormatTypes?.includes(c.value)
                      )}
                      choices={eventFormatTypeChoices}
                      onChange={onChangeEventFormatTypes}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </ClosableContainer>
        </Box>
      )}
    </Box>
  );
};
