import { v4 } from "uuid";

import { Scenario as IScenario, ScenarioSchema } from "../../_gen/zodSchema/index";

export class Scenario implements IScenario {
  static readonly validator = ScenarioSchema;

  id: string;
  tenantId: string;
  spaceId: string;

  name: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<Scenario>) {
    const parsedInit = Scenario.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;

    this.name = parsedInit.name;

    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(params: Omit<ExcludeMethods<Scenario>, "id" | "createdAt" | "updatedAt">) {
    return new Scenario({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(params: Partial<Pick<Scenario, "name">>) {
    return new Scenario({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
