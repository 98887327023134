import liff from "@line/liff";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useAddAccessLog } from "~/hooks/accessLog";
import { useLineUserProfile } from "~/hooks/liff";
import { useCookie, useQuery, useSessionStorage } from "~/hooks/shared";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";

/**
 * アクセスログを記録する
 */
export const AccessLog = () => {
  const { pathname, search } = useLocation();
  const { query } = useQuery();
  const { addAccessLog } = useAddAccessLog();
  const { data: lineUserProfile } = useLineUserProfile();
  const { retrieveValue } = useSessionStorage();
  const { value } = useCookie("employeeId");

  const sessionId = useMemo(() => (retrieveValue("sessionId") || "") as string, [retrieveValue]);
  const externalUserId = useMemo(() => lineUserProfile?.userId, [lineUserProfile]);
  const employeeId = useMemo(() => (value || "") as string, [value]);

  useEffect(() => {
    const tenantIdForPortal = getTenantIdFromDomain();
    // TODO: 管理画面でもtenantIdを取得できるようにする
    // AccessLog が呼ばれるタイミングの問題で、useCurrentUserNonGuarded や useTenant を呼ぶことができない。
    // そのため、現在は取得できていない。

    addAccessLog({
      pathname,
      queryString: search,
      query,
      tenantId: tenantIdForPortal || undefined,
      isLiff: liff.isInClient(),
      sessionId: sessionId || undefined,
      externalUserId,
      employeeId: employeeId || undefined,
      isPortal: !!tenantIdForPortal, // ポータルの場合のみ、取得している
    });
  }, [addAccessLog, pathname, query, search, externalUserId, sessionId, employeeId]);

  return null;
};
