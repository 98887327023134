import React, { useCallback } from "react";

import { Action } from "../../../_share_in_create_edit/reducer";

export const useTextQuestionFormHandler = (dispatch: React.Dispatch<Action>) => {
  const onChangeWordLimit = useCallback(
    ({ questionId, limit }: { questionId: string; limit: string }) => {
      dispatch({
        type: "TEXT_QUESTION_UPDATE_WORD_LIMIT",
        payload: {
          questionId,
          wordLimit: limit,
        },
      });
    },
    [dispatch]
  );

  const onClickIsWordLimit = useCallback(
    ({ questionId, isWordLimit }: { questionId: string; isWordLimit?: boolean }) => {
      dispatch({
        type: "TEXT_QUESTION_UPDATE_IS_WORD_LIMIT",
        payload: {
          questionId,
          isWordLimit: !isWordLimit,
        },
      });
    },
    [dispatch]
  );

  return { onChangeWordLimit, onClickIsWordLimit };
};
