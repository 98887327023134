import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Typography, Icon, TooltipWhenTextTruncated } from "~/components/uiParts";

import theme from "~/config/theme";

export const FileAttachedButtonUI: FC<{
  fileName: string;
  fileSizeLabel: string;
  type: "openNewTab" | "download";
  onClick?: () => void;
}> = ({ fileName, fileSizeLabel, type, onClick }) => {
  return (
    <Box
      px="16px"
      py="8px"
      sx={{
        display: "flex",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: theme.palette.grey[200],
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: "12px",
          ml: "6px",
          flexShrink: 0,
        }}
      >
        <Icon size="sm" color="grey" icon="clip" />
      </Box>
      <Box
        sx={{
          minWidth: "0px",
          flex: 1,
        }}
      >
        <Box>
          <TooltipWhenTextTruncated text={fileName}>
            {(ref) => (
              <>
                <Typography
                  variant="body1"
                  ref={ref}
                  style={{
                    minWidth: 0,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {fileName}
                </Typography>
              </>
            )}
          </TooltipWhenTextTruncated>
        </Box>
        <Typography variant="caption" color="textSecondary">
          {`${fileSizeLabel}`}
        </Typography>
      </Box>
      <Box
        sx={{
          flexShrink: 0,
        }}
      >
        {type === "openNewTab" && <Icon size="md" color="primary" icon="eye" />}
        {type === "download" && <Icon size="md" color="primary" icon="download" />}
      </Box>
    </Box>
  );
};
