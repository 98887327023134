import { OnnEvent, CreateOnnEventRequest } from "@onn/common";
import { useState } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export const useCreateOnnEvent = () => {
  const [isLoading, setIsLoading] = useState(false);

  const execCreateOnnEvent = async ({ onnEvent }: { onnEvent: OnnEvent }) => {
    try {
      const data = CreateOnnEventRequest.createRequestBody({ onnEvent });
      setIsLoading(true);
      const response = await functionOperator.httpsCallFor2ndGen<
        ReturnType<typeof CreateOnnEventRequest.createRequestBody>
      >("onnevent", data);
      return response;
    } finally {
      setIsLoading(false);
    }
  };

  return { execCreateOnnEvent, isLoading };
};
