import { z } from "zod";

export const TaskOptionTypes = {
  CHECK_BOX: "CHECK_BOX",
  TEXT: "TEXT",
  RADIO: "RADIO",
  FILE: "FILE",
} as const;

export type TaskOptionTypesKeys = keyof typeof TaskOptionTypes;

export const baseQuestionSchema = z.object({
  id: z.string().trim(),
  type: z.enum(["TEXT", "RADIO", "CHECK_BOX", "FILE"]),
  title: z.string().trim(),
  isRequired: z.boolean(),
});

export const textQuestionSchema = baseQuestionSchema.and(
  z.object({
    type: z.literal("TEXT"),
    wordLimit: z.number().optional(),
    isWordLimit: z.boolean().optional(),
  })
);

export const checkBoxQuestionSchema = baseQuestionSchema.merge(
  z.object({
    type: z.literal("CHECK_BOX"),
    options: z.array(z.object({ id: z.string().trim(), text: z.string().trim() })),
    allowFreeText: z.boolean(),
  })
);

export const radioQuestionSchema = baseQuestionSchema.merge(
  z.object({
    type: z.literal("RADIO"),
    options: z.array(z.object({ id: z.string().trim(), text: z.string().trim() })),
  })
);

export const fileQuestionSchema = baseQuestionSchema.merge(
  z.object({
    type: z.literal("FILE"),
    sampleFilePath: z.string().trim(),
  })
);

export const questionSchema = z.union([
  textQuestionSchema,
  checkBoxQuestionSchema,
  radioQuestionSchema,
  fileQuestionSchema,
]);

export type ITextQuestionSchema = z.infer<typeof textQuestionSchema>;
export type ICheckBoxQuestionSchema = z.infer<typeof checkBoxQuestionSchema>;
export type IRadioQuestionSchema = z.infer<typeof radioQuestionSchema>;
export type IFileQuestionSchema = z.infer<typeof fileQuestionSchema>;
export type IQuestionSchema = z.infer<typeof questionSchema>;
