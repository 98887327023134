import { generatePagePath } from "@onn/common";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

// 「共通設定」ボタンのクリックハンドラ
export const useOnClickSlotDefaultValueSetting = (eventId: string) => {
  const navigation = useNavigate();

  const onClickSlotDefaultValueSetting = useCallback(() => {
    navigation(generatePagePath.onnEvent.slotDefaultValueSetting(eventId, { from_page: "list" }));
  }, [eventId, navigation]);

  return { onClickSlotDefaultValueSetting };
};
