import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnnEventPlace } from "@onn/common";
import React, { FC, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

import { SelectedPlaceInfoPart } from "../../OnnEventNewOrEdit/SelectedPlaceInfoPart";
import { InputState } from "../hooks/useForm";

import { Button, Icon, Typography } from "~/components/uiParts";
import { Tag } from "~/components/uiParts/Tag";

type Props = {
  selectableEventPlace: OnnEventPlace[];
};
export const PlaceForm: FC<Props> = ({ selectableEventPlace }) => {
  const { control, setValue, clearErrors } = useFormContext<InputState>();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "eventPlaceId" });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isError = error !== undefined;
  const selectedEventPlace = selectableEventPlace.find((eventPlace) => eventPlace.id === value);
  return (
    <Box display="flex" flexDirection="column" gridRowGap={24}>
      <Box display="flex" alignItems="center" gridColumnGap={8}>
        <Typography variant="body1" color="textPrimary" bold>
          開催場所を選択
        </Typography>
        <Tag color="secondary" size="sm" text="必須" />
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        <>
          <Button
            color="default"
            variant="outlined"
            borderRadius="regular"
            fullWidth
            onClick={(e) => setAnchorEl(e.currentTarget)}
            endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
          >
            <Box flex={1} display={"flex"}>
              <Typography variant="body2" noWrap>
                {selectedEventPlace?.name || "対象を選択"}
              </Typography>
            </Box>
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClick={() => setAnchorEl(null)}
            getContentAnchorEl={null}
            MenuListProps={{
              // NOTE: デフォルトだと短くなってしまうためアンカー要素と幅を同じにする
              style: { width: anchorEl?.clientWidth },
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {selectableEventPlace.map((onnEventPlace) => (
              <MenuItem
                key={onnEventPlace.id}
                onClick={() => {
                  setValue("selectedDate", null);
                  setValue("onnEventSlotDateId", null);
                  clearErrors("selectedDate");
                  clearErrors("onnEventSlotDateId");
                  onChange(onnEventPlace.id);
                }}
              >
                <Typography variant="body2">{onnEventPlace.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
        {selectedEventPlace && (
          <SelectedPlaceInfoPart
            selectedEventPlace={selectedEventPlace}
            // selectedOnnEventSlotDate={selectedOnnEventSlotDate}
          />
        )}
      </Box>
      {isError && (
        <Typography variant="caption" color="secondary">
          {error.message}
        </Typography>
      )}
    </Box>
  );
};
