import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION SINGLE SELECT TYPE VALUE SCHEMA
/////////////////////////////////////////

export const EmployeeInformationSingleSelectTypeValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  employeeInformationSingleSelectTypeFieldId: z.string(),
  optionId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationSingleSelectTypeValue = z.infer<
  typeof EmployeeInformationSingleSelectTypeValueSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION SINGLE SELECT TYPE VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationSingleSelectTypeValueOptionalDefaultsSchema =
  EmployeeInformationSingleSelectTypeValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationSingleSelectTypeValueOptionalDefaults = z.infer<
  typeof EmployeeInformationSingleSelectTypeValueOptionalDefaultsSchema
>;

export default EmployeeInformationSingleSelectTypeValueSchema;
