import { OnnEventEvaluation, APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/post_onn_event_evaluations"]["POST"];

export const useCreateOnnEventEvaluation = () => {
  const createOnnEventEvaluation = useCallback(async (requestBody: Endpoint["body"]) => {
    const response = await apiClient.post("/post_onn_event_evaluations", requestBody);
    return new OnnEventEvaluation({
      ...response.data,
    });
  }, []);

  return { createOnnEventEvaluation };
};
