import { FILE_PATH_NORMALIZATION_FORM } from "@onn/common";

import { useTenant } from "~/hooks/tenant";

export const useCreateFileQuestionFilePath = () => {
  const {
    tenant: { tenantId },
  } = useTenant();

  const createFileQuestionFilePath = ({
    questionId,
    fileName,
    onnFormTaskId,
    uuidV4,
  }: {
    questionId: string;
    fileName: string;
    onnFormTaskId: string;
    uuidV4: string;
  }) => {
    return `tenants/${tenantId}/onn_tasks/${onnFormTaskId}/${questionId}/answers/${uuidV4}/${fileName}`.normalize(
      FILE_PATH_NORMALIZATION_FORM
    );
  };

  return { createFileQuestionFilePath };
};
