import React, { ReactNode, FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

type Props = {
  draggableId: string;
  index: number;
  isDragDisabled: boolean;
  children: ReactNode;
  shouldRoundCorner?: boolean;
  disabledBoxShadow?: boolean;
  background?: string;
};

export const DnDDraggable: FC<Props> = ({
  draggableId,
  index,
  isDragDisabled,
  children,
  background = "#fff",
  shouldRoundCorner = true,
  disabledBoxShadow = false,
}) => {
  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <StyledDnDEffect
          $isDragging={snapshot.isDragging}
          $background={background}
          $shouldRoundCorner={shouldRoundCorner}
          $disabledBoxShadow={disabledBoxShadow}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </StyledDnDEffect>
      )}
    </Draggable>
  );
};

const StyledDnDEffect = styled.div<{
  $isDragging: boolean;
  $background: string;
  $shouldRoundCorner: boolean;
  $disabledBoxShadow: boolean;
}>`
  box-shadow: ${({ $isDragging, $disabledBoxShadow }) => {
    if ($disabledBoxShadow) return "none";
    return $isDragging ? "0px 0px 10px #40404033" : "none";
  }};
  background: ${({ $background }) => $background};
  border-radius: ${({ $shouldRoundCorner }) => ($shouldRoundCorner ? "8px" : "0px")};
`;
