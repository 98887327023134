import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { ChangeEvent, FC, useMemo, useState } from "react";
import styled from "styled-components";

import { Account } from "./components/tabs/Account/Account";
import { AccountLinkage } from "./components/tabs/AccountLinkage/AccountLinkage";

import { Paper, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { mixin } from "~/util";

const TAB_VALUES = { account: "account", loginCooperation: "loginCooperation" } as const;
const TAB_LABELS: { [k in keyof typeof TAB_VALUES]: string } = {
  account: "アカウント",
  loginCooperation: "ログイン連携",
} as const;

type TabValue = keyof typeof TAB_VALUES;

const useTabs = () => {
  const { currentUser } = useCurrentUser();

  const [activeTab, setActiveTab] = useState<TabValue>("account");
  const handleChangeActiveTab = (_: ChangeEvent<unknown>, newValue: TabValue) => {
    setActiveTab(newValue);
  };

  // NOTE: currentUserが候補者の場合、ポータルからのアクセスとみなす
  const isPortal = currentUser.isNewcomer();
  const tabs: Array<{ label: string; value: string }> = useMemo(
    () =>
      [
        {
          label: TAB_LABELS.account,
          value: TAB_VALUES.account,
        },
        ...(isPortal
          ? [
              {
                label: TAB_LABELS.loginCooperation,
                value: TAB_VALUES.loginCooperation,
              },
            ]
          : []),
      ].flat(),
    [isPortal]
  );

  return { activeTab, handleChangeActiveTab, tabs, TAB_VALUES, TAB_LABELS };
};

// TODO: LINE認証ユーザーの場合でも、メールアドレスを変更できるようにする。またLINE認証ユーザーの場合は、パスワード変更のinputが表示されないようにする。
// NOTE: 管理画面からもポータルからもこのコンポーネントは呼ばれるため注意する
export const SettingsAccount: FC = () => {
  const { activeTab, handleChangeActiveTab, tabs } = useTabs();

  return (
    <StyledContainer>
      <StyledBox display="flex" flexDirection="column" width="100%" alignItems="center">
        <Box width="100%" maxWidth="1090px">
          <StyledTypography variant="h4" bold>
            アカウント設定
          </StyledTypography>
          <TabContext value={activeTab}>
            <StyledTabList
              onChange={handleChangeActiveTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {/* StyledTabList は children に element しか受け付けないため tabs をあらかじめ用意してレンダリングする */}
              {tabs.map((tab) => (
                <StyledTab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </StyledTabList>
            <StyledPaper square>
              {/* アカウントタブ */}
              <StyledTabPanel value={TAB_VALUES.account}>
                <StyledWrapper>
                  <Account />
                </StyledWrapper>
              </StyledTabPanel>
              {/* ログイン連携タブ */}
              <StyledTabPanel value={TAB_VALUES.loginCooperation}>
                <AccountLinkage />
              </StyledTabPanel>
            </StyledPaper>
          </TabContext>
        </Box>
      </StyledBox>
    </StyledContainer>
  );
};

const FOOTER_HEIGHT = 96;

const StyledBox = styled(Box)`
  ${mixin.pc`
    padding: 48px 40px ${FOOTER_HEIGHT + 40}px 40px;
  `};
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 40px;
  }
`;

const StyledWrapper = styled.div`
  max-width: 500px;
`;

const StyledTypography = styled(Typography)`
  ${mixin.sp`
    &.MuiTypography-root {
      font-size: 32px;
      font-weight: normal;
    }
  `}
`;

const StyledContainer = styled.div`
  ${mixin.sp`
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 40px 24px 40px 24px;
  `};
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    margin: 40px 0px 32px;
    ${mixin.sp`
      box-shadow: none;
      padding: 40px 0;
      margin: 0px;
    `}
  }
`;

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0px;
  }
`;
