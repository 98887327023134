import { zodResolver } from "@hookform/resolvers/zod";
import { employeeInformationValueSchema } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import { InformationValueEditor } from "../InformationValueEditor";

import { SelectForm } from "~/components/uiParts";

type Props = {
  onSave: ({ gender }: { gender: string }) => void;
  onCancel: () => void;
  data?: string;
};

export const GenderEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, control, formState } = useForm<{ gender: string }>({
    defaultValues: {
      gender: data,
    },
    mode: "onChange",
    resolver: zodResolver(employeeInformationValueSchema.pick({ gender: true })),
  });

  return (
    <InformationValueEditor
      form={
        <form style={{ display: "flex", columnGap: 16 }} onSubmit={handleSubmit(onSave)}>
          <Controller
            name="gender"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <SelectForm
                fullWidth
                selected={field.value}
                menuItems={[
                  { name: "男性", value: "男性" },
                  { name: "女性", value: "女性" },
                  { name: "その他", value: "その他" },
                ]}
                errorBorder={!!error}
                errorText={error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};
