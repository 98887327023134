import { AnyTriggerSetting } from "@onn/common";
import { useCallback, useState } from "react";

import { usePopover } from "~/components/uiParts/Popover";

export const usePopoverOfTriggerSetting = () => {
  const [openedTriggerSettingType, setOpenedTriggerSettingType] = useState<
    AnyTriggerSetting["type"] | null
  >(null);
  const popover = usePopover();

  const open = useCallback(
    (type: AnyTriggerSetting["type"]) => {
      setOpenedTriggerSettingType(type);
      popover.openMenu();
    },
    [popover]
  );

  const close = useCallback(() => {
    setOpenedTriggerSettingType(null);
    popover.closeMenu();
  }, [popover]);

  return {
    openedTriggerSettingType,
    open,
    close,
    isOpen: popover.isOpen,
    anchorEl: popover.anchorEl,
  };
};
