import { z } from "zod";

/////////////////////////////////////////
// BM ONN EVENT AVAILABLE SLOT COUNTER SCHEMA
/////////////////////////////////////////

export const BmOnnEventAvailableSlotCounterSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  availableSlotCount: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type BmOnnEventAvailableSlotCounter = z.infer<typeof BmOnnEventAvailableSlotCounterSchema>;

/////////////////////////////////////////
// BM ONN EVENT AVAILABLE SLOT COUNTER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const BmOnnEventAvailableSlotCounterOptionalDefaultsSchema =
  BmOnnEventAvailableSlotCounterSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type BmOnnEventAvailableSlotCounterOptionalDefaults = z.infer<
  typeof BmOnnEventAvailableSlotCounterOptionalDefaultsSchema
>;

export default BmOnnEventAvailableSlotCounterSchema;
