import { v4 } from "uuid";

import { Employee } from "../../../domain/Employee";
import { LineUser } from "../../../domain/Line";
import { Tenant, TenantWithSecret } from "../../../domain/Tenant";
import { ContactMessage } from "../ContactMessage";

export class ContactMessageWithCreator extends ContactMessage {
  creator: Employee | LineUser | Tenant | TenantWithSecret;
  constructor(init: ExcludeMethods<ContactMessageWithCreator>) {
    super(init);
    this.creator = init.creator;
  }

  private getCreatorName(tenant: Tenant | TenantWithSecret): string {
    if (this.isSentByOfficialName) {
      return tenant.tenantName;
    }

    if (this.creator instanceof Employee) {
      return this.creator.getName();
    }
    if (this.creator instanceof LineUser) {
      return this.creator.displayName;
    }
    return this.creator.tenantName;
  }

  getCreatorImageUrl(): string | undefined {
    if (this.creator instanceof Employee) {
      return this.creator.profileIconImageUrl;
    }
    if (this.creator instanceof LineUser) {
      return this.creator.pictureUrl;
    }
    return undefined;
  }

  getCreatorInfo(tenant: Tenant | TenantWithSecret): {
    creatorName: string;
    honorificCreatorName: string;
    creatorImageUrl?: string;
  } {
    const creatorName = this.getCreatorName(tenant);
    const isCreatorTenantLike = "tenantName" in this.creator && "tenantId" in this.creator; // NOTE: TenantWithSecret が pure object の場合があり instanceof が使えないためこうしている
    const shouldUseOfficialImage = this.isSentByOfficialName || isCreatorTenantLike;

    return {
      creatorName,
      honorificCreatorName: this.isSentByOfficialName ? creatorName : creatorName + "さん",
      creatorImageUrl: shouldUseOfficialImage
        ? `public/uploads/logo/${tenant.tenantId}`
        : this.getCreatorImageUrl(),
    };
  }

  public static create(
    params: Optional<ExcludeMethods<ContactMessageWithCreator>, "id">
  ): ContactMessageWithCreator {
    return new ContactMessageWithCreator({
      ...params,
      id: params.id ?? v4(),
    });
  }

  public static fromUser(
    message: ContactMessage,
    creator: Employee | LineUser | Tenant | TenantWithSecret
  ): ContactMessageWithCreator {
    return ContactMessageWithCreator.create({ ...message, creator });
  }
}
