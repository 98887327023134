import { zodResolver } from "@hookform/resolvers/zod";
import { BriefingSessionCategory, OnnEvent, onnEventSchema } from "@onn/common";
import { useForm as useReactHookForm } from "react-hook-form";
import { z } from "zod";

export const useForm = (
  params:
    | {
        mode: "create";
      }
    | {
        mode: "edit";
        onnEvent: OnnEvent;
        briefingSessionCategories: BriefingSessionCategory[];
      }
) => {
  const form = useReactHookForm<InputState>({
    defaultValues:
      params.mode === "create"
        ? {
            title: "",
            content: "",
            isRestrictedAnswerFromNewGraduate: false,
            isRestrictedDaysAgoWhenAnswer: false,
            isRestrictedEditAnswerFromNewGraduate: false,
            daysAgoRestrictAnswer: undefined,
            briefingSessions: [],
          }
        : {
            title: params.onnEvent.title,
            content: params.onnEvent.content,
            isRestrictedAnswerFromNewGraduate: params.onnEvent.isRestrictedAnswerFromNewGraduate,
            isRestrictedDaysAgoWhenAnswer: params.onnEvent.isRestrictedDaysAgoWhenAnswer,
            isRestrictedEditAnswerFromNewGraduate:
              params.onnEvent.isRestrictedEditAnswerFromNewGraduate,
            daysAgoRestrictAnswer: params.onnEvent.daysAgoRestrictAnswer,
            briefingSessions: params.briefingSessionCategories.map((category) => ({
              id: category.id,
              title: category.title,
              description: category.description,
            })),
          },
    mode: "onChange",
    resolver: zodResolver(generateFormSchema()),
  });

  return { form };
};

const generateFormSchema = () => {
  return z
    .object({
      title: z
        .string()
        .min(1, "イベントタイトルを入力してください")
        .max(50, "50文字以内で入力してください"),
      content: z
        .string()
        .min(1, "イベント内容を入力してください")
        .max(1000, "1000文字以内で入力してください"),
      briefingSessions: z
        .array(
          z.object({
            id: z.string().optional(),
            title: z.string(),
            description: z.string(),
          })
        )
        .min(1, "説明会を1つ以上追加してください"),
      isRestrictedAnswerFromNewGraduate: onnEventSchema.shape.isRestrictedAnswerFromNewGraduate,
      isRestrictedDaysAgoWhenAnswer: onnEventSchema.shape.isRestrictedDaysAgoWhenAnswer,
      isRestrictedEditAnswerFromNewGraduate:
        onnEventSchema.shape.isRestrictedEditAnswerFromNewGraduate,
      daysAgoRestrictAnswer: z.union([z.string(), z.number().int(), z.undefined()]),
    })
    .superRefine((value, ctx) => {
      if (value.isRestrictedDaysAgoWhenAnswer) {
        const daysAgoRestrictAnswer = Number(value.daysAgoRestrictAnswer);
        if (!Number.isInteger(daysAgoRestrictAnswer) || daysAgoRestrictAnswer <= 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "1以上の整数を設定してください",
            path: ["daysAgoRestrictAnswer"],
          });
        }
      }
    });
};

export type InputState = z.infer<ReturnType<typeof generateFormSchema>>;
