import { ListOnnEventDeterminedDateRequest, ListOnnEventDeterminedDateResponse } from "@onn/common";
import useSWR, { mutate } from "swr";

import { functionOperator } from "~/infrastructure/api/functionOperator";

const getKey = (onnEventId?: string) => {
  return onnEventId ? `listOnnEventDeterminedDate-${onnEventId}` : null;
};

export const useDeterminedDate = ({ onnEventId }: { onnEventId: string | undefined }) => {
  return useSWR(getKey(onnEventId), async () => {
    const response = await functionOperator.httpsCallFor2ndGen<
      ReturnType<typeof ListOnnEventDeterminedDateRequest.createRequestBody>,
      typeof ListOnnEventDeterminedDateResponse.ResponseBody
    >(
      "onneventdetermineddate",
      ListOnnEventDeterminedDateRequest.createRequestBody({ onnEventId: onnEventId || "" })
    );
    return ListOnnEventDeterminedDateResponse.convertUnixTimeToDate(response.data);
  });
};

export const mutateDeterminedDate = (onnEventId: string) => {
  mutate(getKey(onnEventId));
};
