import { z } from "zod";

import { OnnEventAnswerSchema } from "../../../domain/_gen/zodSchema";

export const onnEventAnswerSchema = OnnEventAnswerSchema.merge(
  z.object({
    answer: z.record(z.enum(["possible", "impossible", "pending"])), // possible: O, impossible: X, pending: ▲
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventAnswer extends z.infer<typeof onnEventAnswerSchema> {}
