import { useState } from "react";
import { v4 } from "uuid";

import { useCreateFileQuestionFilePath } from "./useCreateFileQuestionFilePath";

import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException, captureMessage } from "~/util/loggerUtil";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const useUploadAnswerFile = () => {
  const { createFileQuestionFilePath } = useCreateFileQuestionFilePath();
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const [isUploading, setIsUploading] = useState(false);

  const uploadAnswerFile = async ({
    file,
    onnFormTaskId,
    questionId,
  }: {
    file: File;
    onnFormTaskId: string;
    questionId: string;
  }) => {
    try {
      setIsUploading(true);
      const filePath = createFileQuestionFilePath({
        uuidV4: v4(),
        questionId,
        onnFormTaskId,
        fileName: file.name,
      });

      const res = await fileAPIAdapter.uploadFiles([
        {
          path: filePath,
          file: file,
        },
      ]);
      captureMessage({
        message: "添付ファイルのアップロードに成功しました",
        tags: { type: "useSubmitHandler:onClickSubmit" },
        extras: {
          payload: {
            resLength: res.length,
            fullPaths: res.map((r) => r.ref.fullPath),
            uploadFileName: file.name,
            employeeId: currentUser.id,
            tenantId: currentUser.tenantId,
          },
        },
      });
      return filePath;
    } catch (e) {
      enqueueSnackbar("添付ファイルのアップロードに失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "useUploadAnswerFile" },
      });
      return null;
    } finally {
      setIsUploading(false);
    }
  };

  return { uploadAnswerFile, isUploading };
};
