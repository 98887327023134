import { OnnFormTaskResultActionCondition } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

const generateKey = (onnTaskId: string) => {
  return {
    endpoint: "/scenario_api/action-conditions",
    onnTaskId,
  } as const;
};

export const useOnnFormTaskResultActionCondition = ({ onnTaskId }: { onnTaskId: string }) => {
  return useSWR(generateKey(onnTaskId), async ({ endpoint, onnTaskId }) => {
    const response = await apiClient.get(endpoint, {
      onnFormTaskId: onnTaskId,
      type: "OnnFormTaskResultActionCondition",
    });
    return response.data.filter(
      (condition): condition is OnnFormTaskResultActionCondition =>
        condition.type === "OnnFormTaskResultActionCondition"
    );
  });
};
