import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent/OnnEvent/schema";

export const deleteOnnEventRequestSchema = z.object({
  onnEventId: onnEventSchema.shape.id,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDeleteOnnEventRequestSchema extends z.infer<typeof deleteOnnEventRequestSchema> {}
