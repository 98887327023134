import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type Props = {
  onnEvents: OnnEvent[];
  selectedIds: string[];
  onSwitchCheckBox: (id: string) => void;
};

export const SelectOnnEventsStep: FC<Props> = ({ onnEvents, selectedIds, onSwitchCheckBox }) => {
  return (
    <Box>
      <Typography variant="body1" color="textPrimary">
        {`対象者に配信する現在配信中のイベントを選択してください。\n選択したイベントの回答フォームが、対象者に自動配信されます。`}
      </Typography>
      <StyledBox display="flex" flexDirection="column" mt="32px">
        {onnEvents.map((onnEvent) => {
          return (
            <StyledFormControlLabel
              key={onnEvent.id}
              control={
                <Checkbox
                  checked={selectedIds.includes(onnEvent.id)}
                  onClick={() => onSwitchCheckBox(onnEvent.id)}
                />
              }
              label={
                <Typography variant="body2" color="textPrimary" noWrap>
                  {onnEvent.title}
                </Typography>
              }
            />
          );
        })}
      </StyledBox>
    </Box>
  );
};

const StyledBox = styled(Box)`
  &.MuiBox-root {
    min-height: 105px;
    max-height: 175px;
    overflow-y: auto;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
