import { z } from "zod";

import { emailType, lineType } from "../Employee/AuthenticationType";

export const changeAuthenticationLogSchema = z.object({
  id: z.string(),
  employeeId: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  fromUid: z.string(),
  toUid: z.string(),
  fromType: z.literal(emailType),
  toType: z.literal(lineType),
  fromEmail: z.string().optional(),
  createdAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChangeAuthenticationLogSchema
  extends z.infer<typeof changeAuthenticationLogSchema> {}
