import React, { FC } from "react";

import { CellItem, StyleProps, UserIcon } from "~/components/uiParts";

export const AssigneeCell: FC<
  { assigneeName?: string; profileIconImageUrl?: string } & StyleProps
> = ({ assigneeName, profileIconImageUrl, ...styleProps }) => {
  return (
    <CellItem {...styleProps}>
      <UserIcon
        username={assigneeName || ""}
        size={"small"}
        profileIconImageUrl={profileIconImageUrl}
        circular={true}
      />
    </CellItem>
  );
};
