import React, { FC } from "react";

export const Ban: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g>
        <path d="M24 40C21.7874 40 19.7093 39.5777 17.7656 38.7331C15.8219 37.8884 14.1271 36.7431 12.6812 35.2972C11.2353 33.8513 10.0936 32.1529 9.25613 30.202C8.41871 28.2511 8 26.1694 8 23.9568C8 21.7443 8.41872 19.6697 9.25617 17.7332C10.0936 15.7967 11.2353 14.1091 12.6812 12.6703C14.1271 11.2316 15.8219 10.0936 17.7656 9.25613C19.7093 8.41871 21.7874 8 24 8C26.2125 8 28.2907 8.41872 30.2344 9.25617C32.1781 10.0936 33.8729 11.2317 35.3188 12.6704C36.7647 14.1091 37.9064 15.7967 38.7438 17.7332C39.5813 19.6698 40 21.7443 40 23.9569C40 26.1694 39.5813 28.2511 38.7438 30.202C37.9064 32.1529 36.7647 33.8513 35.3188 35.2973C33.8729 36.7432 32.1781 37.8884 30.2344 38.7331C28.2907 39.5777 26.2125 40 24 40ZM24 35.9543C25.2589 35.9543 26.4814 35.7676 27.6673 35.3943C28.8532 35.0209 29.9373 34.4504 30.9197 33.6826L14.2742 17.0587C13.5352 18.0699 12.9791 19.1612 12.6057 20.3327C12.2323 21.5043 12.0457 22.7123 12.0457 23.9569C12.0457 27.2945 13.2072 30.1284 15.5304 32.4588C17.8536 34.7892 20.6768 35.9543 24 35.9543ZM33.7473 30.855C34.472 29.8438 35.0209 28.7525 35.3943 27.581C35.7677 26.4094 35.9543 25.2014 35.9543 23.9569C35.9543 20.6336 34.7928 17.8176 32.4696 15.5088C30.1464 13.2001 27.3232 12.0457 24 12.0457C22.7554 12.0457 21.551 12.2251 20.3867 12.5841C19.2223 12.9431 18.1346 13.4849 17.1235 14.2095L33.7473 30.855Z" />
      </g>
    </svg>
  );
};
