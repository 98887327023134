import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  questionTitle: string;
  isRequired?: boolean;
  children: React.ReactNode;
  alignItems?: "flex-start" | "center";
};

export const QuestionPartBase: FC<Props> = ({
  questionTitle,
  isRequired,
  children,
  alignItems,
}) => {
  return (
    <Box display="flex" flexDirection="column" gridRowGap="8px" alignItems={alignItems}>
      <Typography variant="body2" bold appendRequiredSymbol={isRequired}>
        {`${questionTitle} `}
      </Typography>
      {children}
    </Box>
  );
};
