import { z } from "zod";

/////////////////////////////////////////
// ANNOUNCEMENT DISTRIBUTION SCHEMA
/////////////////////////////////////////

export const AnnouncementDistributionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  announcementId: z.string(),
  employeeId: z.string(),
  firstReadAt: z.date().nullish(),
  lastReadAt: z.date().nullish(),
  readDates: z.date().array(),
  createdAt: z.date(),
});

export type AnnouncementDistribution = z.infer<typeof AnnouncementDistributionSchema>;

/////////////////////////////////////////
// ANNOUNCEMENT DISTRIBUTION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const AnnouncementDistributionOptionalDefaultsSchema = AnnouncementDistributionSchema.merge(
  z.object({
    createdAt: z.date().optional(),
  })
);

export type AnnouncementDistributionOptionalDefaults = z.infer<
  typeof AnnouncementDistributionOptionalDefaultsSchema
>;

export default AnnouncementDistributionSchema;
