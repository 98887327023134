import { Box, TextField } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { FC, useCallback } from "react";
import { DropResult } from "react-beautiful-dnd";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import styled from "styled-components";

import { v4 } from "uuid";

import { InputState } from "../hooks/schema";

import {
  Checkbox,
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
  IconButton,
  Typography,
} from "~/components/uiParts";

type Props = {
  form: UseFormReturn<InputState>;
};

export const CheckBoxGroupForm: FC<Props> = ({ form }) => {
  const { fields, move, append, remove } = useFieldArray({
    control: form.control,
    name: "employeeInformationMultipleTypeOptions",
  });

  const removeField = useCallback(
    (index: number) => {
      remove(index);
      form.trigger();
    },
    [form, remove]
  );

  const addField = useCallback(() => {
    append({
      id: v4(),
      label: "",
      order: fields.length + 1,
    });
    form.trigger("type");
  }, [append, fields.length, form]);

  const changeOrder = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      move(sourceIndex, destinationIndex);
    },
    [move]
  );

  return (
    <Box width="100%">
      <DnDProvider onDragEnd={changeOrder}>
        <Box width="100%">
          <DnDDroppable droppableId="optionDrop" isDropDisabled={false}>
            {fields.map((option, index) => {
              return (
                <DnDDraggable
                  isDragDisabled={false}
                  draggableId={option.id}
                  index={index}
                  key={option.id}
                >
                  <StyledWrapper
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    width="100%"
                    mb={2}
                  >
                    <Box display="flex" justifyContent="center" p="4px">
                      <Icon
                        id="gripVerticalWrapper"
                        icon="gripVertical"
                        size="sm"
                        color="lightGrey"
                      />
                    </Box>
                    <Stack direction="row" alignItems="center" spacing={2} width="100%">
                      <Checkbox onChange={undefined} disabled style={{ padding: 0 }} />
                      <Controller
                        name={`employeeInformationMultipleTypeOptions.${index}.label`}
                        render={({ field, fieldState }) => (
                          <StyledTextField
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                              form.trigger();
                            }}
                            placeholder="選択肢を入力"
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                      <Box>
                        {/* NOTE: 最後の１つの選択肢は消せないようにする */}
                        {fields.length !== 1 && (
                          <IconButton icon="close" onClick={() => removeField(index)} />
                        )}
                      </Box>
                    </Stack>
                  </StyledWrapper>
                </DnDDraggable>
              );
            })}
          </DnDDroppable>
        </Box>
      </DnDProvider>
      <Stack
        direction="row"
        alignItems="center"
        pl="32px"
        height="40px"
        spacing={2}
        width="100%"
        onClick={addField}
        sx={{ cursor: "pointer" }}
      >
        <Checkbox onChange={undefined} disabled style={{ padding: 0 }} />

        <StyledTypography variant="body1">選択肢を追加</StyledTypography>
      </Stack>
    </Box>
  );
};

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledTextField = styled(TextField)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
  .MuiInputBase-input {
    height: 40px;
    padding: 0;
  }
`;

const StyledWrapper = styled(Stack)`
  #gripVerticalWrapper > svg {
    display: none;
  }
  &:hover,
  &:active {
    #gripVerticalWrapper > svg {
      display: inline-block;
    }
  }
`;
