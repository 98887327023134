import { Announcement } from "@onn/common";
import { useCallback } from "react";
import useSWR, { KeyedMutator, useSWRConfig } from "swr";

import { apiClient } from "~/libs";

const getKey = (announcementId: string) => {
  return {
    endpoint: "/announcement_api/announcement",
    announcementId,
  } as const;
};

export const useAnnouncement = ({ announcementId }: { announcementId: string }) => {
  return useSWR(
    announcementId ? getKey(announcementId) : null,
    async ({ endpoint, announcementId }) => {
      const data = await apiClient.get(endpoint, { announcementId }).then((res) => res.data);

      return new Announcement(data.announcement);
    }
  );
};

export const useGenerateMutateAnnouncement = () => {
  const { mutate } = useSWRConfig();

  const generateMutateAnnouncement = useCallback<
    (announcementId: string) => KeyedMutator<Announcement | undefined>
  >(
    (announcementId) => {
      return async (data, opts) => {
        const key = getKey(announcementId);
        if (!data) {
          return mutate(key);
        }
        return mutate(key, data, opts);
      };
    },
    [mutate]
  );

  return { generateMutateAnnouncement };
};
