import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE TAG RELATION SCHEMA
/////////////////////////////////////////

export const EmployeeTagRelationSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  creatorId: z.string().nullish(),
  employeeId: z.string(),
  tagId: z.string(),
  createdAt: z.date(),
  deleted: z.boolean(),
});

export type EmployeeTagRelation = z.infer<typeof EmployeeTagRelationSchema>;

/////////////////////////////////////////
// EMPLOYEE TAG RELATION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeTagRelationOptionalDefaultsSchema = EmployeeTagRelationSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    deleted: z.boolean().optional(),
  })
);

export type EmployeeTagRelationOptionalDefaults = z.infer<
  typeof EmployeeTagRelationOptionalDefaultsSchema
>;

export default EmployeeTagRelationSchema;
