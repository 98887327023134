import { Stack } from "@mui/material";
import { contactMessagePlaceHolderMap } from "@onn/common";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { SelectorOfContactMessageVariable } from "../Selectors/SelectorOfContactMessageVariable";

import { Button, Modal, TextareaAutosize, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useTenantSettings, useUpdateTenantSettings } from "~/hooks/tenantSetting";

type Props = {
  onCancel: () => void;
  open: boolean;
  onSubmit: (messageTextVariable: string) => void;
};

const INITIAL_MESSAGE_TEXT_VARIABLE_KEY = "newGraduateLastName";

export const ModalOfContactMessageTextSetting: FC<Props> = ({ onCancel, open, onSubmit }) => {
  const { currentUser } = useCurrentUser();
  const { updateTenantSettings } = useUpdateTenantSettings();
  const { tenantSettings, mutateTenantSettings } = useTenantSettings();
  const [altText, setAltText] = useState<string>();
  const [messageTextVariableKey, setMessageTextVariableKey] = useState<
    keyof typeof contactMessagePlaceHolderMap
  >(INITIAL_MESSAGE_TEXT_VARIABLE_KEY);

  useEffect(() => {
    setAltText(tenantSettings.contact?.altTexts?.[messageTextVariableKey]);
  }, [messageTextVariableKey, tenantSettings]);

  const handleClose = useCallback(() => {
    onCancel();

    // NOTE: モーダルが閉じるアニメーションが終わるまで待つ
    setTimeout(() => {
      setMessageTextVariableKey(INITIAL_MESSAGE_TEXT_VARIABLE_KEY);
    }, 300);
  }, [onCancel]);

  const handleSubmit = useCallback(async () => {
    if (altText) {
      await updateTenantSettings(currentUser.tenantId, {
        contact: {
          defaultFollowerEmployeeIds: tenantSettings.contact?.defaultFollowerEmployeeIds || [],
          altTexts: {
            ...tenantSettings.contact?.altTexts,
            [messageTextVariableKey]: altText,
          },
        },
      });
      mutateTenantSettings();
    }
    onSubmit(contactMessagePlaceHolderMap[messageTextVariableKey]);
    handleClose();
  }, [
    altText,
    currentUser.tenantId,
    messageTextVariableKey,
    mutateTenantSettings,
    handleClose,
    onSubmit,
    tenantSettings.contact?.altTexts,
    tenantSettings.contact?.defaultFollowerEmployeeIds,
    updateTenantSettings,
  ]);

  const content = useMemo(
    () => (
      <Stack direction="column" rowGap="16px">
        <Stack direction="column" rowGap="8px">
          <Typography variant="body2" bold>
            変数の追加
          </Typography>
          <SelectorOfContactMessageVariable
            value={messageTextVariableKey}
            onChange={(value) => setMessageTextVariableKey(value)}
          />
        </Stack>
        <Stack direction="column" rowGap="8px">
          <Typography variant="body2" bold>
            代替テキスト
          </Typography>
          <TextareaAutosize
            fullWidth
            onChange={(e) => setAltText(e.target.value)}
            value={altText}
            minRows={2}
            maxRows={2}
            placeholder="値がない場合、代わりに表示するテキストを入力してください"
          />
        </Stack>
      </Stack>
    ),
    [altText, messageTextVariableKey]
  );

  const footer = useMemo(
    () => (
      <Stack direction="row" columnGap="16px" justifyContent="end">
        <Button variant="text" borderRadius="regular" color="default" onClick={handleClose}>
          キャンセル
        </Button>
        <Button variant="contained" borderRadius="regular" color="primary" onClick={handleSubmit}>
          保存
        </Button>
      </Stack>
    ),
    [handleSubmit, handleClose]
  );

  return <StyledModal open={open} content={content} footer={footer} />;
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    min-width: 400px;
    width: 400px;
    padding: 16px;
    border-radius: 8px;
  }

  .MuiBox-root {
    margin-bottom: 0;
  }

  .MuiTypography-h4 {
    font-size: 14px;
  }
`;
