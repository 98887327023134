import { Box, AppBar } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Icon } from "~/components/uiParts";

import theme from "~/config/theme";
import { useShouldSPView } from "~/hooks/shared/useShouldSPView";

type Props = {
  cancelButtonText: string;
  submitButtonText: string;
  fromPage: string;
  onClickGoBack: () => void;
  onClickCancel: () => void;
  onClickConfirmSave: () => void;
  isDisabledSaveButton: boolean;
  isSubmitting: boolean;
};
// MEMO: uiPartとして共通化する
export const ActionFooter: FC<Props> = ({
  cancelButtonText,
  submitButtonText,
  fromPage,
  onClickGoBack,
  onClickCancel,
  onClickConfirmSave,
  isDisabledSaveButton,
  isSubmitting,
}) => {
  const shouldSPView = useShouldSPView();

  //NOTE: styledで上書きできなかったので、styleで定義 */}
  if (fromPage === "create" || fromPage === "edit") {
    return (
      <StyledActionFooter
        style={{
          bottom: 0,
          top: "auto",
          backgroundColor: theme.palette.background.paper,
          height: "80px",
        }}
      >
        <StyledBox
          display="flex"
          height="80px"
          alignItems="center"
          justifyContent="space-between"
          mx="40px"
          my="16px"
          $isSP={shouldSPView}
        >
          <Box>
            {fromPage === "create" && (
              <Button variant="text" borderRadius="regular" onClick={onClickGoBack} color="default">
                <StyledReverseIcon icon="arrowRight" size="sm" color="grey" />
                戻る
              </Button>
            )}
          </Box>
          <Box display="flex" height="46px">
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems="center"
              height={"56px"}
              ml="24px"
            >
              <Box>
                <Button
                  variant="text"
                  borderRadius="regular"
                  onClick={onClickCancel}
                  color="default"
                >
                  {cancelButtonText}
                </Button>
              </Box>
            </Box>
            <Box ml="24px">
              <StyledSaveButton
                variant="contained"
                borderRadius="circle"
                onClick={onClickConfirmSave}
                color="primary"
                disabled={isDisabledSaveButton}
                isLoading={isSubmitting}
              >
                {submitButtonText}
              </StyledSaveButton>
            </Box>
          </Box>
        </StyledBox>
      </StyledActionFooter>
    );
  } else {
    return (
      <StyledActionFooter
        style={{
          bottom: 0,
          top: "auto",
          backgroundColor: theme.palette.background.paper,
          height: "78px",
        }}
      >
        <StyledDynamicPaddingLeftBox
          display="flex"
          height="80px"
          alignItems="center"
          justifyContent="flex-end"
          mx="40px"
          my="16px"
        >
          <Box display="flex" height="46px">
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems="center"
              height={"56px"}
              ml="24px"
            >
              <Box>
                <Button
                  variant="text"
                  borderRadius="regular"
                  onClick={onClickCancel}
                  color="default"
                >
                  {cancelButtonText}
                </Button>
              </Box>
            </Box>
            <Box ml="24px">
              <StyledSaveButton
                variant="contained"
                borderRadius="circle"
                onClick={onClickConfirmSave}
                color="primary"
                disabled={isDisabledSaveButton}
              >
                {submitButtonText}
              </StyledSaveButton>
            </Box>
          </Box>
        </StyledDynamicPaddingLeftBox>
      </StyledActionFooter>
    );
  }
};

const StyledActionFooter = styled(AppBar)``;

const StyledBox = styled(Box)<{ $isSP: boolean }>`
  padding-left: ${(props) => {
    if (props.$isSP) {
      return 0;
    }

    return props.theme.isOpenSidebar
      ? props.theme.constants.SIDEBAR_OPEN_WIDTH
      : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
  }}px;
`;

const StyledSaveButton = styled(Button)`
  &.MuiButton-root > div {
    line-height: 12px;
  }
`;

const StyledDynamicPaddingLeftBox = styled(Box)`
  padding-left: ${(props) => {
    return props.theme.isOpenSidebar
      ? props.theme.constants.SIDEBAR_OPEN_WIDTH
      : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
  }}px;
`;

const StyledReverseIcon = styled(Icon)`
  transform: rotate(180deg);
`;
