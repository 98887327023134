import { Menu, MenuItem, MenuProps } from "@material-ui/core";
import React, { FC, MouseEvent, memo, useCallback, useState } from "react";

export type MenuItemOption =
  | {
      onClick: (e: React.MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => void;
      text: string;
    }
  | {
      renderCustomComponent: (closeMenu: () => void) => React.ReactNode;
    };

// NOTE: 必要になったら渡せる項目を増やす
type Props = {
  renderButton: (openMenu: (event: MouseEvent<HTMLElement>) => void) => React.ReactNode;
  menuItemOptions: MenuItemOption[];
  menuProps?: Pick<MenuProps, "style" | "anchorOrigin" | "transformOrigin">; // 上書きしたいプロパティがあれば追加する
};

export const UncontrolledMenu: FC<Props> = memo(({ renderButton, menuItemOptions, menuProps }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <>
      {renderButton(openMenu)}
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...menuProps}
      >
        {menuItemOptions.map((menuItemOption, index) => {
          if ("renderCustomComponent" in menuItemOption) {
            return menuItemOption.renderCustomComponent(() => setAnchorEl(null));
          }
          return (
            <MenuItem
              key={index}
              onClick={(e) => {
                closeMenu(e);
                menuItemOption.onClick(e);
              }}
            >
              {menuItemOption.text}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
});
