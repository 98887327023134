import { v4 } from "uuid";

import { SendContactMessageActionSetting as ISendContactMessageActionSetting } from "../../_gen/zodSchema/index";

import { sendContactMessageActionSettingSchema } from "./schema";

export class SendContactMessageActionSetting implements ISendContactMessageActionSetting {
  static readonly type = "SendContactMessageActionSetting" as const;
  type = SendContactMessageActionSetting.type;

  static readonly validator = sendContactMessageActionSettingSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  actionId: string;
  filePaths: string[];
  createdAt: Date;
  updatedAt: Date;
  text: string;

  constructor(init: Omit<ExcludeMethods<SendContactMessageActionSetting>, "type">) {
    const parsedInit = SendContactMessageActionSetting.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.actionId = parsedInit.actionId;
    this.filePaths = parsedInit.filePaths;
    this.text = parsedInit.text;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public update(
    params: Pick<ExcludeMethods<SendContactMessageActionSetting>, "text" | "filePaths">
  ) {
    return new SendContactMessageActionSetting({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }

  static create(
    params: Omit<
      ExcludeMethods<SendContactMessageActionSetting>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new SendContactMessageActionSetting({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
