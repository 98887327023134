import React, { FC } from "react";

import { InformationValueViewer } from "../InformationValueViewer";

import { TooltipWhenTextTruncatedOneLine } from "~/components/uiParts";

type Props = {
  value?: string;
  onChangeModeToEdit: () => void;
  onClickCopy?: () => void;
};

export const CustomEmployeeInformationTextTypeViewer: FC<Props> = ({
  value,
  onChangeModeToEdit,
  onClickCopy,
}) => {
  return (
    <InformationValueViewer
      label={
        value ? (
          <TooltipWhenTextTruncatedOneLine
            typographyProps={{ variant: "body2", color: "textPrimary" }}
            text={value}
          />
        ) : undefined
      }
      onChangeModeToEdit={onChangeModeToEdit}
      onClickCopy={onClickCopy}
    />
  );
};
