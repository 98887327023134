import { Employee, OnnEvent, Failure, Success, Result } from "../../../../domain";
import { toCloudFunctionsCompatible } from "../../../../utils";
import { onnEventActionTypes } from "../shared";

import {
  IListCandidateDatesWithNumberOfParticipantsRequestSchema,
  listCandidateDatesWithNumberOfParticipantsRequestSchema,
} from "./schema";

export class ListCandidateDatesWithNumberOfParticipantsRequest
  implements IListCandidateDatesWithNumberOfParticipantsRequestSchema
{
  static readonly validator = listCandidateDatesWithNumberOfParticipantsRequestSchema;

  static actionType: typeof onnEventActionTypes.listCandidateDatesWithNumberOfParticipants =
    "listCandidateDatesWithNumberOfParticipants";
  employeeId: Employee["id"];
  onnEventId: OnnEvent["id"];

  constructor(init: ListCandidateDatesWithNumberOfParticipantsRequest) {
    this.employeeId = init.employeeId;
    this.onnEventId = init.onnEventId;
  }

  // クライアント側でリクエストボディを作成する
  public static createRequestBody(init: ListCandidateDatesWithNumberOfParticipantsRequest) {
    return toCloudFunctionsCompatible({
      ...init,
      actionType: this.actionType,
    });
  }

  public static validate(
    data: ListCandidateDatesWithNumberOfParticipantsRequest
  ): Result<ListCandidateDatesWithNumberOfParticipantsRequest, Error> {
    const result = ListCandidateDatesWithNumberOfParticipantsRequest.validator.safeParse(data);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new ListCandidateDatesWithNumberOfParticipantsRequest(data));
  }
}
