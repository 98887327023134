import { v4 } from "uuid";

import { AnswerOnnEventTriggerSetting as IAnswerOnnEventTriggerSetting } from "../../_gen/zodSchema/index";

import { answerOnnEventTriggerSettingSchema } from "./schema";

export class AnswerOnnEventTriggerSetting implements IAnswerOnnEventTriggerSetting {
  static readonly type = "AnswerOnnEventTriggerSetting" as const;
  type = AnswerOnnEventTriggerSetting.type;

  static readonly validator = answerOnnEventTriggerSettingSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  triggerId: string;
  onnEventId: string;
  createdAt: Date;
  updatedAt: Date;
  constructor(init: Omit<ExcludeMethods<AnswerOnnEventTriggerSetting>, "type">) {
    const parsedInit = AnswerOnnEventTriggerSetting.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.triggerId = parsedInit.triggerId;
    this.onnEventId = parsedInit.onnEventId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(
    params: Omit<
      ExcludeMethods<AnswerOnnEventTriggerSetting>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new AnswerOnnEventTriggerSetting({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
