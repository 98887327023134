import { Box } from "@material-ui/core";
import { Employee, EmployeeToDisplay } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography, UserIconGroup, UserIconWithLabel } from "~/components/uiParts";

type Props = {
  assignee?: EmployeeToDisplay;
  followers: Employee[];
  handleClickManageButton: () => void;
};

export const NewGraduateAssigneeAndFollowers: FC<Props> = ({
  assignee,
  followers,
  handleClickManageButton,
}) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" gridGap={8}>
      <StyledBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex={1}
        onClick={handleClickManageButton}
      >
        <StyledTypography variant="caption" noWrap>
          担当者
        </StyledTypography>
        <Box my={1} />
        <UserIconWithLabel
          name={assignee?.getName() ?? "未登録"}
          iconCircular
          size="semiSmall"
          textSize="small"
          iconPath={assignee?.profileIconImageUrl}
        />
      </StyledBox>
      <StyledBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex={1}
        onClick={handleClickManageButton}
      >
        <StyledTypography variant="caption" noWrap>
          フォロワー
        </StyledTypography>
        <Box my={1} />
        {isEmpty(followers) ? (
          <Box height="32px" display="flex" alignItems="center">
            <Typography variant="caption" color="textPrimary">
              未設定
            </Typography>
          </Box>
        ) : (
          <UserIconGroup
            usersInfo={followers.map((a) => ({
              username: a.getName(),
              profileIconImageUrl: a.profileIconImageUrl,
              borderColor: undefined,
            }))}
            tooltip={true}
            iconSize="semiSmall"
          />
        )}
      </StyledBox>
    </Box>
  );
};

const StyledBox = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    color: ${(props) => props.theme.palette.grey[300]};
  }
`;
