import { Employee } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { useLiffId } from "../liff";

import { apiClient } from "~/libs";
import { ApiResponseError } from "~/util";

type Result = {
  tenantName: string;
  enTenantName?: string;
  employee: Employee;
} | null;

export const useRegistrationInfoByLiff = ({
  liffAccessToken,
}: {
  liffAccessToken?: string | null;
}): SWRResponse<Result, Error> => {
  const liffId = useLiffId();

  const shouldFetch = liffAccessToken && liffId;
  const keys = shouldFetch
    ? (["/api/employee/retrieve-registration-info-by-liff", liffAccessToken, liffId] as const)
    : null;

  return useSWR(keys, async ([endpoint, liffAccessToken, liffId]) => {
    return apiClient
      .post(endpoint, { liffAccessToken, liffId })
      .then((res) => {
        return { tenantName: res.data.tenantName, employee: new Employee(res.data.userAccount) };
      })
      .catch((err) => {
        if (err instanceof ApiResponseError && err.status === 404) {
          return null;
        }
        throw err;
      });
  });
};
