import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";

import { PubliationDatePicker } from "./PubliationDatePicker";

import { Paper, Icon, Typography, Tooltip } from "~/components/uiParts";

type Props = {
  fixedPublicationStartDate?: Date;
  fixedPublicationEndDate?: Date;
  publicationStartDateInput: {
    error: boolean;
    helperText: string;
  };
  publicationStartDate: Date | null;
  updatePublicationStartDate: (date: Date | null) => void;
  publicationEndDateInput: {
    error: boolean;
    helperText: string;
  };
  publicationEndDate: Date | null;
  updatePublicationEndDate: (date: Date | null) => void;
  isDirtyPublicationDate: boolean;
};

export const PublicationSettingsPaper = ({
  publicationStartDateInput,
  publicationStartDate,
  publicationEndDateInput,
  publicationEndDate,
  updatePublicationStartDate,
  updatePublicationEndDate,
  isDirtyPublicationDate,
  fixedPublicationStartDate,
  fixedPublicationEndDate,
}: Props): JSX.Element => {
  return (
    <Paper>
      <Box display={"flex"} flexDirection={"column"} gridGap={"16px"}>
        <Box display={"flex"} alignItems={"center"} gridGap={"8px"}>
          <Typography variant="body1" bold>
            公開期間
          </Typography>
          <Tooltip
            title={"設定された開始日時に配信が開始され、終了日時に非公開化されます。"}
            placement="top-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box display={"flex"} alignItems={"flex-start"} gridGap={"8px"}>
          <Box>
            {fixedPublicationStartDate ? (
              <Box height="50px" display="flex" alignItems="center">
                <Typography>{format(fixedPublicationStartDate, "yyyy/MM/dd H:mm")}</Typography>
              </Box>
            ) : (
              <PubliationDatePicker
                date={publicationStartDate}
                onChangeDate={updatePublicationStartDate}
                dateHelperText={publicationStartDateInput.helperText}
                dateError={
                  isDirtyPublicationDate || publicationEndDate
                    ? publicationStartDateInput.error
                    : false
                }
              />
            )}
          </Box>
          <Box height="50px" display="flex" alignItems="center">
            <Typography>〜</Typography>
          </Box>
          <Box>
            {fixedPublicationEndDate ? (
              <Box height="50px" display="flex" alignItems="center">
                <Typography>{format(fixedPublicationEndDate, "yyyy/MM/dd H:mm")}</Typography>
              </Box>
            ) : (
              <PubliationDatePicker
                date={publicationEndDate}
                onChangeDate={updatePublicationEndDate}
                dateHelperText={
                  isDirtyPublicationDate || publicationEndDate
                    ? publicationEndDateInput.helperText
                    : ""
                }
                dateError={
                  isDirtyPublicationDate || publicationEndDate
                    ? publicationEndDateInput.error
                    : false
                }
              />
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
