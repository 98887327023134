import { v4 } from "uuid";

import { Answer } from "../../OnnTask/Answer";
import { OnnFormTaskResultActionCondition as IOnnFormTaskResultActionCondition } from "../../_gen/zodSchema/index";

import { AnswerOnnTaskTriggerSetting, AnyTriggerSetting } from "../triggerSettings";

import { onnFormTaskResultActionConditionSchema } from "./schema";

export class OnnFormTaskResultActionCondition implements IOnnFormTaskResultActionCondition {
  static readonly type = "OnnFormTaskResultActionCondition" as const;
  type = OnnFormTaskResultActionCondition.type;

  static readonly validator = onnFormTaskResultActionConditionSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  createdAt: Date;
  updatedAt: Date;
  onnFormTaskId: string;
  answerType: AnswerType;
  optionId: string;
  actionId: string;
  questionId: string;

  constructor(init: Omit<ExcludeMethods<OnnFormTaskResultActionCondition>, "type">) {
    const parsedInit = OnnFormTaskResultActionCondition.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.onnFormTaskId = parsedInit.onnFormTaskId;
    this.answerType = parsedInit.answerType;
    this.optionId = parsedInit.optionId;
    this.actionId = parsedInit.actionId;
    this.questionId = parsedInit.questionId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(
    params: Omit<
      ExcludeMethods<OnnFormTaskResultActionCondition>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new OnnFormTaskResultActionCondition({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static isAvailableTriggerSettingType(triggerSettingType: AnyTriggerSetting["type"]): boolean {
    return triggerSettingType === AnswerOnnTaskTriggerSetting.type;
  }
}

type AnswerType = Extract<Answer["type"], "RADIO">;
// Answer の typeと一致する必要がある
