import { Box } from "@material-ui/core";

import { Announcement } from "@onn/common";
import { format } from "date-fns";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { CreatorCell } from "./CreatorCell";

import { AnnouncementManageMenu } from "~/components/domains/announcement";
import { Typography, Button, TooltipWhenTextTruncated, Loading, Chip } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useAnnouncementDistributionsByAnnouncementId } from "~/hooks/announcementDistribution";
import { useCurrentUser } from "~/hooks/employee";

type Props = {
  announcements: Announcement[];
  isLoading: boolean;
};

const MAX_WIDTH = 1090;
const PADDING_X = 40;
const calcWidthParcentage = (width: number) => {
  const ratio = width / (MAX_WIDTH - PADDING_X * 2);
  return `${ratio * 100}%`;
};

export const AnnouncementsListTable: FC<Props> = ({ announcements, isLoading }) => {
  const { currentUser } = useCurrentUser();

  const widthOptions = [
    calcWidthParcentage(460),
    calcWidthParcentage(170),
    calcWidthParcentage(90),
    calcWidthParcentage(90),
    calcWidthParcentage(120),
    calcWidthParcentage(70),
  ];
  return (
    <VirtualizedTableV2<Announcement>
      rowHeight={90}
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={[
        {
          text: "タイトル",
        },
        {
          text: "担当者",
        },
        {
          text: "配信対象",
        },
        {
          text: "既読数",
        },
        {
          text: "",
        },
        {
          text: "",
        },
      ]}
      rows={announcements}
      rowRenderer={({ key, index, style, rowData: announcement }) => {
        const contents = [
          <TitleCell key={announcement.id} announcement={announcement} />,
          <CreatorCell key={announcement.id} announcement={announcement} />,
          <NumberOfDistributionCell key={announcement.id} announcementId={announcement.id} />,
          <ViewedCount key={announcement.id} announcementId={announcement.id} />,
          <ToDetailOrDeliveryButtonCellOrStatusChip
            key={announcement.id}
            announcement={announcement}
          />,
          currentUser.isAdmin() ? (
            <AnnouncementManageMenu announcement={announcement} key={announcement.id} />
          ) : (
            <></>
          ),
        ];
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/announcements/${announcement.id}`,
                isTargetBlank: false,
              }}
              widthOptions={widthOptions}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

const TitleCell = ({ announcement }: { announcement: Announcement }) => {
  const publicationDateStr = `公開期間：${
    announcement.isSettingCompleted()
      ? `${format(announcement.publicationStartDate, "yyyy/MM/dd")} 〜 ${format(
          announcement.publicationEndDate,
          "yyyy/MM/dd"
        )}`
      : "未設定"
  }`;

  const titleText = announcement.title;
  return (
    <Box key={announcement.id}>
      <Box>
        <Box>
          <TooltipWhenTextTruncated text={titleText}>
            {(ref) => (
              <StyledTitle ref={ref} variant="body1">
                {titleText}
              </StyledTitle>
            )}
          </TooltipWhenTextTruncated>
        </Box>
      </Box>
      <StyledDeadlineDate key={announcement.id}>{publicationDateStr}</StyledDeadlineDate>
    </Box>
  );
};

const NumberOfDistributionCell = ({ announcementId }: { announcementId: string }) => {
  const { data: distributions, isLoading } = useAnnouncementDistributionsByAnnouncementId({
    announcementId,
  });

  if (isLoading) return <Loading size="small" fullHeight />;

  if (distributions && distributions.length !== 0) {
    return <>{distributions.length}</>;
  }
  return <>-</>;
};

const ViewedCount = ({ announcementId }: { announcementId: string }) => {
  const { data: distributions, isLoading } = useAnnouncementDistributionsByAnnouncementId({
    announcementId,
  });

  if (isLoading) return <Loading size="small" fullHeight />;

  if (distributions && distributions.length !== 0) {
    const viewedCount = distributions.filter((d) => d.isRead()).length;
    return <>{viewedCount}</>;
  }

  return <>-</>;
};

const ToDetailOrDeliveryButtonCellOrStatusChip = ({
  announcement,
}: {
  announcement: Announcement;
}) => {
  const { data: distributions, isLoading } = useAnnouncementDistributionsByAnnouncementId({
    announcementId: announcement.id,
  });
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  if (isLoading || distributions === undefined) return <Loading size="small" fullHeight />;

  if (announcement.isWaitingPublication() && distributions.length > 0) {
    return <StyledChip label="配信待ち" color="grey" bold />;
  }
  if (announcement.isFinishedPublication() && distributions.length > 0) {
    return <StyledChip label="公開終了" color="darkGrey" bold />;
  }
  if (announcement.isUnderPublication() && distributions.length > 0) {
    return <StyledChip label="配信中" color="primary" bold />;
  }

  return currentUser.isAdmin() ? (
    <Box height={"30px"}>
      <StyledButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          navigate(`/announcements/${announcement.id}/delivery_setting`);
        }}
        color="secondary"
        variant="outlined"
        borderRadius="regular"
      >
        配信設定
      </StyledButton>
    </Box>
  ) : (
    <></>
  );
};

// TODO: Muiを剥がすタイミングでボタンサイズをデザインに揃える
const StyledButton = styled(Button)``;

const StyledTitle = styled(Typography)`
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;

const StyledDeadlineDate = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    font-size: 10px;
    height: 32px;
    padding: 0 8px;
  }
`;
