import { Box } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { Fragment, useCallback, FC } from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  employeeTags: EmployeeTag[];
  selectedTagIds: string[];
  onChange: (selectedTagIds: string[]) => void;
};

// ====================
// main
// ====================

export const EmployeeTagSelectMenu: FC<Props> = ({ employeeTags, selectedTagIds, onChange }) => {
  // ====================
  // useCallback
  // ====================

  const handleSetNewTagIds = useCallback(
    (currentSelected: EmployeeTag) => {
      const newIds = selectedTagIds.includes(currentSelected.id)
        ? selectedTagIds.filter((tagId) => tagId !== currentSelected.id)
        : [...selectedTagIds, currentSelected.id];
      return onChange(newIds);
    },
    [onChange, selectedTagIds]
  );

  const alreadySelected = useCallback(
    (tag: EmployeeTag) => selectedTagIds.includes(tag.id),
    [selectedTagIds]
  );

  // ====================
  // component
  // ====================

  return (
    <Box py="16px" width="318px" display="flex" flexDirection="column">
      <Box px="24px" maxHeight={300} overflow="auto" display="flex" flexDirection="column">
        {employeeTags.map((tag) => (
          <Fragment key={tag.id}>
            <StyledFormControlLabel
              onChange={() => handleSetNewTagIds(tag)}
              control={<Checkbox checked={alreadySelected(tag)} name={tag.id} value={tag.id} />}
              disabled={false}
              label={
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  gridGap="4px"
                  overflow="hidden"
                >
                  <StyledTypography variant="body2" noWrap>
                    {tag.name}
                  </StyledTypography>
                </Box>
              }
            />
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

// ====================
// style
// ====================

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 8px;
    color: ${(props) => props.theme.palette.text.secondary}
`;
