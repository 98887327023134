import { Announcement } from "@onn/common";
import { useCallback, useState } from "react";

import { apiClient } from "~/libs";

export const useCreateAnnouncement = () => {
  const [isLoading, setLoading] = useState(false);
  const execCreateAnnouncement = useCallback(
    async ({
      id,
      title,
      content,
      filePaths,
    }: {
      id: string;
      title: string;
      content: string;
      filePaths: string[];
    }): Promise<Announcement> => {
      setLoading(true);
      const announcement = await apiClient
        .post("/announcement_api/announcement", {
          id,
          title,
          content,
          filePaths,
        })
        .then((res) => Announcement.create(res.data.announcement))
        .catch((err) => {
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });

      return announcement;
    },
    []
  );

  return { execCreateAnnouncement, isLoading };
};
