import { zodResolver } from "@hookform/resolvers/zod";
import {
  Action,
  AnyActionCondition,
  AnyActionSetting,
  instantiateFromAnyTriggerSettingExcludeMethods,
  postScenariosBlockSchema,
  Trigger,
} from "@onn/common";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";

import { useCurrentUser } from "~/hooks/employee";
import { useConvertInputToActionForUpdate } from "~/pages/scenario/Main/editMode/components/ActionPopovers/common/useConvertInputToActionForUpdate";
import { useCreateActionFromInput } from "~/pages/scenario/Main/editMode/components/ActionPopovers/common/useCreateActionFromInput";
import { ValidInputState } from "~/pages/scenario/Main/editMode/components/ActionPopovers/inputStateSchema";
import { TriggerSettingsFormInputValue } from "~/pages/scenario/Main/editMode/components/PopoverOfApplyTriggerSettings";

const generateDefaultTrigger = ({
  tenantId,
  spaceId,
  recruitmentStatusId,
}: {
  tenantId: string;
  spaceId: string;
  recruitmentStatusId: string;
}) => {
  return Trigger.create({
    tenantId,
    spaceId,
    recruitmentStatusId,
  });
};

type InputState = z.infer<typeof postScenariosBlockSchema>;
export const useAddTriggerAndActionForm = ({
  recruitmentStatusId,
  spaceId,
  applyToAddBlock,
  onCancel,
}: {
  recruitmentStatusId: string;
  spaceId: string;
  applyToAddBlock: (inputValue: InputState) => void;
  onCancel: () => void;
}) => {
  const { currentUser } = useCurrentUser();
  const form = useForm<InputState>({
    defaultValues: {
      trigger: generateDefaultTrigger({
        tenantId: currentUser.tenantId,
        spaceId,
        recruitmentStatusId,
      }),
      actions: [],
    },
    resolver: zodResolver(postScenariosBlockSchema),
  });
  const triggerSetting = form.watch("triggerSetting");

  const { createActionFromInput } = useCreateActionFromInput();

  const { convertInputToActionForUpdate } = useConvertInputToActionForUpdate();

  const addTriggerSetting = useCallback(
    (inputValue: TriggerSettingsFormInputValue) => {
      const anyTriggerSetting = instantiateFromAnyTriggerSettingExcludeMethods({
        ...inputValue,
        id: v4(),
        tenantId: currentUser.tenantId,
        spaceId,
        createdAt: new Date(),
        updatedAt: new Date(),
        triggerId: form.watch("trigger.id"),
      });

      form.setValue("triggerSetting", anyTriggerSetting, { shouldValidate: true });
    },
    [currentUser.tenantId, form, spaceId]
  );

  const updateTriggerSetting = useCallback(
    (inputValue: TriggerSettingsFormInputValue) => {
      const anyTriggerSetting = instantiateFromAnyTriggerSettingExcludeMethods({
        ...triggerSetting,
        ...inputValue,
        updatedAt: new Date(),
      });

      form.setValue("triggerSetting", anyTriggerSetting, { shouldValidate: true });
      form.trigger("actions");
    },
    [triggerSetting, form]
  );

  const addAction = useCallback(
    (inputValue: ValidInputState) => {
      const currentActions = form.getValues("actions");
      form.setValue(
        "actions",
        [
          ...currentActions,
          createActionFromInput({
            validInputValue: inputValue,
            triggerSetting,
          }),
        ],
        {
          shouldValidate: true,
        }
      );
    },
    [createActionFromInput, form, triggerSetting]
  );

  const updateAction = useCallback(
    (
      inputValue: ValidInputState,
      existingActionData: {
        action: Action;
        actionSetting: AnyActionSetting;
        actionCondition?: AnyActionCondition;
      }
    ) => {
      const currentActions = form.getValues("actions");
      const targetActionIndex = currentActions.findIndex(
        ({ action }) => action.id === existingActionData.action.id
      );
      if (targetActionIndex === -1) {
        return;
      }

      form.setValue(
        `actions.${targetActionIndex}`,
        convertInputToActionForUpdate({
          validInputValue: inputValue,
          triggerSetting,
          existingActionData,
        }),
        {
          shouldValidate: true,
        }
      );
    },
    [convertInputToActionForUpdate, form, triggerSetting]
  );

  const deleteAction = useCallback(
    (actionId: string) => {
      const currentActions = form.getValues("actions");
      const targetActionIndex = currentActions.findIndex(({ action }) => action.id === actionId);
      if (targetActionIndex === -1) {
        return;
      }
      form.setValue(
        `actions`,
        currentActions.filter(({ action }) => action.id !== actionId),
        {
          shouldValidate: true,
        }
      );
    },
    [form]
  );

  const handleClickCancel = useCallback(() => {
    form.reset();
    // NOTE: トリガーのIDが同一のものになってしまうためトリガーはresetだけでなく都度生成して設定する
    form.setValue(
      "trigger",
      generateDefaultTrigger({ tenantId: currentUser.tenantId, spaceId, recruitmentStatusId })
    );
    onCancel();
  }, [currentUser.tenantId, form, onCancel, recruitmentStatusId, spaceId]);

  const handleSubmit = form.handleSubmit(async (inputValue) => {
    applyToAddBlock(inputValue);
    handleClickCancel();
  });

  const isSubmitButtonDisabled = form.formState.isSubmitting || !form.formState.isValid;

  return {
    form,
    triggerSetting,
    addTriggerSetting,
    updateTriggerSetting,
    addAction,
    updateAction,
    deleteAction,
    isSubmitButtonDisabled,
    handleSubmit,
    handleClickCancel,
  };
};
