import { Employee, NewGraduateToDisplay } from "@onn/common";
import { useContext } from "react";

import { AuthorizationContext } from "~/components/providers";

type GuardedResult = {
  authorizedUsers: NonEmptyArray<Employee | NewGraduateToDisplay>;
  mutateAuthorizedUsers: () => Promise<void>;
};

type NonGuardedResult = {
  authorizedUsers: (Employee | NewGraduateToDisplay)[] | null;
  mutateAuthorizedUsers: () => Promise<void>;
};

export const useAuthorization = (): GuardedResult => {
  const { authorizedUsers, mutateAuthorizedUsers, guardAuthorizedUsers } =
    useContext(AuthorizationContext);

  if (authorizedUsers === undefined) {
    throw new Error("AuthorizationProvider の子コンポーネントでのみ使用できます");
  }

  if (!guardAuthorizedUsers(authorizedUsers)) {
    throw new Error("AuthorizationGuard の子コンポーネントでのみ使用できます");
  }

  return { authorizedUsers, mutateAuthorizedUsers };
};

export const useAuthorizationNonGuarded = (): NonGuardedResult => {
  const { authorizedUsers, mutateAuthorizedUsers } = useContext(AuthorizationContext);

  if (authorizedUsers === undefined) {
    throw new Error("AuthorizationProvider の子コンポーネントでのみ使用できます");
  }

  return { authorizedUsers, mutateAuthorizedUsers };
};
