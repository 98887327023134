import { z } from "zod";

import { ActionSettingTypeSchema } from "../inputTypeSchemas/ActionSettingTypeSchema";

/////////////////////////////////////////
// ACTION SETTINGS SCHEMA
/////////////////////////////////////////

export const ActionSettingsSchema = z.object({
  type: ActionSettingTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  actionId: z.string(),
  recruitmentStatusId: z.string().nullish(),
  onnEventId: z.string().nullish(),
  onnTaskId: z.string().nullish(),
  text: z.string().nullish(),
  filePaths: z.string().array(),
});

export type ActionSettings = z.infer<typeof ActionSettingsSchema>;

/////////////////////////////////////////
// ACTION SETTINGS OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ActionSettingsOptionalDefaultsSchema = ActionSettingsSchema.merge(z.object({}));

export type ActionSettingsOptionalDefaults = z.infer<typeof ActionSettingsOptionalDefaultsSchema>;

export default ActionSettingsSchema;
