import { OnnTask } from "@onn/common";
import React, { FC, useMemo } from "react";

import { useEditModeContext } from "../../EditModeContextProvider";

import { SelectForm } from "~/components/uiParts";

export const SelectorOfOnnTask: FC<{
  value?: string;
  onChange: (value: OnnTask["id"]) => void;
  errorMessage?: string;
}> = ({ onChange, value, errorMessage }) => {
  const { availableOnnTasks } = useEditModeContext();

  const menuItems = useMemo(
    () =>
      availableOnnTasks.map((onnTask) => ({
        value: onnTask.id,
        name: onnTask.title,
      })),
    [availableOnnTasks]
  );

  return (
    <SelectForm
      selected={value || null}
      menuItems={menuItems}
      onChange={(task) => {
        onChange(task.target.value as string);
      }}
      errorText={errorMessage}
    />
  );
};
