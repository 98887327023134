import { useFormContext } from "react-hook-form";

import { useRichMenuTabContext } from "../RichMenuTabContext";

import { InputState } from "../useForm";

import { useGenerateParamsOfEditPortalRichMenu } from "./useGenerateParamsOfEditPortalRichMenu";

import { useGenerateParamsOfEditRegistrationRichMenu } from "./useGenerateParamsOfEditRegistrationRichMenu";

import { useEditPortalRichMenu } from "~/hooks/richMenu/useEditPortalRichMenu";
import { useEditRegistrationRichMenu } from "~/hooks/richMenu/useEditRegistrationRichMenu";
import { useSnackbar } from "~/hooks/shared";

export const useSubmit = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const form = useFormContext<InputState>();
  const richMenuTabContext = useRichMenuTabContext();
  const { type } = richMenuTabContext;
  const { enqueueSnackbar } = useSnackbar();
  const { editPortalRichMenu } = useEditPortalRichMenu();
  const { editRegistrationRichMenu } = useEditRegistrationRichMenu();
  const { generateParamsOfEditPortalRichMenu } = useGenerateParamsOfEditPortalRichMenu();
  const { generateParamsOfEditRegistrationRichMenu } =
    useGenerateParamsOfEditRegistrationRichMenu();

  const submit = form.handleSubmit(
    async () => {
      try {
        if (type === "portalRichMenu") {
          await editPortalRichMenu(generateParamsOfEditPortalRichMenu());
        } else if (type === "registrationRichMenu") {
          await editRegistrationRichMenu({
            scenarioId: richMenuTabContext.scenarioId,
            ...generateParamsOfEditRegistrationRichMenu(),
          });
        } else {
          const _exhaustiveCheck: never = type;
        }
        enqueueSnackbar(
          "リッチメニューの更新を受け付けました。反映されるまでにしばらく時間がかかる場合があります。",
          { variant: "success" }
        );
        closeDrawer();
        // NOTE: 実際にリッチメニューが更新された時にWebsiteNoticeを使ってsnackbarを表示する
      } catch (error) {
        const e = error as Error;
        enqueueSnackbar(e.message, { variant: "error" });
      }
    },
    () => {
      form.trigger();
    }
  );

  return { submit };
};
