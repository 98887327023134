import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon, TooltipWhenTextTruncated, Typography } from "~/components/uiParts";

type Props = {
  title: string;
  scheduledDate: Date | null;
  deadlineDate: Date | null;
};

// タイトルを表示するセル
export const TitleCell: FC<Props> = ({ title, scheduledDate, deadlineDate }) => {
  const deadlineDateStr = (() => {
    if (!scheduledDate || !deadlineDate) return "回答期限：未設定";
    return `回答期限：${format(scheduledDate, "yyyy/MM/dd")}〜${format(
      deadlineDate,
      "yyyy/MM/dd"
    )}`;
  })();

  const titleText = `${title}`;
  return (
    <Box display="flex" alignItems="center" gridGap="16px">
      <Icon icon="form" color="lightGrey" size="md" />
      <Box display="flex" flexDirection="column" gridRowGap="4px" maxWidth="350px">
        <TooltipWhenTextTruncated text={titleText}>
          {(ref) => (
            <StyledTitle ref={ref} variant="body1">
              {titleText}
            </StyledTitle>
          )}
        </TooltipWhenTextTruncated>
        <StyledDeadlineDate>{deadlineDateStr}</StyledDeadlineDate>
      </Box>
    </Box>
  );
};

const StyledTitle = styled(Typography)`
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;

const StyledDeadlineDate = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.muted};
`;
