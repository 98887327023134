import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { IconButton, Typography } from "~/components/uiParts";
import { useClipboard } from "~/hooks/shared";

type Props = {
  title: string;
  values: {
    subtitle?: string;
    value?: string;
  }[];
};

export const SectionPart: FC<Props> = ({ title, values }) => {
  const { handleCopy } = useClipboard();
  const onClickCopy = (value?: string) => {
    if (!value) return;
    handleCopy(value, title);
  };

  return (
    <Box>
      <StyledTitle variant="body2">{title}</StyledTitle>
      {values.map((v, index) => (
        <StyledBox display="flex" alignItems="center" mt={1} key={index}>
          {v.subtitle && <StyledSubTitle variant="body1">{v.subtitle}</StyledSubTitle>}
          {v.value ? (
            <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
              <StyledTypography variant="body1">{v.value}</StyledTypography>
              <Box className="button-wrapper">
                <IconButton icon="copy" size="md" onClick={() => onClickCopy(v.value)} />
              </Box>
            </Box>
          ) : (
            <StyledUnRegisteredTypography variant="body1">未登録</StyledUnRegisteredTypography>
          )}
        </StyledBox>
      ))}
    </Box>
  );
};

const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 8px;
    color: ${(props) => props.theme.palette.grey[300]};
  }
`;

const StyledBox = styled(Box)`
  .button-wrapper {
    visibility: hidden;
  }
  :hover {
    .button-wrapper {
      visibility: visible;
    }
  }
`;

const StyledSubTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-right: 24px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    word-break: break-word;
  }
`;

const StyledUnRegisteredTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 8px;
    margin-bottom: 8px;
    color: ${(props) => props.theme.palette.grey[200]};
  }
`;
