import { Box } from "@material-ui/core";
import React, { CSSProperties, FC, memo } from "react";

import { Typography } from "../Typography";

import theme from "~/config/theme";

type Props = {
  color: "primary" | "secondary";
  size: "sm" | "md";
  text: string;
  style?: CSSProperties;
};

const borderColorMap = {
  primary: theme.palette.primary.main,
  secondary: theme.palette.secondary.main,
};

const heightMap = {
  sm: 24,
  md: 32,
};

const paddingMap = {
  sm: "0px 8px",
  md: "0px 16px",
};

export const Tag: FC<Props> = memo(({ color, size, text, style }) => {
  return (
    <Box
      borderRadius={5}
      p={paddingMap[size]}
      border={`1px solid ${borderColorMap[color]}`}
      bgcolor="white"
      style={style}
      display="flex"
      alignItems="center"
      height={heightMap[size]}
    >
      <Typography color={color} variant={size ? "overline" : "body2"} bold>
        {text}
      </Typography>
    </Box>
  );
});
