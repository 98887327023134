import { IRecruitmentProcessRecordSchema } from "./schema";

export const progressStatusLabelMap: Record<
  Exclude<IRecruitmentProcessRecordSchema["progressStatus"], undefined>,
  string
> = {
  PASSED: "合格",
  WITHDREW: "辞退",
  REJECTED: "不採用",
  CANCELED: "取消し",
} as const;
