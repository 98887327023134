import { z } from "zod";

import { LineUserSchema } from "../../_gen/zodSchema/index";

export const lineUserSchema = LineUserSchema.extend({
  language: z.string().optional(),
  pictureUrl: z.string().optional(),
  statusMessage: z.string().optional(),
  createdAt: z.date().optional(),
  lastUnfollowedAt: z.date().optional(),
  internalRichMenuId: z.string().optional(),
});
