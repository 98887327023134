import { Box } from "@material-ui/core";
import { Announcement, AnnouncementDistribution } from "@onn/common";
import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

import { Button, Linkify, Loading, Typography } from "~/components/uiParts";
import { FileAttachedButtonForStorage } from "~/components/uiParts/FileAttachedButton";
import { useAnnouncementForPortal } from "~/hooks/announcement/useAnnouncementForPortal";
import { useAnnouncementsForPortal } from "~/hooks/announcement/useAnnouncementsForPortal";
import { useReadAnnouncementDistribution } from "~/hooks/announcementDistribution";
import { useMetaDataByUrls } from "~/hooks/file";

import { useQuery } from "~/hooks/shared";
import { useEffectForCreationActiveLog } from "~/hooks/shared/useEffectForCreationActiveLog";

import { isImage, mixin } from "~/util";

export const AnnouncementDetail = (): JSX.Element => {
  const { id: announcementId } = useParams<"id">();
  const { query } = useQuery();
  const isPreview = query.get("preview") === "true";

  const { data: announcementData, isLoading: isLoadingAnnouncement } = useAnnouncementForPortal({
    announcementId,
    isPreview,
  });
  const { data: announcementsData, isLoading: isLoadingAnnouncementsData } =
    useAnnouncementsForPortal();

  useEffectForCreationActiveLog({
    type: "VISITED_ANNOUNCEMENT_PAGE",
    targetId: announcementId as string, // 必ず存在するため型キャストしている
  });

  if (isLoadingAnnouncement || isLoadingAnnouncementsData) {
    return <Loading size="large" fullHeight />;
  }

  if (
    !announcementData?.announcement ||
    (!isPreview &&
      (announcementData.announcement.isWaitingPublication() ||
        announcementData.announcement.isFinishedPublication()))
  ) {
    const isOtherAnnouncementExist =
      announcementsData?.announcements.some((announcement) => announcement.id !== announcementId) ??
      false;
    return <NotFoundOrPublicationEnded isOtherAnnouncementExist={isOtherAnnouncementExist} />;
  }

  return (
    <PageCore
      announcement={announcementData.announcement}
      announcementDistribution={announcementData.announcementDistribution}
      isPreview={isPreview}
      fileSignedUrlMapObj={announcementData?.meta?.fileSignedUrlMapObj ?? {}}
    />
  );
};

type Props = {
  announcement: Announcement;
  announcementDistribution: AnnouncementDistribution | null;
  isPreview: boolean;
  fileSignedUrlMapObj: Record<string, string>;
};

const PageCore = ({
  announcement,
  announcementDistribution,
  isPreview,
  fileSignedUrlMapObj,
}: Props) => {
  const navigate = useNavigate();
  const { data: imageFiles = [], isLoading } = useMetaDataByUrls(
    announcement.filePaths.filter(isImage)
  );

  const { execReadAnnouncementDistribution } = useReadAnnouncementDistribution();

  useEffect(() => {
    if (!isPreview && announcementDistribution) {
      execReadAnnouncementDistribution(announcementDistribution.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  return (
    <StyledBox>
      <Typography variant="h3" color="textPrimary" bold>
        {announcement.title}
      </Typography>
      <StyledCard>
        <Box>
          <Typography variant="body2" color="textPrimary">
            <Linkify>{announcement.content}</Linkify>
          </Typography>
        </Box>
        {announcement.filePaths.length > 0 && (
          <Box mt="24px" display="flex" flexDirection="column" gridGap="16px">
            {imageFiles.map((file) => (
              <Box key={file.url}>
                <img src={file.url} alt={file.name} width={"100%"} />
              </Box>
            ))}
            {announcement.filePaths.map((filePath) =>
              isImage(filePath) ? (
                <></>
              ) : (
                <Box key={filePath}>
                  <FileAttachedButtonForStorage
                    filePath={filePath}
                    signedUrl={fileSignedUrlMapObj[filePath]}
                  />
                </Box>
              )
            )}
          </Box>
        )}
      </StyledCard>
      <StyledButtonContainer width="100%">
        <Button
          color="primary"
          fullWidth
          variant="text"
          borderRadius="regular"
          onClick={isPreview ? () => window.close() : () => navigate("/portal")}
        >
          {isPreview ? "プレビューを終了" : "TOPへ戻る"}
        </Button>
      </StyledButtonContainer>
    </StyledBox>
  );
};

const NotFoundOrPublicationEnded = ({
  isOtherAnnouncementExist,
}: {
  isOtherAnnouncementExist: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <StyledBox>
      <Typography variant="h2" color="textPrimary">
        お知らせ
      </Typography>
      <StyledCard
        mb="16px"
        padding="80px 40px"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box mb="40px">
          <Typography variant="body1" color="textPrimary">
            このお知らせは存在しないか、公開期間が終了しています。
          </Typography>
        </Box>
        {isOtherAnnouncementExist && (
          <StyledButtonContainer>
            <Button
              variant="outlined"
              color="default"
              borderRadius="circle"
              onClick={() => navigate("/portal/announcements")}
            >
              他のお知らせを見る
            </Button>
          </StyledButtonContainer>
        )}
      </StyledCard>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="caption" color="textSecondary" align="center">
          ブラウザを閉じて終了してください
        </Typography>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 40px 24px;
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 24px;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
