import React, { FC } from "react";

export const Triangle: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g clipPath="url(#clip0_15_1128)">
        <path
          d="M24 17.2405L14.964 32.7305H33.036L24 17.2405ZM24 11.2715C24.35 11.2696 24.6942 11.3604 24.9978 11.5346C25.3013 11.7088 25.5532 11.9603 25.728 12.2635L38.245 33.7225C38.4224 34.0265 38.5164 34.3718 38.5176 34.7237C38.5188 35.0757 38.4271 35.4216 38.2518 35.7268C38.0765 36.0319 37.8238 36.2854 37.5192 36.4617C37.2147 36.6379 36.8689 36.7306 36.517 36.7305H11.482C11.1301 36.7306 10.7844 36.6379 10.4798 36.4617C10.1752 36.2854 9.92253 36.0319 9.74723 35.7268C9.57194 35.4216 9.48026 35.0757 9.48146 34.7237C9.48265 34.3718 9.57668 34.0265 9.75404 33.7225L22.272 12.2635C22.4467 11.9602 22.6986 11.7085 23.0021 11.534C23.3057 11.3596 23.65 11.2687 24 11.2705V11.2715Z"
          stroke="none"
        />
      </g>
    </svg>
  );
};
