import React, { FC } from "react";

import { BodyRowData } from "../const";

import { CellItem, Checkbox } from "~/components/uiParts";

const CheckBoxCell: FC<{
  rowData: BodyRowData["checkBox"];
  style: React.CSSProperties;
}> = ({ style, rowData: { onClick, isChecked, slotId } }) => {
  return (
    <CellItem style={{ ...style }} isShowTopBorder isShowRightBorder={false}>
      <Checkbox checked={isChecked} onChange={() => onClick(slotId)} />
    </CellItem>
  );
};

export const CheckBoxCellMemo = React.memo(CheckBoxCell);
