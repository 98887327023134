import { Box } from "@material-ui/core";
import { TextQuestion } from "@onn/common";
import React, { memo } from "react";
import styled from "styled-components";

import { QuestionPartBase } from "./QuestionPartBase";

import { Checkbox, Typography } from "~/components/uiParts";

export const TextQuestionPart = memo(({ question }: { question: TextQuestion }) => {
  return (
    <QuestionPartBase questionTitle={question.title} isRequired={question.isRequired}>
      <OnnTaskDetailBox>※回答者は自由記述式での回答を行うことができます</OnnTaskDetailBox>
      {question.isWordLimit && (
        <Box display="flex" alignItems="center">
          <StyledCursorNotAllowedBox>
            <StyledCheckBox checked disabled />
          </StyledCursorNotAllowedBox>
          <Box mr="16px">
            <Typography variant="caption" display="inline">
              文字数を制限する
            </Typography>
            <StyledWordLimit variant="caption" display="inline" color="textSecondary">
              {`(${question.wordLimit}文字)`}
            </StyledWordLimit>
          </Box>
        </Box>
      )}
    </QuestionPartBase>
  );
});

const OnnTaskDetailBox = styled(Box)`
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid;
  color: ${(props) => props.theme.palette.grey[200]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
  white-space: pre-wrap;
`;

const StyledCheckBox = styled(Checkbox)`
  &.MuiIconButton-root {
    padding: 0;
    margin-right: 8px;
  }
`;

const StyledCursorNotAllowedBox = styled(Box)`
  &.MuiBox-root {
    cursor: not-allowed;
  }
`;

const StyledWordLimit = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 8px;
  }
`;
