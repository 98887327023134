import { Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { EscapedCSVLink } from "~/components/shared/EscapedCSVLink";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  onClickNextButton: () => void;
  downLoadCsvButtons: ReactNode;
};

export const DownloadSampleFileStepContent: FC<Props> = ({
  onClickNextButton,
  downLoadCsvButtons,
}) => {
  return (
    <>
      <Typography>
        最新のサンプルファイルをダウンロードして、必要事項を記入したアップロード用ファイルをご準備ください。
      </Typography>
      {downLoadCsvButtons}
      <StyledContainerBox p={2} mt={4} mb={5}>
        <Typography bold={true}>アップロード用ファイル作成に関するヒント</Typography>
        <Typography>
          フォーマットとなるサンプルファイルは内容が更新される可能性があります。毎回ダウンロードし直してご利用ください。
          <br />
          指定のある項目は設定値ファイルからコピー&ペーストして入力を行なってください。
        </Typography>
      </StyledContainerBox>
      <Box display="flex" justifyContent="center">
        <Box width="240px">
          <Button
            fullWidth={true}
            onClick={onClickNextButton}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
          >
            次へ
          </Button>
        </Box>
      </Box>
    </>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledCSVLink = styled(EscapedCSVLink)`
  height: 48px;
  text-decoration: none;
`;

type EmbedCsvDataButtonProps = {
  buttonText: string;
  csvData: string[][];
  filename: string;
  isLoading?: boolean;
};

export const EmbedCsvDataButton: FC<EmbedCsvDataButtonProps> = ({
  buttonText,
  csvData,
  filename,
  isLoading,
}) => {
  return (
    <StyledCSVLink data={csvData} filename={filename}>
      <Button
        startIcon={<Icon icon="download" size="md" color="primary" />}
        color={"primary"}
        borderRadius={"regular"}
        variant={"outlined"}
        fullHeight
        isLoading={isLoading}
        disabled={isLoading}
      >
        {buttonText}
      </Button>
    </StyledCSVLink>
  );
};

type DownloadCsvDataButtonProps = {
  buttonText: string;
  isLoading?: boolean;
  onClick: () => void;
};

export const DownloadCsvDataButton: FC<DownloadCsvDataButtonProps> = ({
  buttonText,
  isLoading,
  onClick,
}) => {
  return (
    <Box height={"48px"} key={"2"}>
      <Button
        startIcon={<Icon icon="download" size="md" color="primary" />}
        color={"primary"}
        borderRadius={"regular"}
        variant={"outlined"}
        fullHeight
        isLoading={isLoading}
        disabled={isLoading}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Box>
  );
};
