import { z } from "zod";

/////////////////////////////////////////
// RECRUITMENT STATUS RELATION SCHEMA
/////////////////////////////////////////

export const RecruitmentStatusRelationSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  recruitmentStatusId: z.string(),
  employeeId: z.string(),
  isActive: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RecruitmentStatusRelation = z.infer<typeof RecruitmentStatusRelationSchema>;

/////////////////////////////////////////
// RECRUITMENT STATUS RELATION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const RecruitmentStatusRelationOptionalDefaultsSchema =
  RecruitmentStatusRelationSchema.merge(
    z.object({
      isActive: z.boolean().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type RecruitmentStatusRelationOptionalDefaults = z.infer<
  typeof RecruitmentStatusRelationOptionalDefaultsSchema
>;

export default RecruitmentStatusRelationSchema;
