import { Box, Menu, MenuItem } from "@material-ui/core";
import { BriefingSessionCategory } from "@onn/common";
import React, { FC, useState } from "react";

import { useController, useFormContext } from "react-hook-form";

import { InputState } from "../hooks/useForm";

import { ClosableOnPaper } from "./ClosableOnPaper";

import { Button, Icon, TooltipWhenTextTruncated, Typography } from "~/components/uiParts";
import { Tag } from "~/components/uiParts/Tag";

export const BriefingSessionCategorySelector: FC<{
  selectableCategories: BriefingSessionCategory[];
}> = ({ selectableCategories }) => {
  const { control, setValue, clearErrors } = useFormContext<InputState>();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "briefingSessionCategoryId" });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isError = error !== undefined;
  const selectedCategory = selectableCategories.find((category) => category.id === value);

  return (
    <Box display="flex" flexDirection="column" gridRowGap={24}>
      <Box display="flex" alignItems="center" gridColumnGap={8}>
        <Typography variant="body1" color="textPrimary" bold>
          説明会を選択
        </Typography>
        <Tag color="secondary" size="sm" text="必須" />
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        <Button
          color={isError ? "secondary" : "default"}
          variant="outlined"
          borderRadius="regular"
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}
          endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
        >
          <Box
            width={"100%"}
            style={{
              textAlign: "left",
            }}
          >
            <TooltipWhenTextTruncated text={selectedCategory?.title || "対象を選択"}>
              {(ref) => (
                <>
                  <Typography
                    variant="body2"
                    noWrap
                    ref={ref}
                    style={{
                      minWidth: 0,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {selectedCategory?.title || "説明会を選択"}
                  </Typography>
                </>
              )}
            </TooltipWhenTextTruncated>
          </Box>
        </Button>
        {isError && (
          <Typography variant="caption" color="secondary">
            {error.message}
          </Typography>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={() => setAnchorEl(null)}
          getContentAnchorEl={null}
          MenuListProps={{
            // NOTE: デフォルトだと短くなってしまうためアンカー要素と幅を同じにする
            style: { width: anchorEl?.clientWidth },
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {selectableCategories.map((category) => (
            <MenuItem
              key={category.id}
              onClick={() => {
                // type(online,offline)は選択されたカテゴリによって変わるためここではリセットしない
                setValue("eventPlaceId", null);
                setValue("selectedDate", null);
                setValue("onnEventSlotDateId", null);
                clearErrors();
                onChange(category.id);
              }}
            >
              <Typography variant="body2">{category.title}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {selectedCategory && (
        <ClosableOnPaper>
          <Typography>{selectedCategory.description}</Typography>
        </ClosableOnPaper>
      )}
    </Box>
  );
};
