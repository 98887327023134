import { Box } from "@material-ui/core";

import { OnnFormTaskAnswer, OnnTask } from "@onn/common";
import React, { FC, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { ActionFooter } from "./components/ActionFooter";
import { usePage } from "./hooks/usePage";

import { AddEmployeeToDeliverTabsPaper } from "~/components/domains/employees";
import { ScheduleDeliverySettingsPaper } from "~/components/domains/employees/ScheduleDeliverySettingsPaper";
import { Icon, Loading, Tooltip, Typography } from "~/components/uiParts";

import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";
import { useOnnTask } from "~/hooks/onnTask";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { NotFound } from "~/pages/NotFound";

/**
 * ページに必要なデータ取得層
 */
export const Page: FC = () => {
  const params = useParams();
  const onnTaskId = params.id;
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from_page");
  const { switchSpaceTemporary } = useCurrentSpace();

  const {
    data: onnTask,
    isLoading: isLoadingOnnTask,
    mutate: mutateOnnEvent,
  } = useOnnTask({ onnTaskId });
  const {
    data: onnFormTaskAnswers,
    isLoading: isLoadingOnnFormTaskAnswers,
    mutate: mutateOnnEventAnswers,
  } = useOnnFormTasksAnswersOnlyCountable({ onnTaskId });

  useEffect(() => {
    if (!onnTask) return;
    switchSpaceTemporary(onnTask.spaceId);
  }, [switchSpaceTemporary, onnTask]);

  if (isLoadingOnnTask || isLoadingOnnFormTaskAnswers) return <Loading size="large" fullHeight />;
  if (!onnTask || !onnFormTaskAnswers) {
    return <NotFound />;
  }

  return (
    <PageCore
      onnTask={onnTask}
      onnFormTaskAnswers={onnFormTaskAnswers}
      fromPage={fromPage || ""}
      mutateOnnTask={mutateOnnEvent}
      mutateOnnFormTaskAnswer={mutateOnnEventAnswers}
    />
  );
};

type Props = {
  onnTask: OnnTask;
  onnFormTaskAnswers: OnnFormTaskAnswer[];
  fromPage: string;
  mutateOnnTask: (onnTask: OnnTask) => Promise<OnnTask | null | undefined>;
  mutateOnnFormTaskAnswer: (
    onnEventAnswers: OnnFormTaskAnswer[]
  ) => Promise<OnnFormTaskAnswer[] | undefined>;
};

const PageCore: FC<Props> = ({
  onnTask: _onnTask,
  onnFormTaskAnswers: _onnFormTaskAnswers,
  fromPage: _fromPage,
}) => {
  const {
    isAlreadyDelivered,
    fromPage,
    onnTask,
    deadlineDate,
    scheduledDate,
    canAnswerAfterDeadline,
    updateDeadlineDate,
    updateScheduledDate,
    updateCanAnswerAfterDeadline,
    scheduledDateInput,
    deadlineDateInput,

    newSelectedNewGraduates,
    keptNewGraduateIds,
    deleteFromNewSelectedNewGraduates,
    deleteFromRegisteredNewGraduates,

    onClickAddNewGraduateToOnnEventButton,
    onClickGoBack,
    onClickCancel,
    onClickConfirmSave,
    onClickUploadCSVButton,
    isDisabledSaveButton,
    isLoadingSaveButton,

    cancelButtonText,
    submitButtonText,

    isShowGoBackButton,
  } = usePage({
    onnTask: _onnTask,
    onnFormTaskAnswers: _onnFormTaskAnswers,
    fromPage: _fromPage,
  });

  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth="800px">
        <Box mb="24px">
          <Typography variant="h4" bold>
            配信設定
          </Typography>
        </Box>
        <Box mb="40px" display={"flex"} alignItems={"center"} gridGap={"8px"}>
          <Typography variant="body1" color="textPrimary">
            「{onnTask.title}」の配信対象を追加してください
          </Typography>
          <Tooltip
            title="配信時に配信可能な状態になっていない対象者には配信されません。"
            placement="right-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box mb="40px">
          <ScheduleDeliverySettingsPaper
            // イベントでも使うコンポーネントのためnullでは渡せない
            fixedScheduledDate={isAlreadyDelivered ? onnTask.scheduledDate || undefined : undefined}
            scheduledDateInput={scheduledDateInput}
            deadlineDateInput={deadlineDateInput}
            canAnswerAfterDeadline={canAnswerAfterDeadline}
            updateCanAnswerAfterDeadline={updateCanAnswerAfterDeadline}
            scheduledDate={scheduledDate}
            deadlineDate={deadlineDate}
            updateScheduledDate={updateScheduledDate}
            updateDeadlineDate={updateDeadlineDate}
          />
        </Box>
        <Box mb="40px">
          <AddEmployeeToDeliverTabsPaper
            isAlreadyDelivered={isAlreadyDelivered}
            onClickAddNewGraduateToButton={onClickAddNewGraduateToOnnEventButton}
            onClickUploadCSVButton={onClickUploadCSVButton}
            deleteButtonHandlers={{
              fromNewSelectedNewGraduates: deleteFromNewSelectedNewGraduates,
              fromRegisteredNewGraduates: deleteFromRegisteredNewGraduates,
            }}
            newSelectedNewGraduates={newSelectedNewGraduates}
            registeredNewGraduateIds={keptNewGraduateIds}
          />
        </Box>
        <ActionFooter
          cancelButtonText={cancelButtonText}
          submitButtonText={submitButtonText}
          fromPage={fromPage}
          onClickGoBack={onClickGoBack}
          onClickCancel={onClickCancel}
          onClickConfirmSave={onClickConfirmSave}
          isDisabledSaveButton={isDisabledSaveButton}
          isLoadingSaveButton={isLoadingSaveButton}
          isShowGoBackButton={isShowGoBackButton}
        />
      </Box>
    </Box>
  );
};
