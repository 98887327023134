import { OnnEventSlotDate } from "@onn/common";
import { useCallback } from "react";

import { useModal } from "~/hooks/modal";
import { useUpdateOnnEventSlotsDates } from "~/hooks/onnEventSlotDates/useUpdateOnnEventSlotsDates";
import { useSnackbar } from "~/hooks/shared";

export const useStatusBulkUpdateConfirmModal = ({
  onnEventId,
  onnEventSlotIds,
  onResetSelectedSlotIds,
}: {
  onnEventId: string;
  onnEventSlotIds: string[];
  onResetSelectedSlotIds: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleModal } = useModal();
  const { updateOnnEventSlotsDates } = useUpdateOnnEventSlotsDates();

  const generateOnClickAccept = useCallback(
    (toStatus: OnnEventSlotDate["status"]) => async () => {
      try {
        const onnEventSlots = await updateOnnEventSlotsDates({
          onnEventId,
          paramsToUpdate: onnEventSlotIds.map((id) => ({
            onnEventSlotId: id,
            status: toStatus,
          })),
        });
        onResetSelectedSlotIds();
        if (onnEventSlots.length > 0) {
          enqueueSnackbar(
            `予約枠${onnEventSlots.length}件のステータス「${OnnEventSlotDate.getStatusLabel(
              toStatus
            )}」に変更されました`,
            {
              variant: "success",
            }
          );
        }
      } catch {
        enqueueSnackbar("ステータスの更新に失敗しました", { variant: "error" });
      }
    },
    [enqueueSnackbar, onResetSelectedSlotIds, onnEventId, onnEventSlotIds, updateOnnEventSlotsDates]
  );

  const openStatusBulkUpdateConfirmModal = useCallback(
    (toStatus: OnnEventSlotDate["status"]) => {
      handleModal({
        name: "confirmModal",
        args: {
          title: "ステータス一括変更",
          onClickAccept: generateOnClickAccept(toStatus),
          mainContent: `ステータスを「${OnnEventSlotDate.getStatusLabel(
            toStatus
          )}」に変更してよろしいですか？\n変更を保存すると、選択中の予約枠のステータスがすべて変更されます。`,
        },
      });
    },
    [generateOnClickAccept, handleModal]
  );

  return { openStatusBulkUpdateConfirmModal };
};
