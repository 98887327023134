import { Box } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Row } from "../_share/Row";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Loading,
  TextFieldDeprecated,
  Typography,
} from "~/components/uiParts";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";

const useFilteredBySearchValue = (tags: EmployeeTag[]) => {
  const [searchValue, setSearchValue] = useState("");

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  /**
   * タグ名の絞り込み結果
   */
  const tagsFilteredBySearchValue = useMemo(() => {
    return filterObjectByPartialMatch({
      objects: tags,
      searchText: searchValue,
      getProperties: [(tag: EmployeeTag) => tag.name],
    });
  }, [filterObjectByPartialMatch, searchValue, tags]);

  return { tagsFilteredBySearchValue, searchValue, setSearchValue };
};

export const TagTabPanel = ({
  selectedTagIds,
  addSelectedTagIds,
  removeSelectedTagIds,
}: {
  selectedTagIds: string[];
  addSelectedTagIds: (tagIds: string[]) => void;
  removeSelectedTagIds: (tagIds: string[]) => void;
}) => {
  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();

  const { tagsFilteredBySearchValue, searchValue, setSearchValue } = useFilteredBySearchValue(
    employeeTagsData?.employeeTags ?? []
  );

  const [isSelectAll, setIsSelectAll] = useState(false);

  const generateRowClickHandler = useCallback(
    ({ isSelected, tagId }: { isSelected: boolean; tagId: string }) =>
      () => {
        if (isSelected) {
          removeSelectedTagIds([tagId]);
        } else {
          addSelectedTagIds([tagId]);
        }
      },
    [addSelectedTagIds, removeSelectedTagIds]
  );

  return (
    <>
      <Box marginY="24px" display="flex" justifyContent="space-between">
        <Box display={"flex"} alignItems={"center"}>
          {/* すべてを選択する */}
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={isSelectAll}
                onChange={() => {
                  if (isSelectAll) {
                    removeSelectedTagIds(tagsFilteredBySearchValue.map((tag) => tag.id));
                  } else {
                    addSelectedTagIds(tagsFilteredBySearchValue.map((tag) => tag.id));
                  }
                  setIsSelectAll(!isSelectAll);
                }}
              />
            }
            label={
              <StyledSelectAllBox display="flex">
                <Typography variant="body2" color="textSecondary">
                  すべて選択
                </Typography>
                <StyledChip color="grey" label={tagsFilteredBySearchValue.length.toString()} bold />
              </StyledSelectAllBox>
            }
          />
        </Box>

        {/* タグ名 検索フィールド */}
        <Box width="258px">
          <StyledTextField
            fullWidth
            variant={"outlined"}
            placeholder={"タグ名"}
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
            }}
            endAdornment={<IconButton icon="search" size="sm" />}
          />
        </Box>
      </Box>
      <StyledBox height={"212px"}>
        {isLoadingEmployeeTagsData ? (
          <Loading fullHeight size="large" />
        ) : tagsFilteredBySearchValue.length === 0 ? (
          <Typography variant="caption" align="center" display="block" color="textSecondary">
            結果が見つかりませんでした
          </Typography>
        ) : (
          <div>
            {tagsFilteredBySearchValue.map((tag) => {
              const isSelected = selectedTagIds.includes(tag.id);
              return (
                <Row
                  key={tag.id}
                  isSelected={isSelected}
                  label={tag.name}
                  onClick={generateRowClickHandler({ isSelected, tagId: tag.id })}
                />
              );
            })}
          </div>
        )}
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)`
  overflow-y: scroll;
`;

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

const StyledSelectAllBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

const StyledChip = styled(Chip)`
  cursor: pointer;
`;
