import { FormControlLabel } from "@mui/material";
import React, { FC } from "react";

import styled from "styled-components";

import { Checkbox, Typography } from "~/components/uiParts";

export type CheckBoxOfIsImmediateDeliveryProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const CheckBoxOfIsImmediateDelivery: FC<CheckBoxOfIsImmediateDeliveryProps> = (props) => {
  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          checked={props.value}
          onChange={() => {
            props.onChange(!props.value);
          }}
          style={{
            padding: 0,
          }}
        />
      }
      label={
        <Typography variant="body2" color="textSecondary" noWrap>
          即時配信する
        </Typography>
      }
    />
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
