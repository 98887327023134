import {
  ChangeAuthenticationLog,
  ContactMessage,
  AllContactRoom,
  SystemMessage,
  APISchema,
} from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export const generateContactMessagesKey = ({
  tenantId,
  contactRoomId,
  isWithAttachedFileSignedUrl,
}: {
  tenantId: string;
  contactRoomId: string;
  isWithAttachedFileSignedUrl?: boolean;
}) => {
  return {
    name: `get_contact_rooms_messages`,
    tenantId,
    contactRoomId,
    isWithAttachedFileSignedUrl,
  } as const;
};
type EndPoint = APISchema["/api/contact-room/get-contact-rooms-messages"]["GET"];

export const useContactMessages = ({
  tenantId,
  contactRoomId,
  isWithAttachedFileSignedUrl,
}: {
  tenantId: string;
  contactRoomId: string;
  isWithAttachedFileSignedUrl?: boolean;
}) => {
  return useSWR(
    generateContactMessagesKey({ tenantId, contactRoomId, isWithAttachedFileSignedUrl }),
    async ({ isWithAttachedFileSignedUrl }) => {
      const queryParam: EndPoint["query"] = {
        id: contactRoomId,
        "is-with-attached-file-signed-url": isWithAttachedFileSignedUrl,
      };
      const response = await apiClient.get(
        "/api/contact-room/get-contact-rooms-messages",
        queryParam
      );
      if (response.data) {
        return {
          contactRoom: response.data.contactRoom as AllContactRoom,
          messages: response.data.messages.map((message) => {
            if (message.messageType === "ChangeAuthenticationLog") {
              return new ChangeAuthenticationLog(message);
            } else if (message.messageType === "SystemMessage") {
              return SystemMessage.plainToInstance(message as SystemMessage);
            } else if (message.messageType === "ContactMessage") {
              const contactMessage = message as ContactMessage & {
                creatorName: string;
                creatorImageUrl: string | undefined;
              };
              return Object.assign(ContactMessage.plainToInstance(contactMessage), {
                creatorName: contactMessage.creatorName,
                creatorImageUrl: contactMessage.creatorImageUrl,
              });
            } else {
              return message;
            }
          }),
          meta: response.meta,
        };
      }
      return {
        contactRoom: null,
        messages: [],
        meta: {
          fileSignedUrlMapObj: {},
        },
      };
    }
  );
};
