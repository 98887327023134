import { Box, Grid } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useCallback } from "react";

import { AssigneeCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import { Choice } from "../../parts/dropdown-menus/Choice";
import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { CommonProps } from "./type";

import { Loading } from "~/components/uiParts";
import { useAcceptanceEmployees } from "~/hooks/employee";

const TARGET = "assignee";

type Props = CommonProps<AssigneeCondition>;
export const AssigneeConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: assignees, isLoading } = useAcceptanceEmployees();

  const { onChangeAssigneeIds } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
  });
  const { assigneeChoices } = useChoices({
    allAssignees: assignees,
  });

  if (isLoading) {
    return <Loading size="small" />;
  }

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target) => onChangeTarget(index, target)}
          />
        </Grid>
        <Grid item xs={9}>
          <SelectMultipleConditionDropdown
            placeHolder="担当者を選択"
            key={"assignees"}
            selectedChoices={assigneeChoices.filter((c) =>
              condition.assigneeIds?.includes(c.value)
            )}
            choices={assigneeChoices}
            withSearch
            searchPlaceholder="担当者名で検索"
            onChange={onChangeAssigneeIds}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
}: Pick<Props, "index" | "onChangeCondition" | "condition">) => {
  const onChangeAssigneeIds = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        assigneeIds: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangeAssigneeIds,
  };
};

const useChoices = ({ allAssignees }: { allAssignees: Employee[] | undefined }) => {
  if (!allAssignees) {
    return {
      assigneeChoices: [],
    };
  }

  return {
    assigneeChoices: allAssignees.flatMap((assignee) => {
      if (assignee.isOnlyInterviewer()) return [];
      return { label: assignee.getName(), value: assignee.id };
    }),
  };
};
