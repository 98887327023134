import { Announcement } from "@onn/common";
import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useInputs } from "../../shared/useInputs";

import { useGenerateFilePath } from "~/hooks/announcement";
import { useCreateAnnouncement } from "~/hooks/announcement/useCreateAnnouncement";

import { useCurrentUser } from "~/hooks/employee";
import { useOpenPortalAnnouncementPreview } from "~/hooks/openPortalPreview/useOpenPortalAnnouncementPreview";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const usePage = ({ fixedAnnouncementId }: { fixedAnnouncementId: string }) => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  const { execCreateAnnouncement, isLoading: isCreatingAnnouncement } = useCreateAnnouncement();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { titleInput, contentInput, filesInput } = useInputs({
    fixedAnnouncementId,
    tenantId: currentUser.tenantId,
  });
  const { openPortalAnnouncementPreview } = useOpenPortalAnnouncementPreview();
  const { generateFilePath } = useGenerateFilePath();

  const onClickPreview = useCallback(() => {
    let announcementForPreview;
    try {
      announcementForPreview = Announcement.create({
        id: fixedAnnouncementId,
        creatorId: currentUser.id,
        title: titleInput.title,
        content: contentInput.content,
        tenantId: currentUser.tenantId,
        spaceId: currentSpace.id,
        filePaths: filesInput.files.map((file) =>
          generateFilePath({ announcementId: fixedAnnouncementId, fileName: file.name })
        ),
      });
    } catch (_e) {
      enqueueSnackbar("入力されている値ではプレビューを開けません", { variant: "error" });
      return;
    }

    openPortalAnnouncementPreview(announcementForPreview);
  }, [
    contentInput.content,
    currentSpace.id,
    currentUser.id,
    currentUser.tenantId,
    enqueueSnackbar,
    filesInput.files,
    fixedAnnouncementId,
    generateFilePath,
    openPortalAnnouncementPreview,
    titleInput.title,
  ]);

  const onClickSaveAndToNext = useCallback(async () => {
    try {
      await execCreateAnnouncement({
        id: fixedAnnouncementId,
        title: titleInput.title,
        content: contentInput.content,
        filePaths: filesInput.files.map((file) =>
          generateFilePath({ announcementId: fixedAnnouncementId, fileName: file.name })
        ),
      });
      navigate(`/announcements/${fixedAnnouncementId}/delivery_setting?from_page=create`);
      enqueueSnackbar("お知らせを作成しました", { variant: "success" });
    } catch (_e) {
      enqueueSnackbar("お知らせの作成に失敗しました", { variant: "error" });
    }
  }, [
    fixedAnnouncementId,
    contentInput.content,
    enqueueSnackbar,
    execCreateAnnouncement,
    filesInput.files,
    generateFilePath,
    navigate,
    titleInput.title,
  ]);

  const onClickCancel = useCallback(() => {
    navigate("/tools#announcements");
  }, [navigate]);

  const isSaving = isCreatingAnnouncement;
  const isDisabledSaveButton =
    isSaving || titleInput.titleInputError.error || contentInput.contentInputError.error;

  return {
    isDisabledSaveButton,
    onClickCancel,
    onClickSaveAndToNext,
    onClickPreview,
    isSaving,
    titleInput,
    contentInput,
    filesInput,
  };
};
