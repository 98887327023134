import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-slot-dates"]["POST"];
export const useCreateOnnEventSlotDates = () => {
  const createOnnEventSlotDates = useCallback(async (arg: Endpoint["body"]) => {
    const requestBody: Endpoint["body"] = {
      onnEventId: arg.onnEventId,
      paramsToCreate: arg.paramsToCreate,
    };

    const response = await apiClient.post("/api/onn-event-slot-dates", requestBody);
    return response;
  }, []);

  return { createOnnEventSlotDates };
};
