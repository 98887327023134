import { AccessLogRepository } from "~/infrastructure/api/accessLogRepository";

const accessLogRepository = new AccessLogRepository();

export const accessLogUseCase = {
  async add(accessLog: AccessLog): Promise<void> {
    await accessLogRepository.add({
      ...accessLog,
      query: Object.fromEntries(accessLog.query),
      userAgent: window.navigator.userAgent,
    });
  },
};

/**
 * アクセスログ
 * - 作りきりで、アプリケーションでは読み取り禁止とするログのため、common/domainで管理しない
 */
type AccessLog = {
  pathname: string;
  queryString: string;
  query: URLSearchParams;
  tenantId?: string;
  isLiff: boolean;
  sessionId?: string;
  externalUserId?: string;
  employeeId?: string;
  isPortal: boolean;
};
