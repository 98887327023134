import { useState } from "react";

import { v4 } from "uuid";

import { useValidateInput } from "../_share_in_create_edit/hooks/useValidateInput";
import { State } from "../_share_in_create_edit/reducer";

import { useCreateOnnTaskPageContext } from "./context";

import { useActionFooterHandler } from "./useActionFooterHandler";

export const usePage = ({ state }: { state: State }) => {
  const { onnFormTaskId, dispatch } = useCreateOnnTaskPageContext();
  const { validateInput } = useValidateInput(onnFormTaskId);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const actionFooterHandler = useActionFooterHandler({ state, setIsSubmitting, onnFormTaskId });

  const handleAddQuestion = () => {
    dispatch({
      type: "ADD_QUESTION",
      payload: {
        questionId: v4(),
      },
    });
  };

  const isDisableSaveButton = !validateInput({ state }) || isSubmitting;

  const isLoadingSaveButton = isSubmitting;

  return {
    state,
    dispatch,
    handleAddQuestion,
    actionFooterHandler,
    isDisableSaveButton,
    isLoadingSaveButton,
    onnFormTaskId,
  };
};
