import { Box, MenuItem } from "@material-ui/core";
import { OnnEventPlace, OnnEventAnswerForDisplay, NewInterviewEvent } from "@onn/common";

import { intersectionBy } from "lodash";
import React, { FC, useCallback, useMemo } from "react";

import styled from "styled-components";

import { OnnEventAnswersMultiGrid } from "./Table/OnnEventAnswersMultiGrid";

import { useGenerateCsvData } from "./hooks/useGenerateCsvData";

import { useOnnEventAnswersSearchByConditions } from "./hooks/useSlotsSearchByConditions";

import { EscapedCSVLink } from "~/components/shared/EscapedCSVLink";
import {
  Button,
  Icon,
  IconButton,
  Loading,
  SearchByConditionsButton,
  UncontrolledMenu,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";
import { useOnnEventPlaces } from "~/hooks/onnEventPlace/useOnnEventPlaces";
import { removeUnusableCharacters } from "~/util/fileUtil";

type Props = {
  onnEvent: NewInterviewEvent;
};

export const TabPage: FC<Props> = ({ onnEvent }) => {
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();

  const { data: onnEventPlaces = [], isLoading: isLoadingOnnEventPlaces } = useOnnEventPlaces();
  const { data: onnEventAnswersForDisplay = [], isLoading: isLoadingAnswer } =
    useOnnEventAnswersForDisplay(onnEvent.id);

  const isLoading = isLoadingOnnEventPlaces || isLoadingAnswer;

  const { conditions, logicType, onSearchConfirm, searchedAnswers, onResetSearchConditions } =
    useOnnEventAnswersSearchByConditions({
      onnEventId: onnEvent.id,
      onnEventAnswersForDisplay,
    });

  const filteredAnswers = useMemo(() => {
    if (searchedAnswers === null) return onnEventAnswersForDisplay;
    return intersectionBy(searchedAnswers, onnEventAnswersForDisplay);
  }, [onnEventAnswersForDisplay, searchedAnswers]);

  const filteredAndOrderedAnswers = useMemo(() => {
    return OnnEventAnswerForDisplay.sortForDisplay(filteredAnswers);
  }, [filteredAnswers]);

  const handleOnClickSearchByConditionsButton = useCallback(() => {
    handleModal({
      name: "onnEventSlotSearchModal",
      args: {
        onnEvent,
        onSearchConfirm,
        defaultConditions: conditions,
        defaultLogicType: logicType,
        defaultSearchResultCount: searchedAnswers ? searchedAnswers.length : undefined,
        modalType: "answer",
        onnEventAnswersForDisplay,
      },
    });
  }, [
    conditions,
    handleModal,
    logicType,
    onSearchConfirm,
    onnEvent,
    onnEventAnswersForDisplay,
    searchedAnswers,
  ]);

  return (
    <Box pt="40px" display="flex" flexDirection="column" gridRowGap="32px">
      <Box display="flex" gridGap={24} alignItems="flex-end" justifyContent="space-between">
        <Box display={"flex"} gridColumnGap={"24px"} height={"40px"}>
          <SearchByConditionsButton
            onClick={handleOnClickSearchByConditionsButton}
            onReset={onResetSearchConditions}
            count={searchedAnswers ? conditions.length : 0}
          />
        </Box>
        <Box display={"flex"} gridColumnGap={"24px"}>
          {currentUser.isAdmin() && (
            <>
              <AddSlotButton onnEvent={onnEvent} />
              {isLoading ? (
                <Loading size="small" />
              ) : (
                <TableMenu
                  onnEvent={onnEvent}
                  onnEventPlaces={onnEventPlaces}
                  onnEventAnswersForDisplay={filteredAndOrderedAnswers}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      <OnnEventAnswersMultiGrid
        onnEventAnswersForDisplay={filteredAndOrderedAnswers}
        isLoading={isLoadingAnswer}
        onnEvent={onnEvent}
      />
    </Box>
  );
};

const AddSlotButton: FC<{ onnEvent: NewInterviewEvent }> = ({ onnEvent }) => {
  const { handleModal } = useModal();

  const openCreateOrEdiOnnEventSlotsModal = useCallback(() => {
    handleModal({
      name: "answerEventOnBehalfModal",
      args: {
        onnEvent,
        mode: {
          type: "create",
        },
        slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
      },
    });
  }, [handleModal, onnEvent]);

  return (
    <Button
      onClick={openCreateOrEdiOnnEventSlotsModal}
      color="default"
      variant="outlined"
      borderRadius="regular"
      startIcon={<Icon size="sm" icon="add" color="grey400" />}
    >
      代理予約
    </Button>
  );
};

const TableMenu: FC<
  Props & { onnEventPlaces: OnnEventPlace[]; onnEventAnswersForDisplay: OnnEventAnswerForDisplay[] }
> = ({ onnEvent, onnEventPlaces, onnEventAnswersForDisplay }) => {
  const { generateCsvData } = useGenerateCsvData({ onnEventPlaces });
  const { handleModal } = useModal();

  const openAnswerNewInterviewEventOnBehalfWithCSVModal = useCallback(() => {
    handleModal({
      name: "answerNewInterviewEventOnBehalfWithCSVModal",
      args: {
        onnEvent,
      },
    });
  }, [handleModal, onnEvent]);

  const filename = useMemo(
    () => `イベント回答結果_${removeUnusableCharacters(onnEvent.title)}`,
    [onnEvent.title]
  );

  const renderCustomComponent = (closeMenu: () => void) => {
    return (
      <StyledCSVLink
        key="csv_download"
        data={generateCsvData({ onnEventAnswersForDisplay })}
        filename={filename}
      >
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            closeMenu();
          }}
        >
          csvダウンロード
        </MenuItem>
      </StyledCSVLink>
    );
  };
  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={[
        {
          onClick: openAnswerNewInterviewEventOnBehalfWithCSVModal,
          text: "回答一括登録",
        },
        {
          renderCustomComponent,
        },
      ]}
    />
  );
};

const StyledCSVLink = styled(EscapedCSVLink)`
  text-decoration: none;
`;
