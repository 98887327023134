import { v4 } from "uuid";

import { IEmployeeInformationGroup, employeeInformationGroupSchema } from "./schema";

export class EmployeeInformationGroup implements IEmployeeInformationGroup {
  static validator = employeeInformationGroupSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly label: string;
  readonly order: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  constructor(init: ExcludeMethods<EmployeeInformationGroup>) {
    EmployeeInformationGroup.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.label = init.label;
    this.order = init.order;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<ExcludeMethods<EmployeeInformationGroup>, "id" | "createdAt" | "updatedAt">
  ): EmployeeInformationGroup {
    return new EmployeeInformationGroup({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  updateOrderAndLabel({ order, label }: { order: number; label: string }) {
    return new EmployeeInformationGroup({
      ...this,
      order,
      label,
      updatedAt: new Date(),
    });
  }
}
