import { ListItem } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import { ListRowProps } from "react-virtualized";
import styled from "styled-components";

import { UserIconWithLabel, Icon } from "~/components/uiParts";

type Props = ListRowProps & {
  employees: Employee[];
  selectedEmployeeIds: string[];
  onSelect: (employee: Employee) => void;
};

export const DefaultRowRenderer: FC<Props> = ({
  employees,
  selectedEmployeeIds,
  onSelect,
  ...props
}) => {
  const employee = employees[props.index] as (typeof employees)[number];

  const isSelectedAny = selectedEmployeeIds.length > 0;
  const isSelectedThis = selectedEmployeeIds.includes(employee.id);

  return (
    <StyledDiv
      key={props.key}
      style={props.style} // 動的に計算されるtopなどのプロパティが入る
      $isOpacity={!isSelectedAny || isSelectedThis} // 何も選択されていない or 自身が選択されている ときに不透明
    >
      <ListItem
        className={isSelectedThis ? "selected" : ""}
        alignItems="flex-start"
        selected={isSelectedThis}
        onClick={() => onSelect(employee)}
        data-testid={isSelectedThis ? "SlackUserList_StyledListItem_selected" : undefined}
      >
        <UserIconWithLabel
          name={employee.getName()}
          iconPath={employee.profileIconImageUrl}
          iconCircular
        />
        {isSelectedThis && <StyledIcon icon="check" size="sm" color="primary" />}
      </ListItem>
    </StyledDiv>
  );
};

const StyledDiv = styled.div<{
  /**
   * trueのとき、不透明な要素として表示する
   */
  $isOpacity: boolean;
}>`
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${(props) =>
    props.$isOpacity &&
    `
      opacity: 1;
      background-color: inherit;
      :hover,
      :focus {
        background-color: inherit;
      }
    `}
`;

const StyledIcon = styled(Icon)`
  opacity: 1;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
