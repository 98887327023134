import { ContactMessageTemplateFolder } from "@onn/common";

import { captureException } from "@sentry/react";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { useSnackbar } from "../shared";

import { Key } from "./useContactMessageTemplateFolder";

import { apiClient } from "~/libs";

export const useUpdateContactMessageTemplateFolder = ({
  contactMessageTemplateFolders,
  refetchKey,
}: {
  contactMessageTemplateFolders: ContactMessageTemplateFolder[];
  refetchKey: Key;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  /**
   *  コンタクトメッセージテンプレートフォルダを更新する
   */
  const updateContactMessageTemplateFolder = useCallback(
    async (newTemplateFolder: ContactMessageTemplateFolder) => {
      try {
        const optimisticData = contactMessageTemplateFolders.map((currentTemplateFolder) =>
          currentTemplateFolder.id === newTemplateFolder.id
            ? newTemplateFolder
            : currentTemplateFolder
        );

        await mutate(
          refetchKey,
          async () => {
            await apiClient.patch("/contact_message_api/template-folders", {
              ["name"]: newTemplateFolder.name,
              ["id"]: newTemplateFolder.id,
            });
            return mutate(refetchKey);
          },
          {
            optimisticData,
          }
        );
        enqueueSnackbar("フォルダ名を変更しました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("フォルダ名の変更に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useUpdateContactMessageTemplate:updateContactMessageTemplate",
          },
        });
      }
    },
    [contactMessageTemplateFolders, enqueueSnackbar, mutate, refetchKey]
  );

  return { updateContactMessageTemplateFolder };
};
