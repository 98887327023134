import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION TEXT TYPE FIELD SCHEMA
/////////////////////////////////////////

export const EmployeeInformationTextTypeFieldSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeInformationGroupId: z.string(),
  label: z.string().trim().min(1, { message: "ラベルが短すぎます" }),
  order: z.number(),
  sampleValue: z.string(),
  isCustom: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationTextTypeField = z.infer<
  typeof EmployeeInformationTextTypeFieldSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION TEXT TYPE FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationTextTypeFieldOptionalDefaultsSchema =
  EmployeeInformationTextTypeFieldSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationTextTypeFieldOptionalDefaults = z.infer<
  typeof EmployeeInformationTextTypeFieldOptionalDefaultsSchema
>;

export default EmployeeInformationTextTypeFieldSchema;
