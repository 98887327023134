import { Box } from "@material-ui/core";
import React, { FC, useEffect } from "react";

import styled from "styled-components";

import { Typography } from "~/components/uiParts";
import { CenterBox } from "~/components/uiParts/CenterBox";
import { CenterPaper } from "~/components/uiParts/CenterPaper";

import { captureException, mixin } from "~/util";

export const ErrorPage: FC<{
  pageType: string;
  detail?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraInfo?: any;
}> = ({ pageType, detail, extraInfo }) => {
  useEffect(() => {
    captureException({
      error: new Error(`${pageType}でのエラーページが開かれました`),
      tags: { type: "ErrorPage" },
      extras: { detail, ...extraInfo },
    });
  }, [detail, extraInfo, pageType]);

  return (
    <StyledBox>
      <CenterBox>
        <CenterPaper isGrayColorInSP={true} size="medium" showCloseAnnotation={true}>
          <StyledTitle variant="h2" bold>
            エラーが発生しました
          </StyledTitle>
          <StyledMessageBox>
            <StyledDescription variant="body2" color="textSecondary">
              システムエラーにより指定されたリンクの利用ができない状況です。ページの再読み込みやアクセスのし直しをお試しいただき、問題が解決されない場合は企業担当者の方へお問い合わせください。
            </StyledDescription>
          </StyledMessageBox>
        </CenterPaper>
      </CenterBox>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  ${(props) => mixin.breakDown.sm`
    background: ${props.theme.palette.grey[50]}`}
`;

const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 40px;

    ${(props) =>
      mixin.breakUp.sm`
        margin-bottom: 24px;
        color: ${props.theme.palette.primary.main};
        text-align: center;
  `}
  }
`;

const StyledDescription = styled(Typography)`
  &.MuiTypography-root {
    ${mixin.breakUp.sm`
        text-align: center;
  `}
  }
`;

const StyledMessageBox = styled(Box)`
  ${mixin.breakDown.sm`
    background: white;
    border-radius: 15px;
    padding: 24px;
  `}
`;
