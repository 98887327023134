import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Control, Controller, UseFormTrigger } from "react-hook-form";
import styled from "styled-components";

import { DatePickerV2, SelectForm, Typography } from "~/components/uiParts";

type ReminderForm = {
  title: string;
  remindDate: {
    remindedAt: Date | null;
    remindHour: number;
  };
};

type Props = {
  control: Control<ReminderForm, unknown>;
  trigger: UseFormTrigger<ReminderForm>;
};

export const RemindedAtForm: FC<Props> = React.memo(({ control, trigger }) => {
  const selections = Array.from({ length: 24 }, (_, index) => ({
    name: `${index}:00`,
    value: index,
  }));

  return (
    <Box mb="32px">
      <Box mb="16px">
        <Typography variant="body2" bold color="textPrimary">
          日時
        </Typography>
      </Box>
      <Box display="flex" minHeight="50px">
        <Box mr="16px" width="200px">
          <Controller
            name="remindDate.remindedAt"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledDatePicker
                fullWidth
                placeholder="日程を選択"
                disablePast={true}
                error={!!error}
                helperText={error?.message}
                {...field}
                onChange={(data) => {
                  field.onChange(data);
                  trigger("remindDate.remindHour");
                }}
              />
            )}
          />
        </Box>
        <Box width="120px">
          <Controller
            name="remindDate.remindHour"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledSelectForm
                fullWidth
                icon="clock"
                menuItems={selections}
                selected={field.value}
                errorBorder={!!error}
                errorText={error?.message}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("remindDate.remindedAt");
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
});

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 228px;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    height: 50px;
  }
`;
