import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Button, Typography } from "~/components/uiParts";

type Props = {
  canEditAnswer: boolean;
  isConfirm: boolean;
  isPreview: boolean;
  isSubmitting: boolean;
  isDisabledSubmit: boolean;
  setIsConfirm: (isConfirm: boolean) => void;
  onClickSubmit: () => void;
  isLoadingAnswerButton?: boolean;
};

export const BottomButtons: FC<Props> = ({
  canEditAnswer,
  isConfirm,
  isPreview,
  isSubmitting,
  isDisabledSubmit,
  setIsConfirm,
  onClickSubmit,
  isLoadingAnswerButton,
}) => {
  if (!canEditAnswer && !isConfirm) {
    return (
      <Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          borderRadius="circle"
          onClick={() => setIsConfirm(true)}
          disabled={isDisabledSubmit}
          isLoading={isSubmitting}
        >
          確認画面へ
        </Button>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px" textAlign="center">
      <Button
        fullWidth
        variant={isPreview ? "outlined" : "contained"}
        color={isPreview ? "default" : "primary"}
        borderRadius="circle"
        onClick={onClickSubmit}
        disabled={!isPreview && isDisabledSubmit}
        isLoading={isLoadingAnswerButton}
      >
        {isPreview ? "プレビューを終了" : "回答を送信"}
      </Button>
      {!canEditAnswer && isConfirm && (
        <>
          <Button
            fullWidth
            variant={"outlined"}
            color={isPreview ? "default" : "primary"}
            borderRadius="circle"
            onClick={() => setIsConfirm(false)}
            disabled={isSubmitting}
          >
            編集に戻る
          </Button>
          <Typography variant="caption" color="textSecondary">
            送信後は回答を編集できません
          </Typography>
        </>
      )}
    </Box>
  );
};
