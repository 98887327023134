import { useMemo } from "react";

import { useFormContext } from "react-hook-form";

import { InputState } from "./useForm";

import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventForPortal";

export const useSelectableSlotsWithNumberOfParticipants = (
  onnEventSlotDateWithNumberOfParticipants: OnnEventDataForPortal["onnEventSlotDateWithNumberOfParticipants"],
  onnEventDeterminedDate: OnnEventDataForPortal["onnEventDeterminedDate"]
) => {
  const { watch } = useFormContext<InputState>();
  const selectedBriefingSessionCategoryId = watch("briefingSessionCategoryId");
  const selectedEventType = watch("eventType");
  const selectedEventPlaceId = watch("eventPlaceId");

  const selectableSlotsWithNumberOfParticipants = useMemo(() => {
    // 説明会カテゴリーが選択されていない場合
    if (!selectedBriefingSessionCategoryId) return [];
    // イベントタイプが選択されていない場合
    if (!selectedEventType) return [];
    return onnEventSlotDateWithNumberOfParticipants
      .filter(
        (v) => v.onnEventSlotDate.briefingSessionCategoryId === selectedBriefingSessionCategoryId
      )
      .filter((v) => v.onnEventSlotDate.eventType === selectedEventType)
      .filter((v) => {
        if (!selectedEventPlaceId) return !v.onnEventSlotDate.eventPlaceId; // オンラインの時
        return v.onnEventSlotDate.eventPlaceId === selectedEventPlaceId; // オフラインの時
      })
      .filter(
        (v) =>
          v.onnEventSlotDate.id === onnEventDeterminedDate?.onnEventSlotDateId || v.canParticipate()
      );
  }, [
    onnEventDeterminedDate?.onnEventSlotDateId,
    onnEventSlotDateWithNumberOfParticipants,
    selectedBriefingSessionCategoryId,
    selectedEventPlaceId,
    selectedEventType,
  ]);

  return { selectableSlotsWithNumberOfParticipants };
};
