import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/rich_menu_api/portal-rich-menus"]["POST"];

export type EditPortalRichMenuParams = EndPoint["body"];
export const useEditPortalRichMenu = () => {
  const editPortalRichMenu = useCallback(async (params: EditPortalRichMenuParams) => {
    await apiClient.post("/rich_menu_api/portal-rich-menus", params);
  }, []);

  return { editPortalRichMenu };
};
