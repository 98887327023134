import { FC, useEffect } from "react";

export const TransitionToExternalPageInLiff: FC = () => {
  const params = new URLSearchParams(location.search);
  const href = params.get("href") || params.get("to") || "/"; // NOTE: toは古いリッチメニューのパラメータ名。新しいリッチメニューに切り替わったら削除する

  useEffect(() => {
    window.location.href = href;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
