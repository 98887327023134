import { Box } from "@material-ui/core";
import React from "react";

import { Divider } from "~/components/uiParts";

type Props = {
  conditionForm: JSX.Element;
  addConditionButton: JSX.Element;
  clearAllConditionsButton: JSX.Element;
};
export const ModalContentLayout = ({
  conditionForm,
  addConditionButton,
  clearAllConditionsButton,
}: Props): JSX.Element => {
  return (
    <>
      <Box pb={3}>
        <Divider />
      </Box>
      {conditionForm}
      <Box display={"flex"} justifyContent={"space-between"}>
        {addConditionButton}
        {clearAllConditionsButton}
      </Box>
      <Box pt={3}>
        <Divider />
      </Box>
    </>
  );
};
