import { TableRow } from "@mui/material";
import React, { FC } from "react";

import { headers } from "../../../Header/TableHeaderRow";

import { NoneCell } from "../../common/NoneCell";

import { AssigneeIconCell, AssigneeIconCellProps } from "../_share/AssigneeIconCell";

import { RelatedFilesCell } from "../_share/RelatedFilesCell";

import { OnnTaskStatusCell } from "./cells/OnnTaskStatusCell";
import { OnnTaskTitleCell } from "./cells/OnnTaskTitleCell";

import {
  RelatedInformationCell,
  RelatedInformationCellProps,
} from "./cells/RelatedInformationCell";

import { UpdatedAtCell } from "./cells/UpdatedAtCell";

export type RecruitmentProcessRecordOnnTaskItemRowProps = {
  type: "onnTask";
  onnTaskId: string;
  titleCell: {
    onnTaskTitle: string;
    href: string;
  };
  statusCell: {
    isAlreadyAnswered: boolean;
    lastReadAt?: Date;
  };
  assigneeCell: AssigneeIconCellProps;
  relatedFilesCell: {
    filePaths: string[];
  };
  relatedInformationCell: RelatedInformationCellProps;
  updatedAtCell: {
    updatedAt?: Date;
  };
};

export const RecruitmentProcessRecordOnnTaskItemRow: FC<
  RecruitmentProcessRecordOnnTaskItemRowProps
> = (props) => {
  const cells = [
    <OnnTaskTitleCell
      key={`colum-0-${props.onnTaskId}`}
      onnTaskTitle={props.titleCell.onnTaskTitle}
      href={props.titleCell.href}
    />,
    <OnnTaskStatusCell
      key={`colum-1-${props.onnTaskId}`}
      isAlreadyAnswered={props.statusCell.isAlreadyAnswered}
      lastReadAt={props.statusCell.lastReadAt}
    />,
    <NoneCell key={`colum-2-${props.onnTaskId}`} />,
    <AssigneeIconCell
      key={`colum-3-${props.onnTaskId}`}
      iconUrl={props.assigneeCell.iconUrl}
      name={props.assigneeCell.name}
      isShowNoneCell={props.assigneeCell.isShowNoneCell}
    />,
    <NoneCell key={`colum-4-${props.onnTaskId}`} />,
    <RelatedFilesCell
      key={`colum-5-${props.onnTaskId}`}
      filePaths={props.relatedFilesCell.filePaths}
    />,
    <RelatedInformationCell
      key={`colum-6-${props.onnTaskId}`}
      deadlineDate={props.relatedInformationCell.deadlineDate}
      isShowNoneCell={props.relatedInformationCell.isShowNoneCell}
    />,
    <UpdatedAtCell key={`colum-7-${props.onnTaskId}`} updatedAt={props.updatedAtCell.updatedAt} />,
  ];
  return (
    <TableRow>
      {headers.map((_, index) => {
        return cells[index];
      })}
    </TableRow>
  );
};
