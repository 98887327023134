import { ContactMessageTemplateFolder } from "@onn/common";

import { captureException } from "@sentry/react";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { useSnackbar } from "../shared";

import { Key } from "./useContactMessageTemplateFolder";

import { apiClient } from "~/libs";

export const useCreateContactMessageTemplateFolder = ({
  contactMessageTemplateFolders,
  refetchKey,
}: {
  contactMessageTemplateFolders: ContactMessageTemplateFolder[];
  refetchKey: Key;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  /**
   *  コンタクトメッセージテンプレートフォルダを作成する
   */
  const createContactMessageTemplateFolder = useCallback(
    async (templateFolder: ContactMessageTemplateFolder) => {
      try {
        const optimisticData = [...contactMessageTemplateFolders, templateFolder];

        await mutate(
          refetchKey,
          async () => {
            await apiClient.post("/contact_message_api/template-folders", {
              ["name"]: templateFolder.name,
            });
            return mutate(refetchKey);
          },
          {
            optimisticData,
          }
        );
        enqueueSnackbar("新規フォルダを作成しました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("フォルダの作成に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useCreateContactMessageTemplate:createContactMessageTemplate",
          },
        });
      }
    },
    [contactMessageTemplateFolders, enqueueSnackbar, mutate, refetchKey]
  );

  return { createContactMessageTemplateFolder };
};
