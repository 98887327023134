import { Box, Stack, styled } from "@mui/material";
import {
  AnyEmployeeInformationFieldWithOptions,
  instantiateFromAnyEmployeeInformationFieldWithOptions,
} from "@onn/common";
import React, { FC } from "react";

import { FormProvider } from "react-hook-form";

import { useEmployeeInformationFieldsForm } from "./hooks/useForm";

import {
  Button,
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
  IconButton,
  ScrollableBodyModal,
  Typography,
} from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  groupId: string;
  groupName: string;
  employeeInformationFields: AnyEmployeeInformationFieldWithOptions[];
  onClickAddFieldButton: () => void;
  onClickEditFieldButton: (
    anyEmployeeInformationField: AnyEmployeeInformationFieldWithOptions
  ) => void;
};

export const ManageEmployeeInformationFieldsModal: FC<Props> = ({
  open,
  onCancel,
  groupId,
  groupName,
  employeeInformationFields,
  onClickAddFieldButton,
  onClickEditFieldButton,
}) => {
  const { form, fields, changeOrder, handleSubmit, isSubmitButtonDisabled } =
    useEmployeeInformationFieldsForm({
      groupId,
      employeeInformationFields,
      onClose: () => onCancel(),
    });

  return (
    <ScrollableBodyModal
      open={open}
      disableBackdropModal
      title="項目編集"
      content={
        <FormProvider {...form}>
          <Stack direction="column" alignItems="flex-start" textAlign="center" spacing={3}>
            <Typography variant="body1" style={{ width: "100%" }}>
              「{groupName}」の項目を編集することができます。
              <br />
              この変更はすべての候補者詳細に反映されます。
            </Typography>
            <Stack
              direction="column"
              alignItems="flex-start"
              textAlign="center"
              width="100%"
              spacing={3}
            >
              <Stack direction="column" alignItems="flex-start" width="100%">
                <DnDProvider onDragEnd={changeOrder}>
                  <Box width="100%">
                    <DnDDroppable droppableId="droppableId" isDropDisabled={false}>
                      {fields.map((field, index) => (
                        <DnDDraggable
                          draggableId={field.id}
                          key={field.id}
                          index={index}
                          isDragDisabled={false}
                          shouldRoundCorner
                        >
                          <Stack direction="row" alignItems="center" spacing={1} width="100%">
                            <Box px={1} py={2} display="flex" justifyContent="center">
                              <Icon icon="gripVertical" size="md" color="grey400" />
                            </Box>
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              style={{ marginRight: "auto" }}
                            >
                              {field.label}
                            </Typography>
                            <Box
                              px={1}
                              py={1}
                              display="flex"
                              justifyContent="center"
                              sx={{ cursor: "default" }}
                            >
                              <StyledIconButton
                                icon="pencil"
                                size="ssm"
                                color="grey400"
                                onClick={() =>
                                  onClickEditFieldButton(
                                    instantiateFromAnyEmployeeInformationFieldWithOptions(field)
                                  )
                                }
                              />
                            </Box>
                          </Stack>
                        </DnDDraggable>
                      ))}
                    </DnDDroppable>
                  </Box>
                </DnDProvider>
              </Stack>
            </Stack>
          </Stack>
        </FormProvider>
      }
      footer={
        <Stack direction="row" justifyContent="space-between" width={"100%"}>
          <Button
            variant="outlined"
            borderRadius="regular"
            color="primary"
            onClick={onClickAddFieldButton}
          >
            項目追加
          </Button>
          <Stack direction="row" spacing={2}>
            <Button variant="text" borderRadius="circle" color="default" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitButtonDisabled}
              isLoading={form.formState.isSubmitting}
              type="submit"
            >
              変更を保存
            </Button>
          </Stack>
        </Stack>
      }
      onCancel={onCancel}
    />
  );
};

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    padding: 2px;
  }
`;
