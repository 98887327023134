import { Menu, MenuItem } from "@material-ui/core";
import { Announcement } from "@onn/common";
import React, { FC, useState, useCallback, MouseEvent, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { IconButton, Loading, Typography } from "~/components/uiParts";
import { useDeleteAnnouncement } from "~/hooks/announcement";
import { useAnnouncementDistributionsByAnnouncementId } from "~/hooks/announcementDistribution";
import { useModal } from "~/hooks/modal";

type Props = {
  announcement: Announcement;
};

export const AnnouncementManageMenu: FC<Props> = ({ announcement }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const { handleModal } = useModal();
  const { data: announcementDistributions, isLoading: isLoadingAnnouncementDistributions } =
    useAnnouncementDistributionsByAnnouncementId({ announcementId: announcement.id });

  // 画面遷移を防ぐpreventDefaultとstopPropagation,メニューを閉じるsetStateをひとまとめに実行した後に目的のcallbackを実行する
  const handleClickHF = useCallback((e: MouseEvent<HTMLElement>, callback: () => void) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
    callback();
  }, []);

  const { execDeleteAnnouncement } = useDeleteAnnouncement();
  const wasDelivered =
    !announcement.isWaitingPublication() && announcementDistributions?.length !== 0;
  const menuOptions = useMemo(() => {
    return [
      {
        id: "edit",
        title: "編集",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => {
            navigate(`/announcements/${announcement.id}/edit/?from_page=list`);
          }),
      },
      wasDelivered
        ? {
            id: "delivery_setting",
            title: "配信設定",
            func: (e: MouseEvent<HTMLElement>) =>
              handleClickHF(e, () =>
                navigate(`/announcements/${announcement.id}/delivery_setting/?from_page=list`)
              ),
          }
        : [],
      {
        id: "delete",
        title: "削除",
        func: (e: MouseEvent<HTMLElement>) => {
          handleClickHF(e, () => {
            handleModal({
              name: "announcementDeleteConfirmModal",
              args: {
                title: announcement.title,
                onSubmit: async () => {
                  await execDeleteAnnouncement(announcement.id);
                },
              },
            });
          });
        },
      },
    ].flat();
  }, [
    announcement.id,
    announcement.title,
    execDeleteAnnouncement,
    handleClickHF,
    handleModal,
    navigate,
    wasDelivered,
  ]);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  if (isLoadingAnnouncementDistributions) {
    <Loading size="small" />;
  }

  return (
    <>
      <IconButton icon="menuVert" onClick={handleOpenMenu} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuOptions.map((option) => {
          return (
            <MenuItem key={option.title} onClick={option.func}>
              <Typography variant="body2">{option.title}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
