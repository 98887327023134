import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

import { useQuery } from "./useQuery";

export const useNavigateWithToDestPath = () => {
  const navigate = useNavigate();
  const { query } = useQuery();

  /**
   * @param toPathname 遷移先のパス
   */
  const navigateWithDestPath = useCallback(
    (toPathname: string, options?: NavigateOptions) => {
      const destPath = query.get("dest-path") || location.pathname;
      const normalizedToPathname = toPathname.startsWith("/") ? toPathname : `/${toPathname}`;

      const toUrl = new URL(
        `${normalizedToPathname}?dest-path=${destPath}`,
        "http://dummy.example.com"
      );

      navigate(`${toUrl.pathname}${toUrl.search}`, options);
    },
    [navigate, query]
  );

  /**
   * @param defaultPath 遷移先パスのデフォルト値 (何も指定しない場合、"/")
   */
  const navigateToDestPath = useCallback(
    (defaultPath?: string, options?: NavigateOptions) => {
      const destPath = query.get("dest-path") || defaultPath || "/";

      navigate(destPath, options);
    },
    [navigate, query]
  );

  return { navigateWithDestPath, navigateToDestPath };
};
