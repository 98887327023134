import { useCallback } from "react";
import { NavigateOptions, createSearchParams, useNavigate } from "react-router-dom";

import { useQuery } from "./useQuery";

export const useNavigateWithQuery = () => {
  const { query } = useQuery();
  const navigate = useNavigate();

  const navigateFunction = useCallback(
    (
      to: string,
      options?: {
        additionalQuery?: Record<string, string>;
        navigateOptions?: NavigateOptions | undefined;
      }
    ) => {
      const { additionalQuery, navigateOptions } = options || {};
      if (additionalQuery) {
        Object.entries(additionalQuery).forEach(([key, value]) => {
          query.set(key, value);
        });
      }

      navigate(0 === query.size ? to : `${to}?${createSearchParams(query)}`, navigateOptions);
    },
    [navigate, query]
  );

  return navigateFunction;
};
