import { Employee } from "@onn/common";
import React, { useCallback, useState } from "react";

import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";

import { useGenerateRegistrationIndividualLinkUrl } from "./useGenerateRegistrationIndividualLinkUrl";

import { useSendInvitationNotification } from "~/hooks/employee/useSendInvitationNotification";
import { useClipboard } from "~/hooks/shared";
import { useDownloadSvg } from "~/hooks/useDownloadSvg";

export const useShowQRModeModal = ({
  newGraduate,
  onCancel,
  toAddConditionMode,
  isAddedCondition,
  onSendInvitationNotification,
}: {
  newGraduate: Employee;
  onCancel: () => void;
  toAddConditionMode: () => void;
  isAddedCondition: boolean;
  onSendInvitationNotification?: () => void | Promise<void>;
}) => {
  const { handleCopy } = useClipboard();
  const { generateRegistrationIndividualLinkUrl } = useGenerateRegistrationIndividualLinkUrl();

  const [isLoading, setIsLoading] = useState(false);
  const { sendInvitation } = useSendInvitationNotification();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    sendInvitation(newGraduate.id, newGraduate.hasNotifiedInvitation())
      .then(() => {
        onSendInvitationNotification?.();
        onCancel();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [sendInvitation, newGraduate, onSendInvitationNotification, onCancel]);

  const title = `招待｜${newGraduate.getName()}さん専用`;

  // QRコードを画像としてダウンロードする関数
  // NOTE: ユーザー名に"."が含まれている場合は、.pngという拡張子がつかないので、手動でつけています
  const { divRef: qrCodeDivRef, downloadSvg } = useDownloadSvg(
    `Onnアカウント登録QRコード_${newGraduate.getName()}${
      newGraduate.getName().includes(".") ? ".png" : ""
    }`
  );

  const registrationInvitationLinkUrl = generateRegistrationIndividualLinkUrl(
    newGraduate.invitationToken
  );

  // 招待URLをクリップボードにコピーする
  const addRegistrationInvitationLinkUrlToClipBoard = useCallback(() => {
    const copyText = `=== ${newGraduate.getName()}さん登録専用 URL ===\n${registrationInvitationLinkUrl}`;
    handleCopy(copyText, "招待URL");
  }, [handleCopy, newGraduate, registrationInvitationLinkUrl]);

  const onClickCopyLink = useCallback(() => {
    addRegistrationInvitationLinkUrlToClipBoard();
  }, [addRegistrationInvitationLinkUrlToClipBoard]);

  const onClickDownloadQRCode = useCallback(() => {
    downloadSvg();
  }, [downloadSvg]);

  return {
    title,
    content: (
      <ModalContent
        isAddedCondition={!isAddedCondition}
        registrationInvitationLinkUrl={registrationInvitationLinkUrl}
        onClickToAddCondition={toAddConditionMode}
        onClickCopyLink={onClickCopyLink}
        onClickDownloadQRCode={onClickDownloadQRCode}
        qrCodeDivRef={qrCodeDivRef}
      />
    ),
    footer: (
      <ModalFooter
        onCancel={onCancel}
        isLoading={isLoading}
        onClickSendOrResend={handleSubmit}
        lastInvitedAt={newGraduate.lastInvitedAt}
        hasNotifiedInvitation={newGraduate.hasNotifiedInvitation()}
      />
    ),
    handleSubmit,
  };
};
