import { Box } from "@material-ui/core";
import React, { FC } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Paper, Typography } from "~/components/uiParts";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";

export const LineQRPage: FC = () => {
  const { tenant } = useTenant();
  const navigate = useNavigate();

  // NOTE: 公式アカウント登録リンクが存在しない場合はポータルに遷移する
  if (!tenant.lineInvitationLink) {
    navigate("/portal");
    // NOTE: userDataForUpdateが存在しない状態でレンダリングさせないためにnullを返す
    return null;
  }

  return (
    <StyledContainer>
      <StyledPaper>
        <Typography variant="h4" bold>
          LINE友だち追加
        </Typography>
        <Box my="16px" display="flex" justifyContent="center" height="256px" alignItems="center">
          <StyledAnchor href={tenant.lineInvitationLink} target="_blank" rel="noreferrer">
            <QRCode
              style={{ height: "156px", width: "156px" }}
              value={tenant.lineInvitationLink || ""}
            />
          </StyledAnchor>
        </Box>
        <Typography variant="body1" align="center" bold>
          {`LINEに公式アカウントを友だち追加して\n登録を完了させましょう。`}
        </Typography>
        <Box mt="32px" textAlign="center">
          <StyledAnchor href={tenant.lineInvitationLink} target="_blank" rel="noreferrer">
            <Button variant="outlined" borderRadius="circle" color="primary">
              LINE友だち追加
            </Button>
          </StyledAnchor>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.portalSp`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.portalSp`
      box-shadow: none;
      border-radius: 0;
      `}
  }
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
