import { Box } from "@material-ui/core";
import React, { useMemo } from "react";

import { Loading } from "~/components/uiParts";

import { useOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";

// 面談予定を表示するセル
export const DeterminedSlotsCountToInterviewInTheFutureCell = ({
  onnEventId,
}: {
  onnEventId: string;
}) => {
  const { data: onnEventAnswersForDisplay = [], isLoading: isLoadingFetchingAnswers } =
    useOnnEventAnswersForDisplay(onnEventId);

  const determinedSlotsCountToInterviewInTheFuture = useMemo(() => {
    return onnEventAnswersForDisplay.filter((answer) =>
      answer.selectedOnnEventSlotDate ? !answer.selectedOnnEventSlotDate.isDone() : false
    ).length;
  }, [onnEventAnswersForDisplay]);

  return isLoadingFetchingAnswers ? (
    <Loading key={onnEventId} size="small" />
  ) : (
    <Box key={onnEventId}>{determinedSlotsCountToInterviewInTheFuture}</Box>
  );
};
