import { z } from "zod";

import { OnnEventChangeTypeSchema } from "../inputTypeSchemas/OnnEventChangeTypeSchema";

/////////////////////////////////////////
// CHANGE STATUS OF ONN EVENT TRIGGER SETTING SCHEMA
/////////////////////////////////////////

export const ChangeStatusOfOnnEventTriggerSettingSchema = z.object({
  changeType: OnnEventChangeTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  triggerId: z.string(),
  onnEventId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ChangeStatusOfOnnEventTriggerSetting = z.infer<
  typeof ChangeStatusOfOnnEventTriggerSettingSchema
>;

/////////////////////////////////////////
// CHANGE STATUS OF ONN EVENT TRIGGER SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ChangeStatusOfOnnEventTriggerSettingOptionalDefaultsSchema =
  ChangeStatusOfOnnEventTriggerSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type ChangeStatusOfOnnEventTriggerSettingOptionalDefaults = z.infer<
  typeof ChangeStatusOfOnnEventTriggerSettingOptionalDefaultsSchema
>;

export default ChangeStatusOfOnnEventTriggerSettingSchema;
