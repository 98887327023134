import React from "react";
import styled from "styled-components";

import { Chip } from "~/components/uiParts";
import theme from "~/config/theme";

export const NewGraduateNotInvitedChip = (): JSX.Element => {
  return <StyledChip label={"未招待"} />;
};

const StyledChip = styled(Chip).attrs({ color: "muted" })`
  &.MuiChip-root {
    background-color: ${() => theme.palette.grey[300]};
    padding: 4px 8px;
    height: 18px;
  }
  .MuiChip-label {
    padding: 0px;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;
