import { RegistrationInvitationLink } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

const generateKeyObj = (currentUserId: string) => {
  return {
    name: `get_registration_invitation_links`,
    currentUserId,
  };
};
/**
 * idをもとにuseRegistrationInvitationLinksを複数返すSWR
 * @param {string} currentUserId ログイン中のuser
 */
export const useDefaultRegistrationInvitationLink = (
  currentUserId: string
): SWRResponse<RegistrationInvitationLink | null, Error> => {
  return useSWR<RegistrationInvitationLink | null>(generateKeyObj(currentUserId), async () => {
    const response = await apiClient.get(
      "/api/registration-invitation-link/registration-invitation-links-default"
    );
    return response.data ? new RegistrationInvitationLink(response.data) : null;
  });
};
