import React, { FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { PortalLayout } from "~/components/layouts";
import { useCurrentUser } from "~/hooks/employee";
import { useScrollTopByDetectTransition } from "~/hooks/shared";

import { PortalIndex } from "~/pages/portal";
import { LineQRPage } from "~/pages/portal/LineQRPage";
import { AnnouncementDetail } from "~/pages/portal/NewGraduate/announcement/detail";
import { AnnouncementList } from "~/pages/portal/NewGraduate/announcement/list";
import { OnnEventIndex } from "~/pages/portal/NewGraduate/onnEvent";
import { OnnEventAttendance } from "~/pages/portal/NewGraduate/onnEvent/attendance";
import { OnnEventLanding } from "~/pages/portal/NewGraduate/onnEvent/landing";
import { OnnEventNewOrEdit } from "~/pages/portal/NewGraduate/onnEvent/newOrEdit";
import { OnnEventThanks } from "~/pages/portal/NewGraduate/onnEvent/thanks";
import { ScanQrPage } from "~/pages/portal/ScanQrPage";
import { ContactMessageDetailContainer } from "~/pages/portal/contactMessages/detail";
import { ContactRoomsIndex } from "~/pages/portal/contactRooms";
import { LibraryIndex } from "~/pages/portal/libraries";
import { LibraryDetail } from "~/pages/portal/libraries/detail";
import { Page as OnnTaskAnswerIndex } from "~/pages/portal/onnTasks/answer";
import { OnnTasksLanding } from "~/pages/portal/onnTasks/landing";
import { OnnTasksIndex } from "~/pages/portal/onnTasks/list";
import { OnnTasksAnswerThanksPage } from "~/pages/portal/onnTasks/thanks";

import { SettingsAccount } from "~/pages/settings/account";

/**
 * 入社者・候補者のみがアクセスできるポータル用のルート
 * - 認証済みではない場合 のリダイレクト処理は AuthenticationGuard で行う
 */
const NewHireRoute = ({ component: Component }: { component: FC }) => {
  useScrollTopByDetectTransition();
  const { currentUser } = useCurrentUser();
  const location = useLocation();

  const isPreview = new URLSearchParams(location.search).get("preview") === "true";
  const isNewcomer = currentUser.isNewcomer();
  // 入社者・候補者ではない場合、トップページへリダイレクトさせる
  // ただしプレビュー表示の場合は除く
  if (!isNewcomer && !isPreview) {
    return <Navigate to="/" />;
  }

  return <Component />;
};

export const portalRoutes = [
  {
    path: "/portal",
    children: [
      {
        path: "/portal/contact_rooms/:id",
        element: (
          <PortalLayout fixedWindowHeight>
            <NewHireRoute component={ContactMessageDetailContainer} />
          </PortalLayout>
        ),
      },
      {
        element: (
          <PortalLayout>
            <Outlet />
          </PortalLayout>
        ),
        children: [
          {
            path: "/portal/",
            element: <NewHireRoute component={PortalIndex} />,
          },
          {
            path: "/portal/line_qr",
            element: <NewHireRoute component={LineQRPage} />,
          },
          {
            path: "/portal/contact_rooms",
            element: <NewHireRoute component={ContactRoomsIndex} />,
          },
          {
            path: "/portal/libraries",
            element: <NewHireRoute component={LibraryIndex} />,
          },
          {
            path: "/portal/libraries/:libraryId",
            element: <NewHireRoute component={LibraryDetail} />,
          },
          {
            path: "/portal/events",
            element: <NewHireRoute component={OnnEventIndex} />,
          },
          {
            path: "/portal/events/:id",
            element: <NewHireRoute component={OnnEventLanding} />,
          },
          {
            path: "/portal/events/:id/new",
            element: <NewHireRoute component={OnnEventNewOrEdit} />,
          },
          {
            path: "/portal/events/:id/attendance",
            element: <NewHireRoute component={OnnEventAttendance} />,
          },
          {
            path: "/portal/events/:id/thanks",
            element: <NewHireRoute component={OnnEventThanks} />,
          },
          {
            path: "/portal/announcements",
            element: <NewHireRoute component={AnnouncementList} />,
          },
          {
            path: "/portal/announcements/:id",
            element: <NewHireRoute component={AnnouncementDetail} />,
          },
          {
            path: "/portal/settings/account",
            element: <NewHireRoute component={SettingsAccount} />,
          },
          {
            path: "/portal/onn_tasks/:id/landing",
            element: <NewHireRoute component={OnnTasksLanding} />,
          },
          {
            path: "/portal/onn_tasks/:id/answer",
            element: <NewHireRoute component={OnnTaskAnswerIndex} />,
          },
          {
            path: "/portal/onn_tasks/:id/thanks",
            element: <NewHireRoute component={OnnTasksAnswerThanksPage} />,
          },
          {
            path: "/portal/onn_tasks",
            element: <NewHireRoute component={OnnTasksIndex} />,
          },
          {
            path: "/portal/scan_qr",
            element: <NewHireRoute component={ScanQrPage} />,
          },
        ],
      },
    ],
  },
];
