import { Box } from "@material-ui/core";
import { format, isValid, set } from "date-fns";
import React, { ChangeEvent, useCallback, useState } from "react";
import styled from "styled-components";

import { DatePickerV2, SelectForm } from "~/components/uiParts";

// "0:00"~"23:30"の配列
const timeValues = [...Array(48)].map((_, i) => {
  const hour = Math.floor(i / 2);
  const minute = i % 2 === 0 ? "00" : "30";

  return `${hour}:${minute}`;
});

const timeMenuItems = timeValues.map((v) => ({ value: v, name: v }));

/**
 * date が "0:00" なら "0:30" を返し、"0:30" なら "1:00" を返す
 * @param date
 * @returns
 */
const getNextTimeValueFromDate = (date: Date) => {
  const minute = date.getMinutes();

  const hour = minute < 30 ? date.getHours() : (date.getHours() + 1) % 24;
  const minuteValue = minute < 30 ? "30" : "00";

  const timeValue = `${hour}:${minuteValue}`;
  return timeValue;
};

const getDefaultTimeValue = (date: Date) => {
  return format(date, "H:mm");
};

const getHoursAndMinutesNumberFromTimeValue = (timeValue: string) => {
  const [hour, minute] = timeValue.split(":");

  return { hours: Number(hour), minutes: Number(minute) };
};

type Props = {
  date: Date | null;
  onChangeDate: (date: Date | null) => void;
  dateHelperText: string;
  dateError?: boolean;
  dateDisabled?: boolean;
  timeDisabled?: boolean;
};

export const ScheduledDatePicker = ({
  date,
  onChangeDate,
  dateHelperText,
  dateError,
  dateDisabled = false,
  timeDisabled = false,
}: Props): JSX.Element => {
  const [currentTimeValue, setCurrentTimeValue] = useState(date ? getDefaultTimeValue(date) : null);

  const onChangeMonthAndDate = useCallback(
    (newDate: Date | null) => {
      if (!newDate) {
        setCurrentTimeValue(null);
        onChangeDate(null);
        return;
      }

      if (!isValid(newDate)) {
        return;
      }

      const year = newDate.getFullYear();
      const month = newDate.getMonth();
      const date = newDate.getDate();
      const isToday = format(newDate, "yyyy/MM/dd") === format(new Date(), "yyyy/MM/dd");
      const timeValue = currentTimeValue
        ? currentTimeValue
        : isToday
        ? getNextTimeValueFromDate(new Date())
        : "10:00";
      const { hours, minutes } = getHoursAndMinutesNumberFromTimeValue(timeValue);
      setCurrentTimeValue(timeValue);
      onChangeDate(set(date, { year, month, date, hours, minutes }));
      return;
    },
    [currentTimeValue, onChangeDate]
  );

  const onChangeTimeValue = useCallback(
    (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const timeValue = e.target.value as string;
      setCurrentTimeValue(timeValue);

      if (!date) {
        return;
      }

      const { hours, minutes } = getHoursAndMinutesNumberFromTimeValue(timeValue);
      const newDate = set(date, { hours, minutes });
      onChangeDate(newDate);
      return;
    },
    [date, onChangeDate]
  );

  return (
    <>
      <Box display="flex" minHeight="50px" mb="16px">
        <Box mr="8px" width="200px">
          <StyledDatePicker
            fullWidth
            disablePast
            disabled={dateDisabled}
            placeholder="日程を選択"
            value={date}
            onChange={onChangeMonthAndDate}
            error={dateError}
            helperText={dateHelperText}
          />
        </Box>
        <Box>
          <StyledSelectForm
            disabled={timeDisabled}
            selected={currentTimeValue}
            onChange={onChangeTimeValue}
            menuItems={timeMenuItems}
            labelWhenNoSelected="時間を選択"
            icon="clock"
            errorBorder={dateError}
            isSmall
          />
        </Box>
      </Box>
    </>
  );
};

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 228px;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    height: 50px;
  }
`;
