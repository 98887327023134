import React, { useMemo } from "react";

import { ModalContentLayout } from "./ModalContentLayout";
import { TagSelector } from "./parts/TagSelector";

type Props = {
  selectedTagIds: string[];
  onChangeTagIds(tagIds: string[]): void;
};
export const ModalContent = ({ selectedTagIds, onChangeTagIds }: Props): JSX.Element => {
  const tagSelector = useMemo(
    () => <TagSelector selectedTagIds={selectedTagIds} onChange={onChangeTagIds} />,
    [onChangeTagIds, selectedTagIds]
  );

  return <ModalContentLayout tagSelector={tagSelector} />;
};
