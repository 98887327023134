import { v4 } from "uuid";

import {
  employeeInformationTextTypeFieldSchema,
  IEmployeeInformationTextTypeField,
} from "./schema";

export class EmployeeInformationTextTypeField implements IEmployeeInformationTextTypeField {
  static readonly type = "TEXT" as const;
  type = EmployeeInformationTextTypeField.type;
  static validator = employeeInformationTextTypeFieldSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeInformationGroupId: string;
  readonly label: string;
  readonly order: number;
  readonly sampleValue: string;
  readonly isCustom: boolean;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<EmployeeInformationTextTypeField>, "type">) {
    EmployeeInformationTextTypeField.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeInformationGroupId = init.employeeInformationGroupId;
    this.label = init.label;
    this.order = init.order;
    this.sampleValue = init.sampleValue;
    this.isCustom = init.isCustom;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationTextTypeField>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): EmployeeInformationTextTypeField {
    return new EmployeeInformationTextTypeField({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
