import { Box } from "@material-ui/core";
import React, { FC, useEffect, useRef } from "react";

import styled from "styled-components";

import { usePageContext } from "../../pageContext";

import { ActionFooter } from "../components/ActionFooter";
import { TriggerBlockWidth } from "../constant/constant";
import { TriggersApiResponse } from "../type";

import { TriggerBlockForViewMode } from "./TriggerBlockForViewMode";

import { Button, Loading, Tooltip, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";

export const ScenarioFlowAreaWhenViewMode: FC<{
  triggers: TriggersApiResponse;
}> = ({ triggers }) => {
  const { isLockingByOwn } = usePageContext();

  useDisplaySnackbarWhenEditCompletedByOther();

  if (isLockingByOwn) {
    return (
      <Box
        style={{
          marginTop: `calc(50vh - 200px)`,
        }}
      >
        <Loading size="large" />
        <Box display={"flex"} width={"100%"} justifyContent={"center"} p={4}>
          <Typography color="textSecondary">しばらくお待ちください</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Root>
        {triggers.map((trigger) => {
          return (
            <TriggerBlockWrapper key={trigger.id}>
              <TriggerBlockForViewMode trigger={trigger} />
            </TriggerBlockWrapper>
          );
        })}
      </Root>
      <ActionFooter ButtonsArea={<ButtonArea />} />
    </>
  );
};

const ButtonArea: FC = () => {
  const { currentUser } = useCurrentUser();

  const { setMode, isLocking, isLockingByOthers } = usePageContext();

  const isEditButtonDisabled = isLocking || !currentUser.isAdmin();
  const editButtonTooltipText = !currentUser.isAdmin()
    ? "管理者以外は編集できません。"
    : isLockingByOthers &&
      "シナリオは現在別のユーザーによって編集中のため、変更できません。時間をおいてから再度操作をしてください。";
  return (
    <Tooltip title={editButtonTooltipText} placement="bottom" arrow>
      <Box display="flex" justifyContent={"center"} alignItems="center" height={"48px"} ml="24px">
        <Button
          variant="contained"
          borderRadius="circle"
          onClick={() => setMode("edit")}
          color="primary"
          fullHeight
          disabled={isEditButtonDisabled}
        >
          編集
        </Button>
      </Box>
    </Tooltip>
  );
};

const useDisplaySnackbarWhenEditCompletedByOther = () => {
  const { isLockingByOthers } = usePageContext();

  const { enqueueSnackbar } = useSnackbar();
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (!isLockingByOthers) {
      enqueueSnackbar("シナリオの編集が完了しました。", {
        variant: "info",
        actionLabel: "更新",
        type: "onWebsiteNotice", // NOTE: onWebsiteNoticeではないが、onWebsiteNoticeのスタイルを流用するために使用している。
        onAction: () => {
          window.location.reload();
        },
      });
    }
  }, [enqueueSnackbar, isLockingByOthers]);
};

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-bottom: 76px; // アクションフッター分
  position: relative;
  width: ${TriggerBlockWidth}px;
`;

const TriggerBlockWrapper = styled(Box)`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -36px;
    height: 32px;
    border: 1.5px solid ${(props) => props.theme.palette.primary.main};
    border-radius: 3px;
  }
  :last-child::after {
    display: none;
  }
`;
