import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import React from "react";

import { Checkbox, Typography } from "~/components/uiParts";

type TagCheckboxGroupProps<Tag extends { id: string; name: string }> = {
  tags: Tag[];
  selectedTagIds: string[];
  onChange: (selectedTagIds: string[]) => void;
};

export function TagCheckboxGroup<Tag extends { id: string; name: string }>({
  tags,
  selectedTagIds,
  onChange,
}: TagCheckboxGroupProps<Tag>) {
  return (
    <FormControl component="fieldset" aria-label="タグを選択">
      <FormGroup>
        {tags.map((tag) => {
          return (
            <FormControlLabel
              key={tag.id}
              control={
                <Checkbox
                  checked={selectedTagIds.includes(tag.id)}
                  onChange={(e) => {
                    onChange(
                      e.target.checked
                        ? [...selectedTagIds, tag.id]
                        : selectedTagIds.filter((tagId) => tagId !== tag.id)
                    );
                  }}
                />
              }
              label={
                <Typography color="textSecondary" variant="body2">
                  {tag.name}
                </Typography>
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
