import { v4 } from "uuid";

import { AnyTriggerSetting, EvaluateOnnEventTriggerSetting } from "../triggerSettings";

import {
  IOnnEventEvaluationActionConditionSchema,
  onnEventEvaluationActionConditionSchema,
} from "./schema";

export class OnnEventEvaluationActionCondition implements IOnnEventEvaluationActionConditionSchema {
  static readonly type = "OnnEventEvaluationActionCondition" as const;
  type = OnnEventEvaluationActionCondition.type;

  static readonly validator = onnEventEvaluationActionConditionSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  createdAt: Date;
  updatedAt: Date;
  actionId: string;
  onnEventId: string;
  onnEventEvaluationRankIds: string[];

  constructor(init: Omit<ExcludeMethods<OnnEventEvaluationActionCondition>, "type">) {
    const parsedInit = OnnEventEvaluationActionCondition.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.actionId = parsedInit.actionId;
    this.onnEventId = parsedInit.onnEventId;
    this.onnEventEvaluationRankIds = parsedInit.onnEventEvaluationRankIds;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(
    params: Omit<
      ExcludeMethods<OnnEventEvaluationActionCondition>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new OnnEventEvaluationActionCondition({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  static isAvailableTriggerSettingType(
    triggerSettingType: AnyTriggerSetting["type"]
  ): triggerSettingType is EvaluateOnnEventTriggerSetting["type"] {
    return triggerSettingType === EvaluateOnnEventTriggerSetting.type;
  }
}
