import React, { FC } from "react";

import { IconButton, UncontrolledMenu } from "~/components/uiParts";

type Props = {
  onnTaskId: string;
  cancelDelivery: (onnTaskId: string) => void;
};

export const ThreeDotsMenuCell: FC<Props> = ({ onnTaskId, cancelDelivery }) => {
  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={[
        {
          text: "配信取消",
          onClick: () => {
            cancelDelivery(onnTaskId);
          },
        },
      ]}
    />
  );
};
