import { Box } from "@material-ui/core";
import { CandidateDate } from "@onn/common";
import React from "react";
import styled from "styled-components";

import {
  StatusForDisplayEventTable,
  invalidStatus,
} from "../../utils/getStatusForDisplayEventTable";

import { Icon } from "~/components/uiParts";

import theme from "~/config/theme";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

// 日程が確定していない候補者で表示するセル
export const AnswerIconWhenNotExistDeterminedDate = ({
  candidateDate,
  onnEventAnswerWithEmployee,
  statusForDisplayEventTable,
}: {
  candidateDate: CandidateDate;
  onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee;
  statusForDisplayEventTable: StatusForDisplayEventTable;
}) => {
  // NOTE: 参加できる日程がないを選択した場合は、その時点でのanswerの全ての候補日に対してimpossibleが埋められるため区別して表示している
  if (onnEventAnswerWithEmployee.answer[candidateDate.id] === "impossible") {
    return <AnswerWithUnavailableCandidatesBox />;
  }

  const bgColor = invalidStatus.includes(statusForDisplayEventTable)
    ? theme.palette.grey[50]
    : "white";

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={bgColor}
    >
      <Icon color="lightGrey" size="xl" icon="horizontalBar" />
    </Box>
  );
};

const AnswerWithUnavailableCandidatesBox = styled(Box)`
  width: 100%;
  height: 100%;

  &.MuiBox-root {
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;
