import { Box } from "@material-ui/core";
import { OnnEventPlace, OnnEventSlotDate } from "@onn/common";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { Icon, Loading, Typography } from "~/components/uiParts";
import theme from "~/config/theme";

import { useMetaDataByUrls } from "~/hooks/file";
import { useFileViewer } from "~/hooks/shared";
import VisibilityIcon from "~/images/icons/visibility/white_48x48.svg";

type Props = {
  selectedOnnEventSlotDate?: OnnEventSlotDate;
  selectedEventPlace: OnnEventPlace;
};

// NOTE: 備考欄の高さが100pxを超えたら「続きを見る」ボタンを表示する
const BoundaryHeight = 100;

/**
 * 開催場所の詳細情報を表示するコンポーネント
 */
export const SelectedPlaceInfoPart: FC<Props> = ({
  selectedOnnEventSlotDate,
  selectedEventPlace,
}) => {
  const { data: files = [], isLoading: isLoadingFile } = useMetaDataByUrls(
    selectedEventPlace ? selectedEventPlace.filePaths || [] : []
  );
  const { setFiles, setPreviewFileIndex } = useFileViewer();

  const [isExpand, setIsExpand] = useState(false);
  // NOTE: 選択されている会場が変わったらアコーディオンを閉じる
  useEffect(() => {
    setIsExpand(false);
  }, [selectedEventPlace]);

  // NOTE: 備考欄テキスト＋画像の高さに応じて、「続きを見る」ボタンの表示を切り替えるため、ここで高さを動的に取得している
  const innerRemarksAreaRef = React.useRef<HTMLDivElement>(null);
  // NOTE: 画像読み込み後に高さが変わるため、useSyncExternalStoreを使って高さを動的に取得している
  const innerRemarksAreaHeight = React.useSyncExternalStore(
    (onStoreChange: () => void) => {
      const observer = new ResizeObserver((entries) => {
        entries.forEach((_) => {
          onStoreChange();
        });
      });
      if (innerRemarksAreaRef.current) {
        observer.observe(innerRemarksAreaRef.current);
      }
      return () => {
        observer.disconnect();
      };
    },
    () => {
      return innerRemarksAreaRef.current?.offsetHeight;
    }
  );

  const addressText = selectedOnnEventSlotDate?.eventAddressText
    ? selectedOnnEventSlotDate.eventAddressText
    : `${selectedEventPlace.postCode}\n${selectedEventPlace.getFullAddress()}`;

  // 「続きを見る」ボタンを表示するかどうか
  const isShowAccordionButton = innerRemarksAreaHeight && innerRemarksAreaHeight > BoundaryHeight;

  return (
    <Box p="16px" bgcolor={theme.palette.grey[50]} borderRadius="8px">
      <Typography variant="caption" color="textPrimary">
        {addressText}
      </Typography>
      {(selectedEventPlace.remarkText || selectedEventPlace.filePaths.length > 0) && (
        <Box height={"16px"} />
      )}
      <div
        style={{
          overflow: "hidden",
          transition: "height 0.3s",
          position: "relative",
          height: isExpand
            ? undefined
            : innerRemarksAreaHeight && innerRemarksAreaHeight > BoundaryHeight
            ? BoundaryHeight
            : innerRemarksAreaHeight,
        }}
      >
        {isShowAccordionButton && (
          <>
            {/* NOTE: 「続きを見る」ボタンの上周辺の文字をぼやかす */}
            {!isExpand && (
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  width: "100%",
                  height: "100%",
                  background: `linear-gradient(to top,${theme.palette.grey[50]} 30%, transparent 100%)`,
                }}
              />
            )}
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                backgroundColor: theme.palette.grey[50],
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AccordionButton isExpand={isExpand} onClick={() => setIsExpand(!isExpand)} />
            </Box>
          </>
        )}
        <div ref={innerRemarksAreaRef}>
          <Typography variant="caption" color="textPrimary">
            {selectedEventPlace.remarkText}
          </Typography>
          <Box height={"8px"} />
          {isLoadingFile ? (
            <Box height={"210px"}>
              <Loading size="small" />
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                columnGap: "8px",
                rowGap: "16px",
              }}
            >
              {files.map((file, index) => {
                return (
                  <StyledImageContainerPreviewable
                    key={file.fullPath}
                    onClick={() => {
                      setFiles(files);
                      setPreviewFileIndex(index);
                    }}
                  >
                    <StyledImage src={file.url} alt="thumbnail" />
                  </StyledImageContainerPreviewable>
                );
              })}
            </Box>
          )}
        </div>
        {/* NOTE: アコーディオンが開いている時はボトムに「閉じる」ボタンが来るのでそのための40px */}
        <Box height={isExpand ? "40px" : "0px"} />
      </div>
    </Box>
  );
};

const AccordionButton = ({ isExpand, onClick }: { isExpand: boolean; onClick: () => void }) => {
  return (
    <Box
      style={{
        backgroundColor: theme.palette.grey[50],
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Icon icon={isExpand ? "chevronUp" : "chevronDown"} size="sm" color="grey" />
      <Typography variant="body2" color="textPrimary" style={{ marginLeft: "4px" }}>
        {isExpand ? "閉じる" : "続きを見る"}
      </Typography>
    </Box>
  );
};

const StyledImageContainerPreviewable = styled.div`
  cursor: pointer;
  width: 100%;
  margin-bottom: -6px;
  display: flex;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    background-image: url(${VisibilityIcon});
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: -6px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  &:hover {
    opacity: 0.33;
  }
`;
