import {
  CandidateDate,
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEvent,
  OnnEventAnswerOption,
  OnnEventDeterminedDate,
  RecruitmentStatus,
} from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { useMemo } from "react";

import { useDeterminedDate } from "../../determinedDate";

import { useCandidateDatesWithNumberOfParticipants } from "../../useCandidateDatesWithNumberOfParticipants";

import { OnnEventAnswerWithEmployee } from "../useOnnEventAnswersWithEmployee";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";
import { useCurrentUser } from "~/hooks/employee";

import {
  getStatusForDisplayEventTable,
  statusToLabelForFilter,
} from "~/pages/events/detail/components/AnswerResultTab/utils/getStatusForDisplayEventTable";
import { CAN_NOT_JOIN } from "~/pages/events/detail/hooks/OnnEventAnswerResultTab/filter/useFilterByCandidateDates";
import { captureException } from "~/util";

import { removeUnusableCharacters } from "~/util/fileUtil";

const CSV_DATE_FORMAT = "M/d(E)HH:mm";
const CSV_DATE_TIME_FORMAT = "HH:mm";

export const useOutputResultAnswerCsv = ({
  onnEvent,
  onnEventAnswers,
  employeeIdToLatestEmployeeActiveLogMap,
  selectedCandidateDateIds,
}: {
  onnEvent: OnnEvent;
  onnEventAnswers: OnnEventAnswerWithEmployee[];
  employeeIdToLatestEmployeeActiveLogMap: Map<string, EmployeeActiveLog>;
  selectedCandidateDateIds?: string[];
}) => {
  const { currentUser } = useCurrentUser();
  const { recruitmentStatuses } = useRecruitmentStatusList();

  // NOTE: 配信数
  const numberOfDistribution = onnEventAnswers.length;
  // NOTE: 回答があるユーザー数
  const numberOfResponses = onnEventAnswers.filter((v) => v.isAnswered()).length;

  const { data: determinedDates = [], isLoading: isLoadingDeterminedDate } = useDeterminedDate({
    onnEventId: onnEvent.id,
  });

  const { data: candidateDatesWithNumberOfParticipants = [], isLoading: isCandidateDatesLoading } =
    useCandidateDatesWithNumberOfParticipants({
      onnEventId: onnEvent.id,
      currentEmployeeId: currentUser.id,
    });

  const candidateDateIdToCandidateDateMap = useMemo(
    () =>
      new Map(
        candidateDatesWithNumberOfParticipants.map((candidateDate) => [
          candidateDate.id,
          candidateDate,
        ])
      ),
    [candidateDatesWithNumberOfParticipants]
  );

  const employeeIdToDeterminedDate = useMemo(
    () =>
      new Map(
        (determinedDates || []).map((determinedDate) => [determinedDate.employeeId, determinedDate])
      ),
    [determinedDates]
  );

  const targetCandidateDates = useMemo(() => {
    if (selectedCandidateDateIds && selectedCandidateDateIds.length > 0) {
      return candidateDatesWithNumberOfParticipants.filter((candidateDate) =>
        selectedCandidateDateIds.includes(candidateDate.id)
      );
    }
    return candidateDatesWithNumberOfParticipants;
  }, [candidateDatesWithNumberOfParticipants, selectedCandidateDateIds]);

  const isShowCanNotJoinColumn =
    selectedCandidateDateIds && selectedCandidateDateIds.length > 0
      ? selectedCandidateDateIds.includes(CAN_NOT_JOIN)
      : true;

  const csvData = [
    generateCsvHeader({
      numberOfDistribution,
      numberOfResponses,
      candidateDates: targetCandidateDates,
      isShowCanNotJoinColumn,
    }),
    ...onnEventAnswers.map((answer) =>
      generateCsvRow({
        answer,
        employeeIdToLatestEmployeeActiveLogMap,
        recruitmentStatuses,
        employeeIdToDeterminedDate,
        candidateDateIdToCandidateDateMap,
        targetCandidateDates,
        isShowCanNotJoinColumn,
      })
    ),
  ];

  const filename = `イベント回答結果_${removeUnusableCharacters(onnEvent.title)}`;

  const isLoading = isLoadingDeterminedDate || isCandidateDatesLoading;

  return { csvData, filename, isLoading };
};

// NOTE: csvのヘッダー行の生成
export const generateCsvHeader = ({
  numberOfDistribution,
  numberOfResponses,
  candidateDates,
  isShowCanNotJoinColumn,
}: {
  numberOfDistribution: number;
  numberOfResponses: number;
  candidateDates: CandidateDate[];
  isShowCanNotJoinColumn: boolean;
}) => {
  const header = [
    `回答数:${numberOfResponses}/未回答:${numberOfDistribution - numberOfResponses}`,
    `氏名`,
    `メールアドレス`,
    "ステータス",
    ...candidateDates.map(
      (date) =>
        `${format(date.from, CSV_DATE_FORMAT, { locale: ja })}~${format(
          date.until,
          CSV_DATE_TIME_FORMAT,
          { locale: ja }
        )}`
    ),
  ];
  if (isShowCanNotJoinColumn) {
    header.push("参加できる日程がない");
  }
  return header;
};

// NOTE: csvのボディ行の生成
export const generateCsvRow = ({
  answer,
  recruitmentStatuses,
  employeeIdToLatestEmployeeActiveLogMap,
  employeeIdToDeterminedDate,
  candidateDateIdToCandidateDateMap,
  targetCandidateDates,
  isShowCanNotJoinColumn,
}: {
  answer: OnnEventAnswerWithEmployee;
  recruitmentStatuses: RecruitmentStatus[];
  employeeIdToLatestEmployeeActiveLogMap: Map<string, EmployeeActiveLog>;
  employeeIdToDeterminedDate: Map<string, OnnEventDeterminedDate>;
  candidateDateIdToCandidateDateMap: Map<string, CandidateDateWithNumberOfParticipants>;
  targetCandidateDates: CandidateDate[];
  isShowCanNotJoinColumn: boolean;
}): string[] => {
  const onnEventDeterminedDate = employeeIdToDeterminedDate.get(answer.employeeId);

  const generateAnswerStatusCell = (answer: OnnEventAnswerWithEmployee) => {
    const recruitmentStatus = recruitmentStatuses.find(
      (status) => status.id === answer.employee.recruitmentStatusId || ""
    );
    if (!recruitmentStatus) {
      captureException({
        error: new Error("recruitmentStatus が想定しない undefined になっています"),
        tags: { type: "answersFilteredByAnswerStatus" },
        extras: {
          answerId: answer.id,
          employeeId: answer.employeeId,
          recruitmentStatusId: answer.employee.recruitmentStatusId,
          recruitmentStatuses,
        },
      });
      return "";
    }

    const latestEmployeeActiveLog = employeeIdToLatestEmployeeActiveLogMap.get(answer.employeeId);

    const answeredCandidateDate = onnEventDeterminedDate
      ? candidateDateIdToCandidateDateMap.get(onnEventDeterminedDate.candidateDateId)
      : undefined;

    const statusForDisplay = getStatusForDisplayEventTable({
      onnEventDeterminedDate,
      answeredCandidateDate,
      employeeActiveLog: latestEmployeeActiveLog,
      newGraduate: answer.employee,
      onnEventAnswer: answer,
    });

    if (statusForDisplay === "before_answer_and_read" && latestEmployeeActiveLog) {
      const label = format(latestEmployeeActiveLog?.createdAt, "yyyy/MM/dd 既読");
      return label;
    }
    return statusToLabelForFilter[statusForDisplay];
  };

  const row = [
    answer.employee.uniqueId || "",
    answer.employee.getName() || "",
    answer.employee.email || "",
    generateAnswerStatusCell(answer),
    ...targetCandidateDates.map((date) => convertAnswer(answer.answer[date.id])),
  ];
  if (isShowCanNotJoinColumn) {
    row.push(answer.isUnavailableCandidates() ? "◯" : "-");
  }
  return row;
};

const convertAnswer = (answer: OnnEventAnswerOption | undefined) => {
  switch (answer) {
    case "possible":
      return "◯";
    case "impossible":
      return "×";
    case "pending":
      return "△";
    default:
      return "-";
  }
};
