import { APISchema } from "@onn/common";
import { Answer } from "@onn/common/domain/OnnTask/Answer";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/api/onn-form-task-answers/id"]["PATCH"]["body"];

export const useUpdateOnnFormTaskAnswers = () => {
  const updateOnnFormTaskAnswers = async ({
    onnFormTaskId,
    employeeId,
    answers,
    answeredFormRevisionId,
  }: {
    onnFormTaskId: string;
    employeeId: string;
    answers: Answer[];
    answeredFormRevisionId: string;
  }) => {
    const body: Body = {
      onnFormTaskId,
      employeeId,
      answeredFormRevisionId,
      answers,
    };
    await apiClient.patch("/api/onn-form-task-answers/id", instanceToPlain(body) as Body);
  };

  return { updateOnnFormTaskAnswers };
};
