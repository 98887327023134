import { zodResolver } from "@hookform/resolvers/zod";
import { Action, AnyActionCondition, AnyActionSetting, AnyTriggerSetting } from "@onn/common";

import { useForm as useRHF } from "react-hook-form";

import { ActionType, InputState, inputStateSchema } from "./inputStateSchema";
import { useGenerateDefaultValuesForCreate } from "./useGenerateDefaultValuesForCreate";
import { useGenerateDefaultValuesForUpdate } from "./useGenerateDefaultValuesForUpdate";

export const useForm = ({
  mode,
  existingAction,
  existingAnyActionSetting,
  existingActionCondition,
  actionType,
  triggerSetting,
}: { actionType: ActionType; triggerSetting: AnyTriggerSetting } & (
  | {
      mode: "create";
      existingAction: null;
      existingAnyActionSetting: null;
      existingActionCondition: null;
    }
  | {
      mode: "update";
      existingAction: Action;
      existingAnyActionSetting: AnyActionSetting;
      existingActionCondition?: AnyActionCondition;
    }
)) => {
  const { generateDefaultValuesForUpdate } = useGenerateDefaultValuesForUpdate();
  const { generateDefaultValuesForCreate } = useGenerateDefaultValuesForCreate();

  const form = useRHF<InputState>({
    defaultValues:
      mode === "create"
        ? generateDefaultValuesForCreate({ actionType })
        : generateDefaultValuesForUpdate({
            existingAction,
            existingAnyActionSetting,
            existingActionCondition,
            actionType,
            triggerSetting,
          }),
    resolver: zodResolver(inputStateSchema),
  });

  return form;
};
