import { useCallback } from "react";

import { useGetTriggerFormName } from "../useGetTriggerFormName";
import { useFormContext } from "../useScenarioForm";
import { useUpdateWarningRecruitmentStatuses } from "../useUpdateWarningRecruitmentStatuses";

export const useRemoveActionFromForm = ({
  recruitmentStatusId,
  triggerId,
}: {
  recruitmentStatusId: string;
  triggerId: string;
}) => {
  const { getTriggerFormName } = useGetTriggerFormName();
  const { trigger, setValue, getValues } = useFormContext();
  const { updateWarningRecruitmentStatuses } = useUpdateWarningRecruitmentStatuses();

  const removeActionFromForm = useCallback(
    ({ actionId }: { actionId: string }) => {
      const triggerFormName = getTriggerFormName({
        recruitStatusId: recruitmentStatusId,
        triggerId,
      });
      const value = getValues(`${triggerFormName}.actions`);
      setValue(
        `${triggerFormName}.actions`,
        value.filter(({ action }) => action.id !== actionId)
      );
      trigger();
      updateWarningRecruitmentStatuses();
    },
    [
      getTriggerFormName,
      getValues,
      recruitmentStatusId,
      setValue,
      trigger,
      triggerId,
      updateWarningRecruitmentStatuses,
    ]
  );

  return { removeActionFromForm };
};
