import { OnnEvent, OnnEventAnswer } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { convertUnixTimeToDateOnnEvent } from "../../_shared/convertUnixTimeToDate";

export class UpdateDeliverySettingOfOnnEventResponse {
  static ResponseBody: {
    onnEvent: ToCloudFunctionsCompatible<OnnEvent>;
    onnEventAnswers: OnnEventAnswer[];
  };
  public static createResponseBody({
    onnEvent,
    onnEventAnswers,
  }: {
    onnEvent: OnnEvent;
    onnEventAnswers: OnnEventAnswer[];
  }) {
    return {
      onnEvent: toCloudFunctionsCompatible(onnEvent),
      onnEventAnswers: onnEventAnswers ? toCloudFunctionsCompatible(onnEventAnswers) : undefined,
    };
  }
  public static convertUnixTimeToDate(response: typeof this.ResponseBody): {
    onnEvent: OnnEvent;
    onnEventAnswers: OnnEventAnswer[];
  } {
    const onnEvent = response.onnEvent;
    const onnEventAnswers = response.onnEventAnswers;

    return {
      onnEvent: convertUnixTimeToDateOnnEvent(onnEvent),
      onnEventAnswers: onnEventAnswers.map(
        (v) =>
          new OnnEventAnswer({
            ...v,
            createdAt: new Date(v.createdAt),
            updatedAt: new Date(v.updatedAt),
          })
      ),
    };
  }
}
