import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// ONN EVENT ANSWER SCHEMA
/////////////////////////////////////////

export const OnnEventAnswerSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  employeeId: z.string(),
  selectedOnnEventSlotDateId: z.string().nullish(),
  answer: JsonValueSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventAnswer = z.infer<typeof OnnEventAnswerSchema>;

/////////////////////////////////////////
// ONN EVENT ANSWER OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventAnswerOptionalDefaultsSchema = OnnEventAnswerSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventAnswerOptionalDefaults = z.infer<typeof OnnEventAnswerOptionalDefaultsSchema>;

export default OnnEventAnswerSchema;
