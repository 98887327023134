import { Box } from "@material-ui/core";
import { CandidateDate, OnnEventDeterminedDate } from "@onn/common";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { StatusForDisplayEventTable } from "../../utils/getStatusForDisplayEventTable";

import { Icon } from "~/components/uiParts";
import theme from "~/config/theme";

// 日程が確定している候補者で表示するセル
export const AnswerIconWhenExistDeterminedDate = ({
  candidateDate,
  determinedDate,
  statusForDisplayEventTable,
}: {
  candidateDate: CandidateDate;
  determinedDate: OnnEventDeterminedDate;
  statusForDisplayEventTable: StatusForDisplayEventTable;
}) => {
  if (determinedDate.candidateDateId !== candidateDate.id) {
    return <DeterminedAnswerIconWrapper width={"100%"} />;
  }

  let IconNode: ReactNode;
  switch (statusForDisplayEventTable) {
    case "absent_attendance":
      IconNode = <StyledIcon color="grey" size="xl" icon="cross" />;
      break;
    case "unregistered_attendance_and_past":
      IconNode = <StyledIcon color="secondary" size="xl" icon="triangle" />;
      break;
    case "unregistered_attendance_and_not_past":
      IconNode = <Icon color="blue" size="lg" icon="circle" />;
      break;
    case "registered_attendance":
      IconNode = <Icon color="primary" size="xl" icon="checkOutlineCircle" />;
      break;
    default:
      IconNode = <Icon color="grey300" size="lg" icon="circle" />;
      break;
  }

  return (
    <DeterminedAnswerIconWrapper width={"100%"} bgcolor={theme.palette.grey[50]}>
      <Box width="100%" display="flex" justifyContent="center">
        {IconNode}
      </Box>
    </DeterminedAnswerIconWrapper>
  );
};

const StyledIcon = styled(Icon)`
  width: 48px;
  height: 48px;
`;

const DeterminedAnswerIconWrapper = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;
