import { Box } from "@material-ui/core";
import React from "react";

import { Typography } from "~/components/uiParts";
import { useClipboard } from "~/hooks/shared";

export const ActionDataIdRendererForDeveloper = ({
  actionId,
  actionSettingId,
  actionConditionId,
}: {
  actionId: string;
  actionSettingId: string;
  actionConditionId?: string;
}) => {
  const { handleCopy } = useClipboard();
  const onClick = (triggerId: string, target: string) => {
    handleCopy(triggerId, target);
  };
  return (
    <Box>
      <Box onClick={() => onClick(actionId, "アクションID")} style={{ cursor: "pointer" }}>
        <Typography variant="caption" color="textSecondary">
          アクションID: {actionId}
        </Typography>
      </Box>
      <Box
        onClick={() => onClick(actionSettingId, "アクション設定ID")}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="caption" color="textSecondary">
          アクション設定ID: {actionSettingId}
        </Typography>
      </Box>
      <Box
        onClick={() => actionConditionId && onClick(actionConditionId, "アクション条件ID")}
        style={{ cursor: actionConditionId ? "pointer" : "none" }}
      >
        <Typography variant="caption" color="textSecondary">
          アクション条件ID: {actionConditionId || "なし"}
        </Typography>
      </Box>
    </Box>
  );
};
