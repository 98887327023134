import { Box } from "@material-ui/core";
import { Space, SpaceSetting } from "@onn/common";
import React, { useCallback } from "react";
import { GridCellRenderer, Index } from "react-virtualized";

import { useBodyCellRenderer } from "./components/useBodyCellRenderer";
import { useHeaderCellRenderer } from "./components/useHeaderCellRenderer";

import { RowData } from "./types";

import { VirtualizedMultiGrid } from "~/components/uiParts/VirtualizedMultiGrid";
import theme from "~/config/theme";
import { useUpdateSpaceSetting } from "~/hooks/space/useUpdateSpaceSetting";
type SpaceWithSetting = {
  space: Space;
  spaceSetting?: SpaceSetting;
};
export const SpaceSettingsTable = ({
  spacesWithSetting,
}: {
  spacesWithSetting: SpaceWithSetting[];
}) => {
  const {
    cellRenderer,
    dynamicColumWidth,
    rowCount,
    headerCellHeight,
    columnCount,
    bodyCellHeight,
  } = useVirtualizedMultiGrid({ spacesWithSetting });
  return (
    <VirtualizedMultiGrid
      cellRenderer={cellRenderer}
      columnWidth={dynamicColumWidth}
      columnCount={columnCount}
      rowCount={rowCount}
      headerCellHeight={headerCellHeight}
      isLoading={false}
      bodyCellHeight={bodyCellHeight}
      paddingSize="sm"
    />
  );
};

const useVirtualizedMultiGrid = ({
  spacesWithSetting,
}: {
  spacesWithSetting: SpaceWithSetting[];
}) => {
  const { generateRowsData } = useGenerateRowsData();
  const { headerCellRenderer } = useHeaderCellRenderer();
  const { bodyCellRenderer } = useBodyCellRenderer();

  const { onChangeIsAutoCancel } = useOnChangeIsAutoCancel();

  const rowsData = generateRowsData(spacesWithSetting);
  const rowCount = spacesWithSetting.length + 1; // +1 for header row;
  const columnCount = 2;
  const headerCellHeight = 80;
  const bodyCellHeight = 60;
  const dynamicColumWidth = ({ index }: Index) => {
    if (index === 0) {
      return 160;
    }
    return 300;
  };

  const cellRenderer: GridCellRenderer = useCallback(
    ({ key, style, rowIndex, columnIndex }) => {
      let cell: React.ReactNode = null;
      if (rowIndex === 0) {
        cell = headerCellRenderer({ columnIndex });
      } else {
        const rowData = rowsData[rowIndex - 1];
        if (rowData) {
          cell = bodyCellRenderer({
            columnIndex,
            rowData,
            onChangeIsAutoCancel,
          });
        }
      }
      const isLastRow = rowIndex === rowCount - 1;
      const isLastColumn = columnIndex === columnCount - 1;
      const conditionalStyle = {
        borderRight: isLastColumn ? undefined : `1px solid ${theme.palette.grey[100]}`,
        borderBottom: isLastRow ? undefined : `1px solid ${theme.palette.grey[100]}`,
      };
      return (
        <Box key={key} style={{ ...style, ...conditionalStyle }}>
          {cell}
        </Box>
      );
    },
    [bodyCellRenderer, headerCellRenderer, onChangeIsAutoCancel, rowCount, rowsData]
  );

  return {
    cellRenderer,
    dynamicColumWidth,
    rowCount,
    headerCellHeight,
    columnCount,
    bodyCellHeight,
  };
};

const useOnChangeIsAutoCancel = () => {
  const { updateSpaceSetting } = useUpdateSpaceSetting();
  const onChangeIsAutoCancel = useCallback(
    ({
      spaceId,
      shouldCancelDeliveryRegardlessOfScenario,
    }: {
      spaceId: string;
      shouldCancelDeliveryRegardlessOfScenario: boolean;
    }) => {
      updateSpaceSetting({
        shouldCancelDeliveryRegardlessOfScenario,
        targetSpaceId: spaceId,
      });
    },
    [updateSpaceSetting]
  );

  return { onChangeIsAutoCancel };
};

const useGenerateRowsData = () => {
  const generateRowsData = useCallback((spacesWithSetting: SpaceWithSetting[]): RowData[] => {
    return spacesWithSetting.map((spaceWithSetting) => {
      const spaceSetting = spaceWithSetting.spaceSetting;
      return {
        spaceId: spaceWithSetting.space.id,
        spaceName: spaceWithSetting.space.name,
        spaceSetting: spaceSetting && {
          id: spaceSetting.id,
          shouldCancelDeliveryRegardlessOfScenario:
            spaceSetting.shouldCancelDeliveryRegardlessOfScenario,
        },
      };
    });
  }, []);

  return { generateRowsData };
};
