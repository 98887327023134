import { Box } from "@material-ui/core";
import { OnnTask } from "@onn/common";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Chip, Loading } from "~/components/uiParts";
import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";

type Props = {
  onnTask: OnnTask;
};

export const ToDetailButtonCell: FC<Props> = ({ onnTask }) => {
  const { data: onnFormTaskAnswers = [], isLoading } = useOnnFormTasksAnswersOnlyCountable({
    onnTaskId: onnTask.id,
  });
  const navigation = useNavigate();
  if (isLoading) return <Loading size="small" fullHeight />;

  if (
    onnTask.isDeliverySettingsCompleted() &&
    !onnTask.isExceededScheduledDate() &&
    onnFormTaskAnswers.length > 0
  ) {
    // 配信待ちというテキストをChipで表示する
    return <StyledChip label="配信待ち" color="grey" bold />;
  }

  const shouldShowGoToAnswersButton =
    onnTask.isDeliverySettingsCompleted() && onnFormTaskAnswers.length > 0;

  return (
    <Box height={"30px"}>
      {shouldShowGoToAnswersButton ? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigation(`/onn_tasks/${onnTask.id}#answer`);
          }}
          color="default"
          variant="outlined"
          borderRadius="regular"
        >
          回答確認
        </Button>
      ) : (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigation(`/onn_tasks/${onnTask.id}/delivery_setting`);
          }}
          color="secondary"
          variant="outlined"
          borderRadius="regular"
        >
          配信設定
        </Button>
      )}
    </Box>
  );
};

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    font-size: 10px;
    height: 32px;
    padding: 0 8px;
    line-height: 13px;
    cursor: pointer;
  }
`;
