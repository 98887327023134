import { v4 } from "uuid";

import { ChangeRecruitmentStatusTriggerSetting as IChangeRecruitmentStatusTriggerSetting } from "../../_gen/zodSchema/index";

import { changeRecruitmentStatusTriggerSettingSchema } from "./schema";

export class ChangeRecruitmentStatusTriggerSetting
  implements IChangeRecruitmentStatusTriggerSetting
{
  static readonly type = "ChangeRecruitmentStatusTriggerSetting" as const;
  type = ChangeRecruitmentStatusTriggerSetting.type;

  static readonly validator = changeRecruitmentStatusTriggerSettingSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  triggerId: string;
  recruitmentStatusId: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<ChangeRecruitmentStatusTriggerSetting>, "type">) {
    const parsedInit = ChangeRecruitmentStatusTriggerSetting.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.triggerId = parsedInit.triggerId;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(
    params: Omit<
      ExcludeMethods<ChangeRecruitmentStatusTriggerSetting>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ) {
    return new ChangeRecruitmentStatusTriggerSetting({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
