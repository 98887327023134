import {
  AnyActionSetting,
  ChangeRecruitmentStatusActionSetting,
  DeliverOnnEventActionSetting,
  DeliverOnnTaskActionSetting,
  SendContactMessageActionSetting,
} from "@onn/common";
import React, { FC } from "react";

import { Icon } from "~/components/uiParts";

type Props = {
  type: AnyActionSetting["type"];
};

export const ActionIcon: FC<Props> = ({ type }) => {
  const icon: React.ComponentProps<typeof Icon>["icon"] = (() => {
    switch (type) {
      case SendContactMessageActionSetting.type:
        return "paperAirplane";
      case ChangeRecruitmentStatusActionSetting.type:
        return "statusChange";
      case DeliverOnnEventActionSetting.type:
        return "calendar";
      case DeliverOnnTaskActionSetting.type:
        return "checkOutlineCircle";
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = type;
        return _exhaustiveCheck;
    }
  })();
  const iconProps: React.ComponentProps<typeof Icon> = {
    size: "md",
    color: "grey",
    icon,
  };

  return <Icon {...iconProps} />;
};
