import { RemoveFromDeliveredNewGraduatesRequest } from "@onn/common";
import { useState } from "react";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export const useRemoveFromDeliveredNewGraduates = () => {
  const [isLoading, setIsLoading] = useState(false);

  const removeFromDeliveredNewGraduates = async ({
    onnEventAnswer,
  }: {
    onnEventAnswer: RemoveFromDeliveredNewGraduatesRequest["onnEventAnswer"];
  }) => {
    try {
      const data = RemoveFromDeliveredNewGraduatesRequest.createRequestBody({
        onnEventAnswer,
      });
      setIsLoading(true);
      const res = await functionOperator.httpsCallFor2ndGen<
        ReturnType<typeof RemoveFromDeliveredNewGraduatesRequest.createRequestBody>,
        { isNotified: boolean }
      >("onneventanswer", data);

      return res.data;
    } finally {
      setIsLoading(false);
    }
  };

  return { removeFromDeliveredNewGraduates, isLoading };
};
