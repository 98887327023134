// TODO: プレビューに対応させる
export const isAdminHostname = () => {
  const adminHostnameList = [
    "localhost",
    "staging.app.onn-hr.com",
    "app.onn-hr.com",
    "lab.app.onn-hr.com",
  ];
  return adminHostnameList.includes(window.location.hostname);
};
