import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// SYSTEM MESSAGE SCHEMA
/////////////////////////////////////////

export const SystemMessageSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  title: z.string(),
  contents: JsonValueSchema.array(),
  createdAt: z.date(),
});

export type SystemMessage = z.infer<typeof SystemMessageSchema>;

/////////////////////////////////////////
// SYSTEM MESSAGE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const SystemMessageOptionalDefaultsSchema = SystemMessageSchema.merge(
  z.object({
    createdAt: z.date().optional(),
  })
);

export type SystemMessageOptionalDefaults = z.infer<typeof SystemMessageOptionalDefaultsSchema>;

export default SystemMessageSchema;
