import { v4 } from "uuid";

import { IScenarioEditLockSchema, scenarioEditLockSchema } from "./schema";

export class ScenarioEditLock implements IScenarioEditLockSchema {
  private static validator = scenarioEditLockSchema;
  readonly id: string;
  readonly tenantId: string;
  readonly scenarioId: string;
  readonly spaceId: string;
  readonly isCompleted: boolean;
  readonly operationEmployeeId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: {
    id: string;
    tenantId: string;
    scenarioId: string;
    spaceId: string;
    isCompleted: boolean;
    operationEmployeeId: string;
    createdAt: Date;
    updatedAt: Date;
  }) {
    const parsedInit = ScenarioEditLock.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.scenarioId = parsedInit.scenarioId;
    this.spaceId = parsedInit.spaceId;
    this.isCompleted = parsedInit.isCompleted;
    this.operationEmployeeId = parsedInit.operationEmployeeId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  unlock() {
    return new ScenarioEditLock({
      ...this,
      isCompleted: true,
      updatedAt: new Date(),
    });
  }

  public static create(
    params: Omit<ExcludeMethods<ScenarioEditLock>, "id" | "createdAt" | "updatedAt">
  ) {
    return new ScenarioEditLock({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
