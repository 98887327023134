import { zodResolver } from "@hookform/resolvers/zod";
import { Scenario } from "@onn/common";
import { useForm as useRHF } from "react-hook-form";
import { z } from "zod";

import { useCreateScenario } from "~/hooks/scenario/useCreateScenario";
import { useUpdateScenario } from "~/hooks/scenario/useUpdateScenario";

type InputState = z.infer<ReturnType<typeof useGenerateInputZodSchema>>;

export const useForm = ({
  defaultValues,
  existingScenarios,
  onCancel,
  ...rest
}: {
  defaultValues: InputState;
  existingScenarios: Scenario[];
  onCancel: () => void;
} & (
  | {
      type: "create";
    }
  | {
      type: "update";
      scenarioId: string;
    }
)) => {
  const { createScenario } = useCreateScenario();
  const { updateScenario } = useUpdateScenario();
  const inputSchema = useGenerateInputZodSchema(existingScenarios);
  const form = useRHF<InputState>({
    defaultValues,
    mode: "onChange",
    resolver: zodResolver(inputSchema),
  });

  const onSubmit = form.handleSubmit(async () => {
    if (rest.type === "create") {
      await createScenario({
        scenarioName: form.getValues().scenarioName,
      });
    } else {
      await updateScenario({
        scenarioId: rest.scenarioId,
        scenarioName: form.getValues().scenarioName,
      });
    }
    onCancel();
  });

  return {
    ...form,
    onSubmit,
  };
};

const useGenerateInputZodSchema = (existingScenarios: Scenario[]) => {
  const inputSchema = z.object({
    scenarioName: z
      .string({
        required_error: "シナリオ名を入力してください",
      })
      .transform((value) => value.trim())
      .refine((value) => value.length > 0, "シナリオ名を入力してください") // NOTE: trim後はにmin相当のバリデーションを行う
      .refine((value) => {
        if (existingScenarios.some((s) => s.name === value)) {
          return false;
        }
        return true;
      }, "同じ名前のシナリオが既に存在します"),
  });

  return inputSchema;
};
