import { Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

export const ContentDescriptionTypography = React.memo(() => {
  return (
    <StyledTypography variant="caption">
      ※イベントの配信対象者の方へ通知/表示される文章です。イベントの概要や目的が伝わるような説明を入力しましょう。
    </StyledTypography>
  );
});

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.text.muted};
`;
