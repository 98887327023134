import { useNavigate } from "react-router-dom";

import { useFromPageInParam } from "~/pages/onn_task/_share/hooks/useFromPageInParam";

export const useOnClickCancelHandler = (onnFormTaskId: string) => {
  const navigation = useNavigate();
  const { fromPage } = useFromPageInParam();
  const onClickCancel = () => {
    if (fromPage === "detail") {
      navigation(`/onn_tasks/${onnFormTaskId}`);
    } else if (fromPage === "delivery_setting") {
      navigation(`/onn_tasks`);
    } else {
      navigation(`/onn_tasks`);
    }
  };

  return { onClickCancel };
};
