import { Box } from "@material-ui/core";
import styled from "styled-components";

import { mixin } from "~/util";

export const ContainerPaper = styled(Box)`
  padding: 40px;
  width: 100%;
  height: 100%;

  ${mixin.breakDown.sm`
    background-color: white;
    padding: 40px 24px;
  `}
`;
