import { Box, Stack } from "@mui/material";
import { postScenariosBlockSchema } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { z } from "zod";

import { Icon, IconButton, TooltipWhenTextTruncated, Typography } from "~/components/uiParts";
import { ActionDescription } from "~/pages/scenario/Main/components/TriggerBlock/ActionList/Action/ActionDescription/ActionDescription";
import { useActionDescriptionForEditMode } from "~/pages/scenario/Main/editMode/components/ActionAreaForEditMode/useActionDescriptionForEditMode";

type InputState = z.infer<typeof postScenariosBlockSchema>;

type Props = {
  triggerSetting: InputState["triggerSetting"];
  action: InputState["actions"][number]["action"];
  actionSetting: InputState["actions"][number]["actionSetting"];
  actionCondition: InputState["actions"][number]["actionCondition"];
  editButtonRef?: React.RefObject<HTMLButtonElement>;
  actionConditionErrorMessage?: string;
  onClickEditButton: () => void;
  onClickDeleteButton: () => void;
};
export const ActionDescriptionArea: FC<Props> = ({
  triggerSetting: _,
  action,
  actionSetting,
  actionCondition,
  editButtonRef,
  actionConditionErrorMessage,
  onClickEditButton,
  onClickDeleteButton,
}) => {
  const { description, conditionDescription, endLabel } = useActionDescriptionForEditMode({
    setting: actionSetting,
    condition: actionCondition,
    ...action,
  });
  const isInconsistencyError = !!actionConditionErrorMessage;

  return (
    <Root direction="row" columnGap="8px" alignItems="center" $isError={isInconsistencyError}>
      <DescriptionArea>
        {conditionDescription && (
          <Box width="100%">
            <TooltipWhenTextTruncated text={conditionDescription}>
              {(ref) => (
                <Typography
                  bold
                  variant="caption"
                  color={"textSecondary"}
                  component={"div"}
                  noWrap
                  ref={ref}
                >
                  {conditionDescription}
                </Typography>
              )}
            </TooltipWhenTextTruncated>
          </Box>
        )}
        <MainDescriptionArea>
          <ActionDescription
            type={actionSetting.type}
            description={description}
            endLabel={endLabel}
            isError={false}
            isEditMode={false}
          />
        </MainDescriptionArea>
        {isInconsistencyError && (
          <Box display="flex" alignItems="center" columnGap={"8px"}>
            <Icon icon="alert" size="md" color="secondary" />
            <Typography variant="caption" color="secondary">
              {actionConditionErrorMessage}
            </Typography>
          </Box>
        )}
      </DescriptionArea>
      <Box display="flex" ml="auto">
        <IconButton size="md" icon="pencil" onClick={onClickEditButton} buttonRef={editButtonRef} />
        <IconButton size="md" icon="trash" onClick={onClickDeleteButton} />
      </Box>
    </Root>
  );
};

const Root = styled(Stack)<{ $isError?: boolean }>`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid
    ${(props) =>
      props.$isError ? props.theme.palette.secondary.main : props.theme.palette.primary.main};
  height: ${(props) => (props.$isError ? "120px" : "80px")};
`;

const DescriptionArea = styled(Box)`
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  row-gap: 8px;
`;

const MainDescriptionArea = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
