import { Box, Menu } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useState, FC, useCallback, useMemo, memo } from "react";

import styled from "styled-components";

import {
  StatusForDisplayEventTable,
  statusToLabelForFilter,
} from "../utils/getStatusForDisplayEventTable";

import { StatusFilterSelectMenu } from "./StatusFilterSelectMenu";

import { Button, Icon, IconButton, Typography } from "~/components/uiParts";

const menuOptions: Array<{ value: StatusForDisplayEventTable; label: string }> = Object.entries(
  statusToLabelForFilter
).map(([status, label]) => ({ value: status as StatusForDisplayEventTable, label }));

type Props = {
  selectedStatusTypes: StatusForDisplayEventTable[];
  onSetSelectedStatusTypes: React.Dispatch<React.SetStateAction<StatusForDisplayEventTable[]>>;
};

const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClickOpenMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClickCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return { anchorEl, handleClickOpenMenu, handleClickCloseMenu };
};

const useOptionCheckBox = ({ selectedStatusTypes, onSetSelectedStatusTypes }: Props) => {
  const handleClickCheckBox = useCallback(
    (value: StatusForDisplayEventTable) => {
      if (selectedStatusTypes.includes(value)) {
        onSetSelectedStatusTypes((current) => current.filter((v) => v !== value));
      } else {
        onSetSelectedStatusTypes((current) => [...current, value]);
      }
    },
    [selectedStatusTypes, onSetSelectedStatusTypes]
  );
  return { handleClickCheckBox };
};

export const StatusFilter: FC<Props> = memo(({ selectedStatusTypes, onSetSelectedStatusTypes }) => {
  /**
   * 選択されているstatusに応じてラベルを表示する
   */
  const labelText = useMemo(() => {
    if (selectedStatusTypes.length === 0) return "選択してください";
    return selectedStatusTypes
      .map((statusToDisplay) => statusToLabelForFilter[statusToDisplay])
      .join(", ");
  }, [selectedStatusTypes]);

  const { handleClickCheckBox } = useOptionCheckBox({
    selectedStatusTypes,
    onSetSelectedStatusTypes,
  });

  const handleClickClear = useCallback(() => {
    onSetSelectedStatusTypes([]);
  }, [onSetSelectedStatusTypes]);

  const { anchorEl, handleClickOpenMenu, handleClickCloseMenu } = useMenu();

  return (
    <>
      <Box position="relative">
        <Button
          onClick={handleClickOpenMenu}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" noWrap>
              {labelText}
            </Typography>
            <StyledIcon
              icon="filter"
              size="sm"
              color="grey"
              $isEmpty={isEmpty(selectedStatusTypes)}
            />
          </Box>
        </Button>
        {/* Buttonのhoverも効いてしまうので内包しないようにする */}
        {!isEmpty(selectedStatusTypes) && (
          <StyledIconButton icon="close" size="sm" color="grey" onClick={handleClickClear} />
        )}
      </Box>
      <Menu
        key="statusFilter"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClickCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: -8, horizontal: "left" }}
      >
        <StatusFilterSelectMenu
          menuOptions={menuOptions}
          selected={selectedStatusTypes}
          onClickCheckBox={handleClickCheckBox}
        />
      </Menu>
    </>
  );
});

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;
