import React, { useCallback } from "react";

import { usePromptWithoutNavigate } from "~/hooks/shared/usePrompt";
import { useDownloadFilesByZip } from "~/pages/onn_task/_share/hooks/useDownloadFilesByZip";

export const useCurriedOnClickZipDL = () => {
  const { downloadFilesByZip, isLoading: isZipDownloading } = useDownloadFilesByZip();

  const curriedOnClickZipDL = useCallback(
    ({
        onnFormTaskId,
        onnFormTaskTitle,
        handleCloseMenu,
      }: {
        onnFormTaskId: string;
        onnFormTaskTitle: string;
        handleCloseMenu: () => void;
      }) =>
      async (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        await downloadFilesByZip({
          onnFormTaskId,
          onnFormTaskTitle,
        });
        handleCloseMenu();
      },
    [downloadFilesByZip]
  );

  usePromptWithoutNavigate(
    "Zipファイルをダウンロード中のため、画面を移動することはできません。",
    isZipDownloading
  );

  return { curriedOnClickZipDL };
};
