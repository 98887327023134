import { Box } from "@material-ui/core";
import React, { FC, memo } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

// 回答結果列のヘッダーのセル
export const AnswerStatusHeaderCell: FC = memo(() => {
  return (
    <StyledHeaderCellBox
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Typography
        display="block"
        variant="body1"
        noWrap
        // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
        style={{ lineHeight: 1.4, textAlign: "center" }}
      >
        ステータス
      </Typography>
    </StyledHeaderCellBox>
  );
});

const StyledHeaderCellBox = styled(Box)`
  height: 100%;
  width: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 0px 1px 0px 0px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;
