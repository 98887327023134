import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormRevision,
  OnnFormTaskAnswer,
  TaskOptionTypes,
  isCheckBoxAnswer,
  isFileAnswer,
  isRadioAnswer,
  isTextAnswer,
} from "@onn/common";
import { useCallback } from "react";
import { useForm as useRHF, useFormContext as useRHFFormContext } from "react-hook-form";
import { z } from "zod";

export const useFormContext = useRHFFormContext<InputState>;

export const useForm = ({
  formRevision,
  onnFormTaskAnswer,
}: {
  formRevision: FormRevision;
  onnFormTaskAnswer: OnnFormTaskAnswer;
}) => {
  const { generateDefaultValues } = useGenerateDefaultValues();

  const form = useRHF<InputState>({
    defaultValues: generateDefaultValues({
      formRevision,
      onnFormTaskAnswer,
    }),
    resolver: zodResolver(generateFormSchema({ formRevision })),
  });

  return form;
};

const useGenerateDefaultValues = () => {
  const generateDefaultValues = useCallback(
    ({
      formRevision,
      onnFormTaskAnswer,
    }: {
      formRevision: FormRevision;
      onnFormTaskAnswer: OnnFormTaskAnswer;
    }): InputState => {
      const questionIdToAnswerMap = new Map(
        onnFormTaskAnswer.answers.map((answer) => [answer.questionId, answer])
      );
      const answers = formRevision.questions.map((question) => {
        const answer = questionIdToAnswerMap.get(question.id);

        switch (question.type) {
          case "TEXT":
            return {
              questionId: question.id,
              type: TaskOptionTypes.TEXT,
              text: answer ? (isTextAnswer(answer) ? answer.value : "") : "",
            };
          case "RADIO":
            return {
              questionId: question.id,
              type: TaskOptionTypes.RADIO,
              selectedOptionId: answer
                ? isRadioAnswer(answer)
                  ? answer.selectedOptionId
                  : ""
                : "",
            };
          case "CHECK_BOX":
            return {
              questionId: question.id,
              type: TaskOptionTypes.CHECK_BOX,
              selectedOptionIds: answer
                ? isCheckBoxAnswer(answer)
                  ? answer.selectedOptionIds
                  : []
                : [],
            };
          case "FILE":
            return {
              questionId: question.id,
              type: TaskOptionTypes.FILE,
              filePath: answer ? (isFileAnswer(answer) ? answer.filePath : null) : null,
            };
          default: {
            const _: never = question;
            return _;
          }
        }
      });

      return {
        answers,
      };
    },
    []
  );

  return { generateDefaultValues };
};

// NOTE: 代理回答実装時にスキーマを実装
const generateFormSchema = ({ formRevision: _ }: { formRevision: FormRevision }) => {
  const formSchema = z.object({
    answers: z.array(
      z.discriminatedUnion("type", [
        z.object({
          questionId: z.string(),
          type: z.literal(TaskOptionTypes.TEXT),
          text: z.string(),
        }),
        z.object({
          questionId: z.string(),
          type: z.literal(TaskOptionTypes.RADIO),
          selectedOptionId: z.string(),
        }),
        z.object({
          questionId: z.string(),
          type: z.literal(TaskOptionTypes.CHECK_BOX),
          selectedOptionIds: z.array(z.string()),
        }),
        z.object({
          questionId: z.string(),
          type: z.literal(TaskOptionTypes.FILE),
          filePath: z.string().nullable(),
        }),
      ])
    ),
  });

  return formSchema;
};

type InputState = z.infer<ReturnType<typeof generateFormSchema>>;
