import { ITextPart } from "@onn/common";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  content: ITextPart;
};
export const TextPart: FC<Props> = ({ content }) => {
  return <Typography variant="body2">{content.text}</Typography>;
};
