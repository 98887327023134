import React, { FC } from "react";

export const Cross: FC = () => {
  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_148_3222)">
        <path
          d="M35.3131 15.5146C36.0942 14.7336 36.0942 13.4672 35.3131 12.6862C34.5321 11.9051 33.2658 11.9051 32.4847 12.6862L12.6857 32.4852C11.9047 33.2662 11.9047 34.5326 12.6857 35.3136C13.4668 36.0947 14.7331 36.0947 15.5141 35.3136L35.3131 15.5146Z"
          stroke="none"
        />
        <path
          d="M32.4853 35.3141C33.2663 36.0952 34.5326 36.0952 35.3137 35.3141C36.0947 34.5331 36.0947 33.2667 35.3137 32.4857L15.5147 12.6867C14.7337 11.9057 13.4673 11.9057 12.6863 12.6867C11.9052 13.4677 11.9052 14.7341 12.6863 15.5151L32.4853 35.3141Z"
          stroke="none"
        />
      </g>
    </svg>
  );
};
