import { Box, Menu } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, FC } from "react";
import styled from "styled-components";

import { EmployeeTagSelectMenu } from "../EmployeeTagSelectMenu";

import { Icon, Typography, Button } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  employeeTags: EmployeeTag[];
  selectedTagIds: string[];
  onChange: (selectedTagIds: string[]) => void;
};

// ====================
// main
// ====================

export const EmployeeTagSelect: FC<Props> = ({ employeeTags, selectedTagIds, onChange }) => {
  // ====================
  // state
  // ====================

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // ====================
  // component
  // ====================

  return (
    <>
      <Box position="relative">
        <StyledButtonWrapper>
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            borderRadius="regular"
            variant="outlined"
            color="default"
            fullWidth
          >
            <Box width="100%" display="flex" alignItems="center" gridGap="4px">
              <StyledTypography variant="body2" noWrap>
                {selectedTagIds.length
                  ? employeeTags
                      .flatMap((tag) => (selectedTagIds.includes(tag.id) ? tag.name : []))
                      .join(", ")
                  : "タグなし"}
              </StyledTypography>
              <StyledIcon icon="arrowDropDown" size="sm" color="grey" />
            </Box>
          </Button>
        </StyledButtonWrapper>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: -8, horizontal: "right" }}
      >
        {isEmpty(employeeTags) ? (
          <Box py="16px" width="318px">
            <Box px="24px">
              <Typography variant="caption" color="textSecondary">
                タグが存在しません
              </Typography>
            </Box>
          </Box>
        ) : (
          <EmployeeTagSelectMenu
            employeeTags={employeeTags}
            selectedTagIds={selectedTagIds}
            onChange={(selectedTagIds) => onChange(selectedTagIds)}
          />
        )}
      </Menu>
    </>
  );
};

// ====================
// style
// ====================

const StyledButtonWrapper = styled(Box)`
  /* TODO: buttonコンポーネントを修正してネスト指定が必要ないようにする */
  .MuiButtonBase-root > div {
    padding: 16px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: auto;
`;
