import { Box } from "@material-ui/core";
import React, { FC, useCallback, useMemo, useState } from "react";

import { ErrorMessageContent } from "./ErrorMessageContent";

import { Button, FilePicker, Icon, Typography } from "~/components/uiParts";
import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useCurrentUser } from "~/hooks/employee";
import { useConvertFileToBase64 } from "~/hooks/file";
import { useCreateOnnEventSlotDatesWithCsv } from "~/hooks/onnEventSlotDates/useCreateOnnEventSlotDatesWithCsv";
import { useNotifyOperationLog, usePrompt, useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

type Props = {
  onnEventId: string;
  onCancel: () => void;
};

const useHandleInputFile = ({ onnEventId }: { onnEventId: string }) => {
  const [loadedFileName, setLoadedFileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { notifyOperationLog, operationLog } = useNotifyOperationLog();
  const { currentUser } = useCurrentUser();

  const [isCheckingCsv, setIsCheckingCsv] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [inputFile, setInputFile] = useState<File | undefined>();
  const { createOnnEventSlotDatesWithCsv } = useCreateOnnEventSlotDatesWithCsv();
  const { convertFileToBase64 } = useConvertFileToBase64();

  const handleInputFile = useCallback(
    async (payload: Payload) => {
      try {
        setIsCheckingCsv(true);
        if (payload.status === "error") {
          enqueueSnackbar(payload.message, { variant: "error" });
          return;
        }

        const file = payload.files[0] as (typeof payload.files)[number];
        setInputFile(file);

        const base64EncodedCsvFile = await convertFileToBase64(file);

        const response = await createOnnEventSlotDatesWithCsv({
          onnEventId,
          base64EncodedCsvFile,
          isDryRun: true,
        });
        if (response.isOk) {
          setErrorMessages([]);
        } else {
          setErrorMessages(response.errorMessages);
          notifyOperationLog(
            operationLog.notifyCSVUploadError(
              currentUser,
              "[面談イベント]予約枠一括登録",
              response.errorMessages.join("\n")
            )
          );
        }
        setLoadedFileName(file.name);
      } catch (e) {
        captureException({
          error: e as Error,
          tags: {
            type: "CreateOnnEventSlotsWithCSVModal:UploadFileStepContent",
          },
          extras: {
            onnEventId,
            currentUser,
          },
        });
        enqueueSnackbar(
          "エラーが発生しました。もう一度お試しいただけますでしょうか。もし解決しない場合はお問い合わせください。",
          { variant: "error" }
        );
      } finally {
        setIsCheckingCsv(false);
      }
    },
    [
      convertFileToBase64,
      createOnnEventSlotDatesWithCsv,
      currentUser,
      enqueueSnackbar,
      notifyOperationLog,
      onnEventId,
      operationLog,
    ]
  );

  const fileLabel = useMemo(() => {
    if (loadedFileName) return loadedFileName;
    return "ファイルが選択されていません";
  }, [loadedFileName]);

  return { handleInputFile, fileLabel, errorMessages, isCheckingCsv, inputFile };
};

const useHandleUploadFile = ({
  onnEventId,
  inputFile,
  onCancel,
}: {
  onnEventId: string;
  inputFile: File | undefined;
  onCancel: () => void;
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const { createOnnEventSlotDatesWithCsv } = useCreateOnnEventSlotDatesWithCsv();

  const { convertFileToBase64 } = useConvertFileToBase64();
  const handleUpload = useCallback(async () => {
    if (!inputFile) return;

    try {
      setIsUploading(true);

      const base64EncodedCsvFile = await convertFileToBase64(inputFile);
      await createOnnEventSlotDatesWithCsv({
        onnEventId,
        base64EncodedCsvFile,
        isDryRun: false,
      });
      onCancel();
    } catch (e) {
      captureException({
        error: e as Error,
        tags: {
          type: "CreateOnnEventSlotsWithCSVModal:handleUpload",
        },
        extras: {
          onnEventId,
        },
      });
    } finally {
      setIsUploading(false);
    }
  }, [convertFileToBase64, createOnnEventSlotDatesWithCsv, inputFile, onCancel, onnEventId]);

  return { handleUpload, isUploading };
};

// TODO: 改修する際に components/domains/_share/UploadFileStepContent.tsxを使うようにする
export const UploadFileStepContent: FC<Props> = ({ onnEventId, onCancel }) => {
  const { handleInputFile, errorMessages, isCheckingCsv, fileLabel, inputFile } =
    useHandleInputFile({
      onnEventId,
    });

  const { handleUpload, isUploading } = useHandleUploadFile({
    onnEventId,
    inputFile,
    onCancel,
  });

  usePrompt("予約枠の一括登録が正常に完了しない場合があります", isUploading);

  const errorMessage = errorMessages.join("\n");
  const isUploadButtonDisabled =
    isCheckingCsv || errorMessages.length > 0 || !inputFile || isUploading;
  const isUploadButtonLoading = isCheckingCsv || isUploading;
  return (
    <>
      <Typography>準備したCSVファイルを選択し、アップロードしてください。</Typography>
      {errorMessage && (
        <Box mt={4}>
          <ErrorMessageContent errorMessage={errorMessage} />
        </Box>
      )}
      <Box height={48} mt={4} mb={5} gridGap={16} display="flex" alignItems="center">
        <FilePicker accepts={["csv"]} multiple={false} onChange={handleInputFile}>
          <Box height={48}>
            <Button
              startIcon={<Icon icon="clip" size="sm" color="primary" />}
              color={"primary"}
              borderRadius={"regular"}
              variant={"outlined"}
              fullHeight
            >
              ファイルを選択
            </Button>
          </Box>
        </FilePicker>
        <Typography variant="caption">{fileLabel}</Typography>
      </Box>
      <Box pl={5} pr={5} display="flex" alignItems="center" justifyContent="center">
        <Box width={"240px"} mr={3}>
          <Button
            fullWidth={true}
            color={"default"}
            borderRadius={"circle"}
            variant={"outlined"}
            onClick={onCancel}
          >
            キャンセル
          </Button>
        </Box>
        <Box width={"240px"}>
          <Button
            fullWidth={true}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
            onClick={handleUpload}
            disabled={isUploadButtonDisabled}
            isLoading={isUploadButtonLoading}
          >
            アップロード
          </Button>
        </Box>
      </Box>
    </>
  );
};
