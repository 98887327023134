import { Employee, NewGraduateToDisplay } from "@onn/common";
import { useContext } from "react";

import { CurrentUserContext } from "~/components/providers";

export const useCurrentUser = (): {
  currentUser: Employee | NewGraduateToDisplay;
  fetchCurrentUser: () => void;
} => {
  const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext);

  if (currentUser === undefined) {
    throw new Error("ログイン情報の取得に失敗しました");
  }

  return { currentUser, fetchCurrentUser };
};
