type Env = "localhost" | "staging" | "production" | "lab" | "preview" | "test" | undefined;

export const resolveHostname = (
  arg: { isAdmin: true; env: Env } | { isAdmin: false; tenantId: string; env: Env }
) => {
  if (arg.isAdmin) {
    switch (arg.env) {
      case "localhost":
        return "localhost";
      case "staging":
        return "staging.app.onn-hr.com";
      case "production":
        return "app.onn-hr.com";
      case "lab":
        return "lab.app.onn-hr.com";
      case "preview":
        return process.env.HOST;
      default:
        return null;
    }
  }

  switch (arg.env) {
    case "localhost":
      return `${arg.tenantId}.localhost`;
    case "staging":
      return `staging.${arg.tenantId}.onn-hr.com`;
    case "production":
      return `${arg.tenantId}.onn-hr.com`;
    case "lab":
      return `lab.${arg.tenantId}.onn-hr.com`;
    case "preview":
      return process.env.HOST;
    default:
      return null;
  }
};
