import React, { useCallback } from "react";

import { v4 } from "uuid";

import { Action } from "../../../_share_in_create_edit/reducer";

export const useCheckBoxQuestionFormHandler = (dispatch: React.Dispatch<Action>) => {
  const onChangeCheckBoxOptionLabel = useCallback(
    ({ questionId, optionId, label }: { questionId: string; optionId: string; label: string }) => {
      dispatch({
        type: "CHECKBOX_QUESTION_UPDATE_OPTION",
        payload: {
          questionId: questionId,
          optionId,
          text: label,
        },
      });
    },
    [dispatch]
  );

  const onClickDeleteCheckBoxOption = useCallback(
    ({ questionId, optionId }: { questionId: string; optionId: string }) => {
      dispatch({
        type: "CHECKBOX_QUESTION_DELETE_OPTION",
        payload: {
          questionId: questionId,
          optionId,
        },
      });
    },
    [dispatch]
  );

  const onClickAddCheckBoxOption = useCallback(
    ({ questionId }: { questionId: string }) => {
      dispatch({
        type: "CHECKBOX_QUESTION_ADD_OPTION",
        payload: {
          questionId: questionId,
          optionId: v4(),
        },
      });
    },
    [dispatch]
  );

  return { onChangeCheckBoxOptionLabel, onClickDeleteCheckBoxOption, onClickAddCheckBoxOption };
};
