import {
  OnnEventSlotDate,
  OnnEventSlotDateWithNumberOfParticipants,
  CandidateDateWithNumberOfParticipants,
  OnnEventDeterminedDate,
  OnnEventAnswer,
  OnnEvent,
  OnnEventPlace,
  BriefingSessionCategory,
} from "@onn/common";
import { useCallback } from "react";
import useSWR, { SWRResponse, mutate } from "swr";

import { useLocalStorage } from "../shared";

import { apiClient } from "~/libs";

export const ONN_EVENT_PREVIEW_DATA_KEY = "OnnEventDataForPreview";

export type OnnEventDataForPortal = {
  onnEvent: OnnEvent;
  onnEventAnswer: OnnEventAnswer;
  onnEventDeterminedDate: OnnEventDeterminedDate | null;
  onnEventPlaces: OnnEventPlace[];
  candidateDatesWithNumberOfParticipants: CandidateDateWithNumberOfParticipants[] | null;
  onnEventSlotDateWithNumberOfParticipants: OnnEventSlotDateWithNumberOfParticipants[];
  briefingSessionCategories: BriefingSessionCategory[];
};

const useGetOnnEventForPreview = () => {
  const { retrieveValue } = useLocalStorage();

  const getOnnEventForPreview = useCallback(() => {
    // TODO: データを一纏めにしたリレーションデータを common/domain 層に定義する https://app.clickup.com/t/86epcqf2g
    return retrieveValue<{
      onnEvent: ExcludeMethods<OnnEvent>;
      onnEventAnswer: ExcludeMethods<OnnEventAnswer>;
      onnEventDeterminedDate: ExcludeMethods<OnnEventDeterminedDate> | null;
      onnEventPlaces: ExcludeMethods<OnnEventPlace>[] | null;
      candidateDatesWithNumberOfParticipants:
        | ExcludeMethods<CandidateDateWithNumberOfParticipants>[]
        | null;
      onnEventSlotDateWithNumberOfParticipants:
        | ExcludeMethods<OnnEventSlotDateWithNumberOfParticipants>[]
        | null;
      briefingSessionCategories: BriefingSessionCategory[] | null;
    }>(ONN_EVENT_PREVIEW_DATA_KEY, {
      func: (v) => {
        return {
          onnEvent: OnnEvent.forceConvertToDate(v.onnEvent),
          onnEventAnswer: OnnEventAnswer.forceConvertToDate(v.onnEventAnswer),
          onnEventDeterminedDate: v.onnEventDeterminedDate
            ? OnnEventDeterminedDate.forceConvertToDate(v.onnEventDeterminedDate)
            : null,
          onnEventPlaces: v.onnEventPlaces
            ? v.onnEventPlaces.map((place) => OnnEventPlace.forceConvertToDate(place))
            : null,
          candidateDatesWithNumberOfParticipants:
            v.candidateDatesWithNumberOfParticipants?.map((data) => {
              return CandidateDateWithNumberOfParticipants.forceConvertToDate(data);
            }) || [],
          onnEventSlotDateWithNumberOfParticipants:
            v.onnEventSlotDateWithNumberOfParticipants?.map((data) => {
              return OnnEventSlotDateWithNumberOfParticipants.forceConvertToDate(data);
            }) || [],
          briefingSessionCategories:
            v.briefingSessionCategories?.map((data) => {
              return BriefingSessionCategory.forceConvertToDate(data);
            }) || [],
        };
      },
      onError: () => {
        throw new Error("プレビュー用データの取得に失敗しました");
      },
    });
  }, [retrieveValue]);

  return { getOnnEventForPreview };
};

const generateKey = (isPreview: boolean, employeeId: string, id?: string) => {
  return id
    ? ({
        endpoint: `/api/portal/onn-events/id`,
        employeeId: `${employeeId}`,
        id,
        isPreview,
      } as const)
    : null;
};

export const useOnnEventForPortal = ({
  employeeId,
  id,
  isPreview = false,
}: {
  employeeId: string;
  id?: string;
  isPreview?: boolean;
}): SWRResponse<OnnEventDataForPortal | null, Error> => {
  const { getOnnEventForPreview } = useGetOnnEventForPreview();

  return useSWR(generateKey(isPreview, employeeId, id), async ({ endpoint, id, isPreview }) => {
    let data;
    if (isPreview) {
      data = getOnnEventForPreview();
    } else {
      const response = await apiClient.get(endpoint, { id });
      data = response.data;
    }

    if (!data) return null;

    return {
      onnEvent: new OnnEvent(data.onnEvent),
      onnEventAnswer: new OnnEventAnswer(data.onnEventAnswer),
      onnEventDeterminedDate: data.onnEventDeterminedDate
        ? new OnnEventDeterminedDate(data.onnEventDeterminedDate)
        : null,
      onnEventPlaces: data.onnEventPlaces
        ? data.onnEventPlaces.map((data) => {
            return new OnnEventPlace(data);
          })
        : [],
      candidateDatesWithNumberOfParticipants: data.candidateDatesWithNumberOfParticipants
        ? data.candidateDatesWithNumberOfParticipants.map((data) => {
            return new CandidateDateWithNumberOfParticipants(data);
          })
        : null,
      onnEventSlotDateWithNumberOfParticipants: data.onnEventSlotDateWithNumberOfParticipants
        ? data.onnEventSlotDateWithNumberOfParticipants.map((data) => {
            return new OnnEventSlotDateWithNumberOfParticipants({
              onnEventSlotDate: new OnnEventSlotDate(data.onnEventSlotDate),
              numberOfParticipants: data.numberOfParticipants,
            });
          })
        : [],
      briefingSessionCategories: data.briefingSessionCategories
        ? data.briefingSessionCategories.map((data) => {
            return new BriefingSessionCategory(data);
          })
        : [],
    };
  });
};

export const mutateOnnEventForPortal = ({
  isPreview,
  employeeId,
  id,
}: {
  isPreview: boolean;
  employeeId: string;
  id: string;
}) => mutate(generateKey(isPreview, employeeId, id));
