import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { reminderFormSchema } from "./reminderFormSchema";

export const useReminderForm = (
  onSubmit: (params: { title: string; remindedAt: Date }) => void
) => {
  const form = useForm<{
    title: string;
    remindDate: {
      remindedAt: Date | null;
      remindHour: number;
    };
  }>({
    defaultValues: { title: "", remindDate: { remindedAt: null, remindHour: 10 } },
    mode: "onChange",
    resolver: zodResolver(reminderFormSchema),
  });

  return {
    ...form,
    handleSubmit: form.handleSubmit((values) => {
      const remindedAt = values.remindDate.remindedAt;
      if (!remindedAt) return;
      // この時点でremindedAtは必ず存在するが、型定義上は絞り込まれていないため絞り込む

      onSubmit({
        ...values,
        remindedAt,
      });
    }),
  };
};
