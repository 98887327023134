import { useTenant } from "../tenant";

export const useGenerateFilePath = () => {
  const {
    tenant: { tenantId },
  } = useTenant();

  const generateFilePath = ({
    announcementId,
    fileName,
  }: {
    announcementId: string;
    fileName: string;
  }) => {
    return `tenants/${tenantId}/announcements/${announcementId}/${fileName}`;
  };

  return { generateFilePath };
};
