import {
  AnswerOnnEventTriggerSetting,
  AnswerOnnTaskTriggerSetting,
  AnyTriggerSetting,
  ChangeRecruitmentStatusTriggerSetting,
  ChangeStatusOfOnnEventTriggerSetting,
  EvaluateOnnEventTriggerSetting,
} from "@onn/common";
import React, { FC, useMemo } from "react";

import { Icon } from "~/components/uiParts";

type Props = { type: AnyTriggerSetting["type"] };

export const TriggerIcon: FC<Props> = ({ type }) => {
  const icon: React.ComponentProps<typeof Icon>["icon"] = useMemo(() => {
    switch (type) {
      case ChangeRecruitmentStatusTriggerSetting.type:
        return "statusChange" as const;
      case AnswerOnnTaskTriggerSetting.type:
        return "checkOutlineCircle" as const;
      case AnswerOnnEventTriggerSetting.type:
        return "calendar" as const;
      case EvaluateOnnEventTriggerSetting.type:
        return "memo" as const;
      case ChangeStatusOfOnnEventTriggerSetting.type:
        return "calendar" as const;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = type;
        return _exhaustiveCheck;
    }
  }, [type]);

  const iconSize: React.ComponentProps<typeof Icon>["size"] = (() => {
    switch (type) {
      case ChangeRecruitmentStatusTriggerSetting.type:
        return "ssm" as const;
      default:
        return "md" as const;
    }
  })();

  const iconProps: React.ComponentProps<typeof Icon> = {
    size: iconSize,
    color: "primary",
    icon,
  };
  return <Icon {...iconProps} />;
};
