import { Box } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography, Chip } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  tags: ExcludeMethods<EmployeeTag>[];
};

// ====================
// main
// ====================

/**
 * 候補者に付与されているタグ一覧
 */
export const NewGraduateTags: FC<Props> = ({ tags }) => {
  // ====================
  // component
  // ====================

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body2" bold color="textPrimary">
        タグ
      </Typography>

      <Box my="8px" />

      <StyledBox>
        {tags.length ? (
          tags.map((v) => <Chip key={v.id} label={v.name} color="muted" isOutlined />)
        ) : (
          <Typography variant="caption" color="textSecondary">
            タグが設定されていません
          </Typography>
        )}
      </StyledBox>
    </Box>
  );
};

// ====================
// style
// ====================

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 4px;
`;
