import { Action, AnyActionCondition, AnyActionSetting } from "@onn/common";
import { useCallback } from "react";

import { useGetTriggerFormName } from "../useGetTriggerFormName";
import { useFormContext } from "../useScenarioForm";

export const useAddActionToForm = ({ recruitmentStatusId }: { recruitmentStatusId: string }) => {
  const { getTriggerFormName } = useGetTriggerFormName();
  const { setValue, getValues } = useFormContext();

  const addActionToForm = useCallback(
    ({
      action,
      actionSetting,
      actionCondition,
      triggerId,
    }: {
      action: Action;
      actionSetting: AnyActionSetting;
      actionCondition?: AnyActionCondition;
      triggerId: string;
    }) => {
      const triggerFormName = getTriggerFormName({
        recruitStatusId: recruitmentStatusId,
        triggerId,
      });
      const values = getValues(`${triggerFormName}.actions`);
      setValue(`${triggerFormName}.actions`, [
        ...values,
        {
          action,
          actionSetting,
          actionCondition,
        },
      ]);
    },
    [getTriggerFormName, getValues, recruitmentStatusId, setValue]
  );

  return { addActionToForm };
};
