import { Box } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";

import { ErrorMessageContent } from "./ErrorMessageContent";

import { Button, FilePicker, Icon, Typography } from "~/components/uiParts";

type Props = {
  fileLabel: string;
  errorMessage: string;
  isUploadButtonLoading: boolean;
  isUploadButtonDisabled: boolean;
  filePickerProps: Omit<ComponentProps<typeof FilePicker>, "children">;
  onCancel: () => void;
  handleOnClickUpload: () => void;
};

export const UploadFileStepContent: FC<Props> = ({
  fileLabel,
  errorMessage,
  isUploadButtonLoading,
  isUploadButtonDisabled,
  filePickerProps,
  onCancel,
  handleOnClickUpload,
}) => {
  return (
    <>
      <Typography>準備したCSVファイルを選択し、アップロードしてください。</Typography>
      {errorMessage && (
        <Box mt={4}>
          <ErrorMessageContent errorMessage={errorMessage} />
        </Box>
      )}
      <Box height={48} mt={4} mb={5} gridGap={16} display="flex" alignItems="center">
        <FilePicker {...{ ...filePickerProps }}>
          <Box height={48}>
            <Button
              startIcon={<Icon icon="clip" size="sm" color="primary" />}
              color={"primary"}
              borderRadius={"regular"}
              variant={"outlined"}
              fullHeight
            >
              ファイルを選択
            </Button>
          </Box>
        </FilePicker>
        <Typography variant="caption">{fileLabel}</Typography>
      </Box>
      <Box pl={5} pr={5} display="flex" alignItems="center" justifyContent="center">
        <Box width={"240px"} mr={3}>
          <Button
            fullWidth={true}
            color={"default"}
            borderRadius={"circle"}
            variant={"outlined"}
            onClick={onCancel}
          >
            キャンセル
          </Button>
        </Box>
        <Box width={"240px"}>
          <Button
            fullWidth={true}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
            onClick={handleOnClickUpload}
            disabled={isUploadButtonDisabled}
            isLoading={isUploadButtonLoading}
          >
            アップロード
          </Button>
        </Box>
      </Box>
    </>
  );
};
