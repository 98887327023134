import { Box } from "@material-ui/core";
import React from "react";

import styled from "styled-components";

import { AnyInputCondition, AnyTarget } from "../../types/condition";

import { LogicType } from "../../types/logic-type";

import { ConditionRow } from "./ConditionRow";

type Props = {
  conditions: AnyInputCondition[];
  logicType: LogicType;
  onSelectLogicType(logicType: LogicType): void;
  onRemoveCondition(index: number): void;
  onChangeTarget(index: number, changedTarget: AnyTarget): void;
  onChangeCondition(index: number, changedCondition: AnyInputCondition): void;
};
export const ConditionForm = ({
  conditions,
  logicType,
  onSelectLogicType,
  onChangeTarget,
  onChangeCondition,
  onRemoveCondition,
}: Props): JSX.Element => {
  return (
    <Root width={"100%"}>
      {conditions.map((condition, index) => (
        <ConditionRow
          key={`${condition.target ?? "undefined"}-${index}`}
          index={index}
          condition={condition}
          logicType={logicType}
          onSelectLogicType={onSelectLogicType}
          onChangeTarget={onChangeTarget}
          onChangeCondition={onChangeCondition}
          onRemove={onRemoveCondition}
        />
      ))}
    </Root>
  );
};

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  margin-bottom: 16px; // TODO: 外に出す
`;
