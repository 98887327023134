import { Failure, Result, OnnEvent, Success } from "../../../../domain";
import { toCloudFunctionsCompatible } from "../../../../utils/toCloudFunctionsCompatible";
import { OnnEventDeterminedDateRequestType } from "../shared";

import { listOnnEventDeterminedDateRequestSchema } from "./schema";

export class ListOnnEventDeterminedDateRequest {
  constructor(init: ListOnnEventDeterminedDateRequest) {
    this.onnEventId = init.onnEventId;
  }

  static readonly actionType =
    OnnEventDeterminedDateRequestType.actionTypes.listOnnEventDeterminedDates;
  onnEventId: OnnEvent["id"];

  static RequestBody: {
    onnEventId: OnnEvent["id"];
  };

  // クライアント側でリクエストボディを作成する
  public static createRequestBody(init: ListOnnEventDeterminedDateRequest) {
    return toCloudFunctionsCompatible({
      ...init,
      actionType: this.actionType,
    });
  }

  static readonly validator = listOnnEventDeterminedDateRequestSchema;

  public static validate(
    data: typeof ListOnnEventDeterminedDateRequest.RequestBody
  ): Result<ListOnnEventDeterminedDateRequest, Error> {
    const onnEventIdResult = ListOnnEventDeterminedDateRequest.validator.safeParse(data);
    if (!onnEventIdResult.success) {
      return new Failure(new Error(onnEventIdResult.error.message));
    }

    return new Success(new ListOnnEventDeterminedDateRequest(data));
  }
}
