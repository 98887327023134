import { zodResolver } from "@hookform/resolvers/zod";
import { employeeInformationValueSchema } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import styled from "styled-components";

import { InformationValueEditor } from "../InformationValueEditor";

import { TextFieldDeprecated } from "~/components/uiParts";

type Props = {
  onSave: ({ phoneNumber }: { phoneNumber: string }) => void;
  onCancel: () => void;
  data?: string;
};

export const PhoneNumberEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, control, formState } = useForm<{ phoneNumber: string }>({
    defaultValues: {
      phoneNumber: data,
    },
    mode: "onChange",
    resolver: zodResolver(employeeInformationValueSchema.pick({ phoneNumber: true })),
  });

  return (
    <InformationValueEditor
      form={
        <form style={{ display: "flex", columnGap: 16 }} onSubmit={handleSubmit(onSave)}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                defaultValue={data}
                placeholder="000-1234-5678"
                variant="outlined"
                fullWidth
                name={field.name}
                error={!!error}
                helperText={error?.message}
                onChange={field.onChange}
              />
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;
