import { Box, FormControlLabel, Menu, MenuItem } from "@material-ui/core";
import { format } from "date-fns";
import { first, isEmpty } from "lodash";
import React, { FC, useState } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { EventDescriptionTypography } from "../OnnEventNewOrEdit/EventDescriptionTypography";
import { EventTitleTypography } from "../OnnEventNewOrEdit/EventTitleTypography";
import { NoSlotPaper } from "../OnnEventNewOrEdit/NoSlotPaper";
import { PageWrapper } from "../OnnEventNewOrEdit/PageWrapper";

import { SelectedPlaceInfoPart } from "../OnnEventNewOrEdit/SelectedPlaceInfoPart";

import { useAnswerNewInterviewForm } from "./useAnswerNewInterviewForm";

import { Button, Divider, Icon, RadioButton, Typography } from "~/components/uiParts";
import { StaticDatePicker } from "~/components/uiParts/StaticDatePicker";
import { Tag } from "~/components/uiParts/Tag";
import theme from "~/config/theme";

import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventForPortal";
import { mixin } from "~/util";

type Props = {
  eventData: OnnEventDataForPortal;
};

export const OnnEventNewOrEditPageNewInterview: FC<Props> = ({ eventData }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const {
    control,
    setValue,
    trigger,
    isDisabledSubmit,
    isLoading,
    isDisabledDate,
    selectedEventPlace,
    selectedOnnEventSlotDate,
    submit,
    eventType,
    selectableEventPlace,
    selectableSlotDates,
    selectableSlotHours,
    isFixedEventTypes,
    selectableEventTypes,
  } = useAnswerNewInterviewForm(eventData);
  const isDisplayNoSlotPaper = selectableEventTypes.length === 0;

  return (
    <PageWrapper isWhiteBackgroundForSp={!isDisplayNoSlotPaper}>
      <EventTitleTypography onnEventTitle={eventData.onnEvent.title} />
      <EventDescriptionTypography description={eventData.onnEvent.content} />

      {isDisplayNoSlotPaper ? (
        <NoSlotPaper />
      ) : (
        <form onSubmit={submit}>
          <StyledCard>
            {isFixedEventTypes ? (
              <Box
                p="16px"
                bgcolor={theme.palette.grey[50]}
                borderRadius="8px"
                display="flex"
                gridColumnGap="8px"
              >
                <Typography variant="body2" color="textPrimary" bold style={{ width: "80px" }}>
                  開催方法
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {eventType === "online" ? "オンライン" : "オフライン・現地"}
                </Typography>
              </Box>
            ) : (
              <>
                <Box display="flex" flexDirection="column" gridRowGap={24}>
                  <Typography variant="body2" color="textPrimary">
                    以下の候補から参加可能な日時を選択してください。
                  </Typography>
                  <Divider />
                </Box>
                <Box display="flex" flexDirection="column" gridRowGap={24}>
                  <Box display="flex" alignItems="center" gridColumnGap={8}>
                    <Typography variant="body1" color="textPrimary" bold>
                      開催方法を選択
                    </Typography>
                    <Tag color="secondary" size="sm" text="必須" />
                  </Box>
                  <Controller
                    name="eventType"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <Box display="flex" flexDirection="column" gridRowGap={16}>
                        <StyledFormControlLabel
                          value="online"
                          control={
                            <StyledRadioButton
                              color={"primary"}
                              checked={value === "online"}
                              onChange={() => {
                                setValue("eventPlaceId", null);
                                setValue("selectedDate", null);
                                setValue("onnEventSlotDateId", undefined);
                                onChange("online");
                              }}
                            />
                          }
                          label={<Typography variant="body2">オンライン</Typography>}
                        />
                        <StyledFormControlLabel
                          value="offline"
                          control={
                            <StyledRadioButton
                              color={"primary"}
                              checked={value === "offline"}
                              onChange={() => {
                                setValue("eventPlaceId", null);
                                setValue("selectedDate", null);
                                setValue("onnEventSlotDateId", undefined);
                                onChange("offline");
                              }}
                            />
                          }
                          label={<Typography variant="body2">オフライン・現地</Typography>}
                        />
                      </Box>
                    )}
                  />
                </Box>
              </>
            )}
            {eventType === "offline" && (
              <Box display="flex" flexDirection="column" gridRowGap={24}>
                <Box display="flex" alignItems="center" gridColumnGap={8}>
                  <Typography variant="body1" color="textPrimary" bold>
                    開催場所を選択
                  </Typography>
                  <Tag color="secondary" size="sm" text="必須" />
                </Box>
                <Box display="flex" flexDirection="column" gridRowGap={16}>
                  <Controller
                    name="eventPlaceId"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <>
                        <Button
                          color="default"
                          variant="outlined"
                          borderRadius="regular"
                          fullWidth
                          onClick={(e) => setAnchorEl(e.currentTarget)}
                          endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
                        >
                          <Box flex={1} display={"flex"}>
                            <Typography variant="body2" noWrap>
                              {selectedEventPlace?.name || "対象を選択"}
                            </Typography>
                          </Box>
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClick={() => setAnchorEl(null)}
                          getContentAnchorEl={null}
                          MenuListProps={{
                            // NOTE: デフォルトだと短くなってしまうためアンカー要素と幅を同じにする
                            style: { width: anchorEl?.clientWidth },
                          }}
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                          transformOrigin={{ vertical: "top", horizontal: "right" }}
                        >
                          {selectableEventPlace.map((onnEventPlace) => (
                            <MenuItem
                              key={onnEventPlace.id}
                              onClick={() => {
                                setValue("selectedDate", null);
                                setValue("onnEventSlotDateId", undefined);
                                trigger("onnEventSlotDateId");
                                onChange(onnEventPlace.id);
                              }}
                            >
                              <Typography variant="body2">{onnEventPlace.name}</Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      </>
                    )}
                  />
                  {selectedEventPlace && (
                    <SelectedPlaceInfoPart
                      selectedEventPlace={selectedEventPlace}
                      selectedOnnEventSlotDate={selectedOnnEventSlotDate}
                    />
                  )}
                </Box>
              </Box>
            )}
            <Box display="flex" flexDirection="column" gridRowGap={24}>
              <Box display="flex" alignItems="center" gridColumnGap={8}>
                <Typography variant="body1" color="textPrimary" bold>
                  日付を選択
                </Typography>
                <Tag color="secondary" size="sm" text="必須" />
              </Box>
              <Controller
                name="selectedDate"
                control={control}
                render={({ field }) => (
                  <StaticDatePicker
                    {...field}
                    referenceDate={first(selectableSlotDates)?.onnEventSlotDate.from || new Date()}
                    shouldDisableDate={isDisabledDate}
                  />
                )}
              />
            </Box>
            {!isEmpty(selectableSlotHours) && (
              <Box display="flex" flexDirection="column" gridRowGap={24}>
                <Box display="flex" alignItems="center" gridColumnGap={8}>
                  <Typography variant="body1" color="textPrimary" bold>
                    時間を選択
                  </Typography>
                  <Tag color="secondary" size="sm" text="必須" />
                </Box>
                <Controller
                  name="onnEventSlotDateId"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Box display="flex" flexDirection="column" gridRowGap={16}>
                      {selectableSlotHours.map(({ onnEventSlotDate }) => (
                        <StyledFormControlLabel
                          key={onnEventSlotDate.id}
                          value={onnEventSlotDate.id}
                          control={
                            <StyledRadioButton
                              color={"primary"}
                              checked={value === onnEventSlotDate.id}
                              onChange={onChange}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              {`${format(onnEventSlotDate.from, "H:mm")}〜${format(
                                onnEventSlotDate.until,
                                "H:mm"
                              )}`}
                            </Typography>
                          }
                        />
                      ))}
                    </Box>
                  )}
                />
              </Box>
            )}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              borderRadius="circle"
              onClick={submit}
              disabled={isDisabledSubmit}
              isLoading={isLoading}
            >
              回答を送信
            </Button>
          </StyledCard>
        </form>
      )}
    </PageWrapper>
  );
};

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;

  ${mixin.portalSp`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 28px;
    padding: 0px;
    width: 100%;
  `}
`;

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  margin-right: 8px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiButtonBase-root {
    padding: 2px 0px;
    margin-right: 8px;
  }
`;
