import { Box } from "@mui/material";
import React, { FC } from "react";

import { StyledAnchor } from "../../../common/StyledAnchor";
import { Cell } from "../../_share/Cell";

export const OnnTaskTitleCell: FC<{
  onnTaskTitle: string;
  href: string;
}> = ({ href, onnTaskTitle }) => {
  return (
    <Cell sx={{ "&.MuiTableCell-root": { padding: 0 } }}>
      {/* イベント行で表示する3点ドットの横幅40px分だけ余白を指定している */}
      <Box display="flex" alignItems="center" pl="40px">
        <Box p="16px">
          <StyledAnchor target="_blank" href={href}>
            {onnTaskTitle}
          </StyledAnchor>
        </Box>
      </Box>
    </Cell>
  );
};
