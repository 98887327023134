import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { FC } from "react";
import QRCode from "react-qr-code";
import { createSearchParams } from "react-router-dom";
import styled from "styled-components";

import { Button, Modal, Typography } from "~/components/uiParts";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useDownloadSvg } from "~/hooks/useDownloadSvg";
import { generateNewGraduateHost } from "~/libs";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: OnnEvent;
};

export const EventAttendanceQRModal: FC<Props> = ({ open, onCancel, onnEvent }) => {
  const { divRef: qrCodeDivRef, downloadSvg } = useDownloadSvg("イベント参加登録QRコード");

  const queryParam = new URLSearchParams({
    "space-id": onnEvent.spaceId,
    ...(onnEvent.registrationInvitationLinkId
      ? {
          "registration-invitation-link-id": onnEvent.registrationInvitationLinkId,
        }
      : {}),
  });

  const { currentSpace } = useCurrentSpace();
  const baseUrl = generateNewGraduateHost(currentSpace.tenantId);

  const attendanceUrl =
    baseUrl + `/portal/events/${onnEvent.id}/attendance?${createSearchParams(queryParam)}`;

  return (
    <StyledModal
      open={open}
      title="参加登録QRコード"
      titleHint="イベントごとに固有の参加登録QRコードが発行されます。"
      onCancel={onCancel}
      content={
        <>
          <Box display="flex" justifyContent="center" mb="40px">
            <div ref={qrCodeDivRef}>
              <QRCode value={attendanceUrl} style={{ height: "160px", width: "160px" }} />
            </div>
          </Box>
          <Box textAlign="center">
            <Typography>
              「{onnEvent.title}」の参加登録用QRコードです
              <br />
              画像をダウンロードして候補者に参加登録を促しましょう
            </Typography>
          </Box>
          <Box textAlign="center" mt="20px">
            <Typography variant="caption" color="textSecondary">
              ※ QRコードによるアカウント登録時の招待リンクID:{" "}
              {onnEvent.registrationInvitationLinkId || "デフォルト"}
              <br />※ 参加予定者への参加登録依頼の通知設定:{" "}
              {onnEvent.shouldRequestAttendance ? "あり" : "なし"}
            </Typography>
          </Box>
        </>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            戻る
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={downloadSvg}
          >
            画像をダウンロード
          </Button>
        </StyledButtonContainer>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
