import { Box } from "@mui/material";
import { CheckBoxQuestion } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

export const CheckboxQuestionAndAnswer: FC<{
  question: CheckBoxQuestion;
  selectedOptionIds: string[];
  onChangeCheckBox: (optionId: string) => void;
  isDisabled?: boolean;
}> = ({ question, selectedOptionIds, onChangeCheckBox, isDisabled }) => {
  return (
    <>
      {question.options.map((option) => {
        return (
          <Box key={option.id}>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={selectedOptionIds.includes(option.id)}
                  onChange={() => onChangeCheckBox(option.id)}
                  disabled={isDisabled}
                />
              }
              label={
                <Typography variant="body2" color="textPrimary" noWrap>
                  {option.text}
                </Typography>
              }
              disabled={isDisabled}
            />
          </Box>
        );
      })}
    </>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
