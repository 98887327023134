import { Employee, Role } from "@onn/common";
import { UserCredential } from "firebase/auth";

import { EmployeeRepository, AccountRepository } from "~/infrastructure/api/employeeRepository";

export interface IEmployeeRepository {
  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  findById(employeeId: string): Promise<Employee | undefined>;
  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  whereByIds(employeeIds: string[]): Promise<Employee[]>;
  update(employeeId: string, newObject: Partial<Employee>): Promise<void>;
  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  findAll(tenantId: string): Promise<Employee[]>;
  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  findAllWithDeleted(): Promise<Employee[]>;
  findAllNewcomers(tenantId: string): Promise<Employee[]>;
  /**
   * apiで権限チェックも行っているのでrepositoryの責務を超えたメソッドになっている
   * @deprecated 新規でこのメソッドを利用しない。
   */
  findByRole(tenantId: string, role: Role): Promise<Employee[]>;
  checkMailExist(email: string): Promise<boolean>;
  deleteNewHire(employeeId: string): Promise<void>;
}
export interface IAccountRepository {
  findByUid(): Promise<Employee[]>;
  signIn(email: string, password: string): Promise<void>;
  signInWithCustomToken(customToken: string): Promise<UserCredential>;

  sendPasswordResetEmail(email: string): Promise<void>;
  updatePassword(currentPassword: string, newPassword: string): Promise<void>;
  updateEmail(newEmail: string, currentPassword: string): Promise<void>;
}
export const factory = {
  employeeRepository: (): IEmployeeRepository => {
    return new EmployeeRepository();
  },
  accountRepository: (): IAccountRepository => {
    return new AccountRepository();
  },
};
