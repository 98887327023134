import {
  AnswerOnnEventTriggerSetting,
  AnswerOnnTaskTriggerSetting,
  ChangeRecruitmentStatusTriggerSetting,
  ChangeStatusOfOnnEventTriggerSetting,
  EvaluateOnnEventTriggerSetting,
  TriggerWithRelationInfo,
} from "@onn/common";
import { useMemo } from "react";

type Props = {
  trigger: TriggerWithRelationInfo;
};

export const useDescriptionForViewMode = (props: Props) => {
  // NOTE: イベント評価トリガーの場合のみ、サブ説明文を表示する
  const triggerSubDescription = useMemo(() => {
    const triggerSetting = props.trigger.triggerSetting;
    const triggerType = triggerSetting.type;

    switch (triggerType) {
      case EvaluateOnnEventTriggerSetting.type:
        return triggerSetting.onnEventTitle;
      case ChangeRecruitmentStatusTriggerSetting.type:
      case AnswerOnnTaskTriggerSetting.type:
      case AnswerOnnEventTriggerSetting.type:
      case ChangeStatusOfOnnEventTriggerSetting.type:
        return undefined;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = triggerType;
        return _exhaustiveCheck;
    }
  }, [props.trigger.triggerSetting]);

  const description = useMemo(() => {
    const triggerSetting = props.trigger.triggerSetting;
    const triggerType = triggerSetting.type;

    switch (triggerType) {
      case EvaluateOnnEventTriggerSetting.type:
        return `候補者の評価が入力されたとき`;
      case ChangeRecruitmentStatusTriggerSetting.type:
        return `選考ステータスが「${triggerSetting.recruitmentStatusLabel}」に変更された時`;
      case AnswerOnnTaskTriggerSetting.type:
        return `「${triggerSetting.onnTaskTitle}」が「回答済み」に変更された時`;
      case AnswerOnnEventTriggerSetting.type:
        return `「${triggerSetting.onnEventTitle}」が「回答済み」に変更された時`;
      case ChangeStatusOfOnnEventTriggerSetting.type:
        switch (triggerSetting.changeType) {
          case "ATTEND":
            return `「${triggerSetting.onnEventTitle}」が「参加済み」に変更された時`;
          default:
            // eslint-disable-next-line no-case-declarations
            const _exhaustiveCheck: never = triggerSetting.changeType;
            return _exhaustiveCheck;
        }
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = triggerType;
        return _exhaustiveCheck;
    }
  }, [props.trigger.triggerSetting]);

  return { description, triggerSubDescription };
};
