import { zodResolver } from "@hookform/resolvers/zod";
import { OnnEvent, urlSchema } from "@onn/common";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { useUpdateOnnEventV2 } from "~/hooks/onnEvent/useUpdateOnnEventV2";

import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

export const useSlotDefaultValueForm = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [searchParams] = useSearchParams();
  const navigation = useNavigate();
  const fromPage = searchParams.get("from_page");
  const { enqueueSnackbar } = useSnackbar();
  const { updateOnnEvent } = useUpdateOnnEventV2();

  const submitButtonText = fromPage === "delivery_setting" ? "保存して次へ" : "変更を保存";

  const form = useForm({
    defaultValues: {
      online: {
        description: onnEvent.slotDefaultValueSetting?.online?.description,
        url: onnEvent.slotDefaultValueSetting?.online?.url,
      },
      offline: {
        description: onnEvent.slotDefaultValueSetting?.offline?.description,
      },
    },
    resolver: zodResolver(
      z.object({
        online: z.object({
          description: z.string().optional(),
          url: urlSchema.optional(),
        }),
        offline: z.object({ description: z.string().optional() }),
      })
    ),
  });

  const onClickCancel = useCallback(() => {
    if (fromPage === "detail") {
      navigation(`/events/${onnEvent.id}`);
    } else {
      navigation(`/events`);
    }
  }, [fromPage, navigation, onnEvent.id]);

  const handleSubmit = form.handleSubmit(async (inputValue) => {
    setIsSaving(true);

    updateOnnEvent({ updateObject: { slotDefaultValueSetting: inputValue }, id: onnEvent.id })
      .then(() => {
        enqueueSnackbar("イベント設定を更新しました。", { variant: "success" });
        navigation(`/events/${onnEvent.id}`);
      })
      .catch((e) => {
        enqueueSnackbar("イベント設定の更新に失敗しました。", { variant: "error" });
        // NOTE: apiClientを通していないリクエストになるのでhooksでエラーログを送信する
        captureException({
          error: e as Error,
          tags: { type: "usePage:onClickSaveAndToNext" },
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  });

  return {
    form,
    isSaving,
    onClickCancel,
    handleSubmit,
    submitButtonText,
  };
};
