import { MenuItem } from "@material-ui/core";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

export const ZipDLMenuItem: FC<{
  onClickZipDL: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
}> = ({ onClickZipDL }) => {
  return (
    <MenuItem onClick={onClickZipDL}>
      <Typography variant="body2">zipダウンロード</Typography>
    </MenuItem>
  );
};
