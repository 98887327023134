import { BriefingSessionCategory } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

const generateKey = ({ onnEventId }: { onnEventId: string }) => {
  return {
    path: "/onn_event_api/briefing-session-categories",
    onnEventId,
  } as const;
};

export const useBriefingSessionCategories = ({ onnEventId }: { onnEventId: string }) => {
  return useSWR(generateKey({ onnEventId }), async ({ path, onnEventId }) => {
    const response = await apiClient.get(path, { onnEventId }, { isNotifyError: true });
    return response.data.map((d) => new BriefingSessionCategory(d));
  });
};

export const mutateBriefingSessionCategories = async ({ onnEventId }: { onnEventId: string }) =>
  mutate(generateKey({ onnEventId }));
