import { Box } from "@material-ui/core";
import { OnnFormTaskAnswer, OnnTask } from "@onn/common";
import { format } from "date-fns";
import React, { FC, memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";

type OnnTaskAnswerStatusType = "default" | "done" | "danger" | "overdue";
export type OnnTaskWithAnswer = { onnTask: OnnTask; onnTaskAnswer: OnnFormTaskAnswer };

type Props = {
  onnTaskWithAnswer: OnnTaskWithAnswer;
};

export const PortalOnnTaskCard: FC<Props> = memo(({ onnTaskWithAnswer }) => {
  const { onnTask } = onnTaskWithAnswer;
  const statusType = getOnnTaskAnswerStatusType(onnTaskWithAnswer);
  const { border, titleColor, captionColor, icon } = getCardStyle(statusType);

  return (
    <StyledLink to={`/portal/onn_tasks/${onnTask.id}/landing`}>
      <StyledDiv $border={Boolean(border)}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" p={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="body2" color={titleColor}>
              {onnTask.title}
            </Typography>

            {onnTask.deadlineDate && (
              <Typography variant="caption" color={captionColor}>
                {`期日：〜${format(onnTask.deadlineDate, "yyyy/MM/dd")}` +
                  (onnTask.isExceededDeadlineDate() ? " | 回答期限切れ" : "")}
              </Typography>
            )}
          </Box>

          {icon}
        </Box>
      </StyledDiv>
    </StyledLink>
  );
});

/**
 * onnTaskの回答状況から判別しやすいステータスタイプを返却する
 */
const getOnnTaskAnswerStatusType = ({
  onnTask,
  onnTaskAnswer,
}: OnnTaskWithAnswer): OnnTaskAnswerStatusType => {
  // 回答済みの場合
  if (onnTaskAnswer.isAnswered()) return "done";
  // 回答期限が迫っている場合 (回答期限3日前から当日まで)
  if (onnTask.isNearDeadline()) return "danger";
  // 回答期限が過ぎているが回答可能の場合
  if (onnTask.isExceededDeadlineDate() && onnTask.canAnswer()) return "danger";
  // 回答期限が過ぎているかつ回答不可の場合
  if (!onnTask.canAnswer()) return "overdue";
  // デフォルト
  return "default";
};

/**
 * onnTaskの回答状況に応じてカードのstyleを決定する
 */
const getCardStyle = (statusType: OnnTaskAnswerStatusType) => {
  switch (statusType) {
    case "default":
      return {
        titleColor: "textPrimary",
        captionColor: "textSecondary",
        icon: <Icon size="sm" icon={"arrowRight"} color="primary" />,
        border: false,
      } as const;
    case "done":
      return {
        titleColor: "textPrimary",
        captionColor: "textSecondary",
        icon: <Icon size="md" icon={"checkMarkFill"} color="primary" />,
        border: true,
      } as const;
    case "danger":
      return {
        titleColor: "secondary",
        captionColor: "secondary",
        icon: <Icon size="sm" icon={"arrowRight"} color="secondary" />,
        border: false,
      } as const;
    case "overdue":
      return {
        titleColor: "textPrimary",
        captionColor: "textSecondary",
        icon: <Icon size="sm" icon={"arrowRight"} color="grey" />,
        border: false,
      } as const;
    default:
      throw new Error("Invalid status type");
  }
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledDiv = styled.div<{ $border: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.default};
  box-shadow: ${(props) => props.theme.shadows[10]};
  list-style-type: none;
  cursor: pointer;
  border-radius: 15px;
  ${(props) => props.$border && `border: 1px solid ${props.theme.palette.primary.main}`}
`;
