import { z } from "zod";

import { OnnEventChangeTypeSchema } from "../inputTypeSchemas/OnnEventChangeTypeSchema";
import { TriggerSettingTypeSchema } from "../inputTypeSchemas/TriggerSettingTypeSchema";

/////////////////////////////////////////
// TRIGGER SETTINGS SCHEMA
/////////////////////////////////////////

export const TriggerSettingsSchema = z.object({
  type: TriggerSettingTypeSchema,
  changeType: OnnEventChangeTypeSchema.nullish(),
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  triggerId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  recruitmentStatusId: z.string().nullish(),
  onnEventId: z.string().nullish(),
  onnTaskId: z.string().nullish(),
});

export type TriggerSettings = z.infer<typeof TriggerSettingsSchema>;

/////////////////////////////////////////
// TRIGGER SETTINGS OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const TriggerSettingsOptionalDefaultsSchema = TriggerSettingsSchema.merge(z.object({}));

export type TriggerSettingsOptionalDefaults = z.infer<typeof TriggerSettingsOptionalDefaultsSchema>;

export default TriggerSettingsSchema;
