import { Box } from "@material-ui/core";
import { OnnEventAnswerForDisplay, BriefingSessionEvent, NewInterviewEvent } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import {
  ContextProviderOfOnnEventSlotSearchModal,
  useContextOfOnnEventSlotSearchModal,
} from "./Context";
import { ModalContent } from "./modal-content/ModalContent";

import { AnyInputCondition, AnyValidCondition } from "./types/condition";
import { LogicType } from "./types/logic-type";

import { Button, ScrollableBodyModal } from "~/components/uiParts";

type Props = {
  modalType: "slot" | "answer";
  open: boolean;
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
  defaultLogicType: LogicType;
  defaultConditions: AnyInputCondition[];
  defaultSearchResultCount: number | undefined;
  onnEventAnswersForDisplay?: OnnEventAnswerForDisplay[];
  allSlotsCount?: number;
  onCancel: () => void;
  onSearchConfirm: (logicType: LogicType, conditions: AnyValidCondition[]) => Promise<void>;
};

/**
 * イベントの予約枠を詳細検索するためのモーダル
 * ※ 工数短縮のため、イベント回答結果の詳細検索モーダルとしても使用している
 *   modalType で予約枠タブで使用するものか、回答結果タブで使用するものかを切り替えている
 *
 * 検索条件を増やすときはNewGraduateSearchModalを参考にしてください
 */
export const OnnEventSlotSearchModal: FC<Props> = (props) => {
  return (
    <ContextProviderOfOnnEventSlotSearchModal {...props}>
      <OnnEventSlotSearchModalCore open={props.open} onCancel={props.onCancel} />
    </ContextProviderOfOnnEventSlotSearchModal>
  );
};

const OnnEventSlotSearchModalCore: FC<Pick<Props, "open" | "onCancel">> = ({ open, onCancel }) => {
  const { handleSearch, isLoadingSearch, isLoadingChange, searchResultCount, modalType } =
    useContextOfOnnEventSlotSearchModal();

  return (
    <ScrollableBodyModal
      open={open}
      title={"絞り込み"}
      content={<ModalContent />}
      footer={
        <StyledButtonContainer gridGap={"16px"}>
          <Box>{/* TODO: 条件を保存して使用できるようにする */}</Box>
          <Box>
            <Button borderRadius="circle" variant="text" color="default" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              borderRadius="circle"
              variant="contained"
              color="primary"
              onClick={handleSearch}
              isLoading={isLoadingSearch}
            >
              {`${isLoadingChange ? "-" : searchResultCount}件の${
                modalType === "slot" ? "予約枠" : "面談予定"
              }を表示`}
            </Button>
          </Box>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;
