import { Box } from "@material-ui/core";
import { toggleItemInArray } from "@onn/common";
import React, { FC, useCallback } from "react";

import styled from "styled-components";

import { Typography } from "~/components/uiParts";
import { MultipleSelector } from "~/components/uiParts/MultipleSelector";
import { useEditModeContext } from "~/pages/scenario/Main/editMode/EditModeContextProvider";

export type TagSelectionFormProps = TagSelectorProps;
export const TagSelectionForm: FC<TagSelectionFormProps> = (props) => {
  return (
    <Root>
      <Typography variant="caption" bold>
        タグ
      </Typography>
      <TagSelector {...props} />
    </Root>
  );
};

type TagSelectorProps = {
  values?: string[];
  onChange: (newValues: string[]) => void;
  errorMessage?: string;
};

const TagSelector: FC<TagSelectorProps> = ({ values = [], onChange, errorMessage }) => {
  const { allTags, allTagsMap } = useEditModeContext();

  const inputLabel =
    values.length > 0 ? values.map((id) => allTagsMap.get(id)?.name).join(",") : "----";

  const onChangeCheckbox = useCallback(
    (rankId: string) => {
      const newValues = toggleItemInArray(values, rankId);
      onChange(newValues);
    },
    [onChange, values]
  );

  return (
    <MultipleSelector
      values={values}
      menuItemOptions={allTags.map((tag) => ({
        label: tag.name,
        value: tag.id,
      }))}
      inputLabel={inputLabel}
      onChangeCheckBox={onChangeCheckbox}
      menuProps={{
        style: { maxHeight: 300 },
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
        transformOrigin: { vertical: "top", horizontal: "left" },
      }}
      errorMessage={errorMessage}
    />
  );
};

const Root = styled(Box)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;
