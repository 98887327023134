import { Box } from "@material-ui/core";
import { DEFAULT_MAX_FILE_SIZE_MB } from "@onn/common";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { InputState } from "../formUtils/zodFormSchema";

import { TextareaAutosizeAttachableFile, Typography } from "~/components/uiParts";

type Props = {
  form: UseFormReturn<InputState>;
};

export const RemarkInputPart: React.FC<Props> = ({ form }) => {
  return (
    <Box>
      <Box mb="16px" display="flex" alignItems="center">
        <Typography variant="body2" bold>
          備考
        </Typography>
      </Box>
      <Box>
        <Controller
          name="remark"
          control={form.control}
          render={({ field }) => {
            const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
              field.onChange({ note: e.target.value, files: field.value.files });
              form.trigger("remark");
            };
            const onChangeFiles = (files: (File | Pick<File, "name">)[]) => {
              field.onChange({ note: field.value.note, files });
              form.trigger("remark");
            };
            const errorMessage = form.formState.errors.remark?.files?.message;
            return (
              <TextareaAutosizeAttachableFile
                fullWidth
                value={field.value.note}
                placeholder={`アクセス方法やURLを記載しましょう`}
                onChange={onChangeText}
                minRows={4}
                accepts={["image"]}
                inputAccept="image/*"
                onChangeFiles={onChangeFiles}
                attachedFiles={field.value.files}
                maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};
