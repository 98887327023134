import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { FC } from "react";

import {
  ContentDescriptionTypography,
  TitleTextField,
  ContentTextarea,
  ActionFooter,
  CandidateDateRHF,
} from "../../_shared/createAndEdit";
import { RestrictAnswerRHF } from "../../_shared/createAndEdit/ReactHooksFormComponents/RestrictAnswerRHF";

import { usePage } from "./hooks/usePage";

import { Paper, Typography } from "~/components/uiParts";

export const ForNormalAndInterview: FC<{
  eventType: Extract<OnnEvent["type"], "normal" | "new_interview">;
}> = ({ eventType }) => {
  const {
    isDisableSaveButton,
    footerAction: {
      onClickCancel,
      isReadyToOpenPortalOnnEventPreview,
      onClickSaveAndToNext,
      onClickPreview,
    },
    candidateForm: { fromTimeItems, untilTimeItems },
    form,
  } = usePage({ eventType });

  const { control, trigger, watch } = form;
  return (
    <Box display="flex" justifyContent="center">
      <Box width="800px" display="flex" flexDirection="column" gridRowGap={40} pb={8}>
        <Typography variant="h4" bold>
          イベント作成
        </Typography>
        <Paper style={{ display: "flex", flexDirection: "column", rowGap: 32 }}>
          <Box display="flex" flexDirection="column" gridRowGap={16}>
            <Box display="flex" flexDirection="column" gridRowGap={32}>
              <TitleTextField control={control} />
              <ContentTextarea control={control} />
            </Box>
            <ContentDescriptionTypography />
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap={16}>
            <Typography variant="body2" bold>
              イベントタイプ
            </Typography>
            <Typography variant="body1">
              {watch("type") === "new_interview" ? "選考" : "その他"}
            </Typography>
          </Box>
        </Paper>
        <Paper style={{ display: "flex", flexDirection: "column", rowGap: 40 }}>
          {watch("type") === "normal" && (
            <CandidateDateRHF
              control={control}
              trigger={trigger}
              displayDeliveryFromTimeItems={fromTimeItems}
              displayDeliveryUntilTimeItems={untilTimeItems}
            />
          )}
          <RestrictAnswerRHF control={control} onnEventType={watch("type")} />
        </Paper>
        <ActionFooter
          isReadyToOpenPortalOnnEventPreview={isReadyToOpenPortalOnnEventPreview}
          isDisableSaveButton={isDisableSaveButton}
          onClickSaveAndToNext={onClickSaveAndToNext}
          onClickCancel={onClickCancel}
          onClickPreview={watch("type") === "new_interview" ? undefined : onClickPreview}
        />
      </Box>
    </Box>
  );
};
