import { Answer } from "@onn/common/domain/OnnTask/Answer";

import { OnnTaskFormAnswerDraftUseCase } from "~/service/usecases/onnTaskFormAnswerDraftUseCase";
import { OnnTaskFormAnswerDraft } from "~/types/firestore/OnnTaskFormAnswerDraft";

const onnTaskFormAnswerDraftUsecase = new OnnTaskFormAnswerDraftUseCase();

export const useUpdateOnnTaskFormAnswerDraft = (draft: OnnTaskFormAnswerDraft) => {
  const updateDraft = async (answers: Answer[]) => {
    await onnTaskFormAnswerDraftUsecase.save({ ...draft, answers });
  };

  return { updateDraft };
};
