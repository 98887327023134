import { Box, TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

export const headers: Array<{ label: string; width: number }> = [
  { label: "シナリオ", width: 176 },
  { label: "選考ステータス", width: 176 },
  { label: "ヨミ", width: 176 },
  // { label: "次の予定", width: 120 }, // TODO
];

export const TableHeaderRow: FC = () => {
  return (
    <TableRow>
      {headers.map((v, index) => (
        <HeaderCell key={index} text={v.label} width={v.width} />
      ))}
      {/* HACK: なぜかwidth指定のない空のセルを追加しないとセルの幅が調整できなかったので空のTableCellを入れている */}
      <TableCell></TableCell>
    </TableRow>
  );
};

export const HeaderCell: FC<{
  text: string;
  width: number;
}> = ({ text, width }) => {
  return (
    <StyledTableCell
      style={{
        padding: "0px", // NOTE: styled-components で指定してもdefaultのpaddingが優先されるため、ここで指定する
      }}
      $width={`${width}px`}
      width={`${width}px`}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="overline" color="textSecondary">
          {text}
        </Typography>
      </Box>
    </StyledTableCell>
  );
};

export const StyledTableCell = styled(TableCell)<{ $width: string }>`
  text-align: left;
  margin: 0px;
  padding-right: 16px;
  height: 36px;
  width: ${(props) => props.$width};
`;
