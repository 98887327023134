import liff from "@line/liff";
import React, { FC, useCallback } from "react";

import { useParams } from "react-router-dom";

import { ErrorPage } from "~/components/domains/account/ErrorPage";
import { QRCodePage } from "~/components/domains/account/QRCodePage";

import { useLiffUrl } from "~/hooks/liff";
import { isMobileBrowser } from "~/util";

export const RegisterIndividualByLineWrapper: FC<{
  dataLineLoginChannelInfo: {
    clientId: string;
    liffId: string;
  };
  children: React.ReactNode;
}> = ({ dataLineLoginChannelInfo, children }) => {
  const { type } = useParams<"type">();

  const { openLiff, createLiffUrl } = useLiffUrl();
  const pathForLine = location.pathname;

  const startLineRegistrationForMobileBrowser = useCallback(() => {
    if (isMobileBrowser() && dataLineLoginChannelInfo) {
      openLiff({ liffId: dataLineLoginChannelInfo.liffId, path: pathForLine });
    }
  }, [dataLineLoginChannelInfo, openLiff, pathForLine]);

  if (!dataLineLoginChannelInfo) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="個別登録リンク遷移のために必要なログインチャネル情報を取得できませんでした"
        extraInfo={{ type }}
      />
    );
  }

  if (!type) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="URL が正しくありません"
        extraInfo={{ type }}
      />
    );
  }

  if (liff.isInClient() && type !== "line") {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="LINE認証タイプではないリンクは LIFF では開けません"
        extraInfo={{ type }}
      />
    );
  }

  if (type === "line" && !liff.isInClient()) {
    const liffUrl = createLiffUrl({
      liffId: dataLineLoginChannelInfo.liffId,
      path: location.pathname,
    });
    return (
      <QRCodePage
        urlForQRCode={liffUrl}
        startLineRegistration={startLineRegistrationForMobileBrowser}
      />
    );
  }

  return children;
};
