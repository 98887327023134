import React, { FC } from "react";

import { Button, Icon } from "~/components/uiParts";

type Props = {
  onClick: () => void;
  // 表示パターンが2種類しかないのでtypeで管理している
  type: "login" | "registration";
};

export const LineButton: FC<Props> = ({ onClick, type }) => {
  return (
    <Button fullWidth variant="contained" color="line" borderRadius="circle" onClick={onClick}>
      {/* FIXME: サイズを lg にする(lgにすると、ボタンが必要以上に縦長になってしまう) */}
      {/* FIXME: ボタンのホバー時に、アイコンの周りが透過しない */}
      <Icon icon="lineChat" size="md" color="white" />
      {type === "login" ? "LINEでログイン" : "LINEで登録"}
    </Button>
  );
};
