import { Box, TextField, Tooltip } from "@material-ui/core";
import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";

import { Checkbox, Icon, IconButton, RadioButton } from "~/components/uiParts";

export const OptionInputRow: FC<{
  optionLabel: string;
  optionId: string;
  handleDeleteOption: (id: string) => void;
  handleOnChangeOptionLabel: (id: string, label: string) => void;
  disabled?: boolean;
  isShowDeleteIcon: boolean;
  questionType: "RADIO" | "CHECK_BOX";
  deleteIconOption?: {
    disabled: true;
    reason: string;
  };
}> = ({
  optionId,
  optionLabel,
  handleDeleteOption,
  handleOnChangeOptionLabel,
  disabled,
  questionType,
  isShowDeleteIcon,
  deleteIconOption,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    ref.current?.focus();
  }, []);
  const onChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleOnChangeOptionLabel(optionId, e.target.value);
  };
  return (
    <StyledWrapper
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <StyledGrip id="gripVerticalWrapper" display="flex" height="16px" minWidth="16px">
        <Icon icon="gripVertical" size="sm" color="lightGrey" />
      </StyledGrip>
      {questionType === "RADIO" && <RadioButton onChange={undefined} disabled />}
      {questionType === "CHECK_BOX" && <Checkbox onChange={undefined} disabled />}
      <StyledTextField
        value={optionLabel}
        onChange={onChangeLabel}
        fullWidth
        inputRef={ref}
        disabled={disabled}
      />
      {isShowDeleteIcon && (
        <Box ml="16px">
          <Tooltip title={deleteIconOption?.reason || ""} placement="top">
            <Box>
              <IconButton
                icon="close"
                onClick={() => handleDeleteOption(optionId)}
                disabled={deleteIconOption?.disabled}
              />
            </Box>
          </Tooltip>
        </Box>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  margin-bottom: 20px;

  #gripVerticalWrapper > svg {
    display: none;
  }
  &:hover,
  &:active {
    #gripVerticalWrapper > svg {
      display: inline-block;
    }
  }
`;

const StyledGrip = styled(Box)`
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translate(0, -50%);
`;

const StyledTextField = styled(TextField)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
  .MuiInputBase-input {
  }
`;
