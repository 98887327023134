import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Checkbox, FormControlLabel, Modal, Typography } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  title: string;
  cancelLabel?: string;
  acceptLabel?: string;
  acceptButtonColor?: "primary" | "secondary";
  onClickAccept: () => Promise<void>;
  mainContent: string | React.ReactNode;
  subContent?: string;
};

export const ConfirmModalWithCheckbox: FC<Props> = ({
  open,
  onCancel,
  title,
  cancelLabel = "キャンセル",
  acceptLabel = "削除",
  acceptButtonColor = "secondary",
  onClickAccept,
  mainContent,
  subContent,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const _onClickAccept = useCallback(async () => {
    setIsLoading(true);
    try {
      await onClickAccept();
      onCancel();
    } finally {
      setIsLoading(false);
    }
  }, [onCancel, onClickAccept]);

  return (
    <Modal
      open={open}
      title={title}
      content={
        <>
          <Box mb={2} textAlign="center" display="flex" flexDirection="column" gridGap="18px">
            {typeof mainContent === "string" ? (
              <Typography variant="body1">{mainContent}</Typography>
            ) : (
              mainContent
            )}
          </Box>
          {subContent && (
            <Box width={"100%"} textAlign={"center"}>
              <Typography variant="caption" color="textSecondary">
                {subContent}
              </Typography>
            </Box>
          )}
          <Box textAlign={"center"} marginTop={"40px"}>
            <StyledFormControlLabel
              control={
                <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
              }
              label={
                <Typography variant="body2" display="inline">
                  上記確認しました
                </Typography>
              }
            />
          </Box>
        </>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            {cancelLabel}
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color={acceptButtonColor}
            onClick={_onClickAccept}
            isLoading={isLoading}
            disabled={isLoading || !isChecked}
          >
            {acceptLabel}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 4px;
  }
  .MuiCheckbox-root {
    margin-left: 9px;
    margin-right: 8px;
    padding: 0;
  }
  .MuiTypography-root {
    line-height: 1;
  }
`;
