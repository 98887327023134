import { Box } from "@material-ui/core";
import { AnyActionCondition, AnyTriggerSetting } from "@onn/common";
import React, { FC, useMemo } from "react";

import { Button, Typography, Icon, UncontrolledMenu } from "~/components/uiParts";

type MenuOptionsLabelMap = {
  [K in AnyActionCondition["type"]]: {
    label: string;
    key: K;
  };
} & {
  null: {
    label: string;
    key: null;
  };
};

const menuOptionsLabelMap: MenuOptionsLabelMap = {
  EmployeeTagActionCondition: {
    label: "特定のタグが付与されている場合",
    key: "EmployeeTagActionCondition",
  },
  OnnFormTaskResultActionCondition: {
    label: "特定の回答がされた場合",
    key: "OnnFormTaskResultActionCondition",
  },
  OnnEventEvaluationActionCondition: {
    label: "特定の評価がついた場合",
    key: "OnnEventEvaluationActionCondition",
  },
  null: { label: "なし", key: null },
};

export type ActionConditionTypeSelectionFormProps = {
  value?: AnyActionCondition["type"] | null;
  onChange: (value: AnyActionCondition["type"] | null) => void;
  errorMessage?: string;
  triggerSetting: AnyTriggerSetting;
};

export const ActionConditionTypeSelectionForm: FC<ActionConditionTypeSelectionFormProps> = ({
  onChange,
  value,
  errorMessage,
  triggerSetting,
}) => {
  const selected = menuOptionsLabelMap[value || "null"];

  const menuItemOptions = useMemo(() => {
    return Object.values(menuOptionsLabelMap).flatMap((status) => {
      if (
        status.key === "OnnFormTaskResultActionCondition" &&
        triggerSetting.type !== "AnswerOnnTaskTriggerSetting"
      ) {
        return [];
      }

      if (
        status.key === "OnnEventEvaluationActionCondition" &&
        triggerSetting.type !== "EvaluateOnnEventTriggerSetting"
      ) {
        return [];
      }

      return {
        text: status.label,
        onClick: () => {
          onChange(status.key);
        },
      };
    });
  }, [onChange, triggerSetting.type]);

  return (
    <Box>
      <UncontrolledMenu
        renderButton={(openMenu) => (
          <Button
            color="default"
            variant="outlined"
            borderRadius="regular"
            fullWidth
            onClick={openMenu}
            endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
          >
            <Box width={"100%"} flex={1} display={"flex"}>
              <Typography variant="body2" noWrap>
                {selected?.label ?? "なし"}
              </Typography>
            </Box>
          </Button>
        )}
        menuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
        }}
        menuItemOptions={menuItemOptions}
      />
      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
