import { useCallback } from "react";

import { useModal } from "~/hooks/modal";

export const useCreateEventPlaceModal = () => {
  const { handleModal } = useModal();

  const openCreateEventPlaceModal = useCallback(() => {
    handleModal({
      name: "createOrUpdateEventPlaceModal",
      args: {
        data: {
          mode: "create",
          onnEventPlace: undefined,
        },
      },
    });
  }, [handleModal]);

  return { openCreateEventPlaceModal };
};
