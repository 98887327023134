import { Box, Grid, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export const FirstAndLastNameInput = ({
  firstNameError,
  lastNameError,
  tooLongNameError,
  onChangeFirstName,
  onChangeLastName,
  firstName,
  lastName,
}: {
  firstNameError?: boolean;
  lastNameError?: boolean;
  tooLongNameError?: boolean;
  onChangeFirstName: (value: string) => void;
  onChangeLastName: (value: string) => void;
  firstName: string;
  lastName: string;
}) => {
  const { t } = useTranslation(["account"]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            error={lastNameError || tooLongNameError}
            label={t("lastName.label")}
            name="lname"
            variant="outlined"
            type="text"
            margin="normal"
            size="medium"
            color="primary"
            value={lastName}
            helperText={
              (lastNameError && t("lastName.validation.empty")) ||
              (tooLongNameError && t("lastName.validation.tooLong"))
            }
            onChange={(event) => onChangeLastName(event.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            error={firstNameError || tooLongNameError}
            label={t("firstName.label")}
            name="fname"
            variant="outlined"
            type="text"
            margin="normal"
            size="medium"
            color="primary"
            value={firstName}
            helperText={firstNameError && t("firstName.validation.empty")}
            onChange={(event) => onChangeFirstName(event.target.value)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
