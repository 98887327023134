import { OnnEvent } from "@onn/common";
import React from "react";

import { useOnClickCreateButton } from "../hooks/useOnClickCreateButton";

import { Button, Icon } from "~/components/uiParts";

export const CreateEventButton = ({ eventType }: { eventType: OnnEvent["type"] }) => {
  const { onClickCreateButton } = useOnClickCreateButton(eventType);
  return (
    <Button
      color="primary"
      variant="outlined"
      borderRadius="regular"
      startIcon={<Icon icon="add" color="primary" size="md" />}
      onClick={onClickCreateButton}
      fullHeight
    >
      新規作成
    </Button>
  );
};
