import { Employee, OnnEventAnswer } from "@onn/common";
import { useCallback, useState } from "react";

/**
 * 登録済みの候補者を管理するフック
 */
export const useRegisteredNewGraduates = (
  onnEventAnswers: OnnEventAnswer[]
): {
  keptNewGraduateIds: string[];
  isDeleted: boolean;
  deleteFromRegisteredNewGraduates: (target: Employee) => void;
} => {
  const [keptNewGraduateIds, setKeptNewGraduateIds] = useState<string[]>(
    onnEventAnswers.map((answer) => answer.employeeId)
  );
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const deleteFromRegisteredNewGraduates = useCallback(
    (target: Employee) => {
      setKeptNewGraduateIds(keptNewGraduateIds.filter((id) => id !== target.id));
      setIsDeleted(true);
    },
    [keptNewGraduateIds]
  );

  return {
    keptNewGraduateIds,
    isDeleted,
    deleteFromRegisteredNewGraduates,
  };
};
