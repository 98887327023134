import { Announcement, AnnouncementDistribution } from "@onn/common";
import useSWR from "swr";

import { ANNOUNCEMENT_PREVIEW_DATA_KEY } from "../openPortalPreview/useOpenPortalAnnouncementPreview";
import { useLocalStorage } from "../shared";

import { apiClient } from "~/libs";

export const useAnnouncementForPortal = ({
  announcementId,
  isPreview = false,
}: {
  announcementId?: string;
  isPreview?: boolean;
}) => {
  const { retrieveValue } = useLocalStorage();

  return useSWR(
    announcementId
      ? (["/announcement_api/announcement-for-portal", announcementId, isPreview] as const)
      : null,
    async ([endpoint, announcementId, isPreview]) => {
      let data;
      let res;
      if (isPreview) {
        const dataFromStorage = retrieveValue<{ announcement: ExcludeMethods<Announcement> }>(
          ANNOUNCEMENT_PREVIEW_DATA_KEY,
          {
            func: (v) => v,
            onError: () => {
              throw new Error("プレビュー用データの取得に失敗しました");
            },
          }
        );
        data = {
          announcement: dataFromStorage?.announcement
            ? Announcement.forceConvertToDate(dataFromStorage.announcement)
            : null,
          announcementDistribution: null,
        };
      } else {
        res = await apiClient.get(endpoint, { announcementId }).then((res) => res);
        data = res.data;
      }

      if (!data.announcement) {
        throw new Error("お知らせの取得に失敗しました");
      }

      return {
        announcement: new Announcement(data.announcement),
        announcementDistribution: data.announcementDistribution
          ? new AnnouncementDistribution(data.announcementDistribution)
          : null,
        meta: res?.meta,
      };
    }
  );
};
