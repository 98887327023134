import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { AnyEmployeeInformationField } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Loading, Typography } from "~/components/uiParts";

export const EmployeeCustomInformationTable: FC<{
  fields: {
    fieldId: AnyEmployeeInformationField["id"];
    fieldLabel: AnyEmployeeInformationField["label"];
    type: AnyEmployeeInformationField["type"];
    renderRightCell: () => React.ReactNode;
  }[];
  isLoadingData: boolean;
}> = ({ fields, isLoadingData }) => {
  return (
    <StyledTable>
      {isLoadingData ? (
        <Loading size="large" />
      ) : (
        <TableBody>
          {fields.map((field) => {
            return (
              <TableRow key={field.fieldId}>
                <StyledTableCellLeft colSpan={2} align="center" width={320}>
                  <Typography variant="body2">{field.fieldLabel}</Typography>
                </StyledTableCellLeft>
                <StyledTableCellRight>{field.renderRightCell()}</StyledTableCellRight>
              </TableRow>
            );
          })}
        </TableBody>
      )}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &.MuiTable-root {
    width: 100%;
    table-layout: fixed;
  }
  .MuiTableRow-root {
    .MuiTableCell-root {
      border: 1px solid ${(props) => props.theme.palette.grey[100]};
    }
    .MuiTableCell-root:first-child {
      border-left: none;
    }
    .MuiTableCell-root:last-child {
      border-right: none;
    }
  }
`;

const StyledTableCellLeft = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: ${(props) => props.theme.palette.grey[50]};
    padding: 16px 8px;
  }
`;

const StyledTableCellRight = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 8px 16px;
  }
`;
