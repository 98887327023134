import { EmployeeToDisplay } from "@onn/common";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useCallback } from "react";
import useSWR, { KeyedMutator, SWRResponse, useSWRConfig } from "swr";

import { firestore } from "~/config/firebase";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

/**
 * @deprecated useEmployee以外のファイルでは使用せず,useGenerateMutateNewGraduateを使用する
 * 全て置き換えたらdeprecatedを外してexportを削除する
 */
export const generateUseEmployeeKeys = (employeeId: string) => {
  return ["employee", employeeId];
};

/**
 * idをもとにemployeeを返すswr
 * @param employeeId employeeのid
 * @param option フェッチに関するオプション
 */
export const useEmployee = (
  employeeId?: string,
  option: {
    /**
     * hook呼び出し時に自動で再検証をするかどうか(デフォルトでtrue)
     */
    shouldAutoRevalidate?: boolean;
  } = { shouldAutoRevalidate: true }
): SWRResponse<EmployeeToDisplay, Error> => {
  const key = employeeId ? generateUseEmployeeKeys(employeeId) : null;

  const additionalOption = !option?.shouldAutoRevalidate
    ? {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    : undefined;

  return useSWR(
    key,
    async ([_key, employeeId]: [string, string]) => {
      const employee = (await EmployeeUseCase.findById(employeeId)) as EmployeeToDisplay;

      // TODO: 機密情報度合いは高くないがセキュリティ的にバックエンドに処理を移行したほうが良い
      const employeeTagRelations = await getDocs(
        query(collection(firestore, "employeeTagRelations"), where("employeeId", "==", employeeId))
      );

      employee.employeeTagIds = employeeTagRelations.docs.map((doc) => doc.data().tagId);
      return employee;
    },
    {
      ...additionalOption,
    }
  );
};

export const useGenerateMutateEmployee = () => {
  const { mutate } = useSWRConfig();

  const generateMutateEmployee: (
    employeeId: string
  ) => KeyedMutator<EmployeeToDisplay | undefined> = useCallback(
    (employeeId: string) => {
      return async (data, opts) => {
        const key = generateUseEmployeeKeys(employeeId);
        if (!data) {
          return mutate(key);
        }
        return mutate(key, data, opts);
      };
    },
    [mutate]
  );

  return { generateMutateEmployee };
};
