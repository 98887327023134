import { Box } from "@material-ui/core";
import React, { FC } from "react";

import styled from "styled-components";

import { Paper, Typography } from "~/components/uiParts";

import { mixin } from "~/util";

type Props = {
  isGrayColorInSP?: boolean;
  size?: "small" | "medium";
  children: React.ReactNode;
  showCloseAnnotation?: boolean;
};

export const CenterPaper: FC<Props> = ({
  isGrayColorInSP = false,
  size = "small",
  showCloseAnnotation = false,
  children,
}) => {
  const excludePaddingBottom = showCloseAnnotation;
  return (
    <StyledContainer $isGrayColorInSP={isGrayColorInSP} $size={size}>
      <StyledPaper
        $isGrayColorInSP={isGrayColorInSP}
        $excludePaddingBottom={excludePaddingBottom}
        $size={size}
      >
        {children}
      </StyledPaper>
      {showCloseAnnotation && (
        <Box mt="16px" textAlign="center">
          <Typography variant="caption" color="textSecondary" align="center">
            ブラウザを閉じて終了してください。
          </Typography>
        </Box>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)<{ $isGrayColorInSP: boolean; $size: "small" | "medium" }>`
  width: 100%;

  ${mixin.breakDown.sm`
    padding-bottom: 40px;
  `}

  ${(props) => mixin.breakUp.sm`
    max-width: ${props.$size === "small" ? 500 : 600}px;
    padding: 64px 24px;
  `}

  ${(props) => mixin.breakDown.sm`
    background: ${props.$isGrayColorInSP ? props.theme.palette.grey[50] : "white"}
  `}
`;

const StyledPaper = styled(Paper)<{
  $isGrayColorInSP: boolean;
  $excludePaddingBottom: boolean;
  $size: "small" | "medium";
}>`
  &.MuiPaper-root {
    box-shadow: 0 0 10px #0000000d;
    ${(props) => mixin.breakDown.sm`
      box-shadow: none;
      background: ${props.$isGrayColorInSP ? props.theme.palette.grey[50] : "white"};
    `}
  }

  ${(props) => mixin.breakUp.sm`
    padding: ${props.$size === "medium" ? "80px 40px" : ""};
  `}

  ${(props) => mixin.breakDown.sm`
    padding: ${props.$excludePaddingBottom ? "40px 24px 0 24px" : ""};
  `}
`;
