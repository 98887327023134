import { Box } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";

import { RecruitmentProcessArea } from "./components/RecruitmentProcessArea/RecruitmentProcessArea";
import { RecruitmentStatusArea } from "./components/RecruitmentStatusArea/RecruitmentStatusArea";

type Props = {
  employee: NewGraduateToDisplay;
};

export const RecruitmentProcessTab: FC<Props> = ({ employee }) => {
  return (
    <Box
      mt="40px"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
      }}
    >
      <RecruitmentStatusArea employee={employee} />
      <RecruitmentProcessArea employee={employee} />
    </Box>
  );
};
