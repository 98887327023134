/**
 * イベント評価時に添付するファイルのパスを生成する
 */
export const generateOnnEventEvaluationFilePath = ({
  tenantId,
  fileName,
  pathUid,
}: {
  tenantId: string;
  fileName: string;
  pathUid: string;
}) => {
  return `tenants/${tenantId}/onn_event_evaluations/${pathUid}/${fileName}`;
};

/**
 * イベント会場の備考欄に添付するファイルのパスを生成する
 */
export const generateOnnEventPlaceFilePath = ({
  tenantId,
  fileName,
  pathUid,
}: {
  tenantId: string;
  fileName: string;
  pathUid: string;
}) => {
  return `tenants/${tenantId}/onn_event_places/${pathUid}/${fileName}`;
};
