import { z } from "zod";

import { baseOnnTaskSchema } from "../BaseOnnTask/schema";

export const onnFormTaskSchema = baseOnnTaskSchema.merge(
  z.object({
    type: z.enum(["FORM_TASK"]),
  })
);

export type IOnnFormTaskSchema = z.infer<typeof onnFormTaskSchema>;
