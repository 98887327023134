import { OnnEvent } from "@onn/common";
import { useMemo, useState } from "react";

import { useFilterObjectByPartialMatch } from "~/hooks/shared";

export type Filter = {
  eventName: string;
};

export const useFilter = <T extends OnnEvent>({ events }: { events: T[] }) => {
  const [filter, setFilter] = useState<Filter>({
    eventName: "",
  });

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const filteredEvents = useMemo(() => {
    return filter.eventName
      ? filterObjectByPartialMatch({
          objects: events,
          searchText: filter.eventName,
          getProperties: [(e: OnnEvent) => e.title],
        })
      : events;
  }, [filter.eventName, filterObjectByPartialMatch, events]);

  return { filter, setFilter, filteredEvents };
};
