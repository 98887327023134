import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION ACTION CONDITION SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationActionConditionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  actionId: z.string(),
  onnEventId: z.string(),
  onnEventEvaluationRankIds: z.string().array(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluationActionCondition = z.infer<
  typeof OnnEventEvaluationActionConditionSchema
>;

/////////////////////////////////////////
// ONN EVENT EVALUATION ACTION CONDITION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationActionConditionOptionalDefaultsSchema =
  OnnEventEvaluationActionConditionSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type OnnEventEvaluationActionConditionOptionalDefaults = z.infer<
  typeof OnnEventEvaluationActionConditionOptionalDefaultsSchema
>;

export default OnnEventEvaluationActionConditionSchema;
