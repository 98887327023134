import { Box } from "@material-ui/core";
import { ContactMessageTemplateFolder } from "@onn/common";
import React, { FC, useMemo } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { useContactMessageTemplateFolderForm } from "./hooks/useContactMessageTemplateFolderForm";

import { Button, Modal, TextFieldDeprecated } from "~/components/uiParts";
import theme from "~/config/theme";
import { useModal } from "~/hooks/modal";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";

type modeType = "create" | "edit";

const titleTextMap = {
  create: "作成",
  edit: "変更を保存",
} as const;

type Props = {
  open: boolean;
  onCancel: () => void;
  mode: modeType;
  oldContactMessageTemplateFolder?: ContactMessageTemplateFolder;
  onSubmit: (newContactMessageTemplateFolder: ContactMessageTemplateFolder) => void;
  onDelete?: () => void;
};

export const EditContactMessageTemplateFolderModal: FC<Props> = ({
  open,
  onCancel,
  oldContactMessageTemplateFolder,
  mode,
  onSubmit,
  onDelete,
}: Props) => {
  const {
    tenant: { tenantId },
  } = useTenant();
  const { handleModal } = useModal();
  const { currentSpace } = useCurrentSpace();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useContactMessageTemplateFolderForm({
    previousContactMessageTemplateFolder: oldContactMessageTemplateFolder,
    tenantId,
    spaceId: currentSpace.id,
    onSubmit,
  });

  const content = useMemo(
    () => (
      <Box marginTop="16px">
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <StyledTextField
                error={!!error}
                variant="outlined"
                autoFocus
                placeholder="フォルダ名を入力してください"
                {...field}
              />
              {error?.message && (
                <Box color={theme.palette.error.main} fontSize="12px" marginTop="8px">
                  {error.message}
                </Box>
              )}
            </>
          )}
        />
      </Box>
    ),
    [control]
  );

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        {onDelete && oldContactMessageTemplateFolder ? (
          <Button
            variant="outlined"
            borderRadius="regular"
            color="secondary"
            onClick={() => {
              handleModal({
                name: "deleteContactMessageTemplateFolderModal",
                args: {
                  contactMessageTemplateFolder: oldContactMessageTemplateFolder,
                  onSubmit: onDelete,
                },
              });
            }}
          >
            削除
          </Button>
        ) : (
          <Box />
        )}
        <StyledCancelAndSubmitButtonContainer>
          <Button variant="text" borderRadius="regular" color="default" onClick={onCancel}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            borderRadius="regular"
            color="primary"
            onClick={() => {
              handleSubmit();
              onCancel();
            }}
            disabled={!isValid}
          >
            {titleTextMap[mode]}
          </Button>
        </StyledCancelAndSubmitButtonContainer>
      </StyledButtonContainer>
    ),
    [onCancel, isValid, handleSubmit, mode, onDelete, oldContactMessageTemplateFolder, handleModal]
  );

  return (
    <StyledModal
      title="フォルダ名"
      open={open}
      onClose={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledTextField = styled(TextFieldDeprecated)`
  width: 100%;
  padding: 16px;

  &::placeholder {
    color: ${({ theme }) => theme.palette.grey[400]};
    font-size: 14px;
  }
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;
`;

const StyledCancelAndSubmitButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    min-width: 400px;
    width: 400px;
    padding: 16px;
    border-radius: 8px;
  }

  .MuiBox-root {
    margin-bottom: 0;
  }

  .MuiTypography-h4 {
    font-size: 14px;
  }

  .dcDHPG {
    margin-top: 0;
  }
`;
