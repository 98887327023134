import { ContactRoom } from "@onn/common";
import { useState, useCallback } from "react";

import { useSnackbar } from "../shared";

import { CreateContactMessageUseCase } from "~/service/usecases/contactMessage";
import { captureException } from "~/util";

const createContactMessageUseCase = new CreateContactMessageUseCase();
/**
 * 入社者がmessageを作成するための関数を提供するhooks
 * @returns isLoading データを処理中かどうか
 * @returns createContactMessage
 */
export const useCreateContactMessageByNewHire = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * messageを作成するための関数
   */
  const createContactMessage = useCallback(
    async (
      text: string,
      tenantId: string,
      employeeId: string,
      contactRoom: ContactRoom,
      newMessageFiles: File[]
    ) => {
      setIsLoading(true);
      return await createContactMessageUseCase
        .execute({
          text,
          tenantId,
          createdEmployeeId: employeeId,
          contactRoomId: contactRoom.id,
          newMessageFiles,
        })
        .catch((e) => {
          enqueueSnackbar(
            "メッセージの送信が失敗しました。Onnサポートチームまでお問い合わせください。",
            { variant: "error" }
          );
          captureException({
            error: e as Error,
            tags: { type: "useCreateContactMessageByNewHire:createContactMessage" },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar]
  );

  return { isLoading, createContactMessage };
};
