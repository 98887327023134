import {
  CandidateDate,
  Employee,
  OnnEvent,
  OnnEventAnswer,
  OnnEventDeliveryCancellationLog,
  OnnEventDeterminedDate,
  OnnEventEvaluation,
  OnnEventSlotDate,
  OnnFormTask,
  OnnFormTaskAnswer,
  OnnTaskDeliveryCancellationLog,
  RecruitmentProcessRecord,
  RecruitmentProcessRecordOnnEventItem,
  RecruitmentProcessRecordOnnTaskItem,
  instantiateFromAnyRecruitmentProcessRecordItemExcludeMethods,
} from "@onn/common";
import useSWR, { SWRResponse, mutate } from "swr";

import { apiClient } from "~/libs";

type Key = ["/get_employees_recruitment_processes", Employee["id"]];
export const generateUseRecruitmentProcessRecordsByEmployeeKey = (employeeId: string): Key => {
  return ["/get_employees_recruitment_processes", employeeId];
};

export const useRecruitmentProcessRecordsByEmployee = ({
  employeeId,
}: {
  employeeId: string;
}): SWRResponse<RecruitmentProcessRecordByEmployee[], Error> => {
  const key = generateUseRecruitmentProcessRecordsByEmployeeKey(employeeId);

  return useSWR(key, async ([endpoint, employeeId]: Key) => {
    const response = await apiClient.get(endpoint, {
      "employee-id": employeeId,
    });
    return response.map(({ progressStatusLabel, recruitmentStatusLabel, ...result }) => ({
      processRecord: new RecruitmentProcessRecord(result),
      scenarioLabel: result.scenarioLabel,
      progressStatusLabel,
      recruitmentStatusLabel,
      recruitmentProcessRecordItems: result.recruitmentProcessRecordItems.map((item) => {
        return instantiateFromAnyRecruitmentProcessRecordItemExcludeMethods(item);
      }),
      relation: {
        onnEventsWithRelation: result.relation.onnEventsWithRelation?.map(
          ({
            onnEvent,
            statusLabel,
            lastReadAt,
            candidateDate,
            answer,
            determinedDate,
            evaluation,
            assignee,
            slotDate,
            latestDeliveryCancellationLog,
          }) => ({
            onnEvent: new OnnEvent(onnEvent),
            statusLabel,
            lastReadAt,
            candidateDate: candidateDate ? new CandidateDate(candidateDate) : undefined,
            answer: answer ? new OnnEventAnswer(answer) : undefined,
            determinedDate: determinedDate ? new OnnEventDeterminedDate(determinedDate) : undefined,
            evaluation: evaluation ? new OnnEventEvaluation(evaluation) : undefined,
            assignee: assignee ? new Employee(assignee) : undefined,
            slotDate: slotDate ? new OnnEventSlotDate(slotDate) : undefined,
            latestDeliveryCancellationLog: latestDeliveryCancellationLog
              ? new OnnEventDeliveryCancellationLog(latestDeliveryCancellationLog)
              : undefined,
          })
        ),
        onnTasksWithRelation: result.relation.onnTasksWithRelation?.map(
          ({
            onnTask,
            answer,
            statusLabel,
            lastReadAt,
            assignee,
            latestDeliveryCancellationLog,
          }) => ({
            onnTask: new OnnFormTask(onnTask),
            answer: answer ? new OnnFormTaskAnswer(answer) : undefined,
            statusLabel,
            lastReadAt,
            assignee: new Employee(assignee),
            latestDeliveryCancellationLog: latestDeliveryCancellationLog
              ? new OnnTaskDeliveryCancellationLog(latestDeliveryCancellationLog)
              : undefined,
          })
        ),
      },
    }));
  });
};

export const mutateRecruitmentProcessRecordsByEmployee = (employeeId: string) => {
  mutate(generateUseRecruitmentProcessRecordsByEmployeeKey(employeeId));
};

export type OnnEventWithRelation = {
  onnEvent: OnnEvent;
  statusLabel: string | undefined;
  lastReadAt: Date | undefined;
  candidateDate: CandidateDate | undefined;
  answer: OnnEventAnswer | undefined;
  determinedDate: OnnEventDeterminedDate | undefined;
  evaluation: OnnEventEvaluation | undefined;
  assignee: Employee | undefined;
  slotDate: OnnEventSlotDate | undefined;
  latestDeliveryCancellationLog: OnnEventDeliveryCancellationLog | undefined;
};

export type OnnTaskWithRelation = {
  onnTask: OnnFormTask;
  statusLabel: string | undefined;
  lastReadAt: Date | undefined;
  answer: OnnFormTaskAnswer | undefined;
  latestDeliveryCancellationLog: OnnTaskDeliveryCancellationLog | undefined;
  assignee: Employee;
};

export type RecruitmentProcessRecordByEmployee = {
  // 型合わせの都合で、 undefined のユニオン型にしている
  processRecord: RecruitmentProcessRecord;
  scenarioLabel: string;
  recruitmentStatusLabel: string;
  progressStatusLabel: string | undefined;
  recruitmentProcessRecordItems: Array<
    RecruitmentProcessRecordOnnEventItem | RecruitmentProcessRecordOnnTaskItem
  >;
  relation: {
    onnEventsWithRelation: Array<OnnEventWithRelation> | undefined;
    onnTasksWithRelation: Array<OnnTaskWithRelation> | undefined;
  };
};
