import { Box } from "@material-ui/core";
import React from "react";

import { Button, Typography } from "~/components/uiParts";

export type ResetSelectionButtonProps = {
  onResetSelection(): void;
};
export const ResetSelectionButton = ({
  onResetSelection,
}: ResetSelectionButtonProps): JSX.Element => {
  return (
    <Box width={"100%"}>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={() => onResetSelection()}
      >
        <Box width="100%" display="flex" flex={1}>
          <Typography variant="body2" bold noWrap>
            選択解除
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};
