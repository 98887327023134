import liff from "@line/liff";
import React, { FC, useCallback } from "react";

import { useParams } from "react-router-dom";

import { RegistrationInvitationLinksDetail } from "../Detail";

import { ErrorPage } from "~/components/domains/account/ErrorPage";
import { QRCodePage } from "~/components/domains/account/QRCodePage";
import { Loading } from "~/components/uiParts";
import { useLiffUrl } from "~/hooks/liff";
import { useRegistrationInvitationLink } from "~/hooks/registrationInvitationLink/useRegistrationInvitationLink";
import { useTenantLineLoginChannelInfo } from "~/hooks/tenant/useTenantLineLoginChannelInfo";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";
import { isMobileBrowser } from "~/util";

export const Wrapper: FC<{
  children: typeof RegistrationInvitationLinksDetail;
}> = ({ children: Children }) => {
  const { type } = useParams<"type">();
  const { id } = useParams<"id">();
  const tenantId = getTenantIdFromDomain();
  const { data: dateLineLoginChannelInfo, isLoading: isLoadingLineLoginChannelInfo } =
    useTenantLineLoginChannelInfo(tenantId || undefined);
  const { data, isLoading, error } = useRegistrationInvitationLink(id);
  const { openLiff, createLiffUrl } = useLiffUrl();
  const pathForLine = location.pathname;

  const startLineRegistrationForMobileBrowser = useCallback(() => {
    if (isMobileBrowser() && dateLineLoginChannelInfo) {
      openLiff({ liffId: dateLineLoginChannelInfo.liffId, path: pathForLine });
    }
  }, [dateLineLoginChannelInfo, openLiff, pathForLine]);

  if (isLoading || isLoadingLineLoginChannelInfo) {
    return <Loading size="large" />;
  }

  if (error) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="共通登録リンクを取得できませんでした"
        extraInfo={{ type, id }}
      />
    );
  }

  if (!data || !dateLineLoginChannelInfo) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="共通登録リンクを取得できませんでした"
        extraInfo={{ type, id }}
      />
    );
  }

  if (!type || !id) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="URL が正しくありません"
        extraInfo={{ type, id }}
      />
    );
  }

  if (liff.isInClient() && type !== "line") {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="LINE認証タイプではないリンクは LIFF では開けません"
        extraInfo={{ type, id }}
      />
    );
  }

  if (type === "line" && !liff.isInClient()) {
    const liffUrl = createLiffUrl({
      liffId: dateLineLoginChannelInfo.liffId,
      path: location.pathname,
    });
    return (
      <QRCodePage
        urlForQRCode={liffUrl}
        startLineRegistration={startLineRegistrationForMobileBrowser}
      />
    );
  }

  const [lineType, emailType] = data.registrationInvitationLink.selectableAuthenticationFlowTypes;
  const isTypeCorrect = lineType === type || emailType === type;
  if (!isTypeCorrect) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="選択された認証フロータイプは共通リンクで許可されていません"
        extraInfo={{ type, id }}
      />
    );
  }

  return <Children registrationInvitationLink={data.registrationInvitationLink} />;
};
