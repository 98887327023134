import { useCallback } from "react";
import { useSWRConfig } from "swr";

export const useMutateOnnEventAnswers = ({ onnEventId }: { onnEventId?: string }) => {
  const { mutate: globalMutate, cache } = useSWRConfig();

  const mutate = useCallback(async () => {
    const promises = [];
    const keyListOnnEventAnswer = `listOnnEventAnswer-${onnEventId}`;
    if (cache.get(keyListOnnEventAnswer)) {
      promises.push(globalMutate(keyListOnnEventAnswer));
    }
    const keyListOnnEventAnswersWithEmployee = `listOnnEventAnswersWithEmployee-${onnEventId}`;
    if (cache.get(keyListOnnEventAnswersWithEmployee)) {
      promises.push(globalMutate(keyListOnnEventAnswersWithEmployee));
    }

    await Promise.all(promises);
  }, [cache, globalMutate, onnEventId]);

  return mutate;
};
