import { Menu, MenuItem, MenuProps } from "@material-ui/core";
import { Box } from "@mui/material";
import { AnyActionSetting } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon, Typography } from "~/components/uiParts";
import { IconType } from "~/components/uiParts/Icon";
import theme from "~/config/theme";
type Props = {
  anchorEl: MenuProps["anchorEl"];
  onClose: () => void;
  isOpen: boolean;
  onClickItem: (type: AnyActionSetting["type"]) => void;
};

// NOTE: anchorElを使いまわしたいので、UncontrolledMenuはつかわない
export const MenuOfActionSelection: FC<Props> = ({ anchorEl, onClose, isOpen, onClickItem }) => {
  return (
    <Menu
      id={"MenuOfActionSelection"}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      MenuListProps={{
        style: {
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "400px",
        },
      }}
    >
      <ActionMenuItem
        icon="statusChange"
        label="選考ステータスを変更"
        onClick={() => onClickItem("ChangeRecruitmentStatusActionSetting")}
      />
      <ActionMenuItem
        icon="calendar"
        label="イベントを付与・配信"
        onClick={() => onClickItem("DeliverOnnEventActionSetting")}
      />
      <ActionMenuItem
        icon="checkOutlineCircle"
        label="タスクを付与・配信"
        onClick={() => onClickItem("DeliverOnnTaskActionSetting")}
      />
      <ActionMenuItem
        icon="paperAirplane"
        label="カスタムメッセージを送信"
        onClick={() => onClickItem("SendContactMessageActionSetting")}
      />
    </Menu>
  );
};

const ActionMenuItem: FC<{ icon: IconType; label: string; onClick: () => void }> = ({
  icon,
  label,
  onClick,
}) => {
  return (
    <StyledMenuItem onClick={onClick}>
      <Box height={"24px"} width={"24px"}>
        <Icon icon={icon} size="md" color="grey" />
      </Box>
      <Typography variant="body2" bold style={{ flexGrow: 1, color: theme.palette.grey[500] }}>
        {label}
      </Typography>
      <Icon icon="chevronRight" size="md" color="grey" />
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root {
    height: 40px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 4px;
  }
`;
