import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee, OnnEvent } from "@onn/common";

import React, { FC, MouseEvent, useCallback, useMemo, useState } from "react";

import styled from "styled-components";

import { useAnswerResultTab } from "../../hooks/OnnEventAnswerResultTab/useAnswerResultTab";

import { CandidateDateFilter } from "./CandidateDateFilter/CandidateDateFilter";
import { StatusFilter } from "./StatusFilter/StatusFilter";
import { useAnswerResultTable } from "./useAnswerResultTable";

import { EscapedCSVLink } from "~/components/shared/EscapedCSVLink";
import {
  Button,
  IconButton,
  Loading,
  Typography,
  TableActionsLayoutWithFiltersAndSearchForm,
} from "~/components/uiParts";

import { SearchForm } from "~/components/uiParts/SearchForm";

import { VirtualizedMultiGrid } from "~/components/uiParts/VirtualizedMultiGrid";
import { useOutputResultAnswerCsv } from "~/hooks/onnEvent";

type Props = {
  currentUser: Employee;
  onnEvent: OnnEvent;
  onClickBulkRemindButton: (tenantId: string, eventId: string) => void;
};

export const TabPage: FC<Props> = ({ currentUser, onnEvent, onClickBulkRemindButton }) => {
  const {
    selectedOnnEventAnswers,
    searchValue,
    setSearchValue,
    determinedDates,
    isLoading,
    setSelectedStatusTypes,
    selectedStatusTypes,
    candidateDatesWithNumberOfParticipants,
    numberOfDistribution,
    numberOfResponses,
    employeeIdToLatestEmployeeActiveLogMap,
    selectedCandidateDateIds,
    setSelectedCandidateDateIds,
  } = useAnswerResultTab({
    onnEventId: onnEvent.id,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const handleClickSubmitBulkRemind = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      closeMenu(event);
      onClickBulkRemindButton(onnEvent.tenantId, onnEvent.id);
    },
    [onnEvent.tenantId, onnEvent.id, closeMenu, onClickBulkRemindButton]
  );

  const { cellRenderer, rowCount, columnCount, columnWidth, headerCellHeight, bodyCellHeight } =
    useAnswerResultTable({
      onnEvent,
      employeeIdToDeterminedDate: new Map(
        (determinedDates || []).map((determinedDate) => [determinedDate.employeeId, determinedDate])
      ),
      candidateDateList: candidateDatesWithNumberOfParticipants || [],
      onnEventAnswers: selectedOnnEventAnswers,
      hasCapacity: onnEvent.hasCapacity(),
      numberOfDistribution,
      numberOfResponses,
      employeeIdToLatestEmployeeActiveLogMap,
      selectedCandidateDateIds,
    });

  const {
    csvData,
    filename,
    isLoading: isCsvDataLoading,
  } = useOutputResultAnswerCsv({
    onnEvent,
    onnEventAnswers: selectedOnnEventAnswers,
    employeeIdToLatestEmployeeActiveLogMap,
    selectedCandidateDateIds,
  });

  const firstFilter = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gridRowGap="8px">
        <Typography variant="body2" bold color="textSecondary">
          ステータス
        </Typography>
        <Box>
          <StatusFilter
            selectedStatusTypes={selectedStatusTypes}
            onSetSelectedStatusTypes={setSelectedStatusTypes}
          />
        </Box>
      </Box>
    ),
    [selectedStatusTypes, setSelectedStatusTypes]
  );

  const secondFilter = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gridRowGap="8px">
        <Typography variant="body2" bold color="textSecondary">
          開催日時
        </Typography>
        <Box>
          <CandidateDateFilter
            candidateDates={candidateDatesWithNumberOfParticipants || []}
            selectedCandidateDateIds={selectedCandidateDateIds}
            onSetSelectedCandidateDateIds={setSelectedCandidateDateIds}
          />
        </Box>
      </Box>
    ),

    [candidateDatesWithNumberOfParticipants, selectedCandidateDateIds, setSelectedCandidateDateIds]
  );

  /**
   * 全日程で定員が満員かどうか判定する
   */
  const isCapacityFull = useMemo(
    () => (candidateDatesWithNumberOfParticipants ?? []).every((v) => !v.canParticipate()),
    [candidateDatesWithNumberOfParticipants]
  );

  return (
    <>
      <Box
        my="36px"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        gridGap={24}
      >
        <TableActionsLayoutWithFiltersAndSearchForm
          firstFilter={firstFilter}
          secondFilter={secondFilter}
          searchForm={
            <SearchForm
              searchValue={searchValue}
              onSearchValue={setSearchValue}
              placeholder="ユーザー名・メールアドレスで検索"
              variant="standard"
              fullWidth
            />
          }
        />
        {isCsvDataLoading ? (
          <Box height={32}>
            <Button color="primary" variant="outlined" borderRadius="regular" fullWidth>
              {/* NOTE: ローディング中とローディングでない時にボタンの幅が同じになるように調整。ボタンを押してデータをfetchする処理にした後に修正する。 */}
              <Box width="113.58px">
                <Loading size="small" />
              </Box>
            </Button>
          </Box>
        ) : (
          <StyledCSVLink data={csvData} filename={filename}>
            <Box height={40}>
              <Button
                color="primary"
                variant="outlined"
                borderRadius="regular"
                fullWidth
                fullHeight
              >
                CSVダウンロード
              </Button>
            </Box>
          </StyledCSVLink>
        )}
        {currentUser.isAdmin() && (
          <>
            <IconButton icon="menuVert" onClick={openMenu} />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleClickSubmitBulkRemind} disabled={isCapacityFull}>
                <Typography variant="body2">一括リマインド送信</Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
      <VirtualizedMultiGrid
        cellRenderer={cellRenderer}
        columnWidth={columnWidth}
        columnCount={columnCount}
        rowCount={rowCount}
        headerCellHeight={headerCellHeight}
        bodyCellHeight={bodyCellHeight}
        isLoading={isLoading}
        paddingSize="md"
      />
    </>
  );
};

const StyledCSVLink = styled(EscapedCSVLink)`
  text-decoration: none;
`;
