import { OnnTask, OnnFormTask } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

export const useOnnTasks = ({
  tenantId,
  isExceededDeadlineDate,
}: {
  tenantId: string;
  isExceededDeadlineDate?: boolean;
}): SWRResponse<OnnTask[], Error> => {
  return useSWR(generateOnnTasksKey(tenantId), async ({ endpoint }): Promise<OnnTask[]> => {
    const response = await apiClient.get(endpoint, {
      isExceededDeadlineDate,
    });
    return response.data.map((d) => {
      switch (d.type) {
        case "FORM_TASK": {
          return new OnnFormTask(d);
        }
        default: {
          throw new Error(`${d.type} is not implemented yet`);
        }
      }
    });
  });
};

const generateOnnTasksKey = (tenantId: string) => {
  return { endpoint: "/api/onn-task/get-onn-tasks" as const, tenantId };
};
