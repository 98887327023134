import { zodResolver } from "@hookform/resolvers/zod";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { TextFieldDeprecated, Typography } from "~/components/uiParts";

type EmployeeNameFormType = Pick<Employee, "lastName" | "firstName">;

type Props = {
  onSave: (data: EmployeeNameFormType) => void;
  onCancel: () => void;
  data?: EmployeeNameFormType;
};

export const NameEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, control, formState } = useForm<EmployeeNameFormType>({
    defaultValues: { ...data },
    mode: "onChange",
    resolver: zodResolver(
      z.object({
        lastName: z.string().trim().min(1, { message: "姓は1文字以上にしてください" }),
        firstName: z.string().trim().min(1, { message: "名は1文字以上にしてください" }),
      })
    ),
  });

  return (
    <InformationValueEditor
      form={
        <form
          style={{ display: "flex", flexDirection: "column", rowGap: 16 }}
          onSubmit={handleSubmit(onSave)}
        >
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">姓</Typography>
                </div>
                <StyledTextField
                  defaultValue={data?.lastName}
                  placeholder="田中"
                  variant="outlined"
                  fullWidth
                  name={field.name}
                  error={!!error}
                  helperText={error?.message}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">名</Typography>
                </div>
                <StyledTextField
                  defaultValue={data?.firstName}
                  placeholder="太郎"
                  variant="outlined"
                  fullWidth
                  name={field.name}
                  error={!!error}
                  helperText={error?.message}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;
