import { Box } from "@material-ui/core";
import React, { Fragment, FC } from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type Props = {
  selectedIds: AnswerFilterStatus[];
  onChangeSelected: (selectedTagIds: AnswerFilterStatus) => void;
};

export type AnswerFilterStatus = "answered" | "isRead" | "unRead";

export const AnswerStatusList: { id: AnswerFilterStatus; label: string }[] = [
  { id: "answered", label: "回答済み" },
  { id: "isRead", label: "既読" },
  { id: "unRead", label: "未読" },
];

export const AnswerStatusSelectMenu: FC<Props> = ({ selectedIds, onChangeSelected }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (event: React.ChangeEvent<any>) => {
    const answerStatusId = event.currentTarget.value;
    onChangeSelected(answerStatusId);
  };

  return (
    <Box py="16px" width="318px" display="flex" flexDirection="column">
      <Box px="24px" maxHeight={300} overflow="auto" display="flex" flexDirection="column">
        {AnswerStatusList.map((answerStatus) => (
          <Fragment key={answerStatus.id}>
            <StyledFormControlLabel
              onChange={onChange}
              value={answerStatus.id}
              control={
                <Checkbox checked={selectedIds.includes(answerStatus.id)} name={answerStatus.id} />
              }
              disabled={false}
              label={
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  gridGap="4px"
                  overflow="hidden"
                >
                  <StyledTypography variant="body2" noWrap>
                    {answerStatus.label}
                  </StyledTypography>
                </Box>
              }
            />
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 8px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;
