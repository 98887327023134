import { z } from "zod";

export const onWebsiteNoticeTypes = ["success", "error", "info"] as const;
export const onWebsiteNoticeActionTypes = ["editScenarioCompleted"] as const;

export const onWebsiteNoticeSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  targetEmployeeId: z.string(),
  shouldPromptRefetch: z.boolean(),
  shouldPromptContactToDevelopers: z.boolean(),
  type: z.enum(onWebsiteNoticeTypes),
  actionType: z.enum(onWebsiteNoticeActionTypes).optional(),
  text: z.string(),
  createdAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnWebsiteNotice extends z.infer<typeof onWebsiteNoticeSchema> {}
