import React, { FC, useCallback } from "react";

import styled from "styled-components";

import { mutate } from "swr";

import {
  CellItem,
  Chip,
  Icon,
  StyleProps,
  Typography,
  UncontrolledMenu,
} from "~/components/uiParts";
import { colors } from "~/config/theme";
import { useCurrentUser } from "~/hooks/employee";
import { generateOnnEventSlotDatesForDisplayKey } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";
import { useUpdateOnnEventSlotDates } from "~/hooks/onnEventSlotDates/useUpdateOnnEventSlotDates";

// 「調整中」ステータスのセル
export const AdjustingCell: FC<
  StyleProps & { onnEventId: string; slotId: string; reservedCount: number }
> = ({ style, onnEventId, slotId, reservedCount, ...styleProps }) => {
  const { currentUser } = useCurrentUser();

  const isAdmin = currentUser?.isAdmin() || false;

  const { updateOnnEventSlotDates } = useUpdateOnnEventSlotDates();

  const updateOnnEventSlotStatus = useCallback(
    async (status: "draft" | "published" | "closed") => {
      await updateOnnEventSlotDates({
        onnEventId,
        onnEventSlotId: slotId,
        paramsToUpdate: { status, type: "briefing_session" },
      });
      await mutate(generateOnnEventSlotDatesForDisplayKey(onnEventId));
    },
    [onnEventId, slotId, updateOnnEventSlotDates]
  );

  const menuItemOptions = [
    {
      onClick: () => updateOnnEventSlotStatus("closed"),
      text: "募集締切",
    },
    reservedCount === 0
      ? {
          onClick: () => updateOnnEventSlotStatus("draft"),
          text: "非公開",
        }
      : [],
  ].flat();

  return (
    <CellItem {...styleProps} style={{ ...style, backgroundColor: colors.secondary.light }}>
      <UncontrolledMenu
        renderButton={(openMenu) => (
          <StyledChip
            $bgColor={"#fff"}
            label={
              <Typography variant="body2" bold color="secondary">
                {"調整中"}
              </Typography>
            }
            color="muted"
            isOutlined
            icon={isAdmin ? <Icon icon="chevronDown" color="secondary" size="md" /> : undefined}
            onClick={openMenu}
            size="medium"
          />
        )}
        menuItemOptions={menuItemOptions}
      ></UncontrolledMenu>
    </CellItem>
  );
};

const StyledChip = styled(Chip)<{ $bgColor: string }>`
  &.MuiChip-root {
    height: 32px;
    width: 90px;
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  }
  > .MuiChip-icon {
    order: 1; // アイコンと文字列の表示順を入れ替える
    margin-left: -12px;
  }
  .MuiChip-label {
    color: ${({ theme }) => theme.palette.text.primary};
    padding-left: 8px;
  }
  background-color: ${({ $bgColor }) => $bgColor};
  :hover,
  :focus {
    background-color: ${({ $bgColor }) => $bgColor};
  }
  ${({ $bgColor }) =>
    $bgColor !== "#fff" &&
    `
      border: none;
    `}
`;
