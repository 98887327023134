import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";
import { OnnEventTypeSchema } from "../inputTypeSchemas/OnnEventTypeSchema";

/////////////////////////////////////////
// ONN EVENT SCHEMA
/////////////////////////////////////////

export const OnnEventSchema = z.object({
  type: OnnEventTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  title: z
    .string()
    .trim()
    .min(1, { message: "タイトルが短すぎます" })
    .max(50, { message: "タイトルが長すぎます" }),
  content: z
    .string()
    .trim()
    .min(1, { message: "説明が短すぎます" })
    .max(1000, { message: "説明が長すぎます" }),
  candidateDates: JsonValueSchema.array(),
  assigneeIds: z.string().array(),
  createdAt: z.date(),
  updatedAt: z.date(),
  deadlineDate: z.date().nullish(),
  scheduledDate: z.date().nullish(),
  canAnswerAfterDeadline: z.boolean().nullish(),
  firstDeliveredAt: z.date().nullish(),
  shouldRequestAttendance: z.boolean().nullish(),
  registrationInvitationLinkId: z.string().nullish(),
  isRestrictedAnswerFromNewGraduate: z.boolean().nullish(),
  isRestrictedDaysAgoWhenAnswer: z.boolean().nullish(),
  isRestrictedEditAnswerFromNewGraduate: z.boolean().nullish(),
  /**
   * z.number.int.min(1, { message: "1以上の整数を設定してください" })
   */
  daysAgoRestrictAnswer: z.number().nullish(),
  slotDefaultValueSetting: JsonValueSchema.nullable(),
  isNotificationForAnswerOnBehalfCompleted: z.boolean(),
  timingForAnswerOnBehalfCompletedNotification: JsonValueSchema.nullable(),
  isNotificationForRequestForAnswer: z.boolean(),
  timingForRequestForAnswerNotification: JsonValueSchema.nullable(),
});

export type OnnEvent = z.infer<typeof OnnEventSchema>;

/////////////////////////////////////////
// ONN EVENT OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventOptionalDefaultsSchema = OnnEventSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
    isNotificationForAnswerOnBehalfCompleted: z.boolean().optional(),
    isNotificationForRequestForAnswer: z.boolean().optional(),
  })
);

export type OnnEventOptionalDefaults = z.infer<typeof OnnEventOptionalDefaultsSchema>;

export default OnnEventSchema;
