import { v4 } from "uuid";

import { IOnnEventEvaluationRank, onnEventEvaluationRankSchema } from "./schema";

export class OnnEventEvaluationRank implements IOnnEventEvaluationRank {
  static readonly validator = onnEventEvaluationRankSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly label: string;
  readonly order: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventEvaluationRank>) {
    const parsedInit = OnnEventEvaluationRank.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static create(
    params: Omit<ExcludeMethods<OnnEventEvaluationRank>, "id" | "createdAt" | "updatedAt">
  ): OnnEventEvaluationRank {
    return new OnnEventEvaluationRank({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
