import {
  ChangeRecruitmentStatusActionSetting,
  SendContactMessageActionSetting,
  DeliverOnnEventActionSetting,
  DeliverOnnTaskActionSetting,
  Action,
  EmployeeTagActionCondition,
  OnnEventEvaluationActionCondition,
  OnnFormTaskResultActionCondition,
} from "@onn/common";

import { useMemo } from "react";

import { createDifferenceLabel } from "../../../components/TriggerBlock/ActionList/Action/utils/createDifferenceLabel";

import { InputBlockState } from "../../../hooks/scenarioForm/InputState";

import { useEditModeContext } from "../../EditModeContextProvider";

import { useFormRevisions } from "~/hooks/onnTask";

export const useActionDescriptionForEditMode = ({
  setting,
  condition,
  ...action
}: {
  setting: InputBlockState["actions"][number]["actionSetting"];
  condition: InputBlockState["actions"][number]["actionCondition"];
} & Pick<
  ExcludeMethods<Action>,
  "executionTimeAfterDays" | "executionTimeAtHours" | "executionTimeWithinMinutes"
>) => {
  const {
    allOnnEventsMap,
    allOnnTasksMap,
    allRecruitmentStatusesMap,
    allTagsMap,
    allOnnEventEvaluationRanksMap,
  } = useEditModeContext();

  const { data } = useFormRevisions({
    onnFormTaskId:
      condition?.type === "OnnFormTaskResultActionCondition" ? condition.onnFormTaskId : undefined,
  });
  const questionIdToTitleMap = useMemo(
    () =>
      new Map(
        data?.formRevisions.flatMap((formRevision) => {
          return formRevision.questions.map((question) => {
            return [question.id, question.title];
          });
        }) || []
      ),
    [data?.formRevisions]
  );
  const questionOptionIdToTitleMap = useMemo(
    () =>
      new Map(
        data?.formRevisions.flatMap((formRevision) => {
          return formRevision.questions.flatMap((question) => {
            if (question.type === "RADIO") {
              return question.options.map((option) => {
                return [option.id, option.text];
              });
            }
            return [];
          });
        }) || []
      ),
    [data?.formRevisions]
  );

  const description = useMemo(() => {
    switch (setting.type) {
      case ChangeRecruitmentStatusActionSetting.type:
        // ex) 選考ステータスを「一次面接」に変更
        return `選考ステータスを「${
          allRecruitmentStatusesMap.get(setting.recruitmentStatusId)?.label
        }」に変更`;
      case SendContactMessageActionSetting.type:
        return "応募者にメッセージを送信";
      case DeliverOnnEventActionSetting.type:
        return `「${allOnnEventsMap.get(setting.onnEventId)?.title}」を付与・配信`;
      case DeliverOnnTaskActionSetting.type:
        return `「${allOnnTasksMap.get(setting.onnTaskId)?.title}」を付与・配信`;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = setting;
        return "";
    }
  }, [setting, allOnnEventsMap, allOnnTasksMap, allRecruitmentStatusesMap]);

  const conditionDescription = useMemo(() => {
    if (!condition) return undefined;
    switch (condition.type) {
      case EmployeeTagActionCondition.type: {
        const tagsNames = condition.employeeTagIds.flatMap(
          (tagId) => allTagsMap.get(tagId)?.name || []
        );
        return `「候補者のタグ」に「${tagsNames.join(",")}」が設定されている`;
      }
      case OnnEventEvaluationActionCondition.type: {
        const onnEvent = allOnnEventsMap.get(condition.onnEventId);
        const rankLabels = condition.onnEventEvaluationRankIds.flatMap(
          (rankId) => allOnnEventEvaluationRanksMap.get(rankId)?.label || []
        );
        return `「${onnEvent?.title}」の評価が「${rankLabels.join(",")}」の場合`;
      }
      case OnnFormTaskResultActionCondition.type: {
        const questionTitle = questionIdToTitleMap.get(condition.questionId);
        const optionTitle = questionOptionIdToTitleMap.get(condition.optionId);
        return `「${questionTitle}」に「${optionTitle}」と回答`;
      }
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = condition;
    }
  }, [
    allOnnEventEvaluationRanksMap,
    allOnnEventsMap,
    allTagsMap,
    condition,
    questionIdToTitleMap,
    questionOptionIdToTitleMap,
  ]);

  const endLabel = createDifferenceLabel(action);

  return { description, conditionDescription, endLabel };
};
