import { Box } from "@material-ui/core";
import React from "react";

import styled from "styled-components";

import { useContextOfOnnEventSlotSearchModal } from "../../../Context";
import { SlotFromDateRangeCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import { DatePickerV2, Typography } from "~/components/uiParts";

type Props = {
  index: number;
  condition: SlotFromDateRangeCondition;
};

const TARGET = "slotFromDateRange";

export const SlotFromDateRangeConditionSelector = ({ index, condition }: Props): JSX.Element => {
  const { onChangeTarget, onChangeCondition, onnEvent } = useContextOfOnnEventSlotSearchModal();

  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={3}>
        <SelectTargetButton
          onnEventType={onnEvent.type}
          target={TARGET}
          onChange={(target) => onChangeTarget(index, target)}
        />
      </Box>
      <Box
        flex={9}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          gridGap: "16px",
        }}
      >
        <StyledDatePicker
          fullWidth
          placeholder="日程を選択"
          value={condition.rangeStart || null}
          onChange={(date) => {
            onChangeCondition(index, {
              target: "slotFromDateRange",
              rangeStart: date || undefined,
              rangeEnd: condition.rangeEnd,
            });
          }}
        />
        <Box>
          <Typography color={"textSecondary"}>〜</Typography>
        </Box>
        <StyledDatePicker
          fullWidth
          placeholder="日程を選択"
          value={condition.rangeEnd || null}
          onChange={(date) => {
            onChangeCondition(index, {
              target: "slotFromDateRange",
              rangeStart: condition.rangeStart,
              rangeEnd: date || undefined,
            });
          }}
        />
      </Box>
    </Box>
  );
};

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    height: 40px;
  }
  .MuiInputBase-root {
    height: 40px;
    padding: 8px 12px 8px 12px;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 14px;
  }
  .MuiIconButton-root {
    padding: 4px;
  }
`;
