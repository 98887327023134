import { APISchema } from "@onn/common";
import { useSnackbar } from "notistack";
import { useCallback } from "react";

import { useMutateScenarios } from "./useScenarios";

import { apiClient } from "~/libs";
import { ApiResponseError } from "~/util";

type EndPoint = APISchema["/scenario_api/scenarios"]["PATCH"];

export const useUpdateScenario = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateScenarios } = useMutateScenarios();

  const updateScenario = useCallback(
    async ({ scenarioId, scenarioName }: { scenarioId: string; scenarioName: string }) => {
      const body: EndPoint["body"] = {
        scenarioId,
        scenarioName,
      };
      try {
        await apiClient.patch(`/scenario_api/scenarios`, body);
        mutateScenarios();
      } catch (e) {
        if (e instanceof ApiResponseError && e.status === 400) {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("シナリオの更新に失敗しました。管理者までお問い合わせください。", {
            variant: "error",
          });
        }
      }
    },
    [enqueueSnackbar, mutateScenarios]
  );

  return { updateScenario };
};
