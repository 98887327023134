import { Box, Menu } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useState, FC, useMemo } from "react";
import styled from "styled-components";

import {
  AnswerFilterStatus,
  AnswerStatusList,
  AnswerStatusSelectMenu,
} from "./AnswerStatusSelectMenu";

import { Button, Icon, IconButton, Typography } from "~/components/uiParts";

type Props = {
  selectedIds: AnswerFilterStatus[];
  onChange: (answerStatusId: AnswerFilterStatus) => void;
  onDeleteAll: () => void;
};

export const AnswerStatusFilter: FC<Props> = ({ selectedIds, onChange, onDeleteAll }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const labelText = useMemo(() => {
    if (isEmpty(selectedIds)) return "選択してください";

    const selectedStatusLabels = AnswerStatusList.filter((status) =>
      selectedIds.includes(status.id)
    ).map((status) => status.label);

    return selectedStatusLabels.join(",");
  }, [selectedIds]);

  return (
    <>
      <Box position="relative">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" noWrap>
              {labelText}
            </Typography>
            <StyledIcon icon="filter" size="sm" color="grey" $isEmpty={isEmpty(selectedIds)} />
          </Box>
        </Button>
        {/* Buttonのhoverも効いてしまうので内包しないようにする */}
        {!isEmpty(selectedIds) && (
          <StyledIconButton icon="close" size="sm" color="grey" onClick={onDeleteAll} />
        )}
      </Box>

      <Menu
        key="statusFilter"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: -8, horizontal: "right" }}
      >
        <AnswerStatusSelectMenu
          selectedIds={selectedIds}
          onChangeSelected={(answerStatusId) => onChange(answerStatusId)}
        />
        {!isEmpty(selectedIds) && (
          <Box mb="8px" px="24px">
            <StyledResetTypography variant="body2" color="primary" onClick={() => onDeleteAll()}>
              全てクリア
            </StyledResetTypography>
          </Box>
        )}
      </Menu>
    </>
  );
};

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;

const StyledResetTypography = styled(Typography)`
  cursor: pointer;
`;
