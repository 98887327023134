import { Box, Button } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import styled from "styled-components";

import { QuestionPartBase } from "./QuestionPartBase";

import { Icon } from "~/components/uiParts";
import { useMetaDataByUrls } from "~/hooks/file";
import { useFileViewer } from "~/hooks/shared";

type Props = {
  questionTitle: string;
  sampleFilePath?: string;
  isRequired?: boolean;
};

export const FileQuestionPart = memo<Props>(({ questionTitle, sampleFilePath, isRequired }) => {
  const { handleDownloadFile } = useFileViewer();
  const { data: files, isLoading } = useMetaDataByUrls(sampleFilePath ? [sampleFilePath] : []);

  const handleClickCheckFile = useCallback(
    (file: { name: string; size: number; url: string }) => {
      handleDownloadFile(file.url, [file]);
    },
    [handleDownloadFile]
  );

  return (
    <QuestionPartBase questionTitle={questionTitle} isRequired={isRequired} alignItems="flex-start">
      <OnnTaskDetailBox>※回答者はファイルの添付を行うことができます</OnnTaskDetailBox>
      {sampleFilePath && (
        <StyledButton
          color="primary"
          onClick={() =>
            files ? handleClickCheckFile(files[0] as (typeof files)[number]) : void 0
          }
          startIcon={<Icon icon="eye" color="primary" size="md" />}
          style={{ fontWeight: 700 }}
          disabled={isLoading}
        >
          テンプレートファイルを確認
        </StyledButton>
      )}
    </QuestionPartBase>
  );
});

const OnnTaskDetailBox = styled(Box)`
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid;
  color: ${(props) => props.theme.palette.grey[200]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
  white-space: pre-wrap;
`;

const StyledButton = styled(Button)`
  padding: 4px 12px;
  font-size: 12px;
  .MuiButton-startIcon {
    margin-right: 4px;
  }
`;
