import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { DatePickerV2 } from "~/components/uiParts";

type Props = {
  value: Date | null;
  onChange: (date: Date | null) => void;
  helperText: string;
  error?: boolean;
  disabled?: boolean;
};

export const DeadlineDatePicker: FC<Props> = ({
  value,
  onChange,
  helperText,
  error,
  disabled = false,
}) => {
  return (
    <>
      <Box>
        <Box width="200px" mb="8px">
          <StyledDatePicker
            name="deadlineDate"
            placeholder="日程を選択"
            fullWidth
            value={value}
            onChange={onChange}
            helperText={helperText}
            error={error}
            disabled={disabled}
            disablePast
          />
        </Box>
      </Box>
    </>
  );
};

// ヘルパーテキストを改行させないように設定してます
const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 280px;
  }
`;
