import { IndividualInvitationSetting } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export const generateKeyObj = (params: { employeeId: string }) => {
  return {
    endpoint: `/get_individual_invitation_setting`,
    ...params,
  } as const;
};

export const useIndividualInvitationSetting = ({ employeeId }: { employeeId: string }) => {
  const keyObj = generateKeyObj({
    employeeId,
  });

  return useSWR(keyObj, async ({ endpoint, employeeId }) => {
    const response = await apiClient.get(endpoint, {
      employeeId: employeeId,
    });

    return response.data.individualInvitationSetting
      ? new IndividualInvitationSetting(response.data.individualInvitationSetting)
      : null;
  });
};
