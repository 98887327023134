import React, { FC } from "react";
import styled from "styled-components";

import { TextField } from "~/components/uiParts";

export type TextFieldOfDeliveryAtHourProps = {
  value?: number;
  onChange: (value: number) => void;
  errorMessage?: string;
};

export const TextFieldOfDeliveryAtHour: FC<TextFieldOfDeliveryAtHourProps> = (
  deliveryAtHourForm
) => {
  return (
    <StyledTextField
      type="number"
      fullWidth
      value={
        deliveryAtHourForm.value === undefined ? undefined : deliveryAtHourForm.value.toString()
      }
      onChange={(e) => {
        const value = Number(e.target.value);
        if (isNaN(value)) {
          deliveryAtHourForm.onChange(0);
        } else {
          deliveryAtHourForm.onChange(value < 0 ? 0 : value);
        }
      }}
      error={!!deliveryAtHourForm.errorMessage}
      helperText={deliveryAtHourForm.errorMessage}
    />
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    background: ${({ theme }) => theme.palette.background.paper};
  }
`;
