import { Box } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { mixin } from "~/util";

type Props = {
  children: React.ReactNode;
  style?: ComponentProps<typeof Box>["style"];
};

export const CenterBox: FC<Props> = ({ children, style }) => {
  return <StyledBox style={style}>{children}</StyledBox>;
};

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mixin.breakUp.sm`
    height: 100%;
  `}
`;
