import { Box } from "@material-ui/core";
import { Announcement } from "@onn/common";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Paper, Typography, Button, Tooltip, Icon, Linkify } from "~/components/uiParts";
import { FileAttachedButtonForStorage } from "~/components/uiParts/FileAttachedButton";
import { useCurrentUser } from "~/hooks/employee";
import { useMetaDataByUrls } from "~/hooks/file";
import { useOpenPortalAnnouncementPreview } from "~/hooks/openPortalPreview/useOpenPortalAnnouncementPreview";
import { isImage } from "~/util";

type Props = {
  announcement: Announcement;
};

export const AnnouncementContentContainer = ({ announcement }: Props): JSX.Element => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { data: imageFiles = [] } = useMetaDataByUrls(announcement.filePaths.filter(isImage));
  const { openPortalAnnouncementPreview } = useOpenPortalAnnouncementPreview();

  const onClickEditAnnouncement = useCallback(() => {
    navigate(`/announcements/${announcement.id}/edit/?from_page=detail`);
  }, [announcement.id, navigate]);

  return (
    <ContentPaper square={false}>
      <Box display="flex" alignItems="center" mb="24px" gridGap="16px">
        <Box flexGrow={1}>
          <Typography variant="h3">{announcement.title}</Typography>
        </Box>
        <Button
          variant="text"
          borderRadius="regular"
          onClick={() => openPortalAnnouncementPreview(announcement)}
          color="primary"
          startIcon={<Icon icon="eye" color={"primary"} size="md" />}
        >
          プレビュー
        </Button>
        {currentUser.isAdmin() && (
          <Button
            onClick={onClickEditAnnouncement}
            color="primary"
            variant="outlined"
            borderRadius="regular"
          >
            編集
          </Button>
        )}
      </Box>
      <Box mb="32px">
        <ContentBox>
          <Linkify>{announcement.content}</Linkify>
        </ContentBox>
        <Box mt="32px" display="flex" flexDirection="column" gridGap="16px" alignItems="start">
          {imageFiles.map((file) => (
            <Box key={file.url}>
              <StyledImg src={file.url} alt={file.name} />
            </Box>
          ))}
          {announcement.filePaths.map((filePath) =>
            isImage(filePath) ? (
              <></>
            ) : (
              <Box key={filePath} width="50%">
                <FileAttachedButtonForStorage filePath={filePath} />
              </Box>
            )
          )}
        </Box>
      </Box>
      <Box mb="32px">
        <Box display="flex" alignItems="center" gridGap="8px">
          <Typography variant="body2" bold>
            公開期間
          </Typography>
          <Tooltip
            title="設定された開始日時に配信が開始され、終了日時に非公開化されます。"
            placement="top-start"
          >
            <Icon icon="help" size="ssm" color="grey" />
          </Tooltip>
        </Box>
        <Box mt="16px">
          <Typography variant="body1">
            {announcement.isSettingCompleted()
              ? `${format(announcement.publicationStartDate, "yyyy/MM/dd H:mm", {
                  locale: ja,
                })} 〜 ${format(announcement.publicationEndDate, "yyyy/MM/dd H:mm", {
                  locale: ja,
                })}`
              : "未設定"}
          </Typography>
        </Box>
      </Box>
    </ContentPaper>
  );
};

const ContentPaper = styled(Paper)`
  padding: 40px;
  margin-right: 40px;
  width: 67%;
  height: 100%;
`;

const ContentBox = styled(Box)`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 1px solid;
  color: ${(props) => props.theme.palette.grey[300]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 5px;
  white-space: pre-wrap;
`;

const StyledImg = styled.img`
  max-width: 50%;
`;
