import { Employee } from "@onn/common";
import React, { FC, createContext, ReactNode, useMemo } from "react";
import useSWR from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";

export const EmployeesContext = createContext<{
  allEmployees: Employee[] | undefined;
  mutateAllEmployees: () => void;
}>({
  allEmployees: undefined,
  mutateAllEmployees: () => void 0,
});

export const EmployeesProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  const shouldFetchAllEmployees = !currentUser.isNewcomer();
  const { data, mutate: mutateAllEmployees } = useSWR(
    shouldFetchAllEmployees ? ["all-employees", currentUser.tenantId] : null,
    () => EmployeeUseCase.findAll(currentUser.tenantId).then((res) => res)
  );

  const allEmployees = useMemo(() => {
    return data || [currentUser];
  }, [currentUser, data]);

  if (shouldFetchAllEmployees && !allEmployees) return null;

  return (
    <EmployeesContext.Provider value={{ allEmployees, mutateAllEmployees }}>
      {children}
    </EmployeesContext.Provider>
  );
};
