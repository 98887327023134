import { format } from "date-fns";
import React, { FC } from "react";

import { NoneCell } from "../../../common/NoneCell";

import { Cell } from "../../_share/Cell";

import { AnswerStatusChips } from "~/components/domains/onnTasks/AnswerStatusChips";
import { Typography } from "~/components/uiParts";

export const OnnTaskStatusCell: FC<{
  isAlreadyAnswered: boolean;
  lastReadAt?: Date;
}> = ({ isAlreadyAnswered, lastReadAt }) => {
  return (
    <>
      {isAlreadyAnswered ? (
        <Cell>
          <AnswerStatusChips status="回答済み" fontSize={14} type="RecruitmentProcessTab" />
        </Cell>
      ) : lastReadAt ? (
        <Cell>
          <Typography variant="body2" color="textSecondary">
            {`${format(lastReadAt, "yyyy/MM/dd")}既読`}
          </Typography>
        </Cell>
      ) : (
        <NoneCell />
      )}
    </>
  );
};
