import { z } from "zod";

/////////////////////////////////////////
// CONTACT MESSAGE TEMPLATE SCHEMA
/////////////////////////////////////////

export const ContactMessageTemplateSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  folderId: z.string().nullish(),
  title: z.string(),
  text: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ContactMessageTemplate = z.infer<typeof ContactMessageTemplateSchema>;

/////////////////////////////////////////
// CONTACT MESSAGE TEMPLATE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ContactMessageTemplateOptionalDefaultsSchema = ContactMessageTemplateSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type ContactMessageTemplateOptionalDefaults = z.infer<
  typeof ContactMessageTemplateOptionalDefaultsSchema
>;

export default ContactMessageTemplateSchema;
