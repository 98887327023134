import { FileType } from "@onn/common";
import React, { useRef, useCallback, FC, ReactNode } from "react";
import styled, { css } from "styled-components";

import { filterFilesByType, getAcceptValueForFileTypes } from "~/util/fileUtil";

export type Payload = { status: "success"; files: File[] } | { status: "error"; message: string };

type Props = {
  accepts?: FileType[];
  /**
   * 内部で利用しているinput要素のaccept属性に指定するための値
   * 指定しない場合にはacceptsプロパティに対応したファイル形式となる
   */
  inputAccept?: string;
  maxFileSizeMb?: number;
  multiple?: boolean;
  disabled?: boolean;
  children: ReactNode;
  onChange: (payload: Payload) => void;
  validateAfterParsing?: (files: File[]) => Promise<{ isValid: boolean; errorMessage?: string }>;
};

export const FilePicker: FC<Props> = ({
  accepts = [],
  inputAccept,
  maxFileSizeMb,
  multiple,
  disabled,
  children,
  onChange,
  validateAfterParsing,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.value = "";
    inputRef.current.click();
  }, []);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        onChange({ status: "error", message: "ファイルを選択してください。" });
        return;
      }

      const files = Array.from(e.target.files);

      if (filterFilesByType(files, accepts).length !== files.length) {
        onChange({ status: "error", message: "対応していない形式のファイルが含まれています" });
        return;
      }

      if (maxFileSizeMb && files.some((file) => file.size > maxFileSizeMb * 1024 * 1024)) {
        onChange({
          status: "error",
          message: `${maxFileSizeMb}MB以下のファイルのみアップロード可能です`,
        });
        return;
      }
      if (validateAfterParsing) {
        const { isValid, errorMessage } = await validateAfterParsing(files);

        if (!isValid) {
          onChange({ status: "error", message: errorMessage || "" });
          return;
        }
      }

      onChange({ status: "success", files });
    },
    [accepts, onChange, maxFileSizeMb, validateAfterParsing]
  );

  return (
    <>
      {/* childrenにクリッカブルな要素が含まれている場合に、ファイル選択UIが期待通りに開かない場合があったので、あえてlabel要素とinput要素を紐付けていない */}
      <StyledLabel disabled={disabled} onClick={handleClick}>
        {children}
      </StyledLabel>
      <StyledInput
        type="file"
        name="file"
        onClick={handleClick}
        onChange={handleChange}
        accept={inputAccept || getAcceptValueForFileTypes(accepts)}
        disabled={disabled}
        multiple={multiple}
        ref={inputRef}
      />
    </>
  );
};

const disabledStyle = css`
  cursor: not-allowed;
  pointer-events: none;
`;

const StyledLabel = styled.label<{ disabled?: boolean }>`
  cursor: pointer;
  ${(props) => props.disabled && disabledStyle}
`;

const StyledInput = styled.input`
  display: none;
`;
