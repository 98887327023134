import { OnnEvent } from "@onn/common";
import { useState } from "react";

export const useFilterByAssignees = (onnEvents: OnnEvent[]) => {
  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([]);

  const handleChangeAssigneeFilter = (selectedEmployeeIds: string[]) => {
    setSelectedAssigneeIds(selectedEmployeeIds);
  };

  const filterOnnEventsByAssignees = (onnEvents: OnnEvent[], selectedAssigneeIds: string[]) => {
    if (!selectedAssigneeIds.length) return onnEvents;
    return onnEvents.filter((v) => selectedAssigneeIds.some((u) => v.assigneeIds.includes(u)));
  };

  // フィルタリング結果
  const onnEventsFilteredByAssignees = filterOnnEventsByAssignees(onnEvents, selectedAssigneeIds);

  return {
    selectedAssigneeIds,
    handleChangeAssigneeFilter,
    onnEventsFilteredByAssignees,
  };
};
