import {
  FormRevision,
  OnnFormTask,
  OnnTaskQuestion,
  toCloudFunctionsCompatible,
} from "@onn/common";
import { useCallback } from "react";

import { useLocalStorage } from "~/hooks/shared";

export const useOpenPortalOnnTaskPreview = () => {
  const { storeValue } = useLocalStorage();

  /**
   * ポータルのタスクプレビューを開く
   */
  const openPortalOnnTaskPreview = useCallback(
    ({
      onnFormTask,
      questions,
      formRevision,
    }: {
      onnFormTask: OnnFormTask;
      questions: OnnTaskQuestion[];
      formRevision?: FormRevision;
    }) => {
      storeValue("OnnFormTaskForPreview", {
        onnFormTask: toCloudFunctionsCompatible(onnFormTask),
        questions: questions.map((q) => toCloudFunctionsCompatible(q)),
        formRevision: formRevision ? toCloudFunctionsCompatible(formRevision) : undefined,
      });
      window.open(
        `/portal/onn_tasks/${onnFormTask.id}/answer?preview=true`,
        "popup",
        "width=375,height=480"
      );
    },
    [storeValue]
  );

  return { openPortalOnnTaskPreview };
};
