import {
  CandidateDateWithNumberOfParticipants,
  Employee,
  EmployeeActiveLog,
  OnnEventAnswer,
  OnnEventDeterminedDate,
} from "@onn/common";
import { isPast } from "date-fns";

type Args = {
  onnEventDeterminedDate?: OnnEventDeterminedDate;
  onnEventAnswer: OnnEventAnswer;
  answeredCandidateDate?: CandidateDateWithNumberOfParticipants;
  employeeActiveLog?: EmployeeActiveLog;
  newGraduate: Employee;
};

// 回答結果一覧テーブルのUIのためにのみ扱われるステータスを返す
export const getStatusForDisplayEventTable = (args: Args) => {
  const {
    onnEventDeterminedDate,
    onnEventAnswer,
    answeredCandidateDate,
    employeeActiveLog,
    newGraduate,
  } = args;
  if (!newGraduate.isNotifiableBySelectedAuthenticationFlowType()) return "not_notifiable";
  if (!answeredCandidateDate) {
    if (onnEventAnswer.isUnavailableCandidates()) return "unregistered_attendance_and_not_past";
    return employeeActiveLog ? "before_answer_and_read" : "before_answer_and_unread";
  }
  if (onnEventDeterminedDate?.attendanceStatus === "ATTENDED") return "registered_attendance";
  if (onnEventDeterminedDate?.attendanceStatus === "ABSENT") return "absent_attendance";

  return isPast(answeredCandidateDate.from)
    ? "unregistered_attendance_and_past"
    : "unregistered_attendance_and_not_past";
};

export type StatusForDisplayEventTable = ReturnType<typeof getStatusForDisplayEventTable>;

export const statusToLabelForFilter: { [key in StatusForDisplayEventTable]: string } = {
  before_answer_and_unread: "配信済み",
  before_answer_and_read: "既読",
  unregistered_attendance_and_not_past: "回答済み",
  unregistered_attendance_and_past: "参加未登録",
  registered_attendance: "参加済み",
  absent_attendance: "不参加",
  not_notifiable: "ブロック",
};

export const invalidStatus: StatusForDisplayEventTable[] = ["not_notifiable"];
