import { z } from "zod";

import { ActionSchema } from "../../../domain/_gen/zodSchema";

export const actionSchema = ActionSchema.extend({
  executionTimeAfterDays: z.number().min(1).optional(),
  executionTimeAtHours: z.number().min(1).optional(),
  executionTimeWithinMinutes: z.number().min(1).optional(),
}).superRefine((data, ctx) => {
  if (
    (data.executionTimeAfterDays !== undefined || data.executionTimeAtHours !== undefined) &&
    data.executionTimeWithinMinutes !== undefined
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        "次のプロパティの組み合わせを同時に設定することはできません: executionTimeAfterDays,executionTimeAtHours と executionTimeWithinMinutes",
      path: ["executionTimeAfterDays", "executionTimeAtHours", "executionTimeWithinMinutes"],
    });
  }

  if (!!data.executionTimeAfterDays !== !!data.executionTimeAtHours) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        "次のプロパティを設定する場合、同時に設定する必要があります: executionTimeAfterDays, executionTimeAtHours",
      path: ["executionTimeAfterDays", "executionTimeAtHours"],
    });
  }

  return data;
});
