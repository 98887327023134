import { IconButton } from "@material-ui/core";
import React, { FC, ComponentProps, useCallback, ChangeEvent, useState } from "react";

import { Icon } from "~/components/uiParts/Icon";
import { TextFieldDeprecated } from "~/components/uiParts/TextField";

type TextFieldProps = ComponentProps<typeof TextFieldDeprecated>;

type Props = {
  value: string;
  onChange: (value: string) => void;
} & Pick<
  TextFieldProps,
  | "fullWidth"
  | "disabled"
  | "error"
  | "helperText"
  | "autoFocus"
  | "onFocus"
  | "onBlur"
  | "autoComplete"
  | "label"
  | "variant"
  | "placeholder"
>;

export const PasswordField: FC<Props> = ({
  onChange,
  error,
  label = "パスワード",
  autoComplete = "password",
  ...rest
}) => {
  const [masked, setMasked] = useState(true);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange]
  );
  const handleToggleMasked = useCallback(() => setMasked((v) => !v), []);

  return (
    <>
      <TextFieldDeprecated
        type={masked ? "password" : "text"}
        autoComplete={autoComplete}
        label={label}
        variant="outlined"
        onChange={handleChange}
        error={error}
        endAdornment={
          <IconButton size="small" onClick={handleToggleMasked}>
            {masked ? (
              <Icon icon="visibility" color="lightGrey" size="md" />
            ) : (
              <Icon icon="unVisibility" color="lightGrey" size="md" />
            )}
          </IconButton>
        }
        {...rest}
      />
    </>
  );
};
