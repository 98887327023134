import { Box } from "@material-ui/core";
import { Employee, getHowLongAgo } from "@onn/common";
import { isPast } from "date-fns";
import React, { FC, useCallback } from "react";

import { useContextOfListOfEmployeesParticipatingForSlotModal } from "../../Context";

import { AttendanceStatusSelector, DeterminedDateWithEmployee } from "./AttendanceStatus";

import { IconButton, UncontrolledMenu, UserIconWithLabel } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const EmployeeRow: FC<{ determinedDateWithEmployee: DeterminedDateWithEmployee }> = ({
  determinedDateWithEmployee,
}) => {
  const { handleModal } = useModal();
  const { onnEvent, slotId, slotFrom, briefingSessionCategoryId } =
    useContextOfListOfEmployeesParticipatingForSlotModal();
  const isShowAttendanceStatus = isPast(slotFrom);

  const openNewInterviewAnswerForUpdateModal = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "answerEventOnBehalfModal",
        args: {
          onnEvent,
          fixedOption: {
            selectedEmployeeId: employee.id,
          },
          mode: {
            type: "edit",
            onnEventSlotDateId: slotId,
            briefingSessionCategoryId,
          },
          slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
        },
      });
    },
    [briefingSessionCategoryId, handleModal, onnEvent, slotId]
  );

  const onClickOpenRemoveDeterminedDateModal = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "removeNewInterviewDeterminedDateModal",
        args: {
          onnEvent,
          employee,
          isAlreadyAnswered: true,
        },
      });
    },
    [handleModal, onnEvent]
  );

  return (
    <Box
      key={determinedDateWithEmployee.id}
      p="8px"
      width={"100%"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <UserIconWithLabel
        size="small"
        name={determinedDateWithEmployee.employee.getName()}
        secondaryText={
          determinedDateWithEmployee.employee.lastActiveTime
            ? `最終アクセス：${getHowLongAgo(determinedDateWithEmployee.employee.lastActiveTime)}`
            : "未読"
        }
        textSize={"normal"}
        iconPath={determinedDateWithEmployee.employee.profileIconImageUrl}
        to={`/employee/${determinedDateWithEmployee.employee.id}`}
        isTargetBlank={true}
      />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          columnGap: "16px",
        }}
      >
        {isShowAttendanceStatus && (
          <AttendanceStatusSelector determinedDateWithEmployee={determinedDateWithEmployee} />
        )}
        <UncontrolledMenu
          renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
          menuItemOptions={[
            {
              text: "予約変更",
              onClick: () =>
                openNewInterviewAnswerForUpdateModal(determinedDateWithEmployee.employee),
            },
            {
              text: "予約取り消し",
              onClick: () =>
                onClickOpenRemoveDeterminedDateModal(determinedDateWithEmployee.employee),
            },
          ]}
        />
      </Box>
    </Box>
  );
};
