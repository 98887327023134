import { RegistrationInvitationLink } from "@onn/common";
import React, { useCallback } from "react";

import { ModalContent } from "./ModalContent";

import { ModalFooter } from "./ModalFooter";

import { useGenerateRegistrationInvitationLinkUrl } from "./useGenerateRegistrationInvitationLinkUrl";

import { useClipboard } from "~/hooks/shared";
import { useDownloadSvg } from "~/hooks/useDownloadSvg";

export const useShowQRModeModal = ({
  toAddConditionMode,
  defaultRegistrationInvitationLink,
  createdRegistrationInvitationLink,
}: {
  toAddConditionMode: () => void;
  defaultRegistrationInvitationLink: RegistrationInvitationLink;
  createdRegistrationInvitationLink: RegistrationInvitationLink | null;
}) => {
  const { generateRegistrationInvitationLinkUrl } = useGenerateRegistrationInvitationLinkUrl();
  const { handleCopy } = useClipboard();

  // 共通URL
  const registrationInvitationLinkUrl = generateRegistrationInvitationLinkUrl({
    registrationInvitationLinkId:
      createdRegistrationInvitationLink?.id || defaultRegistrationInvitationLink.id,
  });

  // QRコードを画像としてダウンロードする関数
  const { divRef: qrCodeDivRef, downloadSvg } = useDownloadSvg(`Onnアカウント登録QRコード`);

  // 招待URLをクリップボードにコピーする
  const addRegistrationInvitationLinkUrlToClipBoard = useCallback(() => {
    handleCopy(registrationInvitationLinkUrl, "招待URL");
  }, [handleCopy, registrationInvitationLinkUrl]);

  return {
    title: "招待QR・URL",
    titleHint:
      "招待QR・URLを知っている人は誰でもアカウント登録を行うことが可能です。対象候補者のスマートフォンでQRコードを直接読み取ってもらうか、QRコード画像または招待URLを共有してアカウント登録の依頼を行なってください。",
    content: (
      <ModalContent
        toAddConditionMode={toAddConditionMode}
        qrCodeDivRef={qrCodeDivRef}
        registrationInvitationLinkUrl={registrationInvitationLinkUrl}
        isCreatedRegistrationInvitationLink={!!createdRegistrationInvitationLink}
      />
    ),
    footer: (
      <ModalFooter
        downloadSvg={downloadSvg}
        addRegistrationInvitationLinkUrlToClipBoard={addRegistrationInvitationLinkUrlToClipBoard}
      />
    ),
  };
};
