import { z } from "zod";

/////////////////////////////////////////
// ANSWER ONN EVENT TRIGGER SETTING SCHEMA
/////////////////////////////////////////

export const AnswerOnnEventTriggerSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  triggerId: z.string(),
  onnEventId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AnswerOnnEventTriggerSetting = z.infer<typeof AnswerOnnEventTriggerSettingSchema>;

/////////////////////////////////////////
// ANSWER ONN EVENT TRIGGER SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const AnswerOnnEventTriggerSettingOptionalDefaultsSchema =
  AnswerOnnEventTriggerSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type AnswerOnnEventTriggerSettingOptionalDefaults = z.infer<
  typeof AnswerOnnEventTriggerSettingOptionalDefaultsSchema
>;

export default AnswerOnnEventTriggerSettingSchema;
