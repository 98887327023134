import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useState, useMemo, FC, useContext } from "react";
import styled from "styled-components";

import { RecruitmentStatusFilterMenu } from "../RecruitmentStatusFilterMenu/RecruitmentStatusFilterMenu";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { Button, Icon, IconButton, Typography } from "~/components/uiParts";

type Props = {
  defaultLabel?: string;
  selectedRecruitmentStatusIds: string[];
  setSelectedRecruitmentStatusIds: (ids: string[]) => void;
  includeRejectedAndWithdrew: boolean;
};

export const RecruitmentStatusFilter: FC<Props> = ({
  defaultLabel = "ステータス",
  selectedRecruitmentStatusIds,
  setSelectedRecruitmentStatusIds,
  includeRejectedAndWithdrew,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { recruitmentStatuses } = useContext(RecruitmentStatusesContext);

  const recruitmentStatusKeys = recruitmentStatuses.map((v) => v.type);

  const labelText = useMemo(() => {
    if (
      isEmpty(selectedRecruitmentStatusIds) ||
      selectedRecruitmentStatusIds.length === recruitmentStatusKeys.length
    ) {
      return defaultLabel;
    }
    return `${selectedRecruitmentStatusIds.length}個のステータス`;
  }, [selectedRecruitmentStatusIds, recruitmentStatusKeys.length, defaultLabel]);

  return (
    <>
      <Box position="relative">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          borderRadius="regular"
          variant="outlined"
          color="default"
          fullWidth
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" noWrap>
              {labelText}
            </Typography>
            <StyledIcon
              icon="filter"
              size="sm"
              color="grey"
              $isEmpty={isEmpty(selectedRecruitmentStatusIds)}
            />
          </Box>
        </Button>
        {/* Buttonのhoverも効いてしまうので内包しないようにする */}
        {!isEmpty(selectedRecruitmentStatusIds) && (
          <StyledIconButton
            icon="close"
            size="sm"
            color="grey"
            onClick={() => setSelectedRecruitmentStatusIds([])}
          />
        )}
      </Box>

      <RecruitmentStatusFilterMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedRecruitmentStatusIds={selectedRecruitmentStatusIds}
        setSelectedRecruitmentStatusIds={setSelectedRecruitmentStatusIds}
        includeRejectedAndWithdrew={includeRejectedAndWithdrew}
      />
    </>
  );
};

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;
