import { isEmpty } from "lodash";

import { useCurrentUser } from "~/hooks/employee";

import { useOnnTasksForPortal } from "~/hooks/onnTask/useOnnTasksForPortal";

export const useCanDisplayOnnTasks = (isPreview?: boolean) => {
  const { currentUser } = useCurrentUser();
  const { data: onnTasks } = useOnnTasksForPortal({ employeeId: currentUser.id, isPreview });

  const canDisplayOnnTasks = () => {
    return !isEmpty(onnTasks);
  };

  return { canDisplayOnnTasks };
};
