import { z } from "zod";

/////////////////////////////////////////
// REGISTRATION RICH MENU RELATION SCHEMA
/////////////////////////////////////////

export const RegistrationRichMenuRelationSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  scenarioId: z.string(),
  internalRichMenuId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RegistrationRichMenuRelation = z.infer<typeof RegistrationRichMenuRelationSchema>;

/////////////////////////////////////////
// REGISTRATION RICH MENU RELATION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const RegistrationRichMenuRelationOptionalDefaultsSchema =
  RegistrationRichMenuRelationSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type RegistrationRichMenuRelationOptionalDefaults = z.infer<
  typeof RegistrationRichMenuRelationOptionalDefaultsSchema
>;

export default RegistrationRichMenuRelationSchema;
