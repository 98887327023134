import { Box } from "@material-ui/core";
import { AnyTriggerSetting } from "@onn/common";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { TriggerDataIdRendererForDeveloper } from "./TriggerDataIdRendererForDeveloper";
import { TriggerIcon } from "./TriggerIcon";

import { Icon, IconButton, TooltipWhenTextTruncated, Typography } from "~/components/uiParts";
import { usePageContext } from "~/pages/scenario/pageContext";

type Props = {
  type: AnyTriggerSetting["type"];
  triggerSettingId: string;
  triggerId: string;
  triggerDescription: string;
  triggerSubDescription?: string;
  waitingTriggerBlockCount: number;
  actionCount: number;
  actionConditionCount?: number;
  isError: boolean;
} & (
  | {
      isEditMode: true;
      onClickEditButton: () => void;
      editButtonRef: React.RefObject<HTMLButtonElement>;
    }
  | {
      isEditMode?: false;
      onClickEditButton?: undefined;
      editButtonRef?: undefined;
    }
);

export const TriggerDescriptionArea: FC<Props> = ({
  actionCount,
  actionConditionCount,
  isError,
  isEditMode,
  onClickEditButton,
  editButtonRef,
  ...props
}) => {
  const { isDeveloperMode } = usePageContext();
  const triggerSubDescription = useMemo(
    () => props.triggerSubDescription || "",
    [props.triggerSubDescription]
  );
  return (
    <Root>
      <TriggerIconWrapper $isError={isError} flexShrink={0}>
        {isError ? (
          <Icon icon="alert" size="md" color="secondary" />
        ) : (
          <TriggerIcon type={props.type} />
        )}
      </TriggerIconWrapper>
      {/* 説明文が長い時に、アイコンの横幅を保つためにflexアイテムに横幅指定 */}
      {/* 編集時の編集ボタン分の */}
      <Box width={`${430 - (isEditMode ? 48 : 0)}px`}>
        {triggerSubDescription && (
          <Box width="100%">
            <TooltipWhenTextTruncated text={triggerSubDescription}>
              {(ref) => (
                <Typography
                  bold
                  variant="caption"
                  color={isError ? "secondary" : "primary"}
                  component={"div"}
                  noWrap
                  ref={ref}
                >
                  {triggerSubDescription}
                </Typography>
              )}
            </TooltipWhenTextTruncated>
          </Box>
        )}
        <Box width="100%">
          <TooltipWhenTextTruncated text={props.triggerDescription}>
            {(ref) => (
              <Typography
                bold
                color={isError ? "secondary" : "textPrimary"}
                variant="body2"
                noWrap
                ref={ref}
              >
                {props.triggerDescription}
              </Typography>
            )}
          </TooltipWhenTextTruncated>
        </Box>
        <Box width="100%">
          {isDeveloperMode && (
            <TriggerDataIdRendererForDeveloper
              triggerId={props.triggerId}
              triggerSettingId={props.triggerSettingId}
              waitingTriggerBlockCount={props.waitingTriggerBlockCount}
            />
          )}
          <Typography color="textSecondary" variant="caption" component="div">
            {`アクション：${actionCount}${
              actionConditionCount ? `、アクション条件：${actionConditionCount}` : ""
            }`}
          </Typography>
        </Box>
      </Box>
      {isEditMode && (
        <Box flexShrink={0}>
          <IconButton
            size="md"
            icon="pencil"
            onClick={onClickEditButton}
            buttonRef={editButtonRef}
          />
        </Box>
      )}
    </Root>
  );
};

const Root = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const TriggerIconWrapper = styled(Box)<{ $isError: boolean }>`
  border-radius: 5px;
  border: 1px solid
    ${(props) =>
      props.$isError ? props.theme.palette.secondary.main : props.theme.palette.primary.main};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
