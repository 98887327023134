import { Box } from "@material-ui/core";
import { OnnFormTask } from "@onn/common";
import React from "react";

import { TabPageCore } from "./TabPageCore";

import { Loading } from "~/components/uiParts";
import { useAllNewcomers } from "~/hooks/employee";
import { useOnnFormTaskAnswers } from "~/hooks/onnFormTaskAnswer";
import { useFormRevisions } from "~/hooks/onnTask";

type Props = {
  onnFormTask: OnnFormTask;
};

export const TabPage = ({ onnFormTask }: Props) => {
  const { data: onnFormTaskAnswers, isLoading: isLoadingOnnFormTaskAnswers } =
    useOnnFormTaskAnswers({
      onnTaskId: onnFormTask.id,
    });
  const { data: allNewGraduates = [], isLoading: isLoadingAllNewGraduates } = useAllNewcomers();
  const { data: formRevisionsData, isLoading: isLoadingFormRevisions } = useFormRevisions({
    onnFormTaskId: onnFormTask.id,
  });

  const targetNewGraduates = allNewGraduates.filter((employee) => {
    return onnFormTaskAnswers?.some(
      (onnFormTaskAnswer) => onnFormTaskAnswer.employeeId === employee.id
    );
  });

  if (isLoadingOnnFormTaskAnswers || isLoadingAllNewGraduates || isLoadingFormRevisions) {
    return (
      <Box mt="40px">
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <TabPageCore
      onnFormTaskAnswers={onnFormTaskAnswers || []}
      targetNewGraduates={targetNewGraduates || []}
      formRevisions={formRevisionsData?.formRevisions || []}
      onnFormTask={onnFormTask}
    />
  );
};
