/**
 * ストレージに保存されているファイルパスからファイル名を取得する
 * 例）/tenants/tenantId/onn_event_evaluations/sample.zip -> sample.zip
 */
export const getFileLikeObjectsFromFilePath = (filePath: string) => {
  const fileName = filePath.split("/").pop();
  return fileName;
};

/**
 * ストレージに保存されているファイルパスの配列からファイル名を取得した配列を取得する
 * ※ ファイル名が取得できない場合は除外する
 * 例）["/tenants/tenantId/onn_event_evaluations/sample.zip",""] -> ["sample.zip"]
 */
export const getFileLikeObjectsFromFilePaths = (filePaths: string[]) => {
  return filePaths.flatMap((filePath) => {
    const fileName = getFileLikeObjectsFromFilePath(filePath);
    if (fileName) {
      return { name: fileName };
    }
    return [];
  });
};
