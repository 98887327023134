import { Box } from "@material-ui/core";

import { Employee } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import { List as VirtualizedList } from "react-virtualized";
import styled from "styled-components";

import { ClosableEmployeeIconAndName } from "../ClosableEmployeeIconAndName";

import { Icon, Loading, UserIconWithLabel } from "~/components/uiParts";

import { useEmployees } from "~/hooks/employee";

const useVirtualizedList = (_itemCount: number) => {
  const ROW_HEIGHT = 56;

  const virtualizedListHeight = useMemo(() => {
    const itemCount = _itemCount;
    if (itemCount === 0) return 0;
    if (itemCount < 3) return ROW_HEIGHT;
    if (itemCount < 5) return ROW_HEIGHT * 2;
    if (itemCount < 7) return ROW_HEIGHT * 3;
    return ROW_HEIGHT * 4;
  }, [_itemCount]);

  const virtualizedListCount = useMemo(() => {
    return Math.ceil(_itemCount / 2);
  }, [_itemCount]);

  return { virtualizedListHeight, virtualizedListCount, ROW_HEIGHT };
};

// 配信予約済みの新卒一覧表示リスト
export const ListOfNewGraduateForDeliver: FC<{
  registeredNewGraduateIds: string[];
  isAlreadyDelivered: boolean;
  onClickDeleteButton?: ((target: Employee) => void) | undefined;
}> = ({ registeredNewGraduateIds, isAlreadyDelivered, onClickDeleteButton }) => {
  // NOTE:
  // 削除ボタンを押したときにregisteredNewGraduateIdsが更新されるが、
  // ユーザー数が多いときに都度APIを叩くと長いローディングが走りUXを損ねるので、初期値をstateとして保持しておく。
  const [initialRegisteredNewGraduateIds] = useState(registeredNewGraduateIds);
  const [initialRegisteredNewGraduateIdsMap] = useState(
    new Map(registeredNewGraduateIds.map((id) => [id, true]))
  );

  const isNotIncludeInItialRegisteredNewGraduateIds = useMemo(() => {
    return !registeredNewGraduateIds.every((id) => initialRegisteredNewGraduateIdsMap.has(id));
  }, [initialRegisteredNewGraduateIdsMap, registeredNewGraduateIds]);

  // NOTE:
  // 再フェッチを防ぎ、すでに取得したデータを再利用するために、initialRegisteredNewGraduateIds をキーにしている。
  // ただし、initialRegisteredNewGraduateIdsに含まれてないIdを registeredNewGraduateIds が含んでいる場合は、
  // registeredNewGraduateIdsをキーにしてAPIを叩く。
  const { data: employees = [], isLoading } = useEmployees(
    isNotIncludeInItialRegisteredNewGraduateIds
      ? registeredNewGraduateIds
      : initialRegisteredNewGraduateIds
  );

  const employeeIdToEmployeeMap = useMemo(() => {
    return new Map(employees.map((employee) => [employee.id, employee]));
  }, [employees]);

  const registeredNewGraduates = registeredNewGraduateIds.flatMap((id) => {
    return employeeIdToEmployeeMap.get(id) || [];
  });

  const { virtualizedListCount, virtualizedListHeight, ROW_HEIGHT } = useVirtualizedList(
    registeredNewGraduates.length
  );

  if (isLoading) return <Loading size="large" />;

  return (
    <VirtualizedList
      height={virtualizedListHeight}
      width={720}
      rowCount={virtualizedListCount}
      rowHeight={ROW_HEIGHT}
      rowRenderer={({ index, key, style }) => {
        const firstIemIndex = index * 2;
        const firstEmployee = registeredNewGraduates[firstIemIndex];
        const secondEmployee = registeredNewGraduates[firstIemIndex + 1];
        return (
          <Box key={key} style={style} sx={{ display: "flex" }}>
            <Box width={"356px"} mr={"8px"}>
              {firstEmployee && (
                <RegisteredNewGraduate
                  newGraduate={firstEmployee}
                  isAlreadyDelivered={isAlreadyDelivered}
                  onClickDeleteButton={onClickDeleteButton}
                />
              )}
            </Box>
            <Box width={"356px"}>
              {secondEmployee && (
                <RegisteredNewGraduate
                  newGraduate={secondEmployee}
                  isAlreadyDelivered={isAlreadyDelivered}
                  onClickDeleteButton={onClickDeleteButton}
                />
              )}
            </Box>
          </Box>
        );
      }}
    />
  );
};

const RegisteredNewGraduate = ({
  newGraduate,
  isAlreadyDelivered,
  onClickDeleteButton,
}: {
  newGraduate: Employee;
  isAlreadyDelivered: boolean;
  onClickDeleteButton?: (target: Employee) => void;
}) => {
  return !onClickDeleteButton || isAlreadyDelivered ? (
    <Box display="flex" justifyContent="space-between" width="344px" p="8px">
      <UserIconWithLabel
        iconPath={newGraduate.profileIconImageUrl || ""}
        name={newGraduate.getName()}
        iconBackgroundColor={newGraduate.uid ? "primary" : "grey"}
        size="small"
      />
      <StyledIconWrapper>
        <Icon icon="close" size="sm" color="grey" />
      </StyledIconWrapper>
    </Box>
  ) : (
    <ClosableEmployeeIconAndName employee={newGraduate} onClickDeleteButton={onClickDeleteButton} />
  );
};

const StyledIconWrapper = styled("div")`
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  &:hover {
    background-color: ${(props) => props.theme.palette.grey[50]};
    cursor: pointer;
  }
`;
