import { OnnEventEvaluation, getFileLikeObjectsFromFilePaths } from "@onn/common";
import { useCallback, useState } from "react";

// ファイル添付フォームのカスタムフック
export const useAttachedFilesForm = (_currentOnnEventEvaluation?: OnnEventEvaluation) => {
  const alreadyExistAttachedDummyFile = getFileLikeObjectsFromFilePaths(
    _currentOnnEventEvaluation?.filePaths || []
  );
  const [attachedFiles, setAttachedFiles] = useState<File[] | Pick<File, "name">[]>(
    alreadyExistAttachedDummyFile
  );
  const onChangeFiles = useCallback((newFiles: (File | Pick<File, "name">)[]) => {
    setAttachedFiles(newFiles);
  }, []);
  return { attachedFiles, onChangeFiles };
};
