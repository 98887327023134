import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";

import { HelpAccordionForOpenLiff } from "~/components/domains/lineUsers/HelpAccordionForOpenLiff";
import { Button, Icon, Typography } from "~/components/uiParts";
import { useClipboard } from "~/hooks/shared";

export const PageOnNotLiffBrowser: FC<{ urlForQRCode: string }> = ({ urlForQRCode }) => {
  const { handleCopy } = useClipboard();

  const handleClickCopyButton = useCallback(() => {
    handleCopy(urlForQRCode, "招待URL");
  }, [handleCopy, urlForQRCode]);

  const handleClickOpenLiff = useCallback(() => {
    window.open(urlForQRCode);
  }, [urlForQRCode]);

  return (
    <Box maxWidth="100%" display="flex" justifyContent="center" px="24px" py="40px">
      <Box maxWidth="500px">
        <Box>
          <Typography variant="h2" bold>
            ログイン方法の変更
          </Typography>
        </Box>
        <Box mt="16px">
          <Box>
            <Typography variant="body1">
              以下のボタンを押すか、QRコードをスキャンしてログイン方法の変更を完了しましょう
            </Typography>
            <Typography variant="caption" component={"div"} style={{ marginTop: "16px" }}>
              ※LINEで「友だち追加を許可」するとログイン方法の変更が完了します
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ backgroundColor: "white" }}
              p="16px"
              my="40px"
            >
              <StyledAnchor href={urlForQRCode} target="_blank" rel="noreferrer">
                <QRCode style={{ height: "148px", width: "148px" }} value={urlForQRCode} />
              </StyledAnchor>
            </Box>
          </Box>
          <Box>
            <Box>
              <Button
                variant="outlined"
                color="primary"
                borderRadius="circle"
                onClick={handleClickOpenLiff}
                fullWidth
              >
                LINEログインへ変更
              </Button>
            </Box>
            <Box mt="16px" height={"48px"} width={"100%"}>
              <Button
                variant="text"
                color="primary"
                borderRadius="circle"
                fullWidth
                startIcon={<Icon icon="link" size="sm" color="primary" />}
                onClick={handleClickCopyButton}
              >
                URLをコピー
              </Button>
            </Box>
          </Box>
          <Box mt="40px">
            <HelpAccordionForOpenLiff buttonText={"LINEログインへ変更"} downloadQRcode={() => 0} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
