import { z } from "zod";

/////////////////////////////////////////
// CHANGE RECRUITMENT STATUS TRIGGER SETTING SCHEMA
/////////////////////////////////////////

export const ChangeRecruitmentStatusTriggerSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  triggerId: z.string(),
  recruitmentStatusId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ChangeRecruitmentStatusTriggerSetting = z.infer<
  typeof ChangeRecruitmentStatusTriggerSettingSchema
>;

/////////////////////////////////////////
// CHANGE RECRUITMENT STATUS TRIGGER SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ChangeRecruitmentStatusTriggerSettingOptionalDefaultsSchema =
  ChangeRecruitmentStatusTriggerSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type ChangeRecruitmentStatusTriggerSettingOptionalDefaults = z.infer<
  typeof ChangeRecruitmentStatusTriggerSettingOptionalDefaultsSchema
>;

export default ChangeRecruitmentStatusTriggerSettingSchema;
