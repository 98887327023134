import {
  AnyActionCondition,
  AnyTriggerSetting,
  EmployeeTagActionCondition,
  OnnEventEvaluationActionCondition,
  OnnFormTaskResultActionCondition,
} from "@onn/common";
import { useCallback } from "react";

import { ActionConditionValidInput } from "../components/ActionConditionForm/actionConditionInputSchema";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const useConvertInputToActionCondition = () => {
  const { currentSpace } = useCurrentSpace();

  const convertInputToActionCondition = useCallback(
    ({
      actionConditionInput,
      actionId,
      triggerSetting,
      existingActionCondition,
    }: {
      actionConditionInput: ActionConditionValidInput["actionCondition"];
      actionId: string;
      triggerSetting: AnyTriggerSetting;
      existingActionCondition?: AnyActionCondition;
    }) => {
      let actionCondition: AnyActionCondition | undefined = undefined;
      switch (actionConditionInput.conditionType) {
        case "EmployeeTagActionCondition": {
          if (existingActionCondition?.type === "EmployeeTagActionCondition") {
            actionCondition = new EmployeeTagActionCondition({
              ...existingActionCondition,
              employeeTagIds: actionConditionInput.tagIds || [],
            });
          } else {
            actionCondition = EmployeeTagActionCondition.create({
              tenantId: currentSpace.tenantId,
              spaceId: currentSpace.id,
              employeeTagIds: actionConditionInput.tagIds || [],
              actionId,
            });
          }
          break;
        }
        case "OnnEventEvaluationActionCondition": {
          if (triggerSetting.type === "EvaluateOnnEventTriggerSetting") {
            if (existingActionCondition?.type === "OnnEventEvaluationActionCondition") {
              actionCondition = new OnnEventEvaluationActionCondition({
                ...existingActionCondition,
                onnEventId: triggerSetting.onnEventId,
                onnEventEvaluationRankIds: actionConditionInput.eventEvaluationRankIds || [],
              });
            } else {
              actionCondition = OnnEventEvaluationActionCondition.create({
                tenantId: currentSpace.tenantId,
                spaceId: currentSpace.id,
                onnEventId: triggerSetting.onnEventId,
                onnEventEvaluationRankIds: actionConditionInput.eventEvaluationRankIds || [],
                actionId,
              });
            }
          }
          break;
        }
        case "OnnFormTaskResultActionCondition": {
          if (triggerSetting.type === "AnswerOnnTaskTriggerSetting") {
            if (existingActionCondition?.type === "OnnFormTaskResultActionCondition") {
              actionCondition = new OnnFormTaskResultActionCondition({
                ...existingActionCondition,
                onnFormTaskId: triggerSetting.onnTaskId,
                questionId: actionConditionInput.questionId,
                optionId: actionConditionInput.questionOptionId,
              });
            } else {
              actionCondition = OnnFormTaskResultActionCondition.create({
                tenantId: currentSpace.tenantId,
                spaceId: currentSpace.id,
                onnFormTaskId: triggerSetting.onnTaskId,
                questionId: actionConditionInput.questionId,
                optionId: actionConditionInput.questionOptionId,
                actionId,
                answerType: "RADIO",
              });
            }
          }
          break;
        }

        case undefined:
        case null: {
          break;
        }
        default: {
          const _exhaustiveCheck: never = actionConditionInput;
          break;
        }
      }
      return actionCondition;
    },
    [currentSpace.id, currentSpace.tenantId]
  );

  return { convertInputToActionCondition };
};
