import { Announcement } from "@onn/common";
import { useCallback } from "react";
import useSWR, { useSWRConfig } from "swr";

import { useCurrentUser } from "../employee";

import { apiClient } from "~/libs";

const generateKey = ({ tenantId }: { tenantId: string }) => {
  return ["/announcement_api/announcements", tenantId] as const;
};

const sortByPublicationStartDate = (a: Announcement, b: Announcement) => {
  if (!a.isSettingCompleted() || !b.isSettingCompleted()) {
    return 0;
  }

  return a.publicationStartDate.getTime() - b.publicationStartDate.getTime();
};

const sortBySettingCompleted = (a: Announcement, b: Announcement) => {
  if (!a.isSettingCompleted()) {
    return -1;
  }

  if (!b.isSettingCompleted()) {
    return 1;
  }

  return 0;
};

export const useAnnouncements = () => {
  const { currentUser } = useCurrentUser();
  const key = generateKey({ tenantId: currentUser.tenantId });

  return useSWR(key, async ([endpoint]) => {
    const announcements = await apiClient.get(endpoint).then((res) => {
      return res.data.announcements.map((data: Announcement) => new Announcement(data));
    });
    return announcements.sort(sortByPublicationStartDate).sort(sortBySettingCompleted);
  });
};

export const useMutateAnnouncements = () => {
  const { currentUser } = useCurrentUser();
  const key = generateKey({ tenantId: currentUser.tenantId });

  const { mutate } = useSWRConfig();

  const mutateAnnouncements = useCallback(async () => {
    await mutate(key);
  }, [key, mutate]);

  return {
    mutateAnnouncements,
  };
};
