import { Box, MenuItem, Menu } from "@material-ui/core";
import { Announcement, AnnouncementDistribution, Employee } from "@onn/common";
import React, { useCallback, useState, MouseEvent, memo, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { DeliveredEmployeeVirtualizedList } from "~/components/domains/delivery/DeliveredEmployeeList";

import {
  Paper,
  Typography,
  Button,
  Loading,
  Icon,
  UserIcon,
  TooltipWhenTextTruncated,
  IconButton,
} from "~/components/uiParts";
import {
  useDeleteAnnouncementDistribution,
  useAnnouncementDistributionsByAnnouncementId,
} from "~/hooks/announcementDistribution";
import { useCurrentUser, useEmployees } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

type Props = { announcement: Announcement };
export const TargetNewGraduateContainer = ({ announcement }: Props): JSX.Element => {
  const [selectedFilter, setSelectedFilter] = useState<"read" | "unread">("read");
  const navigate = useNavigate();
  const { execDeleteAnnouncementDistribution } = useDeleteAnnouncementDistribution();
  const { handleModal } = useModal();
  const {
    data: announcementDistributions = [],
    isLoading: isLoadingAnnouncementDistributions,
    mutate,
  } = useAnnouncementDistributionsByAnnouncementId({
    announcementId: announcement.id,
  });
  const { currentUser } = useCurrentUser();
  const { data: newGraduates = [], isLoading: isLoadingNewGraduates } = useEmployees(
    announcementDistributions.map((d) => d.employeeId)
  );

  const onClickGoToDeliberySettings = useCallback(() => {
    navigate(`/announcements/${announcement.id}/delivery_setting?from_page=detail`);
  }, [announcement.id, navigate]);

  const onClickDeleteButtonForRead = useCallback(
    ({
      announcementDistribution,
      newGraduate,
    }: {
      announcementDistribution: AnnouncementDistribution;
      newGraduate: Employee;
    }) => {
      handleModal({
        name: "announcementDistributionDeleteConfirmModal",
        args: {
          onSubmit: async () => {
            await execDeleteAnnouncementDistribution(announcementDistribution.id);
          },
          announcement,
          announcementDistribution,
          newGraduate,
        },
      });
    },
    [announcement, execDeleteAnnouncementDistribution, handleModal]
  );

  const onClickDeleteButtonForUnread = useCallback(
    async (announcementDistributionId: string) => {
      await execDeleteAnnouncementDistribution(announcementDistributionId);
      mutate();
    },
    [execDeleteAnnouncementDistribution, mutate]
  );

  const readAnnouncementDistributions = useMemo(() => {
    return announcementDistributions.filter((d) => d.isRead());
  }, [announcementDistributions]);
  const unreadAnnouncementDistributions = useMemo(() => {
    return announcementDistributions.filter((d) => !d.isRead());
  }, [announcementDistributions]);

  const announcementDistributionsToDisplay =
    selectedFilter === "read" ? readAnnouncementDistributions : unreadAnnouncementDistributions;

  if (isLoadingAnnouncementDistributions || isLoadingNewGraduates) {
    return (
      <StyledTargetNewGraduatePaperWithPaddingY square={false}>
        <Loading size="large" />
      </StyledTargetNewGraduatePaperWithPaddingY>
    );
  }

  return (
    <TargetNewGraduatePaper square={false}>
      <Box display="flex" alignItems="center" mb="24px">
        <Box flexGrow={1}>
          <Typography variant="body2" bold>
            配信対象({announcementDistributions.length})
          </Typography>
        </Box>
        {currentUser.isAdmin() && announcementDistributions.length !== 0 && (
          <Button
            onClick={onClickGoToDeliberySettings}
            color="primary"
            variant="outlined"
            borderRadius="regular"
          >
            配信設定
          </Button>
        )}
      </Box>
      {announcementDistributions.length !== 0 && (
        <StyledBoxWithFlex mb="24px">
          <StyledDivFlex>
            <Button
              onClick={() => setSelectedFilter("read")}
              color={selectedFilter === "read" ? "primary" : "default"}
              variant={selectedFilter === "read" ? "contained" : "text"}
              borderRadius="regular"
              fullWidth
            >
              <Box>
                既読(
                {isLoadingAnnouncementDistributions ? "-" : readAnnouncementDistributions.length})
              </Box>
            </Button>
          </StyledDivFlex>
          <StyledDivFlex>
            <Button
              onClick={() => setSelectedFilter("unread")}
              color={selectedFilter === "unread" ? "primary" : "default"}
              variant={selectedFilter === "unread" ? "contained" : "text"}
              borderRadius="regular"
              fullWidth
            >
              <Box>
                未読(
                {isLoadingAnnouncementDistributions ? "-" : unreadAnnouncementDistributions.length})
              </Box>
            </Button>
          </StyledDivFlex>
        </StyledBoxWithFlex>
      )}
      <Box>
        {currentUser.isAdmin() && announcementDistributions.length === 0 && (
          <Box>
            {/* <Typography variant="body2" color="textSecondary">
              配信対象を追加しましょう
            </Typography> */}
            <Box mt="24px" display="flex" justifyContent="space-around">
              <Button
                color="primary"
                variant="contained"
                borderRadius="circle"
                startIcon={<Icon icon="add" color="white" size="md" />}
                onClick={onClickGoToDeliberySettings}
              >
                配信対象を追加
              </Button>
            </Box>
          </Box>
        )}
        <DeliveredEmployeeVirtualizedList
          itemCount={announcementDistributionsToDisplay.length}
          rowRenderer={(props) => {
            const announcementDistribution = announcementDistributionsToDisplay[props.index];
            if (!announcementDistribution) return <></>;
            const newGraduate = newGraduates.find(
              (n) => n.id === announcementDistribution.employeeId
            );
            if (!newGraduate) return <></>;
            return (
              <Box
                key={props.key}
                style={props.style} // 動的に計算されるtopなどのプロパティが入る
              >
                <CandidateListItem
                  key={newGraduate.id}
                  selectedFilter={selectedFilter}
                  announcementDistribution={announcementDistribution}
                  newGraduate={newGraduate}
                  onClickOpenRemoveModal={() =>
                    onClickDeleteButtonForRead({ newGraduate, announcementDistribution })
                  }
                  onSubmitRemove={onClickDeleteButtonForUnread}
                />
              </Box>
            );
          }}
        />
      </Box>
    </TargetNewGraduatePaper>
  );
};

type CandidateListItemProps = {
  selectedFilter: "read" | "unread";
  announcementDistribution: AnnouncementDistribution;
  newGraduate: Employee;
  onClickOpenRemoveModal: (announcementDistributionId: string) => Promise<void> | void;
  onSubmitRemove: (announcementDistributionId: string) => Promise<void> | void;
};
const CandidateListItem = memo((props: CandidateListItemProps) => {
  const {
    selectedFilter,
    announcementDistribution,
    newGraduate,
    onClickOpenRemoveModal,
    onSubmitRemove,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { currentUser } = useCurrentUser();

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const shouldShowOptionBtn = currentUser.isAdmin() && !newGraduate.deleted;
  return (
    <Box
      key={announcementDistribution.id}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb="16px"
      position="relative"
    >
      <Box
        width={
          shouldShowOptionBtn ? "85%" : "100%" /* NOTE: VirtualizedTable の widthOptions を模倣 */
        }
        display="flex"
        justifyContent="space-between"
      >
        <TooltipWhenTextTruncated text={newGraduate.getName()}>
          {(ref) => (
            <Box width="100%" display="flex" alignItems="center" gridGap="12px">
              <UserIcon
                username={newGraduate.getName()}
                profileIconImageUrl={newGraduate.profileIconImageUrl}
                size="small"
                backgroundColor={newGraduate.isRegistered() ? "primary" : "grey"}
              />
              <Typography variant="body1" color="textPrimary" bold noWrap ref={ref}>
                {newGraduate.getName()}
              </Typography>
            </Box>
          )}
        </TooltipWhenTextTruncated>
      </Box>
      {shouldShowOptionBtn && (
        <Box width="15%">
          <IconButton icon="menuVert" onClick={handleOpenMenu} />
          <StyledMenu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                selectedFilter === "read"
                  ? onClickOpenRemoveModal(announcementDistribution.id)
                  : onSubmitRemove(announcementDistribution.id);
              }}
            >
              削除
            </MenuItem>
          </StyledMenu>
        </Box>
      )}
    </Box>
  );
});

const TargetNewGraduatePaper = styled(Paper)`
  padding: 24px;
  width: 30%;
`;

const StyledMenu = styled(Menu)`
  margin-top: 8px;
`;

const StyledBoxWithFlex = styled(Box)`
  &.MuiBox-root {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const StyledDivFlex = styled.div`
  flex: 1 1 auto;
`;

const StyledTargetNewGraduatePaperWithPaddingY = styled(TargetNewGraduatePaper)`
  &.MuiPaper-root {
    padding: 50px 0;
  }
`;
