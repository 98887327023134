import liff from "@line/liff";
import { Box } from "@material-ui/core";
import React, { FC, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Paper, Typography } from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";
import { captureException, mixin } from "~/util";

export const ScanQrPage: FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const scanQr = useCallback(() => {
    const errorMessage = "誤ったQRコードです。企業の担当者に正しいQRコードをお問い合わせください。";

    liff
      .scanCodeV2()
      .then((result) => {
        if (!result.value) {
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
          return;
        }

        try {
          const url = new URL(result.value);
          const path = url.pathname;
          const searchParams = url.search;
          navigate(path + searchParams, { state: { from: "ScanQrPage" } });
        } catch (error) {
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
          // 本来は、開発者が検知しなくても良いエラーである
          // 様子を見るために captureException しているが、頻発する場合はこのコードを削除する
          captureException({
            error: error as Error,
            tags: {
              type: "ScanQrPage:scanQr",
            },
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          "読み取りに失敗しました。スマートフォン純正のカメラまたはQRコードスキャン機能から再度QRコードを読み取ってください。",
          {
            variant: "error",
          }
        );
        captureException(error);
      });
  }, [enqueueSnackbar, navigate]);

  useEffect(() => {
    liff.isInClient() ? scanQr() : navigate("/portal");

    // ページ読み込み時のみ実行する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer>
      <StyledPaper>
        <Box pb="10px">
          <Typography variant="h4" bold>
            QRコードスキャン
          </Typography>
        </Box>

        <Box pb="20px">
          <Typography variant="body1">
            Onnが提供するQRコードを読み取ると、そのページへ遷移することができます。
          </Typography>
        </Box>

        <Box textAlign="center">
          <Button variant="outlined" borderRadius="circle" color="primary" onClick={scanQr}>
            カメラを起動
          </Button>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.portalSp`
    padding: 0px;
    height: 100%;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.portalSp`
      box-shadow: none;
      border-radius: 0;
      height: 100%;
      `}
  }
`;
