import { Space } from "@onn/common";
import { useContext } from "react";

import { SpaceContext } from "~/components/providers";

// ポータルで使用するとクラッシュするため使わないように気をつける
export const useCurrentSpace = (): {
  currentSpace: Space;
  switchSpace: (spaceId: string) => void;
  switchSpaceTemporary: (spaceId: string) => void;
  spaces: Space[];
  isShowSpaceOnScreen: (spaces: Space[]) => spaces is NonEmpty<Space>;
} => {
  const { currentSpace, switchSpace, switchSpaceTemporary, spaces, isShowSpaceOnScreen } =
    useContext(SpaceContext);

  if (spaces === undefined || currentSpace === undefined) {
    throw new Error("スペース情報の取得に失敗しました");
  }

  return { currentSpace, switchSpace, switchSpaceTemporary, spaces, isShowSpaceOnScreen };
};
