import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Typography, Notice } from "~/components/uiParts";

type Props = {
  tenantName: string;
  userName: string;
  onClick: () => void;
};

export const FrontPage: FC<Props> = ({ tenantName, userName, onClick }) => {
  return (
    <StyledBox px={3} pt={5}>
      <Notice title="🎉Welcome!🎉" showCloseAnnotation={false}>
        <Typography variant="body1" color="textPrimary">
          {`ようこそ、${tenantName}へ！\n${tenantName}では${userName}さんのファーストキャリアの成功を心より大切にしています。\n大切な意思決定に関する不安を解消できるように、社員一同精一杯サポートしていきます。\nこちらのサイトでは、様々なコンテンツやイベントをお伝えしていきますので、ぜひ定期的に見てみてくださいね。`}
        </Typography>

        <Box height="24px" />

        <Button
          color="primary"
          variant="contained"
          borderRadius="circle"
          fullWidth
          onClick={onClick}
        >
          {/* NOTE: 今後、選考中トライアルなどにも鑑み、汎用的な名称にしています */}
          スタート
        </Button>
      </Notice>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;
