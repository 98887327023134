import { useCallback, useState, MouseEvent, useRef } from "react";

import { generateMessageWithTemplateInserted } from "~/components/domains/contactRooms/ContactRoomItem/parts/generateMessageWithTemplateInserted";

export const useContactMessageTemplatesMenuHandling = ({
  setNewMessage,
  currentMessage,
}: {
  setNewMessage: (message: string) => void;
  currentMessage: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // メッセージ入力textareaのカーソル位置を取得するためのref
  const textareaAutosizeAttachableFileRef = useRef<HTMLTextAreaElement>(null);

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleReflectMessage = useCallback(
    (text: string) => {
      const templateAppliedMessage = generateMessageWithTemplateInserted({
        currentMessage,
        templateMessage: text,
        textareaAutosizeAttachableFileRef,
      });
      setNewMessage(templateAppliedMessage);
    },
    [currentMessage, setNewMessage]
  );

  return {
    anchorEl,
    handleOpen,
    handleClose,
    handleReflectMessage,
    textareaAutosizeAttachableFileRef,
  };
};
