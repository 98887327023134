import { OnnEvent } from "@onn/common";

import { InputState } from "../../../_shared/createAndEdit";

export const useGenerateRHFDefaultValue = ({
  eventType,
}: {
  eventType: Extract<OnnEvent["type"], "normal" | "new_interview">;
}) => {
  const defaultType: InputState["type"] = eventType;

  const defaultCandidateDates =
    defaultType === "normal"
      ? [
          {
            candidateDateId: "",
            from: null,
            until: null,
            fromTime: 10,
            untilTime: 11,
            capacity: "10",
          },
          {
            candidateDateId: "",
            from: null,
            until: null,
            fromTime: 10,
            untilTime: 11,
            capacity: "10",
          },
        ]
      : [];

  const defaultValues: InputState = {
    title: "",
    content: "",
    type: defaultType,
    hasCapacity: true,
    candidateDates: defaultCandidateDates,
    isRestrictedDaysAgoWhenAnswer: false,
    daysAgoRestrictAnswer: undefined,
  };

  return { defaultValues };
};
