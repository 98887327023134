import React, { FC } from "react";

import { BodyRowData, ColumnType } from "../const";

import { AnsweredAtCell } from "./AnsweredAtCell";
import { StyleProps } from "./CellItem";

import { RelatedFilesCell } from "./RelatedFilesCell";
import { TextCell } from "./TextCell";

export const BodyCell: FC<
  {
    bodyRowData: BodyRowData;
    columnType: ColumnType;
    onCancel: () => void;
  } & StyleProps
> = ({ bodyRowData, columnType, ...props }) => {
  const result = (): JSX.Element => {
    switch (columnType) {
      case "type":
        return <TextCell text={bodyRowData[columnType]} {...props} />;
      case "title":
        return <TextCell {...bodyRowData[columnType]} {...props} />;
      case "status":
        return <TextCell text={bodyRowData[columnType] || null} {...props} />;
      case "relatedFiles":
        return <RelatedFilesCell filePaths={bodyRowData[columnType]} {...props} />;
      case "answeredAt":
        return <AnsweredAtCell date={bodyRowData[columnType]} {...props} />;
    }
  };
  return result();
};
