import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION MULTIPLE TYPE OPTION SCHEMA
/////////////////////////////////////////

export const EmployeeInformationMultipleTypeOptionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeInformationMultipleSelectTypeFieldId: z.string(),
  label: z.string().trim().min(1, { message: "ラベルが短すぎます" }),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationMultipleTypeOption = z.infer<
  typeof EmployeeInformationMultipleTypeOptionSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION MULTIPLE TYPE OPTION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationMultipleTypeOptionOptionalDefaultsSchema =
  EmployeeInformationMultipleTypeOptionSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationMultipleTypeOptionOptionalDefaults = z.infer<
  typeof EmployeeInformationMultipleTypeOptionOptionalDefaultsSchema
>;

export default EmployeeInformationMultipleTypeOptionSchema;
