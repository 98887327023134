import { Box } from "@material-ui/core";
import { Library } from "@onn/common";
import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LibraryTable } from "./LibraryTable";

import { Alert, Button, Typography, NotFoundPaper, Loading, Icon } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { getSelectedSpaceId } from "~/libs/spaceIdFromStorage";
import { LibraryUseCase } from "~/service/usecases/libraryUseCase";

const libraryUseCase = new LibraryUseCase();

export const LibraryTab: FC = () => {
  const { currentUser } = useCurrentUser();
  const { tenantSettings } = useTenantSettings();
  const navigate = useNavigate();

  const [libraries, setLibraries] = useState<Library[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    libraryUseCase
      .fetchAll(
        currentUser.tenantId,
        { includePrivate: true },
        // ライブラリは中途向けテナントも用いるためSpaceProviderを使用できない。そのためgetSelectedSpaceIdを直接使用する。
        getSelectedSpaceId(currentUser.tenantId) || undefined
      )
      .then((res) => setLibraries(res))
      .finally(() => setLoading(false));
  }, [currentUser.tenantId]);

  const handleClickAddCategoryButton = () => {
    navigate("/libraries/new");
  };

  const syncUpdatedLibrary = ({
    updatedLibrariesIds,
    updatedLibraries,
  }: {
    updatedLibrariesIds: string[];
    updatedLibraries: Library[];
  }) => {
    setLibraries((prevState) => {
      const newArrays = [
        // updatedLibraries が存在する場合は、それを取り込んで libraries を更新する。存在しない場合は古い library を取り除いて libraries を更新する。
        ...prevState.filter((v) => !updatedLibrariesIds.includes(v.id)),
        ...updatedLibraries,
      ];
      return newArrays.sort((a, b) => a.index - b.index);
    });
  };

  if (loading) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <Box pt={5} pb={4}>
      {!tenantSettings.features.library && (
        <>
          <Alert
            text="※ライブラリ機能がオフになっています。機能をオンにすると候補者への公開が再開されます。機能設定で設定を確認しましょう。"
            to="/settings/admin?target=portal#feature"
          />
          <Box height="40px" />
        </>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="textSecondary">
          {`ライブラリは、候補者がポータルで閲覧できる「組織・カルチャー・人」などに関わるコンテンツを登録する場所です。\n候補者が入社1日目を迎えるまでに会社へのエンゲージメントを高めるコンテンツをカテゴリーごとに登録しましょう。`}
        </Typography>
        <Box display="inline-block" width="40px" />
        <Box display="flex" flexDirection="row-reverse">
          <Button
            variant="outlined"
            borderRadius="circle"
            color="primary"
            onClick={handleClickAddCategoryButton}
            startIcon={<Icon icon="add" color="primary" size="md" />}
          >
            カテゴリー追加
          </Button>
        </Box>
      </Box>
      <Box height="40px" />
      {libraries.length > 0 ? (
        <Box>
          <LibraryTable libraries={libraries} syncUpdatedLibrary={syncUpdatedLibrary} />
        </Box>
      ) : (
        <NotFoundPaper
          text={`候補者が入社1日目を迎えるまでに会社へのエンゲージメントを高めるコンテンツを\nカテゴリーごとに登録しましょう。`}
          buttons={
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={handleClickAddCategoryButton}
              startIcon={<Icon icon="add" color="primary" size="md" />}
            >
              カテゴリー追加
            </Button>
          }
        />
      )}
    </Box>
  );
};
