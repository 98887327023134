import { Box, Stack } from "@mui/material";
import React, { FC, useContext } from "react";

import { Controller, FormProvider } from "react-hook-form";

import { useEmployeePredictionsForm } from "./hooks/useForm";

import { InputRow } from "./parts/InputRow";

import { EmployeePredictionContext } from "~/components/providers/EmployeePredictionProvider";
import {
  Button,
  ScrollableBodyModal,
  Typography,
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
} from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
};

export const EditEmployeePredictionsModal: FC<Props> = ({ open, onCancel }) => {
  const { employeePredictions } = useContext(EmployeePredictionContext);

  const {
    form,
    fields,
    changeOrder,
    addPrediction,
    removePrediction,
    handleSubmit,
    isSubmitButtonDisabled,
  } = useEmployeePredictionsForm({
    employeePredictions,
    onSuccess: () => {
      // TODO[ヨミ設定]: 閉じる前に候補者一覧ページをrefetchする
      onCancel();
    },
  });

  return (
    <>
      <ScrollableBodyModal
        open={open}
        disableBackdropModal
        title="ヨミ設定"
        titleHint="候補者の志望度や内定承諾に至るまでの確度を表す「ヨミ」の項目を設定することができます。「ヨミ」は候補者に公開されません。"
        content={
          <FormProvider {...form}>
            <Stack direction="column" alignItems="flex-start" textAlign="center" spacing={3}>
              <Typography variant="body1" style={{ width: "100%" }}>
                ヨミに表示する項目を設定してください。
                <br />
                既存の項目を編集した場合、その項目が選択されている候補者のヨミも変更されます。
              </Typography>
              <Stack
                direction="column"
                alignItems="flex-start"
                textAlign="center"
                width="100%"
                spacing={3}
              >
                <Stack direction="column" alignItems="flex-start" width="100%">
                  <DnDProvider onDragEnd={changeOrder}>
                    <Box width="100%">
                      <DnDDroppable droppableId="droppableId" isDropDisabled={false}>
                        {fields.map((prediction, index) => (
                          <DnDDraggable
                            draggableId={prediction.id}
                            key={prediction.id}
                            index={index}
                            isDragDisabled={false}
                            shouldRoundCorner
                          >
                            <Stack direction="row" alignItems="start" spacing={1} width="100%">
                              <Box px={1} py={2} display="flex" justifyContent="center">
                                <Icon icon="gripVertical" size="md" color="grey400" />
                              </Box>
                              <Controller
                                key={prediction.id}
                                control={form.control}
                                name={`employeePredictions.${index}.label`}
                                render={({ field, fieldState }) => (
                                  <InputRow
                                    onChange={(e) => {
                                      field.onChange(e);

                                      form.trigger("employeePredictions");
                                      // あるinputの入力を変更したときに、他のinputのエラーの表示が切り替わることがあるため全体のエラーを再検証する
                                    }}
                                    label={field.value}
                                    errorText={fieldState.error?.message}
                                    onClickRemoveButton={() => removePrediction(index)}
                                  />
                                )}
                              />
                            </Stack>
                          </DnDDraggable>
                        ))}
                      </DnDDroppable>
                    </Box>
                  </DnDProvider>
                </Stack>
                <Button
                  variant="text"
                  borderRadius="regular"
                  onClick={addPrediction}
                  color="primary"
                  startIcon={<Icon icon="add" color="primary" size="md" />}
                >
                  項目を追加
                </Button>
              </Stack>
            </Stack>
          </FormProvider>
        }
        footer={
          <Stack direction="row" spacing={2}>
            <Button variant="text" borderRadius="circle" color="default" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitButtonDisabled}
              isLoading={form.formState.isSubmitting}
              type="submit"
            >
              変更を保存
            </Button>
          </Stack>
        }
        onCancel={onCancel}
      />
    </>
  );
};
