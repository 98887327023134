import { Reminder, APISchema } from "@onn/common";
import { useSWRConfig } from "swr";

import { useSnackbar } from "../shared";

import { Key } from "./useInCompletedReminders";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useCompleteReminder = ({
  reminders,
  refetchKey,
}: {
  reminders: Reminder[];
  refetchKey: Key;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  const completeReminder = async (reminder: Reminder) => {
    const newReminder = reminder.complete();
    const body: APISchema["/reminder_api/reminders"]["PATCH"]["body"] = {
      id: newReminder.id,
      "is-completed": newReminder.isCompleted,
    };
    const optimisticData = reminders.filter((r) => r.id !== reminder.id);

    try {
      await mutate(
        refetchKey,
        async () => {
          await apiClient.patch(`/reminder_api/reminders`, body);
          return mutate(refetchKey);
        },
        {
          optimisticData,
        }
      );
      enqueueSnackbar("リマインダーを完了しました", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("リマインダーの完了に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: {
          type: "useCompleteReminder:completeReminder",
        },
      });
    }
  };

  return { completeReminder };
};
