import { TextField, Box, Link } from "@material-ui/core";
import { isValidEmail } from "@onn/common";
import React, { useState, useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, PasswordField, Typography } from "~/components/uiParts";
import { CenterPaper } from "~/components/uiParts/CenterPaper";
import { useSnackbar, useQuery } from "~/hooks/shared";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

export const SignIn: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { query } = useQuery();
  const destPath = query.get("dest-path");

  // 中途向け環境からリダイレクトしてきた時に表示するメッセージ
  useEffect(() => {
    const isRedirect = query.get("is-redirect");

    if (isRedirect === "true") {
      enqueueSnackbar(
        `アクセスしたURLはお使いのアカウントでは使用できません。再度、ログインをお願いします。\n今後はこちらのURLをご利用ください。`,
        { variant: "info" }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDisableLoginButton: boolean =
    loading || email.length === 0 || emailError || password.length < 6;
  const handleSignInClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isDisableLoginButton) {
      return;
    }

    try {
      setLoading(true);
      await AccountUseCase.signIn(email, password);
      navigate(destPath ?? "/");
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      captureException({
        error: e as Error,
        tags: {
          type: "SignIn:handleSignInClick",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // email が形式と合致するかのチェック
  useEffect(() => {
    if (email.length === 0) {
      return setEmailError(false);
    }
    setEmailError(!isValidEmail(email));
  }, [email]);

  const isNewGraduateOrigin = !!getTenantIdFromDomain();
  const navigateWithQuery = useNavigateWithQuery();

  useEffect(() => {
    // NOTE: サブドメインにテナントが存在している場合新しいログイン画面に遷移する。
    isNewGraduateOrigin && navigateWithQuery("/portal/accounts/login");
  }, [isNewGraduateOrigin, navigateWithQuery]);

  // NOTE: 画面に一瞬でもフォームが出てしまうのを抑えるためリダイレクトする場合はnullを返しておく
  if (isNewGraduateOrigin) return null;

  return (
    <CenterPaper>
      <Typography variant="h1" bold color="textPrimary">
        ログイン
      </Typography>
      <StyledForm onSubmit={handleSignInClick} noValidate>
        <Box mt={8}>
          <Box mb={4}>
            <TextField
              error={emailError}
              fullWidth
              margin="normal"
              id="email"
              type="email"
              label="メールアドレス"
              variant="outlined"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              helperText={emailError && "メールアドレスの形式を正しく入力してください"}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box mb={5}>
            <PasswordField
              fullWidth
              value={password}
              onChange={(value: string) => setPassword(value)}
            />
          </Box>
          <Box textAlign="center" mt={5} mb={5}>
            <Link href="/reset_password" underline="always" color="textSecondary">
              <Typography variant="body2">パスワードを忘れた場合</Typography>
            </Link>
          </Box>
        </Box>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          borderRadius="circle"
          color="primary"
          disabled={isDisableLoginButton}
        >
          ログイン
        </Button>
      </StyledForm>
    </CenterPaper>
  );
};

const StyledForm = styled.form`
  width: 100%;
`;
