import { ContactMessageTemplateFolder } from "@onn/common";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { Key } from "./useContactMessageTemplateFolder";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * コンタクトメッセージテンプレートを削除するAPIを呼び出す関数を返す
 * @param mutateKeys
 */
export const useDeleteContactMessageTemplateFolder = ({
  contactMessageTemplateFolders,
  refetchKey,
}: {
  contactMessageTemplateFolders: ContactMessageTemplateFolder[];
  refetchKey: Key;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  /**
   *  コンタクトメッセージテンプレートを削除する
   */
  const deleteContactMessageTemplateFolder = useCallback(
    async (folderToDelete: ContactMessageTemplateFolder) => {
      try {
        const optimisticData = contactMessageTemplateFolders.filter(
          (f) => f.id !== folderToDelete.id
        );
        await mutate(
          refetchKey,
          async () => {
            await apiClient.delete("/contact_message_api/template-folders", undefined, {
              ["id"]: folderToDelete.id,
            });
            return mutate(refetchKey);
          },
          {
            optimisticData,
          }
        );
        enqueueSnackbar("フォルダを削除しました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("フォルダの削除に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useDeleteContactMessageTemplateFolder:deleteContactMessageTemplateFolder",
          },
        });
      }
    },
    [contactMessageTemplateFolders, enqueueSnackbar, mutate, refetchKey]
  );

  return { deleteContactMessageTemplateFolder };
};
