import { AuthenticationType } from "@onn/common";

import { useQuery } from "~/hooks/shared";

export const useRegistrationLinks = (): {
  pathForLine: string;
  pathForEmail: string;
} => {
  const { query } = useQuery();
  const registrationInvitationLinkId = query.get("registration-invitation-link-id");

  return {
    pathForLine: generatePath("line", registrationInvitationLinkId),
    pathForEmail: generatePath("email", registrationInvitationLinkId),
  };
};

const generatePath = (authenticationType: AuthenticationType, linkId: string | null) => {
  const basePath = `/portal/accounts/registration/${authenticationType}/registration_invitation_links`;

  return linkId ? `${basePath}/${linkId}` : basePath;
};
