import { Box } from "@material-ui/core";
import { NewInterviewEvent } from "@onn/common";
import { intersectionBy } from "lodash";
import React, { FC, useCallback, useMemo } from "react";

import { OnnEventSlotDatesMultiGrid } from "./Table/OnnEventSlotDatesMultiGrid";

import { AddSlotButton } from "./components/AddSlotButton";
import { BulkOperationButton } from "./components/BulkOperationButton";
import { CancelSelectionButton } from "./components/CancelSelectionButton";
import { useSelectedSlots } from "./useSelectedSlots";

import { useSlotsSearchByConditions } from "./useSlotsSearchByConditions";

import { useSortSlots } from "./useSortSlots";

import {
  IconButton,
  UncontrolledMenu,
  Typography,
  SearchByConditionsButton,
} from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";

type Props = {
  onnEvent: NewInterviewEvent;
};

export const TabPage: FC<Props> = ({ onnEvent }) => {
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();
  const { sortSlots } = useSortSlots();

  const { data: onnEventSlotDatesForDisplay = [], isLoading: isLoadingFetchingSlots } =
    useOnnEventSlotDatesForDisplay(onnEvent.id);

  const slotIdToSlotForDisplayMap = useMemo(
    () => new Map(onnEventSlotDatesForDisplay.map((slot) => [slot.id, slot])),
    [onnEventSlotDatesForDisplay]
  );

  const { conditions, logicType, onSearchConfirm, searchedSlots, onResetSearchConditions } =
    useSlotsSearchByConditions({
      onnEventId: onnEvent.id,
      slotIdToSlotForDisplayMap,
    });

  const filteredSlots = useMemo(() => {
    if (searchedSlots === null) return onnEventSlotDatesForDisplay;
    return intersectionBy(searchedSlots, onnEventSlotDatesForDisplay);
  }, [onnEventSlotDatesForDisplay, searchedSlots]);

  const orderedAndFilteredSlots = useMemo(
    () => sortSlots(filteredSlots),
    [filteredSlots, sortSlots]
  );

  const { allSelectionState, selectedSlotIds, setSelectedSlotIds, onResetSelectedSlotIds } =
    useSelectedSlots({
      onnEventSlotDatesForDisplay: orderedAndFilteredSlots,
    });

  const handleOnClickSearchByConditionsButton = useCallback(() => {
    handleModal({
      name: "onnEventSlotSearchModal",
      args: {
        onnEvent,
        onSearchConfirm,
        defaultConditions: conditions,
        defaultLogicType: logicType,
        allSlotsCount: onnEventSlotDatesForDisplay.length,
        defaultSearchResultCount: searchedSlots ? searchedSlots.length : undefined,
        modalType: "slot",
      },
    });
  }, [
    conditions,
    handleModal,
    logicType,
    onSearchConfirm,
    onnEvent,
    onnEventSlotDatesForDisplay.length,
    searchedSlots,
  ]);

  return (
    <>
      <Box my="24px" display="flex" gridGap={24} alignItems="center" justifyContent="space-between">
        {allSelectionState.stateType === "isUnchecked" ? (
          <>
            <Box display={"flex"} gridColumnGap={"24px"} height={"40px"}>
              <SearchByConditionsButton
                onClick={handleOnClickSearchByConditionsButton}
                onReset={onResetSearchConditions}
                count={searchedSlots ? conditions.length : 0}
              />
            </Box>
            <Box display={"flex"} gridColumnGap={"24px"} height={"40px"}>
              {currentUser.isAdmin() && (
                <>
                  <AddSlotButton onnEvent={onnEvent} />
                  <TableMenu onnEvent={onnEvent} />
                </>
              )}
            </Box>
          </>
        ) : (
          <>
            <Typography>{`${allSelectionState.label.count}件の予約枠が選択されています`}</Typography>
            <Box display={"flex"} gridColumnGap={"24px"} height={"40px"}>
              {currentUser.isAdmin() && (
                <>
                  <BulkOperationButton
                    onnEvent={onnEvent}
                    selectedSlotIds={selectedSlotIds}
                    onResetSelectedSlotIds={onResetSelectedSlotIds}
                    slotIdToSlotForDisplayMap={slotIdToSlotForDisplayMap}
                  />
                  <CancelSelectionButton onResetSelectedSlotIds={onResetSelectedSlotIds} />
                </>
              )}
            </Box>
          </>
        )}
      </Box>
      <OnnEventSlotDatesMultiGrid
        onnEvent={onnEvent}
        onnEventSlotDatesForDisplay={orderedAndFilteredSlots}
        isLoadingFetchingSlots={isLoadingFetchingSlots}
        setSelectedSlotIds={setSelectedSlotIds}
        allSelectionState={allSelectionState}
        selectedSlotIds={selectedSlotIds}
      />
    </>
  );
};

const TableMenu: FC<Props> = ({ onnEvent }) => {
  const { handleModal } = useModal();

  const openCreateOnnEventSlotsWithCSVModal = useCallback(() => {
    handleModal({
      name: "createOnnEventSlotsWithCSVModal",
      args: {
        onnEvent,
      },
    });
  }, [handleModal, onnEvent]);

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={[
        {
          onClick: openCreateOnnEventSlotsWithCSVModal,
          text: "予約枠一括登録",
        },
      ]}
    />
  );
};
