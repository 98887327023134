import { zodResolver } from "@hookform/resolvers/zod";
import { AnyEmployeeInformationFieldWithOptions } from "@onn/common";
import { useCallback } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useFieldArray, useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { formSchema, InputState } from "./schema";

import { useUpdateEmployeeInformationFieldsOrder } from "~/hooks/employeeInformationField";
import { mutateEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";
import { usePrompt } from "~/hooks/shared";

export const useEmployeeInformationFieldsForm = ({
  groupId,
  employeeInformationFields,
  onClose,
}: {
  groupId: string;
  employeeInformationFields: AnyEmployeeInformationFieldWithOptions[];
  onClose: () => void;
}) => {
  const { execUpdateEmployeeInformationFieldsOrder } = useUpdateEmployeeInformationFieldsOrder();
  const form = useForm<InputState, void>({
    defaultValues: {
      employeeInformationFields,
    },
    resolver: zodResolver(z.object({ employeeInformationFields: formSchema })),
    mode: "all",
  });
  const { fields, removeField, changeOrder } = useFields(form);

  const submit = form.handleSubmit(async (value) => {
    await execUpdateEmployeeInformationFieldsOrder({
      employeeInformationFieldIds: value.employeeInformationFields.map((field) => field.id),
      employeeInformationGroupId: groupId,
    });
    mutateEmployeeInformationGroups();
    onClose();
  });

  const handleSubmit = useCallback(async () => {
    await submit();
  }, [submit]);

  usePrompt("項目設定の編集を破棄しますか？", form.formState.isDirty);

  return {
    form,
    fields,
    changeOrder,
    removeField,
    handleSubmit,
    isSubmitButtonDisabled:
      form.formState.isSubmitting || !form.formState.isValid || !form.formState.isDirty,
  };
};

const useFields = (form: UseFormReturn<InputState, void, undefined>) => {
  const { fields, move, remove } = useFieldArray({
    control: form.control,
    name: "employeeInformationFields",
    keyName: "rhf_field_id",
  });

  const removeField = useCallback(
    (index: number) => {
      remove(index);
      form.trigger("employeeInformationFields");
    },
    [form, remove]
  );

  const changeOrder = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      move(sourceIndex, destinationIndex);
    },
    [move]
  );

  return {
    fields,
    removeField,
    changeOrder,
  };
};
