import { v4 } from "uuid";
import { z } from "zod";

export const NAME_MAX_LENGTH = 20;

export const contactMessageTemplateFolderSchema = z.object({
  id: z.string().trim().default(v4),
  tenantId: z.string(),
  spaceId: z.string(),
  name: z
    .string()
    .trim()
    .min(1, { message: "フォルダ名が短すぎます" })
    .max(NAME_MAX_LENGTH, { message: "フォルダ名が長すぎます" }),
  createdAt: z.date().default(() => new Date()),
  updatedAt: z.date().default(() => new Date()),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContactMessageTemplateFolderSchema
  extends z.infer<typeof contactMessageTemplateFolderSchema> {}
