import { v4 } from "uuid";

import { IBriefingSessionCategory, briefingSessionCategorySchema } from "./schema";

export class BriefingSessionCategory implements IBriefingSessionCategory {
  static readonly validator = briefingSessionCategorySchema;

  readonly id: string;
  readonly tenantId: string;
  readonly onnEventId: string;
  readonly title: string;
  readonly description: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<BriefingSessionCategory>) {
    const parsedInit = BriefingSessionCategory.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.onnEventId = parsedInit.onnEventId;
    this.title = parsedInit.title;
    this.description = parsedInit.description;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static create(
    params: Optional<ExcludeMethods<BriefingSessionCategory>, "id" | "createdAt" | "updatedAt">
  ): BriefingSessionCategory {
    return new BriefingSessionCategory({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
    });
  }

  update(
    params: Partial<ExcludeMethods<Pick<BriefingSessionCategory, "title" | "description">>>
  ): BriefingSessionCategory {
    return new BriefingSessionCategory({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 ExcludeMethods<BriefingSessionCategory> にしている
   */
  static forceConvertToDate(briefingSessionCategory: ExcludeMethods<BriefingSessionCategory>) {
    return new BriefingSessionCategory({
      ...briefingSessionCategory,
      createdAt: new Date(briefingSessionCategory.createdAt),
      updatedAt: new Date(briefingSessionCategory.updatedAt),
    });
  }
}
