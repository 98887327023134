import { APISchema } from "@onn/common";
import { useState } from "react";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/download_employee_information_setting_csv"]["GET"];

export const useDownloadBulkUpdateEmployeeInfoSettingCSV = () => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * 候補者詳細情報csv一括更新時に使用する設定ファイルを
   */
  const downloadBulkUpdateEmployeeInfoSettingCSV = async () => {
    setIsLoading(true);
    const queryForNewGraduateSetting: EndPoint["query"] = {
      type: "newGraduateSetting",
    };
    const queryForCustomEmployeeInformationSetting: EndPoint["query"] = {
      type: "customEmployeeInformationSetting",
    };
    await Promise.all([
      apiClient.get("/download_employee_information_setting_csv", queryForNewGraduateSetting, {
        fileDownload: {
          fileName: "候補者情報一括更新_設定可能な候補者リスト.csv",
          isCSV: true,
        },
      }),
      apiClient.get(
        "/download_employee_information_setting_csv",
        queryForCustomEmployeeInformationSetting,
        {
          fileDownload: {
            fileName: "候補者情報一括更新_カスタム項目設定値ファイル.csv",
            isCSV: true,
          },
        }
      ),
    ]);

    setIsLoading(false);
  };

  return { downloadBulkUpdateEmployeeInfoSettingCSV, isLoading };
};
