import { OnnEventEvaluation, APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/patch_onn_event_evaluations"]["PATCH"];

export const useUpdateOnnEventEvaluation = () => {
  const updateOnnEventEvaluation = useCallback(async (requestBody: Endpoint["body"]) => {
    const response = await apiClient.patch("/patch_onn_event_evaluations", requestBody);
    return new OnnEventEvaluation({
      ...response.data,
    });
  }, []);

  return { updateOnnEventEvaluation };
};
