import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Icon, TooltipWhenTextTruncatedOneLine } from "~/components/uiParts";

type Props = {
  title: string;
};

export const TitleCell: FC<Props> = ({ title }) => {
  const titleText = `${title}`;

  return (
    <Box display="flex" alignItems="center" gridGap="16px">
      <Icon icon="form" color="lightGrey" size="md" />
      <TooltipWhenTextTruncatedOneLine
        text={titleText}
        typographyProps={{ variant: "body1", color: "textPrimary" }}
      />
    </Box>
  );
};
