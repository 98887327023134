import { OnnEventSlotDate } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { CellItem, Icon, StyleProps, Typography } from "~/components/uiParts";

type Props = {
  onnEventSlotDate: OnnEventSlotDate;
} & StyleProps;

export const SlotTypeCell: FC<Props> = ({ onnEventSlotDate, style }) => {
  const label = onnEventSlotDate.eventType === "offline" ? "オフライン・現地" : "オンライン";

  if (onnEventSlotDate.eventType === "online" && onnEventSlotDate.url) {
    return (
      <CellItem style={{ ...style }} isShowTopBorder>
        <StyledAnchor href={onnEventSlotDate.url} target="_blank">
          <Typography variant="body2" color="primary" style={{ cursor: "pointer" }}>
            オンライン
          </Typography>
          <Icon icon="link" size="md" color="primary" />
        </StyledAnchor>
      </CellItem>
    );
  }

  return (
    <CellItem style={{ ...style }} isShowTopBorder>
      <Typography
        variant="body2"
        color={onnEventSlotDate.eventType === "online" ? "primary" : "textPrimary"}
      >
        {label}{" "}
      </Typography>
      {onnEventSlotDate.eventType === "online" && <Icon icon="link" size="md" color="primary" />}
    </CellItem>
  );
};

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 4px;
`;
