import { v4 } from "uuid";

import { onnTaskDeliveryCancellationLogSchema, IOnnTaskDeliveryCancellationLog } from "./schema";

export class OnnTaskDeliveryCancellationLog implements IOnnTaskDeliveryCancellationLog {
  static readonly validator = onnTaskDeliveryCancellationLogSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly onnTaskId: string;
  readonly employeeId: string;
  readonly createdAt: Date;

  constructor(init: ExcludeMethods<OnnTaskDeliveryCancellationLog>) {
    const parsedInit = OnnTaskDeliveryCancellationLog.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.onnTaskId = parsedInit.onnTaskId;
    this.employeeId = parsedInit.employeeId;
    this.createdAt = parsedInit.createdAt;
  }

  public static createNew(
    init: Omit<ExcludeMethods<OnnTaskDeliveryCancellationLog>, "id" | "createdAt">
  ): OnnTaskDeliveryCancellationLog {
    return new OnnTaskDeliveryCancellationLog({
      ...init,
      id: v4(),
      createdAt: new Date(),
    });
  }
}
