import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION SINGLE SELECT TYPE FIELD SCHEMA
/////////////////////////////////////////

export const EmployeeInformationSingleSelectTypeFieldSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeInformationGroupId: z.string(),
  label: z.string().trim().min(1, { message: "ラベルが短すぎます" }),
  order: z.number(),
  sampleValue: z.string(),
  isCustom: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationSingleSelectTypeField = z.infer<
  typeof EmployeeInformationSingleSelectTypeFieldSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION SINGLE SELECT TYPE FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationSingleSelectTypeFieldOptionalDefaultsSchema =
  EmployeeInformationSingleSelectTypeFieldSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationSingleSelectTypeFieldOptionalDefaults = z.infer<
  typeof EmployeeInformationSingleSelectTypeFieldOptionalDefaultsSchema
>;

export default EmployeeInformationSingleSelectTypeFieldSchema;
