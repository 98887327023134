import { BriefingSessionEvent, OnnEventSlotDatesForDisplay, SlotTypeForDisplay } from "@onn/common";
import { SlotDateStatusForDisplay } from "@onn/common/domain/OnnEvent/OnnEventSlotDate/OnnEventSlotDatesForDisplay";

export const columnTypes = [
  "checkBox",
  "menu",
  "uniqueId",
  "briefingSession",
  "slotDate",
  "slotTime",
  "capacity",
  "reserved",
  "status",
  "assignee",
  "subAssignee",
  "slotType",
  "description",
] as const;
// key の順序をテーブル表示に利用する

export type ColumnType = (typeof columnTypes)[number];

export const labelMap: Record<ColumnType, string> = {
  checkBox: "",
  menu: "",
  uniqueId: "日程ID",
  briefingSession: "説明会",
  slotDate: "開催日",
  slotTime: "開催時間",
  capacity: "定員",
  reserved: "予約",
  status: "ステータス",
  assignee: "主担当",
  subAssignee: "副担当",
  slotType: "開催方法",
  description: "概要",
};

export const widthPxMap: Record<ColumnType, number> = {
  checkBox: 40,
  menu: 40,
  uniqueId: 120,
  briefingSession: 200,
  slotDate: 100,
  slotTime: 120,
  capacity: 80,
  reserved: 80,
  status: 160,
  assignee: 100,
  subAssignee: 140,
  slotType: 160,
  description: 240,
};

export type HeaderRowData = {
  checkBox: {
    isChecked: boolean;
    onClick: () => void;
    indeterminate: boolean;
  };
};

export type BodyRowData = {
  checkBox: {
    isChecked: boolean;
    onClick: (slotId: string) => void;
    slotId: string;
  };
  menu: {
    slot: OnnEventSlotDatesForDisplay;
    reservedCount: number;
    onnEvent: BriefingSessionEvent;
  };
  uniqueId?: number;
  briefingSession: {
    title?: string;
  };
  slotDate: string;
  slotTime: string;
  capacity: number;
  reserved: {
    count: number;
    onnEvent: BriefingSessionEvent;
    slotId: string;
    fromDate: Date;
    untilDate: Date;
    type: OnnEventSlotDatesForDisplay["eventType"];
    briefingSessionCategoryId?: string;
    isReservable: boolean;
  };
  status: {
    status: SlotDateStatusForDisplay;
    onnEventId: string;
    slotId: string;
    reservedCount: number;
  };
  assignee: { assigneeName?: string; profileIconImageUrl?: string };
  subAssignee: { subAssigneeName?: string; profileIconImageUrl?: string }[];
  slotType: SlotTypeForDisplay;
  description: string;
};

export const EMPTY_TEXT_AREA_HEIGHT = 200;

export const HEADER_CELL_HEIGHT_PX = 56;
export const BODY_CELL_HEIGHT_PX = 80;
