import { MenuItem, Typography } from "@material-ui/core";
import { ContactMessageTemplateFolderWithTemplates } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { TooltipWhenTextTruncated, IconButton } from "~/components/uiParts";

export type Props = {
  id: string;
  folder: ContactMessageTemplateFolderWithTemplates;
  isSelected: boolean;
  handleClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export const FolderMenuItem: FC<Props> = ({ id, folder, isSelected, handleClick }: Props) => {
  return (
    <StyledMenuItem
      id={id}
      selected={isSelected}
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        handleClick(e);
      }}
    >
      <TooltipWhenTextTruncated text={folder.name}>
        {(ref) => (
          <>
            <Typography variant="body2" noWrap ref={ref}>
              {folder.name}
            </Typography>
            <IconButton icon="chevronRight" />
          </>
        )}
      </TooltipWhenTextTruncated>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-top: 2px;
    padding-bottom: 2px;

    // TooltipWhenTextTruncated を使用するために、root, span のそれぞれに flex style が必要
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => props.selected && props.theme.palette.action.selected};

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
