import { FormControl, FormGroup } from "@material-ui/core";
import { RadioQuestion } from "@onn/common";
import React, { memo } from "react";
import styled from "styled-components";

import { QuestionPartBase } from "./QuestionPartBase";

import { FormControlLabel, Typography, RadioButton } from "~/components/uiParts";
import theme from "~/config/theme";

export const RadioQuestionPart = memo(({ question }: { question: RadioQuestion }) => {
  return (
    <QuestionPartBase questionTitle={question.title} isRequired={question.isRequired}>
      <FormControl component="fieldset" aria-label="タグを選択" disabled>
        <FormGroup>
          {question.options.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                control={<StyledRadio checked={false} disabled />}
                label={
                  <Typography variant="body2" style={{ color: theme.palette.grey[200] }}>
                    {option.text}
                  </Typography>
                }
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </QuestionPartBase>
  );
});

const StyledRadio = styled(RadioButton)``;
