import React, { FC } from "react";

import { RegisterIndividualByEmail } from "~/components/domains/account/RegisterIndividualByEmail";

import { RegistrationInfo } from "~/hooks/employee/useRegistrationInfoByInvitationToken";

type Props = {
  registrationInfoByInvitationToken: RegistrationInfo;
};
export const RegistrationIndividual: FC<Props> = ({ registrationInfoByInvitationToken }) => {
  return <RegisterIndividualByEmail registrationInfo={registrationInfoByInvitationToken} />;
};
