import { useCallback } from "react";

import { useGetTriggerFormIndex } from "./useGetTriggerFormIndex";

export const useGetTriggerFormName = () => {
  const { getTriggerFormIndex } = useGetTriggerFormIndex();

  const getTriggerFormName = useCallback(
    ({ recruitStatusId, triggerId }: { recruitStatusId: string; triggerId: string }) => {
      const { scenarioIndex, triggerIndex } = getTriggerFormIndex({ recruitStatusId, triggerId });
      if (triggerIndex === -1) {
        // NOTE 検証用
        // eslint-disable-next-line no-console
        console.log("trigger not found");
        // eslint-disable-next-line no-console
        console.log("triggerId", triggerId);
        alert("trigger not found");
      }

      return `body.${scenarioIndex}.blocks.${triggerIndex}` as const;
    },
    [getTriggerFormIndex]
  );

  return { getTriggerFormName };
};
