import { useSWRConfig } from "swr";

import { generateUseNewGraduateKey } from "../useNewGraduate";

import { generateUseEmployeeKeys } from "~/hooks/employee/useEmployee";
import { apiClient } from "~/libs";

export const useRemoveTagFromEmployee = () => {
  const { mutate } = useSWRConfig();

  const removeTagFromEmployee = async (tagID: string, newHireID: string) => {
    await apiClient.delete("/tag_api/remove-tag-from-employee", {
      employeeId: newHireID,
      employeeTagId: tagID,
    });
    const employeeId = newHireID;
    mutate(generateUseEmployeeKeys(employeeId));
    mutate(generateUseNewGraduateKey({ newGraduateId: employeeId }));
  };

  return {
    removeTagFromEmployee,
  };
};
