import { Box } from "@mui/material";
import React, { FC, useCallback } from "react";

import { EmployeeDefaultInformationTable } from "~/components/domains/employees/EmployeeDefaultInformationTable/EmployeeDefaultInformationTable";
import { Icon, Tooltip, Typography } from "~/components/uiParts";

export const DefaultInformationSettings: FC = () => {
  const renderSampleLabelCellComponent = useCallback(
    (sampleLabel: string) => (
      <Typography variant="body2" color="textSecondary">
        {sampleLabel}
      </Typography>
    ),
    []
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          columnGap: "4px",
        }}
      >
        <Typography variant="body2" bold color="textPrimary" style={{ lineHeight: "21px" }}>
          基本情報
        </Typography>
        <Tooltip
          title="基本情報の項目は、システムで固定されているため追加・編集・削除ができません。"
          placement="top"
        >
          <Box p="4px" height={24} display="flex" alignItems="center">
            <Icon icon="help" size="sm" color="grey" />
          </Box>
        </Tooltip>
      </Box>
      <EmployeeDefaultInformationTable
        nameCellComponent={renderSampleLabelCellComponent("例) 例田 太郎")}
        kanaNameCellComponent={renderSampleLabelCellComponent("例) レイダ タロウ")}
        genderCellComponent={renderSampleLabelCellComponent("例) 男性")}
        dateOfBirthCellComponent={renderSampleLabelCellComponent("例) 2004年9月10日 (満20歳)")}
        emailCellComponent={renderSampleLabelCellComponent("例) hagpihwagasiiga@xxxx.co.jp")}
        phoneNumberCellComponent={renderSampleLabelCellComponent("例) 080-1234-5678")}
        homePhoneNumberCellComponent={renderSampleLabelCellComponent("例) 080-1234-5678")}
        graduationYearAndMonthCellComponent={renderSampleLabelCellComponent(
          "例) 2026年 3月 卒業予定"
        )}
        affiliationCellComponent={renderSampleLabelCellComponent("例) W大学 経済学部")}
        addressCellComponent={renderSampleLabelCellComponent(
          "例) 〒270-1153 千葉県我孫子市緑1-363-7 コーポ伊藤 205"
        )}
        hometownAddressCellComponent={renderSampleLabelCellComponent(
          "例) 〒270-1153 千葉県我孫子市緑1-363-7 コーポ伊藤 205"
        )}
        uniqueIdCellComponent={renderSampleLabelCellComponent("例) GYW193865916336")}
        externalIdCellComponent={renderSampleLabelCellComponent("例) 02750260939705-SU-26056")}
        resumeFilePathsCellComponent={renderSampleLabelCellComponent("例) 2022年度_履歴書.pdf")}
        entrySheetFilePathsCellComponent={renderSampleLabelCellComponent(
          "例) 2022年度_エントリーシート.pdf"
        )}
        spiFilePathsCellComponent={renderSampleLabelCellComponent("例) 2022年度_履歴書.pdf")}
        offerAcceptanceDeadlineCellComponent={renderSampleLabelCellComponent("例) 2022年9月30日")}
      />
    </Box>
  );
};
