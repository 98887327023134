import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useUpdateSequenceEndLineUserActiveLogs = () => {
  const updateSequenceEndLineUserActiveLogs = useCallback(
    async ({
      lineAccessToken,
      tenantId,
      liffId,
      sequenceId,
      location,
    }: {
      lineAccessToken: string;
      tenantId: string;
      liffId: string;
      sequenceId: string;
      location: string;
    }) => {
      //  NOTE: このエンドポイントは、ログインしていないユーザーでもアクセスできるようにするため、useCurrentUserを使わず引数tenantIdを受け取る
      await apiClient.patch("/update_line_user_active_logs", {
        lineAccessToken,
        tenantId,
        liffId,
        sequenceId,
        location,
      });
    },
    []
  );

  return { updateSequenceEndLineUserActiveLogs };
};
