import {
  DeprecatedNewGraduate,
  Employee,
  NewGraduateToDisplay,
  NewGraduateToListView,
} from "@onn/common";
import { useCallback, useContext } from "react";

import { mutate } from "swr";

import { useSnackbar } from "../../shared";

import { useMutateAllNewcomers } from "../useAllNewcomers";
import { useGenerateMutateEmployee } from "../useEmployee";

import { useGenerateMutateNewGraduate } from "../useNewGraduate";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { mutateEmployeeInformation } from "~/hooks/employeeInformation/useEmployeeInformation";
import { generateUseRecruitmentProcessRecordsByEmployeeKey } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

export type OnUpdateRecruitmentStatus = (args: {
  updatedNewGraduate: NewGraduateToListView;
  updateRecruitmentStatusPromise: Promise<unknown>;
}) => void | Promise<void>;

/**
 * 新卒候補者のRecruitmentStatusを更新する関数を提供するhooks
 */
export const useUpdateNewGraduateRecruitmentStatus = (options?: {
  onUpdateRecruitmentStatus?: OnUpdateRecruitmentStatus;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAllNewcomers } = useMutateAllNewcomers();
  const { generateMutateEmployee } = useGenerateMutateEmployee();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();
  const { recruitmentStatuses } = useContext(RecruitmentStatusesContext);

  const { contactRoomsMutate } = useContactContext();

  const updateNewGraduateRecruitmentStatus = useCallback(
    async (
      newGraduate: NewGraduateToDisplay,
      recruitmentStatusId: string,
      offerAcceptanceDeadline?: Date,
      newGraduateToListView?: NewGraduateToListView
    ) => {
      try {
        const newRecruitmentStatus = recruitmentStatuses.find(
          (status) => status.id === recruitmentStatusId
        );
        if (!newRecruitmentStatus) {
          throw new Error("recruitmentStatusIdが不正です");
        }
        const employeeTagIds = newGraduate.employeeTagIds;
        const updatedNewGraduate = new NewGraduateToDisplay(
          new Employee({
            ...newGraduate,
            recruitmentStatusId: newRecruitmentStatus.id,
          }) as DeprecatedNewGraduate,
          employeeTagIds,
          {
            id: newRecruitmentStatus.id,
            label: newRecruitmentStatus.label,
            type: newRecruitmentStatus.type,
          },
          newGraduate.predictionId
        );

        const updateRecruitmentStatusPromise = apiClient
          .patch("/api/employee/update-recruitment-status", {
            employeeId: newGraduate.id,
            recruitmentStatusId,
            offerAcceptanceDeadline,
          })
          .then((res) => {
            enqueueSnackbar("選考ステータスを変更しました", { variant: "success" });
            return res;
          });

        // 候補者ステータスの変更を画面に即時反映させるために、楽観的更新を行う
        const mutateEmployee = generateMutateEmployee(newGraduate.id);
        mutateEmployee(
          async () => {
            await updateRecruitmentStatusPromise;
            return updatedNewGraduate;
          },
          {
            optimisticData: updatedNewGraduate,
          }
        );
        const mutateNewGraduate = generateMutateNewGraduate(newGraduate.id);
        mutateNewGraduate(
          async () => {
            await updateRecruitmentStatusPromise;
            return updatedNewGraduate;
          },
          {
            optimisticData: updatedNewGraduate,
          }
        );

        // NOTE: 一覧画面と詳細画面で異なるmutateを行う必要がある、今は一つなのでnewGraduateToListViewがあるかどうかで分岐している
        if (newGraduateToListView) {
          const updatedNewGraduateToListView = new NewGraduateToListView(
            new Employee({
              ...newGraduateToListView,
              recruitmentStatusId: newRecruitmentStatus.id,
            }) as DeprecatedNewGraduate,
            newGraduateToListView.employeeTagIds,
            {
              id: newRecruitmentStatus.id,
              label: newRecruitmentStatus.label,
              type: newRecruitmentStatus.type,
            },
            newGraduate.predictionId,
            newGraduateToListView.nextPlan,
            newGraduateToListView.unansweredOnnTaskCount,
            newGraduateToListView.schoolName,
            newGraduateToListView.affiliationFullName,
            newGraduateToListView.phoneNumber
          );
          options?.onUpdateRecruitmentStatus?.({
            updatedNewGraduate: updatedNewGraduateToListView,
            updateRecruitmentStatusPromise,
          });
        }

        const {
          data: { updatedOfferAcceptanceDeadline },
        } = await updateRecruitmentStatusPromise;

        // 全候補者のミューテーション
        mutateAllNewcomers();
        // 選考ステータス変更時に、選考プロセス履歴が作成されるので再取得する
        // 作成される履歴の詳細はフロントエンドでは不明のため、楽観的更新はできない
        mutate(generateUseRecruitmentProcessRecordsByEmployeeKey(newGraduate.id));

        // NOTE:
        // 候補者一覧画面で選考ステータスを更新した後に、コンタクトルームに移動した時に、選考ステータスの更新を反映するためにcontactRoomsMutateを呼び出している
        // その際、楽観的更新の必要性はないと思われるので、ここでcontactRoomsMutateを実行している
        contactRoomsMutate();

        // 内定承諾期日の更新がある場合
        if (offerAcceptanceDeadline) {
          mutateEmployeeInformation(newGraduate.id);
          if (updatedOfferAcceptanceDeadline) {
            enqueueSnackbar("内定承諾期日が登録されました", { variant: "success" });
          } else {
            enqueueSnackbar(
              "内定承諾期日の保存に失敗しました。候補者情報ページより改めて操作をお試しください。",
              { variant: "error" }
            );
          }
        }
      } catch (e) {
        enqueueSnackbar("ステータスの保存に失敗しました。改めて操作をお試しください。", {
          variant: "error",
        });
        captureException({
          error: e as Error,
          tags: {
            type: "useUpdateNewGraduateRecruitmentStatus:updateNewGraduateRecruitmentStatus",
          },
        });
      }
    },
    [
      recruitmentStatuses,
      generateMutateEmployee,
      generateMutateNewGraduate,
      options,
      mutateAllNewcomers,
      contactRoomsMutate,
      enqueueSnackbar,
    ]
  );

  return { updateNewGraduateRecruitmentStatus };
};
