import { Box } from "@material-ui/core";

import { isEmpty } from "lodash";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { OnnTaskWithAnswer, PortalOnnTaskCard } from "../../onnTasks/_share/PortalOnnTaskCard";

import { Typography, Button, Icon } from "~/components/uiParts";

type Props = {
  onnTasksWithAnswer: OnnTaskWithAnswer[];
  isPreview: boolean;
};

export const TaskBlock: FC<Props> = ({ onnTasksWithAnswer, isPreview }) => {
  const navigate = useNavigate();

  const handleClickNavigateTasks = () => {
    navigate(`/portal/onn_tasks${isPreview ? "?preview=true" : ""}`);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          フォーム
        </Typography>
        <Button
          color="primary"
          variant="text"
          borderRadius="regular"
          endIcon={<Icon icon="arrowRight" color="primary" size="sm" />}
          onClick={handleClickNavigateTasks}
        >
          すべて見る
        </Button>
      </Box>

      <Typography variant="caption" color="textPrimary">
        期日の近い3つを表示しています
      </Typography>

      <Box height="8px" />

      {!isEmpty(onnTasksWithAnswer) ? (
        onnTasksWithAnswer.map((onnTaskWithAnswer) => (
          <Box key={onnTaskWithAnswer.onnTask.id} mt={2}>
            <PortalOnnTaskCard onnTaskWithAnswer={onnTaskWithAnswer} />
          </Box>
        ))
      ) : (
        <Box mt={2}>
          <StyledDiv>
            <Box width="100%" p={3}>
              <Typography variant="body2" color="textPrimary">
                未回答のものはありません
              </Typography>
            </Box>
          </StyledDiv>
        </Box>
      )}
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.default};
  box-shadow: ${(props) => props.theme.shadows[10]};
  list-style-type: none;
  border-radius: 15px;
`;
