import { FormControl, FormGroup } from "@material-ui/core";
import { CheckBoxQuestion } from "@onn/common";
import React, { memo } from "react";
import styled from "styled-components";

import { QuestionPartBase } from "./QuestionPartBase";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";
import theme from "~/config/theme";

export const CheckBoxQuestionPart = memo(({ question }: { question: CheckBoxQuestion }) => {
  return (
    <QuestionPartBase questionTitle={question.title} isRequired={question.isRequired}>
      <StyledFormControl disabled>
        <FormGroup>
          {question.options.map((option) => {
            return (
              <FormControlLabel
                key={option.id}
                control={<Checkbox checked={false} disableEffect disabled />}
                label={
                  <Typography variant="body2" style={{ color: theme.palette.grey[200] }}>
                    {option.text}
                  </Typography>
                }
              />
            );
          })}
        </FormGroup>
      </StyledFormControl>
    </QuestionPartBase>
  );
});

const StyledFormControl = styled(FormControl)`
  .MuiButtonBase-root {
    padding: 0px;
    margin-right: 8px;
  }
  .MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormGroup-root {
    row-gap: 8px;
  }
  .MuiFormControlLabel-label {
    height: 24px;
  }
  #checkbox {
    background-color: ${(props) => props.theme.palette.grey[50]};
    border-radius: 5px;
  }
`;
