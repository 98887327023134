import React, { FC } from "react";

import { ColumnType, labelMap } from "../const";

import { CellItem } from "./CellItem";

export const HeaderCell: FC<{
  style: React.CSSProperties;
  columnType: ColumnType;
  isLastColumn: boolean;
}> = ({ columnType, ...props }) => {
  const text = labelMap[columnType];
  return <CellItem {...props} text={text} />;
};
