import { Employee, EmployeeTag, Space } from "@onn/common";

import { format } from "date-fns";

export const useCsvData = ({
  taggedEmployees,
  employeeTag,
  spaces,
  employeeIdToTaggedAtMap,
}: {
  taggedEmployees: Employee[];
  employeeTag: EmployeeTag;
  spaces: Space[];
  employeeIdToTaggedAtMap: Record<string, string>;
}) => {
  const headerRow = ["タグ名", "ID", "ユーザー名", "メールアドレス", "年次", "追加日"];
  const bodyRows = taggedEmployees.map((employee) => {
    return [
      employeeTag.name,
      employee.uniqueId ?? "",
      employee.getName(),
      employee.email,
      spaces.find((space) => space.id === employee.spaceId)?.name || "",
      format(
        new Date(employeeIdToTaggedAtMap[employee.id] as (typeof employeeIdToTaggedAtMap)[string]),
        "yyyy/M/d"
      ),
    ];
  });

  const csvData = [headerRow, ...bodyRows];

  const filename = `${employeeTag.name}_候補者一覧.csv`;

  return { csvData, filename };
};
