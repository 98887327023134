import { CandidateDateWithNumberOfParticipants } from "@onn/common";
import { useMemo, useState } from "react";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

export const CAN_NOT_JOIN = "canNotJoin";

/**
 * イベント実施日フィルターのHooks
 */
export const useFilterByCandidateDates = ({
  onnEventAnswersWithEmployee,
  getAnsweredCandidateDate,
}: {
  onnEventAnswersWithEmployee: OnnEventAnswerWithEmployee[];
  getAnsweredCandidateDate: (
    employeeId: string
  ) => CandidateDateWithNumberOfParticipants | undefined;
}) => {
  // 実施日列の表示制御
  const [selectedCandidateDateIds, setSelectedCandidateDateIds] = useState<string[]>([]);

  // 行の絞り込み
  const answersFilteredByCandidateDates = useMemo(() => {
    if (!selectedCandidateDateIds.length) return onnEventAnswersWithEmployee;

    return onnEventAnswersWithEmployee.filter((answer) => {
      if (selectedCandidateDateIds.includes(CAN_NOT_JOIN) && answer.isUnavailableCandidates())
        return true;

      const answeredCandidateDate = getAnsweredCandidateDate(answer.employee.id);
      return answeredCandidateDate && selectedCandidateDateIds.includes(answeredCandidateDate.id);
    });
  }, [onnEventAnswersWithEmployee, selectedCandidateDateIds, getAnsweredCandidateDate]);

  return {
    selectedCandidateDateIds,
    setSelectedCandidateDateIds,
    answersFilteredByCandidateDates,
  };
};
