import Popper from "@material-ui/core/Popper";
import { styled as muiStyled } from "@material-ui/core/styles";
import { LineStamp, allLineStampMap } from "@onn/common";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Icon, IconButton } from "~/components/uiParts";
import { useSentLineStamps } from "~/hooks/contactMessage/useSentLineStampMessages";
import { usePreviousValue } from "~/hooks/shared";
import { useTenant } from "~/hooks/tenant";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

type Props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onSelect: (lineStamp: LineStamp) => void;
};

export function LineStampPopover({ anchorEl, open, onSelect }: Props) {
  const { tenant } = useTenant();
  const { data: sentLineStamps, isLoading } = useSentLineStamps({ tenantId: tenant.tenantId });
  const { value: previousIsLoading } = usePreviousValue(isLoading);
  const rootRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<"history" | string>("history");

  useEffect(() => {
    if (!isLoading && previousIsLoading) {
      // 初期描画時、送信済みスタンプがない場合は、デフォルトタブを履歴ではなく最初のパッケージにする
      if (!sentLineStamps?.length) {
        setActiveTab([...allLineStampMap.keys()][0] as string);
      }
    }
  }, [isLoading, previousIsLoading, sentLineStamps?.length]);

  return (
    // 明示的なクローズがないので、領域外クリックで閉じれるようにした方が良い？
    <Popper
      open={open}
      anchorEl={anchorEl}
      style={{
        width: anchorEl?.offsetWidth,
        zIndex: 1,
      }}
    >
      <Root ref={rootRef}>
        <TabList role="tablist">
          <TabButton
            type="button"
            role="tab"
            id="tab-trigger-history"
            aria-selected={activeTab === "history"}
            aria-controls="tabpanel-history"
            onClick={() => setActiveTab("history")}
          >
            <Icon icon="clock" color="grey" size="sm" />
          </TabButton>
          {[...allLineStampMap.entries()].map(([packageId, stamps]) => (
            <TabButton
              key={packageId}
              type="button"
              role="tab"
              id={`tab-trigger-${packageId}`}
              aria-selected={activeTab === packageId}
              aria-controls={`tabpanel-${packageId}`}
              onClick={() => setActiveTab(packageId)}
            >
              <Img
                src={stamps[0]?.path ? fileAPIAdapter.getFileUrl(stamps[0].path) : undefined}
                alt={stamps[0]?.stickerId ?? ""}
                width={32}
                height={32}
              />
            </TabButton>
          ))}
        </TabList>
        <TabPanel
          id="tabpanel-history"
          role="tabpanel"
          aria-labelledby="tab-trigger-history"
          data-active={activeTab === "history"}
        >
          <Ul>
            {sentLineStamps?.map((s) => (
              <LineStampItem key={s.stickerId} lineStamp={s} onSelect={onSelect} />
            ))}
          </Ul>
        </TabPanel>
        {[...allLineStampMap.entries()].map(([packageId, stamps]) => (
          <TabPanel
            key={packageId}
            id={`tabpanel-${packageId}`}
            role="tabpanel"
            aria-labelledby={`tab-trigger-${packageId}`}
            data-active={activeTab === packageId}
          >
            <Ul>
              {stamps.map((stamp) => (
                <LineStampItem key={stamp.stickerId} lineStamp={stamp} onSelect={onSelect} />
              ))}
            </Ul>
          </TabPanel>
        ))}
      </Root>
    </Popper>
  );
}

const Root = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  max-height: 240px;
`;

const TabList = muiStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  display: "flex",
  gap: "8px",
  paddingLeft: "8px",
  paddingRight: "8px",
}));

const TabButton = muiStyled("button")(({ theme }) => ({
  all: "unset",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  height: "32px",
  ['&[aria-selected="true"]']: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const TabPanel = muiStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: "200px",
  overflowY: "auto",
  padding: "16px",
  ['&[data-active="false"]']: {
    display: "none",
  },
}));

type LineStampItemProps = {
  lineStamp: LineStamp;
  onSelect: (lineStamp: LineStamp) => void;
};

function LineStampItem({ lineStamp, onSelect }: LineStampItemProps) {
  const [showPreview, setShowPreview] = useState(false);

  return (
    <Li key={lineStamp.stickerId}>
      <Button aria-label={`line stamp ${lineStamp.stickerId}`} onClick={() => setShowPreview(true)}>
        <Img
          height={80}
          width={80}
          src={fileAPIAdapter.getFileUrl(lineStamp.path)}
          alt={lineStamp.stickerId}
        />
      </Button>
      {showPreview && (
        <PreviewArea>
          <PreviewClose>
            <IconButton
              icon="close"
              color="white"
              size="md"
              onClick={() => setShowPreview(false)}
              aria-label="close preview"
            />
          </PreviewClose>
          <PreviewedStampButton onClick={() => onSelect(lineStamp)}>
            <Img
              height={160}
              width={160}
              src={fileAPIAdapter.getFileUrl(lineStamp.path)}
              alt={lineStamp.stickerId}
            />
          </PreviewedStampButton>
        </PreviewArea>
      )}
    </Li>
  );
}

const Ul = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 8px;
`;

const Li = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  all: unset;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Img = styled.img`
  object-fit: contain;
`;

const PreviewArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #757575e5;
  width: 100%;
  height: 100%;
  padding: 8px;
  display: grid;
  place-items: center;
`;

const PreviewedStampButton = styled.button`
  all: unset;
  cursor: pointer;
`;

const PreviewClose = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;
