import { Box } from "@mui/material";
import { OnnEventSlotDate, BriefingSessionEvent, NewInterviewEvent, OnnEvent } from "@onn/common";
import React, { FC, useCallback } from "react";

import { StyledAnchor } from "../../../common/StyledAnchor";
import { Cell } from "../../_share/Cell";

import { IconButton, UncontrolledMenu } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

type Props = {
  newGraduateId: string;
  onnEvent: OnnEvent;
  selectedOnnEventSlotDate?: OnnEventSlotDate;
};

export const OnnEventTitleCell: FC<Props> = ({
  onnEvent,
  selectedOnnEventSlotDate,
  newGraduateId,
}) => {
  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();

  const openAnswerEventOnBehalfModal = useCallback(
    (onnEvent: BriefingSessionEvent | NewInterviewEvent) => {
      handleModal({
        name: "answerEventOnBehalfModal",
        args: {
          onnEvent,
          fixedOption: {
            selectedEmployeeId: newGraduateId,
          },
          mode: selectedOnnEventSlotDate
            ? {
                type: "edit",
                onnEventSlotDateId: selectedOnnEventSlotDate.id,
                briefingSessionCategoryId:
                  selectedOnnEventSlotDate.briefingSessionCategoryId || undefined,
              }
            : {
                type: "create",
              },
          slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
        },
      });
    },
    [handleModal, newGraduateId, selectedOnnEventSlotDate]
  );

  const isDisplayManageMenu =
    currentUser.isAdmin() && (onnEvent.isBriefingSessionEvent() || onnEvent.isNewInterviewEvent());

  return (
    <Cell sx={{ "&.MuiTableCell-root": { padding: 0 } }}>
      <Box display="flex" alignItems="center">
        <Box width="40px">
          {isDisplayManageMenu && (
            <UncontrolledMenu
              renderButton={(openMenu) => (
                <IconButton icon="menuVert" size="md" onClick={openMenu} />
              )}
              menuItemOptions={[
                {
                  onClick: () => openAnswerEventOnBehalfModal(onnEvent),
                  text: selectedOnnEventSlotDate ? "予約変更" : "代理回答",
                },
              ]}
            />
          )}
        </Box>
        <Box p="16px">
          <StyledAnchor target="_blank" href={`/events/${onnEvent.id}`}>
            {onnEvent.title}
          </StyledAnchor>
        </Box>
      </Box>
    </Cell>
  );
};
