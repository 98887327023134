import { zodResolver } from "@hookform/resolvers/zod";
import { kanaNameSchema } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { TextFieldDeprecated, Typography } from "~/components/uiParts";

type Props = {
  onSave: (kanaName: z.infer<typeof kanaNameSchema>) => void;
  onCancel: () => void;
  data?: z.infer<typeof kanaNameSchema>;
};

export const KanaNameEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, control, formState } = useForm<z.infer<typeof kanaNameSchema>>({
    defaultValues: { ...data },
    mode: "onChange",
    resolver: zodResolver(kanaNameSchema),
  });

  return (
    <InformationValueEditor
      form={
        <form
          style={{ display: "flex", flexDirection: "column", rowGap: 16 }}
          onSubmit={handleSubmit(onSave)}
        >
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">セイ</Typography>
                </div>
                <StyledTextField
                  defaultValue={data?.lastName}
                  placeholder="タナカ"
                  variant="outlined"
                  fullWidth
                  name={field.name}
                  error={!!error}
                  helperText={error?.message}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">メイ</Typography>
                </div>
                <StyledTextField
                  defaultValue={data?.firstName}
                  placeholder="タロウ"
                  variant="outlined"
                  fullWidth
                  name={field.name}
                  error={!!error}
                  helperText={error?.message}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;
