import { RegistrationInvitationLink } from "@onn/common";
import { EmailType, LineType } from "@onn/common/domain/Employee/AuthenticationType";
import { useState, useCallback } from "react";

import { Condition } from "./type";

import { useCreateRegistrationInvitationLink } from "~/hooks/registrationInvitationLink/useCreateRegistrationInvitationLink";
import { useUpdateRegistrationInvitationLink } from "~/hooks/registrationInvitationLink/useUpdateRegistrationInvitationLink";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const useCreateOrUpdateRegistrationInvitationLink = ({
  selectedCondition,
}: {
  selectedCondition: Condition;
}) => {
  const { currentSpace } = useCurrentSpace();
  const [isLoading, setIsLoading] = useState(false);

  // 条件付与された共通QRの情報を管理するステート
  const [createdRegistrationInvitationLink, setCreatedRegistrationInvitationLink] =
    useState<RegistrationInvitationLink | null>(null);

  const { create: createRegistrationInvitationLink } = useCreateRegistrationInvitationLink();
  const { update: updateRegistrationInvitationLink } = useUpdateRegistrationInvitationLink();

  const createOrUpdateRegistrationInvitationLink = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    const conditions = {
      selectableAuthenticationFlowTypes: selectedCondition.authenticationFlowTypes as
        | [LineType]
        | [LineType, EmailType],
      onnEventIds: selectedCondition.onnEventIds,
      onnTaskIds: selectedCondition.onnTaskIds,
      recruitmentStatusId: selectedCondition.recruitmentStatusId,
      tagIds: selectedCondition.tagIds,
    };

    if (createdRegistrationInvitationLink) {
      const updatedRegistrationInvitationLink = await updateRegistrationInvitationLink({
        registrationInvitationLinkId: createdRegistrationInvitationLink.id,
        conditions: conditions,
      });
      setCreatedRegistrationInvitationLink(updatedRegistrationInvitationLink);
      setIsLoading(false);
      return updatedRegistrationInvitationLink;
    } else {
      const _createdRegistrationInvitationLink = await createRegistrationInvitationLink({
        conditions: {
          spaceId: currentSpace.id,
          ...conditions,
        },
      });
      setCreatedRegistrationInvitationLink(_createdRegistrationInvitationLink);
      setIsLoading(false);
      return _createdRegistrationInvitationLink;
    }
  }, [
    createRegistrationInvitationLink,
    createdRegistrationInvitationLink,
    currentSpace.id,
    isLoading,
    selectedCondition,
    updateRegistrationInvitationLink,
  ]);

  return {
    createdRegistrationInvitationLink,
    createOrUpdateRegistrationInvitationLink,
    isLoading,
  };
};
