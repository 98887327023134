import {
  Box,
  Accordion as AccordionOrigin,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import React, { FC, ReactNode, useCallback } from "react";
import styled from "styled-components";

import { Divider } from "~/components/uiParts/Divider";
import { IconButton } from "~/components/uiParts/IconButton";
import { Tooltip } from "~/components/uiParts/Tooltip";
import { mixin } from "~/util";

type Props = {
  expanded: boolean;
  headerContent: ReactNode;
  children: ReactNode;
  onToggleExpanded: (value: boolean) => void;
  hideToggleExpandedButton?: boolean;
  isActive?: boolean;
};

/**
 * @deprecated
 * NewAccordionを使うこと
 */
export const Accordion: FC<Props> = ({
  expanded,
  headerContent,
  children,
  onToggleExpanded,
  hideToggleExpandedButton = false,
  isActive = false,
}) => {
  const handleToggleExpanded = useCallback(
    () => onToggleExpanded(!expanded),
    [onToggleExpanded, expanded]
  );

  return (
    <StyledAccordion
      // 開いてるときはボタンをクリックしないと閉じないようにする
      onChange={expanded ? undefined : handleToggleExpanded}
      // メニューボタンを非表示にすると同時に、expansionしないようにする
      expanded={hideToggleExpandedButton ? false : expanded}
      // アニメーション
      $isActive={isActive}
    >
      <StyledAccordionSummary $defaultCursor={hideToggleExpandedButton}>
        <Box display="flex" width="calc(100% - 48px)">
          {headerContent}
        </Box>
        <Box m="auto" visibility={hideToggleExpandedButton ? "hidden" : "visible"}>
          <Tooltip title={expanded ? "閉じる" : "開く"} placement="bottom">
            <IconButton
              icon={expanded ? "chevronUp" : "chevronDown"}
              size="md"
              onClick={handleToggleExpanded}
            />
          </Tooltip>
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Box flex="1 1 auto" mt={1.5} mb={1.5}>
          <Divider />
        </Box>
        {children}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(AccordionOrigin)<{ $isActive: boolean }>`
  padding: 16px 40px;

  ${(props) => props.$isActive && mixin.outlineAnimation()}

  &.MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &.MuiAccordion-rounded:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)<{ $defaultCursor: boolean }>`
  display: flex;
  align-items: center;
  padding: 0px;

  &.MuiAccordionSummary-root {
    padding: 0px;
    width: 100%;
    ${({ $defaultCursor }) => $defaultCursor && `cursor: default !important;`}
  }

  &.MuiAccordionSummary-root.Mui-expanded {
    margin: 0;
    min-height: unset;
  }

  > .MuiAccordionSummary-content {
    margin: 0;
    width: 100%;

    &.Mui-expanded {
      cursor: default;
      margin: 0;
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;

  &.MuiAccordionDetails-root {
    padding: 0px;
  }
`;
