import { Chip as MuiChip } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

export type Status = "未着手" | "未配信" | "回答済み" | "期限切れ";
type Props = {
  status: Status;
  fontSize?: number;
  type: "RecruitmentProcessTab" | "EmployeeOnnTaskTab"; // NOTE: 「回答済み」の配色が異なるため、どちらの画面で使用するかを判別するためのtypeを追加
};

export const AnswerStatusChips: FC<Props> = ({ status, fontSize = 12, type }) => {
  return <StyledChip $status={status} label={status} $fontSize={fontSize} $type={type} />;
};

const StyledChip = styled(MuiChip)<{
  $status: Status;
  $fontSize?: number;
  $type: "RecruitmentProcessTab" | "EmployeeOnnTaskTab";
}>`
  &.MuiChip-root {
    height: 32px;
    font-size: ${(props) => props.$fontSize}px;
    font-weight: 700;
    ${(props) => {
      switch (props.$status) {
        case "未着手": {
          return `
                color: ${props.theme.palette.common.black};
                background-color: ${props.theme.palette.grey[100]};
                `;
        }
        case "未配信": {
          return `
                color: ${props.theme.palette.common.white};
                background-color: ${props.theme.palette.grey[400]};
                `;
        }
        case "回答済み": {
          switch (props.$type) {
            case "EmployeeOnnTaskTab": {
              return `
                color: ${props.theme.palette.common.white};
                background-color: ${props.theme.palette.primary.main};
                `;
            }
            case "RecruitmentProcessTab": {
              return `
                color: ${props.theme.palette.primary.main};
                background-color: ${props.theme.palette.common.white};
                border: 1px solid ${props.theme.palette.primary.main};
                `;
            }
            default: {
              const _exhaustiveCheck: never = props.$type;
              return _exhaustiveCheck;
            }
          }
        }
        case "期限切れ": {
          return `
                color: ${props.theme.palette.common.white};
                background-color: ${props.theme.palette.error.light};
                `;
        }
      }
    }}
  }
`;
