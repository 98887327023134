import { IDescriptionListPart } from "@onn/common/domain/NotificationPart/IDescriptionListPart";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

type Props = {
  content: IDescriptionListPart;
};
export const DescriptionListPart: FC<Props> = ({ content }) => {
  return (
    <>
      {content.items.map((item, i) => {
        return (
          <Typography variant="body2" key={i}>
            {item.term}: {item.description}
          </Typography>
        );
      })}
    </>
  );
};
