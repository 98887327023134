import { RecruitmentStatus, RecruitmentStatusWithRelatedInfo } from "@onn/common";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";
import { useRecruitmentStatusesWithRelations } from "~/hooks/recruitmentStatusWithRelation";

export const useSelectedRecruitmentStatus = () => {
  const navigate = useNavigate();
  const { recruitmentStatuses } = useRecruitmentStatusList();
  const defaultRecruitmentStatus = recruitmentStatuses[0];
  // 選考ステータスは必ず存在するため、undefinedの場合はエラーを投げる
  if (!defaultRecruitmentStatus) throw new Error("defaultRecruitmentStatus is undefined");

  const [selectedRecruitmentStatusId, setSelectedRecruitmentStatusId] = useState<
    RecruitmentStatus["id"]
  >(defaultRecruitmentStatus.id);
  const selectedRecruitmentStatus = recruitmentStatuses.find(
    (v) => v.id === selectedRecruitmentStatusId
  );

  // 選択中の選考ステータスは必ず存在するため、undefinedの場合はエラーを投げる
  if (!selectedRecruitmentStatus) throw new Error("selectedRecruitmentStatus is undefined");

  // URLのアンカーによる選考ステータスの選択
  useEffect(() => {
    const statusLabel = decodeURI(location.hash.replace("#", ""));
    if (!statusLabel) return;

    const status = recruitmentStatuses.find((s) => s.label === statusLabel);
    if (status) setSelectedRecruitmentStatusId(status.id);
  }, [recruitmentStatuses]);

  const resetSelectedRecruitmentStatus = useCallback(() => {
    setSelectedRecruitmentStatusId(defaultRecruitmentStatus.id);
  }, [defaultRecruitmentStatus]);

  const changeSelectedRecruitmentStatusId = useCallback(
    (id: RecruitmentStatus["id"]) => {
      setSelectedRecruitmentStatusId(id);

      const status = recruitmentStatuses.find((s) => s.id === id);
      if (status) navigate(`#${status.label}`);
    },
    [navigate, recruitmentStatuses]
  );

  return {
    selectedRecruitmentStatus,
    changeSelectedRecruitmentStatusId,
    resetSelectedRecruitmentStatus,
    ...useWarningRecruitmentStatuses(),
  };
};

const useWarningRecruitmentStatuses = () => {
  const { data: recruitmentStatuses = [] } = useRecruitmentStatusesWithRelations({
    withDeleted: false,
  });

  const [initialWarningRecruitmentStatuses] = useState<RecruitmentStatusWithRelatedInfo[]>(
    recruitmentStatuses.filter(
      (s) => s.isRelatedTriggerForDeletedStatus || s.isRelatedActionForDeletedStatus
    )
  );
  const [warningRecruitmentStatuses, setWarningRecruitmentStatuses] = useState<RecruitmentStatus[]>(
    initialWarningRecruitmentStatuses
  );
  const resetWarningRecruitmentStatuses = useCallback(() => {
    setWarningRecruitmentStatuses(initialWarningRecruitmentStatuses);
  }, [initialWarningRecruitmentStatuses]);

  return {
    warningRecruitmentStatuses,
    setWarningRecruitmentStatuses,
    resetWarningRecruitmentStatuses,
  };
};
