import { Employee } from "@onn/common";

import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";

import { useCancelButtonHandler } from "../../_share/hooks/useCancelButtonHandler";
import { useFromPageInQS } from "../../_share/hooks/useFromPageInQS";
import { useGoBackButtonHandler } from "../../_share/hooks/useGoBackButtonHandler";

import { useSubmitButtonHandler } from "./useSubmitButtonHandler";

export const useActionFooter = ({
  onnEvent,
  setSelectedNewGraduates,
  selectedNewGraduates,
}: {
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
  selectedNewGraduates: Employee[];
}) => {
  const fromPage = useFromPageInQS();
  const { onClickCancel } = useCancelButtonHandler({ onnEvent });

  const { onClickGoBack } = useGoBackButtonHandler(onnEvent.id);

  const { onClickConfirmSave, isLoading: isSubmittingLoading } = useSubmitButtonHandler({
    onnEvent,
    setSelectedNewGraduates,
    selectedNewGraduates,
  });

  const isDisabledSaveButton = isSubmittingLoading || selectedNewGraduates.length === 0;
  return {
    cancelButtonText: fromPage === "create" ? "あとで設定" : "キャンセル",
    submitButtonText: "配信",
    onClickGoBack,
    onClickCancel,
    onClickConfirmSave,
    isDisabledSaveButton,
    isSubmittingLoading,
  };
};
