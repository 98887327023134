import { z } from "zod";

import { AttendanceStatusSchema } from "../inputTypeSchemas/AttendanceStatusSchema";

/////////////////////////////////////////
// ONN EVENT DETERMINED DATE SCHEMA
/////////////////////////////////////////

export const OnnEventDeterminedDateSchema = z.object({
  attendanceStatus: AttendanceStatusSchema,
  id: z.string(),
  onnEventId: z.string(),
  onnEventSlotDateId: z.string().nullish(),
  employeeId: z.string(),
  candidateDateId: z.string(),
  tenantId: z.string(),
  latestSentAtOnlineUrlNotification: z.date().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventDeterminedDate = z.infer<typeof OnnEventDeterminedDateSchema>;

/////////////////////////////////////////
// ONN EVENT DETERMINED DATE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventDeterminedDateOptionalDefaultsSchema = OnnEventDeterminedDateSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventDeterminedDateOptionalDefaults = z.infer<
  typeof OnnEventDeterminedDateOptionalDefaultsSchema
>;

export default OnnEventDeterminedDateSchema;
