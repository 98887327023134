import { useCallback, useMemo, useState } from "react";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useCurrentUser } from "~/hooks/employee";
import { useConvertFileToBase64 } from "~/hooks/file";
import { useNotifyOperationLog, useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

type Props = {
  validate: ({
    isDryRun,
    base64EncodedCsvFile,
  }: {
    isDryRun: boolean;
    base64EncodedCsvFile: string;
  }) => Promise<
    | {
        isOk: true;
      }
    | {
        isOk: false;
        errorMessages: string[];
      }
  >;
  // NOTE: このラベルはinvalidなcsvが選択された時に、slack通知がいくため、具体的な内容を記載してください
  // ex) "[面談イベント]予約枠一括登録"
  csvUploadErrorLabel: string;
};

/**
 * csvによる登録・更新モーダルでのファイル選択時の処理を行う
 * 選択時にバリデーションも行っている
 */
export const useHandleInputCsvFileWithValidation = ({ validate, csvUploadErrorLabel }: Props) => {
  const [inputFile, setInputFile] = useState<File | undefined>();

  const [loadedFileName, setLoadedFileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { notifyOperationLog, operationLog } = useNotifyOperationLog();
  const { currentUser } = useCurrentUser();

  const [isCheckingCsv, setIsCheckingCsv] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const { convertFileToBase64 } = useConvertFileToBase64();

  const handleInputFile = useCallback(
    async (payload: Payload) => {
      try {
        setIsCheckingCsv(true);
        if (payload.status === "error") {
          enqueueSnackbar(payload.message, { variant: "error" });
          return;
        }

        const file = payload.files[0];
        if (!file) return;
        setInputFile(file);

        const base64EncodedCsvFile = await convertFileToBase64(file);

        const response = await validate({
          isDryRun: true,
          base64EncodedCsvFile,
        });
        if (response.isOk) {
          setErrorMessages([]);
        } else {
          setErrorMessages(response.errorMessages);
          notifyOperationLog(
            operationLog.notifyCSVUploadError(
              currentUser,
              csvUploadErrorLabel,
              response.errorMessages.join("\n")
            )
          );
        }
        setLoadedFileName(file.name);
      } catch (e) {
        captureException({
          error: e as Error,
          tags: {
            type: "useHandleInputCsvFile",
          },
          extras: {
            currentUser,
          },
        });
        enqueueSnackbar(
          "エラーが発生しました。もう一度お試しいただけますでしょうか。もし解決しない場合はお問い合わせください。",
          { variant: "error" }
        );
      } finally {
        setIsCheckingCsv(false);
      }
    },
    [
      convertFileToBase64,
      csvUploadErrorLabel,
      currentUser,
      enqueueSnackbar,
      notifyOperationLog,
      operationLog,
      validate,
    ]
  );

  const fileLabel = useMemo(() => {
    if (loadedFileName) return loadedFileName;
    return "ファイルが選択されていません";
  }, [loadedFileName]);

  return { handleInputFile, fileLabel, errorMessages, isCheckingCsv, inputFile };
};
