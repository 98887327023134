import { APISchema, BriefingSessionCategory, OnnEvent } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { useAllOnnEvent } from "./useAllOnnEvents";

import { mutateBriefingSessionCategories } from "./useBriefingSessionCategories";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/onn_event_api/onn-event"]["POST"];

export const useCreateOnnEventV2 = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: mutateAllOnnEvents } = useAllOnnEvent();

  const createOnnEvent = useCallback(
    async (param: EndPoint["body"]["createParams"]) => {
      try {
        const response = await apiClient.post("/onn_event_api/onn-event", {
          createParams: param,
        });
        mutateAllOnnEvents();
        mutateBriefingSessionCategories({ onnEventId: response.data.onnEvent.id });
        return {
          onnEvent: new OnnEvent(response.data.onnEvent),
          briefingSessionCategories: response.data.briefingSessionCategories.map(
            (b) => new BriefingSessionCategory(b)
          ),
        };
      } catch (_e) {
        enqueueSnackbar("イベントの作成に失敗しました", { variant: "error" });
      }
    },
    [enqueueSnackbar, mutateAllOnnEvents]
  );

  return { createOnnEvent };
};
