import { zodResolver } from "@hookform/resolvers/zod";
import { EmployeePrediction } from "@onn/common";
import { useCallback } from "react";
import { DropResult } from "react-beautiful-dnd";
import { UseFormReturn, useFieldArray, useForm } from "react-hook-form";
import { v4 } from "uuid";
import { z } from "zod";

import { InputState, formSchema } from "./schema";

import { useEditEmployeePredictions } from "~/hooks/employeePrediction";

import { usePrompt } from "~/hooks/shared";

export const useEmployeePredictionsForm = ({
  employeePredictions,
  onSuccess,
}: {
  employeePredictions: EmployeePrediction[];
  onSuccess: () => void;
}) => {
  const form = useForm<InputState, void>({
    defaultValues: {
      employeePredictions: employeePredictions.map(({ id, label }) => ({ id, label })),
    },
    resolver: zodResolver(z.object({ employeePredictions: formSchema })),
    mode: "all",
  });
  const { fields, addPrediction, removePrediction, changeOrder } = useFields(form);

  const { editEmployeePredictions } = useEditEmployeePredictions();
  const submit = form.handleSubmit(async (data) => {
    await editEmployeePredictions({
      employeePredictions: data.employeePredictions,
      successMessage: "ヨミ設定を更新しました",
    }).then(() => {
      onSuccess();
    });
  });
  const handleSubmit = useCallback(async () => {
    await submit();
  }, [submit]);

  usePrompt("ヨミ設定の編集を破棄しますか？", form.formState.isDirty);

  return {
    form,
    fields,
    changeOrder,
    addPrediction,
    removePrediction,
    handleSubmit,
    isSubmitButtonDisabled:
      form.formState.isSubmitting || !form.formState.isValid || !form.formState.isDirty,
  };
};

const useFields = (form: UseFormReturn<InputState, void, undefined>) => {
  const { fields, insert, move, remove } = useFieldArray({
    control: form.control,
    name: "employeePredictions",
  });

  const addPrediction = useCallback(() => {
    const index = fields.length;
    insert(index, {
      id: v4(),
      label: "",
    });
  }, [fields, insert]);

  const removePrediction = useCallback(
    (index: number) => {
      remove(index);
      form.trigger("employeePredictions");
    },
    [form, remove]
  );

  const changeOrder = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const {
        source: { index: sourceIndex },
        destination: { index: destinationIndex },
      } = result;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      move(sourceIndex, destinationIndex);
    },
    [move]
  );

  return {
    fields,
    addPrediction,
    removePrediction,
    changeOrder,
  };
};
