import {
  ListCandidateDatesWithNumberOfParticipantsRequest,
  ListCandidateDatesWithNumberOfParticipantsResponse,
  OnnEvent,
  OnnEventAnswer,
  OnnEventDeterminedDate,
} from "@onn/common";
import useSWR, { mutate } from "swr";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export type EventDataType = {
  onnEvent: OnnEvent;
  onnEventAnswer: OnnEventAnswer;
  onnEventDeterminedDate: OnnEventDeterminedDate | null;
};

export const generateSWRKeys = (
  currentEmployeeId?: string,
  onnEventId?: string
): SWRKeyWhenCF<{
  currentEmployeeId: string;
  onnEventId: string;
}> | null => {
  if (!onnEventId || !currentEmployeeId) return null;
  return {
    CF: "onneventanswer",
    actionType: ListCandidateDatesWithNumberOfParticipantsRequest.actionType,
    args: {
      currentEmployeeId: currentEmployeeId || "",
      onnEventId: onnEventId || "",
    },
  };
};

export const useCandidateDatesWithNumberOfParticipants = ({
  currentEmployeeId,
  onnEventId,
}: {
  currentEmployeeId?: string;
  onnEventId?: string;
}) => {
  return useSWR(
    generateSWRKeys(currentEmployeeId, onnEventId),
    async ({ args: { currentEmployeeId, onnEventId } }) => {
      const res = await functionOperator.httpsCallFor2ndGen<
        ReturnType<typeof ListCandidateDatesWithNumberOfParticipantsRequest.createRequestBody>,
        typeof ListCandidateDatesWithNumberOfParticipantsResponse.ResponseBody
      >(
        "onnevent",
        ListCandidateDatesWithNumberOfParticipantsRequest.createRequestBody({
          employeeId: currentEmployeeId,
          onnEventId,
        })
      );
      const result = ListCandidateDatesWithNumberOfParticipantsResponse.convertUnixTimeToDate(
        res.data
      );
      return result;
    }
  );
};

export const mutateCandidateDatesWithNumberOfParticipants = (
  currentEmployeeId: string,
  onnEventId: string
) => {
  mutate(generateSWRKeys(currentEmployeeId, onnEventId));
};
