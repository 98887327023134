import { useCallback } from "react";

import { useSelectableNewGraduatesForAnswerEvent } from "~/hooks/onnEvent/useSelectableNewGraduatesForAnswerEvent";

export const useGenerateSettingCSV = (onnEventId: string) => {
  const { selectableNewGraduates, isLoading: isLoadingNewGraduates } =
    useSelectableNewGraduatesForAnswerEvent({
      onnEventId,
    });

  const generateSettingCSV = useCallback(() => {
    return [
      ["「Onn固有ID」コピー欄", "名前", "メールアドレス"],
      ...selectableNewGraduates.map((v) => {
        return [v.uniqueId, v.getName(), v.email];
      }),
    ];
  }, [selectableNewGraduates]);

  return { generateSettingCSV, isLoading: isLoadingNewGraduates };
};
