import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Checkbox } from "../Checkbox";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";

type TableHeaderRowType =
  | {
      text: string;
      order?: "asc" | "desc";
      disabled?: boolean;
    } & (
      | { onSort?: () => void; onChangeCheckBox?: undefined; checked?: undefined }
      | {
          onSort?: undefined;
          onChangeCheckBox: () => void;
          checked: boolean;
          indeterminate: boolean;
        }
    );

type Props = {
  widthOptions: string[];
  headers: TableHeaderRowType[];
};

export const TableHeaderRow: FC<Props> = ({ widthOptions, headers }) => {
  return (
    <StyledTableHeadRow display="flex" alignItems="center" height="100%" position="relative">
      {headers.map((header, index) => {
        if (header.onChangeCheckBox) {
          return (
            <StyledBox key={`TableHead-${index}`} ml="10px" px="16px" width={widthOptions[index]}>
              <Checkbox
                indeterminate={header.indeterminate}
                checked={header.checked}
                onClick={() => header.onChangeCheckBox()}
                disabled={header.disabled || false}
                size="small"
              />
            </StyledBox>
          );
        }
        return header.onSort ? (
          <StyledBox key={`TableHead-${index}`} py="10px" px="16px" width={widthOptions[index]}>
            <StyledBox display="flex" gridGap="4px" alignItems="center" onClick={header.onSort}>
              <Typography variant="overline" color="textSecondary" noWrap>
                {header.text}
              </Typography>

              <Icon
                icon={header.order === "asc" ? "arrowDropUp" : "arrowDropDown"}
                color="grey"
                size="sm"
              />
            </StyledBox>
          </StyledBox>
        ) : (
          <Box key={`TableHead-${index}`} py="10px" px="16px" width={widthOptions[index]}>
            <Typography display="block" variant="overline" color="textSecondary" noWrap>
              {header.text}
            </Typography>
          </Box>
        );
      })}
    </StyledTableHeadRow>
  );
};

const StyledTableHeadRow = styled(Box)`
  > div {
    &:first-child {
      padding-left: 40px;
    }
    &:last-child {
      padding-right: 40px;
    }
  }

  &::after {
    content: "";
    background-color: ${(props) => props.theme.palette.grey[100]};
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: 0%;
    height: 1px;
  }
`;

const StyledBox = styled(Box)`
  cursor: pointer;
`;
