import { OnnEventSlotDatesForDisplay } from "@onn/common";
import { useCallback, useMemo, useState } from "react";

import {
  AnyInputCondition,
  AnyValidCondition,
} from "~/components/domains/onnEvents/OnnEventSlotSearchModal/types/condition";
import { LogicType } from "~/components/domains/onnEvents/OnnEventSlotSearchModal/types/logic-type";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";

export const useSlotsSearchByConditions = ({
  onnEventId,
  slotIdToSlotForDisplayMap,
}: {
  onnEventId: string;
  slotIdToSlotForDisplayMap: Map<string, OnnEventSlotDatesForDisplay>;
}) => {
  const [searchedSlotIds, setSearchedSlotIds] = useState<string[] | null>(null);
  const [conditions, setConditions] = useState<AnyInputCondition[]>([{ target: undefined }]);
  const [logicType, setLogicType] = useState<LogicType>("AND");

  const { enqueueSnackbar } = useSnackbar();

  const onResetSearchConditions = useCallback(() => {
    setConditions([{ target: undefined }]);
    setSearchedSlotIds(null);
    setLogicType("AND");
  }, []);

  const onSearchConfirm = useCallback(
    async (logicType: LogicType, conditions: AnyValidCondition[]) => {
      setConditions(conditions.length ? conditions : [{ target: undefined }]);
      setLogicType(logicType);

      if (conditions.length === 0) {
        onResetSearchConditions();
        return;
      }

      const { data: slotIds } = await apiClient
        .post("/onn_event_api/slots/search-by-conditions", {
          type: logicType,
          conditions,
          onnEventId,
        })
        .catch((e) => {
          enqueueSnackbar("検索に失敗しました。通信環境をご確認の上、再度お試しください。", {
            variant: "error",
          });
          throw e;
        });
      setSearchedSlotIds(slotIds);
    },
    [enqueueSnackbar, onResetSearchConditions, onnEventId]
  );

  const searchedSlots = useMemo(() => {
    if (searchedSlotIds === null) return null;
    return searchedSlotIds.flatMap((slotId) => slotIdToSlotForDisplayMap.get(slotId) || []);
  }, [searchedSlotIds, slotIdToSlotForDisplayMap]);

  return { conditions, logicType, onSearchConfirm, searchedSlots, onResetSearchConditions };
};
