import { z } from "zod";

export const ActionSettingTypeSchema = z.enum([
  "SendContactMessageActionSetting",
  "ChangeRecruitmentStatusActionSetting",
  "DeliverOnnTaskActionSetting",
  "DeliverOnnEventActionSetting",
]);

export type ActionSettingTypeType = `${z.infer<typeof ActionSettingTypeSchema>}`;

export default ActionSettingTypeSchema;
