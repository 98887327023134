import { z } from "zod";

export const TriggerSettingTypeSchema = z.enum([
  "ChangeRecruitmentStatusTriggerSetting",
  "AnswerOnnEventTriggerSetting",
  "AnswerOnnTaskTriggerSetting",
  "ChangeStatusOfOnnEventTriggerSetting",
  "EvaluateOnnEventTriggerSetting",
]);

export type TriggerSettingTypeType = `${z.infer<typeof TriggerSettingTypeSchema>}`;

export default TriggerSettingTypeSchema;
