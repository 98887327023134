import { Failure, OnnEvent, Result, Success } from "../../../../domain";
import { toCloudFunctionsCompatible } from "../../../../utils";
import { onnEventActionTypes } from "../shared";

import { IListOnnEventsRequestSchema, listOnnEventsRequestSchema } from "./schema";

export class ListOnnEventsRequest implements IListOnnEventsRequestSchema {
  static readonly validator = listOnnEventsRequestSchema;

  static actionType: typeof onnEventActionTypes.listOnnEvents = "listOnnEvents";
  tenantId: OnnEvent["tenantId"];
  isExpired?: boolean;

  constructor(init: ListOnnEventsRequest) {
    this.tenantId = init.tenantId;
    this.isExpired = init.isExpired;
  }

  // クライアント側でリクエストボディを作成する
  public static createRequestBody({
    tenantId,
    isExpired,
  }: {
    tenantId: string;
    isExpired?: boolean;
  }) {
    return toCloudFunctionsCompatible({
      tenantId,
      isExpired,
      actionType: this.actionType,
    });
  }

  public static validate(data: ListOnnEventsRequest): Result<ListOnnEventsRequest, Error> {
    const result = ListOnnEventsRequest.validator.safeParse(data);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new ListOnnEventsRequest(data));
  }
}
