import { useCallback } from "react";

import { useLocalStorage } from "../shared";

export const useLineAuthStateInLocalStorage = () => {
  const { storeValue, retrieveValue, removeValue } = useLocalStorage();
  const value = retrieveValue<string>("lineLoginState");
  const remove = useCallback(() => removeValue("lineLoginState"), [removeValue]);
  const store = useCallback((value: string) => storeValue("lineLoginState", value), [storeValue]);

  return { value, remove, store };
};
