import { format } from "date-fns";

import { BriefingSessionEvent, NewInterviewEvent } from "../../domain/OnnEvent";

export type InputValue = {
  slotDate: string;
  briefingSessionTitle?: string;
  slotStartTime: string;
  slotEndTime: string;
  capacity: string;
  assigneeEmail?: string;
  subAssigneeEmails?: string[];
  publicationStatus: "公開" | "非公開";
  slotType: "オンライン" | "オフライン";
  description: string;
  onlineUrl?: string;
  eventPlaceName?: string;
  eventAddressPostCode?: string;
  eventAddressText?: string;
};

export const slotType = {
  online: "オンライン",
  offline: "オフライン",
} as const;

export const publicationStatus = {
  published: "公開",
  draft: "非公開",
} as const;

export const generateCsvInputValueKeyToInfo = (
  onnEvent: NewInterviewEvent | BriefingSessionEvent
) => {
  const briefingSession = (() => {
    if (onnEvent.type === "briefing_session") {
      return { briefingSessionTitle: { labelInCsv: "説明会", isRequired: true } } as const;
    } else if (onnEvent.type === "new_interview") {
      return {};
    } else {
      const _exhaustiveCheck: never = onnEvent;
      return {};
    }
  })();

  const assigneeAndSubAssignee = (() => {
    if (onnEvent.type === "briefing_session") {
      return {
        assigneeEmail: { labelInCsv: "主担当", isRequired: false },
        subAssigneeEmails: { labelInCsv: "副担当", isRequired: false, multipleColumnCount: 3 },
      } as const;
    } else if (onnEvent.type === "new_interview") {
      return {
        assigneeEmail: { labelInCsv: "選考担当", isRequired: false },
      };
    } else {
      const _exhaustiveCheck: never = onnEvent;
      return {};
    }
  })();

  return {
    slotDate: { labelInCsv: "開催日", isRequired: true },
    ...briefingSession,
    slotStartTime: { labelInCsv: "開催時間（開始）", isRequired: true },
    slotEndTime: { labelInCsv: "開催時間（終了）", isRequired: true },
    capacity: { labelInCsv: "定員", isRequired: true },
    assigneeEmail: { labelInCsv: "選考担当", isRequired: false },
    ...assigneeAndSubAssignee,
    publicationStatus: {
      labelInCsv: "ステータス",
      isRequired: true,
      allowedValues: [publicationStatus.published, publicationStatus.draft],
    },
    slotType: {
      labelInCsv: "開催方法",
      isRequired: true,
      allowedValues: [slotType.online, slotType.offline],
    },
    description: { labelInCsv: "概要", isRequired: true },
    onlineUrl: { labelInCsv: "（オンラインのみ）URL", isRequired: false },
    eventPlaceName: { labelInCsv: "（オフラインのみ）会場名", isRequired: false },
    eventAddressPostCode: { labelInCsv: "（オフラインのみ）会場郵便番号", isRequired: false },
    eventAddressText: { labelInCsv: "（オフラインのみ）会場住所", isRequired: false },
  };
};

// サンプルCSVファイルのデータ

const generateSampleCsvBodyData = (onnEvent: NewInterviewEvent | BriefingSessionEvent) => {
  const briefingSession = (() => {
    if (onnEvent.type === "briefing_session") {
      return { briefingSessionTitle: "説明会A" };
    } else if (onnEvent.type === "new_interview") {
      return {};
    } else {
      const _exhaustiveCheck: never = onnEvent;
      return {};
    }
  })();

  const subAssigneeEmail = (() => {
    if (onnEvent.type === "briefing_session") {
      return {
        subAssigneeEmails: Array(3).fill("sub_admin@example.com"),
      };
    } else if (onnEvent.type === "new_interview") {
      return {};
    } else {
      const _exhaustiveCheck: never = onnEvent;
      return {};
    }
  })();

  return [
    {
      slotDate: format(new Date(), "yyyyMMdd"),
      ...briefingSession,
      slotStartTime: "09:00",
      slotEndTime: "17:00",
      capacity: "10",
      assigneeEmail: "admin@example.com",
      ...subAssigneeEmail,
      publicationStatus: "公開",
      slotType: "オンライン",
      description: onnEvent.slotDefaultValueSetting?.online?.description || "イベント概要",
      onlineUrl: onnEvent.slotDefaultValueSetting?.online?.url || "https://example.com",
      eventPlaceName: "",
      eventAddressPostCode: "",
      eventAddressText: "",
    },
    {
      slotDate: format(new Date(), "yyyyMMdd"),
      ...briefingSession,
      slotStartTime: "09:00",
      slotEndTime: "17:00",
      capacity: "10",
      assigneeEmail: "admin@example.com",
      ...subAssigneeEmail,
      publicationStatus: "公開",
      slotType: "オフライン",
      description: onnEvent.slotDefaultValueSetting?.offline?.description || "イベント概要",
      onlineUrl: "",
      eventPlaceName: "東京会場",
      eventAddressPostCode: "100-0000",
      eventAddressText: "東京都千代田区千代田1-1-1",
    },
  ];
};

export const generateSampleCsvData = (onnEvent: NewInterviewEvent | BriefingSessionEvent) => {
  return [
    [
      ...Object.entries(generateCsvInputValueKeyToInfo(onnEvent)).flatMap(([_, value]) => {
        if (value.multipleColumnCount) {
          return Array(value.multipleColumnCount).fill(value.labelInCsv);
        }
        return value.labelInCsv;
      }),
    ],
    ...generateSampleCsvBodyData(onnEvent).map((value) => [
      ...Object.entries(value).flatMap(([_, value]) => value),
    ]),
  ];
};
