import { UpdateOnnEventAnswerRequest } from "@onn/common";
import { useState } from "react";

import { mutateOnnEventForPortal } from "./useOnnEventForPortal";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export const useUpdateOnnEventAnswer = () => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * イベントの回答を更新する
   */
  const execUpdateOnnEventAnswer = async ({
    onnEventAnswerId,
    answer,
    employeeId,
    onnEventId,
  }: {
    onnEventAnswerId: UpdateOnnEventAnswerRequest["onnEventAnswerId"];
    answer: UpdateOnnEventAnswerRequest["answer"];
    employeeId: string;
    onnEventId: string;
  }) => {
    try {
      const data = UpdateOnnEventAnswerRequest.createRequestBody({
        onnEventAnswerId,
        answer,
      });
      setIsLoading(true);
      await functionOperator.httpsCallFor2ndGen<
        ReturnType<typeof UpdateOnnEventAnswerRequest.createRequestBody>,
        void
      >("onneventanswer", data);
      mutateOnnEventForPortal({ isPreview: false, employeeId, id: onnEventId });
    } finally {
      setIsLoading(false);
    }
  };

  return { execUpdateOnnEventAnswer, isLoading };
};
