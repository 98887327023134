import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent/OnnEvent/schema";

export const createOnnEventRequestSchema = z.object({
  onnEvent: onnEventSchema.omit({ id: true }),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICreateOnnEventRequest extends z.infer<typeof createOnnEventRequestSchema> {}
