import { Employee } from "@onn/common";
import { useCallback, useState } from "react";

import { useSnackbar } from "../shared";

import { useCurrentUser } from "./useCurrentUser";

import { AccountSettingUseCase } from "~/service/usecases/accountSettingUseCase";
import { captureException } from "~/util";

const accountSettingUseCase = new AccountSettingUseCase();
/**
 * Employeeを削除する関数を提供するhooks
 */
export const useDeleteEmployee = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  /**
   * Employeeを削除する
   * @param {string} employeeId
   */
  const deleteEmployee = useCallback(
    async (employee: Employee, employees: Employee[]) => {
      setIsLoading(true);

      const assignedAsMentor = employees.some((em) => em.mentorUserId === employee.id);
      const assignedAsSupportMember = employees
        .flatMap((v) => v.supportMemberEmployeeIds)
        .includes(employee.id);

      if (assignedAsMentor) {
        enqueueSnackbar("バディに割り当てられているユーザーは削除できません", {
          variant: "error",
        });
        throw new Error("バディに割り当てられているユーザーは削除できません");
      } else if (assignedAsSupportMember) {
        enqueueSnackbar("サポートメンバーに割り当てられているユーザーは削除できません", {
          variant: "error",
        });
        throw new Error("サポートメンバーに割り当てられているユーザーは削除できません");
      } else if (currentUser.id === employee.id) {
        enqueueSnackbar("自分自身を削除することはできません", { variant: "error" });
        throw new Error("自分自身を削除することはできません");
      } else {
        await accountSettingUseCase
          .delete(employee.id)
          .then(() => {
            enqueueSnackbar("アカウントを削除しました", { variant: "success" });
          })
          .catch((e) => {
            enqueueSnackbar("アカウントの削除に失敗しました", { variant: "error" });
            captureException({
              error: e as Error,
              tags: { type: "useDeleteEmployee:deleteEmployee" },
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [enqueueSnackbar, currentUser.id]
  );

  return {
    isLoading,
    deleteEmployee,
  };
};
