import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { useMutateScenarios } from "./useScenarios";

import { apiClient } from "~/libs";
import { ApiResponseError } from "~/util";

type EndPoint = APISchema["/scenario_api/scenarios"]["POST"];

export const useCreateScenario = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateScenarios } = useMutateScenarios();

  const createScenario = useCallback(
    async ({ scenarioName }: { scenarioName: string }) => {
      const body: EndPoint["body"] = {
        scenarioName,
      };
      try {
        await apiClient.post("/scenario_api/scenarios", body);
        mutateScenarios();
      } catch (e) {
        if (e instanceof ApiResponseError && e.status === 400) {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("シナリオの作成に失敗しました。管理者までお問い合わせください。", {
            variant: "error",
          });
        }
      }
    },
    [enqueueSnackbar, mutateScenarios]
  );

  return { createScenario };
};
