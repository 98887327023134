import { Box } from "@material-ui/core";
import { AnyActionSetting } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { ActionIcon } from "./ActionIcon";

import { DescriptionAndEndLabel } from "./DescriptionAndEndLabel";

import { Icon, IconButton } from "~/components/uiParts";

type Props = {
  type: AnyActionSetting["type"];
  description: string;
  endLabel?: string;
  isError: boolean;
} & (
  | {
      isEditMode: true;
      onClickEdit: () => void;
      onClickDelete: () => void;
      editButtonRef: React.RefObject<HTMLButtonElement>;
    }
  | {
      isEditMode: false;
      onClickEdit?: undefined;
      onClickDelete?: undefined;
      editButtonRef?: undefined;
    }
);

export const ActionDescription: FC<Props> = ({
  type,
  description,
  endLabel,
  isError,
  isEditMode,
  editButtonRef,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <Root>
      <ActionIconWrapper>
        {isError ? <Icon icon="alert" size="md" color="secondary" /> : <ActionIcon type={type} />}
      </ActionIconWrapper>
      <DescriptionAndEndLabel description={description} endLabel={endLabel} isError={isError} />
      {isEditMode && editButtonRef && (
        <EditAndDeleteButtons
          editButtonRef={editButtonRef}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      )}
    </Root>
  );
};

const EditAndDeleteButtons: FC<{
  editButtonRef: React.RefObject<HTMLButtonElement>;
  onClickEdit: () => void;
  onClickDelete: () => void;
}> = ({ editButtonRef, onClickEdit, onClickDelete }) => {
  return (
    <Box display="flex">
      <IconButton size="md" icon="pencil" onClick={onClickEdit} buttonRef={editButtonRef} />
      <IconButton size="md" icon="trash" onClick={onClickDelete} />
    </Box>
  );
};

const Root = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: space-between;
  width: 100%;
`;

const ActionIconWrapper = styled(Box)`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
