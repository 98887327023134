import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Loading, Typography } from "~/components/uiParts";

// 認証切り替え中のローディング
export const LoadingWhenChangingAuthentication: FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection={"column"}
      alignItems={"center"}
      height="100%"
    >
      <Box>
        <Box mt="40px" mb="16px">
          <Loading size="large" />
        </Box>
        <Typography variant="body1">ログイン方法切り替え中...</Typography>
      </Box>
    </Box>
  );
};
