import { v4 } from "uuid";

import {
  employeeInformationSingleTypeOptionSchema,
  IEmployeeInformationSingleTypeOption,
} from "./schema";

export class EmployeeInformationSingleTypeOption implements IEmployeeInformationSingleTypeOption {
  static validator = employeeInformationSingleTypeOptionSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeInformationSingleSelectTypeFieldId: string;
  readonly label: string;
  readonly order: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<EmployeeInformationSingleTypeOption>) {
    EmployeeInformationSingleTypeOption.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeInformationSingleSelectTypeFieldId =
      init.employeeInformationSingleSelectTypeFieldId;
    this.label = init.label;
    this.order = init.order;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationSingleTypeOption>,
      "id" | "createdAt" | "updatedAt"
    >
  ): EmployeeInformationSingleTypeOption {
    return new EmployeeInformationSingleTypeOption({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(
    params: Pick<EmployeeInformationSingleTypeOption, "label" | "order">
  ): EmployeeInformationSingleTypeOption {
    return new EmployeeInformationSingleTypeOption({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
