import { z } from "zod";

/////////////////////////////////////////
// ACTION SCHEMA
/////////////////////////////////////////

export const ActionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  triggerId: z.string(),
  executionTimeAfterDays: z.number().nullish(),
  executionTimeAtHours: z.number().nullish(),
  executionTimeWithinMinutes: z.number().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Action = z.infer<typeof ActionSchema>;

/////////////////////////////////////////
// ACTION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ActionOptionalDefaultsSchema = ActionSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type ActionOptionalDefaults = z.infer<typeof ActionOptionalDefaultsSchema>;

export default ActionSchema;
