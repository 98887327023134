import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION DATE TYPE FIELD SCHEMA
/////////////////////////////////////////

export const EmployeeInformationDateTypeFieldSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeInformationGroupId: z.string(),
  label: z.string().trim().min(1, { message: "ラベルが短すぎます" }),
  order: z.number(),
  sampleValue: z.string(),
  isCustom: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationDateTypeField = z.infer<
  typeof EmployeeInformationDateTypeFieldSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION DATE TYPE FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationDateTypeFieldOptionalDefaultsSchema =
  EmployeeInformationDateTypeFieldSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationDateTypeFieldOptionalDefaults = z.infer<
  typeof EmployeeInformationDateTypeFieldOptionalDefaultsSchema
>;

export default EmployeeInformationDateTypeFieldSchema;
