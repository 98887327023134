// NOTE: generateLiffUrlStringと組み合わせて使う場合を考慮すると、queryParamsがない方が使いやすいため、新規で追加する場合はqueryParamsは取り除いていく
export const generatePagePath = {
  employee: {
    taskTab: (employeeId: string, queryParams?: Record<string, string>) => {
      const queryString = generateQueryString(queryParams);
      return `/employee/${employeeId}${queryString}#onnTask`;
    },
  },
  onnEvent: {
    create: (queryParams?: Record<string, string>) => {
      const queryString = generateQueryString(queryParams);
      return `/events/new${queryString}`;
    },
    edit: (id: string, queryParams?: Record<string, string>) => {
      const queryString = generateQueryString(queryParams);
      return `/events/${id}/edit${queryString}`;
    },
    deliverySetting: (id: string, queryParams?: Record<string, string>) => {
      const queryString = generateQueryString(queryParams);
      return `/events/${id}/delivery_setting${queryString}`;
    },
    setting: (queryParams?: Record<string, string>) => {
      const queryString = generateQueryString(queryParams);
      return `/events/settings${queryString}`;
    },
    slotDefaultValueSetting: (id: string, queryParams?: Record<string, string>) => {
      const queryString = generateQueryString(queryParams);
      return `/events/${id}/slot_default_value_setting${queryString}`;
    },
  },
  onnTask: {
    detail: (id: string, queryParams?: Record<string, string>) => {
      const queryString = generateQueryString(queryParams);
      return `/onn_tasks/${id}${queryString}`;
    },
  },
  tools: {
    scenarioTab: (queryParams?: Record<string, string>) => {
      const queryString = generateQueryString(queryParams);
      return `/tools${queryString}#scenario`;
    },
  },
  portal: {
    top: () => {
      return `/portal`;
    },
    onnEvent: {
      list: () => {
        return `/portal/events`;
      },
    },
    onnTasks: {
      list: () => {
        return `/portal/onn_tasks`;
      },
    },
    announcements: {
      list: () => {
        return `/portal/announcements`;
      },
    },
    settings: {
      account: () => {
        return `/portal/settings/account`;
      },
    },
  },
  account: {
    register: () => {
      return `/account/register`;
    },
  },
};

const generateQueryString = (queryParams?: Record<string, string>) =>
  queryParams ? `?${new URLSearchParams(queryParams).toString()}` : "";
