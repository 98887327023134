import { v4 } from "uuid";

import { IOnnEventDeliveryCancellationLog, onnEventDeliveryCancellationLogSchema } from "./schema";

export class OnnEventDeliveryCancellationLog implements IOnnEventDeliveryCancellationLog {
  static readonly validator = onnEventDeliveryCancellationLogSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly onnEventId: string;
  readonly employeeId: string;
  readonly createdAt: Date;

  constructor(init: ExcludeMethods<OnnEventDeliveryCancellationLog>) {
    const parsedInit = OnnEventDeliveryCancellationLog.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.onnEventId = parsedInit.onnEventId;
    this.employeeId = parsedInit.employeeId;
    this.createdAt = parsedInit.createdAt;
  }

  public static createNew(
    init: Omit<ExcludeMethods<OnnEventDeliveryCancellationLog>, "id" | "createdAt">
  ): OnnEventDeliveryCancellationLog {
    return new OnnEventDeliveryCancellationLog({
      ...init,
      id: v4(),
      createdAt: new Date(),
    });
  }
}
