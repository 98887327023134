import { Box } from "@material-ui/core";
import {
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEvent,
  OnnEventDeterminedDate,
} from "@onn/common";
import React, { useCallback, useContext, useMemo } from "react";
import { GridCellRenderer, Index } from "react-virtualized";

import { useGenerateRows } from "./useGenerateRows";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

const BODY_CELL_HEIGHT = 72;

const HEADER_HEIGHT_WHEN_EXIST_CAPACITY = 88;

const HEADER_HEIGHT_WHEN_NOT_EXIST_CAPACITY = 60;

export const useAnswerResultTable = ({
  onnEvent,
  onnEventAnswers,
  candidateDateList,
  employeeIdToDeterminedDate,
  hasCapacity,
  numberOfDistribution,
  numberOfResponses,
  employeeIdToLatestEmployeeActiveLogMap,
  selectedCandidateDateIds,
}: {
  onnEvent: OnnEvent;
  candidateDateList: CandidateDateWithNumberOfParticipants[];
  onnEventAnswers: OnnEventAnswerWithEmployee[];
  employeeIdToDeterminedDate: Map<string, OnnEventDeterminedDate>;
  hasCapacity: boolean;
  numberOfDistribution: number;
  numberOfResponses: number;
  employeeIdToLatestEmployeeActiveLogMap: Map<string, EmployeeActiveLog>;
  selectedCandidateDateIds: string[];
}) => {
  const { recruitmentStatuses } = useContext(RecruitmentStatusesContext);
  const { generateRows } = useGenerateRows();

  // NOTE:
  // 開催日時フィルターが何も選択されていない場合は「内定者アイコンの列」＋「回答状況列」＋「候補日の列」＋「参加できる日程がない列」の合計値が列数
  // 開催日時フィルターが選択されている場合は「内定者アイコンの列」＋「回答状況列」＋「選択されている開催日時（参加できる日程がないも含む）の列」の合計値が列数
  const employeeColumnCount = 1;
  const answerStatusColumnCount = 1;
  const candidateDateColumnCount = candidateDateList.length;
  const cannotParticipateColumnCount = 1;
  const columnCount =
    employeeColumnCount +
    answerStatusColumnCount +
    (selectedCandidateDateIds.length === 0
      ? candidateDateColumnCount + cannotParticipateColumnCount
      : selectedCandidateDateIds.length);

  // ヘッダー行の数＋回答者数 の合計値が行数
  const headerRowCount = 1;
  const rowCount = headerRowCount + onnEventAnswers.length;

  // ヘッダーの高さ
  const headerCellHeight = hasCapacity
    ? HEADER_HEIGHT_WHEN_EXIST_CAPACITY
    : HEADER_HEIGHT_WHEN_NOT_EXIST_CAPACITY;

  // 各カラムの幅
  const columnWidth = (params: Index) => (params.index === 0 ? 204 : 148);
  const candidateDateMap = useMemo(
    () => new Map(candidateDateList.map((candidateDate) => [candidateDate.id, candidateDate])),
    [candidateDateList]
  );

  const rows = useMemo(
    () =>
      generateRows({
        onnEvent,
        candidateDateMap,
        candidateDateList,
        employeeIdToDeterminedDate,
        onnEventAnswers,
        hasCapacity,
        numberOfDistribution,
        numberOfResponses,
        selectedCandidateDateIds,
        recruitmentStatuses,
        employeeIdToLatestEmployeeActiveLogMap,
      }),
    [
      generateRows,
      onnEvent,
      candidateDateMap,
      candidateDateList,
      employeeIdToDeterminedDate,
      onnEventAnswers,
      hasCapacity,
      numberOfDistribution,
      numberOfResponses,
      selectedCandidateDateIds,
      recruitmentStatuses,
      employeeIdToLatestEmployeeActiveLogMap,
    ]
  );

  const cellRenderer: GridCellRenderer = useCallback(
    ({ key, style, rowIndex, columnIndex }) => {
      const cell = rows[rowIndex]?.[columnIndex];
      return (
        <Box key={key} style={{ ...style, border: "1px" }}>
          {cell}
        </Box>
      );
    },
    [rows]
  );

  return {
    cellRenderer,
    rowCount,
    columnCount,
    columnWidth,
    headerCellHeight: headerCellHeight,
    bodyCellHeight: BODY_CELL_HEIGHT,
  };
};
