// イベント一覧テーブルの担当者の項目
import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React from "react";

import styled from "styled-components";

import { Typography, Loading, Tooltip } from "~/components/uiParts";

import { UserIcon } from "~/components/uiParts/UserIcon";
import { useEmployees } from "~/hooks/employee";

export const AssigneeCell = ({
  onnEvent,
  onClickAssignButton,
}: {
  onnEvent: OnnEvent;
  onClickAssignButton: (onnEventId: string) => void;
}) => {
  const { data: employees, isLoading } = useEmployees(onnEvent.assigneeIds);
  if (isLoading) return <Loading size="small" fullHeight={false} />;
  if (!employees || employees?.length === 0) return <></>;

  const employee = employees[0] as (typeof employees)[number];

  return (
    <Tooltip arrow placement="bottom" title={"担当者変更を開く"}>
      <StyledClickableBox
        key={onnEvent.id}
        display="flex"
        alignItems="center"
        gridGap={"8px"}
        ml={0}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickAssignButton(onnEvent.id);
        }}
      >
        <UserIcon
          username={employee.getName()}
          profileIconImageUrl={employee.profileIconImageUrl}
          size={"small"}
          circular={true}
        />
        <Box display="grid" gridRowGap="4px">
          <Typography variant={"body2"} color="textPrimary" noWrap>
            {employee.getName()}
          </Typography>
        </Box>
      </StyledClickableBox>
    </Tooltip>
  );
};

const StyledClickableBox = styled(Box)`
  cursor: pointer;
  width: 100%;
`;
