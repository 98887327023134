import { Box, MenuItem } from "@mui/material";
import React, { ComponentProps, FC } from "react";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  Typography,
  UncontrolledMenu,
} from "~/components/uiParts";

export const MultipleSelector: FC<{
  values: string[];
  menuItemOptions: { label: string; value: string }[];
  inputLabel: string;
  onChangeCheckBox: (optionValue: string) => void;
  errorMessage?: string;
  menuProps?: ComponentProps<typeof UncontrolledMenu>["menuProps"];
}> = ({ values, menuItemOptions, inputLabel, onChangeCheckBox, errorMessage, menuProps }) => {
  return (
    <Box width={"100%"}>
      <UncontrolledMenu
        renderButton={(openMenu) => (
          <Button
            color="default"
            variant="outlined"
            borderRadius="regular"
            fullWidth
            onClick={openMenu}
            endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
          >
            <Box width={"100%"} flex={1} display={"flex"}>
              <Typography variant="body2" noWrap>
                {inputLabel}
              </Typography>
            </Box>
          </Button>
        )}
        menuProps={
          menuProps || {
            anchorOrigin: { vertical: "top", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
          }
        }
        menuItemOptions={menuItemOptions.map((option) => ({
          renderCustomComponent: () => {
            return (
              <MenuItem
                style={{
                  padding: 0,
                }}
              >
                <FormControlLabel
                  onChange={() => onChangeCheckBox(option.value)}
                  control={
                    <Checkbox
                      checked={values.includes(option.value)}
                      name={option.label}
                      value={option.value}
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      {option.label}
                    </Typography>
                  }
                  style={{
                    padding: "8px 16px",
                    width: "100%",
                  }}
                />
              </MenuItem>
            );
          },
        }))}
      />
      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
