import React, { FC } from "react";

import { Button, Icon } from "~/components/uiParts";

export const ButtonToAddAction: FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}> = ({ onClick, buttonRef }) => {
  return (
    <Button
      startIcon={<Icon icon="add" size="sm" color="primary" />}
      color={"primary"}
      borderRadius={"regular"}
      variant={"dashedOutlined"}
      fullWidth
      fullHeight
      onClick={onClick}
      buttonRef={buttonRef}
      buttonStyle={{ height: "42px" }}
    >
      アクションを追加
    </Button>
  );
};
