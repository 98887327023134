import { Employee } from "@onn/common";
import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import { uniq } from "lodash";
import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useEnqueueSuccessSnackbar } from "./useEnqueueSuccessSnackbar";

import { useModal } from "~/hooks/modal";
import { mutateOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { useCreateOnnEventAnswerBox } from "~/hooks/onnEvent/useCreateOnnEventAnswerBox";
import { useSnackbar } from "~/hooks/shared";

export const useSubmitButtonHandler = ({
  onnEvent,
  setSelectedNewGraduates,
  selectedNewGraduates,
}: {
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
  selectedNewGraduates: Employee[];
}) => {
  const { handleModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { enqueueSuccessSnackbar } = useEnqueueSuccessSnackbar();

  const navigate = useNavigate();

  const { createNewInterviewAnswerBox } = useCreateOnnEventAnswerBox();
  const nonInvitedEmployees = selectedNewGraduates.filter((employee) =>
    employee.isNotRegisteredAndInvited()
  );
  const isExistNonInvitedUser = nonInvitedEmployees.length > 0;
  const employeeToDeliverIds = uniq(selectedNewGraduates.map((employee) => employee.id));
  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      const _employeeToDeliverIds = [...employeeToDeliverIds];
      setSelectedNewGraduates([]);
      await createNewInterviewAnswerBox({
        onnEventId: onnEvent.id,
        employeeIds: _employeeToDeliverIds,
      });
      enqueueSuccessSnackbar(_employeeToDeliverIds.length, onnEvent);
      mutateOnnEventAnswers(onnEvent.id);
      navigate(`/events/${onnEvent.id}`);
    } catch (_error) {
      enqueueSnackbar("配信対象の追加に失敗しました。管理者より連絡がくるまで、お待ちください", {
        variant: "error",
      });
      navigate(`/events/${onnEvent.id}`);
    } finally {
      setIsLoading(false);
    }
  }, [
    createNewInterviewAnswerBox,
    employeeToDeliverIds,
    enqueueSnackbar,
    enqueueSuccessSnackbar,
    navigate,
    onnEvent,
    setSelectedNewGraduates,
  ]);

  const onClickConfirmSave = useCallback(async () => {
    if (isExistNonInvitedUser) {
      handleModal({
        name: "confirmModal",
        args: {
          title: "確認",
          onClickAccept: onSubmit,
          mainContent: `配信対象に「未招待」の候補者が含まれています。\n配信する場合、候補者は招待中のステータスに切り替わりますがよろしいですか？`,
          acceptLabel: "配信",
        },
      });
    } else {
      await onSubmit();
    }
  }, [handleModal, isExistNonInvitedUser, onSubmit]);

  return { onClickConfirmSave, isLoading };
};
