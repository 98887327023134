import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

export const EventTitleTypography: FC<{ onnEventTitle: string }> = ({ onnEventTitle }) => {
  return (
    <Typography variant="h2" color="textPrimary" style={{ color: "black", fontWeight: 400 }}>
      {onnEventTitle}
    </Typography>
  );
};
