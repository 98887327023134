import { Box } from "@material-ui/core";

import React, { FC } from "react";
import styled from "styled-components";

import { Typography, Paper } from "~/components/uiParts";

import { mixin } from "~/util";

type Props = {
  title: string;
  children: React.ReactNode;
};

export const CenterPaperWithOuterTitle: FC<Props> = ({ title, children }) => {
  return (
    <StyledBox>
      <Typography variant="h2" color="textPrimary" style={{ fontWeight: 700 }}>
        {title}
      </Typography>
      <StyledPaper>{children}</StyledPaper>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;
  width: 100%;

  ${mixin.breakUp.sm`
    background: none;
    max-width: 550px;
  `}

  ${mixin.portalSp`
    padding: 40px 24px;
    width: 100%;
  `}
`;

const StyledPaper = styled(Paper)`
  margin-top: 28px;
  padding: 80px 40px;

  ${mixin.breakDown.sm`
    margin-top: 0px;
    padding: 40px 0px;
  `}

    &.MuiPaper-root {
    box-shadow: 0 0 10px #0000000d;


    ${(props) => mixin.breakDown.sm`
      box-shadow: none;
      background: ${props.theme.palette.grey[50]};`}
`;
