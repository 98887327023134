import React, { FC } from "react";

import { useNavigate } from "react-router-dom";

import { CellItem, StyleProps } from "./CellItem";

import { Button, Icon, Typography } from "~/components/uiParts";

export const TextCell: FC<
  {
    text: string | null;
    linkPath?: string;
    onCancel: () => void;
  } & StyleProps
> = ({ text, linkPath, onCancel, ...styleProps }) => {
  const navigate = useNavigate();

  if (!text) {
    return (
      <CellItem {...styleProps}>
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      </CellItem>
    );
  }

  return (
    <CellItem {...styleProps}>
      {linkPath ? (
        <Button
          color="primary"
          variant="text"
          borderRadius="regular"
          onClick={() => {
            navigate(linkPath);
            onCancel();
          }}
        >
          <Typography variant="body2" color="primary">
            {text}
          </Typography>
        </Button>
      ) : (
        <Typography variant="body2" color="textSecondary">
          {text}
        </Typography>
      )}
    </CellItem>
  );
};
