import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Typography, FormControlLabel, Checkbox } from "~/components/uiParts";

export const Row = ({
  label,
  onClick,
  isSelected,
}: {
  label: string;
  onClick: () => void;
  isSelected: boolean;
}) => {
  return (
    <Box>
      <StyledFormControlLabel
        control={<Checkbox onClick={onClick} checked={isSelected} />}
        label={
          <Box width="100%" display="flex">
            <StyledTypography variant="body2" noWrap>
              {label}
            </StyledTypography>
          </Box>
        }
      />
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
    height: 24px;
    margin-bottom: 16px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiCheckbox-root {
    padding: 11.5px 0px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    padding: 0;
  }
`;
