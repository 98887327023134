import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CsvDLMenuItem } from "./CsvDLMenuItem";

import { ZipDLMenuItem } from "./ZipDLMenuItem";

import { IconButton, Loading, Typography } from "~/components/uiParts";
import { useEmployee } from "~/hooks/employee";

import { useModal } from "~/hooks/modal";
import { useRemindOnnFormTaskAnswer } from "~/hooks/onnFormTaskAnswer";
import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";
import { useFormRevisions } from "~/hooks/onnTask";
import { useDeleteOnnTask } from "~/hooks/onnTask/useDeleteOnnTask";

type Props = {
  taskId: string;
  assigneeId: string;
  taskName: string;
  tenantId: string;
  isExceededScheduledDate: boolean;
  isExceededDeadlineDate: boolean;
  curriedOnClickZipDL: ({
    onnFormTaskId,
    onnFormTaskTitle,
    handleCloseMenu,
  }: {
    onnFormTaskId: string;
    onnFormTaskTitle: string;
    handleCloseMenu: () => void;
  }) => (e: React.MouseEvent<HTMLElement>) => Promise<void>;
};

export const OnnTaskManageCell: FC<Props> = ({
  taskId,
  assigneeId,
  taskName,
  tenantId,
  isExceededScheduledDate,
  isExceededDeadlineDate,
  curriedOnClickZipDL,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigation = useNavigate();
  const { handleModal } = useModal();
  const { data: assignee } = useEmployee(assigneeId);
  const { deleteOnnTask } = useDeleteOnnTask(tenantId);
  const { execRemindOnnFormTaskAnswer } = useRemindOnnFormTaskAnswer();

  const { data: onnFormTaskAnswers, isLoading: isLoadingOFTAs } =
    useOnnFormTasksAnswersOnlyCountable({
      onnTaskId: taskId,
    });
  const { data: formRevisionsData, isLoading: isLoadingFormRevisions } = useFormRevisions({
    onnFormTaskId: taskId,
  });

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // 画面遷移を防ぐpreventDefaultとstopPropagation,メニューを閉じるsetStateをひとまとめに実行した後に目的のcallbackを実行する
  const handleClickHF = useCallback(
    (e: MouseEvent<HTMLElement>, callback: () => void) => {
      e.stopPropagation();
      e.preventDefault();
      closeMenu();
      callback();
    },
    [closeMenu]
  );

  const isShowBulkRemindMenuItem = useMemo(
    () =>
      isExceededScheduledDate &&
      !isExceededDeadlineDate &&
      onnFormTaskAnswers &&
      onnFormTaskAnswers.length > 0,
    [isExceededDeadlineDate, isExceededScheduledDate, onnFormTaskAnswers]
  );

  const isShowCsvDLMenuItem = useMemo(
    () => isExceededScheduledDate && onnFormTaskAnswers && onnFormTaskAnswers.length > 0,
    [isExceededScheduledDate, onnFormTaskAnswers]
  );

  const isShowZipDLMenuItem = useMemo(
    () =>
      isExceededScheduledDate &&
      onnFormTaskAnswers &&
      onnFormTaskAnswers.some((taskAnswer) =>
        taskAnswer.answers.some((answer) => answer.type === "FILE" && answer.filePath)
      ),
    [isExceededScheduledDate, onnFormTaskAnswers]
  );

  const menuOptions = useMemo(() => {
    return [
      {
        id: "edit",
        title: "編集",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => {
            navigation(`/onn_tasks/${taskId}/edit?from_page=list`);
          }),
      },
      {
        id: "delivery_setting",
        title: "配信設定",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => {
            navigation(`/onn_tasks/${taskId}/delivery_setting?from_page=list`);
          }),
      },
      isShowBulkRemindMenuItem
        ? {
            id: "bulk_remind",
            title: "一括リマインド",
            func: (e: MouseEvent<HTMLElement>) =>
              handleClickHF(e, () => {
                handleModal({
                  name: "onnTaskBulkRemindModal",
                  args: {
                    onSubmit: () => execRemindOnnFormTaskAnswer({ onnTaskId: taskId }),
                  },
                });
              }),
          }
        : [],
      isShowCsvDLMenuItem
        ? {
            id: "csv",
            title: "CSVダウンロード",
            func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => 0),
          }
        : [],
      isShowZipDLMenuItem
        ? {
            id: "zip",
            title: "zipダウンロード",
            func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => 0),
          }
        : [],
      {
        id: "delete",
        title: "削除",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => {
            handleModal({
              name: "deleteOnnTaskModal",
              args: {
                taskName,
                username: assignee?.getName() ?? "",
                profileIconImageUrl: assignee?.profileIconImageUrl ?? "",
                onSubmit: () => deleteOnnTask({ id: taskId }),
              },
            });
          }),
      },
    ].flat();
  }, [
    isShowBulkRemindMenuItem,
    isShowCsvDLMenuItem,
    isShowZipDLMenuItem,
    handleClickHF,
    navigation,
    taskId,
    handleModal,
    execRemindOnnFormTaskAnswer,
    taskName,
    assignee,
    deleteOnnTask,
  ]);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      closeMenu();
    },
    [closeMenu]
  );

  return (
    <Box>
      <IconButton icon="menuVert" onClick={handleOpenMenu} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuOptions.map((option) => {
          if (option.id === "csv") {
            if (isLoadingOFTAs || isLoadingFormRevisions) {
              return <Loading key={option.id} size="small" fullHeight />;
            }
            return (
              <CsvDLMenuItem
                key={option.id}
                onnFormTaskAnswers={onnFormTaskAnswers || []}
                taskTitle={taskName}
                formRevisions={formRevisionsData?.formRevisions || []}
              />
            );
          }
          if (option.id === "zip") {
            return (
              <ZipDLMenuItem
                key={option.id}
                onClickZipDL={curriedOnClickZipDL({
                  onnFormTaskId: taskId,
                  onnFormTaskTitle: taskName,
                  handleCloseMenu: closeMenu,
                })}
              />
            );
          }
          return (
            <MenuItem key={option.title} onClick={option.func}>
              <Typography variant="body2">{option.title}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
