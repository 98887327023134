import { Box } from "@material-ui/core";
import { Announcement } from "@onn/common";
import React from "react";

import styled from "styled-components";

import { AnnouncementContentContainer } from "./AnnouncementContentContainer";
import { TargetNewGraduateContainer } from "./TargetNewGraduateContainer";

type Props = {
  announcement: Announcement;
};

export const AnnouncementContents = ({ announcement }: Props): JSX.Element => {
  return (
    <StyledBox display="flex" alignItems="flex-start" mt="40px">
      <AnnouncementContentContainer announcement={announcement} />
      <TargetNewGraduateContainer announcement={announcement} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
`;
