import { Checkbox as MuiCheckbox } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Checked } from "./icons/Checked";
import { Indeterminate } from "./icons/Indeterminate";
import { Unchecked } from "./icons/Unchecked";

type CustomProps = {
  disableEffect?: boolean;
};

type Props = ComponentProps<typeof MuiCheckbox> & CustomProps;

// NOTE: なぜかref型がエラーになるため、anyで回避
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Checkbox: FC<Props & { ref?: any }> = React.forwardRef<HTMLButtonElement, Props>(
  ({ disableEffect, disabled, indeterminate, ...rest }, ref) => {
    return (
      <StyledMuiCheckbox
        ref={ref}
        color="primary"
        icon={<Unchecked disabled={disabled} />}
        checkedIcon={<Checked disabled={disabled} />}
        indeterminateIcon={<Indeterminate disabled={disabled} />}
        indeterminate={indeterminate}
        disableRipple={disableEffect}
        disabled={disabled}
        readOnly={disableEffect}
        {...rest}
      />
    );
  }
);

const StyledMuiCheckbox = styled(MuiCheckbox)`
  &.MuiCheckbox-root {
    ${(props) =>
      props.readOnly &&
      `
        cursor: default;
        background-color: transparent !important;
      `}
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .MuiIconButton-label {
    background: white;
  }
`;
