import { useMemo } from "react";

import { useAllNewcomers } from "~/hooks/employee";

function useCandidates({
  canUseNotRegistered,
  canUseNotInvited,
}: {
  canUseNotRegistered: boolean;
  canUseNotInvited: boolean;
}) {
  const { data: allNewGraduates } = useAllNewcomers();

  const shouldExcludeNotInvited = !canUseNotInvited;
  const shouldExcludeNotRegistered = !canUseNotRegistered;
  const candidates = useMemo(() => {
    return allNewGraduates?.filter((newGraduate) => {
      //  条件によってはチェックをスルー
      if (shouldExcludeNotInvited && newGraduate.isNotRegisteredAndInvited()) return false;
      if (shouldExcludeNotRegistered && !newGraduate.isRegistered()) return false;
      // 常にチェック
      if (!canUseNotRegistered && !newGraduate.isRegistered()) return false;
      if (!newGraduate.canNotifyWithEmail()) return false;

      return true;
    });
  }, [allNewGraduates, canUseNotRegistered, shouldExcludeNotInvited, shouldExcludeNotRegistered]);

  return { candidates };
}

const csvHeader = ["姓", "名", "メールアドレス"] as const;

export function useCandidatesCsvData({
  canUseNotRegistered,
  canUseNotInvited,
}: {
  canUseNotRegistered: boolean;
  canUseNotInvited: boolean;
}) {
  const { candidates } = useCandidates({ canUseNotRegistered, canUseNotInvited });

  const records = candidates?.map((candidate) => {
    return [candidate.lastName, candidate.firstName, candidate.email];
  });

  return {
    data: [csvHeader, ...(records ?? [])],
  };
}
