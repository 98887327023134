import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { FC, useCallback } from "react";

import { NoneCell } from "../../common/NoneCell";

import { Cell } from "./Cell";

import {
  Icon,
  TooltipWhenTextTruncatedOneLine,
  Button,
  Typography,
  ScrollableBodyModal,
} from "~/components/uiParts";
import { useFileViewer } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

type File = { path: string; name: string };

export const RelatedFilesCell: FC<{
  filePaths?: string[];
}> = ({ filePaths }) => {
  const { setFiles, setPreviewFileIndex } = useFileViewer();
  const [openFileListModal, setOpenFileListModal] = React.useState(false);
  const files = filePaths?.map((path) => ({
    path,
    name: path.split("/").pop() || "",
  }));

  const onClickFile = useCallback(
    async (file: File) => {
      const fileUrl = await fileAPIAdapter.fetchUrl({ path: file.path });
      if (fileUrl) {
        setFiles([{ name: file.name, url: fileUrl, size: 100 }]);
        setPreviewFileIndex(0);
      } else {
        captureException({
          error: new Error("ファイルが取得できませんでした"),
          tags: { type: "RelatedFilesCell" },
          extras: { filePath: file.path },
        });
      }
    },
    [setFiles, setPreviewFileIndex]
  );

  if (!files || isEmpty(files)) {
    return <NoneCell />;
  }

  return (
    <>
      <Cell>
        {files.length > 2 ? (
          <Button
            color="primary"
            variant="text"
            borderRadius="regular"
            startIcon={<Icon icon="attachmentFile" color="primary" size="md" />}
            onClick={() => setOpenFileListModal(true)}
          >
            <Typography variant="body2" color="primary" bold>
              {files.length}
            </Typography>
          </Button>
        ) : (
          <FileList files={files} onClickFile={onClickFile} />
        )}
      </Cell>
      <ScrollableBodyModal
        open={openFileListModal}
        title={"関連ファイル"}
        content={<FileList files={files} onClickFile={onClickFile} />}
        onCancel={() => setOpenFileListModal(false)}
        fullWidth
        disableBackdropModal
      />
    </>
  );
};

const FileList: FC<{ files: File[]; onClickFile: (files: File) => void }> = ({
  files,
  onClickFile,
}) => {
  return (files || []).map((file, index) => (
    <Box
      key={index}
      px="10px"
      width="100%"
      height={"24px"}
      onClick={() => onClickFile(file)}
      style={{ cursor: "pointer" }}
    >
      <TooltipWhenTextTruncatedOneLine
        text={file.name}
        typographyProps={{
          variant: "body2",
          color: "primary",
          style: {
            textDecoration: "underline",
          },
        }}
      />
    </Box>
  ));
};
