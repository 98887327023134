import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { FC } from "react";

import { CreateEventButton } from "../common/components/CreateEventButton";

import { EventsListTable } from "./components/EventsListTable/EventsListTable";

import { useFilterByAssignees, useFilterByEventName, useHooks } from "./hooks";

import { EmployeeFilter } from "~/components/domains/employees";
import { Typography, SearchForm } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

export const NormalEventTabPage: FC<{ allNormalOnnEvents: OnnEvent[] }> = ({
  allNormalOnnEvents,
}) => {
  const { currentUser } = useCurrentUser();
  const allOnnEvents = allNormalOnnEvents;
  /**
   * 「担当者」のフィルタリング
   */
  const { selectedAssigneeIds, handleChangeAssigneeFilter, onnEventsFilteredByAssignees } =
    useFilterByAssignees(allOnnEvents ?? []);

  /**
   * 「イベント名」のフィルタリング
   */
  const {
    eventName,
    handleChangeEventName,
    onnEventsFilteredByEventName: displayOnnEvents,
  } = useFilterByEventName(onnEventsFilteredByAssignees);

  /**
   * それ以外のロジック
   */
  const { handleClickAssignButton } = useHooks(allOnnEvents ?? []);

  // 「担当者」の選択肢
  const selectableAssigneesIds = [...new Set((allOnnEvents ?? []).flatMap((v) => v.assigneeIds))];

  return (
    <>
      <Box>
        {currentUser.isAdmin() && (
          <Box mb="24px" mt="24px" display="flex" justifyContent="space-between" gridGap="16px">
            <Box mb="8px" height="72px" flex="0 1 240px">
              <Box height="30px">
                <Typography variant="body2" bold color="textSecondary">
                  担当者
                </Typography>
              </Box>
              <Box height="46px">
                <EmployeeFilter
                  selectedEmployeeIds={selectedAssigneeIds}
                  selectableEmployeeIds={selectableAssigneesIds}
                  onChange={handleChangeAssigneeFilter}
                />
              </Box>
            </Box>

            <Box display="flex" alignItems="flex-end" gridGap="32px">
              <Box pb="8px">
                <SearchForm
                  searchValue={eventName}
                  variant="standard"
                  onSearchValue={handleChangeEventName}
                  placeholder="イベント名で検索"
                />
              </Box>
              <Box height="40px">
                <CreateEventButton eventType="normal" />
              </Box>
              {/* NOTE:初回リリースではテーブル外のメニュー実装しない */}
              {/* <IconButton icon="menuVert" onClick={() => 0} /> */}
              {/* <Menu
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem onClick={handleClickedMenuItem}>
                <Typography variant="body2">{"CSVアップロード"}</Typography>
              </MenuItem>
            </Menu> */}
            </Box>
          </Box>
        )}
      </Box>
      <EventsListTable
        currentUser={currentUser}
        onnEvents={displayOnnEvents}
        onClickAssignButton={handleClickAssignButton}
      />
    </>
  );
};
