import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React, { FC } from "react";
import styled from "styled-components";

export const headers: Array<{ label: string; width: number }> = [
  { label: "履歴", width: 200 },
  { label: "ステータス", width: 160 },
  { label: "日程", width: 200 },
  { label: "担当者", width: 120 },
  { label: "評価", width: 160 },
  { label: "関連ファイル", width: 200 },
  { label: "関連情報", width: 200 },
  { label: "更新日時", width: 200 },
];

export const TableHeaderRow: FC = () => {
  return (
    <TableRow>
      {headers.map((v, index) => (
        <HeaderCell key={index} text={v.label} width={v.width} />
      ))}
    </TableRow>
  );
};

export const HeaderCell: FC<{
  text: string;
  width: number;
}> = ({ text, width }) => {
  return (
    <StyledTableCell style={{ width }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2">{text}</Typography>
      </Box>
    </StyledTableCell>
  );
};

export const StyledTableCell = styled(TableCell)`
  padding: 16px 0px;
  background-color: ${(props) => props.theme.palette.grey[50]};
  text-align: center;
`;
