import {
  Action,
  AnyActionConditionExcludeMethods,
  AnyActionSettingExcludeMethods,
} from "@onn/common";
import { useCallback, useState } from "react";

import { ActionType } from "../components/ActionPopovers/inputStateSchema";

import { usePopover } from "~/components/uiParts/Popover";

type ActionResource = {
  action: ExcludeMethods<Action>;
  actionSetting: AnyActionSettingExcludeMethods;
  actionCondition?: AnyActionConditionExcludeMethods;
};
export const usePopoverOfActionSetting = () => {
  const [openedActionType, setOpenedActionType] = useState<ActionType | undefined>(undefined);
  const [existingResource, setExistingResource] = useState<ActionResource | undefined>(undefined);

  const popover = usePopover();

  const open = useCallback(
    (actionType: ActionType, resource?: ActionResource) => {
      setOpenedActionType(actionType);
      setExistingResource(resource);
      popover.openMenu();
    },
    [popover]
  );

  const close = useCallback(() => {
    setOpenedActionType(undefined);
    setExistingResource(undefined);
    popover.closeMenu();
  }, [popover]);

  const backToSelectActionType = useCallback(() => {
    popover.closeMenu();
  }, [popover]);

  return {
    openedActionType,
    open,
    close,
    isOpen: popover.isOpen,
    anchorEl: popover.anchorEl,
    existingResource,
    backToSelectActionType,
  };
};
