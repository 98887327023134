import { z } from "zod";

import { onnEventSchema } from "../../../../domain/OnnEvent/OnnEvent/schema";

export const listOnnEventsRequestSchema = z.object({
  tenantId: onnEventSchema.shape.tenantId,
  isExpired: z.boolean().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IListOnnEventsRequestSchema extends z.infer<typeof listOnnEventsRequestSchema> {}
