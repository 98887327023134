import { z } from "zod";

import {
  ChangeRecruitmentStatusActionSettingSchema,
  DeliverOnnEventActionSettingSchema,
  DeliverOnnTaskActionSettingSchema,
  SendContactMessageActionSettingSchema,
} from "../../_gen/zodSchema/index";

export const sendContactMessageActionSettingSchema = SendContactMessageActionSettingSchema;

export const changeRecruitmentStatusActionSettingSchema =
  ChangeRecruitmentStatusActionSettingSchema;

export const deliverOnnEventActionSettingSchema = DeliverOnnEventActionSettingSchema;

export const deliverOnnTaskActionSettingSchema = DeliverOnnTaskActionSettingSchema;

// typeがついている型のスキーマをunionでまとめる
export const anyActionSettingWithTypeSchema = z.union([
  sendContactMessageActionSettingSchema.merge(
    z.object({
      type: z.literal("SendContactMessageActionSetting"),
    })
  ),
  changeRecruitmentStatusActionSettingSchema.merge(
    z.object({
      type: z.literal("ChangeRecruitmentStatusActionSetting"),
    })
  ),
  deliverOnnEventActionSettingSchema.merge(
    z.object({
      type: z.literal("DeliverOnnEventActionSetting"),
    })
  ),
  deliverOnnTaskActionSettingSchema.merge(
    z.object({
      type: z.literal("DeliverOnnTaskActionSetting"),
    })
  ),
]);
