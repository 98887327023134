import { Box } from "@material-ui/core";
import { EmailType, LineType } from "@onn/common/domain/Employee/AuthenticationType";
import React from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Icon, Tooltip, Typography } from "~/components/uiParts";

export const AuthenticationFlowTypeTabPanel = ({
  selectedAuthenticationFlowTypes,
  addSelectedAuthenticationFlowTypes,
  removeSelectedAuthenticationFlowTypes,
}: {
  selectedAuthenticationFlowTypes: (LineType | EmailType)[];
  addSelectedAuthenticationFlowTypes: (authenticationFlowTypes: ["email"]) => void;
  removeSelectedAuthenticationFlowTypes: (
    authenticationFlowTypes: (LineType | EmailType)[]
  ) => void;
}) => {
  const onChangeEmailType = () => {
    if (selectedAuthenticationFlowTypes.includes("email")) {
      removeSelectedAuthenticationFlowTypes(["email"]);
    } else {
      addSelectedAuthenticationFlowTypes(["email"]);
    }
  };

  return (
    <StyledBox py={"24px"} height={"300px"}>
      <StyledFormControlLabel
        control={<Checkbox checked disabled />}
        label={
          <Box width="100%" display="flex" alignItems={"center"}>
            <StyledTypography variant="body2" noWrap>
              {"LINE登録"}
            </StyledTypography>
            <Box ml="12px" display="flex" alignItems={"center"}>
              <Tooltip title={"LINE登録は必須項目です。"} placement="top-start">
                <Icon icon="info" color="grey" size="sm" />
              </Tooltip>
            </Box>
          </Box>
        }
      />

      <StyledFormControlLabel
        control={
          <Checkbox
            onClick={onChangeEmailType}
            checked={selectedAuthenticationFlowTypes.includes("email")}
          />
        }
        label={
          <Box width="100%" display="flex">
            <StyledTypography variant="body2" noWrap>
              {"メールアドレス登録"}
            </StyledTypography>
          </Box>
        }
      />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  overflow-y: scroll;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
    height: 24px;
    margin-bottom: 16px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
  .MuiCheckbox-root {
    padding: 0px 0px;
    margin: 11.5px 0px;
    color: #000000;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    padding: 0;
  }
`;
