import { v4 } from "uuid";

import { Trigger as ITrigger } from "../../_gen/zodSchema/index";

import { triggerSchema } from "./schema";

export class Trigger implements ITrigger {
  static readonly validator = triggerSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  recruitmentStatusId: string;
  parentId?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<Trigger>) {
    const parsedInit = Trigger.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;
    this.parentId = parsedInit.parentId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public isRoot(): boolean {
    return !this.parentId;
  }

  static create(params: Omit<ExcludeMethods<Trigger>, "id" | "createdAt" | "updatedAt">) {
    return new Trigger({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
