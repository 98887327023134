import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";
import QRCode from "react-qr-code";
import styled from "styled-components";

import { HelpAccordionForOpenLiff } from "~/components/domains/lineUsers/HelpAccordionForOpenLiff";

import { Button, Icon, Typography } from "~/components/uiParts";

import { CenterPaperWithOuterTitle } from "~/components/uiParts/CenterPaperWithOuterTitle";
import { useClipboard } from "~/hooks/shared";
import { useDownloadSvg } from "~/hooks/useDownloadSvg";
import { mixin } from "~/util";

type Props = {
  urlForQRCode: string;
  startLineRegistration: () => void;
};

export const QRCodePage: FC<Props> = ({ urlForQRCode, startLineRegistration }) => {
  const { divRef: qrCodeDivRef, downloadSvg } = useDownloadSvg("LINE友だち追加");
  const { handleCopy } = useClipboard();

  const handleClickCopyButton = useCallback(() => {
    handleCopy(urlForQRCode, "招待URL");
  }, [handleCopy, urlForQRCode]);

  return (
    <StyledBox>
      <CenterPaperWithOuterTitle title="LINE友だち追加">
        <Box display="flex" flexDirection="column" alignItems="center" gridGap="40px">
          <Box display="flex" flexDirection="column" alignItems="center" gridGap="24px">
            <Box
              style={{ background: "white" }}
              padding="12px"
              borderRadius="5px"
              {...{ ref: qrCodeDivRef }}
            >
              <QRCode style={{ height: "146px", width: "146px" }} value={urlForQRCode} />
            </Box>
            <Typography variant="body1" color="textPrimary" align="center">
              スマートフォンからQRコードをスキャンしてアカウント登録を完了させましょう
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gridGap="16px"
            width="100%"
          >
            <Button
              variant="outlined"
              color="primary"
              borderRadius="circle"
              fullWidth
              onClick={startLineRegistration}
            >
              LINE友だち追加
            </Button>
            <Button
              variant="text"
              color="primary"
              borderRadius="circle"
              fullWidth
              onClick={handleClickCopyButton}
              startIcon={<Icon icon="link" size="sm" color="primary" />}
            >
              URLをコピー
            </Button>
          </Box>
          <StyledAccordionWrapper>
            <HelpAccordionForOpenLiff downloadQRcode={downloadSvg} buttonText="LINE友だち追加" />
          </StyledAccordionWrapper>
        </Box>
      </CenterPaperWithOuterTitle>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledAccordionWrapper = styled(Box)`
  ${mixin.breakUp.sm`
    .MuiPaper-root {
      box-shadow: none;
    }
  `}
`;
