import { z } from "zod";

import { ActionOrderSchema, ActionSettingsSchema } from "../../../_gen/zodSchema/index";

export const actionOrderSchema = ActionOrderSchema.omit({
  type: true,
  recruitmentStatusId: true,
  onnEventId: true,
  onnTaskId: true,
  text: true,
  filePaths: true,
}).extend({
  cloudTasksTaskName: z.string().optional(),
  actionSetting: ActionSettingsSchema.pick({
    type: true,
    recruitmentStatusId: true,
    onnEventId: true,
    onnTaskId: true,
    text: true,
    filePaths: true,
  }),
});
