import { Box, Chip as MuiChip } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

import theme from "~/config/theme";

export const AnswerStatusCell: FC<{
  isAnswered: boolean;
  lastReadAt: Date | null;
}> = ({ isAnswered, lastReadAt }) => {
  if (isAnswered) {
    return (
      <CellWrapper bgcolor={theme.palette.primary.light} p={"1px"}>
        <StyledChip color="primary" label={"回答済み"} />
      </CellWrapper>
    );
  } else {
    if (lastReadAt) {
      const label = format(lastReadAt, "yyyy/MM/dd 既読");
      return (
        <CellWrapper p={"1px"}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
        </CellWrapper>
      );
    } else {
      // TODO: デザインに揃える
      return (
        <CellWrapper bgcolor={theme.palette.info} p={"1px"}>
          <Typography variant="body2" color="textSecondary">
            -
          </Typography>
        </CellWrapper>
      );
    }
  }
};

const CellWrapper = styled(Box)<{ bgcolor?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px 1px 0px 0px;
`;

const StyledChip = styled(MuiChip)`
  &.MuiChip-root {
    height: 32px;
    font-size: 14px;
    background-color: white;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 700;
  }
`;
