import { z } from "zod";

export const OnnEventNotificationStatusSchema = z.enum([
  "WAITING",
  "SENDING",
  "FAILED",
  "CANCELLED",
]);

export type OnnEventNotificationStatusType = `${z.infer<typeof OnnEventNotificationStatusSchema>}`;

export default OnnEventNotificationStatusSchema;
