import { v4 } from "uuid";

import { EmailType, LineType } from "../Employee/AuthenticationType";

import { IRegistrationInvitationLinkSchema, registrationInvitationLinkSchema } from "./schema";

export class RegistrationInvitationLink implements IRegistrationInvitationLinkSchema {
  static readonly validator = registrationInvitationLinkSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  isDefault: boolean;
  recruitmentStatusId: string;
  expiredAt: Date;
  selectableAuthenticationFlowTypes: [LineType] | [LineType, EmailType];
  tagIds?: string[];
  onnEventIds?: string[];
  onnTaskIds?: string[];
  creatorEmployeeId?: string;
  createdAt: Date;

  constructor(init: ExcludeMethods<RegistrationInvitationLink>) {
    const parsedInit = RegistrationInvitationLink.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.isDefault = parsedInit.isDefault;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;
    this.expiredAt = parsedInit.expiredAt;
    this.selectableAuthenticationFlowTypes = parsedInit.selectableAuthenticationFlowTypes;
    this.tagIds = parsedInit.tagIds;
    this.onnEventIds = parsedInit.onnEventIds;
    this.onnTaskIds = parsedInit.onnTaskIds;
    this.creatorEmployeeId = parsedInit.creatorEmployeeId;
    this.createdAt = parsedInit.createdAt;
  }

  static createDefault(
    init: Pick<
      ExcludeMethods<RegistrationInvitationLink>,
      "tenantId" | "spaceId" | "recruitmentStatusId" | "selectableAuthenticationFlowTypes"
    >
  ) {
    const parsedInit = RegistrationInvitationLink.validator.parse({
      ...init,
      id: v4(),
      isDefault: true,
      expiredAt: new Date("2030-01-01"),
      createdAt: new Date(),
    });

    return new RegistrationInvitationLink(parsedInit);
  }

  /**
   * 有効期限が切れているかどうかを返す
   * @returns boolean
   */
  public isExpired(): boolean {
    return this.expiredAt < new Date();
  }

  public canAuthenticateByEmail(): boolean {
    const [_lineType, emailType] = this.selectableAuthenticationFlowTypes;
    return emailType === "email";
  }
}
