import { Box } from "@mui/material";
import { Employee, FormRevision, OnnFormTask, OnnFormTaskAnswer } from "@onn/common";
import React, { FC, useEffect } from "react";

import { Content } from "./components/Content";
import { useForm } from "./useForm";

import { CustomRHFProvider } from "~/components/providers/CustomRHFProvider";
import { Loading, ScrollableBodyModal, Typography } from "~/components/uiParts";
import { useOnnFormTasksAnswerForAdmin } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswerForAdmin";
import { useFormRevisions } from "~/hooks/onnTask";
import { captureException } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnTaskId: string;
  employeeId: string;
  mode: "VIEW";
};

export const AnswerOnnTaskOnBehalfModal: FC<Props> = (props) => {
  const { relatedData, isLoading } = useRelatedData({
    onnTaskId: props.onnTaskId,
    employeeId: props.employeeId,
  });

  return (
    <ScrollableBodyModal
      open={props.open}
      title={"回答確認"}
      content={
        isLoading ? (
          <Loading size="large" fullHeight />
        ) : relatedData ? (
          <ModalContent relatedData={relatedData} />
        ) : (
          // NOTE: 想定外のfetch結果なのでエラー表示してcaptureExceptionで通知する
          <ErrorContent />
        )
      }
      onCancel={props.onCancel}
      fullWidth
    />
  );
};

const ModalContent: FC<{
  relatedData: {
    onnTask: OnnFormTask;
    onnFormTaskAnswer: OnnFormTaskAnswer;
    formRevision: FormRevision;
    newGraduate: Employee;
  };
}> = ({ relatedData: { formRevision, newGraduate, onnFormTaskAnswer } }) => {
  const form = useForm({
    formRevision,
    onnFormTaskAnswer,
  });
  return (
    <CustomRHFProvider {...form}>
      <Content formRevision={formRevision} employee={newGraduate} />
    </CustomRHFProvider>
  );
};

// NOTE: 関連データを取得する
const useRelatedData = ({ onnTaskId, employeeId }: { onnTaskId: string; employeeId: string }) => {
  const { data: answersData, isLoading: isLoadingData } = useOnnFormTasksAnswerForAdmin({
    newGraduateId: employeeId,
    onnTaskId: onnTaskId,
  });
  const { data: latestFormRevisionData, isLoading: isLoadingFormRevision } = useFormRevisions({
    onnFormTaskId: onnTaskId,
    isOnlyLatest: true,
  });
  const isLoading = isLoadingData || isLoadingFormRevision;

  // NOTE: 候補者が未回答の場合は、最新のフォームリビジョンを取得する
  const formRevision = answersData?.relatedFormRevision || latestFormRevisionData?.formRevisions[0];

  const relatedData =
    answersData && formRevision
      ? {
          onnTask: answersData.onnTask,
          onnFormTaskAnswer: answersData.onnFormTaskAnswer,
          formRevision,
          newGraduate: answersData.newGraduate,
        }
      : null;

  return {
    isLoading,
    relatedData,
  };
};

const ErrorContent: FC = () => {
  useEffect(() => {
    captureException({
      error: new Error("タスク代理回答/結果確認モーダルでエラーが発生しました"),
      tags: {
        type: "AnswerOnnTaskOnBehalfModal:ErrorContent",
      },
    });
  }, []);

  return (
    <Box>
      <Typography>
        エラーが発生しました。申し訳ありませんが、担当者までお問い合わせください。
      </Typography>
    </Box>
  );
};
