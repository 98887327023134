import { postEmployeeInformationFieldsRequestSchema } from "@onn/common";
import { useCallback } from "react";
import { z } from "zod";

import { apiClient } from "~/libs";

export const useCreateEmployeeInformationField = () => {
  const execCreateEmployeeInformationField = useCallback(
    async (data: z.infer<typeof postEmployeeInformationFieldsRequestSchema>): Promise<void> => {
      await apiClient.post("/api/employee-information-fields", data);
    },
    []
  );

  return { execCreateEmployeeInformationField };
};
