import { Box } from "@material-ui/core";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { FC, Fragment, memo } from "react";
import styled from "styled-components";

import { Chip } from "~/components/domains/onnEvents";
import { Typography } from "~/components/uiParts";

export const CandidateDateHeaderCell: FC<{
  hasCapacity: boolean;
  capacity?: number;
  numberOfParticipants: number;
  from: Date;
  until: Date;
}> = memo(({ hasCapacity, capacity, numberOfParticipants, from, until }) => {
  const dateStr = format(from, "MM/dd(eee)", { locale: ja });
  const fromTimeStr = format(from, "HH:mm", { locale: ja });
  const untilTimeStr = format(until, "HH:mm", { locale: ja });
  const label = `${dateStr}\n${fromTimeStr}〜${untilTimeStr}`;

  return (
    <StyledHeaderCellBox>
      <Typography
        display="block"
        variant="body1"
        noWrap
        // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
        style={{ lineHeight: 1.4, textAlign: "center", width: "100%" }}
      >
        {label.split("\n").map((v, i) => (
          <Fragment key={i}>
            {v}
            <br />
          </Fragment>
        ))}
      </Typography>
      {hasCapacity && (
        <Box display="flex" justifyContent="center" mt="8px">
          <Chip numberOfParticipants={numberOfParticipants} capacity={capacity || 0} />
        </Box>
      )}
    </StyledHeaderCellBox>
  );
});

const StyledHeaderCellBox = styled(Box)`
  height: 100%;
  width: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 0px 1px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;
