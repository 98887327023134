import { CandidateDateWithNumberOfParticipants } from "../../../../../domain";

export const convertUnixTimeToDateCandidateDateWithNumberOfParticipants = (
  // なぜか型エラーになるのでanyにしている
  // d: ToCloudFunctionsCompatible<CandidateDateWithNumberOfParticipants>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  d: any
): CandidateDateWithNumberOfParticipants => {
  return new CandidateDateWithNumberOfParticipants({
    id: d.id,
    from: new Date(d.from),
    until: new Date(d.until),
    createdAt: new Date(d.createdAt),
    updatedAt: new Date(d.updatedAt),
    numberOfParticipants: d.numberOfParticipants,
    capacity: d.capacity,
  });
};
