import { Library } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { LibraryUseCase } from "~/service/usecases/libraryUseCase";

const useCase = new LibraryUseCase();

/**
 * tenantId をもとに library の配列を返すswr
 * @param tenantId tenantのid
 */
export const useLibraries = (tenantId?: string, spaceId?: string): SWRResponse<Library[]> => {
  const keys = tenantId ? ["libraries", tenantId] : null;

  return useSWR(keys, ([_key, tenantId]: [string, string]) =>
    useCase.fetchAll(tenantId, undefined, spaceId)
  );
};
