type UnitType = "15_MINUTE" | "30_MINUTE";

export const getTimeMenuItems = (
  units: UnitType, // Now accepts "30_MINUTE" as well
  isZeroPadding = false
) => {
  const timeValues =
    units === "15_MINUTE"
      ? isZeroPadding
        ? fifteenMinuteTimeValuesZeroPadding
        : fifteenMinuteTimeValues
      : isZeroPadding
      ? thirtyMinuteTimeValuesZeroPadding
      : thirtyMinuteTimeValues;

  return timeValues.map((v) => ({ value: v, name: v }));
};

const fifteenMinuteTimeValues = [...Array(24)].flatMap((_, hour) =>
  ["00", "15", "30", "45"].map((minute) => `${hour}:${minute}`)
);

const fifteenMinuteTimeValuesZeroPadding = [...Array(24)].flatMap((_, hour) =>
  ["00", "15", "30", "45"].map(
    (minute) => `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`
  )
);

const thirtyMinuteTimeValues = [...Array(24)].flatMap((_, hour) =>
  ["00", "30"].map((minute) => `${hour}:${minute}`)
);

const thirtyMinuteTimeValuesZeroPadding = [...Array(24)].flatMap((_, hour) =>
  ["00", "30"].map(
    (minute) => `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`
  )
);
