import { Box } from "@material-ui/core";
import { Reminder } from "@onn/common";

import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { TextFieldDeprecated, Typography } from "~/components/uiParts";

type Props = {
  control: Control<
    {
      title: string;
      remindDate: {
        remindedAt: Date | null;
        remindHour: number;
      };
    },
    unknown
  >;
};

export const TitleForm: FC<Props> = React.memo(({ control }) => {
  return (
    <Box mb="32px">
      <Box mb="16px">
        <Typography variant="body2" bold color="textPrimary">
          タイトル
        </Typography>
      </Box>
      <Controller
        name="title"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextFieldDeprecated
            placeholder="例：入社当日の予定を確認する"
            variant="outlined"
            fullWidth
            maxTextCount={Reminder.titleMaxLength}
            error={!!error}
            helperText={error?.message}
            {...field}
          />
        )}
      />
    </Box>
  );
});
