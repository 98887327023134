import liff from "@line/liff";
import React, { ReactNode, createContext, useCallback, useContext, useState } from "react";

import { LiffInitializeContext } from "./LiffInitializeProvider";

import { useLiffId } from "~/hooks/liff";

const guardLineAccessToken = (lineAccessToken: unknown): lineAccessToken is string => {
  return !!lineAccessToken;
};

/**
 * 使う側で型ガードしたいため、Context では null を許容している
 */
export type ValidLineAccessTokenByLiffContextValue = string | null;

export const LineAccessTokenByLiffContext = createContext<{
  lineAccessToken: ValidLineAccessTokenByLiffContextValue | undefined;
  setLineAccessToken: (lineAccessToken: ValidLineAccessTokenByLiffContextValue) => void;
  /**
   * LIFF SDK でアクセストークンを取得して、コンテキストにセットする
   */
  setLineAccessTokenByLiff: () => Promise<void>;
  guardLineAccessToken: (lineAccessToken: unknown) => lineAccessToken is string;
}>({
  lineAccessToken: undefined,
  setLineAccessToken: () => void 0,
  setLineAccessTokenByLiff: async () => void 0,
  guardLineAccessToken,
});

export const LineAccessTokenByLiffProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const { isReady } = useContext(LiffInitializeContext);
  const [lineAccessToken, setLineAccessToken] =
    useState<ValidLineAccessTokenByLiffContextValue>(null);
  const liffId = useLiffId();

  const setLineAccessTokenByLiff = useCallback(async () => {
    if (!isReady) return;
    if (!liff.isInClient()) return;

    // LIFF SDK のメソッドを使う前に LIFF の初期化を保証する
    if (!liffId) {
      return;
    }

    const accessToken = liff.getAccessToken();

    setLineAccessToken(accessToken);
  }, [isReady, liffId]);

  return (
    <LineAccessTokenByLiffContext.Provider
      value={{
        lineAccessToken,
        guardLineAccessToken,
        setLineAccessToken,
        setLineAccessTokenByLiff,
      }}
    >
      {children}
    </LineAccessTokenByLiffContext.Provider>
  );
};
