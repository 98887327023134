import React from "react";
import { GridCellRenderer, Index } from "react-virtualized";

import { BodyCell } from "./BodyCell";
import { HeaderCell } from "./HeaderCell";
import { BodyRowData, HeaderRowData, columnTypes, widthPxMap } from "./const";

export const useMultiGridOnPaper = ({
  headerRowData,
  bodyRowDataArray,
}: {
  headerRowData: HeaderRowData;
  bodyRowDataArray: BodyRowData[];
}) => {
  const headerRowCount = 1;
  const rowCount = headerRowCount + bodyRowDataArray.length;
  const columnCount = columnTypes.length;

  // MultiGridが各列の幅を算出するための関数
  const columnWidth = (params: Index) => {
    const columnType = columnTypes[params.index];
    if (columnType) return widthPxMap[columnType];

    return widthPxMap.slotDate;
  };

  const cellRenderer: GridCellRenderer = ({ style, rowIndex, columnIndex }) => {
    const columnType = columnTypes[columnIndex];
    if (!columnType) return;

    const isLastColumn = columnIndex === columnTypes.length - 1;
    const isShowRightBorder = !isLastColumn && columnIndex !== 0 && columnIndex !== 1;

    const key = String(rowIndex) + String(columnIndex);
    if (rowIndex === 0) {
      return (
        <HeaderCell
          key={key}
          style={style}
          columnType={columnType}
          isShowRightBorder={isShowRightBorder}
          headerRowData={headerRowData}
        />
      );
    }
    const bodyRowData = bodyRowDataArray[rowIndex - 1];
    if (!bodyRowData) return;

    return (
      <BodyCell
        key={key}
        bodyRowData={bodyRowData}
        columnType={columnType}
        style={style}
        isShowRightBorder={isShowRightBorder}
      />
    );
  };

  return {
    rowCount,
    columnCount,
    columnWidth,
    cellRenderer,
  };
};
