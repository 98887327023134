import React, { useContext, type ReactNode } from "react";

import { LoadingForInitialRendering } from "../shared";

import { AuthorizationContext } from "./AuthorizationProvider";

type Props = {
  children: ReactNode;
};

export const AuthorizationGuard = ({ children }: Props) => {
  const { authorizedUsers, guardAuthorizedUsers } = useContext(AuthorizationContext);

  if (!guardAuthorizedUsers(authorizedUsers))
    return <LoadingForInitialRendering ns="AuthenticationGuard" enableCaptureException />;

  return <>{children}</>;
};
