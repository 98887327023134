import { APISchema, Method, QueryParamsValueType, convertStringToKebabCase } from "@onn/common";
import { instanceToPlain } from "class-transformer";
import { getToken } from "firebase/app-check";
import { stringify } from "qs";
import superjson from "superjson";
import urlJoin from "url-join";

import { getSelectedSpaceId } from "./spaceIdFromStorage";

import { appCheck, auth } from "~/config/firebase";
import { generateBaseUrl } from "~/infrastructure/api/functionOperator";
import { ApiResponseError, getStorageAccessors } from "~/util";
import { getCookieValue } from "~/util/getCookieValue";
import { NetworkError, captureException } from "~/util/loggerUtil";

type Path<M extends Method> = {
  [K in keyof APISchema]: M extends keyof APISchema[K] ? K : never;
}[keyof APISchema];

type Body<P extends keyof APISchema, M extends keyof APISchema[P]> = APISchema[P][M] extends {
  body: infer R;
}
  ? R
  : never;

type QueryParams = { [key: string]: QueryParamsValueType };
type Response<P extends keyof APISchema, M extends keyof APISchema[P]> = APISchema[P][M] extends {
  response: infer R;
}
  ? R
  : never;

const { retrieveValue } = getStorageAccessors(localStorage);

const handler = async <P extends keyof APISchema, M extends keyof APISchema[P]>(
  path: P,
  method: M,
  options?: {
    body?: Body<P, M>;
    queryParams?: QueryParams;
    fileDownload?: {
      isFileDownload: boolean;
      isCSV: boolean;
      fileName?: string;
    };
    keepalive?: boolean; // NOTE: ページ離脱時にログを送信する時などにkeepaliveをtrueにし、リクエストがキャンセルされないようにする
    isNotifyError?: boolean; // NOTE: エラー通知を行うかどうか
  }
): Promise<Response<P, M>> => {
  const url = buildURL({
    // NOTE:local環境の場合はCF実行URLはスネークケースになるため、ケバブケースに変換しない
    path:
      process.env.NODE_ENV === "localhost" || process.env.NODE_ENV === "preview"
        ? path
        : String(convertStringToKebabCase(path)),
    queryParams: options?.queryParams,
  });

  let idToken = getCookieValue("idToken");
  // NOTE: idTokenがcookieにない場合はfirebaseから取得する（ネットワーク再接続時のSWR自動再検証が実行された場合などにidTokenがcookieにない場合があるため）
  if (!idToken) idToken = (await auth.currentUser?.getIdToken(true)) || "";

  const tenantId = retrieveValue<string>("tenantId");
  const spaceId = tenantId ? getSelectedSpaceId(tenantId) : null;

  // NOTE: AppCheckトークンの取得に失敗しても throw しない (本適用時にも残す。Cloud Logging で集計したいため)
  const appCheckToken = await getToken(appCheck).catch((e) => {
    captureException(e);
    return;
  });

  const headers: HeadersInit = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${idToken}`,
    "x-onn-tenant-id": tenantId ?? "",
    "x-space-id": spaceId ?? "",
    "X-Firebase-AppCheck": appCheckToken?.token ?? "",
  };
  if (process.env.NODE_ENV === "preview") {
    // preview 時に ngrok の警告を無視しないと LIFF ブラウザでエラーが出て使えないため設定
    headers["ngrok-skip-browser-warning"] = "true";
  }

  const init: RequestInit = {
    method: method as string,
    headers,
    keepalive: options?.keepalive,
  };

  if (options?.body) {
    // NOTE: DATE型の値をJSONに変換すると文字列になってしまうため、JSON.stringifyではなくsuperjson.stringifyを使っている
    init.body = superjson.stringify(instanceToPlain(options?.body));
  }

  if (options?.fileDownload?.isFileDownload) {
    try {
      // HACK: .toString() しないと preview 時に Failed to construct 'URL'. が発生したためワークアラウンド
      const response = await fetch(process.env.NODE_ENV === "preview" ? url.toString() : url, init);

      const buffer = await response.arrayBuffer();
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8 と明示するための BOM
      const blob = options.fileDownload.isCSV
        ? new Blob([bom, buffer], { type: "text/csv" })
        : new Blob([buffer]);
      // ファイルをダウンロードするためのa要素を作成
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      // Blobをa要素にセットし、ファイル名を指定
      a.href = window.URL.createObjectURL(blob);
      if (options.fileDownload.fileName) {
        a.download = options.fileDownload.fileName;
      }
      // ファイルをクリックしてダウンロードを開始
      a.click();
      // a要素を削除
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
      return {} as Promise<Response<P, M>>;
    } catch (e) {
      throw new Error("ファイルのダウンロードに失敗" + (e as Error).message);
    }
  }

  const response = await fetch(url, init).catch((e: Error) => {
    throw new NetworkError(`[${url}]${e.message}`, url.toString());
  });
  const responseText = await response.text();

  if (response.ok) {
    if (response.status === 204) return {} as Promise<Response<P, M>>;

    const parsedBody = superjson.parse(responseText);
    return parsedBody as Promise<Response<P, M>>;
  }

  let responseObjOrText: object | string;
  try {
    responseObjOrText = JSON.parse(responseText);
  } catch (_e) {
    responseObjOrText = responseText;
  }

  /* 以下のパターンのレスポンスに対応してエラーメッセージを抽出する
   * res.status(400).send();
   * res.status(400).send("message");
   * res.status(400).send(result.error);
   * res.status(400).send({ message: "message" });
   * res.status(500).send({ error: "message" });
   */
  let errorMessage = "エラーが発生しました。";
  if (typeof responseObjOrText === "string") {
    errorMessage = responseObjOrText;
  } else if ("message" in responseObjOrText && typeof responseObjOrText.message === "string") {
    errorMessage = responseObjOrText.message;
  } else if ("error" in responseObjOrText && typeof responseObjOrText.error === "string") {
    errorMessage = responseObjOrText.error;
  }

  if (options?.isNotifyError) {
    captureException({
      error: new Error("apiClientでレスポンスエラーが発生しました。"),
      tags: {
        type: `${method as string} ${url.toString()}`,
      },
      extras: {
        requestBody: options?.body,
        response: responseObjOrText,
      },
    });
  }

  throw new ApiResponseError(
    errorMessage,
    url.toString(),
    options?.body,
    responseObjOrText,
    response.status
  );
};

/**
 * cloud functionエンドポイントのURLを生成する
 */
const buildURL = ({ path, queryParams }: { path: string; queryParams?: QueryParams }) => {
  const apiPath = (path as string).split("/");
  // NOTE: /transactionsapi/survey のような場合、CF名はtransactionsapiになる
  const functionName = apiPath[1] as (typeof apiPath)[number];

  const convertedFunctionName =
    process.env.NODE_ENV === "localhost"
      ? functionName
      : process.env.NODE_ENV === "preview"
      ? functionName
      : process.env.NODE_ENV === "production"
      ? convertStringToKebabCase(functionName)
      : convertStringToKebabCase(functionName);

  const urlStr = generateBaseUrl(convertedFunctionName);
  const restPaths = apiPath.slice(2);

  /**
   * addQueryPrefixオプションで先頭に?をつける
   * ex) qs.stringify({ a: 'b', c: 'd' }, { addQueryPrefix: true }) // '?a=b&c=d'
   *
   * allowDotsオプションでオブジェクトのネストをドット区切りにする
   * ex) qs.stringify({ a: { b: { c: 'd', e: 'f' } } }, { allowDots: true });
   * 'a.b.c=d&a.b.e=f'
   *
   * arrayFormat(indices)で例のように配列のインデックスをつける
   * ex) qs.stringify({ a: ['b', 'c'] }, { arrayFormat: 'indices' })
   * 'a[0]=b&a[1]=c'
   *
   * 空文字の扱い
   * qs.stringify({ a: '' }) // 'a='
   *
   * nullは空文字扱い・undefinedは無視される
   * ex) qs.stringify({ a: null, b: undefined }) // 'a='
   */
  const queryString = queryParams
    ? stringify(queryParams, {
        addQueryPrefix: true,
        allowDots: true,
        arrayFormat: "indices",
      })
    : "";
  const url = new URL(urlJoin(urlStr, ...restPaths) + queryString);

  return url;
};

export const apiClient = {
  get: async <P extends Path<"GET">>(
    path: P,
    queryParams?: QueryParams,
    options?: {
      fileDownload?: {
        fileName: string;
        isCSV: boolean;
      };
      isNotifyError?: boolean;
    }
  ) => {
    return await handler(path, "GET", {
      queryParams,
      fileDownload: {
        isFileDownload: !!options?.fileDownload,
        isCSV: options?.fileDownload?.isCSV || false,
        fileName: options?.fileDownload && options?.fileDownload.fileName,
      },
      isNotifyError: options?.isNotifyError,
    });
  },
  patch: async <P extends Path<"PATCH">>(
    path: P,
    body?: Body<P, "PATCH">,
    options?: {
      isNotifyError?: boolean;
    }
  ) => {
    return await handler(path, "PATCH", { body, isNotifyError: options?.isNotifyError });
  },
  post: async <P extends Path<"POST">>(
    path: P,
    body?: Body<P, "POST">,
    options?: {
      keepalive: boolean;
      fileDownload?: {
        fileName: string;
        isCSV: boolean;
      };
      isNotifyError?: boolean;
    }
  ) => {
    return await handler(path, "POST", {
      body,
      keepalive: options?.keepalive,
      fileDownload: {
        isFileDownload: !!options?.fileDownload,
        isCSV: options?.fileDownload?.isCSV || false,
        fileName: options?.fileDownload && options?.fileDownload.fileName,
      },
      isNotifyError: options?.isNotifyError,
    });
  },
  delete: async <P extends Path<"DELETE">>(
    path: P,
    queryParams?: QueryParams,
    body?: Body<P, "DELETE">,
    options?: {
      isNotifyError?: boolean;
    }
  ) => {
    // FIXME: queryParams は使わないようにする
    // pathParams を使えるようにするまでの一時的な措置
    return await handler(path, "DELETE", {
      queryParams,
      body,
      isNotifyError: options?.isNotifyError,
    });
  },
};
