import { orderBy } from "lodash";

import { OnnEventSlotDate } from "./OnnEventSlotDate";

export class OnnEventSlotDatesForDisplay extends OnnEventSlotDate {
  reservedCount: number;
  assigneeInfo?: {
    id: string;
    name: string;
    iconUrl?: string;
  };
  subAssigneesInfo: {
    id: string;
    name: string;
    iconUrl?: string;
  }[];
  briefingSessionCategoryInfo: {
    id: string;
    title: string;
  } | null;
  constructor(init: ExcludeMethods<OnnEventSlotDatesForDisplay>) {
    super(init);
    this.reservedCount = init.reservedCount;
    this.assigneeInfo = init.assigneeInfo;
    this.subAssigneesInfo = init.subAssigneesInfo;
    this.briefingSessionCategoryInfo = init.briefingSessionCategoryInfo;
  }

  public getStatusForDisplay(): SlotDateStatusForDisplay {
    if (this.isDraft()) return "draft";
    if (this.isDone()) return "done";
    if (this.isClosed()) return "closed";
    if (this.isFull(this.reservedCount)) return "full";
    return "adjusting";
  }

  public getSlotTypeForDisplay(): SlotTypeForDisplay {
    return new SlotTypeForDisplay({
      type: this.eventType,
      url: this.url || undefined,
      location: this.eventAddressText || undefined,
    });
  }

  static sortForDisplay(
    onnEventSlotDatesForDisplay: OnnEventSlotDatesForDisplay[]
  ): OnnEventSlotDatesForDisplay[] {
    const orderedOnnEventSlotDatesForDisplay = orderBy(
      onnEventSlotDatesForDisplay,
      [(v) => v.status === "draft", (v) => v.from, (v) => v.createdAt],
      ["asc", "asc", "asc"]
    );

    return orderedOnnEventSlotDatesForDisplay;
  }

  isReservable(): boolean {
    return this.isPublished() && !this.isFull(this.reservedCount);
  }
}

export class SlotTypeForDisplay {
  type: "online" | "offline";
  url?: string;
  location?: string;
  constructor(init: ExcludeMethods<SlotTypeForDisplay>) {
    this.type = init.type;
    this.url = init.url;
    this.location = init.location;
  }

  isWarning(): boolean {
    if (this.type === "online") {
      return !this.isValidUrl();
    }
    if (this.type === "offline") {
      return !this.location;
    }
    throw new Error("Invalid slot type");
  }

  isValidUrl(): boolean {
    // urlがhttp(s)://のみの場合はfalse
    return !!this.url && !/^https?:\/\/$/.test(this.url);
  }

  hasLocation(): boolean {
    return !!this.location;
  }

  getLabel(): string {
    return this.type === "offline" ? "オフライン・現地" : "オンライン";
  }

  isOnline(): boolean {
    return this.type === "online";
  }

  isOffline(): boolean {
    return this.type === "offline";
  }
}

export type SlotDateStatusForDisplay = "done" | "closed" | "draft" | "full" | "adjusting";
