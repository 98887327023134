import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

export const DescriptionAndEndLabel: FC<{
  description: string;
  endLabel?: string;
  isError: boolean;
}> = ({ endLabel, description, isError }) => {
  return (
    <DescriptionAndEndLabelWrapper width="100%">
      <Box>
        <Typography
          bold
          color={isError ? "secondary" : "textPrimary"}
          variant="body2"
          component="div"
        >
          {description}
        </Typography>
      </Box>
      {endLabel && (
        <EndChip>
          <Typography color="textPrimary" variant="caption">
            {endLabel}
          </Typography>
        </EndChip>
      )}
    </DescriptionAndEndLabelWrapper>
  );
};

const DescriptionAndEndLabelWrapper = styled(Box)`
  width: 100%;
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

const EndChip = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  border-radius: 8px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 86px;
  max-width: 110px;
  height: 24px;
`;
