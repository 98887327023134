import { Employee } from "@onn/common";
import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { useCallback } from "react";

import { useModal } from "~/hooks/modal";

export const useAddNewGraduateToOnnEventWithCSVButtonHandler = ({
  selectedNewGraduates,
  alreadyRegisteredNewGraduateIds,
  setSelectedNewGraduates,
  onnEvent,
}: {
  selectedNewGraduates: Employee[];
  alreadyRegisteredNewGraduateIds: string[];
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
}) => {
  const { handleModal } = useModal();

  const onSubmit = useCallback(
    (newTargets: Employee[]) => {
      setSelectedNewGraduates((prev) => {
        const prevIds = prev.map((v) => v.id);
        const newSelectedNewGraduates = [
          ...prev,
          ...newTargets.filter((v) => !prevIds.includes(v.id)), // 重複を除外
        ];
        return newSelectedNewGraduates;
      });
    },
    [setSelectedNewGraduates]
  );

  // 「CSVアップロード」ボタンハンドラー
  const onClickUploadCSVButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateByCSVModal",
      args: {
        currentSelectedNewGraduates: selectedNewGraduates,
        registeredEmployeeIds: alreadyRegisteredNewGraduateIds,
        onSubmit,
        // NOTE: ゆくゆくは新面談タイプも同じ挙動になる
        canUseNotInvited: (() => {
          if (onnEvent.type === "briefing_session") {
            return true;
          } else if (onnEvent.type === "new_interview") {
            return false;
          } else {
            const _exhaustiveCheck: never = onnEvent;
          }
        })(),
      },
    });
  }, [handleModal, selectedNewGraduates, alreadyRegisteredNewGraduateIds, onSubmit, onnEvent]);

  return { onClickUploadCSVButton };
};
