import { ContactMessageTemplate, ContactMessageTemplateFolderWithTemplates } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

/**
 * コンタクトメッセージテンプレートフォルダ一覧を取得するhooks
 * - 作成日時昇順
 */
export const useContactMessageTemplateFolders = (
  tenantId: string,
  spaceId: string
): SWRResponse<ContactMessageTemplateFolderWithTemplates[], Error> & { refetchKey: Key } => {
  const keyObject = { tenantId, spaceId };

  const fetcher = async (): Promise<ContactMessageTemplateFolderWithTemplates[]> => {
    const response = await apiClient.get(path);
    return response.data.map((d) => {
      // NOTE: フォルダに紐付くテンプレートまでインスタンスを生成できないので、個別で生成しておく
      const templates = d.templates.map((t) => new ContactMessageTemplate(t));
      return new ContactMessageTemplateFolderWithTemplates({ ...d, templates });
    });
  };
  return { ...useSWR(keyObject, fetcher), refetchKey: keyObject };
};

const path = "/contact_message_api/template-folders";
export type Key = { tenantId: string; spaceId: string };
