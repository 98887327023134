import { TableCell } from "@mui/material";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts";

export const NoneCell: FC<{}> = () => {
  return (
    <Cell>
      <Icon color="lightGrey" size="xl" icon="horizontalBar" />
    </Cell>
  );
};

const Cell = styled(TableCell)`
  &.MuiTableCell-root {
    width: 100%;
    height: 80px;
    padding: 0px;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    border: solid ${(props) => props.theme.palette.grey[100]} 1px;
    border-width: 1px 1px 0px 0px;
    text-align: center;
  }
`;
