import { EmployeeInformation } from "@onn/common";
import React, { FC } from "react";

import { InformationValueViewer } from "../InformationValueViewer";

import { Typography } from "~/components/uiParts";

type Props = {
  value?: EmployeeInformation["value"]["address"];
  onChangeModeToEdit: () => void;
  onClickCopy?: () => void;
};

export const AddressViewer: FC<Props> = ({ value, onChangeModeToEdit, onClickCopy }) => {
  return (
    <InformationValueViewer
      label={
        value ? (
          <Typography variant="body2">{value ? value.getFullAddress() : null}</Typography>
        ) : undefined
      }
      onChangeModeToEdit={onChangeModeToEdit}
      onClickCopy={onClickCopy}
    />
  );
};
