import { FormRevision } from "../../domain";

export const createIMockFormRevision = (mock: Partial<FormRevision> = {}): FormRevision => {
  return new FormRevision({
    id: mock.id ?? "formRevision1",
    tenantId: mock.tenantId ?? "tenant1",
    formTaskId: mock.formTaskId ?? "formTask1",
    createdAt: mock.createdAt ?? new Date("2021-01-01T00:00:00.000Z"),
    createdUserId: mock.createdUserId ?? "assigneeId",
    questions: mock.questions ?? [
      {
        id: "question1",
        type: "CHECK_BOX",
        title: "質問1",
        isRequired: true,
        options: [
          {
            id: "question1-option1",
            text: "選択肢1",
          },
          {
            id: "question1-option2",
            text: "選択肢2",
          },
        ],
        allowFreeText: true,
      },
      {
        id: "question2",
        type: "TEXT",
        title: "質問2",
        isRequired: false,
        isWordLimit: false,
      },
      {
        id: "question3",
        type: "RADIO",
        title: "質問3",
        isRequired: true,
        options: [
          {
            id: "question3-option1",
            text: "選択肢1",
          },
          {
            id: "question3-option2",
            text: "選択肢2",
          },
        ],
      },
      {
        id: "question4",
        type: "FILE",
        title: "質問4",
        isRequired: false,
        sampleFilePath: "sample.pdf",
      },
    ],
  });
};
