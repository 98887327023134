import { ContactRoomPin } from "@onn/common";
import { useCallback } from "react";

import { useCreateContactRoomPin } from "~/hooks/contactRoomPin/useCreateContactRoomPin";
import { useDeleteContactRoomPin } from "~/hooks/contactRoomPin/useDeleteContactRoomPin";

import { useSnackbar } from "~/hooks/shared";

export const useHandleOnClickPin = ({
  selectedContactRoomId,
  contactRoomPin,
}: {
  selectedContactRoomId: string;
  contactRoomPin?: ContactRoomPin;
}) => {
  const isPinned = !!contactRoomPin;

  const { enqueueSnackbar } = useSnackbar();
  const { createContactRoomPin } = useCreateContactRoomPin();
  const { deleteContactRoomPin } = useDeleteContactRoomPin();

  const handleOnClickPin = useCallback(async () => {
    try {
      await createContactRoomPin(selectedContactRoomId);
    } catch (_error) {
      // NOTE: バックエンド側でエラーが発生した場合はエラーメッセージを通知する
      enqueueSnackbar("ピン留めに失敗しました。", { variant: "error" });
    }
  }, [createContactRoomPin, enqueueSnackbar, selectedContactRoomId]);

  const handleOnClickUnpin = useCallback(async () => {
    if (!contactRoomPin) return;
    try {
      await deleteContactRoomPin({ contactRoomPinId: contactRoomPin.id });
    } catch (_error) {
      // NOTE: バックエンド側でエラーが発生した場合はエラーメッセージを通知する
      enqueueSnackbar("ピン留め解除に失敗しました。", { variant: "error" });
    }
  }, [contactRoomPin, deleteContactRoomPin, enqueueSnackbar]);

  return {
    isPinned,
    handleOnClickPin,
    handleOnClickUnpin,
  };
};
