import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Button } from "~/components/uiParts";
type Props = {
  form: JSX.Element;
  onSave: () => void;
  onCancel: () => void;
  disabled?: boolean;
};

export const InformationValueEditor: FC<Props> = ({ form, onSave, onCancel, disabled }) => {
  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px" py="8px">
      {form}
      <Box display="flex" ml="auto" gridColumnGap="8px">
        <Button
          color="primary"
          variant="contained"
          borderRadius="regular"
          onClick={onSave}
          disabled={disabled}
        >
          保存
        </Button>
        <Button color="default" variant="outlined" borderRadius="regular" onClick={onCancel}>
          キャンセル
        </Button>
      </Box>
    </Box>
  );
};
