import { Box, Stack } from "@mui/material";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { IconButton, TextField, Tooltip } from "~/components/uiParts";

type Props = {
  label: string;
  onChange: (value: string) => void;
  errorText?: string;
  onClickRemoveButton: () => void;
  disableRemoveButton: boolean;
};

export const CustomStatusInputRow: FC<Props> = ({
  label,
  onChange,
  errorText,
  onClickRemoveButton,
  disableRemoveButton,
}) => {
  // TextFieldのhelperTextに以前の値を表示し続けるためにuseStateを使って初期値を固定している
  const [defaultLabel] = useState(label);

  return (
    <Stack direction="row" alignItems="start" spacing={1} py={1} width="100%">
      <TextField
        fullWidth
        variant="outlined"
        placeholder="選考ステータスの名称を入力してください"
        value={label}
        onChange={(event) => onChange(event.target.value)}
        error={!!errorText}
        helperText={errorText || defaultLabel}
      />
      <Box
        px={1}
        py={1}
        display="flex"
        justifyContent="center"
        sx={{ cursor: disableRemoveButton ? "not-allowed" : "default" }}
      >
        <Tooltip
          title={
            disableRemoveButton ? "候補者に紐づいている選考ステータスは削除できません" : undefined
          }
        >
          <StyledIconButton
            icon="close"
            size="ssm"
            color="grey400"
            onClick={onClickRemoveButton}
            disabled={disableRemoveButton}
          />
        </Tooltip>
      </Box>
    </Stack>
  );
};

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    padding: 2px;
  }
`;
