import { zodResolver } from "@hookform/resolvers/zod";
import {
  AnyEmployeeInformationFieldWithOptions,
  EmployeeInformationMultipleTypeOption,
  EmployeeInformationSingleTypeOption,
} from "@onn/common";
import { instanceToPlain } from "class-transformer";
import { useCallback } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import { formSchema, InputState } from "./schema";

import {
  useCreateEmployeeInformationField,
  useUpdateEmployeeInformationField,
} from "~/hooks/employeeInformationField";
import { mutateEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";
import { usePrompt, useSnackbar } from "~/hooks/shared";

type Args = {
  defaultGroupId: string;
  onClose: () => void;
} & (
  | {
      mode: "create";
      employeeInformationField: undefined;
    }
  | {
      mode: "edit";
      employeeInformationField: AnyEmployeeInformationFieldWithOptions;
    }
);

export const useEmployeeInformationFieldsFrom = ({
  mode,
  defaultGroupId,
  employeeInformationField,
  onClose,
}: Args) => {
  // NOTE: defaultValueにクラスのインスタンスを渡すと、isDirtyの挙動が不安定になるため、plain objectに変換している
  const defaultValues = instanceToPlain(
    mode === "edit"
      ? {
          ...employeeInformationField,
          employeeInformationGroupId: employeeInformationField.employeeInformationGroupId,
          ...(employeeInformationField.type === "SINGLE_SELECT"
            ? {
                employeeInformationSingleTypeOptions: employeeInformationField.options,
              }
            : []),
          ...(employeeInformationField.type === "MULTIPLE_SELECT"
            ? {
                employeeInformationMultipleTypeOptions: employeeInformationField.options,
              }
            : []),
        }
      : {
          employeeInformationGroupId: defaultGroupId,
        }
  );

  const form = useForm<InputState>({
    defaultValues,
    resolver: zodResolver(formSchema),
    mode: "all",
  });

  const { handleSubmit } = useHandleSubmit({
    form,
    closeModal: onClose,
    ...(mode === "create"
      ? { mode: "create", existingEmployeeInformationField: undefined }
      : { mode: "edit", existingEmployeeInformationField: employeeInformationField }),
  });

  usePrompt("項目設定の編集を破棄しますか？", form.formState.isDirty);

  return {
    form,
    handleSubmit,
    isSubmitButtonDisabled:
      form.formState.isSubmitting || !form.formState.isValid || !form.formState.isDirty,
  };
};

const useHandleSubmit = ({
  form,
  closeModal,
  ...props
}: {
  form: UseFormReturn<InputState>;
  closeModal: () => void;
} & (
  | {
      mode: "create";
      existingEmployeeInformationField: undefined;
    }
  | {
      mode: "edit";
      existingEmployeeInformationField: AnyEmployeeInformationFieldWithOptions;
    }
)) => {
  const { execCreateEmployeeInformationField } = useCreateEmployeeInformationField();
  const { execUpdateEmployeeInformationField } = useUpdateEmployeeInformationField();
  const { enqueueSnackbar } = useSnackbar();

  const submit = form.handleSubmit(async (anyEmployeeInformationField) => {
    if (props.mode === "create") {
      if (anyEmployeeInformationField.type === "SINGLE_SELECT") {
        anyEmployeeInformationField.employeeInformationSingleTypeOptions =
          anyEmployeeInformationField.employeeInformationSingleTypeOptions.map((option, index) => ({
            ...option,
            order: index,
          })) as [EmployeeInformationSingleTypeOption];
      }
      if (anyEmployeeInformationField.type === "MULTIPLE_SELECT") {
        anyEmployeeInformationField.employeeInformationMultipleTypeOptions =
          anyEmployeeInformationField.employeeInformationMultipleTypeOptions.map(
            (option, index) => ({
              ...option,
              order: index,
            })
          ) as [EmployeeInformationMultipleTypeOption];
      }

      await execCreateEmployeeInformationField({ anyEmployeeInformationField });
      enqueueSnackbar("候補者情報項目を作成しました", { variant: "success" });
    } else {
      const existingEmployeeInformationField = props.existingEmployeeInformationField;
      await execUpdateEmployeeInformationField({
        employeeInformationField:
          anyEmployeeInformationField.type === "SINGLE_SELECT"
            ? {
                ...existingEmployeeInformationField,
                type: anyEmployeeInformationField.type,
                label: anyEmployeeInformationField.label,
                employeeInformationGroupId: anyEmployeeInformationField.employeeInformationGroupId,
                employeeInformationSingleTypeOptions:
                  anyEmployeeInformationField.employeeInformationSingleTypeOptions.map(
                    (option, index) => {
                      // NOTE: 既存のものがあれば既存のoptionをもとにidを引き継いで更新する
                      const existedOption =
                        existingEmployeeInformationField.type === "SINGLE_SELECT"
                          ? existingEmployeeInformationField.options.find((o) => o.id === option.id)
                          : null;

                      return existedOption
                        ? existedOption.update({
                            label: option.label,
                            order: index, // NOTE: 並び替えが行われてもorderは変更されないので、配列のindexをそのままorderとして利用する
                          })
                        : EmployeeInformationSingleTypeOption.createNew({
                            ...option,
                            order: index,
                            tenantId: existingEmployeeInformationField.tenantId,
                            employeeInformationSingleSelectTypeFieldId:
                              existingEmployeeInformationField.id,
                          });
                    }
                  ) as [EmployeeInformationSingleTypeOption], // NOTE: バリデーションで長さが1以上であることが保証されているためここでアサーションして長さが1以上であること示している
              }
            : anyEmployeeInformationField.type === "MULTIPLE_SELECT"
            ? {
                ...existingEmployeeInformationField,
                type: anyEmployeeInformationField.type,
                label: anyEmployeeInformationField.label,
                employeeInformationGroupId: anyEmployeeInformationField.employeeInformationGroupId,
                employeeInformationMultipleTypeOptions:
                  anyEmployeeInformationField.employeeInformationMultipleTypeOptions.map(
                    (option, index) => {
                      // NOTE: 既存のものがあれば既存のoptionをもとにidを引き継いで更新する
                      const existedOption =
                        existingEmployeeInformationField.type === "MULTIPLE_SELECT"
                          ? existingEmployeeInformationField.options.find((o) => o.id === option.id)
                          : null;

                      return existedOption
                        ? existedOption.update({
                            label: option.label,
                            order: index, // NOTE: 並び替えが行われてもorderは変更されないので、配列のindexをそのままorderとして利用する
                          })
                        : EmployeeInformationMultipleTypeOption.createNew({
                            ...option,
                            order: index,
                            tenantId: existingEmployeeInformationField.tenantId,
                            employeeInformationMultipleSelectTypeFieldId:
                              existingEmployeeInformationField.id,
                          });
                    }
                  ) as [EmployeeInformationMultipleTypeOption], // NOTE: バリデーションで長さが1以上であることが保証されているためここでアサーションして長さが1以上であること示している
              }
            : {
                ...existingEmployeeInformationField,
                type: anyEmployeeInformationField.type,
                label: anyEmployeeInformationField.label,
                employeeInformationGroupId: anyEmployeeInformationField.employeeInformationGroupId,
              },
      });
      enqueueSnackbar("候補者情報項目を更新しました", { variant: "success" });
    }

    closeModal();
    mutateEmployeeInformationGroups();
  });

  const handleSubmit = useCallback(async () => {
    await submit();
  }, [submit]);

  return { handleSubmit };
};
