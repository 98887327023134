import { z } from "zod";

import { PlatFormTypeSchema } from "../inputTypeSchemas/PlatFormTypeSchema";

/////////////////////////////////////////
// CONTACT MESSAGE SCHEMA
/////////////////////////////////////////

export const ContactMessageSchema = z.object({
  platformTypeCreatedBy: PlatFormTypeSchema.nullish(),
  id: z.string(),
  tenantId: z.string(),
  contactRoomId: z.string(),
  lineUserId: z.string().nullish(),
  isNewHireMessage: z.boolean().nullish(),
  text: z.string().nullish(),
  filePaths: z.string().array(),
  stickerId: z.string().nullish(),
  createdEmployeeId: z.string().nullish(),
  updatedEmployeeId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  unsend: z.boolean().nullish(),
  messageIdOfLine: z.string().nullish(),
  isSentByOfficialName: z.boolean().nullish(),
  isFailedDelivery: z.boolean().nullish(),
});

export type ContactMessage = z.infer<typeof ContactMessageSchema>;

/////////////////////////////////////////
// CONTACT MESSAGE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ContactMessageOptionalDefaultsSchema = ContactMessageSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type ContactMessageOptionalDefaults = z.infer<typeof ContactMessageOptionalDefaultsSchema>;

export default ContactMessageSchema;
