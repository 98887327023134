import { z } from "zod";

import { onnEventAnswerSchema } from "../../../../domain/OnnEvent/OnnEventAnswer";

export const updateOnnEventAnswerRequestSchema = z.object({
  onnEventAnswerId: onnEventAnswerSchema.shape.id,
  answer: onnEventAnswerSchema.shape.answer,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUpdateOnnEventAnswerRequest
  extends z.infer<typeof updateOnnEventAnswerRequestSchema> {}
