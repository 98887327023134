import { v4 } from "uuid";

import { EmployeeTag as IEmployeeTag } from "../_gen/zodSchema/index";

import { employeeTagSchema } from "./schema";

export class EmployeeTag implements IEmployeeTag {
  static validator = employeeTagSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly creatorId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<EmployeeTag>) {
    const parsedInit = employeeTagSchema.parse(init);

    this.id = parsedInit.id;
    this.name = parsedInit.name;
    this.tenantId = parsedInit.tenantId;
    this.creatorId = parsedInit.creatorId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static create(init: ExcludeMethods<Optional<EmployeeTag, "id" | "createdAt" | "updatedAt">>) {
    const parsedInit = EmployeeTag.validator.parse({
      ...init,
      id: init.id ?? v4(),
      createdAt: init.createdAt ?? new Date(),
      updatedAt: init.updatedAt ?? new Date(),
    });

    return new EmployeeTag(parsedInit);
  }

  public update(update: ExcludeMethods<Partial<Pick<EmployeeTag, "name">>>): EmployeeTag {
    const parsedUpdate = EmployeeTag.validator.partial().parse(update);

    if (parsedUpdate.name) {
      this.name = parsedUpdate.name;
    }

    this.updatedAt = new Date();

    return this;
  }
}
