/**
 * LINEスタンプを表すクラス
 * - プロパティ名は LINE API に準拠
 * - 一旦、アプリ内でのユニークIDは振らずに、stickerId をユニークキーとして扱う
 *
 * https://developers.line.biz/ja/reference/messaging-api/#sticker-message
 * https://developers.line.biz/ja/docs/messaging-api/sticker-list/#sticker-definitions
 */
export class LineStamp {
  packageId: string;
  stickerId: string;

  constructor({ packageId, stickerId }: { packageId: string; stickerId: string }) {
    this.packageId = packageId;
    this.stickerId = stickerId;
  }

  /**
   * GCS Bucket における path (file name)
   * line stamp id はユニークっぽいので、stamp id をそのまま使う
   */
  get path(): string {
    return `line-stamps/${this.stickerId}.png`;
  }
}
