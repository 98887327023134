import { OnnFormTask } from "@onn/common";
import React from "react";

import { useNavigate } from "react-router-dom";

import { useConstructUploadFilesInfo } from "../_share_in_create_edit/hooks/useConstructUploadFilesInfo";
import { State } from "../_share_in_create_edit/reducer";

import { useCurrentUser } from "~/hooks/employee";
import { useCreateOnnFormTask } from "~/hooks/onnTask/useCreateOnnFormTask";
import { useOpenPortalOnnTaskPreview } from "~/hooks/openPortalPreview";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const useActionFooterHandler = ({
  state,
  setIsSubmitting,
  onnFormTaskId,
}: {
  state: State;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  onnFormTaskId: string;
}) => {
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const tenantId = tenant.tenantId;
  const { currentSpace } = useCurrentSpace();
  const { createOnnFormTask } = useCreateOnnFormTask();
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();

  const constructUploadFilesInfo = useConstructUploadFilesInfo();

  const { openPortalOnnTaskPreview } = useOpenPortalOnnTaskPreview();

  const actionFooterHandler = {
    onClickCancel: () => {
      navigation(`/onn_tasks`);
    },
    onClickPreview: async () => {
      setIsSubmitting(true);
      const uploadFilesInfo = constructUploadFilesInfo({
        state,
        isPreview: true,
      });

      try {
        await fileAPIAdapter.uploadFiles(uploadFilesInfo);
      } catch (e) {
        enqueueSnackbar("添付ファイルのアップロードに失敗しました。", {
          variant: "error",
        });
        captureException({
          error: e as Error,
          tags: { type: "useActionFooterHandler:fileAPIAdapter.uploadFiles" },
          extras: {
            description: "（プレビュー版）サンプルファイルのアップロードに失敗しました。",
          },
        });
        setIsSubmitting(false);
        return;
      }

      openPortalOnnTaskPreview({
        onnFormTask: OnnFormTask.create({
          id: onnFormTaskId,
          isAllowEditAnswer: state.isAllowEditAnswer,
          title: state.onnTaskTitleTextField.value,
          description: state.onnTaskDescriptionTextField.value,
          filePaths: uploadFilesInfo.map((v) => v.path),
          tenantId,
          spaceId: currentSpace.id,
          assigneeId: currentUser.id,
          scheduledDate: null,
          deadlineDate: null,
          firstDeliveredAt: null,
          canAnswerAfterDeadline: false,
        }),
        questions: state.questions.map((question) => {
          if (question.type !== "TEXT") return question;
          return { ...question, wordLimit: Number(question.wordLimit) };
        }),
        formRevision: undefined,
      });
      setIsSubmitting(false);
    },

    onClickSaveAndToNext: async () => {
      setIsSubmitting(true);

      const uploadFilesInfo = constructUploadFilesInfo({
        state,
      });

      const onnFormTask = OnnFormTask.create({
        id: onnFormTaskId,
        title: state.onnTaskTitleTextField.value,
        isAllowEditAnswer: state.isAllowEditAnswer,
        description: state.onnTaskDescriptionTextField.value,
        filePaths: uploadFilesInfo.map((v) => v.path),
        tenantId,
        spaceId: currentSpace.id,
        assigneeId: currentUser.id,
        scheduledDate: null,
        deadlineDate: null,
        firstDeliveredAt: null,
        canAnswerAfterDeadline: false,
      });

      try {
        await fileAPIAdapter.uploadFiles(uploadFilesInfo);
      } catch (e) {
        enqueueSnackbar("添付ファイルのアップロードに失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useActionFooterHandler:fileAPIAdapter.uploadFiles" },
        });
        setIsSubmitting(false);
        return;
      }

      try {
        await createOnnFormTask({
          onnFormTask,
          questions: state.questions.map((question) => {
            switch (question.type) {
              case "FILE":
                // question.type === "FILE"の場合、sampleFileは不要なのでhttpリクエストに含めない
                return {
                  id: question.id,
                  type: question.type,
                  title: question.title,
                  isRequired: question.isRequired,
                  sampleFilePath: question.sampleFilePath,
                };
              case "TEXT":
                return {
                  ...question,
                  wordLimit: question.isWordLimit ? Number(question.wordLimit) : undefined,
                };
              default:
                return question;
            }
          }),
        });
        enqueueSnackbar("配信タスクを作成しました", { variant: "success" });
        navigation(`/onn_tasks/${onnFormTaskId}/delivery_setting?from_page=create`);
      } catch (e) {
        enqueueSnackbar("配信タスクの作成に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useActionFooterHandler:createOnnFormTask" },
        });
      } finally {
        setIsSubmitting(false);
      }
    },
  };

  return actionFooterHandler;
};
