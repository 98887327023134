import { Box } from "@material-ui/core";
import React, { FC } from "react";

import styled from "styled-components";

import { AttachmentFileLink } from "./AttachmentFileLink";

import { IconButton, Tooltip, Typography } from "~/components/uiParts";

import theme from "~/config/theme";
import { FileMetaData } from "~/infrastructure/usecases/file/fileAPIAdapter";

type Props = {
  status: "uploading" | "error" | "success";
  file?: FileMetaData;
  onDelete: () => void;
};

export const AttachmentFileListItem: FC<Props> = ({ status, file, onDelete }) => {
  return (
    <Box display="flex" gridColumnGap="16px" alignItems="center" justifyContent="space-between">
      {file ? (
        <AttachmentFileLink file={file} />
      ) : (
        <>
          {status === "error" ? (
            <Typography variant="body2" style={{ color: theme.palette.secondary.main }}>
              ファイルの読み込みに失敗しました。再度ファイルを選択してください。
            </Typography>
          ) : (
            <Typography variant="body2" style={{ color: theme.palette.grey[200] }}>
              ファイルを読み込み中です。
            </Typography>
          )}
        </>
      )}
      {status === "success" ? (
        <Tooltip title="削除" arrow>
          <StyledIcon icon="trash" color="grey" size="md" onClick={onDelete} />
        </Tooltip>
      ) : (
        <Tooltip title="キャンセル" arrow>
          <StyledIcon icon="close" color="grey" size="md" onClick={onDelete} />
        </Tooltip>
      )}
    </Box>
  );
};

const StyledIcon = styled(IconButton)`
  &.MuiButtonBase-root {
    border-radius: 5px;
  }
`;
