import { Box } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { FC, memo } from "react";
import styled from "styled-components";

import {
  Checkbox,
  FormControlLabel,
  Icon,
  Paper,
  SelectForm,
  TextFieldDeprecated,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "~/components/uiParts";
const menuItems = [{ value: "FORM_TASK", name: "回答フォーム" }];

export const OnnTaskOverViewFormUIMemo: FC<{
  selectedOnnTaskType: "FORM_TASK" | "TODO_TASK";
  onChangeTaskType: (e: React.ChangeEvent<{ value: unknown }>) => void;
  onChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isAllowEditAnswer: boolean;
  onChangeIsAllowEditAnswer: () => void;
  onChangeDescription: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onnTaskTitleTextField: {
    value: string;
    error: boolean;
    helperText: string;
  };
  onnTaskDescriptionTextField: {
    value: string;
    error: boolean;
    helperText: string;
  };
}> = memo(
  ({
    selectedOnnTaskType,
    onnTaskDescriptionTextField,
    onChangeTaskType,
    isAllowEditAnswer,
    onChangeIsAllowEditAnswer,
    onChangeTitle,
    onChangeDescription,
    onnTaskTitleTextField,
  }) => {
    return (
      <Paper>
        <Box mb="40px" display="flex" flexDirection="column" gridRowGap="12px">
          <Box height="24px" display={"flex"} alignItems="center">
            <Typography variant="body2" bold display="inline">
              タスクタイプ
            </Typography>
            {/* TODO: TODOフォームを実装時にツールチップを表示 */}
            {/* <Tooltip title="" placement="top-start">
            <Icon icon="help" size="ssm" color="grey" />
          </Tooltip> */}
          </Box>
          <Box width="212px">
            <SelectForm
              fullWidth
              selected={selectedOnnTaskType}
              menuItems={menuItems}
              onChange={onChangeTaskType}
            />
          </Box>
          <FormControlLabel
            onChange={() => onChangeIsAllowEditAnswer()}
            control={<Checkbox checked={isAllowEditAnswer} name="canAnswerAfterDeadline" />}
            labelPlacement="end"
            label={
              <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
                <Typography variant="body2" color="textPrimary" noWrap>
                  回答の編集を許可する
                </Typography>
                <Tooltip
                  title={
                    "配信されたフォームに回答することでタスクが完了します。編集を許可すると、候補者が回答後に回答を編集することが可能になります。"
                  }
                  placement="top-start"
                >
                  <Icon icon="help" size="sm" color="grey" />
                </Tooltip>
              </Box>
            }
          />
        </Box>
        <Box>
          <Box height="40px" display={"flex"}>
            <Typography variant="body2" bold display="inline">
              タスク概要
            </Typography>
          </Box>
          <Box width="100%" height="48px" mb="32px">
            <StyledTextField
              placeholder="タイトル"
              fullWidth
              onChange={onChangeTitle}
              value={onnTaskTitleTextField.value}
              error={onnTaskTitleTextField.error}
              helperText={onnTaskTitleTextField.helperText}
            />
          </Box>
          <Box mb="16px">
            <StyledEventContentTextarea
              name="content"
              placeholder="配信タスクの説明／依頼文"
              value={onnTaskDescriptionTextField.value}
              error={onnTaskDescriptionTextField.error}
              helperText={onnTaskDescriptionTextField.helperText}
              onChange={onChangeDescription}
              fullWidth
              minRows={3}
            />
          </Box>
          <Box>
            <Typography variant="caption" color="textSecondary">
              ※回答を始める際に対象者へ通知・表示される文章です。目的や意図が伝わるような説明や依頼文を設定しましょう。
            </Typography>
          </Box>
        </Box>
      </Paper>
    );
  }
);

const StyledTextField = styled(TextFieldDeprecated)`
  color: ${(props) => props.theme.palette.text.muted};

  // TODO: スタイルをfigmaに揃える
  .MuiInput-underline:before {
    border-bottom: 2px solid ${grey["300"]};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 10.5px;
    padding-bottom: 0px;
    height: 1.4em; // アルファベットのgが切れるのを防ぐ
  }
`;

const StyledEventContentTextarea = styled(TextareaAutosize)`
  font-size: 14px;
  height: 72px;
`;
