import React, { FC } from "react";

import { Typography } from "~/components/uiParts";
import { FileMetaData } from "~/infrastructure/usecases/file/fileAPIAdapter";

type Props = {
  file: FileMetaData;
};

export const AttachmentFileLink: FC<Props> = ({ file }) => {
  return (
    <a
      key={file.url}
      href={file.url}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Typography variant="body2" style={{ lineHeight: "24px" }} color="primary">
        {file.name}
      </Typography>
    </a>
  );
};
