import { useCallback, useState } from "react";

import { useSnackbar } from "~/hooks/shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useDownloadFilesByZip = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const downloadFilesByZip = useCallback(
    async ({
      onnFormTaskId,
      onnFormTaskTitle,
    }: {
      onnFormTaskId: string;
      onnFormTaskTitle: string;
    }) => {
      try {
        setIsLoading(true);
        await apiClient.get(
          "/download_onn_task_answer_file_zip",
          {
            "form-task-id": onnFormTaskId,
          },
          {
            fileDownload: {
              fileName: `${onnFormTaskTitle}.zip`,
              isCSV: false,
            },
          }
        );
      } catch (e) {
        enqueueSnackbar("zipファイルのダウンロードに失敗しました。", { variant: "error" });

        captureException({
          error: e as Error,
          tags: { type: "useDownloadFilesByZip:downloadFilesByZip" },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  return { downloadFilesByZip, isLoading };
};
