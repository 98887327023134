import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React from "react";
import styled from "styled-components";

import { ModalContent } from "./ModalContent";

import { useSelectionConditions } from "./useSelectionConditions";

import { Button, ScrollableBodyModal } from "~/components/uiParts";
import { useDownloadNewGraduatesCSV } from "~/hooks/employee/useDownloadNewGraduatesCSV";
type Props = {
  open: boolean;
  onCancel(): void;
  employeeIds: string[];
};

export const DownloadEmployeesWithCSVActionModal = ({
  open,
  onCancel,
  employeeIds,
}: Props): JSX.Element => {
  const { selectedConditionFieldKeys, ...selection } = useSelectionConditions();
  const { downloadNewGraduatesCSV, isLoading } = useDownloadNewGraduatesCSV();

  return (
    <ScrollableBodyModal
      open={open}
      title={"CSVダウンロード｜候補者データ"}
      titleHint="項目名の右側にあるチェックマークで書き出しに含めるかどうか選択することができます。また、項目名の左側にあるアイコンをドラッグ&ドロップすることで順序を並び替えることができます。"
      footer={
        <StyledButtonContainer>
          <Box display={"flex"} gridGap={"16px"}>
            <Box>
              <Button borderRadius="circle" variant="text" color="default" onClick={onCancel}>
                キャンセル
              </Button>
            </Box>
            <Box>
              <Button
                borderRadius="circle"
                variant="contained"
                color="primary"
                onClick={() => {
                  downloadNewGraduatesCSV({
                    employeeIds,
                    conditionFields: selectedConditionFieldKeys,
                  });
                }}
                isLoading={isLoading}
                disabled={isEmpty(selectedConditionFieldKeys)}
              >
                ダウンロード
              </Button>
            </Box>
          </Box>
        </StyledButtonContainer>
      }
      content={<ModalContent {...selection} />}
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;
