import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { mutateBriefingSessionCategories } from "./useBriefingSessionCategories";
import { mutateOnnEvent } from "./useOnnEvent";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/onn_event_api/onn-event"]["PATCH"];

export const useUpdateOnnEventV2 = () => {
  const updateOnnEvent = useCallback(async (body: EndPoint["body"]) => {
    const response = await apiClient.patch(`/onn_event_api/onn-event`, {
      id: body.id,
      updateObject: body.updateObject,
      briefingSessionCategoriesToCreate: body.briefingSessionCategoriesToCreate,
      briefingSessionCategoriesToUpdate: body.briefingSessionCategoriesToUpdate,
    });
    mutateOnnEvent(body.id);
    mutateBriefingSessionCategories({ onnEventId: body.id });
    return response;
  }, []);

  return { updateOnnEvent };
};
