import { OnnEvent } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { convertUnixTimeToDateOnnEvent } from "../../_shared/convertUnixTimeToDate";

export class ListOnnEventsResponse {
  static ResponseBody: {
    onnEvents: ToCloudFunctionsCompatible<OnnEvent>[];
  };

  public static createResponseBody({ onnEvents }: { onnEvents: OnnEvent[] }) {
    return { onnEvents: onnEvents.map((onnEvent) => toCloudFunctionsCompatible(onnEvent)) };
  }

  public static convertUnixTimeToDate(response: typeof this.ResponseBody) {
    return {
      onnEvents: response.onnEvents.map((onnEvent) => convertUnixTimeToDateOnnEvent(onnEvent)),
    };
  }
}
