import { Failure, Result, Success, OnnEventAnswer, OnnEventAnswerOption } from "../../../../domain";
import { toCloudFunctionsCompatible } from "../../../../utils/toCloudFunctionsCompatible";
import { OnnEventAnswerRequestType } from "../shared";

import { updateOnnEventAnswerRequestSchema } from "./schema";

export class UpdateOnnEventAnswerRequest {
  constructor(init: UpdateOnnEventAnswerRequest) {
    this.onnEventAnswerId = init.onnEventAnswerId;
    this.answer = init.answer;
  }

  static readonly actionType = OnnEventAnswerRequestType.actionTypes.updateOnnEventAnswer;
  onnEventAnswerId: OnnEventAnswer["id"];
  answer: OnnEventAnswer["answer"];

  static RequestBody: {
    onnEventAnswerId: OnnEventAnswer["id"];
    answer: OnnEventAnswer["answer"];
  };

  public static createRequestBody({
    onnEventAnswerId,
    answer,
  }: {
    onnEventAnswerId: string;
    answer: Record<string, OnnEventAnswerOption>;
  }) {
    return toCloudFunctionsCompatible({
      actionType: this.actionType,
      onnEventAnswerId,
      answer,
    });
  }

  static readonly validator = updateOnnEventAnswerRequestSchema;

  public static validate(
    data: UpdateOnnEventAnswerRequest
  ): Result<UpdateOnnEventAnswerRequest, Error> {
    const onnEventIdResult = UpdateOnnEventAnswerRequest.validator.safeParse(data);
    if (!onnEventIdResult.success) {
      return new Failure(new Error(onnEventIdResult.error.message));
    }

    return new Success(new UpdateOnnEventAnswerRequest(data));
  }
}
