import { Box } from "@material-ui/core";
import React, { memo } from "react";

import styled from "styled-components";

import { QuestionFormPaper } from "../parts/QuestionFormPaper";

import { BaseProps } from "./types";

import { Checkbox, FormControlLabel, TextFieldDeprecated, Typography } from "~/components/uiParts";

type Props = BaseProps & {
  isWordLimit?: boolean;
  wordLimit?: string;
  onChangeWordLimit: (limit: string) => void;
  onClickIsWordLimit: () => void;
};
export const TextQuestionFormUIMemo = memo<Props>(
  ({
    isRequired,
    onChangeTitle,
    onChangeOptionType,
    onChangeIsRequired,
    onClickDelete,
    onClickDuplication,
    disabledTitle,
    disabledOptionType,
    questionDragHandleProps,
    titleTextField,
    isWordLimit,
    wordLimit,
    onChangeWordLimit,
    onClickIsWordLimit,
  }) => (
    <QuestionFormPaper
      onChangeOptionType={onChangeOptionType}
      onChangeTitle={onChangeTitle}
      selectedOptionType={"TEXT"}
      isRequired={isRequired}
      onChangeIsRequired={onChangeIsRequired}
      onClickDelete={onClickDelete}
      onClickDuplication={onClickDuplication}
      disabledTitle={disabledTitle}
      disabledOptionType={disabledOptionType}
      questionDragHandleProps={questionDragHandleProps}
      titleTextField={titleTextField}
    >
      <Box paddingX={5}>
        <Typography variant="caption" color="textSecondary">
          ※回答者には、テキスト欄が表示され自由記述での回答を行うことができます。
        </Typography>
      </Box>
      <Box marginX={5} marginY={4} display="flex" flexDirection="column">
        <FormControlLabel
          control={<StyledCheckBox checked={isWordLimit} onClick={onClickIsWordLimit} />}
          label={<Typography variant="body2">文字数を制限する</Typography>}
        />
        {isWordLimit && (
          <StyledTextField
            value={wordLimit}
            onChange={(e) => onChangeWordLimit(e.target.value)}
            variant="outlined"
            placeholder="半角数字で入力してください"
            size="small"
          />
        )}
      </Box>
    </QuestionFormPaper>
  )
);

const StyledCheckBox = styled(Checkbox)`
  &.MuiIconButton-root {
    margin-left: 4px;
  }
`;

const StyledTextField = styled(TextFieldDeprecated)`
  &.MuiTextField-root {
    margin-top: 12px;
    margin-left: 32px;
    max-width: 240px;
  }
`;
