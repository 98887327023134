import { Box, Stack } from "@mui/material";
import { RecruitmentStatusWithRelatedInfo } from "@onn/common";
import React, { FC } from "react";

import { Controller, FormProvider } from "react-hook-form";

import { useRecruitmentStatusesForm } from "./hooks/useForm";

import { CustomStatusInputRow } from "./parts/CustomStatusInputRow";
import { StandardStatusInputRow } from "./parts/StandardStatusInputRow";

import {
  Button,
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
  ScrollableBodyModal,
  Typography,
} from "~/components/uiParts";
import { ConfirmModal } from "~/components/uiParts/Modal/ConfirmModal";
import { mutateScenarioTriggersForDisplay } from "~/hooks/scenario/useScenarioTriggersForDisplay";

type Props = {
  open: boolean;
  onCancel: () => void;
  resetSelectedRecruitmentStatus: () => void;
  scenarioId: string;
  recruitmentStatuses: RecruitmentStatusWithRelatedInfo[];
};

export const EditRecruitmentStatusesModal: FC<Props> = ({
  scenarioId,
  recruitmentStatuses,
  open,
  onCancel,
  resetSelectedRecruitmentStatus,
}) => {
  const {
    form,
    separatedFields,
    changeOrder,
    addStatus,
    removeStatus,
    handleSubmit,
    handleClickSaveButton,
    isSubmitButtonDisabled,
    isOpenConfirmDeleteModal,
    setIsOpenConfirmDeleteModal,
    isOpenConfirmEditModal,
    setIsOpenConfirmEditModal,
  } = useRecruitmentStatusesForm({
    scenarioId,
    recruitmentStatuses,
    onSuccess: () => {
      mutateScenarioTriggersForDisplay();
      resetSelectedRecruitmentStatus();
      onCancel();
    },
  });

  return (
    <>
      <ScrollableBodyModal
        open={open}
        disableBackdropModal
        title="選考ステータス設定"
        content={
          <FormProvider {...form}>
            <Stack direction="column" alignItems="flex-start" textAlign="center" spacing={3}>
              <Typography variant="body1" style={{ width: "100%" }}>
                シナリオに含める選考ステータスを設定してください
              </Typography>
              <Stack
                direction="column"
                alignItems="flex-start"
                textAlign="center"
                width="100%"
                spacing={3}
              >
                <Stack direction="column" alignItems="flex-start" width="100%">
                  {separatedFields.preEntry.map((status) => (
                    <Stack
                      key={status.id}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      width="100%"
                    >
                      <Box minWidth="40px" height="56px" />
                      <Controller
                        control={form.control}
                        name={`recruitmentStatuses.${status.index}.label`}
                        render={({ field, fieldState }) => (
                          <StandardStatusInputRow
                            onChange={field.onChange}
                            label={field.value}
                            type={status.type}
                            errorText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Stack>
                  ))}

                  <DnDProvider onDragEnd={changeOrder}>
                    <Box width="100%">
                      <DnDDroppable droppableId="droppableId" isDropDisabled={false}>
                        {separatedFields.screening.map((status) => (
                          <DnDDraggable
                            draggableId={status.id}
                            key={status.id}
                            index={status.index}
                            isDragDisabled={false}
                            shouldRoundCorner
                          >
                            <Stack direction="row" alignItems="start" spacing={1} width="100%">
                              <Box px={1} py={2} display="flex" justifyContent="center">
                                <Icon icon="gripVertical" size="md" color="grey400" />
                              </Box>
                              <Controller
                                key={status.id}
                                control={form.control}
                                name={`recruitmentStatuses.${status.index}.label`}
                                render={({ field, fieldState }) => (
                                  <CustomStatusInputRow
                                    onChange={field.onChange}
                                    label={field.value}
                                    errorText={fieldState.error?.message}
                                    onClickRemoveButton={() => removeStatus(status.index)}
                                    disableRemoveButton={status.isRelatedEmployee}
                                  />
                                )}
                              />
                            </Stack>
                          </DnDDraggable>
                        ))}
                      </DnDDroppable>
                    </Box>
                  </DnDProvider>
                  {separatedFields.others.map((status) => (
                    <Stack
                      key={status.id}
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      width="100%"
                    >
                      <Box minWidth="40px" height="56px" />
                      <Controller
                        key={status.id}
                        control={form.control}
                        name={`recruitmentStatuses.${status.index}.label`}
                        render={({ field, fieldState }) => (
                          <StandardStatusInputRow
                            onChange={field.onChange}
                            label={field.value}
                            type={status.type}
                            errorText={fieldState.error?.message}
                          />
                        )}
                      />
                    </Stack>
                  ))}
                </Stack>
                <Button
                  variant="text"
                  borderRadius="regular"
                  onClick={addStatus}
                  color="primary"
                  startIcon={<Icon icon="add" color="primary" size="md" />}
                >
                  項目を追加
                </Button>
              </Stack>
            </Stack>
          </FormProvider>
        }
        footer={
          <Stack direction="row" spacing={2}>
            <Button variant="text" borderRadius="circle" color="default" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={handleClickSaveButton}
              disabled={isSubmitButtonDisabled}
              isLoading={form.formState.isSubmitting}
              type="submit"
            >
              変更を保存
            </Button>
          </Stack>
        }
        onCancel={onCancel}
      />
      {/* NOTE: useModalを使用するとEditRecruitmentStatusesModal自体が閉じられてしまうため、このコンポーネントでモーダルをレンダリングしている */}
      <ConfirmModal
        open={isOpenConfirmDeleteModal}
        onCancel={() => setIsOpenConfirmDeleteModal(false)}
        title="選考ステータス設定"
        onClickAccept={handleSubmit}
        mainContent={`選考ステータスが削除されています。\n選考ステータスに設定されているシナリオも全て削除されます。\n本当に変更を保存しますか？`}
        subContent={`この操作は取り消すことができません`}
        acceptButtonColor="secondary"
        cancelLabel="戻る"
        acceptLabel="変更を保存"
      />
      <ConfirmModal
        open={isOpenConfirmEditModal}
        onCancel={() => setIsOpenConfirmEditModal(false)}
        title="選考ステータス設定"
        onClickAccept={handleSubmit}
        mainContent={`既にシナリオが設定されている選考ステータスが編集されています。\n変更を保存しますか？`}
        subContent={`この操作は取り消すことができません`}
        acceptButtonColor="secondary"
        cancelLabel="戻る"
        acceptLabel="変更を保存"
      />
    </>
  );
};
