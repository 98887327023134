import React, { FC } from "react";
import styled from "styled-components";

import { TextareaAutosize } from "~/components/uiParts";

type Props = React.ComponentProps<typeof TextareaAutosize>;

export const OnnEventContentTextArea: FC<Props> = (props) => {
  return (
    <StyledTextarea
      name="content"
      placeholder="イベントの説明文を入力してください"
      maxTextCount={1000}
      fullWidth
      minRows={2}
      autoFocus
      {...props}
    />
  );
};

const StyledTextarea = styled(TextareaAutosize)`
  font-size: 14px;
  height: 72px;
`;
