import { Box, Tooltip as MuiTooltip } from "@mui/material";
import { OnnEventEvaluation } from "@onn/common";
import React, { FC } from "react";

import { NoneCell } from "../../../common/NoneCell";
import { Cell } from "../../_share/Cell";

import { Button, Icon, Tooltip, Typography } from "~/components/uiParts";
import theme from "~/config/theme";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

export type EvaluationCellProps = {
  onnEventId: string;
  evaluation?: OnnEventEvaluation;
  onnEventEvaluationRankLabel?: string;
  newGraduateName: string;
  newGraduateId: string;
  isExistOnnEventDeterminingDate: boolean;
};

export const EvaluationCell: FC<EvaluationCellProps> = (props) => {
  if (!props.isExistOnnEventDeterminingDate) return <NoneCell />;
  return <EvaluationCellCore {...props} />;
};

export const EvaluationCellCore: FC<EvaluationCellProps> = ({
  onnEventId,
  evaluation,
  onnEventEvaluationRankLabel,
  newGraduateId,
  newGraduateName,
}) => {
  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();

  const handleOpenEvaluationModal = () => {
    // 評価入力モーダルを開くときは必ず渡される
    handleModal({
      name: "editOnnEventEvaluationModal",
      args: {
        onnEventId: onnEventId,
        newGraduateName,
        newGraduateId,
        alreadyRegisteredOnnEventEvaluation: evaluation,
      },
    });
  };
  return (
    <Cell>
      {evaluation ? (
        <EvaluationMemoTooltip text={evaluation.text || ""}>
          <Box>
            <Button
              color="primary"
              variant="text"
              borderRadius="regular"
              endIcon={<Icon icon="memo" color="primary" size="md" />}
              onClick={handleOpenEvaluationModal}
              disabled={!currentUser.isAdmin()}
            >
              <Typography variant="body2" color="primary" bold>
                {onnEventEvaluationRankLabel}
              </Typography>
            </Button>
          </Box>
        </EvaluationMemoTooltip>
      ) : (
        <Tooltip
          arrow
          placement="bottom"
          title={!currentUser.isAdmin() && "管理者のみが評価を入力できます"}
        >
          <Button
            color="primary"
            variant="text"
            borderRadius="regular"
            startIcon={<Icon icon="add" color="secondary" size="sm" />}
            onClick={handleOpenEvaluationModal}
            disabled={!currentUser.isAdmin()}
          >
            <Typography variant="body2" color="secondary" bold>
              評価を入力
            </Typography>
          </Button>
        </Tooltip>
      )}
    </Cell>
  );
};

const EvaluationMemoTooltip: FC<{ text: string; children: React.ReactElement }> = ({
  text,
  children,
}) => {
  return (
    <MuiTooltip
      placement="bottom"
      title={
        <Box
          display={"flex"}
          flexDirection={"column"}
          style={{
            gap: "16px",
          }}
          padding={"16px"}
          sx={{
            boxShadow: 3,
            borderRadius: "16px",
          }}
        >
          <Typography variant="body2" bold>
            評価メモ
          </Typography>
          <Typography variant="body2">{text}</Typography>
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
            borderRadius: "16px",
            p: 0,
            m: 0,
          },
        },
      }}
    >
      {children}
    </MuiTooltip>
  );
};
