import { FormRevision, OnnFormTask, APISchema, OnnFormTaskAnswer, Employee } from "@onn/common";
import { isArray } from "lodash";
import useSWR from "swr";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

const generateKey = ({
  newGraduateId,
  onnTaskId,
}: {
  newGraduateId: string;
  onnTaskId: string;
}) => {
  return {
    endpoint: "/api/onn-form-task-answers",
    newGraduateId,
    onnTaskId,
  } as const;
};

type EndPoint = APISchema["/api/onn-form-task-answers"]["GET"];

export const useOnnFormTasksAnswerForAdmin = ({
  newGraduateId,
  onnTaskId,
}: {
  newGraduateId: string;
  onnTaskId: string;
}) => {
  return useSWR(generateKey({ newGraduateId, onnTaskId }), async ({ endpoint }) => {
    const query: EndPoint["query"] = {
      "new-graduate-id": newGraduateId,
      "onn-task-id": onnTaskId,
    };
    const response = await apiClient.get(endpoint, query);
    if (isArray(response.data)) {
      captureException({
        error: new Error("onnエンドポイントから想定外のレスポンスが返ってきています"),
        tags: {
          type: "useOnnFormTasksAnswersForAdmin",
        },
        extras: {
          response,
          query,
          endpoint,
        },
      });
      return null;
    }
    return response.data
      ? {
          newGraduate: new Employee(response.data.newGraduate),
          onnTask: new OnnFormTask(response.data.onnTask),
          onnFormTaskAnswer: new OnnFormTaskAnswer(response.data.onnFormTaskAnswer),
          relatedFormRevision: response.data.relatedFormRevision
            ? new FormRevision(response.data.relatedFormRevision)
            : null,
        }
      : null;
  });
};
