import { useCallback } from "react";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { generateNewGraduateHost } from "~/libs";

/**
 * 個別QR・URLのURLを生成する
 */
export const useGenerateRegistrationIndividualLinkUrl = () => {
  const { currentSpace } = useCurrentSpace();

  const generateRegistrationIndividualLinkUrl = useCallback(
    (id: string) => {
      const baseUrl = generateNewGraduateHost(currentSpace.tenantId);
      const url = new URL(`/portal/accounts/invitations/${id}`, baseUrl);

      return url.toString();
    },
    [currentSpace.tenantId]
  );

  return { generateRegistrationIndividualLinkUrl };
};
