import { z } from "zod";

/////////////////////////////////////////
// DELIVER ONN TASK ACTION SETTING SCHEMA
/////////////////////////////////////////

export const DeliverOnnTaskActionSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  actionId: z.string(),
  onnTaskId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type DeliverOnnTaskActionSetting = z.infer<typeof DeliverOnnTaskActionSettingSchema>;

/////////////////////////////////////////
// DELIVER ONN TASK ACTION SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const DeliverOnnTaskActionSettingOptionalDefaultsSchema =
  DeliverOnnTaskActionSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type DeliverOnnTaskActionSettingOptionalDefaults = z.infer<
  typeof DeliverOnnTaskActionSettingOptionalDefaultsSchema
>;

export default DeliverOnnTaskActionSettingSchema;
