import { Box } from "@material-ui/core";
import { BriefingSessionCategory } from "@onn/common";
import { BriefingSessionEvent } from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { FC } from "react";

import { FormProvider } from "react-hook-form";

import { ContentDescriptionTypography, ActionFooter } from "../../_shared/createAndEdit";

import { BriefingSessionsForm } from "./components/BriefingSessionsForm";
import { OnnEventContentInputForm } from "./components/OnnEventContentInputForm";
import { OnnEventTitleInputForm } from "./components/OnnEventTitleInputForm";
import { RestrictAnswerForm } from "./components/RestrictAnswerForm";

import { usePage } from "./usePage";

import { Paper, Typography } from "~/components/uiParts";

type Props =
  | {
      mode: "create";
    }
  | {
      mode: "edit";
      onnEvent: BriefingSessionEvent;
      briefingSessionCategories: BriefingSessionCategory[];
    };

export const CreateOrEditBriefingSessionEvent: FC<Props> = (props) => {
  const { mode } = props;
  const {
    form,
    onClickSaveAndToNext,
    onClickCancel,
    isSaveButtonDisabled,
    disabledSessionCategoriesDeleteIndexes,
  } = usePage(props);

  return (
    <FormProvider {...form}>
      <Box display="flex" justifyContent="center">
        <Box width="800px" display="flex" flexDirection="column" gridRowGap={40} pb={8}>
          <Typography variant="h4" bold>
            {mode === "create" ? "イベント作成" : "イベント編集"}
          </Typography>
          <Paper style={{ display: "flex", flexDirection: "column", rowGap: 32 }}>
            <Box display="flex" flexDirection="column" gridRowGap={16}>
              <Box display="flex" flexDirection="column" gridRowGap={32}>
                <OnnEventTitleInputForm />
                <OnnEventContentInputForm />
              </Box>
              <ContentDescriptionTypography />
            </Box>
          </Paper>
          <Paper style={{ display: "flex", flexDirection: "column", rowGap: 40 }}>
            <BriefingSessionsForm disabledDeleteIndexes={disabledSessionCategoriesDeleteIndexes} />
            <RestrictAnswerForm />
          </Paper>
          <ActionFooter
            onClickSaveAndToNext={onClickSaveAndToNext}
            onClickCancel={onClickCancel}
            isReadyToOpenPortalOnnEventPreview={false}
            isDisableSaveButton={isSaveButtonDisabled}
          />
        </Box>
      </Box>
    </FormProvider>
  );
};
