import { z } from "zod";

/////////////////////////////////////////
// CONTACT ROOM PIN SCHEMA
/////////////////////////////////////////

export const ContactRoomPinSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  contactRoomId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ContactRoomPin = z.infer<typeof ContactRoomPinSchema>;

/////////////////////////////////////////
// CONTACT ROOM PIN OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ContactRoomPinOptionalDefaultsSchema = ContactRoomPinSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type ContactRoomPinOptionalDefaults = z.infer<typeof ContactRoomPinOptionalDefaultsSchema>;

export default ContactRoomPinSchema;
