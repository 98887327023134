import { Menu, MenuItem, MenuProps } from "@material-ui/core";
import { Box, PopoverOrigin } from "@mui/material";
import { AnyTriggerSetting } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon, Typography } from "~/components/uiParts";
import { IconType } from "~/components/uiParts/Icon";
import theme from "~/config/theme";
type Props = {
  anchorEl: MenuProps["anchorEl"];
  onClose: () => void;
  isOpen: boolean;
  onSelectTriggerSettingsType: (type: AnyTriggerSetting["type"]) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

const triggerSettingItemMap: {
  icon: IconType;
  label: string;
  description: string;
  type: AnyTriggerSetting["type"];
}[] = [
  {
    icon: "checkOutlineCircle",
    label: "タスクが「回答済み」になった時",
    description: "候補者により対象タスクのステータスが「回答済み」になった際に発火されます。",
    type: "AnswerOnnTaskTriggerSetting",
  },
  {
    icon: "calendar",
    label: "イベントのステータスが変更された時",
    description: "候補者または管理者により対象イベントのステータスが変更された際に発火されます。",
    type: "ChangeStatusOfOnnEventTriggerSetting",
  },
  {
    icon: "memo",
    label: "候補者の評価が入力された時",
    description: "選考タイプのイベントで選考担当からの評価が入力された際に発火されます。",
    type: "EvaluateOnnEventTriggerSetting",
  },
  {
    icon: "statusChange",
    label: "選考ステータスが変更された時",
    description: "選考ステータスが手動または自動で変更された際に発火されます。",
    type: "ChangeRecruitmentStatusTriggerSetting",
  },
];

// NOTE: anchorElを使いまわしたいので、UncontrolledMenuはつかわない
export const MenuOfTriggerSelection: FC<Props> = ({
  anchorEl,
  onClose,
  isOpen,
  onSelectTriggerSettingsType,
  anchorOrigin,
  transformOrigin,
}) => {
  return (
    <Menu
      id={"MenuOfActionSelection"}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={
        anchorOrigin || {
          vertical: "bottom",
          horizontal: "left",
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: "top",
          horizontal: "left",
        }
      }
      MenuListProps={{
        style: {
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "400px",
        },
      }}
    >
      {triggerSettingItemMap.map((item, index) => (
        <TriggerMenuItem
          key={index}
          icon={item.icon}
          label={item.label}
          description={item.description}
          onClick={() => onSelectTriggerSettingsType(item.type)}
        />
      ))}
    </Menu>
  );
};

const TriggerMenuItem: FC<{
  icon: IconType;
  label: string;
  description: string;
  onClick: () => void;
}> = ({ icon, label, description, onClick }) => {
  return (
    <StyledMenuItem onClick={onClick}>
      <Box height={"24px"} width={"24px"}>
        <Icon icon={icon} size="md" color="grey" />
      </Box>
      <Box display="flex" alignItems="center" columnGap="8px">
        <Box flexDirection="column" rowGap="4px">
          <Typography variant="body2" bold style={{ flexGrow: 1, color: theme.palette.grey[500] }}>
            {label}
          </Typography>
          <Typography variant="caption" style={{ flexGrow: 1, color: theme.palette.grey[500] }}>
            {description}
          </Typography>
        </Box>
        <Icon icon="chevronRight" size="md" color="grey" />
      </Box>
    </StyledMenuItem>
  );
};

const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root {
    padding: 8px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    gap: 8px;
    border-radius: 4px;
  }
`;
