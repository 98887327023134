import { useMemo } from "react";

export const useToggleSelectAllForIds = ({
  options,
  selectedOptions,
}: {
  options: Set<string>;
  selectedOptions: Set<string>;
}) => {
  const stateType: StateType = useMemo(() => {
    if (0 === selectedOptions.size) {
      return "isUnchecked";
    }
    const isCheckedAll = Array.from(options).every((id) => selectedOptions.has(id));

    return isCheckedAll ? "isCheckedAll" : "isCheckedSome";
  }, [options, selectedOptions]);

  const state: State = useMemo(() => {
    const isSelected = stateType !== "isUnchecked";
    const label = isSelected
      ? {
          text: "選択中",
          count: selectedOptions.size,
        }
      : {
          text: "全て選択",
          count: options.size,
        };

    switch (stateType) {
      case "isCheckedAll":
        return {
          stateType,
          checked: true,
          indeterminate: false,
          label,
        };
      case "isCheckedSome":
        return {
          stateType,
          checked: true,
          indeterminate: true,
          label,
        };
      case "isUnchecked":
        return {
          stateType,
          checked: false,
          indeterminate: false,
          label,
        };
    }
  }, [options.size, selectedOptions.size, stateType]);

  const toggleSelectAll = (): Set<string> => {
    const isSelectedAll = stateType === "isCheckedAll";
    const newIsSelectedAll = !isSelectedAll;

    if (newIsSelectedAll) {
      return new Set(options);
    } else {
      return new Set();
    }
  };

  return { toggleSelectAll, allSelectionState: state };
};

type StateType = "isCheckedAll" | "isCheckedSome" | "isUnchecked";
export type State = {
  stateType: StateType;
  checked: boolean;
  indeterminate: boolean;
  label: {
    text: string;
    count: number;
  };
};
