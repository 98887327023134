import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { useGetBorderColorById } from "../../hooks";

import { Typography, UserIconGroup, Button } from "~/components/uiParts";

type Props = {
  newcomerEmployee: AugmentedRequired<Employee, "joinAt">;
  users: Employee[];
  isDisplayMessage: boolean;
  onClickButton?: () => void;
};

export const BeforeJoinOneDayAgo: FC<Props> = ({
  newcomerEmployee,
  users,
  isDisplayMessage,
  onClickButton,
}) => {
  const { getBorderColorById } = useGetBorderColorById();
  return (
    <>
      <Box textAlign="left">
        <Typography variant="caption" color="textSecondary">
          入社まであと
        </Typography>
      </Box>
      <Box textAlign="center">
        <Box height="16px" />
        <Typography variant="h1" color="primary" display="inline" bold>
          1
        </Typography>
        <Box display="inline" m="2px" />
        <Typography variant="h2" color="primary" display="inline" bold>
          day
        </Typography>

        <Box height="16px" />

        <Box lineHeight={2} textAlign="center">
          <Typography variant="body2">
            {isDisplayMessage
              ? `オンボーディングチームから届いた\nメッセージを確認しましょう！`
              : `${newcomerEmployee.lastName}さんの入社を\nメンバー全員で楽しみにお待ちしています！`}
          </Typography>
        </Box>
        <Box height="16px" />
      </Box>

      <StyledUserIconGroup
        usersInfo={users.map((u) => ({
          username: u.getName(),
          profileIconImageUrl: u.profileIconImageUrl,
          borderColor: getBorderColorById(newcomerEmployee, u.id),
        }))}
        max={users.length}
        iconSize="small"
      />

      {isDisplayMessage && (
        <Box mt="16px" display="flex" justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            borderRadius="circle"
            fullWidth
            onClick={onClickButton}
          >
            Welcomeメッセージを見る
          </Button>
        </Box>
      )}
    </>
  );
};

const StyledUserIconGroup = styled(UserIconGroup)`
  display: flex;
  justify-content: center;
`;
