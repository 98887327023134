import {
  TenantSettings,
  SelectableFeatures,
  Message,
  GeneralTaskSettings,
  EmployeeTag,
} from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  updateDoc,
  where,
  query,
  getDoc,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { v4 } from "uuid";

import { firestore } from "~/config/firebase";
import { ITenantSettingsRepository } from "~/service/repository/ITenantSettingsRepository";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

const COLLECTION_NAME = "tenantSettings";

type TenantSettingsForDb = Omit<TenantSettings, "messages" | "employeeTags"> & {
  messages: MessageForDB[];
  employeeTags?: EmployeeTagForDB[];
  invitationMessage: InvitationMessageForDB;
};
type MessageForDB = Omit<ConstructorParameters<typeof Message>[0], "createdAt" | "updatedAt"> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};
type EmployeeTagForDB = Omit<
  ConstructorParameters<typeof EmployeeTag>[0],
  "createdAt" | "updatedAt"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};
type InvitationMessageForDB = Omit<
  ConstructorParameters<typeof Message>[0],
  "createdAt" | "updatedAt"
> & {
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export class TenantSettingsRepository implements ITenantSettingsRepository {
  async get(tenantId: string): Promise<TenantSettings> {
    const refs = await getDocs(query(this.collection(), where("tenantId", "==", tenantId)));
    if (refs.docs.length === 0) {
      throw new Error("no tenantSettings found");
    }
    const doc = refs.docs[0] as (typeof refs.docs)[number];
    return this.dbToObject(doc.data() as TenantSettingsForDb);
  }

  async updateFeature(
    tenantId: string,
    features: Record<SelectableFeatures, boolean>
  ): Promise<void> {
    const refs = await getDocs(query(this.collection(), where("tenantId", "==", tenantId)));
    if (refs.docs.length === 0) {
      throw new Error("no tenantSettings found");
    }
    const doc = refs.docs[0] as (typeof refs.docs)[number];
    await updateDoc(doc.ref, { features });
  }

  /**
   * @deprecated
   * この関数はtenantIdを使って更新しているので非推奨です。(将来的にtenantSettingsに同一tenantIdが含まれる場合にバグを起こす可能性があるためです)
   * newUpdateを使ってください。
   * TODO: 全て置き換えた時にnewUpdateをリネームする。
   */
  async update(tenantId: string, updates: Partial<TenantSettings>): Promise<void> {
    const refs = await getDocs(query(this.collection(), where("tenantId", "==", tenantId)));
    if (refs.docs.length === 0) {
      throw new Error("no tenantSettings found");
    }
    const doc = refs.docs[0] as (typeof refs.docs)[number];

    await updateDoc(doc.ref, {
      ...updates,
    });
  }

  async newUpdate(id: string, updates: Partial<TenantSettings>): Promise<void> {
    const doc = await getDoc(this.doc(id));

    await updateDoc(doc.ref, {
      ...updates,
    });
  }

  async updateMessage(tenantId: string, message: Message): Promise<void> {
    const refs = await getDocs(query(this.collection(), where("tenantId", "==", tenantId)));

    if (!refs.docs.length) {
      throw new Error("no tenantSettings found");
    }
    const doc = refs.docs[0] as (typeof refs.docs)[number];
    const tenantSetting = doc.data();

    const id = v4();
    const now = convertDateToTimestamp(new Date());

    let messages: MessageForDB[];
    if (tenantSetting.messages) {
      const index = tenantSetting.messages.findIndex(
        (existMessage: MessageForDB) => existMessage.id === message.id
      );
      if (index >= 0) {
        // update
        messages = tenantSetting.messages;
        messages[index] = {
          ...message,
          createdAt: convertDateToTimestamp(message.createdAt),
          updatedAt: now,
        };
      } else {
        // create
        messages = [...tenantSetting.messages, { ...message, id, createdAt: now, updatedAt: now }];
      }
    } else {
      // messagesが無いとき
      messages = [{ ...message, id, createdAt: now, updatedAt: now }];
    }

    await updateDoc(doc.ref, { messages });
  }

  private dbToObject(data: TenantSettingsForDb): TenantSettings {
    const messages =
      data.messages?.map(
        (message) =>
          new Message({
            ...message,
            createdAt: convertTimestampToDate(message.createdAt),
            updatedAt: convertTimestampToDate(message.updatedAt),
          })
      ) ?? [];

    return new TenantSettings({
      id: data.id,
      tenantId: data.tenantId,
      newcomerIntroduction: data.newcomerIntroduction,
      invitationMessage: data.invitationMessage,
      contact: data.contact,
      generalTask: GeneralTaskSettings.create(data.generalTask),
      features: data.features,
      messages,
      ignoreMailsForInviteMail: data.ignoreMailsForInviteMail,
      greetingMessageTemplate: data.greetingMessageTemplate,
      whiteIpAddresses: data.whiteIpAddresses,
      notifyAdminsOfMessageFromUnknownUser: data.notifyAdminsOfMessageFromUnknownUser,
    });
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }
}
