import liff from "@line/liff";
import useSWR from "swr";

import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";

const getKey = (tenantId: string | null | undefined) => {
  return { endpoint: "/liff/line-user-profile", tenantId } as const;
};
// プロバイダーごとにLINEユーザーは同一のはずだが、念の為テナントごとに再取得する

export const useLineUserProfile = () => {
  const tenantId = getTenantIdFromDomain();

  return useSWR(getKey(tenantId), async () => {
    if (!liff.isInClient()) return null;

    const lineUserProfile = await liff.getProfile().catch(() => undefined);
    return lineUserProfile || null;
  });
};
