import { Box } from "@material-ui/core";
import { DEFAULT_MAX_FILE_SIZE_MB, DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES } from "@onn/common";
import React, { memo, useState } from "react";
import styled from "styled-components";

import { v4 } from "uuid";

import { ActionFooterWithPreview } from "../../ActionFooterWithPreview";
import { usePage } from "../hooks/usePage";

import {
  Paper,
  TextFieldDeprecated,
  TextareaAutosizeAttachableFile,
  Typography,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { NotFound } from "~/pages/NotFound";

export const Page = (): JSX.Element => {
  const [fixedAnnouncementId] = useState(v4());
  const { currentUser } = useCurrentUser();

  const {
    isDisabledSaveButton,
    onClickCancel,
    onClickSaveAndToNext,
    onClickPreview,
    isSaving,
    titleInput,
    contentInput,
    filesInput,
  } = usePage({ fixedAnnouncementId });

  if (!currentUser.isAdmin()) return <NotFound />;

  return (
    <Box display="flex" justifyContent="center">
      <Box width="800px">
        <Typography variant="h4" bold>
          お知らせ作成
        </Typography>
        <ContainerPaper>
          <Box mb={"32px"}>
            <TitleTextField
              value={titleInput.title}
              onChange={(v) => titleInput.onChangeTitle(v)}
              isError={titleInput.isDirtyTitle && titleInput.titleInputError.error}
              helperText={titleInput.isDirtyTitle ? titleInput.titleInputError.helperText : ""}
            />
          </Box>
          <Box mb={"12px"}>
            <ContentTextareaAutosizeAttachableFile
              content={contentInput.content}
              files={filesInput.files}
              onChangeFiles={(v) => {
                filesInput.onChangeFiles(v);
              }}
              onChangeContent={(v) => contentInput.onChangeContent(v)}
              isError={contentInput.isDirtyContent && contentInput.contentInputError.error}
              helperText={
                contentInput.isDirtyContent ? contentInput.contentInputError.helperText : ""
              }
            />
          </Box>
          <ContentDescriptionTypography />
        </ContainerPaper>
        <ActionFooterWithPreview
          isDisableSaveButton={isDisabledSaveButton}
          onClickSaveAndToNext={onClickSaveAndToNext}
          onClickCancel={onClickCancel}
          onClickPreview={onClickPreview}
          buttonText={"保存して次へ"}
          isSaving={isSaving}
        />
      </Box>
    </Box>
  );
};

const TitleTextField = ({
  value,
  onChange,
  isError,
  helperText,
}: {
  value: string;
  onChange: (v: string) => void;
  isError: boolean;
  helperText: string;
}) => {
  return (
    <StyledTextField
      maxTextCount={50}
      name="title"
      placeholder="タイトルを入力してください"
      fullWidth
      value={value}
      onChange={(e) => onChange(e.target.value)}
      error={isError}
      autoFocus
      helperText={helperText}
    />
  );
};

const ContentTextareaAutosizeAttachableFile = memo(
  ({
    content,
    files,
    isError,
    helperText,
    onChangeContent,
    onChangeFiles,
  }: {
    content: string;
    files: (File | Pick<File, "name">)[];
    onChangeContent: (v: string) => void;
    onChangeFiles: (v: (File | Pick<File, "name">)[]) => Promise<void> | void;
    isError: boolean;
    helperText: string;
  }) => {
    return (
      <StyledContentTextareaAutosizeAttachableFile
        value={content}
        fullWidth
        placeholder="お知らせの説明文を入力してください"
        onChange={(e) => onChangeContent(e.target.value)}
        minRows={15}
        maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
        accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
        onChangeFiles={onChangeFiles}
        attachedFiles={files}
        error={isError}
        helperText={helperText}
      />
    );
  }
);

const StyledTextField = styled(TextFieldDeprecated)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 16px;
    padding-bottom: 0px;
    height: 1.4em; // アルファベットのgが切れるのを防ぐ
  }
`;

const StyledContentTextareaAutosizeAttachableFile = styled(TextareaAutosizeAttachableFile)`
  font-size: 14px;
  height: 72px;
`;

const ContentDescriptionTypography = React.memo(() => {
  return (
    <StyledTypography variant="caption">
      ※対象者へ通知・表示される文章です。アナウンスの目的や意図が伝わるような説明や依頼文を設定しましょう。
    </StyledTypography>
  );
});

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.text.muted};
`;

const ContainerPaper = styled(Paper)<{
  $isLast?: boolean;
}>`
  padding: 40px;
  margin-top: 40px;
  margin-bottom: ${(props) => (props.$isLast ? "80px" : "0")};
`;
