import { OnnTask } from "@onn/common";
import { useCallback } from "react";

export const useExtractAssigneeEmployeeIds = () => {
  /**
   * OnnTasksにアサインされているユーザーのidを取得する
   * @param {OnnTask[]} onnTasks
   */
  const extractAssigneeEmployeeIds = useCallback((onnTasks: OnnTask[]) => {
    const assigneeIds = onnTasks.map((onnTask) => {
      return onnTask.assigneeId;
    });

    return Array.from(new Set(assigneeIds));
  }, []);

  return { extractAssigneeEmployeeIds };
};
