import { TableBody } from "@material-ui/core";
import { Table, TableHead } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Row } from "./Body/Row";
import { TableHeaderRow } from "./Header/TableHeaderRow";

type Props = {
  employee: NewGraduateToDisplay;
};

export const RecruitmentStatusTable: FC<Props> = ({ employee }) => {
  return (
    <StyledTable
      sx={{
        tableLayout: "fixed",
      }}
    >
      <TableHead>
        <TableHeaderRow />
      </TableHead>
      <TableBody>
        <Row employee={employee} />
      </TableBody>
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &.MuiTable-root {
    table-layout: fixed;
  }

  .MuiTableRow-root {
    .MuiTableCell-body,
    .MuiTableCell-head {
      border-bottom: 1px solid ${(props) => props.theme.palette.grey[100]};
    }
  }
`;
