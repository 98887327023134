import { Box } from "@material-ui/core";
import { Announcement } from "@onn/common";
import React from "react";

import { Typography, Loading } from "~/components/uiParts";

import { UserIcon } from "~/components/uiParts/UserIcon";
import { useEmployee } from "~/hooks/employee";

export const CreatorCell = ({ announcement }: { announcement: Announcement }) => {
  const { data: employees, isLoading } = useEmployee(announcement.creatorId);

  if (isLoading) return <Loading size="small" fullHeight={false} />;
  if (!employees) return <></>;

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
      <UserIcon
        username={employees.getName()}
        profileIconImageUrl={employees.profileIconImageUrl}
        size={"small"}
        circular={true}
      />
      <Box ml={"8px"}>
        <Typography variant={"caption"} color="textPrimary" noWrap>
          {employees.getName()}
        </Typography>
      </Box>
    </Box>
  );
};
