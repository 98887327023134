import { Box } from "@mui/material";
import { RichMenuCellType } from "@onn/common";
import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import styled from "styled-components";

import { InputState } from "../../useForm";

import { positions } from "./const";

import { SelectForm, TextField } from "~/components/uiParts";

type Position = (typeof positions)[number];

type PortalRichMenuCellType = Exclude<RichMenuCellType, "PORTAL_REGISTRATION_LINK">;
type RegistrationRichMenuCellType = Extract<
  RichMenuCellType,
  "PORTAL_REGISTRATION_LINK" | "PORTAL_EXTERNAL_PAGE_LINK" | "MESSAGE_ACTION_LINK"
>;
type PortalRichMenuCellTypeToLabel = Record<PortalRichMenuCellType, string>;
type RegistrationRichMenuCellTypeToLabel = Record<RegistrationRichMenuCellType, string>;

const portalRichMenuCellTypeToLabel: PortalRichMenuCellTypeToLabel = {
  PORTAL_TOP_LINK: "トップページ",
  PORTAL_EVENT_LIST_LINK: "イベント一覧",
  PORTAL_TASK_LIST_LINK: "タスク一覧",
  PORTAL_ANNOUNCEMENT_LIST_LINK: "お知らせ一覧",
  PORTAL_ACCOUNT_SETTING_LINK: "アカウント設定",
  PORTAL_EXTERNAL_PAGE_LINK: "外部リンク",
  MESSAGE_ACTION_LINK: "メッセージアクション",
};
const registrationRichMenuCellTypeToLabel: RegistrationRichMenuCellTypeToLabel = {
  PORTAL_REGISTRATION_LINK: "登録",
  PORTAL_EXTERNAL_PAGE_LINK: "外部リンク",
  MESSAGE_ACTION_LINK: "メッセージアクション",
};

const richMenuCellTypesToLabelToOptions = (
  object: RegistrationRichMenuCellTypeToLabel | PortalRichMenuCellTypeToLabel
) =>
  Object.entries(object)
    .map(([value, name]) => ({
      value,
      name,
    }))
    .concat({ value: "", name: "------" });

type CellLinkSettingFormName<
  T extends Position,
  K extends "messageActionText" | "type" | "externalLinkUrl"
> = `cellLinkSetting.${T}.${K}`;

export const RichMenuCellTypeSelector: FC<{
  position: Position;
  isPortalRichMenu: boolean;
}> = ({ isPortalRichMenu, position }) => {
  const { control, resetField } = useFormContext<InputState>();
  const formName: CellLinkSettingFormName<
    typeof position,
    "type"
  > = `cellLinkSetting.${position}.type`;
  const extenalLinkFormName: CellLinkSettingFormName<
    typeof position,
    "externalLinkUrl"
  > = `cellLinkSetting.${position}.externalLinkUrl`;
  const messageActionTextFormName: CellLinkSettingFormName<
    typeof position,
    "messageActionText"
  > = `cellLinkSetting.${position}.messageActionText`;

  const {
    field: { value, ...field },
    fieldState: { error },
  } = useController({ control, name: formName });
  const errorMessage = error?.message;
  const menuItems = richMenuCellTypesToLabelToOptions(
    isPortalRichMenu ? portalRichMenuCellTypeToLabel : registrationRichMenuCellTypeToLabel
  );
  // NOTE: 「外部リンク入力」「テキストメッセージ」のinputをエラー状態にした状態でセルタイプを変更した場合、エラー状態を解除するため
  const onChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    field.onChange(e);
    resetField(extenalLinkFormName);
    resetField(messageActionTextFormName);
  };

  const CellTypeSelector: FC = () => {
    return (
      <StyledSelectForm
        errorText={errorMessage}
        menuItems={menuItems}
        fullWidth
        onChange={onChange}
        selected={value}
      />
    );
  };

  return (
    <Box display={"flex"} columnGap={"8px"}>
      <CellTypeSelector />
      {value === "MESSAGE_ACTION_LINK" ? (
        <MessageActionInput position={position} isPortalRichMenu={isPortalRichMenu} />
      ) : value === "PORTAL_EXTERNAL_PAGE_LINK" ? (
        <ExternalLinkUrlInput position={position} isPortalRichMenu={isPortalRichMenu} />
      ) : null}
    </Box>
  );
};

const MessageActionInput: FC<{
  position: Position;
  isPortalRichMenu: boolean;
}> = ({ position }) => {
  const { control } = useFormContext<InputState>();
  const name: CellLinkSettingFormName<
    typeof position,
    "messageActionText"
  > = `cellLinkSetting.${position}.messageActionText`;
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  const errorMessage = error?.message;
  return (
    <StyledTextField
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
    />
  );
};

const ExternalLinkUrlInput: FC<{
  position: Position;
  isPortalRichMenu: boolean;
}> = ({ position }) => {
  const { control } = useFormContext<InputState>();
  const name: CellLinkSettingFormName<
    typeof position,
    "externalLinkUrl"
  > = `cellLinkSetting.${position}.externalLinkUrl`;
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name });

  const errorMessage = error?.message;
  return (
    <StyledTextField
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
    />
  );
};

const StyledTextField = styled(TextField)`
  .MuiFormHelperText-contained {
    font-size: 10px;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    min-width: 100px;
    height: 40px;
    font-size: 14px;
  }
  .MuiTypography-root {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .MuiFormHelperText-contained {
    font-size: 10px;
  }
`;
