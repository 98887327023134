import { Box } from "@material-ui/core";
import { Announcement, AnnouncementDistribution } from "@onn/common";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PortalListItem } from "./PortalListItem";

export const PortalContent = ({
  announcementsData,
}: {
  announcementsData: {
    announcements: Announcement[];
    announcementDistributions: AnnouncementDistribution[];
  };
}): JSX.Element => {
  const navigate = useNavigate();

  const slicedAnnouncements = useMemo(
    () => announcementsData.announcements.slice(0, 3),
    [announcementsData.announcements]
  );

  return (
    <StyledCard>
      <Box>
        {slicedAnnouncements.map((announcement, i) => (
          <PortalListItem
            key={announcement.id}
            title={announcement.title}
            content={announcement.content}
            onClick={() => navigate(`/portal/announcements/${announcement.id}`)}
            isRead={announcementsData.announcementDistributions.some(
              (d) => d.announcementId === announcement.id && d.isRead()
            )}
            isLast={i === slicedAnnouncements.length - 1}
          />
        ))}
      </Box>
    </StyledCard>
  );
};

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  border-radius: 8px;
  padding: 16px;
`;
