import { z } from "zod";

/////////////////////////////////////////
// EVALUATE ONN EVENT TRIGGER SETTING SCHEMA
/////////////////////////////////////////

export const EvaluateOnnEventTriggerSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  triggerId: z.string(),
  onnEventId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EvaluateOnnEventTriggerSetting = z.infer<typeof EvaluateOnnEventTriggerSettingSchema>;

/////////////////////////////////////////
// EVALUATE ONN EVENT TRIGGER SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EvaluateOnnEventTriggerSettingOptionalDefaultsSchema =
  EvaluateOnnEventTriggerSettingSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EvaluateOnnEventTriggerSettingOptionalDefaults = z.infer<
  typeof EvaluateOnnEventTriggerSettingOptionalDefaultsSchema
>;

export default EvaluateOnnEventTriggerSettingSchema;
