import { Box } from "@material-ui/core";
import React, { FC } from "react";

import styled from "styled-components";

import { CreateReminderButton, ReminderItem } from "./parts";

import { Icon, Loading, Tooltip, Typography } from "~/components/uiParts";
import { useCompleteReminder } from "~/hooks/reminder/useCompleteReminder";
import { useCreateReminder } from "~/hooks/reminder/useCreateReminder";
import { useInCompletedReminders } from "~/hooks/reminder/useInCompletedReminders";
import { useTenant } from "~/hooks/tenant";

type Props = {
  contactRoomId: string;
};

export const NewGraduateReminder: FC<Props> = React.memo(({ contactRoomId }) => {
  const { tenant } = useTenant();
  const {
    data: reminders,
    isLoading,
    isValidating,
    refetchKey,
  } = useInCompletedReminders({
    tenantId: tenant.tenantId,
    contactRoomId,
  });
  const { completeReminder } = useCompleteReminder({ refetchKey, reminders: reminders || [] });
  const { createReminder } = useCreateReminder({
    refetchKey,
    reminders: reminders || [],
    contactRoomId,
  });

  const content = (
    <>
      {isLoading ? (
        <Box mt="8px">
          <Loading size="small" />
        </Box>
      ) : (
        reminders?.map((reminder) => (
          <ReminderItem key={reminder.id} reminder={reminder} completeReminder={completeReminder} />
        ))
      )}
    </>
  );

  return (
    <Box display="flex" flexDirection="column">
      <StyledTitleBox>
        <Typography variant="body2" bold color="textPrimary">
          リマインダー
        </Typography>
        <Tooltip
          title="リマインダーはコンタクトルームの担当者へリマインドされ、候補者にリマインダーは表示されません。リマインダーは日時が近い順に最新3件が表示されます。"
          placement="top-start"
        >
          <Icon icon="help" size="sm" color="grey" />
        </Tooltip>
      </StyledTitleBox>

      <Typography variant="caption" color="textSecondary">
        期日が近い順に3件まで表示されます。
      </Typography>

      {content}
      <CreateReminderButton
        reminders={reminders || []}
        createReminder={createReminder}
        isLoading={isLoading || isValidating}
      />
    </Box>
  );
});

const StyledTitleBox = styled(Box)`
  display: flex;
  margin-bottom: 8px;
  column-gap: 16px;
  align-items: center;
`;
