import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION MULTIPLE SELECT TYPE VALUE SCHEMA
/////////////////////////////////////////

export const EmployeeInformationMultipleSelectTypeValueSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  employeeInformationMultipleSelectTypeFieldId: z.string(),
  optionId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationMultipleSelectTypeValue = z.infer<
  typeof EmployeeInformationMultipleSelectTypeValueSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION MULTIPLE SELECT TYPE VALUE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationMultipleSelectTypeValueOptionalDefaultsSchema =
  EmployeeInformationMultipleSelectTypeValueSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationMultipleSelectTypeValueOptionalDefaults = z.infer<
  typeof EmployeeInformationMultipleSelectTypeValueOptionalDefaultsSchema
>;

export default EmployeeInformationMultipleSelectTypeValueSchema;
