import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT EVALUATION SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  onnEventId: z.string(),
  employeeId: z.string(),
  onnEventEvaluationRankId: z.string().nullish(),
  text: z.string().nullish(),
  filePaths: z.string().array(),
  updatedEmployeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventEvaluation = z.infer<typeof OnnEventEvaluationSchema>;

/////////////////////////////////////////
// ONN EVENT EVALUATION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventEvaluationOptionalDefaultsSchema = OnnEventEvaluationSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventEvaluationOptionalDefaults = z.infer<
  typeof OnnEventEvaluationOptionalDefaultsSchema
>;

export default OnnEventEvaluationSchema;
