import "./i18n/config";

import { initializeZodSetting } from "@onn/common";
import React, { FC, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import { AsyncLocalStorageSpaceId } from "./components/providers/AsyncLocalStorageSpaceId";
import { AuthenticationGuard } from "./components/providers/AuthenticationGuard";
import { AuthorizationGuard } from "./components/providers/AuthorizationGuard";
import { I18nProvider } from "./components/providers/I18nProvider";
import { LineAccessTokenGuard } from "./components/providers/LineAccessTokenGuard";
import { OnWebsiteNoticeHandler } from "./components/providers/OnWebsiteNoticeHandler";
import { SWRConfigWrapper } from "./components/providers/SWRConfigWrapper";
import { SpaceProvider } from "./components/providers/SpaceProvider";
import { AccessLog } from "./pages/utils/AccessLog";
import { GuestRouterWrapper } from "./routes/GuestRouterWrapper/GuestRouterWrapper";
import { RouterWrapper } from "./routes/RouterWrapper/RouterWrapper";
import { initializeLogger } from "./util";

import { initializeCustomConsole } from "./util/initializeCustomConsole";

import {
  AccessControlProvider,
  CurrentUserProvider,
  EmployeesProvider,
  FileViewerProvider,
  GiveLoggerTagsProvider,
  MaintenanceProvider,
  ModalProvider,
  SnackbarProvider,
  SwitchViewportProvider,
  ThemeProvider,
  TenantProvider,
  TenantSettingsProvider,
  RevisionProvider,
  ContactProviderV2,
  AuthenticationProvider,
  AuthorizationProvider,
  CurrentTenantIdProvider,
  LineAccessTokenByLiffProvider,
  LiffInitializeProvider,
  PortalLineUserActiveLogger,
  CreateSessionIdInSessionStorage,
  RecruitmentStatusProvider,
  EmployeePredictionProvider,
} from "~/components/providers";

import { ErrorBoundary, ScrollToTop, GoogleAnalytics, ChanelTalk } from "~/pages/utils";

try {
  initializeCustomConsole();
} catch {
  // eslint-disable-next-line no-console
  console.error("Failed to initialize custom console");
}

initializeLogger();

// zodのエラーメッセージを指定したものに上書き
initializeZodSetting();

export const App: FC = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  // null を返す場合、マウント先ではスピナーを表示する
  return loading ? null : (
    <CreateSessionIdInSessionStorage>
      <ThemeProvider>
        <ErrorBoundary>
          <SnackbarProvider>
            <MaintenanceProvider>
              <SWRConfigWrapper>
                <Router>
                  <GoogleAnalytics />
                  <ScrollToTop />
                  <SwitchViewportProvider>
                    <FileViewerProvider>
                      <RevisionProvider>
                        <LiffInitializeProvider>
                          <AccessLog />
                          <LineAccessTokenByLiffProvider>
                            <LineAccessTokenGuard>
                              <CurrentTenantIdProvider>
                                <AuthenticationProvider>
                                  <AuthorizationProvider>
                                    <PortalLineUserActiveLogger>
                                      <GuestRouterWrapper>
                                        <AuthenticationGuard>
                                          <AuthorizationGuard>
                                            <CurrentUserProvider>
                                              <I18nProvider>
                                                <AccessControlProvider>
                                                  <GiveLoggerTagsProvider>
                                                    <TenantProvider>
                                                      <SpaceProvider>
                                                        <AsyncLocalStorageSpaceId />
                                                        <TenantSettingsProvider>
                                                          <OnWebsiteNoticeHandler />
                                                          <ChanelTalk />
                                                          <RecruitmentStatusProvider>
                                                            <EmployeePredictionProvider>
                                                              <EmployeesProvider>
                                                                <ContactProviderV2>
                                                                  <ModalProvider>
                                                                    <RouterWrapper />
                                                                  </ModalProvider>
                                                                </ContactProviderV2>
                                                              </EmployeesProvider>
                                                            </EmployeePredictionProvider>
                                                          </RecruitmentStatusProvider>
                                                        </TenantSettingsProvider>
                                                      </SpaceProvider>
                                                    </TenantProvider>
                                                  </GiveLoggerTagsProvider>
                                                </AccessControlProvider>
                                              </I18nProvider>
                                            </CurrentUserProvider>
                                          </AuthorizationGuard>
                                        </AuthenticationGuard>
                                      </GuestRouterWrapper>
                                    </PortalLineUserActiveLogger>
                                  </AuthorizationProvider>
                                </AuthenticationProvider>
                              </CurrentTenantIdProvider>
                            </LineAccessTokenGuard>
                          </LineAccessTokenByLiffProvider>
                        </LiffInitializeProvider>
                      </RevisionProvider>
                    </FileViewerProvider>
                  </SwitchViewportProvider>
                </Router>
              </SWRConfigWrapper>
            </MaintenanceProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </CreateSessionIdInSessionStorage>
  );
};

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = createRoot(container);
root.render(<App />);
