import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Loading } from "~/components/uiParts";
import { CenterBox } from "~/components/uiParts/CenterBox";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";

/**
 * (旧)共通招待リンクアカウント登録ページ
 * - アカウント登録扉ページへ遷移する
 *
 * @deprecated
 */
export const ShareInvite: FC = () => {
  const { id: invitationLinkId } = useParams<"id">();
  const navigate = useNavigateWithQuery();

  useEffect(() => {
    if (invitationLinkId) {
      navigate("/portal/accounts/registration", {
        additionalQuery: { "registration-invitation-link-id": invitationLinkId || "" },
      });
    }
  }, [invitationLinkId, navigate]);

  // このページの責務はリダイレクトなので、エラー以外はローディング表示しかしない
  return (
    <CenterBox>
      <Loading size="large" />
    </CenterBox>
  );
};
