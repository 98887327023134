import { Box } from "@material-ui/core";
import {
  ChangeRecruitmentStatusActionSetting,
  SendContactMessageActionSetting,
  DeliverOnnEventActionSetting,
  DeliverOnnTaskActionSetting,
  Action,
  AnyTriggerSetting,
  instantiateFromAnyActionSettingExcludeMethods,
} from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components";

import { usePageContext } from "../../../../pageContext";
import { ActionCondition } from "../../../components/TriggerBlock/ActionList/Action/ActionCondition/ActionCondition";

import { ActionDataIdRendererForDeveloper } from "../../../components/TriggerBlock/ActionList/Action/ActionDataIdRendererForDeveloper";
import { ActionDescription } from "../../../components/TriggerBlock/ActionList/Action/ActionDescription/ActionDescription";

import { useMenu } from "../../../components/useMenu";
import { ActionAreaMinHeight } from "../../../constant/constant";

import { InputBlockState } from "../../../hooks/scenarioForm/InputState";

import { useEditModeContext } from "../../EditModeContextProvider";

import { usePopoverOfActionSetting } from "../../hooks/usePopoverOfActionSetting";
import { PopoverOfActionSetting } from "../ActionPopovers/PopoverOfActionSetting";

import { useActionDescriptionForEditMode } from "./useActionDescriptionForEditMode";
import { useApplyToScenarioForm } from "./useApplyToScenarioForm";

import { Icon, Typography } from "~/components/uiParts";
import { MenuOfActionSelection } from "~/pages/scenario/Main/editMode/components/MenuOfActionSelection";

type Props = {
  actionWithRelatedInfo: InputBlockState["actions"][number];
  triggerId: string;
  recruitmentStatusId: string;
  triggerSetting: AnyTriggerSetting;
  actionConditionErrorMessage?: string;
  deleteAction: () => void;
};

export const ActionAreaForEditMode: FC<Props> = ({
  triggerId,
  recruitmentStatusId,
  actionWithRelatedInfo,
  triggerSetting,
  actionConditionErrorMessage,
  deleteAction,
}) => {
  const { allRecruitmentStatusesMap } = useEditModeContext();
  const isActionRelatedDeletedStatus = useMemo(() => {
    switch (actionWithRelatedInfo.actionSetting.type) {
      case ChangeRecruitmentStatusActionSetting.type: {
        const recruitmentStatus = allRecruitmentStatusesMap.get(
          actionWithRelatedInfo.actionSetting.recruitmentStatusId
        );
        return recruitmentStatus?.deleted || false;
      }
      case SendContactMessageActionSetting.type:
      case DeliverOnnEventActionSetting.type:
      case DeliverOnnTaskActionSetting.type:
        return false;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = actionWithRelatedInfo.actionSetting;
        return false;
    }
  }, [actionWithRelatedInfo.actionSetting, allRecruitmentStatusesMap]);

  const { isDeveloperMode } = usePageContext();

  const editButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const actionSelectMenu = useMenu();
  const popoverOfActionSetting = usePopoverOfActionSetting();

  // NOTE: 各ポップオーバーを同じ位置に表示させるためアンカー要素を同期させる
  actionSelectMenu.anchorEl = editButtonRef;
  popoverOfActionSetting.anchorEl = editButtonRef;

  const { description, endLabel, conditionDescription } = useActionDescriptionForEditMode({
    setting: actionWithRelatedInfo.actionSetting,
    condition: actionWithRelatedInfo.actionCondition,
    ...actionWithRelatedInfo.action,
  });

  const onClickEditAction = useCallback(() => {
    switch (actionWithRelatedInfo.actionSetting.type) {
      case "ChangeRecruitmentStatusActionSetting": {
        popoverOfActionSetting.open("changeRecruitmentStatus");
        break;
      }
      case "DeliverOnnEventActionSetting": {
        popoverOfActionSetting.open("deliveryOnnEvent");
        break;
      }
      case "DeliverOnnTaskActionSetting": {
        popoverOfActionSetting.open("deliveryOnnTask");
        break;
      }
      case "SendContactMessageActionSetting": {
        popoverOfActionSetting.open("sendContactMessage");
        break;
      }
      default: {
        const _exhaustiveCheck: never = actionWithRelatedInfo.actionSetting;
        break;
      }
    }
  }, [actionWithRelatedInfo.actionSetting, popoverOfActionSetting]);

  const { applyToScenarioForm } = useApplyToScenarioForm({
    triggerId,
    recruitmentStatusId,
    triggerSetting,
    closePopover: popoverOfActionSetting.close,
    existingAction: new Action(actionWithRelatedInfo.action),
    existingAnyActionSetting: instantiateFromAnyActionSettingExcludeMethods(
      actionWithRelatedInfo.actionSetting
    ),
    existingAnyActionCondition: actionWithRelatedInfo.actionCondition,
  });
  return (
    <Root
      $isExistCondition={!!actionWithRelatedInfo.actionCondition}
      $isDeveloperMode={isDeveloperMode}
      $isError={isActionRelatedDeletedStatus || !!actionConditionErrorMessage}
    >
      {isDeveloperMode && (
        <ActionDataIdRendererForDeveloper
          actionId={actionWithRelatedInfo.action.id}
          actionSettingId={actionWithRelatedInfo.actionSetting.id}
          actionConditionId={actionWithRelatedInfo.actionCondition?.id}
        />
      )}
      {conditionDescription && <ActionCondition conditionDescription={conditionDescription} />}
      <ActionDescription
        type={actionWithRelatedInfo.actionSetting.type}
        description={description}
        endLabel={endLabel}
        isError={isActionRelatedDeletedStatus}
        isEditMode
        editButtonRef={editButtonRef}
        onClickEdit={onClickEditAction}
        onClickDelete={deleteAction}
      />
      {actionConditionErrorMessage && (
        <Box display="flex" gridColumnGap="8px" alignItems="center">
          <Icon icon="alert" size="md" color="secondary" />
          <Typography variant="caption" color="secondary">
            {actionConditionErrorMessage}
          </Typography>
        </Box>
      )}
      <MenuOfActionSelection
        anchorEl={actionSelectMenu.anchorEl.current}
        onClose={actionSelectMenu.closeMenu}
        onClickItem={(type) => {
          // TODO: ActionTypeとAnyActionSetting["type"] の型を揃える
          switch (type) {
            case "ChangeRecruitmentStatusActionSetting":
              popoverOfActionSetting.open("changeRecruitmentStatus");
              break;
            case "DeliverOnnEventActionSetting":
              popoverOfActionSetting.open("deliveryOnnEvent");
              break;
            case "DeliverOnnTaskActionSetting":
              popoverOfActionSetting.open("deliveryOnnTask");
              break;
            case "SendContactMessageActionSetting":
              popoverOfActionSetting.open("sendContactMessage");
              break;
            default: {
              const _: never = type;
              break;
            }
          }
          actionSelectMenu.closeMenu();
        }}
        isOpen={actionSelectMenu.isOpen}
      />
      {popoverOfActionSetting.openedActionType && (
        <PopoverOfActionSetting
          key={`${String(popoverOfActionSetting.isOpen)}-${triggerId}-${
            popoverOfActionSetting.openedActionType
          }`}
          anchorEl={popoverOfActionSetting.anchorEl.current}
          onClose={popoverOfActionSetting.close}
          isOpen={popoverOfActionSetting.isOpen}
          mode="update"
          existingAction={new Action(actionWithRelatedInfo.action)}
          existingAnyActionSetting={instantiateFromAnyActionSettingExcludeMethods(
            actionWithRelatedInfo.actionSetting
          )}
          existingActionCondition={actionWithRelatedInfo.actionCondition}
          backToSelectActionType={() => {
            actionSelectMenu.openMenu();
            popoverOfActionSetting.close();
          }}
          triggerSetting={triggerSetting}
          actionType={popoverOfActionSetting.openedActionType}
          onClickApplyButton={applyToScenarioForm}
        />
      )}
    </Root>
  );
};

const Root = styled(Box)<{
  $isExistCondition: boolean;
  $isDeveloperMode: boolean;
  $isError: boolean;
}>`
  ${(props) =>
    !props.$isExistCondition &&
    !props.$isDeveloperMode &&
    `
      display: flex;
      align-items: center;
    `}
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isError ? props.theme.palette.secondary.light : props.theme.palette.grey[50]};
  min-height: ${ActionAreaMinHeight}px;
  padding: 8px;
`;
