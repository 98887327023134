import React, { useMemo } from "react";

import { AnyInputCondition, AnyTarget } from "../types/condition";

import { LogicType } from "../types/logic-type";

import { ModalContentLayout } from "./ModalContentLayout";
import { ConditionForm } from "./condition-form/ConditionForm";
import { AddConditionButton } from "./parts/buttons/AddConditionButton";
import { ClearAllConditionsButton } from "./parts/buttons/ClearAllConditionsButton";

type Props = {
  conditions: AnyInputCondition[];
  logicType: LogicType;
  onAddCondition(): void;
  onRemoveCondition(index: number): void;
  onClearAllConditions(): void;
  onChangeCondition(index: number, changedCondition: AnyInputCondition): void;
  onChangeTarget(index: number, changedTarget: AnyTarget): void;
  onSelectLogicType(logicType: LogicType): void;
};
export const ModalContent = ({
  conditions,
  logicType,
  onAddCondition,
  onClearAllConditions,
  onRemoveCondition,
  onChangeCondition,
  onChangeTarget,
  onSelectLogicType,
}: Props): JSX.Element => {
  const conditionForm = useMemo(
    () => (
      <ConditionForm
        conditions={conditions}
        logicType={logicType}
        onSelectLogicType={onSelectLogicType}
        onChangeCondition={onChangeCondition}
        onChangeTarget={onChangeTarget}
        onRemoveCondition={onRemoveCondition}
      />
    ),
    [conditions, logicType, onChangeCondition, onChangeTarget, onRemoveCondition, onSelectLogicType]
  );
  const addConditionButton = useMemo(
    () => <AddConditionButton onClick={onAddCondition} />,
    [onAddCondition]
  );
  const clearAllConditionsButton = useMemo(
    () => <ClearAllConditionsButton onClick={onClearAllConditions} />,
    [onClearAllConditions]
  );

  return (
    <ModalContentLayout
      conditionForm={conditionForm}
      addConditionButton={addConditionButton}
      clearAllConditionsButton={clearAllConditionsButton}
    />
  );
};
