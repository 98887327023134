import liff from "@line/liff";
import React, { useContext, type ReactNode, useEffect } from "react";

import { LoadingForInitialRendering } from "../shared";

import { LineAccessTokenByLiffContext } from "./LineAccessTokenByLiffProvider";

type Props = {
  children: ReactNode;
};

export const LineAccessTokenGuard = ({ children }: Props): JSX.Element => {
  const { lineAccessToken, guardLineAccessToken, setLineAccessTokenByLiff } = useContext(
    LineAccessTokenByLiffContext
  );

  useEffect(() => {
    setLineAccessTokenByLiff();
  }, [setLineAccessTokenByLiff]);

  // LIFF でないなら Guard しない
  if (!liff.isInClient()) {
    return <>{children}</>;
  }

  if (!guardLineAccessToken(lineAccessToken)) {
    return <LoadingForInitialRendering ns="LineAccessTokenGuard" enableCaptureException />;
  }

  return <>{children}</>;
};
