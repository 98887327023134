import { CandidateDate, Failure, OnnEvent, Result, Success } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { convertUnixTimeToDateOnnEvent } from "../../_shared/convertUnixTimeToDate";
import { onnEventActionTypes } from "../shared";

import { createOnnEventRequestSchema, ICreateOnnEventRequest } from "./schema";

export class CreateOnnEventRequest implements ICreateOnnEventRequest {
  static readonly validator = createOnnEventRequestSchema;
  static actionType = onnEventActionTypes.createOnnEvent;
  onnEvent: OnnEvent;

  constructor(init: { onnEvent: OnnEvent }) {
    this.onnEvent = init.onnEvent;
  }

  // クライアント側でリクエストボディを作成する
  public static createRequestBody({ onnEvent }: { onnEvent: OnnEvent }) {
    return toCloudFunctionsCompatible({
      actionType: this.actionType,
      onnEvent,
    });
  }

  public static validate(
    data: ToCloudFunctionsCompatible<CreateOnnEventRequest>
  ): Result<CreateOnnEventRequest, Error> {
    const requestData = convertUnixTimeToDateOnnEvent(data.onnEvent);
    const parseResult = OnnEvent.validator.safeParse(requestData);
    if (!parseResult.success) {
      return new Failure(new Error(parseResult.error.message));
    }

    return new Success(
      new CreateOnnEventRequest({
        onnEvent: OnnEvent.create({
          ...parseResult.data,
          candidateDates: parseResult.data.candidateDates.map((v) => CandidateDate.create(v)),
        }),
      })
    );
  }
}
