import { z } from "zod";

const httpSchema = z
  .string()
  .trim()
  .regex(
    new RegExp("https://|http://|^$"),
    "正しいURLまたは、「https://」「http://」を入力してください"
  );
const validUrlSchema = z.string().url({ message: "正しいURLを入力してください" });

export const urlSchema = z
  .union([httpSchema, validUrlSchema])
  .transform((url) => url.replace(/\n/, ""));
