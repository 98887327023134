import { useCallback, useMemo } from "react";

import { useTenant } from "~/hooks/tenant";

// Firebase Storage用のファイルパスを生成する
export const useCreateFilePath = () => {
  const { tenant } = useTenant();

  const tenantId = useMemo(() => {
    return tenant.tenantId;
  }, [tenant]);

  const createFileQuestionFilePath = useCallback(
    ({
      questionId,
      fileName,
      onnFormTaskId,
    }: {
      questionId: string;
      fileName: string;
      onnFormTaskId: string;
    }) => {
      return `tenants/${tenantId}/onn_tasks/${onnFormTaskId}/${questionId}/sample/${fileName}`;
    },
    [tenantId]
  );

  const createFileQuestionFilePathForPreview = useCallback(
    ({
      questionId,
      fileName,
      onnFormTaskId,
    }: {
      questionId: string;
      fileName: string;
      onnFormTaskId: string;
    }) => {
      return `tenants/${tenantId}/onn_tasks/${onnFormTaskId}/${questionId}/sampleForPreview/${fileName}`;
    },
    [tenantId]
  );

  return { createFileQuestionFilePath, createFileQuestionFilePathForPreview };
};
