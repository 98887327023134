import { Box } from "@material-ui/core";
import { EmployeeInformationGroupWithFieldAndOptions } from "@onn/common";

import React, { useCallback } from "react";

import { useModal } from "../modal";

import { useEditEmployeeInformationGroups } from "./useEditEmployeeInformationGroups";

import { Typography } from "~/components/uiParts";

export const useOpenEditEmployeeInformationGroupModal = (
  exitingGroups: EmployeeInformationGroupWithFieldAndOptions[]
) => {
  const { handleModal } = useModal();
  const { editEmployeeEmployeeInformationGroups } = useEditEmployeeInformationGroups();

  const openDeleteGroupConfirmModal = useCallback(
    (
      inputGroups: {
        id: string;
        label: string;
      }[]
    ) => {
      const toDeleteGroups =
        exitingGroups?.filter((exitingGroup) => {
          return !inputGroups.some(
            (inputGroup) => exitingGroup.employeeInformationGroup.id === inputGroup.id
          );
        }) || [];
      handleModal({
        name: "confirmModalWithCheckbox",
        args: {
          title: "項目削除",
          mainContent: (
            <Box>
              <Typography variant="body1">
                「{toDeleteGroups.map((g) => g.employeeInformationGroup.label).join(",")}
                」を削除しますか？
              </Typography>
              <Typography variant="body1">
                削除すると
                <Typography variant="body1" bold component="span">
                  配下のすべての項目
                </Typography>
                と
                <Typography variant="body1" bold component="span">
                  すべての候補者情報
                </Typography>
                から該当の項目が削除されます。
              </Typography>
            </Box>
          ),
          subContent: `この操作は取り消すことができません`,
          onClickAccept: async () => {
            await editEmployeeEmployeeInformationGroups({
              employeeInformationGroups: inputGroups,
              successMessage: "グループを編集しました。",
            });
          },
        },
      });
    },
    [editEmployeeEmployeeInformationGroups, exitingGroups, handleModal]
  );

  const openEditEmployeeInformationGroupModal = useCallback(
    ({ type }: { type: "add" | "edit" }) => {
      handleModal({
        name: "editEmployeeInformationGroupModal",
        args: {
          data: {
            type,
            groups: exitingGroups || [],
            onSubmit: async ({ groups: inputGroups }) => {
              const inputGroupIds = inputGroups.map((inputGroup) => inputGroup.id);
              const exitingGroupIds =
                exitingGroups?.map((group) => group.employeeInformationGroup.id) || [];
              // もし、既存のグループが削除されていれば、モーダルを表示
              const toDeleteGroupsIds = exitingGroupIds.filter((id) => !inputGroupIds.includes(id));
              if (toDeleteGroupsIds.length > 0) {
                openDeleteGroupConfirmModal(inputGroups);
              } else {
                await editEmployeeEmployeeInformationGroups({
                  employeeInformationGroups: inputGroups,
                  successMessage:
                    type === "add" ? "グループを追加しました。" : "グループを編集しました。",
                });
              }
            },
          },
        },
      });
    },
    [editEmployeeEmployeeInformationGroups, exitingGroups, handleModal, openDeleteGroupConfirmModal]
  );

  return { openEditEmployeeInformationGroupModal };
};
