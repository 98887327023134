import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * notifyAdminsOfMessageFromUnknownUserを更新するための関数を提供するhooks
 * @returns updateAssigneeIds
 */
export const useUpdateNotifyAdminsOfMessageFromUnknownUser = (): {
  updateAssigneeIds: (id: string, newAssigneeIds: string[]) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  /**
   * notifyAdminsOfMessageFromUnknownUserを更新するための関数
   * @param tenantSettingId
   * @param newAssigneeIds
   */
  const updateAssigneeIds = useCallback(
    async (tenantSettingId: string, newAssigneeIds: string[]) =>
      await apiClient
        .post("/update_notify_admins_of_message_from_unknown_user", {
          tenantSettingId,
          newAssigneeIds,
        })
        .then(() => {
          enqueueSnackbar("通知の対象者を更新しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useUpdateNotifyAdminsOfMessageFromUnknownUser:updateAssigneeIds",
            },
          });
        }),
    [enqueueSnackbar]
  );

  return {
    updateAssigneeIds,
  };
};
