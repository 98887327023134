import { getStorageAccessors } from "~/util";

const getStorageKey = (tenantId: string) => {
  return `${tenantId}-currentSpaceId`;
};

const {
  retrieveValue: retrieveValueFromLS,
  storeValue: storeValueToLS,
  removeValue: removeValueFromLS,
} = getStorageAccessors(localStorage);
const {
  retrieveValue: retrieveValueFromSS,
  storeValue: storeValueToSS,
  removeValue: removeValueFromSS,
} = getStorageAccessors(sessionStorage);

const getSpaceIdFromLS = (tenantId: string) => retrieveValueFromLS<string>(getStorageKey(tenantId));
const getSpaceIdFromSS = (tenantId: string) => retrieveValueFromSS<string>(getStorageKey(tenantId));
const clearSpaceIdFromLSAndSS = (tenantId: string) => {
  removeValueFromSS(getStorageKey(tenantId));
  removeValueFromLS(getStorageKey(tenantId));
};

const getSelectedSpaceId = (tenantId: string) => {
  // 以前に選択したスペースを保持するためにローカルストレージを使用している
  const spaceIdFromLS = getSpaceIdFromLS(tenantId);
  // すでにOnnを開いている状態で別タブで直接別スペースにアクセスしたときに元タブでのスペースを更新せずにスペースを扱うためにセッションストレージを使用している
  const spaceIdFromSS = getSpaceIdFromSS(tenantId);

  // そのタブ内で扱う目的で保持したのがセッションストレージの値なので優先的に扱う
  return spaceIdFromSS || spaceIdFromLS;
};

export {
  getStorageKey,
  storeValueToLS,
  storeValueToSS,
  getSpaceIdFromLS,
  getSpaceIdFromSS,
  removeValueFromSS,
  getSelectedSpaceId,
  clearSpaceIdFromLSAndSS,
};
