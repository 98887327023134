import { useEffect } from "react";

import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";
import { useFormRevisions, useOnnTask } from "~/hooks/onnTask";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const usePage = ({ onnTaskId }: { onnTaskId?: string }) => {
  const { data: onnTask, isLoading: isLoadingOnnTask } = useOnnTask({ onnTaskId });
  const { data: onnFormTaskAnswers, isLoading: isLoadingOnnFormTaskAnswers } =
    useOnnFormTasksAnswersOnlyCountable({ onnTaskId });
  const { data: formRevisionsData, isLoading: isLoadingFormRevision } = useFormRevisions({
    onnFormTaskId: onnTask?.id,
    isOnlyLatest: true,
  });
  const { switchSpaceTemporary } = useCurrentSpace();

  useEffect(() => {
    if (!onnTask) return;
    switchSpaceTemporary(onnTask.spaceId);
  }, [switchSpaceTemporary, onnTask]);

  const latestFormRevision = formRevisionsData?.formRevisions?.[0];

  return {
    onnTask,
    isLoadingOnnTask,
    latestFormRevision,
    onnFormTaskAnswers,
    isLoadingOnnFormTaskAnswers,
    isLoadingFormRevision,
  };
};
