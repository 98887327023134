import { v4 } from "uuid";

import {
  IEmployeeInformationSingleSelectTypeValue,
  employeeInformationSingleSelectTypeValueSchema,
} from "./schema";

export class EmployeeInformationSingleSelectTypeValue
  implements IEmployeeInformationSingleSelectTypeValue
{
  static readonly type = "SINGLE_SELECT" as const;
  type = EmployeeInformationSingleSelectTypeValue.type;
  static validator = employeeInformationSingleSelectTypeValueSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeId: string;
  readonly employeeInformationSingleSelectTypeFieldId: string;
  readonly optionId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<EmployeeInformationSingleSelectTypeValue>, "type">) {
    EmployeeInformationSingleSelectTypeValue.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeId = init.employeeId;
    this.employeeInformationSingleSelectTypeFieldId =
      init.employeeInformationSingleSelectTypeFieldId;
    this.optionId = init.optionId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationSingleSelectTypeValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): EmployeeInformationSingleSelectTypeValue {
    return new EmployeeInformationSingleSelectTypeValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<Pick<EmployeeInformationSingleSelectTypeValue, "optionId">>
  ): EmployeeInformationSingleSelectTypeValue {
    return new EmployeeInformationSingleSelectTypeValue({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
