import { Role } from "@onn/common";
import { HttpsCallableResult } from "firebase/functions";

import { functionOperator } from "~/infrastructure/api/functionOperator";

export class AccountSettingUseCase {
  async delete(employeeId: string): Promise<HttpsCallableResult<{ message: string }>> {
    return functionOperator.httpsCall<unknown, { message: string }>("deleteEmployee", {
      employeeId,
    });
  }

  async createAdminAccount(
    userDataArray: { email: string; role: Role }[]
  ): Promise<HttpsCallableResult> {
    return functionOperator.httpsCallFor2ndGen("accountcreate", {
      userDataArray,
    });
  }

  async resendInvitationToSlack(
    employeeId: string
  ): Promise<HttpsCallableResult<{ message: string }>> {
    return functionOperator.httpsCall<unknown, { message: string }>("resendInvitationToSlack", {
      employeeId,
    });
  }
}
