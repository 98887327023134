import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { FC, memo } from "react";
import styled from "styled-components";

import { Tooltip, UserIcon } from "~/components/uiParts";

const getTooltipTitleForMentor = (isEditable: boolean, mentor?: Employee) => {
  if (mentor) {
    return "受け入れチーム設定を開く";
  } else if (isEditable) {
    return "担当者を設定する";
  }
  return "";
};

type Props = {
  mentor?: Employee;
  isEditable: boolean;
  onClickMentor: () => void;
};

export const NewGraduateMentor: FC<Props> = memo(({ mentor, isEditable, onClickMentor }) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Tooltip arrow placement="bottom" title={getTooltipTitleForMentor(isEditable, mentor)}>
        <StyledClickableBox
          display="flex"
          alignItems="center"
          $isClickable={isEditable || Boolean(mentor)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClickMentor();
          }}
        >
          <UserIcon
            circular
            size="small"
            username={mentor ? mentor.getName() : "未登録"}
            profileIconImageUrl={mentor?.profileIconImageUrl}
            badgeType={mentor ? undefined : "dot"}
            borderColor="primary"
          />
        </StyledClickableBox>
      </Tooltip>
    </Box>
  );
});

const StyledClickableBox = styled(Box)<{ $isClickable: boolean }>`
  ${({ $isClickable }) => ($isClickable ? `cursor : pointer;` : `cursor: default;`)}
  width: 100%;
  height: 40px;
`;
