import { ChangeRecruitmentStatusActionSetting } from "./ChangeRecruitmentStatusActionSetting";
import { DeliverOnnEventActionSetting } from "./DeliverOnnEventActionSetting";
import { DeliverOnnTaskActionSetting } from "./DeliverOnnTaskActionSetting";
import { SendContactMessageActionSetting } from "./SendContactMessageActionSetting";

export { ChangeRecruitmentStatusActionSetting } from "./ChangeRecruitmentStatusActionSetting";
export { SendContactMessageActionSetting } from "./SendContactMessageActionSetting";
export { DeliverOnnEventActionSetting } from "./DeliverOnnEventActionSetting";
export { DeliverOnnTaskActionSetting } from "./DeliverOnnTaskActionSetting";

export type AnyActionSetting =
  | ChangeRecruitmentStatusActionSetting
  | SendContactMessageActionSetting
  | DeliverOnnEventActionSetting
  | DeliverOnnTaskActionSetting;

export type AnyActionSettingExcludeMethods =
  | ExcludeMethods<ChangeRecruitmentStatusActionSetting>
  | ExcludeMethods<SendContactMessageActionSetting>
  | ExcludeMethods<DeliverOnnEventActionSetting>
  | ExcludeMethods<DeliverOnnTaskActionSetting>;

export const instantiateFromAnyActionSettingExcludeMethods = (
  anyActionSettingExcludeMethods: AnyActionSettingExcludeMethods
) => {
  switch (anyActionSettingExcludeMethods.type) {
    case "ChangeRecruitmentStatusActionSetting":
      return new ChangeRecruitmentStatusActionSetting(anyActionSettingExcludeMethods);
    case "SendContactMessageActionSetting":
      return new SendContactMessageActionSetting(anyActionSettingExcludeMethods);
    case "DeliverOnnEventActionSetting":
      return new DeliverOnnEventActionSetting(anyActionSettingExcludeMethods);
    case "DeliverOnnTaskActionSetting":
      return new DeliverOnnTaskActionSetting(anyActionSettingExcludeMethods);
    default: {
      const _exhaustiveCheck: never = anyActionSettingExcludeMethods;
      return _exhaustiveCheck;
    }
  }
};

export const groupByActionSettingType = (actionSettings: AnyActionSetting[]) => {
  const result: {
    changeRecruitmentStatusActionSettings: ChangeRecruitmentStatusActionSetting[];
    sendContactMessageActionSettings: SendContactMessageActionSetting[];
    deliverOnnEventActionSettings: DeliverOnnEventActionSetting[];
    deliverOnnTaskActionSettings: DeliverOnnTaskActionSetting[];
  } = {
    changeRecruitmentStatusActionSettings: [],
    sendContactMessageActionSettings: [],
    deliverOnnEventActionSettings: [],
    deliverOnnTaskActionSettings: [],
  };

  for (const actionSetting of actionSettings) {
    switch (actionSetting.type) {
      case "ChangeRecruitmentStatusActionSetting":
        result.changeRecruitmentStatusActionSettings.push(actionSetting);
        break;
      case "SendContactMessageActionSetting":
        result.sendContactMessageActionSettings.push(actionSetting);
        break;
      case "DeliverOnnEventActionSetting":
        result.deliverOnnEventActionSettings.push(actionSetting);
        break;
      case "DeliverOnnTaskActionSetting":
        result.deliverOnnTaskActionSettings.push(actionSetting);
        break;
      default: {
        const _exhaustiveCheck: never = actionSetting;
        return _exhaustiveCheck;
      }
    }
  }

  return result;
};
