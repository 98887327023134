import { Box } from "@mui/material";
import {
  AnyEmployeeInformationField,
  AnyEmployeeInformationFieldWithOptions,
  EmployeeInformationGroup,
  EmployeeInformationGroupWithFieldAndOptions,
} from "@onn/common";
import React, { FC, useCallback, useState } from "react";

import { ManageEmployeeInformationFieldsModal } from "../ManageEmployeeInformationFieldsModal";

import { IconButton, Typography, UncontrolledMenu } from "~/components/uiParts";
import { useDeleteEmployeeInformationField } from "~/hooks/employeeInformationField";
import { useModal } from "~/hooks/modal";

type Props = {
  groups: EmployeeInformationGroupWithFieldAndOptions[];
  employeeInformationGroup: EmployeeInformationGroup;
  employeeInformationFieldWithOptions: AnyEmployeeInformationFieldWithOptions[];
};

export const EmployeeInformationFieldManageMenu: FC<Props> = ({
  groups,
  employeeInformationGroup,
  employeeInformationFieldWithOptions,
}) => {
  const [
    isOpenManageEmployeeInformationFieldsModal,
    setIsOpenManageEmployeeInformationFieldsModal,
  ] = useState(false);
  const { execDeleteEmployeeInformationField } = useDeleteEmployeeInformationField();
  const { handleModal } = useModal();
  const onClickAddFieldButton = useCallback(
    (group: EmployeeInformationGroup) => {
      handleModal({
        name: "editEmployeeInformationFieldsModal",
        args: {
          data: {
            mode: "create",
            defaultGroupId: group.id,
            allEmployeeInformationGroups: groups,
          },
        },
      });
    },
    [groups, handleModal]
  );

  const onClickDeleteFieldButton = useCallback(
    (employeeInformationField: AnyEmployeeInformationField) => {
      handleModal({
        name: "confirmModalWithCheckbox",
        args: {
          title: "項目削除",
          mainContent: (
            <Box>
              <Typography variant="body1">
                「{employeeInformationField.label}」を削除しますか？
              </Typography>
              <Typography variant="body1">
                削除すると
                <Typography variant="body1" bold component="span">
                  すべての候補者情報
                </Typography>
                から該当の項目が削除されます。
              </Typography>
            </Box>
          ),
          subContent: `この操作は取り消すことができません`,
          onClickAccept: async () => {
            await execDeleteEmployeeInformationField({
              employeeInformationFieldId: employeeInformationField.id,
            });
          },
        },
      });
    },
    [execDeleteEmployeeInformationField, handleModal]
  );

  const onClickEditFieldButton = useCallback(
    (
      group: EmployeeInformationGroup,
      employeeInformationField: AnyEmployeeInformationFieldWithOptions
    ) => {
      handleModal({
        name: "editEmployeeInformationFieldsModal",
        args: {
          data: {
            mode: "edit",
            defaultGroupId: group.id,
            allEmployeeInformationGroups: groups,
            employeeInformationField,
            onClickDeleteFieldButton: () => onClickDeleteFieldButton(employeeInformationField),
            onCancelForEditMode: () => {
              setIsOpenManageEmployeeInformationFieldsModal(true);
            },
          },
        },
      });
    },
    [groups, handleModal, onClickDeleteFieldButton]
  );

  const handleClickEditFieldButton = useCallback(
    ({
      employeeInformationGroup,
      employeeInformationFieldWithOptions,
    }: EmployeeInformationGroupWithFieldAndOptions) => {
      handleModal({
        name: "manageEmployeeInformationFieldsModal",
        args: {
          groupId: employeeInformationGroup.id,
          groupName: employeeInformationGroup.label,
          employeeInformationFields: employeeInformationFieldWithOptions,
          onClickAddFieldButton: () => onClickAddFieldButton(employeeInformationGroup),
          onClickEditFieldButton: (field) =>
            onClickEditFieldButton(employeeInformationGroup, field),
        },
      });
    },
    [handleModal, onClickAddFieldButton, onClickEditFieldButton]
  );

  return (
    <>
      <UncontrolledMenu
        renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
        menuItemOptions={[
          {
            text: "項目追加",
            onClick: () => onClickAddFieldButton(employeeInformationGroup),
          },
          ...(employeeInformationFieldWithOptions.length > 0
            ? [
                {
                  text: "項目編集",
                  onClick: () =>
                    handleClickEditFieldButton({
                      employeeInformationGroup,
                      employeeInformationFieldWithOptions,
                    }),
                },
              ]
            : []),
        ]}
      />
      {/* NOTE: EditEmployeeInformationFieldsModal のキャンセル時に再度戻ってくるためuseModalを使わずに開閉状態をstate管理する */}
      <ManageEmployeeInformationFieldsModal
        open={isOpenManageEmployeeInformationFieldsModal}
        onCancel={() => {
          setIsOpenManageEmployeeInformationFieldsModal(false);
        }}
        groupId={employeeInformationGroup.id}
        groupName={employeeInformationGroup.label}
        employeeInformationFields={employeeInformationFieldWithOptions}
        onClickAddFieldButton={() => {
          onClickAddFieldButton(employeeInformationGroup);
          setIsOpenManageEmployeeInformationFieldsModal(false);
        }}
        onClickEditFieldButton={(field) => {
          onClickEditFieldButton(employeeInformationGroup, field);
          setIsOpenManageEmployeeInformationFieldsModal(false);
        }}
      />
    </>
  );
};
