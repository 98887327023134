import { Box, List } from "@material-ui/core";
import { NewGraduateToDisplay } from "@onn/common";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { useToggleSelectAll } from "../../../../hooks/shared/useToggleSelectAll";

import { Row } from "./Row";

import { Checkbox, Chip, FormControlLabel, Icon, Tooltip, Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";

type SelectListProps = {
  employees: NewGraduateToDisplay[];
  selectedEmployees: NewGraduateToDisplay[];
  onSelect: (employees: NewGraduateToDisplay[]) => void;
  height?: number;
};

export const SelectList = ({
  employees,
  selectedEmployees,
  onSelect,
  height = 224,
}: SelectListProps): JSX.Element => {
  const overscanRowCount = 10;
  const { toggleSelectAll, allSelectionState } = useToggleSelectAll({
    options: employees,
    selectedOptions: selectedEmployees,
  });

  const includeRejectedORWithdrewNewGraduate = useMemo(
    () => !!selectedEmployees.find((e) => e.isRejectedOrWithdrew()),
    [selectedEmployees]
  );

  const handleSelect = useCallback(
    (employee: NewGraduateToDisplay) => {
      // 選択済みのユーザーをクリックしたとき
      if (selectedEmployees.includes(employee)) {
        const newArray = selectedEmployees.filter((v) => {
          return v.id !== employee.id;
        });
        onSelect(newArray);
      } else {
        onSelect([...selectedEmployees, employee]);
      }
    },
    [onSelect, selectedEmployees]
  );

  return (
    <>
      <Box marginY="24px">
        <StyledFormControlLabel
          control={
            <Checkbox
              {...allSelectionState}
              name={allSelectionState.label.text}
              onChange={() => {
                const newSelectedEmployees = toggleSelectAll();
                onSelect(newSelectedEmployees);
              }}
            />
          }
          label={
            <StyledSelectAllBox>
              <Typography variant="body2" color="textSecondary">
                {allSelectionState.label.text}
              </Typography>
              <StyledChip color="grey" label={employees.length} bold />
            </StyledSelectAllBox>
          }
        />
        {allSelectionState.label.count > 0 && (
          <Box marginTop="16px" display="flex" alignItems="center" gridGap="5px">
            <Typography color="textSecondary">
              {allSelectionState.label.count.toString()}名の候補者が選択されています
            </Typography>
            {includeRejectedORWithdrewNewGraduate && (
              <Tooltip
                title="いずれかのシナリオの選考ステータスが「不採用」または「辞退」になっている候補者を含んでいます"
                placement="top-start"
              >
                <Icon icon="error" color="secondary" size="ssm" />
              </Tooltip>
            )}
          </Box>
        )}
      </Box>

      <StyledList $height={height}>
        {
          <AutoSizer
            disableHeight // 子要素の高さが固定長であるため高さを計算しない
          >
            {(size) => (
              <VirtualizedList
                height={height}
                width={size.width}
                overscanRowCount={overscanRowCount}
                rowCount={employees.length}
                rowHeight={57}
                rowRenderer={(props) => {
                  return (
                    <Row
                      {...props}
                      employees={employees}
                      selectedEmployees={selectedEmployees}
                      onSelect={handleSelect}
                    />
                  );
                }}
                noRowsRenderer={() => (
                  <Typography
                    variant="caption"
                    align="center"
                    display="block"
                    color="textSecondary"
                  >
                    結果が見つかりませんでした
                  </Typography>
                )}
              />
            )}
          </AutoSizer>
        }
      </StyledList>
    </>
  );
};

const StyledList = styled(List)<{ $height: number }>`
  height: ${(props) => props.$height}px;
  overflow: auto;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

const StyledSelectAllBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

const StyledChip = styled(Chip)`
  cursor: pointer;
`;
