import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";
import { mixin } from "~/util";

type Props = {
  text: React.ReactNode;
  color: "success" | "error" | "info";
  onAction?: () => void;
  actionLabel?: string;
};

export const SnackbarForOnWebsiteNotice: FC<Props> = ({ color, text, onAction, actionLabel }) => {
  return (
    <StyledBox
      py="12px"
      px="16px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gridGap="8px"
      width="100%"
      $color={color}
    >
      {color === "success" && <Icon icon="check" size="sm" color="primary" />}
      {color === "error" && <Icon icon="error" size="sm" color="secondary" />}
      <Typography variant="caption" bold>
        {text}
      </Typography>
      {onAction && (
        <StyledButton onClick={onAction} $color={color}>
          {actionLabel}
        </StyledButton>
      )}
    </StyledBox>
  );
};

const StyledBox = styled(Box)<{
  $color: "success" | "error" | "info";
}>`
  background-color: white;
  border-radius: 8px;
  ${mixin.breakUp.sm`
    width: 50vw;
  `}
  color: ${(props) => {
    switch (props.$color) {
      case "success":
        return props.theme.palette.primary.main;
      case "error":
        return props.theme.palette.secondary.main;
      case "info":
        return props.theme.palette.blue.main;
    }
  }};
  border: ${(props) => {
    let color = "";
    switch (props.$color) {
      case "success":
        color = props.theme.palette.primary.main;
        break;
      case "error":
        color = props.theme.palette.secondary.main;
        break;
      case "info":
        color = props.theme.palette.blue.main;
        break;
    }
    return `2px solid ${color}}`;
  }};
`;

const StyledButton = styled.button<{
  $color: "success" | "error" | "info";
}>`
  background-color: white;
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 8px;
  margin-left: 20px;
  min-width: 80px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;
