import { OnnEvent, OnnEventSlotDate } from "@onn/common";
import { format } from "date-fns";

export const generateInitialValues = ({
  onnEventSlotDate,
  defaultValueSettings,
  type,
}: {
  onnEventSlotDate?: OnnEventSlotDate;
  defaultValueSettings: OnnEvent["slotDefaultValueSetting"];
  type: "briefing_session" | "new_interview";
}) => {
  if (!onnEventSlotDate) {
    return {
      assigneeId: undefined,
      subAssigneeIds: [],
      briefingSessionCategory:
        type === "briefing_session"
          ? {
              type,
              briefingSessionCategoryId: undefined,
            }
          : {
              type,
              briefingSessionCategoryId: null,
            },
      slotDate: new Date(),
      slotFromTimeString: "10:00",
      slotUntilString: "11:00",
      capacity: "1",
      slotInfo: {
        type: defaultValueSettings?.slotType || "online",
        online: {
          description: defaultValueSettings?.online?.description || "",
          url: defaultValueSettings?.online?.url || "",
        },
        offline: {
          description: defaultValueSettings?.offline?.description || "",
          location: "",
          postCode: "",
          address: "",
        },
      },
    };
  }

  return {
    assigneeId: onnEventSlotDate.assigneeId || undefined,
    subAssigneeIds: onnEventSlotDate.subAssigneeIds,
    slotDate: onnEventSlotDate.from,
    slotFromTimeString: format(onnEventSlotDate.from, "HH:mm"),
    slotUntilString: format(onnEventSlotDate.until, "HH:mm"),
    capacity: String(onnEventSlotDate.capacity),
    slotInfo: {
      type: onnEventSlotDate.eventType,
      online: {
        description: (onnEventSlotDate.isOnline() && onnEventSlotDate.description) || "",
        url: onnEventSlotDate.url || "",
      },
      offline: {
        description: (onnEventSlotDate.isOffline() && onnEventSlotDate.description) || "",
        location: onnEventSlotDate.eventPlaceId || "",
        postCode: onnEventSlotDate.eventAddressPostCode || "",
        address: onnEventSlotDate.eventAddressText || "",
      },
    },
    briefingSessionCategory:
      type === "briefing_session"
        ? {
            type,
            briefingSessionCategoryId: onnEventSlotDate.briefingSessionCategoryId || undefined,
          }
        : {
            type,
            briefingSessionCategoryId: null,
          },
  };
};
