import { useCallback, useState } from "react";

import { useBulkUpdateEmployeesInformationWithCsv } from "~/hooks/employee/useBulkUpdateEmployeesInformation";
import { useConvertFileToBase64 } from "~/hooks/file";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

/**
 * アップロードボタンが押された時の処理を管理するhooks
 */
export const useHandleClickUploadButton = ({
  inputFile,
  closeModal,
  onUpload,
}: {
  inputFile?: File;
  closeModal: () => void;
  onUpload?: () => void | Promise<void>;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const { convertFileToBase64 } = useConvertFileToBase64();
  const { enqueueSnackbar } = useSnackbar();
  const { bulkUpdateEmployeesInformationWithCsv } = useBulkUpdateEmployeesInformationWithCsv();

  const handleClickUploadButton = useCallback(async () => {
    if (!inputFile) return;
    try {
      setIsUploading(true);

      const base64EncodedCsvFile = await convertFileToBase64(inputFile);
      await bulkUpdateEmployeesInformationWithCsv({
        base64EncodedCsvFile,
        isDryRun: false,
      });
      enqueueSnackbar(`候補者情報を一括更新しました`, {
        variant: "success",
      });

      onUpload?.();

      closeModal();
    } catch (error) {
      captureException({
        error: error as Error,
        tags: {
          type: "useHandleClickUploadButton",
        },
        extras: {
          inputFileText: await inputFile.text(),
        },
      });
      enqueueSnackbar(`候補者情報の一括更新に失敗しました。再度お試しください。`, {
        variant: "error",
      });
    } finally {
      setIsUploading(false);
    }
  }, [
    inputFile,
    convertFileToBase64,
    bulkUpdateEmployeesInformationWithCsv,
    enqueueSnackbar,
    onUpload,
    closeModal,
  ]);

  return { handleClickUploadButton, isUploading };
};
