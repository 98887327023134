import { Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

export type StyleProps = {
  style: React.CSSProperties;
  isColumnDirection?: boolean;
  isLastColumn: boolean;
};

export const CellItem: FC<
  StyleProps & {
    children: ReactNode;
  }
> = ({ style, children, isColumnDirection = false, isLastColumn }) => {
  return (
    <Box style={{ ...style }}>
      <StyledBox isLastColumn={isLastColumn} isColumnDirection={isColumnDirection}>
        {children}
      </StyledBox>
    </Box>
  );
};

const StyledBox = styled(Box)<{
  bgcolor?: string;
  isLastColumn: boolean;
  isColumnDirection: boolean;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isColumnDirection ? "column" : "row")};
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px ${(props) => (props.isLastColumn ? 0 : 1)}px 0px 0px;
  text-align: center;
`;
