import { Box } from "@mui/material";
import React, { FC } from "react";

import { RichMenuCellTypeSelector } from "./components/RichMenuCellTypeSelector";

import { positions } from "./components/const";

import { Typography } from "~/components/uiParts";
import { Tag } from "~/components/uiParts/Tag";
import { useRichMenuCellLinkExplanationImageUrl } from "~/hooks/richMenu/useRichMenuCellLinkExplanationImageUrl";

type Props = {
  isPortalRichMenu: boolean;
};

export const CellLinkSetting: FC<Props> = ({ isPortalRichMenu }) => {
  const explanationImageUrl = useRichMenuCellLinkExplanationImageUrl();
  return (
    <Box display="flex" flexDirection={"column"} rowGap={"16px"}>
      <Box display="flex" alignItems="center" columnGap={"8px"}>
        <Typography bold variant="body2">
          リンク設定
        </Typography>
        <Tag color="secondary" size="sm" text="必須" />
      </Box>
      <Typography variant="body2">
        設定した画像のAからFのエリアに該当するリンクをそれぞれ設定してください。
      </Typography>
      <img src={explanationImageUrl} />
      <Box display={"grid"} gridTemplateColumns={"32px 1fr"} rowGap={"16px"}>
        {positions.map((position) => (
          <React.Fragment key={position}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="body2">{position}</Typography>
            </Box>
            <RichMenuCellTypeSelector
              key={position}
              position={position}
              isPortalRichMenu={isPortalRichMenu}
            />
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
