import { z } from "zod";

import {
  ActionConditionInput,
  actionConditionInputSchema,
} from "./components/ActionConditionForm/actionConditionInputSchema";
import {
  DeliveryTimingInput,
  deliveryTimingInputSchema,
} from "./components/deliveryTimingInputSchema";

const changeRecruitmentStatusSchema = z.object({
  actionType: z.literal("changeRecruitmentStatus"),
  recruitmentStatusId: z.string({ required_error: "選考ステータスを選択してください" }),
});

const deliveryOnnEventSchema = z.object({
  actionType: z.literal("deliveryOnnEvent"),
  onnEventId: z.string({ required_error: "イベントを選択してください" }),
});

const deliveryOnnTaskSchema = z.object({
  actionType: z.literal("deliveryOnnTask"),
  onnTaskId: z.string({ required_error: "タスクを選択してください" }),
});

const sendContactMessageSchema = z.object({
  actionType: z.literal("sendContactMessage"),
  text: z
    .string({ required_error: "メッセージを入力してください" })
    .min(1, "メッセージを入力してください"),
});

export const inputStateSchema = z
  .object({
    actionSetting: z.discriminatedUnion("actionType", [
      changeRecruitmentStatusSchema,
      deliveryOnnEventSchema,
      deliveryOnnTaskSchema,
      sendContactMessageSchema,
    ]),
  })
  .and(deliveryTimingInputSchema)
  .and(actionConditionInputSchema);

const _actionTypes = {
  changeRecruitmentStatus: "changeRecruitmentStatus",
  deliveryOnnEvent: "deliveryOnnEvent",
  deliveryOnnTask: "deliveryOnnTask",
  sendContactMessage: "sendContactMessage",
} as const;

export type ActionType = (typeof _actionTypes)[keyof typeof _actionTypes];
export type ActionSettingInput =
  | {
      actionType: "changeRecruitmentStatus";
      recruitmentStatusId?: string;
      onnEventId?: string;
      onnTaskId?: string;
      text?: string;
    }
  | {
      actionType: "deliveryOnnEvent";
      recruitmentStatusId?: string;
      onnEventId?: string;
      onnTaskId?: string;
      text?: string;
    }
  | {
      actionType: "deliveryOnnTask";
      recruitmentStatusId?: string;
      onnEventId?: string;
      onnTaskId?: string;
      text?: string;
    }
  | {
      actionType: "sendContactMessage";
      recruitmentStatusId?: string;
      onnEventId?: string;
      onnTaskId?: string;
      text?: string;
    };
export type InputState = {
  actionSetting: ActionSettingInput;
} & DeliveryTimingInput &
  ActionConditionInput;

export type ValidInputState = z.infer<typeof inputStateSchema>;
