import { OnnEvent } from "@onn/common";
import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { FC } from "react";

type Props = {
  onnEvent: OnnEvent;
  renderForNormal: (onnEvent: OnnEvent) => JSX.Element;
  renderForNewInterview: (onnEvent: NewInterviewEvent) => JSX.Element;
  renderForBriefingSession: (onnEvent: BriefingSessionEvent) => JSX.Element;
};

export const EventTypeDisplaySwitcher: FC<Props> = ({
  onnEvent,
  renderForNormal,
  renderForNewInterview,
  renderForBriefingSession,
}) => {
  switch (onnEvent.type) {
    case "normal": {
      return renderForNormal(onnEvent);
    }
    case "new_interview": {
      return renderForNewInterview(OnnEvent.castToNewInterviewEvent(onnEvent));
    }
    case "briefing_session": {
      return renderForBriefingSession(OnnEvent.castToBriefingSessionEvent(onnEvent));
    }
    default: {
      const _exhaustiveCheck: never = onnEvent.type;
      return <></>;
    }
  }
};
