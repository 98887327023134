import { Box, Divider, Menu, MenuItem, Typography } from "@material-ui/core";
import { ContactMessageTemplate, ContactMessageTemplateFolder } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useMemo, useState } from "react";

import styled from "styled-components";

import { useSWRConfig } from "swr";

import { TemplateChangeMenuItem } from "./TemplateChangeMenuItem";
import { TemplateMenuItem } from "./TemplateMenuItem";

import { Key as TemplatesRefetchKey } from "~/hooks/contactMessageTemplate/useContactMessageTemplates";
import { useDeleteContactMessageTemplate } from "~/hooks/contactMessageTemplate/useDeleteContactMessageTemplate";
import { useMoveContactMessageTemplate } from "~/hooks/contactMessageTemplate/useMoveContactMessageTemplate";
import { useUpdateContactMessageTemplate } from "~/hooks/contactMessageTemplate/useUpdateContactMessageTemplate";
import { Key as FoldersRefetchKey } from "~/hooks/contactMessageTemplateFolder/useContactMessageTemplateFolder";
import { useCurrentUser } from "~/hooks/employee";

import { useModal } from "~/hooks/modal";

type Props = {
  contactMessageTemplates: ContactMessageTemplate[];
  templatesRefetchKey: TemplatesRefetchKey;
  foldersRefetchKey: FoldersRefetchKey;
  templateFolders: ContactMessageTemplateFolder[];
  reflectMessage: (v: string) => void;
  closeMenu: () => void;
  anchorEl: HTMLElement | null;
  handleMenuClose: () => void;
  isOpen: boolean;
  selectedTemplateFolder?: ContactMessageTemplateFolder;
  handleSubmitEditFolder: (newTemplateFolder: ContactMessageTemplateFolder) => void;
  handleDeleteFolder: (targetTemplateFolder: ContactMessageTemplateFolder) => void;
};

export const TemplateMenu: FC<Props> = ({
  contactMessageTemplates,
  templatesRefetchKey,
  foldersRefetchKey,
  templateFolders,
  reflectMessage,
  closeMenu,
  anchorEl,
  handleMenuClose,
  isOpen,
  selectedTemplateFolder,
  handleSubmitEditFolder,
  handleDeleteFolder,
}: Props) => {
  const [templateChangeMenu, setTemplateChangeMenu] = useState<HTMLElement | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<ContactMessageTemplate | undefined>(
    undefined
  );

  const movableFolders = useMemo(() => {
    return templateFolders.filter((folder) => folder.id !== selectedTemplate?.folderId);
  }, [templateFolders, selectedTemplate]);

  const { mutate } = useSWRConfig();

  const [moveTargetAnchorEl, setMoveTargetAnchorEl] = useState<HTMLElement | null>(null);

  const { updateContactMessageTemplate } = useUpdateContactMessageTemplate({
    contactMessageTemplates: contactMessageTemplates,
    templatesRefetchKey,
    foldersRefetchKey,
  });

  const { moveContactMessageTemplate } = useMoveContactMessageTemplate({
    contactMessageTemplates: contactMessageTemplates,
    templateFolders,
    templatesRefetchKey,
    foldersRefetchKey,
  });
  const { deleteContactMessageTemplate } = useDeleteContactMessageTemplate({
    contactMessageTemplates: contactMessageTemplates,
    refetchKey: templatesRefetchKey,
  });

  const { handleModal } = useModal();

  const { currentUser } = useCurrentUser();
  return (
    <>
      <StyledMenu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {
          <StyledMenuBox>
            {isEmpty(contactMessageTemplates) ? (
              <Box p={2}>
                <Typography variant="body2" color="textSecondary">
                  テンプレートが存在しません
                </Typography>
              </Box>
            ) : (
              contactMessageTemplates.map((contactMessageTemplate) => (
                <TemplateMenuItem
                  key={contactMessageTemplate.id}
                  isSelected={selectedTemplate?.id === contactMessageTemplate.id}
                  contactMessageTemplate={contactMessageTemplate}
                  isAdmin={currentUser.isAdmin()}
                  openTemplateChangeMenu={(e: React.MouseEvent<HTMLElement>) => {
                    setTemplateChangeMenu(e.currentTarget);
                    setSelectedTemplate(contactMessageTemplate);
                  }}
                  onClick={() => {
                    reflectMessage(contactMessageTemplate.text);
                    closeMenu();
                  }}
                />
              ))
            )}
          </StyledMenuBox>
        }
        {selectedTemplateFolder &&
          currentUser.isAdmin() && [
            <StyledDivider key="folderDivider" />,
            <StyledCreateOrEditMenuItem
              key="editFolder"
              onClick={() =>
                handleModal({
                  name: "editContactMessageTemplateFolderModal",
                  args: {
                    mode: "edit",
                    oldContactMessageTemplateFolder: selectedTemplateFolder,
                    onSubmit: handleSubmitEditFolder,
                    onDelete: () => {
                      handleDeleteFolder(selectedTemplateFolder);
                    },
                  },
                })
              }
            >
              <Typography variant="body2">フォルダを編集</Typography>
            </StyledCreateOrEditMenuItem>,
          ]}
      </StyledMenu>
      <StyledTemplateUpdateMenu
        anchorEl={templateChangeMenu}
        open={Boolean(templateChangeMenu)}
        onClose={() => {
          setTemplateChangeMenu(null);
          setSelectedTemplate(undefined);
        }}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
        getContentAnchorEl={null}
      >
        <TemplateChangeMenuItem
          label="編集"
          onClick={() => {
            handleModal({
              name: "editContactMessageTemplateModal",
              args: {
                mode: "edit",
                oldContactMessageTemplate: selectedTemplate,
                onSubmit: async (newTemplate: ContactMessageTemplate) => {
                  await updateContactMessageTemplate(newTemplate);
                },
                templateFolders,
              },
            });
          }}
        />
        <StyledMenuItem
          selected={moveTargetAnchorEl !== null}
          onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
            setMoveTargetAnchorEl(e.currentTarget)
          }
        >
          <Typography variant="body2">移動</Typography>
        </StyledMenuItem>
        <StyledMoveFolderMenu
          anchorEl={moveTargetAnchorEl}
          open={Boolean(moveTargetAnchorEl)}
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
          getContentAnchorEl={null}
          onClose={() => setMoveTargetAnchorEl(null)}
        >
          {movableFolders.length ? (
            movableFolders.map((folder) => (
              <StyledMoveFolderMenuItem
                key={folder.id}
                onClick={async () => {
                  setMoveTargetAnchorEl(null);
                  setTemplateChangeMenu(null);
                  handleMenuClose();
                  // NOTE: テンプレートが選択されている時に移動先のフォルダ一覧を表示できるという前提がある
                  if (!selectedTemplate) throw new Error("template is not selected");
                  await moveContactMessageTemplate(selectedTemplate, folder.id);
                }}
              >
                {folder.name}
              </StyledMoveFolderMenuItem>
            ))
          ) : (
            <Box p={2} px="24px">
              <Typography variant="body2" color="textSecondary">
                フォルダが存在しません
              </Typography>
            </Box>
          )}
        </StyledMoveFolderMenu>
        <TemplateChangeMenuItem
          label="削除"
          onClick={() => {
            if (!selectedTemplate) throw new Error("template is not selected");

            handleModal({
              name: "deleteContactMessageTemplateModal",
              args: {
                contactMessageTemplate: selectedTemplate,
                onSubmit: async (newTemplate: ContactMessageTemplate) => {
                  await deleteContactMessageTemplate(newTemplate);
                  //NOTE: フォルダ内のテンプレートはフォルダ一覧取得apiから取得しているので
                  // フォルダ内のテンプレート更新時には一覧の再取得が必要
                  if (selectedTemplateFolder) {
                    await mutate(foldersRefetchKey);
                  }
                  setTemplateChangeMenu(null);
                  handleMenuClose();
                },
              },
            });
          }}
        />
      </StyledTemplateUpdateMenu>
    </>
  );
};

const StyledMenuBox = styled(Box)`
  &.MuiBox-root {
    max-height: 242px; // スクロールできるような見た目にするため5.5行分の高さにしている (44 * 5.5)
    overflow-y: auto;
  }
`;

const StyledTemplateUpdateMenu = styled(Menu)`
  .MuiList-padding {
    padding: 12px 0;
  }
  .MuiPaper-root {
    margin-left: 24px;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-left: 8px;
    padding-top: 8px;
    width: 244px;
  }
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 10px 0 2px 0;
  }
`;

const StyledCreateOrEditMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const StyledMoveFolderMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-left: 8px;
    max-height: 242px; // スクロールできるような見た目にするため5.5行分の高さにしている (44 * 5.5)
    overflow-y: auto;
  }
`;

const StyledMoveFolderMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    width: 244px;
    font-size: 14px;
    padding: 8px 24px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 8px 24px;
    background-color: ${(props) => props.selected && props.theme.palette.action.selected};
  }
`;
