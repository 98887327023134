import { APISchema } from "@onn/common";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/api/onn-form-task-answers/id"]["DELETE"]["query"];

export const useDeleteOnnFormTaskAnswer = () => {
  const execDeleteOnnFormTaskAnswer = async ({
    onnFormTaskAnswerId,
  }: {
    onnFormTaskAnswerId: string;
  }) => {
    const body: Body = {
      "onn-form-task-answer-id": onnFormTaskAnswerId,
    };
    await apiClient.delete("/api/onn-form-task-answers/id", instanceToPlain(body) as Body);
  };

  return { execDeleteOnnFormTaskAnswer };
};
