import { Box, Chip as MuiChip } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC, MouseEventHandler } from "react";
import styled from "styled-components";

import { StatusForDisplayEventTable } from "../../utils/getStatusForDisplayEventTable";

import { Icon, Typography } from "~/components/uiParts";

import theme from "~/config/theme";

type Props = {
  lastReadAt: Date | null;
  statusForDisplayEventTable: StatusForDisplayEventTable;
  openMenu: MouseEventHandler<HTMLDivElement>;
};

// TODO: 共通化した packages/front/src/components/domains/onnEvents/components/AnswerStatusCell.tsx を使用する
export const AnswerStatusCell: FC<Props> = ({
  lastReadAt,
  statusForDisplayEventTable,
  openMenu,
}) => {
  switch (statusForDisplayEventTable) {
    case "not_notifiable":
      return (
        <CellWrapper bgcolor={theme.palette.grey[50]} p={"1px"}>
          <Box width="100%" display="flex" justifyContent="center">
            <Icon color="grey300" size="xl" icon="ban" />
          </Box>
        </CellWrapper>
      );
    case "before_answer_and_read": {
      // before_answer_and_readになる時点でlastReadAtは存在するが型ガードをしている
      if (!lastReadAt) return;
      const label = format(lastReadAt, "yyyy/MM/dd 既読");
      return (
        <CellWrapper p={"1px"}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
        </CellWrapper>
      );
    }
    case "before_answer_and_unread":
      return (
        <CellWrapper bgcolor={theme.palette.info} p={"1px"}>
          <Box width="100%" display="flex" justifyContent="center">
            <Icon color="lightGrey" size="xl" icon="horizontalBar" />
          </Box>
        </CellWrapper>
      );
    case "unregistered_attendance_and_past":
      return (
        <CellWrapper bgcolor={theme.palette.secondary.light} p={"1px"}>
          <StyledSecondaryChip
            label={"参加未登録"}
            icon={<Icon icon="dropdownArrow" color="secondary" size="sm" />}
            onClick={openMenu}
          />
        </CellWrapper>
      );

    case "unregistered_attendance_and_not_past":
      return (
        <CellWrapper bgcolor={theme.palette.blue.light} p={"1px"}>
          <StyledBlueChip label={"回答済み"} />
        </CellWrapper>
      );

    case "absent_attendance":
      return (
        <CellWrapper bgcolor={theme.palette.grey[50]} p={"1px"}>
          <StyledGreyChip
            label={"不参加"}
            icon={<Icon icon="dropdownArrow" color="white" size="sm" />}
            onClick={openMenu}
          />
        </CellWrapper>
      );
    case "registered_attendance":
      return (
        <CellWrapper bgcolor={theme.palette.primary.light} p={"1px"}>
          <StyledPrimaryChip
            label={"参加済み"}
            icon={<Icon icon="dropdownArrow" color="white" size="sm" />}
            onClick={openMenu}
          />
        </CellWrapper>
      );
  }
};

const CellWrapper = styled(Box)<{ bgcolor?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px 1px 0px 0px;
`;

const StyledChipBase = styled(MuiChip)`
  &.MuiChip-root {
    > .MuiChip-icon {
      order: 1; // アイコンと文字列の表示順を入れ替える
      margin-left: -12px;
      margin-right: 4px;
    }
    height: 32px;
    font-size: 14px;
    font-weight: 700;
  }
`;

const StyledBlueChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: ${(props) => props.theme.palette.blue.main};
    border: 1px solid ${(props) => props.theme.palette.blue.main};
    color: white;
  }
`;

const StyledSecondaryChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: white;
    border: 1px solid ${(props) => props.theme.palette.secondary.main};
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const StyledGreyChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: ${(props) => props.theme.palette.grey[300]};
    border: 1px solid ${(props) => props.theme.palette.grey[300]};
    color: white;
  }
`;

const StyledPrimaryChip = styled(StyledChipBase)`
  &.MuiChip-root {
    background-color: ${(props) => props.theme.palette.primary.main};
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    color: white;
  }
`;
