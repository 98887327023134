import { Box } from "@material-ui/core";
import { TaskOptionTypes } from "@onn/common";
import React, { memo } from "react";

import { DropResult } from "react-beautiful-dnd";

import { CHECK_BOX_QUESTION } from "../../types";
import { AddOptionRow } from "../parts/AddOptionRow";
import { OptionInputRow } from "../parts/OptionInputRow";
import { QuestionFormPaper } from "../parts/QuestionFormPaper";

import { BaseProps } from "./types";

import { DnDDraggable, DnDDroppable, DnDProvider } from "~/components/uiParts";

type Props = BaseProps & {
  options: CHECK_BOX_QUESTION["options"];
  onChangeOptionType: (e: React.ChangeEvent<{ value: keyof typeof TaskOptionTypes }>) => void;
  onClickDeleteOption: (optionId: string) => void;
  onChangeOptionLabel: (optionId: string, optionLabel: string) => void;
  onClickAddOption: () => void;
  onChangeOptionsOrder: (result: DropResult) => void;
};

export const CheckBoxQuestionFormUIMemo = memo<Props>(
  ({
    isRequired,
    options,
    onChangeTitle,
    onChangeOptionType,
    onChangeIsRequired,
    onClickDelete,
    onClickDuplication,
    onClickDeleteOption,
    onChangeOptionLabel,
    onClickAddOption,
    onChangeOptionsOrder,
    disabledTitle,
    disabledOptionType,
    questionDragHandleProps,
    titleTextField,
  }) => {
    return (
      <QuestionFormPaper
        onChangeOptionType={onChangeOptionType}
        onChangeTitle={onChangeTitle}
        selectedOptionType={"CHECK_BOX"}
        onChangeIsRequired={onChangeIsRequired}
        isRequired={isRequired}
        onClickDelete={onClickDelete}
        onClickDuplication={onClickDuplication}
        disabledTitle={disabledTitle}
        disabledOptionType={disabledOptionType}
        questionDragHandleProps={questionDragHandleProps}
        titleTextField={titleTextField}
      >
        <DnDProvider onDragEnd={onChangeOptionsOrder}>
          <DnDDroppable droppableId="optionDrop" isDropDisabled={false}>
            {options.map((option, index) => (
              <DnDDraggable
                isDragDisabled={false}
                draggableId={option.id}
                index={index}
                key={option.id}
              >
                <Box paddingX={5}>
                  <OptionInputRow
                    key={option.id}
                    optionId={option.id}
                    optionLabel={option.text}
                    handleDeleteOption={onClickDeleteOption}
                    handleOnChangeOptionLabel={onChangeOptionLabel}
                    isShowDeleteIcon={options.length > 1}
                    questionType="CHECK_BOX"
                    disabled={option.disabled}
                  />
                </Box>
              </DnDDraggable>
            ))}
          </DnDDroppable>
        </DnDProvider>
        <Box paddingX={5} mb={4}>
          <AddOptionRow handleAddOption={onClickAddOption} questionType="CHECK_BOX" />
        </Box>
      </QuestionFormPaper>
    );
  }
);

// TODO: 「その他」を実装
// const AddOtherButton = styled(Typography)`
//   color: ${(props) => props.theme.palette.primary.main};
//   cursor: pointer;

//   &.MuiTypography-root {
//     padding-right: 8px;
//     padding-top: 4px;
//     padding-bottom: 4px;

//     &:hover {
//       background-color: ${(props) => props.theme.palette.grey[50]};
//     }
//   }
// `;
