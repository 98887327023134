import { zodResolver } from "@hookform/resolvers/zod";
import { ContactMessageTemplate } from "@onn/common";
import { useForm } from "react-hook-form";

import { ContactMessageTemplateFormSchema } from "./ContactMessageTemplateFormSchema";

export const useContactMessageTemplateForm = ({
  oldContactMessageTemplate,
  tenantId,
  spaceId,
  onSubmit,
}: {
  oldContactMessageTemplate?: ContactMessageTemplate;
  tenantId: string;
  spaceId: string;
  onSubmit: (newContactMessageTemplate: ContactMessageTemplate) => void;
}) => {
  const form = useForm<{
    title: string;
    text: string;
    folderId: string;
  }>({
    defaultValues: { ...oldContactMessageTemplate },
    mode: "onChange",
    resolver: zodResolver(ContactMessageTemplateFormSchema),
  });

  return {
    ...form,
    handleSubmit: form.handleSubmit((values) => {
      const template = oldContactMessageTemplate
        ? oldContactMessageTemplate.update(values)
        : ContactMessageTemplate.createNew({
            tenantId,
            spaceId,
            ...values,
          });

      onSubmit(template);
    }),
  };
};
