import React from "react";
import styled from "styled-components";

import { Chip } from "~/components/uiParts";
import theme from "~/config/theme";

export const NewGraduateAlreadyInvitedChip = (): JSX.Element => {
  return <StyledChip label={"招待中"} />;
};

const StyledChip = styled(Chip).attrs({ color: "muted" })`
  &.MuiChip-root {
    background-color: ${() => theme.palette.primary.light};
    padding: 4px 8px;
    height: 18px;
  }
  .MuiChip-label {
    padding: 0px;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
