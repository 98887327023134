import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useMutateContactRoomPins } from "./useContactRoomPins";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/contact_room_api/contact-room-pins"]["DELETE"];

export const useDeleteContactRoomPin = () => {
  const { mutateContactRoomPins } = useMutateContactRoomPins();

  const deleteContactRoomPin = useCallback(
    async ({ contactRoomPinId }: { contactRoomPinId: string }) => {
      const requestBody: EndPoint["body"] = {
        contactRoomPinId,
      };
      await apiClient.delete("/contact_room_api/contact-room-pins", undefined, requestBody);
      await mutateContactRoomPins();
      return;
    },
    [mutateContactRoomPins]
  );

  return { deleteContactRoomPin };
};
