import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import {
  AnswerOnnTaskTriggerSetting,
  AnyTriggerSetting,
  EvaluateOnnEventTriggerSetting,
} from "@onn/common";
import React, { FC } from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { useEditModeContext } from "../../../../EditModeContextProvider";

import {
  ActionConditionTypeSelectionForm,
  ActionConditionTypeSelectionFormProps,
} from "./components/ActionConditionTypeSelectionForm";

import {
  OnnEventEvaluationRankSelectionForm,
  OnnEventEvaluationRankSelectionFormProps,
} from "./components/OnnEventEvaluationRankSelectionForm";
import {
  OnnTaskQuestionAnswerSelectionForm,
  OnnTaskQuestionAnswerSelectionFormProps,
} from "./components/OnnTaskQuestionAnswerSelectionForm";
import { TagSelectionForm, TagSelectionFormProps } from "./components/TagSelectionForm";

import { Divider, Icon, Typography } from "~/components/uiParts";
import theme from "~/config/theme";

type Props = {
  actionConditionTypeSelectionFormProps: Omit<
    ActionConditionTypeSelectionFormProps,
    "triggerSetting"
  >;
  tagSelectionFormProps: TagSelectionFormProps;
  onnEventEvaluationRankSelectionFormProps: OnnEventEvaluationRankSelectionFormProps;
  onnTaskQuestionAnswerSelectionFormProps: Omit<
    OnnTaskQuestionAnswerSelectionFormProps,
    "onnFormTaskId"
  >;
  triggerSetting: AnyTriggerSetting;
};

export const ActionConditionForm: FC<Props> = ({
  actionConditionTypeSelectionFormProps,
  tagSelectionFormProps,
  onnEventEvaluationRankSelectionFormProps,
  onnTaskQuestionAnswerSelectionFormProps,
  triggerSetting,
}) => {
  let describeArea: React.ReactElement = <></>;
  let formArea: React.ReactElement = <></>;
  let triggerRelatedResource: React.ReactElement | null = null;

  switch (actionConditionTypeSelectionFormProps.value) {
    case "EmployeeTagActionCondition": {
      describeArea = (
        <StyledTypography variant="overline" color="textSecondary">
          {`トリガーが実行された時、「タグ」が候補者に付与されていた場合アクションを実行します。`}
        </StyledTypography>
      );
      formArea = <TagSelectionForm {...tagSelectionFormProps} />;
      break;
    }
    case "OnnEventEvaluationActionCondition": {
      if (triggerSetting.type === "EvaluateOnnEventTriggerSetting") {
        describeArea = (
          <StyledTypography variant="overline" color="textSecondary">
            {`トリガーが実行された時、特定の「評価」が付与されていた場合アクションを実行します。`}
          </StyledTypography>
        );
        formArea = (
          <OnnEventEvaluationRankSelectionForm {...onnEventEvaluationRankSelectionFormProps} />
        );
        triggerRelatedResource = <TriggerRelatedResource triggerSetting={triggerSetting} />;
      }
      break;
    }
    case "OnnFormTaskResultActionCondition": {
      if (triggerSetting.type === "AnswerOnnTaskTriggerSetting") {
        describeArea = (
          <StyledTypography variant="overline" color="textSecondary">
            {`トリガーに設定されたタスクの、特定の「設問」と「選択肢」が回答されていた場合アクションを実行します。`}
          </StyledTypography>
        );
        formArea = (
          <OnnTaskQuestionAnswerSelectionForm
            {...onnTaskQuestionAnswerSelectionFormProps}
            onnFormTaskId={triggerSetting.onnTaskId}
          />
        );
        triggerRelatedResource = <TriggerRelatedResource triggerSetting={triggerSetting} />;
      }
      break;
    }
    case null:
    case undefined: {
      break;
    }
    default: {
      const _exhaustiveCheck: never = actionConditionTypeSelectionFormProps.value;
      break;
    }
  }

  return (
    <Root>
      <Typography variant="body2" bold>
        アクション条件
      </Typography>
      <Stack spacing={"8px"}>
        <ActionConditionTypeSelectionForm
          {...actionConditionTypeSelectionFormProps}
          triggerSetting={triggerSetting}
        />
        {describeArea}
      </Stack>
      {formArea}
      {triggerRelatedResource && <Divider />}
      {triggerRelatedResource && triggerRelatedResource}
    </Root>
  );
};

const TriggerRelatedResource = ({
  triggerSetting,
}: {
  triggerSetting: AnswerOnnTaskTriggerSetting | EvaluateOnnEventTriggerSetting;
}) => {
  const { allOnnEventsMap, allOnnTasksMap } = useEditModeContext();
  let label = "";
  let to = "";
  switch (triggerSetting.type) {
    case "AnswerOnnTaskTriggerSetting": {
      label = allOnnTasksMap.get(triggerSetting.onnTaskId)?.title || "";
      to = `/onn_tasks/${triggerSetting.onnTaskId}`;
      break;
    }
    case "EvaluateOnnEventTriggerSetting": {
      label = allOnnEventsMap.get(triggerSetting.onnEventId)?.title || "";
      to = `/events/${triggerSetting.onnEventId}`;
      break;
    }
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="caption" bold>
        トリガーに設定された対象
      </Typography>
      <StyledLink to={to} target="_blank">
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: theme.palette.background.paper,
            cursor: "pointer",
            borderRadius: "4px",
          }}
          px="8px"
          height="40px"
        >
          <Typography variant="caption" bold color="primary">
            {label}
          </Typography>
          <Icon icon="openInNew" size="md" color="primary" />
        </Box>
      </StyledLink>
    </Box>
  );
};

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1;
    display: block;
  }
`;

const Root = styled(Box)`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const StyledLink = styled(Link)`
  width: 100%;
  display: contents;
  text-decoration: none;
  color: inherit;
`;
