import { BriefingSessionEvent, NewInterviewEvent } from "@onn/common";
import React, { useCallback, useState } from "react";

import { OnClickChip, OnClickMenuItem } from "./AttendanceStatusMenu";
import { AttendanceStatus } from "./types";

import { useUpdateAttendanceStatus } from "~/hooks/onnEvent";
import { mutateDeterminedDate } from "~/hooks/onnEvent/determinedDate/useDeterminedDate";
import { mutateOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";
import { useSnackbar } from "~/hooks/shared";

const AttendanceStatusLabelMap = {
  UNREGISTERED: "参加未登録",
  ATTENDED: "参加済み",
  ABSENT: "不参加",
};

export const useAttendanceStatusMenu = ({
  onnEvent,
}: {
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
}) => {
  const { execUpdateAttendanceStatus } = useUpdateAttendanceStatus();
  const { enqueueSnackbar } = useSnackbar();

  const [currentStatus, setCurrentStatus] = useState<AttendanceStatus | null>(null);
  const [currentEmployeeId, setCurrentEmployeeId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const onClickMenuItem = useCallback<OnClickMenuItem>(
    (event, attendanceStatus, employeeId) => {
      const attendanceStatus_ = convertStatus(attendanceStatus);

      event.preventDefault();
      setAnchorEl(null);

      execUpdateAttendanceStatus({
        onnEventId: onnEvent.id,
        targetEmployeeId: employeeId,
        updates: {
          attendanceStatus: convertStatus(attendanceStatus),
        },
        type: onnEvent.type,
      })
        .then(() => {
          enqueueSnackbar(
            `ステータスが「${AttendanceStatusLabelMap[attendanceStatus_]}」に変更されました`,
            { variant: "success" }
          );
          mutateDeterminedDate(onnEvent.id);
          mutateOnnEventAnswersForDisplay(onnEvent.id);
        })
        .catch(() => {
          enqueueSnackbar("エラーが発生しました。Onnサポートチームまでお問い合わせください。", {
            variant: "error",
          });
        });
    },
    [enqueueSnackbar, execUpdateAttendanceStatus, onnEvent]
  );

  const onClickChip = useCallback<OnClickChip>(
    ({ newGraduateId, event, attendanceStatus }) => {
      setCurrentStatus(attendanceStatus);
      setCurrentEmployeeId(newGraduateId);
      openMenu(event);
    },
    [openMenu]
  );

  return {
    anchorEl,
    currentStatus,
    currentEmployeeId,
    closeMenu,
    onClickMenuItem,
    onClickChip,
  };
};

const convertStatus = (attendanceStatus: AttendanceStatus) => {
  switch (attendanceStatus) {
    case "absent_attendance": {
      return "ABSENT";
    }
    case "registered_attendance": {
      return "ATTENDED";
    }
    case "unregistered_attendance_and_past": {
      return "UNREGISTERED";
    }
    default: {
      const _exhaustiveCheck: never = attendanceStatus;
      return _exhaustiveCheck;
    }
  }
};
