import { Box, Grid } from "@material-ui/core";

import { OnnFormTask } from "@onn/common";
import React, { useMemo } from "react";

import { OnnTaskCondition } from "../../../types/condition";
import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";
import { SelectMultipleConditionDropdown } from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";
import { SelectSingleConditionDropdown } from "../../parts/dropdown-menus/SelectSingleConditionDropdown";

import { CommonProps } from "./type";

import { Choice } from "~/components/types/choice";
import { Loading } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useOnnTasks } from "~/hooks/onnTask";

const TARGET = "onnTask";

type Props = CommonProps<OnnTaskCondition>;

export const OnnTaskConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { currentUser } = useCurrentUser();
  const { data: onnTasks = [], isLoading: isLoadingOnnTasks } = useOnnTasks({
    tenantId: currentUser.tenantId,
  });

  type TaskChoice = Choice<OnnFormTask["id"]>;
  const onnTaskChoices = useMemo(
    () =>
      onnTasks.map<TaskChoice>((task) => ({
        label: task.title,
        value: task.id,
      })),
    [onnTasks]
  );

  const selectedTaskChoice = onnTaskChoices.find((choice) => choice.value === condition.taskId);
  const onChangeTask = (task: TaskChoice) => {
    onChangeCondition(index, {
      ...condition,
      taskId: task.value,
    });
  };

  type AnswerStatusChoice = Choice<OnnTaskCondition["answerStatuses"][number]>;
  const answerStatusChoices: AnswerStatusChoice[] = [
    { label: "未配信", value: "not_delivered" },
    { label: "未回答", value: "before_answer" },
    { label: "回答済み", value: "after_answer" },
  ] as const;

  const selectedAnswerStatusChoices = answerStatusChoices.filter((choice) => {
    return condition.answerStatuses?.includes(choice.value);
  });
  const onChangeAnswerStatuses = (answerStatuses: AnswerStatusChoice[]) => {
    onChangeCondition(index, {
      ...condition,
      answerStatuses: answerStatuses.map((choice) => choice.value),
    });
  };

  if (isLoadingOnnTasks) {
    return <Loading size="small" />;
  }

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target) => onChangeTarget(index, target)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectSingleConditionDropdown
            placeHolder="タスクを選択"
            key={"event"}
            selectedChoice={selectedTaskChoice}
            choices={onnTaskChoices}
            onChange={onChangeTask}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectMultipleConditionDropdown
            placeHolder="ステータスを選択"
            key={"answerStatuses"}
            selectedChoices={selectedAnswerStatusChoices}
            choices={answerStatusChoices}
            onChange={onChangeAnswerStatuses}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
