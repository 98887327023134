import { v4 } from "uuid";

import { CheckBoxQuestion, OnnTaskQuestion as Question, RadioQuestion } from "../Question";

import { IFormRevisionSchema, formRevisionSchema } from "./schema";

export class FormRevision implements IFormRevisionSchema {
  static readonly validator = formRevisionSchema;
  id: string;
  tenantId: string;
  formTaskId: string;
  createdAt: Date;
  createdUserId: string;
  questions: Question[];

  constructor(init: ExcludeMethods<FormRevision>) {
    const parsedInit = FormRevision.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.formTaskId = parsedInit.formTaskId;
    this.createdAt = parsedInit.createdAt;
    this.createdUserId = parsedInit.createdUserId;
    this.questions = parsedInit.questions;
  }

  public static create(params: Optional<ExcludeMethods<FormRevision>, "id" | "createdAt">) {
    return new FormRevision({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
    });
  }

  public clone(): FormRevision {
    return new FormRevision(this);
  }

  public updateQuestions({
    updatedQuestions,
    isAlreadySentToSomeone,
  }: {
    updatedQuestions: Question[];
    isAlreadySentToSomeone: boolean; // 既に誰かに送信済みかどうか
  }) {
    this.validate({ questions: updatedQuestions });

    // 未送信のタスクに対しては制限なしに更新できる
    if (!isAlreadySentToSomeone) {
      this.questions = updatedQuestions;
      return;
    }

    // 送信済みのタスクに対しては、更新に制限がかかる

    // 送信済みのタスクに対して、設問の削除はできる
    this.questions = updatedQuestions.map((updatedQuestion) => {
      const existingQuestion = this.questions.find((q) => q.id === updatedQuestion.id);
      const isNew = !existingQuestion;
      // 送信済みのタスクに対して、設問の追加はできる
      if (isNew) return updatedQuestion;

      // 送信済みのタスクに対して、設問タイプを変更できない
      if (existingQuestion.type !== updatedQuestion.type) return existingQuestion;

      // 送信済みの質問に対しては、単数選択設問・複数選択設問ともに選択肢を変更できない
      if (existingQuestion.type === "CHECK_BOX" || existingQuestion.type === "RADIO") {
        const _updatedQuestion = updatedQuestion as CheckBoxQuestion | RadioQuestion;
        const isChangedExistingOptionLabel = FormRevision.isChangedExistingOptionLabel(
          existingQuestion.options,
          _updatedQuestion.options
        );
        if (isChangedExistingOptionLabel)
          throw new Error(
            "配信済みのタスクに関しては、すでに設定されている設問の選択肢の変更はできません"
          );
        return _updatedQuestion;
      }

      return updatedQuestion;
    });
  }

  // 引数で渡された更新前と更新後の設問の選択肢のラベルが、変更されているかどうかを判定する
  static isChangedExistingOptionLabel = (
    currentOptions: {
      id: string;
      text: string;
    }[],
    updatedOptions: {
      id: string;
      text: string;
    }[]
  ) => {
    return updatedOptions.some((uo) => {
      const existingOption = currentOptions.find((co) => co.id === uo.id);
      const isExist = !!existingOption;
      if (!isExist) return false;
      if (isExist && uo.text !== existingOption.text) return true;
      return false;
    });
  };

  public validate(update: Partial<FormRevision>) {
    FormRevision.validator.parse({ ...this, ...update });
  }
}
