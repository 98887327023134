import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { useNavigate } from "react-router-dom";

import { AnnouncementsListTable } from "./AnnouncementsListTable/AnnouncementsListTable";

import { Button, Icon, Loading } from "~/components/uiParts";
import { useAnnouncements } from "~/hooks/announcement";
import { useCurrentUser } from "~/hooks/employee";

export const AnnouncementTab: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { data: announcements = [], isLoading } = useAnnouncements();

  if (isLoading) <Loading size="large" fullHeight />;

  return (
    <Box pt={5} pb={4}>
      <Box>
        <Box mb="24px" display="flex">
          <Box display="flex" alignItems="flex-end" gridGap="32px">
            {currentUser.isAdmin() && (
              <Button
                color="primary"
                variant="outlined"
                borderRadius="regular"
                startIcon={<Icon icon="add" color="primary" size="md" />}
                onClick={() => navigate("/announcements/new")}
              >
                新規作成
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <AnnouncementsListTable announcements={announcements} isLoading={isLoading} />
    </Box>
  );
};
