import { Box } from "@material-ui/core";
import React from "react";

import { Divider, Typography } from "~/components/uiParts";

type Props = {
  tagSelector: JSX.Element;
};
export const ModalContentLayout = ({ tagSelector }: Props): JSX.Element => {
  return (
    <>
      <Box pt={"24px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography>付与するタグを選択してください。</Typography>
        <Typography>タグを付与すると、選択中の候補者の詳細に反映されます。</Typography>
      </Box>
      <Box py={"24px"}>
        <Divider />
      </Box>
      {tagSelector}
      <Box pt={"24px"}>
        <Divider />
      </Box>
    </>
  );
};
