import { Employee } from "../../Employee";

import { IBaseOnnTaskSchema, baseOnnTaskSchema } from "./schema";

export abstract class BaseOnnTask implements IBaseOnnTaskSchema {
  static readonly validator = baseOnnTaskSchema;
  abstract type: "FORM_TASK";

  id: string;
  title: string;
  description: string;
  filePaths: string[];
  scheduledDate: Date | null;
  deadlineDate: Date | null;
  firstDeliveredAt: Date | null;
  tenantId: string;
  spaceId: string;
  assigneeId: string;
  isAllowEditAnswer: boolean;
  canAnswerAfterDeadline: boolean;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<BaseOnnTask>) {
    const parsedInit = BaseOnnTask.validator.parse(init);

    this.id = parsedInit.id;
    this.title = parsedInit.title;
    this.description = parsedInit.description;
    this.filePaths = parsedInit.filePaths;
    this.scheduledDate = parsedInit.scheduledDate;
    this.deadlineDate = parsedInit.deadlineDate;
    this.firstDeliveredAt = parsedInit.firstDeliveredAt;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.assigneeId = parsedInit.assigneeId;
    this.isAllowEditAnswer = parsedInit.isAllowEditAnswer;
    this.canAnswerAfterDeadline = parsedInit.canAnswerAfterDeadline;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  abstract isExceededScheduledDate(): boolean;
  abstract isExceededDeadlineDate(): boolean;
  abstract isDeliverySettingsCompleted(): boolean;
  abstract isNearDeadline(): boolean;
  abstract isEditable(currentUser: Employee): boolean;
  abstract isDeletable(currentUser: Employee): boolean;
  abstract isRemindable(currentUser: Employee): boolean;
}
