import { Employee } from "../../Employee";
import { Address, Affiliation, GraduationYearAndMonth, KanaName } from "../../EmployeeInformation";
import { ContactRoom } from "../ContactRoom";

export abstract class ContactRoomWithEmployee extends ContactRoom {
  employee?: Employee; // contactRoomのemployeeIdに紐づくEmployee

  // NOTE: employeeInformationに格納されているコンタクトルーム検索対象
  // employeeInformationをそのまま格納するとパフォーマンスが落ちるため、検索に必要な情報だけを格納する
  kanaName?: KanaName;
  phoneNumber?: string;
  homePhoneNumber?: string;
  affiliation?: Affiliation;
  graduationYearAndMonth?: GraduationYearAndMonth;
  address?: Address;

  constructor(init: {
    contactRoom: ContactRoom;
    employee?: Employee;
    kanaName?: KanaName;
    phoneNumber?: string;
    homePhoneNumber?: string;
    affiliation?: Affiliation;
    graduationYearAndMonth?: GraduationYearAndMonth;
    address?: Address;
  }) {
    super(init.contactRoom);
    this.employee = init.employee;
    this.kanaName = init.kanaName;
    this.phoneNumber = init.phoneNumber;
    this.homePhoneNumber = init.homePhoneNumber;
    this.affiliation = init.affiliation;
    this.graduationYearAndMonth = init.graduationYearAndMonth;
    this.address = init.address;
  }

  abstract getRoomName(): string;

  abstract getHonorificRoomName(): string;
}
