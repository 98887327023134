import { Box } from "@material-ui/core";
import React, { useMemo, FC } from "react";
import styled from "styled-components";

import { useReminderForm } from "./hooks/useReminderForm";
import { RemindedAtForm } from "./parts/RemindedAtForm";
import { TitleForm } from "./parts/TitleForm";

import { Button, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (params: { title: string; remindedAt: Date }) => void;
};

export const CreateReminderModal: FC<Props> = ({ open, onCancel, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    trigger,
  } = useReminderForm(onSubmit);

  const content = useMemo(
    () => (
      <>
        <TitleForm control={control} />
        <RemindedAtForm control={control} trigger={trigger} />
      </>
    ),
    [control, trigger]
  );

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="primary"
          disabled={!isValid}
          onClick={async () => {
            await handleSubmit();
            onCancel();
          }}
        >
          追加
        </Button>
      </StyledButtonContainer>
    ),
    [handleSubmit, isValid, onCancel]
  );

  return (
    <Modal
      title="リマインダー追加"
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
