import { Box } from "@material-ui/core";
import { EmployeeInformationGroupWithFieldAndOptions } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent } from "../../share/csvDownload";
import {
  DownloadCsvDataButton,
  EmbedCsvDataButton,
} from "../../share/csvDownload/DownloadSampleFileStepContent";
import { UploadFileStepContent } from "../../share/csvDownload/UploadFileStepContent";

import { useHandleInputCsvFileWithValidation } from "../../share/csvDownload/useHandleInputCsvFileWithValidation";

import { useGenerateSampleCSV } from "./useGenerateSampleCSV";

import { useHandleClickUploadButton } from "./useHandleClickUploadButton";

import { Modal, VerticalStepper } from "~/components/uiParts";
import { useBulkUpdateEmployeesInformationWithCsv } from "~/hooks/employee/useBulkUpdateEmployeesInformation";
import { useDownloadBulkUpdateEmployeeInfoSettingCSV } from "~/hooks/employee/useDownloadBulkUpdateEmployeeInfoSettingCSV";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type Props = {
  open: boolean;
  onCancel: () => void;
  onUpdate?: () => void | Promise<void>;
  employeeInformationGroups: EmployeeInformationGroupWithFieldAndOptions[];
};

export const UpdateEmployeeInformationWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onUpdate,
  employeeInformationGroups,
}) => {
  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();
  const { activeStep, handleNext } = useActiveStep();

  const { bulkUpdateEmployeesInformationWithCsv } = useBulkUpdateEmployeesInformationWithCsv();

  const { generateSampleCSV } = useGenerateSampleCSV();

  const { handleInputFile, errorMessages, isCheckingCsv, fileLabel, inputFile } =
    useHandleInputCsvFileWithValidation({
      validate: bulkUpdateEmployeesInformationWithCsv,
      csvUploadErrorLabel: "候補者情報一括追加",
    });

  const { handleClickUploadButton, isUploading } = useHandleClickUploadButton({
    inputFile,
    closeModal: onCancel,
    onUpload: onUpdate,
  });

  const sampleCsv = useMemo(
    () => generateSampleCSV(employeeInformationGroups),
    [employeeInformationGroups, generateSampleCSV]
  );

  const isUploadButtonDisabled =
    isCheckingCsv || isUploading || errorMessages.length > 0 || !inputFile;
  const isUploadButtonLoading = isCheckingCsv || isUploading;

  const { downloadBulkUpdateEmployeeInfoSettingCSV, isLoading: isLoadingSettingFile } =
    useDownloadBulkUpdateEmployeeInfoSettingCSV();

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            downLoadCsvButtons={
              <Box mt={4} display="flex" flexDirection="row" gridGap={16}>
                <EmbedCsvDataButton
                  buttonText="サンプルファイルをダウンロード"
                  csvData={sampleCsv}
                  filename="候補者情報一括更新_アップロード用サンプルファイル"
                />
                <DownloadCsvDataButton
                  buttonText="設定値ファイルをダウンロード"
                  isLoading={isLoadingSettingFile}
                  onClick={downloadBulkUpdateEmployeeInfoSettingCSV}
                />
              </Box>
            }
          />
        );
      case 2:
        return (
          <UploadFileStepContent
            errorMessage={errorMessages.join("\n")}
            fileLabel={fileLabel}
            onCancel={onCancel}
            handleOnClickUpload={handleClickUploadButton}
            isUploadButtonDisabled={isUploadButtonDisabled}
            isUploadButtonLoading={isUploadButtonLoading}
            filePickerProps={{
              onChange: handleInputFile,
              accepts: ["csv"],
              multiple: false,
            }}
          />
        );
    }
  }, [
    activeStep,
    downloadBulkUpdateEmployeeInfoSettingCSV,
    errorMessages,
    fileLabel,
    handleClickUploadButton,
    handleInputFile,
    handleNext,
    isLoadingSettingFile,
    isUploadButtonDisabled,
    isUploadButtonLoading,
    onCancel,
    sampleCsv,
  ]);

  return (
    <Modal
      open={open}
      title={
        isShowSpaceOnScreen(spaces)
          ? `候補者情報一括更新｜${currentSpace.name}`
          : "候補者情報一括更新"
      }
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const useActiveStep = () => {
  const [activeStep, setActiveStep] = useState(1);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return { activeStep, handleNext };
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
