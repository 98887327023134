import { z } from "zod";

import { LinePlatformRichMenuSchema } from "../../_gen/zodSchema";

import { cell1To6InfoSchema } from "../RichMenuCellSetting/schema";

export const linePlatformRichMenuSchema = LinePlatformRichMenuSchema.merge(cell1To6InfoSchema);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ILinePlatformRichMenu extends z.infer<typeof linePlatformRichMenuSchema> {}
