import { OnnTaskQuestion } from "@onn/common";

import { Action, State } from "../reducer";

import { useSubmitHandler } from "./useSubmitHandler";
import { useUploadAnswerFile } from "./useUploadAnswerFile";
import { useValidateInput } from "./useValidateInput";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";

export const usePageForFormTaskCore = ({
  state,
  isPreview,
  formRevisionId,
  onnFormTaskId,
  questions,
  isAlreadyAnswered,
  dispatch,
}: {
  state: State;
  isPreview?: boolean;
  formRevisionId: string;
  onnFormTaskId: string;
  questions: OnnTaskQuestion[];
  isAlreadyAnswered: boolean;
  dispatch: React.Dispatch<Action>;
}) => {
  const { onClickSubmit, isSubmitting } = useSubmitHandler({
    state,
    isPreview,
    formRevisionId,
    onnFormTaskId,
    isAlreadyAnswered,
  });

  const { onChangeFile, isUploading } = useOnChangeFileQuestion({
    dispatch,
    onnFormTaskId,
  });
  const { validateInput } = useValidateInput();

  const isValid = validateInput(state, questions);

  const isDisabledSubmit = !isValid || isSubmitting || isUploading;

  const isSubmitButtonLoading = isSubmitting || isUploading;
  return {
    onClickSubmit,
    isSubmitting,
    isDisabledSubmit,
    onChangeFile,
    isSubmitButtonLoading,
  };
};

/**
 * NOTE: 下書き機能で保存する添付ファイルのパスとの整合性を持たせるため、ファイル設問のファイルが変更された際にGCSストレージにファイルをアップロードする
 */
const useOnChangeFileQuestion = ({
  dispatch,
  onnFormTaskId,
}: {
  dispatch: React.Dispatch<Action>;
  onnFormTaskId: string;
}) => {
  const { uploadAnswerFile, isUploading } = useUploadAnswerFile();

  const onChangeFile = async (payload: Payload, questionId: string) => {
    if (payload.status === "success") {
      const file = payload.files[0];

      if (!file) {
        dispatch({
          type: "CHANGE_FILE_ANSWER",
          payload: {
            questionId: questionId,
            file: undefined,
            filePath: "",
          },
        });
        return;
      }
      const filePath = await uploadAnswerFile({
        file,
        onnFormTaskId,
        questionId,
      });
      if (filePath) {
        dispatch({
          type: "CHANGE_FILE_ANSWER",
          payload: {
            questionId,
            file,
            filePath,
          },
        });
      }
    }
  };

  return { onChangeFile, isUploading };
};
