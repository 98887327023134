import { OnnFormTask } from "@onn/common";
import { first } from "lodash";

import { useState } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { useFormRevisions } from "~/hooks/onnTask";
import { useOpenPortalOnnTaskPreview } from "~/hooks/openPortalPreview";

import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

import { useTenant } from "~/hooks/tenant";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { useConstructUploadFilesInfo } from "~/pages/onn_task/_share_in_create_edit/hooks/useConstructUploadFilesInfo";
import { State } from "~/pages/onn_task/_share_in_create_edit/reducer";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const useOnClickPreviewHandler = ({
  state,
  onnFormTaskId,
}: {
  state: State;
  onnFormTaskId: string;
}) => {
  const constructUploadFilesInfo = useConstructUploadFilesInfo();
  const { enqueueSnackbar } = useSnackbar();
  const { openPortalOnnTaskPreview } = useOpenPortalOnnTaskPreview();
  const { data } = useFormRevisions({ onnFormTaskId, isOnlyLatest: true });
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickPreview = async () => {
    setIsSubmitting(true);
    const uploadFilesInfo = constructUploadFilesInfo({
      state,
      isPreview: true,
    });

    try {
      await fileAPIAdapter.uploadFiles(uploadFilesInfo);
    } catch (e) {
      enqueueSnackbar("添付ファイルのアップロードに失敗しました。", {
        variant: "error",
      });
      captureException({
        error: e as Error,
        tags: { type: "useActionFooterHandler:fileAPIAdapter.uploadFiles" },
        extras: {
          description: "（プレビュー版）サンプルファイルのアップロードに失敗しました。",
        },
      });
      setIsSubmitting(false);
      return;
    }

    openPortalOnnTaskPreview({
      onnFormTask: OnnFormTask.create({
        id: onnFormTaskId,
        isAllowEditAnswer: state.isAllowEditAnswer,
        title: state.onnTaskTitleTextField.value,
        description: state.onnTaskDescriptionTextField.value,
        filePaths: uploadFilesInfo.map((v) => v.path),
        tenantId: tenant.tenantId,
        spaceId: currentSpace.id,
        assigneeId: currentUser.id,
        scheduledDate: null,
        deadlineDate: null,
        firstDeliveredAt: null,
        canAnswerAfterDeadline: false,
      }),
      questions: state.questions.map((question) => {
        if (question.type === "FILE") {
          // sampleFilePathForPreviewが空文字でないにも関わらず、sampleFileが設定されていない場合は、フォームタスク新規作成時にすでにアップロードされているファイルである
          const isExistingUploadFile =
            !question.sampleFile && question.sampleFilePathForPreview !== "";
          if (isExistingUploadFile) {
            return {
              id: question.id,
              title: question.title,
              type: question.type,
              isRequired: question.isRequired,
              sampleFilePathForPreview: question.sampleFilePath, // 既存のサンプルファイルの場合は、sampleFilePathをそのまま使う
              sampleFilePath: question.sampleFilePath,
            };
          }
        }
        if (question.type === "TEXT") {
          return { ...question, wordLimit: Number(question.wordLimit) };
        }

        return question;
      }),
      formRevision: first(data?.formRevisions),
    });
    setIsSubmitting(false);
  };

  return { onClickPreview, isSubmitting };
};
