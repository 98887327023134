import { AllContactRoom, ContactMessageDraft } from "@onn/common";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

import { useContactRoomListV2 } from "./useContactRoomListV2";

import { useContactMessageDraftByCreatedUserId } from "~/hooks/contactMessageDraft";
import { useContactRoomPins } from "~/hooks/contactRoomPin/useContactRoomPins";
import { useCurrentUser, useSearchNewGraduateIds } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useLocalStorage, useQuery, useSetQueryString } from "~/hooks/shared";
import { useNewGraduateSearchState } from "~/hooks/shared/useNewGraduateSearchState";

const IS_DISPLAY_ONLY_MY_CANDIDATES = "isDisplayOnlyMyCandidates";

const useContactRoomIdInQuery = () => {
  const { query } = useQuery();
  const contactRoomIdInQuery = query.get("contactRoomId");

  return { contactRoomIdInQuery };
};

const useSyncContactRoomIdInQueryAndState = ({
  setSelectedContactRoomId,
  currentContactRooms,
}: {
  setSelectedContactRoomId: Dispatch<SetStateAction<string | undefined>>;
  currentContactRooms: AllContactRoom[];
}) => {
  const { contactRoomIdInQuery } = useContactRoomIdInQuery();

  useEffect(() => {
    if (currentContactRooms) {
      if (contactRoomIdInQuery) setSelectedContactRoomId(contactRoomIdInQuery);
    }
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContactRooms]);
};

export const useViewModelV2 = () => {
  const { data: contactRoomPins = [] } = useContactRoomPins();

  const { currentUser } = useCurrentUser();
  const { storeValue, retrieveValue } = useLocalStorage();
  const { handleModal } = useModal();

  const [selectedContactRoomId, setSelectedContactRoomId] = useState<string>();

  // NOTE: フリーテキスト検索はフロントで行うため keywordsString 系の返り値は使用しない
  const { conditions, validConditions, logicType, validConditionsCount, onSearchConfirm } =
    useNewGraduateSearchState();
  const { data: searchedNewGraduateIds, isLoading: isLoadingSearchNewGraduateIds } =
    useSearchNewGraduateIds({
      type: logicType,
      conditions: validConditions,
      keywordsString: "",
    });

  // NOTE: 初期表示タブは管理者の場合は全体、そうでない場合は自身が担当者のみのルームのみ
  const [isDisplayOnlyMyCandidates, setIsDisplayOnlyMyCandidates] = useState(
    retrieveValue<boolean>(IS_DISPLAY_ONLY_MY_CANDIDATES) ?? !currentUser.isAdmin()
  );

  const { contactRoomUnreadCountMap, ...contactRoomListOperationObj } = useContactRoomListV2({
    isDisplayOnlyMyCandidates,
    searchedNewGraduateIds: searchedNewGraduateIds ?? new Set(),
  });

  useSyncContactRoomIdInQueryAndState({
    setSelectedContactRoomId,
    currentContactRooms: contactRoomListOperationObj.currentContactRooms,
  });

  const isLoading =
    contactRoomListOperationObj.isLoadingContactRooms ||
    contactRoomListOperationObj.isLoadingLatestMessages ||
    isLoadingSearchNewGraduateIds;

  const { contactMessageDrafts, isLoadingDraft, saveContactMessageDraft, contactMessageDraft } =
    useContactMessageDraft({ selectedContactRoomId });

  const { setQueryString } = useSetQueryString();

  const handleChangeIsDisplayOnlyMyCandidates = useCallback(() => {
    storeValue(IS_DISPLAY_ONLY_MY_CANDIDATES, !isDisplayOnlyMyCandidates);
    setIsDisplayOnlyMyCandidates((prev) => !prev);
  }, [isDisplayOnlyMyCandidates, storeValue]);

  const handleOnClickContactRoomListItem = useCallback(
    (contactRoomId: string | undefined) => {
      setSelectedContactRoomId(contactRoomId);
      setQueryString({ contactRoomId });
    },
    [setQueryString]
  );

  const handleOnClickOpenNewGraduateSearchModal = useCallback(() => {
    if (isLoading) return;

    handleModal({
      name: "newGraduateSearchModal",
      args: {
        conditions,
        logicType,
        defaultCount: contactRoomListOperationObj.currentContactRooms.length,
        onSearchConfirm,
      },
    });
  }, [handleModal, conditions, logicType, onSearchConfirm, isLoading, contactRoomListOperationObj]);

  const contactRoomIdToPinnedFlagMap = new Map(
    contactRoomPins.map((contactRoomPins) => [contactRoomPins.contactRoomId, true])
  );

  const tabs: Array<{
    label: string;
    type: "message";
  }> = [{ label: "メッセージ", type: "message" }];

  return {
    ...contactRoomListOperationObj,
    handleChangeIsDisplayOnlyMyCandidates,
    tabs,
    isLoading,
    contactMessageDrafts,
    isLoadingDraft,
    saveContactMessageDraft,
    contactMessageDraft,
    isDisplayOnlyMyCandidates,
    selectedContactRoomId,
    handleOnClickContactRoomListItem,
    /** Map<コンタクトルームID, 未読数> */
    contactRoomUnreadCountMap,
    contactRoomIdToPinnedFlagMap,

    validConditionsCount,
    handleOnClickOpenNewGraduateSearchModal,
  };
};

const useContactMessageDraft = ({ selectedContactRoomId }: { selectedContactRoomId?: string }) => {
  const { currentUser } = useCurrentUser();

  const {
    contactMessageDrafts,
    isLoading: isLoadingDraft,
    saveContactMessageDraft,
  } = useContactMessageDraftByCreatedUserId(currentUser.id);

  const contactMessageDraft = selectedContactRoomId
    ? contactMessageDrafts.find(({ contactRoomId }) => contactRoomId === selectedContactRoomId) ??
      ContactMessageDraft.create({
        tenantId: currentUser.tenantId,
        createdUserId: currentUser.id,
        contactRoomId: selectedContactRoomId,
        text: "",
      })
    : undefined;

  return { contactMessageDrafts, isLoadingDraft, saveContactMessageDraft, contactMessageDraft };
};
