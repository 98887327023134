import { OnnEventDeterminedDate as DeterminedDate } from "../../../../../domain";
import { ToCloudFunctionsCompatible } from "../../../../../utils";

export const convertUnixTimeToDateDeterminedDate = (
  d: ToCloudFunctionsCompatible<DeterminedDate>
): DeterminedDate => {
  return new DeterminedDate({
    ...d,
    createdAt: new Date(d.createdAt),
    updatedAt: new Date(d.updatedAt),
    latestSentAtOnlineUrlNotification:
      d.latestSentAtOnlineUrlNotification == undefined
        ? undefined
        : new Date(d.latestSentAtOnlineUrlNotification),
  });
};
