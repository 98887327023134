/**
 * 画像ファイルのサイズを取得する
 */
type ImageMetaData = {
  naturalWidth: number;
  naturalHeight: number;
  // 他のプロパティが必要になった時に増やす
};
export const getImageMetaDataFromFile = async (file: File) => {
  return new Promise<ImageMetaData>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const size = {
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
      };

      URL.revokeObjectURL(img.src);
      resolve(size);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = URL.createObjectURL(file);
  });
};
