import { z } from "zod";

export const recruitmentStatusRevisionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  updatedEmployeeId: z.string(),
});

export type IRecruitmentStatusRevisionSchema = z.infer<typeof recruitmentStatusRevisionSchema>;
