import { isEmpty } from "lodash";
import { useCallback, useState } from "react";

import { useCreateNewGraduateWithCsv } from "~/hooks/employee";
import { useConvertFileToBase64 } from "~/hooks/file";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

/**
 * アップロードボタンが押された時の処理を管理するhooks
 */
export const useHandleClickUploadButton = ({
  inputFile,
  closeModal,
  onCreateNewGraduateWithCsv,
}: {
  inputFile?: File;
  closeModal: () => void;
  onCreateNewGraduateWithCsv?: () => void | Promise<void>;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const { convertFileToBase64 } = useConvertFileToBase64();
  const { createNewGraduateWithCsv } = useCreateNewGraduateWithCsv();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickUploadButton = useCallback(async () => {
    if (!inputFile) return;
    try {
      setIsUploading(true);

      const base64EncodedCsvFile = await convertFileToBase64(inputFile);
      const response = await createNewGraduateWithCsv({ base64EncodedCsvFile });
      const createdNewGraduates = response.isOk ? response.createdNewGraduates : [];
      if (!isEmpty(createdNewGraduates)) {
        enqueueSnackbar(`${createdNewGraduates.length}名の候補者が追加されました`, {
          variant: "success",
        });
      }

      onCreateNewGraduateWithCsv?.();

      closeModal();
    } catch (error) {
      captureException({
        error: error as Error,
        tags: {
          type: "InviteNewGraduateWithCSVModal:handleClickUploadButton",
        },
        extras: {
          inputFileText: await inputFile.text(),
        },
      });
      enqueueSnackbar(`候補者の追加に失敗しました。担当者までご連絡ください。`, {
        variant: "error",
      });
    } finally {
      setIsUploading(false);
    }
  }, [
    inputFile,
    convertFileToBase64,
    createNewGraduateWithCsv,
    onCreateNewGraduateWithCsv,
    closeModal,
    enqueueSnackbar,
  ]);

  return { handleClickUploadButton, isUploading };
};
