import { OnnEventSlotDate } from "../OnnEventSlotDate/OnnEventSlotDate";

export class OnnEventSlotDateWithNumberOfParticipants {
  readonly onnEventSlotDate: OnnEventSlotDate;
  readonly numberOfParticipants: number;
  constructor(init: ExcludeMethods<OnnEventSlotDateWithNumberOfParticipants>) {
    this.onnEventSlotDate = init.onnEventSlotDate;
    this.numberOfParticipants = init.numberOfParticipants;
  }

  // 候補日程に参加可能かどうかを返す
  canParticipate(): boolean {
    // 定員が設定されていない場合は常に参加可能
    if (!this.onnEventSlotDate.capacity) return true;
    return this.numberOfParticipants < this.onnEventSlotDate.capacity;
  }

  // 候補日程が定員オーバーしているかどうかを返す
  doesOverCapacity(): boolean {
    return this.numberOfParticipants > (this.onnEventSlotDate.capacity || 0);
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 IAnnouncement にしている
   */
  static forceConvertToDate(
    onnEventSlotDateWithNumberOfParticipants: ExcludeMethods<OnnEventSlotDateWithNumberOfParticipants>
  ) {
    return new OnnEventSlotDateWithNumberOfParticipants({
      ...onnEventSlotDateWithNumberOfParticipants,
      onnEventSlotDate: OnnEventSlotDate.forceConvertToDate(
        onnEventSlotDateWithNumberOfParticipants.onnEventSlotDate
      ),
    });
  }
}
