import { RichMenuCellType } from "../RichMenuCellType";

import { IRichMenuCellSetting, richMenuCellSettingSchema } from "./schema";

export class RichMenuCellSetting implements IRichMenuCellSetting {
  readonly cell1Type: RichMenuCellType;
  readonly cell1ExternalSiteUrl?: string;
  readonly cell1MessageText?: string;
  readonly cell2Type: RichMenuCellType;
  readonly cell2ExternalSiteUrl?: string;
  readonly cell2MessageText?: string;
  readonly cell3Type: RichMenuCellType;
  readonly cell3ExternalSiteUrl?: string;
  readonly cell3MessageText?: string;
  readonly cell4Type: RichMenuCellType;
  readonly cell4ExternalSiteUrl?: string;
  readonly cell4MessageText?: string;
  readonly cell5Type: RichMenuCellType;
  readonly cell5ExternalSiteUrl?: string;
  readonly cell5MessageText?: string;
  readonly cell6Type: RichMenuCellType;
  readonly cell6ExternalSiteUrl?: string;
  readonly cell6MessageText?: string;

  constructor(data: ExcludeMethods<RichMenuCellSetting>) {
    const parsedInit = richMenuCellSettingSchema.parse(data);

    this.cell1Type = parsedInit.cell1Type;
    this.cell1ExternalSiteUrl = parsedInit.cell1ExternalSiteUrl;
    this.cell1MessageText = parsedInit.cell1MessageText;
    this.cell2Type = parsedInit.cell2Type;
    this.cell2ExternalSiteUrl = parsedInit.cell2ExternalSiteUrl;
    this.cell2MessageText = parsedInit.cell2MessageText;
    this.cell3Type = parsedInit.cell3Type;
    this.cell3ExternalSiteUrl = parsedInit.cell3ExternalSiteUrl;
    this.cell3MessageText = parsedInit.cell3MessageText;
    this.cell4Type = parsedInit.cell4Type;
    this.cell4ExternalSiteUrl = parsedInit.cell4ExternalSiteUrl;
    this.cell4MessageText = parsedInit.cell4MessageText;
    this.cell5Type = parsedInit.cell5Type;
    this.cell5ExternalSiteUrl = parsedInit.cell5ExternalSiteUrl;
    this.cell5MessageText = parsedInit.cell5MessageText;
    this.cell6Type = parsedInit.cell6Type;
    this.cell6ExternalSiteUrl = parsedInit.cell6ExternalSiteUrl;
    this.cell6MessageText = parsedInit.cell6MessageText;
  }

  /**
   * リッチメニューに設定する外部リンクURLのバリデーション
   * テナントIDの文字数に依存するのでzodスキーマではなく、staticメソッドとして定義している
   */
  static validateExternalLinkUrl({
    liffId,
    tenantId,
    externalSiteUrl,
  }: {
    liffId: string;
    tenantId: string;
    externalSiteUrl: string;
  }) {
    // NOTE: 実際にリッチメニューに登録される文字列数が1000文字を超えないようにする
    // - https://developers.line.biz/ja/reference/messaging-api/#uri-action
    const mockSpaceId = "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx";
    const actualExternalLinkUrl = RichMenuCellSetting.generateExternalLinkUrl({
      liffId,
      tenantId,
      // NOTE: spaceIdは固定の文字数（uuidV4）のため、ダミーのspaceIdを使ってバリデーションを行う
      spaceId: mockSpaceId,
      externalSiteUrl,
    });

    if (actualExternalLinkUrl.length > 1000) {
      return false;
    }
    return true;
  }

  /**
   * リッチメニューセル経由で外部リンクが開かれた時に、クリック計測をするためのLiffURLを生成する
   */
  static generateExternalLinkUrl({
    liffId,
    tenantId,
    spaceId,
    externalSiteUrl,
  }: {
    liffId: string;
    tenantId: string;
    spaceId: string;
    externalSiteUrl: string;
  }) {
    return `https://liff.line.me/${liffId}/portal/external_page/transition?isLineUserActiveLog=true&from=richmenu&href=${encodeURI(
      externalSiteUrl
    )}&tenantId=${tenantId}&spaceId=${spaceId}`;
  }
}
