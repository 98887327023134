import { addHours } from "date-fns";
import { v4 } from "uuid";

import { EmailType, LineType } from "../Employee/AuthenticationType";

import { IChangeAuthenticationTypeLinkSchema, changeAuthenticationTypeLinkSchema } from "./schema";

export class ChangeAuthenticationTypeLink implements IChangeAuthenticationTypeLinkSchema {
  private static validator = changeAuthenticationTypeLinkSchema;

  readonly id: string;
  readonly employeeId: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly fromType: EmailType;
  readonly toType: LineType;
  readonly usedAt?: Date;
  readonly expiredAt: Date;
  readonly createdAt: Date;

  constructor(init: ExcludeMethods<ChangeAuthenticationTypeLink>) {
    ChangeAuthenticationTypeLink.validator.parse(init);

    this.id = init.id;
    this.employeeId = init.employeeId;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.fromType = init.fromType;
    this.toType = init.toType;
    this.usedAt = init.usedAt;
    this.expiredAt = init.expiredAt;
    this.createdAt = init.createdAt;
  }

  public static create(
    params: Omit<ExcludeMethods<ChangeAuthenticationTypeLink>, "id" | "createdAt">
  ) {
    return new ChangeAuthenticationTypeLink({
      id: v4(),
      employeeId: params.employeeId,
      tenantId: params.tenantId,
      spaceId: params.spaceId,
      fromType: params.fromType,
      toType: params.toType,
      usedAt: params.usedAt,
      expiredAt: params.expiredAt,
      createdAt: new Date(),
    });
  }

  public static createByNewGraduate(
    params: Omit<ExcludeMethods<ChangeAuthenticationTypeLink>, "id" | "createdAt" | "expiredAt">
  ) {
    return ChangeAuthenticationTypeLink.create({
      ...params,
      expiredAt: addHours(new Date(), 24),
    });
  }
  public use() {
    return new ChangeAuthenticationTypeLink({
      ...this,
      usedAt: new Date(),
    });
  }
  public canUse() {
    return !this.isUsed() && !this.isExpired();
  }

  public isUsed(): boolean {
    return this.usedAt != undefined;
  }

  public isExpired(): boolean {
    if (this.expiredAt == undefined) {
      return false;
    }

    return this.expiredAt.getTime() < Date.now();
  }
}
