import { Box } from "@mui/material";
import React, { FC } from "react";

import { InformationSettingTables } from "./components/InformationSettingTables/InformationSettingTables";

import { Button, IconButton, Typography, UncontrolledMenu } from "~/components/uiParts";
import { useOpenEditEmployeeInformationGroupModal } from "~/hooks/employeeInformationGroup";
import { useEmployeeInformationGroups } from "~/hooks/employeeInformationGroup/useEmployeeInformationGroups";

export const EmployeeInformationSettingPage: FC = () => {
  const { data: exitingGroups, isLoading: isLoadingEmployeeInformationGroups } =
    useEmployeeInformationGroups();
  const { openEditEmployeeInformationGroupModal } = useOpenEditEmployeeInformationGroupModal(
    exitingGroups || []
  );

  return (
    <Box>
      <PageTitle />
      <Box mt="40px">
        <GroupControl
          onClickAddGroup={() => openEditEmployeeInformationGroupModal({ type: "add" })}
          onClickEditGroup={() => openEditEmployeeInformationGroupModal({ type: "edit" })}
        />
      </Box>
      <Box mt="24px">
        <InformationSettingTables
          groups={exitingGroups || []}
          isLoadingEmployeeInformationGroups={isLoadingEmployeeInformationGroups}
        />
      </Box>
    </Box>
  );
};

const PageTitle: FC = () => {
  return (
    <Box>
      <Typography variant="h4" bold color="textPrimary">
        候補者設定
      </Typography>
    </Box>
  );
};

const GroupControl: FC<{
  onClickAddGroup: () => void;
  onClickEditGroup: () => void;
}> = ({ onClickAddGroup, onClickEditGroup }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" height={"40px"}>
      <Typography variant="body2" color="textSecondary">
        候補者詳細に表示する項目を設定できます。この設定は他の年次には影響しません。
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          columnGap: "24px",
        }}
      >
        <Button
          borderRadius="regular"
          variant="outlined"
          color="primary"
          fullHeight
          onClick={onClickAddGroup}
        >
          グループを追加
        </Button>
        <UncontrolledMenu
          renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
          menuItemOptions={[
            {
              text: "グループ編集",
              onClick: onClickEditGroup,
            },
          ]}
        />
      </Box>
    </Box>
  );
};
