import { Box } from "@material-ui/core";
import React from "react";

import styled from "styled-components";

import { useContextOfOnnEventSlotSearchModal } from "../../Context";

import { ConditionRow } from "./ConditionRow";

export const ConditionForm = (): JSX.Element => {
  const { conditions } = useContextOfOnnEventSlotSearchModal();

  return (
    <Root width={"100%"}>
      {conditions.map((condition, index) => (
        <ConditionRow
          key={`${condition.target ?? "undefined"}-${index}`}
          index={index}
          condition={condition}
        />
      ))}
    </Root>
  );
};

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  margin-bottom: 16px;
`;
