import { Box } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { useMemo, useState } from "react";

import styled from "styled-components";

import { useToggleSelectAll } from "../../../../../../hooks/shared/useToggleSelectAll";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  Icon,
  Loading,
  TextFieldDeprecated,
  Typography,
} from "~/components/uiParts";
import { useEmployeeTags } from "~/hooks/employeeTag";

type Props = {
  selectedTagIds: string[];
  onChange(tagIds: string[]): void;
};
export const TagSelector = ({ selectedTagIds, onChange }: Props): JSX.Element => {
  const { data, isLoading } = useEmployeeTags();

  const [searchValue, setSearchValue] = useState("");
  const searchedTags = useMemo(() => {
    return (
      data?.employeeTags.filter((tag) =>
        searchValue.trim() ? tag.name.includes(searchValue) : true
      ) ?? []
    );
  }, [data?.employeeTags, searchValue]);

  const { toggleSelectAll, allSelectionState } = useToggleSelectAll<EmployeeTag>({
    options: searchedTags,
    selectedOptions:
      data?.employeeTags && selectedTagIds
        ? data?.employeeTags.filter((tag) => selectedTagIds.includes(tag.id))
        : [],
  });

  if (isLoading || !data) {
    return <Loading size="large" />;
  }

  return (
    <>
      <Box marginY="24px" display="flex" justifyContent="space-between">
        <Box display={"flex"} alignItems={"center"}>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={allSelectionState.checked}
                indeterminate={allSelectionState.indeterminate}
                onChange={() => onChange(toggleSelectAll().map((tag) => tag.id))}
              />
            }
            label={
              <StyledSelectAllBox display="flex">
                <Typography variant="body2" color="textSecondary">
                  {allSelectionState.label.text}
                </Typography>
                <StyledChip color="grey" label={selectedTagIds.length.toString()} bold />
              </StyledSelectAllBox>
            }
          />
        </Box>
        <Box width="258px">
          <StyledTextField
            fullWidth
            variant={"outlined"}
            placeholder={"タグ名"}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            endAdornment={<Icon color="grey400" icon="search" size="sm" />}
          />
        </Box>
      </Box>
      <StyledBox gridGap={"16px"} display={"flex"} flexDirection={"column"}>
        {searchedTags.map((tag) => (
          <Box key={tag.id}>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={selectedTagIds && selectedTagIds.includes(tag.id)}
                  color="primary"
                  onChange={(e) =>
                    onChange(
                      selectedTagIds.includes(e.target.value)
                        ? selectedTagIds.filter((id) => id !== e.target.value)
                        : [...selectedTagIds, e.target.value]
                    )
                  }
                />
              }
              label={tag.name}
              value={tag.id}
            />
          </Box>
        ))}
      </StyledBox>
    </>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

const StyledSelectAllBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

const StyledChip = styled(Chip)`
  cursor: pointer;
`;

const StyledBox = styled(Box)`
  overflow-y: scroll;
`;

const StyledTextField = styled(TextFieldDeprecated)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;
