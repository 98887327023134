import { Box } from "@material-ui/core";
import { CheckBoxQuestion } from "@onn/common";
import React, { FC, useCallback } from "react";

import styled from "styled-components";

import { Action } from "../../reducer";

import { AnswerBaseBox } from "./AnswerBaseBox";
import { StyledTextarea } from "./StyledTextarea";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

export const CheckBoxQuestionForm: FC<{
  question: CheckBoxQuestion;
  dispatch: React.Dispatch<Action>;
  answer: { freeText?: string; selectedOptionIds: string[] };
}> = ({ question, dispatch, answer: { freeText, selectedOptionIds } }) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const optionId = e.target.value;
      if (selectedOptionIds.includes(optionId)) {
        dispatch({
          type: "CHANGE_CHECKBOX_ANSWER",
          payload: {
            questionId: question.id,
            removedSelectedOptionId: optionId,
          },
        });
      } else {
        dispatch({
          type: "CHANGE_CHECKBOX_ANSWER",
          payload: {
            questionId: question.id,
            addedSelectedOptionId: optionId,
          },
        });
      }
    },
    [dispatch, question.id, selectedOptionIds]
  );

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      dispatch({
        type: "CHANGE_CHECKBOX_TEXT_ANSWER",
        payload: {
          questionId: question.id,
          text: e.target.value,
        },
      });
    },
    [dispatch, question.id]
  );

  return (
    <AnswerBaseBox questionTitle={question.title} isRequired={question.isRequired}>
      {question.options.map((option) => {
        return (
          <StyledFormControlLabel
            key={option.id}
            value={option.id}
            control={
              <StyledCheckbox
                color="primary"
                checked={selectedOptionIds.includes(option.id)}
                onChange={onChange}
              />
            }
            label={<Typography variant="body2">{option.text}</Typography>}
          />
        );
      })}
      {question.allowFreeText && (
        <Box ml="28px" mt="4px">
          <StyledTextarea
            fullWidth
            placeholder="回答を入力"
            minRows={3}
            onChange={onChangeText}
            value={freeText}
          />
        </Box>
      )}
    </AnswerBaseBox>
  );
};

const StyledCheckbox = styled(Checkbox)`
  padding-left: 0;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
