import { Box } from "@material-ui/core";
import React, { useCallback } from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts";

export const ModalFooter = ({
  downloadSvg,
  addRegistrationInvitationLinkUrlToClipBoard,
}: {
  downloadSvg: () => void;
  addRegistrationInvitationLinkUrlToClipBoard: () => void;
}) => {
  const onClickCopyInvitationLink = useCallback(() => {
    addRegistrationInvitationLinkUrlToClipBoard();
  }, [addRegistrationInvitationLinkUrlToClipBoard]);

  const onCLickDLInvitationLinkQRImage = useCallback(() => {
    downloadSvg();
  }, [downloadSvg]);

  return (
    <StyledButtonContainer>
      <Box width="240px">
        <Button
          variant="outlined"
          color="primary"
          borderRadius="circle"
          onClick={onClickCopyInvitationLink}
          fullWidth
        >
          招待URLをコピー
        </Button>
      </Box>
      <Box width="240px">
        <Button
          variant="contained"
          color="primary"
          borderRadius="circle"
          onClick={onCLickDLInvitationLinkQRImage}
          fullWidth
        >
          画像をダウンロード
        </Button>
      </Box>
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 24px;
  justify-content: center;
  width: 100%;
`;
