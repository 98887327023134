import { Box } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  title: string;
  cancelLabel?: string;
  acceptLabel?: string;
  acceptButtonColor?: "primary" | "secondary";
  onClickAccept: () => Promise<void>;
  mainContent: string;
  subContent?: string;
};

export const ConfirmModal: FC<Props> = ({
  open,
  onCancel,
  title,
  cancelLabel = "キャンセル",
  acceptLabel = "変更を保存",
  acceptButtonColor = "primary",
  onClickAccept,
  mainContent,
  subContent,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const _onClickAccept = useCallback(async () => {
    setIsLoading(true);
    try {
      await onClickAccept();
      onCancel();
    } finally {
      setIsLoading(false);
    }
  }, [onCancel, onClickAccept]);

  return (
    <Modal
      open={open}
      title={title}
      content={
        <>
          <Box mb={2} textAlign="center" display="flex" flexDirection="column" gridGap="18px">
            <Typography variant="body1">{mainContent}</Typography>
          </Box>
          {subContent && (
            <Box width={"100%"} textAlign={"center"}>
              <Typography variant="caption" color="textSecondary">
                {subContent}
              </Typography>
            </Box>
          )}
        </>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="outlined"
            color="default"
            onClick={onCancel}
          >
            {cancelLabel}
          </Button>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color={acceptButtonColor}
            onClick={_onClickAccept}
            isLoading={isLoading}
            disabled={isLoading}
          >
            {acceptLabel}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
