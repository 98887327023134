import { z } from "zod";

import { OnnEventType } from "../../../../../function/src/.prisma/client";
import OnnEventTypeSchema from "../../../domain/_gen/zodSchema/inputTypeSchemas/OnnEventTypeSchema";
import { onnTaskTypes, OnnTaskType } from "../../OnnTask/BaseOnnTask/types";

export type RecruitmentProcessRelationSetting = {
  event?: {
    type: OnnEventType;
    id: string;
  };
  tasks: Array<{
    type: OnnTaskType;
    id: string;
  }>;
};

export const relationSettingSchema = z.object({
  event: z
    .object({
      type: OnnEventTypeSchema,
      id: z.string(),
    })
    .optional(),
  tasks: z.array(
    z.object({
      type: z.enum(onnTaskTypes),
      id: z.string(),
    })
  ),
});
