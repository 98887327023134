import {
  createIMockOnnEventAnswer,
  OnnEvent,
  toCloudFunctionsCompatible,
  OnnEventSlotDateWithNumberOfParticipants,
} from "@onn/common";
import { useCallback } from "react";

import { useCurrentUser } from "../../employee";
import { useCandidateDatesWithNumberOfParticipants } from "../../onnEvent";
import { ONN_EVENT_PREVIEW_DATA_KEY } from "../../onnEvent/useOnnEventForPortal";
import { useOnnEventPlaces } from "../../onnEventPlace/useOnnEventPlaces";
import { useOnnEventSlotDatesForDisplay } from "../../onnEventSlotDates/useOnnEventSlotDatesForDisplay";

import { useBriefingSessionCategories } from "~/hooks/onnEvent/useBriefingSessionCategories";
import { useLocalStorage } from "~/hooks/shared";

export const useOpenPortalOnnEventPreview = (onnEventId?: string) => {
  const { currentUser } = useCurrentUser();
  const { storeValue } = useLocalStorage();
  const { data: onnEventPlaces, isLoading: isLoadingOnnEventPlaces } = useOnnEventPlaces();
  const {
    data: onnEventSlotDateWithNumberOfParticipants,
    isLoading: isLoadingOnnEventSlotDateWithNumberOfParticipants,
  } = useOnnEventSlotDatesForDisplay(onnEventId);

  const {
    data: candidateDatesWithNumberOfParticipants,
    isLoading: isLoadingCandidateDatesWithNumberOfParticipants,
  } = useCandidateDatesWithNumberOfParticipants({
    onnEventId: onnEventId,
    currentEmployeeId: currentUser.id,
  });
  const { data: briefingSessionCategories = [], isLoading: isLoadingBriefingSessionCategories } =
    useBriefingSessionCategories({
      onnEventId: onnEventId ?? "",
    });

  const storeNormalEvent = useCallback(
    (onnEvent: OnnEvent) => {
      storeValue(ONN_EVENT_PREVIEW_DATA_KEY, {
        onnEvent: toCloudFunctionsCompatible({ ...onnEvent, type: "normal" }),
        onnEventAnswer: toCloudFunctionsCompatible(createIMockOnnEventAnswer()),
      });
    },
    [storeValue]
  );

  const storeForNewInterviewEvent = useCallback(
    (onnEvent: OnnEvent) => {
      storeValue(ONN_EVENT_PREVIEW_DATA_KEY, {
        onnEvent: toCloudFunctionsCompatible({ ...onnEvent, type: "new_interview" }),
        onnEventAnswer: toCloudFunctionsCompatible(
          createIMockOnnEventAnswer({ employeeId: currentUser.id, tenantId: currentUser.tenantId })
        ),
        onnEventDeterminedDate: null,
        onnEventPlaces: onnEventPlaces || [],
        candidateDatesWithNumberOfParticipants: candidateDatesWithNumberOfParticipants || [],
        onnEventSlotDateWithNumberOfParticipants:
          onnEventSlotDateWithNumberOfParticipants
            ?.map(
              (v) =>
                new OnnEventSlotDateWithNumberOfParticipants({
                  onnEventSlotDate: v,
                  numberOfParticipants: v.reservedCount,
                })
            )
            .filter((v) => v.canParticipate()) || [],
      });
    },
    [
      candidateDatesWithNumberOfParticipants,
      currentUser.id,
      currentUser.tenantId,
      onnEventPlaces,
      onnEventSlotDateWithNumberOfParticipants,
      storeValue,
    ]
  );

  const storeForBriefingSessionEvent = useCallback(
    (onnEvent: OnnEvent) => {
      storeValue(ONN_EVENT_PREVIEW_DATA_KEY, {
        onnEvent: toCloudFunctionsCompatible({ ...onnEvent, type: "briefing_session" }),
        onnEventAnswer: toCloudFunctionsCompatible(
          createIMockOnnEventAnswer({ employeeId: currentUser.id, tenantId: currentUser.tenantId })
        ),
        onnEventDeterminedDate: null,
        onnEventPlaces: onnEventPlaces || [],
        candidateDatesWithNumberOfParticipants: candidateDatesWithNumberOfParticipants || [],
        onnEventSlotDateWithNumberOfParticipants:
          onnEventSlotDateWithNumberOfParticipants
            ?.map(
              (v) =>
                new OnnEventSlotDateWithNumberOfParticipants({
                  onnEventSlotDate: v,
                  numberOfParticipants: v.reservedCount,
                })
            )
            .filter((v) => v.canParticipate()) || [],
        briefingSessionCategories,
      });
    },
    [
      candidateDatesWithNumberOfParticipants,
      currentUser.id,
      currentUser.tenantId,
      onnEventPlaces,
      onnEventSlotDateWithNumberOfParticipants,
      briefingSessionCategories,
      storeValue,
    ]
  );

  /**
   * ポータルのイベントプレビューを開く
   * @param {OnnEvent} onnEvent
   */
  const openPortalOnnEventPreview = useCallback(
    (onnEvent: OnnEvent) => {
      switch (onnEvent.type) {
        case "normal":
          storeNormalEvent(onnEvent);
          break;
        case "new_interview":
          storeForNewInterviewEvent(onnEvent);
          break;
        case "briefing_session":
          storeForBriefingSessionEvent(onnEvent);
          break;
      }
      window.open(`/portal/events/${onnEvent.id}?preview=true`, "popup", "width=375,height=480");
    },
    [storeForBriefingSessionEvent, storeForNewInterviewEvent, storeNormalEvent]
  );

  const isReady =
    !isLoadingOnnEventPlaces ||
    !isLoadingOnnEventSlotDateWithNumberOfParticipants ||
    !isLoadingCandidateDatesWithNumberOfParticipants ||
    !isLoadingBriefingSessionCategories;

  return { openPortalOnnEventPreview, isReady };
};
