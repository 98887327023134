export const emailType = "email";
export const lineType = "line";
export const googleType = "google";

export type EmailType = typeof emailType;
export type LineType = typeof lineType;
export type GoogleType = typeof googleType;

export type AuthenticationTypeForNewGraduate = EmailType | LineType;

// NOTE: 新卒の場合はgoogle認証の動線は存在しないが既存データのために定義している
// TODO: https://app.clickup.com/t/86enwex9k で対応する
export type AuthenticationType = EmailType | LineType | GoogleType;
