import { removeUndefinedFromObject } from "@onn/common";
import { DocumentData, DocumentReference, doc, setDoc } from "firebase/firestore";
import { v4 as uuidV4 } from "uuid";

import { firestore } from "~/config/firebase";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";

const COLLECTION_NAME = "accessLogs";

export class AccessLogRepository {
  async add(document: object): Promise<void> {
    const id = uuidV4();
    const ref = this.doc(id);
    await setDoc(
      ref,
      removeUndefinedFromObject({ ...document, id, createdAt: convertDateToTimestamp(new Date()) })
    );
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }
}
