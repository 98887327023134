import { z } from "zod";

/////////////////////////////////////////
// SPACE SETTING SCHEMA
/////////////////////////////////////////

export const SpaceSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  registrationMessageForPreEntry: z.string().nullish(),
  shouldCancelDeliveryRegardlessOfScenario: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type SpaceSetting = z.infer<typeof SpaceSettingSchema>;

/////////////////////////////////////////
// SPACE SETTING OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const SpaceSettingOptionalDefaultsSchema = SpaceSettingSchema.merge(
  z.object({
    id: z.string().optional(),
    shouldCancelDeliveryRegardlessOfScenario: z.boolean().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type SpaceSettingOptionalDefaults = z.infer<typeof SpaceSettingOptionalDefaultsSchema>;

export default SpaceSettingSchema;
