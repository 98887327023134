import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useMutateContactRoomPins } from "./useContactRoomPins";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/contact_room_api/contact-room-pins"]["POST"];

export const useCreateContactRoomPin = () => {
  const { mutateContactRoomPins } = useMutateContactRoomPins();
  const createContactRoomPin = useCallback(
    async (contactRoomId: string) => {
      const requestBody: EndPoint["body"] = {
        contactRoomId,
      };
      await apiClient.post("/contact_room_api/contact-room-pins", requestBody);
      await mutateContactRoomPins();
      return;
    },
    [mutateContactRoomPins]
  );

  return { createContactRoomPin };
};
