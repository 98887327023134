import { useCallback, useMemo, useState } from "react";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useCurrentUser } from "~/hooks/employee";
import { useConvertFileToBase64 } from "~/hooks/file";
import { useAnswerOnBehalfToExistedSlotDatesWithCsv } from "~/hooks/onnEventSlotDates/useAnswerOnBehalfToExistedSlotDatesWithCsv";
import { useNotifyOperationLog, useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

export const useHandleInputFile = ({ onnEventId }: { onnEventId: string }) => {
  const [loadedFileName, setLoadedFileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { notifyOperationLog, operationLog } = useNotifyOperationLog();
  const { currentUser } = useCurrentUser();

  const [isCheckingCsv, setIsCheckingCsv] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [inputFile, setInputFile] = useState<File | undefined>();
  const { answerOnBehalfToExistedSlotDatesWithCsv } = useAnswerOnBehalfToExistedSlotDatesWithCsv();
  const { convertFileToBase64 } = useConvertFileToBase64();

  const handleInputFile = useCallback(
    async (payload: Payload) => {
      try {
        setIsCheckingCsv(true);
        if (payload.status === "error") {
          enqueueSnackbar(payload.message, { variant: "error" });
          return;
        }

        const file = payload.files[0] as (typeof payload.files)[number];
        setInputFile(file);

        const base64EncodedCsvFile = await convertFileToBase64(file);

        const response = await answerOnBehalfToExistedSlotDatesWithCsv({
          onnEventId,
          base64EncodedCsvFile,
          isDryRun: true,
        });
        if (response.isOk) {
          setErrorMessages([]);
        } else {
          setErrorMessages(response.errorMessages);
          notifyOperationLog(
            operationLog.notifyCSVUploadError(
              currentUser,
              "[説明会イベント]すでに存在する予約枠に対しての代理回答",
              response.errorMessages.join("\n")
            )
          );
        }
        setLoadedFileName(file.name);
      } catch (e) {
        captureException({
          error: e as Error,
          tags: {
            type: "useHandleInputFile:answerNewInterviewOnBehalfWithCsv",
          },
          extras: {
            onnEventId,
            currentUser,
          },
        });
        enqueueSnackbar(
          "エラーが発生しました。もう一度お試しいただけますでしょうか。もし解決しない場合はお問い合わせください。",
          { variant: "error" }
        );
      } finally {
        setIsCheckingCsv(false);
      }
    },
    [
      convertFileToBase64,
      answerOnBehalfToExistedSlotDatesWithCsv,
      onnEventId,
      enqueueSnackbar,
      notifyOperationLog,
      operationLog,
      currentUser,
    ]
  );

  const fileLabel = useMemo(() => {
    if (loadedFileName) return loadedFileName;
    return "ファイルが選択されていません";
  }, [loadedFileName]);

  return { handleInputFile, fileLabel, errorMessages, isCheckingCsv, inputFile };
};
