import { OnnEventDeterminedDate } from "@onn/common";
import { useCallback } from "react";

import { AttendanceStatus } from "./types";

export const useConvertToDeterminedDateAttendanceStatus = () => {
  const convertStatus = useCallback(
    (attendanceStatus: AttendanceStatus): OnnEventDeterminedDate["attendanceStatus"] => {
      switch (attendanceStatus) {
        case "absent_attendance": {
          return "ABSENT";
        }
        case "registered_attendance": {
          return "ATTENDED";
        }
        case "unregistered_attendance_and_past": {
          return "UNREGISTERED";
        }
        default: {
          const _exhaustiveCheck: never = attendanceStatus;
          return _exhaustiveCheck;
        }
      }
    },
    []
  );

  return { convertStatus };
};
