import { Box } from "@material-ui/core";
import { Employee, EmployeeTag } from "@onn/common";
import { format } from "date-fns";
import React, { memo } from "react";
import styled from "styled-components";

import { ContentWithLabel } from "./ContentWithLabel";

import { ContextMenu } from "~/components/domains/tenantSettings/EmployeeTagsTable";
import { Paper, TooltipWhenTextTruncated, Typography, UserIcon } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";

export type Props = {
  employeeTag: EmployeeTag;
  numberOfTaggedEmployees: number;
  creator: Employee;
  onClickEdit: () => void;
  onClickDelete: () => void;
  isRounded?: boolean;
};

export const EmployeeTagDetailSummaryPaper = memo(
  ({
    isRounded = true,
    numberOfTaggedEmployees,
    creator,
    employeeTag,
    onClickEdit,
    onClickDelete,
  }: Props): JSX.Element => {
    const { currentUser } = useCurrentUser();

    return (
      <StyledPaper square={!isRounded}>
        <Box display="flex" alignItems="center" justifyContent="space-between" height={68}>
          <Box>
            <Box display="flex" alignItems="center" maxWidth="350px" mb="8px">
              <TooltipWhenTextTruncated text={employeeTag.name}>
                {(ref) => (
                  <StyledTitle ref={ref} bold variant="h4">
                    {employeeTag.name}
                  </StyledTitle>
                )}
              </TooltipWhenTextTruncated>
            </Box>
            <Box display="flex" alignItems="center" maxWidth="350px">
              <Typography variant="caption" color="textSecondary">
                作成日：{format(employeeTag.createdAt, "yyyy/M/d")}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box minWidth={50}>
              <Creator creator={creator} />
            </Box>
            <Box ml="40px" minWidth={50}>
              <NumberOfTaggedEmployees count={numberOfTaggedEmployees} />
            </Box>
            {currentUser.isAdmin() && (
              <Box ml="40px">
                <ContextMenu
                  id={employeeTag.id}
                  onClickEdit={onClickEdit}
                  onClickDelete={onClickDelete}
                />
              </Box>
            )}
          </Box>
        </Box>
      </StyledPaper>
    );
  }
);

const Creator = ({ creator }: { creator: Employee }) => {
  return (
    <ContentWithLabel label="作成者">
      <StyledClickableBox display="flex" alignItems="center">
        <Box display="flex" alignItems="center" gridGap={"8px"}>
          <UserIcon
            username={creator.getName()}
            profileIconImageUrl={creator.profileIconImageUrl}
            size={"small"}
            circular={true}
          />
          <Box display="grid" gridRowGap="4px">
            <StyledTypography variant={"caption"} color="textPrimary" noWrap>
              {creator.getName()}
            </StyledTypography>
          </Box>
        </Box>
      </StyledClickableBox>
    </ContentWithLabel>
  );
};

const NumberOfTaggedEmployees = ({ count }: { count: number }) => {
  return (
    <ContentWithLabel label="付与対象">
      <Typography variant="h3">{count}</Typography>
    </ContentWithLabel>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const StyledClickableBox = styled(Box)<{ $width?: number }>`
  cursor: pointer;
  ${({ $width }) => ($width ? `width: ${$width}px` : "width: 100%")}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;

const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
