import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@material-ui/core";
import { OnnEventPlace, getFileLikeObjectsFromFilePath } from "@onn/common";
import React, { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { AddressInputPart } from "./components/AddressInputPart";
import { PlaceNameInputPart } from "./components/PlaceNameInputPart";
import { RemarkInputPart } from "./components/RemarkInputPart";

import { generateInitialValues } from "./formUtils/generateInitialValues";
import { InputState, zodFormSchema } from "./formUtils/zodFormSchema";

import { useUploadRemarkFilesToStorage } from "./hooks/useUploadRemarkFilesToStorage";

import { Button, Modal } from "~/components/uiParts";
import { useCreateOnnEventPlace } from "~/hooks/onnEventPlace/useCreateOnnEventPlace";
import { useUpdateOnnEventPlace } from "~/hooks/onnEventPlace/useUpdateOnnEventPlace";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

type CreateModeProps = {
  mode: "create";
  onnEventPlace: undefined;
};

type UpdateModeProps = {
  mode: "update";
  onnEventPlace: OnnEventPlace;
};

type Props = {
  open: boolean;
  onCancel: () => void;
  data: CreateModeProps | UpdateModeProps;
};

export const CreateOrUpdateEventPlaceModal: FC<Props> = ({
  open,
  onCancel,
  data: { mode, onnEventPlace },
}) => {
  const form = useForm<InputState>({
    defaultValues: generateInitialValues({
      onnEventPlace,
    }),
    resolver: zodResolver(zodFormSchema),
  });
  const { submit, isLoading } = useSubmit({
    placeId: onnEventPlace?.id || "",
    previousFilePaths: onnEventPlace?.filePaths || [],
    onCancel,
    mode,
  });
  const handleSubmit = form.handleSubmit(submit, () => {
    form.trigger();
  });

  const hasError = Object.keys(form.formState.errors).length > 0;
  const isSubmitButtonDisabled =
    form.formState.isSubmitting || hasError || isLoading || !form.formState.isDirty;

  return (
    <StyledModal
      open={open}
      title={mode === "create" ? "会場追加" : "会場編集"}
      onCancel={onCancel}
      content={
        <>
          <Box mb="40px">
            <PlaceNameInputPart form={form} />
          </Box>
          <Box mb="40px">
            <AddressInputPart form={form} />
          </Box>
          <Box mb="40px">
            <RemarkInputPart form={form} />
          </Box>
        </>
      }
      footer={
        <Box display="flex" justifyContent="end">
          <Button variant="text" color="default" borderRadius="regular" onClick={onCancel}>
            キャンセル
          </Button>
          <Box width={16} />
          <Button
            borderRadius="circle"
            variant="contained"
            color="primary"
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            内容を保存
          </Button>
        </Box>
      }
    />
  );
};

export const useSubmit = ({
  placeId,
  onCancel,
  mode,
  previousFilePaths,
}: {
  placeId: string;
  previousFilePaths: string[];
  onCancel: () => void;
} & (
  | {
      mode: "update";
      placeId: string;
    }
  | {
      mode: "create";
    }
)) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const { updateOnnEventPlace } = useUpdateOnnEventPlace();
  const { createOnnEventPlace } = useCreateOnnEventPlace();

  const { uploadRemarkFilesToStorage } = useUploadRemarkFilesToStorage();
  const submit = useCallback(
    async (inputValue: InputState) => {
      try {
        setIsLoading(true);

        const filesToUpload = inputValue.remark.files.filter(
          (file): file is File => file instanceof File
          // 既に添付されているファイルは File型ではなく、また新たにアップロードする必要はない
        );
        const uploadedFiles = await uploadRemarkFilesToStorage(filesToUpload);
        if (mode === "create") {
          await createOnnEventPlace({
            paramsToCreate: {
              name: inputValue.placeName,
              postCode: inputValue.postCode,
              state: inputValue.state,
              city: inputValue.city,
              address1: inputValue.address1,
              address2: inputValue.address2,
              remarkText: inputValue.remark.note,
              filePaths: uploadedFiles.map((file) => file.path),
            },
          });
          enqueueSnackbar("会場が作成されました。", {
            variant: "success",
          });
          onCancel();
          return;
        } else {
          const filePaths = inputValue.remark.files.map((inputRemarkFile) => {
            // 新たに追加されたファイルの場合はストレージにアップロードされるためuploadedFilesからファイルパスを取得
            if (inputRemarkFile instanceof File) {
              return (
                uploadedFiles.find(
                  (uploadedFile) => uploadedFile.file.name === inputRemarkFile.name
                )?.path || ""
              );
            }
            const alreadyExistFilePath = previousFilePaths.find((filePath) => {
              return inputRemarkFile.name === getFileLikeObjectsFromFilePath(filePath);
            });
            if (!alreadyExistFilePath) {
              captureException({
                error: new Error("イベント会場の備考添付ファイルのパスが取得できませんでした"),
                tags: { type: "CreateOrUpdateEventPlaceModal" },
                extras: { inputRemarkFile },
              });
              return "";
            }
            return alreadyExistFilePath;
          });
          await updateOnnEventPlace({
            placeId,
            paramsToUpdate: {
              name: inputValue.placeName,
              postCode: inputValue.postCode,
              state: inputValue.state,
              city: inputValue.city,
              address1: inputValue.address1,
              address2: inputValue.address2,
              remarkText: inputValue.remark.note,
              filePaths,
            },
          });
          enqueueSnackbar("会場が更新されました。", {
            variant: "success",
          });
        }
        onCancel();
      } catch (_e) {
        const errorMessage = "エラーが発生しました。お手数ですが担当者までご連絡ください";

        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      createOnnEventPlace,
      enqueueSnackbar,
      mode,
      onCancel,
      placeId,
      previousFilePaths,
      updateOnnEventPlace,
      uploadRemarkFilesToStorage,
    ]
  );

  return { submit, isLoading };
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    width: 800px;
    height: 100%;

    & > .MuiBox-root {
      height: 100%;

      /* content */
      & > .MuiBox-root:nth-child(2) {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }
  }
`;
