import liff from "@line/liff";

import { useEffect } from "react";

import { useDefaultRegistrationInvitationLinkBySpace } from "../useDefaultRegistrationInvitationLinkBySpace";
import { useLastRegistrationInvitationLink } from "../useLastRegistrationInvitationLink";

import { useLineAccessToken } from "~/hooks/context";
import { useNotifyOperationLog, useQuery } from "~/hooks/shared";
import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";

export const useRegistrationInvitationLinkWithoutId = (canFetch: boolean) => {
  const { guardAndGetLineAccessTokenFromLiff } = useLineAccessToken();
  const { data: lastLink, isLoading: isLoadingLastLink } = useLastRegistrationInvitationLink({
    liffAccessToken:
      liff.isInClient() && canFetch ? guardAndGetLineAccessTokenFromLiff() : undefined,
  });

  const tenantId = getTenantIdFromDomain();
  const { query } = useQuery();
  const { data: defaultLinkData, isLoading: isLoadingDefaultLink } =
    useDefaultRegistrationInvitationLinkBySpace({
      tenantId: canFetch ? tenantId : undefined,
      spaceId: query.get("space-id"),
    });

  const isLoading = isLoadingLastLink || isLoadingDefaultLink;
  const isNotFound = !isLoading && !lastLink && !defaultLinkData?.registrationInvitationLink;

  const { notifyOperationLog, operationLog } = useNotifyOperationLog();

  useEffect(() => {
    if (isLoading) return;

    if (lastLink) {
      notifyOperationLog(
        operationLog.notifyDeterminedRegistrationInvitationLink("前回の招待リンク", lastLink.id)
      );
    } else if (defaultLinkData?.registrationInvitationLink) {
      notifyOperationLog(
        operationLog.notifyDeterminedRegistrationInvitationLink(
          "デフォルト招待リンク",
          defaultLinkData.registrationInvitationLink.id
        )
      );
    }
  }, [defaultLinkData, isLoading, lastLink, notifyOperationLog, operationLog]);

  return {
    isLoading,
    isNotFound,
    registrationInvitationLink: isLoading
      ? undefined
      : lastLink || defaultLinkData?.registrationInvitationLink,
  };
};
