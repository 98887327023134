import { Action, AnyActionCondition, AnyActionSetting, AnyTriggerSetting } from "@onn/common";

import { useUpdateActionOnForm } from "../../../hooks/scenarioForm/actions/useUpdateActionOnForm";

import { useConvertInputToActionForUpdate } from "../ActionPopovers/common/useConvertInputToActionForUpdate";

import { ValidInputState } from "../ActionPopovers/inputStateSchema";

export const useApplyToScenarioForm = ({
  triggerId,
  recruitmentStatusId,
  existingAction,
  existingAnyActionSetting,
  closePopover,
  triggerSetting,
  existingAnyActionCondition,
}: {
  triggerId: string;
  recruitmentStatusId: string;
  closePopover: () => void;
  triggerSetting: AnyTriggerSetting;
  existingAction: Action;
  existingAnyActionSetting: AnyActionSetting;
  existingAnyActionCondition?: AnyActionCondition;
}) => {
  const { updateActionOnForm } = useUpdateActionOnForm({
    recruitmentStatusId,
    triggerId,
  });

  const { convertInputToActionForUpdate } = useConvertInputToActionForUpdate();
  const applyToScenarioForm = async (validInputValue: ValidInputState) => {
    updateActionOnForm(
      convertInputToActionForUpdate({
        validInputValue,
        triggerSetting,
        existingActionData: {
          action: existingAction,
          actionSetting: existingAnyActionSetting,
          actionCondition: existingAnyActionCondition,
        },
      })
    );

    closePopover();
  };

  return { applyToScenarioForm };
};
