import { intersectionBy } from "lodash";
import { useMemo } from "react";

import { useFilterByNameOrEmail, useFilterByStatus } from "./filter";

import { useFilterByCandidateDates } from "./filter/useFilterByCandidateDates";

import { useGetDeterminedAndCandidateDate } from "./filter/useGetDeterminedAndCandidateDate";

import { useCurrentUser } from "~/hooks/employee";
import {
  useEmployeeActiveLogsByType,
  useGenerateEmployeeIdToLatestEmployeeActiveLogMap,
} from "~/hooks/employeeActiveLog";
import {
  useCandidateDatesWithNumberOfParticipants,
  useDeterminedDate,
  useOnnEventAnswersWithEmployee,
} from "~/hooks/onnEvent";

export const useAnswerResultTab = ({ onnEventId }: { onnEventId: string }) => {
  const { currentUser } = useCurrentUser();
  const { data: onnEventAnswersWithEmployee, isLoading: isLoadingEventAnswers } =
    useOnnEventAnswersWithEmployee({ onnEventId });

  const { data: determinedDates, isLoading: isLoadingDeterminedDate } = useDeterminedDate({
    onnEventId,
  });
  const { data: candidateDatesWithNumberOfParticipants, isLoading: isCandidateDatesLoading } =
    useCandidateDatesWithNumberOfParticipants({ onnEventId, currentEmployeeId: currentUser.id });

  const { data: employeeActiveLogs = [] } = useEmployeeActiveLogsByType({
    onnEventId,
    type: "VISITED_ONN_EVENT_LANDING_PAGE",
  });

  const { generateEmployeeIdToLatestEmployeeActiveLogMap } =
    useGenerateEmployeeIdToLatestEmployeeActiveLogMap();

  const employeeIdToLatestEmployeeActiveLogMap = useMemo(
    () => generateEmployeeIdToLatestEmployeeActiveLogMap({ employeeActiveLogs }),
    [employeeActiveLogs, generateEmployeeIdToLatestEmployeeActiveLogMap]
  );

  const { getOnnEventDeterminedDate, getAnsweredCandidateDate } = useGetDeterminedAndCandidateDate({
    determinedDates: determinedDates ?? [],
    candidateDateList: candidateDatesWithNumberOfParticipants ?? [],
  });

  const { selectedCandidateDateIds, setSelectedCandidateDateIds, answersFilteredByCandidateDates } =
    useFilterByCandidateDates({
      onnEventAnswersWithEmployee: onnEventAnswersWithEmployee ?? [],
      getAnsweredCandidateDate,
    });
  const { answersFilteredByAnswerStatus, setSelectedStatusTypes, selectedStatusTypes } =
    useFilterByStatus({
      onnEventAnswersWithEmployee: onnEventAnswersWithEmployee ?? [],
      employeeIdToLatestEmployeeActiveLogMap,
      getOnnEventDeterminedDate,
      getAnsweredCandidateDate,
    });

  const { searchValue, setSearchValue, answersFilteredBySearchValue } = useFilterByNameOrEmail(
    onnEventAnswersWithEmployee ?? []
  );

  /**
   * 絞り込みの積集合
   */
  const selectedOnnEventAnswers = useMemo(() => {
    // 回答状況, ユーザー名・メールアドレス による絞り込み
    return intersectionBy(
      answersFilteredBySearchValue,
      answersFilteredByAnswerStatus,
      answersFilteredByCandidateDates
    );
  }, [
    answersFilteredByAnswerStatus,
    answersFilteredBySearchValue,
    answersFilteredByCandidateDates,
  ]);

  // 配信数
  const numberOfDistribution = onnEventAnswersWithEmployee ? onnEventAnswersWithEmployee.length : 0;
  // 回答者数
  const numberOfResponses = onnEventAnswersWithEmployee
    ? onnEventAnswersWithEmployee.filter((v) => v.isAnswered()).length
    : 0;

  const isLoading = isLoadingEventAnswers || isLoadingDeterminedDate || isCandidateDatesLoading;

  return {
    determinedDates,
    selectedOnnEventAnswers,
    searchValue,
    setSearchValue,
    isLoading,
    setSelectedStatusTypes,
    selectedStatusTypes,
    candidateDatesWithNumberOfParticipants,
    numberOfDistribution,
    numberOfResponses,
    employeeIdToLatestEmployeeActiveLogMap,
    selectedCandidateDateIds,
    setSelectedCandidateDateIds,
  };
};
