import React, { ReactNode } from "react";

import { Typography } from "~/components/uiParts";

export const columnTypes = [
  "newGraduate",
  "slotTime",
  "status",
  "assignee",
  // "evaluation", TODO: 実施後の面談で評価を記入できる https://app.clickup.com/t/86enrmfxw
  "eventType",
  "description",
] as const;
// key の順序をテーブル表示に利用する

export type ColumnType = (typeof columnTypes)[number];

export const generateHeaderNodeMap = ({
  numberOfAdjusted,
}: {
  numberOfAdjusted: number;
}): Record<ColumnType, ReactNode> => {
  return {
    newGraduate: (
      <Typography variant="body2" color="textSecondary" align="left" style={{ width: "100%" }}>
        回答済み：{numberOfAdjusted}
      </Typography>
    ),
    slotTime: "開催日時",
    status: "ステータス",
    assignee: "選考担当",
    // evaluation: "評価",
    eventType: "開催方法",
    description: "概要",
  };
};

export const widthPxMap: Record<ColumnType, number> = {
  newGraduate: 204,
  slotTime: 200,
  status: 160,
  assignee: 120,
  // evaluation: 120,
  eventType: 160,
  description: 240,
};

export const EMPTY_TEXT_AREA_HEIGHT = 200;

export const HEADER_CELL_HEIGHT_PX = 56;
export const BODY_CELL_HEIGHT_PX = 80;
