import liff from "@line/liff";
import { useCallback, useContext } from "react";

import { LineAccessTokenByLiffContext } from "~/components/providers";
import { ValidLineAccessTokenByLiffContextValue } from "~/components/providers/LineAccessTokenByLiffProvider";
import { captureException } from "~/util";

type Result = {
  setLineAccessToken: (lineAccessToken: ValidLineAccessTokenByLiffContextValue) => void;
  /** LIFF のときに明示的に guard できるように Context に持たせている */
  guardAndGetLineAccessTokenFromLiff: () => string;
};

export const useLineAccessToken = (): Result => {
  const { lineAccessToken, setLineAccessToken, guardLineAccessToken } = useContext(
    LineAccessTokenByLiffContext
  );

  const guardAndGetLineAccessTokenFromLiff = useCallback((): string => {
    if (!liff.isInClient()) {
      throw new Error("LIFF でのみ使用できます");
    }
    if (!guardLineAccessToken(lineAccessToken)) {
      captureException({
        error: new Error("[要確認] lineAccessToken が正しく取得できませんでした"),
        tags: { type: "要確認" },
        extras: {
          location: window.location.href,
          isCookieEnabled: navigator.cookieEnabled,
        },
      });
      throw new Error("LineAccessTokenGuard の子コンポーネントでのみ使用できます");
    }

    return lineAccessToken;
  }, [guardLineAccessToken, lineAccessToken]);

  if (lineAccessToken === undefined) {
    throw new Error("LineAccessTokenByLiffProvider の子コンポーネントでのみ使用できます");
  }

  return { setLineAccessToken, guardAndGetLineAccessTokenFromLiff };
};
