import { Box } from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { FC } from "react";

import styled from "styled-components";

import { DeliveryAtHourForm } from "./components/DeliveryAtHourForm";
import { DeliveryTimingTypeAndNumberForm } from "./components/DeliveryTimingTypeAndNumberForm";
import {
  CheckBoxOfIsImmediateDelivery,
  CheckBoxOfIsImmediateDeliveryProps,
} from "./components/inputs/CheckBoxOfIsImmediateDelivery";
import { SelectorOfDeliveryTimingTypeProps } from "./components/inputs/SelectorOfDeliveryTimingType";
import { TextFieldOfDeliveryAtHourProps } from "./components/inputs/TextFieldOfDeliveryAtHour";
import { TextFieldOfDeliveryTimingNumberProps } from "./components/inputs/TextFieldOfDeliveryTimingNumber";

import { Icon, Tooltip, Typography } from "~/components/uiParts";

type Props = {
  checkBoxOfIsImmediateDeliveryProps: CheckBoxOfIsImmediateDeliveryProps;
  selectorOfDeliveryTimingTypeProps: SelectorOfDeliveryTimingTypeProps;
  textFieldOfDeliveryTimingNumberProps: TextFieldOfDeliveryTimingNumberProps;
  textFieldOfDeliveryAtHourProps: TextFieldOfDeliveryAtHourProps;
};

export const DeliveryTimingForm: FC<Props> = ({
  checkBoxOfIsImmediateDeliveryProps,
  selectorOfDeliveryTimingTypeProps,
  textFieldOfDeliveryTimingNumberProps,
  textFieldOfDeliveryAtHourProps,
}) => {
  return (
    <Root>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" bold>
          配信タイミング
        </Typography>
        <Tooltip
          title="実行可能になったアクションの配信タイミングを設定できます。配信タイミングが設定されていない場合、即時配信されます。"
          placement="top-start"
        >
          <Icon icon="help" size="ssm" color="grey" />
        </Tooltip>
      </Stack>
      {checkBoxOfIsImmediateDeliveryProps.value || (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <DeliveryTimingTypeAndNumberForm
            textFieldOfDeliveryTimingNumberProps={textFieldOfDeliveryTimingNumberProps}
            selectorOfDeliveryTimingTypeProps={selectorOfDeliveryTimingTypeProps}
          />
          {selectorOfDeliveryTimingTypeProps.value === "days" && (
            <DeliveryAtHourForm textFieldOfDeliveryAtHourProps={textFieldOfDeliveryAtHourProps} />
          )}
        </Box>
      )}

      {/* NOTE: ラベルに関係ないエリアをクリックしてチェックボックスをon/offさせないようにwidthを設定 */}
      <Box width={"120px"}>
        <CheckBoxOfIsImmediateDelivery {...checkBoxOfIsImmediateDeliveryProps} />
      </Box>
    </Root>
  );
};

const Root = styled(Box)`
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
