import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Button, Chip, Icon, Typography } from "~/components/uiParts";
import { useHover } from "~/hooks/richMenu/useHover";

type Props = {
  onClick(): void;
  onReset(): void;
  count: number;
};
export const SearchByConditionsButton = ({ onClick, onReset, count }: Props): JSX.Element => {
  const { isHover, ...onMouseHandlers } = useHover();

  return (
    <Button
      onClick={onClick}
      borderRadius="regular"
      variant="outlined"
      color={count ? "primary" : "default"}
      fullWidth
    >
      <Box
        width="100%"
        height={"100%"}
        display="flex"
        alignItems="center"
        gridGap={"4px"}
        {...onMouseHandlers}
      >
        <Box display="flex" alignItems="center" pt={"2px"}>
          <Icon icon="filter" size="sm" color={count ? "primary" : "grey400"} />
        </Box>
        <Typography variant="body2" bold noWrap>
          詳細絞り込み
        </Typography>
        {count ? (
          isHover ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
            >
              <StyledIcon icon="close" size="sm" color={"primary"} />
            </Box>
          ) : (
            <StyledChip color={"primary"} label={`${count}`} bold />
          )
        ) : (
          <></>
        )}
      </Box>
    </Button>
  );
};

const StyledIcon = styled(Icon)`
  margin-left: 4px;
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: 13px;
    margin-left: 4px;
    padding: 0 6px;
    font-size: 10px;

    & > .MuiChip-label {
      height: fit-content;
      padding: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
