import React from "react";
import { FieldValues, FormProvider, FormProviderProps, UseFormSetValue } from "react-hook-form";

export const CustomRHFProvider = <InputState extends FieldValues>({
  children,
  ...formProviderProps
}: {
  children: React.ReactNode;
} & FormProviderProps<InputState>) => {
  const { setValue: originalSetValue } = formProviderProps;
  const setValue: UseFormSetValue<InputState> = (name, value, options = {}) => {
    const customOptions = {
      shouldDirty: true,
      shouldValidate: true,
      ...options,
    };
    // NOTE: 型エラーが出るため、型を明示的に指定している
    originalSetValue(
      name as Parameters<UseFormSetValue<InputState>>[0],
      value as Parameters<UseFormSetValue<InputState>>[1],
      customOptions as Parameters<UseFormSetValue<InputState>>[2]
    );
  };
  return (
    <FormProvider {...formProviderProps} setValue={setValue}>
      {children}
    </FormProvider>
  );
};
