import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { mutateEmployeeInformationGroups } from "./useEmployeeInformationGroups";

import { apiClient } from "~/libs";

export const useEditEmployeeInformationGroups = () => {
  const { enqueueSnackbar } = useSnackbar();

  const editEmployeeEmployeeInformationGroups = useCallback(
    async ({
      employeeInformationGroups,
      successMessage,
    }: {
      employeeInformationGroups: Array<{ id: string; label: string }>;
      successMessage: string;
    }) => {
      await apiClient
        .post("/api/employee-information-groups", {
          employeeInformationGroups,
        })
        .then(async () => {
          enqueueSnackbar(successMessage, { variant: "success" });
          mutateEmployeeInformationGroups();
        })
        .catch((error) => {
          if (error instanceof Error) {
            enqueueSnackbar(error.message, { variant: "error" });
          }
        });
    },
    [enqueueSnackbar]
  );

  return { editEmployeeEmployeeInformationGroups };
};
