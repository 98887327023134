import { ScenarioEditLock } from "@onn/common";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Timestamp,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { firestore } from "~/config/firebase";
import { IScenarioEditLockRepository } from "~/service/repository/IScenarioEditLockRepository";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

const COLLECTION_NAME = "scenarioEditLocks";

type ScenarioEditLockForDB = Omit<ExcludeMethods<ScenarioEditLock>, "updatedAt" | "createdAt"> & {
  updatedAt: Timestamp;
  createdAt: Timestamp;
};

export class ScenarioEditLockRepository implements IScenarioEditLockRepository {
  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }

  dbToObject({ updatedAt, createdAt, ...rest }: ScenarioEditLockForDB): ScenarioEditLock {
    return new ScenarioEditLock({
      ...rest,
      updatedAt: convertTimestampToDate(updatedAt),
      createdAt: convertTimestampToDate(createdAt),
    });
  }
  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  async findByIsCompleted({
    tenantId,
    scenarioId,
    isCompleted,
  }: {
    tenantId: string;
    scenarioId: string;
    isCompleted: boolean;
  }) {
    const refs = await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("scenarioId", "==", scenarioId),
        where("isCompleted", "==", isCompleted)
      )
    );
    return refs.docs.map((doc) => this.dbToObject(doc.data() as ScenarioEditLockForDB));
  }

  listenScenarioEditLocks({
    tenantId,
    scenarioId,
    onChangedScenarioEditLock,
  }: {
    tenantId: string;
    scenarioId: string;
    onChangedScenarioEditLock: (lock: ScenarioEditLock) => void;
  }) {
    const q = query(
      this.collection(),
      where("tenantId", "==", tenantId),
      where("scenarioId", "==", scenarioId)
    );
    return onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        onChangedScenarioEditLock(this.dbToObject(change.doc.data() as ScenarioEditLockForDB));
      });
    });
  }
}
