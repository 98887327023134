import { Box } from "@material-ui/core";
import { Employee, Role } from "@onn/common";
import React, { FC, useState } from "react";

import { EmployeeList, EmployeeSearchForm } from "./parts";

import { Divider } from "~/components/uiParts";
import { useAllEmployees } from "~/hooks/employee";

type Props = {
  notAllowEmails: string[];
  selectedEmails: string[];
  onSelectEmails: (emails: string[]) => void;
  // 入社者の関係者を上部に固定するために渡す
  targetNewHireForFixingMemberOnTop?: Employee;
  rowRendererMode?: "checkbox" | "default" | "compact";
  isExcludeNewGraduate?: boolean;
  excludeRoles?: Role[];
};

const siftEmployees = (employees: Employee[], newHire?: Employee) => {
  if (!newHire) return employees;

  const generatedEmployees: Employee[] = [];
  // バディとサポートメンバーは先頭に移動させる
  employees.forEach((employee) => {
    if (newHire.getMentorAndSupportMemberIds().includes(employee.id)) {
      return generatedEmployees.unshift(employee);
    }
    return generatedEmployees.push(employee);
  });

  return generatedEmployees;
};

export const SelectEmployeesForm: FC<Props> = ({
  notAllowEmails,
  selectedEmails,
  onSelectEmails,
  targetNewHireForFixingMemberOnTop,
  rowRendererMode,
  isExcludeNewGraduate,
  excludeRoles,
}) => {
  const { allEmployees } = useAllEmployees();
  const notNewGraduateEmployees = allEmployees.filter((v) => !v.isNewGraduateEmployee());

  const selectableEmployees = (
    isExcludeNewGraduate ? notNewGraduateEmployees : allEmployees
  ).filter((v) => !excludeRoles?.includes(v.role));

  const [displayEmployees, setDisplayEmployees] = useState<Employee[]>(selectableEmployees); // クライアント検索処理で再構成される表示用の配列

  return (
    <>
      <Box p={rowRendererMode === "checkbox" ? "16px" : "0px"}>
        <EmployeeSearchForm
          showPlaceholder
          variant={rowRendererMode === "checkbox" ? "outlined" : "standard"}
          employees={selectableEmployees}
          onSearch={(results) => setDisplayEmployees(results)}
        />
      </Box>
      {rowRendererMode === "checkbox" && <Divider />}
      <EmployeeList
        employees={siftEmployees(
          displayEmployees.filter((v) => !notAllowEmails.includes(v.email)),
          targetNewHireForFixingMemberOnTop
        )}
        selectedEmployees={selectableEmployees.filter((v) => selectedEmails.includes(v.email))}
        onSelect={(employees) => onSelectEmails(employees.map((v) => v.email))}
        isMultiple
        rowRendererMode={rowRendererMode}
      />
    </>
  );
};
