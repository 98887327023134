import { TType } from "@onn/common/domain/LineUserActiveLog/schema";
import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useCreateLineUserActiveLog = () => {
  const createLineUserActiveLogs = useCallback(
    async ({
      lineAccessToken,
      location,
      tenantId,
      liffId,
      spaceId,
      type,
      sequenceId,
    }: {
      lineAccessToken: string;
      location: string;
      tenantId: string;
      liffId: string;
      spaceId?: string;
      type: TType;
      sequenceId: string;
    }) => {
      //  NOTE: このエンドポイントは、ログインしていないユーザーでもアクセスできるようにするため、useCurrentUserを使わず引数tenantIdを受け取る
      await apiClient.post(
        "/create_line_user_active_logs",
        {
          lineAccessToken,
          location,
          tenantId,
          liffId,
          spaceId,
          type,
          sequenceId,
        },
        { keepalive: true }
      );
    },
    []
  );

  return { createLineUserActiveLogs };
};
