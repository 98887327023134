import { ContactMessage } from "@onn/common";
import { isEmpty } from "lodash";
import { v4 } from "uuid";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { apiClient } from "~/libs";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

// TODO: ポータル側でも楽観的更新を行う
export class CreateContactMessageUseCase {
  async execute({
    text,
    tenantId,
    contactRoomId,
    newMessageFiles,
  }: Pick<ContactMessage, "text" | "contactRoomId"> & {
    createdEmployeeId: string;
    tenantId: string;
    newMessageFiles: File[];
  }): Promise<ContactMessage> {
    let filePaths: string[] | undefined = undefined;
    if (!isEmpty(newMessageFiles)) {
      const files = newMessageFiles.map((newMessageFile) => {
        const uid = v4();
        return {
          path: `tenants/${tenantId}/contact_messages/${uid}/${newMessageFile.name}`,
          file: newMessageFile,
        };
      });
      filePaths = files.map((v) => v.path);
      await fileAPIAdapter.uploadFiles(files);
    }

    const {
      data: { contactMessage },
    } = await apiClient.post("/api/contact-room/post-contact-rooms-messages-by-new-hire", {
      contactRoomId: contactRoomId,
      messageText: text || "",
      messageFilePaths: filePaths,
    });

    return contactMessage;
  }
}
