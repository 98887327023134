import { v4 } from "uuid";
import { z } from "zod";

const functions = ["notifyAttendanceRequest"] as const;
export type FunctionName = (typeof functions)[number];

export const notificationLogSchema = z.object({
  id: z.string().trim().default(v4),
  tenantId: z.string(),
  functionName: z.enum(functions),
  targetId: z.string(),
  createdAt: z.date().default(() => new Date()),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INotificationLog extends z.infer<typeof notificationLogSchema> {}
