import { v4 } from "uuid";

import { EmailType, LineType } from "../Employee/AuthenticationType";

import { IChangeAuthenticationLogSchema, changeAuthenticationLogSchema } from "./schema";

export class ChangeAuthenticationLog implements IChangeAuthenticationLogSchema {
  static validator = changeAuthenticationLogSchema;

  readonly id: string;
  readonly employeeId: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly fromUid: string;
  readonly toUid: string;
  readonly fromType: EmailType;
  readonly toType: LineType;
  readonly fromEmail?: string;
  readonly createdAt: Date;

  constructor(init: ExcludeMethods<ChangeAuthenticationLog>) {
    ChangeAuthenticationLog.validator.parse(init);
    this.id = init.id;
    this.employeeId = init.employeeId;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.fromUid = init.fromUid;
    this.toUid = init.toUid;
    this.fromType = init.fromType;
    this.toType = init.toType;
    this.fromEmail = init.fromEmail;
    this.createdAt = init.createdAt;
  }

  public static create(params: Omit<ExcludeMethods<ChangeAuthenticationLog>, "id" | "createdAt">) {
    return new ChangeAuthenticationLog({
      id: v4(),
      employeeId: params.employeeId,
      tenantId: params.tenantId,
      spaceId: params.spaceId,
      fromUid: params.fromUid,
      toUid: params.toUid,
      fromType: params.fromType,
      toType: params.toType,
      fromEmail: params.fromEmail,
      createdAt: new Date(),
    });
  }
}
