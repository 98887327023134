import { Box } from "@material-ui/core";
import React, { useMemo } from "react";

import { Loading } from "~/components/uiParts";

import { useOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";

// 公開予約枠を表示するセル
export const PublicSlotsCountCell = ({ onnEventId }: { onnEventId: string }) => {
  const { data: onnEventSlotDatesForDisplay = [], isLoading: isLoadingFetchingSlots } =
    useOnnEventSlotDatesForDisplay(onnEventId);

  const publicSlotsCount = useMemo(() => {
    return onnEventSlotDatesForDisplay.filter((slot) => slot.isPublishedAndNotDone()).length;
  }, [onnEventSlotDatesForDisplay]);

  return isLoadingFetchingSlots ? (
    <Loading key={onnEventId} size="small" />
  ) : (
    <Box key={onnEventId}>{publicSlotsCount}</Box>
  );
};
