import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { PortalOnnEventCard } from "../onnEvent/components/PortalOnnEventCard";

import { Typography, Button, Icon } from "~/components/uiParts";
import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventsForPortal";

type Props = {
  onnEventDataArray: OnnEventDataForPortal[];
  isPreview: boolean;
};

export const EventBlock: FC<Props> = ({ onnEventDataArray, isPreview }) => {
  const navigate = useNavigate();

  const handleClickNavigateEvents = () => {
    navigate(`/portal/events${isPreview ? "?preview=true" : ""}`);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary">
          イベント
        </Typography>
        <Button
          color="primary"
          variant="text"
          borderRadius="regular"
          endIcon={<Icon icon="arrowRight" color="primary" size="sm" />}
          onClick={handleClickNavigateEvents}
        >
          すべて見る
        </Button>
      </Box>

      <Typography variant="caption" color="textPrimary">
        期日の近い3つを表示しています
      </Typography>

      <Box height="8px" />

      {!isEmpty(onnEventDataArray) ? (
        onnEventDataArray.map((v) => (
          <Box key={v.onnEvent.id} mt={2}>
            <PortalOnnEventCard onnEventData={v} />
          </Box>
        ))
      ) : (
        <Box mt={2}>
          <StyledDiv>
            <Box width="100%" p={3}>
              <Typography variant="body2" color="textPrimary">
                未回答のものはありません
              </Typography>
            </Box>
          </StyledDiv>
        </Box>
      )}
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.default};
  box-shadow: ${(props) => props.theme.shadows[10]};
  list-style-type: none;
  border-radius: 15px;
`;
