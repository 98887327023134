import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useState } from "react";

import { FilePreparationStep } from "./FilePreparationStep";

import { FileUploadStep } from "./FileUploadStep";

import { Divider, Modal, VerticalStepper } from "~/components/uiParts";

type Props = {
  open: boolean;
} & ContentProps;

export const AddNewGraduateByCSVModal = ({
  open,
  onSubmit,
  onCancel,
  ...params
}: Props): JSX.Element => {
  return (
    <Modal
      title="CSVアップロード"
      fullWidth
      open={open}
      onCancel={onCancel}
      content={
        <Content
          {...params}
          onCancel={onCancel}
          onSubmit={(v) => {
            onSubmit(v);
            onCancel();
          }}
        />
      }
    />
  );
};

type ContentProps = {
  currentSelectedNewGraduates: Employee[];
  registeredEmployeeIds: string[];
  onCancel: () => void;
  onSubmit: (newComers: Employee[]) => void;
  canUseNotRegistered?: boolean;
  canUseNotInvited?: boolean;
};

function Content(props: ContentProps) {
  const [activeStep, setActiveStep] = useState(1);

  const canUseNotRegistered = props.canUseNotRegistered ?? true;
  const canUseNotInvited = !!props.canUseNotInvited;

  return (
    <Box display="grid" gridTemplateColumns="auto auto 1fr" gridGap="40px">
      <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
      <Divider orientation="vertical" />
      {activeStep === 1 && (
        <FilePreparationStep
          onNext={() => setActiveStep((prev) => prev + 1)}
          canUseNotRegistered={canUseNotRegistered}
          canUseNotInvited={canUseNotInvited}
        />
      )}
      {activeStep === 2 && (
        <FileUploadStep
          {...props}
          canUseNotRegistered={canUseNotRegistered}
          canUseNotInvited={canUseNotInvited}
        />
      )}
    </Box>
  );
}
