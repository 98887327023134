import React, { FC, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { LoadingWhenChangingAuthentication } from "./LoadingWhenChangingAuthentication";
import { AlreadyExistLineAuth } from "./forFailed/AlreadyExistLineAuth";
import { AlreadyUsedChangeAuthenticationTypeLinkPage } from "./forFailed/AlreadyUsedChangeAuthenticationTypeLinkPage";
import { ExpiredChangeAuthenticationTypeLinkPage } from "./forFailed/ExpiredChangeAuthenticationTypeLinkPage";
import { FailedToChangeAuthenticationPage } from "./forFailed/FailedToChangeAuthenticationPage";

import { NotFoundChangeAuthenticationTypeLinkPage } from "./forFailed/NotFoundChangeAuthenticationTypeLinkPage";

import { useLineAccessToken } from "~/hooks/context";
import { useChangeAuthentication } from "~/hooks/employee/useChangeAuthentication";
import { useNotifyOperationLog, useSnackbar } from "~/hooks/shared";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * ・ログイン方法変更エンドポイントを叩くhooks
 */
const useExecChangeAuthentication = ({
  changeAuthenticationTypeLinkId,
  employeeId,
}: {
  changeAuthenticationTypeLinkId: string;
  employeeId: string;
}) => {
  const { guardAndGetLineAccessTokenFromLiff } = useLineAccessToken();
  const { changeAuthentication } = useChangeAuthentication();
  const [isLoading, setIsLoading] = useState(true);
  const [responseType, setResponseType] = useState<
    | "NotFoundChangeAuthenticationTypeLink"
    | "ChangeAuthenticationTypeLinkWasUsed"
    | "ChangeAuthenticationTypeLinkIsExpired"
    | "NotFoundLineUser"
    | "AlreadyExistAuthInSameTenant"
    | ""
  >("");
  const [isSuccessfullyChangeAuthentication, setIsSuccessfullyChangeAuthentication] =
    useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { notifyOperationLog, operationLog } = useNotifyOperationLog();

  const lineAccessToken = guardAndGetLineAccessTokenFromLiff();
  useEffect(() => {
    (async () => {
      try {
        await notifyOperationLog(operationLog.notifyStartChangeAuthentication(employeeId));
        const response = await changeAuthentication({
          changeAuthenticationTypeLinkId,
          employeeId: employeeId,
          lineAccessToken,
        });

        if (response.isSuccess) {
          const { customToken, isFollowedLineOfficialAccount } = response;
          await AccountUseCase.signInWithCustomToken(customToken);
          setIsSuccessfullyChangeAuthentication(response.isSuccess);
          enqueueSnackbar("LINEログインに変更されました", {
            variant: "success",
          });
          if (isFollowedLineOfficialAccount) {
            navigate("/");
          } else {
            // NOTE: LINE公式アカウントをフォローしていない場合は友達登録を促す画面へ遷移
            navigate("/portal/line_qr");
          }
        } else {
          setResponseType(response.type);
        }
      } catch (error) {
        captureException({
          error: error as Error,
          tags: {
            type: "useExecChangeAuthentication",
          },
          extras: {
            changeAuthenticationTypeLinkId,
            employeeId,
          },
        });
      } finally {
        setIsLoading(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, isSuccessfullyChangeAuthentication, responseType };
};

export const PageOnLiffBrowser: FC<{
  changeAuthenticationTypeLinkId: string;
  employeeId: string;
}> = ({ changeAuthenticationTypeLinkId, employeeId }) => {
  const { isLoading, isSuccessfullyChangeAuthentication, responseType } =
    useExecChangeAuthentication({
      changeAuthenticationTypeLinkId,
      employeeId,
    });

  // NOTE: 成功した場合は自動でポータルに遷移するため、ローディングを表示し続けておく
  if (isLoading || isSuccessfullyChangeAuthentication) {
    return <LoadingWhenChangingAuthentication />;
  }

  switch (responseType) {
    case "NotFoundChangeAuthenticationTypeLink":
      return <NotFoundChangeAuthenticationTypeLinkPage />;
    case "ChangeAuthenticationTypeLinkWasUsed":
      return <AlreadyUsedChangeAuthenticationTypeLinkPage />;
    case "ChangeAuthenticationTypeLinkIsExpired":
      return <ExpiredChangeAuthenticationTypeLinkPage />;
    case "AlreadyExistAuthInSameTenant":
      return <AlreadyExistLineAuth />;
    default:
      // NOTE: ここには来ない想定
      return <FailedToChangeAuthenticationPage />;
  }
};
