import { useCallback } from "react";

import { DeliveryTimingValidInput } from "../components/deliveryTimingInputSchema";

/**
 * NOTE: inputState から Action の配信タイミング（executionTimeAfterDays,executionTimeAtHours,executionTimeWithinMinutes）に変換
 */
export const useConvertInputToActionTiming = () => {
  const convertInputToActionTiming = useCallback(
    (deliveryTiming: DeliveryTimingValidInput["deliveryTiming"]) => {
      if (deliveryTiming.isImmediateDelivery) {
        return {
          executionTimeAfterDays: undefined,
          executionTimeAtHours: undefined,
          executionTimeWithinMinutes: undefined,
        };
      } else {
        switch (deliveryTiming.scheduledDeliveryTiming?.type) {
          case "minutes": {
            return {
              executionTimeAfterDays: undefined,
              executionTimeAtHours: undefined,
              executionTimeWithinMinutes: deliveryTiming.scheduledDeliveryTiming.timingNumber,
            };
          }
          case "hours": {
            return {
              executionTimeAfterDays: undefined,
              executionTimeAtHours: undefined,
              executionTimeWithinMinutes:
                (deliveryTiming.scheduledDeliveryTiming.timingNumber || 0) * 60,
            };
          }
          case "days": {
            return {
              executionTimeAfterDays: deliveryTiming.scheduledDeliveryTiming.timingNumber,
              executionTimeAtHours: deliveryTiming.scheduledDeliveryTiming.atHour,
              executionTimeWithinMinutes: undefined,
            };
          }
        }
      }
    },
    []
  );

  return { convertInputToActionTiming };
};
