import React, { FC } from "react";
import styled from "styled-components";

import { Chip } from "~/components/uiParts";
import theme from "~/config/theme";

type Props = {
  /**
   * 招待済み or 未招待
   */
  invited: boolean;
};

export const NewGraduateInvitationChip: FC<Props> = ({ invited }) => {
  return <StyledChip $invited={invited} label={invited ? "招待中" : "未招待"} />;
};

const StyledChip = styled(Chip).attrs({ color: "muted" })<{ $invited: boolean }>`
  &.MuiChip-root {
    background-color: ${({ $invited }) => ($invited ? undefined : theme.palette.grey[300])};
    border: ${({ $invited }) => ($invited ? `solid 1px ${theme.palette.primary.main}` : undefined)};
  }
  .MuiChip-label {
    padding: 0 8px;
    color: ${({ $invited }) =>
      $invited ? theme.palette.primary.main : theme.palette.primary.contrastText};
  }
`;
