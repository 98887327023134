import { Box } from "@mui/material";
import { Employee, FormRevision } from "@onn/common";
import React, { FC } from "react";

import { QuestionsAndAnswers } from "./components/QuestionsAndAnswers";
import { TargetEmployee } from "./components/TargetEmployee";

export const Content: FC<{
  formRevision: FormRevision;
  employee: Employee;
}> = ({ formRevision, employee }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <TargetEmployee employee={employee} />
      <QuestionsAndAnswers formRevision={formRevision} />
    </Box>
  );
};
