import { Box, Popover as MuiPopover, PopoverProps } from "@mui/material";
import React, { FC } from "react";

import styled from "styled-components";

import { Divider, Typography, Icon, IconButton, Tooltip } from "~/components/uiParts";
import theme from "~/config/theme";

type Props = Pick<
  PopoverProps,
  "id" | "anchorEl" | "open" | "onClose" | "className" | "anchorOrigin" | "transformOrigin"
> & {
  onClickArrowLeftIconButton: () => void;
  children?: React.ReactNode;
  title: string;
  infoIconText?: string;
};
export const Popover: FC<Props> = ({
  onClickArrowLeftIconButton,
  children,
  title,
  infoIconText,
  anchorOrigin,
  transformOrigin,
  ...props
}) => {
  return (
    <MuiPopover
      disableEnforceFocus // モーダルと一緒に用いる場合このオプションが設定されていない場合にメモリリークを引き起こすため
      anchorOrigin={
        anchorOrigin || {
          vertical: "bottom",
          horizontal: "left",
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: "top",
          horizontal: "left",
        }
      }
      slotProps={{
        paper: {
          sx: {
            p: "16px",
            width: "400px",
          },
        },
      }}
      {...props}
    >
      <Box display={"flex"} position={"relative"}>
        <Box
          position={"absolute"}
          top={"0px"}
          display={"flex"}
          alignItems={"center"}
          height={"100%"}
        >
          <StyledIconButton
            icon="arrowLeft"
            size="ssm"
            borderRadius="regular"
            onClick={onClickArrowLeftIconButton}
          />
        </Box>
        <Typography
          variant="body2"
          bold
          component={"div"}
          style={{ flexGrow: 1, textAlign: "center" }}
        >
          {title}
        </Typography>
        {infoIconText && (
          <Tooltip title={infoIconText} placement="top-start">
            <Icon icon="help" size="ssm" color="grey" />
          </Tooltip>
        )}
      </Box>
      <Divider style={{ margin: "16px 0px" }} />
      {children}
    </MuiPopover>
  );
};

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 0px;
    background-color: ${theme.palette.common.white};
  }
`;
