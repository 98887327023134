import { Box, Stack } from "@mui/material";
import { RecruitmentStatus } from "@onn/common";
import React, { FC } from "react";

import { Icon, TextField, Tooltip } from "~/components/uiParts";

type Props = {
  label: string;
  type: Exclude<RecruitmentStatus["type"], "screening">;
  onChange: (value: string) => void;
  errorText?: string;
};

export const StandardStatusInputRow: FC<Props> = ({ label, type, onChange, errorText }) => {
  return (
    <Stack direction="row" alignItems="start" spacing={1} py={1} width="100%">
      <TextField
        fullWidth
        variant="outlined"
        value={RecruitmentStatus.typeToDefaultLabelMap[type]}
        disabled
      />
      <TextField
        fullWidth
        variant="outlined"
        placeholder="代替テキストを入力してください"
        defaultValue={RecruitmentStatus.typeToDefaultLabelMap[type] === label ? undefined : label}
        onChange={(e) => onChange(e.target.value)}
        error={!!errorText}
        helperText={errorText}
      />
      <Box px={1} py={1} display="flex" justifyContent="center">
        <Tooltip title="「プレエントリー」「内定(承諾待ち)」「入社承諾」「不採用」「辞退」の選考ステータスは、システムで管理されているため削除できません。表示名を変更したい場合は、代替テキストへ入力してください。">
          <Icon icon="help" size="md" color="grey400" />
        </Tooltip>
      </Box>
    </Stack>
  );
};
