import { Box } from "@material-ui/core";
import React from "react";

import { UndefinedCondition } from "../../../types/condition";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import { CommonProps } from "./type";

const TARGET = undefined;

type Props = CommonProps<UndefinedCondition>;
export const UndefinedConditionSelector = ({ index, onChangeTarget }: Props): JSX.Element => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={2}>
        <SelectTargetButton target={TARGET} onChange={(target) => onChangeTarget(index, target)} />
      </Box>
      <Box flex={10}></Box>
    </Box>
  );
};
