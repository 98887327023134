import { z } from "zod";

/////////////////////////////////////////
// ONN TASK DELIVERY CANCELLATION LOG SCHEMA
/////////////////////////////////////////

export const OnnTaskDeliveryCancellationLogSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  onnTaskId: z.string(),
  employeeId: z.string(),
  createdAt: z.date(),
});

export type OnnTaskDeliveryCancellationLog = z.infer<typeof OnnTaskDeliveryCancellationLogSchema>;

/////////////////////////////////////////
// ONN TASK DELIVERY CANCELLATION LOG OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnTaskDeliveryCancellationLogOptionalDefaultsSchema =
  OnnTaskDeliveryCancellationLogSchema.merge(
    z.object({
      createdAt: z.date().optional(),
    })
  );

export type OnnTaskDeliveryCancellationLogOptionalDefaults = z.infer<
  typeof OnnTaskDeliveryCancellationLogOptionalDefaultsSchema
>;

export default OnnTaskDeliveryCancellationLogSchema;
