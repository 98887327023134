import { Stack } from "@mui/material";
import {
  Action,
  AnyActionCondition,
  AnyActionSetting,
  instantiateFromAnyActionSettingExcludeMethods,
} from "@onn/common";
import React, { createRef, FC, RefObject, useEffect, useState } from "react";

import { UseFormReturn } from "react-hook-form";

import { InputState } from "../../type";

import { ActionDescriptionArea } from "./ActionDescriptionArea";

import { Button, Icon, Tooltip, Typography } from "~/components/uiParts";
import { useMenu } from "~/pages/scenario/Main/components/useMenu";
import { PopoverOfActionSetting } from "~/pages/scenario/Main/editMode/components/ActionPopovers/PopoverOfActionSetting";
import { ValidInputState } from "~/pages/scenario/Main/editMode/components/ActionPopovers/inputStateSchema";
import { MenuOfActionSelection } from "~/pages/scenario/Main/editMode/components/MenuOfActionSelection";
import { usePopoverOfActionSetting } from "~/pages/scenario/Main/editMode/hooks/usePopoverOfActionSetting";

export const ActionArea: FC<{
  actions: InputState["actions"];
  triggerSetting: InputState["triggerSetting"];
  addAction: (inputValue: ValidInputState) => void;
  updateAction: (
    inputValue: ValidInputState,
    existingActionData: {
      action: Action;
      actionSetting: AnyActionSetting;
      actionCondition?: AnyActionCondition;
    }
  ) => void;
  deleteAction: (actionId: string) => void;
  form: UseFormReturn<InputState>;
}> = ({ actions, triggerSetting, form, addAction, updateAction, deleteAction }) => {
  const {
    actionSelectMenuForUpdate,
    popoverOfActionSettingForUpdate,
    actionEditButtonRefs,
    actionEditButtonElement,
    setActionEditButtonElement,
  } = usePopoverOfActionSettings(actions.length);

  const { popoverOfActionSettingForCreate, actionSelectMenuForCreate } =
    usePopoverForCreateAction();

  const isExistTriggerSetting = Boolean(triggerSetting);

  return (
    <>
      <Stack direction="column" rowGap={2} alignItems="start">
        <Typography variant="body2" bold>
          アクション
        </Typography>
        {actions.map(({ action, actionSetting, actionCondition }, index) => {
          const errorMessage = form.formState.errors.actions?.[index]?.actionCondition;
          return (
            <ActionDescriptionArea
              key={action.id}
              action={action}
              actionSetting={actionSetting}
              actionCondition={actionCondition}
              editButtonRef={actionEditButtonRefs.current[index]}
              actionConditionErrorMessage={errorMessage?.message}
              onClickEditButton={() => {
                setActionEditButtonElement(actionEditButtonRefs.current[index]?.current || null);
                const resource = {
                  action,
                  actionSetting,
                  actionCondition,
                };
                switch (actionSetting.type) {
                  case "ChangeRecruitmentStatusActionSetting": {
                    popoverOfActionSettingForUpdate.open("changeRecruitmentStatus", resource);
                    break;
                  }
                  case "DeliverOnnEventActionSetting": {
                    popoverOfActionSettingForUpdate.open("deliveryOnnEvent", resource);
                    break;
                  }
                  case "DeliverOnnTaskActionSetting": {
                    popoverOfActionSettingForUpdate.open("deliveryOnnTask", resource);
                    break;
                  }
                  case "SendContactMessageActionSetting": {
                    popoverOfActionSettingForUpdate.open("sendContactMessage", resource);
                    break;
                  }
                }
              }}
              onClickDeleteButton={() => deleteAction(action.id)}
              triggerSetting={triggerSetting}
            />
          );
        })}
        <Tooltip
          title={isExistTriggerSetting ? "" : "トリガーを追加後、アクションを設定してください。"}
          placement="top-start"
        >
          <Button
            variant="outlined"
            color="primary"
            borderRadius="regular"
            startIcon={
              <Icon icon="add" color={isExistTriggerSetting ? "primary" : "lightGrey"} size="ssm" />
            }
            onClick={actionSelectMenuForCreate.openMenu}
            buttonRef={actionSelectMenuForCreate.anchorEl}
            disabled={!isExistTriggerSetting}
          >
            <Typography variant="body2" bold style={{ lineHeight: "20px" }}>
              追加
            </Typography>
          </Button>
        </Tooltip>
      </Stack>
      {/* アクション作成用のアクション設定選択popover */}
      <MenuOfActionSelection
        anchorEl={actionSelectMenuForCreate.anchorEl.current}
        onClose={actionSelectMenuForCreate.closeMenu}
        isOpen={actionSelectMenuForCreate.isOpen}
        onClickItem={(type) => {
          // TODO: ActionTypeとAnyActionSetting["type"] の型を揃える
          switch (type) {
            case "ChangeRecruitmentStatusActionSetting":
              popoverOfActionSettingForCreate.open("changeRecruitmentStatus");
              break;
            case "DeliverOnnEventActionSetting":
              popoverOfActionSettingForCreate.open("deliveryOnnEvent");
              break;
            case "DeliverOnnTaskActionSetting":
              popoverOfActionSettingForCreate.open("deliveryOnnTask");
              break;
            case "SendContactMessageActionSetting":
              popoverOfActionSettingForCreate.open("sendContactMessage");
              break;
            default: {
              const _: never = type;
              break;
            }
          }
          actionSelectMenuForCreate.closeMenu();
        }}
      />
      {/* アクション更新用のアクション設定選択popover */}
      <MenuOfActionSelection
        anchorEl={actionEditButtonElement}
        onClose={actionSelectMenuForUpdate.closeMenu}
        isOpen={actionSelectMenuForUpdate.isOpen}
        onClickItem={(type) => {
          // TODO: ActionTypeとAnyActionSetting["type"] の型を揃える
          switch (type) {
            case "ChangeRecruitmentStatusActionSetting":
              popoverOfActionSettingForUpdate.open(
                "changeRecruitmentStatus",
                popoverOfActionSettingForUpdate.existingResource
              );
              break;
            case "DeliverOnnEventActionSetting":
              popoverOfActionSettingForUpdate.open(
                "deliveryOnnEvent",
                popoverOfActionSettingForUpdate.existingResource
              );
              break;
            case "DeliverOnnTaskActionSetting":
              popoverOfActionSettingForUpdate.open(
                "deliveryOnnTask",
                popoverOfActionSettingForUpdate.existingResource
              );
              break;
            case "SendContactMessageActionSetting":
              popoverOfActionSettingForUpdate.open(
                "sendContactMessage",
                popoverOfActionSettingForUpdate.existingResource
              );
              break;
            default: {
              const _: never = type;
              break;
            }
          }
          actionSelectMenuForUpdate.closeMenu();
        }}
      />
      {/* アクション作成用 */}
      {popoverOfActionSettingForCreate.openedActionType && (
        <PopoverOfActionSetting
          key={`${String(popoverOfActionSettingForCreate.isOpen)}-${
            popoverOfActionSettingForCreate.openedActionType
          }`}
          anchorEl={popoverOfActionSettingForCreate.anchorEl.current}
          onClose={popoverOfActionSettingForCreate.close}
          isOpen={popoverOfActionSettingForCreate.isOpen}
          triggerSetting={triggerSetting}
          backToSelectActionType={() => {
            actionSelectMenuForCreate.openMenu();
            popoverOfActionSettingForCreate.backToSelectActionType();
          }}
          actionType={popoverOfActionSettingForCreate.openedActionType}
          mode="create"
          existingAction={null}
          existingAnyActionSetting={null}
          existingActionCondition={null}
          onClickApplyButton={addAction}
        />
      )}
      {/* アクション更新用 */}
      {popoverOfActionSettingForUpdate.openedActionType &&
        popoverOfActionSettingForUpdate.existingResource && (
          <PopoverOfActionSetting
            key={`${String(popoverOfActionSettingForUpdate.isOpen)}-${
              popoverOfActionSettingForUpdate.openedActionType
            }`}
            anchorEl={actionEditButtonElement}
            onClose={popoverOfActionSettingForUpdate.close}
            isOpen={popoverOfActionSettingForUpdate.isOpen}
            triggerSetting={triggerSetting}
            backToSelectActionType={() => {
              actionSelectMenuForUpdate.openMenu();
              popoverOfActionSettingForUpdate.backToSelectActionType();
            }}
            actionType={popoverOfActionSettingForUpdate.openedActionType}
            {...(() => {
              const existingAction = new Action(
                popoverOfActionSettingForUpdate.existingResource.action
              );
              const existingActionSetting = instantiateFromAnyActionSettingExcludeMethods(
                popoverOfActionSettingForUpdate.existingResource.actionSetting
              );
              const existingActionCondition =
                popoverOfActionSettingForUpdate.existingResource.actionCondition;
              return {
                mode: "update",
                existingAction,
                existingAnyActionSetting: existingActionSetting,
                existingActionCondition,
                onClickApplyButton: (inputValue) => {
                  updateAction(inputValue, {
                    action: existingAction,
                    actionSetting: existingActionSetting,
                    actionCondition: existingActionCondition,
                  });
                },
              };
            })()}
          />
        )}
    </>
  );
};

const usePopoverOfActionSettings = (actionsLength: number) => {
  const actionSelectMenuForUpdate = useMenu();
  const popoverOfActionSettingForUpdate = usePopoverOfActionSetting();
  // NOTE: 押されたアクション編集ボタンのRefを保持する
  const actionEditButtonRefs = React.useRef<RefObject<HTMLButtonElement>[]>([]);
  useEffect(() => {
    actionEditButtonRefs.current = [...Array(actionsLength)].map(() =>
      createRef<HTMLButtonElement>()
    );
  }, [actionsLength]);

  // NOTE: 押されたアクション編集ボタンに応じてポップオーバーのアンカー要素を動的に設定するためstateで管理している
  const [actionEditButtonElement, setActionEditButtonElement] = useState<HTMLButtonElement | null>(
    null
  );

  return {
    actionSelectMenuForUpdate,
    popoverOfActionSettingForUpdate,
    actionEditButtonRefs,
    actionEditButtonElement,
    setActionEditButtonElement,
  };
};

const usePopoverForCreateAction = () => {
  const actionSelectMenuForCreate = useMenu();
  const popoverOfActionSettingForCreate = usePopoverOfActionSetting();

  // NOTE: アクション設定セレクトメニューとアクション設定ポップオーバーを同じ位置に表示させるためアンカー要素を同期させる
  popoverOfActionSettingForCreate.anchorEl = actionSelectMenuForCreate.anchorEl;
  return {
    actionSelectMenuForCreate,
    popoverOfActionSettingForCreate,
  };
};
