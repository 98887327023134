import { EventAnswerStatusType } from "./type";

/**
 * onnEventの回答状況から判別しやすいステータスタイプを返却する
 */
export const getEventAnswerStatusType = ({
  isAnswered,
  isNearDeadline,
  isExpired,
  canAnswer,
}: {
  isAnswered: boolean;
  isNearDeadline: boolean;
  isExpired: boolean;
  canAnswer: boolean;
}): EventAnswerStatusType => {
  // 回答済みの場合
  if (isAnswered) {
    return "done";
  } else {
    if (!canAnswer) {
      return "overdue";
    }

    // 回答期限が近づいている場合 or 期限切れかつ回答期限後も回答可能の場合は赤く表示する
    if (isNearDeadline || isExpired) {
      return "danger";
    }

    return "default";
  }
};
