import {
  BriefingSessionEvent,
  NewInterviewEvent,
} from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import { useCallback } from "react";

import { useSnackbar } from "~/hooks/shared";

export const useEnqueueSuccessSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSuccessSnackbar = useCallback(
    (deliveredCount: number, onnEvent: BriefingSessionEvent | NewInterviewEvent) => {
      const notificationInfo = onnEvent.getNotificationInformationOfRequestForAnswer();
      const interviewOrBriefing = (() => {
        if (onnEvent.type === "briefing_session") {
          return "説明会イベント";
        } else if (onnEvent.type === "new_interview") {
          return "面談イベント";
        } else {
          const _exhaustiveCheck: never = onnEvent;
        }
      })();
      let text = undefined;
      if (notificationInfo.type === "NO_NOTIFICATION") {
        text = `${deliveredCount}名の候補者に${interviewOrBriefing}を配信しました`;
      } else if (notificationInfo.type === "SCHEDULE_NOTIFICATION") {
        text = `${deliveredCount}名の候補者に${interviewOrBriefing}を配信しました。`;
        const timing = onnEvent.timingForRequestForAnswerNotification?.timing;
        if (timing === "DAYS_AGO") {
          text += `${onnEvent.timingForRequestForAnswerNotification?.delayDays}日後に通知が行われます。`;
        } else if (timing === "MINUTES_AGO") {
          text += `${onnEvent.timingForRequestForAnswerNotification?.delayMinutes}分後に通知が行われます。`;
        }
      } else {
        text = `${deliveredCount}名の候補者に${interviewOrBriefing}を配信・通知しました`;
      }

      enqueueSnackbar(text, {
        variant: "success",
      });
    },
    [enqueueSnackbar]
  );

  return { enqueueSuccessSnackbar };
};
