import { OnnEvent, generatePagePath } from "@onn/common";

import React from "react";
import { useNavigate } from "react-router-dom";

import { useOnClickDeleteButton } from "../../../common/hooks/useOnClickDeleteButton";
import { useOnClickSlotDefaultValueSetting } from "../../../common/hooks/useOnClickSlotDefaultValueSetting";

import { UncontrolledMenu, IconButton } from "~/components/uiParts";

// ３点ドットメニューセル
export const ThreeDotMenu = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const navigation = useNavigate();

  const { onClickSlotDefaultValueSetting } = useOnClickSlotDefaultValueSetting(onnEvent.id);
  const { onClickDeleteButton } = useOnClickDeleteButton({
    onnEventTitle: onnEvent.title,
    onnEventId: onnEvent.id,
  });

  const menuItemOptions = [
    {
      text: "編集",
      onClick: () => navigation(generatePagePath.onnEvent.edit(onnEvent.id, { from_page: "list" })),
    },
    {
      text: "共通設定",
      onClick: onClickSlotDefaultValueSetting,
    },
    {
      text: "配信設定",
      onClick: () =>
        navigation(generatePagePath.onnEvent.deliverySetting(onnEvent.id, { from_page: "list" })),
    },
    {
      text: "削除",
      onClick: onClickDeleteButton,
    },
  ];

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => {
        return <IconButton icon="menuVert" onClick={openMenu} />;
      }}
      menuItemOptions={menuItemOptions}
    />
  );
};
