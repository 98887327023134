import { AnyTriggerSetting } from "@onn/common";

import { useAddActionToForm } from "../../../hooks/scenarioForm/actions/useAddActionToForm";

import { useCreateActionFromInput } from "../ActionPopovers/common/useCreateActionFromInput";
import { ValidInputState } from "../ActionPopovers/inputStateSchema";

export const useApplyToScenarioForm = ({
  recruitmentStatusId,
  closePopover,
  triggerSetting,
}: {
  recruitmentStatusId: string;
  closePopover: () => void;
  triggerSetting: AnyTriggerSetting;
}) => {
  const { addActionToForm } = useAddActionToForm({
    recruitmentStatusId,
  });

  const { createActionFromInput } = useCreateActionFromInput();
  const applyToScenarioForm = async (validInputValue: ValidInputState) => {
    addActionToForm(
      createActionFromInput({
        validInputValue,
        triggerSetting,
      })
    );

    closePopover();
  };

  return { applyToScenarioForm };
};
