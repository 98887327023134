import { Box } from "@material-ui/core";
import {
  OnnEventEvaluationActionCondition,
  ChangeRecruitmentStatusActionSetting,
  OnnFormTaskResultActionCondition,
  SendContactMessageActionSetting,
  DeliverOnnEventActionSetting,
  DeliverOnnTaskActionSetting,
  EmployeeTagActionCondition,
} from "@onn/common";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

import { ActionCondition } from "../components/TriggerBlock/ActionList/Action/ActionCondition/ActionCondition";

import { ActionDataIdRendererForDeveloper } from "../components/TriggerBlock/ActionList/Action/ActionDataIdRendererForDeveloper";
import { ActionDescription } from "../components/TriggerBlock/ActionList/Action/ActionDescription/ActionDescription";

import { createDifferenceLabel } from "../components/TriggerBlock/ActionList/Action/utils/createDifferenceLabel";
import { ActionAreaMinHeight } from "../constant/constant";

import { TriggersApiResponse } from "~/pages/scenario/Main/type";
import { usePageContext } from "~/pages/scenario/pageContext";

type Props = {
  action: TriggersApiResponse[0]["actions"][0];
  triggerId: string;
  recruitmentStatusId: string;
};

export const ActionAreaForViewMode: FC<Props> = ({ triggerId, recruitmentStatusId, action }) => {
  const isActionRelatedDeletedStatus = useMemo(() => {
    switch (action.setting.type) {
      case ChangeRecruitmentStatusActionSetting.type:
        return action.setting.isDeletedRecruitmentStatus;
      case SendContactMessageActionSetting.type:
      case DeliverOnnEventActionSetting.type:
      case DeliverOnnTaskActionSetting.type:
        return false;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = action.setting;
        return false;
    }
  }, [action.setting]);

  const { condition } = action;
  const { renderActionCondition } = useRenderActionCondition(condition);

  const { isDeveloperMode } = usePageContext();
  const isExistCondition = !!renderActionCondition;

  return (
    <Root
      $isExistCondition={isExistCondition}
      $isDeveloperMode={isDeveloperMode}
      $isError={isActionRelatedDeletedStatus}
    >
      {isDeveloperMode && (
        <ActionDataIdRendererForDeveloper
          actionId={action.id}
          actionSettingId={action.setting.id}
          actionConditionId={action.condition?.id}
        />
      )}
      {renderActionCondition && renderActionCondition()}
      <ActionDescriptionArea
        action={action}
        isError={isActionRelatedDeletedStatus}
        triggerId={triggerId}
        recruitmentStatusId={recruitmentStatusId}
      />
    </Root>
  );
};

const useRenderActionCondition = (condition: Props["action"]["condition"]) => {
  if (!condition) return { renderActionCondition: undefined };

  const renderActionCondition = () => {
    let conditionDescription = "";

    const conditionType = condition.type;
    switch (conditionType) {
      case EmployeeTagActionCondition.type:
        conditionDescription = `「候補者のタグ」に「${condition.tagNames.join(
          ","
        )}」が設定されている`;
        break;
      case OnnEventEvaluationActionCondition.type:
        conditionDescription = `「${condition.onnEventTitle}」の評価が「${condition.rankLabels.join(
          ","
        )}」の場合`;
        break;
      case OnnFormTaskResultActionCondition.type:
        conditionDescription = `「${condition.questionLabel}」に「${condition.optionTitle}」と回答`;
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = conditionType;
        break;
    }

    return <ActionCondition conditionDescription={conditionDescription} />;
  };

  return { renderActionCondition };
};

const ActionDescriptionArea: FC<
  Props & {
    isError: boolean;
  }
> = (props) => {
  const { action, isError } = props;
  const setting = action.setting;
  const description = (() => {
    switch (setting.type) {
      case ChangeRecruitmentStatusActionSetting.type:
        // ex) 選考ステータスを「一次面接」に変更
        return `選考ステータスを「${setting.recruitmentStatusLabel}」に変更`;
      case SendContactMessageActionSetting.type:
        return "応募者にメッセージを送信";
      case DeliverOnnEventActionSetting.type:
        return `「${setting.onnEventTitle}」を付与・配信`;
      case DeliverOnnTaskActionSetting.type:
        return `「${setting.onnTaskTitle}」を付与・配信`;
      default:
        // eslint-disable-next-line no-case-declarations
        const _exhaustiveCheck: never = setting;
        return "";
    }
  })();

  const endLabel = createDifferenceLabel(action);

  return (
    <ActionDescription
      type={setting.type}
      description={description}
      endLabel={endLabel}
      isError={isError}
      isEditMode={false}
    />
  );
};

const Root = styled(Box)<{
  $isExistCondition: boolean;
  $isDeveloperMode: boolean;
  $isError: boolean;
}>`
  ${(props) =>
    !props.$isExistCondition &&
    !props.$isDeveloperMode &&
    `
      display: flex;
      align-items: center;
    `}
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isError ? props.theme.palette.secondary.light : props.theme.palette.grey[50]};
  min-height: ${ActionAreaMinHeight}px;
  padding: 8px;
`;
