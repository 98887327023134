import { Box, AppBar } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import theme from "~/config/theme";
import { useShouldSPView } from "~/hooks/shared/useShouldSPView";

type Props = {
  ButtonsArea: React.ReactNode;
};

export const ActionFooter: FC<Props> = ({ ButtonsArea }) => {
  const shouldSPView = useShouldSPView();

  return (
    <AppBar
      style={{
        bottom: 0,
        top: "auto",
        backgroundColor: theme.palette.background.paper,
        height: "78px",
      }}
    >
      <StyledBox
        display="flex"
        height="80px"
        alignItems="center"
        justifyContent="flex-end"
        mx="40px"
        my="16px"
        $isSP={shouldSPView}
      >
        <Box display="flex" height="46px">
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            height={"48px"}
            ml="24px"
          >
            {ButtonsArea}
          </Box>
        </Box>
      </StyledBox>
    </AppBar>
  );
};

const StyledBox = styled(Box)<{ $isSP: boolean }>`
  padding-left: ${(props) => {
    if (props.$isSP) {
      return 0;
    }

    return props.theme.isOpenSidebar
      ? props.theme.constants.SIDEBAR_OPEN_WIDTH
      : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
  }}px;
`;
