import { ContactMessageTemplate } from "@onn/common";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { Key as FolderKey } from "../contactMessageTemplateFolder/useContactMessageTemplateFolder";

import { Key } from "./useContactMessageTemplates";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * コンタクトメッセージテンプレートを更新するAPIを呼び出す関数を返す
 * @param mutateKeys
 */
export const useUpdateContactMessageTemplate = ({
  contactMessageTemplates,
  templatesRefetchKey,
  foldersRefetchKey,
}: {
  contactMessageTemplates: ContactMessageTemplate[];
  templatesRefetchKey: Key;
  foldersRefetchKey: FolderKey;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  /**
   *  コンタクトメッセージテンプレートを更新する
   */
  const updateContactMessageTemplate = useCallback(
    async (template: ContactMessageTemplate) => {
      try {
        const optimisticData = contactMessageTemplates.map((contactMessageTemplate) =>
          contactMessageTemplate.id === template.id ? template : contactMessageTemplate
        );

        await mutate(
          templatesRefetchKey,
          async () => {
            await apiClient.patch("/api/contact-message-templates", {
              id: template.id,
              ["title"]: template.title,
              ["text"]: template.text,
              ["folderId"]: template.folderId,
            });
            // Note: フォルダに紐つくテンプレート一覧も表示の対象なので、refetchして更新する必要がある
            if (template.folderId) {
              mutate(foldersRefetchKey);
            }
            return mutate(templatesRefetchKey);
          },
          {
            optimisticData,
          }
        );
        enqueueSnackbar("テンプレートが編集されました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("テンプレートの編集に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useUpdateContactMessageTemplate:updateContactMessageTemplate",
          },
        });
      }
    },
    [contactMessageTemplates, enqueueSnackbar, mutate, templatesRefetchKey, foldersRefetchKey]
  );

  return { updateContactMessageTemplate };
};
