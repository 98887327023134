import { APISchema } from "@onn/common";

import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { useMutateSpaceSettings } from "./useSpaceSettings";

import { apiClient } from "~/libs";
import { ApiResponseError } from "~/util";

type EndPoint = APISchema["/space_api/space-settings"]["PATCH"];

export const useUpdateSpaceSetting = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateSpaceSettings } = useMutateSpaceSettings();

  const updateSpaceSetting = useCallback(
    async ({
      targetSpaceId,
      shouldCancelDeliveryRegardlessOfScenario,
    }: {
      targetSpaceId: string;
      shouldCancelDeliveryRegardlessOfScenario: boolean;
    }) => {
      const body: EndPoint["body"] = {
        spaceSetting: {
          shouldCancelDeliveryRegardlessOfScenario,
        },
        targetSpaceId,
      };

      try {
        await apiClient.patch("/space_api/space-settings", body);
        mutateSpaceSettings();
        enqueueSnackbar("スペース設定を更新しました。", {
          variant: "success",
        });
      } catch (e) {
        if (e instanceof ApiResponseError && e.status === 400) {
          enqueueSnackbar(e.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar("スペース設定の更新に失敗しました。管理者までお問い合わせください。", {
            variant: "error",
          });
        }
      }
    },
    [enqueueSnackbar, mutateSpaceSettings]
  );

  return { updateSpaceSetting };
};
