import { Box, Menu } from "@material-ui/core";
import { CandidateDate } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { isEmpty } from "lodash";
import React, { useState, FC, useCallback, useMemo, memo } from "react";

import styled from "styled-components";

import { CAN_NOT_JOIN } from "../../../hooks/OnnEventAnswerResultTab/filter/useFilterByCandidateDates";

import { CandidateDateFilterFilterFilterSelectMenu } from "./CandidateDateFilterFilterFilterSelectMenu";

import { Button, Icon, IconButton, Typography } from "~/components/uiParts";

type Props = {
  selectedCandidateDateIds: string[];
  onSetSelectedCandidateDateIds: React.Dispatch<React.SetStateAction<string[]>>;
  candidateDates: CandidateDate[];
};

export const CandidateDateFilter: FC<Props> = memo(
  ({ selectedCandidateDateIds, onSetSelectedCandidateDateIds, candidateDates }) => {
    // メニューのオプション
    const menuOptions: Array<{ value: string; label: string }> = candidateDates
      .map((candidateDate) => {
        const dateStr = format(candidateDate.from, "MM/dd(eee)", { locale: ja });
        const fromTimeStr = format(candidateDate.from, "HH:mm", { locale: ja });
        const untilTimeStr = format(candidateDate.until, "HH:mm", { locale: ja });
        const label = `${dateStr}\n${fromTimeStr}〜${untilTimeStr}`;
        return {
          value: candidateDate.id,
          label,
        };
      })
      .concat({
        value: CAN_NOT_JOIN,
        label: "参加できる日程がない",
      });

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    /**
     * 選択されている実施日時フィルターの値に応じてラベルを表示する
     */
    const labelText = useMemo(() => {
      if (selectedCandidateDateIds.length === 0) return "選択してください";
      return selectedCandidateDateIds
        .map((id) => {
          if (id === CAN_NOT_JOIN) return "参加できる日程がない";
          const targetCandidateDate = candidateDates.find(
            (candidateDate) => candidateDate.id === id
          );
          if (!targetCandidateDate) return "";
          const dateStr = format(targetCandidateDate.from, "MM/dd(eee)", { locale: ja });
          const fromTimeStr = format(targetCandidateDate.from, "HH:mm", { locale: ja });
          const untilTimeStr = format(targetCandidateDate.until, "HH:mm", { locale: ja });
          return `${dateStr}\n${fromTimeStr}〜${untilTimeStr}`;
        })
        .join(", ");
    }, [candidateDates, selectedCandidateDateIds]);

    const handleClickCheckBox = useCallback(
      (candidateDateId: string) => {
        if (selectedCandidateDateIds.includes(candidateDateId)) {
          onSetSelectedCandidateDateIds((current) =>
            current.filter((cid) => cid !== candidateDateId)
          );
        } else {
          onSetSelectedCandidateDateIds((cid) => [...cid, candidateDateId]);
        }
      },
      [onSetSelectedCandidateDateIds, selectedCandidateDateIds]
    );

    const handleClickClear = useCallback(() => {
      onSetSelectedCandidateDateIds([]);
    }, [onSetSelectedCandidateDateIds]);

    const handleClickOpenMenu = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(e.currentTarget);
      },
      []
    );

    const handleClickCloseMenu = useCallback(() => {
      setAnchorEl(null);
    }, []);

    return (
      <>
        <Box position="relative">
          <Button
            onClick={handleClickOpenMenu}
            borderRadius="regular"
            variant="outlined"
            color="default"
            fullWidth
          >
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" noWrap>
                {labelText}
              </Typography>
              <StyledIcon
                icon="filter"
                size="sm"
                color="grey"
                $isEmpty={isEmpty(selectedCandidateDateIds)}
              />
            </Box>
          </Button>
          {/* Buttonのhoverも効いてしまうので内包しないようにする */}
          {!isEmpty(selectedCandidateDateIds) && (
            <StyledIconButton icon="close" size="sm" color="grey" onClick={handleClickClear} />
          )}
        </Box>
        <Menu
          key="statusFilter"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClickCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: -8, horizontal: "right" }}
        >
          <CandidateDateFilterFilterFilterSelectMenu
            menuOptions={menuOptions}
            selected={selectedCandidateDateIds}
            onClickCheckBox={handleClickCheckBox}
          />
        </Menu>
      </>
    );
  }
);

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;
