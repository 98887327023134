import { Box } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

import { Icon, Typography } from "~/components/uiParts";
import { Badge } from "~/components/uiParts/Badge";

type Props = {
  disabledContactRoomIcon: boolean;
  contactRoomId: string | undefined;
  unreadContactMessageCount: number | undefined;
};
export const NewGraduateContactRoom = ({
  disabledContactRoomIcon,
  contactRoomId,
  unreadContactMessageCount,
}: Props) => {
  return (
    <StyledBox>
      <StyledLink
        to={`/contact_rooms?contactRoomId=${contactRoomId}`}
        disabled={disabledContactRoomIcon}
      >
        <StyledBadge
          badgeContent={
            unreadContactMessageCount && unreadContactMessageCount > 0 ? (
              <Typography bold variant="caption">
                {unreadContactMessageCount}
              </Typography>
            ) : undefined
          }
          color="secondary"
        >
          <Icon
            icon="paperAirplane"
            color={disabledContactRoomIcon ? "lightGrey" : "primary"}
            size="md"
          />
        </StyledBadge>
      </StyledLink>
    </StyledBox>
  );
};
const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  flex-shrink: 0;
`;
const StyledLink = styled(Link)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const StyledBadge = styled(Badge)`
  & > .MuiBadge-badge {
    width: 19px;
    height: 13px;
    border-radius: 100vh;
  }
  & .MuiTypography-caption {
    font-size: 10px;
  }
`;
