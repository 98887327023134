import { AnyTriggerSetting } from "@onn/common";
import { useCallback } from "react";

import { ValidInputState } from "../inputStateSchema";

import { captureException } from "~/util";

export const useNotifyInconsistencyError = () => {
  const notifyInconsistencyError = useCallback(
    (triggerSetting: AnyTriggerSetting, validInputValue: ValidInputState) => {
      captureException({
        error: new Error("アクション条件とトリガー設定の不整合が発生しています"),
        tags: {
          type: "useApplyToScenarioForm",
        },
        extras: {
          triggerSetting,
          validInputValue,
        },
      });
    },
    []
  );

  return { notifyInconsistencyError };
};
