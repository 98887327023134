import { TenantSettingsRepository } from "~/infrastructure/api/tenantSettingsRepository";

const tenantSettingsRepository = new TenantSettingsRepository();

export class UpdateNotifyAdminsOfMessageFromUnknownUserUseCase {
  async execute(id: string, newAssigneeIds: string[]): Promise<void> {
    await tenantSettingsRepository.newUpdate(id, {
      notifyAdminsOfMessageFromUnknownUser: [...newAssigneeIds],
    });
  }
}
