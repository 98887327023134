import { EmployeeActiveLog } from "@onn/common";
import { useCallback } from "react";

export const useGenerateEmployeeIdToLatestEmployeeActiveLogMap = () => {
  const generateEmployeeIdToLatestEmployeeActiveLogMap = useCallback(
    ({ employeeActiveLogs }: { employeeActiveLogs: EmployeeActiveLog[] }) => {
      const latestEmployeeActiveLog = employeeActiveLogs.reduce((acc, cur) => {
        const latest = acc.get(cur.employeeId);
        if (!latest || latest.createdAt < cur.createdAt) {
          acc.set(cur.employeeId, cur);
        }
        return acc;
      }, new Map<string, EmployeeActiveLog>());

      return latestEmployeeActiveLog;
    },
    []
  );

  return { generateEmployeeIdToLatestEmployeeActiveLogMap };
};
