import { Box } from "@material-ui/core";
import { OnnFormTask, FormRevision } from "@onn/common";
import React, { FC } from "react";

import { useParams } from "react-router-dom";

import { ActionFooter } from "../_share_in_create_edit/components/ActionFooter";

import { OnnTaskOverViewFormUIMemo } from "../_share_in_create_edit/components/OnnTaskOverViewFormUIMemo";

import { AddQuestionButton } from "../_share_in_create_edit/components/parts/AddQuestionButton";

import { OnnFormTaskQuestions } from "./components/OnnFormTaskQuestions";
import { usePage } from "./hooks/usePage";

import { usePageCore } from "./hooks/usePageCore";

import { Loading, Typography } from "~/components/uiParts";
import { NotFound } from "~/pages/NotFound";

export const Page: FC = () => {
  const params = useParams();
  const onnTaskId = params.id;

  const {
    onnTask,
    isLoadingOnnTask,
    onnFormTaskAnswers,
    isLoadingOnnFormTaskAnswers,
    latestFormRevision,
    isLoadingFormRevision,
  } = usePage({
    onnTaskId,
  });

  if (isLoadingOnnTask || isLoadingOnnFormTaskAnswers || isLoadingFormRevision)
    return <Loading size="large" fullHeight />;
  if (!onnTask || !latestFormRevision) {
    return <NotFound />;
  }

  // 候補者に配信済みかどうか
  const isAlreadySentToSomeone = (() => {
    if (!onnTask.scheduledDate) return false;
    const onnFormTaskAnswersLength = onnFormTaskAnswers ? onnFormTaskAnswers.length : 0;
    if (onnTask.scheduledDate < new Date() && onnFormTaskAnswersLength > 0) return true;
    return false;
  })();

  return (
    <PageCore
      onnFormTask={onnTask}
      latestFormRevision={latestFormRevision}
      isAlreadySentToSomeone={isAlreadySentToSomeone}
    />
  );
};

const PageCore: FC<{
  onnFormTask: OnnFormTask;
  latestFormRevision: FormRevision;
  isAlreadySentToSomeone: boolean;
}> = ({ onnFormTask, latestFormRevision, isAlreadySentToSomeone }) => {
  const {
    onnFormTaskId,
    actionFooterHandler,
    reducer: { state, dispatch },
    isDisableSaveButton,
    isLoadingSaveButton,
    onnTaskTitleInput,
    onnTaskDescriptionInput,
    onChangeTaskType,
    onChangeIsAllowEditAnswer,
    onClickAddQuestion,
    actionFooterButtonsText,
  } = usePageCore({
    onnFormTask,
    latestFormRevision,
    isAlreadySentToSomeone,
  });

  return (
    <Box display="flex" justifyContent="center">
      <Box width="800px" mb="80px">
        <Typography variant="h4" bold style={{ marginBottom: "40px" }}>
          配信タスク編集
        </Typography>
        <OnnTaskOverViewFormUIMemo
          selectedOnnTaskType={"FORM_TASK"}
          onChangeTaskType={onChangeTaskType}
          onChangeTitle={onnTaskTitleInput.onChangeTitle}
          isAllowEditAnswer={state.isAllowEditAnswer}
          onChangeIsAllowEditAnswer={onChangeIsAllowEditAnswer}
          onnTaskTitleTextField={{
            value: onnTaskTitleInput.value,
            error: onnTaskTitleInput.error,
            helperText: onnTaskTitleInput.helperText,
          }}
          onChangeDescription={onnTaskDescriptionInput.onChangeDescription}
          onnTaskDescriptionTextField={{
            value: onnTaskDescriptionInput.value,
            error: onnTaskDescriptionInput.error,
            helperText: onnTaskDescriptionInput.helperText,
          }}
        />
        <Typography variant="body1" color="textPrimary" style={{ marginTop: "40px" }}>
          設問
        </Typography>
        <OnnFormTaskQuestions state={state} dispatch={dispatch} onnFormTaskId={onnFormTaskId} />
        <AddQuestionButton onClick={onClickAddQuestion} />
        <ActionFooter
          onClickCancel={actionFooterHandler.onClickCancel}
          onClickPreview={actionFooterHandler.onClickPreview}
          onClickSaveAndToNext={actionFooterHandler.onClickSaveAndToNext}
          isDisableSaveButton={isDisableSaveButton}
          isLoadingSaveButton={isLoadingSaveButton}
          saveButtonText={actionFooterButtonsText.saveButtonText}
        />
      </Box>
    </Box>
  );
};
