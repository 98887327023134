import { Box } from "@material-ui/core";

import React, { FC, useCallback } from "react";

import { ActionFooter as CommonActionFooter } from "../../components/ActionFooter";

import { useFormContext } from "../../hooks/scenarioForm/useScenarioForm";

import { Button } from "~/components/uiParts";
import { useEditScenario } from "~/hooks/scenario/useEditScenario";
import { usePageContext } from "~/pages/scenario/pageContext";

export const ActionFooterForEdit: FC = () => {
  const { scenarioId, isLocking, setMode, resetWarningRecruitmentStatuses } = usePageContext();
  const form = useFormContext();
  const { editScenario } = useEditScenario();

  const handleCancel = useCallback(() => {
    if (!form.formState.isDirty) {
      setMode("view");
      return;
    }

    if (window.confirm("変更内容を破棄しますか？")) {
      setMode("view");
      resetWarningRecruitmentStatuses();
      return;
    }
  }, [form.formState.isDirty, resetWarningRecruitmentStatuses, setMode]);

  const handleSubmit = form.handleSubmit(async (inputValue) => {
    await editScenario({ scenarioId, workflows: inputValue.body });
  });

  const isUpdateButtonDisabled =
    form.formState.isSubmitting || Object.keys(form.formState.errors).length > 0 || isLocking;

  return (
    <CommonActionFooter
      ButtonsArea={
        <>
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            height={"48px"}
            ml="24px"
          >
            <Button
              variant="text"
              borderRadius="circle"
              onClick={handleCancel}
              color="default"
              fullHeight
            >
              キャンセル
            </Button>
          </Box>
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            height={"48px"}
            ml="24px"
          >
            <Button
              variant="contained"
              borderRadius="circle"
              onClick={handleSubmit}
              color="primary"
              fullHeight
              disabled={isUpdateButtonDisabled}
              isLoading={form.formState.isSubmitting}
            >
              変更を保存
            </Button>
          </Box>
        </>
      }
    />
  );
};
