import { OnnEvent } from "../../OnnEvent/OnnEvent";
import { OnnEventAnswer } from "../../OnnEvent/OnnEventAnswer/OnnEventAnswer";
import { OnnEventDeterminedDate } from "../../OnnEvent/OnnEventDeterminedDate";
import { OnnEventSlotDate } from "../../OnnEvent/OnnEventSlotDate";

export type NextPlan =
  | {
      type: "OfferAcceptance";
      deadline: Date | null;
    }
  | {
      type: "EvaluationOfEvent";
      onnEvent: OnnEvent;
      onnEventSlotDate: OnnEventSlotDate | null;
    }
  | {
      type: "StartOfEvent";
      onnEvent: OnnEvent;
      onnEventAnswer: OnnEventAnswer;
      onnDeterminedDate: OnnEventDeterminedDate;
      onnEventSlotDate: OnnEventSlotDate | null;
    }
  | {
      type: "AnswerOfEvent";
      onnEvent: OnnEvent;
      onnEventAnswer: OnnEventAnswer | null;
    };

export const generateNextPlanDomain = (
  nextPlan: NextPlan | DeepExcludeMethods<NextPlan>
): NextPlan | null => {
  switch (nextPlan.type) {
    case "OfferAcceptance": {
      return {
        type: "OfferAcceptance",
        deadline: nextPlan.deadline ? new Date(nextPlan.deadline) : null,
      };
    }
    case "EvaluationOfEvent": {
      return {
        type: "EvaluationOfEvent",
        onnEvent: new OnnEvent(nextPlan.onnEvent),
        onnEventSlotDate: nextPlan.onnEventSlotDate
          ? new OnnEventSlotDate(nextPlan.onnEventSlotDate)
          : null,
      };
    }
    case "StartOfEvent": {
      return {
        type: "StartOfEvent",
        onnEvent: new OnnEvent(nextPlan.onnEvent),
        onnEventAnswer: new OnnEventAnswer(nextPlan.onnEventAnswer),
        onnDeterminedDate: new OnnEventDeterminedDate(nextPlan.onnDeterminedDate),
        onnEventSlotDate: nextPlan.onnEventSlotDate
          ? new OnnEventSlotDate(nextPlan.onnEventSlotDate)
          : null,
      };
    }
    case "AnswerOfEvent": {
      return {
        type: "AnswerOfEvent",
        onnEvent: new OnnEvent(nextPlan.onnEvent),
        onnEventAnswer: nextPlan.onnEventAnswer
          ? new OnnEventAnswer(nextPlan.onnEventAnswer)
          : null,
      };
    }
    default: {
      const _exhaustiveCheck: never = nextPlan;
      return null;
    }
  }
};
