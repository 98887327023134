import { Box } from "@material-ui/core";
import { CandidateDateWithNumberOfParticipants, OnnEvent } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";
import theme from "~/config/theme";

type Props = {
  onnEvent: OnnEvent;
  candidateDate: CandidateDateWithNumberOfParticipants;
};

export const CandidateDateOptionLabel: FC<Props> = ({ onnEvent, candidateDate }) => {
  const labelDate = `${format(candidateDate.from, "MM/dd(E) HH:mm", { locale: ja })}〜${format(
    candidateDate.until,
    "HH:mm"
  )}`;

  if (!onnEvent.hasCapacity()) {
    return (
      <Box display="flex" gridGap="16px" alignItems="center">
        <Typography variant="body2">{labelDate}</Typography>
      </Box>
    );
  }

  if (!candidateDate.canParticipate()) {
    return (
      <Box display="flex" gridGap="16px" alignItems="center">
        <Typography variant="body2">{labelDate}</Typography>
        <Box
          display="flex"
          p="4px 8px"
          alignItems="center"
          bgcolor={theme.palette.grey[50]}
          borderRadius="100px"
          height="21px"
        >
          <Typography variant="overline" color="textSecondary">
            定員締切
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box display="flex" gridGap="16px" alignItems="center">
      <Typography variant="body2">{labelDate}</Typography>
      <Box
        display="flex"
        p="4px 8px"
        gridGap="2px"
        alignItems="center"
        bgcolor={theme.palette.primary.light}
        borderRadius="100px"
        height="21px"
      >
        <Typography variant="overline" color="textSecondary">
          定員：
        </Typography>
        <Typography variant="overline" color="textPrimary">
          {candidateDate.capacity}名
        </Typography>
      </Box>
    </Box>
  );
};
