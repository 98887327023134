import { Box } from "@material-ui/core";
import { NewGraduateToDisplay } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { NewGraduateSelection } from "./NewGraduateSelection";

import { Button, Modal } from "~/components/uiParts";
import { useModal, useModalContentHeight } from "~/hooks/modal";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (employeeIds: string[]) => Promise<void>;
};

const MODAL_PADDING_TOP = 40;
const MODAL_PADDING_BOTTOM = 24;
const MODAL_MARGIN = 32;
export const NewGraduateInviteModal = ({ open, onCancel, onSubmit }: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<NewGraduateToDisplay[]>([]);

  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();
  const { handleModal } = useModal();

  const handleClickInvite = useCallback(async () => {
    setLoading(true);
    await onSubmit(selectedEmployees.map((employee) => employee.id))
      .then(() => {
        onCancel();
      })
      .finally(() => setLoading(false));
  }, [onCancel, onSubmit, selectedEmployees]);

  const handleClickGenerateRegistrationInvitationLink = useCallback(() => {
    handleModal({
      name: "generateRegistrationInvitationLinkModal",
      args: {},
    });
  }, [handleModal]);

  const { contentHeight } = useModalContentHeight({
    headerHeight: 32,
    footerHeight: 50 + 24, // hooterの高さ+上方向の余白
    sumOfVerticalPadding: MODAL_PADDING_TOP + MODAL_PADDING_BOTTOM,
    sumOfVerticalMargin: MODAL_MARGIN * 2, // 上下方向分なので2倍している
  });

  const selectionHeight = useMemo(() => {
    // NewGraduateSelection以外のコンテンツの高さ
    const otherContentsHeight = 36;
    return contentHeight - otherContentsHeight;
  }, [contentHeight]);

  return (
    <StyledModal
      open={open}
      title={isShowSpaceOnScreen(spaces) ? `招待｜${currentSpace.name}` : "招待"}
      titleHint="「未招待」または「招待済み未登録」状態に該当するユーザーが表示されています。「招待済み未登録」ユーザーにチェックつけた場合、招待が再送されます。"
      footer={
        <StyledButtonContainer>
          <Box>
            <Button
              borderRadius="regular"
              variant="outlined"
              color="primary"
              fullHeight
              onClick={handleClickGenerateRegistrationInvitationLink}
            >
              招待QR・URL発行
            </Button>
          </Box>
          <Box>
            <Button borderRadius="circle" variant="text" color="default" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              borderRadius="circle"
              variant="contained"
              color="primary"
              onClick={handleClickInvite}
              disabled={loading || isEmpty(selectedEmployees)}
            >
              招待を送信
            </Button>
          </Box>
        </StyledButtonContainer>
      }
      content={
        <>
          <NewGraduateSelection
            height={selectionHeight}
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
          />
        </>
      }
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    height: 100%;
    padding-bottom: ${MODAL_PADDING_BOTTOM}px;
  }
  footer {
    margin-top: 24px;
  }
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;
