import { Box } from "@material-ui/core";
import { OnnTask } from "@onn/common";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { first } from "lodash";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { OnnFormTaskRevisionDetails } from "./OnnFormTaskRevisionDetails";

import {
  Paper,
  Typography,
  Button,
  Checkbox,
  Tooltip,
  Icon,
  Divider,
  Loading,
  Linkify,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useFormRevisions } from "~/hooks/onnTask";
import { useOpenPortalOnnTaskPreview } from "~/hooks/openPortalPreview";

type Props = {
  onnTask: OnnTask;
};

export const OnnTaskContentContainer: FC<Props> = ({ onnTask }) => {
  const { currentUser } = useCurrentUser();
  const navigation = useNavigate();
  const { data: formRevisionsData, isLoading: isLoadingFormRevisions } = useFormRevisions({
    onnFormTaskId: onnTask.id,
    isOnlyLatest: true,
  });
  const formRevision = first(formRevisionsData?.formRevisions);
  const { openPortalOnnTaskPreview } = useOpenPortalOnnTaskPreview();

  const onClickEditOnnEvent = () => {
    navigation(`/onn_tasks/${onnTask.id}/edit/?from_page=detail`);
  };

  const onClickPreview = () => {
    openPortalOnnTaskPreview({
      onnFormTask: onnTask,
      questions:
        formRevision?.questions.map((question) => {
          if (question.type === "FILE") {
            return {
              ...question,
              sampleFilePathForPreview: question.sampleFilePath,
            };
          }
          return question;
        }) || [],
      formRevision,
    });
  };

  return (
    <EventContentPaper square={false}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gridGap="8px"
        height="32px"
      >
        <Typography variant="h3">{onnTask.title}</Typography>
        <StyledBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap="8px"
          height="32px"
        >
          <Button
            variant="text"
            borderRadius="regular"
            onClick={onClickPreview}
            color="primary"
            disabled={!formRevision}
            startIcon={
              <Icon icon="eye" color={!formRevision ? "lightGrey" : "primary"} size="md" />
            }
          >
            プレビュー
          </Button>
          {currentUser.isAdmin() && (
            <Button
              onClick={onClickEditOnnEvent}
              color="primary"
              variant="outlined"
              borderRadius="regular"
            >
              編集
            </Button>
          )}
        </StyledBox>
      </Box>
      <Box>
        <OnnTaskDescriptionBox>
          <Linkify>{onnTask.description}</Linkify>
        </OnnTaskDescriptionBox>
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap="12px">
        <Typography variant="body2" bold>
          タスクタイプ
        </Typography>
        <Typography variant="body1">回答フォーム</Typography>
        <Box height="24px" display="flex" alignItems="center">
          <StyledCheckBox checked={onnTask.isAllowEditAnswer} readOnly />
          <Box mr="8px">
            <Typography variant="caption" display="inline">
              回答の編集を許可する
            </Typography>
          </Box>
          <Tooltip
            title="配信されたフォームに回答することでタスクが完了します。編集を許可すると、候補者が回答後に回答を編集することが可能になります。"
            placement="top-start"
          >
            <Icon icon="help" size="ssm" color="grey" />
          </Tooltip>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap="12px">
        <Typography variant="body2" bold>
          配信タイミング
        </Typography>
        <Typography variant="body1">
          {onnTask.scheduledDate
            ? format(onnTask.scheduledDate, "yyyy/MM/dd HH:mm", { locale: ja })
            : "未設定"}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap="12px">
        <Typography variant="body2" bold>
          回答期限
        </Typography>
        <Typography variant="body1">
          {onnTask.deadlineDate
            ? format(onnTask.deadlineDate, "〜yyyy/MM/dd", { locale: ja })
            : "未設定"}
        </Typography>
        <Box height="24px" display="flex" alignItems="center">
          <StyledCheckBox checked={onnTask.canAnswerAfterDeadline} disabled />
          <Box mr="8px">
            <Typography variant="caption" display="inline">
              回答期限に設定されている日程の2週間後まで回答可能になります。
            </Typography>
          </Box>
          <Tooltip
            title="回答期限に設定されている日程の2週間後まで回答可能になります。"
            placement="top-start"
          >
            <Icon icon="help" size="ssm" color="grey" />
          </Tooltip>
        </Box>
      </Box>
      {isLoadingFormRevisions && <Loading size="large" />}
      {formRevision && (
        <>
          <Divider margin={0} />
          <Typography variant="h4">設問</Typography>
          <OnnFormTaskRevisionDetails onnFormTaskRevision={formRevision} />
        </>
      )}
    </EventContentPaper>
  );
};

const EventContentPaper = styled(Paper)`
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const OnnTaskDescriptionBox = styled(Box)`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 1px solid;
  color: ${(props) => props.theme.palette.grey[300]};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 4px;
  background-color: fff;
  white-space: pre-wrap;
`;

const StyledCheckBox = styled(Checkbox)`
  &.MuiIconButton-root {
    padding: 0;
    margin-right: 8px;
    cursor: default;
  }
`;

const StyledBox = styled(Box)`
  button {
    height: 32px;
  }
`;
