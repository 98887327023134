import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useCurrentUser } from "~/hooks/employee";
import { useTenant } from "~/hooks/tenant";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { chanelTalk } from "~/util";

export const ChanelTalk = (): null => {
  const { currentUser } = useCurrentUser();
  const { tenant } = useTenant();
  const { tenantSettings } = useTenantSettings();
  const location = useLocation();

  useEffect(() => {
    if (!tenant) return;
    if (!tenantSettings.features.channelTalk) return;
    if (!currentUser.isAdmin()) return;

    chanelTalk(currentUser.getName(), tenant.tenantName, location.pathname);
  }, [currentUser, location.pathname, tenant, tenantSettings.features.channelTalk]);

  return null;
};
