import {
  IContactMessageTemplateFolderSchema,
  contactMessageTemplateFolderSchema,
  NAME_MAX_LENGTH,
} from "./schema";

export class ContactMessageTemplateFolder implements IContactMessageTemplateFolderSchema {
  static maxNameLength = NAME_MAX_LENGTH;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly name: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ContactMessageTemplateFolderInput) {
    const parsedInit = contactMessageTemplateFolderSchema.parse(init);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.name = parsedInit.name;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static createNew(init: {
    tenantId: string;
    spaceId: string;
    name: string;
  }): ContactMessageTemplateFolder {
    return new ContactMessageTemplateFolder(init);
  }

  public update(
    updateParams: Pick<ContactMessageTemplateFolderInput, "name">
  ): ContactMessageTemplateFolder {
    return new ContactMessageTemplateFolder({
      ...this,
      ...updateParams,
      updatedAt: new Date(),
    });
  }
}

export type ContactMessageTemplateFolderType = ExcludeMethods<ContactMessageTemplateFolder>;
export type ContactMessageTemplateFolderInput = Partial<ContactMessageTemplateFolderType>;
