import { Theme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Add } from "./icons/Add";
import { Alert } from "./icons/Alert";
import { Analytics } from "./icons/Analytics";
import { ArrowDouble } from "./icons/ArrowDouble";
import { ArrowDown } from "./icons/ArrowDown";
import { ArrowDropDown } from "./icons/ArrowDropDown";
import { ArrowDropUp } from "./icons/ArrowDropUp";
import { ArrowLeft } from "./icons/ArrowLeft";
import { ArrowRight } from "./icons/ArrowRight";
import { At } from "./icons/At";
import { AttachmentFile } from "./icons/AttachmentFile";
import { Ban } from "./icons/Ban";
import { Bookmark } from "./icons/Bookmark";
import { BookmarkBorder } from "./icons/BookmarkBorder";
import { Calendar } from "./icons/Calendar";
import { Check } from "./icons/Check";
import { CheckCircle } from "./icons/CheckCircle";
import { CheckMarkFill } from "./icons/CheckMarkFill";
import { CheckOutlineCircle } from "./icons/CheckOutlineCircle";
import { ChevronDown } from "./icons/ChevronDown";
import { ChevronLeft } from "./icons/ChevronLeft";
import { ChevronRight } from "./icons/ChevronRight";
import { ChevronUp } from "./icons/ChevronUp";
import { Circle } from "./icons/Circle";
import { Clip } from "./icons/Clip";
import { Clock } from "./icons/Clock";
import { Close } from "./icons/Close";
import { CloudUpload } from "./icons/CloudUpload";
import { Code } from "./icons/Code";
import { Copy } from "./icons/Copy";
import { Cross } from "./icons/Cross";
import { Document } from "./icons/Document";
import { DoubleArrowRight } from "./icons/DoubleArrowRight";
import { Download } from "./icons/Download";
import { DropdownArrow } from "./icons/DropdownArrow";
import { Edit } from "./icons/Edit";
import { Email } from "./icons/Email";
import { EmojiSmile } from "./icons/EmojiSmile";
import { Error } from "./icons/Error";
import { Eye } from "./icons/Eye";
import { Feedback } from "./icons/Feedback";
import { File } from "./icons/File";
import { FilePdf } from "./icons/FilePdf";
import { Filter } from "./icons/Filter";
import { Form } from "./icons/Form";
import { GoBack } from "./icons/GoBack";
import { Grid } from "./icons/Grid";
import { GripHorizontal } from "./icons/GripHorizontal";
import { GripVertical } from "./icons/GripVertical";
import { Help } from "./icons/Help";
import { HorizontalBar } from "./icons/HorizontalBar";
import { Info } from "./icons/Info";
import { Line } from "./icons/Line";
import { LineChat } from "./icons/LineChat";
import { Link } from "./icons/Link";
import { List } from "./icons/List";
import { Location } from "./icons/Location";
import { Memo } from "./icons/Memo";
import { MenuHor } from "./icons/MenuHor";
import { MenuVert } from "./icons/MenuVert";
import { Navi } from "./icons/Navi";
import { Notice } from "./icons/Notice";
import { Onboarding } from "./icons/Onboarding";
import { OpenInNew } from "./icons/OpenInNew";
import { PaperAirplane } from "./icons/PaperAirplane";
import { Pencil } from "./icons/Pencil";
import { Photo } from "./icons/Photo";
import { Pin } from "./icons/Pin";
import { QRCode } from "./icons/QRCode";
import { Remove } from "./icons/Remove";
import { Search } from "./icons/Search";
import { Security } from "./icons/Security";
import { Send } from "./icons/Send";
import { Setting } from "./icons/Setting";
import { Slack } from "./icons/Slack";
import { SpeechBalloon } from "./icons/SpeechBalloon";
import { StatusChange } from "./icons/StatusChange";
import { Story } from "./icons/Story";
import { Suitcase } from "./icons/Suitcase";
import { Trash } from "./icons/Trash";
import { Triangle } from "./icons/Triangle";
import { UnVisibility } from "./icons/UnVisibility";
import { Upload } from "./icons/Upload";
import { User } from "./icons/User";
import { Visibility } from "./icons/Visibility";
import { Warning } from "./icons/Warning";

export const icons = {
  add: Add,
  alert: Alert,
  analytics: Analytics,
  arrowDouble: ArrowDouble,
  arrowDropDown: ArrowDropDown,
  arrowDropUp: ArrowDropUp,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  arrowDown: ArrowDown,
  at: At,
  attachmentFile: AttachmentFile,
  ban: Ban,
  bookmark: Bookmark,
  bookmarkBorder: BookmarkBorder,
  calendar: Calendar,
  check: Check,
  checkCircle: CheckCircle,
  checkOutlineCircle: CheckOutlineCircle,
  checkMarkFill: CheckMarkFill,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  circle: Circle,
  clip: Clip,
  clock: Clock,
  close: Close,
  cloudUpload: CloudUpload,
  code: Code,
  copy: Copy,
  cross: Cross,
  email: Email,
  emojiSmile: EmojiSmile,
  document: Document,
  doubleArrowRight: DoubleArrowRight,
  download: Download,
  dropdownArrow: DropdownArrow,
  edit: Edit,
  error: Error,
  eye: Eye,
  feedback: Feedback,
  file: File,
  filePdf: FilePdf,
  filter: Filter,
  form: Form,
  goBack: GoBack,
  grid: Grid,
  gripHorizontal: GripHorizontal,
  gripVertical: GripVertical,
  help: Help,
  horizontalBar: HorizontalBar,
  info: Info,
  line: Line,
  lineChat: LineChat,
  link: Link,
  list: List,
  location: Location,
  memo: Memo,
  menuHor: MenuHor,
  menuVert: MenuVert,
  navi: Navi,
  notice: Notice,
  onboarding: Onboarding,
  openInNew: OpenInNew,
  paperAirplane: PaperAirplane,
  pencil: Pencil,
  photo: Photo,
  pin: Pin,
  qrCode: QRCode,
  remove: Remove,
  search: Search,
  security: Security,
  send: Send,
  setting: Setting,
  slack: Slack,
  speechBalloon: SpeechBalloon,
  story: Story,
  suitcase: Suitcase,
  statusChange: StatusChange,
  trash: Trash,
  triangle: Triangle,
  unVisibility: UnVisibility,
  upload: Upload,
  user: User,
  visibility: Visibility,
  warning: Warning,
};

const SIZE = {
  vsm: 8,
  sm: 16,
  ssm: 20,
  md: 24,
  lg: 32,
  xl: 40,
  xxl: 80,
};

const COLOR = (theme: Theme) => {
  return {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    lightGrey: theme.palette.grey[200],
    grey300: theme.palette.grey[300],
    grey400: theme.palette.grey[400],
    grey500: theme.palette.grey[500],
    grey: theme.palette.text.secondary,
    darkGrey: theme.palette.grey[500],
    error: theme.palette.error.main,
    white: theme.palette.primary.contrastText,
    blue: theme.palette.blue.main,
    inherit: "currentColor",
  } as const;
};

export type IconType = keyof typeof icons;

type Props = {
  size?: keyof typeof SIZE;
  icon: keyof typeof icons;
  color: keyof ReturnType<typeof COLOR>;
  id?: string;
};

export const Icon = ({ size, icon, color, id, ...props }: Props): JSX.Element => {
  const IconComponent = icons[icon];
  return (
    <StyledWrapper color={color} size={size} {...props} id={id}>
      <IconComponent />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.svg<Pick<Props, "size" | "color">>`
  flex-shrink: 0;
  ${(props) => props.size && `height: ${SIZE[props.size]}px;`}
  ${(props) => props.size && `width: ${SIZE[props.size]}px;`}
  fill: ${(props) => COLOR(props.theme)[props.color]};
  stroke: ${(props) => COLOR(props.theme)[props.color]};
`;
