import { Box } from "@material-ui/core";
import { allLineStamps } from "@onn/common";
import React from "react";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "public" });

type Props = {
  variant: "left" | "right";
  stickerId: string;
};

export function MessageLineStamp({ variant, stickerId }: Props) {
  const stamp = allLineStamps.find((stamp) => stamp.stickerId === stickerId);

  if (!stamp) {
    return null;
  }

  return (
    <Box textAlign={variant}>
      <img
        src={fileAPIAdapter.getFileUrl(stamp.path)}
        width={160}
        height={160}
        alt={stamp.stickerId}
        style={{ objectFit: "cover" }}
      />
    </Box>
  );
}
