/**
 * searchString (検索文字列) を分割する
 * - "," or "、" or " " or "全角スペース" で区切り、トリムする
 * - 空白は排除される
 */
export const splitSearchString = (searchString: string) => {
  return searchString
    .split(/[,、 \u3000]/)
    .map((s) => s.trim())
    .filter((s) => s !== "");
};
