import { Employee } from "@onn/common";
import { useState } from "react";

export const useFirstAndLastNameInput = (employee: Employee) => {
  const defaultFirstName = employee.firstName;
  const defaultLastName = defaultFirstName && employee.lastName;
  const [firstName, setFirstName] = useState<string>(defaultFirstName);
  const [lastName, setLastName] = useState<string>(defaultLastName);
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [tooLongNameError, setTooLongNameError] = useState<boolean>(false);
  const isNewGraduate = employee.isNewGraduateEmployee();

  const onChangeFirstName = (value: string) => {
    setFirstName(value);
    const {
      reason: { isEmptyFirstName, isTooLongName },
    } = Employee.validateFullName({
      firstName: value,
      lastName,
      isNewGraduate,
    });
    setFirstNameError(isEmptyFirstName);
    setTooLongNameError(isTooLongName);
  };

  const onChangeLastName = (value: string) => {
    setLastName(value);
    const {
      reason: { isEmptyLastName, isTooLongName },
    } = Employee.validateFullName({ firstName, lastName: value, isNewGraduate });
    setLastNameError(isEmptyLastName);
    setTooLongNameError(isTooLongName);
  };

  return {
    onChangeFirstName,
    onChangeLastName,
    firstName,
    lastName,
    firstNameError,
    lastNameError,
    tooLongNameError,
  };
};
