import { useMemo } from "react";

export const SELECTABLE_START_HOUR = 8;
export const SELECTABLE_END_HOUR = 21;

const fiveMinuteIncrements = Array.from({ length: 60 / 5 }, (_, i) => i * 5);
const fifteenMinuteIncrements = Array.from({ length: 60 / 15 }, (_, i) => i * 15);

export const useSelectableTimeItems = (option?: {
  selectableMinutesRange?: number[] | "fiveMinuteIncrements" | "fifteenMinuteIncrements";
}) => {
  const selectableMinutesRange = useMemo(() => {
    if (option?.selectableMinutesRange === "fiveMinuteIncrements") {
      return fiveMinuteIncrements;
    }
    if (option?.selectableMinutesRange === "fifteenMinuteIncrements") {
      return fifteenMinuteIncrements;
    }
    return option?.selectableMinutesRange || fifteenMinuteIncrements;
  }, [option?.selectableMinutesRange]);

  const selectableTimeRange = useMemo(
    () =>
      Array.from(
        { length: SELECTABLE_END_HOUR - SELECTABLE_START_HOUR + 1 },
        (_, i) => i + SELECTABLE_START_HOUR
      ),
    []
  );

  const displayDeliveryTimeItems = useMemo(
    () =>
      selectableTimeRange.flatMap((hour, i) => {
        return selectableMinutesRange.flatMap((min, ii) => {
          if (i === selectableTimeRange.length - 1) {
            if (ii !== 0) return [];
          }
          return {
            value: hour + min / 60,
            name: `${("00" + hour).slice(-2)}:${("00" + min).slice(-2)}`, //19時ぴったりの時などに、19:0ではなく19:00と表示するため
          };
        });
      }),
    [selectableTimeRange, selectableMinutesRange]
  );

  // 開始時間セレクターの選択肢
  const fromTimeItems = useMemo(
    () => displayDeliveryTimeItems.filter((_, i) => i < displayDeliveryTimeItems.length - 1),
    [displayDeliveryTimeItems]
  );
  // 終了時間セレクターの選択肢
  const untilTimeItems = useMemo(
    () => displayDeliveryTimeItems.filter((_, i) => i !== 0),
    [displayDeliveryTimeItems]
  );

  // valueがnameと同じパターン(面談・選考イベントで使用)
  // 開始時間セレクターの選択肢
  const fromTimeItemsWithValueTypeString = useMemo(
    () =>
      displayDeliveryTimeItems.flatMap((v, i) => {
        if (i === displayDeliveryTimeItems.length - 1) return [];
        return {
          value: v.name,
          name: v.name,
        };
      }),
    [displayDeliveryTimeItems]
  );
  // 終了時間セレクターの選択肢
  const untilTimeItemsWithValueTypeString = useMemo(
    () =>
      displayDeliveryTimeItems.flatMap((v, i) => {
        if (i === 0) return [];

        return {
          value: v.name,
          name: v.name,
        };
      }),
    [displayDeliveryTimeItems]
  );

  return {
    fromTimeItems,
    untilTimeItems,
    fromTimeItemsWithValueTypeString,
    untilTimeItemsWithValueTypeString,
  };
};
