import { OnnEvent } from "@onn/common";
import React, { FC, useMemo } from "react";

import { useEditModeContext } from "../../EditModeContextProvider";

import { SelectForm } from "~/components/uiParts";

export const SelectorOfOnnEvent: FC<{
  value?: string;
  onChange: (value: OnnEvent["id"]) => void;
  errorMessage?: string;
}> = ({ onChange, value, errorMessage }) => {
  const { availableOnnEvents } = useEditModeContext();

  const menuItems = useMemo(
    () =>
      availableOnnEvents.map((onnEvent) => ({
        value: onnEvent.id,
        name: onnEvent.title,
      })),
    [availableOnnEvents]
  );

  return (
    <SelectForm
      selected={value || null}
      menuItems={menuItems}
      onChange={(event) => {
        onChange(event.target.value as string);
      }}
      errorText={errorMessage}
    />
  );
};
