import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC, memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getEventAnswerStatusType } from "./getEventAnswerStatusType";
import { EventAnswerStatusType } from "./type";

import { Icon } from "~/components/uiParts/Icon";
import { Typography } from "~/components/uiParts/Typography";
import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventsForPortal";

type Props = {
  onnEventData: OnnEventDataForPortal;
};

export const PortalOnnEventCard: FC<Props> = memo(({ onnEventData }) => {
  const { onnEvent, isAnswered } = onnEventData;

  const statusType = getEventAnswerStatusType({
    isAnswered,
    isNearDeadline: onnEvent.isNearDeadline(),
    isExpired: onnEvent.isExpired(),
    canAnswer: onnEvent.canAnswer(),
  });
  const { border, titleColor, captionColor, icon } = getCardStyle(statusType);

  return (
    <StyledLink to={`/portal/events/${onnEvent.id}`}>
      <StyledDiv $border={Boolean(border)}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" p={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="body2" color={titleColor}>
              {onnEvent.title}
            </Typography>

            {onnEvent.deadlineDate && (
              <Typography variant="caption" color={captionColor}>
                {`期日：〜${format(onnEvent.deadlineDate, "yyyy/MM/dd")}` +
                  (onnEvent.isExpired() ? " | 回答期限切れ" : "")}
              </Typography>
            )}
          </Box>

          {icon}
        </Box>
      </StyledDiv>
    </StyledLink>
  );
});

/**
 * onnEventの回答状況に応じてカードのstyleを決定する
 */
const getCardStyle = (statusType: EventAnswerStatusType) => {
  switch (statusType) {
    case "default":
      return {
        titleColor: "textPrimary",
        captionColor: "textSecondary",
        icon: <Icon size="sm" icon={"arrowRight"} color="primary" />,
        border: false,
      } as const;
    case "done":
      return {
        titleColor: "textPrimary",
        captionColor: "textSecondary",
        icon: <Icon size="md" icon={"checkMarkFill"} color="primary" />,
        border: true,
      } as const;
    case "danger":
      return {
        titleColor: "secondary",
        captionColor: "secondary",
        icon: <Icon size="sm" icon={"arrowRight"} color="secondary" />,
        border: false,
      } as const;
    case "overdue":
      return {
        titleColor: "textPrimary",
        captionColor: "textSecondary",
        icon: <Icon size="sm" icon={"arrowRight"} color="grey" />,
        border: false,
      } as const;
    default:
      throw new Error("Invalid status type");
  }
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledDiv = styled.div<{ $border: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.default};
  box-shadow: ${(props) => props.theme.shadows[10]};
  list-style-type: none;
  cursor: pointer;
  border-radius: 15px;
  ${(props) => props.$border && `border: 1px solid ${props.theme.palette.primary.main}`}
`;
