import { httpsCallable as httpsFunctionCallable, httpsCallableFromURL } from "firebase/functions";
import urlJoin from "url-join";

import { getSelectedSpaceId } from "../../libs/spaceIdFromStorage";

import { functions } from "~/config/firebase";
import { getStorageAccessors } from "~/util";

/**
 * cloud functionのURLを生成する
 * @param functionName
 * @returns url
 */
export const generateBaseUrl = (functionName: string) => {
  return process.env.NODE_ENV === "localhost"
    ? urlJoin("http://localhost:5001/onn-staging/asia-northeast1", functionName)
    : process.env.NODE_ENV === "preview"
    ? urlJoin(`${process.env.NGROK_ORIGIN_HTTP_80}/onn-staging/asia-northeast1`, functionName)
    : process.env.NODE_ENV === "production"
    ? urlJoin(`https://${functionName}-ry7zy2jhea-an.a.run.app`)
    : urlJoin(`https://${functionName}-rmxxoytrmq-an.a.run.app`);
};

const { retrieveValue } = getStorageAccessors(localStorage);

// onCall関数の場合headersに値をセットできないためbodyに詰めて送る
type DataAsHeader = {
  tenantId: string | null;
  spaceId: string | null;
};

export const functionOperator = {
  httpsCall: <RequestData = unknown, ResponseData = unknown>(
    functionName: string,
    data?: RequestData | null
  ) => {
    const tenantId = retrieveValue<string>("tenantId");
    const spaceId = tenantId ? getSelectedSpaceId(tenantId) : null;
    const requestData = data
      ? { ...data, tenantId, spaceId }
      : ({ tenantId, spaceId } as RequestData & DataAsHeader);

    return httpsFunctionCallable<RequestData, ResponseData>(functions, functionName)(requestData);
  },

  /**
   * 第二世代のCloudFunction関数
   */
  httpsCallFor2ndGen: <RequestData = unknown, ResponseData = unknown>(
    functionName: string,
    data?: RequestData | null
  ) => {
    const tenantId = retrieveValue<string>("tenantId");
    const spaceId = tenantId ? getSelectedSpaceId(tenantId) : null;
    const requestData = data
      ? { ...data, tenantId, spaceId }
      : ({ tenantId, spaceId } as RequestData & DataAsHeader);

    return httpsCallableFromURL<RequestData, ResponseData>(
      functions,
      generateBaseUrl(functionName)
    )(requestData);
  },
};
