import { AnswerOnnEventTriggerSetting } from "./AnswerOnnEventTriggerSetting";
import { AnswerOnnTaskTriggerSetting } from "./AnswerOnnTaskTriggerSetting";
import { ChangeRecruitmentStatusTriggerSetting } from "./ChangeRecruitmentStatusTriggerSetting";
import { ChangeStatusOfOnnEventTriggerSetting } from "./ChangeStatusOfOnnEventTriggerSetting";
import { EvaluateOnnEventTriggerSetting } from "./EvaluateOnnEventTriggerSetting";

export { AnswerOnnEventTriggerSetting } from "./AnswerOnnEventTriggerSetting";
export { AnswerOnnTaskTriggerSetting } from "./AnswerOnnTaskTriggerSetting";
export { ChangeRecruitmentStatusTriggerSetting } from "./ChangeRecruitmentStatusTriggerSetting";
export { ChangeStatusOfOnnEventTriggerSetting } from "./ChangeStatusOfOnnEventTriggerSetting";
export { EvaluateOnnEventTriggerSetting } from "./EvaluateOnnEventTriggerSetting";

export type { OnnEventChangeType } from "./ChangeStatusOfOnnEventTriggerSetting";

export { Trigger } from "../Trigger/Trigger";

export type AnyTriggerSetting =
  | AnswerOnnEventTriggerSetting
  | AnswerOnnTaskTriggerSetting
  | ChangeRecruitmentStatusTriggerSetting
  | EvaluateOnnEventTriggerSetting
  | ChangeStatusOfOnnEventTriggerSetting;

export type AnyTriggerSettingExcludeMethods =
  | ExcludeMethods<AnswerOnnEventTriggerSetting>
  | ExcludeMethods<AnswerOnnTaskTriggerSetting>
  | ExcludeMethods<ChangeRecruitmentStatusTriggerSetting>
  | ExcludeMethods<EvaluateOnnEventTriggerSetting>
  | ExcludeMethods<ChangeStatusOfOnnEventTriggerSetting>;

export const instantiateFromAnyTriggerSettingExcludeMethods = (
  anyTriggerSettingExcludeMethods: AnyTriggerSettingExcludeMethods
) => {
  switch (anyTriggerSettingExcludeMethods.type) {
    case "AnswerOnnEventTriggerSetting":
      return new AnswerOnnEventTriggerSetting(anyTriggerSettingExcludeMethods);
    case "AnswerOnnTaskTriggerSetting":
      return new AnswerOnnTaskTriggerSetting(anyTriggerSettingExcludeMethods);
    case "ChangeRecruitmentStatusTriggerSetting":
      return new ChangeRecruitmentStatusTriggerSetting(anyTriggerSettingExcludeMethods);
    case "EvaluateOnnEventTriggerSetting":
      return new EvaluateOnnEventTriggerSetting(anyTriggerSettingExcludeMethods);
    case "ChangeStatusOfOnnEventTriggerSetting":
      return new ChangeStatusOfOnnEventTriggerSetting(anyTriggerSettingExcludeMethods);
    default: {
      const _exhaustiveCheck: never = anyTriggerSettingExcludeMethods;
      return _exhaustiveCheck;
    }
  }
};

export const groupByTriggerSettingType = (triggerSettings: AnyTriggerSetting[]) => {
  const result: {
    answerOnnEventTriggerSettings: AnswerOnnEventTriggerSetting[];
    answerOnnTaskTriggerSettings: AnswerOnnTaskTriggerSetting[];
    changeRecruitmentStatusTriggerSettings: ChangeRecruitmentStatusTriggerSetting[];
    evaluateOnnEventTriggerSettings: EvaluateOnnEventTriggerSetting[];
    changeStatusOfOnnEventTriggerSettings: ChangeStatusOfOnnEventTriggerSetting[];
  } = {
    answerOnnEventTriggerSettings: [],
    answerOnnTaskTriggerSettings: [],
    changeRecruitmentStatusTriggerSettings: [],
    evaluateOnnEventTriggerSettings: [],
    changeStatusOfOnnEventTriggerSettings: [],
  };

  for (const triggerSetting of triggerSettings) {
    switch (triggerSetting.type) {
      case "AnswerOnnEventTriggerSetting":
        result.answerOnnEventTriggerSettings.push(triggerSetting);
        break;
      case "AnswerOnnTaskTriggerSetting":
        result.answerOnnTaskTriggerSettings.push(triggerSetting);
        break;
      case "ChangeRecruitmentStatusTriggerSetting":
        result.changeRecruitmentStatusTriggerSettings.push(triggerSetting);
        break;
      case "EvaluateOnnEventTriggerSetting":
        result.evaluateOnnEventTriggerSettings.push(triggerSetting);
        break;
      case "ChangeStatusOfOnnEventTriggerSetting":
        result.changeStatusOfOnnEventTriggerSettings.push(triggerSetting);
        break;
      default: {
        const _exhaustiveCheck: never = triggerSetting;
        return _exhaustiveCheck;
      }
    }
  }

  return result;
};
