import React, { FC } from "react";

import { AnswerStatus, AttendanceStatus } from "../types";

import { AnswerStatusCell } from "~/components/domains/onnEvents/AnswerStatusCell";
import { CellItem, StyleProps } from "~/components/uiParts";

export type OnClickChip = (arg: {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  attendanceStatus: AttendanceStatus;
  newGraduateId: string;
}) => void;

type Props = {
  answerStatus: AnswerStatus;
  onClickChip: OnClickChip;
  newGraduateId: string;
} & StyleProps;

export const StatusCell: FC<Props> = ({
  answerStatus,
  onClickChip,
  newGraduateId,
  ...styleProps
}) => {
  return (
    <CellItem {...styleProps}>
      <AnswerStatusCell
        status={answerStatus}
        openMenu={(event) => {
          // NOTE: 参加未登録・参加済み・不参加の場合のみメニューが表示される
          if (
            answerStatus === "absent_attendance" ||
            answerStatus === "registered_attendance" ||
            answerStatus === "unregistered_attendance_and_past"
          ) {
            const attendanceStatus = answerStatus;
            onClickChip({ attendanceStatus, event, newGraduateId });
          }
        }}
        type="new_interview"
      />
    </CellItem>
  );
};
