import { useCallback } from "react";

import { useCookie } from "../shared";
import { useCurrentTenantId } from "../tenant/useCurrentTenantId";

import { auth } from "~/config/firebase";
import { clearSpaceIdFromLSAndSS } from "~/libs/spaceIdFromStorage";

export const useSignOut = () => {
  const { resetCurrentTenantId, currentTenantId } = useCurrentTenantId();
  const { removeValue: removeCookie } = useCookie("employeeId");

  const signOut = useCallback(async () => {
    const isSignedIn = !!auth.currentUser;
    if (isSignedIn) {
      await auth.signOut();
    }

    resetCurrentTenantId();
    removeCookie();
    clearSpaceIdFromLSAndSS(currentTenantId);
  }, [currentTenantId, removeCookie, resetCurrentTenantId]);

  return { signOut };
};
