import { Box } from "@material-ui/core";
import { Employee, Role } from "@onn/common";
import React, { FC, useCallback } from "react";

import { MemberTable } from "~/components/domains/employees";
import { Button, Icon, SearchForm } from "~/components/uiParts";
import { useAllEmployees, useCreateAdminAccount, useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

type OrderType = "asc" | "desc";
type UserDataType = { email: string; role: Role };

type Props = {
  displayEmployees: Employee[];
  mutateEmployees: () => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  order: OrderType;
  setOrder: (order: OrderType) => void;
};

export const MemberTab: FC<Props> = ({
  displayEmployees,
  mutateEmployees,
  searchValue,
  setSearchValue,
  order,
  setOrder,
}) => {
  const { currentUser } = useCurrentUser();
  const { allEmployees } = useAllEmployees();

  const { handleModal } = useModal();
  const { createAdminAccount } = useCreateAdminAccount();

  const handleClickSortLastRefreshTime = () => {
    // orderをtoggleする
    setOrder(order === "desc" ? "asc" : "desc");
  };

  const handleClickAddAdminButton = useCallback(() => {
    handleModal({
      name: "addAdminModal",
      args: {
        currentUser,
        existedEmployees: allEmployees,
        onSubmit: async (userDataArray: UserDataType[]) => {
          await createAdminAccount(userDataArray).then(() => {
            mutateEmployees();
          });
        },
      },
    });
  }, [handleModal, currentUser, allEmployees, createAdminAccount, mutateEmployees]);

  return (
    <>
      <Box display="flex" alignItems="flex-end" justifyContent="end">
        <Box display="flex" gridColumnGap="40px" alignItems="flex-end">
          <SearchForm
            searchValue={searchValue}
            onSearchValue={setSearchValue}
            placeholder="ユーザー名・メールアドレスで検索"
            variant="standard"
          />
          <Button
            color="primary"
            variant="outlined"
            borderRadius="regular"
            startIcon={<Icon icon="add" color="primary" size="md" />}
            onClick={handleClickAddAdminButton}
          >
            管理者を追加
          </Button>
        </Box>
      </Box>

      <Box height="36px" />

      <MemberTable
        isLoading={false}
        employees={displayEmployees}
        mutateEmployees={mutateEmployees}
        order={order}
        onClickSortLastRefreshTime={handleClickSortLastRefreshTime}
      />
    </>
  );
};
