import { z } from "zod";

import { onnEventSchema } from "../../../../domain";

export const listCandidateDatesWithNumberOfParticipantsRequestSchema = z.object({
  employeeId: z.string(),
  onnEventId: onnEventSchema.shape.id,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IListCandidateDatesWithNumberOfParticipantsRequestSchema
  extends z.infer<typeof listCandidateDatesWithNumberOfParticipantsRequestSchema> {}
