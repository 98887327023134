import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { useSnackbar } from "../shared";

import { useAllOnnEvent } from "./useAllOnnEvents";
import { useUpdateOnnEvent } from "./useUpdateOnnEvent";

import { captureException } from "~/util";

export const useUpdateOnnEventAssignee = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const { mutate: mutateAllOnnEvents } = useAllOnnEvent();
  const { execUpdateOnnEvent } = useUpdateOnnEvent();

  const updateOnnEventAssignee = useCallback(
    async ({ onnEventId, employeeIds }: { onnEventId: string; employeeIds: string[] }) => {
      await execUpdateOnnEvent({
        onnEventId: onnEventId,
        updateObject: {
          assigneeIds: employeeIds,
        },
      })
        .then(async () => {
          enqueueSnackbar("担当者が変更されました", { variant: "success" });
          mutate(`retrieveOnnEvent-${onnEventId}`);
          mutateAllOnnEvents();
        })
        .catch((e) => {
          enqueueSnackbar("担当者の変更に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useUpdateOnnEventAssignee:updateOnnEventAssignee",
            },
          });
        });
    },
    [enqueueSnackbar, mutate, mutateAllOnnEvents, execUpdateOnnEvent]
  );

  return { updateOnnEventAssignee };
};
