import { Box } from "@material-ui/core";
import React from "react";

import { useContextOfOnnEventSlotSearchModal } from "../../../Context";

import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

const TARGET = undefined;

type Props = {
  index: number;
};
export const UndefinedConditionSelector = ({ index }: Props): JSX.Element => {
  const { onChangeTarget, onnEvent } = useContextOfOnnEventSlotSearchModal();
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gridGap={"16px"}>
      <Box flex={2}>
        <SelectTargetButton
          onnEventType={onnEvent.type}
          target={TARGET}
          onChange={(target) => onChangeTarget(index, target)}
        />
      </Box>
      <Box flex={10}></Box>
    </Box>
  );
};
