import { Box } from "@material-ui/core";
import { ContactMessageTemplate, ContactMessageTemplateFolder } from "@onn/common";
import React, { useMemo, FC } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { useContactMessageTemplateForm } from "./hooks/useContactMessageTemplateForm";

import {
  Button,
  Modal,
  SelectForm,
  TextFieldDeprecated,
  TextareaAutosize,
  Typography,
} from "~/components/uiParts";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";

type ModeType = "create" | "edit";

const titleTextMap = {
  create: "作成",
  edit: "編集",
} as const;

type Props = {
  open: boolean;
  onCancel: () => void;
  mode: ModeType;
  oldContactMessageTemplate?: ContactMessageTemplate;
  onSubmit: (newContactMessageTemplate: ContactMessageTemplate) => void;
  templateFolders: ContactMessageTemplateFolder[];
};

export const EditContactMessageTemplateModal: FC<Props> = ({
  open,
  onCancel,
  oldContactMessageTemplate,
  mode,
  onSubmit,
  templateFolders,
}) => {
  const {
    tenant: { tenantId },
  } = useTenant();
  const { currentSpace } = useCurrentSpace();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useContactMessageTemplateForm({
    oldContactMessageTemplate,
    tenantId,
    spaceId: currentSpace.id,
    onSubmit,
  });

  const content = useMemo(
    () => (
      <Box marginTop="16px">
        <Box marginBottom="32px">
          <Controller
            name="title"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextFieldDeprecated
                fullWidth
                autoFocus
                placeholder="テンプレートタイトル"
                maxTextCount={ContactMessageTemplate.titleMaxLength}
                error={!!error}
                helperText={error?.message}
                {...field}
                inputProps={{ style: { fontSize: 24 } }}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="text"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextareaAutosize
                fullWidth
                placeholder="テンプレートに登録するメッセージを入力してください"
                minRows={3}
                error={!!error}
                helperText={error?.message}
                {...field}
              />
            )}
          />
        </Box>
        <Box>
          <StyledTypography variant="body1" color="textPrimary" bold>
            フォルダ
          </StyledTypography>
          <Controller
            name="folderId"
            control={control}
            render={({ field }) => (
              <StyledSelectFolderForm
                selected={field.value || ""}
                menuItems={templateFolders.map((folder) => ({
                  value: folder.id,
                  name: folder.name,
                }))}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    ),
    [control, templateFolders]
  );

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit();
            onCancel();
          }}
          disabled={!isValid}
        >
          保存
        </Button>
      </StyledButtonContainer>
    ),
    [onCancel, isValid, handleSubmit]
  );

  return (
    <Modal
      title={`テンプレート${titleTextMap[mode]}`}
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledSelectFolderForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    margin-top: 8px;
    width: 212px;
  }
  .MuiTypography-root {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 16px;
  }
`;
