import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE INFORMATION SINGLE TYPE OPTION SCHEMA
/////////////////////////////////////////

export const EmployeeInformationSingleTypeOptionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  employeeInformationSingleSelectTypeFieldId: z.string(),
  label: z.string().trim().min(1, { message: "ラベルが短すぎます" }),
  order: z.number(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationSingleTypeOption = z.infer<
  typeof EmployeeInformationSingleTypeOptionSchema
>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION SINGLE TYPE OPTION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationSingleTypeOptionOptionalDefaultsSchema =
  EmployeeInformationSingleTypeOptionSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeInformationSingleTypeOptionOptionalDefaults = z.infer<
  typeof EmployeeInformationSingleTypeOptionOptionalDefaultsSchema
>;

export default EmployeeInformationSingleTypeOptionSchema;
