export const useCanAnswerAfterDeadlineInput = ({
  canAnswerAfterDeadline,
  initialValue,
}: {
  canAnswerAfterDeadline: boolean;
  initialValue?: boolean;
}) => {
  const isChanged = canAnswerAfterDeadline !== !!initialValue;

  return {
    canAnswerAfterDeadline,
    error: false,
    isChanged,
  };
};
