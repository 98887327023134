import React, { FC } from "react";

import { NoneCell } from "../../common/NoneCell";

import { Cell } from "./Cell";

import { Tooltip, UserIcon } from "~/components/uiParts";

export type AssigneeIconCellProps = {
  name: string;
  iconUrl?: string;
  isShowNoneCell: boolean;
};

export const AssigneeIconCell: FC<AssigneeIconCellProps> = ({ iconUrl, name, isShowNoneCell }) => {
  if (isShowNoneCell) {
    return <NoneCell />;
  }
  return (
    <Cell>
      <Tooltip arrow placement="bottom" title={name}>
        <UserIcon circular username={name} profileIconImageUrl={iconUrl} size={"small"} />
      </Tooltip>
    </Cell>
  );
};
