import { Box } from "@mui/material";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { RecruitmentStatusTable } from "./RecruitmentStatusTable/RecruitmentStatusTable";

import { Paper, Typography } from "~/components/uiParts";

type Props = {
  employee: NewGraduateToDisplay;
};

export const RecruitmentStatusArea: FC<Props> = ({ employee }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="body2" bold color="textPrimary">
        選考状況
      </Typography>
      <StyledPaper>
        <RecruitmentStatusTable employee={employee} />
      </StyledPaper>
    </Box>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px;

  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;
