import { Box } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import styled from "styled-components";

import { Notice, Typography } from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";
import paperBgImageBottom from "~/images/paper_background_bottom.svg";
import paperBgImageTop from "~/images/paper_background_top.svg";
import { mixin } from "~/util";

export const AlreadyExistLineAuth: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    enqueueSnackbar("ログイン方法の切り替えに失敗しました。", {
      variant: "error",
    });
  }, [enqueueSnackbar]);

  return (
    <StyledBox px="24px">
      <Notice title="すでに登録済みのLINEアカウント" showCloseAnnotation={false}>
        <StyledTypography variant="body1" color="textSecondary" align="center">
          このLINEアカウントに紐づくユーザーは既に存在しています。
          <br />
          お手数ですが、担当者までお問い合わせください。
        </StyledTypography>
        <StyledButtonContainer width="100%"></StyledButtonContainer>
      </Notice>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  position: absolute;
  left: 0;
  /* FIXME: 一時的に NotFound で背景表示。背景画像はルールを整理して場所を移す */
  background-image: url(${paperBgImageTop}), url(${paperBgImageBottom});
  background-repeat: no-repeat;
  background-position: right top, left bottom;
  background-size: 60%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 32px;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
