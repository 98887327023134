import React, { FC } from "react";

import { SpaceSettingsTable } from "./SpaceSettingsTable/SpaceSettingsTable";

import { Loading } from "~/components/uiParts";

import { useAllSpaces } from "~/hooks/space/useAllSpaces";
import { useSpaceSettings } from "~/hooks/space/useSpaceSettings";

export const SpaceSettingsTab: FC = () => {
  const { data: spaces = [], isLoading: isLoadingSpaces } = useAllSpaces();
  const { data: spaceSettings = [], isLoading: isLoadingSpaceSettings } = useSpaceSettings();
  const spacesWithSetting = spaces.map((space) => {
    return {
      space,
      spaceSetting: spaceSettings.find((spaceSetting) => spaceSetting.spaceId === space.id),
    };
  });

  const isLoading = isLoadingSpaces || isLoadingSpaceSettings;

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }
  return <SpaceSettingsTable spacesWithSetting={spacesWithSetting} />;
};
