import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/employee/get-line-access-token-by-authorize-code"]["POST"];
/**
 * LINEのOAuthの認可コードをもとにアクセストークンを返す
 */
export const useGetLineAccessTokenByAuthorizeCode = () => {
  const getLineAccessTokenByAuthorizeCode = useCallback(
    async ({ authorizeCode, tenantId }: { authorizeCode: string; tenantId?: string }) => {
      const requestBody: Endpoint["body"] = { authorizeCode, tenantId };
      try {
        const response = await apiClient.post(
          "/api/employee/get-line-access-token-by-authorize-code",
          requestBody
        );

        return { accessToken: response.data.accessToken, isSuccess: true };
      } catch (_e) {
        return { accessToken: "", isSuccess: false };
      }
    },
    []
  );

  return { getLineAccessTokenByAuthorizeCode };
};
