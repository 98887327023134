import { Box } from "@material-ui/core";
import { StaticDatePicker as StaticDatePickerOrigin } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ja from "date-fns/locale/ja";
import React, { FC } from "react";

import styled from "styled-components";

import { colors } from "~/config/theme";

// import { CalendarHeader } from "./parts/CalendarHeader";

type Props = {
  referenceDate: Date;
  value: Date | null;
  onChange: (date: Date | null) => void;
  shouldDisableDate?: (date: Date) => boolean;
};

export const StaticDatePicker: FC<Props> = ({
  referenceDate,
  value,
  onChange,
  shouldDisableDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
      <StyledStaticDatePickerOriginWrapper>
        <StaticDatePickerOrigin<Date>
          referenceDate={referenceDate}
          slots={{
            toolbar: undefined,
            // TODO: イベントの日程選択のデザインを修正する https://app.clickup.com/t/86enrbmgc
            // calendarHeader: (props) => <CalendarHeader {...props} />,
            actionBar: undefined,
          }}
          disablePast
          shouldDisableDate={shouldDisableDate}
          onChange={onChange}
          value={value}
          sx={{
            ".MuiDateCalendar-root": {
              maxHeight: "unset",
              height: "100%",
              width: "100%",
            },
            ".MuiPickersCalendarHeader-root": {
              marginBottom: "16px",
            },
            ".MuiDayCalendar-root": {
              display: "flex",
              flexDirection: "column",
              rowGap: "16px",
            },

            ".MuiDayCalendar-weekDayLabel": {
              height: "fit-content",
              fontWeight: 400,
              padding: "0px 12px",
              lineHeight: "24px",
              fontSize: "14px",
              color: colors.grey[500],
              ":first-of-type": {
                color: colors.secondary.main,
              },
              ":last-child": {
                color: colors.blue.main,
              },
            },
            ".MuiDayCalendar-monthContainer": {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              rowGap: "8px",
            },
            ".MuiPickersSlideTransition-root": {
              minHeight: "274px",
            },
            ".MuiDayCalendar-header": {
              paddingBottom: "16px",
              borderBottom: "1px solid #E5E5E5",
              justifyContent: "space-between",
            },
            ".MuiDayCalendar-weekContainer": {
              margin: 0,
              justifyContent: "space-between",
            },
            ".MuiPickersDay-root": {
              width: "38px",
              height: "39px",
              borderRadius: "8px",
              lineHeight: "24px",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: colors.primary.light,
              },
              "&:selected": {
                backgroundColor: colors.primary.main,
              },
              "&.Mui-selected": {
                backgroundColor: colors.primary.main,
                color: "white",
                padding: "10px",
                fontWeight: 700,
                lineHeight: "24px",
              },
              "&.MuiPickersDay-today": {
                border: "none",
              },
              "&.Mui-disabled": {
                color: colors.grey[100],
              },
            },
          }}
        />
      </StyledStaticDatePickerOriginWrapper>
    </LocalizationProvider>
  );
};

const StyledStaticDatePickerOriginWrapper = styled(Box)`
  .MuiDialogActions-root {
    display: none;
  }
`;
