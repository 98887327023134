import React, { FC } from "react";

export const AttachmentFile: FC = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_989_194)">
        <path
          d="M5.85714 21C5.34643 21 4.90923 20.8237 4.54554 20.4713C4.18185 20.1187 4 19.695 4 19.2V4.8C4 4.305 4.18185 3.88125 4.54554 3.52875C4.90923 3.17625 5.34643 3 5.85714 3H15.3571L20 7.5V19.2C20 19.695 19.8182 20.1187 19.4545 20.4713C19.0908 20.8237 18.6536 21 18.1429 21H5.85714ZM5.85714 19.2H18.1429V8.4H14.4286V4.8H5.85714V19.2ZM11.7143 17.625C12.7512 17.625 13.6295 17.2725 14.3491 16.5675C15.0688 15.8625 15.4286 15.015 15.4286 14.025V10.875H13.5714V14.025C13.5714 14.52 13.3935 14.9438 13.0375 15.2963C12.6815 15.6488 12.2405 15.825 11.7143 15.825C11.2036 15.825 10.7664 15.6488 10.4027 15.2963C10.039 14.9438 9.85714 14.52 9.85714 14.025V9.075C9.85714 8.94 9.90357 8.83125 9.99643 8.74875C10.0893 8.66625 10.1976 8.625 10.3214 8.625C10.4607 8.625 10.5729 8.66625 10.658 8.74875C10.7432 8.83125 10.7857 8.94 10.7857 9.075V14.25H12.6429V9.075C12.6429 8.445 12.4185 7.9125 11.9696 7.4775C11.5208 7.0425 10.9714 6.825 10.3214 6.825C9.67143 6.825 9.12202 7.0425 8.67321 7.4775C8.22441 7.9125 8 8.445 8 9.075V14.025C8 15.015 8.36369 15.8625 9.09107 16.5675C9.81845 17.2725 10.6929 17.625 11.7143 17.625Z"
          stroke="none"
        />
      </g>
    </svg>
  );
};
