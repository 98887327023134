import { Box } from "@material-ui/core";
import { OnnEvent, EmployeeTag } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC } from "react";
import styled from "styled-components";

import { Divider, Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

type UserDataType = { email: string; employeeTagIds: string[] };

type Props = {
  onnEvents: OnnEvent[];
  userDataArray: UserDataType[];
  selectedOnnEventIds: string[];
  employeeTags: EmployeeTag[];
  isSendEmail: boolean;
  setIsSendEmail: (isSendEmail: boolean) => void;
};

export const ConfirmStep: FC<Props> = ({
  onnEvents,
  userDataArray,
  selectedOnnEventIds,
  employeeTags,
  isSendEmail,
  setIsSendEmail,
}) => {
  return (
    <>
      <Box>
        <Typography variant="body1" color="textPrimary" style={{ lineHeight: 1 }}>
          入力・選択した内容を確認してください。
        </Typography>
        <Box mt="32px" display="flex" flexDirection="column">
          <Typography variant="body2" bold color="textSecondary" style={{ lineHeight: 1 }}>
            対象者情報
          </Typography>
        </Box>
        <StyledNewHiresBox>
          {userDataArray.map((userData, index) => {
            return (
              <>
                {index !== 0 && <Divider orientation="horizontal" margin={8} />}
                <Box key={index} display="flex" gridGap="32px">
                  <Box style={{ minWidth: "300px" }}>
                    <Typography noWrap style={{ lineHeight: "18px" }}>
                      {userData.email}
                    </Typography>
                  </Box>
                  <Box pr={3}>
                    <Typography noWrap style={{ lineHeight: "18px" }}>
                      {userData.employeeTagIds
                        .map((id) => {
                          const tag = employeeTags.find((v) => v.id === id)?.name;
                          return `${tag}`;
                        })
                        .join(", ")}
                    </Typography>
                  </Box>
                </Box>
              </>
            );
          })}
        </StyledNewHiresBox>
        {!isEmpty(selectedOnnEventIds) && (
          <>
            <Box mt="16px" display="flex" flexDirection="column">
              <Typography variant="body2" bold color="textSecondary" style={{ lineHeight: 1 }}>
                配信するイベント
              </Typography>
            </Box>
            <StyledBox>
              {onnEvents
                .filter((v) => selectedOnnEventIds.includes(v.id))
                .map((onnEvent) => {
                  return (
                    <StyledFormControlLabel
                      key={onnEvent.id}
                      control={<Checkbox checked={true} disableEffect disabled />}
                      label={
                        <Typography variant="body1" color="textPrimary" noWrap>
                          {onnEvent.title}
                        </Typography>
                      }
                      disableEffect
                    />
                  );
                })}
            </StyledBox>
          </>
        )}
      </Box>
      <Box
        mt="32px"
        gridGap="16px"
        textAlign="center"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <StyledFormControlLabel
          control={
            <Checkbox
              checked={isSendEmail}
              onChange={(v) => setIsSendEmail(v.target.checked)}
              style={{ marginRight: "8px", padding: "0px" }}
            />
          }
          label={
            <Typography variant="caption" color="textPrimary" noWrap>
              対象者に招待メールを自動送信する
            </Typography>
          }
        />
        <Typography variant="caption" color="textSecondary">
          ※あとから任意のタイミングで個別に招待を送信することも可能です。
        </Typography>
      </Box>
    </>
  );
};

const StyledNewHiresBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  &.MuiBox-root {
    padding: 16px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 155px;
  }
`;

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  &.MuiBox-root {
    padding: 12px 16px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 108px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 3px 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;
