import { zodResolver } from "@hookform/resolvers/zod";
import {
  BriefingSessionEvent,
  CandidateDate,
  NewInterviewEvent,
  OnnEvent,
  OnnEventDeterminedDate,
} from "@onn/common";

import { uniqBy } from "lodash";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

import {
  InputState,
  answerInterviewEventOnBehalfFormSchema,
} from "../answerInterviewEventOnBehalfFormSchema";

import { useHandleSubmit } from "./useHandleSubmit";
import { useSelectableNewGraduates } from "./useSelectableNewGraduates";

import { useSelectableOnnEventSlotDatesForDisplay } from "./useSelectableOnnEventSlotDatesForDisplay";

import { useAdmins, useCurrentUser } from "~/hooks/employee";

import { useSelectableTimeItems } from "~/hooks/onnEvent";
import { useBriefingSessionCategories } from "~/hooks/onnEvent/useBriefingSessionCategories";
import { useOnnEventPlaces } from "~/hooks/onnEventPlace/useOnnEventPlaces";

export const useAnswerNewInterviewEventOnBehalfForm = ({
  onnEvent,
  current: _current,
  onSubmit,
  fixedOption,
  defaultValues,
  slotDefaultValueSetting,
}: {
  current?: {
    onnEventDeterminedDate: OnnEventDeterminedDate;
    candidateDate: CandidateDate;
  };
  onnEvent: NewInterviewEvent | BriefingSessionEvent;
  onSubmit: () => void;
  fixedOption?: {
    selectedEmployeeId?: string;
    selectedSlotDateId?: string;
    selectedBriefingSessionCategoryId?: string;
  };
  defaultValues?: {
    onnEventSlotDateId?: string;
    briefingSessionCategoryId?: string;
  };
  slotDefaultValueSetting: OnnEvent["slotDefaultValueSetting"];
}) => {
  const { currentUser } = useCurrentUser();
  const { data: admins, isLoading: isLoadingAdmins } = useAdmins(currentUser.tenantId);
  const { data: onnEventPlaces = [], isLoading: isLoadingPlaces } = useOnnEventPlaces();
  const adminsMap = new Map((admins || []).map((employee) => [employee.id, employee]));
  const { data: briefingSessionCategories = [], isLoading: isLoadingBriefingSessionCategories } =
    useBriefingSessionCategories({ onnEventId: onnEvent.id });
  const briefingSessionCategoriesMap = new Map(
    briefingSessionCategories.map((category) => [category.id, category])
  );

  const {
    selectableNewGraduates,
    selectableNewGraduatesMap,
    isLoading: isLoadingSelectableNewGraduates,
  } = useSelectableNewGraduates({
    onnEventId: onnEvent.id,
    onnEventType: onnEvent.type,
    selectedEmployeeId: fixedOption?.selectedEmployeeId,
  });

  const form = useForm<InputState>({
    defaultValues: {
      slotSelectionType:
        fixedOption?.selectedSlotDateId || defaultValues?.onnEventSlotDateId
          ? "existed"
          : undefined,
      employeeId: fixedOption?.selectedEmployeeId,
      selectedOnnEventSlotDateId:
        fixedOption?.selectedSlotDateId || defaultValues?.onnEventSlotDateId,
      briefingSessionCategory:
        onnEvent.type === "briefing_session"
          ? {
              type: onnEvent.type,
              briefingSessionCategoryId:
                fixedOption?.selectedBriefingSessionCategoryId ||
                defaultValues?.briefingSessionCategoryId,
            }
          : {
              type: onnEvent.type,
              briefingSessionCategoryId: null,
            },
    },
    resolver: zodResolver(answerInterviewEventOnBehalfFormSchema),
  });

  const { handleSubmit, isSubmitting } = useHandleSubmit({
    form,
    onnEventId: onnEvent.id,
    onSubmit,
  });
  const {
    fromTimeItemsWithValueTypeString: fromTimeItems,
    untilTimeItemsWithValueTypeString: untilTimeItems,
  } = useSelectableTimeItems({
    selectableMinutesRange: "fiveMinuteIncrements",
  });

  const {
    selectableOnnEventSlotDatesForDisplay,
    selectableOnnEventSlotDatesForDisplayMap,
    isLoading: isLoadingSelectableOnnEventSlotDatesForDisplay,
  } = useSelectableOnnEventSlotDatesForDisplay({
    onnEventId: onnEvent.id,
    includeIdsRegardlessOfFull: [
      defaultValues?.onnEventSlotDateId || [],
      fixedOption?.selectedSlotDateId || [],
    ].flat(),
    briefingSessionCategoryId: form.watch("briefingSessionCategory.briefingSessionCategoryId"),
  });

  // 既存の予約枠から選択する時の選択肢
  const selectedOnnEventSlotDateOptions = uniqBy(
    selectableOnnEventSlotDatesForDisplay.map((date) => ({
      value: date.id,
      name: date.getTermText(),
    })),
    "name"
  );

  const latestSelectableOnnEventSlotDatesForDisplay = selectableOnnEventSlotDatesForDisplay.find(
    (date) => date.until >= new Date()
  );

  const hasError = Object.keys(form.formState.errors).length > 0;
  const isSubmitButtonDisabled =
    form.formState.isSubmitting ||
    hasError ||
    isSubmitting ||
    isLoadingSelectableOnnEventSlotDatesForDisplay ||
    isLoadingSelectableNewGraduates;
  const watchSelectedOnnEventSlotDateId = form.watch("selectedOnnEventSlotDateId");
  const selectedOnnEventSlotDate = selectableOnnEventSlotDatesForDisplayMap.get(
    watchSelectedOnnEventSlotDateId
  );

  const selectBriefingSessionCategoryId = useCallback(
    (briefingSessionCategoryId: string) => {
      // NOTE: setValueを用いるとフォームのバリデーションの検証が行われないのでshouldValidateを渡して明示的にバリデーションを行うようにしている
      form.setValue(
        "briefingSessionCategory.briefingSessionCategoryId",
        briefingSessionCategoryId,
        {
          shouldValidate: true,
        }
      );

      form.resetField("slotSelectionType");
      form.resetField("assigneeId", { defaultValue: null });
      form.resetField("subAssigneeIds", { defaultValue: [] });
      form.resetField("slotInfo.type", { defaultValue: null });

      // NOTE: イベントに紐づく予約枠のデフォルト値をフォームにセットする
      form.setValue(
        "slotInfo.online.description",
        slotDefaultValueSetting?.online?.description || ""
      );
      form.setValue("slotInfo.online.url", slotDefaultValueSetting?.online?.url || "");
      form.setValue(
        "slotInfo.offline.description",
        slotDefaultValueSetting?.offline?.description || ""
      );

      form.resetField("slotInfo.offline.postCode");
      form.resetField("slotInfo.offline.address");
      form.resetField("slotInfo.offline.location");
    },
    [
      form,
      slotDefaultValueSetting?.offline?.description,
      slotDefaultValueSetting?.online?.description,
      slotDefaultValueSetting?.online?.url,
    ]
  );

  const selectOnnEventSlotDate = useCallback(
    (selectedOnnEventSlotDateId: string) => {
      // NOTE: setValueを用いるとフォームのバリデーションの検証が行われないのでshouldValidateを渡して明示的にバリデーションを行うようにしている
      form.setValue("selectedOnnEventSlotDateId", selectedOnnEventSlotDateId, {
        shouldValidate: true,
      });
      const selectedOnnEventSlotDate = selectableOnnEventSlotDatesForDisplayMap.get(
        selectedOnnEventSlotDateId
      );

      if (!selectedOnnEventSlotDate) {
        return;
      }

      form.setValue("assigneeId", selectedOnnEventSlotDate.assigneeId);
      form.setValue("subAssigneeIds", selectedOnnEventSlotDate.subAssigneeIds);
      form.setValue("slotInfo.type", selectedOnnEventSlotDate.eventType);
      form.setValue("slotInfo.online.description", selectedOnnEventSlotDate.description);
      form.setValue("slotInfo.online.url", selectedOnnEventSlotDate.url || undefined);
      form.setValue("slotInfo.offline.description", selectedOnnEventSlotDate.description);
      form.setValue(
        "slotInfo.offline.postCode",
        selectedOnnEventSlotDate.eventAddressPostCode || ""
      );
      form.setValue("slotInfo.offline.address", selectedOnnEventSlotDate.eventAddressText || "");
      form.setValue("slotInfo.offline.location", selectedOnnEventSlotDate.eventPlaceId || "");
    },
    [form, selectableOnnEventSlotDatesForDisplayMap]
  );

  const changeSlotSelectionTypeExisted = useCallback(() => {
    // NOTE: setValueを用いるとフォームのバリデーションの検証が行われないのでshouldValidateを渡して明示的にバリデーションを行うようにしている
    form.setValue("slotSelectionType", "existed", {
      shouldValidate: true,
    });

    if (latestSelectableOnnEventSlotDatesForDisplay) {
      selectOnnEventSlotDate(latestSelectableOnnEventSlotDatesForDisplay.id);
    }
  }, [form, latestSelectableOnnEventSlotDatesForDisplay, selectOnnEventSlotDate]);

  const changeSlotSelectionTypeNew = useCallback(() => {
    // NOTE: setValueを用いるとフォームのバリデーションの検証が行われないのでshouldValidateを渡して明示的にバリデーションを行うようにしている
    form.setValue("slotSelectionType", "new", {
      shouldValidate: true,
    });

    form.resetField("assigneeId", { defaultValue: null });
    form.resetField("subAssigneeIds", { defaultValue: [] });
    form.resetField("slotInfo.type", { defaultValue: null });

    // NOTE: イベントに紐づく予約枠のデフォルト値をフォームにセットする
    form.setValue(
      "slotInfo.online.description",
      slotDefaultValueSetting?.online?.description || ""
    );
    form.setValue("slotInfo.online.url", slotDefaultValueSetting?.online?.url || "");
    form.setValue(
      "slotInfo.offline.description",
      slotDefaultValueSetting?.offline?.description || ""
    );

    form.resetField("slotInfo.offline.postCode");
    form.resetField("slotInfo.offline.address");
    form.resetField("slotInfo.offline.location");
  }, [
    form,
    slotDefaultValueSetting?.offline?.description,
    slotDefaultValueSetting?.online?.description,
    slotDefaultValueSetting?.online?.url,
  ]);

  const setCandidateDateUntilTimeAfterHalfHour = useCallback(
    (newFromTime: string) => {
      const targetIndex = fromTimeItems.findIndex((v) => v.value === newFromTime);

      // NOTE: 5分単位で区切られている要素のうち30分後の時間を取得したいのと、終了時間の選択肢は5分進んでいるので、足し引きしてtargetIndex + 5 で取得する
      const targetUntilTimeItem =
        untilTimeItems[Math.min(targetIndex + 5, fromTimeItems.length - 1)];

      if (!targetUntilTimeItem) return;

      form.setValue("slotTimeInfo.slotUntilString", targetUntilTimeItem.name);
    },
    [form, fromTimeItems, untilTimeItems]
  );

  const handleChangeCandidateDateFromTime = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const selectedFromTime = String(event.target.value);
      form.setValue("slotTimeInfo.slotFromTimeString", selectedFromTime);
      setCandidateDateUntilTimeAfterHalfHour(selectedFromTime);
      form.trigger([
        "slotTimeInfo.slotDate",
        "slotTimeInfo.slotFromTimeString",
        "slotTimeInfo.slotUntilString",
      ]);
    },
    [form, setCandidateDateUntilTimeAfterHalfHour]
  );

  const handleChangeCandidateDateUntilTime = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      form.setValue("slotTimeInfo.slotUntilString", String(event.target.value));
      form.trigger([
        "slotTimeInfo.slotDate",
        "slotTimeInfo.slotFromTimeString",
        "slotTimeInfo.slotUntilString",
      ]);
    },
    [form]
  );

  return {
    form,
    isLoadingSelectableNewGraduates,
    isLoadingAdmins,
    isLoadingPlaces,
    isSubmitButtonDisabled,
    isLoadingSelectableOnnEventSlotDatesForDisplay,
    admins,
    adminsMap,
    isLoadingBriefingSessionCategories,
    briefingSessionCategories,
    briefingSessionCategoriesMap,
    selectableOnnEventSlotDatesForDisplay,
    selectedOnnEventSlotDate,
    onnEventPlaces,
    selectableNewGraduates,
    selectableNewGraduatesMap,
    handleSubmit,
    isSubmitting,
    selectedOnnEventSlotDateOptions,
    fromTimeItems,
    untilTimeItems,
    handleChangeCandidateDateFromTime,
    handleChangeCandidateDateUntilTime,
    selectBriefingSessionCategoryId,
    selectOnnEventSlotDate,
    changeSlotSelectionTypeExisted,
    changeSlotSelectionTypeNew,
  };
};
