import { isAdminHostname } from "../util/isAdminHostname";

export const getTenantIdFromDomain = () => {
  if (isAdminHostname()) {
    return null;
  }

  switch (process.env.NODE_ENV) {
    case "localhost":
      return window.location.href.split(".")[0]?.replace("http://", ""); // e.g. http://tenantName.localhost:8080
    case "staging":
      return window.location.href.split(".")[1]?.replace("https://", ""); // e.g. https://staging.tenantName.onn-hr.com
    case "production":
      return window.location.href.split(".")[0]?.replace("https://", ""); // e.g. https://tenantName.onn-hr.com
    case "preview":
      return process.env.PREVIEW_TENANT_ID;
    case "lab":
      return window.location.href.split(".")[1]?.replace("https://", ""); // e.g. https://lab.tenantName.onn-hr.com
    default:
      return null;
  }
};
