import { Box } from "@mui/material";
import {
  AnyEmployeeInformationValue,
  EmployeeInformationDateTypeValue,
  EmployeeInformationGroupWithFieldAndOptions,
  EmployeeInformationMultipleSelectTypeValue,
  EmployeeInformationSingleSelectTypeValue,
  EmployeeInformationTextTypeValue,
} from "@onn/common";
import React, { FC, useMemo } from "react";

import { CustomInformationSettingsViewerAndEditorManagerRenderer } from "./parts/CustomInformationSettingsViewerAndEditorManagerRenderer";

import { EmployeeInformationFieldManageMenu } from "~/components/domains/employeeInformationSetting/EmployeeInformationFieldManageMenu";
import { Divider, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { EmployeeCustomInformationTable } from "~/pages/employeeInformationSetting/components/InformationSettingTables/components/EmployeeCustomInformationTable";

export const CustomInformationSettingTables: FC<{
  employeeId: string;
  groups: EmployeeInformationGroupWithFieldAndOptions[];
  values: AnyEmployeeInformationValue[];
  isLoadingEmployeeInformationGroupsOrValues: boolean;
}> = ({ employeeId, groups, values, isLoadingEmployeeInformationGroupsOrValues }) => {
  const { currentUser } = useCurrentUser();

  const employeeInformationValueMap = useMemo(() => {
    return values.reduce(
      (acc, current) => {
        return {
          ...acc,
          [current.type]: [...acc[current.type], current],
        };
      },
      {
        [EmployeeInformationTextTypeValue.type]: [] as EmployeeInformationTextTypeValue[],
        [EmployeeInformationSingleSelectTypeValue.type]:
          [] as EmployeeInformationSingleSelectTypeValue[],
        [EmployeeInformationMultipleSelectTypeValue.type]:
          [] as EmployeeInformationMultipleSelectTypeValue[],
        [EmployeeInformationDateTypeValue.type]: [] as EmployeeInformationDateTypeValue[],
      }
    );
  }, [values]);

  return (
    <Box display="flex" flexDirection="column" style={{ rowGap: "32px" }}>
      {groups.map(({ employeeInformationGroup, employeeInformationFieldWithOptions }) => {
        return (
          <Box
            key={employeeInformationGroup.id}
            display="flex"
            flexDirection="column"
            style={{ rowGap: "16px" }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" height={"40px"}>
              <Typography variant="body2" bold color="textPrimary">
                {employeeInformationGroup.label}
              </Typography>
              {currentUser.isAdmin() && (
                <EmployeeInformationFieldManageMenu
                  groups={groups}
                  employeeInformationGroup={employeeInformationGroup}
                  employeeInformationFieldWithOptions={employeeInformationFieldWithOptions}
                />
              )}
            </Box>
            {employeeInformationFieldWithOptions.length > 0 ? (
              <EmployeeCustomInformationTable
                fields={employeeInformationFieldWithOptions.map((field) => ({
                  fieldId: field.id,
                  fieldLabel: field.label,
                  renderRightCell: () => (
                    <CustomInformationSettingsViewerAndEditorManagerRenderer
                      employeeId={employeeId}
                      employeeInformationField={field}
                      employeeInformationValueMap={employeeInformationValueMap}
                    />
                  ),
                  type: field.type,
                }))}
                isLoadingData={isLoadingEmployeeInformationGroupsOrValues}
              />
            ) : (
              <NotExistFieldsSection />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export const NotExistFieldsSection: FC = () => {
  return (
    <Box>
      <Divider />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={"16px"}
        rowGap={"8px"}
      >
        <Typography variant="body2" color="textSecondary">
          グループに項目がまだ定義されていません。
        </Typography>
      </Box>
    </Box>
  );
};
