import React, { useMemo } from "react";

import { AutoSizer, ListRowRenderer } from "react-virtualized";

import { List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";
import { useWindowDimensions } from "~/hooks/useWindowDimensions";

const useVirtualizedList = ({ itemCount }: { itemCount: number }) => {
  const { windowDimensions } = useWindowDimensions();

  const virtualizedListRowHeight = 56;

  const virtualizedListHeight = useMemo(() => {
    // NOTE:
    // 配信対象一覧ペーパーの最大の高さがブラウザwindowの下端から40px上になるように
    // window.innerHeight から 580pxを引いた値をmaxHeightに設定しています。
    // （580pxは、配信対象一覧ペーパーの上部からブラウザの上端までの距離です。）
    const maxHeight = windowDimensions.height - 580;
    const sumOfItemHeight = itemCount * virtualizedListRowHeight;
    if (sumOfItemHeight > maxHeight) return maxHeight;
    return sumOfItemHeight;
  }, [itemCount, windowDimensions.height]);

  return { virtualizedListHeight, virtualizedListRowHeight };
};

export const DeliveredEmployeeVirtualizedList = ({
  itemCount,
  rowRenderer,
}: {
  itemCount: number;
  rowRenderer: ListRowRenderer;
}) => {
  const { virtualizedListHeight, virtualizedListRowHeight } = useVirtualizedList({ itemCount });
  return (
    <AutoSizer
      disableHeight // 子要素の高さが固定長であるため高さを計算しない
    >
      {(size) => (
        <VirtualizedList
          height={virtualizedListHeight}
          width={size.width}
          rowCount={itemCount}
          rowHeight={virtualizedListRowHeight}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};
