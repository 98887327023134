import { ListOnnEventAnswersRequest, ListOnnEventAnswersResponse } from "@onn/common";
import useSWR, { mutate } from "swr";

import { functionOperator } from "~/infrastructure/api/functionOperator";

const getKey = (onnEventId?: string) => {
  return onnEventId ? `listOnnEventAnswer-${onnEventId}` : null;
};

export const useOnnEventAnswers = ({ onnEventId }: { onnEventId: string | undefined }) => {
  return useSWR(getKey(onnEventId), async () => {
    const response = await functionOperator.httpsCallFor2ndGen<
      ReturnType<typeof ListOnnEventAnswersRequest.createRequestBody>,
      typeof ListOnnEventAnswersResponse.ResponseBody
    >(
      "onneventanswer",
      ListOnnEventAnswersRequest.createRequestBody({ onnEventId: onnEventId || "" })
    );
    return ListOnnEventAnswersResponse.convertUnixTimeToDate(response.data);
  });
};

export const mutateOnnEventAnswers = (onnEventId: string) => {
  mutate(getKey(onnEventId));
};
