import { generateLiffUrlString } from "@onn/common";
import { useCallback } from "react";
import { createSearchParams } from "react-router-dom";

import { useQuery } from "../shared/useQuery";

export const useLiffUrl = () => {
  const { query } = useQuery();

  const openLiff = useCallback(
    ({ path, liffId }: { path: string; liffId: string }) => {
      const url = generateLiffUrlString(liffId, path);
      window.location.replace(url + "?" + createSearchParams(query));
    },
    [query]
  );

  const createLiffUrl = useCallback(
    ({ path, liffId }: { path: string; liffId: string }) => {
      const url = generateLiffUrlString(liffId, path);
      return url + "?" + createSearchParams(query);
    },
    [query]
  );

  return { openLiff, createLiffUrl };
};
