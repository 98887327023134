import { OnnEvent } from "@onn/common";
import React, { FC } from "react";

import { useSearchParams } from "react-router-dom";

import { ForBriefingSession } from "./ForBriefingSession/page";
import { ForNormalAndInterview } from "./ForNormalAndInterview/page";

export const Page: FC = () => {
  const eventType = useOnnEventType();

  switch (eventType) {
    case "normal":
    case "new_interview":
      return <ForNormalAndInterview eventType={eventType} />;
    case "briefing_session":
      return <ForBriefingSession />;
    default:
      // eslint-disable-next-line no-case-declarations
      const _exhaustiveCheck: never = eventType;
      return _exhaustiveCheck;
  }
};

/**
 * クエリパラメータを元に作成するイベントの種類を決める
 */
const useOnnEventType = (): OnnEvent["type"] => {
  const [searchParams] = useSearchParams();
  const eventTypeInSearchParam = searchParams.get("eventType");

  switch (eventTypeInSearchParam) {
    case "normal":
      return "normal" as const;
    case "new_interview":
      return "new_interview" as const;
    case "briefing_session":
      return "briefing_session" as const;
    default:
      return "normal" as const;
  }
};
