import React, { FC, memo, useCallback } from "react";

import { DraggableProvidedDragHandleProps, DropResult } from "react-beautiful-dnd";
import { v4 } from "uuid";

import { CheckBoxQuestionFormUIMemo } from "../../_share_in_create_edit/components/forms/CheckBoxQuestionFormUIMemo";
import { Action } from "../../_share_in_create_edit/reducer";

import { CHECK_BOX_QUESTION_FOR_FORM } from "../../_share_in_create_edit/types";

import { useQuestionFormBase } from "./useQuestionFormBase";

export type Props = {
  checkBoxQuestion: CHECK_BOX_QUESTION_FOR_FORM;
  dispatch: React.Dispatch<Action>;
  questionDragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

const useCheckBoxQuestionForm = ({
  dispatch,
  checkBoxQuestion: question,
}: {
  dispatch: React.Dispatch<Action>;
  checkBoxQuestion: CHECK_BOX_QUESTION_FOR_FORM;
}) => {
  const {
    onChangeQuestionOptionType,
    onChangeTitle,
    onChangeIsRequired,
    deleteQuestion,
    onChangeOptionOrder,
  } = useQuestionFormBase({
    question,
    dispatch,
  });

  const onClickDuplication = () => {
    dispatch({
      type: "DUPLICATION_QUESTION",
      payload: {
        question: {
          type: "CHECK_BOX",
          title: question.title,
          isRequired: question.isRequired,
          id: v4(),
          options: [...question.options],
          allowFreeText: question.allowFreeText,
          disabledTitle: false,
          disabledOptionType: false,
          isDirtyTitle: question.isDirtyTitle,
          isTitleError: question.isTitleError,
          titleHelperText: question.titleHelperText,
        },
        fromQuestionId: question.id,
      },
    });
  };

  const handleAddOption = () => {
    dispatch({
      type: "CHECKBOX_QUESTION_ADD_OPTION",
      payload: {
        questionId: question.id,
        optionId: v4(),
      },
    });
  };

  const handleOnChangeOptionLabel = (id: string, label: string) => {
    dispatch({
      type: "CHECKBOX_QUESTION_UPDATE_OPTION",
      payload: {
        questionId: question.id,
        optionId: id,
        text: label,
      },
    });
  };

  const handleDeleteOption = (id: string) => {
    dispatch({
      type: "CHECKBOX_QUESTION_DELETE_OPTION",
      payload: {
        questionId: question.id,
        optionId: id,
      },
    });
  };

  const onChangeOptionsOrder = useCallback(
    (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { index: sourceIndex } = result.source;
      const { index: destinationIndex } = result.destination;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      onChangeOptionOrder({
        sourceIndex,
        destinationIndex,
      });
    },
    [onChangeOptionOrder]
  );

  return {
    onChangeQuestionOptionType,
    onChangeTitle,
    onChangeIsRequired,
    handleAddOption,
    deleteQuestion,
    onClickDuplication,
    handleDeleteOption,
    handleOnChangeOptionLabel,
    onChangeOptionsOrder,
  };
};

export const CheckBoxQuestionForm: FC<Props> = ({
  dispatch,
  checkBoxQuestion: question,
  questionDragHandleProps,
}) => {
  const {
    onChangeQuestionOptionType,
    onChangeTitle,
    handleAddOption,
    onChangeIsRequired,
    deleteQuestion,
    onClickDuplication,
    handleDeleteOption,
    handleOnChangeOptionLabel,
    onChangeOptionsOrder,
  } = useCheckBoxQuestionForm({
    dispatch,
    checkBoxQuestion: question,
  });

  return (
    <CheckBoxQuestionFormUIMemo
      isRequired={question.isRequired}
      options={question.options}
      onChangeTitle={onChangeTitle}
      onChangeOptionType={onChangeQuestionOptionType}
      onClickAddOption={handleAddOption}
      onChangeIsRequired={onChangeIsRequired}
      onClickDelete={deleteQuestion}
      onClickDuplication={onClickDuplication}
      onClickDeleteOption={handleDeleteOption}
      onChangeOptionLabel={handleOnChangeOptionLabel}
      onChangeOptionsOrder={onChangeOptionsOrder}
      questionDragHandleProps={questionDragHandleProps}
      titleTextField={{
        value: question.title,
        helperText: question.titleHelperText,
        error: question.isTitleError,
      }}
    />
  );
};

// TODO: 「その他」を実装
// TODO: hover時のバックグラウンドのborderをbuttonのものに寄せる
// const AddOtherButton = styled(Typography)`
//   color: ${(props) => props.theme.palette.primary.main};
//   cursor: pointer;

//   &.MuiTypography-root {
//     padding-right: 8px;
//     padding-top: 4px;
//     padding-bottom: 4px;

//     &:hover {
//       background-color: ${(props) => props.theme.palette.grey[50]};
//     }
//   }
// `;

export const CheckBoxQuestionFormMemo = memo<Props>(CheckBoxQuestionForm);
