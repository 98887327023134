import { Box, TextareaAutosize as MuiTextareaAutosize } from "@material-ui/core";
import React, { ComponentProps, FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts/Typography";

type CustomProps = {
  value?: string;
  error?: boolean;
  isDisplayCount?: boolean;
  maxTextCount?: number;
  fullWidth?: boolean;
  defaultValue?: string;
  helperText?: React.ReactNode;
};

type Props = ComponentProps<typeof MuiTextareaAutosize> & CustomProps;

// 特に指定しない場合はmaxRows=16
// NOTE: なぜかref型がエラーになるため、anyで回避
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextareaAutosize: FC<Props & { ref?: any }> = React.forwardRef<
  HTMLTextAreaElement,
  Props
>(({ value = "", error, maxRows = 16, isDisplayCount, maxTextCount, fullWidth, ...props }, ref) => {
  const isDisplayCounter = isDisplayCount || typeof maxTextCount !== "undefined";

  return (
    <StyledBox width={fullWidth ? "100%" : "auto"}>
      <StyledRelativeBox>
        <StyledMuiTextareaAutosize
          {...props}
          value={value}
          $error={error}
          $fullWidth={fullWidth}
          $isDisplayCounter={isDisplayCounter || false}
          maxRows={maxRows}
          ref={ref}
          $isDisabled={props.disabled}
        />
        {/* maxTextCountを渡すことはカウントを表示することと同義のためこのような条件式になっている */}
        {isDisplayCounter && (
          <StyledMaxLengthWrapper>
            <StyledLengthTypography
              variant="caption"
              $length={value.length}
              $maxLength={maxTextCount}
            >
              {value ? value.length : ""}
            </StyledLengthTypography>
            <StyledMaxLengthTypography variant="caption">
              {maxTextCount && `/${maxTextCount}`}
            </StyledMaxLengthTypography>
          </StyledMaxLengthWrapper>
        )}
      </StyledRelativeBox>
      {props.helperText && (
        <StyledTypography variant="overline" color={error ? "error" : "textSecondary"}>
          {props.helperText}
        </StyledTypography>
      )}
    </StyledBox>
  );
});

const StyledRelativeBox = styled(Box)`
  position: relative;
  height: 100%;
`;

const StyledBox = styled(Box)`
  display: inline;
`;

const StyledMaxLengthWrapper = styled(Box)`
  position: absolute;
  right: 16px;
  bottom: 16px;
`;

const StyledLengthTypography = styled(Typography)<Props & { $length: number; $maxLength?: number }>`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) =>
    props.$maxLength && props.$length > props.$maxLength
      ? props.theme.palette.error.main
      : props.theme.palette.text.muted};
`;

const StyledMaxLengthTypography = styled(Typography)`
  /* FIX: Mui v5で color="text.muted" を typography に設定できる */
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledMuiTextareaAutosize = styled(MuiTextareaAutosize)<
  Props & {
    $fullWidth?: boolean;
    $error?: boolean;
    $isDisplayCounter?: boolean;
    $isDisabled?: boolean;
  }
>`
  width: ${(props) => (props.$fullWidth ? `100%;` : `inherit`)};
  color: ${(props) => props.theme.palette.text.primary};
  padding: ${(props) => (props.$isDisplayCounter ? "16px 16px 32px 16px" : "16px")};
  font-size: 16px;
  border: 1px solid
    ${(props) => (props.$error ? props.theme.palette.error.main : props.theme.palette.grey[200])};
  border-radius: 5px;
  resize: none;
  outline: none;
  white-space: pre-wrap;

  ::placeholder {
    color: ${(props) => props.theme.palette.grey[200]};
  }

  &:hover {
    border: 1px solid
      ${(props) =>
        props.$error
          ? props.theme.palette.error.main
          : props.$isDisabled
          ? props.theme.palette.action.disabled
          : props.theme.palette.action.active};
  }

  &:focus {
    border: 1px solid
      ${(props) =>
        props.$error ? props.theme.palette.error.main : props.theme.palette.primary.main};
    outline: 1px solid
      ${(props) =>
        props.$error ? props.theme.palette.error.main : props.theme.palette.primary.main};
  }
`;

const StyledTypography = styled(Typography)`
  display: block;
`;
