import { FormRevision } from "@onn/common";
import React, { FC, memo } from "react";

import { CheckBoxQuestionPart } from "./parts/CheckBoxQuestionPart";
import { FileQuestionPart } from "./parts/FileQuestionPart";
import { RadioQuestionPart } from "./parts/RadioQuestionPart";
import { TextQuestionPart } from "./parts/TextQuestionPart";

type Props = {
  onnFormTaskRevision: FormRevision;
};

export const OnnFormTaskRevisionDetails: FC<Props> = memo(({ onnFormTaskRevision }) => {
  return (
    <>
      {onnFormTaskRevision.questions.map((question) => {
        switch (question.type) {
          case "TEXT":
            return <TextQuestionPart question={question} />;
          case "FILE":
            return (
              <FileQuestionPart
                sampleFilePath={question.sampleFilePath}
                questionTitle={question.title}
                isRequired={question.isRequired}
              />
            );
          case "CHECK_BOX":
            return <CheckBoxQuestionPart question={question} />;
          case "RADIO":
            return <RadioQuestionPart question={question} />;
          default:
            break;
        }
      })}
    </>
  );
});
