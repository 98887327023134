import {
  Action,
  AnyActionCondition,
  AnyActionSetting,
  AnyTriggerSetting,
  ChangeRecruitmentStatusActionSetting,
  DeliverOnnEventActionSetting,
  DeliverOnnTaskActionSetting,
  SendContactMessageActionSetting,
} from "@onn/common";
import { useCallback } from "react";

import { ValidInputState } from "../inputStateSchema";

import { useConvertInputToActionCondition } from "./useConvertInputToActionCondition";
import { useConvertInputToActionTiming } from "./useConvertInputToActionTiming";
import { useEnqueueInconsistencyErrorSnackbar } from "./useEnqueueInconsistencyErrorSnackbar";
import { useNotifyInconsistencyError } from "./useNotifyInconsistencyError";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const useConvertInputToActionForUpdate = () => {
  const { currentSpace } = useCurrentSpace();

  const { enqueueInconsistencyErrorSnackbar } = useEnqueueInconsistencyErrorSnackbar();

  const { convertInputToActionTiming } = useConvertInputToActionTiming();

  const { convertInputToActionCondition } = useConvertInputToActionCondition();

  const { notifyInconsistencyError } = useNotifyInconsistencyError();

  const convertInputToActionForUpdate = useCallback(
    ({
      validInputValue,
      triggerSetting,
      existingActionData: {
        action: existingAction,
        actionSetting: existingAnyActionSetting,
        actionCondition: existingActionCondition,
      },
    }: {
      validInputValue: ValidInputState;
      triggerSetting: AnyTriggerSetting;
      existingActionData: {
        action: Action;
        actionSetting: AnyActionSetting;
        actionCondition?: AnyActionCondition;
      };
    }) => {
      const actionCondition = convertInputToActionCondition({
        actionConditionInput: validInputValue.actionCondition,
        actionId: existingAction.id,
        triggerSetting,
        existingActionCondition,
      });
      // NOTE: conditionTypeの指定があるのに、actionConditionが存在しない場合はトリガー設定が不整合なので、エラーを通知
      if (validInputValue.actionCondition.conditionType && actionCondition === undefined) {
        notifyInconsistencyError(triggerSetting, validInputValue);
        enqueueInconsistencyErrorSnackbar();
      }

      return {
        action: existingAction.update({
          ...convertInputToActionTiming(validInputValue.deliveryTiming),
        }),
        actionSetting: (() => {
          switch (validInputValue.actionSetting.actionType) {
            case "changeRecruitmentStatus": {
              if (existingAnyActionSetting.type === "ChangeRecruitmentStatusActionSetting") {
                return existingAnyActionSetting.update({
                  recruitmentStatusId: validInputValue.actionSetting.recruitmentStatusId,
                });
              } else {
                return ChangeRecruitmentStatusActionSetting.create({
                  tenantId: currentSpace.tenantId,
                  spaceId: currentSpace.id,
                  recruitmentStatusId: validInputValue.actionSetting.recruitmentStatusId,
                  actionId: existingAction.id,
                });
              }
            }
            case "deliveryOnnEvent": {
              if (existingAnyActionSetting.type === "DeliverOnnEventActionSetting") {
                return existingAnyActionSetting.update({
                  onnEventId: validInputValue.actionSetting.onnEventId,
                });
              } else {
                return DeliverOnnEventActionSetting.create({
                  tenantId: currentSpace.tenantId,
                  spaceId: currentSpace.id,
                  onnEventId: validInputValue.actionSetting.onnEventId,
                  actionId: existingAction.id,
                });
              }
            }
            case "deliveryOnnTask": {
              if (existingAnyActionSetting.type === "DeliverOnnTaskActionSetting") {
                return existingAnyActionSetting.update({
                  onnTaskId: validInputValue.actionSetting.onnTaskId,
                });
              } else {
                return DeliverOnnTaskActionSetting.create({
                  tenantId: currentSpace.tenantId,
                  spaceId: currentSpace.id,
                  onnTaskId: validInputValue.actionSetting.onnTaskId,
                  actionId: existingAction.id,
                });
              }
            }
            case "sendContactMessage": {
              if (existingAnyActionSetting.type === "SendContactMessageActionSetting") {
                return existingAnyActionSetting.update({
                  text: validInputValue.actionSetting.text,
                  filePaths: [],
                });
              } else {
                return SendContactMessageActionSetting.create({
                  tenantId: currentSpace.tenantId,
                  spaceId: currentSpace.id,
                  actionId: existingAction.id,
                  text: validInputValue.actionSetting.text,
                  filePaths: [],
                });
              }
            }
            default: {
              const _exhaustiveCheck: never = validInputValue.actionSetting;
              return _exhaustiveCheck;
            }
          }
        })(),
        actionCondition,
      };
    },
    [
      convertInputToActionCondition,
      convertInputToActionTiming,
      currentSpace.id,
      currentSpace.tenantId,
      enqueueInconsistencyErrorSnackbar,
      notifyInconsistencyError,
    ]
  );

  return { convertInputToActionForUpdate };
};
