import { compact } from "lodash";
import { v4 } from "uuid";

import { Answer } from "../Answer";

import { OnnTask } from "../OnnTask";

import { onnTaskAnswerStatusMap } from "./onnTaskAnswerStatusMap";
import { IOnnFormTaskAnswerSchema, onnFormTaskAnswerSchema } from "./schema";

export class OnnFormTaskAnswer implements IOnnFormTaskAnswerSchema {
  static readonly validator = onnFormTaskAnswerSchema;
  id: string;
  formTaskId: string;
  employeeId: string;
  tenantId: string;
  // 入社者がはじめに既読にした時刻
  firstReadAt: Date | null;
  // 入社者が既読にした時刻
  lastReadAt: Date | null;
  // 入社者が回答した時刻
  lastAnsweredAt: Date | null;

  // 表示するのは最新のrevision,回答したタイミングでidが固定される
  answeredFormRevisionId: string | null;
  answers: Answer[];
  createdAt: Date | null;
  updatedAt: Date | null;

  constructor(init: ExcludeMethods<OnnFormTaskAnswer>) {
    const parsedInit = OnnFormTaskAnswer.validator.parse(init);

    this.id = parsedInit.id;
    this.formTaskId = parsedInit.formTaskId;
    this.employeeId = parsedInit.employeeId;
    this.tenantId = parsedInit.tenantId;
    this.firstReadAt = parsedInit.firstReadAt;
    this.lastReadAt = parsedInit.lastReadAt;
    this.lastAnsweredAt = parsedInit.lastAnsweredAt;
    this.answeredFormRevisionId = parsedInit.answeredFormRevisionId;
    this.answers = parsedInit.answers;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static create(
    params: Optional<ExcludeMethods<OnnFormTaskAnswer>, "id" | "createdAt" | "updatedAt">
  ) {
    return new OnnFormTaskAnswer({
      ...params,
      id: params.id ?? v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public updateAnswer(update: { answeredFormRevisionId: string; answers: Answer[] }) {
    this.validate(update);

    this.answeredFormRevisionId = update.answeredFormRevisionId;
    this.answers = update.answers;
    this.lastAnsweredAt = new Date();
    this.updatedAt = new Date();
  }

  public read() {
    if (!this.firstReadAt) this.firstReadAt = new Date();
    this.lastReadAt = new Date();
  }

  public validate(update: Partial<OnnFormTaskAnswer>) {
    OnnFormTaskAnswer.validator.parse({ ...this, ...update });
  }

  public clone(): OnnFormTaskAnswer {
    return new OnnFormTaskAnswer(this);
  }

  /**
   * ファイル設問に対して回答されたファイルのパスを抽出する
   * ex) 設問1（ファイル設問）, 設問2（ファイル設問）, 設問3（ファイル設問） の設問がある場合
   *  設問1: ファイル1, 設問2: ファイル2, 設問3: 未回答 の場合、[ファイル1のパス, ファイル2のパス] を返す
   */
  public extractRelatedFilePaths(): string[] {
    // NOTE: 未回答の場合は、filePathに空文字が入るため、それを除外するためにcompactを使用
    return compact(
      this.answers.reduce<string[]>((acc, answer) => {
        if (answer.type === "FILE") {
          acc.push(answer.filePath);
        }
        return acc;
      }, [])
    );
  }

  isAnswered(): boolean {
    return !!this.answeredFormRevisionId;
  }

  /**
   * 回答ステータスを算出する
   */
  static calculateAnswerStatus({
    onnTask,
    onnFormTaskAnswer,
  }: {
    onnTask: OnnTask;
    onnFormTaskAnswer: OnnFormTaskAnswer;
  }): keyof typeof onnTaskAnswerStatusMap {
    if (onnFormTaskAnswer.isAnswered()) {
      return onnTaskAnswerStatusMap.answered;
    } else {
      if (onnTask.isExceededDeadlineDate()) {
        return onnTaskAnswerStatusMap.expired;
      } else if (onnTask.isExceededScheduledDate()) {
        return onnTaskAnswerStatusMap.notAnswered;
      } else {
        return onnTaskAnswerStatusMap.notDelivered;
      }
    }
  }
}
