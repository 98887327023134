import { isValidEmail } from "@onn/common";
import { useCallback } from "react";

export const useEmailInput = ({
  email,
  initialValue,
}: {
  email: string;
  initialValue?: string;
}) => {
  const getHelperText = useCallback((email: string) => {
    const trimedEmail = email.trim();

    if (trimedEmail.length === 0) {
      return "メールアドレスを入力してください";
    }

    if (!isValidEmail(email)) {
      return "メールアドレスの形式が間違っています";
    }

    return "";
  }, []);

  const isError = useCallback(
    (email: string) => {
      return !!getHelperText(email);
    },
    [getHelperText]
  );

  const isChanged = email !== (initialValue ?? "");

  return {
    email,
    error: isError(email),
    helperText: getHelperText(email),
    isChanged,
  };
};
