import { Announcement } from "@onn/common";
import { useCallback, useState } from "react";

import { useSnackbar } from "~/hooks/shared";
import { usePrivateFileAPIAdapter } from "~/hooks/shared/usePrivateFileAPIAdapter";

// NOTE: useGetFileLikeObjectsFromFilePathsとして共通化したので、もし改修する場合はそちらを使用するように修正する
const getFileLikeObjectsFromFilePaths = (filePaths: string[]) => {
  return filePaths.reduce((acc, filePath) => {
    const fileName = filePath.split("/").pop() ?? "";

    if (fileName) {
      acc.push({ name: fileName });
      return acc;
    }

    return acc;
  }, [] as Pick<File, "name">[]);
};

export const useInputs = ({
  announcement,
  fixedAnnouncementId,
  tenantId,
}:
  | {
      announcement?: Announcement;
      fixedAnnouncementId: string;
      tenantId: string;
    }
  | {
      announcement: Announcement;
      fixedAnnouncementId?: string;
      tenantId: string;
    }) => {
  const [title, setTitle] = useState(announcement ? announcement.title : "");
  const [content, setContent] = useState(announcement ? announcement.content : "");
  const [files, setFiles] = useState<(File | Pick<File, "name">)[]>(
    getFileLikeObjectsFromFilePaths(announcement ? announcement.filePaths : [])
  );

  const [isDirtyTitle, setIsDirtyTitle] = useState(false);
  const titleInputError = useTitleInputError({ title });
  const [isDirtyContent, setIsDirtyContent] = useState(false);
  const contentInputError = useContentInputError({ content });
  const [isDirtyFiles, setIsDirtyFiles] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { uploadFile } = usePrivateFileAPIAdapter();

  return {
    titleInput: {
      title,
      onChangeTitle: (v: string) => {
        setIsDirtyTitle(true);
        setTitle(v);
      },
      titleInputError,
      isDirtyTitle,
    },
    contentInput: {
      content,
      onChangeContent: (v: string) => {
        setIsDirtyContent(true);
        setContent(v);
      },
      contentInputError,
      isDirtyContent,
    },
    filesInput: {
      files,
      onChangeFiles: async (files: (File | Pick<File, "name">)[]) => {
        setIsDirtyFiles(true);
        const newFiles = files.filter((v): v is File => {
          return v instanceof File;
        });
        const existedFiles = files.filter((v): v is Pick<File, "name"> => {
          return !(v instanceof File);
        });
        const newFileNames = newFiles.map((v) => v.name);
        const existedFileNames = existedFiles.map((v) => v.name);
        const duplicatedFileNames = newFileNames.filter((v) => existedFileNames.includes(v));
        if (duplicatedFileNames.length > 0) {
          enqueueSnackbar(
            `${duplicatedFileNames.join(", ")}という名前のファイルは既に添付されています`,
            {
              variant: "error",
            }
          );
          return;
        }

        // TODO: もしかしたら移動するかも
        const uploadedFilePaths = await Promise.all(
          newFiles.map((v) =>
            uploadFile({
              path: `/tenants/${tenantId}/announcements/${
                announcement ? announcement.id : fixedAnnouncementId
              }/${v.name}`,
              file: v,
            })
          )
        );
        setFiles(files);

        return uploadedFilePaths;
      },
      isDirtyFiles,
    },
  };
};

const useTitleInputError = ({ title }: { title: string }) => {
  const getHelperText = useCallback((title: string) => {
    const trimedTitle = title.trim();
    if (!trimedTitle) {
      return "タイトルを記入してください";
    }

    return "";
  }, []);

  const isError = useCallback(
    (title: string) => {
      return !!getHelperText(title);
    },
    [getHelperText]
  );

  return {
    helperText: getHelperText(title),
    error: isError(title),
  };
};

const useContentInputError = ({ content }: { content: string }) => {
  const getHelperText = useCallback((content: string) => {
    const trimedContent = content.trim();
    if (!trimedContent) {
      return "説明を記入してください";
    }

    if (trimedContent.length > 5000) {
      return `説明は5000文字以内で記入してください (${trimedContent.length}/5000)`;
    }

    return "";
  }, []);

  const isError = useCallback(
    (content: string) => {
      return !!getHelperText(content);
    },
    [getHelperText]
  );

  return {
    helperText: getHelperText(content),
    error: isError(content),
  };
};
