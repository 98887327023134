import { Role } from "@onn/common";
import { useCallback } from "react";

type UserDataFormType = { email?: string; role?: Role };

/**
 * AddAdminModalの入力値が正しいかどうかを返す関数を提供するhooks
 */
export const useCheckUserDataArrayValid = () => {
  /**
   * AddAdminModalの入力値が正しいかどうかを返す関数
   * @param {UserDataFormType[]} userDataArray
   * @param {string[]} existedEmails
   * @returns boolean
   */
  const checkUserDataArrayValid = useCallback(
    (userDataArray: UserDataFormType[], existedEmails: string[]): boolean => {
      return userDataArray.every((userData) => {
        if (userData.email && existedEmails.includes(userData.email)) return false;

        return userData.email && userData.role;
      });
    },
    []
  );

  return { checkUserDataArrayValid };
};
