import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Icon, Loading, Tooltip, Typography } from "~/components/uiParts";

type Props = {
  richMenuUrl?: string;
  isLoading: boolean;
};

export const NewGraduateRichMenu: FC<Props> = ({ richMenuUrl, isLoading }) => {
  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px" position="relative">
      <Box display="flex" gridGap="4px" alignItems="center">
        <Typography variant="body2" bold color="textPrimary">
          リッチメニュー
        </Typography>
        <Tooltip
          title="LINEでログイン中の候補者に表示されているリッチメニューです。"
          placement="top-start"
        >
          <Icon icon="help" size="sm" color="grey" />
        </Tooltip>
      </Box>

      {isLoading ? (
        <Loading size="large" />
      ) : richMenuUrl ? (
        <img width="100%" src={richMenuUrl} alt="リッチメニュー" />
      ) : (
        <Typography variant="caption" color="textSecondary">
          リッチメニューが設定されていません
        </Typography>
      )}
    </Box>
  );
};
