import { v4 } from "uuid";

import { ICandidateDate, candidateDateSchema } from "./schema";

export class CandidateDate implements ICandidateDate {
  static validator = candidateDateSchema;

  readonly id: string;
  readonly from: Date;
  readonly until: Date;
  readonly capacity?: number;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<CandidateDate>) {
    const parsedInit = CandidateDate.validator.parse(init);

    this.id = parsedInit.id;
    this.from = parsedInit.from;
    this.until = parsedInit.until;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.capacity = parsedInit.capacity;
  }

  public update(update: Partial<ExcludeMethods<CandidateDate>>): CandidateDate {
    return new CandidateDate({
      ...this,
      ...update,
    });
  }

  /**
   * CandidateDateを新規作成するときに使うメソッド
   * @param {Optional<ExcludeMethods<CandidateDate>, "id" | "createdAt" | "updatedAt">} params
   * @returns CandidateDate
   */
  public static create(
    params: Optional<ExcludeMethods<CandidateDate>, "id" | "createdAt" | "updatedAt">
  ): CandidateDate {
    return new CandidateDate({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
    });
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 IAnnouncement にしている
   */
  static forceConvertToDate(candidateDate: ExcludeMethods<CandidateDate>) {
    return new CandidateDate({
      ...candidateDate,
      createdAt: new Date(candidateDate.createdAt),
      updatedAt: new Date(candidateDate.updatedAt),
      from: new Date(candidateDate.from),
      until: new Date(candidateDate.until),
    });
  }
}
