import { z } from "zod";

/////////////////////////////////////////
// RECRUITMENT PROCESS RECORD ONN EVENT ITEM SCHEMA
/////////////////////////////////////////

export const RecruitmentProcessRecordOnnEventItemSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  employeeId: z.string(),
  recruitmentStatusId: z.string(),
  recruitmentProcessRecordId: z.string(),
  onnEventId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RecruitmentProcessRecordOnnEventItem = z.infer<
  typeof RecruitmentProcessRecordOnnEventItemSchema
>;

/////////////////////////////////////////
// RECRUITMENT PROCESS RECORD ONN EVENT ITEM OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const RecruitmentProcessRecordOnnEventItemOptionalDefaultsSchema =
  RecruitmentProcessRecordOnnEventItemSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type RecruitmentProcessRecordOnnEventItemOptionalDefaults = z.infer<
  typeof RecruitmentProcessRecordOnnEventItemOptionalDefaultsSchema
>;

export default RecruitmentProcessRecordOnnEventItemSchema;
