import { Box } from "@mui/material";
import { NewGraduateToDisplay, OnnEventEvaluationRank } from "@onn/common";
import React, { FC } from "react";

import styled from "styled-components";

import { useConvertToTableData } from "../../hooks/useConvertToTableData";

import { RecruitmentProcessTable } from "./RecruitmentProcessTable/RecruitmentProcessTable";

import { Loading, Paper, Typography } from "~/components/uiParts";
import { useOnnEventEvaluationRanks } from "~/hooks/onnEventEvaluationRank/useOnnEventEvaluationRanks";
import { useRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess";
import { RecruitmentProcessRecordByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

type Props = {
  employee: NewGraduateToDisplay;
};

export const RecruitmentProcessArea: FC<Props> = ({ employee }) => {
  const {
    data: recruitmentProcessRecordByEmployee,
    isLoading: isLoadingRecruitmentProcessRecordByEmployee,
  } = useRecruitmentProcessRecordsByEmployee({
    employeeId: employee.id,
  });
  const { data: onnEventEvaluationRanks, isLoading: isLoadingOnnEventEvaluationRanks } =
    useOnnEventEvaluationRanks();

  const isLoading = isLoadingRecruitmentProcessRecordByEmployee || isLoadingOnnEventEvaluationRanks;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="body2" bold color="textPrimary">
        選考履歴
      </Typography>
      <StyledPaper>
        {isLoading ? (
          <Loading size="large" color="primary" />
        ) : (
          <RecruitmentProcessAreaCore
            employee={employee}
            recruitmentProcessRecordByEmployee={recruitmentProcessRecordByEmployee || []}
            onnEventEvaluationRanks={onnEventEvaluationRanks || []}
          />
        )}
      </StyledPaper>
    </Box>
  );
};

type CoreProps = Props & {
  recruitmentProcessRecordByEmployee: RecruitmentProcessRecordByEmployee[];
  onnEventEvaluationRanks: OnnEventEvaluationRank[];
};

export const RecruitmentProcessAreaCore: FC<CoreProps> = ({
  employee,
  recruitmentProcessRecordByEmployee,
  onnEventEvaluationRanks,
}) => {
  const { convertToTableData } = useConvertToTableData();

  const tableData = convertToTableData(
    recruitmentProcessRecordByEmployee,
    employee,
    onnEventEvaluationRanks
  );

  return <RecruitmentProcessTable tableData={tableData} />;
};

const StyledPaper = styled(Paper)`
  padding: 40px;

  overflow-x: scroll;

  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;
