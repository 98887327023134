import { Box } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { TaskOptionTypes, TaskOptionTypesKeys } from "@onn/common/domain/OnnTask/Question";
import React, { FC } from "react";
import styled from "styled-components";

import { SelectForm, TextFieldDeprecated } from "~/components/uiParts";

type TaskOptionTypesLabelObjType = { [key in TaskOptionTypesKeys]: string };

const TaskOptionTypesLabelObj: TaskOptionTypesLabelObjType = {
  CHECK_BOX: "複数選択",
  TEXT: "記述式",
  RADIO: "単一選択",
  FILE: "ファイル添付",
};

const menuItems = Object.entries(TaskOptionTypesLabelObj).map(([key, value]) => ({
  value: key,
  name: value,
}));

export const QuestionTitleAndSelector: FC<{
  onChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOptionType: (e: React.ChangeEvent<{ value: keyof typeof TaskOptionTypes }>) => void;
  selectedOptionType: keyof typeof TaskOptionTypes;
  disabledTitle?: boolean;
  disabledOptionType?: boolean;
  titleTextField: {
    value: string;
    error: boolean;
    helperText: string;
  };
}> = ({
  onChangeTitle,
  onChangeOptionType,
  selectedOptionType,
  disabledTitle,
  disabledOptionType,
  titleTextField,
}) => {
  const onChange = onChangeOptionType as unknown as (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    child: React.ReactNode
  ) => void;
  return (
    <Box
      display={"flex"}
      sx={{
        marginBottom: "32px",
        justifyContent: "space-between",
      }}
    >
      <Box mr="16px" width="100%">
        <StyledTextField
          placeholder="設問"
          fullWidth
          onChange={onChangeTitle}
          value={titleTextField.value}
          disabled={disabledTitle}
          error={titleTextField.error}
          helperText={titleTextField.helperText}
        />
      </Box>
      <Box width={"160px"}>
        <SelectForm
          fullWidth
          selected={selectedOptionType}
          menuItems={menuItems}
          onChange={onChange}
          disabled={disabledOptionType}
        />
      </Box>
    </Box>
  );
};

const StyledTextField = styled(TextFieldDeprecated)`
  color: ${(props) => props.theme.palette.text.muted};

  // TODO: スタイルをfigmaに揃える
  .MuiInput-underline:before {
    border-bottom: 2px solid ${grey["300"]};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 10.5px;
    padding-bottom: 0px;
  }
`;
