import React, { FC } from "react";
import styled from "styled-components";

import { TextFieldDeprecated } from "~/components/uiParts";

type Props = React.ComponentProps<typeof TextFieldDeprecated>;

export const OnnEventTitleTextField: FC<Props> = (props) => {
  return (
    <StyledTextField
      name="title"
      placeholder="イベントタイトルを入力してください"
      fullWidth
      maxTextCount={50}
      autoFocus
      {...props}
    />
  );
};

const StyledTextField = styled(TextFieldDeprecated)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 16px;
    padding-bottom: 0px;
    height: 1.4em; // アルファベットのgが切れるのを防ぐ
  }
`;
