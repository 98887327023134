import {
  ChangeRecruitmentStatusActionSetting,
  SendContactMessageActionSetting,
  DeliverOnnTaskActionSetting,
  DeliverOnnEventActionSetting,
} from "@onn/common";
import { useMemo } from "react";

import { TriggersApiResponse } from "../../type";

export const useIsActionRelatedDeletedStatus = ({
  trigger,
}: {
  trigger: TriggersApiResponse[0];
}) => {
  const isActionRelatedDeletedStatus = useMemo(() => {
    return trigger.actions.some((action) => {
      switch (action.setting.type) {
        case ChangeRecruitmentStatusActionSetting.type:
          return action.setting.isDeletedRecruitmentStatus;
        case SendContactMessageActionSetting.type:
        case DeliverOnnEventActionSetting.type:
        case DeliverOnnTaskActionSetting.type:
          return false;
        default:
          // eslint-disable-next-line no-case-declarations
          const _exhaustiveCheck: never = action.setting;
          return;
      }
    });
  }, [trigger]);

  return { isActionRelatedDeletedStatus };
};
