import { RecruitmentStatus } from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";

import { SelectFormV2 } from "~/components/uiParts/SelectFormV2";
import { captureException } from "~/util";

type Props = {
  selectedRecruitmentStatusId: string;
  disabled?: boolean;
  onChange: (recruitmentStatus: RecruitmentStatus) => void;
};
export const RecruitmentStatusSelector: FC<Props> = ({
  selectedRecruitmentStatusId,
  disabled,
  onChange,
}) => {
  const { recruitmentStatuses } = useRecruitmentStatusList();

  const onChange_ = useCallback(
    (recruitmentStatusId: string) => {
      const recruitmentStatus = recruitmentStatuses.find(
        (status) => status.id === recruitmentStatusId
      );
      if (!recruitmentStatus) {
        // NOTE: バグの可能性があるためエラーを通知する
        captureException({
          error: new Error("recruitmentStatus not found"),
          tags: { type: "RecruitmentStatusSelector" },
          extras: { recruitmentStatusId },
        });
        return;
      }
      onChange(recruitmentStatus);
    },
    [onChange, recruitmentStatuses]
  );

  const menuItemOptions = useMemo(() => {
    return recruitmentStatuses.map((status) => {
      return {
        value: status.id,
        name: status.label,
      };
    });
  }, [recruitmentStatuses]);

  return (
    <SelectFormV2<string>
      selected={selectedRecruitmentStatusId}
      onChange={(e) => onChange_(e.target.value)}
      menuItems={menuItemOptions}
      fullWidth
      disabled={disabled}
    />
  );
};
