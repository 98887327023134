import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Typography } from "~/components/uiParts";

import { NewAccordion } from "~/components/uiParts/Accordion";
import theme from "~/config/theme";
import helpForQrCodeReaderImg from "~/images/help-for-qr-code-reader.png";

type Props = {
  downloadQRcode: () => void;
  buttonText: string;
};

export const HelpAccordionForOpenLiff: FC<Props> = ({ downloadQRcode, buttonText }) => {
  return (
    <NewAccordion defaultExpanded={false} title="LINEが開かない方はこちら">
      <Box display="flex" flexDirection="column" gridRowGap="16px">
        <Typography variant="body2" color="textSecondary">
          {`「${buttonText}」ボタンを押してもLINEアプリが開かない場合は、スマートフォンからLINEアプリを開き以下いずれかの方法をお試しください。`}
        </Typography>
        <Box display="flex" flexDirection="column" gridRowGap="8px">
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 700, lineHeight: 1.5, flex: 1 }}
          >
            QRコード画像を保存して読み込む
          </Typography>
          <StyledOrderListWrapper
            style={{
              color: theme.palette.grey[400],
              fontSize: "14px",
              lineHeight: "24px",
              paddingLeft: 20,
            }}
          >
            <ol>
              <li>
                <span style={{ fontWeight: "bold" }}>QRコードをスクリーンショット</span>
                で撮影するか、以下の「画像をダウンロード」ボタンを押してQRコード画像を保存
              </li>
              <ButtonWrapper>
                <Button
                  variant="outlined"
                  color="default"
                  borderRadius="circle"
                  fullWidth
                  onClick={downloadQRcode}
                >
                  画像をダウンロード
                </Button>
                <span>
                  ※画像をダウンロードする場合、ブラウザの「ダウンロード」からカメラロールへ保存する必要があります
                </span>
              </ButtonWrapper>
              <li>LINEアプリでQRコードリーダーを起動する</li>
              <StyledIframeWrapper>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/9AKNTDFPmV4?si=I-60RqLUoraoJ1KG"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </StyledIframeWrapper>
              <li>QRコードリーダー画面の右下にあるカメラロールアイコンをタップ</li>
              <img
                style={{ margin: "8px 0px" }}
                width="100%"
                src={helpForQrCodeReaderImg}
                alt="QRコードリーダーでカメラロールを開く手順"
              />
              <li>1で保存したQRコード画像を読み込む</li>
            </ol>
          </StyledOrderListWrapper>
        </Box>
      </Box>
    </NewAccordion>
  );
};

const StyledOrderListWrapper = styled(Box)`
  li::marker {
    font-family: "Noto Sans";
  }
`;
const StyledIframeWrapper = styled(Box)`
  margin: 8px 0px;
  aspect-ratio: 16 / 9;
  max-width: 600px;
`;

const ButtonWrapper = styled(Box)`
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  button > div {
    padding: 8px 16px;
    line-height: 21px;
  }
`;
