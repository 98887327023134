import { Employee, OnnEventAnswer } from "../../../../domain";
import { JoinMultiple } from "../../../../types";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";

import { SupportedJoins } from "./protocol";

/**
 * Join によって追加されるプロパティを記述する
 */
export class JoinedOnnEventAnswer extends OnnEventAnswer {
  employee?: Employee;
  constructor(init: ExcludeMethods<JoinedOnnEventAnswer>) {
    super(init);
    this.employee = init.employee;
  }
}

export class ListOnnEventAnswersResponse {
  static ResponseBody: {
    onnEventAnswers: ToCloudFunctionsCompatible<JoinMultiple<OnnEventAnswer, SupportedJoins>>[];
  };
  public static createResponseBody({
    onnEventAnswers,
  }: {
    onnEventAnswers: JoinMultiple<OnnEventAnswer, SupportedJoins>[];
  }) {
    return { onnEventAnswers: toCloudFunctionsCompatible(onnEventAnswers) };
  }
  public static convertUnixTimeToDate(response: typeof this.ResponseBody) {
    const onnEventAnswers = response.onnEventAnswers;
    return onnEventAnswers.map((v) => {
      return new JoinedOnnEventAnswer({
        ...v,
        createdAt: new Date(v.createdAt),
        updatedAt: new Date(v.updatedAt),
        employee: v.employee ? new Employee({ ...v.employee }) : undefined,
      });
    });
  }
}
