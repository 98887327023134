import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { useContextOfOnnEventSlotSearchModal } from "../../Context";
import { AnyInputCondition } from "../../types/condition";

import { RemoveButton } from "../parts/buttons/RemoveButton";
import { SelectLogicTypeButton } from "../parts/buttons/SelectLogicTypeButton";

import { ConditionSelector } from "./ConditionSelector";

import { Typography } from "~/components/uiParts";

type Props = {
  index: number;
  condition: AnyInputCondition;
};
export const ConditionRow = ({ index, condition }: Props): JSX.Element => {
  const { logicType, removeCondition, onSelectLogicType } = useContextOfOnnEventSlotSearchModal();

  return (
    <Root>
      <SelectLogicTypeButtonContainer>
        {index === 0 ? (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            <Typography bold noWrap>
              条件
            </Typography>
          </Box>
        ) : index === 1 ? (
          <SelectLogicTypeButton logicType={logicType} onChange={onSelectLogicType} />
        ) : (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            <Typography color="textSecondary" bold>
              {logicType}
            </Typography>
          </Box>
        )}
      </SelectLogicTypeButtonContainer>
      <SelectorContainer>
        <ConditionSelector index={index} condition={condition} />
      </SelectorContainer>
      <RemoveButtonContainer>
        <RemoveButton onClick={() => removeCondition(index)} />
      </RemoveButtonContainer>
    </Root>
  );
};

const logicTypeButtonWidth = "88px";
const removeButtonWidth = "24px";
const gapWidth = "16px";

const Root = styled(Box)`
  width: 100%;
  display: flex;
  column-gap: ${gapWidth};
  align-items: center;
`;

const SelectLogicTypeButtonContainer = styled(Box)`
  width: ${logicTypeButtonWidth};
  display: flex;
  align-items: center;
`;

const removeButtonPaddingPx = "8px";
const SelectorContainer = styled(Box)`
  width: calc(
    100% - ${logicTypeButtonWidth} - ${removeButtonWidth} - ${removeButtonPaddingPx} -
      ${removeButtonPaddingPx} - ${gapWidth} - ${gapWidth}
  );
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
`;

const RemoveButtonContainer = styled(Box)`
  width: ${removeButtonWidth};
  display: flex;
  align-items: center;
  justify-content: center;
`;
