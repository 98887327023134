import { OnnEventSlotDate, APISchema } from "@onn/common";
import { useCallback } from "react";

import { mutateOnnEventSlotDatesForDisplay } from "./useOnnEventSlotDatesForDisplay";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-slot-dates"]["PATCH"];
export const useUpdateOnnEventSlotsDates = () => {
  const updateOnnEventSlotsDates = useCallback(async (arg: Endpoint["body"]) => {
    const response = await apiClient.patch("/api/onn-event-slot-dates", arg, {
      isNotifyError: true,
    });
    mutateOnnEventSlotDatesForDisplay(arg.onnEventId);

    return response.onnEventSlotsDate.map((onnEventSlotDate) => {
      return new OnnEventSlotDate(onnEventSlotDate);
    });
  }, []);

  return { updateOnnEventSlotsDates };
};
