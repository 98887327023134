import { z } from "zod";

import { RecruitmentStatusSchema } from "../../_gen/zodSchema";
import { RecruitmentStatusTypeType } from "../../_gen/zodSchema/inputTypeSchemas/RecruitmentStatusTypeSchema";

import { relationSettingSchema } from "./RecruitmentProcessRelationSetting";

export type RecruitmentStatusType = RecruitmentStatusTypeType;

export const recruitmentStatusSchema = RecruitmentStatusSchema.merge(
  z.object({
    label: z
      .string()
      .trim()
      .min(1, { message: "1文字以上で入力してください。" })
      .max(30, { message: "30文字以下で入力してください。" }),
    recruitmentProcessRelationSetting: relationSettingSchema.optional(),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRecruitmentStatusSchema extends z.infer<typeof recruitmentStatusSchema> {}
