import { Box } from "@material-ui/core";
import React, { memo } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

export const HeaderCell = memo<{
  isLast?: boolean;
  title: string;
  key: string;
}>(({ isLast, title, key }) => {
  return (
    <StyledHeaderCellBox key={`TableHead-${key}`} px="16px" isLast={isLast}>
      <Typography
        display="block"
        variant="body1"
        noWrap
        // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
        style={{ lineHeight: 1.4, textAlign: "center", width: "100%" }}
      >
        {title.split("\n").map((v) => (
          <>
            {v}
            <br />
          </>
        ))}
      </Typography>
    </StyledHeaderCellBox>
  );
});

const StyledHeaderCellBox = styled(Box)<{ isLast?: boolean }>`
  height: 100%;
  width: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 0px ${(props) => (props.isLast ? 0 : 1)}px 0px 0px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;
