import { AnyActionCondition } from "@onn/common";
import { z } from "zod";

export type ActionConditionInput = {
  actionCondition: {
    conditionType?: AnyActionCondition["type"] | null | undefined;
    tagIds?: string[];
    eventEvaluationRankIds?: string[];
    questionId?: string;
    questionOptionId?: string;
  };
};

export const actionConditionInputSchema = z.object({
  actionCondition: z.discriminatedUnion("conditionType", [
    z.object({
      conditionType: z.literal("EmployeeTagActionCondition"),
      tagIds: z.array(z.string(), { required_error: "1つ以上選択してください" }),
    }),
    z.object({
      conditionType: z.literal("OnnEventEvaluationActionCondition"),
      eventEvaluationRankIds: z.array(z.string(), { required_error: "1つ以上選択してください" }),
    }),
    z.object({
      conditionType: z.literal("OnnFormTaskResultActionCondition"),
      questionId: z.string(),
      questionOptionId: z.string(),
    }),
    z.object({
      conditionType: z.literal(null),
    }),
  ]),
});

export type ActionConditionValidInput = z.infer<typeof actionConditionInputSchema>;
