import { Box, Menu, MenuItem } from "@material-ui/core";
import { DeprecatedNewGraduate, OnnEvent } from "@onn/common";
import { AttendanceStatus } from "@onn/common/domain/OnnEvent/OnnEventDeterminedDate/schema";
import React, { FC, MouseEvent, useCallback, useState } from "react";
import styled from "styled-components";

import { StatusForDisplayEventTable } from "../../utils/getStatusForDisplayEventTable";

import { AnswerStatusCell } from "./AnswerStatusCell";

import { Divider, Typography } from "~/components/uiParts";

import theme from "~/config/theme";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { OnnEventAnswerWithEmployee, useUpdateAttendanceStatus } from "~/hooks/onnEvent";
import { mutateOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { mutateOnnEventAnswersWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";
import { mutateDeterminedDate } from "~/hooks/onnEvent/determinedDate/useDeterminedDate";
import { mutateCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent/useCandidateDatesWithNumberOfParticipants";
import { mutateOnnEvent } from "~/hooks/onnEvent/useOnnEvent";
import { useSnackbar } from "~/hooks/shared";

type Props = {
  onnEvent: OnnEvent;
  onnEventAnswer: OnnEventAnswerWithEmployee;
  lastReadAt: Date | null;
  statusForDisplayEventTable: StatusForDisplayEventTable;
};

export const AnswerStatusCellWrapper: FC<Props> = ({ onnEvent, onnEventAnswer, ...props }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { handleModal } = useModal();
  const { execUpdateAttendanceStatus } = useUpdateAttendanceStatus();
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const handleClickUpdateStatus = useCallback(
    (event: MouseEvent<HTMLElement>, attendanceStatus: AttendanceStatus) => {
      closeMenu(event);
      if (attendanceStatus === "ATTENDED") {
        return handleModal({
          name: "attendNormalEventOnBehalfModal",
          args: {
            onnEvent,
            onnEventAnswer,
            newGraduate: onnEventAnswer.employee as DeprecatedNewGraduate,
          },
        });
      }

      execUpdateAttendanceStatus({
        onnEventId: onnEvent.id,
        targetEmployeeId: onnEventAnswer.employee.id,
        updates: {
          attendanceStatus,
        },
        type: "normal",
      })
        .then(() => {
          enqueueSnackbar(
            `ステータスが${
              attendanceStatus === "UNREGISTERED" ? "「参加未登録」" : "「不参加」"
            }に変更されました`,
            { variant: "success" }
          );
          mutateDeterminedDate(onnEvent.id);
          mutateOnnEvent(onnEvent.id);
          mutateOnnEventAnswers(onnEvent.id);
          mutateOnnEventAnswersWithEmployee(onnEvent.id);
          mutateCandidateDatesWithNumberOfParticipants(currentUser.id, onnEvent.id);
        })
        .catch(() => {
          enqueueSnackbar("エラーが発生しました。Onnサポートチームまでお問い合わせください。", {
            variant: "error",
          });
        });
    },
    [
      closeMenu,
      currentUser.id,
      enqueueSnackbar,
      execUpdateAttendanceStatus,
      handleModal,
      onnEvent,
      onnEventAnswer,
    ]
  );

  const handleClickEditOnnEventAnswerMenuItem = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      closeMenu(event);
      handleModal({
        name: "answerNormalEventOnBehalfModal",
        args: {
          onnEvent,
          onnEventAnswer,
          newGraduate: onnEventAnswer.employee as DeprecatedNewGraduate,
        },
      });
    },
    [closeMenu, handleModal, onnEvent, onnEventAnswer]
  );

  return (
    <>
      <AnswerStatusCell {...props} openMenu={openMenu} />
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ paddingTop: 8, paddingBottom: 0 }}
      >
        <Box display="flex" flexDirection="column" gridRowGap="4px">
          {props.statusForDisplayEventTable !== "unregistered_attendance_and_past" && (
            <MenuItem
              onClick={(e) => handleClickUpdateStatus(e, "UNREGISTERED")}
              style={{ padding: "8px 24px" }}
            >
              <Typography variant="body2">参加未登録</Typography>
            </MenuItem>
          )}
          {props.statusForDisplayEventTable !== "registered_attendance" && (
            <MenuItem
              onClick={(e) => handleClickUpdateStatus(e, "ATTENDED")}
              style={{ padding: "8px 24px" }}
            >
              <Typography variant="body2">参加済み</Typography>
            </MenuItem>
          )}
          {props.statusForDisplayEventTable !== "absent_attendance" && (
            <MenuItem
              onClick={(e) => handleClickUpdateStatus(e, "ABSENT")}
              style={{ padding: "8px 24px" }}
            >
              <Typography variant="body2">不参加</Typography>
            </MenuItem>
          )}
        </Box>
        <Divider margin={8} orientation="horizontal" />
        <MenuItem onClick={handleClickEditOnnEventAnswerMenuItem} style={{ padding: "16px 24px" }}>
          <Typography variant="caption" style={{ color: theme.palette.grey[300] }}>
            参加日程を編集
          </Typography>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding-top: 8;
    padding-bottom: 0;
  }
`;
