import { RecruitmentStatus } from "./RecruitmentStatus";

export class RecruitmentStatusWithRelatedInfo extends RecruitmentStatus {
  isRelatedEmployee: boolean;
  isRelatedScenario: boolean;
  isRelatedTriggerForDeletedStatus: boolean;
  isRelatedActionForDeletedStatus: boolean;
  constructor(init: ExcludeMethods<RecruitmentStatusWithRelatedInfo>) {
    super(init);
    this.isRelatedEmployee = init.isRelatedEmployee;
    this.isRelatedScenario = init.isRelatedScenario;
    this.isRelatedTriggerForDeletedStatus = init.isRelatedTriggerForDeletedStatus;
    this.isRelatedActionForDeletedStatus = init.isRelatedActionForDeletedStatus;
  }
}
