import { v4 } from "uuid";

import {
  IEmployeeInformationTextTypeValue,
  employeeInformationTextTypeValueSchema,
} from "./schema";

export class EmployeeInformationTextTypeValue implements IEmployeeInformationTextTypeValue {
  static readonly type = "TEXT" as const;
  type = EmployeeInformationTextTypeValue.type;
  static validator = employeeInformationTextTypeValueSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly employeeId: string;
  readonly employeeInformationTextTypeFieldId: string;
  readonly value: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: Omit<ExcludeMethods<EmployeeInformationTextTypeValue>, "type">) {
    EmployeeInformationTextTypeValue.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.employeeId = init.employeeId;
    this.employeeInformationTextTypeFieldId = init.employeeInformationTextTypeFieldId;
    this.value = init.value;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<EmployeeInformationTextTypeValue>,
      "id" | "createdAt" | "updatedAt" | "type"
    >
  ): EmployeeInformationTextTypeValue {
    return new EmployeeInformationTextTypeValue({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  update(
    params: Partial<Pick<EmployeeInformationTextTypeValue, "value">>
  ): EmployeeInformationTextTypeValue {
    return new EmployeeInformationTextTypeValue({
      ...this,
      ...params,
      updatedAt: new Date(),
    });
  }
}
