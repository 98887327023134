import { Employee, OnnEventAnswerForDisplay, OnnEventDeterminedDate } from "@onn/common";
import React, { FC } from "react";

import { useContextOfListOfEmployeesParticipatingForSlotModal } from "../../Context";

import { AnswerStatusChip } from "~/components/domains/onnEvents/AnswerStatusChip";
import { AttendanceStatusMenu } from "~/components/domains/onnEvents/AttendanceStatusMenu";
import { useAttendanceStatusMenu } from "~/components/domains/onnEvents/AttendanceStatusMenu/useAttendanceStatusMenu";

export type DeterminedDateWithEmployee = OnnEventDeterminedDate & {
  employee: Employee;
};

export const AttendanceStatusSelector: FC<{
  determinedDateWithEmployee: DeterminedDateWithEmployee;
}> = ({ determinedDateWithEmployee }) => {
  const { onnEvent } = useContextOfListOfEmployeesParticipatingForSlotModal();
  const { onClickChip, onClickMenuItem, closeMenu, anchorEl } = useAttendanceStatusMenu({
    onnEvent,
  });
  const attendanceStatus = determinedDateWithEmployee.attendanceStatus;
  const currentStatus =
    OnnEventAnswerForDisplay.convertDeterminedDateAttendanceStatusToAnswerStatus(attendanceStatus);

  return (
    <>
      <AnswerStatusChip
        status={currentStatus}
        openMenu={(e) =>
          onClickChip({
            event: e,
            newGraduateId: determinedDateWithEmployee.employee.id,
            attendanceStatus: currentStatus,
          })
        }
      />
      <AttendanceStatusMenu
        anchorEl={anchorEl}
        onClose={closeMenu}
        onClickMenuItem={onClickMenuItem}
        currentStatus={currentStatus}
        employeeId={determinedDateWithEmployee.employee.id}
      />
    </>
  );
};
