import { Box } from "@material-ui/core";
import React from "react";

import { ResetSelectionButton, ResetSelectionButtonProps } from "./ResetSelectionButton";
import { SelectActionButton, SelectActionButtonProps } from "./SelectActionButton";

import { Typography } from "~/components/uiParts";

type Props = {
  selectedCount: number;
  onResetSelection(): void;
} & SelectActionButtonProps &
  ResetSelectionButtonProps;
export const BulkActionControl = ({
  selectedCount,
  onResetSelection,
  ...onClickFunctions
}: Props) => {
  return (
    <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
      <Box>
        <Typography color="textSecondary">{selectedCount}名の候補者が選択されています</Typography>
      </Box>
      <Box display={"flex"} alignItems={"center"} gridGap={"24px"}>
        <Box>
          <SelectActionButton {...onClickFunctions} />
        </Box>
        <Box>
          <ResetSelectionButton onResetSelection={onResetSelection} />
        </Box>
      </Box>
    </Box>
  );
};
