import { CandidateDate, OnnEvent } from "@onn/common";
import { toNumber } from "lodash";
import { ZodError } from "zod";

import { InputState } from "./ReactHooksFormComponents/InputState";

export const createOnnEventOrNull = ({
  tenantId,
  spaceId,
  currentUserId,
  RHFInput,
  isEdit,
  currentOnnEventId,
}: {
  tenantId: string;
  spaceId: string;
  currentUserId: string;
  RHFInput: InputState;
  isEdit: boolean;
  currentOnnEventId?: string;
}): OnnEvent | null => {
  // 候補日が選択されていないフォームは登録対象から除く
  try {
    // 回答期限は過去日を入力できないようにするため、入力フォームではzodを拡張しているが、OnnEventクラスの方は何もしていない。
    // OnnEventクラスを入力フォーム用に拡張できるともっとシンプルな実装になりそう
    const _onnEvent = OnnEvent.create({
      id: isEdit ? currentOnnEventId : undefined,
      tenantId,
      title: RHFInput.title,
      content: RHFInput.content,
      spaceId,
      candidateDates: RHFInput.candidateDates.map((v) => {
        if (v.from === null || v.until === null) throw new ZodError([]);
        return CandidateDate.create({
          id: v.candidateDateId === "" ? undefined : v.candidateDateId, // undefinedを渡すことで新しいIDを作成
          from: v.from,
          until: v.until,
          capacity: RHFInput.hasCapacity
            ? v.capacity === undefined
              ? undefined
              : parseInt(v.capacity, 10)
            : undefined,
        });
      }),
      assigneeIds: [currentUserId],
      type: RHFInput.type as OnnEvent["type"],
      isRestrictedAnswerFromNewGraduate: RHFInput.isRestrictedAnswerFromNewGraduate,
      isRestrictedDaysAgoWhenAnswer: RHFInput.isRestrictedDaysAgoWhenAnswer,
      isRestrictedEditAnswerFromNewGraduate: RHFInput.isRestrictedEditAnswerFromNewGraduate,
      daysAgoRestrictAnswer:
        RHFInput.isRestrictedDaysAgoWhenAnswer && RHFInput.daysAgoRestrictAnswer
          ? toNumber(RHFInput.daysAgoRestrictAnswer)
          : undefined,
      isNotificationForAnswerOnBehalfCompleted: true,
      isNotificationForRequestForAnswer: true,
    });
    return _onnEvent;
  } catch (_e) {
    return null;
  }
};
