import React, { FC } from "react";

export const Close: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M21.3549 3.93567C21.7113 3.57932 21.7113 3.00157 21.3549 2.64522C20.9986 2.28888 20.4208 2.28888 20.0645 2.64522L2.64584 20.0639C2.28949 20.4202 2.28949 20.998 2.64584 21.3543C3.00219 21.7107 3.57994 21.7107 3.93628 21.3543L21.3549 3.93567Z"
        stroke="none"
      />
      <path
        d="M20.0639 21.3546C20.4203 21.7109 20.998 21.7109 21.3544 21.3546C21.7107 20.9983 21.7107 20.4205 21.3544 20.0642L3.93572 2.64552C3.57937 2.28917 3.00162 2.28917 2.64527 2.64552C2.28893 3.00187 2.28893 3.57962 2.64527 3.93596L20.0639 21.3546Z"
        stroke="none"
      />
    </svg>
  );
};
