import { Box, Menu, MenuItem } from "@material-ui/core";
import React, { FC } from "react";

import { AttendanceStatus } from "./types";

import { Typography } from "~/components/uiParts";

export type OnClickMenuItem = (
  event: React.MouseEvent<HTMLElement>,
  attendanceStatus: AttendanceStatus,
  employeeId: string
) => void;

export type OnClickChip = (arg: {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  attendanceStatus: AttendanceStatus;
  newGraduateId: string;
}) => void;

export const AttendanceStatusMenu: FC<{
  currentStatus: AttendanceStatus;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  onClickMenuItem: OnClickMenuItem;
  anchorEl: Element | ((element: Element) => Element) | null;
  employeeId: string;
}> = ({ anchorEl, currentStatus, onClose, onClickMenuItem, employeeId }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{ paddingTop: 8, paddingBottom: 0 }}
    >
      <Box display="flex" flexDirection="column" gridRowGap="4px">
        {currentStatus !== "unregistered_attendance_and_past" && (
          <MenuItem
            onClick={(e) => onClickMenuItem(e, "unregistered_attendance_and_past", employeeId)}
            style={{ padding: "8px 24px" }}
          >
            <Typography variant="body2">参加未登録</Typography>
          </MenuItem>
        )}
        {currentStatus !== "registered_attendance" && (
          <MenuItem
            onClick={(e) => onClickMenuItem(e, "registered_attendance", employeeId)}
            style={{ padding: "8px 24px" }}
          >
            <Typography variant="body2">参加済み</Typography>
          </MenuItem>
        )}
        {currentStatus !== "absent_attendance" && (
          <MenuItem
            onClick={(e) => onClickMenuItem(e, "absent_attendance", employeeId)}
            style={{ padding: "8px 24px" }}
          >
            <Typography variant="body2">不参加</Typography>
          </MenuItem>
        )}
      </Box>
    </Menu>
  );
};
