import { z } from "zod";

import { ActionConditionTypeSchema } from "../inputTypeSchemas/ActionConditionTypeSchema";
import { OnnFormTaskResultActionConditionAnswerTypeSchema } from "../inputTypeSchemas/OnnFormTaskResultActionConditionAnswerTypeSchema";

/////////////////////////////////////////
// ACTION CONDITIONS SCHEMA
/////////////////////////////////////////

export const ActionConditionsSchema = z.object({
  type: ActionConditionTypeSchema,
  answerType: OnnFormTaskResultActionConditionAnswerTypeSchema.nullish(),
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  actionId: z.string(),
  onnFormTaskId: z.string().nullish(),
  onnEventEvaluationRankIds: z.string().array(),
  onnEventId: z.string().nullish(),
  questionId: z.string().nullish(),
  optionId: z.string().nullish(),
  employeeTagIds: z.string().array(),
});

export type ActionConditions = z.infer<typeof ActionConditionsSchema>;

/////////////////////////////////////////
// ACTION CONDITIONS OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const ActionConditionsOptionalDefaultsSchema = ActionConditionsSchema.merge(z.object({}));

export type ActionConditionsOptionalDefaults = z.infer<
  typeof ActionConditionsOptionalDefaultsSchema
>;

export default ActionConditionsSchema;
