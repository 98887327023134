import { z } from "zod";

import { ProgressStatusSchema, RecruitmentProcessRecordSchema } from "../../_gen/zodSchema";

export const recruitmentProcessRecordSchema = RecruitmentProcessRecordSchema.merge(
  z.object({
    progressStatus: ProgressStatusSchema.optional(),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRecruitmentProcessRecordSchema
  extends z.infer<typeof recruitmentProcessRecordSchema> {}
