import { z } from "zod";

import { OnnEventNotificationTypeSchema } from "../inputTypeSchemas/OnnEventNotificationTypeSchema";

/////////////////////////////////////////
// BM ONN EVENT NOTIFICATION HISTORY SCHEMA
/////////////////////////////////////////

export const BmOnnEventNotificationHistorySchema = z.object({
  type: OnnEventNotificationTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  onnEventId: z.string(),
  employeeIdToSend: z.string(),
  sentTime: z.date(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type BmOnnEventNotificationHistory = z.infer<typeof BmOnnEventNotificationHistorySchema>;

/////////////////////////////////////////
// BM ONN EVENT NOTIFICATION HISTORY OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const BmOnnEventNotificationHistoryOptionalDefaultsSchema =
  BmOnnEventNotificationHistorySchema.merge(
    z.object({
      sentTime: z.date().optional(),
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type BmOnnEventNotificationHistoryOptionalDefaults = z.infer<
  typeof BmOnnEventNotificationHistoryOptionalDefaultsSchema
>;

export default BmOnnEventNotificationHistorySchema;
