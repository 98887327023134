import { patchEmployeeInformationFieldsRequestSchema } from "@onn/common";
import { useCallback } from "react";
import { z } from "zod";

import { apiClient } from "~/libs";

export const useUpdateEmployeeInformationField = () => {
  const execUpdateEmployeeInformationField = useCallback(
    async (data: z.infer<typeof patchEmployeeInformationFieldsRequestSchema>): Promise<void> => {
      await apiClient.patch("/api/employee-information-fields", data);
    },
    []
  );

  return { execUpdateEmployeeInformationField };
};
