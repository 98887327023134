import { Box } from "@material-ui/core";
import React, { useCallback, useState, ReactNode } from "react";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  label: string;
  children: ReactNode;
  defaultShown?: boolean;
};
export const ClosableContainer = ({ label, children, defaultShown }: Props): JSX.Element => {
  const [isShown, setShown] = useState(defaultShown ?? false);
  const toggle = useCallback(() => setShown((prev) => !prev), []);

  return (
    <Box width={"100%"} gridGap={"8px"}>
      {isShown && children}
      <Box>
        <Button onClick={toggle} color="default" variant="text" borderRadius="regular">
          <Box display={"flex"} alignItems={"center"} gridGap={"4px"}>
            <Typography variant="caption" bold noWrap>
              {label}
            </Typography>
            <Icon icon={isShown ? "chevronUp" : "chevronDown"} size="sm" color="grey" />
          </Box>
        </Button>
      </Box>
    </Box>
  );
};
