import { Box } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "~/components/uiParts";
import { useCancelContactMessagesOrder } from "~/hooks/contactMessage";

export const CancelContactMessagesOrder: FC = () => {
  const navigate = useNavigate();
  const { cancelOrder } = useCancelContactMessagesOrder();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id)
      cancelOrder(id).then(() => {
        navigate("/contact_rooms");
      });
  }, [cancelOrder, id, navigate]);

  return (
    <Box marginTop="40%">
      <Loading size="large" />
    </Box>
  );
};
