import liff from "@line/liff";
import { convertBytesToSize } from "@onn/common";
import React, { ComponentProps, FC } from "react";

import { FileAttachedButtonUI } from "./FileAttachedButton";

import { Loading } from "~/components/uiParts";

import { useMetaDataByUrls } from "~/hooks/file";
import { useFileViewer } from "~/hooks/shared";

export const FileAttachedButtonForStorage: FC<{
  filePath?: string;
  signedUrl?: string;
  renderCustomButton?: (props: ComponentProps<typeof FileAttachedButtonUI>) => JSX.Element;
  LoadingProps?: ComponentProps<typeof Loading>;
}> = ({
  filePath,
  signedUrl,
  renderCustomButton,
  LoadingProps = {
    size: "large",
    fullHeight: true,
  },
}) => {
  const { data: fileMetaData, isLoading } = useMetaDataByUrls(filePath ? [filePath] : []);
  const { handleDownloadFile } = useFileViewer();

  if (isLoading) return <Loading {...LoadingProps} />;
  if (!fileMetaData) return <></>;

  const fileMeta = fileMetaData[0] as (typeof fileMetaData)[number];
  const ext = fileMeta.name.split(".")?.pop() || "";

  const type = (() => {
    if (["png", "jpg", "jpeg", "gif"].includes(ext)) return "openNewTab";
    if (ext === "pdf") return "openNewTab";
    return "download";
  })();

  const onClick = () => {
    if (type !== "download") {
      window.open(fileMeta.url, "_blank");
      return;
    }

    if (liff.isInClient() && signedUrl) {
      liff.openWindow({
        url: signedUrl,
        external: true,
      });
      return;
    }

    handleDownloadFile(fileMeta.url, [fileMeta]);
    return;
  };

  if (renderCustomButton) {
    return renderCustomButton({
      fileName: fileMeta.name,
      fileSizeLabel: convertBytesToSize(fileMeta.size),
      type,
      onClick,
    });
  }
  return (
    <FileAttachedButtonUI
      fileName={fileMeta.name}
      type={type}
      onClick={onClick}
      fileSizeLabel={convertBytesToSize(fileMeta.size)}
    />
  );
};
