import { Employee } from "@onn/common";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import { useSnackbar } from "~/hooks/shared";

export const useEnqueueSuccessSnackbar = ({
  fromPage,
  newSelectedNewGraduates,
  isAlreadyDelivered,
}: {
  fromPage: string;
  newSelectedNewGraduates: Employee[];
  isAlreadyDelivered: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSuccessSnackbar = useCallback(() => {
    const isCreate = fromPage === "create";
    const isAdded = !isEmpty(newSelectedNewGraduates);

    let text;
    if (isCreate) {
      text = "配信予約が設定されました";
    } else if (isAlreadyDelivered && isAdded) {
      text = `${newSelectedNewGraduates.length}名の追加配信対象者へタスクを配信・通知しました`;
    } else {
      text = "配信タスクの内容が更新されました";
    }

    enqueueSnackbar(text, {
      variant: "success",
    });
  }, [enqueueSnackbar, fromPage, isAlreadyDelivered, newSelectedNewGraduates]);

  return { enqueueSuccessSnackbar };
};
