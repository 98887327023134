import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { useMutateEmployeePredictions } from "./useEmployeePredictions";

import { apiClient } from "~/libs";

export const useEditEmployeePredictions = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateEmployeePredictions } = useMutateEmployeePredictions();

  const editEmployeePredictions = useCallback(
    async ({
      employeePredictions,
      successMessage,
    }: {
      employeePredictions: Array<{ id: string; label: string }>;
      successMessage: string;
    }) => {
      await apiClient
        .post("/api/employee-predictions", {
          employeePredictions,
        })
        .then(async () => {
          enqueueSnackbar(successMessage, { variant: "success" });
          await mutateEmployeePredictions();
        })
        .catch((error) => {
          if (error instanceof Error) {
            enqueueSnackbar(error.message, { variant: "error" });
          }
        });
    },
    [enqueueSnackbar, mutateEmployeePredictions]
  );

  return { editEmployeePredictions };
};
