import { Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

export type StyleProps = {
  style: React.CSSProperties;
  isColumnDirection?: boolean;
  isLastColumn?: boolean;
  isShowTopBorder?: boolean;
  isShowRightBorder?: boolean;
  textAlign?: string;
  innerBoxStyle?: React.CSSProperties;
};

export const CellItem: FC<
  StyleProps & {
    children: ReactNode;
  }
> = ({
  style,
  children,
  isColumnDirection = false,
  isShowRightBorder = true,
  isShowTopBorder,
  textAlign,
  innerBoxStyle,
}) => {
  return (
    <Box style={{ ...style }}>
      <StyledBox
        $isShowTopBorder={isShowTopBorder}
        $isShowRightBorder={isShowRightBorder || false}
        $isColumnDirection={isColumnDirection}
        $textAlign={textAlign}
        style={innerBoxStyle}
      >
        {children}
      </StyledBox>
    </Box>
  );
};

const StyledBox = styled(Box)<{
  $bgcolor?: string;
  $isShowRightBorder: boolean;
  $isColumnDirection: boolean;
  $isShowTopBorder?: boolean;
  $textAlign?: string;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.$isColumnDirection ? "column" : "row")};
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.$bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: ${(props) => (props.$isShowTopBorder ? 1 : 0)}px
    ${(props) => (props.$isShowRightBorder ? 1 : 0)}px 0px 0px;
  text-align: ${(props) => props.$textAlign || "center"};
`;
