import { OnnEventPlace } from "@onn/common";
import { useCallback } from "react";

import { useModal } from "~/hooks/modal";
import { useDeleteOnnEventPlace } from "~/hooks/onnEventPlace/useDeleteOnnEventPlace";
import { useSnackbar } from "~/hooks/shared";

export const useDeletePlaceConfirmModal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleModal } = useModal();
  const { deleteOnnEventPlace } = useDeleteOnnEventPlace();

  const generateOnClickAccept = useCallback(
    (placeId: string) => async () => {
      try {
        await deleteOnnEventPlace({
          placeId,
        });
        enqueueSnackbar(`会場が削除されました`, {
          variant: "success",
        });
      } catch {
        enqueueSnackbar("会場の削除に失敗しました", { variant: "error" });
      }
    },
    [deleteOnnEventPlace, enqueueSnackbar]
  );

  const openDeletePlaceConfirmModal = useCallback(
    (onnEventPlace: OnnEventPlace) => {
      handleModal({
        name: "confirmModal",
        args: {
          title: "会場削除",
          onClickAccept: generateOnClickAccept(onnEventPlace.id),
          mainContent: `「${onnEventPlace.name}」を削除しますか？`,
          subContent: `この操作は取り消すことができません`,
          acceptButtonColor: "secondary",
          acceptLabel: "削除",
        },
      });
    },
    [generateOnClickAccept, handleModal]
  );

  return { openDeletePlaceConfirmModal };
};
