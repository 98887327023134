import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import { useMutateAllNewcomers } from "../useAllNewcomers";

import { generateUseNewGraduateKey } from "../useNewGraduate";

import { generateUseEmployeeKeys } from "~/hooks/employee/useEmployee";
import { apiClient } from "~/libs";

export const useAddTagToEmployee = ({ employeeID }: { employeeID: string }) => {
  const { mutate } = useSWRConfig();
  const { mutateAllNewcomers } = useMutateAllNewcomers();

  return useSWRMutation(
    { key: "addTagToEmployee", employeeID },
    async ({ employeeID }, arg: { arg: { newTagIDs: string[] } }) => {
      const { newTagIDs } = arg.arg;
      await apiClient.post("/tag_api/add-tags-to-employee", {
        employeeId: employeeID,
        employeeTagIds: newTagIDs,
      });
    },
    {
      onSuccess: () => {
        mutate(generateUseEmployeeKeys(employeeID));
        mutate(generateUseNewGraduateKey({ newGraduateId: employeeID }));
        mutateAllNewcomers();
      },
    }
  );
};
