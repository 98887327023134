import React from "react";

import { PasswordField } from "~/components/uiParts";

export const PasswordInput = ({
  password,
  passwordError,
  onChange,
}: {
  password: string;
  passwordError?: boolean;
  onChange: (value: string) => void;
}) => {
  return (
    <PasswordField
      error={passwordError}
      fullWidth
      helperText={
        passwordError ? "半角英数字6文字以上で入力してください" : "半角英数字で入力してください"
      }
      value={password}
      onChange={(inputValue: string): void => onChange(inputValue)}
    />
  );
};
