import { useCallback } from "react";

import { useGetTriggerFormIndex } from "../../../hooks/scenarioForm/useGetTriggerFormIndex";
import { useFormContext } from "../../../hooks/scenarioForm/useScenarioForm";

export const useExtractActionConditionError = () => {
  const { getTriggerFormIndex } = useGetTriggerFormIndex();
  const form = useFormContext();

  const extractActionConditionError = useCallback(
    ({ recruitmentStatusId, triggerId }: { recruitmentStatusId: string; triggerId: string }) => {
      const { scenarioIndex, triggerIndex } = getTriggerFormIndex({
        recruitStatusId: recruitmentStatusId,
        triggerId: triggerId,
      });

      const actions = form.getValues(`body.${scenarioIndex}.blocks.${triggerIndex}.actions`);

      const errors = (actions || []).flatMap((_action, index) => {
        const actionCondition = form.getFieldState(
          `body.${scenarioIndex}.blocks.${triggerIndex}.actions.${index}.actionCondition`
        );
        return actionCondition.error;
      });

      return { actionConditionErrorMessage: errors[0]?.message };
    },
    [form, getTriggerFormIndex]
  );

  return { extractActionConditionError };
};
