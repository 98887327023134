import { OnnEventSlotDatesForDisplay, BriefingSessionEvent } from "@onn/common";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { FC, useCallback } from "react";

import { BODY_CELL_HEIGHT_PX, BodyRowData, HEADER_CELL_HEIGHT_PX, HeaderRowData } from "./const";

import { useMultiGridOnPaper } from "./useMultiGridOnPaper";

import { VirtualizedMultiGrid } from "~/components/uiParts/VirtualizedMultiGrid";
import { State } from "~/hooks/shared/useToggleSelectAll/useToggleSelectAll";

export const OnnEventSlotDatesMultiGrid: FC<{
  onnEvent: BriefingSessionEvent;
  onnEventSlotDatesForDisplay: OnnEventSlotDatesForDisplay[];
  isLoadingFetchingSlots: boolean;
  setSelectedSlotIds: React.Dispatch<React.SetStateAction<string[]>>;
  allSelectionState: State;
  selectedSlotIds: string[];
}> = ({
  onnEvent,
  onnEventSlotDatesForDisplay,
  isLoadingFetchingSlots,
  setSelectedSlotIds,
  allSelectionState,
  selectedSlotIds,
}) => {
  const generateHeaderCheckBoxClickHandler = useCallback(() => {
    return () => {
      if (allSelectionState.indeterminate || !allSelectionState.checked) {
        setSelectedSlotIds(onnEventSlotDatesForDisplay.map((slotDate) => slotDate.id));
      } else {
        setSelectedSlotIds([]);
      }
    };
  }, [
    allSelectionState.checked,
    allSelectionState.indeterminate,
    onnEventSlotDatesForDisplay,
    setSelectedSlotIds,
  ]);

  const generateRowBodyCheckBoxClickHandler = useCallback(() => {
    return (slotId: string) => {
      setSelectedSlotIds((prev) => {
        if (prev.includes(slotId)) {
          return prev.filter((id) => id !== slotId);
        } else {
          return [...prev, slotId];
        }
      });
    };
  }, [setSelectedSlotIds]);

  const headerRowData: HeaderRowData = {
    checkBox: {
      isChecked: allSelectionState.checked,
      onClick: generateHeaderCheckBoxClickHandler(),
      indeterminate: allSelectionState.indeterminate,
    },
  };

  const bodyRowDataArray: BodyRowData[] = onnEventSlotDatesForDisplay.map((slotDate) => {
    return {
      checkBox: {
        isChecked: selectedSlotIds.some((id) => id === slotDate.id),
        onClick: generateRowBodyCheckBoxClickHandler(),
        slotId: slotDate.id,
      },
      menu: {
        slot: slotDate,
        reservedCount: slotDate.reservedCount,
        onnEvent,
      },
      uniqueId: slotDate.uniqueId,
      briefingSession: {
        title: slotDate.briefingSessionCategoryInfo?.title,
      },
      slotDate: format(slotDate.from, "MM/dd(E)", { locale: ja }),
      slotTime: `${format(slotDate.from, "HH:mm")}〜${format(slotDate.until, "HH:mm")}`,
      capacity: slotDate.capacity || 0,
      reserved: {
        count: slotDate.reservedCount || 0,
        slotId: slotDate.id,
        briefingSessionCategoryId: slotDate.briefingSessionCategoryInfo?.id,
        fromDate: slotDate.from,
        untilDate: slotDate.until,
        type: slotDate.eventType,
        onnEvent,
        isReservable: slotDate.isReservable(),
      },
      status: {
        status: slotDate.getStatusForDisplay(),
        onnEventId: slotDate.onnEventId,
        slotId: slotDate.id,
        reservedCount: slotDate.reservedCount,
      },
      assignee: {
        assigneeName: slotDate.assigneeInfo && slotDate.assigneeInfo.name,
        profileIconImageUrl: slotDate.assigneeInfo && slotDate.assigneeInfo.iconUrl,
      },
      subAssignee: slotDate.subAssigneesInfo.map((subAssignee) => ({
        subAssigneeName: subAssignee.name,
        profileIconImageUrl: subAssignee.iconUrl,
      })),
      slotType: slotDate.getSlotTypeForDisplay(),
      description: slotDate.description,
    };
  });

  const { cellRenderer, columnWidth, columnCount, rowCount } = useMultiGridOnPaper({
    bodyRowDataArray,
    headerRowData,
  });

  return (
    <VirtualizedMultiGrid
      cellRenderer={cellRenderer}
      columnWidth={columnWidth}
      columnCount={columnCount}
      rowCount={rowCount}
      headerCellHeight={HEADER_CELL_HEIGHT_PX}
      isLoading={isLoadingFetchingSlots}
      emptyText="予約枠がありません"
      bodyCellHeight={BODY_CELL_HEIGHT_PX}
      paddingSize="sm"
    />
  );
};
