import { ContactRoom } from "@onn/common";
import { useState, useCallback, useMemo } from "react";

import { useSWRConfig } from "swr";

import { useContactContext } from "../contactMessage/useContactContext";
import { useGenerateMutateContactRoomUnreadCountV2 } from "../contactRoomUnreadCount";
import { useSnackbar } from "../shared";

import { useUpdateReadLogAndUnreadCount } from "./useUpdateReadLogAndUnreadCount";

import { captureException } from "~/util";

/**
 *AssigneeとFollowersのIDを更新するための関数を提供するhooks
 */
export const useUpdateReadLogOfContactRoom = (): {
  isLoading: boolean;
  updateReadLogOfContactRoom: (
    employeeId: string,
    contactRoomId: string
  ) => Promise<ContactRoom | void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const { contactRoomUnreadCountMap, isLoadingUnreadMessageCountList } = useContactContext();
  const { updateReadLogAndUnreadCount } = useUpdateReadLogAndUnreadCount();
  const { generateMutateContactRoomUnreadCountV2 } = useGenerateMutateContactRoomUnreadCountV2();
  const mutateContactRoomUnreadCountV2 = useMemo(
    () => generateMutateContactRoomUnreadCountV2(),
    [generateMutateContactRoomUnreadCountV2]
  );
  /**
   * contactRoomのReadLogを更新するための関数
   * @params employeeId
   * @params contactRoomId
   */
  const updateReadLogOfContactRoom = useCallback(
    async (employeeId: string, contactRoomId: string) => {
      try {
        setIsLoading(true);

        if (!isLoadingUnreadMessageCountList) {
          // 楽観的更新をする
          // 更新対象のcontactRoomのメッセージは全て閲覧されるため、未読数を0にする
          contactRoomUnreadCountMap.set(contactRoomId, 0);
        }

        await updateReadLogAndUnreadCount(contactRoomId);

        // 未読数を取得中にmutateを呼び出すと、バグの原因になるため、取得中の場合はmutateを呼び出さない
        if (!isLoadingUnreadMessageCountList) {
          await mutateContactRoomUnreadCountV2();
        }
      } catch (e) {
        // readLogの更新に失敗した場合、未読数を元に戻す
        mutate(mutateContactRoomUnreadCountV2());
        enqueueSnackbar((e as Error).message, { variant: "error" });
        captureException({
          error: e as Error,
          tags: { type: "useUpdateReadLogOfContactRoom:updateReadLogOfContactRoom" },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      contactRoomUnreadCountMap,
      isLoadingUnreadMessageCountList,
      updateReadLogAndUnreadCount,
      mutateContactRoomUnreadCountV2,
      mutate,
      enqueueSnackbar,
    ]
  );

  return { isLoading, updateReadLogOfContactRoom };
};
