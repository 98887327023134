import { z } from "zod";

import { EmployeeInformationFieldTypeSchema } from "../inputTypeSchemas/EmployeeInformationFieldTypeSchema";

/////////////////////////////////////////
// EMPLOYEE INFORMATION FIELD SCHEMA
/////////////////////////////////////////

export const EmployeeInformationFieldSchema = z.object({
  type: EmployeeInformationFieldTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  employeeInformationGroupId: z.string(),
  label: z.string(),
  order: z.number(),
  sampleValue: z.string(),
  isCustom: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeInformationField = z.infer<typeof EmployeeInformationFieldSchema>;

/////////////////////////////////////////
// EMPLOYEE INFORMATION FIELD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeInformationFieldOptionalDefaultsSchema = EmployeeInformationFieldSchema.merge(
  z.object({})
);

export type EmployeeInformationFieldOptionalDefaults = z.infer<
  typeof EmployeeInformationFieldOptionalDefaultsSchema
>;

export default EmployeeInformationFieldSchema;
