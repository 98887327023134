import { z } from "zod";

/////////////////////////////////////////
// ONN EVENT PLACE SCHEMA
/////////////////////////////////////////

export const OnnEventPlaceSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  name: z.string(),
  postCode: z.string(),
  state: z.string(),
  city: z.string(),
  address1: z.string(),
  address2: z.string(),
  remarkText: z.string(),
  filePaths: z.string().array(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnEventPlace = z.infer<typeof OnnEventPlaceSchema>;

/////////////////////////////////////////
// ONN EVENT PLACE OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnEventPlaceOptionalDefaultsSchema = OnnEventPlaceSchema.merge(
  z.object({
    remarkText: z.string().optional(),
    filePaths: z.string().array().optional(),
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnEventPlaceOptionalDefaults = z.infer<typeof OnnEventPlaceOptionalDefaultsSchema>;

export default OnnEventPlaceSchema;
