import { format } from "date-fns";
import React, { FC } from "react";

import { InformationValueViewer } from "../InformationValueViewer";

import { Typography } from "~/components/uiParts";

type Props = {
  value?: Date;
  onChangeModeToEdit: () => void;
  onClickCopy?: () => void;
};

export const CustomEmployeeInformationDateTypeViewer: FC<Props> = ({
  value,
  onChangeModeToEdit,
  onClickCopy,
}) => {
  return (
    <InformationValueViewer
      label={
        value ? (
          <Typography variant="body2">{format(value, "yyyy年MM月dd日")}</Typography>
        ) : undefined
      }
      onChangeModeToEdit={onChangeModeToEdit}
      onClickCopy={onClickCopy}
    />
  );
};
