import { z } from "zod";

export const AnnouncementScalarFieldEnumSchema = z.enum([
  "id",
  "tenantId",
  "spaceId",
  "title",
  "content",
  "filePaths",
  "creatorId",
  "publicationStartDate",
  "publicationEndDate",
  "firstPublishedAt",
  "createdAt",
  "updatedAt",
]);

export default AnnouncementScalarFieldEnumSchema;
