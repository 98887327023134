import { Box, Chip as MuiChip } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

export const UnAvailableHeaderCell = ({
  numOfAnswerWithUnavailableCandidates,
}: {
  numOfAnswerWithUnavailableCandidates: number;
}) => {
  return (
    <StyledHeaderCellBox px="16px">
      <Typography
        display="block"
        variant="body1"
        noWrap
        // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
        style={{ lineHeight: 1.4, textAlign: "center", width: "100%" }}
      >
        参加できる
        <br />
        日程がない
      </Typography>
      <StyledChip
        label={
          <Typography variant="overline" display="inline" color="textSecondary">
            {`${numOfAnswerWithUnavailableCandidates}人`}
          </Typography>
        }
      />
    </StyledHeaderCellBox>
  );
};

const StyledChip = styled(MuiChip)`
  &.MuiChip-root {
    margin-top: 8px;
    font-size: 10px;
    height: 24px;
    padding: 4px;

    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;

const StyledHeaderCellBox = styled(Box)`
  height: 100%;
  width: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 0px 1px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;
