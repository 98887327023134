import { Box } from "@material-ui/core";
import { BriefingSessionEvent, NewInterviewEvent } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import {
  DownloadSampleFileStepContent,
  EmbedCsvDataButton,
} from "../../share/csvDownload/DownloadSampleFileStepContent";

import { UploadFileStepContent } from "./UploadFileStepContent";

import { useGenerateSampleCSV } from "./useGenerateSampleCSV";
import { useGenerateSettingCSV } from "./useGenerateSettingCSV";

import { Modal, VerticalStepper } from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: NewInterviewEvent | BriefingSessionEvent;
};

export const CreateOnnEventSlotsWithCSVModal: FC<Props> = ({ open, onCancel, onnEvent }) => {
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const { generateSettingCSV, isLoading: isLoadingSettingCsv } = useGenerateSettingCSV();
  const { generateSampleCSV } = useGenerateSampleCSV();

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            downLoadCsvButtons={
              <Box mt={4} display="flex" flexDirection="row" gridGap={16}>
                <EmbedCsvDataButton
                  buttonText="サンプルファイルをダウンロード"
                  csvData={generateSampleCSV(onnEvent)}
                  filename="予約枠一括登録アップロード用サンプルファイル"
                />
                <EmbedCsvDataButton
                  buttonText="設定値ファイルをダウンロード"
                  csvData={generateSettingCSV()}
                  filename="予約枠一括登録_設定値ファイル"
                  isLoading={isLoadingSettingCsv}
                />
              </Box>
            }
          />
        );
      case 2:
        return <UploadFileStepContent onnEventId={onnEvent.id} onCancel={onCancel} />;
    }
  }, [activeStep, generateSampleCSV, generateSettingCSV, isLoadingSettingCsv, onCancel, onnEvent]);

  return (
    <Modal
      open={open}
      title={"予約枠一括登録"}
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
