import {
  BriefingSessionCategory,
  BriefingSessionEvent,
  NewInterviewEvent,
  OnnEventAnswer,
  searchOnnEventSlotsByConditionSchema,
} from "@onn/common";
import { throttle } from "lodash";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import { AnyCondition, AnyInputCondition, AnyTarget, AnyValidCondition } from "./types/condition";

import { LogicType } from "./types/logic-type";

import { useBriefingSessionCategories } from "~/hooks/onnEvent/useBriefingSessionCategories";
import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";

type ContextType = {
  modalType: "slot" | "answer";
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
  conditions: AnyInputCondition[];
  logicType: "AND" | "OR";
  searchResultCount: number | undefined;
  isLoadingSearch: boolean;
  isLoadingChange: boolean;
  isLoadingFetchCategories: boolean;
  briefingSessionCategories: BriefingSessionCategory[];
  addCondition: () => void;
  clearAllConditions: () => void;
  onChangeTarget: (index: number, changedTarget: AnyTarget) => void;
  onSelectLogicType: (logicType: LogicType) => void;
  removeCondition: (index: number) => void;
  onChangeCondition: (index: number, changedCondition: AnyInputCondition) => void;
  handleSearch: () => Promise<void>;
};

/**
 * 予約枠検索モーダルでのみ使用するコンテキスト
 */
export const ContextOfOnnEventSlotSearchModal = createContext<ContextType | undefined>(undefined);

export const useContextOfOnnEventSlotSearchModal = () => {
  const c = useContext(ContextOfOnnEventSlotSearchModal);
  if (!c)
    throw new Error("useContextOfOnnEventSlotSearchModal must be inside a Provider with a value");
  return c;
};

type Props = {
  modalType: "slot" | "answer";
  children: React.ReactNode;
  onnEvent: BriefingSessionEvent | NewInterviewEvent;
  defaultConditions: AnyInputCondition[];
  defaultLogicType: LogicType;
  defaultSearchResultCount: number | undefined;
  allSlotsCount?: number;
  onnEventAnswersForDisplay?: OnnEventAnswer[];
  onCancel(): void;
  onSearchConfirm(logicType: LogicType, conditions: AnyCondition[]): Promise<void>;
};

export const ContextProviderOfOnnEventSlotSearchModal: React.FC<Props> = (props) => {
  const [conditions, setConditions] = useState<AnyInputCondition[]>(props.defaultConditions);
  const [logicType, setLogicType] = useState(props.defaultLogicType);
  const [searchResultCount, setSearchResultCount] = useState(
    props.defaultSearchResultCount === undefined
      ? props.modalType === "slot"
        ? props.allSlotsCount
        : props.onnEventAnswersForDisplay?.length ?? 0
      : props.defaultSearchResultCount
  );
  const [isLoadingChange, setLoadingChange] = useState(false);
  const [isLoadingSearch, setLoadingSearch] = useState(false);

  const { data: briefingSessionCategories = [], isLoading: isLoadingFetchCategories } =
    useBriefingSessionCategories({
      onnEventId: props.onnEvent.id,
    });

  const { enqueueSnackbar } = useSnackbar();

  const validateConditions = useCallback(
    (conditions: AnyInputCondition[]) =>
      conditions.filter(
        (c): c is AnyValidCondition => searchOnnEventSlotsByConditionSchema.safeParse(c).success
      ),
    []
  );

  const validConditions: AnyValidCondition[] = useMemo(
    () => validateConditions(conditions),
    [conditions, validateConditions]
  );

  const throttledOnChange = useMemo(
    () =>
      throttle(async (logicType: LogicType, conditions: AnyInputCondition[]) => {
        const validConditions = validateConditions(conditions);
        if (validConditions.length === 0) {
          switch (props.modalType) {
            case "slot":
              setSearchResultCount(props.allSlotsCount);
              break;
            case "answer":
              setSearchResultCount(props.onnEventAnswersForDisplay?.length ?? 0);
              break;
            default: {
              const _exhaustiveCheck: never = props.modalType;
              return _exhaustiveCheck;
            }
          }

          return;
        }

        const { data: slotIds } = await apiClient
          .post("/onn_event_api/slots/search-by-conditions", {
            type: logicType,
            conditions: validConditions,
            onnEventId: props.onnEvent.id,
          })
          .catch((e) => {
            enqueueSnackbar("検索に失敗しました。通信環境をご確認の上、再度お試しください。", {
              variant: "error",
            });
            throw e;
          })
          .finally(() => setLoadingChange(false));

        switch (props.modalType) {
          case "slot": {
            setSearchResultCount(slotIds.length);
            break;
          }
          case "answer": {
            // NOTE: 面談予定の詳細検索で使用する場合は、検索結果に一致する予約枠に紐づく回答をカウントする
            const searchedAnswers = props.onnEventAnswersForDisplay?.filter((answer) =>
              slotIds.includes(answer.selectedOnnEventSlotDateId || "")
            );
            setSearchResultCount(searchedAnswers?.length ?? 0);
            break;
          }
          default: {
            const _exhaustiveCheck: never = props.modalType;
            return _exhaustiveCheck;
          }
        }
      }, 1000),
    [
      enqueueSnackbar,
      props.allSlotsCount,
      props.modalType,
      props.onnEvent.id,
      props.onnEventAnswersForDisplay,
      validateConditions,
    ]
  );

  const onChangeCondition = useCallback(
    (index: number, changedCondition: AnyInputCondition) => {
      const changedConditions = conditions.map((c) => {
        if (c === conditions[index]) return changedCondition;
        return c;
      });
      setConditions(changedConditions);

      throttledOnChange(logicType, validateConditions(changedConditions));
    },
    [conditions, logicType, throttledOnChange, validateConditions]
  );

  const handleSearch = useCallback(async () => {
    setLoadingSearch(true);
    await props.onSearchConfirm(logicType, validConditions).finally(() => setLoadingSearch(false));
    props.onCancel();
  }, [logicType, props, validConditions]);

  const addCondition = useCallback(() => {
    setConditions([...conditions, { target: undefined }]);
  }, [conditions]);

  const clearAllConditions = useCallback(() => {
    setConditions([{ target: undefined }]);
    throttledOnChange(logicType, [{ target: undefined }]);
  }, [logicType, throttledOnChange]);

  const onChangeTarget = useCallback(
    (index: number, changedTarget: AnyTarget) => {
      const exTarget = conditions[index]?.target;
      const isChanged = changedTarget !== exTarget;
      if (isChanged) {
        const cloned = [...conditions];
        cloned.splice(index, 1, { target: changedTarget });
        setConditions(cloned);
        throttledOnChange(logicType, cloned);
      }
    },
    [conditions, logicType, throttledOnChange]
  );

  const onSelectLogicType = useCallback(
    (logicType: LogicType) => {
      setLogicType(logicType);
      throttledOnChange(logicType, conditions);
    },
    [conditions, throttledOnChange]
  );

  const removeCondition = useCallback(
    (index: number) => {
      const isLastOne = conditions.length === 1;
      if (isLastOne) {
        setConditions([{ target: undefined }]);
        throttledOnChange(logicType, [{ target: undefined }]);
        return;
      }
      const updatedconditions = conditions.filter((_, i) => i !== index);
      setConditions(updatedconditions);
      throttledOnChange(logicType, updatedconditions);
    },
    [conditions, logicType, throttledOnChange]
  );

  return (
    <ContextOfOnnEventSlotSearchModal.Provider
      value={{
        onnEvent: props.onnEvent,
        conditions,
        logicType,
        searchResultCount,
        briefingSessionCategories,
        isLoadingSearch,
        isLoadingChange,
        isLoadingFetchCategories,
        modalType: props.modalType,
        addCondition,
        removeCondition,
        clearAllConditions,
        onChangeTarget,
        onSelectLogicType,
        onChangeCondition,
        handleSearch,
      }}
    >
      {props.children}
    </ContextOfOnnEventSlotSearchModal.Provider>
  );
};
