import { AllContactRoom } from "@onn/common";
import { useCallback } from "react";

export const useFilterByIsInvited = () => {
  const filterByIsInvited = useCallback((contactRooms: AllContactRoom[]) => {
    return contactRooms.filter((contactRoom) => {
      if (contactRoom.type === "LINE_USER") return true;
      if (!contactRoom.employee) return true;
      return contactRoom.employee.isNotRegisteredAndInvited() ? false : true;
    });
  }, []);

  return {
    filterByIsInvited,
  };
};
