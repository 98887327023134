import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { Draggable, DropResult } from "react-beautiful-dnd";

import styled from "styled-components";

import { Action, State } from "../_share_in_create_edit/reducer";

import {
  CheckBoxQuestionFormMemo,
  Props as CheckBoxQuestionFormProps,
} from "./components/CheckBoxQuestionForm";

import {
  FileQuestionFormMemo,
  Props as FileQuestionFormProps,
} from "./components/FileQuestionForm";
import {
  RadioQuestionFormMemo,
  Props as RadioQuestionFormProps,
} from "./components/RadioQuestionForm";
import {
  TextQuestionFormMemo,
  Props as TextQuestionFormProps,
} from "./components/TextQuestionForm";

import { DnDDroppable, DnDProvider } from "~/components/uiParts";

// NOTE: 新しいタスクオプションタイプを追加した際に、追加し忘れた時にタイプエラーになるよう実装をする
type TaskOptionTypesComponentMap = {
  CHECK_BOX: React.NamedExoticComponent<CheckBoxQuestionFormProps>;
  TEXT: React.NamedExoticComponent<TextQuestionFormProps>;
  RADIO: React.NamedExoticComponent<RadioQuestionFormProps>;
  FILE: React.NamedExoticComponent<FileQuestionFormProps>;
};

const TaskOptionTypesLabelObj: TaskOptionTypesComponentMap = {
  CHECK_BOX: CheckBoxQuestionFormMemo,
  TEXT: TextQuestionFormMemo,
  RADIO: RadioQuestionFormMemo,
  FILE: FileQuestionFormMemo,
};

export const OnnFormTaskQuestions: FC<{ state: State; dispatch: React.Dispatch<Action> }> = ({
  state,
  dispatch,
}) => {
  const onChangeExperiencesOrder = (result: DropResult) => {
    // drop先がない場合は処理を終了
    if (!result.destination) return;

    // drag開始元とdrop先を取得
    const { index: sourceIndex } = result.source;
    const { index: destinationIndex } = result.destination;

    // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
    if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

    dispatch({
      type: "CHANGE_QUESTION_ORDER",
      payload: {
        questionId: (state.questions[sourceIndex] as (typeof state.questions)[number]).id,
        sourceIndex,
        destinationIndex,
      },
    });
  };

  return (
    <DnDProvider onDragEnd={onChangeExperiencesOrder}>
      <DnDDroppable droppableId="droppableId" isDropDisabled={false}>
        <Box my="32px" display="flex" flexDirection="column" gridRowGap="32px">
          {state.questions.map((question, i) => {
            return (
              // NOTE: ドラッグ可能な範囲とドラッグするコンポートが異なるため、ライブラリの方を直接利用する
              <Draggable draggableId={question.id} index={i} key={question.id}>
                {(provided, snapshot) => (
                  <StyledDnDEffect
                    $isDragging={snapshot.isDragging}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    {question.type === "TEXT" && (
                      <TaskOptionTypesLabelObj.TEXT
                        dispatch={dispatch}
                        textQuestion={question}
                        questionDragHandleProps={provided.dragHandleProps}
                      />
                    )}
                    {question.type === "CHECK_BOX" && (
                      <TaskOptionTypesLabelObj.CHECK_BOX
                        dispatch={dispatch}
                        checkBoxQuestion={question}
                        questionDragHandleProps={provided.dragHandleProps}
                      />
                    )}
                    {question.type === "FILE" && (
                      <TaskOptionTypesLabelObj.FILE
                        dispatch={dispatch}
                        fileQuestion={question}
                        questionDragHandleProps={provided.dragHandleProps}
                      />
                    )}
                    {question.type === "RADIO" && (
                      <TaskOptionTypesLabelObj.RADIO
                        dispatch={dispatch}
                        radioQuestion={question}
                        questionDragHandleProps={provided.dragHandleProps}
                      />
                    )}
                  </StyledDnDEffect>
                )}
              </Draggable>
            );
          })}
        </Box>
      </DnDDroppable>
    </DnDProvider>
  );
};

const StyledDnDEffect = styled.div<{ $isDragging: boolean }>`
  box-shadow: ${({ $isDragging }) => ($isDragging ? "0px 0px 10px #40404033" : "none")};
  background: #fff;
  border-radius: 8px;
`;
