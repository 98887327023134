import { Box } from "@material-ui/core";
import React, { memo } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

export const TotalCountHeaderCell = memo(
  ({
    numberOfDistribution,
    numberOfResponses,
  }: {
    numberOfDistribution: number;
    numberOfResponses: number;
  }) => {
    return (
      <StyledHeaderCellBox>
        <Typography
          variant="body2"
          color="textSecondary"
          // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
          style={{ lineHeight: 1.5 }}
        >
          回答済み：{numberOfResponses}
          <br />
          未回答：{numberOfDistribution - numberOfResponses}
        </Typography>
      </StyledHeaderCellBox>
    );
  }
);

const StyledHeaderCellBox = styled(Box)`
  height: 100%;
  width: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 0px 1px 0px 0px;
  display: flex;
  align-items: center;
`;
