import { Role } from "@onn/common";
import { useCallback, useState } from "react";

import { useSnackbar } from "../shared";

import { AccountSettingUseCase } from "~/service/usecases/accountSettingUseCase";

type UserDataType = { email: string; role: Role };

const accountSettingUseCase = new AccountSettingUseCase();
/**
 * emailをもとにadminアカウントを作成して招待する関数を提供するhooks
 */
export const useCreateAdminAccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * emailをもとにadminアカウントを作成して招待する
   * @param {string} email
   */
  const createAdminAccount = useCallback(
    async (userDataArray: UserDataType[]) => {
      setIsLoading(true);
      await accountSettingUseCase
        .createAdminAccount(userDataArray)
        .then(() => {
          enqueueSnackbar("招待メールを送信しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar("招待の送信に失敗しました", { variant: "error" });
          throw new Error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  return {
    isLoading,
    createAdminAccount,
  };
};
