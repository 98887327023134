import { Box } from "@material-ui/core";
import { OnnTaskQuestion } from "@onn/common";
import React, { FC, useMemo } from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as VirtualizedList,
  WindowScroller,
} from "react-virtualized";

import { Action, State } from "../../reducer";
import { CheckBoxQuestionForm } from "../form/CheckBoxQuestionForm";
import { FileQuestionForm } from "../form/FileQuestionForm";
import { RadioQuestionForm } from "../form/RadioQuestionForm";
import { TextQuestionForm } from "../form/TextQuestionForm";

import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { extractFileNameFromPath } from "~/pages/onn_task/_share_in_create_edit/extractFileNameFromPath";
type Props = {
  questions: OnnTaskQuestion[];
  state: State;
  dispatch: React.Dispatch<Action>;
  sampleFileSignedUrlMapObj?: Record<string, string>;
  onChangeFile: (payload: Payload, questionId: string) => void;
};

const MIN_ROW_HEIGHT = 200;

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: MIN_ROW_HEIGHT,
});

export const AnswerEditor: FC<Props> = ({
  questions,
  state,
  dispatch,
  sampleFileSignedUrlMapObj,
  onChangeFile,
}) => {
  const questionIdsToAnswerMap = useMemo(
    () => new Map(state.answers.map((q) => [q.questionId, q])),
    [state.answers]
  );

  return (
    <WindowScroller>
      {({ height, isScrolling, scrollTop, onChildScroll }) => (
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <VirtualizedList
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                scrollTop={scrollTop}
                autoHeight
                height={height}
                width={width}
                overscanRowCount={5}
                rowCount={questions.length}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={({ index, key, style, parent }) => {
                  const question = questions[index] as (typeof questions)[number];
                  const answer = questionIdsToAnswerMap.get(question.id);
                  return (
                    <CellMeasurer
                      key={key}
                      cache={cache}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}
                    >
                      {({ measure }) => (
                        <Box style={style} minHeight={MIN_ROW_HEIGHT} pb="40px">
                          {question.type === "TEXT" && (
                            <TextQuestionForm
                              question={question}
                              dispatch={(args) => {
                                dispatch(args);
                                measure();
                              }}
                              answer={{
                                text: answer?.type === "TEXT" ? answer.value : "",
                              }}
                            />
                          )}
                          {question.type === "RADIO" && (
                            <RadioQuestionForm
                              question={question}
                              dispatch={(args) => {
                                dispatch(args);
                                measure();
                              }}
                              selectedOptionId={
                                answer?.type === "RADIO" ? answer.selectedOptionId : ""
                              }
                            />
                          )}

                          {question.type === "CHECK_BOX" && (
                            <CheckBoxQuestionForm
                              question={question}
                              dispatch={(args) => {
                                dispatch(args);
                                measure();
                              }}
                              answer={{
                                freeText: answer?.type === "CHECK_BOX" ? answer.freeText : "",
                                selectedOptionIds:
                                  answer?.type === "CHECK_BOX" ? answer.selectedOptionIds : [],
                              }}
                            />
                          )}

                          {question.type === "FILE" && (
                            <FileQuestionForm
                              question={question}
                              answer={{
                                fileName:
                                  answer?.type === "FILE"
                                    ? extractFileNameFromPath(answer?.filePath)
                                    : "",
                              }}
                              sampleFileSignedUrl={
                                question.sampleFilePath
                                  ? sampleFileSignedUrlMapObj?.[question.sampleFilePath]
                                  : undefined
                              }
                              onChangeFile={onChangeFile}
                            />
                          )}
                        </Box>
                      )}
                    </CellMeasurer>
                  );
                }}
              />
            );
          }}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};
