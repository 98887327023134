import {
  EmployeeInformationDateTypeField,
  employeeInformationDateTypeFieldSchema,
  EmployeeInformationMultipleSelectTypeField,
  employeeInformationMultipleSelectTypeFieldSchema,
  employeeInformationMultipleTypeOptionSchema,
  EmployeeInformationSingleSelectTypeField,
  employeeInformationSingleSelectTypeFieldSchema,
  employeeInformationSingleTypeOptionSchema,
  EmployeeInformationTextTypeField,
  employeeInformationTextTypeFieldSchema,
} from "@onn/common";
import { z } from "zod";

export const formSchema = z.array(
  z.union([
    employeeInformationTextTypeFieldSchema.merge(
      z.object({
        type: z.literal(EmployeeInformationTextTypeField.type),
      })
    ),
    employeeInformationSingleSelectTypeFieldSchema.merge(
      z
        .object({
          type: z.literal(EmployeeInformationSingleSelectTypeField.type),
        })
        .merge(z.object({ options: z.array(employeeInformationSingleTypeOptionSchema) }))
    ),
    employeeInformationMultipleSelectTypeFieldSchema.merge(
      z
        .object({
          type: z.literal(EmployeeInformationMultipleSelectTypeField.type),
        })
        .merge(z.object({ options: z.array(employeeInformationMultipleTypeOptionSchema) }))
    ),
    employeeInformationDateTypeFieldSchema.merge(
      z.object({
        type: z.literal(EmployeeInformationDateTypeField.type),
      })
    ),
  ])
);

export type InputState = { employeeInformationFields: z.infer<typeof formSchema> };
