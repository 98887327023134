export const getHowLongAgo = (date: Date): string => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  if (diffDays === 0) {
    const diffHours = Math.floor(diff / (1000 * 60 * 60));
    if (diffHours === 0) {
      return "1時間以内";
    }
    return `${diffHours}時間前`;
  } else {
    return `${diffDays}日前`;
  }
};
