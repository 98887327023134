import { Box } from "@mui/material";
import React, { FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import styled from "styled-components";

import { useRichMenuTabContext } from "../../RichMenuTabContext";
import { InputState } from "../../useForm";

import { SelectForm, Tooltip } from "~/components/uiParts";

export const DisplaySelector: FC = () => {
  const { control, trigger, clearErrors } = useFormContext<InputState>();
  const { field } = useController({ control, name: "isSetRichMenu" });
  const { isAlreadySettingRichMenu } = useRichMenuTabContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    field.onChange(e.target.value);
    if (e.target.value === "false") {
      trigger();
    } else {
      clearErrors();
    }
  };
  // NOTE: 初期はリッチメニューを削除できないようにする
  const isDisabled = isAlreadySettingRichMenu;
  return (
    <Tooltip
      title={isDisabled ? "リッチメニューの取り外しはできません。" : undefined}
      isWrapSpan={false}
    >
      {/* tooltipのchildrenにはrefを渡す必要があるのでBoxでラップしている */}
      <Box>
        <StyledSelectForm
          onChange={onChange}
          menuItems={[
            { value: "true", name: "リッチメニュー表示あり" },
            { value: "false", name: "表示しない" },
          ]}
          fullWidth
          selected={field.value}
          disabled={isDisabled}
        />
      </Box>
    </Tooltip>
  );
};

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    min-width: 100px;
    height: 40px;
    font-size: 16px;
  }
  .MuiTypography-root {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
