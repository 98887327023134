import { z } from "zod";

import { OnnEventDeterminedDateSchema } from "../../../domain/_gen/zodSchema";

const AttendanceStatuses = ["ATTENDED", "ABSENT", "UNREGISTERED"] as const;
export type AttendanceStatus = (typeof AttendanceStatuses)[number];

export const onnEventDeterminedDateSchema = OnnEventDeterminedDateSchema.merge(
  z.object({
    onnEventSlotDateId: z.string().optional(),
    attendanceStatus: z.enum(AttendanceStatuses),
    latestSentAtOnlineUrlNotification: z.date().optional(),
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventDeterminedDate extends z.infer<typeof onnEventDeterminedDateSchema> {}
