import { v4 } from "uuid";

import { ISpaceSetting, spaceSettingSchema } from "./schema";

export class SpaceSetting implements ISpaceSetting {
  static validator = spaceSettingSchema;
  id: string;
  spaceId: string;
  tenantId: string;
  registrationMessageForPreEntry?: string;
  shouldCancelDeliveryRegardlessOfScenario: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<SpaceSetting>) {
    this.id = init.id;
    this.spaceId = init.spaceId;
    this.tenantId = init.tenantId;
    this.registrationMessageForPreEntry = init.registrationMessageForPreEntry;
    this.shouldCancelDeliveryRegardlessOfScenario = init.shouldCancelDeliveryRegardlessOfScenario;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static create(
    init: Pick<
      ExcludeMethods<SpaceSetting>,
      | "spaceId"
      | "tenantId"
      | "registrationMessageForPreEntry"
      | "shouldCancelDeliveryRegardlessOfScenario"
    >
  ) {
    const createdAt = new Date();
    const updatedAt = new Date();
    const parsedInit = SpaceSetting.validator.parse({
      ...init,
      id: v4(),
      createdAt,
      updatedAt,
    });

    return new SpaceSetting(parsedInit);
  }

  update(init: { registrationMessageForPreEntry?: string }) {
    return new SpaceSetting({
      ...this,
      ...init,
      updatedAt: new Date(),
    });
  }
}
