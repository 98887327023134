import { Box } from "@material-ui/core";
import { BriefingSessionEvent, NewInterviewEvent, OnnEventSlotDate } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import {
  ContextProviderOfListOfEmployeesParticipatingForSlotModal,
  useContextOfListOfEmployeesParticipatingForSlotModal,
} from "./Context";

import { EmployeeRow } from "./components/EmployeeRow/EmployeeRow";

import {
  Button,
  Divider,
  Icon,
  Loading,
  ScrollableBodyModal,
  Typography,
} from "~/components/uiParts";
import { colors } from "~/config/theme";
import { useModal } from "~/hooks/modal";
import { captureException } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: NewInterviewEvent | BriefingSessionEvent;
  slotId: string;
  briefingSessionCategoryId?: string;
  fromDate: Date;
  untilDate: Date;
  type: OnnEventSlotDate["eventType"];
  isReservable: boolean;
};

export const ListOfEmployeesParticipatingForSlotDate: FC<Props> = (props) => {
  return (
    <ContextProviderOfListOfEmployeesParticipatingForSlotModal
      onnEvent={props.onnEvent}
      slotId={props.slotId}
      slotFrom={props.fromDate}
      slotUntil={props.untilDate}
    >
      <ListOfEmployeesParticipatingForSlotDateCore {...props} />
    </ContextProviderOfListOfEmployeesParticipatingForSlotModal>
  );
};

export const ListOfEmployeesParticipatingForSlotDateCore: FC<Props> = ({
  open,
  onCancel,
  slotId,
  briefingSessionCategoryId,
  fromDate,
  untilDate,
  type: _type,
  isReservable,
}) => {
  const { handleModal } = useModal();
  // ex) 2024/10/4(月) 15:00〜16:00
  const dateAndTime = `${format(fromDate, "yyyy/MM/dd(E) HH:mm", { locale: ja })}〜${format(
    untilDate,
    "HH:mm",
    { locale: ja }
  )}`;
  const type = _type === "offline" ? "オフライン・現地" : "オンライン";

  const { onnEvent, determinedDatesWithEmployee, isLoading } =
    useContextOfListOfEmployeesParticipatingForSlotModal();
  const openNewInterviewAnswerModal = useCallback(() => {
    handleModal({
      name: "answerEventOnBehalfModal",
      args: {
        onnEvent,
        fixedOption: {
          selectedSlotDateId: slotId,
          selectedBriefingSessionCategoryId: briefingSessionCategoryId,
        },
        mode: {
          type: "create",
        },
        slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
      },
    });
  }, [briefingSessionCategoryId, handleModal, onnEvent, slotId]);

  const ModalContent = useCallback(
    () => (
      <Box display="flex" flexDirection="column" gridRowGap="24px" style={{ overflowY: "scroll" }}>
        <SlotInfoArea dataAndTime={dateAndTime} type={type} />
        <Box
          display="flex"
          gridRowGap={"8px"}
          flexDirection={"column"}
          minHeight={"312px"}
          justifyContent={determinedDatesWithEmployee.length === 0 ? "center" : "flex-start"}
          alignItems={"center"}
          style={{ overflowY: "scroll" }}
        >
          {determinedDatesWithEmployee?.length === 0 ? (
            <Typography
              variant="body1"
              color="textPrimary"
              component={"div"}
              style={{ height: "100%" }}
            >
              予約している候補者がいません。
            </Typography>
          ) : (
            determinedDatesWithEmployee?.map((determinedDateWithEmployee) => {
              const employee = determinedDateWithEmployee.employee;
              if (!employee) {
                captureException({
                  error: new Error("employee is not found in determinedDateWithEmployee."),
                  tags: {
                    type: "determinedDateWithEmployee",
                  },
                  extras: { determinedDateWithEmployee },
                });
                return null;
              }
              return (
                <EmployeeRow
                  key={determinedDateWithEmployee.id}
                  determinedDateWithEmployee={Object.assign(determinedDateWithEmployee, {
                    employee,
                  })}
                />
              );
            })
          )}
        </Box>
        {isReservable && (
          <>
            <Divider />
            <Box display="flex" justifyContent="start">
              <Button
                onClick={openNewInterviewAnswerModal}
                color="primary"
                variant="outlined"
                borderRadius="regular"
                startIcon={<Icon size="sm" icon="add" color="primary" />}
              >
                代理予約
              </Button>
            </Box>
          </>
        )}
      </Box>
    ),
    [dateAndTime, determinedDatesWithEmployee, openNewInterviewAnswerModal, type, isReservable]
  );

  return (
    <StyledModal
      open={open}
      title="予約確認"
      onCancel={onCancel}
      content={
        isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="400px">
            <Loading size="small" />
          </Box>
        ) : (
          <ModalContent />
        )
      }
    />
  );
};

const SlotInfoArea: FC<{ dataAndTime: string; type: string }> = ({ dataAndTime, type }) => {
  return (
    <Box
      height={"56px"}
      display="flex"
      alignItems={"center"}
      p="16px"
      style={{ backgroundColor: colors.grey["50"], borderRadius: "4px" }}
    >
      <Box width="80px" mr="8px">
        <Typography variant="body2" color="textPrimary" bold>
          開催日時
        </Typography>
      </Box>
      <Box width="248px">
        <Typography variant="body2" color="textPrimary">
          {dataAndTime}
        </Typography>
      </Box>
      <Box width="80px" mr="8px">
        <Typography variant="body2" color="textPrimary" bold>
          開催方法
        </Typography>
      </Box>
      <Box width="248px">
        <Typography variant="body2" color="textPrimary">
          {type}
        </Typography>
      </Box>
    </Box>
  );
};

const StyledModal = styled(ScrollableBodyModal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;
