import { Box, DialogActions } from "@material-ui/core";

import React, { FC } from "react";
import { AutoSizer, MultiGrid } from "react-virtualized";
import styled from "styled-components";

import { createBodyRowDataArray } from "./parts/createBodyRowData";

import { useTableView } from "./parts/useTableView";

import { Button, Modal } from "~/components/uiParts";
import { RecruitmentProcessRecordByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  recruitmentProcessRecord: RecruitmentProcessRecordByEmployee;
};

export const RecruitmentProcessDetailModal: FC<Props> = ({
  open,
  onCancel,
  recruitmentProcessRecord,
}) => {
  const bodyRowDataArray = createBodyRowDataArray(recruitmentProcessRecord);
  const tableViewInfo = useTableView({ bodyRowDataArray, onCancel });

  const Content = (
    <StyledDetailBox>
      <AutoSizer disableHeight>
        {({ width }) => {
          return (
            <StyledBox width={width}>
              <MultiGrid
                {...tableViewInfo}
                height={tableViewInfo.multiGridHight}
                width={width}
                fixedColumnCount={1}
                fixedRowCount={1}
                enableFixedColumnScroll={true}
                ref={tableViewInfo.multiGridRef}
              />
            </StyledBox>
          );
        }}
      </AutoSizer>
    </StyledDetailBox>
  );

  const Footer = (
    <StyledDialogActions>
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          閉じる
        </Button>
      </StyledButtonContainer>
    </StyledDialogActions>
  );

  return (
    <Modal
      open={open}
      title={`${recruitmentProcessRecord.recruitmentStatusLabel}｜詳細ステータス`}
      content={Content}
      footer={Footer}
      onCancel={onCancel}
    />
  );
};

const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    text-align: center;
    display: block;
  }
`;

const StyledDetailBox = styled(Box)`
  padding-top: 16px;
  text-align: center;
`;

const StyledButtonContainer = styled(Box)`
  background-color: ${(props) => props.theme.palette.background.default};
  ${mixin.fixedWidthButtonContainer}
`;

const StyledBox = styled(Box)`
  .ReactVirtualized__Grid:first-child {
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
