import { Box } from "@material-ui/core";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { mixin } from "~/util";

type Props = {
  children: ReactNode;
  isWhiteBackgroundForSp: boolean;
};

export const PageWrapper: FC<Props> = ({ children, isWhiteBackgroundForSp }) => {
  return (
    <StyledBox maxWidth="848px" $isWhiteBackgroundForSp={isWhiteBackgroundForSp}>
      {children}
    </StyledBox>
  );
};

const StyledBox = styled(Box)<{ $isWhiteBackgroundForSp: boolean }>`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;
  display: flex;
  flex-direction: column;
  grid-gap: 40px;

  ${(props) => mixin.portalSp`
    background-color: ${props.$isWhiteBackgroundForSp ? "white" : "none"};
    padding: 40px 24px;
    width: 100%;
    height: 100%;
  `}
`;
