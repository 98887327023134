import { OnnEventPlace } from "@onn/common";
import { useCallback } from "react";
import useSWR, { mutate } from "swr";

import { useCurrentUser } from "../employee";
import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

const generateKey = (arg: { tenantId: string; spaceId: string }) =>
  ["/onn_event_place_api/onn-event-places", arg.tenantId, arg.spaceId] as const;

export const useOnnEventPlaces = () => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();
  return useSWR(
    generateKey({
      tenantId: currentUser.tenantId,
      spaceId: currentSpace.id,
    }),
    async ([endpoint]) => {
      const response = await apiClient.get(endpoint);
      return response.data.map((d) => new OnnEventPlace(d));
    }
  );
};

export const useMutateOnnEventPlaces = () => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  const mutateOnnEventPlaces = useCallback(async () => {
    await mutate(
      generateKey({
        tenantId: currentUser.tenantId,
        spaceId: currentSpace.id,
      })
    );
  }, [currentSpace.id, currentUser.tenantId]);

  return { mutateOnnEventPlaces };
};
