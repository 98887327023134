import React, { FC } from "react";

export const Line: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="none">
      <g clipPath="url(#clip0_98_3721)">
        <path d="M18 6H6V8H18V6Z" />
        <path d="M18 11H6V13H18V11Z" />
        <path d="M14 16H6V18H14V16Z" />
      </g>
    </svg>
  );
};
