import { Box } from "@material-ui/core";
import React from "react";
import QRCode from "react-qr-code";

import { ConductorPaperToAddCondition } from "../../_share_InvitationQR/ConductorPaperToAddCondition";

export const ModalContent = ({
  toAddConditionMode,
  qrCodeDivRef,
  registrationInvitationLinkUrl,
  isCreatedRegistrationInvitationLink,
}: {
  toAddConditionMode: () => void;
  qrCodeDivRef: React.RefObject<HTMLDivElement>;
  registrationInvitationLinkUrl: string;
  isCreatedRegistrationInvitationLink: boolean;
}) => {
  return (
    <Box>
      <ConductorPaperToAddCondition
        isAddedCondition={isCreatedRegistrationInvitationLink}
        onClickToAddCondition={toAddConditionMode}
      />
      <Box
        mt="32px"
        mb="24px"
        display="flex"
        justifyContent="center"
        height="200px"
        alignItems="center"
      >
        <div ref={qrCodeDivRef}>
          <QRCode
            style={{ height: "156px", width: "156px" }}
            value={registrationInvitationLinkUrl}
          />
        </div>
      </Box>
    </Box>
  );
};
