import React, { FC } from "react";

import { Loading } from "~/components/uiParts";
import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";

type Props = {
  onnTaskId: string;
};

export const NumberOfResponses: FC<Props> = ({ onnTaskId }) => {
  const { data: onnFormTaskAnswers, isLoading } = useOnnFormTasksAnswersOnlyCountable({
    onnTaskId,
  });
  if (isLoading) return <Loading size="small" fullHeight />;

  return <>{onnFormTaskAnswers?.filter((v) => v.isAnswered()).length}</>;
};
