import { Box, TextField } from "@material-ui/core";
import React, { FC, useRef } from "react";
import styled from "styled-components";

import { Checkbox, RadioButton } from "~/components/uiParts";

export const AddOptionRow: FC<{
  handleAddOption: () => void;
  questionType: "CHECK_BOX" | "RADIO";
}> = ({ handleAddOption: _handleAddOption, questionType }) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleAddOption = () => {
    _handleAddOption();
    ref.current?.blur();
  };
  return (
    <Box
      display={"flex"}
      sx={{
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {/* 後続TODO: 並び替え実装 */}
      {questionType === "CHECK_BOX" && <Checkbox onChange={undefined} disabled />}
      {questionType === "RADIO" && <RadioButton onChange={undefined} disabled />}

      <StyledTextFieldForAddOption
        placeholder="選択肢を追加"
        onClick={() => handleAddOption()}
        value={""}
        inputRef={ref}
      />
      {/* TODO: 「その他」を実装 */}
      {/* <Typography color="textSecondary">または</Typography>
      <AddOtherButton color="primary">「その他」を追加</AddOtherButton> */}
    </Box>
  );
};

const StyledTextFieldForAddOption = styled(TextField)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 0px;
  }
  .MuiInputBase-input {
    width: 100px;
  }
`;
