import { MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT, splitSearchString } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { AnyValidCondition } from "~/components/domains/employees/NewGraduateSearchModal/types/condition";

import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";

import { useCurrentUser } from "~/hooks/employee";
import { apiClient } from "~/libs";

const getKey = ({
  tenantId,
  type,
  conditions,
  keywordsString: _keywordsString,
}: {
  tenantId: string;
  type: LogicType;
  conditions: AnyValidCondition[];
  keywordsString: string;
}) => {
  // 検索キーワードは5つまでに制限する
  // SWR のキーを変えないようにするため、5つまでのキーワードを使ったキーワード文字列を生成してキーに含める
  const firstFiveKeywords = splitSearchString(_keywordsString).slice(
    0,
    MAX_FREE_TEXT_SEARCH_KEYWORD_COUNT
  );
  const keywordsString = firstFiveKeywords.join(" ");

  return {
    endpoint: "/api/new-graduates/search/ids",
    tenantId,
    type,
    conditions,
    keywordsString,
  } as const;
};

export const useSearchNewGraduateIds = ({
  type,
  conditions,
  keywordsString,
}: {
  type: LogicType;
  conditions: AnyValidCondition[];
  keywordsString: string;
}): SWRResponse<Set<string>, Error> => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    getKey({ tenantId: currentUser.tenantId, type, conditions, keywordsString }),
    async ({ endpoint, type, conditions, keywordsString }) => {
      const response = await apiClient.post(endpoint, {
        type,
        conditions,
        keywordsString,
      });

      return new Set(response.newGraduateIds);
    },
    { keepPreviousData: true }
  );
};
