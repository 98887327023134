import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnnEvent, Employee } from "@onn/common";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { isEmpty } from "lodash";
import React, { useState, FC, MouseEvent, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ContentWithLabel } from "./ContentWithLabel";

import {
  IconButton,
  Paper,
  Typography,
  Tooltip,
  UserIcon,
  TooltipWhenTextTruncated,
  Loading,
} from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import { useDeleteOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";

export type Props = {
  currentUser: Employee;
  onnEvent: OnnEvent;
  assigneeEmployees: Employee[];
  isRounded?: boolean;
  onClickAssignButton: () => void;
};

export const EventDetailSummaryPaper: FC<Props> = memo(function EventDetailSummary({
  currentUser,
  isRounded = true,
  onnEvent,
  assigneeEmployees,
  onClickAssignButton,
}) {
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { handleModal } = useModal();
  const { execDeleteOnnEvent } = useDeleteOnnEvent();
  const { data: onnEventAnswers = [], isLoading: isLoadingOnnEventAnswers } = useOnnEventAnswers({
    onnEventId: onnEvent.id,
  });

  // 回答者数
  const numberOfResponses = onnEventAnswers.filter(
    (v) => Object.keys(v.answer).length !== 0
  ).length;

  // 配信数
  const numberOfDistribution = onnEventAnswers.length;

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onClickDeliverySetting = () => {
    handleCloseMenu();
    navigation(`/events/${onnEvent.id}/delivery_setting/?from_page=detail`);
  };

  const onClickEditOnnEvent = () => {
    handleCloseMenu();
    navigation(`/events/${onnEvent.id}/edit/?from_page=detail`);
  };

  const onClickDeleteButton = () => {
    handleCloseMenu();
    handleModal({
      name: "eventDeleteConfirmModal",
      args: {
        eventTitle: onnEvent.title,
        onSubmit: async () => {
          await execDeleteOnnEvent(onnEvent.id);
          navigation(`/events?from_page=detail`);
        },
      },
    });
  };

  const openQRCodeModal = () => {
    handleModal({
      name: "eventAttendanceQRModal",
      args: {
        onnEvent,
      },
    });
  };

  return (
    <StyledPaper square={!isRounded}>
      <Box display="flex" alignItems="center" justifyContent="space-between" height={68}>
        <Box display="flex">
          <Box display="flex" alignItems="center" maxWidth="350px">
            <TooltipWhenTextTruncated text={onnEvent.title}>
              {(ref) => (
                <StyledTitle ref={ref} bold variant="h4">
                  {onnEvent.title}
                </StyledTitle>
              )}
            </TooltipWhenTextTruncated>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box minWidth={50} onClick={onClickAssignButton}>
            <AssigneeEmployees assigneeEmployees={assigneeEmployees} />
          </Box>
          <Box ml="40px" minWidth={50}>
            <NumberOfDistribution
              isLoading={isLoadingOnnEventAnswers}
              count={numberOfDistribution}
            />
          </Box>
          <Box ml="40px" minWidth={50}>
            <NumberOfResponses isLoading={isLoadingOnnEventAnswers} count={numberOfResponses} />
          </Box>
          {onnEvent.type === "normal" && (
            <Box ml="40px" minWidth={50}>
              <DeadlineDate deadlineDate={onnEvent.deadlineDate} />
            </Box>
          )}
          {currentUser.isAdmin() && (
            <Box ml="40px">
              {onnEvent.type === "normal" && <IconButton icon="qrCode" onClick={openQRCodeModal} />}
              <IconButton icon="menuVert" onClick={handleOpenMenu} />
              <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuItem onClick={onClickEditOnnEvent}>編集</MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    onClickAssignButton();
                  }}
                >
                  担当者変更
                </MenuItem>
                <MenuItem onClick={onClickDeliverySetting}>配信設定</MenuItem>
                {/* NOTE: 初回リリースでは実装しない */}
                {/* <MenuItem
                onClick={() => {
                  handleCloseMenu();
                }}
              >
                招待URLコピー
              </MenuItem> */}
                <MenuItem onClick={onClickDeleteButton}>削除</MenuItem>
              </StyledMenu>
            </Box>
          )}
        </Box>
      </Box>
    </StyledPaper>
  );
});

// 担当者
const AssigneeEmployees = ({ assigneeEmployees }: { assigneeEmployees: Employee[] }) => {
  return (
    <ContentWithLabel label="担当者">
      {isEmpty(assigneeEmployees) ? (
        <Tooltip arrow placement="bottom" title={"担当者を設定する"}>
          <StyledClickableBox display="flex" alignItems="center">
            <>
              <UserIcon circular size="small" username="未登録" badgeType="dot" />
              <Box display="inline-block" width="8px" />
            </>
            <Typography variant="caption" color="textSecondary" display="block">
              未登録
            </Typography>
          </StyledClickableBox>
        </Tooltip>
      ) : (
        <StyledClickableBox display="flex" alignItems="center">
          {/* NOTE: 初回リリースは担当者は1人のみ */}
          <Box display="flex" alignItems="center" gridGap={"8px"}>
            <UserIcon
              username={(assigneeEmployees[0] as (typeof assigneeEmployees)[number]).getName()}
              profileIconImageUrl={
                (assigneeEmployees[0] as (typeof assigneeEmployees)[number]).profileIconImageUrl
              }
              size={"small"}
              circular={true}
            />

            <Box display="grid" gridRowGap="4px">
              <StyledTypography variant={"caption"} color="textPrimary" noWrap>
                {(assigneeEmployees[0] as (typeof assigneeEmployees)[number]).getName()}
              </StyledTypography>
            </Box>
          </Box>
          {/* <UserIconGroup usersInfo={supportMembersInfoForIcon} max={4} tooltip /> */}
        </StyledClickableBox>
      )}
    </ContentWithLabel>
  );
};

// 配信数
const NumberOfDistribution = ({ isLoading, count }: { isLoading: boolean; count: number }) => {
  return (
    <ContentWithLabel label="配信数">
      {isLoading ? <Loading size="small" /> : <Typography variant="h3">{count}</Typography>}
    </ContentWithLabel>
  );
};

// 回答者数
const NumberOfResponses = ({ isLoading, count }: { isLoading: boolean; count: number }) => {
  return (
    <ContentWithLabel label="回答者数">
      {isLoading ? <Loading size="small" /> : <Typography variant="h3">{count}</Typography>}
    </ContentWithLabel>
  );
};

// 回答期限
const DeadlineDate = ({ deadlineDate }: { deadlineDate: OnnEvent["deadlineDate"] }) => {
  const deadlineDateStr = deadlineDate
    ? format(deadlineDate, "〜yyyy/MM/dd", { locale: ja })
    : "未設定";
  return (
    <ContentWithLabel label="回答期限">
      <Typography variant="body1">{deadlineDateStr}</Typography>
    </ContentWithLabel>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const StyledMenu = styled(Menu)`
  margin-top: 8px;
`;

const StyledClickableBox = styled(Box)<{ $width?: number }>`
  cursor: pointer;
  ${({ $width }) => ($width ? `width: ${$width}px` : "width: 100%")}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;

const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
