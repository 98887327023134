import React, { FC } from "react";

export const ExternalPageInIframe: FC = () => {
  const params = new URLSearchParams(location.search);
  const src = params.get("src") || params.get("to") || "/"; // NOTE: toは古いリッチメニューのパラメータ名。新しいリッチメニューに切り替わったら削除する

  return (
    // スクロール時にiframeの裏のOnnが見えないようにするために、divで囲っている
    <div
      className="iframe-wrap"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 10000,
        backgroundColor: "white",
      }}
    >
      <iframe
        src={src}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 10001,
        }}
        // sandbox属性を指定した方が安全になるが、指定することでコンテンツが表示できないサイトもあるため指定しない
        // NOTE: 外部サイトにtenantIdやliffIdを漏らさないために、referrerPolicyをno-referrerに設定する
        referrerPolicy="no-referrer"
      ></iframe>
    </div>
  );
};
