import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Condition } from "../type";

import { Button } from "~/components/uiParts";

export const ModalFooter = ({
  selectedCondition,
  onAddConditions,
  onCancel,
  isLoading,
}: {
  selectedCondition: Condition;
  onAddConditions: () => Promise<void>;
  onCancel: () => void;
  isLoading: boolean;
}) => {
  const isValid =
    !!selectedCondition.recruitmentStatusId && 0 < selectedCondition.authenticationFlowTypes.length;

  return (
    <StyledButtonContainer>
      <Button
        variant="text"
        onClick={onCancel}
        color="default"
        borderRadius="circle"
        disabled={isLoading}
      >
        キャンセル
      </Button>
      <Button
        variant="contained"
        color="primary"
        borderRadius="circle"
        onClick={onAddConditions}
        disabled={isLoading || !isValid}
        isLoading={isLoading}
      >
        条件を付与
      </Button>
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: right;
`;
