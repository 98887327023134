import { Box, Tab } from "@material-ui/core";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import React, { FC } from "react";
import styled from "styled-components";

import { PlaceSettingTab } from "./tabs/PlaceSettingTab/PlaceSettingTab";

import { Typography } from "~/components/uiParts";

export const Page: FC = () => {
  return (
    <>
      <Box mb={4}>
        <Typography variant="h4" color="textPrimary" bold>
          イベント設定
        </Typography>
      </Box>
      <TabContext value={"placeSetting"}>
        <StyledTabList indicatorColor="primary" textColor="primary">
          <StyledTab key={"placeSetting"} label={"会場設定"} value={"placeSetting"} />
        </StyledTabList>
        <StyledTabPanel value="placeSetting">
          <PlaceSettingTab />
        </StyledTabPanel>
      </TabContext>
    </>
  );
};

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    padding: 0px 20px;
  }
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
