import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { mutate } from "swr";

import { generateOnnEventSlotDatesForDisplayKey } from "./useOnnEventSlotDatesForDisplay";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/api/onn-event-slot-dates/csv"]["POST"];
export const useCreateOnnEventSlotDatesWithCsv = () => {
  const createOnnEventSlotDatesWithCsv = useCallback(
    async (arg: { onnEventId: string; base64EncodedCsvFile: string; isDryRun: boolean }) => {
      const requestBody: Endpoint["body"] = {
        onnEventId: arg.onnEventId,
        base64EncodedCsvFile: arg.base64EncodedCsvFile,
        isDryRun: arg.isDryRun,
      };
      const response = await apiClient.post("/api/onn-event-slot-dates/csv", requestBody);
      mutate(generateOnnEventSlotDatesForDisplayKey(arg.onnEventId));

      return response;
    },
    []
  );

  return { createOnnEventSlotDatesWithCsv };
};
