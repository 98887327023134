import React, { FC } from "react";

export const Pencil: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        d="M12.448 3.55204C11.8811 2.98535 11.1122 2.66699 10.3106 2.66699C9.50904 2.66699 8.74029 2.98535 8.17335 3.55204L3.55201 8.17338C3.2709 8.45387 3.04794 8.78715 2.89601 9.15405C2.74407 9.52096 2.66616 9.91424 2.66668 10.3114V13.334H5.6893C6.08633 13.3346 6.47964 13.2568 6.84645 13.1048C7.21325 12.9529 7.54637 12.7299 7.82667 12.4487L12.448 7.82735C12.7288 7.54666 12.9515 7.21342 13.1034 6.84664C13.2554 6.47985 13.3337 6.08672 13.3337 5.6897C13.3337 5.29268 13.2554 4.89954 13.1034 4.53276C12.9515 4.16597 12.7288 3.83273 12.448 3.55204ZM11.166 6.54472L6.54469 11.1661C6.46527 11.2428 6.37549 11.3081 6.278 11.36L4.63868 9.72005C4.69043 9.62262 4.75553 9.53285 4.83204 9.45337L9.4533 4.83138C9.56486 4.71589 9.69834 4.62379 9.84588 4.56042C9.99341 4.49706 10.1521 4.46367 10.3127 4.46228C10.4732 4.46089 10.6325 4.49152 10.7811 4.55233C10.9297 4.61313 11.0647 4.70291 11.1782 4.81645C11.2918 4.92999 11.3816 5.06501 11.4424 5.21362C11.5032 5.36224 11.5338 5.52148 11.5324 5.68205C11.531 5.84261 11.4977 6.0013 11.4343 6.14884C11.371 6.29638 11.2788 6.42982 11.1633 6.54138L11.166 6.54472Z"
        stroke="none"
      />
    </svg>
  );
};
