import { Box } from "@material-ui/core";
import { OnnEvent, OnnEventSlotDatesForDisplay } from "@onn/common";
import React, { FC, useMemo } from "react";

import { useSlotBulkDeleteConfirmModal } from "../useSlotBulkDeleteConfirmModal";

import { useStatusBulkUpdateConfirmModal } from "../useStatusBulkUpdateConfirmModal";

import { Button, Icon, UncontrolledMenu, Typography } from "~/components/uiParts";

export const BulkOperationButton: FC<{
  onnEvent: OnnEvent;
  selectedSlotIds: string[];
  onResetSelectedSlotIds: () => void;
  slotIdToSlotForDisplayMap: Map<string, OnnEventSlotDatesForDisplay>;
}> = ({ onnEvent, selectedSlotIds, onResetSelectedSlotIds, slotIdToSlotForDisplayMap }) => {
  const selectedSlots = useMemo(() => {
    return selectedSlotIds.flatMap((slotId) => slotIdToSlotForDisplayMap.get(slotId) ?? []);
  }, [selectedSlotIds, slotIdToSlotForDisplayMap]);

  const { openStatusBulkUpdateConfirmModal } = useStatusBulkUpdateConfirmModal({
    onnEventId: onnEvent.id,
    onnEventSlotIds: selectedSlotIds,
    onResetSelectedSlotIds,
  });

  const { openSlotBulkDeleteConfirmModal } = useSlotBulkDeleteConfirmModal({
    onnEventId: onnEvent.id,
    onnEventSlotIds: selectedSlotIds,
    onResetSelectedSlotIds,
  });

  const isShowToPublicMenu = selectedSlots.every((slot) => slot.status === "draft");
  const isShowToDraftMenu = selectedSlots.every(
    (slot) => slot.getStatusForDisplay() === "adjusting" && slot.reservedCount === 0
  );

  const menuItemOptions = [
    {
      onClick: openSlotBulkDeleteConfirmModal,
      text: "予約枠削除",
    },
    isShowToPublicMenu
      ? {
          onClick: () => openStatusBulkUpdateConfirmModal("published"),
          text: "公開",
        }
      : [],
    isShowToDraftMenu
      ? {
          onClick: () => openStatusBulkUpdateConfirmModal("draft"),
          text: "非公開",
        }
      : [],
  ].flat();

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => (
        <Button
          color="primary"
          variant="outlined"
          borderRadius="regular"
          fullWidth
          onClick={openMenu}
          startIcon={<Icon icon="pencil" size="md" color="primary" />}
          endIcon={<Icon icon="dropdownArrow" size="sm" color="primary" />}
        >
          <Box width="100%" display="flex" flex={1}>
            <Typography variant="body2" bold noWrap>
              一括操作
            </Typography>
          </Box>
        </Button>
      )}
      menuItemOptions={menuItemOptions}
    />
  );
};
