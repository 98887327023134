import { OnnFormTaskAnswer, APISchema } from "@onn/common";
import useSWR, { SWRResponse, mutate } from "swr";

import { apiClient } from "~/libs";

export const useOnnFormTaskAnswers = ({
  onnTaskId,
}: {
  onnTaskId?: string;
}): SWRResponse<OnnFormTaskAnswer[], Error> => {
  const asyncJob = async ({
    onnTaskId,
    endpoint,
  }: {
    onnTaskId: string;
    endpoint: "/get_onn_form_task_answers";
  }): Promise<OnnFormTaskAnswer[]> => {
    const queryParam: APISchema[typeof endpoint]["GET"]["query"] = {
      ["onn-task-id"]: onnTaskId,
    };
    const response = await apiClient.get(endpoint, queryParam);
    return response.data.map((v) => new OnnFormTaskAnswer(v));
  };

  return useSWR(onnTaskId ? generateOnnFormTaskAnswersKey(onnTaskId) : null, asyncJob);
};

export const generateOnnFormTaskAnswersKey = (onnTaskId: string) => {
  return { endpoint: `/get_onn_form_task_answers`, onnTaskId };
};

export const mutateOnnFromTaskAnswers = (onnTaskId: string) => {
  mutate(generateOnnFormTaskAnswersKey(onnTaskId));
};
