import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React, { FC } from "react";

import { Typography } from "~/components/uiParts";

export const TitleAndDeadlineDate: FC<{
  title: string;
  deadlineDate: Date | null;
}> = ({ title, deadlineDate }) => {
  const deadlineDateStr = deadlineDate ? format(deadlineDate, "〜yyyy/MM/dd") : "";
  return (
    <Box>
      <Box mb="8px">
        <Typography variant="h2" bold>
          {title}
        </Typography>
      </Box>
      {deadlineDate && (
        <Typography variant="body2" color="textSecondary">
          回答期限：{deadlineDateStr}
        </Typography>
      )}
    </Box>
  );
};
