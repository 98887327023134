import { Failure, Success, Result, OnnEvent, CandidateDate } from "../../../../domain";
import {
  ToCloudFunctionsCompatible,
  toCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { convertUnixTimeToDateCandidateDate } from "../../_shared/convertUnixTimeToDate";
import { onnEventActionTypes } from "../shared";

import { IUpdateOnnEventRequestSchema, updateOnnEventRequestSchema } from "./schema";

// イベント編集画面からイベント内容を更新する場合と、イベント詳細画面から担当者を更新する場合で使用されるリクエストの型
export class UpdateOnnEventRequest implements IUpdateOnnEventRequestSchema {
  constructor(init: UpdateOnnEventRequest) {
    this.onnEventId = init.onnEventId;
    this.updateObject = init.updateObject;
  }

  static actionType: typeof onnEventActionTypes.updateOnnEvent = "updateOnnEvent";
  onnEventId: OnnEvent["id"];
  updateObject:
    | {
        title: OnnEvent["title"];
        content: OnnEvent["content"];
        candidateDates: OnnEvent["candidateDates"];
        isRestrictedAnswerFromNewGraduate: OnnEvent["isRestrictedAnswerFromNewGraduate"];
        isRestrictedDaysAgoWhenAnswer: OnnEvent["isRestrictedDaysAgoWhenAnswer"];
        isRestrictedEditAnswerFromNewGraduate: OnnEvent["isRestrictedEditAnswerFromNewGraduate"];
        daysAgoRestrictAnswer: OnnEvent["daysAgoRestrictAnswer"];
      }
    | {
        assigneeIds: OnnEvent["assigneeIds"];
      };

  static RequestBody: {
    onnEventId: OnnEvent["id"];
    updateObject:
      | {
          title: OnnEvent["title"];
          content: OnnEvent["content"];
          candidateDates: ToCloudFunctionsCompatible<CandidateDate>[];
          isRestrictedAnswerFromNewGraduate: OnnEvent["isRestrictedAnswerFromNewGraduate"];
          isRestrictedDaysAgoWhenAnswer: OnnEvent["isRestrictedDaysAgoWhenAnswer"];
          isRestrictedEditAnswerFromNewGraduate: OnnEvent["isRestrictedEditAnswerFromNewGraduate"];
          daysAgoRestrictAnswer: OnnEvent["daysAgoRestrictAnswer"];
        }
      | {
          assigneeIds: OnnEvent["assigneeIds"];
        };
  };

  // クライアント側でリクエストボディを作成する
  public static createRequestBody(init: UpdateOnnEventRequest) {
    return toCloudFunctionsCompatible({
      ...init,
      actionType: this.actionType,
    });
  }

  static readonly validator = updateOnnEventRequestSchema;

  public static validate(
    data: typeof UpdateOnnEventRequest.RequestBody
  ): Result<UpdateOnnEventRequest, Error> {
    const requestData = convertUnixTimeToDate(data);
    const result = UpdateOnnEventRequest.validator.safeParse(requestData);
    if (!result.success) {
      return new Failure(new Error(result.error.message));
    }

    return new Success(new UpdateOnnEventRequest(requestData));
  }
}

// unixTimeをDate型に変換する
const convertUnixTimeToDate = (
  d: typeof UpdateOnnEventRequest.RequestBody
): ExcludeMethods<UpdateOnnEventRequest> => {
  if ("candidateDates" in d.updateObject) {
    return {
      onnEventId: d.onnEventId,
      updateObject: {
        ...d.updateObject,
        candidateDates: d.updateObject.candidateDates.map((v) =>
          convertUnixTimeToDateCandidateDate(v)
        ),
      },
    };
  } else {
    return {
      onnEventId: d.onnEventId,
      updateObject: {
        ...d.updateObject,
      },
    };
  }
};
