import { BriefingSessionEvent, NewInterviewEvent } from "@onn/common";
import React, { FC, useCallback } from "react";

import { Button, Icon } from "~/components/uiParts";

import { useModal } from "~/hooks/modal";

export const AddSlotButton: FC<{ onnEvent: NewInterviewEvent | BriefingSessionEvent }> = ({
  onnEvent,
}) => {
  const { handleModal } = useModal();

  const openCreateOrEdiOnnEventSlotsModal = useCallback(() => {
    handleModal({
      name: "createOrEditOnnEventSlotDateModal",
      args: {
        data: {
          mode: "create",
          onnEvent,
        },
      },
    });
    return 0;
  }, [handleModal, onnEvent]);

  return (
    <Button
      onClick={openCreateOrEdiOnnEventSlotsModal}
      color="primary"
      variant="outlined"
      borderRadius="regular"
      startIcon={<Icon size="sm" icon="add" color="primary" />}
      fullHeight
    >
      予約枠追加
    </Button>
  );
};
