import { OnnEvent } from "@onn/common";
import { useMemo, useState } from "react";

export function useFilterByEventName(onnEvents: OnnEvent[]) {
  const [eventName, setEventName] = useState("");

  const handleChangeEventName = (eventName: string) => {
    setEventName(eventName);
  };

  const onnEventsFilteredByEventName = useMemo(() => {
    if (!eventName) return onnEvents;
    return onnEvents.filter((v) => v.title.includes(eventName));
  }, [eventName, onnEvents]);

  return {
    eventName,
    handleChangeEventName,
    onnEventsFilteredByEventName,
  };
}
