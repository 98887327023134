import { z } from "zod";

/////////////////////////////////////////
// EMPLOYEE TAG ACTION CONDITION SCHEMA
/////////////////////////////////////////

export const EmployeeTagActionConditionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  actionId: z.string(),
  employeeTagIds: z.string().array(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type EmployeeTagActionCondition = z.infer<typeof EmployeeTagActionConditionSchema>;

/////////////////////////////////////////
// EMPLOYEE TAG ACTION CONDITION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const EmployeeTagActionConditionOptionalDefaultsSchema =
  EmployeeTagActionConditionSchema.merge(
    z.object({
      createdAt: z.date().optional(),
      updatedAt: z.date().optional(),
    })
  );

export type EmployeeTagActionConditionOptionalDefaults = z.infer<
  typeof EmployeeTagActionConditionOptionalDefaultsSchema
>;

export default EmployeeTagActionConditionSchema;
