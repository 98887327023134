import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC } from "react";

import { NoneCell } from "../../../common/NoneCell";
import { Cell } from "../../_share/Cell";

import { Typography } from "~/components/uiParts";

export type RelatedInformationCellProps = {
  deadlineDate?: Date;
  isShowNoneCell: boolean;
};

export const RelatedInformationCell: FC<RelatedInformationCellProps> = ({
  deadlineDate,
  isShowNoneCell,
}) => {
  if (isShowNoneCell) {
    return <NoneCell />;
  }
  return (
    <Cell>
      <Typography variant="body2" color="textSecondary">
        {deadlineDate && `期日：${format(deadlineDate, "MM/dd(E)", { locale: ja })}`}
      </Typography>
    </Cell>
  );
};
