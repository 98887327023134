import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

type Body = APISchema["/api/contact-room/patch-read-log-and-unread-count"]["PATCH"]["body"];

export const useUpdateReadLogAndUnreadCount = () => {
  const updateReadLogAndUnreadCount = useCallback(async (contactRoomId: string) => {
    const body: Body = {
      contactRoomId,
    };
    await apiClient.patch("/api/contact-room/patch-read-log-and-unread-count", body);
  }, []);

  return { updateReadLogAndUnreadCount };
};
