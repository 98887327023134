import { z } from "zod";

import { FormTypeSchema } from "../inputTypeSchemas/FormTypeSchema";

/////////////////////////////////////////
// ONN TASK SCHEMA
/////////////////////////////////////////

export const OnnTaskSchema = z.object({
  type: FormTypeSchema,
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  title: z
    .string()
    .trim()
    .min(1, { message: "タイトルが短すぎます" })
    .max(70, { message: "タイトルが長すぎます" }),
  description: z.string().trim().min(1, { message: "説明が短すぎます" }),
  filePaths: z.string().array(),
  assigneeId: z.string(),
  isAllowEditAnswer: z.boolean(),
  canAnswerAfterDeadline: z.boolean(),
  scheduledDate: z.date().nullish(),
  deadlineDate: z.date().nullish(),
  firstDeliveredAt: z.date().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type OnnTask = z.infer<typeof OnnTaskSchema>;

/////////////////////////////////////////
// ONN TASK OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const OnnTaskOptionalDefaultsSchema = OnnTaskSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type OnnTaskOptionalDefaults = z.infer<typeof OnnTaskOptionalDefaultsSchema>;

export default OnnTaskSchema;
