import {
  Action,
  AnyActionSettingExcludeMethods,
  AnyActionConditionExcludeMethods,
  AnyTriggerSetting,
} from "@onn/common";
import { useCallback } from "react";

import { useGenerateActionConditionDefaultValue } from "./common/useGenerateActionConditionDefaultValue";
import { useGenerateActionSettingDefaultValue } from "./common/useGenerateActionSettingDefaultValue";
import { useGenerateDeliveryTimingDefaultValue } from "./common/useGenerateDeliveryTimingDefaultValue";
import { ActionType, InputState } from "./inputStateSchema";

export const useGenerateDefaultValuesForUpdate = () => {
  const { generateActionSettingDefaultValue } = useGenerateActionSettingDefaultValue();
  const { generateDeliveryTimingDefaultValue } = useGenerateDeliveryTimingDefaultValue();
  const { generateActionConditionDefaultValue } = useGenerateActionConditionDefaultValue();

  const generateDefaultValuesForUpdate = useCallback(
    ({
      existingAction,
      existingAnyActionSetting,
      existingActionCondition,
      actionType,
      triggerSetting,
    }: {
      existingAction: Action;
      existingAnyActionSetting: AnyActionSettingExcludeMethods;
      existingActionCondition?: AnyActionConditionExcludeMethods;
      actionType: ActionType;
      triggerSetting: AnyTriggerSetting;
    }): Partial<InputState> => {
      return {
        actionSetting: generateActionSettingDefaultValue({
          actionType,
          existingAnyActionSetting,
        }),
        deliveryTiming: generateDeliveryTimingDefaultValue({ existingAction }),
        actionCondition: generateActionConditionDefaultValue({
          actionCondition: existingActionCondition,
          triggerSetting,
        }),
      };
    },
    [
      generateActionConditionDefaultValue,
      generateActionSettingDefaultValue,
      generateDeliveryTimingDefaultValue,
    ]
  );

  return { generateDefaultValuesForUpdate };
};
