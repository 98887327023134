import { OnnEventSlotDatesForDisplay } from "@onn/common";
import useSWR, { mutate } from "swr";

import { apiClient } from "~/libs";

export const generateOnnEventSlotDatesForDisplayKey = (onnEventId?: string) => {
  if (!onnEventId) return null;
  return [`/api/onn-event-slot-dates`, onnEventId];
};
export const useOnnEventSlotDatesForDisplay = (onnEventId?: string) => {
  return useSWR(generateOnnEventSlotDatesForDisplayKey(onnEventId), async () => {
    const response = await apiClient.get("/api/onn-event-slot-dates", { onnEventId });

    return response.data.onnEventSlotDatesForDisplay.map((v) => new OnnEventSlotDatesForDisplay(v));
  });
};

export const mutateOnnEventSlotDatesForDisplay = (onnEventId: string) => {
  mutate(generateOnnEventSlotDatesForDisplayKey(onnEventId));
};
