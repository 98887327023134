import { CandidateDateWithNumberOfParticipants } from "@onn/common";
import React, { useCallback } from "react";

import { CAN_NOT_JOIN } from "../../hooks/OnnEventAnswerResultTab/filter/useFilterByCandidateDates";

import { AnswerStatusHeaderCell } from "./TableCell/Header/AnswerStatusHeaderCell";
import { CandidateDateHeaderCell } from "./TableCell/Header/CandidateDateHeaderCell";
import { TotalCountHeaderCell } from "./TableCell/Header/TotalCountHeaderCell";
import { UnAvailableHeaderCell } from "./TableCell/Header/UnAvailableHeaderCell";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

// 回答ステータステーブルのヘッダーを生成する
export const useGenerateHeaderRow = () => {
  const generateHeaderRow = useCallback(
    ({
      candidateDateList,
      onnEventAnswers,
      hasCapacity,
      numberOfDistribution,
      numberOfResponses,
      selectedCandidateDateIds,
    }: {
      candidateDateList: CandidateDateWithNumberOfParticipants[];
      onnEventAnswers: OnnEventAnswerWithEmployee[];
      hasCapacity: boolean;
      numberOfDistribution: number;
      numberOfResponses: number;
      selectedCandidateDateIds: string[];
    }): JSX.Element[] => {
      const headerRow = [
        <TotalCountHeaderCell
          key="count-header-cell"
          numberOfDistribution={numberOfDistribution}
          numberOfResponses={numberOfResponses}
        />,
        <AnswerStatusHeaderCell key="answer-status-header-cell" />,
      ];

      const numOfAnswerWithUnavailableCandidates = onnEventAnswers.reduce((acc, currentAnswer) => {
        if (currentAnswer.isUnavailableCandidates()) return acc + 1;
        return acc;
      }, 0);

      // NOTE: 開催日時フィルターが選択されている場合は、選択された日程のみ表示する
      const displayedCandidateDates =
        selectedCandidateDateIds.length !== 0
          ? candidateDateList.filter((candidateDate) => {
              return selectedCandidateDateIds.includes(candidateDate.id);
            })
          : candidateDateList;

      displayedCandidateDates.forEach((candidateDate) => {
        headerRow.push(
          <CandidateDateHeaderCell
            hasCapacity={hasCapacity}
            numberOfParticipants={candidateDate.numberOfParticipants}
            capacity={candidateDate.capacity}
            from={candidateDate.from}
            until={candidateDate.until}
          />
        );
      });

      // NOTE: 開催日時フィルターが選択されていない場合か、「参加できる日程がない」のみ表示する
      if (
        selectedCandidateDateIds.length === 0 ||
        selectedCandidateDateIds.includes(CAN_NOT_JOIN)
      ) {
        headerRow.push(
          <UnAvailableHeaderCell
            numOfAnswerWithUnavailableCandidates={numOfAnswerWithUnavailableCandidates}
          />
        );
      }

      return headerRow;
    },
    []
  );

  return { generateHeaderRow };
};
