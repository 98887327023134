import { Box } from "@material-ui/core";
import React, { ReactNode, FC } from "react";

import { Loading, Typography } from "~/components/uiParts";

type Props = {
  label: string;
  children: ReactNode;
  isLoading: boolean;
};

export const ContentWithLabel: FC<Props> = ({ label, children, isLoading }) => (
  <Box height="100%" width="100%" display="flex" flexDirection="column" gridGap="16px">
    <Typography variant="caption" color="textSecondary" noWrap>
      {label}
    </Typography>
    {isLoading ? (
      <Loading size="small" />
    ) : (
      <Box height="40px" width="100%" display="flex" alignItems="center">
        {children}
      </Box>
    )}
  </Box>
);
