import { v4 } from "uuid";

import {
  IRecruitmentProcessRecordOnnTaskItemSchema,
  recruitmentProcessRecordOnnTaskItemSchema,
} from "./schema";

export class RecruitmentProcessRecordOnnTaskItem
  implements IRecruitmentProcessRecordOnnTaskItemSchema
{
  static readonly type = "Task" as const;
  type = RecruitmentProcessRecordOnnTaskItem.type;

  static readonly validator = recruitmentProcessRecordOnnTaskItemSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  employeeId: string;
  recruitmentStatusId: string;

  recruitmentProcessRecordId: string;
  onnTaskId: string;

  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<RecruitmentProcessRecordOnnTaskItem>) {
    const parsedInit = RecruitmentProcessRecordOnnTaskItem.validator.parse(init);
    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.employeeId = parsedInit.employeeId;
    this.recruitmentStatusId = parsedInit.recruitmentStatusId;

    this.recruitmentProcessRecordId = parsedInit.recruitmentProcessRecordId;

    this.onnTaskId = parsedInit.onnTaskId;

    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<RecruitmentProcessRecordOnnTaskItem>,
      "id" | "createdAt" | "updatedAt"
    >
  ): RecruitmentProcessRecordOnnTaskItem {
    return new RecruitmentProcessRecordOnnTaskItem({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }
}
