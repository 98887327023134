import { State } from "../../../_share_in_create_edit/reducer";

import { useOnClickCancelHandler } from "./useOnClickCancelHandler";
import { useOnClickPreviewHandler } from "./useOnClickPreviewHandler";
import { useOnClickSaveAndToNextHandler } from "./useOnClickSaveAndToNextHandler";

import { useFromPageInParam } from "~/pages/onn_task/_share/hooks/useFromPageInParam";

export const useActionFooter = ({
  state,
  onnFormTaskId,
}: {
  state: State;
  onnFormTaskId: string;
}) => {
  const { fromPage } = useFromPageInParam();
  const { onClickCancel } = useOnClickCancelHandler(onnFormTaskId);
  const { onClickPreview, isSubmitting: isSubmittingPreview } = useOnClickPreviewHandler({
    state,
    onnFormTaskId,
  });
  const { onClickSaveAndToNext, isSubmitting: isSubmittingSaving } = useOnClickSaveAndToNextHandler(
    {
      state,
      onnFormTaskId,
    }
  );
  const isSubmitting = isSubmittingPreview || isSubmittingSaving;

  const actionFooterHandler = {
    onClickCancel: onClickCancel,
    onClickPreview: onClickPreview,
    onClickSaveAndToNext: onClickSaveAndToNext,
  };

  const buttonsText = {
    saveButtonText: fromPage === "delivery_setting" ? "保存して次へ" : "変更を保存",
  };

  return { actionFooterHandler, isSubmitting, buttonsText };
};
