import { RadioQuestion, CheckBoxQuestion, FormRevision } from "@onn/common";
import { Question } from "@onn/common/domain/OnnTask/Question/Question";
import { isEmpty, unionBy } from "lodash";

type MergedOptionsQuestion = Question;
type QuestionHasOptions = RadioQuestion | CheckBoxQuestion;
const hasOptions = (q: Question): q is QuestionHasOptions =>
  q.type === "RADIO" || q.type === "CHECK_BOX";

export type RevisionQuestionData = {
  isLatest?: boolean;
  question: MergedOptionsQuestion;
};
export type SortedRevisionQuestionDataList = RevisionQuestionData[];

const sortByCreatedAtDESC = (a: FormRevision, b: FormRevision) => {
  return b.createdAt.getTime() - a.createdAt.getTime();
};

// 回答ステータステーブルのカラムデータを生成する
export const useGenerateSortedRevisionQuestionDataList = () => {
  const generateSortedRevisionQuestionDataList = (
    formRevisions: FormRevision[]
  ): SortedRevisionQuestionDataList => {
    if (isEmpty(formRevisions)) return [];

    const sortedRevisionQuestionDataList: SortedRevisionQuestionDataList = [];

    const sortedRevisions = formRevisions.sort(sortByCreatedAtDESC);
    const [latestFormRevision, ...notLatestFormRevisions] = sortedRevisions;

    sortedRevisionQuestionDataList.push(
      ...(latestFormRevision as (typeof sortedRevisions)[number]).questions.map((question) => ({
        isLatest: true,
        question,
      }))
    );

    // TODO: アルゴリズムを改善する
    notLatestFormRevisions.forEach((revision) => {
      revision.questions.forEach((question) => {
        const alreadyPushedData = sortedRevisionQuestionDataList.find(
          (data) => data.question.id === question.id
        );
        if (!alreadyPushedData) {
          sortedRevisionQuestionDataList.push({
            isLatest: false,
            question,
          });
          return;
        }

        const { question: alreadyPushedQuestion } = alreadyPushedData;
        // 複数選択肢の設問ではない場合はスキップする
        if (!hasOptions(alreadyPushedQuestion) || !hasOptions(question)) {
          return;
        }

        // 既にpush済みの選択肢をマージする
        alreadyPushedQuestion.options = unionBy(
          [...alreadyPushedQuestion.options, ...question.options],
          "id"
        );
      });
    });

    return sortedRevisionQuestionDataList;
  };

  return { generateSortedRevisionQuestionDataList };
};
