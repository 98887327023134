import { OnnEventDeterminedDate } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { convertUnixTimeToDateDeterminedDate } from "../../_shared/convertUnixTimeToDate";

export class ListOnnEventDeterminedDateResponse {
  static ResponseBody: ToCloudFunctionsCompatible<OnnEventDeterminedDate>[];

  public static createResponseBody({
    onnEventDeterminedDates,
  }: {
    onnEventDeterminedDates: OnnEventDeterminedDate[];
  }) {
    return toCloudFunctionsCompatible(onnEventDeterminedDates);
  }
  public static convertUnixTimeToDate(response: typeof this.ResponseBody) {
    return response.map((v) => convertUnixTimeToDateDeterminedDate(v));
  }
}
