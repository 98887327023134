import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useState, useCallback, FC, useMemo } from "react";
import styled from "styled-components";

import { SelectSingleEmployeesForm } from "../SelectEmployeesForm";

import { UserIcon, Button, Typography, Modal } from "~/components/uiParts";
import { useAllEmployees } from "~/hooks/employee";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (newMentorId?: string, emailForInvite?: string) => Promise<void>;
  newHire: Employee;
  mentor?: Employee;
  supportMembers?: Employee[];
};

export const AssignMentorModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  newHire,
  supportMembers,
  mentor,
}) => {
  const { allEmployees } = useAllEmployees();

  const notAllowEmails = useMemo(() => {
    // 自分自身、サポートメンバーのemailから成る配列
    return [
      newHire.email,
      ...(supportMembers || []).map((sm) => sm.email),
      ...(mentor?.email ? [mentor?.email] : []),
    ];
  }, [mentor?.email, newHire.email, supportMembers]);

  const [selectedEmail, setSelectedEmail] = useState<string | undefined>();

  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    const newMentor = allEmployees.find((v) => v.email === selectedEmail);
    const emailForInvite = selectedEmail;

    setSending(true);
    await onSubmit(newMentor?.id, emailForInvite)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [allEmployees, onCancel, onSubmit, selectedEmail]);

  const buildButtonText = () =>
    newHire.mentorUserId
      ? sending
        ? "変更中"
        : "バディを変更する"
      : sending
      ? "設定中"
      : "バディを設定する";

  return (
    <Modal
      open={open}
      title="バディ設定"
      content={
        <Box>
          <Box pr={3} pb={1} pl={3} display="flex" alignItems="center" gridGap="16px">
            <Box width="100%">
              <Box textAlign="center">
                <UserIcon
                  username={newHire.getName()}
                  profileIconImageUrl={newHire.profileIconImageUrl}
                  size="medium"
                  borderColor="primary"
                />
                <Box pb={2} />
                <Typography variant="body2" align="center">
                  {`${newHire.getName()}さんのバディを\n設定してください`}
                </Typography>
              </Box>
            </Box>
            <Box width="100%">
              <SelectSingleEmployeesForm
                notAllowEmails={notAllowEmails}
                selectedEmail={selectedEmail}
                onSelectEmail={setSelectedEmail}
              />
            </Box>
          </Box>
          <Typography variant="caption" color="textSecondary" align="center" display="block">
            Onnに登録されていないメンバーを設定したい場合は、
            <StyledPointerTypography
              display="inline"
              variant="caption"
              bold
              color="primary"
              onClick={() => window.open("/settings/admin", "_blank")}
            >
              こちら
            </StyledPointerTypography>
            から招待を行ってください。
          </Typography>
        </Box>
      }
      footer={
        <StyledButtonContainer>
          <Button
            fullWidth
            borderRadius="circle"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedEmail || sending}
          >
            {buildButtonText()}
          </Button>
        </StyledButtonContainer>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledPointerTypography = styled(Typography)`
  cursor: pointer;
`;
