import { Box } from "@material-ui/core";
import React from "react";

import { Typography } from "~/components/uiParts";

export const NewGraduateWelcomePaper = ({ tenantName }: { tenantName: string }): JSX.Element => {
  return (
    <Box textAlign="center">
      <Typography variant="h2" bold color="primary">
        🎉Welcome!🎉
      </Typography>

      <Box height="16px" />

      <Typography variant="body2">
        {`${tenantName}に興味をもっていただき、ありがとうございます！\n定期的に配信されるコンテンツをぜひご覧ください！`}
      </Typography>
    </Box>
  );
};
