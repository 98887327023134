import { Box } from "@mui/material";
import { TriggerWithRelationInfo } from "@onn/common";
import React, { FC, useCallback, useEffect, useState } from "react";

import styled from "styled-components";

import { OptionSettingDrawer } from "../../../components/domains/richmenu/OptionSettingDrawer/OptionSettingDrawer";
import { usePageContext } from "../pageContext";

import { ScenarioFlowAreaWhenEditMode } from "./editMode/ScenarioFlowAreaWhenEditMode";

import { ScenarioFlowAreaWhenViewMode } from "./viewMode/ScenarioFlowAreaWhenViewMode";

import { Button, Icon, Loading, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useScenarioTriggersForDisplay } from "~/hooks/scenario/useScenarioTriggersForDisplay";

/**
 * シナリオフローのメイン画面
 * この画面で使用している /scenario_api/triggers/for_display はこの画面専用に作っているので、コンポーネントをAPISchemaのresponseに依存させている
 */
export const Main: FC = () => {
  const { data: triggers, isLoading: isLoadingTriggers } = useScenarioTriggersForDisplay();

  if (isLoadingTriggers) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100vh">
        <Loading size={"large"} color="primary" />
      </Box>
    );
  }

  return <MainCore triggers={triggers || []} />;
};

export const MainCore: FC<{
  triggers: TriggerWithRelationInfo[];
}> = ({ triggers }) => {
  const { currentUser } = useCurrentUser();
  const { scenarioId, selectedRecruitmentStatus, mode } = usePageContext();
  const filteredTriggers = triggers.filter(
    (trigger) => trigger.recruitmentStatusId === selectedRecruitmentStatus.id
  );

  const [isOpenOptionSettingDrawer, setIsOpenOptionSettingDrawer] = useState(false);
  const closeOptionSettingDrawer = useCallback(() => {
    setIsOpenOptionSettingDrawer(false);
  }, []);
  const { optionSettingDrawerKey } = useOptionSettingDrawerKey(
    selectedRecruitmentStatus.id,
    isOpenOptionSettingDrawer
  );

  return (
    <Root>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <RecruitmentStatusLabel />
        {currentUser.isAdmin() && mode === "view" && (
          <OptionSettingButton onClick={() => setIsOpenOptionSettingDrawer(true)} />
        )}
      </Box>
      {mode === "view" && <ScenarioFlowAreaWhenViewMode triggers={filteredTriggers} />}
      {mode === "edit" && <ScenarioFlowAreaWhenEditMode allTriggers={triggers} />}
      <OptionSettingDrawer
        key={optionSettingDrawerKey}
        isOpen={isOpenOptionSettingDrawer}
        close={closeOptionSettingDrawer}
        scenarioId={scenarioId}
        recruitmentStatusId={selectedRecruitmentStatus.id}
        type={"portalRichMenu"}
      />
    </Root>
  );
};

/**
 * オプション設定drawerのkeyを設定する
 * - 「選考ステータスが切り替わる」or「drawerが閉じられる」ときにkeyが変更される
 *  - リッチメニューの設定は非同期で行われるため、mutateを行うのが難しく、drawer内のステートをリセットするため
 */
const useOptionSettingDrawerKey = (
  selectedRecruitmentStatusId: string,
  isOpenOptionSettingDrawer: boolean
) => {
  const [optionSettingDrawerKey, setIsOpenOptionSettingDrawerKey] = useState<string | null>(
    selectedRecruitmentStatusId
  );
  // drawer開閉時にコンポーネントごと初期化するためにuseEffectでkeyを制御する
  useEffect(() => {
    if (isOpenOptionSettingDrawer) {
      setIsOpenOptionSettingDrawerKey(selectedRecruitmentStatusId);
    } else {
      setIsOpenOptionSettingDrawerKey(null);
    }
  }, [isOpenOptionSettingDrawer, selectedRecruitmentStatusId]);

  return { optionSettingDrawerKey };
};

const OptionSettingButton: FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={onClick}
      startIcon={<Icon icon="setting" size="ssm" color="primary" />}
      borderRadius="regular"
    >
      オプション設定
    </Button>
  );
};

const RecruitmentStatusLabel: FC = () => {
  const { selectedRecruitmentStatus } = usePageContext();

  return (
    <Box paddingY={"4px"}>
      <Typography bold variant="h4">
        {selectedRecruitmentStatus.label}
      </Typography>
    </Box>
  );
};

const Root = styled(Box)`
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
`;
