import { useMemo } from "react";

export const useErrorTargetText = ({
  isTriggerRelatedDeletedStatus,
  isActionRelatedDeletedStatus,
}: {
  isTriggerRelatedDeletedStatus: boolean;
  isActionRelatedDeletedStatus: boolean;
}) => {
  const errorTargetText = useMemo(() => {
    if (isTriggerRelatedDeletedStatus && isActionRelatedDeletedStatus)
      return "トリガーとアクションの条件";
    if (isTriggerRelatedDeletedStatus) return "トリガーの条件";
    if (isActionRelatedDeletedStatus) return "アクションの条件";

    return undefined;
  }, [isActionRelatedDeletedStatus, isTriggerRelatedDeletedStatus]);

  return { errorTargetText };
};
