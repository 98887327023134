import { Box } from "@material-ui/core";
import { RecruitmentStatus } from "@onn/common";
import React, { FC, useMemo } from "react";

import { useEditModeContext } from "../../EditModeContextProvider";

import { Button, Typography, Icon, UncontrolledMenu } from "~/components/uiParts";

/**
 * 選考ステータスを選択するセレクター
 * selectableRecruitmentStatusesを指定しない場合は、全ての選考ステータスが選択可能
 */
export const SelectorOfRecruitmentStatus: FC<{
  selectedRecruitmentStatusId?: string;
  onChange: (value: RecruitmentStatus["id"]) => void;
  errorMessage?: string;
  selectableRecruitmentStatuses?: RecruitmentStatus[];
}> = ({ onChange, selectedRecruitmentStatusId, errorMessage, selectableRecruitmentStatuses }) => {
  const { allRecruitmentStatuses } = useEditModeContext();
  const recruitmentStatusesForOptions = useMemo(() => {
    return selectableRecruitmentStatuses ?? allRecruitmentStatuses;
  }, [allRecruitmentStatuses, selectableRecruitmentStatuses]);
  const selected = allRecruitmentStatuses.find(
    (status) => status.id === selectedRecruitmentStatusId
  );
  return (
    <Box>
      <UncontrolledMenu
        renderButton={(openMenu) => (
          <Button
            color="default"
            variant="outlined"
            borderRadius="regular"
            fullWidth
            onClick={openMenu}
            endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
          >
            <Box width={"100%"} flex={1} display={"flex"}>
              <Typography variant="body2" noWrap>
                {selected?.label ?? "選考ステータスを選択"}
              </Typography>
            </Box>
          </Button>
        )}
        menuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
        }}
        menuItemOptions={recruitmentStatusesForOptions.map((status) => ({
          text: status.label,
          onClick: () => {
            onChange(status.id);
          },
        }))}
      />
      {errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
