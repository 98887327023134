import { z } from "zod";

export const loginFormSchema = z.object({
  email: z
    .string({
      required_error: "メールアドレスを入力してください",
    })
    .min(1, { message: "メールアドレスを入力してください" })
    .email({ message: "メールアドレスの形式を正しく入力してください" }),
  password: z.string().min(6),
});
