import { useCallback } from "react";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useUpdateUnregisteredEmployeeInvitedAt = () => {
  /**
   * 未登録ユーザーの招待日時を更新する
   */
  const updateUnregisteredEmployeeInvitedAt = useCallback(
    async (invitationToken: string, lineAccessToken?: string) => {
      try {
        await apiClient.patch("/api/employee/update-unregistered-employee-invited-at", {
          invitationToken,
          lineAccessToken,
        });
      } catch (e) {
        captureException({
          error: e as Error,
          tags: { type: "updateUnregisteredEmployeeInvitedAt" },
        });
      }
    },
    []
  );

  return { updateUnregisteredEmployeeInvitedAt };
};
