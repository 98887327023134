import React, { useCallback } from "react";

import { v4 } from "uuid";

import { Action } from "../../../_share_in_create_edit/reducer";

export const useRadioQuestionFormHandler = (dispatch: React.Dispatch<Action>) => {
  const onChangeRadioOptionLabel = useCallback(
    ({ questionId, optionId, label }: { questionId: string; optionId: string; label: string }) => {
      dispatch({
        type: "RADIO_QUESTION_UPDATE_OPTION",
        payload: {
          questionId: questionId,
          optionId,
          text: label,
        },
      });
    },
    [dispatch]
  );

  const onClickDeleteRadioOption = useCallback(
    ({ questionId, optionId }: { questionId: string; optionId: string }) => {
      dispatch({
        type: "RADIO_QUESTION_DELETE_OPTION",
        payload: {
          questionId: questionId,
          optionId,
        },
      });
    },
    [dispatch]
  );

  const onClickAddRadioOption = useCallback(
    ({ questionId }: { questionId: string }) => {
      dispatch({
        type: "RADIO_QUESTION_ADD_OPTION",
        payload: {
          questionId: questionId,
          optionId: v4(),
        },
      });
    },
    [dispatch]
  );

  return { onChangeRadioOptionLabel, onClickDeleteRadioOption, onClickAddRadioOption };
};
