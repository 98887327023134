import { Box } from "@material-ui/core";
import React from "react";

import { Typography } from "~/components/uiParts";
import { useClipboard } from "~/hooks/shared";

export const TriggerDataIdRendererForDeveloper = ({
  triggerId,
  triggerSettingId,
  waitingTriggerBlockCount,
}: {
  triggerId: string;
  triggerSettingId: string;
  waitingTriggerBlockCount: number;
}) => {
  const { handleCopy } = useClipboard();
  const onClick = (triggerId: string, target: string) => {
    handleCopy(triggerId, target);
  };
  return (
    <Box>
      <Box onClick={() => onClick(triggerId, "トリガーID")} style={{ cursor: "pointer" }}>
        <Typography variant="caption" color="textSecondary">
          トリガーID: {triggerId}
        </Typography>
      </Box>
      <Box
        onClick={() => onClick(triggerSettingId, "トリガー設定ID")}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="caption" color="textSecondary">
          トリガー設定ID: {triggerSettingId}
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" color="textSecondary">
          トリガー待機ブロック: {waitingTriggerBlockCount || "0"}
        </Typography>
      </Box>
    </Box>
  );
};
