import { AnyEmployeeInformationValue } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { mutateEmployeeInformationValues } from "./useEmployeeInformationValues";

import { apiClient } from "~/libs";

export const useEditEmployeeInformationValues = () => {
  const { enqueueSnackbar } = useSnackbar();

  const editEmployeeInformationValues = useCallback(
    async ({
      employeeId,
      employeeInformationFieldId,
      employeeInformationValues,
    }: {
      employeeId: string;
      employeeInformationFieldId: string;
      employeeInformationValues: AnyEmployeeInformationValue[];
    }) => {
      await apiClient
        .post("/api/employee-information-values", {
          employeeId,
          employeeInformationFieldId,
          employeeInformationValues,
        })
        .then(async () => {
          enqueueSnackbar("候補者情報を更新しました", { variant: "success" });
          mutateEmployeeInformationValues({ employeeId });
        })
        .catch((error) => {
          if (error instanceof Error) {
            enqueueSnackbar(error.message, { variant: "error" });
          }
        });
    },
    [enqueueSnackbar]
  );

  return { editEmployeeInformationValues };
};
