export const onnEventActionTypes = {
  updateDeliverySettingOfOnnEvent: "updateDeliverySettingOfOnnEvent",
  createOnnEvent: "createOnnEvent",
  listOnnEvents: "listOnnEvents",
  updateOnnEvent: "updateOnnEvent",
  deleteOnnEvent: "deleteOnnEvent",
  listCandidateDatesWithNumberOfParticipants: "listCandidateDatesWithNumberOfParticipants",
} as const;

export type OnnEventActionTypes = (typeof onnEventActionTypes)[keyof typeof onnEventActionTypes];
