import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ErrorPage } from "~/components/domains/account/ErrorPage";
import { Loading } from "~/components/uiParts";
import { CenterBox } from "~/components/uiParts/CenterBox";

import { useRegistrationInvitationLinkWithoutId } from "~/hooks/registrationInvitationLink/useRegistrationInvitationLinkWithoutId";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";

export const RegistrationInvitationLinks: FC = () => {
  const { isLoading, isNotFound, registrationInvitationLink } =
    useRegistrationInvitationLinkWithoutId(true);
  const navigate = useNavigateWithQuery();
  const { type } = useParams<{ type: string }>();

  useEffect(() => {
    if (registrationInvitationLink) {
      navigate(
        `/portal/accounts/registration/${type}/registration_invitation_links/${registrationInvitationLink.id}`
      );
    }
  }, [isLoading, navigate, registrationInvitationLink, type]);

  if (isNotFound) {
    return (
      <ErrorPage
        pageType="アカウント登録ページ"
        detail="アカウント登録中間ページで、共通招待リンクが取得できませんでした"
      />
    );
  }

  // このページの責務はリダイレクトなので、エラー以外はローディング表示しかしない
  return (
    <CenterBox>
      <Loading size="large" />
    </CenterBox>
  );
};
