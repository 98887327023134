import { z } from "zod";

/////////////////////////////////////////
// ANNOUNCEMENT SCHEMA
/////////////////////////////////////////

export const AnnouncementSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  title: z
    .string()
    .trim()
    .min(1, { message: "タイトルは1文字以上で設定してください" })
    .max(50, { message: "タイトルは50文字以内で設定してください" }),
  content: z
    .string()
    .trim()
    .min(1, { message: "内容は1文字以上で設定してください" })
    .max(5000, { message: "内容は5000文字以内で設定してください" }),
  filePaths: z.string().array(),
  creatorId: z.string(),
  publicationStartDate: z.date().nullish(),
  publicationEndDate: z.date().nullish(),
  firstPublishedAt: z.date().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Announcement = z.infer<typeof AnnouncementSchema>;

/////////////////////////////////////////
// ANNOUNCEMENT OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const AnnouncementOptionalDefaultsSchema = AnnouncementSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type AnnouncementOptionalDefaults = z.infer<typeof AnnouncementOptionalDefaultsSchema>;

export default AnnouncementSchema;
