import { TextField as MuiTextField, useTheme } from "@mui/material";
import React, { FC, ComponentProps } from "react";

type MuiTextFieldProps = ComponentProps<typeof MuiTextField>;

type Props = Pick<
  MuiTextFieldProps,
  | "value"
  | "variant"
  | "helperText"
  | "error"
  | "fullWidth"
  | "placeholder"
  | "disabled"
  | "defaultValue"
  | "onChange"
  | "type"
>;

export const TextField: FC<Props> = (props) => {
  const { palette } = useTheme();
  return (
    <MuiTextField
      {...props}
      size="small"
      sx={{
        ".MuiInputBase-input": {
          px: 2,
          py: "6.5px",
          fontSize: 16,
          height: 27,
          borderRadius: "5px",
          "&.Mui-disabled": {
            background: palette.grey[50],
            color: palette.grey[400],
          },
        },
        ".MuiFormHelperText-root": {
          marginLeft: 0,
          fontSize: "10px",
          lineHeight: "13px",
        },
      }}
    />
  );
};
