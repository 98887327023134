import React, { FC } from "react";

export const PaperAirplane: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        id="paper-airplane"
        stroke="none"
        d="M11.353,24.13,7.8,17.785l-6.353-3.56a1.98,1.98,0,0,1,.687-3.306L17.1,5.854a2.086,2.086,0,0,1,2.619,2.619L14.658,23.442a1.914,1.914,0,0,1-1.839,1.3A2.063,2.063,0,0,1,11.353,24.13Zm1.369-2.5L17.207,8.37,3.945,12.856l4.531,2.539L11.27,12.6a1.207,1.207,0,1,1,1.706,1.706L10.184,17.1Z"
        transform="translate(1.172 -2.747)"
      />
    </svg>
  );
};
