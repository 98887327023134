import { zodResolver } from "@hookform/resolvers/zod";
import { graduationYearAndMonthSchema } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { SelectForm, Typography } from "~/components/uiParts";

type Props = {
  onSave: (graduationYearAndMonth: z.infer<typeof graduationYearAndMonthSchema>) => void;
  onCancel: () => void;
  data?: z.infer<typeof graduationYearAndMonthSchema>;
};

export const GraduationYearAndMonthEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, control, formState } = useForm<
    z.infer<typeof graduationYearAndMonthSchema>
  >({
    defaultValues: {
      ...data,
    },
    mode: "onChange",
    resolver: zodResolver(graduationYearAndMonthSchema),
  });

  return (
    <InformationValueEditor
      form={
        <form
          style={{ display: "flex", flexDirection: "column", rowGap: 16 }}
          onSubmit={handleSubmit(onSave)}
        >
          <Controller
            name="graduationYear"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">年</Typography>
                </div>
                <SelectForm
                  fullWidth
                  selected={field.value || null}
                  menuItems={[...new Array(10)].map((_, index) => {
                    // NOTE: 現時点から前後5年分を選択肢として表示する
                    const number = index + new Date().getFullYear() - 5;
                    return { name: number.toString(), value: number };
                  })}
                  errorBorder={!!error}
                  errorText={error?.message}
                  onChange={field.onChange}
                  isSmall
                />
              </div>
            )}
          />
          <Controller
            name="graduationMonth"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">月</Typography>
                </div>
                <SelectForm
                  fullWidth
                  selected={field.value || null}
                  menuItems={[...new Array(12)].map((_, index) => {
                    const number = index + 1;
                    return { name: number.toString(), value: number };
                  })}
                  errorBorder={!!error}
                  errorText={error?.message}
                  onChange={field.onChange}
                  isSmall
                />
              </div>
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};
