import { convertFileToBase64 } from "@onn/common";
import React, { FC, useCallback, useEffect } from "react";

import { FileAttachedButtonForStorage } from "./FileAttachedButtonForStorage";

export const FileQuestionContent: FC<{
  file: File;
  signedUrl?: string;
}> = ({ file, signedUrl }) => {
  const [url, setUrl] = React.useState<string | undefined>("");

  const handleGenerateUrl = useCallback(async () => {
    const url = file ? await convertFileToBase64(file).then((res) => res) : undefined;
    setUrl(url);
  }, [file]);
  useEffect(() => {
    handleGenerateUrl();
  }, [handleGenerateUrl]);

  const ext = file.name.split(".")?.pop() || "";
  const isImageFile = ["png", "jpg", "jpeg", "gif"].includes(ext);

  if (isImageFile) {
    return <img src={url} width={"100%"} style={{ objectFit: "contain" }} />;
  } else {
    return <FileAttachedButtonForStorage file={file} url={url} signedUrl={signedUrl} />;
  }
};
