import { Announcement, toCloudFunctionsCompatible } from "@onn/common";
import { useCallback } from "react";

import { useLocalStorage } from "~/hooks/shared";

export const ANNOUNCEMENT_PREVIEW_DATA_KEY = "AnnouncementsDataForPreview";

export const useOpenPortalAnnouncementPreview = () => {
  const { storeValue } = useLocalStorage();

  const openPortalAnnouncementPreview = useCallback(
    (announcement: Announcement) => {
      storeValue(ANNOUNCEMENT_PREVIEW_DATA_KEY, {
        announcement: toCloudFunctionsCompatible(announcement),
      });
      window.open(
        `/portal/announcements/${announcement.id}?preview=true`,
        "popup",
        "width=375,height=480"
      );
    },
    [storeValue]
  );

  return { openPortalAnnouncementPreview };
};
