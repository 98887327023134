import { reminderSchema } from "@onn/common";
import { set } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { z } from "zod";

export const reminderFormSchema = z.object({
  title: reminderSchema.shape.title,
  remindDate: z
    .object({
      remindedAt: z.date(),
      remindHour: z.number(),
    })
    .transform((data) => ({
      ...data,
      remindedAt: zonedTimeToUtc(
        set(utcToZonedTime(data.remindedAt, "Asia/Tokyo"), {
          hours: data.remindHour,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }),
        "Asia/Tokyo"
      ),
    }))
    .superRefine((data, context) => {
      if (data.remindedAt <= new Date()) {
        context.addIssue({
          path: ["remindHour"],
          code: z.ZodIssueCode.invalid_date,
          message: "現在より後の時刻を設定してください",
        });
      }
    }),
});
