import { Failure, Result, OnnEvent, Success } from "../../../../domain";
import { toCloudFunctionsCompatible } from "../../../../utils/toCloudFunctionsCompatible";
import { OnnEventAnswerRequestType } from "../shared";

import { SupportedJoinProperty } from "./protocol";
import { listOnnEventAnswersRequestSchema } from "./schema";

export class ListOnnEventAnswersRequest {
  static readonly validator = listOnnEventAnswersRequestSchema;
  static actionType = OnnEventAnswerRequestType.actionTypes.listOnnEventAnswers;
  onnEventId: OnnEvent["id"];
  join?: SupportedJoinProperty[];

  constructor(init: ListOnnEventAnswersRequest) {
    this.onnEventId = init.onnEventId;
    this.join = init.join;
  }

  public static createRequestBody({
    onnEventId,
    join,
  }: {
    onnEventId: ListOnnEventAnswersRequest["onnEventId"];
    join?: ListOnnEventAnswersRequest["join"];
  }) {
    return toCloudFunctionsCompatible({
      actionType: this.actionType,
      onnEventId,
      join,
    });
  }

  public static validate(
    data: ListOnnEventAnswersRequest
  ): Result<ListOnnEventAnswersRequest, Error> {
    const onnEventIdResult = ListOnnEventAnswersRequest.validator.safeParse(data);
    if (!onnEventIdResult.success) {
      return new Failure(new Error(onnEventIdResult.error.message));
    }

    return new Success(new ListOnnEventAnswersRequest(data));
  }
}
