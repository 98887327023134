import { EmployeeTagActionCondition } from "./EmployeeTagActionCondition";
import { OnnEventEvaluationActionCondition } from "./OnnEventEvaluationActionCondition";
import { OnnFormTaskResultActionCondition } from "./OnnFormTaskResultActionCondition";

export { EmployeeTagActionCondition } from "./EmployeeTagActionCondition";
export { OnnFormTaskResultActionCondition } from "./OnnFormTaskResultActionCondition";
export { OnnEventEvaluationActionCondition } from "./OnnEventEvaluationActionCondition";

export type AnyActionCondition =
  | EmployeeTagActionCondition
  | OnnFormTaskResultActionCondition
  | OnnEventEvaluationActionCondition;

export type AnyActionConditionExcludeMethods =
  | ExcludeMethods<EmployeeTagActionCondition>
  | ExcludeMethods<OnnFormTaskResultActionCondition>
  | ExcludeMethods<OnnEventEvaluationActionCondition>;

export const groupByActionConditionType = (actionConditions: AnyActionCondition[]) => {
  const result: {
    employeeTagActionConditions: EmployeeTagActionCondition[];
    onnFormTaskResultActionConditions: OnnFormTaskResultActionCondition[];
    onnEventEvaluationActionConditions: OnnEventEvaluationActionCondition[];
  } = {
    employeeTagActionConditions: [],
    onnFormTaskResultActionConditions: [],
    onnEventEvaluationActionConditions: [],
  };

  for (const actionCondition of actionConditions) {
    switch (actionCondition.type) {
      case "EmployeeTagActionCondition":
        result.employeeTagActionConditions.push(actionCondition);
        break;
      case "OnnFormTaskResultActionCondition":
        result.onnFormTaskResultActionConditions.push(actionCondition);
        break;
      case "OnnEventEvaluationActionCondition":
        result.onnEventEvaluationActionConditions.push(actionCondition);
        break;
      default: {
        const _exhaustiveCheck: never = actionCondition;
        return _exhaustiveCheck;
      }
    }
  }

  return result;
};

export const extractRelatedResourceFromAnyActionCondition = (
  anyActionCondition: AnyActionConditionExcludeMethods
): {
  onnEventId: string | null;
  onnEventEvaluationRankIds: string[] | null;
  employeeTagIds: string[] | null;
  onnTaskId: string | null;
  questionId: string | null;
  optionId: string | null;
} => {
  switch (anyActionCondition.type) {
    case "OnnEventEvaluationActionCondition":
      return {
        onnEventId: anyActionCondition.onnEventId,
        onnEventEvaluationRankIds: anyActionCondition.onnEventEvaluationRankIds,
        employeeTagIds: null,
        onnTaskId: null,
        questionId: null,
        optionId: null,
      };
    case "EmployeeTagActionCondition":
      return {
        onnEventId: null,
        onnEventEvaluationRankIds: null,
        employeeTagIds: anyActionCondition.employeeTagIds,
        onnTaskId: null,
        questionId: null,
        optionId: null,
      };
    case "OnnFormTaskResultActionCondition":
      return {
        onnEventId: null,
        onnEventEvaluationRankIds: null,
        employeeTagIds: null,
        onnTaskId: anyActionCondition.onnFormTaskId,
        questionId: anyActionCondition.questionId,
        optionId: anyActionCondition.optionId,
      };
    default: {
      const _exhaustiveCheck: never = anyActionCondition;
      return _exhaustiveCheck;
    }
  }
};
