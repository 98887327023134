import { APISchema, OnnTask, OnnFormTask } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

export const useOnnTask = ({ onnTaskId }: { onnTaskId?: string }): SWRResponse<OnnTask, Error> => {
  return useSWR(
    onnTaskId ? generateOnnTaskKey(onnTaskId) : null,
    async ({ onnTaskId, endpoint }): Promise<OnnTask> => {
      const queryParam: APISchema["/api/onn-task/get-onn-task"]["GET"]["query"] = {
        id: onnTaskId,
      };
      const response = await apiClient.get(endpoint, queryParam);
      switch (response.data.type) {
        case "FORM_TASK": {
          return new OnnFormTask(response.data);
        }
        default: {
          throw new Error(`${response.data.type} is not implemented yet`);
        }
      }
    }
  );
};

const generateOnnTaskKey = (onnTaskId: string) => {
  return { endpoint: "/api/onn-task/get-onn-task" as const, onnTaskId };
};
